/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityRelationStatsGroupResult } from './MonitEntityRelationStatsGroupResult';
import {
    MonitEntityRelationStatsGroupResultFromJSON,
    MonitEntityRelationStatsGroupResultFromJSONTyped,
    MonitEntityRelationStatsGroupResultToJSON,
} from './MonitEntityRelationStatsGroupResult';

/**
 * 
 * @export
 * @interface MonitEntityRelationStatsGroups
 */
export interface MonitEntityRelationStatsGroups {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationStatsGroups
     */
    assets?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationStatsGroups
     */
    risk_score?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationStatsGroups
     */
    total?: number;
    /**
     * 
     * @type {Array<MonitEntityRelationStatsGroupResult>}
     * @memberof MonitEntityRelationStatsGroups
     */
    results?: Array<MonitEntityRelationStatsGroupResult>;
}

/**
 * Check if a given object implements the MonitEntityRelationStatsGroups interface.
 */
export function instanceOfMonitEntityRelationStatsGroups(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityRelationStatsGroupsFromJSON(json: any): MonitEntityRelationStatsGroups {
    return MonitEntityRelationStatsGroupsFromJSONTyped(json, false);
}

export function MonitEntityRelationStatsGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRelationStatsGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MonitEntityRelationStatsGroupResultFromJSON)),
    };
}

export function MonitEntityRelationStatsGroupsToJSON(value?: MonitEntityRelationStatsGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets,
        'risk_score': value.risk_score,
        'total': value.total,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MonitEntityRelationStatsGroupResultToJSON)),
    };
}

