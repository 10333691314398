/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor } from './ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor';
import {
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSON,
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSONTyped,
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorToJSON,
} from './ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor';
import type { ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress } from './ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress';
import {
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSON,
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressToJSON,
} from './ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990ContractorCompensation
 */
export interface ExecRiskAppCoreModelsScansIRS990ContractorCompensation {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensation
     */
    contractor_name?: ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensation
     */
    contractor_address?: ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensation
     */
    services_desc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensation
     */
    compensation_amount?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990ContractorCompensation interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990ContractorCompensation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990ContractorCompensation {
    return ExecRiskAppCoreModelsScansIRS990ContractorCompensationFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990ContractorCompensation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractor_name': !exists(json, 'contractor_name') ? undefined : ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSON(json['contractor_name']),
        'contractor_address': !exists(json, 'contractor_address') ? undefined : ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSON(json['contractor_address']),
        'services_desc': !exists(json, 'services_desc') ? undefined : json['services_desc'],
        'compensation_amount': !exists(json, 'compensation_amount') ? undefined : json['compensation_amount'],
    };
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationToJSON(value?: ExecRiskAppCoreModelsScansIRS990ContractorCompensation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractor_name': ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorToJSON(value.contractor_name),
        'contractor_address': ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressToJSON(value.contractor_address),
        'services_desc': value.services_desc,
        'compensation_amount': value.compensation_amount,
    };
}

