/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlVendor = {
    Acreto: 'Acreto',
    Aqua: 'Aqua',
    Armorblox: 'Armorblox',
    Atera: 'Atera',
    AwsWaf: 'AWS WAF',
    AzureAppProxy: 'Azure App Proxy',
    CarbonBlackCloud: 'Carbon Black Cloud',
    CiscoAmp: 'Cisco AMP',
    Cloudflare: 'Cloudflare',
    Coalition: 'Coalition',
    CoalitionMdr: 'Coalition MDR',
    Cofense: 'Cofense',
    Comodo: 'Comodo',
    Crowdstrike: 'Crowdstrike',
    Curricula: 'Curricula',
    Datto: 'Datto',
    Duo: 'Duo',
    JumpCloud: 'JumpCloud',
    Kandji: 'Kandji',
    KnowBe4: 'KnowBe4',
    Malwarebytes: 'Malwarebytes',
    MaterialSecurity: 'Material Security',
    NinjaOne: 'NinjaOne',
    Okta: 'Okta',
    Orca: 'Orca',
    PaloAltoNetworks: 'Palo Alto Networks',
    PaloAltoNetworksPrisma: 'Palo Alto Networks Prisma',
    PhishedIo: 'phished.io',
    Proofpoint: 'Proofpoint',
    Rubrik: 'Rubrik',
    SentinelOne: 'SentinelOne',
    Sysdig: 'Sysdig',
    Tailscale: 'Tailscale',
    Twingate: 'Twingate',
    Vaeem: 'Vaeem',
    WindowsAutopatch: 'Windows Autopatch',
    Wiz: 'Wiz',
    Zscalar: 'Zscalar'
} as const;
export type ControlVendor = typeof ControlVendor[keyof typeof ControlVendor];


export function ControlVendorFromJSON(json: any): ControlVendor {
    return ControlVendorFromJSONTyped(json, false);
}

export function ControlVendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlVendor {
    return json as ControlVendor;
}

export function ControlVendorToJSON(value?: ControlVendor | null): any {
    return value as any;
}

