/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientRoles } from './ControlClientRoles';
import {
    ControlClientRolesFromJSON,
    ControlClientRolesFromJSONTyped,
    ControlClientRolesToJSON,
} from './ControlClientRoles';
import type { ControlPostTestingUser } from './ControlPostTestingUser';
import {
    ControlPostTestingUserFromJSON,
    ControlPostTestingUserFromJSONTyped,
    ControlPostTestingUserToJSON,
} from './ControlPostTestingUser';

/**
 * 
 * @export
 * @interface ControlPostTestingCreateClient
 */
export interface ControlPostTestingCreateClient {
    /**
     * 
     * @type {ControlClientRoles}
     * @memberof ControlPostTestingCreateClient
     */
    client_role: ControlClientRoles;
    /**
     * 
     * @type {Array<ControlPostTestingUser>}
     * @memberof ControlPostTestingCreateClient
     */
    users: Array<ControlPostTestingUser>;
}

/**
 * Check if a given object implements the ControlPostTestingCreateClient interface.
 */
export function instanceOfControlPostTestingCreateClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_role" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function ControlPostTestingCreateClientFromJSON(json: any): ControlPostTestingCreateClient {
    return ControlPostTestingCreateClientFromJSONTyped(json, false);
}

export function ControlPostTestingCreateClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPostTestingCreateClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_role': ControlClientRolesFromJSON(json['client_role']),
        'users': ((json['users'] as Array<any>).map(ControlPostTestingUserFromJSON)),
    };
}

export function ControlPostTestingCreateClientToJSON(value?: ControlPostTestingCreateClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_role': ControlClientRolesToJSON(value.client_role),
        'users': ((value.users as Array<any>).map(ControlPostTestingUserToJSON)),
    };
}

