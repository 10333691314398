const fallbacks: string[] = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const fontDMSans: string = ['"DM Sans"', ...fallbacks].join(',');

export const fontPublicSans: string = ['"Public Sans"', ...fallbacks].join(',');
