/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlFindingsLevel = {
    Info: 'info',
    Low: 'low',
    Medium: 'medium',
    High: 'high',
    Critical: 'critical'
} as const;
export type ControlFindingsLevel = typeof ControlFindingsLevel[keyof typeof ControlFindingsLevel];


export function ControlFindingsLevelFromJSON(json: any): ControlFindingsLevel {
    return ControlFindingsLevelFromJSONTyped(json, false);
}

export function ControlFindingsLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFindingsLevel {
    return json as ControlFindingsLevel;
}

export function ControlFindingsLevelToJSON(value?: ControlFindingsLevel | null): any {
    return value as any;
}

