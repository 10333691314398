/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskTriggerResponse } from './ExecRiskTriggerResponse';
import {
    ExecRiskTriggerResponseFromJSON,
    ExecRiskTriggerResponseFromJSONTyped,
    ExecRiskTriggerResponseToJSON,
} from './ExecRiskTriggerResponse';

/**
 * 
 * @export
 * @interface ExecRiskTriggerListResponse
 */
export interface ExecRiskTriggerListResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskTriggerListResponse
     */
    total: number;
    /**
     * 
     * @type {Array<ExecRiskTriggerResponse>}
     * @memberof ExecRiskTriggerListResponse
     */
    result: Array<ExecRiskTriggerResponse>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerListResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskTriggerListResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskTriggerListResponse
     */
    page_size: number;
}

/**
 * Check if a given object implements the ExecRiskTriggerListResponse interface.
 */
export function instanceOfExecRiskTriggerListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;

    return isInstance;
}

export function ExecRiskTriggerListResponseFromJSON(json: any): ExecRiskTriggerListResponse {
    return ExecRiskTriggerListResponseFromJSONTyped(json, false);
}

export function ExecRiskTriggerListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskTriggerListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'result': ((json['result'] as Array<any>).map(ExecRiskTriggerResponseFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': json['page'],
        'page_size': json['page_size'],
    };
}

export function ExecRiskTriggerListResponseToJSON(value?: ExecRiskTriggerListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'result': ((value.result as Array<any>).map(ExecRiskTriggerResponseToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

