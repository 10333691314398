/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalGetReportFormInstancesByFilterResponseResultsInner } from './SupplementalGetReportFormInstancesByFilterResponseResultsInner';
import {
    SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSON,
    SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSONTyped,
    SupplementalGetReportFormInstancesByFilterResponseResultsInnerToJSON,
} from './SupplementalGetReportFormInstancesByFilterResponseResultsInner';

/**
 * 
 * @export
 * @interface SupplementalGetReportFormInstancesByRecipientAccountResponse
 */
export interface SupplementalGetReportFormInstancesByRecipientAccountResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplementalGetReportFormInstancesByRecipientAccountResponse
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplementalGetReportFormInstancesByRecipientAccountResponse
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplementalGetReportFormInstancesByRecipientAccountResponse
     */
    next?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SupplementalGetReportFormInstancesByRecipientAccountResponse
     */
    previous?: number | null;
    /**
     * 
     * @type {Array<SupplementalGetReportFormInstancesByFilterResponseResultsInner>}
     * @memberof SupplementalGetReportFormInstancesByRecipientAccountResponse
     */
    results?: Array<SupplementalGetReportFormInstancesByFilterResponseResultsInner>;
}

/**
 * Check if a given object implements the SupplementalGetReportFormInstancesByRecipientAccountResponse interface.
 */
export function instanceOfSupplementalGetReportFormInstancesByRecipientAccountResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetReportFormInstancesByRecipientAccountResponseFromJSON(json: any): SupplementalGetReportFormInstancesByRecipientAccountResponse {
    return SupplementalGetReportFormInstancesByRecipientAccountResponseFromJSONTyped(json, false);
}

export function SupplementalGetReportFormInstancesByRecipientAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetReportFormInstancesByRecipientAccountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSON)),
    };
}

export function SupplementalGetReportFormInstancesByRecipientAccountResponseToJSON(value?: SupplementalGetReportFormInstancesByRecipientAccountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'limit': value.limit,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SupplementalGetReportFormInstancesByFilterResponseResultsInnerToJSON)),
    };
}

