import React, { useMemo } from 'react';
import clsx from 'clsx';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { formatMessage } from '@explorer/helpers';
import { useIntl } from 'react-intl';
import { withCoreProps } from '../../withCoreProps';

export const Text = withCoreProps<TextProps>(
  ({
    label,
    children,
    className,
    ...props
  }: React.PropsWithChildren<TextProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);
    const intl = useIntl();

    const content = useMemo(() => {
      if (label && children) {
        // eslint-disable-next-line no-console
        console.warn(
          'Warning: [packages/core/Text] `children` is overwritten by the usage of a `label`. Please use only one.',
        );
      }

      if (label) return formatMessage(intl, label);

      return children;
    }, [label, children]);

    return (
      <MuiTypography {...props} className={clsxClass}>
        {content}
      </MuiTypography>
    );
  },
);

export interface TextProps extends TypographyProps {
  /**
   * `label` - *OPTIONAL* - string or localized message id
   */
  label?: IntlLabel;
}
