/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress
     */
    SupportedOrganizationName?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress
     */
    CityNm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress
     */
    StateAbbreviationCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress
     */
    CountryCd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SupportedOrganizationName': !exists(json, 'SupportedOrganizationName') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['SupportedOrganizationName']),
        'CityNm': !exists(json, 'CityNm') ? undefined : json['CityNm'],
        'StateAbbreviationCd': !exists(json, 'StateAbbreviationCd') ? undefined : json['StateAbbreviationCd'],
        'CountryCd': !exists(json, 'CountryCd') ? undefined : json['CountryCd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SupportedOrganizationName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.SupportedOrganizationName),
        'CityNm': value.CityNm,
        'StateAbbreviationCd': value.StateAbbreviationCd,
        'CountryCd': value.CountryCd,
    };
}

