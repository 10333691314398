/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityLookalikesDnsAnswer } from './ControlEntityLookalikesDnsAnswer';
import {
    ControlEntityLookalikesDnsAnswerFromJSON,
    ControlEntityLookalikesDnsAnswerFromJSONTyped,
    ControlEntityLookalikesDnsAnswerToJSON,
} from './ControlEntityLookalikesDnsAnswer';

/**
 * 
 * @export
 * @interface ControlEntityLookalikesDns
 */
export interface ControlEntityLookalikesDns {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesDns
     */
    type?: string;
    /**
     * 
     * @type {Array<ControlEntityLookalikesDnsAnswer>}
     * @memberof ControlEntityLookalikesDns
     */
    answers?: Array<ControlEntityLookalikesDnsAnswer>;
}

/**
 * Check if a given object implements the ControlEntityLookalikesDns interface.
 */
export function instanceOfControlEntityLookalikesDns(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityLookalikesDnsFromJSON(json: any): ControlEntityLookalikesDns {
    return ControlEntityLookalikesDnsFromJSONTyped(json, false);
}

export function ControlEntityLookalikesDnsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityLookalikesDns {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'answers': !exists(json, 'answers') ? undefined : ((json['answers'] as Array<any>).map(ControlEntityLookalikesDnsAnswerFromJSON)),
    };
}

export function ControlEntityLookalikesDnsToJSON(value?: ControlEntityLookalikesDns | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'answers': value.answers === undefined ? undefined : ((value.answers as Array<any>).map(ControlEntityLookalikesDnsAnswerToJSON)),
    };
}

