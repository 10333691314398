/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlReportedAssetStatus = {
    Reported: 'reported',
    FalsePositiveConfirmed: 'false_positive_confirmed',
    FalsePositiveRejected: 'false_positive_rejected',
    Cancelled: 'cancelled'
} as const;
export type ControlReportedAssetStatus = typeof ControlReportedAssetStatus[keyof typeof ControlReportedAssetStatus];


export function ControlReportedAssetStatusFromJSON(json: any): ControlReportedAssetStatus {
    return ControlReportedAssetStatusFromJSONTyped(json, false);
}

export function ControlReportedAssetStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlReportedAssetStatus {
    return json as ControlReportedAssetStatus;
}

export function ControlReportedAssetStatusToJSON(value?: ControlReportedAssetStatus | null): any {
    return value as any;
}

