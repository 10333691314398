/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Holds all required information about the desired policy (quote).
 * @export
 * @interface CRSFDesiredPolicyFieldsOutput
 */
export interface CRSFDesiredPolicyFieldsOutput {
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    agency: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    attachment_point?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    company_name: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    company_revenue: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    employee_count: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    insurance_market?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    is_excess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    mfa_email?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    mfa_other_privileged_accounts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    mfa_remote_access?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    pii_phi_count: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    quote_origination?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    technical_sophistication?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDesiredPolicyFieldsOutput
     */
    updated?: number;
}

/**
 * Check if a given object implements the CRSFDesiredPolicyFieldsOutput interface.
 */
export function instanceOfCRSFDesiredPolicyFieldsOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "company_revenue" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "employee_count" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "is_excess" in value;
    isInstance = isInstance && "pii_phi_count" in value;

    return isInstance;
}

export function CRSFDesiredPolicyFieldsOutputFromJSON(json: any): CRSFDesiredPolicyFieldsOutput {
    return CRSFDesiredPolicyFieldsOutputFromJSONTyped(json, false);
}

export function CRSFDesiredPolicyFieldsOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDesiredPolicyFieldsOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': json['agency'],
        'attachment_point': !exists(json, 'attachment_point') ? undefined : json['attachment_point'],
        'company_name': json['company_name'],
        'company_revenue': json['company_revenue'],
        'created': json['created'],
        'employee_count': json['employee_count'],
        'industry': json['industry'],
        'insurance_market': !exists(json, 'insurance_market') ? undefined : json['insurance_market'],
        'is_excess': json['is_excess'],
        'mfa_email': !exists(json, 'mfa_email') ? undefined : json['mfa_email'],
        'mfa_other_privileged_accounts': !exists(json, 'mfa_other_privileged_accounts') ? undefined : json['mfa_other_privileged_accounts'],
        'mfa_remote_access': !exists(json, 'mfa_remote_access') ? undefined : json['mfa_remote_access'],
        'pii_phi_count': json['pii_phi_count'],
        'quote_origination': !exists(json, 'quote_origination') ? undefined : json['quote_origination'],
        'technical_sophistication': !exists(json, 'technical_sophistication') ? undefined : json['technical_sophistication'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function CRSFDesiredPolicyFieldsOutputToJSON(value?: CRSFDesiredPolicyFieldsOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': value.agency,
        'attachment_point': value.attachment_point,
        'company_name': value.company_name,
        'company_revenue': value.company_revenue,
        'created': value.created,
        'employee_count': value.employee_count,
        'industry': value.industry,
        'insurance_market': value.insurance_market,
        'is_excess': value.is_excess,
        'mfa_email': value.mfa_email,
        'mfa_other_privileged_accounts': value.mfa_other_privileged_accounts,
        'mfa_remote_access': value.mfa_remote_access,
        'pii_phi_count': value.pii_phi_count,
        'quote_origination': value.quote_origination,
        'technical_sophistication': value.technical_sophistication,
        'updated': value.updated,
    };
}

