/**
 * Returns array of keys from object, which values were true.
 *
 * __> Example:__
 * ```ts
 * getTruthyKeysFromObject({val1: true, val2: false, val3: "foo"});
 * // ['val1']
 * ```
 */
export const getTruthyKeysFromObject = (obj: Record<string, boolean> = {}) => {
  if (!obj) {
    return [];
  }

  return Object.keys(obj).filter((k: string) => obj[k] === true);
};
