/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewDomain
 */
export interface CRSFSecReviewDomain {
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFSecReviewDomain
     */
    blocklists?: Array<any>;
    /**
     * 
     * @type {Array<object>}
     * @memberof CRSFSecReviewDomain
     */
    dns_records: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDomain
     */
    domain: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFSecReviewDomain
     */
    ip_addresses?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDomain
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDomain
     */
    source_asset: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewDomain
     */
    updated: number;
}

/**
 * Check if a given object implements the CRSFSecReviewDomain interface.
 */
export function instanceOfCRSFSecReviewDomain(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dns_records" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "source_asset" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFSecReviewDomainFromJSON(json: any): CRSFSecReviewDomain {
    return CRSFSecReviewDomainFromJSONTyped(json, false);
}

export function CRSFSecReviewDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocklists': !exists(json, 'blocklists') ? undefined : json['blocklists'],
        'dns_records': json['dns_records'],
        'domain': json['domain'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'source': json['source'],
        'source_asset': json['source_asset'],
        'updated': json['updated'],
    };
}

export function CRSFSecReviewDomainToJSON(value?: CRSFSecReviewDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocklists': value.blocklists,
        'dns_records': value.dns_records,
        'domain': value.domain,
        'ip_addresses': value.ip_addresses,
        'source': value.source,
        'source_asset': value.source_asset,
        'updated': value.updated,
    };
}

