import React, {
  useState,
  useMemo,
  useCallback,
  ReactElement,
  ReactNode,
} from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { formatMessage } from '@explorer/helpers';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Tooltip = withCoreProps<TooltipProps>(
  ({
    title,
    children,
    className,
    ...props
  }: React.PropsWithChildren<TooltipProps>) => {
    const clsxClass = clsx(className);
    const intl = useIntl();
    const [open, setOpen] = useState<boolean>(false);

    const openTooltip = useCallback(() => setOpen(true), []);
    const closeTooltip = useCallback(() => setOpen(false), []);

    const tooltipTitle = useMemo(() => {
      if (typeof title === 'string' || (title as any)?.id) {
        return formatMessage(intl, title as IntlLabel);
      }
      return title as ReactNode;
    }, [intl, title]);

    return (
      <MuiTooltip
        {...props}
        title={
          tooltipTitle ? (
            // Use 'pre-line' to preserve whitespace characters such as newlines (\n) and tabs (\t)
            <div style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</div>
          ) : null
        }
        open={open}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        onClick={closeTooltip}
        disableHoverListener
        className={clsxClass}
      >
        <div style={{ display: 'inline-block' }}>{children}</div>
      </MuiTooltip>
    );
  },
);

export type TooltipProps = Omit<
  MuiTooltipProps,
  'disableHoverListener' | 'open' | 'title'
> &
  CoreProps & {
    title: IntlLabel | ReactNode;
  };
