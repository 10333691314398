/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityKeywordsResult } from './MonitEntityKeywordsResult';
import {
    MonitEntityKeywordsResultFromJSON,
    MonitEntityKeywordsResultFromJSONTyped,
    MonitEntityKeywordsResultToJSON,
} from './MonitEntityKeywordsResult';

/**
 * 
 * @export
 * @interface MonitPaginatedEntityKeywordsResponse
 */
export interface MonitPaginatedEntityKeywordsResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityKeywordsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityKeywordsResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityKeywordsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityKeywordsResult>}
     * @memberof MonitPaginatedEntityKeywordsResponse
     */
    results?: Array<MonitEntityKeywordsResult>;
}

/**
 * Check if a given object implements the MonitPaginatedEntityKeywordsResponse interface.
 */
export function instanceOfMonitPaginatedEntityKeywordsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitPaginatedEntityKeywordsResponseFromJSON(json: any): MonitPaginatedEntityKeywordsResponse {
    return MonitPaginatedEntityKeywordsResponseFromJSONTyped(json, false);
}

export function MonitPaginatedEntityKeywordsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitPaginatedEntityKeywordsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MonitEntityKeywordsResultFromJSON)),
    };
}

export function MonitPaginatedEntityKeywordsResponseToJSON(value?: MonitPaginatedEntityKeywordsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MonitEntityKeywordsResultToJSON)),
    };
}

