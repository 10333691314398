import React from 'react';
import clsx from 'clsx';
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const AccordionSummary = withCoreProps<AccordionSummaryProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<AccordionSummaryProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAccordionSummary {...props} className={clsxClass}>
        {children}
      </MuiAccordionSummary>
    );
  },
);

export interface AccordionSummaryProps
  extends MuiAccordionSummaryProps,
    CoreProps {}
