/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlImpersonationDetails } from './ControlImpersonationDetails';
import {
    ControlImpersonationDetailsFromJSON,
    ControlImpersonationDetailsFromJSONTyped,
    ControlImpersonationDetailsToJSON,
} from './ControlImpersonationDetails';
import type { ControlImpersonationType } from './ControlImpersonationType';
import {
    ControlImpersonationTypeFromJSON,
    ControlImpersonationTypeFromJSONTyped,
    ControlImpersonationTypeToJSON,
} from './ControlImpersonationType';
import type { ControlProfileFieldResponseAny } from './ControlProfileFieldResponseAny';
import {
    ControlProfileFieldResponseAnyFromJSON,
    ControlProfileFieldResponseAnyFromJSONTyped,
    ControlProfileFieldResponseAnyToJSON,
} from './ControlProfileFieldResponseAny';
import type { ControlSurvey } from './ControlSurvey';
import {
    ControlSurveyFromJSON,
    ControlSurveyFromJSONTyped,
    ControlSurveyToJSON,
} from './ControlSurvey';
import type { ControlTrial } from './ControlTrial';
import {
    ControlTrialFromJSON,
    ControlTrialFromJSONTyped,
    ControlTrialToJSON,
} from './ControlTrial';

/**
 * 
 * @export
 * @interface ControlUserProfile
 */
export interface ControlUserProfile {
    /**
     * 
     * @type {string}
     * @memberof ControlUserProfile
     */
    coalition_user_uuid?: string;
    /**
     * 
     * @type {object}
     * @memberof ControlUserProfile
     */
    quotas?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlUserProfile
     */
    invited_emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlUserProfile
     */
    er_process_id?: string;
    /**
     * 
     * @type {object}
     * @memberof ControlUserProfile
     */
    overview: object;
    /**
     * 
     * @type {Array<ControlSurvey>}
     * @memberof ControlUserProfile
     */
    eligible_surveys: Array<ControlSurvey>;
    /**
     * 
     * @type {string}
     * @memberof ControlUserProfile
     */
    source?: string;
    /**
     * 
     * @type {ControlTrial}
     * @memberof ControlUserProfile
     */
    trial?: ControlTrial;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserProfile
     */
    impersonation: boolean;
    /**
     * 
     * @type {ControlImpersonationType}
     * @memberof ControlUserProfile
     */
    impersonation_type?: ControlImpersonationType;
    /**
     * 
     * @type {ControlImpersonationDetails}
     * @memberof ControlUserProfile
     */
    impersonation_details?: ControlImpersonationDetails;
    /**
     * 
     * @type {string}
     * @memberof ControlUserProfile
     */
    principal_client_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserProfile
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {Array<ControlProfileFieldResponseAny>}
     * @memberof ControlUserProfile
     */
    profile_fields?: Array<ControlProfileFieldResponseAny>;
}

/**
 * Check if a given object implements the ControlUserProfile interface.
 */
export function instanceOfControlUserProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "overview" in value;
    isInstance = isInstance && "eligible_surveys" in value;
    isInstance = isInstance && "impersonation" in value;

    return isInstance;
}

export function ControlUserProfileFromJSON(json: any): ControlUserProfile {
    return ControlUserProfileFromJSONTyped(json, false);
}

export function ControlUserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coalition_user_uuid': !exists(json, 'coalition_user_uuid') ? undefined : json['coalition_user_uuid'],
        'quotas': !exists(json, 'quotas') ? undefined : json['quotas'],
        'invited_emails': !exists(json, 'invited_emails') ? undefined : json['invited_emails'],
        'er_process_id': !exists(json, 'er_process_id') ? undefined : json['er_process_id'],
        'overview': json['overview'],
        'eligible_surveys': ((json['eligible_surveys'] as Array<any>).map(ControlSurveyFromJSON)),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'trial': !exists(json, 'trial') ? undefined : ControlTrialFromJSON(json['trial']),
        'impersonation': json['impersonation'],
        'impersonation_type': !exists(json, 'impersonation_type') ? undefined : ControlImpersonationTypeFromJSON(json['impersonation_type']),
        'impersonation_details': !exists(json, 'impersonation_details') ? undefined : ControlImpersonationDetailsFromJSON(json['impersonation_details']),
        'principal_client_name': !exists(json, 'principal_client_name') ? undefined : json['principal_client_name'],
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'profile_fields': !exists(json, 'profile_fields') ? undefined : ((json['profile_fields'] as Array<any>).map(ControlProfileFieldResponseAnyFromJSON)),
    };
}

export function ControlUserProfileToJSON(value?: ControlUserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coalition_user_uuid': value.coalition_user_uuid,
        'quotas': value.quotas,
        'invited_emails': value.invited_emails,
        'er_process_id': value.er_process_id,
        'overview': value.overview,
        'eligible_surveys': ((value.eligible_surveys as Array<any>).map(ControlSurveyToJSON)),
        'source': value.source,
        'trial': ControlTrialToJSON(value.trial),
        'impersonation': value.impersonation,
        'impersonation_type': ControlImpersonationTypeToJSON(value.impersonation_type),
        'impersonation_details': ControlImpersonationDetailsToJSON(value.impersonation_details),
        'principal_client_name': value.principal_client_name,
        'account_line_uuid': value.account_line_uuid,
        'profile_fields': value.profile_fields === undefined ? undefined : ((value.profile_fields as Array<any>).map(ControlProfileFieldResponseAnyToJSON)),
    };
}

