import React, { memo, ReactNode, useMemo } from 'react';
import { useUser, Permissions } from '@explorer/stores';

/**
 * PermissionWrapper
 */
export const PermissionWrapper = memo(
  //
  function PermissionWrapper({
    has = [],
    children = null,
    notAllowedComponent = null,
    hasAtLeastOnePermission = false,
  }: PermissionWrapperProps) {
    const { userHasPermissions, userHasSomePermissions } = useUser();

    const allow = useMemo(() => {
      if (hasAtLeastOnePermission) {
        return userHasSomePermissions(has);
      }
      return userHasPermissions(has);
    }, [has, hasAtLeastOnePermission]);

    if (allow) return <>{children}</>;

    return <>{notAllowedComponent}</>;
  },
);

PermissionWrapper.displayName = 'PermissionWrapper';

/**
 * PermissionWrapperProps
 */
export interface PermissionWrapperProps {
  readonly has: Permissions[];
  readonly hasAtLeastOnePermission?: boolean;
  readonly children: ReactNode;
  readonly notAllowedComponent?: ReactNode;
}
