// Same regex as one used on the backend
// const IS_VALID_DOMAIN_NAME_REGEX = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;
// regex without an extra space at the end of it
const IS_VALID_DOMAIN_NAME_REGEX =
  /^(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)(?!\s)/;

/**
 * Checks if the param is a valid domain name.
 *
 * __> Example:__
 * ```ts
 * isValidDomain('google.com');
 * // true
 *
 * isValidDomain('coalitioninc.com');
 * // true
 * ```
 */

export const isValidDomain: IsValidDomain = (domainName) => {
  return IS_VALID_DOMAIN_NAME_REGEX.test(domainName);
};

type IsValidDomain = (domainName: string) => boolean;
