/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlPPPLoansScan
 */
export interface ControlPPPLoansScan {
    /**
     * 
     * @type {string}
     * @memberof ControlPPPLoansScan
     */
    date_approved?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPPPLoansScan
     */
    loan_amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPPPLoansScan
     */
    lender?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPPPLoansScan
     */
    recipient?: string;
}

/**
 * Check if a given object implements the ControlPPPLoansScan interface.
 */
export function instanceOfControlPPPLoansScan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlPPPLoansScanFromJSON(json: any): ControlPPPLoansScan {
    return ControlPPPLoansScanFromJSONTyped(json, false);
}

export function ControlPPPLoansScanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPPPLoansScan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_approved': !exists(json, 'date_approved') ? undefined : json['date_approved'],
        'loan_amount': !exists(json, 'loan_amount') ? undefined : json['loan_amount'],
        'lender': !exists(json, 'lender') ? undefined : json['lender'],
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
    };
}

export function ControlPPPLoansScanToJSON(value?: ControlPPPLoansScan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_approved': value.date_approved,
        'loan_amount': value.loan_amount,
        'lender': value.lender,
        'recipient': value.recipient,
    };
}

