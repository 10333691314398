/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlExceptionStatus = {
    AcceptedRisk: 'accepted_risk',
    ThirdPartyRisk: 'third_party_risk',
    FalsePositive: 'false_positive'
} as const;
export type ControlExceptionStatus = typeof ControlExceptionStatus[keyof typeof ControlExceptionStatus];


export function ControlExceptionStatusFromJSON(json: any): ControlExceptionStatus {
    return ControlExceptionStatusFromJSONTyped(json, false);
}

export function ControlExceptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlExceptionStatus {
    return json as ControlExceptionStatus;
}

export function ControlExceptionStatusToJSON(value?: ControlExceptionStatus | null): any {
    return value as any;
}

