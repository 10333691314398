import React from 'react';
import { TypographyProps, ChipSmall, ChipSmallProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellChip
 */
export const CellChip = ({
  label,
  variant = 'bodyXS',
  color = 'neutral',
  fullWidth = true,
  textTransform,
}: CellChipProps) => {
  if (!label && label !== 0) return <CellEmpty variant={variant} />;

  return (
    <ChipSmall
      label={label}
      color={color}
      fullWidth={fullWidth}
      textTransform={textTransform}
    />
  );
};

/**
 * CellChip Props
 */
export interface CellChipProps
  extends Pick<TypographyProps, 'variant'>,
    Pick<ChipSmallProps, 'label' | 'color' | 'fullWidth' | 'textTransform'> {}
