/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Builtwith } from './Builtwith';
import {
    BuiltwithFromJSON,
    BuiltwithFromJSONTyped,
    BuiltwithToJSON,
} from './Builtwith';
import type { CRSFEmailLeaksAggregatedResponse } from './CRSFEmailLeaksAggregatedResponse';
import {
    CRSFEmailLeaksAggregatedResponseFromJSON,
    CRSFEmailLeaksAggregatedResponseFromJSONTyped,
    CRSFEmailLeaksAggregatedResponseToJSON,
} from './CRSFEmailLeaksAggregatedResponse';
import type { CRSFScanEventRecord } from './CRSFScanEventRecord';
import {
    CRSFScanEventRecordFromJSON,
    CRSFScanEventRecordFromJSONTyped,
    CRSFScanEventRecordToJSON,
} from './CRSFScanEventRecord';
import type { CRSFSourcelistsData } from './CRSFSourcelistsData';
import {
    CRSFSourcelistsDataFromJSON,
    CRSFSourcelistsDataFromJSONTyped,
    CRSFSourcelistsDataToJSON,
} from './CRSFSourcelistsData';
import type { CRSFSpidersAggregatedResponse } from './CRSFSpidersAggregatedResponse';
import {
    CRSFSpidersAggregatedResponseFromJSON,
    CRSFSpidersAggregatedResponseFromJSONTyped,
    CRSFSpidersAggregatedResponseToJSON,
} from './CRSFSpidersAggregatedResponse';
import type { CRSFWappalyzerAggregatedData } from './CRSFWappalyzerAggregatedData';
import {
    CRSFWappalyzerAggregatedDataFromJSON,
    CRSFWappalyzerAggregatedDataFromJSONTyped,
    CRSFWappalyzerAggregatedDataToJSON,
} from './CRSFWappalyzerAggregatedData';
import type { RiskSummary } from './RiskSummary';
import {
    RiskSummaryFromJSON,
    RiskSummaryFromJSONTyped,
    RiskSummaryToJSON,
} from './RiskSummary';
import type { Virustotal } from './Virustotal';
import {
    VirustotalFromJSON,
    VirustotalFromJSONTyped,
    VirustotalToJSON,
} from './Virustotal';

/**
 * 
 * @export
 * @interface CRSFAggregatedResponse
 */
export interface CRSFAggregatedResponse {
    /**
     * 
     * @type {Builtwith}
     * @memberof CRSFAggregatedResponse
     */
    builtwith: Builtwith;
    /**
     * 
     * @type {CRSFEmailLeaksAggregatedResponse}
     * @memberof CRSFAggregatedResponse
     */
    dataleaks: CRSFEmailLeaksAggregatedResponse;
    /**
     * 
     * @type {object}
     * @memberof CRSFAggregatedResponse
     */
    enumerated: object;
    /**
     * 
     * @type {Array<CRSFScanEventRecord>}
     * @memberof CRSFAggregatedResponse
     */
    events: Array<CRSFScanEventRecord>;
    /**
     * 
     * @type {RiskSummary}
     * @memberof CRSFAggregatedResponse
     */
    risk_summary: RiskSummary;
    /**
     * 
     * @type {CRSFSourcelistsData}
     * @memberof CRSFAggregatedResponse
     */
    sourcelists: CRSFSourcelistsData;
    /**
     * 
     * @type {CRSFSpidersAggregatedResponse}
     * @memberof CRSFAggregatedResponse
     */
    spiders: CRSFSpidersAggregatedResponse;
    /**
     * 
     * @type {Virustotal}
     * @memberof CRSFAggregatedResponse
     */
    virustotal: Virustotal;
    /**
     * 
     * @type {Array<CRSFWappalyzerAggregatedData>}
     * @memberof CRSFAggregatedResponse
     */
    wappalyzer?: Array<CRSFWappalyzerAggregatedData>;
}

/**
 * Check if a given object implements the CRSFAggregatedResponse interface.
 */
export function instanceOfCRSFAggregatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "builtwith" in value;
    isInstance = isInstance && "dataleaks" in value;
    isInstance = isInstance && "enumerated" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "risk_summary" in value;
    isInstance = isInstance && "sourcelists" in value;
    isInstance = isInstance && "spiders" in value;
    isInstance = isInstance && "virustotal" in value;

    return isInstance;
}

export function CRSFAggregatedResponseFromJSON(json: any): CRSFAggregatedResponse {
    return CRSFAggregatedResponseFromJSONTyped(json, false);
}

export function CRSFAggregatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFAggregatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builtwith': BuiltwithFromJSON(json['builtwith']),
        'dataleaks': CRSFEmailLeaksAggregatedResponseFromJSON(json['dataleaks']),
        'enumerated': json['enumerated'],
        'events': ((json['events'] as Array<any>).map(CRSFScanEventRecordFromJSON)),
        'risk_summary': RiskSummaryFromJSON(json['risk_summary']),
        'sourcelists': CRSFSourcelistsDataFromJSON(json['sourcelists']),
        'spiders': CRSFSpidersAggregatedResponseFromJSON(json['spiders']),
        'virustotal': VirustotalFromJSON(json['virustotal']),
        'wappalyzer': !exists(json, 'wappalyzer') ? undefined : ((json['wappalyzer'] as Array<any>).map(CRSFWappalyzerAggregatedDataFromJSON)),
    };
}

export function CRSFAggregatedResponseToJSON(value?: CRSFAggregatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builtwith': BuiltwithToJSON(value.builtwith),
        'dataleaks': CRSFEmailLeaksAggregatedResponseToJSON(value.dataleaks),
        'enumerated': value.enumerated,
        'events': ((value.events as Array<any>).map(CRSFScanEventRecordToJSON)),
        'risk_summary': RiskSummaryToJSON(value.risk_summary),
        'sourcelists': CRSFSourcelistsDataToJSON(value.sourcelists),
        'spiders': CRSFSpidersAggregatedResponseToJSON(value.spiders),
        'virustotal': VirustotalToJSON(value.virustotal),
        'wappalyzer': value.wappalyzer === undefined ? undefined : ((value.wappalyzer as Array<any>).map(CRSFWappalyzerAggregatedDataToJSON)),
    };
}

