import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {
  Box,
  Container,
  Grid,
  Image,
  Typography,
  TypographyLink,
} from '@explorer/core';

/**
 * Footer Styles
 */
const useStyles = ({}: Partial<FooterProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        footer: {
          width: '100%',
          height: '400px',
          paddingTop: '160px',
          backgroundImage:
            'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjU1MHB4IiBoZWlnaHQ9IjE4MTZweCIgdmlld0JveD0iMCAwIDI1NTAgMTgxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICA8cGF0aCBkPSJNMjM5OC4zNTU1OSwwIEMyNDUwLjkyNDY5LDAgMjUwMS4zNzQ4MywxLjEyODk4MDYzIDI1NTAsMy4xNjQxODUyIEwyNTUwLDEwMzYgTDAsMTAzNiBMMCwzLjE2NDE3OTU4IEM0NDQuMjY4NjU0LDIxLjc1ODcwNDcgNzM2LjE5MTMzOSwxMTYgMTA5OS45OTY4LDExNiBDMTUzMS40NTc1MywxMTYgMTYwNC4wMzA0MSwwIDIzOTguMzU1NTksMCBaIiBmaWxsPSIjMkVBMEVEIj48L3BhdGg+CiAgPHBhdGggZD0iTTE4NzcuOTg5NjIsNDMgQzIxNTMuOTE4NTcsNDMgMjM3MS4yNjkxOSw1MC44MTcyNTUyIDI1NTAsNjEuNDcwMTk3NiBMMjU1MCwxMDM2IEwwLDEwMzYgTDAsNjEuNDcwMzUwMSBDMzc2LjYxNjM4Myw4My45MTcwMzI1IDU4MS43NTI0MzMsMTE4Ljk1MzQ4IDgwMi4xMzY5NDgsMTE5Ljk3NzA1NCBMODExLjk5NTUxLDEyMCBDMTE5NS40NTIwNCwxMjAgMTMyNS41NzI1Niw0MyAxODc3Ljk4OTYyLDQzIFoiIGZpbGw9IiMyNzczRTAiPjwvcGF0aD4KICA8cGF0aCBkPSJNMjEwMS45NzcwMSw2NSBMMjExNy4xMjc5Miw2NSBDMjI3OS44MjAyNiw2NS4xMjgwNTM3IDI0MjMuMTE2NjgsNjcuMjE3MTMyNiAyNTUwLDcwLjUyNjk2NjUgTDI1NDkuOTcyMTEsMTgxNS41Mjk4NSBMOTY1Ljk4OTQzNCwxODE1LjUyOTg1IEwwLDE4MTYgTDAuMDI2ODg4OTEyNiw3MC45OTcwOTQ4IEM1NDIuNjgyNDgyLDg1LjE1MjU4MjQgNzg1LjEyNDY1NywxMjEuNjM3MDEzIDk2MC42ODk1MTcsMTIyLjA5NjEwMSBMOTY2LDEyMiBDMTI2Ny45OTY3LDEyMi4zNDM2MyAxNjgzLjk4MTU4LDY1IDIxMDEuOTc3MDEsNjUgWiIgZmlsbD0iIzAzMkY2NCI+PC9wYXRoPgo8L3N2Zz4=")',
        },
        copyright: {
          width: '100%',
          height: '80px',
          backgroundColor: '#000',
        },
        position: {
          position: 'relative',
          bottom: 0,
          width: '100%',
        },
      }),
    { index: 1 },
  )();

/**
 * Footer
 */
export const Footer = ({}: FooterProps) => {
  const classes = useStyles({});

  return (
    <Box mt={6} textColor="#fff" className={classes.position}>
      <Box className={classes.footer}>
        <Container height="100%">
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
            mt={2}
          >
            <Grid item width="40%">
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">
                    Coalition Explorer
                  </Typography>
                </Grid>
                <Grid item mt={2}>
                  <Typography variant="caption">
                    Explore Coalition&apos;s book through the tools and data
                    collected by the Customer Security team about Policyholders.
                    Cyber security posture and behaviour. Details about assets,
                    technologies, and vulnerabilities. ASM notifications and CRA
                    generation. With a whole new module to manage claims data,
                    contacts, vendors, losses, and carrier loss runs.
                  </Typography>
                </Grid>
                <Grid item mt={4}>
                  <TypographyLink
                    title="Coalition Inc."
                    href="https://www.coalitioninc.com/"
                    linkIcon="external"
                    color="secondary"
                    variant="bodyS"
                    newTab
                  >
                    coalitioninc.com
                  </TypographyLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">Get in touch</Typography>
                </Grid>
                <Grid item mt={2}>
                  <Typography variant="caption">
                    Are you having issues? Or have some feedback about the app?
                    Send us a message in Slack.
                  </Typography>
                </Grid>
                <Grid item mt={4}>
                  <TypographyLink
                    title="Send us a message in Slack (#help-team-explorer)"
                    href="https://coalitionrisk.slack.com/archives/C04DDE6KW93"
                    linkIcon="external"
                    color="secondary"
                    variant="bodyS"
                    newTab
                  >
                    #help-team-explorer
                  </TypographyLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.copyright}>
        <Container height="100%">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
          >
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Image
                    title="Coalition, Inc."
                    src="/img/coalition-outline-logo-i.png"
                    height="35px"
                    noUserSelect
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Typography variant="caption">
                    Coalition Explorer © {new Date().getFullYear()} Coalition,
                    Inc.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

/**
 * Footer Props
 */
export interface FooterProps {}
