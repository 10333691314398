/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitCountFindingsInterval = {
    _050: '0-50',
    _51100: '51-100',
    _101500: '101-500',
    _500: '+500'
} as const;
export type MonitCountFindingsInterval = typeof MonitCountFindingsInterval[keyof typeof MonitCountFindingsInterval];


export function MonitCountFindingsIntervalFromJSON(json: any): MonitCountFindingsInterval {
    return MonitCountFindingsIntervalFromJSONTyped(json, false);
}

export function MonitCountFindingsIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitCountFindingsInterval {
    return json as MonitCountFindingsInterval;
}

export function MonitCountFindingsIntervalToJSON(value?: MonitCountFindingsInterval | null): any {
    return value as any;
}

