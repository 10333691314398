/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupplementalLocale = {
    EnUs: 'en-US',
    EnCa: 'en-CA',
    EnGb: 'en-GB',
    EnAu: 'en-AU',
    FrCa: 'fr-CA',
    DeDe: 'de-DE'
} as const;
export type SupplementalLocale = typeof SupplementalLocale[keyof typeof SupplementalLocale];


export function SupplementalLocaleFromJSON(json: any): SupplementalLocale {
    return SupplementalLocaleFromJSONTyped(json, false);
}

export function SupplementalLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalLocale {
    return json as SupplementalLocale;
}

export function SupplementalLocaleToJSON(value?: SupplementalLocale | null): any {
    return value as any;
}

