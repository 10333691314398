/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityReportData
 */
export interface MonitEntityReportData {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportData
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportData
     */
    process_id?: string;
}

/**
 * Check if a given object implements the MonitEntityReportData interface.
 */
export function instanceOfMonitEntityReportData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityReportDataFromJSON(json: any): MonitEntityReportData {
    return MonitEntityReportDataFromJSONTyped(json, false);
}

export function MonitEntityReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
    };
}

export function MonitEntityReportDataToJSON(value?: MonitEntityReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'process_id': value.process_id,
    };
}

