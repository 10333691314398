/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskWaveOverviewEntry
 */
export interface ExecRiskWaveOverviewEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWaveOverviewEntry
     */
    entity_name: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWaveOverviewEntry
     */
    entity_domain: string;
    /**
     * 
     * @type {object}
     * @memberof ExecRiskWaveOverviewEntry
     */
    results?: object;
}

/**
 * Check if a given object implements the ExecRiskWaveOverviewEntry interface.
 */
export function instanceOfExecRiskWaveOverviewEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_name" in value;
    isInstance = isInstance && "entity_domain" in value;

    return isInstance;
}

export function ExecRiskWaveOverviewEntryFromJSON(json: any): ExecRiskWaveOverviewEntry {
    return ExecRiskWaveOverviewEntryFromJSONTyped(json, false);
}

export function ExecRiskWaveOverviewEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskWaveOverviewEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_name': json['entity_name'],
        'entity_domain': json['entity_domain'],
        'results': !exists(json, 'results') ? undefined : json['results'],
    };
}

export function ExecRiskWaveOverviewEntryToJSON(value?: ExecRiskWaveOverviewEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'results': value.results,
    };
}

