/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityHoneypotsData } from './ControlEntityHoneypotsData';
import {
    ControlEntityHoneypotsDataFromJSON,
    ControlEntityHoneypotsDataFromJSONTyped,
    ControlEntityHoneypotsDataToJSON,
} from './ControlEntityHoneypotsData';

/**
 * 
 * @export
 * @interface ControlEntityHoneypotsAggResponse
 */
export interface ControlEntityHoneypotsAggResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityHoneypotsAggResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ControlEntityHoneypotsData>}
     * @memberof ControlEntityHoneypotsAggResponse
     */
    malicious?: Array<ControlEntityHoneypotsData>;
    /**
     * 
     * @type {Array<ControlEntityHoneypotsData>}
     * @memberof ControlEntityHoneypotsAggResponse
     */
    other?: Array<ControlEntityHoneypotsData>;
}

/**
 * Check if a given object implements the ControlEntityHoneypotsAggResponse interface.
 */
export function instanceOfControlEntityHoneypotsAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityHoneypotsAggResponseFromJSON(json: any): ControlEntityHoneypotsAggResponse {
    return ControlEntityHoneypotsAggResponseFromJSONTyped(json, false);
}

export function ControlEntityHoneypotsAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityHoneypotsAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'malicious': !exists(json, 'malicious') ? undefined : ((json['malicious'] as Array<any>).map(ControlEntityHoneypotsDataFromJSON)),
        'other': !exists(json, 'other') ? undefined : ((json['other'] as Array<any>).map(ControlEntityHoneypotsDataFromJSON)),
    };
}

export function ControlEntityHoneypotsAggResponseToJSON(value?: ControlEntityHoneypotsAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'malicious': value.malicious === undefined ? undefined : ((value.malicious as Array<any>).map(ControlEntityHoneypotsDataToJSON)),
        'other': value.other === undefined ? undefined : ((value.other as Array<any>).map(ControlEntityHoneypotsDataToJSON)),
    };
}

