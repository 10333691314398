import React from 'react';
import clsx from 'clsx';
import MuiContainer, {
  ContainerProps as MuiContainerProps,
} from '@mui/material/Container';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Container = withCoreProps<ContainerProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<ContainerProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiContainer {...props} className={clsxClass}>
        {children}
      </MuiContainer>
    );
  },
  ['maxWidth'],
);

export type ContainerProps = Partial<CoreProps> & MuiContainerProps;
