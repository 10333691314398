/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFTechnologyItem
 */
export interface CRSFTechnologyItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTechnologyItem
     */
    Categories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    Description?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFTechnologyItem
     */
    FirstDetected: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    IsPremium: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFTechnologyItem
     */
    LastDetected: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    Link?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    Parent?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTechnologyItem
     */
    Tag: string;
}

/**
 * Check if a given object implements the CRSFTechnologyItem interface.
 */
export function instanceOfCRSFTechnologyItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "FirstDetected" in value;
    isInstance = isInstance && "IsPremium" in value;
    isInstance = isInstance && "LastDetected" in value;
    isInstance = isInstance && "Name" in value;
    isInstance = isInstance && "Tag" in value;

    return isInstance;
}

export function CRSFTechnologyItemFromJSON(json: any): CRSFTechnologyItem {
    return CRSFTechnologyItemFromJSONTyped(json, false);
}

export function CRSFTechnologyItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTechnologyItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Categories': !exists(json, 'Categories') ? undefined : json['Categories'],
        'Description': !exists(json, 'Description') ? undefined : json['Description'],
        'FirstDetected': json['FirstDetected'],
        'IsPremium': json['IsPremium'],
        'LastDetected': json['LastDetected'],
        'Link': !exists(json, 'Link') ? undefined : json['Link'],
        'Name': json['Name'],
        'Parent': !exists(json, 'Parent') ? undefined : json['Parent'],
        'Tag': json['Tag'],
    };
}

export function CRSFTechnologyItemToJSON(value?: CRSFTechnologyItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Categories': value.Categories,
        'Description': value.Description,
        'FirstDetected': value.FirstDetected,
        'IsPremium': value.IsPremium,
        'LastDetected': value.LastDetected,
        'Link': value.Link,
        'Name': value.Name,
        'Parent': value.Parent,
        'Tag': value.Tag,
    };
}

