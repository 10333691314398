import React, { useMemo, useCallback, ReactNode } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  Checkbox,
  FormLabel,
  FormGroup,
  FormGroupProps,
  FormControl,
  FormControlLabel,
  Tooltip,
} from '@explorer/core';
import { Stack } from '@mui/material';

/**
 * FieldCheckbox
 */
export const FieldCheckbox = ({
  id,
  name,
  label,
  value,
  options,
  color = 'primary',
  setFieldValue,
  setFieldError,
  onChange,
  error,
  className,
  disabled,
  ...props
}: FieldCheckboxProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const handleChange = useCallback(
    (option: CheckboxOption) => {
      if (setFieldValue)
        setFieldValue(name, {
          ...value,
          [option.name]: !value[option.name],
        });
    },
    [name, value, setFieldValue],
  );

  const checkboxOptions = useMemo(
    () =>
      options.map((option) => {
        const optionLabel = formatMessage(intl, option?.label);

        return (
          <Stack flexDirection={'row'} key={option.name} alignItems={'center'}>
            <FormControlLabel
              noUserSelect
              label={optionLabel}
              sx={{ mr: option.tooltip ? '6px' : '' }}
              control={
                <Checkbox
                  id={`${id}-${option.name}`}
                  name={option.name}
                  color={color}
                  checked={value[option.name]}
                  disabled={disabled}
                  onChange={() => handleChange(option)}
                />
              }
            />

            {option.tooltip && (
              <Tooltip title={option.tooltip} placement="top">
                <InfoOutlined sx={{ fontSize: '16px', mt: '6px' }} />
              </Tooltip>
            )}
          </Stack>
        );
      }),
    [options, name, value, setFieldValue],
  );

  return (
    <FormControl>
      <FormLabel>{intlLabel}</FormLabel>
      <FormGroup id={id} {...props} className={clsxClass}>
        {checkboxOptions}
      </FormGroup>
    </FormControl>
  );
};

/**
 * FieldCheckbox Props
 */
export type FieldCheckboxProps = FormGroupProps & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `value` - object that contains the selected values
   */
  value: Record<string, boolean>;
  /**
   * `options` - field options
   */
  options: CheckboxOptions;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `disabled` - field is disabled
   */
  disabled?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `color` - color of the Checkbox Input
   */
  color?: 'primary' | 'secondary' | 'default' | undefined;
};

export interface CheckboxOption {
  label: IntlLabel;
  name: string;
  tooltip?: IntlLabel;
}

export type CheckboxOptions = CheckboxOption[];
