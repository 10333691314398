/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAddressSchemaORM
 */
export interface ControlAddressSchemaORM {
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    formatted?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddressSchemaORM
     */
    region?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlAddressSchemaORM
     */
    id: number;
}

/**
 * Check if a given object implements the ControlAddressSchemaORM interface.
 */
export function instanceOfControlAddressSchemaORM(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ControlAddressSchemaORMFromJSON(json: any): ControlAddressSchemaORM {
    return ControlAddressSchemaORMFromJSONTyped(json, false);
}

export function ControlAddressSchemaORMFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAddressSchemaORM {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'formatted': !exists(json, 'formatted') ? undefined : json['formatted'],
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'postal_code': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'id': json['id'],
    };
}

export function ControlAddressSchemaORMToJSON(value?: ControlAddressSchemaORM | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'formatted': value.formatted,
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'postal_code': value.postal_code,
        'region': value.region,
        'id': value.id,
    };
}

