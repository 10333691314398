import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * FundTransferFraudIcon
 */
export const FundTransferFraudIcon = ({
  ...props
}: FundTransferFraudIconProps) => {
  return (
    <Icon label="funds_transfer" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6,13.9c-0.3-1.1-0.8-2.2-1.4-3.1c-0.7-0.9-1.5-1.7-2.4-2.3c-0.9-0.6-1.9-1.1-3.1-1.4c-0.9-0.3-1.7-0.4-2.6-0.5V5.8c0-0.2-0.1-0.4-0.2-0.6C16.7,5.1,16.5,5,16.3,5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.6v0.8c-1.3,0.1-2.5,0.3-3.7,0.8c-1.4,0.6-2.6,1.4-3.7,2.5c-1.1,1.1-1.8,2.4-2.1,4c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.4-0.4,0.8-0.7,1.2-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.6,0,1.1,0.2,1.6,0.5c0.5,0.3,0.9,0.7,1.3,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.1,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.3c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1-0.5,1.6-0.5c0.6,0,1.1,0.2,1.6,0.5c0.5,0.3,0.9,0.7,1.3,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.3-0.2c0,0,0.1-0.1,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.3c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1-0.5,1.6-0.5c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,1.2,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1C26.6,14.2,26.6,14.1,26.6,13.9C26.6,13.9,26.6,13.9,26.6,13.9z M16.2,15.8h1.3c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6H17v-0.2c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v0.2c-0.6,0.1-1.1,0.6-1.1,1.2c0,0.5,0.2,1,0.7,1.2l1.4,0.5c0,0,0,0.1,0,0.1c0,0,0,0.1-0.2,0.1h-1.3c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h0.5v0.2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6V19c0.6-0.1,1.1-0.6,1.1-1.2c0-0.5-0.2-1-0.7-1.2L16,16c0,0,0-0.1,0-0.1C15.9,15.8,15.9,15.8,16.2,15.8z M15.7,21.2c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7V22c0,1.7-1.4,3-3,3c-1.7,0-3-1.4-3-3V22c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7V22c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7V21.2z"
        />
      </g>
    </Icon>
  );
};

/**
 * FundTransferFraudIcon Props
 */
export interface FundTransferFraudIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
