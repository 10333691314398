/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityNoDomainResponse
 */
export interface ControlEntityNoDomainResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    page_title?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNoDomainResponse
     */
    url?: string;
}

/**
 * Check if a given object implements the ControlEntityNoDomainResponse interface.
 */
export function instanceOfControlEntityNoDomainResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityNoDomainResponseFromJSON(json: any): ControlEntityNoDomainResponse {
    return ControlEntityNoDomainResponseFromJSONTyped(json, false);
}

export function ControlEntityNoDomainResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityNoDomainResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'page_title': !exists(json, 'page_title') ? undefined : json['page_title'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ControlEntityNoDomainResponseToJSON(value?: ControlEntityNoDomainResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_name': value.entity_name,
        'location': value.location,
        'industry': value.industry,
        'entity_domain': value.entity_domain,
        'page_title': value.page_title,
        'url': value.url,
    };
}

