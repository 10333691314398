import { createAppRoutes } from '@explorer/helpers';

const routes = [
  '/',
  '/404',
  '/error',
  '/admin-control/companies',
  '/admin-control/invite',
  '/admin-control/new-control-user',
  '/admin-control/modify-client',
  '/admin-control/associate-email-to-client',
  '/admin-control/users',
  '/admin-explorer/client',
  '/admin-explorer/companies',
  '/admin-explorer/email',
  '/admin-explorer/token',
  '/admin-explorer/user',
  '/admin-control/manage-access-requests',
  '/asm',
  '/asm/assets',
  '/asm/assets/dns',
  '/asm/assets/screenshots',
  '/asm/assets/vulnerabilities',
  '/asm/assets/technologies',
  '/asm/companies',
  '/asm/companies/assets',
  '/asm/companies/claims',
  '/asm/companies/dashboard',
  '/asm/companies/data-breaches',
  '/asm/companies/honeypots',
  '/asm/companies/keywords',
  '/asm/companies/lookalikes',
  '/asm/companies/notifications',
  '/asm/companies/technologies',
  '/asm/companies/torrents',
  '/asm/companies/vulnerabilities',
  '/asm/companies/event-log',
  '/asm/notifications',
  '/asm/technologies',
  '/asm/vulnerabilities',
  '/claims',
  '/claims/accounts',
  '/claims/calculator',
  '/claims/clr',
  '/claims/cls',
  '/claims/contacts',
  '/claims/coverages',
  '/claims/documents',
  '/claims/losses',
  '/claims/vendors',
  '/exec-risks',
  '/exec-risks/employee-sentiment',
  '/exec-risks/financial-stability',
  '/exec-risks/investors',
  '/exec-risks/litigation',
  '/exec-risks/news',
  '/exec-risks/regulatory-issues',
  '/risk-assessment',
  '/risk-assessment/assets',
  '/risk-assessment/data-breaches',
  '/risk-assessment/findings',
  '/risk-assessment/honeypots',
  '/risk-assessment/technologies',
  '/risk-assessment/torrents',
  '/sec-review',
  '/sec-review/assets',
  '/sec-review/data-breaches',
  '/sec-review/documents/csv',
  '/sec-review/documents/html',
  '/sec-review/documents/txt',
  '/sec-review/findings',
  '/sec-review/honeypots',
  '/sec-review/asset-removals',
  '/sec-review/vulnerability-resolutions',
  '/sec-review/vulnerability-resolutions/resolution-request',
  '/sec-review/vulnerability-resolutions/resolution-request/assets',
  '/sec-review/vulnerability-resolutions/resolution-request/details',
  '/sec-review/technologies',
  '/sec-review/torrents',
  '/supplementals',
  '/supplementals/account',
  '/supplementals/recipient',
  '/supplementals/recipient/logs',
  '/users',
  '/users/notifications',
  '/users/filters',
  '/zero-day-alert',
  '/zero-day-alert/details',
  '/zero-day-alert/companies',
  '/zero-day-alert/history',
] as const;

export type Routes = (typeof routes)[number];

export const appRoutes = createAppRoutes<AppRoutes<Routes>>(routes);
