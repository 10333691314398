/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskItemI
 */
export interface ExecRiskItemI {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    ack_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemI
     */
    row_order?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    provider_eligible_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_prov_st?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemI
     */
    prov_eligible_foreign_post_cd?: string;
}

/**
 * Check if a given object implements the ExecRiskItemI interface.
 */
export function instanceOfExecRiskItemI(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskItemIFromJSON(json: any): ExecRiskItemI {
    return ExecRiskItemIFromJSONTyped(json, false);
}

export function ExecRiskItemIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskItemI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'row_order': !exists(json, 'row_order') ? undefined : json['row_order'],
        'provider_eligible_name': !exists(json, 'provider_eligible_name') ? undefined : json['provider_eligible_name'],
        'provider_eligible_ein': !exists(json, 'provider_eligible_ein') ? undefined : json['provider_eligible_ein'],
        'provider_eligible_us_address1': !exists(json, 'provider_eligible_us_address1') ? undefined : json['provider_eligible_us_address1'],
        'provider_eligible_us_address2': !exists(json, 'provider_eligible_us_address2') ? undefined : json['provider_eligible_us_address2'],
        'provider_eligible_us_city': !exists(json, 'provider_eligible_us_city') ? undefined : json['provider_eligible_us_city'],
        'provider_eligible_us_state': !exists(json, 'provider_eligible_us_state') ? undefined : json['provider_eligible_us_state'],
        'provider_eligible_us_zip': !exists(json, 'provider_eligible_us_zip') ? undefined : json['provider_eligible_us_zip'],
        'prov_eligible_foreign_address1': !exists(json, 'prov_eligible_foreign_address1') ? undefined : json['prov_eligible_foreign_address1'],
        'prov_eligible_foreign_address2': !exists(json, 'prov_eligible_foreign_address2') ? undefined : json['prov_eligible_foreign_address2'],
        'prov_eligible_foreign_city': !exists(json, 'prov_eligible_foreign_city') ? undefined : json['prov_eligible_foreign_city'],
        'prov_eligible_foreign_prov_st': !exists(json, 'prov_eligible_foreign_prov_st') ? undefined : json['prov_eligible_foreign_prov_st'],
        'prov_eligible_foreign_cntry': !exists(json, 'prov_eligible_foreign_cntry') ? undefined : json['prov_eligible_foreign_cntry'],
        'prov_eligible_foreign_post_cd': !exists(json, 'prov_eligible_foreign_post_cd') ? undefined : json['prov_eligible_foreign_post_cd'],
    };
}

export function ExecRiskItemIToJSON(value?: ExecRiskItemI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'row_order': value.row_order,
        'provider_eligible_name': value.provider_eligible_name,
        'provider_eligible_ein': value.provider_eligible_ein,
        'provider_eligible_us_address1': value.provider_eligible_us_address1,
        'provider_eligible_us_address2': value.provider_eligible_us_address2,
        'provider_eligible_us_city': value.provider_eligible_us_city,
        'provider_eligible_us_state': value.provider_eligible_us_state,
        'provider_eligible_us_zip': value.provider_eligible_us_zip,
        'prov_eligible_foreign_address1': value.prov_eligible_foreign_address1,
        'prov_eligible_foreign_address2': value.prov_eligible_foreign_address2,
        'prov_eligible_foreign_city': value.prov_eligible_foreign_city,
        'prov_eligible_foreign_prov_st': value.prov_eligible_foreign_prov_st,
        'prov_eligible_foreign_cntry': value.prov_eligible_foreign_cntry,
        'prov_eligible_foreign_post_cd': value.prov_eligible_foreign_post_cd,
    };
}

