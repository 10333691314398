/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewSREntityResultDataResponseHeadersHeaders
 */
export interface SecReviewSREntityResultDataResponseHeadersHeaders {
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    cache_control?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    cf_ray?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    content_length?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    content_type?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    expires?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    referrer_policy?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    server?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    vary?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseHeadersHeaders
     */
    x_frame_options?: string;
}

/**
 * Check if a given object implements the SecReviewSREntityResultDataResponseHeadersHeaders interface.
 */
export function instanceOfSecReviewSREntityResultDataResponseHeadersHeaders(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSREntityResultDataResponseHeadersHeadersFromJSON(json: any): SecReviewSREntityResultDataResponseHeadersHeaders {
    return SecReviewSREntityResultDataResponseHeadersHeadersFromJSONTyped(json, false);
}

export function SecReviewSREntityResultDataResponseHeadersHeadersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSREntityResultDataResponseHeadersHeaders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cache_control': !exists(json, 'cache-control') ? undefined : json['cache-control'],
        'cf_ray': !exists(json, 'cf-ray') ? undefined : json['cf-ray'],
        'content_length': !exists(json, 'content-length') ? undefined : json['content-length'],
        'content_type': !exists(json, 'content-type') ? undefined : json['content-type'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'expires': !exists(json, 'expires') ? undefined : json['expires'],
        'referrer_policy': !exists(json, 'referrer-policy') ? undefined : json['referrer-policy'],
        'server': !exists(json, 'server') ? undefined : json['server'],
        'vary': !exists(json, 'vary') ? undefined : json['vary'],
        'x_frame_options': !exists(json, 'x-frame-options') ? undefined : json['x-frame-options'],
    };
}

export function SecReviewSREntityResultDataResponseHeadersHeadersToJSON(value?: SecReviewSREntityResultDataResponseHeadersHeaders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cache-control': value.cache_control,
        'cf-ray': value.cf_ray,
        'content-length': value.content_length,
        'content-type': value.content_type,
        'date': value.date,
        'expires': value.expires,
        'referrer-policy': value.referrer_policy,
        'server': value.server,
        'vary': value.vary,
        'x-frame-options': value.x_frame_options,
    };
}

