/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskWebhoseDetailsEntry
 */
export interface ExecRiskWebhoseDetailsEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    snippet: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    overall_sentiment: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    positive: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    neutral: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    negative: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    date?: string;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskWebhoseDetailsEntry
     */
    triggers: Triggers;
}

/**
 * Check if a given object implements the ExecRiskWebhoseDetailsEntry interface.
 */
export function instanceOfExecRiskWebhoseDetailsEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "snippet" in value;
    isInstance = isInstance && "overall_sentiment" in value;
    isInstance = isInstance && "positive" in value;
    isInstance = isInstance && "neutral" in value;
    isInstance = isInstance && "negative" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "triggers" in value;

    return isInstance;
}

export function ExecRiskWebhoseDetailsEntryFromJSON(json: any): ExecRiskWebhoseDetailsEntry {
    return ExecRiskWebhoseDetailsEntryFromJSONTyped(json, false);
}

export function ExecRiskWebhoseDetailsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskWebhoseDetailsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'snippet': json['snippet'],
        'overall_sentiment': json['overall_sentiment'],
        'positive': json['positive'],
        'neutral': json['neutral'],
        'negative': json['negative'],
        'url': json['url'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'triggers': TriggersFromJSON(json['triggers']),
    };
}

export function ExecRiskWebhoseDetailsEntryToJSON(value?: ExecRiskWebhoseDetailsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'snippet': value.snippet,
        'overall_sentiment': value.overall_sentiment,
        'positive': value.positive,
        'neutral': value.neutral,
        'negative': value.negative,
        'url': value.url,
        'date': value.date,
        'triggers': TriggersToJSON(value.triggers),
    };
}

