/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor
 */
export interface ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor
     */
    person_name?: string;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor
     */
    business_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor {
    return ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
    };
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorToJSON(value?: ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'business_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
    };
}

