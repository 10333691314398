import { useState, useRef, useEffect, useCallback, memo, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import Download from '@mui/icons-material/Download';
import { useIntl } from 'react-intl';
import { useQuerySnackbar } from '@explorer/hooks';
import { Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';
import { PaginatedResponse } from '@explorer/query';

type ExportProps<TData = PaginatedResponse<unknown>, CSVExport = TData> = {
  refetch: () => Promise<TData>;
  transformFnc: (data: TData) => CSVExport;
  filename?: string;
  disabled?: boolean;
};

export const ExportCSV = <
  TData extends PaginatedResponse<unknown> = PaginatedResponse<any>,
  CSVExport = TData,
>({
  refetch,
  transformFnc,
  filename,
  disabled = false,
}: ExportProps<TData, CSVExport>) => {
  const intl = useIntl();
  const { snackInfo, closeSnackbar, snackSuccess, snackError } =
    useQuerySnackbar();
  const [bulkData, setBulkData] = useState<any>([]);
  const { addError, addAction } = useUnsafeDataDogRum();
  const csvLinkRef = useRef<any | null>(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (bulkData.length) {
      csvLinkRef?.current?.link?.click();
      setBulkData([]);
    }
  }, [bulkData.length]);

  const handleExport = useCallback(() => {
    setIsExporting(true);
    const key = snackInfo(intl.formatMessage({ id: 'global.exporting-csv' }));

    refetch()
      .then((res: TData) => {
        closeSnackbar(key);
        const transformedData = transformFnc(res);
        setBulkData(transformedData);
        snackSuccess(
          intl.formatMessage({
            id: 'global.successful-export-csv',
          }),
        );
        addAction('Export Table Results', {
          filename,
          csvSize: Array.isArray(transformedData) ? transformedData?.length : 1,
          totalCount: (res?.count || res?.total) ?? 0,
        });
      })
      .catch((err) => {
        closeSnackbar(key);
        addError(err?.cause);
        snackError(
          intl.formatMessage({
            id: 'global.fail-export-csv',
          }),
          err?.error,
        );
      })
      .finally(() => setIsExporting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return bulkData.length > 0 ? (
    <CSVLink
      data={bulkData}
      ref={csvLinkRef}
      filename={`${filename}${new Date().toLocaleDateString()}.csv`}
      style={{ color: 'inherit', textDecoration: 'none' }}
      data-testid="csv-link"
    />
  ) : (
    <Tooltip title={intl.formatMessage({ id: 'global.export-tooltip' })}>
      <span
        style={{
          marginLeft: 'auto',
          paddingTop: '10px',
        }}
      >
        <LoadingButton
          variant="outlined"
          onClick={handleExport}
          loading={isExporting}
          color="primary"
          loadingPosition="start"
          startIcon={<Download />}
          disabled={disabled}
          sx={{
            padding: '7px 15px 8px',
            gap: '4px',
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            color={disabled ? 'disabled' : 'primary'}
          >
            {intl.formatMessage({ id: 'global.export' })}
          </Typography>
        </LoadingButton>
      </span>
    </Tooltip>
  );
};
