/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskWebhoseDetailsEntry } from './ExecRiskWebhoseDetailsEntry';
import {
    ExecRiskWebhoseDetailsEntryFromJSON,
    ExecRiskWebhoseDetailsEntryFromJSONTyped,
    ExecRiskWebhoseDetailsEntryToJSON,
} from './ExecRiskWebhoseDetailsEntry';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskWebhoseOverviewEntry
 */
export interface ExecRiskWebhoseOverviewEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWebhoseOverviewEntry
     */
    entity: string;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskWebhoseOverviewEntry
     */
    triggers: Triggers;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskWebhoseOverviewEntry
     */
    triggers_count: number;
    /**
     * 
     * @type {Array<ExecRiskWebhoseDetailsEntry>}
     * @memberof ExecRiskWebhoseOverviewEntry
     */
    articles: Array<ExecRiskWebhoseDetailsEntry>;
}

/**
 * Check if a given object implements the ExecRiskWebhoseOverviewEntry interface.
 */
export function instanceOfExecRiskWebhoseOverviewEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "triggers" in value;
    isInstance = isInstance && "triggers_count" in value;
    isInstance = isInstance && "articles" in value;

    return isInstance;
}

export function ExecRiskWebhoseOverviewEntryFromJSON(json: any): ExecRiskWebhoseOverviewEntry {
    return ExecRiskWebhoseOverviewEntryFromJSONTyped(json, false);
}

export function ExecRiskWebhoseOverviewEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskWebhoseOverviewEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'triggers': TriggersFromJSON(json['triggers']),
        'triggers_count': json['triggers_count'],
        'articles': ((json['articles'] as Array<any>).map(ExecRiskWebhoseDetailsEntryFromJSON)),
    };
}

export function ExecRiskWebhoseOverviewEntryToJSON(value?: ExecRiskWebhoseOverviewEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'triggers': TriggersToJSON(value.triggers),
        'triggers_count': value.triggers_count,
        'articles': ((value.articles as Array<any>).map(ExecRiskWebhoseDetailsEntryToJSON)),
    };
}

