import { createApiEndpoints, FuncEndpoint } from '../utils';

const PREFIX_BREACH = '';

const ENDPOINTS_BREACH = [`/static`] as const;

type Endpoints = typeof ENDPOINTS_BREACH[number];

export type EndpointsBREACH = Record<Endpoints, FuncEndpoint>;

export const endpointsBREACH = createApiEndpoints<Endpoints>(
  PREFIX_BREACH,
  ENDPOINTS_BREACH,
) as EndpointsBREACH;
