/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFileType } from './SupplementalFileType';
import {
    SupplementalFileTypeFromJSON,
    SupplementalFileTypeFromJSONTyped,
    SupplementalFileTypeToJSON,
} from './SupplementalFileType';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalAttachment
 */
export interface SupplementalAttachment {
    /**
     * 
     * @type {string}
     * @memberof SupplementalAttachment
     */
    attachment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalAttachment
     */
    form_instance_id?: string;
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalAttachment
     */
    locale?: SupplementalLocale;
    /**
     * 
     * @type {SupplementalFileType}
     * @memberof SupplementalAttachment
     */
    file_type?: SupplementalFileType;
    /**
     * 
     * @type {string}
     * @memberof SupplementalAttachment
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalAttachment
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplementalAttachment
     */
    file_size?: number;
}

/**
 * Check if a given object implements the SupplementalAttachment interface.
 */
export function instanceOfSupplementalAttachment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalAttachmentFromJSON(json: any): SupplementalAttachment {
    return SupplementalAttachmentFromJSONTyped(json, false);
}

export function SupplementalAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachment_id': !exists(json, 'attachment_id') ? undefined : json['attachment_id'],
        'form_instance_id': !exists(json, 'form_instance_id') ? undefined : json['form_instance_id'],
        'locale': !exists(json, 'locale') ? undefined : SupplementalLocaleFromJSON(json['locale']),
        'file_type': !exists(json, 'file_type') ? undefined : SupplementalFileTypeFromJSON(json['file_type']),
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'file_size': !exists(json, 'file_size') ? undefined : json['file_size'],
    };
}

export function SupplementalAttachmentToJSON(value?: SupplementalAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachment_id': value.attachment_id,
        'form_instance_id': value.form_instance_id,
        'locale': SupplementalLocaleToJSON(value.locale),
        'file_type': SupplementalFileTypeToJSON(value.file_type),
        'updated_at': value.updated_at,
        'created_at': value.created_at,
        'file_size': value.file_size,
    };
}

