/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitAccountResolutions
 */
export interface MonitAccountResolutions {
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    account_line_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    port: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    reviewed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAccountResolutions
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitAccountResolutions interface.
 */
export function instanceOfMonitAccountResolutions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "account_line_uuid" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "datapoint" in value;

    return isInstance;
}

export function MonitAccountResolutionsFromJSON(json: any): MonitAccountResolutions {
    return MonitAccountResolutionsFromJSONTyped(json, false);
}

export function MonitAccountResolutionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitAccountResolutions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_line_uuid': json['account_line_uuid'],
        'domain': json['domain'],
        'asset': json['asset'],
        'port': json['port'],
        'datapoint': json['datapoint'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitAccountResolutionsToJSON(value?: MonitAccountResolutions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_line_uuid': value.account_line_uuid,
        'domain': value.domain,
        'asset': value.asset,
        'port': value.port,
        'datapoint': value.datapoint,
        'reason': value.reason,
        'reviewed_by': value.reviewed_by,
        'created': value.created,
        'updated': value.updated,
    };
}

