/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasAdminAuth0Roles } from './ControlAppSchemasAdminAuth0Roles';
import {
    ControlAppSchemasAdminAuth0RolesFromJSON,
    ControlAppSchemasAdminAuth0RolesFromJSONTyped,
    ControlAppSchemasAdminAuth0RolesToJSON,
} from './ControlAppSchemasAdminAuth0Roles';

/**
 * 
 * @export
 * @interface ControlClientInfoUser
 */
export interface ControlClientInfoUser {
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    coalition_user_uuid?: string;
    /**
     * 
     * @type {Array<ControlAppSchemasAdminAuth0Roles>}
     * @memberof ControlClientInfoUser
     */
    auth0_roles: Array<ControlAppSchemasAdminAuth0Roles>;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    last_login?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlClientInfoUser
     */
    is_active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlClientInfoUser
     */
    auth0_id_exists: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoUser
     */
    updated_at?: string;
}

/**
 * Check if a given object implements the ControlClientInfoUser interface.
 */
export function instanceOfControlClientInfoUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "auth0_roles" in value;
    isInstance = isInstance && "is_active" in value;
    isInstance = isInstance && "auth0_id_exists" in value;

    return isInstance;
}

export function ControlClientInfoUserFromJSON(json: any): ControlClientInfoUser {
    return ControlClientInfoUserFromJSONTyped(json, false);
}

export function ControlClientInfoUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlClientInfoUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'user_id': json['user_id'],
        'coalition_user_uuid': !exists(json, 'coalition_user_uuid') ? undefined : json['coalition_user_uuid'],
        'auth0_roles': ((json['auth0_roles'] as Array<any>).map(ControlAppSchemasAdminAuth0RolesFromJSON)),
        'last_login': !exists(json, 'last_login') ? undefined : json['last_login'],
        'is_active': json['is_active'],
        'auth0_id_exists': json['auth0_id_exists'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function ControlClientInfoUserToJSON(value?: ControlClientInfoUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'user_id': value.user_id,
        'coalition_user_uuid': value.coalition_user_uuid,
        'auth0_roles': ((value.auth0_roles as Array<any>).map(ControlAppSchemasAdminAuth0RolesToJSON)),
        'last_login': value.last_login,
        'is_active': value.is_active,
        'auth0_id_exists': value.auth0_id_exists,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}

