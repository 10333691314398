/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskCoordinates } from './ExecRiskCoordinates';
import {
    ExecRiskCoordinatesFromJSON,
    ExecRiskCoordinatesFromJSONTyped,
    ExecRiskCoordinatesToJSON,
} from './ExecRiskCoordinates';
import type { ExecRiskPropertyCrimeDetail } from './ExecRiskPropertyCrimeDetail';
import {
    ExecRiskPropertyCrimeDetailFromJSON,
    ExecRiskPropertyCrimeDetailFromJSONTyped,
    ExecRiskPropertyCrimeDetailToJSON,
} from './ExecRiskPropertyCrimeDetail';
import type { ExecRiskViolentCrimeDetail } from './ExecRiskViolentCrimeDetail';
import {
    ExecRiskViolentCrimeDetailFromJSON,
    ExecRiskViolentCrimeDetailFromJSONTyped,
    ExecRiskViolentCrimeDetailToJSON,
} from './ExecRiskViolentCrimeDetail';

/**
 * 
 * @export
 * @interface ExecRiskCoreLogicCrimeEntry
 */
export interface ExecRiskCoreLogicCrimeEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    census_block_fips_code: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    motor_vehicle_theft_index?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    property_crime_index?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    risk_index?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    violent_crime_index?: number;
    /**
     * 
     * @type {ExecRiskPropertyCrimeDetail}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    property_crime_detail?: ExecRiskPropertyCrimeDetail;
    /**
     * 
     * @type {ExecRiskViolentCrimeDetail}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    violent_crime_detail?: ExecRiskViolentCrimeDetail;
    /**
     * 
     * @type {ExecRiskCoordinates}
     * @memberof ExecRiskCoreLogicCrimeEntry
     */
    location?: ExecRiskCoordinates;
}

/**
 * Check if a given object implements the ExecRiskCoreLogicCrimeEntry interface.
 */
export function instanceOfExecRiskCoreLogicCrimeEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "census_block_fips_code" in value;

    return isInstance;
}

export function ExecRiskCoreLogicCrimeEntryFromJSON(json: any): ExecRiskCoreLogicCrimeEntry {
    return ExecRiskCoreLogicCrimeEntryFromJSONTyped(json, false);
}

export function ExecRiskCoreLogicCrimeEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskCoreLogicCrimeEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'census_block_fips_code': json['census_block_fips_code'],
        'motor_vehicle_theft_index': !exists(json, 'motor_vehicle_theft_index') ? undefined : json['motor_vehicle_theft_index'],
        'property_crime_index': !exists(json, 'property_crime_index') ? undefined : json['property_crime_index'],
        'risk_index': !exists(json, 'risk_index') ? undefined : json['risk_index'],
        'violent_crime_index': !exists(json, 'violent_crime_index') ? undefined : json['violent_crime_index'],
        'property_crime_detail': !exists(json, 'property_crime_detail') ? undefined : ExecRiskPropertyCrimeDetailFromJSON(json['property_crime_detail']),
        'violent_crime_detail': !exists(json, 'violent_crime_detail') ? undefined : ExecRiskViolentCrimeDetailFromJSON(json['violent_crime_detail']),
        'location': !exists(json, 'location') ? undefined : ExecRiskCoordinatesFromJSON(json['location']),
    };
}

export function ExecRiskCoreLogicCrimeEntryToJSON(value?: ExecRiskCoreLogicCrimeEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'census_block_fips_code': value.census_block_fips_code,
        'motor_vehicle_theft_index': value.motor_vehicle_theft_index,
        'property_crime_index': value.property_crime_index,
        'risk_index': value.risk_index,
        'violent_crime_index': value.violent_crime_index,
        'property_crime_detail': ExecRiskPropertyCrimeDetailToJSON(value.property_crime_detail),
        'violent_crime_detail': ExecRiskViolentCrimeDetailToJSON(value.violent_crime_detail),
        'location': ExecRiskCoordinatesToJSON(value.location),
    };
}

