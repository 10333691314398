import React, { useCallback } from 'react';
import { useTheme } from '@mui/material';
import { Box, Typography, Tooltip, Grid } from '@explorer/core';
import { capitalize } from '@explorer/helpers';

import QueueIcon from '@mui/icons-material/Queue';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import {
  CRSFStatusType,
  CRSFSecReviewStatusType,
} from '@explorer/open-api-client';

/**
 * ScanChip
 */
export const ScanChip = ({ label, status }: ScanChipProps) => {
  const { palette } = useTheme();

  const getChipBG = useCallback(() => {
    switch (status) {
      case 'requested':
        return palette.secondary.main;
      case 'started':
        return palette.primary.main;
      case 'skipped':
        return palette.high[300];
      case 'errored':
        return palette.critical[300];
      case 'revoked':
        return palette.purple[500];
      case 'finished':
        return palette.low[300];
      default:
        return palette.neutral[300];
    }
  }, [status]);

  const getStatusText = useCallback(() => {
    switch (status) {
      case null:
        return 'Queued';
      default:
        return capitalize(status);
    }
  }, [status]);

  // MUI Icons can sometimes be a pain to center vertically
  const getStatusIcon = useCallback(() => {
    switch (status) {
      case 'requested':
        return (
          <SettingsEthernetIcon
            fontSize="inherit"
            style={{ marginTop: '4px' }}
          />
        );
      case 'started':
        return (
          <PlayArrowIcon fontSize="inherit" style={{ marginTop: '4px' }} />
        );
      case 'skipped':
        return <SkipNextIcon fontSize="inherit" style={{ marginTop: '4px' }} />;
      case 'errored':
        return <CloseIcon fontSize="inherit" style={{ marginTop: '4px' }} />;
      case 'revoked':
        return <WarningIcon fontSize="inherit" style={{ marginTop: '4px' }} />;
      case 'finished':
        return <CheckIcon fontSize="inherit" style={{ marginTop: '4px' }} />;
      default:
        return <QueueIcon fontSize="inherit" style={{ marginTop: '4px' }} />;
    }
  }, [status]);

  const getLabelText = useCallback(() => {
    switch (label) {
      case 'dataleaks-pwds':
        return 'Dataleaks: Passwords';
      case 'dnsres':
        return 'DNS Resolution';
      case 'pdfgen':
        return 'PDF Gen';
      case 'pdfgen-historical':
        return 'PDF Gen: Historical';
      case 'pdfgen-scans':
        return 'PDF Gen: Scans';
      case 'secchecks-assets':
        return 'Sec. Checks: Assets';
      case 'secchecks-scans':
        return 'Sec. Checks: Scans';
      case 'secchecks-historical':
        return 'Sec. Checks: Historical';
      case 'techstack-assets':
        return 'Technologies: Assets';
      case 'techstack-scans':
        return 'Technologies: Scans';
      case 'scans-request':
        return 'Scans: Request';
      case 'scans-status':
        return 'Scans: Status';
      case 'scans-replay':
        return 'Scans: Replay';
      case 'techstack-historical':
        return 'Technologies: Historical';
      case 'summary-historical':
        return 'Summary: Historical';
      case 'summary-scans':
        return 'Summary: Scans';
      case 'txtgen-historical':
        return 'TXT Gen: Historical';
      case 'txtgen-scans':
        return 'TXT Gen: Scans';
      case 'sec-analysis':
        return 'Security Analysis';
      default:
        return capitalize(label);
    }
  }, [label]);

  return (
    <Tooltip title={getStatusText()}>
      <Box px={1} py={0.2} bgColor={getChipBG()} borderRadius={3}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography
              variant="bodyS"
              textColor={palette.primary.contrastText}
            >
              {getLabelText()}
            </Typography>
          </Grid>
          <Grid item>
            <Box textAlign="center" textColor={palette.primary.contrastText}>
              {getStatusIcon()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
};

/**
 * ScanChip Props
 */
export type ScanStatusOpts =
  | null
  | 'requested'
  | 'started'
  | 'skipped'
  | 'errored'
  | 'revoked'
  | 'finished';

export interface ScanChipProps {
  label: string;
  status: ScanStatusOpts | CRSFSecReviewStatusType | CRSFStatusType;
}
