import React, { memo } from 'react';
import Head from 'next/head';

/**
 * PageHeadWrapper
 */
export const PageHeadWrapper = memo(
  //
  function PageHeadWrapper({
    pageMeta: { metaTitle, metaDescription, metaKeywords, metaImage },
    children,
  }: PageHeadWrapperProps) {
    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          {metaKeywords && <meta name="keywords" content={metaKeywords} />}

          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
          {metaImage && <meta name="og:image" content={metaImage} />}
        </Head>
        <>{children}</>
      </>
    );
  },
);

PageHeadWrapper.displayName = 'PageHeadWrapper';

/**
 * PageHeadWrapperProps
 */
export type PageHeadWrapperProps = React.PropsWithChildren<{
  readonly pageMeta: PageMeta;
}>;
