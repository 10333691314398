/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFAttributesItem } from './CRSFAttributesItem';
import {
    CRSFAttributesItemFromJSON,
    CRSFAttributesItemFromJSONTyped,
    CRSFAttributesItemToJSON,
} from './CRSFAttributesItem';
import type { CRSFMetaItem } from './CRSFMetaItem';
import {
    CRSFMetaItemFromJSON,
    CRSFMetaItemFromJSONTyped,
    CRSFMetaItemToJSON,
} from './CRSFMetaItem';
import type { CRSFResultItem } from './CRSFResultItem';
import {
    CRSFResultItemFromJSON,
    CRSFResultItemFromJSONTyped,
    CRSFResultItemToJSON,
} from './CRSFResultItem';

/**
 * 
 * @export
 * @interface CRSFBuiltwithRecordItem
 */
export interface CRSFBuiltwithRecordItem {
    /**
     * 
     * @type {CRSFAttributesItem}
     * @memberof CRSFBuiltwithRecordItem
     */
    Attributes: CRSFAttributesItem;
    /**
     * 
     * @type {number}
     * @memberof CRSFBuiltwithRecordItem
     */
    FirstIndexed: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFBuiltwithRecordItem
     */
    LastIndexed: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFBuiltwithRecordItem
     */
    Lookup: string;
    /**
     * 
     * @type {CRSFMetaItem}
     * @memberof CRSFBuiltwithRecordItem
     */
    Meta: CRSFMetaItem;
    /**
     * 
     * @type {CRSFResultItem}
     * @memberof CRSFBuiltwithRecordItem
     */
    Result: CRSFResultItem;
    /**
     * 
     * @type {number}
     * @memberof CRSFBuiltwithRecordItem
     */
    SalesRevenue: number;
}

/**
 * Check if a given object implements the CRSFBuiltwithRecordItem interface.
 */
export function instanceOfCRSFBuiltwithRecordItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Attributes" in value;
    isInstance = isInstance && "FirstIndexed" in value;
    isInstance = isInstance && "LastIndexed" in value;
    isInstance = isInstance && "Lookup" in value;
    isInstance = isInstance && "Meta" in value;
    isInstance = isInstance && "Result" in value;
    isInstance = isInstance && "SalesRevenue" in value;

    return isInstance;
}

export function CRSFBuiltwithRecordItemFromJSON(json: any): CRSFBuiltwithRecordItem {
    return CRSFBuiltwithRecordItemFromJSONTyped(json, false);
}

export function CRSFBuiltwithRecordItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFBuiltwithRecordItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Attributes': CRSFAttributesItemFromJSON(json['Attributes']),
        'FirstIndexed': json['FirstIndexed'],
        'LastIndexed': json['LastIndexed'],
        'Lookup': json['Lookup'],
        'Meta': CRSFMetaItemFromJSON(json['Meta']),
        'Result': CRSFResultItemFromJSON(json['Result']),
        'SalesRevenue': json['SalesRevenue'],
    };
}

export function CRSFBuiltwithRecordItemToJSON(value?: CRSFBuiltwithRecordItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Attributes': CRSFAttributesItemToJSON(value.Attributes),
        'FirstIndexed': value.FirstIndexed,
        'LastIndexed': value.LastIndexed,
        'Lookup': value.Lookup,
        'Meta': CRSFMetaItemToJSON(value.Meta),
        'Result': CRSFResultItemToJSON(value.Result),
        'SalesRevenue': value.SalesRevenue,
    };
}

