/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsTriggerStatus } from './ExecRiskAppCoreModelsTriggerStatus';
import {
    ExecRiskAppCoreModelsTriggerStatusFromJSON,
    ExecRiskAppCoreModelsTriggerStatusFromJSONTyped,
    ExecRiskAppCoreModelsTriggerStatusToJSON,
} from './ExecRiskAppCoreModelsTriggerStatus';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsTriggerScanStatus
 */
export interface ExecRiskAppCoreModelsTriggerScanStatus {
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    ppploans?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    alexa?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    glassdoor?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    watchlist?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    form990?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    docketalarm?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    experian?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    bingnews?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    wave?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    proximityone?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    layoffs?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    zoominfo?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    webhose?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    violations?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    investors?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    industry?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    everify?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    aylien?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    news?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    nativelands?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    greatdata?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    form5500?: ExecRiskAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskAppCoreModelsTriggerStatus}
     * @memberof ExecRiskAppCoreModelsTriggerScanStatus
     */
    corelogic?: ExecRiskAppCoreModelsTriggerStatus;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsTriggerScanStatus interface.
 */
export function instanceOfExecRiskAppCoreModelsTriggerScanStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsTriggerScanStatusFromJSON(json: any): ExecRiskAppCoreModelsTriggerScanStatus {
    return ExecRiskAppCoreModelsTriggerScanStatusFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsTriggerScanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsTriggerScanStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ppploans': !exists(json, 'ppploans') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['ppploans']),
        'alexa': !exists(json, 'alexa') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['alexa']),
        'glassdoor': !exists(json, 'glassdoor') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['glassdoor']),
        'watchlist': !exists(json, 'watchlist') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['watchlist']),
        'form990': !exists(json, 'form990') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['form990']),
        'docketalarm': !exists(json, 'docketalarm') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['docketalarm']),
        'experian': !exists(json, 'experian') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['experian']),
        'bingnews': !exists(json, 'bingnews') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['bingnews']),
        'wave': !exists(json, 'wave') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['wave']),
        'proximityone': !exists(json, 'proximityone') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['proximityone']),
        'layoffs': !exists(json, 'layoffs') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['layoffs']),
        'zoominfo': !exists(json, 'zoominfo') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['zoominfo']),
        'webhose': !exists(json, 'webhose') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['webhose']),
        'violations': !exists(json, 'violations') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['violations']),
        'investors': !exists(json, 'investors') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['investors']),
        'industry': !exists(json, 'industry') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['industry']),
        'everify': !exists(json, 'everify') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['everify']),
        'aylien': !exists(json, 'aylien') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['aylien']),
        'news': !exists(json, 'news') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['news']),
        'nativelands': !exists(json, 'nativelands') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['nativelands']),
        'greatdata': !exists(json, 'greatdata') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['greatdata']),
        'form5500': !exists(json, 'form5500') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['form5500']),
        'corelogic': !exists(json, 'corelogic') ? undefined : ExecRiskAppCoreModelsTriggerStatusFromJSON(json['corelogic']),
    };
}

export function ExecRiskAppCoreModelsTriggerScanStatusToJSON(value?: ExecRiskAppCoreModelsTriggerScanStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ppploans': ExecRiskAppCoreModelsTriggerStatusToJSON(value.ppploans),
        'alexa': ExecRiskAppCoreModelsTriggerStatusToJSON(value.alexa),
        'glassdoor': ExecRiskAppCoreModelsTriggerStatusToJSON(value.glassdoor),
        'watchlist': ExecRiskAppCoreModelsTriggerStatusToJSON(value.watchlist),
        'form990': ExecRiskAppCoreModelsTriggerStatusToJSON(value.form990),
        'docketalarm': ExecRiskAppCoreModelsTriggerStatusToJSON(value.docketalarm),
        'experian': ExecRiskAppCoreModelsTriggerStatusToJSON(value.experian),
        'bingnews': ExecRiskAppCoreModelsTriggerStatusToJSON(value.bingnews),
        'wave': ExecRiskAppCoreModelsTriggerStatusToJSON(value.wave),
        'proximityone': ExecRiskAppCoreModelsTriggerStatusToJSON(value.proximityone),
        'layoffs': ExecRiskAppCoreModelsTriggerStatusToJSON(value.layoffs),
        'zoominfo': ExecRiskAppCoreModelsTriggerStatusToJSON(value.zoominfo),
        'webhose': ExecRiskAppCoreModelsTriggerStatusToJSON(value.webhose),
        'violations': ExecRiskAppCoreModelsTriggerStatusToJSON(value.violations),
        'investors': ExecRiskAppCoreModelsTriggerStatusToJSON(value.investors),
        'industry': ExecRiskAppCoreModelsTriggerStatusToJSON(value.industry),
        'everify': ExecRiskAppCoreModelsTriggerStatusToJSON(value.everify),
        'aylien': ExecRiskAppCoreModelsTriggerStatusToJSON(value.aylien),
        'news': ExecRiskAppCoreModelsTriggerStatusToJSON(value.news),
        'nativelands': ExecRiskAppCoreModelsTriggerStatusToJSON(value.nativelands),
        'greatdata': ExecRiskAppCoreModelsTriggerStatusToJSON(value.greatdata),
        'form5500': ExecRiskAppCoreModelsTriggerStatusToJSON(value.form5500),
        'corelogic': ExecRiskAppCoreModelsTriggerStatusToJSON(value.corelogic),
    };
}

