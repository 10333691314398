/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityHoneypotsResult
 */
export interface MonitEntityHoneypotsResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    port?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityHoneypotsResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityHoneypotsResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityHoneypotsResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntityHoneypotsResult interface.
 */
export function instanceOfMonitEntityHoneypotsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityHoneypotsResultFromJSON(json: any): MonitEntityHoneypotsResult {
    return MonitEntityHoneypotsResultFromJSONTyped(json, false);
}

export function MonitEntityHoneypotsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityHoneypotsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntityHoneypotsResultToJSON(value?: MonitEntityHoneypotsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_domain': value.entity_domain,
        'entity_name': value.entity_name,
        'asset': value.asset,
        'source_asset': value.source_asset,
        'port': value.port,
        'tags': value.tags,
        'shared': value.shared,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
    };
}

