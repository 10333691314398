/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskScheduleSBBase } from './ExecRiskScheduleSBBase';
import {
    ExecRiskScheduleSBBaseFromJSON,
    ExecRiskScheduleSBBaseFromJSONTyped,
    ExecRiskScheduleSBBaseToJSON,
} from './ExecRiskScheduleSBBase';

/**
 * 
 * @export
 * @interface ExecRiskScheduleSBDef
 */
export interface ExecRiskScheduleSBDef {
    /**
     * 
     * @type {ExecRiskScheduleSBBase}
     * @memberof ExecRiskScheduleSBDef
     */
    base_form?: ExecRiskScheduleSBBase;
}

/**
 * Check if a given object implements the ExecRiskScheduleSBDef interface.
 */
export function instanceOfExecRiskScheduleSBDef(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleSBDefFromJSON(json: any): ExecRiskScheduleSBDef {
    return ExecRiskScheduleSBDefFromJSONTyped(json, false);
}

export function ExecRiskScheduleSBDefFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleSBDef {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_form': !exists(json, 'base_form') ? undefined : ExecRiskScheduleSBBaseFromJSON(json['base_form']),
    };
}

export function ExecRiskScheduleSBDefToJSON(value?: ExecRiskScheduleSBDef | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_form': ExecRiskScheduleSBBaseToJSON(value.base_form),
    };
}

