import { Box, Button, ButtonProps, Tooltip, Typography } from '@explorer/core';
import { ReportAsset } from '@explorer/forms/presets';
import { useDialog } from '@explorer/hooks';
import {
  ControlEntityAssetsIpAddressesResult,
  ControlEntityAssetsDomainsResult,
  ControlReportedAssetStatus,
} from '@explorer/open-api-client';
import { useUser } from '@explorer/stores';
import { GotoAssetProps } from '@explorer/tables/types';
import { useIntl } from 'react-intl';

/**
 * CellRemoveAsset
 */
export const CellRemoveAsset = ({
  disabled = false,
  reportedStatus,
  tableVariant,
  cellData,
  onSuccess,
  gotoAssetProps,
  ...props
}: CellRemoveAssetProps) => {
  const { open, openDialog, closeDialog } = useDialog();
  const { formatMessage } = useIntl();

  // User Has Some Permissions
  const { userHasPermissions } = useUser();
  const hasControlPermissions = userHasPermissions(['explorer:control']);

  if (disabled || !hasControlPermissions) {
    return null;
  }

  if (reportedStatus === ControlReportedAssetStatus.Reported) {
    return (
      <Typography variant="bodyS" color="error" style={{ fontStyle: 'italic' }}>
        {formatMessage({ id: 'tables-presets.cell-remove-assets-pending' })}
      </Typography>
    );
  }

  return (
    <>
      <Box>
        <Tooltip
          title={{
            id: 'tables-presets.cell-remove-assets-tooltip',
          }}
        >
          <Button
            disabled={!!reportedStatus}
            type="button"
            variant="outlined"
            color="primary"
            label={{ id: 'tables-presets.cell-remove-assets' }}
            size="small"
            style={{ height: '23px' }}
            {...props}
            onClick={(e) => {
              e.stopPropagation();
              openDialog();
            }}
          />
        </Tooltip>
      </Box>
      <ReportAsset
        open={open}
        entityUUID={cellData.entity_id!}
        onSuccess={onSuccess}
        asset={
          tableVariant === 'DOMAIN'
            ? (cellData as ControlEntityAssetsDomainsResult).domain!
            : (cellData as ControlEntityAssetsIpAddressesResult).ip_address!
        }
        gotoAssetProps={gotoAssetProps}
        assetSource={cellData.source!}
        handleClose={closeDialog}
      />
    </>
  );
};

export interface CellRemoveAssetProps extends ButtonProps {
  disabled?: boolean;
  reportedStatus?: ControlEntityAssetsIpAddressesResult['report_status'];
  gotoAssetProps: GotoAssetProps;
  tableVariant: 'IP' | 'DOMAIN';
  cellData:
    | ControlEntityAssetsIpAddressesResult & ControlEntityAssetsDomainsResult;
  onSuccess?: () => void;
}
