import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectProps } from '@mui/material/Select';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { TextField, TextFieldProps } from '@explorer/core';
import { formatMessage } from '@explorer/helpers';
import { OptionItem } from './OptionItem';
import { TitleItem } from './TitleItem';
/**
 * FieldSelect
 */
export const FieldSelect = ({
  options = [],
  name,
  label,
  id,
  onBlur,
  onChange,
  value,
  setFieldValue,
  setFieldError,
  className,
  multiple = false,
  MenuProps,
  ...props
}: FieldSelectProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const handleOnChange = useCallback(
    (event) => {
      const v = event.target.value;
      const isArr = Array.isArray(v);

      if (onChange) {
        if (isArr && v[0] !== null) onChange(event);
        if (isArr && v[0] !== '') onChange(event);
        if (!isArr && v !== '') onChange(event);
      }
    },
    [onChange],
  );

  /**
   * Get the labels of all the selected items
   */
  const getSelectedLabels = useCallback(
    (v: unknown) => {
      const selectedLabels: string[] = [];
      const selectedValues: string[] = Array.isArray(v) ? v : [v];

      for (let i = 0, ii = selectedValues.length; i < ii; i++) {
        const svalue = selectedValues[i];

        for (let j = 0, jj = options.length; j < jj; j++) {
          const opt = options[j];

          if (opt.value.toString() === svalue && !opt.isTitle) {
            selectedLabels.push(formatMessage(intl, opt?.label).toString());
          }
        }
      }

      return selectedLabels;
    },
    [intl, options],
  );

  const selectProps = useMemo<SelectProps>(
    () => ({
      multiple,
      MenuProps,
      renderValue: (v) => {
        return <>{getSelectedLabels(v).join(', ')}</>;
      },
    }),
    [multiple, MenuProps],
  );

  const selectOptions = useMemo(
    () =>
      options.map((option) => {
        if (option.isTitle) {
          return <TitleItem id={id} key={uuidv4()} label={option.label} />;
        }

        return (
          <OptionItem
            id={id}
            key={uuidv4()}
            label={option.label}
            value={option.value.toString()}
          />
        );
      }),
    [options, id, value],
  );

  return (
    <TextField
      {...props}
      select
      SelectProps={selectProps}
      id={id}
      label={intlLabel}
      onBlur={onBlur}
      onChange={handleOnChange}
      className={clsxClass}
      value={value}
      name={name}
    >
      {selectOptions}
    </TextField>
  );
};

/**
 * FieldSelect Props
 */
export type FieldSelectProps = Omit<TextFieldProps, 'multiline'> & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `options` - field options
   */
  options: SelectOptions;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * multiple
   */
  multiple?: SelectProps['multiple'];
  /**
   * MenuProps
   */
  MenuProps?: SelectProps['MenuProps'];
  /**
   * value
   */
  value?: string | string[];
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
};

export interface SelectOption {
  label: IntlLabel;
  value: string | number;
  isTitle?: boolean;
}

export type SelectOptions = SelectOption[];
