import React from 'react';
import clsx from 'clsx';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const AppBar = withCoreProps<AppBarProps>(
  ({ children, className, ...props }: React.PropsWithChildren<AppBarProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAppBar {...props} className={clsxClass}>
        {children}
      </MuiAppBar>
    );
  },
);

export interface AppBarProps extends MuiAppBarProps, CoreProps {}
