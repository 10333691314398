/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName
 */
export interface ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName
     */
    business_name_line_1_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName
     */
    business_name_line_2_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990CommonTypesBusinessName(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json: any): ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName {
    return ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'business_name_line_1_txt': !exists(json, 'business_name_line_1_txt') ? undefined : json['business_name_line_1_txt'],
        'business_name_line_2_txt': !exists(json, 'business_name_line_2_txt') ? undefined : json['business_name_line_2_txt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business_name_line_1_txt': value.business_name_line_1_txt,
        'business_name_line_2_txt': value.business_name_line_2_txt,
    };
}

