/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAuthenticationFactor } from './ControlAuthenticationFactor';
import {
    ControlAuthenticationFactorFromJSON,
    ControlAuthenticationFactorFromJSONTyped,
    ControlAuthenticationFactorToJSON,
} from './ControlAuthenticationFactor';

/**
 * 
 * @export
 * @interface ControlMFAChallengeResponse
 */
export interface ControlMFAChallengeResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlMFAChallengeResponse
     */
    mfa_token: string;
    /**
     * 
     * @type {ControlAuthenticationFactor}
     * @memberof ControlMFAChallengeResponse
     */
    enrolled_mfa_factor: ControlAuthenticationFactor;
    /**
     * 
     * @type {string}
     * @memberof ControlMFAChallengeResponse
     */
    oob_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMFAChallengeResponse
     */
    emailed_to?: string;
}

/**
 * Check if a given object implements the ControlMFAChallengeResponse interface.
 */
export function instanceOfControlMFAChallengeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mfa_token" in value;
    isInstance = isInstance && "enrolled_mfa_factor" in value;

    return isInstance;
}

export function ControlMFAChallengeResponseFromJSON(json: any): ControlMFAChallengeResponse {
    return ControlMFAChallengeResponseFromJSONTyped(json, false);
}

export function ControlMFAChallengeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMFAChallengeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfa_token': json['mfa_token'],
        'enrolled_mfa_factor': ControlAuthenticationFactorFromJSON(json['enrolled_mfa_factor']),
        'oob_code': !exists(json, 'oob_code') ? undefined : json['oob_code'],
        'emailed_to': !exists(json, 'emailed_to') ? undefined : json['emailed_to'],
    };
}

export function ControlMFAChallengeResponseToJSON(value?: ControlMFAChallengeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfa_token': value.mfa_token,
        'enrolled_mfa_factor': ControlAuthenticationFactorToJSON(value.enrolled_mfa_factor),
        'oob_code': value.oob_code,
        'emailed_to': value.emailed_to,
    };
}

