/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEvidenceCollection } from './ControlEvidenceCollection';
import {
    ControlEvidenceCollectionFromJSON,
    ControlEvidenceCollectionFromJSONTyped,
    ControlEvidenceCollectionToJSON,
} from './ControlEvidenceCollection';
import type { ControlSuggestedAnswerSource } from './ControlSuggestedAnswerSource';
import {
    ControlSuggestedAnswerSourceFromJSON,
    ControlSuggestedAnswerSourceFromJSONTyped,
    ControlSuggestedAnswerSourceToJSON,
} from './ControlSuggestedAnswerSource';
import type { FieldAnswer } from './FieldAnswer';
import {
    FieldAnswerFromJSON,
    FieldAnswerFromJSONTyped,
    FieldAnswerToJSON,
} from './FieldAnswer';

/**
 * 
 * @export
 * @interface ControlAnswer
 */
export interface ControlAnswer {
    /**
     * 
     * @type {FieldAnswer}
     * @memberof ControlAnswer
     */
    field_answer?: FieldAnswer;
    /**
     * 
     * @type {ControlEvidenceCollection}
     * @memberof ControlAnswer
     */
    evidence: ControlEvidenceCollection;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAnswer
     */
    is_complete: boolean;
    /**
     * 
     * @type {ControlSuggestedAnswerSource}
     * @memberof ControlAnswer
     */
    source?: ControlSuggestedAnswerSource;
    /**
     * 
     * @type {string}
     * @memberof ControlAnswer
     */
    answered_by_user_id?: string;
}

/**
 * Check if a given object implements the ControlAnswer interface.
 */
export function instanceOfControlAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "evidence" in value;
    isInstance = isInstance && "is_complete" in value;

    return isInstance;
}

export function ControlAnswerFromJSON(json: any): ControlAnswer {
    return ControlAnswerFromJSONTyped(json, false);
}

export function ControlAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_answer': !exists(json, 'field_answer') ? undefined : FieldAnswerFromJSON(json['field_answer']),
        'evidence': ControlEvidenceCollectionFromJSON(json['evidence']),
        'is_complete': json['is_complete'],
        'source': !exists(json, 'source') ? undefined : ControlSuggestedAnswerSourceFromJSON(json['source']),
        'answered_by_user_id': !exists(json, 'answered_by_user_id') ? undefined : json['answered_by_user_id'],
    };
}

export function ControlAnswerToJSON(value?: ControlAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_answer': FieldAnswerToJSON(value.field_answer),
        'evidence': ControlEvidenceCollectionToJSON(value.evidence),
        'is_complete': value.is_complete,
        'source': ControlSuggestedAnswerSourceToJSON(value.source),
        'answered_by_user_id': value.answered_by_user_id,
    };
}

