import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

export const DisabledTextField = ({
  value,
  label,
  multiline = false,
  rows = 0,
}: DisabledTextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      disabled
      fullWidth
      label={intl.formatMessage(label)}
      multiline={multiline}
      rows={rows}
      value={value}
      sx={(theme) => ({
        '& .MuiOutlinedInput-root': {
          bgcolor: `${theme.palette.grey[900]} !important`,
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.grey[400],
        },
        '& .Mui-disabled': {
          color: `${theme.palette.grey[300]} !important`,
        },
      })}
    />
  );
};

export interface DisabledTextFieldProps {
  value: string;
  label: { id: string };
  multiline?: boolean;
  rows?: number;
}
