import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * FundsTransferLiabilityIcon
 */
export const FundsTransferLiabilityIcon = ({
  ...props
}: FundsTransferLiabilityIconProps) => {
  return (
    <Icon label="funds_transfer_liability" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8,10.2L24,8.4C23.7,8.2,23.3,8,22.9,8h-8.8c-0.8,0-1.5,0.7-1.5,1.5v5.4c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.8,0,1.1,0.1c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1V9.9c0-0.2,0.2-0.4,0.4-0.4h8.2c0.1,0,0.2,0,0.3,0.1l1.6,1.6c0.1,0.1,0.1,0.2,0.1,0.3v10c0,0.2-0.2,0.4-0.4,0.4h-5.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.4-0.1,0.7-0.3,1.1c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.2,0.1h5.9c0.8,0,1.5-0.7,1.5-1.5V11.3C26.2,10.9,26.1,10.5,25.8,10.2z M20.7,18.2h-0.8c-0.2,0-0.4-0.2-0.4-0.4v-5.5c0-0.2,0.2-0.4,0.4-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4v5.5C21.1,18,20.9,18.2,20.7,18.2z M23.4,17.8c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4v-3.8c0-0.2,0.2-0.4,0.4-0.4H23c0.2,0,0.4,0.2,0.4,0.4V17.8z M17,15.4h0.8c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2,0-0.2,0c-0.3-0.3-0.7-0.7-1.2-0.9c-0.1,0-0.1-0.1-0.1-0.2v-0.2C16.6,15.5,16.8,15.4,17,15.4z M9.2,16c-0.1-0.1-0.2-0.2-0.4-0.2l-0.7,0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2c0.2-1.6,1-3.1,2.2-4.1l0.7,0.7c0.1,0.1,0.3,0.1,0.4,0.1c0.1-0.1,0.2-0.2,0.2-0.4l0-2.3c0-0.2-0.2-0.4-0.4-0.3L8.6,9.1c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.1,0,0.3,0.1,0.4l0.7,0.7c-1.5,1.4-2.5,3.3-2.6,5.4c0,0.1-0.1,0.2-0.2,0.2l-0.7,0.1c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.3,0.1,0.4l1.7,1.3c0.2,0.1,0.4,0.1,0.5,0l1.6-1.6C9.3,16.3,9.3,16.2,9.2,16z M8.4,21.2c0,2.7,2.1,4.9,4.7,4.9h0.1c2.6,0,4.7-2.2,4.7-4.9c0-2.7-2.1-4.9-4.8-4.9C10.5,16.3,8.4,18.5,8.4,21.2z M15.1,22.6c-0.1,0.7-0.6,1.3-1.3,1.4c-0.1,0-0.1,0.1-0.1,0.2v0.3c0,0.3-0.2,0.6-0.6,0.6s-0.6-0.3-0.6-0.6v-0.3c0-0.1-0.1-0.1-0.1-0.1h-0.4c-0.3,0-0.6-0.3-0.6-0.6s0.2-0.6,0.6-0.6h1.5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.4-0.3-0.5l-1.5-0.6c-0.6-0.3-1-0.9-1-1.6c0.1-0.7,0.6-1.3,1.3-1.4v0v0c0.1,0,0.1-0.1,0.1-0.2V18c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v0.3c0,0.1,0.1,0.1,0.1,0.1h0.4c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6h-1.5c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.4,0.3,0.5l1.5,0.6C14.8,21.3,15.2,21.9,15.1,22.6z"
        />
      </g>
    </Icon>
  );
};

/**
 * FundsTransferLiabilityIcon Props
 */
export interface FundsTransferLiabilityIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
