import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * PendingResolutionIcon
 */
export const PendingResolutionIcon = ({
  ...props
}: PendingResolutionIconProps) => {
  return (
    <Icon label="pending_muted" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14.9 13.49L13.5 14.89C13.3 15.09 12.99 15.09 12.79 14.89L9.38 11.48C8.16 11.91 6.74 11.65 5.76 10.67C4.65 9.56 4.46 7.88 5.17 6.57L7.52 8.92L8.93 7.51L6.58 5.17C7.9 4.46 9.57 4.65 10.68 5.76C11.66 6.74 11.92 8.16 11.49 9.38L14.9 12.79C15.09 12.98 15.09 13.3 14.9 13.49Z"
        fill="#323232"
      />
    </Icon>
  );
};

/**
 * PendingResolutionIcon Props
 */
export interface PendingResolutionIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor' | 'viewBox'> {}
