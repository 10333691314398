import React from 'react';
import { Avatar, Image } from '@explorer/core';

/**
 * CompanyLogo
 */
export const CompanyLogo = ({ src, name }: CompanyLogoProps) => {
  if (!src || src === '') return <Avatar>{name[0]}</Avatar>;

  return <Image src={src} responsive width="99px" title={name} />;
};

/**
 * CompanyLogo Props
 */
export interface CompanyLogoProps {
  src: string | undefined;
  name: string;
}
