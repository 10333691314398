/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskGreatDataScan
 */
export interface ExecRiskGreatDataScan {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGreatDataScan
     */
    postal_code: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGreatDataScan
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGreatDataScan
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGreatDataScan
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGreatDataScan
     */
    type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGreatDataScan
     */
    rural_code: string;
}

/**
 * Check if a given object implements the ExecRiskGreatDataScan interface.
 */
export function instanceOfExecRiskGreatDataScan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "postal_code" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "county" in value;
    isInstance = isInstance && "rural_code" in value;

    return isInstance;
}

export function ExecRiskGreatDataScanFromJSON(json: any): ExecRiskGreatDataScan {
    return ExecRiskGreatDataScanFromJSONTyped(json, false);
}

export function ExecRiskGreatDataScanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskGreatDataScan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postal_code': json['postal_code'],
        'state': json['state'],
        'city': json['city'],
        'county': json['county'],
        'type_code': !exists(json, 'type_code') ? undefined : json['type_code'],
        'rural_code': json['rural_code'],
    };
}

export function ExecRiskGreatDataScanToJSON(value?: ExecRiskGreatDataScan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postal_code': value.postal_code,
        'state': value.state,
        'city': value.city,
        'county': value.county,
        'type_code': value.type_code,
        'rural_code': value.rural_code,
    };
}

