/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplementalReenableFormInstanceRequestBody
 */
export interface SupplementalReenableFormInstanceRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof SupplementalReenableFormInstanceRequestBody
     */
    copy_data?: boolean;
}

/**
 * Check if a given object implements the SupplementalReenableFormInstanceRequestBody interface.
 */
export function instanceOfSupplementalReenableFormInstanceRequestBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalReenableFormInstanceRequestBodyFromJSON(json: any): SupplementalReenableFormInstanceRequestBody {
    return SupplementalReenableFormInstanceRequestBodyFromJSONTyped(json, false);
}

export function SupplementalReenableFormInstanceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalReenableFormInstanceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copy_data': !exists(json, 'copy_data') ? undefined : json['copy_data'],
    };
}

export function SupplementalReenableFormInstanceRequestBodyToJSON(value?: SupplementalReenableFormInstanceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copy_data': value.copy_data,
    };
}

