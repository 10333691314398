/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDesiredPolicyFieldsInput } from './CRSFSecReviewDesiredPolicyFieldsInput';
import {
    CRSFSecReviewDesiredPolicyFieldsInputFromJSON,
    CRSFSecReviewDesiredPolicyFieldsInputFromJSONTyped,
    CRSFSecReviewDesiredPolicyFieldsInputToJSON,
} from './CRSFSecReviewDesiredPolicyFieldsInput';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';
import type { CRSFSecReviewTicketPayloadInput } from './CRSFSecReviewTicketPayloadInput';
import {
    CRSFSecReviewTicketPayloadInputFromJSON,
    CRSFSecReviewTicketPayloadInputFromJSONTyped,
    CRSFSecReviewTicketPayloadInputToJSON,
} from './CRSFSecReviewTicketPayloadInput';

/**
 * The secondary scan trigger.
 * @export
 * @interface CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
 */
export interface CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    account_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    attachment_domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    cobranded?: string;
    /**
     * 
     * @type {CRSFSecReviewDesiredPolicyFieldsInput}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    desired_policy_fields?: CRSFSecReviewDesiredPolicyFieldsInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    issue_key?: string;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    new_domains_at_bind?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewTicketPayloadInput}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    ticket_payload?: CRSFSecReviewTicketPayloadInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger interface.
 */
export function instanceOfCRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerFromJSON(json: any): CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger {
    return CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerFromJSONTyped(json, false);
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'attachment_domains': !exists(json, 'attachment_domains') ? undefined : json['attachment_domains'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_fields': !exists(json, 'desired_policy_fields') ? undefined : CRSFSecReviewDesiredPolicyFieldsInputFromJSON(json['desired_policy_fields']),
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': !exists(json, 'issue_key') ? undefined : json['issue_key'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'new_domains_at_bind': !exists(json, 'new_domains_at_bind') ? undefined : json['new_domains_at_bind'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'ticket_payload': !exists(json, 'ticket_payload') ? undefined : CRSFSecReviewTicketPayloadInputFromJSON(json['ticket_payload']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerToJSON(value?: CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_uuid': value.account_uuid,
        'attachment_domains': value.attachment_domains,
        'cobranded': value.cobranded,
        'desired_policy_fields': CRSFSecReviewDesiredPolicyFieldsInputToJSON(value.desired_policy_fields),
        'desired_policy_uuid': value.desired_policy_uuid,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'new_domains_at_bind': value.new_domains_at_bind,
        'renewal_of_uuid': value.renewal_of_uuid,
        'request_origin': value.request_origin,
        'retained_from_uuid': value.retained_from_uuid,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'ticket_payload': CRSFSecReviewTicketPayloadInputToJSON(value.ticket_payload),
        'user': value.user,
    };
}

