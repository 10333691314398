/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityMalwareData } from './ControlEntityMalwareData';
import {
    ControlEntityMalwareDataFromJSON,
    ControlEntityMalwareDataFromJSONTyped,
    ControlEntityMalwareDataToJSON,
} from './ControlEntityMalwareData';

/**
 * 
 * @export
 * @interface ControlEntityMalwareAggResponse
 */
export interface ControlEntityMalwareAggResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityMalwareAggResponse
     */
    assets?: number;
    /**
     * 
     * @type {ControlEntityMalwareData}
     * @memberof ControlEntityMalwareAggResponse
     */
    malicious?: ControlEntityMalwareData;
    /**
     * 
     * @type {ControlEntityMalwareData}
     * @memberof ControlEntityMalwareAggResponse
     */
    spam?: ControlEntityMalwareData;
}

/**
 * Check if a given object implements the ControlEntityMalwareAggResponse interface.
 */
export function instanceOfControlEntityMalwareAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityMalwareAggResponseFromJSON(json: any): ControlEntityMalwareAggResponse {
    return ControlEntityMalwareAggResponseFromJSONTyped(json, false);
}

export function ControlEntityMalwareAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityMalwareAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'malicious': !exists(json, 'malicious') ? undefined : ControlEntityMalwareDataFromJSON(json['malicious']),
        'spam': !exists(json, 'spam') ? undefined : ControlEntityMalwareDataFromJSON(json['spam']),
    };
}

export function ControlEntityMalwareAggResponseToJSON(value?: ControlEntityMalwareAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets,
        'malicious': ControlEntityMalwareDataToJSON(value.malicious),
        'spam': ControlEntityMalwareDataToJSON(value.spam),
    };
}

