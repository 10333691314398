/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityMuteNotificationsOrderBy } from './MonitEntityMuteNotificationsOrderBy';
import {
    MonitEntityMuteNotificationsOrderByFromJSON,
    MonitEntityMuteNotificationsOrderByFromJSONTyped,
    MonitEntityMuteNotificationsOrderByToJSON,
} from './MonitEntityMuteNotificationsOrderBy';
import type { MonitResolutionRequestStatus } from './MonitResolutionRequestStatus';
import {
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusFromJSONTyped,
    MonitResolutionRequestStatusToJSON,
} from './MonitResolutionRequestStatus';

/**
 * 
 * @export
 * @interface MonitGetEntityMuteNotificationsQueries
 */
export interface MonitGetEntityMuteNotificationsQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    page_size?: number;
    /**
     * 
     * @type {MonitResolutionRequestStatus}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    status?: MonitResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    datapoint?: string;
    /**
     * 
     * @type {MonitEntityMuteNotificationsOrderBy}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    order_by?: MonitEntityMuteNotificationsOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityMuteNotificationsQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityMuteNotificationsQueries interface.
 */
export function instanceOfMonitGetEntityMuteNotificationsQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityMuteNotificationsQueriesFromJSON(json: any): MonitGetEntityMuteNotificationsQueries {
    return MonitGetEntityMuteNotificationsQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityMuteNotificationsQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityMuteNotificationsQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'status': !exists(json, 'status') ? undefined : MonitResolutionRequestStatusFromJSON(json['status']),
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityMuteNotificationsOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityMuteNotificationsQueriesToJSON(value?: MonitGetEntityMuteNotificationsQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'status': MonitResolutionRequestStatusToJSON(value.status),
        'datapoint': value.datapoint,
        'order_by': MonitEntityMuteNotificationsOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

