/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityStatsCyberBenchmark } from './ControlEntityStatsCyberBenchmark';
import {
    ControlEntityStatsCyberBenchmarkFromJSON,
    ControlEntityStatsCyberBenchmarkFromJSONTyped,
    ControlEntityStatsCyberBenchmarkToJSON,
} from './ControlEntityStatsCyberBenchmark';
import type { ControlEntityStatsRiskScoreV2 } from './ControlEntityStatsRiskScoreV2';
import {
    ControlEntityStatsRiskScoreV2FromJSON,
    ControlEntityStatsRiskScoreV2FromJSONTyped,
    ControlEntityStatsRiskScoreV2ToJSON,
} from './ControlEntityStatsRiskScoreV2';
import type { ControlEntityStatsSurface } from './ControlEntityStatsSurface';
import {
    ControlEntityStatsSurfaceFromJSON,
    ControlEntityStatsSurfaceFromJSONTyped,
    ControlEntityStatsSurfaceToJSON,
} from './ControlEntityStatsSurface';
import type { ControlEntityStatsVulnerabilities } from './ControlEntityStatsVulnerabilities';
import {
    ControlEntityStatsVulnerabilitiesFromJSON,
    ControlEntityStatsVulnerabilitiesFromJSONTyped,
    ControlEntityStatsVulnerabilitiesToJSON,
} from './ControlEntityStatsVulnerabilities';

/**
 * 
 * @export
 * @interface ControlEntityStatsResponse
 */
export interface ControlEntityStatsResponse {
    /**
     * 
     * @type {ControlEntityStatsSurface}
     * @memberof ControlEntityStatsResponse
     */
    surface?: ControlEntityStatsSurface;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilities}
     * @memberof ControlEntityStatsResponse
     */
    vulnerabilities?: ControlEntityStatsVulnerabilities;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    stored?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    risk_score?: number;
    /**
     * 
     * @type {ControlEntityStatsRiskScoreV2}
     * @memberof ControlEntityStatsResponse
     */
    risk_score_v2?: ControlEntityStatsRiskScoreV2;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityStatsResponse
     */
    report_url?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ControlEntityStatsResponse
     */
    localized_reports?: { [key: string]: string; };
    /**
     * 
     * @type {ControlEntityStatsCyberBenchmark}
     * @memberof ControlEntityStatsResponse
     */
    cyber_benchmark?: ControlEntityStatsCyberBenchmark;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    assets_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    vulnerabilities_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    dataleaks_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    honeypots_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    torrents_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityStatsResponse
     */
    reason?: string;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsResponse
     */
    previous_risk_score_v2?: object;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    previous_assets_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsResponse
     */
    previous_vulnerabilities_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityStatsResponse
     */
    created?: string;
}

/**
 * Check if a given object implements the ControlEntityStatsResponse interface.
 */
export function instanceOfControlEntityStatsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsResponseFromJSON(json: any): ControlEntityStatsResponse {
    return ControlEntityStatsResponseFromJSONTyped(json, false);
}

export function ControlEntityStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surface': !exists(json, 'surface') ? undefined : ControlEntityStatsSurfaceFromJSON(json['surface']),
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : ControlEntityStatsVulnerabilitiesFromJSON(json['vulnerabilities']),
        'stored': !exists(json, 'stored') ? undefined : json['stored'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : ControlEntityStatsRiskScoreV2FromJSON(json['risk_score_v2']),
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'localized_reports': !exists(json, 'localized_reports') ? undefined : json['localized_reports'],
        'cyber_benchmark': !exists(json, 'cyber_benchmark') ? undefined : ControlEntityStatsCyberBenchmarkFromJSON(json['cyber_benchmark']),
        'assets_count': !exists(json, 'assets_count') ? undefined : json['assets_count'],
        'vulnerabilities_count': !exists(json, 'vulnerabilities_count') ? undefined : json['vulnerabilities_count'],
        'dataleaks_count': !exists(json, 'dataleaks_count') ? undefined : json['dataleaks_count'],
        'honeypots_count': !exists(json, 'honeypots_count') ? undefined : json['honeypots_count'],
        'torrents_count': !exists(json, 'torrents_count') ? undefined : json['torrents_count'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'previous_risk_score_v2': !exists(json, 'previous_risk_score_v2') ? undefined : json['previous_risk_score_v2'],
        'previous_assets_count': !exists(json, 'previous_assets_count') ? undefined : json['previous_assets_count'],
        'previous_vulnerabilities_count': !exists(json, 'previous_vulnerabilities_count') ? undefined : json['previous_vulnerabilities_count'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function ControlEntityStatsResponseToJSON(value?: ControlEntityStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surface': ControlEntityStatsSurfaceToJSON(value.surface),
        'vulnerabilities': ControlEntityStatsVulnerabilitiesToJSON(value.vulnerabilities),
        'stored': value.stored,
        'risk_score': value.risk_score,
        'risk_score_v2': ControlEntityStatsRiskScoreV2ToJSON(value.risk_score_v2),
        'report_url': value.report_url,
        'localized_reports': value.localized_reports,
        'cyber_benchmark': ControlEntityStatsCyberBenchmarkToJSON(value.cyber_benchmark),
        'assets_count': value.assets_count,
        'vulnerabilities_count': value.vulnerabilities_count,
        'dataleaks_count': value.dataleaks_count,
        'honeypots_count': value.honeypots_count,
        'torrents_count': value.torrents_count,
        'reason': value.reason,
        'previous_risk_score_v2': value.previous_risk_score_v2,
        'previous_assets_count': value.previous_assets_count,
        'previous_vulnerabilities_count': value.previous_vulnerabilities_count,
        'created': value.created,
    };
}

