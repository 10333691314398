/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskViolationTrackerViolation
 */
export interface ExecRiskViolationTrackerViolation {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    agency?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    naics?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    info_source?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskViolationTrackerViolation
     */
    penalty_year?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskViolationTrackerViolation
     */
    penalty?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskViolationTrackerViolation
     */
    sub_penalty?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskViolationTrackerViolation
     */
    penalty_adjusted?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    orig_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    npa_dpa?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    offense_group?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    primary_offense?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    secondary_offense?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    naics_tr?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    case_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    penalty_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    litigation_case_title?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    court?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    lawsuit_resolution?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    case_category?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskViolationTrackerViolation
     */
    pacer_link?: string;
}

/**
 * Check if a given object implements the ExecRiskViolationTrackerViolation interface.
 */
export function instanceOfExecRiskViolationTrackerViolation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskViolationTrackerViolationFromJSON(json: any): ExecRiskViolationTrackerViolation {
    return ExecRiskViolationTrackerViolationFromJSONTyped(json, false);
}

export function ExecRiskViolationTrackerViolationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskViolationTrackerViolation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : json['company'],
        'agency': !exists(json, 'agency') ? undefined : json['agency'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'naics': !exists(json, 'naics') ? undefined : json['naics'],
        'info_source': !exists(json, 'info_source') ? undefined : json['info_source'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'penalty_year': !exists(json, 'penalty_year') ? undefined : json['penalty_year'],
        'penalty': !exists(json, 'penalty') ? undefined : json['penalty'],
        'sub_penalty': !exists(json, 'sub_penalty') ? undefined : json['sub_penalty'],
        'penalty_adjusted': !exists(json, 'penalty_adjusted') ? undefined : json['penalty_adjusted'],
        'orig_id': !exists(json, 'orig_id') ? undefined : json['orig_id'],
        'npa_dpa': !exists(json, 'npa_dpa') ? undefined : json['npa_dpa'],
        'offense_group': !exists(json, 'offense_group') ? undefined : json['offense_group'],
        'primary_offense': !exists(json, 'primary_offense') ? undefined : json['primary_offense'],
        'secondary_offense': !exists(json, 'secondary_offense') ? undefined : json['secondary_offense'],
        'naics_tr': !exists(json, 'naics_tr') ? undefined : json['naics_tr'],
        'case_id': !exists(json, 'case_id') ? undefined : json['case_id'],
        'penalty_date': !exists(json, 'penalty_date') ? undefined : json['penalty_date'],
        'litigation_case_title': !exists(json, 'litigation_case_title') ? undefined : json['litigation_case_title'],
        'court': !exists(json, 'court') ? undefined : json['court'],
        'lawsuit_resolution': !exists(json, 'lawsuit_resolution') ? undefined : json['lawsuit_resolution'],
        'case_category': !exists(json, 'case_category') ? undefined : json['case_category'],
        'pacer_link': !exists(json, 'pacer_link') ? undefined : json['pacer_link'],
    };
}

export function ExecRiskViolationTrackerViolationToJSON(value?: ExecRiskViolationTrackerViolation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': value.company,
        'agency': value.agency,
        'state': value.state,
        'naics': value.naics,
        'info_source': value.info_source,
        'description': value.description,
        'notes': value.notes,
        'penalty_year': value.penalty_year,
        'penalty': value.penalty,
        'sub_penalty': value.sub_penalty,
        'penalty_adjusted': value.penalty_adjusted,
        'orig_id': value.orig_id,
        'npa_dpa': value.npa_dpa,
        'offense_group': value.offense_group,
        'primary_offense': value.primary_offense,
        'secondary_offense': value.secondary_offense,
        'naics_tr': value.naics_tr,
        'case_id': value.case_id,
        'penalty_date': value.penalty_date,
        'litigation_case_title': value.litigation_case_title,
        'court': value.court,
        'lawsuit_resolution': value.lawsuit_resolution,
        'case_category': value.case_category,
        'pacer_link': value.pacer_link,
    };
}

