/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlThreat = {
    AccountTakeover: 'Account takeover',
    AdvancedPersistentThreats: 'Advanced persistent threats',
    BusinessEmailCompromise: 'Business Email Compromise',
    BusinessInterruption: 'Business Interruption',
    CredentialStuffing: 'Credential Stuffing',
    DataLoss: 'Data Loss',
    Ddos: 'DDOS',
    FundsTransferFraud: 'Funds Transfer Fraud',
    HumanError: 'Human Error',
    Malware: 'Malware',
    ManInTheMiddleMitm: 'Man-in-the-middle (MITM)',
    OutdatedSoftware: 'Outdated software',
    PasswordBased: 'Password-based',
    PasswordReuseAttacks: 'Password reuse attacks',
    Phishing: 'Phishing',
    Ransomware: 'Ransomware',
    ScriptBasedAttacks: 'Script-based attacks',
    SessionHijacking: 'Session hijacking',
    SocialEngineering: 'Social Engineering',
    UnauthorizedAccess: 'Unauthorized access'
} as const;
export type ControlThreat = typeof ControlThreat[keyof typeof ControlThreat];


export function ControlThreatFromJSON(json: any): ControlThreat {
    return ControlThreatFromJSONTyped(json, false);
}

export function ControlThreatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlThreat {
    return json as ControlThreat;
}

export function ControlThreatToJSON(value?: ControlThreat | null): any {
    return value as any;
}

