/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlASMEntityResponse } from './ControlASMEntityResponse';
import {
    ControlASMEntityResponseFromJSON,
    ControlASMEntityResponseFromJSONTyped,
    ControlASMEntityResponseToJSON,
} from './ControlASMEntityResponse';

/**
 * 
 * @export
 * @interface ControlAppSchemasUserClientResponse
 */
export interface ControlAppSchemasUserClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasUserClientResponse
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasUserClientResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<ControlASMEntityResponse>}
     * @memberof ControlAppSchemasUserClientResponse
     */
    asm_entities?: Array<ControlASMEntityResponse>;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasUserClientResponse
     */
    role?: string;
}

/**
 * Check if a given object implements the ControlAppSchemasUserClientResponse interface.
 */
export function instanceOfControlAppSchemasUserClientResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;

    return isInstance;
}

export function ControlAppSchemasUserClientResponseFromJSON(json: any): ControlAppSchemasUserClientResponse {
    return ControlAppSchemasUserClientResponseFromJSONTyped(json, false);
}

export function ControlAppSchemasUserClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppSchemasUserClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'asm_entities': !exists(json, 'asm_entities') ? undefined : ((json['asm_entities'] as Array<any>).map(ControlASMEntityResponseFromJSON)),
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function ControlAppSchemasUserClientResponseToJSON(value?: ControlAppSchemasUserClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'name': value.name,
        'asm_entities': value.asm_entities === undefined ? undefined : ((value.asm_entities as Array<any>).map(ControlASMEntityResponseToJSON)),
        'role': value.role,
    };
}

