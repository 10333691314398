/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityAssetTechData } from './MonitEntityAssetTechData';
import {
    MonitEntityAssetTechDataFromJSON,
    MonitEntityAssetTechDataFromJSONTyped,
    MonitEntityAssetTechDataToJSON,
} from './MonitEntityAssetTechData';
import type { MonitEntityFindingsAggAsset } from './MonitEntityFindingsAggAsset';
import {
    MonitEntityFindingsAggAssetFromJSON,
    MonitEntityFindingsAggAssetFromJSONTyped,
    MonitEntityFindingsAggAssetToJSON,
} from './MonitEntityFindingsAggAsset';

/**
 * 
 * @export
 * @interface MonitEntityTechAggEntry
 */
export interface MonitEntityTechAggEntry {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechAggEntry
     */
    tech_name: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechAggEntry
     */
    version?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityTechAggEntry
     */
    count: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityTechAggEntry
     */
    category?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityTechAggEntry
     */
    cves?: Array<string>;
    /**
     * 
     * @type {Array<MonitEntityFindingsAggAsset>}
     * @memberof MonitEntityTechAggEntry
     */
    assets?: Array<MonitEntityFindingsAggAsset>;
    /**
     * 
     * @type {MonitEntityAssetTechData}
     * @memberof MonitEntityTechAggEntry
     */
    data?: MonitEntityAssetTechData;
}

/**
 * Check if a given object implements the MonitEntityTechAggEntry interface.
 */
export function instanceOfMonitEntityTechAggEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tech_name" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function MonitEntityTechAggEntryFromJSON(json: any): MonitEntityTechAggEntry {
    return MonitEntityTechAggEntryFromJSONTyped(json, false);
}

export function MonitEntityTechAggEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityTechAggEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tech_name': json['tech_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'count': json['count'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'cves': !exists(json, 'cves') ? undefined : json['cves'],
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(MonitEntityFindingsAggAssetFromJSON)),
        'data': !exists(json, 'data') ? undefined : MonitEntityAssetTechDataFromJSON(json['data']),
    };
}

export function MonitEntityTechAggEntryToJSON(value?: MonitEntityTechAggEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tech_name': value.tech_name,
        'version': value.version,
        'count': value.count,
        'category': value.category,
        'cves': value.cves,
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(MonitEntityFindingsAggAssetToJSON)),
        'data': MonitEntityAssetTechDataToJSON(value.data),
    };
}

