/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewSREntityResultDataResponseBody } from './SecReviewSREntityResultDataResponseBody';
import {
    SecReviewSREntityResultDataResponseBodyFromJSON,
    SecReviewSREntityResultDataResponseBodyFromJSONTyped,
    SecReviewSREntityResultDataResponseBodyToJSON,
} from './SecReviewSREntityResultDataResponseBody';
import type { SecReviewSREntityResultDataResponseHeaders } from './SecReviewSREntityResultDataResponseHeaders';
import {
    SecReviewSREntityResultDataResponseHeadersFromJSON,
    SecReviewSREntityResultDataResponseHeadersFromJSONTyped,
    SecReviewSREntityResultDataResponseHeadersToJSON,
} from './SecReviewSREntityResultDataResponseHeaders';
import type { SecReviewSREntityResultDataResponseStatus } from './SecReviewSREntityResultDataResponseStatus';
import {
    SecReviewSREntityResultDataResponseStatusFromJSON,
    SecReviewSREntityResultDataResponseStatusFromJSONTyped,
    SecReviewSREntityResultDataResponseStatusToJSON,
} from './SecReviewSREntityResultDataResponseStatus';

/**
 * 
 * @export
 * @interface SecReviewSREntityResultDataResponse
 */
export interface SecReviewSREntityResultDataResponse {
    /**
     * 
     * @type {SecReviewSREntityResultDataResponseBody}
     * @memberof SecReviewSREntityResultDataResponse
     */
    body?: SecReviewSREntityResultDataResponseBody;
    /**
     * 
     * @type {SecReviewSREntityResultDataResponseHeaders}
     * @memberof SecReviewSREntityResultDataResponse
     */
    headers?: SecReviewSREntityResultDataResponseHeaders;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponse
     */
    path?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof SecReviewSREntityResultDataResponse
     */
    redirects?: Array<object>;
    /**
     * 
     * @type {SecReviewSREntityResultDataResponseStatus}
     * @memberof SecReviewSREntityResultDataResponse
     */
    status?: SecReviewSREntityResultDataResponseStatus;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponse
     */
    version?: string;
}

/**
 * Check if a given object implements the SecReviewSREntityResultDataResponse interface.
 */
export function instanceOfSecReviewSREntityResultDataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSREntityResultDataResponseFromJSON(json: any): SecReviewSREntityResultDataResponse {
    return SecReviewSREntityResultDataResponseFromJSONTyped(json, false);
}

export function SecReviewSREntityResultDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSREntityResultDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': !exists(json, 'body') ? undefined : SecReviewSREntityResultDataResponseBodyFromJSON(json['body']),
        'headers': !exists(json, 'headers') ? undefined : SecReviewSREntityResultDataResponseHeadersFromJSON(json['headers']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'redirects': !exists(json, 'redirects') ? undefined : json['redirects'],
        'status': !exists(json, 'status') ? undefined : SecReviewSREntityResultDataResponseStatusFromJSON(json['status']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function SecReviewSREntityResultDataResponseToJSON(value?: SecReviewSREntityResultDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': SecReviewSREntityResultDataResponseBodyToJSON(value.body),
        'headers': SecReviewSREntityResultDataResponseHeadersToJSON(value.headers),
        'path': value.path,
        'redirects': value.redirects,
        'status': SecReviewSREntityResultDataResponseStatusToJSON(value.status),
        'title': value.title,
        'url': value.url,
        'version': value.version,
    };
}

