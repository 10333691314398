/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlAlertTypes = {
    HellosignDocument: 'hellosign_document',
    InviteItContact: 'invite_it_contact',
    AddWatchlistCompany: 'add_watchlist_company',
    AccountEligibleForUpgrade: 'account_eligible_for_upgrade',
    ZeroDayAlert: 'zero_day_alert'
} as const;
export type ControlAlertTypes = typeof ControlAlertTypes[keyof typeof ControlAlertTypes];


export function ControlAlertTypesFromJSON(json: any): ControlAlertTypes {
    return ControlAlertTypesFromJSONTyped(json, false);
}

export function ControlAlertTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAlertTypes {
    return json as ControlAlertTypes;
}

export function ControlAlertTypesToJSON(value?: ControlAlertTypes | null): any {
    return value as any;
}

