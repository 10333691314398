import React from 'react';
import clsx from 'clsx';
import MuiFormHelperText, {
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@mui/material/FormHelperText';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const FormHelperText = withCoreProps<FormHelperTextProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<FormHelperTextProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiFormHelperText {...props} className={clsxClass}>
        {children}
      </MuiFormHelperText>
    );
  },
);

export interface FormHelperTextProps
  extends MuiFormHelperTextProps,
    CoreProps {}
