/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlUserFilterCode } from './ControlUserFilterCode';
import {
    ControlUserFilterCodeFromJSON,
    ControlUserFilterCodeFromJSONTyped,
    ControlUserFilterCodeToJSON,
} from './ControlUserFilterCode';

/**
 * 
 * @export
 * @interface ControlFilterSchemaResponse
 */
export interface ControlFilterSchemaResponse {
    /**
     * 
     * @type {Array<ControlUserFilterCode>}
     * @memberof ControlFilterSchemaResponse
     */
    results?: Array<ControlUserFilterCode>;
    /**
     * 
     * @type {number}
     * @memberof ControlFilterSchemaResponse
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ControlFilterSchemaResponse
     */
    lead_id?: number;
}

/**
 * Check if a given object implements the ControlFilterSchemaResponse interface.
 */
export function instanceOfControlFilterSchemaResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ControlFilterSchemaResponseFromJSON(json: any): ControlFilterSchemaResponse {
    return ControlFilterSchemaResponseFromJSONTyped(json, false);
}

export function ControlFilterSchemaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFilterSchemaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlUserFilterCodeFromJSON)),
        'count': json['count'],
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
    };
}

export function ControlFilterSchemaResponseToJSON(value?: ControlFilterSchemaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlUserFilterCodeToJSON)),
        'count': value.count,
        'lead_id': value.lead_id,
    };
}

