/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationsMessageForJiraService } from './NotificationsMessageForJiraService';
import {
    NotificationsMessageForJiraServiceFromJSON,
    NotificationsMessageForJiraServiceFromJSONTyped,
    NotificationsMessageForJiraServiceToJSON,
} from './NotificationsMessageForJiraService';

/**
 * 
 * @export
 * @interface NotificationsMessageAsAlertForJiraServiceResponse
 */
export interface NotificationsMessageAsAlertForJiraServiceResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationsMessageAsAlertForJiraServiceResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsMessageAsAlertForJiraServiceResponse
     */
    page_size: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsMessageAsAlertForJiraServiceResponse
     */
    count: number;
    /**
     * 
     * @type {Array<NotificationsMessageForJiraService>}
     * @memberof NotificationsMessageAsAlertForJiraServiceResponse
     */
    results: Array<NotificationsMessageForJiraService>;
}

/**
 * Check if a given object implements the NotificationsMessageAsAlertForJiraServiceResponse interface.
 */
export function instanceOfNotificationsMessageAsAlertForJiraServiceResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function NotificationsMessageAsAlertForJiraServiceResponseFromJSON(json: any): NotificationsMessageAsAlertForJiraServiceResponse {
    return NotificationsMessageAsAlertForJiraServiceResponseFromJSONTyped(json, false);
}

export function NotificationsMessageAsAlertForJiraServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessageAsAlertForJiraServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'page_size': json['page_size'],
        'count': json['count'],
        'results': ((json['results'] as Array<any>).map(NotificationsMessageForJiraServiceFromJSON)),
    };
}

export function NotificationsMessageAsAlertForJiraServiceResponseToJSON(value?: NotificationsMessageAsAlertForJiraServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': ((value.results as Array<any>).map(NotificationsMessageForJiraServiceToJSON)),
    };
}

