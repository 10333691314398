/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitExceptionStatus } from './MonitExceptionStatus';
import {
    MonitExceptionStatusFromJSON,
    MonitExceptionStatusFromJSONTyped,
    MonitExceptionStatusToJSON,
} from './MonitExceptionStatus';

/**
 * 
 * @export
 * @interface MonitResolutionMetadata
 */
export interface MonitResolutionMetadata {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitResolutionMetadata
     */
    attestations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitResolutionMetadata
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitResolutionMetadata
     */
    resolution_notes?: string;
    /**
     * 
     * @type {MonitExceptionStatus}
     * @memberof MonitResolutionMetadata
     */
    exception_status?: MonitExceptionStatus;
}

/**
 * Check if a given object implements the MonitResolutionMetadata interface.
 */
export function instanceOfMonitResolutionMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitResolutionMetadataFromJSON(json: any): MonitResolutionMetadata {
    return MonitResolutionMetadataFromJSONTyped(json, false);
}

export function MonitResolutionMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitResolutionMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestations': !exists(json, 'attestations') ? undefined : json['attestations'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'resolution_notes': !exists(json, 'resolution_notes') ? undefined : json['resolution_notes'],
        'exception_status': !exists(json, 'exception_status') ? undefined : MonitExceptionStatusFromJSON(json['exception_status']),
    };
}

export function MonitResolutionMetadataToJSON(value?: MonitResolutionMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestations': value.attestations,
        'notes': value.notes,
        'resolution_notes': value.resolution_notes,
        'exception_status': MonitExceptionStatusToJSON(value.exception_status),
    };
}

