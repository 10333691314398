import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * NetworkInformationIcon
 */
export const NetworkInformationIcon = ({
  ...props
}: NetworkInformationIconProps) => {
  return (
    <Icon label="network" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.6,7h16.7C25.3,7,26,7.7,26,8.6l0,6c0,5.9-4,11-9.8,12.3c-0.1,0-0.2,0-0.3,0C10.1,25.7,6,20.6,6,14.8l0-6.1C6,7.7,6.7,7,7.6,7z M18.1,20.1l0.5,0.9c1.6-0.9,2.6-2.6,2.6-4.5c0-2.9-2.4-5.2-5.2-5.2s-5.2,2.4-5.2,5.2c0,1.9,1,3.6,2.6,4.5l0.5-0.9c-1.3-0.7-2.1-2.1-2.1-3.6c0-2.3,1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2C20.2,18,19.4,19.3,18.1,20.1z M18.1,16.5c0,0.8-0.4,1.5-1,1.8l0.5,0.9c0.9-0.6,1.6-1.6,1.6-2.7c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1c0,1.2,0.6,2.1,1.6,2.7l0.5-0.9c-0.6-0.4-1-1-1-1.8c0-1.2,0.9-2.1,2.1-2.1C17.2,14.4,18.1,15.4,18.1,16.5z M16,17.5c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1C17,17,16.6,17.5,16,17.5z"
        />
      </g>
    </Icon>
  );
};

/**
 * NetworkInformationIcon Props
 */
export interface NetworkInformationIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
