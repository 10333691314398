import React from 'react';
import clsx from 'clsx';
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';
import { withCoreProps } from '../../withCoreProps';

export const Grid = withCoreProps<GridProps>(
  ({ children, className, ...props }: React.PropsWithChildren<GridProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiGrid {...props} className={clsxClass}>
        {children}
      </MuiGrid>
    );
  },
);

export interface GridProps extends MuiGridProps {}
