/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlInvestors
 */
export interface ControlInvestors {
    /**
     * 
     * @type {string}
     * @memberof ControlInvestors
     */
    investor_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlInvestors
     */
    is_activist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlInvestors
     */
    updated?: string;
}

/**
 * Check if a given object implements the ControlInvestors interface.
 */
export function instanceOfControlInvestors(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlInvestorsFromJSON(json: any): ControlInvestors {
    return ControlInvestorsFromJSONTyped(json, false);
}

export function ControlInvestorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlInvestors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investor_name': !exists(json, 'investor_name') ? undefined : json['investor_name'],
        'is_activist': !exists(json, 'is_activist') ? undefined : json['is_activist'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function ControlInvestorsToJSON(value?: ControlInvestors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investor_name': value.investor_name,
        'is_activist': value.is_activist,
        'updated': value.updated,
    };
}

