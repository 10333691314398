/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRSFSecReviewTriggerNoIssue
 */
export interface CRSFSecReviewTriggerNoIssue {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    cobranded?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    domains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    entity_name?: string;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    extra?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    request_origin?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerNoIssue
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTriggerNoIssue interface.
 */
export function instanceOfCRSFSecReviewTriggerNoIssue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function CRSFSecReviewTriggerNoIssueFromJSON(json: any): CRSFSecReviewTriggerNoIssue {
    return CRSFSecReviewTriggerNoIssueFromJSONTyped(json, false);
}

export function CRSFSecReviewTriggerNoIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTriggerNoIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'domains': json['domains'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSecReviewTriggerNoIssueToJSON(value?: CRSFSecReviewTriggerNoIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cobranded': value.cobranded,
        'domains': value.domains,
        'entity_name': value.entity_name,
        'extra': value.extra,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'ip_addresses': value.ip_addresses,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'request_origin': value.request_origin,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'user': value.user,
    };
}

