/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityUploadImportResponse
 */
export interface MonitEntityUploadImportResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityUploadImportResponse
     */
    import_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityUploadImportResponse
     */
    entity_domain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityUploadImportResponse
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityUploadImportResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityUploadImportResponse
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntityUploadImportResponse interface.
 */
export function instanceOfMonitEntityUploadImportResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityUploadImportResponseFromJSON(json: any): MonitEntityUploadImportResponse {
    return MonitEntityUploadImportResponseFromJSONTyped(json, false);
}

export function MonitEntityUploadImportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityUploadImportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'import_id': !exists(json, 'import_id') ? undefined : json['import_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntityUploadImportResponseToJSON(value?: MonitEntityUploadImportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'import_id': value.import_id,
        'entity_domain': value.entity_domain,
        'status': value.status,
        'created': value.created,
        'updated': value.updated,
    };
}

