/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskExperianLienDetails } from './ExecRiskExperianLienDetails';
import {
    ExecRiskExperianLienDetailsFromJSON,
    ExecRiskExperianLienDetailsFromJSONTyped,
    ExecRiskExperianLienDetailsToJSON,
} from './ExecRiskExperianLienDetails';
import type { ExecRiskExperianTradeDBT } from './ExecRiskExperianTradeDBT';
import {
    ExecRiskExperianTradeDBTFromJSON,
    ExecRiskExperianTradeDBTFromJSONTyped,
    ExecRiskExperianTradeDBTToJSON,
} from './ExecRiskExperianTradeDBT';
import type { NaicsCodes } from './NaicsCodes';
import {
    NaicsCodesFromJSON,
    NaicsCodesFromJSONTyped,
    NaicsCodesToJSON,
} from './NaicsCodes';
import type { SicCodes } from './SicCodes';
import {
    SicCodesFromJSON,
    SicCodesFromJSONTyped,
    SicCodesToJSON,
} from './SicCodes';

/**
 * 
 * @export
 * @interface ExecRiskExperianBusinessEntry
 */
export interface ExecRiskExperianBusinessEntry {
    /**
     * 
     * @type {SicCodes}
     * @memberof ExecRiskExperianBusinessEntry
     */
    sic_codes?: SicCodes;
    /**
     * 
     * @type {NaicsCodes}
     * @memberof ExecRiskExperianBusinessEntry
     */
    naics_codes?: NaicsCodes;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianBusinessEntry
     */
    company_type?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianBusinessEntry
     */
    fsr_score?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianBusinessEntry
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianBusinessEntry
     */
    incorporated?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskExperianBusinessEntry
     */
    public_indicator?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianBusinessEntry
     */
    ticker_symbol?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianBusinessEntry
     */
    stock_exchange?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskExperianBusinessEntry
     */
    non_profit_indicator?: boolean;
    /**
     * 
     * @type {ExecRiskExperianTradeDBT}
     * @memberof ExecRiskExperianBusinessEntry
     */
    days_beyond_terms?: ExecRiskExperianTradeDBT;
    /**
     * 
     * @type {ExecRiskExperianLienDetails}
     * @memberof ExecRiskExperianBusinessEntry
     */
    liens?: ExecRiskExperianLienDetails;
}

/**
 * Check if a given object implements the ExecRiskExperianBusinessEntry interface.
 */
export function instanceOfExecRiskExperianBusinessEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskExperianBusinessEntryFromJSON(json: any): ExecRiskExperianBusinessEntry {
    return ExecRiskExperianBusinessEntryFromJSONTyped(json, false);
}

export function ExecRiskExperianBusinessEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskExperianBusinessEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sic_codes': !exists(json, 'sic_codes') ? undefined : SicCodesFromJSON(json['sic_codes']),
        'naics_codes': !exists(json, 'naics_codes') ? undefined : NaicsCodesFromJSON(json['naics_codes']),
        'company_type': !exists(json, 'company_type') ? undefined : json['company_type'],
        'fsr_score': !exists(json, 'fsr_score') ? undefined : json['fsr_score'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'incorporated': !exists(json, 'incorporated') ? undefined : json['incorporated'],
        'public_indicator': !exists(json, 'public_indicator') ? undefined : json['public_indicator'],
        'ticker_symbol': !exists(json, 'ticker_symbol') ? undefined : json['ticker_symbol'],
        'stock_exchange': !exists(json, 'stock_exchange') ? undefined : json['stock_exchange'],
        'non_profit_indicator': !exists(json, 'non_profit_indicator') ? undefined : json['non_profit_indicator'],
        'days_beyond_terms': !exists(json, 'days_beyond_terms') ? undefined : ExecRiskExperianTradeDBTFromJSON(json['days_beyond_terms']),
        'liens': !exists(json, 'liens') ? undefined : ExecRiskExperianLienDetailsFromJSON(json['liens']),
    };
}

export function ExecRiskExperianBusinessEntryToJSON(value?: ExecRiskExperianBusinessEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sic_codes': SicCodesToJSON(value.sic_codes),
        'naics_codes': NaicsCodesToJSON(value.naics_codes),
        'company_type': value.company_type,
        'fsr_score': value.fsr_score,
        'website': value.website,
        'incorporated': value.incorporated,
        'public_indicator': value.public_indicator,
        'ticker_symbol': value.ticker_symbol,
        'stock_exchange': value.stock_exchange,
        'non_profit_indicator': value.non_profit_indicator,
        'days_beyond_terms': ExecRiskExperianTradeDBTToJSON(value.days_beyond_terms),
        'liens': ExecRiskExperianLienDetailsToJSON(value.liens),
    };
}

