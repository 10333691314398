/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityFindingsAssetsResult } from './MonitEntityFindingsAssetsResult';
import {
    MonitEntityFindingsAssetsResultFromJSON,
    MonitEntityFindingsAssetsResultFromJSONTyped,
    MonitEntityFindingsAssetsResultToJSON,
} from './MonitEntityFindingsAssetsResult';

/**
 * 
 * @export
 * @interface MonitPaginatedEntityFindingsAssetsResponse
 */
export interface MonitPaginatedEntityFindingsAssetsResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsAssetsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsAssetsResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsAssetsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityFindingsAssetsResult>}
     * @memberof MonitPaginatedEntityFindingsAssetsResponse
     */
    results: Array<MonitEntityFindingsAssetsResult>;
}

/**
 * Check if a given object implements the MonitPaginatedEntityFindingsAssetsResponse interface.
 */
export function instanceOfMonitPaginatedEntityFindingsAssetsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function MonitPaginatedEntityFindingsAssetsResponseFromJSON(json: any): MonitPaginatedEntityFindingsAssetsResponse {
    return MonitPaginatedEntityFindingsAssetsResponseFromJSONTyped(json, false);
}

export function MonitPaginatedEntityFindingsAssetsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitPaginatedEntityFindingsAssetsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': ((json['results'] as Array<any>).map(MonitEntityFindingsAssetsResultFromJSON)),
    };
}

export function MonitPaginatedEntityFindingsAssetsResponseToJSON(value?: MonitPaginatedEntityFindingsAssetsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': ((value.results as Array<any>).map(MonitEntityFindingsAssetsResultToJSON)),
    };
}

