import { FormikProps } from 'formik';
import { CheckboxOptions } from './base/FieldCheckbox';
import { RadioOptions } from './base/FieldRadio';
import { SelectOptions } from './base/FieldSelect';
import { FormFieldControlProps } from './base/FormFieldControl';

export interface FormSchemaOptions<FormValues = any> {
  formId: string;
  formik: FormikProps<FormValues>;
  options?: Record<
    string,
    SelectOptions | CheckboxOptions | RadioOptions | Options
  >;
}

export type FormSchemaReturn<FormValues = any> = Record<
  keyof FormValues,
  FormFieldControlProps<FormValues, keyof FormValues>
>;

/**
 * CommonFormProps
 */
export interface CommonFormProps<FormValues> {
  /**
   * `endpoint` - endpoint used to make the request
   */
  endpoint: string;
  /**
   * `prefilledValues` - prefilled values to overwrite the initial values
   */
  prefilledValues?: Partial<FormValues>;
  /**
   * `onSuccess` - callback to be called on success
   */
  onSuccess?: (params: { data: any; error: any; values: any }) => void;
  /**
   * `onError` - callback to be called on error
   */
  onError?: (params: { data: any; error: any; values: any }) => void;
}

export type AssetType = 'domain' | 'ip_address';

interface Attestations {
  attestation: string;
  created: number;
  request_id: string;
  updated: number;
}

export enum ClientRoles {
  PORTAL_INTERNAL = 'portal::internal',
  PORTAL_BINARYEDGE = 'portal::binaryedge',
  PORTAL_SUPERUSER = 'portal::superuser',
  PORTAL_POLICYHOLDER = 'portal::policyholder',
  PORTAL_FREEMIUM = 'portal::freemium',
  PORTAL_BROKER = 'portal::broker',
  PORTAL_PREMIUM = 'portal::premium',
  PORTAL_EXECRISK = 'portal::execrisk',
  PORTAL_MIXED = 'portal::mixed',
  PORTAL_CONTROLUSER = 'portal::controluser',
  PORTAL_MONITOR = 'portal::monitor',
  PORTAL_RISKAGGREGATION = 'portal::riskaggregation',
}
