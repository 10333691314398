/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDecisionsSummary } from './CRSFDecisionsSummary';
import {
    CRSFDecisionsSummaryFromJSON,
    CRSFDecisionsSummaryFromJSONTyped,
    CRSFDecisionsSummaryToJSON,
} from './CRSFDecisionsSummary';
import type { CRSFScanType } from './CRSFScanType';
import {
    CRSFScanTypeFromJSON,
    CRSFScanTypeFromJSONTyped,
    CRSFScanTypeToJSON,
} from './CRSFScanType';

/**
 * 
 * @export
 * @interface CRSFGetTicketResponse
 */
export interface CRSFGetTicketResponse {
    /**
     * 
     * @type {CRSFDecisionsSummary}
     * @memberof CRSFGetTicketResponse
     */
    data?: CRSFDecisionsSummary;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetTicketResponse
     */
    process_id: string;
    /**
     * 
     * @type {CRSFScanType}
     * @memberof CRSFGetTicketResponse
     */
    scan_type: CRSFScanType;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetTicketResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetTicketResponse
     */
    ticket: string;
}

/**
 * Check if a given object implements the CRSFGetTicketResponse interface.
 */
export function instanceOfCRSFGetTicketResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "ticket" in value;

    return isInstance;
}

export function CRSFGetTicketResponseFromJSON(json: any): CRSFGetTicketResponse {
    return CRSFGetTicketResponseFromJSONTyped(json, false);
}

export function CRSFGetTicketResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFGetTicketResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CRSFDecisionsSummaryFromJSON(json['data']),
        'process_id': json['process_id'],
        'scan_type': CRSFScanTypeFromJSON(json['scan_type']),
        'status': json['status'],
        'ticket': json['ticket'],
    };
}

export function CRSFGetTicketResponseToJSON(value?: CRSFGetTicketResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CRSFDecisionsSummaryToJSON(value.data),
        'process_id': value.process_id,
        'scan_type': CRSFScanTypeToJSON(value.scan_type),
        'status': value.status,
        'ticket': value.ticket,
    };
}

