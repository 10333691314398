/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitEntityReportedAssetOrderBy = {
    ExternalId: 'external_id',
    EntityId: 'entity_id',
    Asset: 'asset',
    Status: 'status',
    Reason: 'reason',
    ReasonInternal: 'reason_internal',
    AssetType: 'asset_type',
    Removed: 'removed',
    ReviewedBy: 'reviewed_by',
    Created: 'created',
    Updated: 'updated'
} as const;
export type MonitEntityReportedAssetOrderBy = typeof MonitEntityReportedAssetOrderBy[keyof typeof MonitEntityReportedAssetOrderBy];


export function MonitEntityReportedAssetOrderByFromJSON(json: any): MonitEntityReportedAssetOrderBy {
    return MonitEntityReportedAssetOrderByFromJSONTyped(json, false);
}

export function MonitEntityReportedAssetOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityReportedAssetOrderBy {
    return json as MonitEntityReportedAssetOrderBy;
}

export function MonitEntityReportedAssetOrderByToJSON(value?: MonitEntityReportedAssetOrderBy | null): any {
    return value as any;
}

