/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityDomainsEmailAggData } from './ControlEntityDomainsEmailAggData';
import {
    ControlEntityDomainsEmailAggDataFromJSON,
    ControlEntityDomainsEmailAggDataFromJSONTyped,
    ControlEntityDomainsEmailAggDataToJSON,
} from './ControlEntityDomainsEmailAggData';

/**
 * 
 * @export
 * @interface ControlEntityDomainsEmailAggResponse
 */
export interface ControlEntityDomainsEmailAggResponse {
    /**
     * 
     * @type {ControlEntityDomainsEmailAggData}
     * @memberof ControlEntityDomainsEmailAggResponse
     */
    spf?: ControlEntityDomainsEmailAggData;
    /**
     * 
     * @type {ControlEntityDomainsEmailAggData}
     * @memberof ControlEntityDomainsEmailAggResponse
     */
    without_spf?: ControlEntityDomainsEmailAggData;
    /**
     * 
     * @type {ControlEntityDomainsEmailAggData}
     * @memberof ControlEntityDomainsEmailAggResponse
     */
    dmarc?: ControlEntityDomainsEmailAggData;
    /**
     * 
     * @type {ControlEntityDomainsEmailAggData}
     * @memberof ControlEntityDomainsEmailAggResponse
     */
    without_dmarc?: ControlEntityDomainsEmailAggData;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDomainsEmailAggResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the ControlEntityDomainsEmailAggResponse interface.
 */
export function instanceOfControlEntityDomainsEmailAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityDomainsEmailAggResponseFromJSON(json: any): ControlEntityDomainsEmailAggResponse {
    return ControlEntityDomainsEmailAggResponseFromJSONTyped(json, false);
}

export function ControlEntityDomainsEmailAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDomainsEmailAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spf': !exists(json, 'spf') ? undefined : ControlEntityDomainsEmailAggDataFromJSON(json['spf']),
        'without_spf': !exists(json, 'without_spf') ? undefined : ControlEntityDomainsEmailAggDataFromJSON(json['without_spf']),
        'dmarc': !exists(json, 'dmarc') ? undefined : ControlEntityDomainsEmailAggDataFromJSON(json['dmarc']),
        'without_dmarc': !exists(json, 'without_dmarc') ? undefined : ControlEntityDomainsEmailAggDataFromJSON(json['without_dmarc']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function ControlEntityDomainsEmailAggResponseToJSON(value?: ControlEntityDomainsEmailAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spf': ControlEntityDomainsEmailAggDataToJSON(value.spf),
        'without_spf': ControlEntityDomainsEmailAggDataToJSON(value.without_spf),
        'dmarc': ControlEntityDomainsEmailAggDataToJSON(value.dmarc),
        'without_dmarc': ControlEntityDomainsEmailAggDataToJSON(value.without_dmarc),
        'total': value.total,
    };
}

