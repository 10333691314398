import { ErrorPage } from '@explorer/components/shared';
import { CoalitionLoading } from '@explorer/core/presets';
import { Permissions, useAuth, useUser } from '@explorer/stores';
import React, { PropsWithChildren, memo } from 'react';
import { PageHeadWrapper, PageHeadWrapperProps } from '../PageHeadWrapper';

/**
 * AuthenticationWrapper
 * @description wrapped around main app, handles authentication rendering and calls hook with login
 */
export const AuthenticationWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isLoading } = useAuth();
  const { userAuthenticated } = useUser();

  if (userAuthenticated && !isLoading) {
    return <>{children}</>;
  }

  return <CoalitionLoading />;
};

/**
 * PageAuthenticationWrapper
 * @description handles user permissions on every view wrapped with component.
 */
export const PageAuthenticationWrapper = memo(
  //
  function PageAuthenticationWrapper({
    pageMeta,
    permissions = [],
    userHasAtLeastOnePermission = false,
    children = null,
  }: PageAuthenticationWrapperProps) {
    const { userHasPermissions, userHasSomePermissions, user } = useUser();
    const noPermissions = user.permissions.length === 0;

    const hasPermissions = userHasAtLeastOnePermission
      ? userHasSomePermissions(permissions)
      : userHasPermissions(permissions);

    if (noPermissions) {
      return <ErrorPage error="user-has-no-permissions" />;
    }

    return (
      <PageHeadWrapper pageMeta={pageMeta}>
        {hasPermissions ? (
          <>{children}</>
        ) : (
          <ErrorPage error="user-not-allowed" />
        )}
      </PageHeadWrapper>
    );
  },
);

PageAuthenticationWrapper.displayName = 'PageAuthenticationWrapper';

/**
 * PageAuthenticationWrapperProps
 */
export interface PageAuthenticationWrapperProps extends PageHeadWrapperProps {
  readonly permissions?: Permissions[];
  readonly userHasAtLeastOnePermission?: boolean;
}
