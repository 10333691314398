/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metrics1AnyOfValue } from './Metrics1AnyOfValue';
import {
    Metrics1AnyOfValueFromJSON,
    Metrics1AnyOfValueFromJSONTyped,
    Metrics1AnyOfValueToJSON,
} from './Metrics1AnyOfValue';

/**
 * 
 * @export
 * @interface Metrics1
 */
export interface Metrics1 {
}

/**
 * Check if a given object implements the Metrics1 interface.
 */
export function instanceOfMetrics1(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Metrics1FromJSON(json: any): Metrics1 {
    return Metrics1FromJSONTyped(json, false);
}

export function Metrics1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Metrics1 {
    return json;
}

export function Metrics1ToJSON(value?: Metrics1 | null): any {
    return value;
}

