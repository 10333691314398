/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityHoneypotsData } from './MonitEntityHoneypotsData';
import {
    MonitEntityHoneypotsDataFromJSON,
    MonitEntityHoneypotsDataFromJSONTyped,
    MonitEntityHoneypotsDataToJSON,
} from './MonitEntityHoneypotsData';

/**
 * 
 * @export
 * @interface MonitEntityHoneypotsAggResponse
 */
export interface MonitEntityHoneypotsAggResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityHoneypotsAggResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<MonitEntityHoneypotsData>}
     * @memberof MonitEntityHoneypotsAggResponse
     */
    malicious?: Array<MonitEntityHoneypotsData>;
    /**
     * 
     * @type {Array<MonitEntityHoneypotsData>}
     * @memberof MonitEntityHoneypotsAggResponse
     */
    other?: Array<MonitEntityHoneypotsData>;
}

/**
 * Check if a given object implements the MonitEntityHoneypotsAggResponse interface.
 */
export function instanceOfMonitEntityHoneypotsAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityHoneypotsAggResponseFromJSON(json: any): MonitEntityHoneypotsAggResponse {
    return MonitEntityHoneypotsAggResponseFromJSONTyped(json, false);
}

export function MonitEntityHoneypotsAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityHoneypotsAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'malicious': !exists(json, 'malicious') ? undefined : ((json['malicious'] as Array<any>).map(MonitEntityHoneypotsDataFromJSON)),
        'other': !exists(json, 'other') ? undefined : ((json['other'] as Array<any>).map(MonitEntityHoneypotsDataFromJSON)),
    };
}

export function MonitEntityHoneypotsAggResponseToJSON(value?: MonitEntityHoneypotsAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'malicious': value.malicious === undefined ? undefined : ((value.malicious as Array<any>).map(MonitEntityHoneypotsDataToJSON)),
        'other': value.other === undefined ? undefined : ((value.other as Array<any>).map(MonitEntityHoneypotsDataToJSON)),
    };
}

