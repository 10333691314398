import { getCredentials } from '@explorer/api';
import { V1Api, BaseAPI, Configuration } from '@explorer/open-api-client';
import { useUser } from '@explorer/stores';
import { useMemo } from 'react';

export function useAPIClient<API extends BaseAPI>(args?: {
  clientId: string;
  APIClient: new (config: Configuration) => API;
}) {
  const { clientId, APIClient } = args ?? {};

  useUser();
  const token = getCredentials({ auth: 'bearer' });
  /* Wait until token is available before enabling the query */
  const client = useMemo(() => {
    const headers = {
      'Coalition-App': 'coalition-explorer',
    };

    if (clientId) {
      headers['EXTERNAL-ID'] = clientId;
    }

    /* This is the codegen client that is generated from the openapi spec */
    return new APIClient(
      new Configuration({
        basePath: process.env.API_PORTAL,
        headers,
        accessToken: () => token,
        middleware: [
          {
            post: async (context) => {
              if (!context.response.ok) {
                return await context.response.json();
              }
            },
          },
        ],
      }),
    );
  }, [APIClient, clientId, token]);
  return { client, isReady: !!token };
}

type ReducedClientArgs = Omit<Parameters<typeof useAPIClient>[0], 'APIClient'>;
export function useV1APIClient(args?: ReducedClientArgs) {
  return useAPIClient<V1Api>({ ...args, APIClient: V1Api });
}
