/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
 */
export interface ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
     */
    ptin?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
     */
    phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
     */
    preparation_dt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
     */
    self_employed_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp
     */
    preparer_person_nm?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp interface.
 */
export function instanceOfExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSON(json: any): ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp {
    return ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ptin': !exists(json, 'ptin') ? undefined : json['ptin'],
        'phone_num': !exists(json, 'phone_num') ? undefined : json['phone_num'],
        'preparation_dt': !exists(json, 'preparation_dt') ? undefined : json['preparation_dt'],
        'self_employed_ind': !exists(json, 'self_employed_ind') ? undefined : json['self_employed_ind'],
        'preparer_person_nm': !exists(json, 'preparer_person_nm') ? undefined : json['preparer_person_nm'],
    };
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpToJSON(value?: ExecRiskAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ptin': value.ptin,
        'phone_num': value.phone_num,
        'preparation_dt': value.preparation_dt,
        'self_employed_ind': value.self_employed_ind,
        'preparer_person_nm': value.preparer_person_nm,
    };
}

