/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewScanType } from './CRSFSecReviewScanType';
import {
    CRSFSecReviewScanTypeFromJSON,
    CRSFSecReviewScanTypeFromJSONTyped,
    CRSFSecReviewScanTypeToJSON,
} from './CRSFSecReviewScanType';
import type { CRSFSecReviewStatusType } from './CRSFSecReviewStatusType';
import {
    CRSFSecReviewStatusTypeFromJSON,
    CRSFSecReviewStatusTypeFromJSONTyped,
    CRSFSecReviewStatusTypeToJSON,
} from './CRSFSecReviewStatusType';
import type { CRSFSecReviewThreadResponse } from './CRSFSecReviewThreadResponse';
import {
    CRSFSecReviewThreadResponseFromJSON,
    CRSFSecReviewThreadResponseFromJSONTyped,
    CRSFSecReviewThreadResponseToJSON,
} from './CRSFSecReviewThreadResponse';

/**
 * 
 * @export
 * @interface CRSFSecReviewGetStatusResponse
 */
export interface CRSFSecReviewGetStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    account_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    created: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    domains?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    quote_id?: string;
    /**
     * 
     * @type {CRSFSecReviewScanType}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    scan_type: CRSFSecReviewScanType;
    /**
     * 
     * @type {CRSFSecReviewStatusType}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    status: CRSFSecReviewStatusType;
    /**
     * 
     * @type {Array<CRSFSecReviewThreadResponse>}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    threads: Array<CRSFSecReviewThreadResponse>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewGetStatusResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFSecReviewGetStatusResponse interface.
 */
export function instanceOfCRSFSecReviewGetStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "threads" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFSecReviewGetStatusResponseFromJSON(json: any): CRSFSecReviewGetStatusResponse {
    return CRSFSecReviewGetStatusResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewGetStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewGetStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'created': json['created'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'process_id': json['process_id'],
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'scan_type': CRSFSecReviewScanTypeFromJSON(json['scan_type']),
        'status': CRSFSecReviewStatusTypeFromJSON(json['status']),
        'threads': ((json['threads'] as Array<any>).map(CRSFSecReviewThreadResponseFromJSON)),
        'updated': json['updated'],
    };
}

export function CRSFSecReviewGetStatusResponseToJSON(value?: CRSFSecReviewGetStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'created': value.created,
        'domains': value.domains,
        'elapsed': value.elapsed,
        'external_id': value.external_id,
        'process_id': value.process_id,
        'quote_id': value.quote_id,
        'scan_type': CRSFSecReviewScanTypeToJSON(value.scan_type),
        'status': CRSFSecReviewStatusTypeToJSON(value.status),
        'threads': ((value.threads as Array<any>).map(CRSFSecReviewThreadResponseToJSON)),
        'updated': value.updated,
    };
}

