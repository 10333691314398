/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewTriggerScanIssueResponse
 */
export interface CRSFSecReviewTriggerScanIssueResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerScanIssueResponse
     */
    issue_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerScanIssueResponse
     */
    process_id: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTriggerScanIssueResponse interface.
 */
export function instanceOfCRSFSecReviewTriggerScanIssueResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "issue_key" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSecReviewTriggerScanIssueResponseFromJSON(json: any): CRSFSecReviewTriggerScanIssueResponse {
    return CRSFSecReviewTriggerScanIssueResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewTriggerScanIssueResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTriggerScanIssueResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_key': json['issue_key'],
        'process_id': json['process_id'],
    };
}

export function CRSFSecReviewTriggerScanIssueResponseToJSON(value?: CRSFSecReviewTriggerScanIssueResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_key': value.issue_key,
        'process_id': value.process_id,
    };
}

