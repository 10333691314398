import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * PendingMuteIcon
 */
export const PendingMuteIcon = ({ ...props }: PendingMuteIconProps) => {
  return (
    <Icon label="pending_muted" {...props}>
      <path
        d="M11.9999 11.334V7.16732C11.9999 4.60898 10.6333 2.46732 8.24992 1.90065V1.33398C8.24992 0.642318 7.69159 0.0839844 6.99992 0.0839844C6.30825 0.0839844 5.74992 0.642318 5.74992 1.33398V1.90065C3.35825 2.46732 1.99992 4.60065 1.99992 7.16732V11.334L0.333252 13.0007V13.834H13.6666V13.0007L11.9999 11.334ZM7.83325 11.334H6.16658V9.66732H7.83325V11.334ZM7.83325 8.00065H6.16658V4.66732H7.83325V8.00065ZM6.99992 16.334C7.91658 16.334 8.66658 15.584 8.66658 14.6673H5.33325C5.33325 15.584 6.07492 16.334 6.99992 16.334Z"
        fill="black"
        fillOpacity="0.12"
      />
    </Icon>
  );
};

/**
 * PendingMuteIcon Props
 */
export interface PendingMuteIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor' | 'viewBox'> {}
