/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFVirustotalDetectedURLItem } from './CRSFVirustotalDetectedURLItem';
import {
    CRSFVirustotalDetectedURLItemFromJSON,
    CRSFVirustotalDetectedURLItemFromJSONTyped,
    CRSFVirustotalDetectedURLItemToJSON,
} from './CRSFVirustotalDetectedURLItem';

/**
 * 
 * @export
 * @interface CRSFVirustotalData
 */
export interface CRSFVirustotalData {
    /**
     * 
     * @type {string}
     * @memberof CRSFVirustotalData
     */
    asset: string;
    /**
     * 
     * @type {Array<CRSFVirustotalDetectedURLItem>}
     * @memberof CRSFVirustotalData
     */
    detected_urls?: Array<CRSFVirustotalDetectedURLItem>;
    /**
     * 
     * @type {number}
     * @memberof CRSFVirustotalData
     */
    first_seen?: number;
    /**
     * 
     * @type {object}
     * @memberof CRSFVirustotalData
     */
    hits?: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFVirustotalData
     */
    last_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFVirustotalData
     */
    total_events: number;
}

/**
 * Check if a given object implements the CRSFVirustotalData interface.
 */
export function instanceOfCRSFVirustotalData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "total_events" in value;

    return isInstance;
}

export function CRSFVirustotalDataFromJSON(json: any): CRSFVirustotalData {
    return CRSFVirustotalDataFromJSONTyped(json, false);
}

export function CRSFVirustotalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFVirustotalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'detected_urls': !exists(json, 'detected_urls') ? undefined : ((json['detected_urls'] as Array<any>).map(CRSFVirustotalDetectedURLItemFromJSON)),
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'hits': !exists(json, 'hits') ? undefined : json['hits'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'total_events': json['total_events'],
    };
}

export function CRSFVirustotalDataToJSON(value?: CRSFVirustotalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'detected_urls': value.detected_urls === undefined ? undefined : ((value.detected_urls as Array<any>).map(CRSFVirustotalDetectedURLItemToJSON)),
        'first_seen': value.first_seen,
        'hits': value.hits,
        'last_seen': value.last_seen,
        'total_events': value.total_events,
    };
}

