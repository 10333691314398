/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlGetEntityRelationOrderBy = {
    MonitType: 'monit_type',
    ReportUrl: 'report_url',
    RiskScore: 'risk_score',
    RiskScoreV2: 'risk_score_v2',
    AssetsCount: 'assets_count',
    VulnerabilitiesCount: 'vulnerabilities_count',
    DataleaksCount: 'dataleaks_count',
    HomoglyphsCount: 'homoglyphs_count',
    SinkholeCount: 'sinkhole_count',
    TorrentsCount: 'torrents_count',
    HomoglyphsDeltaCount: 'homoglyphs_delta_count',
    SinkholeDeltaCount: 'sinkhole_delta_count',
    TorrentsDeltaCount: 'torrents_delta_count',
    Created: 'created',
    Updated: 'updated',
    EntityName: 'entity_name',
    Scanned: 'scanned'
} as const;
export type ControlGetEntityRelationOrderBy = typeof ControlGetEntityRelationOrderBy[keyof typeof ControlGetEntityRelationOrderBy];


export function ControlGetEntityRelationOrderByFromJSON(json: any): ControlGetEntityRelationOrderBy {
    return ControlGetEntityRelationOrderByFromJSONTyped(json, false);
}

export function ControlGetEntityRelationOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetEntityRelationOrderBy {
    return json as ControlGetEntityRelationOrderBy;
}

export function ControlGetEntityRelationOrderByToJSON(value?: ControlGetEntityRelationOrderBy | null): any {
    return value as any;
}

