/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDataleak } from './CRSFDataleak';
import {
    CRSFDataleakFromJSON,
    CRSFDataleakFromJSONTyped,
    CRSFDataleakToJSON,
} from './CRSFDataleak';

/**
 * 
 * @export
 * @interface CRSFDataleaksPagedResponse
 */
export interface CRSFDataleaksPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleaksPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleaksPagedResponse
     */
    page_size?: number;
    /**
     * 
     * @type {Array<CRSFDataleak>}
     * @memberof CRSFDataleaksPagedResponse
     */
    results?: Array<CRSFDataleak>;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleaksPagedResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the CRSFDataleaksPagedResponse interface.
 */
export function instanceOfCRSFDataleaksPagedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFDataleaksPagedResponseFromJSON(json: any): CRSFDataleaksPagedResponse {
    return CRSFDataleaksPagedResponseFromJSONTyped(json, false);
}

export function CRSFDataleaksPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDataleaksPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(CRSFDataleakFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function CRSFDataleaksPagedResponseToJSON(value?: CRSFDataleaksPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(CRSFDataleakToJSON)),
        'total': value.total,
    };
}

