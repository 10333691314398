/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAdminResolutionRequestBody
 */
export interface ControlAdminResolutionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestBody
     */
    datapoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminResolutionRequestBody
     */
    attestations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminResolutionRequestBody
     */
    assets?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestBody
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestBody
     */
    staff_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestBody
     */
    reason: string;
}

/**
 * Check if a given object implements the ControlAdminResolutionRequestBody interface.
 */
export function instanceOfControlAdminResolutionRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ControlAdminResolutionRequestBodyFromJSON(json: any): ControlAdminResolutionRequestBody {
    return ControlAdminResolutionRequestBodyFromJSONTyped(json, false);
}

export function ControlAdminResolutionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminResolutionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoint': json['datapoint'],
        'attestations': !exists(json, 'attestations') ? undefined : json['attestations'],
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'staff_email': !exists(json, 'staff_email') ? undefined : json['staff_email'],
        'reason': json['reason'],
    };
}

export function ControlAdminResolutionRequestBodyToJSON(value?: ControlAdminResolutionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoint': value.datapoint,
        'attestations': value.attestations,
        'assets': value.assets,
        'notes': value.notes,
        'staff_email': value.staff_email,
        'reason': value.reason,
    };
}

