import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ScanningIcon
 */
export const ScanningIcon = ({ ...props }: ScanningIconProps) => {
  return (
    <Icon label="rescan_in_progress" {...props}>
      <mask id="path-1-inside-1_273_9589" fill="white">
        <path d="M16.5001 8.99935C16.9603 8.99935 17.3377 8.62532 17.2918 8.16739C17.1449 6.70357 16.6124 5.29933 15.7419 4.10114C14.7075 2.67741 13.2489 1.61769 11.5752 1.07388C9.90153 0.530061 8.09863 0.530062 6.42494 1.07388C4.75124 1.61769 3.29267 2.67741 2.25827 4.10114C1.22387 5.52487 0.666748 7.23952 0.666748 8.99935C0.666748 10.7592 1.22387 12.4738 2.25827 13.8976C3.29267 15.3213 4.75125 16.381 6.42494 16.9248C7.8335 17.3825 9.33356 17.455 10.7711 17.1423C11.2208 17.0445 11.4599 16.57 11.3177 16.1323C11.1755 15.6946 10.7056 15.4606 10.2536 15.5471C9.15366 15.7577 8.01359 15.6886 6.93997 15.3397C5.60101 14.9047 4.43416 14.0569 3.60663 12.9179C2.77911 11.7789 2.33341 10.4072 2.33341 8.99935C2.33341 7.59149 2.77911 6.21977 3.60663 5.08078C4.43415 3.9418 5.60101 3.09403 6.93997 2.65897C8.27892 2.22392 9.72124 2.22392 11.0602 2.65897C12.3991 3.09403 13.566 3.9418 14.3935 5.08078C15.0571 5.99405 15.4751 7.05696 15.6147 8.16816C15.6721 8.6248 16.0398 8.99935 16.5001 8.99935Z" />
      </mask>
      <path
        d="M16.5001 8.99935C16.9603 8.99935 17.3377 8.62532 17.2918 8.16739C17.1449 6.70357 16.6124 5.29933 15.7419 4.10114C14.7075 2.67741 13.2489 1.61769 11.5752 1.07388C9.90153 0.530061 8.09863 0.530062 6.42494 1.07388C4.75124 1.61769 3.29267 2.67741 2.25827 4.10114C1.22387 5.52487 0.666748 7.23952 0.666748 8.99935C0.666748 10.7592 1.22387 12.4738 2.25827 13.8976C3.29267 15.3213 4.75125 16.381 6.42494 16.9248C7.8335 17.3825 9.33356 17.455 10.7711 17.1423C11.2208 17.0445 11.4599 16.57 11.3177 16.1323C11.1755 15.6946 10.7056 15.4606 10.2536 15.5471C9.15366 15.7577 8.01359 15.6886 6.93997 15.3397C5.60101 14.9047 4.43416 14.0569 3.60663 12.9179C2.77911 11.7789 2.33341 10.4072 2.33341 8.99935C2.33341 7.59149 2.77911 6.21977 3.60663 5.08078C4.43415 3.9418 5.60101 3.09403 6.93997 2.65897C8.27892 2.22392 9.72124 2.22392 11.0602 2.65897C12.3991 3.09403 13.566 3.9418 14.3935 5.08078C15.0571 5.99405 15.4751 7.05696 15.6147 8.16816C15.6721 8.6248 16.0398 8.99935 16.5001 8.99935Z"
        stroke="black"
        strokeOpacity="0.12"
        strokeWidth="2"
        mask="url(#path-1-inside-1_273_9589)"
      />
    </Icon>
  );
};

/**
 * ScanningIcon Props
 */
export interface ScanningIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor' | 'viewBox'> {}
