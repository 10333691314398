/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount } from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import {
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSONTyped,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON,
} from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import type { ControlEntityStatsCriticalityCategories } from './ControlEntityStatsCriticalityCategories';
import {
    ControlEntityStatsCriticalityCategoriesFromJSON,
    ControlEntityStatsCriticalityCategoriesFromJSONTyped,
    ControlEntityStatsCriticalityCategoriesToJSON,
} from './ControlEntityStatsCriticalityCategories';
import type { ControlEntityStatsVulnerabilitiesSpecific } from './ControlEntityStatsVulnerabilitiesSpecific';
import {
    ControlEntityStatsVulnerabilitiesSpecificFromJSON,
    ControlEntityStatsVulnerabilitiesSpecificFromJSONTyped,
    ControlEntityStatsVulnerabilitiesSpecificToJSON,
} from './ControlEntityStatsVulnerabilitiesSpecific';
import type { ControlEntityStatsVulnerabilitiesSslV2 } from './ControlEntityStatsVulnerabilitiesSslV2';
import {
    ControlEntityStatsVulnerabilitiesSslV2FromJSON,
    ControlEntityStatsVulnerabilitiesSslV2FromJSONTyped,
    ControlEntityStatsVulnerabilitiesSslV2ToJSON,
} from './ControlEntityStatsVulnerabilitiesSslV2';

/**
 * 
 * @export
 * @interface ControlEntityStatsVulnerabilitiesV2
 */
export interface ControlEntityStatsVulnerabilitiesV2 {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    count: number;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    score_categories?: object;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    criticality?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    criticality_sum?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlEntityStatsCriticalityCategories}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    criticality_categories?: ControlEntityStatsCriticalityCategories;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSslV2}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    ssl?: ControlEntityStatsVulnerabilitiesSslV2;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    encryption?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    web?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    remote?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    storage?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    eol?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    cve?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    dns?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    token?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    exposure?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilitiesV2
     */
    other?: ControlEntityStatsVulnerabilitiesSpecific;
}

/**
 * Check if a given object implements the ControlEntityStatsVulnerabilitiesV2 interface.
 */
export function instanceOfControlEntityStatsVulnerabilitiesV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ControlEntityStatsVulnerabilitiesV2FromJSON(json: any): ControlEntityStatsVulnerabilitiesV2 {
    return ControlEntityStatsVulnerabilitiesV2FromJSONTyped(json, false);
}

export function ControlEntityStatsVulnerabilitiesV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsVulnerabilitiesV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'score_categories': !exists(json, 'score_categories') ? undefined : json['score_categories'],
        'criticality': !exists(json, 'criticality') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['criticality']),
        'criticality_sum': !exists(json, 'criticality_sum') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['criticality_sum']),
        'criticality_categories': !exists(json, 'criticality_categories') ? undefined : ControlEntityStatsCriticalityCategoriesFromJSON(json['criticality_categories']),
        'ssl': !exists(json, 'ssl') ? undefined : ControlEntityStatsVulnerabilitiesSslV2FromJSON(json['ssl']),
        'encryption': !exists(json, 'encryption') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['encryption']),
        'web': !exists(json, 'web') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['web']),
        'remote': !exists(json, 'remote') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['remote']),
        'storage': !exists(json, 'storage') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['storage']),
        'eol': !exists(json, 'eol') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['eol']),
        'cve': !exists(json, 'cve') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['cve']),
        'dns': !exists(json, 'dns') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['dns']),
        'token': !exists(json, 'token') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['token']),
        'exposure': !exists(json, 'exposure') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['exposure']),
        'other': !exists(json, 'other') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['other']),
    };
}

export function ControlEntityStatsVulnerabilitiesV2ToJSON(value?: ControlEntityStatsVulnerabilitiesV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'score_categories': value.score_categories,
        'criticality': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.criticality),
        'criticality_sum': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.criticality_sum),
        'criticality_categories': ControlEntityStatsCriticalityCategoriesToJSON(value.criticality_categories),
        'ssl': ControlEntityStatsVulnerabilitiesSslV2ToJSON(value.ssl),
        'encryption': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.encryption),
        'web': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.web),
        'remote': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.remote),
        'storage': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.storage),
        'eol': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.eol),
        'cve': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.cve),
        'dns': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.dns),
        'token': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.token),
        'exposure': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.exposure),
        'other': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.other),
    };
}

