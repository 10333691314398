/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityReportedAssetOrderBy = {
    ExternalId: 'external_id',
    EntityId: 'entity_id',
    Asset: 'asset',
    Status: 'status',
    Reason: 'reason',
    ReasonInternal: 'reason_internal',
    AssetType: 'asset_type',
    Removed: 'removed',
    ReviewedBy: 'reviewed_by',
    Created: 'created',
    Updated: 'updated'
} as const;
export type ControlEntityReportedAssetOrderBy = typeof ControlEntityReportedAssetOrderBy[keyof typeof ControlEntityReportedAssetOrderBy];


export function ControlEntityReportedAssetOrderByFromJSON(json: any): ControlEntityReportedAssetOrderBy {
    return ControlEntityReportedAssetOrderByFromJSONTyped(json, false);
}

export function ControlEntityReportedAssetOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityReportedAssetOrderBy {
    return json as ControlEntityReportedAssetOrderBy;
}

export function ControlEntityReportedAssetOrderByToJSON(value?: ControlEntityReportedAssetOrderBy | null): any {
    return value as any;
}

