/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventsInner } from './EventsInner';
import {
    EventsInnerFromJSON,
    EventsInnerFromJSONTyped,
    EventsInnerToJSON,
} from './EventsInner';

/**
 * 
 * @export
 * @interface MonitEntitySourcelistsResultEvent
 */
export interface MonitEntitySourcelistsResultEvent {
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResultEvent
     */
    first_seen?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResultEvent
     */
    last_seen?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResultEvent
     */
    subcategory?: string;
    /**
     * 
     * @type {Array<EventsInner>}
     * @memberof MonitEntitySourcelistsResultEvent
     */
    events?: Array<EventsInner>;
}

/**
 * Check if a given object implements the MonitEntitySourcelistsResultEvent interface.
 */
export function instanceOfMonitEntitySourcelistsResultEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySourcelistsResultEventFromJSON(json: any): MonitEntitySourcelistsResultEvent {
    return MonitEntitySourcelistsResultEventFromJSONTyped(json, false);
}

export function MonitEntitySourcelistsResultEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySourcelistsResultEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(EventsInnerFromJSON)),
    };
}

export function MonitEntitySourcelistsResultEventToJSON(value?: MonitEntitySourcelistsResultEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_seen': value.first_seen,
        'last_seen': value.last_seen,
        'subcategory': value.subcategory,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(EventsInnerToJSON)),
    };
}

