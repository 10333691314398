export const formatLongitude = (longtitude: number | undefined) => {
  if (typeof longtitude !== 'number') {
    return '-';
  }
  if (longtitude >= 0) {
    return `${longtitude}° E`;
  }
  if (longtitude < 0) {
    return `${Math.abs(longtitude)}° W`;
  }

  return '-';
};

export const formatLatitude = (latitude: number | undefined) => {
  if (typeof latitude !== 'number') {
    return '-';
  }
  if (latitude >= 0) {
    return `${latitude}° N`;
  }
  if (latitude < 0) {
    return `${Math.abs(latitude)}° S`;
  }

  return '-';
};
