/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalAnswerType } from './SupplementalAnswerType';
import {
    SupplementalAnswerTypeFromJSON,
    SupplementalAnswerTypeFromJSONTyped,
    SupplementalAnswerTypeToJSON,
} from './SupplementalAnswerType';
import type { SupplementalFormSchemaAnswerOption } from './SupplementalFormSchemaAnswerOption';
import {
    SupplementalFormSchemaAnswerOptionFromJSON,
    SupplementalFormSchemaAnswerOptionFromJSONTyped,
    SupplementalFormSchemaAnswerOptionToJSON,
} from './SupplementalFormSchemaAnswerOption';
import type { SupplementalFormSchemaCondition } from './SupplementalFormSchemaCondition';
import {
    SupplementalFormSchemaConditionFromJSON,
    SupplementalFormSchemaConditionFromJSONTyped,
    SupplementalFormSchemaConditionToJSON,
} from './SupplementalFormSchemaCondition';

/**
 * 
 * @export
 * @interface SupplementalFormSchemaQuestion
 */
export interface SupplementalFormSchemaQuestion {
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchemaQuestion
     */
    question_number: string;
    /**
     * 
     * @type {number}
     * @memberof SupplementalFormSchemaQuestion
     */
    question_hierarchy: number;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchemaQuestion
     */
    question_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchemaQuestion
     */
    question_title: string;
    /**
     * 
     * @type {Array<SupplementalAnswerType>}
     * @memberof SupplementalFormSchemaQuestion
     */
    answer_type?: Array<SupplementalAnswerType>;
    /**
     * 
     * @type {Array<SupplementalFormSchemaAnswerOption>}
     * @memberof SupplementalFormSchemaQuestion
     */
    answer_options?: Array<SupplementalFormSchemaAnswerOption> | null;
    /**
     * 
     * @type {SupplementalFormSchemaCondition}
     * @memberof SupplementalFormSchemaQuestion
     */
    condition?: SupplementalFormSchemaCondition | null;
}

/**
 * Check if a given object implements the SupplementalFormSchemaQuestion interface.
 */
export function instanceOfSupplementalFormSchemaQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "question_number" in value;
    isInstance = isInstance && "question_hierarchy" in value;
    isInstance = isInstance && "question_id" in value;
    isInstance = isInstance && "question_title" in value;

    return isInstance;
}

export function SupplementalFormSchemaQuestionFromJSON(json: any): SupplementalFormSchemaQuestion {
    return SupplementalFormSchemaQuestionFromJSONTyped(json, false);
}

export function SupplementalFormSchemaQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFormSchemaQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'question_number': json['question_number'],
        'question_hierarchy': json['question_hierarchy'],
        'question_id': json['question_id'],
        'question_title': json['question_title'],
        'answer_type': !exists(json, 'answer_type') ? undefined : ((json['answer_type'] as Array<any>).map(SupplementalAnswerTypeFromJSON)),
        'answer_options': !exists(json, 'answer_options') ? undefined : (json['answer_options'] === null ? null : (json['answer_options'] as Array<any>).map(SupplementalFormSchemaAnswerOptionFromJSON)),
        'condition': !exists(json, 'condition') ? undefined : SupplementalFormSchemaConditionFromJSON(json['condition']),
    };
}

export function SupplementalFormSchemaQuestionToJSON(value?: SupplementalFormSchemaQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question_number': value.question_number,
        'question_hierarchy': value.question_hierarchy,
        'question_id': value.question_id,
        'question_title': value.question_title,
        'answer_type': value.answer_type === undefined ? undefined : ((value.answer_type as Array<any>).map(SupplementalAnswerTypeToJSON)),
        'answer_options': value.answer_options === undefined ? undefined : (value.answer_options === null ? null : (value.answer_options as Array<any>).map(SupplementalFormSchemaAnswerOptionToJSON)),
        'condition': SupplementalFormSchemaConditionToJSON(value.condition),
    };
}

