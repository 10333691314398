/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlCreateUserVersion = {
    Free: 'free',
    Prem: 'prem',
    Trial: 'trial'
} as const;
export type ControlCreateUserVersion = typeof ControlCreateUserVersion[keyof typeof ControlCreateUserVersion];


export function ControlCreateUserVersionFromJSON(json: any): ControlCreateUserVersion {
    return ControlCreateUserVersionFromJSONTyped(json, false);
}

export function ControlCreateUserVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCreateUserVersion {
    return json as ControlCreateUserVersion;
}

export function ControlCreateUserVersionToJSON(value?: ControlCreateUserVersion | null): any {
    return value as any;
}

