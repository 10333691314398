/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
     */
    AddressLine1Txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
     */
    AddressLine2Txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
     */
    CityNm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
     */
    StateAbbreviationCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress
     */
    ZIPCd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress {
    return ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AddressLine1Txt': !exists(json, 'AddressLine1Txt') ? undefined : json['AddressLine1Txt'],
        'AddressLine2Txt': !exists(json, 'AddressLine2Txt') ? undefined : json['AddressLine2Txt'],
        'CityNm': !exists(json, 'CityNm') ? undefined : json['CityNm'],
        'StateAbbreviationCd': !exists(json, 'StateAbbreviationCd') ? undefined : json['StateAbbreviationCd'],
        'ZIPCd': !exists(json, 'ZIPCd') ? undefined : json['ZIPCd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AddressLine1Txt': value.AddressLine1Txt,
        'AddressLine2Txt': value.AddressLine2Txt,
        'CityNm': value.CityNm,
        'StateAbbreviationCd': value.StateAbbreviationCd,
        'ZIPCd': value.ZIPCd,
    };
}

