/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskGlassdoorReview
 */
export interface ExecRiskGlassdoorReview {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorReview
     */
    review: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorReview
     */
    overall_sentiment?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    positive?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    negative?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    neutral?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorReview
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    count_helpful?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    count_not_helpful?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskGlassdoorReview
     */
    reviewer_rating_of_company?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorReview
     */
    review_date?: string;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskGlassdoorReview
     */
    triggers?: Triggers;
}

/**
 * Check if a given object implements the ExecRiskGlassdoorReview interface.
 */
export function instanceOfExecRiskGlassdoorReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "review" in value;

    return isInstance;
}

export function ExecRiskGlassdoorReviewFromJSON(json: any): ExecRiskGlassdoorReview {
    return ExecRiskGlassdoorReviewFromJSONTyped(json, false);
}

export function ExecRiskGlassdoorReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskGlassdoorReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'review': json['review'],
        'overall_sentiment': !exists(json, 'overall_sentiment') ? undefined : json['overall_sentiment'],
        'positive': !exists(json, 'positive') ? undefined : json['positive'],
        'negative': !exists(json, 'negative') ? undefined : json['negative'],
        'neutral': !exists(json, 'neutral') ? undefined : json['neutral'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'count_helpful': !exists(json, 'count_helpful') ? undefined : json['count_helpful'],
        'count_not_helpful': !exists(json, 'count_not_helpful') ? undefined : json['count_not_helpful'],
        'reviewer_rating_of_company': !exists(json, 'reviewer_rating_of_company') ? undefined : json['reviewer_rating_of_company'],
        'review_date': !exists(json, 'review_date') ? undefined : json['review_date'],
        'triggers': !exists(json, 'triggers') ? undefined : TriggersFromJSON(json['triggers']),
    };
}

export function ExecRiskGlassdoorReviewToJSON(value?: ExecRiskGlassdoorReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'review': value.review,
        'overall_sentiment': value.overall_sentiment,
        'positive': value.positive,
        'negative': value.negative,
        'neutral': value.neutral,
        'title': value.title,
        'count_helpful': value.count_helpful,
        'count_not_helpful': value.count_not_helpful,
        'reviewer_rating_of_company': value.reviewer_rating_of_company,
        'review_date': value.review_date,
        'triggers': TriggersToJSON(value.triggers),
    };
}

