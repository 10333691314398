import { ChipSmallProps } from '@explorer/core';
import { ControlResolutionRequestStatus } from '@explorer/open-api-client';
import { CellChip } from '@explorer/tables/presets/cells';
import React from 'react';

export const REQUEST_STATUS_TO_LABEL_MAP: Record<
  ControlResolutionRequestStatus,
  ChipSmallProps
> = {
  [ControlResolutionRequestStatus.Requested]: {
    label: { id: 'tables-presets.reported' },
    color: 'info',
  },
  [ControlResolutionRequestStatus.Approved]: {
    label: { id: 'tables-presets.approved' },
    color: 'low',
  },
  [ControlResolutionRequestStatus.Rejected]: {
    label: { id: 'tables-presets.rejected' },
    color: 'critical',
  },
  [ControlResolutionRequestStatus.Cancelled]: {
    label: { id: 'tables-presets.cancelled' },
    color: 'high',
  },
  [ControlResolutionRequestStatus.SelfResolved]: {
    label: { id: 'tables-presets.self-resolved' },
    color: 'low',
  },
};

export type VulnRequestStatusChipProps = {
  status: ControlResolutionRequestStatus;
} & Omit<React.ComponentProps<typeof CellChip>, 'label' | 'color'>;

export const VulnRequestStatusChip: React.FC<VulnRequestStatusChipProps> = ({
  status,
  fullWidth,
}: VulnRequestStatusChipProps) => {
  const { color, label } =
    REQUEST_STATUS_TO_LABEL_MAP[status] ??
    REQUEST_STATUS_TO_LABEL_MAP[ControlResolutionRequestStatus.Requested];

  return (
    <CellChip
      variant="bodyS"
      label={label}
      color={color}
      fullWidth={fullWidth}
    />
  );
};
