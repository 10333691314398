/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityAssetsDomainDnsRecordAnswer } from './MonitEntityAssetsDomainDnsRecordAnswer';
import {
    MonitEntityAssetsDomainDnsRecordAnswerFromJSON,
    MonitEntityAssetsDomainDnsRecordAnswerFromJSONTyped,
    MonitEntityAssetsDomainDnsRecordAnswerToJSON,
} from './MonitEntityAssetsDomainDnsRecordAnswer';

/**
 * 
 * @export
 * @interface MonitEntityAssetsDomainDnsRecord
 */
export interface MonitEntityAssetsDomainDnsRecord {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainDnsRecord
     */
    type?: string;
    /**
     * 
     * @type {Array<MonitEntityAssetsDomainDnsRecordAnswer>}
     * @memberof MonitEntityAssetsDomainDnsRecord
     */
    answers?: Array<MonitEntityAssetsDomainDnsRecordAnswer>;
}

/**
 * Check if a given object implements the MonitEntityAssetsDomainDnsRecord interface.
 */
export function instanceOfMonitEntityAssetsDomainDnsRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityAssetsDomainDnsRecordFromJSON(json: any): MonitEntityAssetsDomainDnsRecord {
    return MonitEntityAssetsDomainDnsRecordFromJSONTyped(json, false);
}

export function MonitEntityAssetsDomainDnsRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityAssetsDomainDnsRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'answers': !exists(json, 'answers') ? undefined : ((json['answers'] as Array<any>).map(MonitEntityAssetsDomainDnsRecordAnswerFromJSON)),
    };
}

export function MonitEntityAssetsDomainDnsRecordToJSON(value?: MonitEntityAssetsDomainDnsRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'answers': value.answers === undefined ? undefined : ((value.answers as Array<any>).map(MonitEntityAssetsDomainDnsRecordAnswerToJSON)),
    };
}

