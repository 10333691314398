import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { CoalitionVariant } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import clsx from 'clsx';
import NextLink from 'next/link';
import { ApiClientParams } from '@explorer/api';
import { formatMessage, isExternalUrl, trailingUrl } from '@explorer/helpers';
import { CoreProps } from '../../types';
import { withCoreProps } from '../../withCoreProps';

const useStyles = ({ color }: Partial<TypographyLinkProps>) =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        link: {
          paddingBottom: '2px',
          '&:hover': {
            textDecoration: 'none',
            borderBottomColor:
              color === 'secondary'
                ? palette.secondary.main
                : palette.primary.main,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
          },
        },
        noStyle: {
          '&:hover': {
            textDecoration: 'none',
          },
        },
        linkIcon: {
          width: '10px',
          height: '100%',
          marginLeft: '3px',
          marginBottom: '-1px',
        },
      }),
    { index: 1 },
  )();

export const TypographyLink = withCoreProps<TypographyLinkProps>(
  ({
    href,
    params = {},
    label,
    color,
    title,
    variant = 'body1',
    newTab = false,
    monospace = false,
    linkIcon = undefined,
    children,
    className,
    noStyle = false,
    style,
    ...props
  }: React.PropsWithChildren<TypographyLinkProps>) => {
    const classes = useStyles({ color });
    const clsxClass = clsx(
      `CoalitionTypography-${variant}`,
      noStyle ? classes.noStyle : classes.link,
      className,
    );
    const intl = useIntl();

    const isExternal = useMemo<boolean>(() => {
      return isExternalUrl(href);
    }, [href]);
    const url = useMemo(() => {
      return isExternal ? href : trailingUrl(href, params);
    }, [isExternal, href, params]);

    const content = useMemo(() => {
      if (label && children) {
        // eslint-disable-next-line no-console
        console.warn(
          'Warning: [packages/core/TypographyLink] `children` is overwritten by the usage of a `label`. Please use only one.',
        );
      }

      if (label) return formatMessage(intl, label);

      return children;
    }, [intl, label, children]);

    const linkTitle = useMemo<string>(
      () => formatMessage(intl, title).toString(),
      [intl, title],
    );

    const targetProps = useMemo<Partial<TypographyLinkProps>>(() => {
      return newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
    }, [newTab]);

    let internalLinkComponent = useMemo(
      () =>
        forwardRef(function NextLinkWithRef<PropsWithChildren>(
          { children, ...otherProps },
          ref,
        ) {
          return (
            <NextLink {...otherProps} href={url} passHref ref={ref}>
              {children}
            </NextLink>
          );
        }),
      [url],
    );

    return (
      <MuiLink
        color={color}
        className={clsxClass}
        {...props}
        {...targetProps}
        href={url}
        variant="inherit"
        title={linkTitle || ''}
        style={monospace ? { ...style, fontFamily: 'monospace' } : style}
        {...(!isExternal ? { component: internalLinkComponent } : {})}
      >
        {content}
        {linkIcon === 'external' && <LaunchIcon className={classes.linkIcon} />}
        {linkIcon === 'mailto' && (
          <MailOutlineIcon className={classes.linkIcon} />
        )}
      </MuiLink>
    );
  },
);

export interface TypographyLinkProps
  extends Omit<MuiLinkProps & CoreProps, 'variant' | 'title'> {
  /**
   * `variant` - the typography variation
   */
  variant: CoalitionVariant;
  /**
   * `label` - *OPTIONAL* - string or localized message id
   */
  label?: IntlLabel;
  /**
   * `href` - url used in the link
   */
  href: string;
  /**
   * `title` - title used in the link
   */
  title: IntlLabel;
  /**
   * `params` - *OPTIONAL* - values to be used in the querystring. Default: `false`
   */
  params?: ApiClientParams;
  /**
   * `newTab` - *OPTIONAL* - forces the url to open in a new tab.
   */
  newTab?: boolean;
  /**
   * `linkIcon` - *OPTIONAL* - adds the external icon
   */
  linkIcon?: 'external' | 'mailto';
  /**
   * `monospace` - *OPTIONAL* - forces the font family to be 'monospace'
   */
  monospace?: boolean;
  /**
   * `noStyle` - *OPTIONAL* - removes the style
   */
  noStyle?: boolean;
}
