import React from 'react';
import * as yup from 'yup';
import {
  FieldCheckbox,
  FieldText,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';

/**
 * Form Validation Schema
 */
export const validationSchema = (intl) =>
  yup
    .object({
      mute_asset_reason: yup.string().required(
        intl.formatMessage({
          id: 'forms-errors.required-report-asset-reason',
        }),
      ),
      options: yup.object({
        attestation_legal: yup.boolean(),
      }),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  mute_asset_reason: {
    formId,
    formik,
    name: 'mute_asset_reason',
    renderField: (props) => (
      <FieldText
        {...props}
        label={{ id: 'forms-presets.mute-notifications-reason' }}
      />
    ),
  },
  options: {
    formId,
    formik,
    name: 'options',
    renderField: (props) => (
      <FieldCheckbox
        {...props}
        options={[
          {
            label: { id: 'forms-presets.legal-disclaimer-mute-notifications' },
            name: 'attestation_legal',
          },
        ]}
      />
    ),
  },
});
