import React from 'react';
import { Grid, Skeleton } from '@explorer/core';

/**
 * PieLoadingSkeleton
 */
export const PieLoadingSkeleton = ({}: PieLoadingSkeletonProps) => {
  // const classes = useStyles({});

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Skeleton variant="circular" width={240} height={240} />
      </Grid>
    </Grid>
  );
};

/**
 * PieLoadingSkeleton Props
 */
export interface PieLoadingSkeletonProps {}
