import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  LDSingleKindContext,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useUser } from '@explorer/stores';
import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';

type LaunchDarklyWrapperContextValue = {
  initialized: boolean;
  anonymous: boolean;
  error?: Error;
};

export const LaunchDarklyWrapperContext =
  React.createContext<LaunchDarklyWrapperContextValue>({
    initialized: false,
    anonymous: true,
  });

/**
 * LaunchDarklyWrapper
 */
export const LaunchDarklyWrapper = memo(
  //
  function LaunchDarklyWrapper({
    children = null,
    enabled = true,
  }: LaunchDarklyWrapperProps) {
    const { addError } = useUnsafeDataDogRum();

    const preInitialized = !enabled;

    const [initialized, setInitialized] = useState(preInitialized);
    const [anonymous, setAnonymous] = useState(true);
    const [error, setError] = useState<Error | undefined>(undefined);

    const ldClient = useLDClient();
    const { user } = useUser();

    useEffect(() => {
      let cancelled = false;

      if (!enabled) {
        return;
      }

      if (!ldClient) {
        return;
      }

      ldClient
        .waitForInitialization()
        .then(() => {
          !cancelled && setInitialized(true);
        })
        .catch((e) => {
          const error = new Error('LaunchDarkly failed to initialize');
          error.cause = e;
          addError(error);
          !cancelled && setError(e);
        });

      return () => {
        /* If this component is unmounted before the initialization call returns, we don't want to set the anonymous flag and cause React to throw a warning */
        cancelled = true;
      };
    }, [addError, ldClient]);

    useEffect(() => {
      let cancelled = false;

      if (!ldClient) {
        return;
      }

      if (!enabled) {
        return;
      }

      const currentUser = ldClient.getContext();

      if (!currentUser.anonymous) {
        setAnonymous(false);
        return;
      }

      /* Auth0 user is not fetched yet */
      if (!user?.sub) {
        return;
      }

      /* Create a LaunchDarkly user based on the auth0 user info */
      const ldUser: LDSingleKindContext = {
        kind: 'user',
        name: user?.name,
        email: user?.email,
        key: user?.sub,
      };

      ldClient
        ?.identify(ldUser)
        .then(() => {
          !cancelled && setAnonymous(false);
        })
        .catch((e) => {
          if (cancelled) {
            return;
          }

          const error = new Error('LaunchDarkly failed to retrieve user');
          error.cause = e;
          addError(error, {
            cause: e,
          });
          setAnonymous(true);
          !cancelled && setError(e);
        });

      return () => {
        /* If this component is unmounted before the identify call returns, we don't want to set the anonymous flag and cause React to throw a warning */
        cancelled = true;
      };
    }, [user, ldClient]);

    const contextValue = useMemo(
      () => ({ initialized, anonymous, error }),
      [initialized, anonymous, error],
    );

    return (
      <LaunchDarklyWrapperContext.Provider value={contextValue}>
        {children}
      </LaunchDarklyWrapperContext.Provider>
    );
  },
);

LaunchDarklyWrapper.displayName = 'LaunchDarklyWrapper';

/**
 * LaunchDarklyWrapperProps
 */
export interface LaunchDarklyWrapperProps {
  enabled?: boolean;
  readonly children: JSX.Element[] | JSX.Element | null;
}
