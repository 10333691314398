import React from 'react';
import {
  Grid,
  Typography,
  TypographyProps,
  CopyToClipboard,
} from '@explorer/core';
import { truncateText } from '@explorer/helpers';
import { CellEmpty } from '../CellEmpty';

/**
 * CellCopyText
 */
export const CellCopyText = ({
  text,
  truncateAt,
  variant = 'bodyXS',
  textColor = undefined,
  textTransform = undefined,
  monospace = false,
  ...otherProps
}: CellCopyTextProps) => {
  if (!text) return <CellEmpty variant={variant} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      {...otherProps}
    >
      <Grid item mr={0.5}>
        <CopyToClipboard size="small" copyMe={text} />
      </Grid>
      <Grid item>
        <Typography
          variant={variant}
          monospace={monospace}
          textColor={textColor}
          textTransform={textTransform}
        >
          {truncateAt ? truncateText(text, truncateAt) : text}
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 * CellCopyText Props
 */
export interface CellCopyTextProps
  extends Pick<
    TypographyProps,
    'variant' | 'monospace' | 'textColor' | 'textTransform'
  > {
  text?: string;
  truncateAt?: number;
}
