/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ControlBooleanFieldAnswer,
    instanceOfControlBooleanFieldAnswer,
    ControlBooleanFieldAnswerFromJSON,
    ControlBooleanFieldAnswerFromJSONTyped,
    ControlBooleanFieldAnswerToJSON,
} from './ControlBooleanFieldAnswer';
import {
    ControlMultiSelectFieldAnswer,
    instanceOfControlMultiSelectFieldAnswer,
    ControlMultiSelectFieldAnswerFromJSON,
    ControlMultiSelectFieldAnswerFromJSONTyped,
    ControlMultiSelectFieldAnswerToJSON,
} from './ControlMultiSelectFieldAnswer';
import {
    ControlSingleSelectFieldAnswer,
    instanceOfControlSingleSelectFieldAnswer,
    ControlSingleSelectFieldAnswerFromJSON,
    ControlSingleSelectFieldAnswerFromJSONTyped,
    ControlSingleSelectFieldAnswerToJSON,
} from './ControlSingleSelectFieldAnswer';
import {
    ControlTextFieldAnswer,
    instanceOfControlTextFieldAnswer,
    ControlTextFieldAnswerFromJSON,
    ControlTextFieldAnswerFromJSONTyped,
    ControlTextFieldAnswerToJSON,
} from './ControlTextFieldAnswer';

/**
 * @type FieldAnswer
 * 
 * @export
 */
export type FieldAnswer = { field_type: 'boolean' } & ControlBooleanFieldAnswer | { field_type: 'multiselect' } & ControlMultiSelectFieldAnswer | { field_type: 'singleselect' } & ControlSingleSelectFieldAnswer | { field_type: 'text' } & ControlTextFieldAnswer;

export function FieldAnswerFromJSON(json: any): FieldAnswer {
    return FieldAnswerFromJSONTyped(json, false);
}

export function FieldAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['field_type']) {
        case 'boolean':
            return {...ControlBooleanFieldAnswerFromJSONTyped(json, true), field_type: 'boolean'};
        case 'multiselect':
            return {...ControlMultiSelectFieldAnswerFromJSONTyped(json, true), field_type: 'multiselect'};
        case 'singleselect':
            return {...ControlSingleSelectFieldAnswerFromJSONTyped(json, true), field_type: 'singleselect'};
        case 'text':
            return {...ControlTextFieldAnswerFromJSONTyped(json, true), field_type: 'text'};
        default:
            throw new Error(`No variant of FieldAnswer exists with 'field_type=${json['field_type']}'`);
    }
}

export function FieldAnswerToJSON(value?: FieldAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['field_type']) {
        case 'boolean':
            return ControlBooleanFieldAnswerToJSON(value);
        case 'multiselect':
            return ControlMultiSelectFieldAnswerToJSON(value);
        case 'singleselect':
            return ControlSingleSelectFieldAnswerToJSON(value);
        case 'text':
            return ControlTextFieldAnswerToJSON(value);
        default:
            throw new Error(`No variant of FieldAnswer exists with 'field_type=${value['field_type']}'`);
    }

}

