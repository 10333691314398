/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';

/**
 * 
 * @export
 * @interface MonitGetEntityTechAggQueries
 */
export interface MonitGetEntityTechAggQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityTechAggQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityTechAggQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechAggQueries
     */
    process_id?: string;
    /**
     * 
     * @type {Category}
     * @memberof MonitGetEntityTechAggQueries
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechAggQueries
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechAggQueries
     */
    version?: string;
}

/**
 * Check if a given object implements the MonitGetEntityTechAggQueries interface.
 */
export function instanceOfMonitGetEntityTechAggQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityTechAggQueriesFromJSON(json: any): MonitGetEntityTechAggQueries {
    return MonitGetEntityTechAggQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityTechAggQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityTechAggQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function MonitGetEntityTechAggQueriesToJSON(value?: MonitGetEntityTechAggQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'process_id': value.process_id,
        'category': CategoryToJSON(value.category),
        'name': value.name,
        'version': value.version,
    };
}

