/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsName1 } from './AsName1';
import {
    AsName1FromJSON,
    AsName1FromJSONTyped,
    AsName1ToJSON,
} from './AsName1';
import type { Category1 } from './Category1';
import {
    Category1FromJSON,
    Category1FromJSONTyped,
    Category1ToJSON,
} from './Category1';

/**
 * 
 * @export
 * @interface ControlBodyGetAssetsAsmAssetsGet
 */
export interface ControlBodyGetAssetsAsmAssetsGet {
    /**
     * 
     * @type {Category1}
     * @memberof ControlBodyGetAssetsAsmAssetsGet
     */
    category?: Category1;
    /**
     * 
     * @type {AsName1}
     * @memberof ControlBodyGetAssetsAsmAssetsGet
     */
    as_name?: AsName1;
}

/**
 * Check if a given object implements the ControlBodyGetAssetsAsmAssetsGet interface.
 */
export function instanceOfControlBodyGetAssetsAsmAssetsGet(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlBodyGetAssetsAsmAssetsGetFromJSON(json: any): ControlBodyGetAssetsAsmAssetsGet {
    return ControlBodyGetAssetsAsmAssetsGetFromJSONTyped(json, false);
}

export function ControlBodyGetAssetsAsmAssetsGetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlBodyGetAssetsAsmAssetsGet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : Category1FromJSON(json['category']),
        'as_name': !exists(json, 'as_name') ? undefined : AsName1FromJSON(json['as_name']),
    };
}

export function ControlBodyGetAssetsAsmAssetsGetToJSON(value?: ControlBodyGetAssetsAsmAssetsGet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': Category1ToJSON(value.category),
        'as_name': AsName1ToJSON(value.as_name),
    };
}

