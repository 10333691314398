/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';
import type { ControlResolutionTypes } from './ControlResolutionTypes';
import {
    ControlResolutionTypesFromJSON,
    ControlResolutionTypesFromJSONTyped,
    ControlResolutionTypesToJSON,
} from './ControlResolutionTypes';

/**
 * 
 * @export
 * @interface ControlEntityFindingsResult
 */
export interface ControlEntityFindingsResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    risk?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityFindingsResult
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityFindingsResult
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    port?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityFindingsResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    first_detected?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    score_category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    datapoint?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof ControlEntityFindingsResult
     */
    evidences?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityFindingsResult
     */
    risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    finding_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    monit_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityFindingsResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    muted_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsResult
     */
    scan_status?: string;
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlEntityFindingsResult
     */
    resolution_status?: ControlResolutionRequestStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityFindingsResult
     */
    is_contingency?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof ControlEntityFindingsResult
     */
    matches?: Array<object>;
    /**
     * 
     * @type {Array<ControlResolutionTypes>}
     * @memberof ControlEntityFindingsResult
     */
    resolution_types?: Array<ControlResolutionTypes>;
}

/**
 * Check if a given object implements the ControlEntityFindingsResult interface.
 */
export function instanceOfControlEntityFindingsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityFindingsResultFromJSON(json: any): ControlEntityFindingsResult {
    return ControlEntityFindingsResultFromJSONTyped(json, false);
}

export function ControlEntityFindingsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityFindingsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'risk': !exists(json, 'risk') ? undefined : json['risk'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'first_detected': !exists(json, 'first_detected') ? undefined : json['first_detected'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'evidences': !exists(json, 'evidences') ? undefined : json['evidences'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'finding_id': !exists(json, 'finding_id') ? undefined : json['finding_id'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'monit_type': !exists(json, 'monit_type') ? undefined : json['monit_type'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'muted_status': !exists(json, 'muted_status') ? undefined : json['muted_status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : json['scan_status'],
        'resolution_status': !exists(json, 'resolution_status') ? undefined : ControlResolutionRequestStatusFromJSON(json['resolution_status']),
        'is_contingency': !exists(json, 'is_contingency') ? undefined : json['is_contingency'],
        'matches': !exists(json, 'matches') ? undefined : json['matches'],
        'resolution_types': !exists(json, 'resolution_types') ? undefined : ((json['resolution_types'] as Array<any>).map(ControlResolutionTypesFromJSON)),
    };
}

export function ControlEntityFindingsResultToJSON(value?: ControlEntityFindingsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'description': value.description,
        'risk': value.risk,
        'recommendation': value.recommendation,
        'references': value.references,
        'asset': value.asset,
        'source_asset': value.source_asset,
        'port': value.port,
        'shared': value.shared,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
        'first_detected': value.first_detected,
        'level': value.level,
        'category': value.category,
        'score_category': value.score_category,
        'datapoint': value.datapoint,
        'evidences': value.evidences,
        'risk_score': value.risk_score,
        'status': value.status,
        'finding_id': value.finding_id,
        'process_id': value.process_id,
        'monit_type': value.monit_type,
        'tags': value.tags,
        'muted_status': value.muted_status,
        'scan_status': value.scan_status,
        'resolution_status': ControlResolutionRequestStatusToJSON(value.resolution_status),
        'is_contingency': value.is_contingency,
        'matches': value.matches,
        'resolution_types': value.resolution_types === undefined ? undefined : ((value.resolution_types as Array<any>).map(ControlResolutionTypesToJSON)),
    };
}

