import React from 'react';
import clsx from 'clsx';
import MuiInputAdornment, {
  InputAdornmentProps as MuiInputAdornmentProps,
} from '@mui/material/InputAdornment';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const InputAdornment = withCoreProps<InputAdornmentProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<InputAdornmentProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiInputAdornment {...props} className={clsxClass}>
        {children}
      </MuiInputAdornment>
    );
  },
);

export interface InputAdornmentProps
  extends MuiInputAdornmentProps,
    CoreProps {}
