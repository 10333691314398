/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityTorrentsOrderBy = {
    EntityId: 'entity_id',
    Asset: 'asset',
    SourceAsset: 'source_asset',
    Name: 'name',
    Infohash: 'infohash',
    Shared: 'shared',
    Found: 'found',
    Created: 'created',
    Updated: 'updated',
    Category: 'category'
} as const;
export type ControlEntityTorrentsOrderBy = typeof ControlEntityTorrentsOrderBy[keyof typeof ControlEntityTorrentsOrderBy];


export function ControlEntityTorrentsOrderByFromJSON(json: any): ControlEntityTorrentsOrderBy {
    return ControlEntityTorrentsOrderByFromJSONTyped(json, false);
}

export function ControlEntityTorrentsOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityTorrentsOrderBy {
    return json as ControlEntityTorrentsOrderBy;
}

export function ControlEntityTorrentsOrderByToJSON(value?: ControlEntityTorrentsOrderBy | null): any {
    return value as any;
}

