/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDataItem } from './CRSFDataItem';
import {
    CRSFDataItemFromJSON,
    CRSFDataItemFromJSONTyped,
    CRSFDataItemToJSON,
} from './CRSFDataItem';
import type { CRSFOriginItem } from './CRSFOriginItem';
import {
    CRSFOriginItemFromJSON,
    CRSFOriginItemFromJSONTyped,
    CRSFOriginItemToJSON,
} from './CRSFOriginItem';
import type { CRSFTargetItem } from './CRSFTargetItem';
import {
    CRSFTargetItemFromJSON,
    CRSFTargetItemFromJSONTyped,
    CRSFTargetItemToJSON,
} from './CRSFTargetItem';
import type { CRSFTargetMetadata } from './CRSFTargetMetadata';
import {
    CRSFTargetMetadataFromJSON,
    CRSFTargetMetadataFromJSONTyped,
    CRSFTargetMetadataToJSON,
} from './CRSFTargetMetadata';

/**
 * 
 * @export
 * @interface CRSFScanEventRecord
 */
export interface CRSFScanEventRecord {
    /**
     * 
     * @type {CRSFOriginItem}
     * @memberof CRSFScanEventRecord
     */
    origin: CRSFOriginItem;
    /**
     * 
     * @type {CRSFDataItem}
     * @memberof CRSFScanEventRecord
     */
    result: CRSFDataItem;
    /**
     * 
     * @type {CRSFTargetItem}
     * @memberof CRSFScanEventRecord
     */
    target: CRSFTargetItem;
    /**
     * 
     * @type {CRSFTargetMetadata}
     * @memberof CRSFScanEventRecord
     */
    target_metadata?: CRSFTargetMetadata;
}

/**
 * Check if a given object implements the CRSFScanEventRecord interface.
 */
export function instanceOfCRSFScanEventRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "target" in value;

    return isInstance;
}

export function CRSFScanEventRecordFromJSON(json: any): CRSFScanEventRecord {
    return CRSFScanEventRecordFromJSONTyped(json, false);
}

export function CRSFScanEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFScanEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'origin': CRSFOriginItemFromJSON(json['origin']),
        'result': CRSFDataItemFromJSON(json['result']),
        'target': CRSFTargetItemFromJSON(json['target']),
        'target_metadata': !exists(json, 'target_metadata') ? undefined : CRSFTargetMetadataFromJSON(json['target_metadata']),
    };
}

export function CRSFScanEventRecordToJSON(value?: CRSFScanEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'origin': CRSFOriginItemToJSON(value.origin),
        'result': CRSFDataItemToJSON(value.result),
        'target': CRSFTargetItemToJSON(value.target),
        'target_metadata': CRSFTargetMetadataToJSON(value.target_metadata),
    };
}

