type RiskKeys = 'LOW' | 'LOW_MEDIUM' | 'MEDIUM' | 'MEDIUM_HIGH' | 'HIGH';

const FSR_SCORE_RATINGS: Record<RiskKeys, FSRScoreRating> = {
  LOW: {
    riskClass: 1,
    riskLabel: 'Low',
    riskPalette: 'low',
  },
  LOW_MEDIUM: {
    riskClass: 2,
    riskLabel: 'Low-Medium',
    riskPalette: 'medium',
  },
  MEDIUM: {
    riskClass: 3,
    riskLabel: 'Medium',
    riskPalette: 'medium',
  },
  MEDIUM_HIGH: {
    riskClass: 4,
    riskLabel: 'Medium-High',
    riskPalette: 'high',
  },
  HIGH: {
    riskClass: 5,
    riskLabel: 'High',
    riskPalette: 'high',
  },
} as const;

/**
 * Get the FSR (Financial Stability Rating Score) risk rating.
 *
 * __> Example:__
 * ```ts
 * getFSRScoreRating(1);
 * // { riskClass: 5, riskLabel: 'High', riskPalette: 'high' }
 * ```
 */
export const getFSRScoreRating: GetFSRScoreRating = (fsr) => {
  // 1 - 3
  if (fsr <= 3) return FSR_SCORE_RATINGS.HIGH;

  // 4 - 10
  if (fsr <= 10) return FSR_SCORE_RATINGS.MEDIUM_HIGH;

  // 11 - 30
  if (fsr <= 30) return FSR_SCORE_RATINGS.MEDIUM;

  // 31 - 66
  if (fsr <= 66) return FSR_SCORE_RATINGS.LOW_MEDIUM;

  // 67 - 100
  return FSR_SCORE_RATINGS.LOW;
};

type GetFSRScoreRating = (fsr: number) => FSRScoreRating;

interface FSRScoreRating {
  riskClass: RiskClass;
  riskLabel: RiskLabel;
  riskPalette: RiskPalette;
}

type RiskClass = 1 | 2 | 3 | 4 | 5;

type RiskLabel = 'Low' | 'Low-Medium' | 'Medium' | 'Medium-High' | 'High';

type RiskPalette = 'low' | 'medium' | 'high';
