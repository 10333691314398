/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalRecipientLog } from './SupplementalRecipientLog';
import {
    SupplementalRecipientLogFromJSON,
    SupplementalRecipientLogFromJSONTyped,
    SupplementalRecipientLogToJSON,
} from './SupplementalRecipientLog';

/**
 * 
 * @export
 * @interface SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf
 */
export interface SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf {
    /**
     * 
     * @type {Array<SupplementalRecipientLog>}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf
     */
    recipient_logs?: Array<SupplementalRecipientLog>;
}

/**
 * Check if a given object implements the SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf interface.
 */
export function instanceOfSupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOfFromJSON(json: any): SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf {
    return SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOfFromJSONTyped(json, false);
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient_logs': !exists(json, 'recipient_logs') ? undefined : ((json['recipient_logs'] as Array<any>).map(SupplementalRecipientLogFromJSON)),
    };
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOfToJSON(value?: SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_logs': value.recipient_logs === undefined ? undefined : ((value.recipient_logs as Array<any>).map(SupplementalRecipientLogToJSON)),
    };
}

