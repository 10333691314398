import React from 'react';
import clsx from 'clsx';
import MuiFormGroup, {
  FormGroupProps as MuiFormGroupProps,
} from '@mui/material/FormGroup';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const FormGroup = withCoreProps<FormGroupProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<FormGroupProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiFormGroup {...props} className={clsxClass}>
        {children}
      </MuiFormGroup>
    );
  },
);

export interface FormGroupProps extends MuiFormGroupProps, CoreProps {}
