/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityRevokeResponse
 */
export interface MonitEntityRevokeResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_enum?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_enum_dns?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_scan?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_snap?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_dataleaks?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_torrents?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_sinkhole?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_homoglyphs?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_homoglyphs_enrich?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_homoglyphs_compare?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_secchecks_scan?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_techstack_scan?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_summary_scan?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_secchecks_snap?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_techstack_snap?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_summary_snap?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityRevokeResponse
     */
    active_scan_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRevokeResponse
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRevokeResponse
     */
    updated?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_keywords?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_pastebin?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityRevokeResponse
     */
    backedup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_snapscan?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityRevokeResponse
     */
    passive_as_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRevokeResponse
     */
    status_sourcelists?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRevokeResponse
     */
    history_index?: number;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRevokeResponse
     */
    partial_scan_ids?: object;
}

/**
 * Check if a given object implements the MonitEntityRevokeResponse interface.
 */
export function instanceOfMonitEntityRevokeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityRevokeResponseFromJSON(json: any): MonitEntityRevokeResponse {
    return MonitEntityRevokeResponseFromJSONTyped(json, false);
}

export function MonitEntityRevokeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRevokeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'status_enum': !exists(json, 'status_enum') ? undefined : json['status_enum'],
        'status_enum_dns': !exists(json, 'status_enum_dns') ? undefined : json['status_enum_dns'],
        'status_scan': !exists(json, 'status_scan') ? undefined : json['status_scan'],
        'status_snap': !exists(json, 'status_snap') ? undefined : json['status_snap'],
        'status_dataleaks': !exists(json, 'status_dataleaks') ? undefined : json['status_dataleaks'],
        'status_torrents': !exists(json, 'status_torrents') ? undefined : json['status_torrents'],
        'status_sinkhole': !exists(json, 'status_sinkhole') ? undefined : json['status_sinkhole'],
        'status_homoglyphs': !exists(json, 'status_homoglyphs') ? undefined : json['status_homoglyphs'],
        'status_homoglyphs_enrich': !exists(json, 'status_homoglyphs_enrich') ? undefined : json['status_homoglyphs_enrich'],
        'status_homoglyphs_compare': !exists(json, 'status_homoglyphs_compare') ? undefined : json['status_homoglyphs_compare'],
        'status_secchecks_scan': !exists(json, 'status_secchecks_scan') ? undefined : json['status_secchecks_scan'],
        'status_techstack_scan': !exists(json, 'status_techstack_scan') ? undefined : json['status_techstack_scan'],
        'status_summary_scan': !exists(json, 'status_summary_scan') ? undefined : json['status_summary_scan'],
        'status_secchecks_snap': !exists(json, 'status_secchecks_snap') ? undefined : json['status_secchecks_snap'],
        'status_techstack_snap': !exists(json, 'status_techstack_snap') ? undefined : json['status_techstack_snap'],
        'status_summary_snap': !exists(json, 'status_summary_snap') ? undefined : json['status_summary_snap'],
        'active_scan_ids': !exists(json, 'active_scan_ids') ? undefined : json['active_scan_ids'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'status_keywords': !exists(json, 'status_keywords') ? undefined : json['status_keywords'],
        'status_pastebin': !exists(json, 'status_pastebin') ? undefined : json['status_pastebin'],
        'backedup': !exists(json, 'backedup') ? undefined : json['backedup'],
        'status_snapscan': !exists(json, 'status_snapscan') ? undefined : json['status_snapscan'],
        'passive_as_active': !exists(json, 'passive_as_active') ? undefined : json['passive_as_active'],
        'status_sourcelists': !exists(json, 'status_sourcelists') ? undefined : json['status_sourcelists'],
        'history_index': !exists(json, 'history_index') ? undefined : json['history_index'],
        'partial_scan_ids': !exists(json, 'partial_scan_ids') ? undefined : json['partial_scan_ids'],
    };
}

export function MonitEntityRevokeResponseToJSON(value?: MonitEntityRevokeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'entity_id': value.entity_id,
        'status_enum': value.status_enum,
        'status_enum_dns': value.status_enum_dns,
        'status_scan': value.status_scan,
        'status_snap': value.status_snap,
        'status_dataleaks': value.status_dataleaks,
        'status_torrents': value.status_torrents,
        'status_sinkhole': value.status_sinkhole,
        'status_homoglyphs': value.status_homoglyphs,
        'status_homoglyphs_enrich': value.status_homoglyphs_enrich,
        'status_homoglyphs_compare': value.status_homoglyphs_compare,
        'status_secchecks_scan': value.status_secchecks_scan,
        'status_techstack_scan': value.status_techstack_scan,
        'status_summary_scan': value.status_summary_scan,
        'status_secchecks_snap': value.status_secchecks_snap,
        'status_techstack_snap': value.status_techstack_snap,
        'status_summary_snap': value.status_summary_snap,
        'active_scan_ids': value.active_scan_ids,
        'created': value.created,
        'updated': value.updated,
        'status_keywords': value.status_keywords,
        'status_pastebin': value.status_pastebin,
        'backedup': value.backedup,
        'status_snapscan': value.status_snapscan,
        'passive_as_active': value.passive_as_active,
        'status_sourcelists': value.status_sourcelists,
        'history_index': value.history_index,
        'partial_scan_ids': value.partial_scan_ids,
    };
}

