import React from 'react';
import {
  Grid,
  Typography,
  TypographyProps,
  TypographyLink,
  CopyToClipboard,
} from '@explorer/core';
import { truncateText } from '@explorer/helpers';
import { CellEmpty } from '../CellEmpty';
import { GotoCompanyProps } from '../../../types';

/**
 * CellCompany
 */
export const CellCompany = ({
  companyId,
  companyName,
  clientId,
  truncateAt,
  variant = 'bodyXS',
  gotoCompanyProps,
}: CellCompanyProps) => {
  if (!companyName || !companyId) return <CellEmpty variant={variant} />;

  if (gotoCompanyProps) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item mr={0.5}>
          <CopyToClipboard size="small" copyMe={companyName} />
        </Grid>
        <Grid item>
          <TypographyLink
            variant={variant}
            title={{
              id: 'tables-presets.goto-company',
              values: { company: companyName },
            }}
            params={{ id: companyId, cid: clientId }}
            onClick={(e: any) => e.stopPropagation()}
            {...gotoCompanyProps()}
          >
            {truncateAt ? truncateText(companyName, truncateAt) : companyName}
          </TypographyLink>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item mr={0.5}>
        <CopyToClipboard size="small" copyMe={companyName} />
      </Grid>
      <Grid item>
        <Typography variant={variant}>
          {truncateAt ? truncateText(companyName, truncateAt) : companyName}
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 * CellCompany Props
 */
export interface CellCompanyProps extends Pick<TypographyProps, 'variant'> {
  companyId?: string;
  clientId?: string;
  companyName?: string;
  truncateAt?: number;
  gotoCompanyProps?: GotoCompanyProps;
}
