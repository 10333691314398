/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlUserFilterCode = {
    Marketo: 'MARKETO',
    SecurityAlert: 'SECURITY_ALERT',
    ControlTrialReminder: 'CONTROL_TRIAL_REMINDER',
    BrokerAlert: 'BROKER_ALERT'
} as const;
export type ControlUserFilterCode = typeof ControlUserFilterCode[keyof typeof ControlUserFilterCode];


export function ControlUserFilterCodeFromJSON(json: any): ControlUserFilterCode {
    return ControlUserFilterCodeFromJSONTyped(json, false);
}

export function ControlUserFilterCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserFilterCode {
    return json as ControlUserFilterCode;
}

export function ControlUserFilterCodeToJSON(value?: ControlUserFilterCode | null): any {
    return value as any;
}

