import { GradientNames, Gradient } from './types';

import { COLD_GRADIENT } from './gradients/COLD';
import { HEAT_GRADIENT } from './gradients/HEAT';
import { PURPLE_GRADIENT } from './gradients/PURPLE';
import { RISK_GRADIENT } from './gradients/RISK';
import { RISKV2_GRADIENT } from './gradients/RISKV2';

export const INTERVALS: number[] = [
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
];

export const GRADIENTS: Record<GradientNames, Gradient> = {
  cold: COLD_GRADIENT,
  heat: HEAT_GRADIENT,
  purple: PURPLE_GRADIENT,
  risk: RISK_GRADIENT,
  riskV2: RISKV2_GRADIENT,
};

/**
 * Returns the either the `linear-gradient` string or the specific color in that interval.
 *
 * __> Example:__
 * ```ts
 * getGradient('risk');
 * // 'linear-gradient(90deg, #19E98F 0%, #FFE865 50.36%, #FA4343 100%)'
 *
 * getGradient('risk', 90);
 * // '#fb5c48'
 * ```
 */
export const getGradient: GetGradient = (name, value, reverse) => {
  if (typeof value !== 'number') return GRADIENTS[name].css;

  const internalValue = !reverse ? value : 100 - value;

  for (let i = 0, ii = INTERVALS.length; i < ii; i++) {
    if (internalValue < INTERVALS[i]) return GRADIENTS[name].colors[i];
  }

  return GRADIENTS[name].colors[INTERVALS.length - 1];
};

type GetGradient = (
  name: GradientNames,
  value?: number,
  reverse?: boolean,
) => string;
