/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFStatsResponse
 */
export interface CRSFStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    assets_count: number;
    /**
     * 
     * @type {object}
     * @memberof CRSFStatsResponse
     */
    claims_benchmark: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    dataleaks_count: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    peer_average?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFStatsResponse
     */
    process_id: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    risk_ranking?: number;
    /**
     * 
     * @type {object}
     * @memberof CRSFStatsResponse
     */
    risk_score_v2: object;
    /**
     * 
     * @type {object}
     * @memberof CRSFStatsResponse
     */
    surface: object;
    /**
     * 
     * @type {object}
     * @memberof CRSFStatsResponse
     */
    vulnerabilities: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFStatsResponse
     */
    vulnerabilities_count: number;
}

/**
 * Check if a given object implements the CRSFStatsResponse interface.
 */
export function instanceOfCRSFStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets_count" in value;
    isInstance = isInstance && "claims_benchmark" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "dataleaks_count" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "risk_score_v2" in value;
    isInstance = isInstance && "surface" in value;
    isInstance = isInstance && "vulnerabilities" in value;
    isInstance = isInstance && "vulnerabilities_count" in value;

    return isInstance;
}

export function CRSFStatsResponseFromJSON(json: any): CRSFStatsResponse {
    return CRSFStatsResponseFromJSONTyped(json, false);
}

export function CRSFStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets_count': json['assets_count'],
        'claims_benchmark': json['claims_benchmark'],
        'created': json['created'],
        'dataleaks_count': json['dataleaks_count'],
        'peer_average': !exists(json, 'peer_average') ? undefined : json['peer_average'],
        'process_id': json['process_id'],
        'risk_ranking': !exists(json, 'risk_ranking') ? undefined : json['risk_ranking'],
        'risk_score_v2': json['risk_score_v2'],
        'surface': json['surface'],
        'vulnerabilities': json['vulnerabilities'],
        'vulnerabilities_count': json['vulnerabilities_count'],
    };
}

export function CRSFStatsResponseToJSON(value?: CRSFStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets_count': value.assets_count,
        'claims_benchmark': value.claims_benchmark,
        'created': value.created,
        'dataleaks_count': value.dataleaks_count,
        'peer_average': value.peer_average,
        'process_id': value.process_id,
        'risk_ranking': value.risk_ranking,
        'risk_score_v2': value.risk_score_v2,
        'surface': value.surface,
        'vulnerabilities': value.vulnerabilities,
        'vulnerabilities_count': value.vulnerabilities_count,
    };
}

