import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  List,
  ListItem,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Collapse,
  TypographyDate,
} from '@explorer/core/base';
import React, { useState } from 'react';
import { ActiveUserIcon } from '@explorer/icons';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ClassKeyOfStyles } from '@mui/styles';
import { appConfig } from '@explorer/src';

const AuthUsersList = ({
  authRoles = [],
  classes,
}: {
  authRoles: APIExplorerClientAssociatedUser['auth0_roles'];
  classes: Partial<ClassKeyOfStyles<any>>;
}) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const authRolesSize = authRoles?.length;
  const { formatMessage } = useIntl();

  if (!authRolesSize) {
    return (
      <Typography variant="bodyS" my={1} fontWeight="bold">
        {formatMessage({ id: 'admin.auth0-roles' })} ({authRolesSize})
      </Typography>
    );
  }

  return (
    <List disablePadding>
      <ListItem style={{ padding: 0 }} onClick={() => setOpenList(!openList)}>
        <Typography variant="bodyS" my={1} fontWeight="bold">
          {formatMessage({ id: 'admin.auth0-roles' })} ({authRolesSize})
        </Typography>
        {openList ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openList} timeout="auto" unmountOnExit>
        <List disablePadding classes={classes.scroll}>
          <ListItem alignItems="center" divider>
            <ListItem>
              <Typography variant="bodyS" fontWeight="bold">
                {formatMessage({ id: 'global.role' })}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="bodyS" fontWeight="bold">
                {formatMessage({ id: 'global.description' })}
              </Typography>
            </ListItem>
          </ListItem>
          {authRoles.map((role) => (
            <ListItem key={uuidv4()} divider>
              <ListItem>
                <Typography variant="bodyS">{role.name}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="bodyS">{role.description}</Typography>
              </ListItem>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};
/**
 * AssociatedUserModal
 */
export const AssociatedUserModal = ({
  user = {},
  open,
  onClose,
  classes,
}: AssociatedUserModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      stopPropagation
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="subtitleL"
              textTransform="uppercase"
              color="textSecondary"
              label={{ id: 'tables-presets.adminusers-summary-title' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box textAlign="right">
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent p={2}>
        <Box m={2}>
          <Typography variant="bodyS" my={1}>
            <b>{formatMessage({ id: 'global.email' })}: </b>
            {user?.email}
          </Typography>
          <Typography variant="bodyS" my={1}>
            <b>{formatMessage({ id: 'global.active' })}: </b>
            <ActiveUserIcon active={user.is_active} />
          </Typography>
          <Typography variant="bodyS" my={1}>
            <b>{formatMessage({ id: 'global.created-at' })}: </b>
            {user.created_at ? (
              <TypographyDate
                variant="bodyXS"
                date={user.created_at}
                format={appConfig.FORMATS.DATE}
              />
            ) : (
              formatMessage({ id: 'global.unknown' })
            )}
          </Typography>
          <Typography variant="bodyS" my={1}>
            <b>{formatMessage({ id: 'global.updated-at' })}: </b>
            {user.updated_at ? (
              <TypographyDate
                variant="bodyXS"
                date={user.updated_at}
                format={appConfig.FORMATS.DATE}
              />
            ) : (
              formatMessage({ id: 'global.unknown' })
            )}
          </Typography>
          <Typography variant="bodyS" my={1}>
            <b>{formatMessage({ id: 'global.last-login' })}: </b>
            {user.last_login ? (
              <TypographyDate
                variant="bodyXS"
                date={user.last_login}
                format={appConfig.FORMATS.DATE}
              />
            ) : (
              formatMessage({ id: 'global.unknown' })
            )}
          </Typography>
          <Typography variant="bodyS" my={1}>
            <b>Auth0: </b>
            <ActiveUserIcon active={user.auth0_id_exists} />
          </Typography>
          <AuthUsersList authRoles={user.auth0_roles} classes={classes} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

/**
 * TableSecReviewListSummaryModal Props
 */
export interface AssociatedUserModalProps {
  user: APIExplorerClientAssociatedUser;
  open: boolean;
  onClose: () => void;
  classes: Partial<ClassKeyOfStyles<any>>;
}
