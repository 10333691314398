/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityDataleaksResultEvidence } from './ControlEntityDataleaksResultEvidence';
import {
    ControlEntityDataleaksResultEvidenceFromJSON,
    ControlEntityDataleaksResultEvidenceFromJSONTyped,
    ControlEntityDataleaksResultEvidenceToJSON,
} from './ControlEntityDataleaksResultEvidence';

/**
 * 
 * @export
 * @interface ControlEntityDataleaksCountResponse
 */
export interface ControlEntityDataleaksCountResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    fullname?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityDataleaksCountResponse
     */
    features?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaksCountResponse
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    severity?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityDataleaksCountResponse
     */
    target_hostname?: string;
    /**
     * 
     * @type {ControlEntityDataleaksResultEvidence}
     * @memberof ControlEntityDataleaksCountResponse
     */
    evidences?: ControlEntityDataleaksResultEvidence;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaksCountResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the ControlEntityDataleaksCountResponse interface.
 */
export function instanceOfControlEntityDataleaksCountResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityDataleaksCountResponseFromJSON(json: any): ControlEntityDataleaksCountResponse {
    return ControlEntityDataleaksCountResponseFromJSONTyped(json, false);
}

export function ControlEntityDataleaksCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDataleaksCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : json['user'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'target_hostname': !exists(json, 'target_hostname') ? undefined : json['target_hostname'],
        'evidences': !exists(json, 'evidences') ? undefined : ControlEntityDataleaksResultEvidenceFromJSON(json['evidences']),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ControlEntityDataleaksCountResponseToJSON(value?: ControlEntityDataleaksCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'name': value.name,
        'domain': value.domain,
        'source_asset': value.source_asset,
        'fullname': value.fullname,
        'features': value.features,
        'year': value.year,
        'date': value.date,
        'severity': value.severity,
        'target_hostname': value.target_hostname,
        'evidences': ControlEntityDataleaksResultEvidenceToJSON(value.evidences),
        'count': value.count,
    };
}

