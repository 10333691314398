import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_USER = '';

const ENDPOINTS_USER = [
  `/auth/confirm`,
  `/auth/login`,
  `/auth/reset-password/confirm`,
  `/client/create`,
  `/notifications/sse`,
  `/rest-auth/login/`,
  `/user/alerts`,
  `/user/context`,
  `/user/create`,
  `/user/custom-domain`,
  `/user/custom-domain/${P1}`,
  `/user/custom-ip-address`,
  `/user/invite`,
  `/user/invites`,
  `/user/policies/cyber/${P1}`,
  `/user/policies/er/${P1}`,
  `/user/policies`,
  `/user/${P1}`,
  `/user`,
  `/user/confirmed-no-domains`,
] as const;

type Endpoints = typeof ENDPOINTS_USER[number];

export type EndpointsUSER = Record<Endpoints, FuncEndpoint>;

export const endpointsUSER = createApiEndpoints<Endpoints>(
  PREFIX_USER,
  ENDPOINTS_USER,
) as EndpointsUSER;
