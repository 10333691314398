/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityLookalikesAggResult } from './MonitEntityLookalikesAggResult';
import {
    MonitEntityLookalikesAggResultFromJSON,
    MonitEntityLookalikesAggResultFromJSONTyped,
    MonitEntityLookalikesAggResultToJSON,
} from './MonitEntityLookalikesAggResult';

/**
 * 
 * @export
 * @interface MonitEntityLookalikesAggResponse
 */
export interface MonitEntityLookalikesAggResponse {
    /**
     * 
     * @type {MonitEntityLookalikesAggResult}
     * @memberof MonitEntityLookalikesAggResponse
     */
    ssl?: MonitEntityLookalikesAggResult;
    /**
     * 
     * @type {MonitEntityLookalikesAggResult}
     * @memberof MonitEntityLookalikesAggResponse
     */
    smtp?: MonitEntityLookalikesAggResult;
    /**
     * 
     * @type {MonitEntityLookalikesAggResult}
     * @memberof MonitEntityLookalikesAggResponse
     */
    both?: MonitEntityLookalikesAggResult;
    /**
     * 
     * @type {MonitEntityLookalikesAggResult}
     * @memberof MonitEntityLookalikesAggResponse
     */
    none?: MonitEntityLookalikesAggResult;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityLookalikesAggResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the MonitEntityLookalikesAggResponse interface.
 */
export function instanceOfMonitEntityLookalikesAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityLookalikesAggResponseFromJSON(json: any): MonitEntityLookalikesAggResponse {
    return MonitEntityLookalikesAggResponseFromJSONTyped(json, false);
}

export function MonitEntityLookalikesAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityLookalikesAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ssl': !exists(json, 'ssl') ? undefined : MonitEntityLookalikesAggResultFromJSON(json['ssl']),
        'smtp': !exists(json, 'smtp') ? undefined : MonitEntityLookalikesAggResultFromJSON(json['smtp']),
        'both': !exists(json, 'both') ? undefined : MonitEntityLookalikesAggResultFromJSON(json['both']),
        'none': !exists(json, 'none') ? undefined : MonitEntityLookalikesAggResultFromJSON(json['none']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function MonitEntityLookalikesAggResponseToJSON(value?: MonitEntityLookalikesAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ssl': MonitEntityLookalikesAggResultToJSON(value.ssl),
        'smtp': MonitEntityLookalikesAggResultToJSON(value.smtp),
        'both': MonitEntityLookalikesAggResultToJSON(value.both),
        'none': MonitEntityLookalikesAggResultToJSON(value.none),
        'total': value.total,
    };
}

