import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Form as CoreForm, FormProps as CoreFormProps } from '@explorer/core';
import { FormikProps } from 'formik';

/**
 * Form
 */
export const Form = ({
  formId,
  formik,
  children,
  className,
  ...props
}: FormProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);

  const { handleSubmit } = formik;

  return (
    <CoreForm
      {...props}
      id={formId}
      onSubmit={handleSubmit}
      className={clsxClass}
    >
      {children}
    </CoreForm>
  );
};

/**
 * Form Props
 */
export interface FormProps extends CoreFormProps {
  /**
   * `formId` - the form id, used to generate ids for every input field
   */
  formId: string;
  /**
   * `formik` - the object returned by the `useFormik()` hook
   */
  formik: FormikProps<any>;
  /**
   * `children` - children components
   */
  children: ReactNode;
}
