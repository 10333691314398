/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFAggregationFindingAssets } from './CRSFAggregationFindingAssets';
import {
    CRSFAggregationFindingAssetsFromJSON,
    CRSFAggregationFindingAssetsFromJSONTyped,
    CRSFAggregationFindingAssetsToJSON,
} from './CRSFAggregationFindingAssets';

/**
 * 
 * @export
 * @interface CRSFAggregationFindings
 */
export interface CRSFAggregationFindings {
    /**
     * 
     * @type {Array<CRSFAggregationFindingAssets>}
     * @memberof CRSFAggregationFindings
     */
    assets: Array<CRSFAggregationFindingAssets>;
    /**
     * 
     * @type {number}
     * @memberof CRSFAggregationFindings
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFAggregationFindings
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFAggregationFindings
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFAggregationFindings
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFAggregationFindings
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFAggregationFindings
     */
    recommendation: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFAggregationFindings
     */
    references: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRSFAggregationFindings
     */
    risk_score: number;
}

/**
 * Check if a given object implements the CRSFAggregationFindings interface.
 */
export function instanceOfCRSFAggregationFindings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "recommendation" in value;
    isInstance = isInstance && "references" in value;
    isInstance = isInstance && "risk_score" in value;

    return isInstance;
}

export function CRSFAggregationFindingsFromJSON(json: any): CRSFAggregationFindings {
    return CRSFAggregationFindingsFromJSONTyped(json, false);
}

export function CRSFAggregationFindingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFAggregationFindings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': ((json['assets'] as Array<any>).map(CRSFAggregationFindingAssetsFromJSON)),
        'count': json['count'],
        'datapoint': json['datapoint'],
        'description': json['description'],
        'level': json['level'],
        'name': json['name'],
        'recommendation': json['recommendation'],
        'references': json['references'],
        'risk_score': json['risk_score'],
    };
}

export function CRSFAggregationFindingsToJSON(value?: CRSFAggregationFindings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': ((value.assets as Array<any>).map(CRSFAggregationFindingAssetsToJSON)),
        'count': value.count,
        'datapoint': value.datapoint,
        'description': value.description,
        'level': value.level,
        'name': value.name,
        'recommendation': value.recommendation,
        'references': value.references,
        'risk_score': value.risk_score,
    };
}

