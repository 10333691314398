/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormInstanceStatus } from './SupplementalFormInstanceStatus';
import {
    SupplementalFormInstanceStatusFromJSON,
    SupplementalFormInstanceStatusFromJSONTyped,
    SupplementalFormInstanceStatusToJSON,
} from './SupplementalFormInstanceStatus';
import type { SupplementalFormType } from './SupplementalFormType';
import {
    SupplementalFormTypeFromJSON,
    SupplementalFormTypeFromJSONTyped,
    SupplementalFormTypeToJSON,
} from './SupplementalFormType';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalFormInstanceWithLocale
 */
export interface SupplementalFormInstanceWithLocale {
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    form_instance_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    recipient_id: string;
    /**
     * 
     * @type {any}
     * @memberof SupplementalFormInstanceWithLocale
     */
    form_data?: any | null;
    /**
     * 
     * @type {SupplementalFormType}
     * @memberof SupplementalFormInstanceWithLocale
     */
    form_type: SupplementalFormType;
    /**
     * 
     * @type {boolean}
     * @memberof SupplementalFormInstanceWithLocale
     */
    is_active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplementalFormInstanceWithLocale
     */
    is_internal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    internal_email?: string | null;
    /**
     * 
     * @type {SupplementalFormInstanceStatus}
     * @memberof SupplementalFormInstanceWithLocale
     */
    form_instance_status: SupplementalFormInstanceStatus;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    submitted_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    expires_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormInstanceWithLocale
     */
    updated_at?: string;
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalFormInstanceWithLocale
     */
    locale: SupplementalLocale;
}

/**
 * Check if a given object implements the SupplementalFormInstanceWithLocale interface.
 */
export function instanceOfSupplementalFormInstanceWithLocale(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "form_instance_id" in value;
    isInstance = isInstance && "recipient_id" in value;
    isInstance = isInstance && "form_type" in value;
    isInstance = isInstance && "form_instance_status" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function SupplementalFormInstanceWithLocaleFromJSON(json: any): SupplementalFormInstanceWithLocale {
    return SupplementalFormInstanceWithLocaleFromJSONTyped(json, false);
}

export function SupplementalFormInstanceWithLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFormInstanceWithLocale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_instance_id': json['form_instance_id'],
        'recipient_id': json['recipient_id'],
        'form_data': !exists(json, 'form_data') ? undefined : json['form_data'],
        'form_type': SupplementalFormTypeFromJSON(json['form_type']),
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'is_internal': !exists(json, 'is_internal') ? undefined : json['is_internal'],
        'internal_email': !exists(json, 'internal_email') ? undefined : json['internal_email'],
        'form_instance_status': SupplementalFormInstanceStatusFromJSON(json['form_instance_status']),
        'submitted_at': !exists(json, 'submitted_at') ? undefined : json['submitted_at'],
        'expires_at': !exists(json, 'expires_at') ? undefined : json['expires_at'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'locale': SupplementalLocaleFromJSON(json['locale']),
    };
}

export function SupplementalFormInstanceWithLocaleToJSON(value?: SupplementalFormInstanceWithLocale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_instance_id': value.form_instance_id,
        'recipient_id': value.recipient_id,
        'form_data': value.form_data,
        'form_type': SupplementalFormTypeToJSON(value.form_type),
        'is_active': value.is_active,
        'is_internal': value.is_internal,
        'internal_email': value.internal_email,
        'form_instance_status': SupplementalFormInstanceStatusToJSON(value.form_instance_status),
        'submitted_at': value.submitted_at,
        'expires_at': value.expires_at,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'locale': SupplementalLocaleToJSON(value.locale),
    };
}

