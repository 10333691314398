/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    activities_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    parent_supported_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    governmental_entity_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    activities_further_exempt_prps_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    activities_engaged_org_invlmnt_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    appoint_elect_majority_officer_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt
     */
    exercise_direction_policies_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt {
    return ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activities_test_ind': !exists(json, 'activities_test_ind') ? undefined : json['activities_test_ind'],
        'parent_supported_org_ind': !exists(json, 'parent_supported_org_ind') ? undefined : json['parent_supported_org_ind'],
        'governmental_entity_ind': !exists(json, 'governmental_entity_ind') ? undefined : json['governmental_entity_ind'],
        'activities_further_exempt_prps_ind': !exists(json, 'activities_further_exempt_prps_ind') ? undefined : json['activities_further_exempt_prps_ind'],
        'activities_engaged_org_invlmnt_ind': !exists(json, 'activities_engaged_org_invlmnt_ind') ? undefined : json['activities_engaged_org_invlmnt_ind'],
        'appoint_elect_majority_officer_ind': !exists(json, 'appoint_elect_majority_officer_ind') ? undefined : json['appoint_elect_majority_officer_ind'],
        'exercise_direction_policies_ind': !exists(json, 'exercise_direction_policies_ind') ? undefined : json['exercise_direction_policies_ind'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activities_test_ind': value.activities_test_ind,
        'parent_supported_org_ind': value.parent_supported_org_ind,
        'governmental_entity_ind': value.governmental_entity_ind,
        'activities_further_exempt_prps_ind': value.activities_further_exempt_prps_ind,
        'activities_engaged_org_invlmnt_ind': value.activities_engaged_org_invlmnt_ind,
        'appoint_elect_majority_officer_ind': value.appoint_elect_majority_officer_ind,
        'exercise_direction_policies_ind': value.exercise_direction_policies_ind,
    };
}

