import React, { ReactNode, useEffect } from 'react';
import Monitoring from '@crucible-risk/react-monitoring';

export const SEGMENT_PREFIX = 'XPLR';

const DEV_ENV = 'development';

export const AppMonitoring = ({
  userId,
  userEmail,
  userName,
  children,
  datadogConfig,
  errorView,
  segmentKey,
  sentryKey,
}: AppMonitoringProps) => {
  const segmentEventPrefix = SEGMENT_PREFIX;
  const isLocalhost =
    process.env.REACT_ENV === DEV_ENV || process.env.REACT_ENV === 'test';
  const environment = isLocalhost ? DEV_ENV : process.env.BE_ENV;

  useEffect(() => {
    if (isLocalhost) {
      return;
    }

    console.log('NextJS 13 be-frontend-explorer migration');
    console.log({ version: process.env.REACT_APP_VERSION ?? 'dev' });
  }, [isLocalhost]);

  return (
    <Monitoring
      datadogApplicationId={datadogConfig.appId ?? ''}
      datadogClientToken={datadogConfig.token ?? ''}
      datadogService={datadogConfig.serviceName ?? ''}
      datadogTrackResources={true}
      datadogTrackUserInteractions={true}
      environment={environment}
      ErrorBoundaryFallback={errorView}
      segmentEventPrefix={segmentEventPrefix}
      segmentWriteKey={segmentKey}
      sentryDsn={sentryKey}
      userEmail={userEmail}
      userId={userId}
      userName={userName}
      version={process.env.REACT_APP_VERSION ?? 'dev'}
      datadogAllowedTracingOrigins={datadogConfig.tracingOrigins}
    >
      {children}
    </Monitoring>
  );
};

export interface AppMonitoringProps {
  userId: string;
  userEmail?: string;
  userName?: string;
  children: ReactNode;
  datadogConfig: {
    appId?: string;
    token?: string;
    serviceName?: string;
    tracingOrigins?: (string | RegExp)[];
  };
  errorView: any;
  segmentKey: string;
  sentryKey: string;
}
