/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRAEntity
 */
export interface CRAEntity {
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    entity_name: string;
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    entity_domain: string;
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRAEntity
     */
    domains: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRAEntity
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRAEntity
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    updated: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRAEntity
     */
    no_result_flag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRAEntity
     */
    report_url?: string;
    /**
     * 
     * @type {object}
     * @memberof CRAEntity
     */
    status?: object;
}

/**
 * Check if a given object implements the CRAEntity interface.
 */
export function instanceOfCRAEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_name" in value;
    isInstance = isInstance && "entity_domain" in value;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRAEntityFromJSON(json: any): CRAEntity {
    return CRAEntityFromJSONTyped(json, false);
}

export function CRAEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'entity_name': json['entity_name'],
        'entity_domain': json['entity_domain'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'domains': json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'updated': json['updated'],
        'no_result_flag': !exists(json, 'no_result_flag') ? undefined : json['no_result_flag'],
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function CRAEntityToJSON(value?: CRAEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'entity_logo_url': value.entity_logo_url,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'updated': value.updated,
        'no_result_flag': value.no_result_flag,
        'report_url': value.report_url,
        'status': value.status,
    };
}

