/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment';

/**
 * 
 * @export
 * @interface ExecRiskIRS990EZ
 */
export interface ExecRiskIRS990EZ {
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment>}
     * @memberof ExecRiskIRS990EZ
     */
    ProgramSrvcAccomplishmentGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl>}
     * @memberof ExecRiskIRS990EZ
     */
    OfficerDirectorTrusteeEmplGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp>}
     * @memberof ExecRiskIRS990EZ
     */
    CompensationHighestPaidEmplGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PartVIOfCompOfHghstPdEmplTxt?: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp>}
     * @memberof ExecRiskIRS990EZ
     */
    CompensationOfHghstPdCntrctGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PartVIHghstPdCntrctProfSrvcTxt?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialConditionDesc?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    accountingPeriodChangeCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    accountingPeriodChangeApprvCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    AddressChangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    NameChangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InitialReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    FinalReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    AmendedReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ApplicationPendingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    GroupExemptionNum?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingCashInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingAccrualInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingOtherDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ScheduleBNotRequiredInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    WebsiteAddressTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization501c3Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    organization_501_c_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    organization_501_c_type_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization4947a1NotPFInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization527Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationCorpInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationTrustInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationAssocInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationOtherInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationOtherDesc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossReceiptsAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ContributionsGiftsGrantsEtcAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ProgramServiceRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    MembershipDuesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    InvestmentIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SaleOfAssetsGrossAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    CostOrOtherBasisExpenseSaleAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GainOrLossFromSaleOfAssetsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    FundraisingGrossIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialEventsDirectExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialEventsNetIncomeLossAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossSalesOfInventoryAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    CostOfGoodsSoldAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossProfitLossSlsOfInvntryAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherRevenueTotalAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrantsAndSimilarAmountsPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    BenefitsPaidToOrForMembersAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SalariesOtherCompEmplBnftAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    FeesAndOtherPymtToIndCntrctAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OccupancyRentUtltsAndMaintAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    PrintingPublicationsPostageAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherExpensesTotalAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ExcessOrDeficitForYearAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesBOYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherChangesInNetAssetsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesEOYAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIIInd?: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    CashSavingsAndInvestmentsGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    LandAndBuildingsGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    OtherAssetsTotalDetail?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    Form990TotalAssetsGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    SumOfTotalLiabilitiesGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIIIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PrimaryExemptPurposeTxt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalProgramServiceExpensesAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIVInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartVInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ActivitiesNotPreviouslyRptInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ChgMadeToOrgnzngDocNotRptInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationHadUBIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationFiled990TInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    SubjectToProxyTaxInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationDissolvedEtcInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    DirectIndirectPltclExpendAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Form1120PolFiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MadeLoansToFromOfficersInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    LoansToFromOfficersAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    InitiationFeesAndCapContriAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossReceiptsForPublicUseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4911Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4912Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4955Amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    EngagedInExcessBenefitTransInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedOnOrganizationMgrAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxReimbursedByOrganizationAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ProhibitedTaxShelterTransInd?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    StatesWhereCopyOfReturnIsFldCd?: Array<string>;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail}
     * @memberof ExecRiskIRS990EZ
     */
    BooksInCareOfDetail?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignFinancialAccountInd?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignFinancialAccountCntryCd?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignOfficeInd?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignOfficeCountryCd?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    NECTFilingForm990Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    DonorAdvisedFndsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OperateHospitalInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TanningServicesProvidedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Form720FiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    RelatedOrganizationCtrlEntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TransactionWithControlEntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PoliticalCampaignActyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartVIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    LobbyingActivitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    SchoolOperatingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TrnsfrExmptNonChrtblRltdOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    RelatedOrgSect527OrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OtherEmployeePaidOver100kCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    CntrctRcvdGreaterThan100KCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    FiledScheduleAInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrantsAndAllocationsAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskIRS990EZ interface.
 */
export function instanceOfExecRiskIRS990EZ(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskIRS990EZFromJSON(json: any): ExecRiskIRS990EZ {
    return ExecRiskIRS990EZFromJSONTyped(json, false);
}

export function ExecRiskIRS990EZFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskIRS990EZ {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ProgramSrvcAccomplishmentGrp': !exists(json, 'ProgramSrvcAccomplishmentGrp') ? undefined : ((json['ProgramSrvcAccomplishmentGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSON)),
        'OfficerDirectorTrusteeEmplGrp': !exists(json, 'OfficerDirectorTrusteeEmplGrp') ? undefined : ((json['OfficerDirectorTrusteeEmplGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSON)),
        'CompensationHighestPaidEmplGrp': !exists(json, 'CompensationHighestPaidEmplGrp') ? undefined : ((json['CompensationHighestPaidEmplGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSON)),
        'PartVIOfCompOfHghstPdEmplTxt': !exists(json, 'PartVIOfCompOfHghstPdEmplTxt') ? undefined : json['PartVIOfCompOfHghstPdEmplTxt'],
        'CompensationOfHghstPdCntrctGrp': !exists(json, 'CompensationOfHghstPdCntrctGrp') ? undefined : ((json['CompensationOfHghstPdCntrctGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON)),
        'PartVIHghstPdCntrctProfSrvcTxt': !exists(json, 'PartVIHghstPdCntrctProfSrvcTxt') ? undefined : json['PartVIHghstPdCntrctProfSrvcTxt'],
        'SpecialConditionDesc': !exists(json, 'SpecialConditionDesc') ? undefined : json['SpecialConditionDesc'],
        'accountingPeriodChangeCd': !exists(json, 'accountingPeriodChangeCd') ? undefined : json['accountingPeriodChangeCd'],
        'accountingPeriodChangeApprvCd': !exists(json, 'accountingPeriodChangeApprvCd') ? undefined : json['accountingPeriodChangeApprvCd'],
        'AddressChangeInd': !exists(json, 'AddressChangeInd') ? undefined : json['AddressChangeInd'],
        'NameChangeInd': !exists(json, 'NameChangeInd') ? undefined : json['NameChangeInd'],
        'InitialReturnInd': !exists(json, 'InitialReturnInd') ? undefined : json['InitialReturnInd'],
        'FinalReturnInd': !exists(json, 'FinalReturnInd') ? undefined : json['FinalReturnInd'],
        'AmendedReturnInd': !exists(json, 'AmendedReturnInd') ? undefined : json['AmendedReturnInd'],
        'ApplicationPendingInd': !exists(json, 'ApplicationPendingInd') ? undefined : json['ApplicationPendingInd'],
        'GroupExemptionNum': !exists(json, 'GroupExemptionNum') ? undefined : json['GroupExemptionNum'],
        'MethodOfAccountingCashInd': !exists(json, 'MethodOfAccountingCashInd') ? undefined : json['MethodOfAccountingCashInd'],
        'MethodOfAccountingAccrualInd': !exists(json, 'MethodOfAccountingAccrualInd') ? undefined : json['MethodOfAccountingAccrualInd'],
        'MethodOfAccountingOtherDesc': !exists(json, 'MethodOfAccountingOtherDesc') ? undefined : json['MethodOfAccountingOtherDesc'],
        'ScheduleBNotRequiredInd': !exists(json, 'ScheduleBNotRequiredInd') ? undefined : json['ScheduleBNotRequiredInd'],
        'WebsiteAddressTxt': !exists(json, 'WebsiteAddressTxt') ? undefined : json['WebsiteAddressTxt'],
        'Organization501c3Ind': !exists(json, 'Organization501c3Ind') ? undefined : json['Organization501c3Ind'],
        'organization_501_c_ind': !exists(json, 'organization_501_c_ind') ? undefined : json['organization_501_c_ind'],
        'organization_501_c_type_text': !exists(json, 'organization_501_c_type_text') ? undefined : json['organization_501_c_type_text'],
        'Organization4947a1NotPFInd': !exists(json, 'Organization4947a1NotPFInd') ? undefined : json['Organization4947a1NotPFInd'],
        'Organization527Ind': !exists(json, 'Organization527Ind') ? undefined : json['Organization527Ind'],
        'TypeOfOrganizationCorpInd': !exists(json, 'TypeOfOrganizationCorpInd') ? undefined : json['TypeOfOrganizationCorpInd'],
        'TypeOfOrganizationTrustInd': !exists(json, 'TypeOfOrganizationTrustInd') ? undefined : json['TypeOfOrganizationTrustInd'],
        'TypeOfOrganizationAssocInd': !exists(json, 'TypeOfOrganizationAssocInd') ? undefined : json['TypeOfOrganizationAssocInd'],
        'TypeOfOrganizationOtherInd': !exists(json, 'TypeOfOrganizationOtherInd') ? undefined : json['TypeOfOrganizationOtherInd'],
        'TypeOfOrganizationOtherDesc': !exists(json, 'TypeOfOrganizationOtherDesc') ? undefined : json['TypeOfOrganizationOtherDesc'],
        'GrossReceiptsAmt': !exists(json, 'GrossReceiptsAmt') ? undefined : json['GrossReceiptsAmt'],
        'InfoInScheduleOPartIInd': !exists(json, 'InfoInScheduleOPartIInd') ? undefined : json['InfoInScheduleOPartIInd'],
        'ContributionsGiftsGrantsEtcAmt': !exists(json, 'ContributionsGiftsGrantsEtcAmt') ? undefined : json['ContributionsGiftsGrantsEtcAmt'],
        'ProgramServiceRevenueAmt': !exists(json, 'ProgramServiceRevenueAmt') ? undefined : json['ProgramServiceRevenueAmt'],
        'MembershipDuesAmt': !exists(json, 'MembershipDuesAmt') ? undefined : json['MembershipDuesAmt'],
        'InvestmentIncomeAmt': !exists(json, 'InvestmentIncomeAmt') ? undefined : json['InvestmentIncomeAmt'],
        'SaleOfAssetsGrossAmt': !exists(json, 'SaleOfAssetsGrossAmt') ? undefined : json['SaleOfAssetsGrossAmt'],
        'CostOrOtherBasisExpenseSaleAmt': !exists(json, 'CostOrOtherBasisExpenseSaleAmt') ? undefined : json['CostOrOtherBasisExpenseSaleAmt'],
        'GainOrLossFromSaleOfAssetsAmt': !exists(json, 'GainOrLossFromSaleOfAssetsAmt') ? undefined : json['GainOrLossFromSaleOfAssetsAmt'],
        'FundraisingGrossIncomeAmt': !exists(json, 'FundraisingGrossIncomeAmt') ? undefined : json['FundraisingGrossIncomeAmt'],
        'SpecialEventsDirectExpensesAmt': !exists(json, 'SpecialEventsDirectExpensesAmt') ? undefined : json['SpecialEventsDirectExpensesAmt'],
        'SpecialEventsNetIncomeLossAmt': !exists(json, 'SpecialEventsNetIncomeLossAmt') ? undefined : json['SpecialEventsNetIncomeLossAmt'],
        'GrossSalesOfInventoryAmt': !exists(json, 'GrossSalesOfInventoryAmt') ? undefined : json['GrossSalesOfInventoryAmt'],
        'CostOfGoodsSoldAmt': !exists(json, 'CostOfGoodsSoldAmt') ? undefined : json['CostOfGoodsSoldAmt'],
        'GrossProfitLossSlsOfInvntryAmt': !exists(json, 'GrossProfitLossSlsOfInvntryAmt') ? undefined : json['GrossProfitLossSlsOfInvntryAmt'],
        'OtherRevenueTotalAmt': !exists(json, 'OtherRevenueTotalAmt') ? undefined : json['OtherRevenueTotalAmt'],
        'TotalRevenueAmt': !exists(json, 'TotalRevenueAmt') ? undefined : json['TotalRevenueAmt'],
        'GrantsAndSimilarAmountsPaidAmt': !exists(json, 'GrantsAndSimilarAmountsPaidAmt') ? undefined : json['GrantsAndSimilarAmountsPaidAmt'],
        'BenefitsPaidToOrForMembersAmt': !exists(json, 'BenefitsPaidToOrForMembersAmt') ? undefined : json['BenefitsPaidToOrForMembersAmt'],
        'SalariesOtherCompEmplBnftAmt': !exists(json, 'SalariesOtherCompEmplBnftAmt') ? undefined : json['SalariesOtherCompEmplBnftAmt'],
        'FeesAndOtherPymtToIndCntrctAmt': !exists(json, 'FeesAndOtherPymtToIndCntrctAmt') ? undefined : json['FeesAndOtherPymtToIndCntrctAmt'],
        'OccupancyRentUtltsAndMaintAmt': !exists(json, 'OccupancyRentUtltsAndMaintAmt') ? undefined : json['OccupancyRentUtltsAndMaintAmt'],
        'PrintingPublicationsPostageAmt': !exists(json, 'PrintingPublicationsPostageAmt') ? undefined : json['PrintingPublicationsPostageAmt'],
        'OtherExpensesTotalAmt': !exists(json, 'OtherExpensesTotalAmt') ? undefined : json['OtherExpensesTotalAmt'],
        'TotalExpensesAmt': !exists(json, 'TotalExpensesAmt') ? undefined : json['TotalExpensesAmt'],
        'ExcessOrDeficitForYearAmt': !exists(json, 'ExcessOrDeficitForYearAmt') ? undefined : json['ExcessOrDeficitForYearAmt'],
        'NetAssetsOrFundBalancesBOYAmt': !exists(json, 'NetAssetsOrFundBalancesBOYAmt') ? undefined : json['NetAssetsOrFundBalancesBOYAmt'],
        'OtherChangesInNetAssetsAmt': !exists(json, 'OtherChangesInNetAssetsAmt') ? undefined : json['OtherChangesInNetAssetsAmt'],
        'NetAssetsOrFundBalancesEOYAmt': !exists(json, 'NetAssetsOrFundBalancesEOYAmt') ? undefined : json['NetAssetsOrFundBalancesEOYAmt'],
        'InfoInScheduleOPartIIInd': !exists(json, 'InfoInScheduleOPartIIInd') ? undefined : json['InfoInScheduleOPartIIInd'],
        'CashSavingsAndInvestmentsGrp': !exists(json, 'CashSavingsAndInvestmentsGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['CashSavingsAndInvestmentsGrp']),
        'LandAndBuildingsGrp': !exists(json, 'LandAndBuildingsGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['LandAndBuildingsGrp']),
        'OtherAssetsTotalDetail': !exists(json, 'OtherAssetsTotalDetail') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['OtherAssetsTotalDetail']),
        'Form990TotalAssetsGrp': !exists(json, 'Form990TotalAssetsGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['Form990TotalAssetsGrp']),
        'SumOfTotalLiabilitiesGrp': !exists(json, 'SumOfTotalLiabilitiesGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['SumOfTotalLiabilitiesGrp']),
        'NetAssetsOrFundBalancesGrp': !exists(json, 'NetAssetsOrFundBalancesGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['NetAssetsOrFundBalancesGrp']),
        'InfoInScheduleOPartIIIInd': !exists(json, 'InfoInScheduleOPartIIIInd') ? undefined : json['InfoInScheduleOPartIIIInd'],
        'PrimaryExemptPurposeTxt': !exists(json, 'PrimaryExemptPurposeTxt') ? undefined : json['PrimaryExemptPurposeTxt'],
        'TotalProgramServiceExpensesAmt': !exists(json, 'TotalProgramServiceExpensesAmt') ? undefined : json['TotalProgramServiceExpensesAmt'],
        'InfoInScheduleOPartIVInd': !exists(json, 'InfoInScheduleOPartIVInd') ? undefined : json['InfoInScheduleOPartIVInd'],
        'InfoInScheduleOPartVInd': !exists(json, 'InfoInScheduleOPartVInd') ? undefined : json['InfoInScheduleOPartVInd'],
        'ActivitiesNotPreviouslyRptInd': !exists(json, 'ActivitiesNotPreviouslyRptInd') ? undefined : json['ActivitiesNotPreviouslyRptInd'],
        'ChgMadeToOrgnzngDocNotRptInd': !exists(json, 'ChgMadeToOrgnzngDocNotRptInd') ? undefined : json['ChgMadeToOrgnzngDocNotRptInd'],
        'OrganizationHadUBIInd': !exists(json, 'OrganizationHadUBIInd') ? undefined : json['OrganizationHadUBIInd'],
        'OrganizationFiled990TInd': !exists(json, 'OrganizationFiled990TInd') ? undefined : json['OrganizationFiled990TInd'],
        'SubjectToProxyTaxInd': !exists(json, 'SubjectToProxyTaxInd') ? undefined : json['SubjectToProxyTaxInd'],
        'OrganizationDissolvedEtcInd': !exists(json, 'OrganizationDissolvedEtcInd') ? undefined : json['OrganizationDissolvedEtcInd'],
        'DirectIndirectPltclExpendAmt': !exists(json, 'DirectIndirectPltclExpendAmt') ? undefined : json['DirectIndirectPltclExpendAmt'],
        'Form1120PolFiledInd': !exists(json, 'Form1120PolFiledInd') ? undefined : json['Form1120PolFiledInd'],
        'MadeLoansToFromOfficersInd': !exists(json, 'MadeLoansToFromOfficersInd') ? undefined : json['MadeLoansToFromOfficersInd'],
        'LoansToFromOfficersAmt': !exists(json, 'LoansToFromOfficersAmt') ? undefined : json['LoansToFromOfficersAmt'],
        'InitiationFeesAndCapContriAmt': !exists(json, 'InitiationFeesAndCapContriAmt') ? undefined : json['InitiationFeesAndCapContriAmt'],
        'GrossReceiptsForPublicUseAmt': !exists(json, 'GrossReceiptsForPublicUseAmt') ? undefined : json['GrossReceiptsForPublicUseAmt'],
        'TaxImposedUnderIRC4911Amt': !exists(json, 'TaxImposedUnderIRC4911Amt') ? undefined : json['TaxImposedUnderIRC4911Amt'],
        'TaxImposedUnderIRC4912Amt': !exists(json, 'TaxImposedUnderIRC4912Amt') ? undefined : json['TaxImposedUnderIRC4912Amt'],
        'TaxImposedUnderIRC4955Amt': !exists(json, 'TaxImposedUnderIRC4955Amt') ? undefined : json['TaxImposedUnderIRC4955Amt'],
        'EngagedInExcessBenefitTransInd': !exists(json, 'EngagedInExcessBenefitTransInd') ? undefined : json['EngagedInExcessBenefitTransInd'],
        'TaxImposedOnOrganizationMgrAmt': !exists(json, 'TaxImposedOnOrganizationMgrAmt') ? undefined : json['TaxImposedOnOrganizationMgrAmt'],
        'TaxReimbursedByOrganizationAmt': !exists(json, 'TaxReimbursedByOrganizationAmt') ? undefined : json['TaxReimbursedByOrganizationAmt'],
        'ProhibitedTaxShelterTransInd': !exists(json, 'ProhibitedTaxShelterTransInd') ? undefined : json['ProhibitedTaxShelterTransInd'],
        'StatesWhereCopyOfReturnIsFldCd': !exists(json, 'StatesWhereCopyOfReturnIsFldCd') ? undefined : json['StatesWhereCopyOfReturnIsFldCd'],
        'BooksInCareOfDetail': !exists(json, 'BooksInCareOfDetail') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON(json['BooksInCareOfDetail']),
        'ForeignFinancialAccountInd': !exists(json, 'ForeignFinancialAccountInd') ? undefined : json['ForeignFinancialAccountInd'],
        'ForeignFinancialAccountCntryCd': !exists(json, 'ForeignFinancialAccountCntryCd') ? undefined : json['ForeignFinancialAccountCntryCd'],
        'ForeignOfficeInd': !exists(json, 'ForeignOfficeInd') ? undefined : json['ForeignOfficeInd'],
        'ForeignOfficeCountryCd': !exists(json, 'ForeignOfficeCountryCd') ? undefined : json['ForeignOfficeCountryCd'],
        'NECTFilingForm990Ind': !exists(json, 'NECTFilingForm990Ind') ? undefined : json['NECTFilingForm990Ind'],
        'DonorAdvisedFndsInd': !exists(json, 'DonorAdvisedFndsInd') ? undefined : json['DonorAdvisedFndsInd'],
        'OperateHospitalInd': !exists(json, 'OperateHospitalInd') ? undefined : json['OperateHospitalInd'],
        'TanningServicesProvidedInd': !exists(json, 'TanningServicesProvidedInd') ? undefined : json['TanningServicesProvidedInd'],
        'Form720FiledInd': !exists(json, 'Form720FiledInd') ? undefined : json['Form720FiledInd'],
        'RelatedOrganizationCtrlEntInd': !exists(json, 'RelatedOrganizationCtrlEntInd') ? undefined : json['RelatedOrganizationCtrlEntInd'],
        'TransactionWithControlEntInd': !exists(json, 'TransactionWithControlEntInd') ? undefined : json['TransactionWithControlEntInd'],
        'PoliticalCampaignActyInd': !exists(json, 'PoliticalCampaignActyInd') ? undefined : json['PoliticalCampaignActyInd'],
        'InfoInScheduleOPartVIInd': !exists(json, 'InfoInScheduleOPartVIInd') ? undefined : json['InfoInScheduleOPartVIInd'],
        'LobbyingActivitiesInd': !exists(json, 'LobbyingActivitiesInd') ? undefined : json['LobbyingActivitiesInd'],
        'SchoolOperatingInd': !exists(json, 'SchoolOperatingInd') ? undefined : json['SchoolOperatingInd'],
        'TrnsfrExmptNonChrtblRltdOrgInd': !exists(json, 'TrnsfrExmptNonChrtblRltdOrgInd') ? undefined : json['TrnsfrExmptNonChrtblRltdOrgInd'],
        'RelatedOrgSect527OrgInd': !exists(json, 'RelatedOrgSect527OrgInd') ? undefined : json['RelatedOrgSect527OrgInd'],
        'OtherEmployeePaidOver100kCnt': !exists(json, 'OtherEmployeePaidOver100kCnt') ? undefined : json['OtherEmployeePaidOver100kCnt'],
        'CntrctRcvdGreaterThan100KCnt': !exists(json, 'CntrctRcvdGreaterThan100KCnt') ? undefined : json['CntrctRcvdGreaterThan100KCnt'],
        'FiledScheduleAInd': !exists(json, 'FiledScheduleAInd') ? undefined : json['FiledScheduleAInd'],
        'GrantsAndAllocationsAmt': !exists(json, 'GrantsAndAllocationsAmt') ? undefined : json['GrantsAndAllocationsAmt'],
    };
}

export function ExecRiskIRS990EZToJSON(value?: ExecRiskIRS990EZ | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ProgramSrvcAccomplishmentGrp': value.ProgramSrvcAccomplishmentGrp === undefined ? undefined : ((value.ProgramSrvcAccomplishmentGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentToJSON)),
        'OfficerDirectorTrusteeEmplGrp': value.OfficerDirectorTrusteeEmplGrp === undefined ? undefined : ((value.OfficerDirectorTrusteeEmplGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplToJSON)),
        'CompensationHighestPaidEmplGrp': value.CompensationHighestPaidEmplGrp === undefined ? undefined : ((value.CompensationHighestPaidEmplGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpToJSON)),
        'PartVIOfCompOfHghstPdEmplTxt': value.PartVIOfCompOfHghstPdEmplTxt,
        'CompensationOfHghstPdCntrctGrp': value.CompensationOfHghstPdCntrctGrp === undefined ? undefined : ((value.CompensationOfHghstPdCntrctGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON)),
        'PartVIHghstPdCntrctProfSrvcTxt': value.PartVIHghstPdCntrctProfSrvcTxt,
        'SpecialConditionDesc': value.SpecialConditionDesc,
        'accountingPeriodChangeCd': value.accountingPeriodChangeCd,
        'accountingPeriodChangeApprvCd': value.accountingPeriodChangeApprvCd,
        'AddressChangeInd': value.AddressChangeInd,
        'NameChangeInd': value.NameChangeInd,
        'InitialReturnInd': value.InitialReturnInd,
        'FinalReturnInd': value.FinalReturnInd,
        'AmendedReturnInd': value.AmendedReturnInd,
        'ApplicationPendingInd': value.ApplicationPendingInd,
        'GroupExemptionNum': value.GroupExemptionNum,
        'MethodOfAccountingCashInd': value.MethodOfAccountingCashInd,
        'MethodOfAccountingAccrualInd': value.MethodOfAccountingAccrualInd,
        'MethodOfAccountingOtherDesc': value.MethodOfAccountingOtherDesc,
        'ScheduleBNotRequiredInd': value.ScheduleBNotRequiredInd,
        'WebsiteAddressTxt': value.WebsiteAddressTxt,
        'Organization501c3Ind': value.Organization501c3Ind,
        'organization_501_c_ind': value.organization_501_c_ind,
        'organization_501_c_type_text': value.organization_501_c_type_text,
        'Organization4947a1NotPFInd': value.Organization4947a1NotPFInd,
        'Organization527Ind': value.Organization527Ind,
        'TypeOfOrganizationCorpInd': value.TypeOfOrganizationCorpInd,
        'TypeOfOrganizationTrustInd': value.TypeOfOrganizationTrustInd,
        'TypeOfOrganizationAssocInd': value.TypeOfOrganizationAssocInd,
        'TypeOfOrganizationOtherInd': value.TypeOfOrganizationOtherInd,
        'TypeOfOrganizationOtherDesc': value.TypeOfOrganizationOtherDesc,
        'GrossReceiptsAmt': value.GrossReceiptsAmt,
        'InfoInScheduleOPartIInd': value.InfoInScheduleOPartIInd,
        'ContributionsGiftsGrantsEtcAmt': value.ContributionsGiftsGrantsEtcAmt,
        'ProgramServiceRevenueAmt': value.ProgramServiceRevenueAmt,
        'MembershipDuesAmt': value.MembershipDuesAmt,
        'InvestmentIncomeAmt': value.InvestmentIncomeAmt,
        'SaleOfAssetsGrossAmt': value.SaleOfAssetsGrossAmt,
        'CostOrOtherBasisExpenseSaleAmt': value.CostOrOtherBasisExpenseSaleAmt,
        'GainOrLossFromSaleOfAssetsAmt': value.GainOrLossFromSaleOfAssetsAmt,
        'FundraisingGrossIncomeAmt': value.FundraisingGrossIncomeAmt,
        'SpecialEventsDirectExpensesAmt': value.SpecialEventsDirectExpensesAmt,
        'SpecialEventsNetIncomeLossAmt': value.SpecialEventsNetIncomeLossAmt,
        'GrossSalesOfInventoryAmt': value.GrossSalesOfInventoryAmt,
        'CostOfGoodsSoldAmt': value.CostOfGoodsSoldAmt,
        'GrossProfitLossSlsOfInvntryAmt': value.GrossProfitLossSlsOfInvntryAmt,
        'OtherRevenueTotalAmt': value.OtherRevenueTotalAmt,
        'TotalRevenueAmt': value.TotalRevenueAmt,
        'GrantsAndSimilarAmountsPaidAmt': value.GrantsAndSimilarAmountsPaidAmt,
        'BenefitsPaidToOrForMembersAmt': value.BenefitsPaidToOrForMembersAmt,
        'SalariesOtherCompEmplBnftAmt': value.SalariesOtherCompEmplBnftAmt,
        'FeesAndOtherPymtToIndCntrctAmt': value.FeesAndOtherPymtToIndCntrctAmt,
        'OccupancyRentUtltsAndMaintAmt': value.OccupancyRentUtltsAndMaintAmt,
        'PrintingPublicationsPostageAmt': value.PrintingPublicationsPostageAmt,
        'OtherExpensesTotalAmt': value.OtherExpensesTotalAmt,
        'TotalExpensesAmt': value.TotalExpensesAmt,
        'ExcessOrDeficitForYearAmt': value.ExcessOrDeficitForYearAmt,
        'NetAssetsOrFundBalancesBOYAmt': value.NetAssetsOrFundBalancesBOYAmt,
        'OtherChangesInNetAssetsAmt': value.OtherChangesInNetAssetsAmt,
        'NetAssetsOrFundBalancesEOYAmt': value.NetAssetsOrFundBalancesEOYAmt,
        'InfoInScheduleOPartIIInd': value.InfoInScheduleOPartIIInd,
        'CashSavingsAndInvestmentsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.CashSavingsAndInvestmentsGrp),
        'LandAndBuildingsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.LandAndBuildingsGrp),
        'OtherAssetsTotalDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.OtherAssetsTotalDetail),
        'Form990TotalAssetsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.Form990TotalAssetsGrp),
        'SumOfTotalLiabilitiesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.SumOfTotalLiabilitiesGrp),
        'NetAssetsOrFundBalancesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.NetAssetsOrFundBalancesGrp),
        'InfoInScheduleOPartIIIInd': value.InfoInScheduleOPartIIIInd,
        'PrimaryExemptPurposeTxt': value.PrimaryExemptPurposeTxt,
        'TotalProgramServiceExpensesAmt': value.TotalProgramServiceExpensesAmt,
        'InfoInScheduleOPartIVInd': value.InfoInScheduleOPartIVInd,
        'InfoInScheduleOPartVInd': value.InfoInScheduleOPartVInd,
        'ActivitiesNotPreviouslyRptInd': value.ActivitiesNotPreviouslyRptInd,
        'ChgMadeToOrgnzngDocNotRptInd': value.ChgMadeToOrgnzngDocNotRptInd,
        'OrganizationHadUBIInd': value.OrganizationHadUBIInd,
        'OrganizationFiled990TInd': value.OrganizationFiled990TInd,
        'SubjectToProxyTaxInd': value.SubjectToProxyTaxInd,
        'OrganizationDissolvedEtcInd': value.OrganizationDissolvedEtcInd,
        'DirectIndirectPltclExpendAmt': value.DirectIndirectPltclExpendAmt,
        'Form1120PolFiledInd': value.Form1120PolFiledInd,
        'MadeLoansToFromOfficersInd': value.MadeLoansToFromOfficersInd,
        'LoansToFromOfficersAmt': value.LoansToFromOfficersAmt,
        'InitiationFeesAndCapContriAmt': value.InitiationFeesAndCapContriAmt,
        'GrossReceiptsForPublicUseAmt': value.GrossReceiptsForPublicUseAmt,
        'TaxImposedUnderIRC4911Amt': value.TaxImposedUnderIRC4911Amt,
        'TaxImposedUnderIRC4912Amt': value.TaxImposedUnderIRC4912Amt,
        'TaxImposedUnderIRC4955Amt': value.TaxImposedUnderIRC4955Amt,
        'EngagedInExcessBenefitTransInd': value.EngagedInExcessBenefitTransInd,
        'TaxImposedOnOrganizationMgrAmt': value.TaxImposedOnOrganizationMgrAmt,
        'TaxReimbursedByOrganizationAmt': value.TaxReimbursedByOrganizationAmt,
        'ProhibitedTaxShelterTransInd': value.ProhibitedTaxShelterTransInd,
        'StatesWhereCopyOfReturnIsFldCd': value.StatesWhereCopyOfReturnIsFldCd,
        'BooksInCareOfDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON(value.BooksInCareOfDetail),
        'ForeignFinancialAccountInd': value.ForeignFinancialAccountInd,
        'ForeignFinancialAccountCntryCd': value.ForeignFinancialAccountCntryCd,
        'ForeignOfficeInd': value.ForeignOfficeInd,
        'ForeignOfficeCountryCd': value.ForeignOfficeCountryCd,
        'NECTFilingForm990Ind': value.NECTFilingForm990Ind,
        'DonorAdvisedFndsInd': value.DonorAdvisedFndsInd,
        'OperateHospitalInd': value.OperateHospitalInd,
        'TanningServicesProvidedInd': value.TanningServicesProvidedInd,
        'Form720FiledInd': value.Form720FiledInd,
        'RelatedOrganizationCtrlEntInd': value.RelatedOrganizationCtrlEntInd,
        'TransactionWithControlEntInd': value.TransactionWithControlEntInd,
        'PoliticalCampaignActyInd': value.PoliticalCampaignActyInd,
        'InfoInScheduleOPartVIInd': value.InfoInScheduleOPartVIInd,
        'LobbyingActivitiesInd': value.LobbyingActivitiesInd,
        'SchoolOperatingInd': value.SchoolOperatingInd,
        'TrnsfrExmptNonChrtblRltdOrgInd': value.TrnsfrExmptNonChrtblRltdOrgInd,
        'RelatedOrgSect527OrgInd': value.RelatedOrgSect527OrgInd,
        'OtherEmployeePaidOver100kCnt': value.OtherEmployeePaidOver100kCnt,
        'CntrctRcvdGreaterThan100KCnt': value.CntrctRcvdGreaterThan100KCnt,
        'FiledScheduleAInd': value.FiledScheduleAInd,
        'GrantsAndAllocationsAmt': value.GrantsAndAllocationsAmt,
    };
}

