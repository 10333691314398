/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewPort } from './SecReviewPort';
import {
    SecReviewPortFromJSON,
    SecReviewPortFromJSONTyped,
    SecReviewPortToJSON,
} from './SecReviewPort';

/**
 * 
 * @export
 * @interface SecReviewItemIP
 */
export interface SecReviewItemIP {
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    as_number?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    as_class_t?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    shared?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    blocklisted?: string;
    /**
     * 
     * @type {Array<SecReviewPort>}
     * @memberof SecReviewItemIP
     */
    ports?: Array<SecReviewPort>;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemIP
     */
    ip: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SecReviewItemIP
     */
    domains?: Set<string>;
}

/**
 * Check if a given object implements the SecReviewItemIP interface.
 */
export function instanceOfSecReviewItemIP(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ip" in value;

    return isInstance;
}

export function SecReviewItemIPFromJSON(json: any): SecReviewItemIP {
    return SecReviewItemIPFromJSONTyped(json, false);
}

export function SecReviewItemIPFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewItemIP {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : json['source'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'as_class_t': !exists(json, 'as_class_t') ? undefined : json['as_class_t'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'blocklisted': !exists(json, 'blocklisted') ? undefined : json['blocklisted'],
        'ports': !exists(json, 'ports') ? undefined : ((json['ports'] as Array<any>).map(SecReviewPortFromJSON)),
        'ip': json['ip'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
    };
}

export function SecReviewItemIPToJSON(value?: SecReviewItemIP | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'isp': value.isp,
        'as_name': value.as_name,
        'as_number': value.as_number,
        'as_class_t': value.as_class_t,
        'shared': value.shared,
        'blocklisted': value.blocklisted,
        'ports': value.ports === undefined ? undefined : ((value.ports as Array<any>).map(SecReviewPortToJSON)),
        'ip': value.ip,
        'domains': value.domains === undefined ? undefined : Array.from(value.domains as Set<any>),
    };
}

