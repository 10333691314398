/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
 */
export interface ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    person_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    title_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    average_hrs_per_wk_devoted_to_pos_rt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    compensation_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    employee_benefit_program_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    expense_account_other_allwnc_amt?: number;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl
     */
    business_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmplFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl {
    return ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmplFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmplFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'title_txt': !exists(json, 'title_txt') ? undefined : json['title_txt'],
        'average_hrs_per_wk_devoted_to_pos_rt': !exists(json, 'average_hrs_per_wk_devoted_to_pos_rt') ? undefined : json['average_hrs_per_wk_devoted_to_pos_rt'],
        'compensation_amt': !exists(json, 'compensation_amt') ? undefined : json['compensation_amt'],
        'employee_benefit_program_amt': !exists(json, 'employee_benefit_program_amt') ? undefined : json['employee_benefit_program_amt'],
        'expense_account_other_allwnc_amt': !exists(json, 'expense_account_other_allwnc_amt') ? undefined : json['expense_account_other_allwnc_amt'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
    };
}

export function ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmplToJSON(value?: ExecRiskAppCoreModelsScansIRS990EZOfficerDirectorTrusteeEmpl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'title_txt': value.title_txt,
        'average_hrs_per_wk_devoted_to_pos_rt': value.average_hrs_per_wk_devoted_to_pos_rt,
        'compensation_amt': value.compensation_amt,
        'employee_benefit_program_amt': value.employee_benefit_program_amt,
        'expense_account_other_allwnc_amt': value.expense_account_other_allwnc_amt,
        'business_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
    };
}

