/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupplementalFormInstanceStatus = {
    Created: 'CREATED',
    AccessedForm: 'ACCESSED_FORM',
    PartiallyCompleted: 'PARTIALLY_COMPLETED',
    Submitted: 'SUBMITTED',
    Invalidated: 'INVALIDATED',
    Expired: 'EXPIRED',
    Outdated: 'OUTDATED'
} as const;
export type SupplementalFormInstanceStatus = typeof SupplementalFormInstanceStatus[keyof typeof SupplementalFormInstanceStatus];


export function SupplementalFormInstanceStatusFromJSON(json: any): SupplementalFormInstanceStatus {
    return SupplementalFormInstanceStatusFromJSONTyped(json, false);
}

export function SupplementalFormInstanceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFormInstanceStatus {
    return json as SupplementalFormInstanceStatus;
}

export function SupplementalFormInstanceStatusToJSON(value?: SupplementalFormInstanceStatus | null): any {
    return value as any;
}

