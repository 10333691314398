import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Box, Grid, Typography } from '@explorer/core';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';
import { useUser } from '@explorer/stores';

const useStyles = ({}: Partial<ErrorStateProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        error: {
          color: 'indianred',
        },
      }),
    { index: 1 },
  )();

/**
 * ErrorState description.
 */
export const ErrorState = ({
  height = 360,
  errorCode,
  errorMsg = 'Something went wrong',
  hideContact = false,
}: ErrorStateProps) => {
  const classes = useStyles({});

  const { addError } = useUnsafeDataDogRum();
  const { user } = useUser();

  useEffect(() => {
    addError(new Error('Error State Shown'), {
      errorMessage: errorMsg,
      permissions: user?.permissions,
    });
  }, [addError, errorMsg, user?.permissions]);

  return (
    <Box height={height}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.error}
        height={height}
      >
        <Grid item>
          <CloudOffOutlinedIcon style={{ fontSize: '5rem' }} />
        </Grid>
        {errorCode && (
          <Grid item>
            <Typography variant="h1" fontSize="5rem">
              {errorCode}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="h3">{errorMsg}</Typography>
        </Grid>
        <Grid item>
          {!hideContact && (
            <Typography variant="bodyS" py={1}>
              Please contact the Customer Security Team
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export interface ErrorStateProps {
  height?: number;
  errorCode?: number | string;
  errorMsg?: string;
  hideContact?: boolean;
}
