import React from 'react';
import { ErrorCode, FileRejection } from 'react-dropzone';
import { Box, Typography } from '@explorer/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import bytes from 'bytes';
import { useIntl } from 'react-intl';

const ErrorMessageUpload = ({
  fileRejections,
  maxSize,
  filesLimit,
  fileFormatText,
}: {
  fileRejections: FileRejection[];
  maxSize: number;
  filesLimit: number;
  fileFormatText: string;
}) => {
  const errorType = fileRejections?.[0]?.errors?.[0]?.code;
  const intl = useIntl();
  let message = { id: '', val: '' };
  if (errorType === ErrorCode.FileTooLarge) {
    message = {
      id: 'forms-presets.upload.error-image-size',
      val: bytes.format(maxSize, { decimalPlaces: 0 }),
    };
  }
  if (errorType === ErrorCode.TooManyFiles) {
    message = {
      id: 'forms-presets.upload.error-file-limit',
      val: filesLimit.toString(),
    };
  }
  if (errorType === ErrorCode.FileInvalidType) {
    message = {
      id: 'forms-presets.upload.error-image-format',
      val: fileFormatText,
    };
  }

  return errorType ? (
    <Box display="flex" padding="16px 0px 0px">
      <ErrorOutlineIcon color="error" fontSize="small" />
      <Typography variant="bodyXS" color="error" style={{ padding: '0 8px' }}>
        {intl.formatMessage({ id: message?.id }, { number: message?.val })}
      </Typography>
    </Box>
  ) : null;
};

export default ErrorMessageUpload;
