/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAdminGetUserInfoResponse
 */
export interface ControlAdminGetUserInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGetUserInfoResponse
     */
    user_email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGetUserInfoResponse
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGetUserInfoResponse
     */
    client_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAdminGetUserInfoResponse
     */
    is_broker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAdminGetUserInfoResponse
     */
    is_active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminGetUserInfoResponse
     */
    policy_ids?: Array<string>;
}

/**
 * Check if a given object implements the ControlAdminGetUserInfoResponse interface.
 */
export function instanceOfControlAdminGetUserInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user_email" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "is_broker" in value;
    isInstance = isInstance && "is_active" in value;

    return isInstance;
}

export function ControlAdminGetUserInfoResponseFromJSON(json: any): ControlAdminGetUserInfoResponse {
    return ControlAdminGetUserInfoResponseFromJSONTyped(json, false);
}

export function ControlAdminGetUserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminGetUserInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_email': json['user_email'],
        'user_id': json['user_id'],
        'client_id': json['client_id'],
        'is_broker': json['is_broker'],
        'is_active': json['is_active'],
        'policy_ids': !exists(json, 'policy_ids') ? undefined : json['policy_ids'],
    };
}

export function ControlAdminGetUserInfoResponseToJSON(value?: ControlAdminGetUserInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_email': value.user_email,
        'user_id': value.user_id,
        'client_id': value.client_id,
        'is_broker': value.is_broker,
        'is_active': value.is_active,
        'policy_ids': value.policy_ids,
    };
}

