/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlMultiSelectFieldAnswer
 */
export interface ControlMultiSelectFieldAnswer {
    /**
     * 
     * @type {string}
     * @memberof ControlMultiSelectFieldAnswer
     */
    field_type?: ControlMultiSelectFieldAnswerFieldTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlMultiSelectFieldAnswer
     */
    selected: Array<string>;
}


/**
 * @export
 */
export const ControlMultiSelectFieldAnswerFieldTypeEnum = {
    Multiselect: 'multiselect'
} as const;
export type ControlMultiSelectFieldAnswerFieldTypeEnum = typeof ControlMultiSelectFieldAnswerFieldTypeEnum[keyof typeof ControlMultiSelectFieldAnswerFieldTypeEnum];


/**
 * Check if a given object implements the ControlMultiSelectFieldAnswer interface.
 */
export function instanceOfControlMultiSelectFieldAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "selected" in value;

    return isInstance;
}

export function ControlMultiSelectFieldAnswerFromJSON(json: any): ControlMultiSelectFieldAnswer {
    return ControlMultiSelectFieldAnswerFromJSONTyped(json, false);
}

export function ControlMultiSelectFieldAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMultiSelectFieldAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_type': !exists(json, 'field_type') ? undefined : json['field_type'],
        'selected': json['selected'],
    };
}

export function ControlMultiSelectFieldAnswerToJSON(value?: ControlMultiSelectFieldAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_type': value.field_type,
        'selected': value.selected,
    };
}

