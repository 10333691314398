/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFTech } from './CRSFTech';
import {
    CRSFTechFromJSON,
    CRSFTechFromJSONTyped,
    CRSFTechToJSON,
} from './CRSFTech';

/**
 * 
 * @export
 * @interface CRSFTechsPagedResponse
 */
export interface CRSFTechsPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof CRSFTechsPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFTechsPagedResponse
     */
    page_size?: number;
    /**
     * 
     * @type {Array<CRSFTech>}
     * @memberof CRSFTechsPagedResponse
     */
    results?: Array<CRSFTech>;
    /**
     * 
     * @type {number}
     * @memberof CRSFTechsPagedResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the CRSFTechsPagedResponse interface.
 */
export function instanceOfCRSFTechsPagedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFTechsPagedResponseFromJSON(json: any): CRSFTechsPagedResponse {
    return CRSFTechsPagedResponseFromJSONTyped(json, false);
}

export function CRSFTechsPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTechsPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(CRSFTechFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function CRSFTechsPagedResponseToJSON(value?: CRSFTechsPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(CRSFTechToJSON)),
        'total': value.total,
    };
}

