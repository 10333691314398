/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityNotificationsOrderBy = {
    EntityId: 'entity_id',
    NotificationMedia: 'notification_media',
    NotificationRecipient: 'notification_recipient',
    Recipient: 'recipient',
    Status: 'status',
    StatusExternal: 'status_external',
    Reason: 'reason',
    SentAt: 'sent_at',
    Created: 'created'
} as const;
export type ControlEntityNotificationsOrderBy = typeof ControlEntityNotificationsOrderBy[keyof typeof ControlEntityNotificationsOrderBy];


export function ControlEntityNotificationsOrderByFromJSON(json: any): ControlEntityNotificationsOrderBy {
    return ControlEntityNotificationsOrderByFromJSONTyped(json, false);
}

export function ControlEntityNotificationsOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityNotificationsOrderBy {
    return json as ControlEntityNotificationsOrderBy;
}

export function ControlEntityNotificationsOrderByToJSON(value?: ControlEntityNotificationsOrderBy | null): any {
    return value as any;
}

