/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitResolutionMetadata } from './MonitResolutionMetadata';
import {
    MonitResolutionMetadataFromJSON,
    MonitResolutionMetadataFromJSONTyped,
    MonitResolutionMetadataToJSON,
} from './MonitResolutionMetadata';
import type { MonitResolutionRequestScope } from './MonitResolutionRequestScope';
import {
    MonitResolutionRequestScopeFromJSON,
    MonitResolutionRequestScopeFromJSONTyped,
    MonitResolutionRequestScopeToJSON,
} from './MonitResolutionRequestScope';
import type { MonitResolutionRequestStatus } from './MonitResolutionRequestStatus';
import {
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusFromJSONTyped,
    MonitResolutionRequestStatusToJSON,
} from './MonitResolutionRequestStatus';

/**
 * 
 * @export
 * @interface MonitFindingResolutionRequest
 */
export interface MonitFindingResolutionRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    datapoint?: string;
    /**
     * 
     * @type {MonitResolutionRequestStatus}
     * @memberof MonitFindingResolutionRequest
     */
    status?: MonitResolutionRequestStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitFindingResolutionRequest
     */
    assets?: Array<string>;
    /**
     * 
     * @type {MonitResolutionRequestScope}
     * @memberof MonitFindingResolutionRequest
     */
    scope: MonitResolutionRequestScope;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    requested_by?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitFindingResolutionRequest
     */
    skip_notification?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    request_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    reviewed_by?: string;
    /**
     * 
     * @type {MonitResolutionMetadata}
     * @memberof MonitFindingResolutionRequest
     */
    resolution_metadata?: MonitResolutionMetadata;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutionRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the MonitFindingResolutionRequest interface.
 */
export function instanceOfMonitFindingResolutionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scope" in value;

    return isInstance;
}

export function MonitFindingResolutionRequestFromJSON(json: any): MonitFindingResolutionRequest {
    return MonitFindingResolutionRequestFromJSONTyped(json, false);
}

export function MonitFindingResolutionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitFindingResolutionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'status': !exists(json, 'status') ? undefined : MonitResolutionRequestStatusFromJSON(json['status']),
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'scope': MonitResolutionRequestScopeFromJSON(json['scope']),
        'requested_by': !exists(json, 'requested_by') ? undefined : json['requested_by'],
        'skip_notification': !exists(json, 'skip_notification') ? undefined : json['skip_notification'],
        'request_id': !exists(json, 'request_id') ? undefined : json['request_id'],
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'resolution_metadata': !exists(json, 'resolution_metadata') ? undefined : MonitResolutionMetadataFromJSON(json['resolution_metadata']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function MonitFindingResolutionRequestToJSON(value?: MonitFindingResolutionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'datapoint': value.datapoint,
        'status': MonitResolutionRequestStatusToJSON(value.status),
        'assets': value.assets,
        'scope': MonitResolutionRequestScopeToJSON(value.scope),
        'requested_by': value.requested_by,
        'skip_notification': value.skip_notification,
        'request_id': value.request_id,
        'reviewed_by': value.reviewed_by,
        'resolution_metadata': MonitResolutionMetadataToJSON(value.resolution_metadata),
        'name': value.name,
    };
}

