import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { CoreProps, CorePropsKeys } from './types';

export const coreKeys: CorePropsKeys[] = [
  'm',
  'mt',
  'mr',
  'mb',
  'ml',
  'mx',
  'my',
  'p',
  'pt',
  'pr',
  'pb',
  'pl',
  'px',
  'py',
  'width',
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  'fontSize',
  'fontWeight',
  'letterSpacing',
  'textAlign',
  'textTransform',
  'wordBreak',
  'bgColor',
  'textColor',
  'borderColor',
  'borderRadius',
  'borderWidth',
  'borderStyle',
  'zIndex',
  'noUserSelect',
];

export const useCoreStyles = ({
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  fontSize,
  fontWeight,
  letterSpacing,
  textAlign,
  textTransform,
  wordBreak,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  borderWidth,
  borderStyle,
  zIndex,
  noUserSelect,
}: Partial<CoreProps>) =>
  makeStyles(
    ({ spacing }: Theme) =>
      createStyles({
        BE: {
          marginBottom: my ? spacing(my) : mb ? spacing(mb) : undefined,
          marginLeft: mx ? spacing(mx) : ml ? spacing(ml) : undefined,
          marginRight: mx ? spacing(mx) : mr ? spacing(mr) : undefined,
          marginTop: my ? spacing(my) : mt ? spacing(mt) : undefined,
          margin: m ? spacing(m) : undefined,
          paddingBottom: py ? spacing(py) : pb ? spacing(pb) : undefined,
          paddingLeft: px ? spacing(px) : pl ? spacing(pl) : undefined,
          paddingRight: px ? spacing(px) : pr ? spacing(pr) : undefined,
          paddingTop: py ? spacing(py) : pt ? spacing(pt) : undefined,
          padding: p ? spacing(p) : undefined,
          width: width || undefined,
          maxWidth: maxWidth || undefined,
          minWidth: minWidth || undefined,
          height: height || undefined,
          maxHeight: maxHeight || undefined,
          minHeight: minHeight || undefined,
          fontSize: fontSize || undefined,
          fontWeight: fontWeight === 'semib' ? 600 : fontWeight || undefined,
          letterSpacing: letterSpacing || undefined,
          textAlign: textAlign || undefined,
          textTransform: textTransform || undefined,
          wordBreak: wordBreak || undefined,
          color: textColor || undefined,
          backgroundColor: bgColor || undefined,
          borderColor: borderColor || undefined,
          borderRadius: borderRadius || undefined,
          borderWidth: borderWidth || undefined,
          borderStyle: borderStyle || undefined,
          zIndex: zIndex || undefined,
          userSelect: noUserSelect ? 'none' : undefined,
        },
      }),
    { index: 1 },
  )();
