import { useState, useCallback } from 'react';

/**
 * Forces a render on a component.
 *
 * __> Example:__
 * ```tsx
 * const { renderKey, forceRender } = useForceRender();
 *
 * return (
 *   <Component>
 *     <ClickMe onClick={forceRender}>
 *     <RenderMeAgain key={renderKey} />
 *   </Component>
 * )
 * ```
 */

export const useForceRender: UseForceRender = () => {
  const [renderKey, setRenderKey] = useState<'B' | 'E'>('B');

  const forceRender = useCallback(
    () => setRenderKey((v) => (v === 'B' ? 'E' : 'B')),
    [],
  );

  return {
    renderKey,
    forceRender,
  };
};

export type UseForceRender = () => {
  renderKey: string;
  forceRender: () => void;
};
