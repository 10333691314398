/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFDocumentType = {
    Cra: 'cra',
    Comment: 'comment',
    Index: 'index',
    Report: 'report',
    WiretappingTechsCsv: 'wiretapping_techs_csv',
    CriticalFindingsCsv: 'critical_findings_csv',
    CompanyHierarchyCsv: 'company_hierarchy_csv',
    Txt: 'txt',
    Json: 'json',
    PostedDecisionsJson: 'posted_decisions_json',
    GroupedAcceptedDecisionsJson: 'grouped_accepted_decisions_json',
    GroupedFullDecisionsJson: 'grouped_full_decisions_json'
} as const;
export type CRSFDocumentType = typeof CRSFDocumentType[keyof typeof CRSFDocumentType];


export function CRSFDocumentTypeFromJSON(json: any): CRSFDocumentType {
    return CRSFDocumentTypeFromJSONTyped(json, false);
}

export function CRSFDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDocumentType {
    return json as CRSFDocumentType;
}

export function CRSFDocumentTypeToJSON(value?: CRSFDocumentType | null): any {
    return value as any;
}

