/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlSurvey } from './ControlSurvey';
import {
    ControlSurveyFromJSON,
    ControlSurveyFromJSONTyped,
    ControlSurveyToJSON,
} from './ControlSurvey';

/**
 * 
 * @export
 * @interface ControlSaveSurveyResponseRequest
 */
export interface ControlSaveSurveyResponseRequest {
    /**
     * 
     * @type {ControlSurvey}
     * @memberof ControlSaveSurveyResponseRequest
     */
    survey: ControlSurvey;
    /**
     * 
     * @type {string}
     * @memberof ControlSaveSurveyResponseRequest
     */
    answer_text: string;
}

/**
 * Check if a given object implements the ControlSaveSurveyResponseRequest interface.
 */
export function instanceOfControlSaveSurveyResponseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "survey" in value;
    isInstance = isInstance && "answer_text" in value;

    return isInstance;
}

export function ControlSaveSurveyResponseRequestFromJSON(json: any): ControlSaveSurveyResponseRequest {
    return ControlSaveSurveyResponseRequestFromJSONTyped(json, false);
}

export function ControlSaveSurveyResponseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSaveSurveyResponseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'survey': ControlSurveyFromJSON(json['survey']),
        'answer_text': json['answer_text'],
    };
}

export function ControlSaveSurveyResponseRequestToJSON(value?: ControlSaveSurveyResponseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'survey': ControlSurveyToJSON(value.survey),
        'answer_text': value.answer_text,
    };
}

