import React from 'react';
import clsx from 'clsx';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Tab = withCoreProps<TabProps>(
  ({ children, className, ...props }: React.PropsWithChildren<TabProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiTab {...props} className={clsxClass}>
        {children}
      </MuiTab>
    );
  },
);

export interface TabProps extends MuiTabProps, CoreProps {}
