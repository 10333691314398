/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskExperianLien
 */
export interface ExecRiskExperianLien {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    dateFiled?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    legalType?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    legalAction?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    filingLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    liabilityAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskExperianLien
     */
    owner?: string;
}

/**
 * Check if a given object implements the ExecRiskExperianLien interface.
 */
export function instanceOfExecRiskExperianLien(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskExperianLienFromJSON(json: any): ExecRiskExperianLien {
    return ExecRiskExperianLienFromJSONTyped(json, false);
}

export function ExecRiskExperianLienFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskExperianLien {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateFiled': !exists(json, 'dateFiled') ? undefined : json['dateFiled'],
        'legalType': !exists(json, 'legalType') ? undefined : json['legalType'],
        'legalAction': !exists(json, 'legalAction') ? undefined : json['legalAction'],
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'filingLocation': !exists(json, 'filingLocation') ? undefined : json['filingLocation'],
        'liabilityAmount': !exists(json, 'liabilityAmount') ? undefined : json['liabilityAmount'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
    };
}

export function ExecRiskExperianLienToJSON(value?: ExecRiskExperianLien | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateFiled': value.dateFiled,
        'legalType': value.legalType,
        'legalAction': value.legalAction,
        'documentNumber': value.documentNumber,
        'filingLocation': value.filingLocation,
        'liabilityAmount': value.liabilityAmount,
        'owner': value.owner,
    };
}

