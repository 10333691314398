/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskRiskFlag
 */
export interface ExecRiskRiskFlag {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskRiskFlag
     */
    risk_tag: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskRiskFlag
     */
    flag: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskRiskFlag
     */
    secondary: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskRiskFlag
     */
    auto_decline: boolean;
}

/**
 * Check if a given object implements the ExecRiskRiskFlag interface.
 */
export function instanceOfExecRiskRiskFlag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "risk_tag" in value;
    isInstance = isInstance && "flag" in value;
    isInstance = isInstance && "secondary" in value;
    isInstance = isInstance && "auto_decline" in value;

    return isInstance;
}

export function ExecRiskRiskFlagFromJSON(json: any): ExecRiskRiskFlag {
    return ExecRiskRiskFlagFromJSONTyped(json, false);
}

export function ExecRiskRiskFlagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskRiskFlag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'risk_tag': json['risk_tag'],
        'flag': json['flag'],
        'secondary': json['secondary'],
        'auto_decline': json['auto_decline'],
    };
}

export function ExecRiskRiskFlagToJSON(value?: ExecRiskRiskFlag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'risk_tag': value.risk_tag,
        'flag': value.flag,
        'secondary': value.secondary,
        'auto_decline': value.auto_decline,
    };
}

