/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewExchangeOWA
 */
export interface SecReviewExchangeOWA {
    /**
     * 
     * @type {string}
     * @memberof SecReviewExchangeOWA
     */
    status?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof SecReviewExchangeOWA
     */
    server_names?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof SecReviewExchangeOWA
     */
    version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewExchangeOWA
     */
    ecp_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewExchangeOWA
     */
    ews_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewExchangeOWA
     */
    rpc_enabled?: boolean;
}

/**
 * Check if a given object implements the SecReviewExchangeOWA interface.
 */
export function instanceOfSecReviewExchangeOWA(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewExchangeOWAFromJSON(json: any): SecReviewExchangeOWA {
    return SecReviewExchangeOWAFromJSONTyped(json, false);
}

export function SecReviewExchangeOWAFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewExchangeOWA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'server_names': !exists(json, 'server_names') ? undefined : json['server_names'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'ecp_enabled': !exists(json, 'ecp_enabled') ? undefined : json['ecp_enabled'],
        'ews_enabled': !exists(json, 'ews_enabled') ? undefined : json['ews_enabled'],
        'rpc_enabled': !exists(json, 'rpc_enabled') ? undefined : json['rpc_enabled'],
    };
}

export function SecReviewExchangeOWAToJSON(value?: SecReviewExchangeOWA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'server_names': value.server_names,
        'version': value.version,
        'ecp_enabled': value.ecp_enabled,
        'ews_enabled': value.ews_enabled,
        'rpc_enabled': value.rpc_enabled,
    };
}

