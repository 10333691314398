/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990ReturnData } from './ExecRiskAppCoreModelsScansForm990ReturnData';
import {
    ExecRiskAppCoreModelsScansForm990ReturnDataFromJSON,
    ExecRiskAppCoreModelsScansForm990ReturnDataFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990ReturnDataToJSON,
} from './ExecRiskAppCoreModelsScansForm990ReturnData';
import type { ExecRiskBaseFilingHeader } from './ExecRiskBaseFilingHeader';
import {
    ExecRiskBaseFilingHeaderFromJSON,
    ExecRiskBaseFilingHeaderFromJSONTyped,
    ExecRiskBaseFilingHeaderToJSON,
} from './ExecRiskBaseFilingHeader';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990
 */
export interface ExecRiskAppCoreModelsScansForm990 {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990
     */
    irs_object_id: string;
    /**
     * 
     * @type {ExecRiskBaseFilingHeader}
     * @memberof ExecRiskAppCoreModelsScansForm990
     */
    return_header?: ExecRiskBaseFilingHeader;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990ReturnData}
     * @memberof ExecRiskAppCoreModelsScansForm990
     */
    return_data?: ExecRiskAppCoreModelsScansForm990ReturnData;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990 interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "irs_object_id" in value;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990FromJSON(json: any): ExecRiskAppCoreModelsScansForm990 {
    return ExecRiskAppCoreModelsScansForm990FromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'irs_object_id': json['irs_object_id'],
        'return_header': !exists(json, 'return_header') ? undefined : ExecRiskBaseFilingHeaderFromJSON(json['return_header']),
        'return_data': !exists(json, 'return_data') ? undefined : ExecRiskAppCoreModelsScansForm990ReturnDataFromJSON(json['return_data']),
    };
}

export function ExecRiskAppCoreModelsScansForm990ToJSON(value?: ExecRiskAppCoreModelsScansForm990 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'irs_object_id': value.irs_object_id,
        'return_header': ExecRiskBaseFilingHeaderToJSON(value.return_header),
        'return_data': ExecRiskAppCoreModelsScansForm990ReturnDataToJSON(value.return_data),
    };
}

