/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZeroDayAlertSearchResult } from './ControlZeroDayAlertSearchResult';
import {
    ControlZeroDayAlertSearchResultFromJSON,
    ControlZeroDayAlertSearchResultFromJSONTyped,
    ControlZeroDayAlertSearchResultToJSON,
} from './ControlZeroDayAlertSearchResult';

/**
 * 
 * @export
 * @interface ControlGetZeroDayAlertsResponse
 */
export interface ControlGetZeroDayAlertsResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlGetZeroDayAlertsResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGetZeroDayAlertsResponse
     */
    page_size: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGetZeroDayAlertsResponse
     */
    count: number;
    /**
     * 
     * @type {Array<ControlZeroDayAlertSearchResult>}
     * @memberof ControlGetZeroDayAlertsResponse
     */
    results: Array<ControlZeroDayAlertSearchResult>;
}

/**
 * Check if a given object implements the ControlGetZeroDayAlertsResponse interface.
 */
export function instanceOfControlGetZeroDayAlertsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ControlGetZeroDayAlertsResponseFromJSON(json: any): ControlGetZeroDayAlertsResponse {
    return ControlGetZeroDayAlertsResponseFromJSONTyped(json, false);
}

export function ControlGetZeroDayAlertsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetZeroDayAlertsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'page_size': json['page_size'],
        'count': json['count'],
        'results': ((json['results'] as Array<any>).map(ControlZeroDayAlertSearchResultFromJSON)),
    };
}

export function ControlGetZeroDayAlertsResponseToJSON(value?: ControlGetZeroDayAlertsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': ((value.results as Array<any>).map(ControlZeroDayAlertSearchResultToJSON)),
    };
}

