/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlAuthenticationFactor = {
    Otp: 'otp',
    OobEmail: 'oob_email',
    RecoveryCode: 'recovery_code'
} as const;
export type ControlAuthenticationFactor = typeof ControlAuthenticationFactor[keyof typeof ControlAuthenticationFactor];


export function ControlAuthenticationFactorFromJSON(json: any): ControlAuthenticationFactor {
    return ControlAuthenticationFactorFromJSONTyped(json, false);
}

export function ControlAuthenticationFactorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAuthenticationFactor {
    return json as ControlAuthenticationFactor;
}

export function ControlAuthenticationFactorToJSON(value?: ControlAuthenticationFactor | null): any {
    return value as any;
}

