/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlSupplementalFormAttachment } from './ControlSupplementalFormAttachment';
import {
    ControlSupplementalFormAttachmentFromJSON,
    ControlSupplementalFormAttachmentFromJSONTyped,
    ControlSupplementalFormAttachmentToJSON,
} from './ControlSupplementalFormAttachment';
import type { ControlSupplementalFormStatus } from './ControlSupplementalFormStatus';
import {
    ControlSupplementalFormStatusFromJSON,
    ControlSupplementalFormStatusFromJSONTyped,
    ControlSupplementalFormStatusToJSON,
} from './ControlSupplementalFormStatus';
import type { ControlSupplementalFormType } from './ControlSupplementalFormType';
import {
    ControlSupplementalFormTypeFromJSON,
    ControlSupplementalFormTypeFromJSONTyped,
    ControlSupplementalFormTypeToJSON,
} from './ControlSupplementalFormType';

/**
 * 
 * @export
 * @interface ControlSupplementalFormInstanceWithAttachment
 */
export interface ControlSupplementalFormInstanceWithAttachment {
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    form_instance_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    recipient_id: string;
    /**
     * 
     * @type {object}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    form_data?: object;
    /**
     * 
     * @type {ControlSupplementalFormType}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    form_type: ControlSupplementalFormType;
    /**
     * 
     * @type {boolean}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    is_active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    is_internal: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    internal_email: string;
    /**
     * 
     * @type {ControlSupplementalFormStatus}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    form_instance_status: ControlSupplementalFormStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    submitted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    form_instance_link?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    jwt_token?: string;
    /**
     * 
     * @type {Array<ControlSupplementalFormAttachment>}
     * @memberof ControlSupplementalFormInstanceWithAttachment
     */
    attachments: Array<ControlSupplementalFormAttachment>;
}

/**
 * Check if a given object implements the ControlSupplementalFormInstanceWithAttachment interface.
 */
export function instanceOfControlSupplementalFormInstanceWithAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "form_instance_id" in value;
    isInstance = isInstance && "recipient_id" in value;
    isInstance = isInstance && "form_type" in value;
    isInstance = isInstance && "is_active" in value;
    isInstance = isInstance && "is_internal" in value;
    isInstance = isInstance && "internal_email" in value;
    isInstance = isInstance && "form_instance_status" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "updated_at" in value;
    isInstance = isInstance && "attachments" in value;

    return isInstance;
}

export function ControlSupplementalFormInstanceWithAttachmentFromJSON(json: any): ControlSupplementalFormInstanceWithAttachment {
    return ControlSupplementalFormInstanceWithAttachmentFromJSONTyped(json, false);
}

export function ControlSupplementalFormInstanceWithAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSupplementalFormInstanceWithAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_instance_id': json['form_instance_id'],
        'recipient_id': json['recipient_id'],
        'form_data': !exists(json, 'form_data') ? undefined : json['form_data'],
        'form_type': ControlSupplementalFormTypeFromJSON(json['form_type']),
        'is_active': json['is_active'],
        'is_internal': json['is_internal'],
        'internal_email': json['internal_email'],
        'form_instance_status': ControlSupplementalFormStatusFromJSON(json['form_instance_status']),
        'submitted_at': !exists(json, 'submitted_at') ? undefined : json['submitted_at'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'form_instance_link': !exists(json, 'form_instance_link') ? undefined : json['form_instance_link'],
        'jwt_token': !exists(json, 'jwt_token') ? undefined : json['jwt_token'],
        'attachments': ((json['attachments'] as Array<any>).map(ControlSupplementalFormAttachmentFromJSON)),
    };
}

export function ControlSupplementalFormInstanceWithAttachmentToJSON(value?: ControlSupplementalFormInstanceWithAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_instance_id': value.form_instance_id,
        'recipient_id': value.recipient_id,
        'form_data': value.form_data,
        'form_type': ControlSupplementalFormTypeToJSON(value.form_type),
        'is_active': value.is_active,
        'is_internal': value.is_internal,
        'internal_email': value.internal_email,
        'form_instance_status': ControlSupplementalFormStatusToJSON(value.form_instance_status),
        'submitted_at': value.submitted_at,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'form_instance_link': value.form_instance_link,
        'jwt_token': value.jwt_token,
        'attachments': ((value.attachments as Array<any>).map(ControlSupplementalFormAttachmentToJSON)),
    };
}

