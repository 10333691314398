import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Auth0Provider, OAuthError, useAuth0 } from '@auth0/auth0-react';
import { appConfig } from '@explorer/src';
import { useUser } from '../UserStore';
import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';
import { useRouter } from 'next/router';

/**
 * useAuth
 */
export const useAuth = () => {
  const auth0 = useAuth0();
  const { isAuthenticated, isLoading, error } = auth0;

  const { addError, addAction } = useUnsafeDataDogRum();
  const { setUser, resetUser, setUserAuthenticated } = useUser();

  const router = useRouter();

  /**
   * Logout
   *
   * Cleanup the access token and redirect to
   * the auth0 login page.
   */
  const logout = useCallback(() => {
    setUserAuthenticated(false);
    global.localStorage.clear();
    auth0.logout({ returnTo: process.env.APP_DOMAIN });
    resetUser();
  }, [auth0, resetUser, setUserAuthenticated]);

  const onTokenRetrievalSuccess = useCallback(
    ({ access_token, id_token }) => {
      setUser({ access_token, id_token });
      setUserAuthenticated(true);
      global.localStorage.setItem('bearer', access_token);
    },
    [setUser, setUserAuthenticated],
  );

  const isHandlingRedirect = useMemo(() => {
    return !!router.query?.code || !!router.query?.state;
  }, [router.query?.code, router.query?.state]);

  useEffect(() => {
    if (isLoading) {
      /* Wait for Auth0 to have initialized */
      return;
    }

    if (error) {
      const wrappedError = new Error('Auth0 error');
      wrappedError.cause = wrappedError;

      addError(wrappedError);
      return;
    }

    if (isAuthenticated) {
      /* If Auth0 claims we are authenticated, we should be able to retrieve the token from cache. */
      auth0
        .getAccessTokenSilently({
          ignoreCache: false,
          detailedResponse: true,
        })
        .then(onTokenRetrievalSuccess)
        .catch((e) => {
          /* If we fail to retrieve the token while already authenticated, we've run into an edge case with has been observed in the logs. Trigger a redirect login. */

          addAction(
            'Failed to retrieve token while authenticated. Using redirect fallback',
            {
              silentLoginError: e,
              errorCode: (e as OAuthError).error,
            },
          );

          const url = new URL(window.location.href);
          url.searchParams.delete('code');
          url.searchParams.delete('state');

          // Otherwise, trigger a redirect within the browser to Auth0
          return auth0.loginWithRedirect({
            redirectUri: `${process.env.APP_DOMAIN}`,
            appState: {
              returnTo: url.pathname + url.search,
            },
          });
        });

      return;
    }

    /* If we are not authenticated, we should try to silently retrieve our access token. This method uses iframes + 3rd party cross domain cookies, and will fail in browsers with higher privacy settings */
    auth0
      .getAccessTokenSilently({
        ignoreCache: true,
        detailedResponse: true,
      })
      .then(onTokenRetrievalSuccess)

      /* If we do fail to authenticate silently, and will need to use login redirect. This is an expected error, but we can log occurrences to better understand this flow */
      .catch((e) => {
        addAction('Using Auth0 redirect fallback', { silentLoginError: e });

        // Prepare the redirect url after login is successful. Ensure that we don't have code or state query params in that redirect url. Otherwise our app logic will think that we are in the middle of a login flow and enter into an infinite loop.
        const url = new URL(window.location.href);
        url.searchParams.delete('code');
        url.searchParams.delete('state');

        // Otherwise, trigger a redirect within the browser to Auth0
        return auth0.loginWithRedirect({
          redirectUri: `${process.env.APP_DOMAIN}`,
          appState: {
            returnTo: url.pathname + url.search,
          },
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isAuthenticated, isLoading]);

  return useMemo(
    () => ({
      logout,
      isLoading: isLoading || !router.isReady || isHandlingRedirect,
      isAuthenticated,
    }),
    [logout, isLoading, router.isReady, isHandlingRedirect, isAuthenticated],
  );
};

/**
 * AuthProvider
 */
export const AuthProvider = memo(
  //
  function AuthProvider({ children }: AuthProviderProps) {
    const { AUTH_0 } = appConfig;
    const router = useRouter();

    return (
      <Auth0Provider
        clientId={AUTH_0?.clientId ?? ''}
        domain={AUTH_0?.domain ?? ''}
        cacheLocation="localstorage"
        redirectUri={`${process.env.APP_DOMAIN}`}
        onRedirectCallback={(appState) => {
          router.replace(appState?.returnTo ?? '/');
        }}
      >
        {children}
      </Auth0Provider>
    );
  },
);

AuthProvider.displayName = 'AuthProvider';

/**
 * AuthProviderProps
 */
interface AuthProviderProps {
  readonly children: JSX.Element | null;
}
