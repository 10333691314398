/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewAggregationFindingAssets
 */
export interface CRSFSecReviewAggregationFindingAssets {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewAggregationFindingAssets
     */
    asset: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewAggregationFindingAssets
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewAggregationFindingAssets
     */
    port: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewAggregationFindingAssets
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewAggregationFindingAssets
     */
    source?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewAggregationFindingAssets interface.
 */
export function instanceOfCRSFSecReviewAggregationFindingAssets(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "shared" in value;

    return isInstance;
}

export function CRSFSecReviewAggregationFindingAssetsFromJSON(json: any): CRSFSecReviewAggregationFindingAssets {
    return CRSFSecReviewAggregationFindingAssetsFromJSONTyped(json, false);
}

export function CRSFSecReviewAggregationFindingAssetsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewAggregationFindingAssets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'found': json['found'],
        'port': json['port'],
        'shared': json['shared'],
        'source': !exists(json, 'source') ? undefined : json['source'],
    };
}

export function CRSFSecReviewAggregationFindingAssetsToJSON(value?: CRSFSecReviewAggregationFindingAssets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'found': value.found,
        'port': value.port,
        'shared': value.shared,
        'source': value.source,
    };
}

