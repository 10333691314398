import React, { useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import { endpoints, useQuery } from '@explorer/api';
import { Grid } from '@explorer/core';
import { FormFieldControl } from '@explorer/forms';
import { RadioOptions } from '@explorer/forms/base/FieldRadio';
import { FormDialog, FormDialogProps } from '@explorer/forms/base/FormDialog';
import { useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { useIntl } from 'react-intl';
import {
  ControlReportedAssetStatus,
  MonitEntityReportedAssetResult,
} from '@explorer/open-api-client';

const FORM_ID = 'FormManageSecReviewRequest';
const INITIAL_FORM_VALUES: FormValues = {
  resolution_notes: '',
  status: ControlReportedAssetStatus.FalsePositiveConfirmed,
};
const STATUS_OPTIONS: RadioOptions = [
  {
    label: { id: 'forms-presets.approve' },
    value: ControlReportedAssetStatus.FalsePositiveConfirmed,
  },
  {
    label: { id: 'forms-presets.reject' },
    value: ControlReportedAssetStatus.FalsePositiveRejected,
  },
  {
    label: { id: 'forms-presets.cancel' },
    value: ControlReportedAssetStatus.Cancelled,
  },
];

const buildBody = (
  requests: MonitEntityReportedAssetResult[],
  values: { resolution_notes: string; status: ControlReportedAssetStatus },
) => {
  return requests.map((item) => ({
    status: values.status,
    reason_internal: values.resolution_notes,
    external_id: item.external_id,
    entity_id: item.entity_id,
    asset: item.asset,
  }));
};

const getTitle = (requests: MonitEntityReportedAssetResult[]) => ({
  id: 'forms-presets.manage-secreview-request-select',
  values: {
    size: requests?.length,
  },
});
/**
 * ManageSecReviewRequest description.
 */
export const ManageSecReviewAssetRemovals = ({
  handleClose,
  maxWidth = 'sm',
  refresh,
  requests,
}: ManageSecReviewAssetRemovalsProps) => {
  const { snackError, snackSuccess } = useQuerySnackbar();
  const intl = useIntl();
  const queryManageRequest = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1monit['/assets/reported'](),
    method: 'PUT',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    validationSchema: validationSchema(intl),
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      queryManageRequest
        .submit({
          payload: buildBody(requests, values),
        })
        .then(({ error }) => {
          if (error) {
            snackError('Manage SecReview Error', error);
          } else {
            snackSuccess('Manage SecReview Success');
            refresh();
            handleClose();
            resetForm();
          }
        })
        .finally(() => setSubmitting(false));
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId: FORM_ID,
        formik,
        options: {
          statusOptions: STATUS_OPTIONS,
        },
      }),
    [formik],
  );

  return (
    <FormDialog
      title={getTitle(requests)}
      formId={FORM_ID}
      formik={formik}
      open
      handleClose={handleClose}
      maxWidth={maxWidth}
      submitButton
      resetButton
      loading={formik.isSubmitting}
      stopPropagation
      subTitle={{ id: 'forms-presets.manage-secreview-subtitle' }}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.status} />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.resolution_notes} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface ManageSecReviewAssetRemovalsProps
  extends Pick<FormDialogProps, 'handleClose' | 'maxWidth'> {
  refresh: () => void;
  requests: MonitEntityReportedAssetResult[];
}
