import { useState, useMemo, useContext, createContext } from 'react';

interface URLParams {
  domain: string;
  processId: string;
}

export interface RiskAssessmentContextType {
  hasEntity: boolean;
  entity: any;
  setEntity: (data: any) => void;
  urlParams: URLParams;
  setUrlParams: (params: URLParams) => void;
}

const initialState: RiskAssessmentContextType = {
  hasEntity: false,
  entity: {},
  setEntity: () => true,
  urlParams: { domain: '', processId: '' },
  setUrlParams: () => true,
};

export const RiskAssessmentContext =
  createContext<RiskAssessmentContextType>(initialState);

export const useRiskAssessmentProvider = (): RiskAssessmentContextType => {
  const [entity, setEntity] = useState<any>(initialState.entity);
  const [urlParams, setUrlParams] = useState<URLParams>(initialState.urlParams);

  const provider = useMemo<RiskAssessmentContextType>(
    () => ({
      hasEntity: !!entity,
      entity,
      setEntity: (data) => {
        setEntity(data);
      },
      urlParams,
      setUrlParams: (params) => {
        setUrlParams(params);
      },
    }),
    [entity, urlParams],
  );

  return provider;
};

export const useRiskAssessmentContext = (): RiskAssessmentContextType => {
  return useContext(RiskAssessmentContext);
};
