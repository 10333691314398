/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplementalServiceHealthz
 */
export interface SupplementalServiceHealthz {
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceHealthz
     */
    state: SupplementalServiceHealthzStateEnum;
}


/**
 * @export
 */
export const SupplementalServiceHealthzStateEnum = {
    Init: 'INIT',
    Terminating: 'TERMINATING',
    Ok: 'OK',
    Degraded: 'DEGRADED',
    Failed: 'FAILED'
} as const;
export type SupplementalServiceHealthzStateEnum = typeof SupplementalServiceHealthzStateEnum[keyof typeof SupplementalServiceHealthzStateEnum];


/**
 * Check if a given object implements the SupplementalServiceHealthz interface.
 */
export function instanceOfSupplementalServiceHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function SupplementalServiceHealthzFromJSON(json: any): SupplementalServiceHealthz {
    return SupplementalServiceHealthzFromJSONTyped(json, false);
}

export function SupplementalServiceHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalServiceHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
    };
}

export function SupplementalServiceHealthzToJSON(value?: SupplementalServiceHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
    };
}

