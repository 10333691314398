import React from 'react';
import clsx from 'clsx';
import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const DialogContent = withCoreProps<DialogContentProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<DialogContentProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiDialogContent {...props} className={clsxClass}>
        {children}
      </MuiDialogContent>
    );
  },
);

export interface DialogContentProps extends MuiDialogContentProps, CoreProps {}
