/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAssetRemovalReasonCode } from './ControlAssetRemovalReasonCode';
import {
    ControlAssetRemovalReasonCodeFromJSON,
    ControlAssetRemovalReasonCodeFromJSONTyped,
    ControlAssetRemovalReasonCodeToJSON,
} from './ControlAssetRemovalReasonCode';

/**
 * 
 * @export
 * @interface ControlReportedAssetRequest
 */
export interface ControlReportedAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlReportedAssetRequest
     */
    reason?: string;
    /**
     * 
     * @type {ControlAssetRemovalReasonCode}
     * @memberof ControlReportedAssetRequest
     */
    code: ControlAssetRemovalReasonCode;
    /**
     * 
     * @type {string}
     * @memberof ControlReportedAssetRequest
     */
    requested_by?: string;
}

/**
 * Check if a given object implements the ControlReportedAssetRequest interface.
 */
export function instanceOfControlReportedAssetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function ControlReportedAssetRequestFromJSON(json: any): ControlReportedAssetRequest {
    return ControlReportedAssetRequestFromJSONTyped(json, false);
}

export function ControlReportedAssetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlReportedAssetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'code': ControlAssetRemovalReasonCodeFromJSON(json['code']),
        'requested_by': !exists(json, 'requested_by') ? undefined : json['requested_by'],
    };
}

export function ControlReportedAssetRequestToJSON(value?: ControlReportedAssetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'code': ControlAssetRemovalReasonCodeToJSON(value.code),
        'requested_by': value.requested_by,
    };
}

