/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitResolutionRequestScope } from './MonitResolutionRequestScope';
import {
    MonitResolutionRequestScopeFromJSON,
    MonitResolutionRequestScopeFromJSONTyped,
    MonitResolutionRequestScopeToJSON,
} from './MonitResolutionRequestScope';
import type { MonitResolutionRequestStatus } from './MonitResolutionRequestStatus';
import {
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusFromJSONTyped,
    MonitResolutionRequestStatusToJSON,
} from './MonitResolutionRequestStatus';

/**
 * 
 * @export
 * @interface MonitFindingResolutions
 */
export interface MonitFindingResolutions {
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    external_id: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    reason: string;
    /**
     * 
     * @type {MonitResolutionRequestScope}
     * @memberof MonitFindingResolutions
     */
    scope: MonitResolutionRequestScope;
    /**
     * 
     * @type {MonitResolutionRequestStatus}
     * @memberof MonitFindingResolutions
     */
    status: MonitResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitFindingResolutions
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitFindingResolutions interface.
 */
export function instanceOfMonitFindingResolutions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "external_id" in value;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "scope" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function MonitFindingResolutionsFromJSON(json: any): MonitFindingResolutions {
    return MonitFindingResolutionsFromJSONTyped(json, false);
}

export function MonitFindingResolutionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitFindingResolutions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'entity_id': json['entity_id'],
        'asset': json['asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'datapoint': json['datapoint'],
        'reason': json['reason'],
        'scope': MonitResolutionRequestScopeFromJSON(json['scope']),
        'status': MonitResolutionRequestStatusFromJSON(json['status']),
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitFindingResolutionsToJSON(value?: MonitFindingResolutions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'entity_id': value.entity_id,
        'asset': value.asset,
        'port': value.port,
        'datapoint': value.datapoint,
        'reason': value.reason,
        'scope': MonitResolutionRequestScopeToJSON(value.scope),
        'status': MonitResolutionRequestStatusToJSON(value.status),
        'created': value.created,
        'updated': value.updated,
    };
}

