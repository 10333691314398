/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskItemIII
 */
export interface ExecRiskItemIII {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    ack_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemIII
     */
    row_order?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_indirect_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_indirect_srvc_codes?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemIII
     */
    provider_indirect_comp_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_payor_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    prov_payor_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemIII
     */
    provider_comp_explain_text?: string;
}

/**
 * Check if a given object implements the ExecRiskItemIII interface.
 */
export function instanceOfExecRiskItemIII(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskItemIIIFromJSON(json: any): ExecRiskItemIII {
    return ExecRiskItemIIIFromJSONTyped(json, false);
}

export function ExecRiskItemIIIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskItemIII {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'row_order': !exists(json, 'row_order') ? undefined : json['row_order'],
        'provider_indirect_name': !exists(json, 'provider_indirect_name') ? undefined : json['provider_indirect_name'],
        'provider_indirect_srvc_codes': !exists(json, 'provider_indirect_srvc_codes') ? undefined : json['provider_indirect_srvc_codes'],
        'provider_indirect_comp_amt': !exists(json, 'provider_indirect_comp_amt') ? undefined : json['provider_indirect_comp_amt'],
        'provider_payor_name': !exists(json, 'provider_payor_name') ? undefined : json['provider_payor_name'],
        'provider_payor_ein': !exists(json, 'provider_payor_ein') ? undefined : json['provider_payor_ein'],
        'provider_payor_us_address1': !exists(json, 'provider_payor_us_address1') ? undefined : json['provider_payor_us_address1'],
        'provider_payor_us_address2': !exists(json, 'provider_payor_us_address2') ? undefined : json['provider_payor_us_address2'],
        'provider_payor_us_city': !exists(json, 'provider_payor_us_city') ? undefined : json['provider_payor_us_city'],
        'provider_payor_us_state': !exists(json, 'provider_payor_us_state') ? undefined : json['provider_payor_us_state'],
        'provider_payor_us_zip': !exists(json, 'provider_payor_us_zip') ? undefined : json['provider_payor_us_zip'],
        'prov_payor_foreign_address1': !exists(json, 'prov_payor_foreign_address1') ? undefined : json['prov_payor_foreign_address1'],
        'prov_payor_foreign_address2': !exists(json, 'prov_payor_foreign_address2') ? undefined : json['prov_payor_foreign_address2'],
        'prov_payor_foreign_city': !exists(json, 'prov_payor_foreign_city') ? undefined : json['prov_payor_foreign_city'],
        'prov_payor_foreign_prov_state': !exists(json, 'prov_payor_foreign_prov_state') ? undefined : json['prov_payor_foreign_prov_state'],
        'prov_payor_foreign_cntry': !exists(json, 'prov_payor_foreign_cntry') ? undefined : json['prov_payor_foreign_cntry'],
        'prov_payor_foreign_postal_cd': !exists(json, 'prov_payor_foreign_postal_cd') ? undefined : json['prov_payor_foreign_postal_cd'],
        'provider_comp_explain_text': !exists(json, 'provider_comp_explain_text') ? undefined : json['provider_comp_explain_text'],
    };
}

export function ExecRiskItemIIIToJSON(value?: ExecRiskItemIII | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'row_order': value.row_order,
        'provider_indirect_name': value.provider_indirect_name,
        'provider_indirect_srvc_codes': value.provider_indirect_srvc_codes,
        'provider_indirect_comp_amt': value.provider_indirect_comp_amt,
        'provider_payor_name': value.provider_payor_name,
        'provider_payor_ein': value.provider_payor_ein,
        'provider_payor_us_address1': value.provider_payor_us_address1,
        'provider_payor_us_address2': value.provider_payor_us_address2,
        'provider_payor_us_city': value.provider_payor_us_city,
        'provider_payor_us_state': value.provider_payor_us_state,
        'provider_payor_us_zip': value.provider_payor_us_zip,
        'prov_payor_foreign_address1': value.prov_payor_foreign_address1,
        'prov_payor_foreign_address2': value.prov_payor_foreign_address2,
        'prov_payor_foreign_city': value.prov_payor_foreign_city,
        'prov_payor_foreign_prov_state': value.prov_payor_foreign_prov_state,
        'prov_payor_foreign_cntry': value.prov_payor_foreign_cntry,
        'prov_payor_foreign_postal_cd': value.prov_payor_foreign_postal_cd,
        'provider_comp_explain_text': value.provider_comp_explain_text,
    };
}

