import React, { ReactNode, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * FieldDatePicker
 */
export const FieldDatePicker = ({
  name,
  label,
  setFieldValue,
  setFieldError,
  error,
  className,
  ...props
}: FieldDatePickerProps) => {
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const handleOnChange = useCallback(
    (date) => {
      // "2023-11-24T11:53:00.000Z"
      if (dayjs(date).isValid() && setFieldValue) {
        setFieldValue(name as string, dayjs(date).toISOString());
        return;
      } else if (setFieldError) {
        setFieldError(name as string, 'Invalid Date');
      }
    },
    [name, setFieldValue],
  );

  return (
    <DesktopDatePicker
      {...props}
      label={intlLabel}
      onChange={handleOnChange}
      value={props.value ? dayjs(props.value) : null}
      timezone={dayjs.tz.guess()}
      format="YYYY-MM-DD"
      className={clsxClass}
      slotProps={{
        textField: {
          required: props?.required ?? false,
        },
      }}
    />
  );
};

/**
 * FieldDatePicker Props
 */
export type FieldDatePickerProps = DatePickerProps<Date> & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `required` - field is required
   */
  required?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError: (field: string, message: string) => void;
};
