import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * BreachResponseCostsIcon
 */
export const BreachResponseCostsIcon = ({
  ...props
}: BreachResponseCostsIconProps) => {
  return (
    <Icon label="breach_response_costs" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1,20.8c-0.1,0.8-0.7,1.5-1.5,1.7c-0.1,0-0.2,0.1-0.2,0.2V23c0,0.4-0.3,0.6-0.6,0.6c-0.4,0-0.6-0.3-0.6-0.6l0-0.3c0-0.1-0.1-0.2-0.2-0.2h-0.5c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6h1.9c0.3,0,0.5-0.2,0.6-0.5c0.1-0.3-0.1-0.6-0.4-0.7l-1.9-0.8c-0.8-0.3-1.3-1.1-1.2-1.9c0.1-0.8,0.7-1.5,1.5-1.7c0.1,0,0.2-0.1,0.2-0.2v-0.3c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v0.3c0,0.1,0.1,0.2,0.2,0.2h0.5c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6h-1.9c-0.3,0-0.5,0.2-0.6,0.5c-0.1,0.3,0.1,0.6,0.4,0.7l1.9,0.8C17.8,19.2,18.2,19.9,18.1,20.8 M22.3,12.9h-0.6v-2.3c0-2.9-2.1-5.3-4.9-5.7c-2.8-0.4-5.5,1.3-6.3,4c-0.1,0.4,0,0.8,0.2,1c0.3,0.3,0.6,0.4,1,0.3c0.4-0.1,0.7-0.4,0.8-0.7c0.5-1.7,2.2-2.8,4-2.5c1.8,0.3,3.1,1.8,3.1,3.6v1.9c0,0.2-0.2,0.4-0.4,0.4H9.5c-0.9,0-1.7,0.8-1.7,1.7v8.9c0,0.9,0.8,1.7,1.7,1.7h12.8c0.9,0,1.7-0.8,1.7-1.7v-8.9C24,13.6,23.2,12.9,22.3,12.9"
        />
      </g>
    </Icon>
  );
};

/**
 * BreachResponseCostsIcon Props
 */
export interface BreachResponseCostsIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
