import React from 'react';
import clsx from 'clsx';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import { withCoreProps } from '../../withCoreProps';
import { createStyles, makeStyles } from '@mui/styles';
import { CoreProps } from '../../types';

export const Paper = withCoreProps<PaperProps>(
  ({ children, className, ...props }: React.PropsWithChildren<PaperProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiPaper {...props} className={clsxClass}>
        {children}
      </MuiPaper>
    );
  },
);

export interface PaperProps extends MuiPaperProps, CoreProps {}
