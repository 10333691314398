import React from 'react';
import clsx from 'clsx';
import MuiSkeleton, {
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material/Skeleton';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Skeleton = withCoreProps<SkeletonProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<SkeletonProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiSkeleton {...props} className={clsxClass}>
        {children}
      </MuiSkeleton>
    );
  },
);

export interface SkeletonProps extends MuiSkeletonProps, CoreProps {}
