import React from 'react';
import { useTheme } from '@mui/material';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { IconButton } from '@explorer/core';

/**
 * CopyToClipboard
 */
export const CopyToClipboard = ({
  size = 'small',
  copyMe = '',
  disabled = false,
}: CopyToClipboardProps) => {
  const theme = useTheme();

  return (
    //@ts-ignore React 18 types break older components
    <ReactCopyToClipboard text={copyMe}>
      <IconButton
        size="small"
        disabled={disabled}
        onClick={(e: any) => e.stopPropagation()}
      >
        <FileCopyOutlinedIcon
          style={{
            color: theme.palette.grey[700],
            fontSize: size === 'small' ? '0.9rem' : undefined,
          }}
        />
      </IconButton>
    </ReactCopyToClipboard>
  );
};

/**
 * CopyToClipboard Props
 */
export interface CopyToClipboardProps {
  size: 'small' | 'medium';
  copyMe: string;
  disabled?: boolean;
}
