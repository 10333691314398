/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFDataleak
 */
export interface CRSFDataleak {
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFDataleak
     */
    features: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleak
     */
    updated: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    user?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleak
     */
    year?: number;
}

/**
 * Check if a given object implements the CRSFDataleak interface.
 */
export function instanceOfCRSFDataleak(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFDataleakFromJSON(json: any): CRSFDataleak {
    return CRSFDataleakFromJSONTyped(json, false);
}

export function CRSFDataleakFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDataleak {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'domain': json['domain'],
        'features': json['features'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'name': json['name'],
        'updated': json['updated'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function CRSFDataleakToJSON(value?: CRSFDataleak | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'domain': value.domain,
        'features': value.features,
        'fullname': value.fullname,
        'name': value.name,
        'updated': value.updated,
        'user': value.user,
        'year': value.year,
    };
}

