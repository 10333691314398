import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Typography } from '../Typography';
import { Box, BoxProps } from '../Box';
import { Grid } from '../Grid';
import { withCoreProps } from '../../withCoreProps';
import { isDarkTheme } from '@explorer/helpers';
import { useThemeContext } from '@explorer/themes';

const useStyles = ({
  clickable,
  isDark,
}: {
  clickable: boolean;
  isDark?: boolean;
}) =>
  makeStyles(
    ({ palette }: Theme) => {
      const borderStyles = {
        borderRadius: '4px',
        borderWidth: '2px',
        border: 'solid',
      };

      return createStyles({
        critical: {
          color: isDark
            ? palette.primary.contrastText
            : palette?.critical[300] || '',
          backgroundColor: isDark
            ? palette?.critical[300]
            : palette?.critical[900],
          borderColor: palette?.critical[300],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.critical[700] : undefined,
          },
        },
        high: {
          color: isDark ? palette.primary.contrastText : palette?.high[300],
          backgroundColor: isDark ? palette?.high[300] : palette?.high[900],
          borderColor: palette?.high[300],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.high[700] : undefined,
          },
        },
        medium: {
          color: isDark ? palette.primary.contrastText : palette?.medium[300],
          backgroundColor: isDark ? palette?.medium[300] : palette?.medium[900],
          borderColor: palette?.medium[300],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.medium[700] : undefined,
          },
        },
        low: {
          color: isDark ? palette.primary.contrastText : palette?.low[300],
          backgroundColor: isDark ? palette?.low[300] : palette?.low[900],
          borderColor: palette?.low[300],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.low[700] : undefined,
          },
        },
        neutral: {
          color: isDark ? palette.primary.contrastText : palette?.neutral[300],
          backgroundColor: isDark
            ? palette?.neutral[300]
            : palette?.neutral[900],
          borderColor: palette?.neutral[300],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.neutral[700] : undefined,
          },
        },
        info: {
          color: isDark
            ? palette.primary.contrastText
            : palette?.informative[300],
          borderColor: palette?.informative[300],
          backgroundColor: isDark
            ? palette?.informative[300]
            : palette?.informative[900],
          ...borderStyles,
          '&:hover': {
            borderColor: clickable ? palette?.informative[700] : undefined,
          },
        },
        clickable: {
          transition: 'border-color 0.1s ease-out',
          cursor: 'pointer',
        },
        large: {
          width: '120px',
          height: '80px',
        },
        small: {
          width: '100px',
          height: '80px',
        },
      });
    },
    { index: 1 },
  )();

export const Chiclet = withCoreProps<ChicletProps>((props) => {
  const {
    label,
    value,
    color = 'neutral',
    size = 'large',
    className,
    onClick,
  } = props;

  const { activeTheme } = useThemeContext();
  const isDark = useMemo(() => {
    if (activeTheme) {
      return isDarkTheme(activeTheme.name);
    }
    return false;
  }, [activeTheme]);

  const clickable = !!onClick;
  const classes = useStyles({ clickable, isDark });
  const clsxClass = clsx(
    classes[color],
    classes[size],
    clickable ? classes.clickable : '',
    className,
  );

  return (
    <Box {...props} noUserSelect className={clsxClass} onClick={onClick}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontSize="13px"
            textAlign="center"
            fontWeight="bold"
            letterSpacing="0.25px"
            textTransform="uppercase"
            py={0.25}
            label={label}
          />
          {value && (
            <Typography variant="h6" fontSize="23px" textAlign="center">
              {value}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export interface ChicletProps extends BoxProps {
  color: 'critical' | 'high' | 'medium' | 'low' | 'info' | 'neutral';
  size: 'large' | 'small';
  label: IntlLabel;
  value?: ReactNode;
}
