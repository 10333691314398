'use client';
import {
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { coalition } from '@explorer/themes/presets';

// consts
const BE_THEME = 'BE_THEME';
const DEFAULT_THEME: AvailableThemes = 'coalition';

// types
export type ThemeContextType = {
  THEMES: ThemesListType;
  defaultTheme: AppTheme;
  activeTheme: AppTheme;
  activeThemeSet: Dispatch<SetStateAction<AppTheme>>;
  setLocalThemeId: (themeId: AvailableThemes) => void;
  getLocalTheme: () => AppTheme;
  setActiveThemeById: (themeId: AvailableThemes) => void;
};

// init
const initialState: ThemeContextType = {
  THEMES: {
    coalition: coalition.light,
  },
  defaultTheme: coalition.light,
  activeTheme: coalition.light,
  activeThemeSet: () => undefined,
  setLocalThemeId: () => undefined,
  getLocalTheme: () => coalition.light,
  setActiveThemeById: () => undefined,
};

// create
export const ThemeContext = createContext<ThemeContextType>(initialState);

// provider hook
export const useThemeContextProvider = ({
  THEMES,
}: {
  THEMES: ThemeContextType['THEMES'];
}): ThemeContextType => {
  // memo default theme
  const defaultTheme = useMemo<AppTheme>(
    () => THEMES[DEFAULT_THEME]!,
    [THEMES],
  );

  // active theme state
  const [activeTheme, activeThemeSet] = useState<AppTheme>(defaultTheme);

  // force set theme id in ls
  const setLocalThemeId = useCallback<ThemeContextType['setLocalThemeId']>(
    (themeId) => {
      localStorage.setItem(BE_THEME, themeId);
    },
    [],
  );

  // get ls theme id
  const getLocalTheme = useCallback<ThemeContextType['getLocalTheme']>(() => {
    try {
      const localTheme = localStorage?.getItem(BE_THEME) as AvailableThemes;
      if (
        localTheme &&
        Object.keys(THEMES).includes(localTheme) &&
        localTheme !== activeTheme.name
      ) {
        setLocalThemeId(THEMES[localTheme]?.name as AvailableThemes);
        return THEMES[localTheme] || defaultTheme;
      }
      return defaultTheme;
    } catch {
      return defaultTheme;
    }
  }, [activeTheme, THEMES]);

  // force set theme in app by id
  const setActiveThemeById = useCallback<
    ThemeContextType['setActiveThemeById']
  >(
    (id: AvailableThemes) => {
      return THEMES[id] || defaultTheme;
    },
    [THEMES],
  );

  // set active theme on mount
  useEffect(() => activeThemeSet(getLocalTheme()), []);

  // update local storage when active theme changes
  useEffect(() => {
    setLocalThemeId(activeTheme.name as AvailableThemes);
  }, [activeTheme]);

  // bundle provider value
  const providerValue = useMemo<ThemeContextType>(
    () => ({
      THEMES,
      defaultTheme,
      activeTheme,
      activeThemeSet,
      setLocalThemeId,
      getLocalTheme,
      setActiveThemeById,
    }),
    [
      THEMES,
      defaultTheme,
      activeTheme,
      activeThemeSet,
      setLocalThemeId,
      getLocalTheme,
      setActiveThemeById,
    ],
  );

  return providerValue;
};

// use context hook
export const useThemeContext = (): ThemeContextType => useContext(ThemeContext);
