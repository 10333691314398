/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlGrantRequestStatus } from './ControlGrantRequestStatus';
import {
    ControlGrantRequestStatusFromJSON,
    ControlGrantRequestStatusFromJSONTyped,
    ControlGrantRequestStatusToJSON,
} from './ControlGrantRequestStatus';

/**
 * 
 * @export
 * @interface ControlAdminUpdateGrantRequestStatusRequest
 */
export interface ControlAdminUpdateGrantRequestStatusRequest {
    /**
     * 
     * @type {ControlGrantRequestStatus}
     * @memberof ControlAdminUpdateGrantRequestStatusRequest
     */
    request_status: ControlGrantRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminUpdateGrantRequestStatusRequest
     */
    decision_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminUpdateGrantRequestStatusRequest
     */
    reviewer: string;
}

/**
 * Check if a given object implements the ControlAdminUpdateGrantRequestStatusRequest interface.
 */
export function instanceOfControlAdminUpdateGrantRequestStatusRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request_status" in value;
    isInstance = isInstance && "reviewer" in value;

    return isInstance;
}

export function ControlAdminUpdateGrantRequestStatusRequestFromJSON(json: any): ControlAdminUpdateGrantRequestStatusRequest {
    return ControlAdminUpdateGrantRequestStatusRequestFromJSONTyped(json, false);
}

export function ControlAdminUpdateGrantRequestStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminUpdateGrantRequestStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_status': ControlGrantRequestStatusFromJSON(json['request_status']),
        'decision_reason': !exists(json, 'decision_reason') ? undefined : json['decision_reason'],
        'reviewer': json['reviewer'],
    };
}

export function ControlAdminUpdateGrantRequestStatusRequestToJSON(value?: ControlAdminUpdateGrantRequestStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_status': ControlGrantRequestStatusToJSON(value.request_status),
        'decision_reason': value.decision_reason,
        'reviewer': value.reviewer,
    };
}

