/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFMalware } from './CRSFMalware';
import {
    CRSFMalwareFromJSON,
    CRSFMalwareFromJSONTyped,
    CRSFMalwareToJSON,
} from './CRSFMalware';

/**
 * 
 * @export
 * @interface CRSFMalwareAggregate
 */
export interface CRSFMalwareAggregate {
    /**
     * 
     * @type {number}
     * @memberof CRSFMalwareAggregate
     */
    assets: number;
    /**
     * 
     * @type {CRSFMalware}
     * @memberof CRSFMalwareAggregate
     */
    malicious: CRSFMalware;
    /**
     * 
     * @type {CRSFMalware}
     * @memberof CRSFMalwareAggregate
     */
    spam: CRSFMalware;
}

/**
 * Check if a given object implements the CRSFMalwareAggregate interface.
 */
export function instanceOfCRSFMalwareAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "malicious" in value;
    isInstance = isInstance && "spam" in value;

    return isInstance;
}

export function CRSFMalwareAggregateFromJSON(json: any): CRSFMalwareAggregate {
    return CRSFMalwareAggregateFromJSONTyped(json, false);
}

export function CRSFMalwareAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFMalwareAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': json['assets'],
        'malicious': CRSFMalwareFromJSON(json['malicious']),
        'spam': CRSFMalwareFromJSON(json['spam']),
    };
}

export function CRSFMalwareAggregateToJSON(value?: CRSFMalwareAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets,
        'malicious': CRSFMalwareToJSON(value.malicious),
        'spam': CRSFMalwareToJSON(value.spam),
    };
}

