/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAuthenticatorDetails
 */
export interface ControlAuthenticatorDetails {
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAuthenticatorDetails
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    last_auth_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAuthenticatorDetails
     */
    enrolled_at?: string;
}

/**
 * Check if a given object implements the ControlAuthenticatorDetails interface.
 */
export function instanceOfControlAuthenticatorDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function ControlAuthenticatorDetailsFromJSON(json: any): ControlAuthenticatorDetails {
    return ControlAuthenticatorDetailsFromJSONTyped(json, false);
}

export function ControlAuthenticatorDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAuthenticatorDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'confirmed': !exists(json, 'confirmed') ? undefined : json['confirmed'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'created_at': json['created_at'],
        'last_auth_at': !exists(json, 'last_auth_at') ? undefined : json['last_auth_at'],
        'enrolled_at': !exists(json, 'enrolled_at') ? undefined : json['enrolled_at'],
    };
}

export function ControlAuthenticatorDetailsToJSON(value?: ControlAuthenticatorDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'confirmed': value.confirmed,
        'email': value.email,
        'created_at': value.created_at,
        'last_auth_at': value.last_auth_at,
        'enrolled_at': value.enrolled_at,
    };
}

