import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_V1CONTROLADMIN = '/v1/control/web/admin';

const ENDPOINTS_V1CONTROLADMIN = [
  `/associate-user/${P1}`,
  `/client-update/${P1}`,
  `/create-user`,
  `/get-client-info/${P1}`,
  `/grant-requests`,
  `/grant-requests/${P1}`,
  `/entity/${P1}/mute`,
  `/entity/${P1}/rescan`,
  `/entity/${P1}/risk_history`,
  `/entity/${P1}/resolutions`,
  `/impersonate`,
  `/invite/${P1}`,
  `/resolutions`,
  `/resolutions/${P1}`,
  `/update`,
  `/update-client-trial/${P1}`,
  `/upgrade/${P1}`,
  `/search`,
  `/users`,
  `/deleted`,
  `/user/${P1}`,
  `/user/${P1}/undelete`,
] as const;

type Endpoints = (typeof ENDPOINTS_V1CONTROLADMIN)[number];

export type EndpointsV1CONTROLADMIN = Record<Endpoints, FuncEndpoint>;

export const endpointsV1CONTROLADMIN = createApiEndpoints<Endpoints>(
  PREFIX_V1CONTROLADMIN,
  ENDPOINTS_V1CONTROLADMIN,
) as EndpointsV1CONTROLADMIN;
