/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskZoomInfoCorpHierarchy } from './ExecRiskZoomInfoCorpHierarchy';
import {
    ExecRiskZoomInfoCorpHierarchyFromJSON,
    ExecRiskZoomInfoCorpHierarchyFromJSONTyped,
    ExecRiskZoomInfoCorpHierarchyToJSON,
} from './ExecRiskZoomInfoCorpHierarchy';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedZoomInfoParentsResponse
 */
export interface ExecRiskPaginatedZoomInfoParentsResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedZoomInfoParentsResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskZoomInfoCorpHierarchy>}
     * @memberof ExecRiskPaginatedZoomInfoParentsResponse
     */
    results: Array<ExecRiskZoomInfoCorpHierarchy>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedZoomInfoParentsResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedZoomInfoParentsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedZoomInfoParentsResponse
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedZoomInfoParentsResponse interface.
 */
export function instanceOfExecRiskPaginatedZoomInfoParentsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedZoomInfoParentsResponseFromJSON(json: any): ExecRiskPaginatedZoomInfoParentsResponse {
    return ExecRiskPaginatedZoomInfoParentsResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedZoomInfoParentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedZoomInfoParentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskZoomInfoCorpHierarchyFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedZoomInfoParentsResponseToJSON(value?: ExecRiskPaginatedZoomInfoParentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskZoomInfoCorpHierarchyToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

