/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientRoles } from './ControlClientRoles';
import {
    ControlClientRolesFromJSON,
    ControlClientRolesFromJSONTyped,
    ControlClientRolesToJSON,
} from './ControlClientRoles';

/**
 * 
 * @export
 * @interface ControlClientUpdateRequest
 */
export interface ControlClientUpdateRequest {
    /**
     * 
     * @type {ControlClientRoles}
     * @memberof ControlClientUpdateRequest
     */
    role: ControlClientRoles;
    /**
     * 
     * @type {number}
     * @memberof ControlClientUpdateRequest
     */
    count_max_lite: number;
    /**
     * 
     * @type {number}
     * @memberof ControlClientUpdateRequest
     */
    count_max_extended: number;
    /**
     * 
     * @type {boolean}
     * @memberof ControlClientUpdateRequest
     */
    msp?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ControlClientUpdateRequest
     */
    control_components?: { [key: string]: boolean; };
}

/**
 * Check if a given object implements the ControlClientUpdateRequest interface.
 */
export function instanceOfControlClientUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "count_max_lite" in value;
    isInstance = isInstance && "count_max_extended" in value;

    return isInstance;
}

export function ControlClientUpdateRequestFromJSON(json: any): ControlClientUpdateRequest {
    return ControlClientUpdateRequestFromJSONTyped(json, false);
}

export function ControlClientUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlClientUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': ControlClientRolesFromJSON(json['role']),
        'count_max_lite': json['count_max_lite'],
        'count_max_extended': json['count_max_extended'],
        'msp': !exists(json, 'msp') ? undefined : json['msp'],
        'control_components': !exists(json, 'control_components') ? undefined : json['control_components'],
    };
}

export function ControlClientUpdateRequestToJSON(value?: ControlClientUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': ControlClientRolesToJSON(value.role),
        'count_max_lite': value.count_max_lite,
        'count_max_extended': value.count_max_extended,
        'msp': value.msp,
        'control_components': value.control_components,
    };
}

