import React, { useMemo, useCallback, ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { Paper, PaperProps } from '@explorer/core';
import { formatMessage } from '@explorer/helpers';

const AutocompletePaper = (props: PaperProps) => (
  <Paper {...props} elevation={1} />
);

/**
 * FieldAutocompleteMultiple
 */
export const FieldAutocompleteMultiple = ({
  autoFocus = false,
  options = [],
  value = [],
  name,
  label,
  onBlur,
  error,
  onChange,
  setFieldValue,
  setFieldError,
  className,
  ...props
}: FieldAutocompleteMultipleProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  /**
   * Reformats the `value` because we use the `SelectOption` structure.
   */
  const internalValue = useMemo<SelectOptions>(() => {
    const v: SelectOptions = [];

    for (let i = 0, ii = options.length; i < ii; i++) {
      const option = options[i];

      if (value.includes(option?.value as never)) {
        v.push(option);
      }
    }

    return v;
  }, [value, options]);

  /**
   * Handles the `onChange` event so it's compatible with `formik`
   */
  const handleChange = useCallback<any>(
    (e: ChangeEvent<any>, selectedOption: SelectOptions) => {
      if (setFieldValue) {
        setFieldValue(
          name,
          selectedOption.map((v) => v.value),
        );
      }
    },
    [],
  );

  /**
   * Handles the localized option labels
   */
  const handleOptionLabel = useCallback(
    (currentOption: SelectOption) => {
      if (!currentOption) return '';

      return formatMessage(intl, currentOption?.label) as string;
    },
    [intl],
  );

  /**
   * Since we use the `SelectOption` structure, this makes
   * sure that the options is indeed marked as selected.
   */
  const handleOptionSelect = useCallback(
    (option: SelectOption, selectedOption: SelectOption) => {
      if (selectedOption && selectedOption.value === option.value) return true;

      return false;
    },
    [],
  );

  /**
   * Wrapper around the `renderInput` function that
   * decreases the number of renders.
   */
  const renderInput = useCallback<
    (params: AutocompleteRenderInputParams) => ReactNode
  >(
    (params) => {
      return (
        <TextField
          {...params}
          variant="outlined"
          autoFocus={autoFocus}
          error={error}
          label={intlLabel}
        />
      );
    },
    [autoFocus, error, intlLabel],
  );

  return (
    <Autocomplete
      {...props}
      multiple
      freeSolo={false}
      value={internalValue}
      limitTags={2}
      options={options}
      onChange={handleChange as FieldAutocompleteMultipleProps['onChange']}
      onBlur={onBlur}
      renderInput={renderInput}
      getOptionLabel={handleOptionLabel}
      isOptionEqualToValue={handleOptionSelect}
      PaperComponent={AutocompletePaper}
      className={clsxClass}
      openOnFocus
      clearOnEscape
    />
  );
};

/**
 * FieldAutocompleteMultiple Props
 */
export interface FieldAutocompleteMultipleProps
  extends Omit<
    AutocompleteProps<SelectOption, true, false, false>,
    | 'value'
    | 'freeSolo'
    | 'multiple'
    | 'renderInput'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'getOptionSelected'
    | 'PaperComponent'
    | 'openOnFocus'
    | 'clearOnEscape'
  > {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `value` - name of the input (used in `formik`)
   */
  value: string[] | number[];
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `options` - field options
   */
  options: SelectOptions;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `autoFocus` - focus the field
   */
  autoFocus?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
}

export interface SelectOption {
  label: IntlLabel;
  value: string | number;
}

export type SelectOptions = SelectOption[];
