/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlZeroDayAlertResolutionStatus = {
    Fixed: 'fixed',
    Ignored: 'ignored',
    FalsePositive: 'false_positive'
} as const;
export type ControlZeroDayAlertResolutionStatus = typeof ControlZeroDayAlertResolutionStatus[keyof typeof ControlZeroDayAlertResolutionStatus];


export function ControlZeroDayAlertResolutionStatusFromJSON(json: any): ControlZeroDayAlertResolutionStatus {
    return ControlZeroDayAlertResolutionStatusFromJSONTyped(json, false);
}

export function ControlZeroDayAlertResolutionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAlertResolutionStatus {
    return json as ControlZeroDayAlertResolutionStatus;
}

export function ControlZeroDayAlertResolutionStatusToJSON(value?: ControlZeroDayAlertResolutionStatus | null): any {
    return value as any;
}

