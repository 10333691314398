/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    related_organization_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    foreign_address?: ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    primary_activities_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    legal_domicile_state_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    legal_domicile_foreign_country_cd?: string;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    direct_controlling_entity_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    direct_controlling_na_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    entity_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    share_of_total_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    share_of_eoy_assets_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ownership_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    controlled_organization_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr {
    return ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'related_organization_name': !exists(json, 'related_organization_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['related_organization_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'primary_activities_txt': !exists(json, 'primary_activities_txt') ? undefined : json['primary_activities_txt'],
        'legal_domicile_state_cd': !exists(json, 'legal_domicile_state_cd') ? undefined : json['legal_domicile_state_cd'],
        'legal_domicile_foreign_country_cd': !exists(json, 'legal_domicile_foreign_country_cd') ? undefined : json['legal_domicile_foreign_country_cd'],
        'direct_controlling_entity_name': !exists(json, 'direct_controlling_entity_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['direct_controlling_entity_name']),
        'direct_controlling_na_cd': !exists(json, 'direct_controlling_na_cd') ? undefined : json['direct_controlling_na_cd'],
        'entity_type_txt': !exists(json, 'entity_type_txt') ? undefined : json['entity_type_txt'],
        'share_of_total_income_amt': !exists(json, 'share_of_total_income_amt') ? undefined : json['share_of_total_income_amt'],
        'share_of_eoy_assets_amt': !exists(json, 'share_of_eoy_assets_amt') ? undefined : json['share_of_eoy_assets_amt'],
        'ownership_pct': !exists(json, 'ownership_pct') ? undefined : json['ownership_pct'],
        'controlled_organization_ind': !exists(json, 'controlled_organization_ind') ? undefined : json['controlled_organization_ind'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'related_organization_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.related_organization_name),
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'ein': value.ein,
        'primary_activities_txt': value.primary_activities_txt,
        'legal_domicile_state_cd': value.legal_domicile_state_cd,
        'legal_domicile_foreign_country_cd': value.legal_domicile_foreign_country_cd,
        'direct_controlling_entity_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.direct_controlling_entity_name),
        'direct_controlling_na_cd': value.direct_controlling_na_cd,
        'entity_type_txt': value.entity_type_txt,
        'share_of_total_income_amt': value.share_of_total_income_amt,
        'share_of_eoy_assets_amt': value.share_of_eoy_assets_amt,
        'ownership_pct': value.ownership_pct,
        'controlled_organization_ind': value.controlled_organization_ind,
    };
}

