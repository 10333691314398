/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityFindingsAggAsset } from './ControlEntityFindingsAggAsset';
import {
    ControlEntityFindingsAggAssetFromJSON,
    ControlEntityFindingsAggAssetFromJSONTyped,
    ControlEntityFindingsAggAssetToJSON,
} from './ControlEntityFindingsAggAsset';
import type { ControlRescanStatus } from './ControlRescanStatus';
import {
    ControlRescanStatusFromJSON,
    ControlRescanStatusFromJSONTyped,
    ControlRescanStatusToJSON,
} from './ControlRescanStatus';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';
import type { ControlResolutionTypes } from './ControlResolutionTypes';
import {
    ControlResolutionTypesFromJSON,
    ControlResolutionTypesFromJSONTyped,
    ControlResolutionTypesToJSON,
} from './ControlResolutionTypes';

/**
 * 
 * @export
 * @interface ControlActiveEntityFindingsAggResult
 */
export interface ControlActiveEntityFindingsAggResult {
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    score_category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<ControlResolutionTypes>}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    resolution_types?: Array<ControlResolutionTypes>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    references?: Array<string>;
    /**
     * 
     * @type {Array<ControlEntityFindingsAggAsset>}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    assets?: Array<ControlEntityFindingsAggAsset>;
    /**
     * 
     * @type {number}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    first_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    last_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    muted_status?: string;
    /**
     * 
     * @type {ControlRescanStatus}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    scan_status?: ControlRescanStatus;
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    resolution_status?: ControlResolutionRequestStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ControlActiveEntityFindingsAggResult
     */
    is_contingency?: boolean;
}

/**
 * Check if a given object implements the ControlActiveEntityFindingsAggResult interface.
 */
export function instanceOfControlActiveEntityFindingsAggResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlActiveEntityFindingsAggResultFromJSON(json: any): ControlActiveEntityFindingsAggResult {
    return ControlActiveEntityFindingsAggResultFromJSONTyped(json, false);
}

export function ControlActiveEntityFindingsAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlActiveEntityFindingsAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'resolution_types': !exists(json, 'resolution_types') ? undefined : ((json['resolution_types'] as Array<any>).map(ControlResolutionTypesFromJSON)),
        'references': !exists(json, 'references') ? undefined : json['references'],
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(ControlEntityFindingsAggAssetFromJSON)),
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'muted_status': !exists(json, 'muted_status') ? undefined : json['muted_status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : ControlRescanStatusFromJSON(json['scan_status']),
        'resolution_status': !exists(json, 'resolution_status') ? undefined : ControlResolutionRequestStatusFromJSON(json['resolution_status']),
        'is_contingency': !exists(json, 'is_contingency') ? undefined : json['is_contingency'],
    };
}

export function ControlActiveEntityFindingsAggResultToJSON(value?: ControlActiveEntityFindingsAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoint': value.datapoint,
        'name': value.name,
        'count': value.count,
        'level': value.level,
        'category': value.category,
        'score_category': value.score_category,
        'description': value.description,
        'recommendation': value.recommendation,
        'resolution_types': value.resolution_types === undefined ? undefined : ((value.resolution_types as Array<any>).map(ControlResolutionTypesToJSON)),
        'references': value.references,
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(ControlEntityFindingsAggAssetToJSON)),
        'first_seen': value.first_seen,
        'last_seen': value.last_seen,
        'risk_score': value.risk_score,
        'muted_status': value.muted_status,
        'scan_status': ControlRescanStatusToJSON(value.scan_status),
        'resolution_status': ControlResolutionRequestStatusToJSON(value.resolution_status),
        'is_contingency': value.is_contingency,
    };
}

