/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlDomainValidationMethods = {
    Dns: 'dns',
    Web: 'web'
} as const;
export type ControlDomainValidationMethods = typeof ControlDomainValidationMethods[keyof typeof ControlDomainValidationMethods];


export function ControlDomainValidationMethodsFromJSON(json: any): ControlDomainValidationMethods {
    return ControlDomainValidationMethodsFromJSONTyped(json, false);
}

export function ControlDomainValidationMethodsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlDomainValidationMethods {
    return json as ControlDomainValidationMethods;
}

export function ControlDomainValidationMethodsToJSON(value?: ControlDomainValidationMethods | null): any {
    return value as any;
}

