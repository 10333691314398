/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewBuiltwithRecordItem } from './CRSFSecReviewBuiltwithRecordItem';
import {
    CRSFSecReviewBuiltwithRecordItemFromJSON,
    CRSFSecReviewBuiltwithRecordItemFromJSONTyped,
    CRSFSecReviewBuiltwithRecordItemToJSON,
} from './CRSFSecReviewBuiltwithRecordItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewBuiltwithRecord
 */
export interface CRSFSecReviewBuiltwithRecord {
    /**
     * 
     * @type {Array<CRSFSecReviewBuiltwithRecordItem>}
     * @memberof CRSFSecReviewBuiltwithRecord
     */
    Results: Array<CRSFSecReviewBuiltwithRecordItem>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBuiltwithRecord
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBuiltwithRecord
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBuiltwithRecord
     */
    process_id: string;
}

/**
 * Check if a given object implements the CRSFSecReviewBuiltwithRecord interface.
 */
export function instanceOfCRSFSecReviewBuiltwithRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Results" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSecReviewBuiltwithRecordFromJSON(json: any): CRSFSecReviewBuiltwithRecord {
    return CRSFSecReviewBuiltwithRecordFromJSONTyped(json, false);
}

export function CRSFSecReviewBuiltwithRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewBuiltwithRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Results': ((json['Results'] as Array<any>).map(CRSFSecReviewBuiltwithRecordItemFromJSON)),
        'created': json['created'],
        'domain': json['domain'],
        'process_id': json['process_id'],
    };
}

export function CRSFSecReviewBuiltwithRecordToJSON(value?: CRSFSecReviewBuiltwithRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Results': ((value.Results as Array<any>).map(CRSFSecReviewBuiltwithRecordItemToJSON)),
        'created': value.created,
        'domain': value.domain,
        'process_id': value.process_id,
    };
}

