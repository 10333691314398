import * as yup from 'yup';
import {
  FieldText,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';

/**
 * Form Validation Schema
 */
export const validationSchema = yup
  .object({
    reason: yup.string().optional(),
  })
  .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<typeof validationSchema>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  reason: {
    formId,
    formik,
    name: 'reason',
    renderField: (props) => <FieldText {...props} label="Optional Reason" />,
  },
});
