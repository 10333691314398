import React from 'react';
import * as yup from 'yup';
import {
  FieldNumber,
  FieldAutocomplete,
  FormSchemaOptions,
  FormSchemaReturn,
  FieldCheckbox,
} from '@explorer/forms';
import { ClientRoles } from '@explorer/forms/types';
import { clientRoleOptions } from './constants';
import { formatDate, isTodayBeforeGivenDate } from '@explorer/helpers';
import {
  Modules,
  SourceSystem,
  SUPPORTED_MODULES,
  SupportedModules,
  SupportedSystems,
} from './types';

/**
 * Form Validation Schema
 */
export const validationSchema = yup
  .object({
    count_max_lite: yup.number().required(),
    count_max_extended: yup
      .number()
      .integer()
      .required()
      .when('role', {
        is: ClientRoles.PORTAL_POLICYHOLDER,
        then: yup.number().integer().min(1),
      })
      .when('role', {
        is: ClientRoles.PORTAL_PREMIUM,
        then: yup.number().integer().min(1),
      }),
    role: yup.mixed<ClientRoles>().oneOf(Object.values(ClientRoles)).required(),
    option: yup.object({
      msp: yup.boolean(),
    }),
    control_components: yup.object({
      MDR: yup.boolean(),
      SAT: yup.boolean(),
    }),
  })
  .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<typeof validationSchema> & {
  control_components: Record<SupportedModules, boolean>;
};

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  count_max_lite: {
    formId,
    formik,
    name: 'count_max_lite',
    renderField: (props) => (
      <FieldNumber {...props} label={{ id: 'forms-presets.max-lite' }} />
    ),
  },
  count_max_extended: {
    formId,
    formik,
    name: 'count_max_extended',
    renderField: (props) => (
      <FieldNumber {...props} label={{ id: 'forms-presets.max-extended' }} />
    ),
  },
  role: {
    formId,
    formik,
    name: 'role',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'forms-presets.role' }}
        options={clientRoleOptions}
      />
    ),
  },
  option: {
    formId,
    formik,
    name: 'option',
    renderField: (props) => (
      <FieldCheckbox
        {...props}
        options={[
          {
            label: { id: 'forms-presets.msp' },
            name: 'msp',
          },
        ]}
      />
    ),
  },
  control_components: {
    formId,
    formik,
    name: 'control_components',
    renderField: () => null,
  },
});

export const getInitialValues = (
  activeClient: APIExplorerGetClient,
  modules: Modules,
) => {
  return {
    role:
      (activeClient?.client?.role as ClientRoles) ??
      ClientRoles.PORTAL_FREEMIUM,
    count_max_lite: activeClient?.client?.quotas?.lite_quota ?? 0,
    count_max_extended: activeClient?.client?.quotas?.extended_quota ?? 0,
    option: { msp: activeClient?.client?.msp },
    control_components: modules.overrides,
  };
};

/**
 * This takes the response from the BE client endpoint and parses the returned data into
 * "Supported" modules.
 *
 * @param components The list of "component" modules that are enabled for the client.
 * @returns metadata about the modules in a record with the module name as the key.
 */
export const getModules = (
  components: APIExplorerActiveComponentsRecord,
): Modules => {
  return SUPPORTED_MODULES.reduce<Modules>(
    (acc, module) => {
      const sourceSystems = components[module] || [];
      const modulesForCurrent = sourceSystems
        .map<SourceSystem>((sourceSystem) =>
          getSourceSystemMetaData(sourceSystem, acc.overrides),
        )
        .filter(Boolean); // Filter out null values

      if (modulesForCurrent.length > 0) {
        acc.modules[module] = modulesForCurrent;
      }
      return acc;
    },
    { overrides: { MDR: false, SAT: false }, modules: { MDR: [], SAT: [] } },
  );
};

/**
 * Maps the supported source systems and modules into the corresponding metadata for that source system.
 *
 * @param sourceSystem The source system where the pricing information comes from (Explorer overrides, Stripe, Hubspot, etc.)
 * @param overrides The supported overrides for the source system (MDR can be overriden in explorer to give access even if Stripe/Hubspot have expired)
 * @returns The SourceSystem metadata
 */
export const getSourceSystemMetaData = (
  sourceSystem: APIExplorerClientComponentModule,
  overrides: Record<SupportedModules, boolean>,
): SourceSystem | null => {
  const { source_system, is_trial, end_date, component_name } = sourceSystem;
  switch (source_system) {
    case SupportedSystems.EXPLORER:
      // If the source system of explorer is in the module list that means we are overriding.
      overrides[component_name] = true;
      return {
        sourceSystems: SupportedSystems.EXPLORER,
        featureSet: { isEnabled: true },
      };

    // TODO: These are for phase 2 of the pricing model upgrades. They aren't used yet.
    case SupportedSystems.STRIPE:
    case SupportedSystems.HUBSPOT:
      return {
        sourceSystems: source_system,
        featureSet: {
          isTrial: is_trial,
          isExpired: !!end_date || !isTodayBeforeGivenDate(end_date),
          endDate: formatDate(end_date),
        },
      };
    default:
      return null;
  }
};
