/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlGrantRequestStatus } from './ControlGrantRequestStatus';
import {
    ControlGrantRequestStatusFromJSON,
    ControlGrantRequestStatusFromJSONTyped,
    ControlGrantRequestStatusToJSON,
} from './ControlGrantRequestStatus';
import type { ControlGrantScope } from './ControlGrantScope';
import {
    ControlGrantScopeFromJSON,
    ControlGrantScopeFromJSONTyped,
    ControlGrantScopeToJSON,
} from './ControlGrantScope';

/**
 * 
 * @export
 * @interface ControlAdminGrantRequestsResponse
 */
export interface ControlAdminGrantRequestsResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    request_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantor_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantor_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantor_entity_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantor_is_policyholder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantee_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantee_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    grantee_entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    notes?: string;
    /**
     * 
     * @type {ControlGrantRequestStatus}
     * @memberof ControlAdminGrantRequestsResponse
     */
    request_status?: ControlGrantRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    decision_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    reviewer?: string;
    /**
     * 
     * @type {Array<ControlGrantScope>}
     * @memberof ControlAdminGrantRequestsResponse
     */
    new_scope?: Array<ControlGrantScope>;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminGrantRequestsResponse
     */
    updated?: string;
}

/**
 * Check if a given object implements the ControlAdminGrantRequestsResponse interface.
 */
export function instanceOfControlAdminGrantRequestsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlAdminGrantRequestsResponseFromJSON(json: any): ControlAdminGrantRequestsResponse {
    return ControlAdminGrantRequestsResponseFromJSONTyped(json, false);
}

export function ControlAdminGrantRequestsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminGrantRequestsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_id': !exists(json, 'request_id') ? undefined : json['request_id'],
        'grantor_id': !exists(json, 'grantor_id') ? undefined : json['grantor_id'],
        'grantor_name': !exists(json, 'grantor_name') ? undefined : json['grantor_name'],
        'grantor_entity_id': !exists(json, 'grantor_entity_id') ? undefined : json['grantor_entity_id'],
        'grantor_is_policyholder': !exists(json, 'grantor_is_policyholder') ? undefined : json['grantor_is_policyholder'],
        'grantee_id': !exists(json, 'grantee_id') ? undefined : json['grantee_id'],
        'grantee_name': !exists(json, 'grantee_name') ? undefined : json['grantee_name'],
        'grantee_entity_id': !exists(json, 'grantee_entity_id') ? undefined : json['grantee_entity_id'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'request_status': !exists(json, 'request_status') ? undefined : ControlGrantRequestStatusFromJSON(json['request_status']),
        'decision_reason': !exists(json, 'decision_reason') ? undefined : json['decision_reason'],
        'reviewer': !exists(json, 'reviewer') ? undefined : json['reviewer'],
        'new_scope': !exists(json, 'new_scope') ? undefined : ((json['new_scope'] as Array<any>).map(ControlGrantScopeFromJSON)),
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function ControlAdminGrantRequestsResponseToJSON(value?: ControlAdminGrantRequestsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_id': value.request_id,
        'grantor_id': value.grantor_id,
        'grantor_name': value.grantor_name,
        'grantor_entity_id': value.grantor_entity_id,
        'grantor_is_policyholder': value.grantor_is_policyholder,
        'grantee_id': value.grantee_id,
        'grantee_name': value.grantee_name,
        'grantee_entity_id': value.grantee_entity_id,
        'notes': value.notes,
        'request_status': ControlGrantRequestStatusToJSON(value.request_status),
        'decision_reason': value.decision_reason,
        'reviewer': value.reviewer,
        'new_scope': value.new_scope === undefined ? undefined : ((value.new_scope as Array<any>).map(ControlGrantScopeToJSON)),
        'created': value.created,
        'updated': value.updated,
    };
}

