/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRAValidationError } from './CRAValidationError';
import {
    CRAValidationErrorFromJSON,
    CRAValidationErrorFromJSONTyped,
    CRAValidationErrorToJSON,
} from './CRAValidationError';

/**
 * 
 * @export
 * @interface CRAHTTPValidationError
 */
export interface CRAHTTPValidationError {
    /**
     * 
     * @type {Array<CRAValidationError>}
     * @memberof CRAHTTPValidationError
     */
    detail?: Array<CRAValidationError>;
}

/**
 * Check if a given object implements the CRAHTTPValidationError interface.
 */
export function instanceOfCRAHTTPValidationError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRAHTTPValidationErrorFromJSON(json: any): CRAHTTPValidationError {
    return CRAHTTPValidationErrorFromJSONTyped(json, false);
}

export function CRAHTTPValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAHTTPValidationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detail': !exists(json, 'detail') ? undefined : ((json['detail'] as Array<any>).map(CRAValidationErrorFromJSON)),
    };
}

export function CRAHTTPValidationErrorToJSON(value?: CRAHTTPValidationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detail': value.detail === undefined ? undefined : ((value.detail as Array<any>).map(CRAValidationErrorToJSON)),
    };
}

