import Cookie from 'js-cookie';

/**
 * Handles all the cookie related actions.
 *
 * __> Example:__
 * ```ts
 * const xtoken = cookieHandler('x-token');
 *
 * xtoken.get();
 * xtoken.set('some_x_token');
 * xtoken.reset();
 * ```
 */

export const cookieHandler: CookieHandler = (id) => ({
  get: () => Cookie.get(id) || null,
  set: (value, options) => Cookie.set(id, value, { secure: true, ...options }),
  reset: (options) => Cookie.remove(id, { ...options }),
});

type CookieHandler = (
  id: 'token' | 'x-token' | 'bearer',
) => {
  get: () => string | null;
  set: (value: string, options?: Cookie.CookieAttributes) => void;
  reset: (options?: Cookie.CookieAttributes) => void;
};
