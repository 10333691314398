/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewSpiderDataItem } from './CRSFSecReviewSpiderDataItem';
import {
    CRSFSecReviewSpiderDataItemFromJSON,
    CRSFSecReviewSpiderDataItemFromJSONTyped,
    CRSFSecReviewSpiderDataItemToJSON,
} from './CRSFSecReviewSpiderDataItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewSpidersEventItem
 */
export interface CRSFSecReviewSpidersEventItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    asset: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    created: number;
    /**
     * 
     * @type {CRSFSecReviewSpiderDataItem}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    data?: CRSFSecReviewSpiderDataItem;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    ext_type: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    process_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSpidersEventItem
     */
    source_asset: string;
}

/**
 * Check if a given object implements the CRSFSecReviewSpidersEventItem interface.
 */
export function instanceOfCRSFSecReviewSpidersEventItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "ext_type" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "shared" in value;
    isInstance = isInstance && "source_asset" in value;

    return isInstance;
}

export function CRSFSecReviewSpidersEventItemFromJSON(json: any): CRSFSecReviewSpidersEventItem {
    return CRSFSecReviewSpidersEventItemFromJSONTyped(json, false);
}

export function CRSFSecReviewSpidersEventItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewSpidersEventItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'created': json['created'],
        'data': !exists(json, 'data') ? undefined : CRSFSecReviewSpiderDataItemFromJSON(json['data']),
        'ext_type': json['ext_type'],
        'found': json['found'],
        'process_id': json['process_id'],
        'shared': json['shared'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': json['source_asset'],
    };
}

export function CRSFSecReviewSpidersEventItemToJSON(value?: CRSFSecReviewSpidersEventItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'created': value.created,
        'data': CRSFSecReviewSpiderDataItemToJSON(value.data),
        'ext_type': value.ext_type,
        'found': value.found,
        'process_id': value.process_id,
        'shared': value.shared,
        'source': value.source,
        'source_asset': value.source_asset,
    };
}

