/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskServiceCodes
 */
export interface ExecRiskServiceCodes {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskServiceCodes
     */
    ack_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskServiceCodes
     */
    row_order?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskServiceCodes
     */
    code_order?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskServiceCodes
     */
    service_code?: number;
}

/**
 * Check if a given object implements the ExecRiskServiceCodes interface.
 */
export function instanceOfExecRiskServiceCodes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskServiceCodesFromJSON(json: any): ExecRiskServiceCodes {
    return ExecRiskServiceCodesFromJSONTyped(json, false);
}

export function ExecRiskServiceCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskServiceCodes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'row_order': !exists(json, 'row_order') ? undefined : json['row_order'],
        'code_order': !exists(json, 'code_order') ? undefined : json['code_order'],
        'service_code': !exists(json, 'service_code') ? undefined : json['service_code'],
    };
}

export function ExecRiskServiceCodesToJSON(value?: ExecRiskServiceCodes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'row_order': value.row_order,
        'code_order': value.code_order,
        'service_code': value.service_code,
    };
}

