/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Answer1 } from './Answer1';
import {
    Answer1FromJSON,
    Answer1FromJSONTyped,
    Answer1ToJSON,
} from './Answer1';
import type { ControlSuggestedAnswerSource } from './ControlSuggestedAnswerSource';
import {
    ControlSuggestedAnswerSourceFromJSON,
    ControlSuggestedAnswerSourceFromJSONTyped,
    ControlSuggestedAnswerSourceToJSON,
} from './ControlSuggestedAnswerSource';

/**
 * 
 * @export
 * @interface ControlSuggestedAnswer
 */
export interface ControlSuggestedAnswer {
    /**
     * 
     * @type {Answer1}
     * @memberof ControlSuggestedAnswer
     */
    answer: Answer1;
    /**
     * 
     * @type {ControlSuggestedAnswerSource}
     * @memberof ControlSuggestedAnswer
     */
    source: ControlSuggestedAnswerSource;
}

/**
 * Check if a given object implements the ControlSuggestedAnswer interface.
 */
export function instanceOfControlSuggestedAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function ControlSuggestedAnswerFromJSON(json: any): ControlSuggestedAnswer {
    return ControlSuggestedAnswerFromJSONTyped(json, false);
}

export function ControlSuggestedAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSuggestedAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': Answer1FromJSON(json['answer']),
        'source': ControlSuggestedAnswerSourceFromJSON(json['source']),
    };
}

export function ControlSuggestedAnswerToJSON(value?: ControlSuggestedAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': Answer1ToJSON(value.answer),
        'source': ControlSuggestedAnswerSourceToJSON(value.source),
    };
}

