/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFSecReviewDecisionConditionPlain = {
    Accepted: 'accepted',
    Waived: 'waived'
} as const;
export type CRSFSecReviewDecisionConditionPlain = typeof CRSFSecReviewDecisionConditionPlain[keyof typeof CRSFSecReviewDecisionConditionPlain];


export function CRSFSecReviewDecisionConditionPlainFromJSON(json: any): CRSFSecReviewDecisionConditionPlain {
    return CRSFSecReviewDecisionConditionPlainFromJSONTyped(json, false);
}

export function CRSFSecReviewDecisionConditionPlainFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDecisionConditionPlain {
    return json as CRSFSecReviewDecisionConditionPlain;
}

export function CRSFSecReviewDecisionConditionPlainToJSON(value?: CRSFSecReviewDecisionConditionPlain | null): any {
    return value as any;
}

