/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityAssetsIpAddressesResult } from './ControlEntityAssetsIpAddressesResult';
import {
    ControlEntityAssetsIpAddressesResultFromJSON,
    ControlEntityAssetsIpAddressesResultFromJSONTyped,
    ControlEntityAssetsIpAddressesResultToJSON,
} from './ControlEntityAssetsIpAddressesResult';

/**
 * 
 * @export
 * @interface ControlPaginatedEntityAssetsIpAddressesResponse
 */
export interface ControlPaginatedEntityAssetsIpAddressesResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityAssetsIpAddressesResult>}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    results?: Array<ControlEntityAssetsIpAddressesResult>;
    /**
     * 
     * @type {object}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    available_filters?: object;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityAssetsIpAddressesResponse
     */
    num_of_custom_ips_not_scanned?: number;
}

/**
 * Check if a given object implements the ControlPaginatedEntityAssetsIpAddressesResponse interface.
 */
export function instanceOfControlPaginatedEntityAssetsIpAddressesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlPaginatedEntityAssetsIpAddressesResponseFromJSON(json: any): ControlPaginatedEntityAssetsIpAddressesResponse {
    return ControlPaginatedEntityAssetsIpAddressesResponseFromJSONTyped(json, false);
}

export function ControlPaginatedEntityAssetsIpAddressesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedEntityAssetsIpAddressesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlEntityAssetsIpAddressesResultFromJSON)),
        'available_filters': !exists(json, 'available_filters') ? undefined : json['available_filters'],
        'num_of_custom_ips_not_scanned': !exists(json, 'num_of_custom_ips_not_scanned') ? undefined : json['num_of_custom_ips_not_scanned'],
    };
}

export function ControlPaginatedEntityAssetsIpAddressesResponseToJSON(value?: ControlPaginatedEntityAssetsIpAddressesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlEntityAssetsIpAddressesResultToJSON)),
        'available_filters': value.available_filters,
        'num_of_custom_ips_not_scanned': value.num_of_custom_ips_not_scanned,
    };
}

