/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt
 */
export interface ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt
     */
    boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt
     */
    eoy_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSON(json: any): ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    return ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boy_amt': !exists(json, 'boy_amt') ? undefined : json['boy_amt'],
        'eoy_amt': !exists(json, 'eoy_amt') ? undefined : json['eoy_amt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmtToJSON(value?: ExecRiskAppCoreModelsScansForm990CommonTypesBOYEOYAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boy_amt': value.boy_amt,
        'eoy_amt': value.eoy_amt,
    };
}

