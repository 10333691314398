/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFBuiltwithRecordItem } from './CRSFBuiltwithRecordItem';
import {
    CRSFBuiltwithRecordItemFromJSON,
    CRSFBuiltwithRecordItemFromJSONTyped,
    CRSFBuiltwithRecordItemToJSON,
} from './CRSFBuiltwithRecordItem';

/**
 * 
 * @export
 * @interface CRSFBuiltwithRecord
 */
export interface CRSFBuiltwithRecord {
    /**
     * 
     * @type {Array<CRSFBuiltwithRecordItem>}
     * @memberof CRSFBuiltwithRecord
     */
    Results: Array<CRSFBuiltwithRecordItem>;
    /**
     * 
     * @type {number}
     * @memberof CRSFBuiltwithRecord
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFBuiltwithRecord
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFBuiltwithRecord
     */
    process_id: string;
}

/**
 * Check if a given object implements the CRSFBuiltwithRecord interface.
 */
export function instanceOfCRSFBuiltwithRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Results" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFBuiltwithRecordFromJSON(json: any): CRSFBuiltwithRecord {
    return CRSFBuiltwithRecordFromJSONTyped(json, false);
}

export function CRSFBuiltwithRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFBuiltwithRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Results': ((json['Results'] as Array<any>).map(CRSFBuiltwithRecordItemFromJSON)),
        'created': json['created'],
        'domain': json['domain'],
        'process_id': json['process_id'],
    };
}

export function CRSFBuiltwithRecordToJSON(value?: CRSFBuiltwithRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Results': ((value.Results as Array<any>).map(CRSFBuiltwithRecordItemToJSON)),
        'created': value.created,
        'domain': value.domain,
        'process_id': value.process_id,
    };
}

