/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';
import type { CRSFScanType } from './CRSFScanType';
import {
    CRSFScanTypeFromJSON,
    CRSFScanTypeFromJSONTyped,
    CRSFScanTypeToJSON,
} from './CRSFScanType';
import type { CRSFStatusType } from './CRSFStatusType';
import {
    CRSFStatusTypeFromJSON,
    CRSFStatusTypeFromJSONTyped,
    CRSFStatusTypeToJSON,
} from './CRSFStatusType';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRSFProcess
 */
export interface CRSFProcess {
    /**
     * 
     * @type {number}
     * @memberof CRSFProcess
     */
    created: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFProcess
     */
    domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFProcess
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFProcess
     */
    entity_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFProcess
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFProcess
     */
    issue?: string;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFProcess
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFProcess
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {boolean}
     * @memberof CRSFProcess
     */
    no_result_flag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFProcess
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFProcess
     */
    report_url?: string;
    /**
     * 
     * @type {CRSFScanType}
     * @memberof CRSFProcess
     */
    scan_type: CRSFScanType;
    /**
     * 
     * @type {{ [key: string]: CRSFStatusType; }}
     * @memberof CRSFProcess
     */
    status: { [key: string]: CRSFStatusType; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CRSFStatusType; }; }}
     * @memberof CRSFProcess
     */
    statuses: { [key: string]: { [key: string]: CRSFStatusType; }; };
    /**
     * 
     * @type {number}
     * @memberof CRSFProcess
     */
    updated: number;
}

/**
 * Check if a given object implements the CRSFProcess interface.
 */
export function instanceOfCRSFProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statuses" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFProcessFromJSON(json: any): CRSFProcess {
    return CRSFProcessFromJSONTyped(json, false);
}

export function CRSFProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'no_result_flag': !exists(json, 'no_result_flag') ? undefined : json['no_result_flag'],
        'process_id': json['process_id'],
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'scan_type': CRSFScanTypeFromJSON(json['scan_type']),
        'status': (mapValues(json['status'], CRSFStatusTypeFromJSON)),
        'statuses': json['statuses'],
        'updated': json['updated'],
    };
}

export function CRSFProcessToJSON(value?: CRSFProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'domains': value.domains,
        'entity_logo_url': value.entity_logo_url,
        'entity_name': value.entity_name,
        'ip_addresses': value.ip_addresses,
        'issue': value.issue,
        'locale': CRSFLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'no_result_flag': value.no_result_flag,
        'process_id': value.process_id,
        'report_url': value.report_url,
        'scan_type': CRSFScanTypeToJSON(value.scan_type),
        'status': (mapValues(value.status, CRSFStatusTypeToJSON)),
        'statuses': value.statuses,
        'updated': value.updated,
    };
}

