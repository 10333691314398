import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ServiceFraudIcon
 */
export const ServiceFraudIcon = ({ ...props }: ServiceFraudIconProps) => {
  return (
    <Icon label="service_fraud" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7,16c0,3,2.4,5.3,5.3,5.3c3,0,5.3-2.4,5.3-5.3c0-3-2.4-5.3-5.3-5.3C13,10.7,10.7,13,10.7,16z M25.3,14.4c0.3,0,0.6,0.2,0.6,0.5c0.1,0.8,0.1,1.5,0,2.3c0,0.3-0.3,0.5-0.6,0.5l-0.1,0c-0.9,0-1.7,0.5-2,1.4c-0.4,0.9-0.1,1.9,0.6,2.5c0.2,0.2,0.2,0.5,0.1,0.8c-0.5,0.6-1,1.1-1.6,1.6C22,24,21.6,24,21.4,23.8c-0.6-0.7-1.7-0.9-2.5-0.6c-0.9,0.3-1.4,1.2-1.4,2.1c0,0.3-0.2,0.5-0.5,0.6C16.7,26,16.4,26,16,26c-0.4,0-0.8,0-1.2-0.1c-0.3,0-0.5-0.3-0.5-0.6c0.1-0.9-0.5-1.8-1.4-2.2c-0.8-0.3-1.9-0.1-2.5,0.6C10.3,24,10,24,9.7,23.8c-0.6-0.5-1.1-1-1.6-1.6C8,22,8,21.6,8.2,21.4c0.7-0.6,0.9-1.6,0.6-2.5c-0.4-0.9-1.2-1.4-2.1-1.4c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.8-0.1-1.5,0-2.3c0-0.3,0.3-0.5,0.6-0.5c1,0,1.8-0.5,2.2-1.4c0.4-0.9,0.1-1.9-0.6-2.5C8,10.3,8,10,8.2,9.7c0.5-0.6,1-1.1,1.6-1.6C10,8,10.4,8,10.6,8.2c0.6,0.7,1.7,0.9,2.5,0.6c0.9-0.3,1.4-1.2,1.4-2.1c0-0.3,0.2-0.5,0.5-0.6c0.7-0.1,1.5-0.1,2.3,0c0.3,0,0.5,0.3,0.5,0.6c-0.1,0.9,0.5,1.8,1.4,2.2c0.8,0.3,1.9,0.1,2.5-0.6C21.7,8,22,8,22.3,8.2c0.6,0.5,1.1,1,1.6,1.6c0.2,0.2,0.2,0.6-0.1,0.8c-0.7,0.6-0.9,1.6-0.6,2.5C23.6,13.9,24.4,14.4,25.3,14.4z M16.8,15c0.1,0,0.1,0,0.1-0.1v-0.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.7c0,0.1,0,0.1,0.1,0.1H16.8z M17.9,15.1L17.9,15.1c0.4,0,0.7,0.3,0.7,0.7v2.4c0,0.4-0.3,0.7-0.7,0.7H14c-0.4,0-0.7-0.3-0.7-0.7v-2.4c0-0.4,0.3-0.7,0.7-0.7h0.1c0.1,0,0.1-0.1,0.1-0.1v-0.7c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8V15C17.8,15,17.8,15.1,17.9,15.1z"
        />
      </g>
    </Icon>
  );
};

/**
 * ServiceFraudIcon Props
 */
export interface ServiceFraudIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
