/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { MonitEntityTechOrderBy } from './MonitEntityTechOrderBy';
import {
    MonitEntityTechOrderByFromJSON,
    MonitEntityTechOrderByFromJSONTyped,
    MonitEntityTechOrderByToJSON,
} from './MonitEntityTechOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityTechQueries
 */
export interface MonitGetEntityTechQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityTechQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityTechQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechQueries
     */
    process_id?: string;
    /**
     * 
     * @type {Category}
     * @memberof MonitGetEntityTechQueries
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechQueries
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechQueries
     */
    search?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityTechQueries
     */
    vuln?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityTechQueries
     */
    shared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityTechQueries
     */
    trim?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityTechQueries
     */
    tech?: string;
    /**
     * 
     * @type {MonitEntityTechOrderBy}
     * @memberof MonitGetEntityTechQueries
     */
    order_by?: MonitEntityTechOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityTechQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityTechQueries interface.
 */
export function instanceOfMonitGetEntityTechQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityTechQueriesFromJSON(json: any): MonitGetEntityTechQueries {
    return MonitGetEntityTechQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityTechQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityTechQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'vuln': !exists(json, 'vuln') ? undefined : json['vuln'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'trim': !exists(json, 'trim') ? undefined : json['trim'],
        'tech': !exists(json, 'tech') ? undefined : json['tech'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityTechOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityTechQueriesToJSON(value?: MonitGetEntityTechQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'process_id': value.process_id,
        'category': CategoryToJSON(value.category),
        'source_asset': value.source_asset,
        'search': value.search,
        'vuln': value.vuln,
        'shared': value.shared,
        'trim': value.trim,
        'tech': value.tech,
        'order_by': MonitEntityTechOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

