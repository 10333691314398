/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartI } from './ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartI';
import {
    ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSON,
    ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSONTyped,
    ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartIToJSON,
} from './ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartI';
import type { ExecRiskScheduleCBase } from './ExecRiskScheduleCBase';
import {
    ExecRiskScheduleCBaseFromJSON,
    ExecRiskScheduleCBaseFromJSONTyped,
    ExecRiskScheduleCBaseToJSON,
} from './ExecRiskScheduleCBase';

/**
 * 
 * @export
 * @interface ExecRiskScheduleCDef
 */
export interface ExecRiskScheduleCDef {
    /**
     * 
     * @type {ExecRiskScheduleCBase}
     * @memberof ExecRiskScheduleCDef
     */
    base_form?: ExecRiskScheduleCBase;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartI}
     * @memberof ExecRiskScheduleCDef
     */
    part1?: ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartI;
}

/**
 * Check if a given object implements the ExecRiskScheduleCDef interface.
 */
export function instanceOfExecRiskScheduleCDef(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleCDefFromJSON(json: any): ExecRiskScheduleCDef {
    return ExecRiskScheduleCDefFromJSONTyped(json, false);
}

export function ExecRiskScheduleCDefFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleCDef {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_form': !exists(json, 'base_form') ? undefined : ExecRiskScheduleCBaseFromJSON(json['base_form']),
        'part1': !exists(json, 'part1') ? undefined : ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSON(json['part1']),
    };
}

export function ExecRiskScheduleCDefToJSON(value?: ExecRiskScheduleCDef | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_form': ExecRiskScheduleCBaseToJSON(value.base_form),
        'part1': ExecRiskAppCoreModelsScansLongForm5500ScheduleCDefPartIToJSON(value.part1),
    };
}

