/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntity } from './MonitEntity';
import {
    MonitEntityFromJSON,
    MonitEntityFromJSONTyped,
    MonitEntityToJSON,
} from './MonitEntity';

/**
 * 
 * @export
 * @interface MonitEntitySearchResponse
 */
export interface MonitEntitySearchResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySearchResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySearchResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySearchResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntity>}
     * @memberof MonitEntitySearchResponse
     */
    results?: Array<MonitEntity>;
}

/**
 * Check if a given object implements the MonitEntitySearchResponse interface.
 */
export function instanceOfMonitEntitySearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySearchResponseFromJSON(json: any): MonitEntitySearchResponse {
    return MonitEntitySearchResponseFromJSONTyped(json, false);
}

export function MonitEntitySearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MonitEntityFromJSON)),
    };
}

export function MonitEntitySearchResponseToJSON(value?: MonitEntitySearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MonitEntityToJSON)),
    };
}

