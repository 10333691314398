import React, { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { formatMessage } from '@explorer/helpers';
import { useIntl } from 'react-intl';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '@explorer/core/types';
import { CoalitionVariant } from '@mui/material';

export const Typography = withCoreProps<TypographyProps>(
  ({
    label,
    variant = 'body1',
    colon = false,
    monospace = false,
    children,
    className,
    style,
    ...props
  }: PropsWithChildren<TypographyProps>) => {
    const clsxClass = clsx(`CoalitionTypography-${variant}`, className);
    const intl = useIntl();

    const content = useMemo(() => {
      if (label && children) {
        // eslint-disable-next-line no-console
        console.warn(
          'Warning: [packages/core/Typography] `children` is overwritten by the usage of a `label`. Please use only one.',
        );
      }

      if (label) return formatMessage(intl, label);

      return children;
    }, [intl, label, children]);

    return (
      <MuiTypography
        {...props}
        className={clsxClass}
        style={monospace ? { ...style, fontFamily: 'monospace' } : style}
      >
        {content}
        {colon ? ':' : null}
      </MuiTypography>
    );
  },
);

export interface TypographyProps
  extends Omit<MuiTypographyProps & CoreProps, 'variant' | 'component'> {
  /**
   * `variant` - the typography variation
   */
  variant: CoalitionVariant;
  /**
   * `label` - *OPTIONAL* - string or localized message id
   */
  label?: IntlLabel;
  /**
   * `component` - *OPTIONAL* - forces a html element to be used
   */
  component?: ComponentProp;
  /**
   * `colon` - *OPTIONAL* - add a ":" in the end
   */
  colon?: boolean;
  /**
   * `monospace` - *OPTIONAL* - forces the font family to be 'monospace'
   */
  monospace?: boolean;
}

type ComponentProp =
  | 'div'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'caption'
  | 'legend';
