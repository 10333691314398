/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFPayloadFieldsInput } from './CRSFPayloadFieldsInput';
import {
    CRSFPayloadFieldsInputFromJSON,
    CRSFPayloadFieldsInputFromJSONTyped,
    CRSFPayloadFieldsInputToJSON,
} from './CRSFPayloadFieldsInput';

/**
 * 
 * @export
 * @interface CRSFTicketPayloadInput
 */
export interface CRSFTicketPayloadInput {
    /**
     * 
     * @type {CRSFPayloadFieldsInput}
     * @memberof CRSFTicketPayloadInput
     */
    fields?: CRSFPayloadFieldsInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFTicketPayloadInput
     */
    key?: string;
}

/**
 * Check if a given object implements the CRSFTicketPayloadInput interface.
 */
export function instanceOfCRSFTicketPayloadInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFTicketPayloadInputFromJSON(json: any): CRSFTicketPayloadInput {
    return CRSFTicketPayloadInputFromJSONTyped(json, false);
}

export function CRSFTicketPayloadInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTicketPayloadInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : CRSFPayloadFieldsInputFromJSON(json['fields']),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function CRSFTicketPayloadInputToJSON(value?: CRSFTicketPayloadInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': CRSFPayloadFieldsInputToJSON(value.fields),
        'key': value.key,
    };
}

