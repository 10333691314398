/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDataleaks } from './MonitEntityDataleaks';
import {
    MonitEntityDataleaksFromJSON,
    MonitEntityDataleaksFromJSONTyped,
    MonitEntityDataleaksToJSON,
} from './MonitEntityDataleaks';
import type { MonitEntitySurfaceAssets } from './MonitEntitySurfaceAssets';
import {
    MonitEntitySurfaceAssetsFromJSON,
    MonitEntitySurfaceAssetsFromJSONTyped,
    MonitEntitySurfaceAssetsToJSON,
} from './MonitEntitySurfaceAssets';
import type { MonitEntitySurfaceType } from './MonitEntitySurfaceType';
import {
    MonitEntitySurfaceTypeFromJSON,
    MonitEntitySurfaceTypeFromJSONTyped,
    MonitEntitySurfaceTypeToJSON,
} from './MonitEntitySurfaceType';

/**
 * 
 * @export
 * @interface MonitEntityStatsSurface
 */
export interface MonitEntityStatsSurface {
    /**
     * 
     * @type {MonitEntitySurfaceType}
     * @memberof MonitEntityStatsSurface
     */
    applications?: MonitEntitySurfaceType;
    /**
     * 
     * @type {MonitEntitySurfaceType}
     * @memberof MonitEntityStatsSurface
     */
    services?: MonitEntitySurfaceType;
    /**
     * 
     * @type {MonitEntitySurfaceType}
     * @memberof MonitEntityStatsSurface
     */
    hosting?: MonitEntitySurfaceType;
    /**
     * 
     * @type {MonitEntitySurfaceAssets}
     * @memberof MonitEntityStatsSurface
     */
    assets?: MonitEntitySurfaceAssets;
    /**
     * 
     * @type {MonitEntityDataleaks}
     * @memberof MonitEntityStatsSurface
     */
    dataleaks?: MonitEntityDataleaks;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsSurface
     */
    homoglyphs?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsSurface
     */
    sinkhole?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsSurface
     */
    torrents?: number;
}

/**
 * Check if a given object implements the MonitEntityStatsSurface interface.
 */
export function instanceOfMonitEntityStatsSurface(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityStatsSurfaceFromJSON(json: any): MonitEntityStatsSurface {
    return MonitEntityStatsSurfaceFromJSONTyped(json, false);
}

export function MonitEntityStatsSurfaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsSurface {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : MonitEntitySurfaceTypeFromJSON(json['applications']),
        'services': !exists(json, 'services') ? undefined : MonitEntitySurfaceTypeFromJSON(json['services']),
        'hosting': !exists(json, 'hosting') ? undefined : MonitEntitySurfaceTypeFromJSON(json['hosting']),
        'assets': !exists(json, 'assets') ? undefined : MonitEntitySurfaceAssetsFromJSON(json['assets']),
        'dataleaks': !exists(json, 'dataleaks') ? undefined : MonitEntityDataleaksFromJSON(json['dataleaks']),
        'homoglyphs': !exists(json, 'homoglyphs') ? undefined : json['homoglyphs'],
        'sinkhole': !exists(json, 'sinkhole') ? undefined : json['sinkhole'],
        'torrents': !exists(json, 'torrents') ? undefined : json['torrents'],
    };
}

export function MonitEntityStatsSurfaceToJSON(value?: MonitEntityStatsSurface | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': MonitEntitySurfaceTypeToJSON(value.applications),
        'services': MonitEntitySurfaceTypeToJSON(value.services),
        'hosting': MonitEntitySurfaceTypeToJSON(value.hosting),
        'assets': MonitEntitySurfaceAssetsToJSON(value.assets),
        'dataleaks': MonitEntityDataleaksToJSON(value.dataleaks),
        'homoglyphs': value.homoglyphs,
        'sinkhole': value.sinkhole,
        'torrents': value.torrents,
    };
}

