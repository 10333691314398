/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlMuteRequestStatus } from './ControlMuteRequestStatus';
import {
    ControlMuteRequestStatusFromJSON,
    ControlMuteRequestStatusFromJSONTyped,
    ControlMuteRequestStatusToJSON,
} from './ControlMuteRequestStatus';

/**
 * 
 * @export
 * @interface ControlMuteRequestBodyAdmin
 */
export interface ControlMuteRequestBodyAdmin {
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestBodyAdmin
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestBodyAdmin
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestBodyAdmin
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestBodyAdmin
     */
    requested_by?: string;
    /**
     * 
     * @type {ControlMuteRequestStatus}
     * @memberof ControlMuteRequestBodyAdmin
     */
    status?: ControlMuteRequestStatus;
}

/**
 * Check if a given object implements the ControlMuteRequestBodyAdmin interface.
 */
export function instanceOfControlMuteRequestBodyAdmin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ControlMuteRequestBodyAdminFromJSON(json: any): ControlMuteRequestBodyAdmin {
    return ControlMuteRequestBodyAdminFromJSONTyped(json, false);
}

export function ControlMuteRequestBodyAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMuteRequestBodyAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'reason': json['reason'],
        'requested_by': !exists(json, 'requested_by') ? undefined : json['requested_by'],
        'status': !exists(json, 'status') ? undefined : ControlMuteRequestStatusFromJSON(json['status']),
    };
}

export function ControlMuteRequestBodyAdminToJSON(value?: ControlMuteRequestBodyAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'datapoint': value.datapoint,
        'reason': value.reason,
        'requested_by': value.requested_by,
        'status': ControlMuteRequestStatusToJSON(value.status),
    };
}

