/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZeroDayAlert } from './ControlZeroDayAlert';
import {
    ControlZeroDayAlertFromJSON,
    ControlZeroDayAlertFromJSONTyped,
    ControlZeroDayAlertToJSON,
} from './ControlZeroDayAlert';
import type { ControlZeroDayAlertClientAssociation } from './ControlZeroDayAlertClientAssociation';
import {
    ControlZeroDayAlertClientAssociationFromJSON,
    ControlZeroDayAlertClientAssociationFromJSONTyped,
    ControlZeroDayAlertClientAssociationToJSON,
} from './ControlZeroDayAlertClientAssociation';

/**
 * 
 * @export
 * @interface ControlAppSchemasZeroDayGetZeroDayAlertResponse
 */
export interface ControlAppSchemasZeroDayGetZeroDayAlertResponse {
    /**
     * 
     * @type {ControlZeroDayAlert}
     * @memberof ControlAppSchemasZeroDayGetZeroDayAlertResponse
     */
    alert: ControlZeroDayAlert;
    /**
     * 
     * @type {ControlZeroDayAlertClientAssociation}
     * @memberof ControlAppSchemasZeroDayGetZeroDayAlertResponse
     */
    association: ControlZeroDayAlertClientAssociation;
}

/**
 * Check if a given object implements the ControlAppSchemasZeroDayGetZeroDayAlertResponse interface.
 */
export function instanceOfControlAppSchemasZeroDayGetZeroDayAlertResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "alert" in value;
    isInstance = isInstance && "association" in value;

    return isInstance;
}

export function ControlAppSchemasZeroDayGetZeroDayAlertResponseFromJSON(json: any): ControlAppSchemasZeroDayGetZeroDayAlertResponse {
    return ControlAppSchemasZeroDayGetZeroDayAlertResponseFromJSONTyped(json, false);
}

export function ControlAppSchemasZeroDayGetZeroDayAlertResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppSchemasZeroDayGetZeroDayAlertResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': ControlZeroDayAlertFromJSON(json['alert']),
        'association': ControlZeroDayAlertClientAssociationFromJSON(json['association']),
    };
}

export function ControlAppSchemasZeroDayGetZeroDayAlertResponseToJSON(value?: ControlAppSchemasZeroDayGetZeroDayAlertResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': ControlZeroDayAlertToJSON(value.alert),
        'association': ControlZeroDayAlertClientAssociationToJSON(value.association),
    };
}

