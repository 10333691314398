import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Box, Grid, Typography } from '@explorer/core';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

const useStyles = ({}: Partial<ChartEmptyStateProps>) =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        content: {
          color: palette.text.secondary,
        },
      }),
    { index: 1 },
  )();

/**
 * ChartEmptyState description.
 */
export const ChartEmptyState = ({ height = 360 }: ChartEmptyStateProps) => {
  const classes = useStyles({});

  return (
    <Box height={height}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.content}
        height={height}
      >
        <Grid item>
          <DonutLargeIcon style={{ fontSize: '5rem' }} />
        </Grid>
        <Grid item>
          <Typography variant="h3">No Results</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export interface ChartEmptyStateProps {
  height?: number;
  errorCode?: number | string;
  errorMsg?: string;
  hideContact?: boolean;
}
