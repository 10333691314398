/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRACoBranded } from './CRACoBranded';
import {
    CRACoBrandedFromJSON,
    CRACoBrandedFromJSONTyped,
    CRACoBrandedToJSON,
} from './CRACoBranded';

/**
 * 
 * @export
 * @interface CRAMultipleCobranded
 */
export interface CRAMultipleCobranded {
    /**
     * 
     * @type {Array<CRACoBranded>}
     * @memberof CRAMultipleCobranded
     */
    results: Array<CRACoBranded>;
}

/**
 * Check if a given object implements the CRAMultipleCobranded interface.
 */
export function instanceOfCRAMultipleCobranded(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function CRAMultipleCobrandedFromJSON(json: any): CRAMultipleCobranded {
    return CRAMultipleCobrandedFromJSONTyped(json, false);
}

export function CRAMultipleCobrandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAMultipleCobranded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(CRACoBrandedFromJSON)),
    };
}

export function CRAMultipleCobrandedToJSON(value?: CRAMultipleCobranded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(CRACoBrandedToJSON)),
    };
}

