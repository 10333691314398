/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsFilterTarget = {
    User: 'user'
} as const;
export type NotificationsFilterTarget = typeof NotificationsFilterTarget[keyof typeof NotificationsFilterTarget];


export function NotificationsFilterTargetFromJSON(json: any): NotificationsFilterTarget {
    return NotificationsFilterTargetFromJSONTyped(json, false);
}

export function NotificationsFilterTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsFilterTarget {
    return json as NotificationsFilterTarget;
}

export function NotificationsFilterTargetToJSON(value?: NotificationsFilterTarget | null): any {
    return value as any;
}

