import React from 'react';
import clsx from 'clsx';
import MuiMenuList, {
  MenuListProps as MuiMenuListProps,
} from '@mui/material/MenuList';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const MenuList = withCoreProps<MenuListProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<MenuListProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiMenuList {...props} className={clsxClass}>
        {children}
      </MuiMenuList>
    );
  },
);

export interface MenuListProps extends MuiMenuListProps, CoreProps {}
