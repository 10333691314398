/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplementalServiceReadinessDependencies
 */
export interface SupplementalServiceReadinessDependencies {
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceReadinessDependencies
     */
    database?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceReadinessDependencies
     */
    databaseschema?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceReadinessDependencies
     */
    secrets?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceReadinessDependencies
     */
    sentry?: string;
}

/**
 * Check if a given object implements the SupplementalServiceReadinessDependencies interface.
 */
export function instanceOfSupplementalServiceReadinessDependencies(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalServiceReadinessDependenciesFromJSON(json: any): SupplementalServiceReadinessDependencies {
    return SupplementalServiceReadinessDependenciesFromJSONTyped(json, false);
}

export function SupplementalServiceReadinessDependenciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalServiceReadinessDependencies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'database': !exists(json, 'database') ? undefined : json['database'],
        'databaseschema': !exists(json, 'database:schema') ? undefined : json['database:schema'],
        'secrets': !exists(json, 'secrets') ? undefined : json['secrets'],
        'sentry': !exists(json, 'sentry') ? undefined : json['sentry'],
    };
}

export function SupplementalServiceReadinessDependenciesToJSON(value?: SupplementalServiceReadinessDependencies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'database': value.database,
        'database:schema': value.databaseschema,
        'secrets': value.secrets,
        'sentry': value.sentry,
    };
}

