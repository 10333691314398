/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityStatsVulnerabilityCategoriesCount
 */
export interface MonitEntityStatsVulnerabilityCategoriesCount {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    ssl: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    encryption: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    web: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    remote: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    storage: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    eol: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    cve: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    dns: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    token: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    exposure: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilityCategoriesCount
     */
    other: number;
}

/**
 * Check if a given object implements the MonitEntityStatsVulnerabilityCategoriesCount interface.
 */
export function instanceOfMonitEntityStatsVulnerabilityCategoriesCount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ssl" in value;
    isInstance = isInstance && "encryption" in value;
    isInstance = isInstance && "web" in value;
    isInstance = isInstance && "remote" in value;
    isInstance = isInstance && "storage" in value;
    isInstance = isInstance && "eol" in value;
    isInstance = isInstance && "cve" in value;
    isInstance = isInstance && "dns" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "exposure" in value;
    isInstance = isInstance && "other" in value;

    return isInstance;
}

export function MonitEntityStatsVulnerabilityCategoriesCountFromJSON(json: any): MonitEntityStatsVulnerabilityCategoriesCount {
    return MonitEntityStatsVulnerabilityCategoriesCountFromJSONTyped(json, false);
}

export function MonitEntityStatsVulnerabilityCategoriesCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsVulnerabilityCategoriesCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ssl': json['ssl'],
        'encryption': json['encryption'],
        'web': json['web'],
        'remote': json['remote'],
        'storage': json['storage'],
        'eol': json['eol'],
        'cve': json['cve'],
        'dns': json['dns'],
        'token': json['token'],
        'exposure': json['exposure'],
        'other': json['other'],
    };
}

export function MonitEntityStatsVulnerabilityCategoriesCountToJSON(value?: MonitEntityStatsVulnerabilityCategoriesCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ssl': value.ssl,
        'encryption': value.encryption,
        'web': value.web,
        'remote': value.remote,
        'storage': value.storage,
        'eol': value.eol,
        'cve': value.cve,
        'dns': value.dns,
        'token': value.token,
        'exposure': value.exposure,
        'other': value.other,
    };
}

