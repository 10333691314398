/**
 * Message Syntax: https://formatjs.io/docs/core-concepts/icu-syntax
 */
// prettier-ignore
export const en: IntlMessages = {
  /**
   * Error Messages
   */
  'errors.invalidEmail': 'The provided email is not valid.',
  'errors.invalidDate': 'The provided date is not valid.',
  'errors.invalidAccountUUID': 'The provided account UUID is not valid.',
  'errors.positiveNumber': 'Must be a positive number.',
  'errors.requireHighestInsuringAgreement': 'Please choose a coverage.',
  'errors.requireReportedDate': 'Please set a reported date.',
  'errors.requiredAccountUUID': 'Please enter an account UUID.',
  'errors.requiredClaimHandler': 'Please choose a claim handler.',
  'errors.requiredClaimType': 'Please choose a claim type.',
  'errors.requiredClientId': 'Please provide a client id.',
  'errors.requiredRole': 'Please select a role.',
  'errors.requiredCloseClaim': 'Please provide a reason for why the claim is closed.',
  'errors.requiredCoalitionHandler': 'Please choose a Coalition handler.',
  'errors.requiredCompany': 'Please provide a company name.',
  'errors.requiredClaimNotes': 'Please provide notes for this claim.',
  'errors.requiredCompanyDomain': 'Please provide a company domain.',
  'errors.requiredContactType': 'Please choose a contact type.',
  'errors.requiredDateExpense': 'Please provide an expense date.',
  'errors.requiredDateLoss': 'Please provide a loss date.',
  'errors.requiredEndorsement': 'Please choose an endorsement.',
  'errors.requiredDescription': 'Please provide a description.',
  'errors.requiredEmail': 'Please provide an email.',
  'errors.requiredExpenseCategory': 'Please select a category for this expense.',
  'errors.requiredIndustry': 'Please provide an industry.',
  'errors.requiredNumberOfEmployees': 'Please enter the number of employees.',
  'errors.requiredNumberOfRecord': 'Please enter the number of records.',
  'errors.requiredCompanyRevenue': 'Please enter the company revenue.',
  'errors.requiredLoginEmail': 'Please enter your login email.',
  'errors.requiredLoginPassword': 'Please enter your password.',
  'errors.requiredEntityDomain': 'Please provide an entity domain.',
  'errors.requiredEntityName': 'Please provide an entity name.',
  'errors.requiredEventTypes': 'Please choose at least one event type.',
  'errors.requiredFirstName': 'Please provide a first name.',
  'errors.requiredLastName': 'Please provide a last name.',
  'errors.requiredLinkContact': 'Please choose a contact to link.',
  'errors.requiredLinkVendor': 'Please choose a vendor to link.',
  'errors.requiredLossCategory': 'Please select a category for this loss.',
  'errors.requiredName': 'Please provide a name.',
  'errors.requiredPDFNoticeType': 'Please choose a notice type.',
  'errors.requiredPDFStatus': 'Please provide a claim status.',
  'errors.requiredPolicy': 'Please select a policy.',
  'errors.requiredPolicyId': 'Please provide a policy id.',
  'errors.requiredPolicyNumber': 'Please provide a policy number.',
  'errors.requiredPolicyholder': 'Please choose a policyholder.',
  'errors.requiredPCA': 'Please provide Pre-Claims Assistance.',
  'errors.requiredVendor': 'Please provide the name of the vendor.',
  'errors.requiredVendorCategory': 'Please select a category for this vendor.',
  'errors.user-not-allowed-title': 'You can\'t access this module.',
  'errors.user-not-allowed-details': 'Your account **does not have the necessary permissions** to access this page. If you believe this is a mistake, please contact us at the [#help-team-explorer](https://coalitionrisk.slack.com/archives/C04DDE6KW93) Slack channel.',
  'errors.user-has-no-permissions-title': 'You can\'t access Coalition Explorer.',
  'errors.user-has-no-permissions-details': 'Your account currently **does not have permissions** to access any module on Coalition Explorer. If you believe this is a mistake, please contact us at the [#help-team-explorer](https://coalitionrisk.slack.com/archives/C04DDE6KW93) Slack channel.',
  'errors.unexpected-error-title': 'An unexpected error has occured',
  'errors.unexpected-error-details': 'Please report this situation in the [#help-team-explorer](https://coalitionrisk.slack.com/archives/C04DDE6KW93) Slack channel so our Engineers can fix the issue.',
  'errors.sentry-error-title': 'An unexpected error with Sentry has occured',
  'errors.sentry-error-details': 'Please report this situation in the [#help-team-explorer](https://coalitionrisk.slack.com/archives/C04DDE6KW93) Slack channel so our Engineers can fix the issue.',
  'errors.page-not-found-title': 'Page not Found',
  'errors.page-not-found-details': 'The page you are tried to access does not exist in Coalition Explorer. If you think this might be a bug, please report the situation in the [#help-team-explorer](https://coalitionrisk.slack.com/archives/C04DDE6KW93) Slack channel.',
  /**
   * Global
   */
  'global.actions': 'Actions',
  'global.advanced-search': 'Advanced Search',
  'global.age': 'Age',
  'global.agency': 'Agency',
  'global.active': 'Active',
  'global.all': 'All',
  'global.app': 'App',
  'global.application': 'Application',
  'global.applications': 'Applications',
  'global.apply': 'Apply',
  'global.are-you-sure': 'Are you sure?',
  'global.asset': 'Asset',
  'global.assets': 'Assets',
  'global.associated-users': 'Associated Users',
  'global.back-to-home': 'Back to the Home page',
  'global.back-to-company-dashboard': 'Back to the company dashboard',
  'global.broker': 'Broker',
  'global.broker_email': 'Broker Email',
  'global.broker_name': 'Broker Name',
  'global.cancel': 'Cancel',
  'global.confirm': 'Confirm',
  'global.carrierFormType': 'Form Type',
  'global.categories': 'Categories',
  'global.category': 'Category',
  'global.claim': 'Claim',
  'global.claimHandler': 'Claim Handler',
  'global.claimNotes': 'Claim Notes',
  'global.claimType': 'Claim Type',
  'global.claims': 'Claims',
  'global.clear': 'Clear',
  'global.clientId': 'Client ID',
  'global.close': 'Close',
  'global.closed': 'Closed',
  'global.coalitionHandler': 'Coalition Handler',
  'global.companyDomain': 'Company Domain',
  'global.companyName': 'Company Name',
  'global.contactType': 'Contact Type',
  'global.created': 'Created',
  'global.created-at': 'Created At',
  'global.criticality': 'Criticality',
  'global.currentStatus': 'Current Status',
  'global.datapoint': 'Datapoint',
  'global.databreaches': 'Data Breaches',
  'global.date': 'Date',
  'global.dateReported': 'Date Reported',
  'global.dateReportedFrom': 'Reported From',
  'global.dateReportedTo': 'Reported To',
  'global.delete-permanently': 'Delete Permanently',
  'global.description': 'Description',
  'global.domain': 'Domain',
  'global.domains': 'Domains',
  'global.ip-address': 'IP Address',
  'global.ip-addresses': 'IP Addresses',
  'global.dragDropLossRuns': 'Please upload an excel file',
  'global.email': 'Email',
  'global.enter': 'Enter',
  'global.encryption': 'Encryption',
  'global.entityDomain': 'Entity Domain',
  'global.entityName': 'Entity Name',
  'global.eventType': 'Event Type',
  'global.eventTypes': 'Event Types',
  'global.expired': 'Expired',
  'global.end-date': 'End Date',
  'global.export': 'Export',
  'global.export-tooltip': 'Export results are limited to the FIRST 5,000 entries. For more, contact #help-team-explorer via Slack.',
  'global.exporting-csv': 'Exporting CSV...',
  'global.successful-export-csv': 'CSV Exported Successfully', 
  'global.fail-export-csv': 'CSV Export Failed',
  'global.findings': 'Findings',
  'global.flags': 'Flags',
  'global.found': 'Found',
  'global.free': 'Free',
  'global.from': 'From',
  'global.highestInsuringAgreement': 'Highest Insuring Agreement',
  'global.home': 'Home',
  'global.hosting': 'Hosting',
  'global.honeypots': 'Honeypots',
  'global.issue': 'Issue',
  'global.jiraTicket': 'JIRA Ticket',
  'global.last-login': 'Last Login',
  'global.leak': 'Leak',
  'global.level': 'Level',
  'global.limit': 'Limit',
  'global.login': 'Log In',
  'global.lastScanned': 'Last Scanned',
  'global.lastUpdated': 'Last Updated',
  'global.lookalikes': 'Lookalikes',
  'global.maxActive': 'Max Extended',
  'global.maxPassive': 'Max Lite',
  'global.monitType': 'Monitoring Type',
  'global.monitTypeActive': 'Extended',
  'global.monitTypePassive': 'Lite',
  'global.moreDetails': 'See more details about {item}',
  'global.most-relevant': 'Most relevant',
  'global.name': 'Name',
  'global.next': 'Next',
  'global.no': 'No',
  'global.notes': 'Notes',
  'global.notification': 'Notification',
  'global.notificationType': 'Notification Type',
  'global.open': 'Open',
  'global.optional': 'Optional',
  'global.paid': 'Paid',
  'global.pageNotFound': 'Page not found',
  'global.password': 'Password',
  'global.phoneExt': 'Phone Ext.',
  'global.phone_number': 'Phone Number',
  'global.policyId': 'Policy ID',
  'global.policyNumber': 'Policy Number',
  'global.policyholder': 'Policyholder',
  'global.policyholders': 'Policyholders',
  'global.port': 'Port',
  'global.preClaim': 'Pre-Claim',
  'global.previous': 'Previous',
  'global.premium': 'Premium',
  'global.protocol': 'Protocol',
  'global.reason': 'Reason',
  'global.recipient': 'Recipient',
  'global.reset': 'Reset',
  'global.retry': 'Retry',
  'global.riskRanking': 'Risk Ranking',
  'global.riskScore': 'Risk Score',
  'global.role': 'Role',
  'global.scans': 'Scans',
  'global.search': 'Search',
  'global.selectCategory': 'Select a category',
  'global.selectContact': 'Select a contact',
  'global.selectVendor': 'Select a vendor',
  'global.sentAt': 'Sent At',
  'global.services': 'Services',
  'global.severity': 'Severity',
  'global.status': 'Status',
  'global.submit': 'Submit',
  'global.tag': 'Tag',
  'global.tags': 'Tags',
  'global.technologies': 'Technologies',
  'global.trialStatus': 'Trial Status',
  'global.trialActivated': 'Trial Activated',
  'global.trialActivated.tooltip': 'Trial activation is defined by when the user has created credentials and logged into Control',
  'global.trialSummary': 'Trial Summary',
  'global.trialStatusInactive': 'Inactive',
  'global.trialLength': 'Trial Length',
  'global.trialPeriod': 'Trial Period',
  'global.noEndDate': 'No End Date',
  'global.to': 'To',
  'global.torrents': 'Torrents',
  'global.total': 'Total',
  'global.type': 'Type',
  'global.updated': 'Updated',
  'global.updated-at': 'Updated At',
  'global.upload': 'Upload',
  'global.update': 'Update',
  'global.unknown': 'Unknown',
  'global.vendor': 'Vendor',
  'global.version': 'Version',
  'global.view-more': 'View More',
  'global.vulnerabilities': 'Vulnerabilities',
  'global.vulnerability': 'Vulnerability',
  'global.yes': 'Yes',
  'global.snackbarDismissal': 'Dismiss',
  'global.critical-risk': 'Critical Risk',
  'global.high-risk': 'High Risk',
  'global.medium-risk': 'Medium Risk',
  'global.low-risk': 'Low Risk',
  'global.rdp': 'RDP',
  'global.iot': 'IoT',
  'global.malware': 'Malware',
  'global.ssl-tls': 'SSL / TLS',
  'global.web': 'Web',
  'global.storage': 'Storage',
  'global.total-leaks': 'Total Leaks',
  'global.pii': 'PII',
  'global.ips': 'IPs',
  'global.passwords': 'Passwords',
  'global.send-email-to': 'Send an email to {email}',
  'global.delete': 'Delete',
  'global.first-name': 'First Name',
  'global.last-name': 'Last Name',
  'global.search-contacts': 'Search for contacts',
  'global.document': 'Document',
  /**
   * Beta Version
   */
  'beta.alert': '**[BETA VERSION]** Welcome to the new Coalition Explorer experience! **Most features are still disabled here**, for a fully working version go to [{linkLabel}]({link})',
  'beta.disabled-in-beta': 'Disabled in Beta',
  /**
   * Home
   */
  'home.explorer-title': 'Coalition Explorer',
  'home.explorer-subtitle': 'One stop shop for all Customer Security needs',
  'home.explorer-description': 'Explore Coalition\'s book through the tools and data collected by the Customer Security team.',
  'home.monitoring': 'Monitoring',
  'home.monitoring-title': 'Attack Surface Monitoring',
  'home.monitoring-description': 'Explore the universe of all internet facing assets of our customers. Analyze their cyber security posture, behaviour, and risk.',
  'home.monitoring-dashboard': 'Book Dashboard',
  'home.monitoring-companies': 'All Companies',
  'home.monitoring-notifications': 'All Notifications',
  'home.monitoring-assets': 'All Assets',
  'home.monitoring-vulnerabilities': 'All Vulnerabilities',
  'home.monitoring-technologies': 'All Technologies',
  'home.claims-title': 'Claims',
  'home.claims-description': 'Explore existing claims in the book or file new ones.',
  'home.claims-claims': 'Claims',
  'home.claims-accounts': 'Accounts',
  'home.claims-claims-calculator': 'Claims Calculator',
  'home.cra-title': 'Risk Assessment',
  'home.cra-description': 'Explore existing CRAs and trigger new document generation.',
  'home.cra-reports': 'CRA Reports',
  'home.cra': 'CRA',
  'home.secreview-title': 'Security Review',
  'home.secreview-description': 'Trigger Security Review scans and explore their details and results.',
  'home.secreview-scans': 'Scans',
  'home.execrisks-title': 'Executive Risks',
  'home.execrisks-description': 'Trigger Executive Risks scans and explore their details and results.',
  'home.supplementals-title': 'Supplementals',
  'home.supplementals-description': 'Admin panel for all Coalition Supplemental Forms (RSA).',
  'home.supplementals-accounts': 'Accounts',
  'home.execrisks-scans': 'Scans',
  'home.admin-control-title': 'Control Admin Panel',
  'home.admin-control-description': 'Administration settings and controls for Coalition Control.',
  'home.admin-explorer-title': 'Explorer Admin Panel',
  'home.admin-explorer-description': 'Administration settings and controls for Coalition Explorer.',
  'home.activity-title': 'Search for activity in the book',
  'home.assets-domains-title': 'Search for domains in the book',
  'home.assets-ips-title': 'Search for IP addresses in the book',
  'home.notifications-title': 'Search for notifications in the book',
  'home.policyolders-title': 'Search for policyholders and brokers in the book',
  'home.technologies-title': 'Search for technologies in the book',
  'home.vulnerabilities-title': 'Search for vulnerabilities in the book',
  'home.chart-title-assets': 'Policyholders with most Assets',
  'home.chart-title-vulnerabilities': 'Policyholders with most Vulnerabilities',
  'home.chart-title-dataleaks': 'Policyholders with most Data Breaches',
  'home.chart-title-risk': 'Riskiest Policyholders',
  'home.go-to-asset': 'Go to asset',
  'home.back-to-company': 'Back to Company',
  'home.fill-last-entry': 'Fill with last entry',
  'home.zero-day-alert-title': 'Zero Day Alert',
  'home.zero-day-alert-description': 'Admin panel to create, view and manage zero-day alerts.',
  'home.all-alerts': 'All Alerts',
  /**
   * Policyholders
   */
  'policyholders.activity-title': 'Search for activity in {companyName}',
  'policyholders.assets-title': 'Search for assets in {companyName}',
  'policyholders.databreaches-title': 'Search for data breaches in {companyName}',
  'policyholders.event-log-title': 'Search event logs in {companyName}',
  'policyholders.honeypots-title': 'Search for honeypot sensors in {companyName}',
  'policyholders.lookalikes-title': 'Search for lookalike domains in {companyName}',
  'policyholders.notifications-title': 'Search for notifications in {companyName}',
  'policyholders.technologies-title': 'Search for technologies in {companyName}',
  'policyholders.torrents-title': 'Search for torrents in {companyName}',
  'policyholders.vulnerabilities-title': 'Search for vulnerabilities in {companyName}',
  'policyholders.vulnerabilities-disclaimer': 'Please note that company-specific actions like Rescan and Mute are only available when navigating to a company via the Control Admin Panel.',
  /**
   * Claims
   */
  'claims.accounts': 'Accounts',
  'claims.add-contact': 'Add Contact',
  'claims.add-expense': 'Add Expense',
  'claims.add-vendor': 'Add Vendor',
  'claims.add-loss-description': 'To select an Endorsement, make sure it is first added under the "Coverages" tab.',
  'claims.add-loss-title': 'Add expenses to the claim',
  'claims.agg-limit-remaining': 'Agg Limit Remaining',
  'claims.change-to-claim': 'Change to Claim',
  'claims.change-to-preclaim': 'Change to Pre-Claim',
  'claims.claim-contacts': 'Contacts for the claim {claimId}',
  'claims.claim-coverages': 'Coverages for the claim {claimId}',
  'claims.claim-vendors': 'Vendors for the claim {claimId}',
  'claims.claims-handling-entity': 'Claims Handling Entity',
  'claims.claims-title': 'Search for claims in the book',
  'claims.coalition-claims-owner': 'Coalition Claims Owner',
  'claims.coalition-sums-data': 'Coalition Sums Data',
  'claims.coverage': 'Coverage',
  'claims.date-of-loss': 'Date of Loss',
  'claims.delete-loss': 'Delete Loss',
  'claims.denotes-separate-limits': '* Denotes separate limits',
  'claims.edit-pca-title': 'Edit Pre-Claims Assistance',
  'claims.edit-loss-title': 'Edit Loss',
  'claims.expense': 'Expense',
  'claims.expense-date': 'Expense Date',
  'claims.file-a-new-claim': 'File a new claim',
  'claims.ftf': 'FTF',
  'claims.ftf-loss': 'FTF Loss',
  'claims.ftf-recovered': 'FTF Recovered',
  'claims.gross-incurred': 'Gross Incurred',
  'claims.gross-loss': 'Gross Loss',
  'claims.gross-paid': 'Gross Paid',
  'claims.gross-paid-recovered': 'Gross Paid Recovered',
  'claims.gross-reserve': 'Gross Reserve',
  'claims.incurred': 'Incurred',
  'claims.insuring-agreement': 'Insuring Agreement',
  'claims.losses-advsearch-title': 'Search for losses',
  'claims.net-incurred': 'Net Incurred',
  'claims.net-loss': 'Net Loss',
  'claims.net-paid': 'Net Paid',
  'claims.net-reserved': 'Net Reserved',
  'claims.no-data-available': 'There is no data available for this claim.',
  'claims.paid-rec': 'Paid Rec.',
  'claims.paid-recovery': 'Paid Recovery',
  'claims.policy': 'Policy',
  'claims.pre-claim': 'Pre Claim',
  'claims.pre-claims-assistance': 'Pre-Claims Assistance',
  'claims.retention-triggered': 'Retention Triggered',
  'claims.reserved': 'Reserved',
  'claims.save-coverages': 'Save Coverages',
  'claims.select-endorsement': 'Select an endorsement',
  'claims.separate-limits': 'Separate Limits',
  'claims.sir-triggered': 'SIR Triggered',
  'claims.sublimit': 'Sub-Limit',
  'claims.subretention': 'Sub-Retention',
  'claims.claim-expenses': 'Expenses for the claim {claimId}',
  'claims.upload-carrier-loss-runs': 'Upload Carrier Loss Runs',
  /**
   * Security Review
   */
  'sec-review.list-title': 'Search for Security Review scans',
  'sec-review.asset-removals-title': 'Search for Security Review Asset Removals',
  'sec-review.new-scan': 'Trigger a Scan',
  'sec-review.rescan': 'Trigger a Rescan',
  'sec-review.process-subtitle': 'Process ID ({pid}) triggered at',
  'sec-review.scans-title': 'Search for scans',
  'sec-review.assets-domain-title': 'Search for domains',
  'sec-review.assets-ips-title': 'Search for IP addresses',
  'sec-review.databreaches-title': 'Search for data breaches',
  'sec-review.findings-title': 'Search for findings',
  'sec-review.honeypots-title': 'Search for honeypots',
  'sec-review.asset-removals': 'Asset Removals',
  'sec-review.vulnerability-resolutions': 'Vulnerability Resolutions',
  'sec-review.vulnerability-resolutions-title': 'Search for Vulnerability Resolutions',
  'sec-review.manage-vulnerability-resolutions-request-title': 'Manage Request',
  'sec-review.technologies-title': 'Search for technologies',
  'sec-review.torrents-title': 'Search for torrents',
  /**
   * Vulnerability Resolutions
   */
  'resolution-request.details': 'Vulnerability Details',
  'resolution-request.assets': 'Assets',
  'resolution-request.request-details': 'Request Details',
  'resolution-request.uploaded-screenshots': 'Uploaded Screenshots',
  'resolution-request.manage-request-action': 'Manage Request',
  'resolution-request.header.companyName': 'Company',
  'resolution-request.header.datapoint': 'Datapoint',
  'resolution-request.header.asset-count': 'Asset Count',
  'resolution-request.header.status': 'Status',
  'resolution-request.header.reviewed-by': 'Reviewed By',
  'resolution-request.header.resolution-notes': 'Resolution Notes',
  'resolution-request.header.request-section': 'Request Summary',
  'resolution-request.header.resolution-section': 'Resolution Summary',
  'resolution-request.header.created-on': 'Created on',
  'resolution-request.header.updated-on': 'Updated on',
  'resolution-request.header.request-reason': 'Reason',
  'resolution-request.header.request-notes': 'Notes',
  'resolution-request.details.tab-header': 'Vulnerability Details',
  'resolution-request.details.summary-evidences': 'To see the evidence for each vulnerable asset, navigate to the <a>Assets tab</a> and select an asset from the list.',
  'resolution-request.request-details.attestations-title': 'Submitted Attestations',
  'resolution-request.request-details.no-attestations': 'No attestations submitted',
  'resolution-request.uploaded-screenshots.none': 'No screenshots uploaded',
  /**
   * Risk Assessment
   */
  'cra.list-title': 'Search for Coalition Risk Assessment reports',
  'cra.new-cra': 'Generate a new CRA',
  'cra.process-subtitle': 'Process ID ({pid}) triggered at',
  'cra.scans-title': 'Search for scans',
  'cra.assets-domain-title': 'Search for domains',
  'cra.assets-ips-title': 'Search for IP addresses',
  'cra.databreaches-title': 'Search for data breaches',
  'cra.findings-title': 'Search for findings',
  'cra.honeypots-title': 'Search for honeypots',
  'cra.technologies-title': 'Search for technologies',
  'cra.torrents-title': 'Search for torrents',
  /**
   * Executive Risks Review
   */
  'exec-risks.title': 'List of Executive Risks scans',
  'exec-risks.trigger-scan': 'Trigger New Scan',
  'exec-risks.all-industry-codes': 'All industry codes',
  'exec-risks.average-days-beyond-terms': 'Average Days Beyond Terms',
  'exec-risks.c-suite': 'C-Suite',
  'exec-risks.career-opportunities': 'Career Opportunities',
  'exec-risks.ceo-approval': 'CEO Approval',
  'exec-risks.compensation-and-benefits': 'Compensation and Benefits',
  'exec-risks.cultural-values': 'Cultural Values',
  'exec-risks.diversity-inclusion': 'Diversity & Inclusion',
  'exec-risks.download-era': 'Download ERA',
  'exec-risks.employee-reviews': 'Employee Reviews',
  'exec-risks.employee-sentiment': 'Employee Sentiment',
  'exec-risks.executive-risks': 'Executive Risks',
  'exec-risks.financial-stability': 'Financial Stability',
  'exec-risks.financial-stability-score': 'Financial Stability Risk Score',
  'exec-risks.industry-codes': 'Industry Codes',
  'exec-risks.investors': 'Investors',
  'exec-risks.investors-title': 'Investors',
  'exec-risks.liens': 'Liens',
  'exec-risks.liens-title': 'Financial Stability: Liens',
  'exec-risks.litigation': 'Litigation',
  'exec-risks.litigation-title': 'Litigation',
  'exec-risks.loans': 'PPP Loans',
  'exec-risks.loans-title': 'Financial Stability: PPP Loans',
  'exec-risks.naics-codes': 'NAICS Codes',
  'exec-risks.new-scan': 'New Scan',
  'exec-risks.news': 'News',
  'exec-risks.news-title': 'Flagged News Articles',
  'exec-risks.not-quoted': 'Not Quoted',
  'exec-risks.positive-business-outlook': 'Positive Business Outlook',
  'exec-risks.quote': 'Quote',
  'exec-risks.recommend-to-a-friend': 'Recommend to a Friend',
  'exec-risks.scanned': 'Scanned',
  'exec-risks.senior-management': 'Senior Management',
  'exec-risks.sic-codes': 'SIC Codes',
  'exec-risks.view-history': 'View History',
  'exec-risks.regulatory-issues': 'Regulatory Issues',
  'exec-risks.regulatory-issues-title': 'Regulatory Issues',
  'exec-risks.work-life-balance': 'Work-Life Balance',
  /**
   * Executive Risks Flags
   */
  'exec-risks.redflags-no-redflags': 'No red flags were found',
  'exec-risks.redflags-alexa_ranking': 'Alexa Ranking',
  'exec-risks.redflags-culture_and_values': 'Culture and Values',
  'exec-risks.redflags-everify': 'E-Verify',
  'exec-risks.redflags-financial_stability': 'Financial Stability',
  'exec-risks.redflags-native_lands': 'Native Lands',
  'exec-risks.redflags-violations': 'Regulatory Issues',
  'exec-risks.redflags-watchlist': 'Watchlist',
  /**
   * Executive Risks Not Found Messages
   */
  'exec-risks.notfound-alexa_rank': 'No Alexa rank found',
  'exec-risks.notfound-company_type': 'No company type found',
  'exec-risks.notfound-domains': 'No domains found',
  'exec-risks.notfound-employee_range': 'No employee range found',
  'exec-risks.notfound-incorporated_date': 'No incorporated date found',
  'exec-risks.notfound-primary_industries': 'No primary industries found',
  'exec-risks.notfound-revenue_range': 'No revenue range found',
  /**
   * Admin
   */
  'admin.alert-client-id': 'Your Client ID is set to **"{clientID}"** while the default for your user is **"{defaultClientID}"**.',
  'admin.explorer-admin': 'Explorer Admin Panel',
  'admin.new-platform-user': 'New Platform User',
  'admin.add-existing-client-id': 'Add Existing Client ID',
  'admin.new-control-user': 'New Control User',
  'admin.new-control-user-description': 'Add a completely new user to Control. The new user will be sent an email with the activation link to their new Control account.',
  'admin.add-new-email-account': 'Add new Email Account',
  'admin.add-new-platform-user': 'Add Platform User',
  'admin.create-token': 'Create a new token',
  'admin.manage-tokens': 'Manage Tokens',
  'admin.manage-tokens.create': 'Create',
  'admin.manage-tokens.created': 'Created',
  'admin.manage-tokens.fetch': 'Fetch',
  'admin.manage-tokens.policy': 'Policies',
  'admin.manage-tokens.revoke': 'Revoke',
  'admin.manage-tokens.modal-confirmation-title': 'Are you sure you want to perform this action?',
  'admin.manage-tokens.revoke-confirmation': 'Proceeding with this action will lead to the deactivation of the existing token. As a consequence, you will need to update any applications that rely on this token for authentication.\n\nThis action will impact all users who utilize this token for access.',
  'admin.manage-tokens.create-confirmation': 'Proceeding with this action will lead to the deactivation of the existing token and the generation of a new token. As a consequence, you will need to update any applications that rely on this token for authentication.\n\nThis action will impact all users who utilize this token for access.',
  'admin.manage-tokens.x-token': 'X-Token',
  'admin.control-admin': 'Control Admin Panel',
  'admin.auth0-roles': 'Auth0 Roles',
  'admin.search-users': 'Search Users',
  'admin.users-found': 'Users found',
  'admin.search-companies': 'Search Companies',
  'admin.invite-users': 'Invite Users',
  'admin.invite-user': 'Invite User',
  'admin.impersonate-entity': 'Impersonate Entity',
  'admin.invite-users-description': 'Send a Control Invitation Email to a user on behalf of a Client.',
  'admin.invite-users-instruction': 'The following emails are already registered in Control',
  'admin.invite-users-roles-admin': 'Administrator',
  'admin.invite-users-roles-it-security': 'IT/Security',
  'admin.invite-users-roles-viewer': 'Read-only',
  'admin.modify-client': 'Modify Client',
  'admin.modify-client-description': 'Modify the Role and/or Quotas for a specific Client (it will affect all associated users).',
  'admin.associate-email-to-client': 'Associate Email to Client',
  'admin.associate-email-to-client-description': `When to use this feature:
  
  **1. Troubleshooting** - This will allow you to associate your existing Control email address to a particular company so that you can see Control as a "peer" user to the customer you are working with. If you are working with the Primary Policyholder, you will NOT be able to access the "My Policies" page.

  **2. Change a Control users "Client ID"** - This is typically used when a user signs up for a Free account by mistake and needs to be associated with a Premium (prem) or Insured (cltn) account to see the correct Control data.
  `,
  'admin.associate-email-to-client-warning': 'DO NOT invite new users to an existing Control company using this feature.',
  'admin.revoke-scan': 'Revoke Scan',
  'admin.trigger-scan': 'Trigger Scan',
  'admin.open-report': 'Open Report',
  'admin.view-report': 'View Report',
  'admin.view-graph': 'View Graph',
  'admin.search-companies.trial': 'Trial',
  'admin.search-companies.active-trial': 'Active Trial',
  'admin.search-companies.inactive-trial': 'Inactive Trial',
  'admin.search-companies.expired-trial': 'Expired Trial',
  'admin.search-companies.upgraded-trial': 'Upgraded Trial',
  'admin.search-companies.manage-trial': 'Manage Trial',
  'admin.view-other-domains': 'View additional domains',
  'admin.explorer.add-user': 'Add a new platform user',
  'admin.explorer.add-user-description': 'Add a completely new user to the platform, linking it immediately to the ASM backend and send a password reset email.',
  'admin.explorer.add-user-description-2': 'You can specify a desired Client ID, but if not specified, will hash from the Company Name. An API token will be created automatically.',
  'admin.explorer.add-client': 'Add existing Client ID to ASM',
  'admin.explorer.add-client-description': 'Add an existing platform Client ID to the ASM backend, allowing access to ASM functionalities and linking it to their main entity (the "My Company").',
  'admin.explorer.add-email': 'Add new email account to existing Client ID',
  'admin.explorer.add-email-description': 'Add a new email account to an existing platform Client ID and send a password reset email.',
  'admin.impersonate-user': 'Impersonate',
  /**
   * Supplementals
   */
  'supplementals.account': 'Account',
  'supplementals.account-uuid': 'Account UUID',
  'supplementals.accounts-description': 'NOTE: This is a TEMPORARY view. In the near future we will implement an Accounts table with search capabilities.',
  'supplementals.accounts-title': 'Search for accounts',
  'supplementals.forms': 'Forms',
  'supplementals.goto-account': 'Go to Account',
  'supplementals.manual-rsa-submission': 'Manual RSA Submission',
  'supplementals.manual-rsa-submission-subtitle': 'Do you want to create a new digital supplemental for this account? Please select the quote and the form type.',
  'supplementals.logs': 'Logs',
  'supplementals.recipient': 'Recipient',
  'supplementals.recipients': 'Recipients',
  'supplementals.recipients-title': 'Search for recipients',
  'supplementals.title': 'Supplementals',
  'supplementals.error-fetching': 'Unexpected error while trying to fetch the Account data.',
  'supplementals.no-quotes': 'This account does not have any associate quotes.',

  /**
   * Event Log - Graph
   */
  'pie-chart.tooltip.title': 'Vulnerabilities',
  'pie-chart.category.encryption': 'Encryption',
  'pie-chart.category.web': 'Web',
  'pie-chart.category.ssl': 'SSL/TLS',
  'pie-chart.category.token': 'Token',
  'pie-chart.category.dns': 'DNS',
  'pie-chart.category.storage': 'Storage',
  'pie-chart.category.exposure': 'Exposure',
  'pie-chart.category.cve': 'CVE',
  'pie-chart.category.eol': 'EOL',
  'pie-chart.category.remote': 'Remote',
  'pie-chart.category.other': 'Others',
  'pie-chart.severity.total': 'Total',
  'pie-chart.severity.critical': 'Critical',
  'pie-chart.severity.high': 'High',
  'pie-chart.severity.low': 'Low',
  'pie-chart.severity.medium': 'Medium',
  'pie-chart.category.subtitle': "By Category",
  'pie-chart.category.subtitle.tooltip': "Breakdown of company's vulnerabilities by category.",
  'pie-chart.link-to-vulnerability': "See all vulnerabilities",

  /**
   * Access Requests
   */
  'admin.manage-access-requests': 'Manage Access Requests',
  'admin.manage-access-requests-subtitle': 'Manage requests from customers (such as MSPs) to add companies in their Portfolio Management view.',
  'admin.manage-access-requests-history-link': 'View Request History',

  /**
   * User Profile
   */
  'users.user-details-title': 'User Summary',
  'users.notifications': 'Notifications',
  'users.configure-user-profile': 'Configure Filters',
  'users.notifications-title': 'Search for notifications sent to {email}',  
  'users.no-email': 'Missing email in url',
  'users.user-fetch-error': 'Failed to load user profile',  
  'users.filters-form-title': 'Communication, Notifications, & Alerts',
  'users.filters-mute-all': 'Mute All',
  'users.filters-mute-all-description': 'Mute all notifications',
  'users.filters-mandatory': 'Mandatory',
  'users.filters-mandatory-description': 'This toggle cannot be turned off and controls functional emails, such as password resets, self-service actions, etc.',
  'users.filters-marketing-alerts': 'Marketing Emails',
  'users.filters-security-alerts': 'Security Alerts',
  'users.filters-broker-alerts': 'Broker Alerts',
  'users.filters-control-invite': 'Control Invite Reminders',
  'users.filters-marketo-emails-description': 'This toggle enables the marketing emails that we send to our clients.',
  'users.filters-security-alerts-description': 'This toggle enables the security alerts that we send to our clients.',
  'users.filters-broker-alerts-description': 'This toggle enables the broker alerts that we send to our clients.',
  'users.filters-control-invite-description': 'This toggle enables the periodic invite reminders for Control that we send to our clients.',
  'users.filters-toggle-success-control-invites': 'Successfully updated Control invites filter',
  'users.filters-toggle-success-marketo': 'Successfully updated Marketo filter',
  'users.filters-toggle-success-security': 'Successfully updated Security Alerts filter',
  'users.filters-toggle-success-broker': 'Successfully updated Broker Alerts filter',
  'users.filters-toggle-error': 'An error occurred while updating the filter',
  'users.filters-missing-marketo-lead-id': 'Missing Marketo Lead ID. User cannot receive marketing emails.',

  /**
   * Zero Day Alert
   */
  'zero-day-alert.search-for-alerts': 'Search by Alert name',
  'zero-day-alert.view-details': 'View Alert Details',
  'zero-day-alert.button-create': 'Create alert',
  'zero-day-alert.button-update': 'Update alert',
  'zero-day-alert.cancel': 'Cancel',
  'zero-day-alert.send': 'Send',
  'zero-day-alert.send-email-title': 'Select the relevant customer segment for notification',
  'zero-day-alert.segment-not-notified': 'Customers we haven\'t notified',
  'zero-day-alert.segment-no-action': 'Customers notified but haven\'t taken any action',
  'zero-day-alert.segment-resolved': 'Customers who have resolved the alert',
  'zero-day-alert.segment-not-applicable': 'Customers who marked ZDA as not applicable',
  'zero-day-alert.segment-accept': 'Customers who have accepted the risk',
  'zero-day-alert.dont-send': 'Don\'t send',
  'zero-day-alert.send-test-email': 'Send Test Email',
  'zero-day-alert.previous': 'Previous',
  'zero-day-alert.title-create-alert': 'Create a new Zero Day Alert',
  'zero-day-alert.title-update-alert': 'Update Zero Day Alert',
  'zero-day-alert.title-alert-info': 'Alert Information',
  'zero-day-alert.title-alert-audience': 'Alert audience',
  'zero-day-alert.save-success-msg': 'Alert saved successfully',
  'zero-day-alert.download-customer-list': 'Download sample list from <a>here</a> and upload revised list in the same format',
  'zero-day-alert.upload-customer-list': 'Upload the customer list',
  'zero-day-alert.alert-history': 'Alert History',
  'zero-day-alert.impacted-companies': 'Impacted Companies',
  'zero-day-alert.alert-information': 'Alert Information',
  'zero-day-alert.total-exposure': 'Total Exposure',
  'zero-day-alert.companies-stats': '{count} companies ({active} Active, {resolved} Resolved, {accepted} Accepted Risk, {notApplicable} Non Applicable)',
  'zero-day-alert.created-date': 'Created Date',
  'zero-day-alert.updated-date': 'Updated Date',
  'zero-day-alert.edit': 'Edit',
  'zero-day-alert.send-email': 'Send Email',
  'zero-day-alert.add-customers': 'Add Customers',
  'zero-day-alert.close-alert': 'Close Alert',
  'zero-day-alert.close-alert-confirmation-text': 'Are you sure you would like to close this alert?',
  'zero-day-alert.close-alert-confirmation-text2': 'Once closed, the zero-day notifications associated with this alert will be removed from the Control UI.',
  'zero-day-alert.list-of-companies': 'List of companies added to this alert',
  'zero-day-alert.accept-risk': 'Acknowledge & Accept Risk',
  'zero-day-alert.resolve': 'Resolve',
  'zero-day-alert.not-applicable': 'Not Applicable',
  'zero-day-alert.email-history': 'Email history',
  'zero-day-alert.tooltip.alert-closed': 'This alert was previously closed',
  'zero-day-alert.validate-users': 'Validate users',
  'zero-day-alert.add-or-update-customers': 'Add/Update the customers on this alert',
  'zero-day-alert.latest-email-sent': 'Latest Email Sent Date',
};
