/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlMultiSelectFieldOption } from './ControlMultiSelectFieldOption';
import {
    ControlMultiSelectFieldOptionFromJSON,
    ControlMultiSelectFieldOptionFromJSONTyped,
    ControlMultiSelectFieldOptionToJSON,
} from './ControlMultiSelectFieldOption';

/**
 * 
 * @export
 * @interface ControlMultiSelectFieldDefinition
 */
export interface ControlMultiSelectFieldDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlMultiSelectFieldDefinition
     */
    field_type?: ControlMultiSelectFieldDefinitionFieldTypeEnum;
    /**
     * 
     * @type {Array<ControlMultiSelectFieldOption>}
     * @memberof ControlMultiSelectFieldDefinition
     */
    options: Array<ControlMultiSelectFieldOption>;
    /**
     * 
     * @type {number}
     * @memberof ControlMultiSelectFieldDefinition
     */
    min_selected?: number;
}


/**
 * @export
 */
export const ControlMultiSelectFieldDefinitionFieldTypeEnum = {
    Multiselect: 'multiselect'
} as const;
export type ControlMultiSelectFieldDefinitionFieldTypeEnum = typeof ControlMultiSelectFieldDefinitionFieldTypeEnum[keyof typeof ControlMultiSelectFieldDefinitionFieldTypeEnum];


/**
 * Check if a given object implements the ControlMultiSelectFieldDefinition interface.
 */
export function instanceOfControlMultiSelectFieldDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "options" in value;

    return isInstance;
}

export function ControlMultiSelectFieldDefinitionFromJSON(json: any): ControlMultiSelectFieldDefinition {
    return ControlMultiSelectFieldDefinitionFromJSONTyped(json, false);
}

export function ControlMultiSelectFieldDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMultiSelectFieldDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_type': !exists(json, 'field_type') ? undefined : json['field_type'],
        'options': ((json['options'] as Array<any>).map(ControlMultiSelectFieldOptionFromJSON)),
        'min_selected': !exists(json, 'min_selected') ? undefined : json['min_selected'],
    };
}

export function ControlMultiSelectFieldDefinitionToJSON(value?: ControlMultiSelectFieldDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_type': value.field_type,
        'options': ((value.options as Array<any>).map(ControlMultiSelectFieldOptionToJSON)),
        'min_selected': value.min_selected,
    };
}

