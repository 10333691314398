/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityReportedAssetOrderBy } from './MonitEntityReportedAssetOrderBy';
import {
    MonitEntityReportedAssetOrderByFromJSON,
    MonitEntityReportedAssetOrderByFromJSONTyped,
    MonitEntityReportedAssetOrderByToJSON,
} from './MonitEntityReportedAssetOrderBy';
import type { Search } from './Search';
import {
    SearchFromJSON,
    SearchFromJSONTyped,
    SearchToJSON,
} from './Search';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface MonitGetEntityReportedAssetQueries
 */
export interface MonitGetEntityReportedAssetQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    page_size?: number;
    /**
     * 
     * @type {Status}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    asset_type?: string;
    /**
     * 
     * @type {Search}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    search?: Search;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    reviewed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    from?: string;
    /**
     * 
     * @type {MonitEntityReportedAssetOrderBy}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    order_by?: MonitEntityReportedAssetOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityReportedAssetQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityReportedAssetQueries interface.
 */
export function instanceOfMonitGetEntityReportedAssetQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityReportedAssetQueriesFromJSON(json: any): MonitGetEntityReportedAssetQueries {
    return MonitGetEntityReportedAssetQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityReportedAssetQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityReportedAssetQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'asset_type': !exists(json, 'asset_type') ? undefined : json['asset_type'],
        'search': !exists(json, 'search') ? undefined : SearchFromJSON(json['search']),
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityReportedAssetOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityReportedAssetQueriesToJSON(value?: MonitGetEntityReportedAssetQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'status': StatusToJSON(value.status),
        'reason': value.reason,
        'asset_type': value.asset_type,
        'search': SearchToJSON(value.search),
        'reviewed_by': value.reviewed_by,
        'to': value.to,
        'from': value.from,
        'order_by': MonitEntityReportedAssetOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

