/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFLocale = {
    EnUs: 'en-US',
    EnGb: 'en-GB',
    EnCa: 'en-CA',
    EnAu: 'en-AU',
    DeDe: 'de-DE',
    FrCa: 'fr-CA'
} as const;
export type CRSFLocale = typeof CRSFLocale[keyof typeof CRSFLocale];


export function CRSFLocaleFromJSON(json: any): CRSFLocale {
    return CRSFLocaleFromJSONTyped(json, false);
}

export function CRSFLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFLocale {
    return json as CRSFLocale;
}

export function CRSFLocaleToJSON(value?: CRSFLocale | null): any {
    return value as any;
}

