import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * TechEOIcon
 */
export const TechEOIcon = ({ ...props }: TechEOIconProps) => {
  return (
    <Icon label="tech_eo" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7,12.4h-13c-1.1,0-1.9-0.9-1.9-1.9V9.7c0-1.1,0.9-1.9,1.9-1.9h13c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4v0.8c0,0.5-0.2,1-0.6,1.4C22.7,12.2,22.2,12.4,21.7,12.4z M10.1,9.3c-0.3,0.1-0.5,0.4-0.5,0.8c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.7-0.2,0.8-0.5c0.1-0.3,0.1-0.7-0.2-0.9C10.8,9.3,10.5,9.2,10.1,9.3z M12.6,10.1c0-0.3,0.2-0.7,0.5-0.8c0.3-0.1,0.7-0.1,0.9,0.2c0.2,0.2,0.3,0.6,0.2,0.9c-0.1,0.3-0.4,0.5-0.8,0.5C12.9,11,12.6,10.6,12.6,10.1z M20.8,15.5c-1-0.3-2.1,0.2-2.6,1.1l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.2H8.7c-1.1,0-1.9-0.9-1.9-1.9v-0.8c0-1.1,0.9-1.9,1.9-1.9h13c1.1,0,1.9,0.9,1.9,1.9v0.8c0,0.5-0.2,1-0.6,1.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.2l-0.2-0.4C21.9,16.2,21.4,15.7,20.8,15.5z M13.7,16.3c0.3-0.1,0.5-0.4,0.5-0.8c0-0.5-0.4-0.9-0.9-0.9c-0.3,0-0.7,0.2-0.8,0.5c-0.1,0.3-0.1,0.7,0.2,0.9C13.1,16.3,13.4,16.4,13.7,16.3z M11.3,15.5c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C10.9,14.6,11.3,15,11.3,15.5z M8.7,23.1h5.9c0.1,0,0.3-0.1,0.3-0.2l2-3.8c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.2H8.7c-1.1,0-1.9,0.9-1.9,1.9v0.8C6.8,22.3,7.7,23.1,8.7,23.1z M9.6,20.8c0-0.3,0.2-0.7,0.5-0.8c0.3-0.1,0.7-0.1,0.9,0.2c0.2,0.2,0.3,0.6,0.2,0.9c-0.1,0.3-0.4,0.5-0.8,0.5c-0.2,0-0.4-0.1-0.6-0.2C9.7,21.3,9.6,21,9.6,20.8L9.6,20.8z M13.1,20c-0.3,0.1-0.5,0.4-0.5,0.8c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.7-0.2,0.8-0.5c0.1-0.3,0.1-0.7-0.2-0.9C13.8,20,13.4,19.9,13.1,20z M15.4,25.6c-0.2-0.3-0.2-0.7,0-1.1l3.9-7.4c0.2-0.4,0.6-0.6,1-0.6s0.8,0.2,1,0.6l3.9,7.4c0.2,0.3,0.2,0.7,0,1.1c-0.2,0.3-0.6,0.5-0.9,0.5h-7.7C16,26.2,15.6,26,15.4,25.6z M20.8,19.7c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6V22c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V19.7z M21,24.1c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8S21,23.6,21,24.1z"
        />
      </g>
    </Icon>
  );
};

/**
 * TechEOIcon Props
 */
export interface TechEOIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
