import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { endpoints, useQuery } from '@explorer/api';
import { Grid } from '@explorer/core';
import { FormDialog, FormDialogProps, FormFieldControl } from '@explorer/forms';
import { CommonFormProps } from '@explorer/forms/types';
import { isTruthy } from '@explorer/helpers';
import { useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { useIntl } from 'react-intl';

const FORM_ID = 'FormFileClaim';
const INITIAL_FORM_VALUES = {
  policy_id: '',
  coalition_handler: '',
  claim_handler: '',
  claim_type: '',
  event_types: [],
  claim_notes: '',
  status: 'open',
  date_reported: dayjs().format(),
  date_of_loss: dayjs().format(),
};
const CLAIM_TYPES: Options = [
  {
    label: { id: 'claims.pre-claim' },
    value: 'pre_claim',
  },
  {
    label: { id: 'global.claim' },
    value: 'claim',
  },
];
const CLAIM_STATUSES: Options = [
  {
    label: { id: 'global.open' },
    value: 'open',
  },
  {
    label: { id: 'global.closed' },
    value: 'closed',
  },
];

export const FileClaimV2 = ({
  open,
  handleClose,
  options,
  refresh,
  uuid,
}: FileClaimV2Props) => {
  const { snackError, snackSuccess } = useQuerySnackbar();
  const intl = useIntl();

  const fileClaimQuery = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1claims['/claims'](),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const queryPolicies = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1claims['/policies'](),
    method: 'GET',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
    digest: ({ rawData }) => {
      const arr: any[] = [];
      rawData?.results.forEach((policy: any) => {
        if (policy?.coalition_policy_number) {
          arr.push({
            label: `${policy?.coalition_policy_number} | ${
              policy?.lifecycle_state || ''
            }`,
            value: policy?.uuid,
          });
        }
      });
      return arr;
    },
  });

  useEffect(() => {
    if (uuid !== '') {
      queryPolicies
        .submit({
          params: {
            account_id: uuid,
          },
        })
        .then(({ error }) => {
          if (error) {
            snackError('Claim Policies: Error', error);
          }
        });
    }
  }, [uuid]);

  const formik = useFormik<FormValues>({
    validationSchema: validationSchema(intl),
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      fileClaimQuery
        .submit({
          payload: values,
        })
        .then(({ error }) => {
          if (error) {
            snackError('File Claim Error', error);
            setSubmitting(false);
          } else {
            resetForm();
            snackSuccess('File Claim Success');
            handleClose();
            refresh();
          }
        });
    },
  });

  const eventTypes = useMemo(() => {
    const arr: any[] = options.events
      ?.flatMap((category: any) => category.events)
      .filter(isTruthy)
      .map((event: any) => ({ label: event.name, value: event.id }));

    return arr || [];
  }, [options]);

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId: FORM_ID,
        formik,
        options: {
          policies: queryPolicies?.data || [],
          claimTypes: CLAIM_TYPES,
          claimStatus: CLAIM_STATUSES,
          claimHandlers: options?.carriers,
          coalitionHandlers: options?.owners,
          eventTypes,
        },
      }),
    [formik, options, queryPolicies.data, eventTypes],
  );

  return (
    <FormDialog
      title={{ id: 'claims.file-a-new-claim' }}
      formId={FORM_ID}
      formik={formik}
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      submitButton
      disableSubmit={!formik.isValid}
      resetButton
      loading={formik.isSubmitting}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12} lg={6}>
          <FormFieldControl {...formSchema.event_types} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFieldControl
            {...formSchema.policy_id}
            disableCondition={() => !queryPolicies.hasSucceeded}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFieldControl {...formSchema.claim_handler} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFieldControl {...formSchema.coalition_handler} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFieldControl {...formSchema.claim_type} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormFieldControl {...formSchema.date_of_loss} />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.claim_notes} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface FileClaimV2Props
  extends Pick<FormDialogProps, 'open' | 'handleClose'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  options: any;
  refresh: () => void;
  uuid: string;
}
