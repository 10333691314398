/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityFindingsTopResult
 */
export interface ControlEntityFindingsTopResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsTopResult
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsTopResult
     */
    risk?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityFindingsTopResult
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityFindingsTopResult
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsTopResult
     */
    level?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityFindingsTopResult
     */
    count?: number;
}

/**
 * Check if a given object implements the ControlEntityFindingsTopResult interface.
 */
export function instanceOfControlEntityFindingsTopResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityFindingsTopResultFromJSON(json: any): ControlEntityFindingsTopResult {
    return ControlEntityFindingsTopResultFromJSONTyped(json, false);
}

export function ControlEntityFindingsTopResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityFindingsTopResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'risk': !exists(json, 'risk') ? undefined : json['risk'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ControlEntityFindingsTopResultToJSON(value?: ControlEntityFindingsTopResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'risk': value.risk,
        'recommendation': value.recommendation,
        'references': value.references,
        'level': value.level,
        'count': value.count,
    };
}

