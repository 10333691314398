import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function dayJSWithTimezone(date: number | string | Date) {
  if (process.env.TZ) {
    return dayjs.tz(date, process.env.TZ);
  }

  return dayjs(date);
}

function dayJSUnixWithTimezone(date: number) {
  if (process.env.TZ) {
    return dayjs.unix(date).utcOffset(process.env.TZ, false);
  }

  return dayjs.unix(date);
}

/**
 * Formats a unix timestamp with the given format.
 *
 * __> Example:__
 * ```ts
 * formatDate(1601463204, 'YYYY-MM-DD');
 * // "2020-09-30"
 * ```
 */

export const formatDate: FormatDate = (date, format = 'YYYY-MM-DD', msUnix) => {
  if (msUnix) return dayJSWithTimezone(date).format(format);

  // BE will sometimes send a 13 or 10 digit timestamp
  // dayjs only uses the `.unix()` method for 10 digit ones
  if (typeof date === 'number' && date.toString().length === 10)
    return dayJSUnixWithTimezone(date).format(format);

  return dayJSWithTimezone(date).format(format);
};

export const daysAgo = (date: number | string | Date) => {
  const dateFormated = formatDate(date, 'YYYY-MM-DD HH:MM');
  return dayjs(dateFormated).fromNow();
};

type FormatDate = (
  date: number | string | Date,
  format?: string,
  msUnix?: boolean,
) => string;
