import { ApiClientAuth, ApiClientHeaders } from '../../types';

export const API_MAPPING: ApiMapping = {
  monit: {
    url: process.env.API_MONIT || 'MISSING_API_MONIT',
    prefix: '',
    auth: 'bearer',
  },
  claims: {
    url: process.env.API_CLAIMS || 'MISSING_API_CLAIMS',
    prefix: '',
    auth: 'bearer',
  },
  cyberrisk: {
    url: process.env.API_CYBERRISK || 'MISSING_API_CYBERRISK',
    prefix: '',
    auth: 'x-token',
  },
  execrisk: {
    url: process.env.API_EXECRISKS || 'MISSING_API_EXECRISKS',
    prefix: '',
    auth: 'bearer',
  },
  breach: {
    url: process.env.API_BREACH || 'MISSING_API_BREACH',
    prefix: '',
    auth: 'bearer',
  },
  v1notifications: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/notifications/web',
    auth: 'bearer',
  },
  v1monit: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/monit/web',
    auth: 'bearer',
  },
  v1cra: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/cra/web',
    auth: 'bearer',
  },
  v1claims: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/claims/web',
    auth: 'bearer',
  },
  v1execrisk: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/execrisks/web',
    auth: 'bearer',
  },
  v1secreview: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '/v1/secreview/web',
    auth: 'bearer',
  },
  supplemental: {
    url: process.env.API_PORTAL || 'MISSING_API_PORTAL',
    prefix: '',
    auth: 'bearer',
  },
};

type ApiMapping = Record<
  MonorepoAPIs,
  {
    url: string;
    prefix: string;
    auth: ApiClientAuth;
  }
>;
