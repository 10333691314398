/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityProcessOrderBy } from './MonitEntityProcessOrderBy';
import {
    MonitEntityProcessOrderByFromJSON,
    MonitEntityProcessOrderByFromJSONTyped,
    MonitEntityProcessOrderByToJSON,
} from './MonitEntityProcessOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityProcessQueries
 */
export interface MonitGetEntityProcessQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityProcessQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityProcessQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityProcessQueries
     */
    process_id?: string;
    /**
     * 
     * @type {MonitEntityProcessOrderBy}
     * @memberof MonitGetEntityProcessQueries
     */
    order_by?: MonitEntityProcessOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityProcessQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityProcessQueries interface.
 */
export function instanceOfMonitGetEntityProcessQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityProcessQueriesFromJSON(json: any): MonitGetEntityProcessQueries {
    return MonitGetEntityProcessQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityProcessQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityProcessQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityProcessOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityProcessQueriesToJSON(value?: MonitGetEntityProcessQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'process_id': value.process_id,
        'order_by': MonitEntityProcessOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

