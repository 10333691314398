import React from 'react';
import clsx from 'clsx';
import MuiFormControl, {
  FormControlProps as MuiFormControlProps,
} from '@mui/material/FormControl';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const FormControl = withCoreProps<FormControlProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<FormControlProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiFormControl {...props} className={clsxClass}>
        {children}
      </MuiFormControl>
    );
  },
);

export interface FormControlProps extends MuiFormControlProps, CoreProps {}
