/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityStatsCyberBenchmark } from './MonitEntityStatsCyberBenchmark';
import {
    MonitEntityStatsCyberBenchmarkFromJSON,
    MonitEntityStatsCyberBenchmarkFromJSONTyped,
    MonitEntityStatsCyberBenchmarkToJSON,
} from './MonitEntityStatsCyberBenchmark';
import type { MonitEntityStatsRiskScoreV2 } from './MonitEntityStatsRiskScoreV2';
import {
    MonitEntityStatsRiskScoreV2FromJSON,
    MonitEntityStatsRiskScoreV2FromJSONTyped,
    MonitEntityStatsRiskScoreV2ToJSON,
} from './MonitEntityStatsRiskScoreV2';
import type { MonitEntityStatsSurface } from './MonitEntityStatsSurface';
import {
    MonitEntityStatsSurfaceFromJSON,
    MonitEntityStatsSurfaceFromJSONTyped,
    MonitEntityStatsSurfaceToJSON,
} from './MonitEntityStatsSurface';
import type { MonitEntityStatsVulnerabilities } from './MonitEntityStatsVulnerabilities';
import {
    MonitEntityStatsVulnerabilitiesFromJSON,
    MonitEntityStatsVulnerabilitiesFromJSONTyped,
    MonitEntityStatsVulnerabilitiesToJSON,
} from './MonitEntityStatsVulnerabilities';

/**
 * 
 * @export
 * @interface MonitEntityStatsResponse
 */
export interface MonitEntityStatsResponse {
    /**
     * 
     * @type {MonitEntityStatsSurface}
     * @memberof MonitEntityStatsResponse
     */
    surface?: MonitEntityStatsSurface;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilities}
     * @memberof MonitEntityStatsResponse
     */
    vulnerabilities?: MonitEntityStatsVulnerabilities;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    stored?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    risk_score?: number;
    /**
     * 
     * @type {MonitEntityStatsRiskScoreV2}
     * @memberof MonitEntityStatsResponse
     */
    risk_score_v2?: MonitEntityStatsRiskScoreV2;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityStatsResponse
     */
    report_url?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MonitEntityStatsResponse
     */
    localized_reports?: { [key: string]: string; };
    /**
     * 
     * @type {MonitEntityStatsCyberBenchmark}
     * @memberof MonitEntityStatsResponse
     */
    cyber_benchmark?: MonitEntityStatsCyberBenchmark;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    assets_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    vulnerabilities_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    dataleaks_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    honeypots_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    torrents_count?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityStatsResponse
     */
    reason?: string;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsResponse
     */
    previous_risk_score_v2?: object;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    previous_assets_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsResponse
     */
    previous_vulnerabilities_count?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityStatsResponse
     */
    created?: string;
}

/**
 * Check if a given object implements the MonitEntityStatsResponse interface.
 */
export function instanceOfMonitEntityStatsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityStatsResponseFromJSON(json: any): MonitEntityStatsResponse {
    return MonitEntityStatsResponseFromJSONTyped(json, false);
}

export function MonitEntityStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surface': !exists(json, 'surface') ? undefined : MonitEntityStatsSurfaceFromJSON(json['surface']),
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : MonitEntityStatsVulnerabilitiesFromJSON(json['vulnerabilities']),
        'stored': !exists(json, 'stored') ? undefined : json['stored'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : MonitEntityStatsRiskScoreV2FromJSON(json['risk_score_v2']),
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'localized_reports': !exists(json, 'localized_reports') ? undefined : json['localized_reports'],
        'cyber_benchmark': !exists(json, 'cyber_benchmark') ? undefined : MonitEntityStatsCyberBenchmarkFromJSON(json['cyber_benchmark']),
        'assets_count': !exists(json, 'assets_count') ? undefined : json['assets_count'],
        'vulnerabilities_count': !exists(json, 'vulnerabilities_count') ? undefined : json['vulnerabilities_count'],
        'dataleaks_count': !exists(json, 'dataleaks_count') ? undefined : json['dataleaks_count'],
        'honeypots_count': !exists(json, 'honeypots_count') ? undefined : json['honeypots_count'],
        'torrents_count': !exists(json, 'torrents_count') ? undefined : json['torrents_count'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'previous_risk_score_v2': !exists(json, 'previous_risk_score_v2') ? undefined : json['previous_risk_score_v2'],
        'previous_assets_count': !exists(json, 'previous_assets_count') ? undefined : json['previous_assets_count'],
        'previous_vulnerabilities_count': !exists(json, 'previous_vulnerabilities_count') ? undefined : json['previous_vulnerabilities_count'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function MonitEntityStatsResponseToJSON(value?: MonitEntityStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surface': MonitEntityStatsSurfaceToJSON(value.surface),
        'vulnerabilities': MonitEntityStatsVulnerabilitiesToJSON(value.vulnerabilities),
        'stored': value.stored,
        'risk_score': value.risk_score,
        'risk_score_v2': MonitEntityStatsRiskScoreV2ToJSON(value.risk_score_v2),
        'report_url': value.report_url,
        'localized_reports': value.localized_reports,
        'cyber_benchmark': MonitEntityStatsCyberBenchmarkToJSON(value.cyber_benchmark),
        'assets_count': value.assets_count,
        'vulnerabilities_count': value.vulnerabilities_count,
        'dataleaks_count': value.dataleaks_count,
        'honeypots_count': value.honeypots_count,
        'torrents_count': value.torrents_count,
        'reason': value.reason,
        'previous_risk_score_v2': value.previous_risk_score_v2,
        'previous_assets_count': value.previous_assets_count,
        'previous_vulnerabilities_count': value.previous_vulnerabilities_count,
        'created': value.created,
    };
}

