import React from 'react';
import clsx from 'clsx';
import MuiTabPanel, {
  TabPanelProps as MuiTabPanelProps,
} from '@mui/lab/TabPanel';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const TabPanel = withCoreProps<TabPanelProps>(
  ({
    children,
    className,
    ...otherProps
  }: React.PropsWithChildren<TabPanelProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiTabPanel {...otherProps} className={clsxClass}>
        {children}
      </MuiTabPanel>
    );
  },
);

export interface TabPanelProps extends MuiTabPanelProps, CoreProps {}
