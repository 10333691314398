import React, { useMemo, useReducer, ReactNode } from 'react';
import { UserContext, UserContextType, initialState } from './context';
import { reducer } from './reducer';

/**
 * useUserProvider
 */
export const useUserProvider = (): UserContextType => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, userFlags, segmentIdentify } = state;

  const provider = useMemo<UserContextType>(
    () => ({
      user,
      userFlags,
      setUser: (data: UserContextType['user']) => {
        dispatch({
          type: 'SET_USER',
          payload: data,
        });
      },
      resetUser: () => {
        dispatch({
          type: 'RESET_USER',
        });
      },
      segmentIdentify,
      segmentIdentifyWasTriggered: () => {
        dispatch({
          type: 'TRIGGER_SEGMENT',
        });
      },
    }),
    [state],
  );

  return provider;
};

/**
 * UserProvider
 */
export const UserProvider = ({ children }: UserProviderProps) => {
  const userProvider = useUserProvider();

  return (
    <UserContext.Provider value={userProvider}>
      {typeof children === 'function' ? children(userProvider) : children}
    </UserContext.Provider>
  );
};

interface UserProviderProps {
  children: ReactNode | ((context: UserContextType) => ReactNode);
}
