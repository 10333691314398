/**
 * Get a simplified version of the supported asset sources.
 *
 * __> Example:__
 * ```ts
 * formatAssetSource('dns-a -> binaryedge.io');
 * // 'DNS A'
 * ```
 */

export const formatAssetSource: FormatAssetSource = (source) => {
  const splitSource = (source && source.split(' -> ')) || [];

  if (splitSource[0] === 'base-asset') return 'BASE ASSET';
  if (splitSource[0] === 'dns-a') return 'DNS A';
  if (splitSource[0] === 'dns-aaaa') return 'DNS AAAA';
  if (splitSource[0] === 'dns-spf') return 'DNS SPF';
  if (splitSource[0] === 'ipwhois') return 'IP WHOIS';
  if (splitSource[0] === 'root-domain') return 'ROOT DOMAIN';
  if (splitSource[0] === 'ssl-certificate') return 'SSL CERT';
  if (splitSource[0] === 'subdomain-enumeration') return 'SUBDOMAIN';

  return '';
};

type FormatAssetSource = (source?: string) => Sources;

type Sources =
  | 'BASE ASSET'
  | 'DNS A'
  | 'DNS AAAA'
  | 'DNS SPF'
  | 'IP WHOIS'
  | 'ROOT DOMAIN'
  | 'SSL CERT'
  | 'SUBDOMAIN'
  | '';
