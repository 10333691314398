/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler
     */
    EIN: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler
     */
    USAddress?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler
     */
    BusinessName?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler
     */
    BusinessNameControlTxt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "EIN" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler {
    return ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'EIN': json['EIN'],
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'BusinessNameControlTxt': !exists(json, 'BusinessNameControlTxt') ? undefined : json['BusinessNameControlTxt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EIN': value.EIN,
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'BusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'BusinessNameControlTxt': value.BusinessNameControlTxt,
    };
}

