/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactType } from './ContactType';
import {
    ContactTypeFromJSON,
    ContactTypeFromJSONTyped,
    ContactTypeToJSON,
} from './ContactType';
import type { ExternalIds } from './ExternalIds';
import {
    ExternalIdsFromJSON,
    ExternalIdsFromJSONTyped,
    ExternalIdsToJSON,
} from './ExternalIds';

/**
 * 
 * @export
 * @interface MonitEntityContactSearchQueries
 */
export interface MonitEntityContactSearchQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityContactSearchQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityContactSearchQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityContactSearchQueries
     */
    search?: string;
    /**
     * 
     * @type {ContactType}
     * @memberof MonitEntityContactSearchQueries
     */
    contact_type?: ContactType;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityContactSearchQueries
     */
    email?: string;
    /**
     * 
     * @type {ExternalIds}
     * @memberof MonitEntityContactSearchQueries
     */
    external_ids?: ExternalIds;
}

/**
 * Check if a given object implements the MonitEntityContactSearchQueries interface.
 */
export function instanceOfMonitEntityContactSearchQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityContactSearchQueriesFromJSON(json: any): MonitEntityContactSearchQueries {
    return MonitEntityContactSearchQueriesFromJSONTyped(json, false);
}

export function MonitEntityContactSearchQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityContactSearchQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'contact_type': !exists(json, 'contact_type') ? undefined : ContactTypeFromJSON(json['contact_type']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'external_ids': !exists(json, 'external_ids') ? undefined : ExternalIdsFromJSON(json['external_ids']),
    };
}

export function MonitEntityContactSearchQueriesToJSON(value?: MonitEntityContactSearchQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'search': value.search,
        'contact_type': ContactTypeToJSON(value.contact_type),
        'email': value.email,
        'external_ids': ExternalIdsToJSON(value.external_ids),
    };
}

