/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityAssetIPAddressSource = {
    DnsA: 'dns-a',
    DnsAaaa: 'dns-aaaa',
    DnsSpf: 'dns-spf',
    SslCertificate: 'ssl-certificate',
    Ipwhois: 'ipwhois',
    BaseAsset: 'base-asset'
} as const;
export type ControlEntityAssetIPAddressSource = typeof ControlEntityAssetIPAddressSource[keyof typeof ControlEntityAssetIPAddressSource];


export function ControlEntityAssetIPAddressSourceFromJSON(json: any): ControlEntityAssetIPAddressSource {
    return ControlEntityAssetIPAddressSourceFromJSONTyped(json, false);
}

export function ControlEntityAssetIPAddressSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetIPAddressSource {
    return json as ControlEntityAssetIPAddressSource;
}

export function ControlEntityAssetIPAddressSourceToJSON(value?: ControlEntityAssetIPAddressSource | null): any {
    return value as any;
}

