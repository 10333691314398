/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFHealthz
 */
export interface CRSFHealthz {
    /**
     * ISO 8601 date and time in UTC of the Git commit from which Krang's Docker image was built. Note that this was not the time at which this Docker image was deployed to this environment.
     * @type {string}
     * @memberof CRSFHealthz
     */
    build_datetime?: string;
    /**
     * SHA-1 of the Git commit from which Krang's Docker image was built.
     * @type {string}
     * @memberof CRSFHealthz
     */
    build_version?: string;
    /**
     * Service disposition.
     * @type {boolean}
     * @memberof CRSFHealthz
     */
    ok: boolean;
}

/**
 * Check if a given object implements the CRSFHealthz interface.
 */
export function instanceOfCRSFHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function CRSFHealthzFromJSON(json: any): CRSFHealthz {
    return CRSFHealthzFromJSONTyped(json, false);
}

export function CRSFHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'build_datetime': !exists(json, 'build_datetime') ? undefined : json['build_datetime'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
        'ok': json['ok'],
    };
}

export function CRSFHealthzToJSON(value?: CRSFHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'build_datetime': value.build_datetime,
        'build_version': value.build_version,
        'ok': value.ok,
    };
}

