/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationsHealthz
 */
export interface NotificationsHealthz {
    /**
     * 
     * @type {string}
     * @memberof NotificationsHealthz
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsHealthz
     */
    build_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsHealthz
     */
    ok: boolean;
}

/**
 * Check if a given object implements the NotificationsHealthz interface.
 */
export function instanceOfNotificationsHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hostname" in value;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function NotificationsHealthzFromJSON(json: any): NotificationsHealthz {
    return NotificationsHealthzFromJSONTyped(json, false);
}

export function NotificationsHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hostname': json['hostname'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
        'ok': json['ok'],
    };
}

export function NotificationsHealthzToJSON(value?: NotificationsHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hostname': value.hostname,
        'build_version': value.build_version,
        'ok': value.ok,
    };
}

