/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDecisionConditionPlain } from './CRSFSecReviewDecisionConditionPlain';
import {
    CRSFSecReviewDecisionConditionPlainFromJSON,
    CRSFSecReviewDecisionConditionPlainFromJSONTyped,
    CRSFSecReviewDecisionConditionPlainToJSON,
} from './CRSFSecReviewDecisionConditionPlain';
import type { CRSFSecReviewDecisionTypePlain } from './CRSFSecReviewDecisionTypePlain';
import {
    CRSFSecReviewDecisionTypePlainFromJSON,
    CRSFSecReviewDecisionTypePlainFromJSONTyped,
    CRSFSecReviewDecisionTypePlainToJSON,
} from './CRSFSecReviewDecisionTypePlain';

/**
 * Class that represents one security decision (output of security_decisions module)
 * @export
 * @interface CRSFSecReviewDecisionRecord
 */
export interface CRSFSecReviewDecisionRecord {
    /**
     * 
     * @type {CRSFSecReviewDecisionConditionPlain}
     * @memberof CRSFSecReviewDecisionRecord
     */
    condition: CRSFSecReviewDecisionConditionPlain;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewDecisionRecord
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    decision_key: string;
    /**
     * 
     * @type {CRSFSecReviewDecisionTypePlain}
     * @memberof CRSFSecReviewDecisionRecord
     */
    decision_type: CRSFSecReviewDecisionTypePlain;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    enforce_type: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    evidence_type: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    finding_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    issue: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    process_id: string;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewDecisionRecord
     */
    raw_finding_record?: object;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionRecord
     */
    template_name: string;
}

/**
 * Check if a given object implements the CRSFSecReviewDecisionRecord interface.
 */
export function instanceOfCRSFSecReviewDecisionRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "condition" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "decision_key" in value;
    isInstance = isInstance && "decision_type" in value;
    isInstance = isInstance && "enforce_type" in value;
    isInstance = isInstance && "evidence_type" in value;
    isInstance = isInstance && "finding_key" in value;
    isInstance = isInstance && "issue" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "template_name" in value;

    return isInstance;
}

export function CRSFSecReviewDecisionRecordFromJSON(json: any): CRSFSecReviewDecisionRecord {
    return CRSFSecReviewDecisionRecordFromJSONTyped(json, false);
}

export function CRSFSecReviewDecisionRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDecisionRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition': CRSFSecReviewDecisionConditionPlainFromJSON(json['condition']),
        'created': json['created'],
        'datapoint': json['datapoint'],
        'decision_key': json['decision_key'],
        'decision_type': CRSFSecReviewDecisionTypePlainFromJSON(json['decision_type']),
        'enforce_type': json['enforce_type'],
        'evidence_type': json['evidence_type'],
        'finding_key': json['finding_key'],
        'issue': json['issue'],
        'process_id': json['process_id'],
        'raw_finding_record': !exists(json, 'raw_finding_record') ? undefined : json['raw_finding_record'],
        'template_name': json['template_name'],
    };
}

export function CRSFSecReviewDecisionRecordToJSON(value?: CRSFSecReviewDecisionRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition': CRSFSecReviewDecisionConditionPlainToJSON(value.condition),
        'created': value.created,
        'datapoint': value.datapoint,
        'decision_key': value.decision_key,
        'decision_type': CRSFSecReviewDecisionTypePlainToJSON(value.decision_type),
        'enforce_type': value.enforce_type,
        'evidence_type': value.evidence_type,
        'finding_key': value.finding_key,
        'issue': value.issue,
        'process_id': value.process_id,
        'raw_finding_record': value.raw_finding_record,
        'template_name': value.template_name,
    };
}

