/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlRescanReason = {
    PortClosed: 'port_closed',
    Removed: 'removed',
    Patched: 'patched',
    AddedFirewall: 'added_firewall',
    LimitedIpAccess: 'limited_ip_access',
    FixedSpfInDns: 'fixed_spf_in_dns',
    Other: 'other'
} as const;
export type ControlRescanReason = typeof ControlRescanReason[keyof typeof ControlRescanReason];


export function ControlRescanReasonFromJSON(json: any): ControlRescanReason {
    return ControlRescanReasonFromJSONTyped(json, false);
}

export function ControlRescanReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRescanReason {
    return json as ControlRescanReason;
}

export function ControlRescanReasonToJSON(value?: ControlRescanReason | null): any {
    return value as any;
}

