/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityPasswordsAggCharacters
 */
export interface MonitEntityPasswordsAggCharacters {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityPasswordsAggCharacters
     */
    lowercase?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityPasswordsAggCharacters
     */
    uppercase?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityPasswordsAggCharacters
     */
    numbers?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityPasswordsAggCharacters
     */
    special?: number;
}

/**
 * Check if a given object implements the MonitEntityPasswordsAggCharacters interface.
 */
export function instanceOfMonitEntityPasswordsAggCharacters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityPasswordsAggCharactersFromJSON(json: any): MonitEntityPasswordsAggCharacters {
    return MonitEntityPasswordsAggCharactersFromJSONTyped(json, false);
}

export function MonitEntityPasswordsAggCharactersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityPasswordsAggCharacters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowercase': !exists(json, 'lowercase') ? undefined : json['lowercase'],
        'uppercase': !exists(json, 'uppercase') ? undefined : json['uppercase'],
        'numbers': !exists(json, 'numbers') ? undefined : json['numbers'],
        'special': !exists(json, 'special') ? undefined : json['special'],
    };
}

export function MonitEntityPasswordsAggCharactersToJSON(value?: MonitEntityPasswordsAggCharacters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lowercase': value.lowercase,
        'uppercase': value.uppercase,
        'numbers': value.numbers,
        'special': value.special,
    };
}

