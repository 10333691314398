/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitDomainSourcelistsResultEvent } from './MonitDomainSourcelistsResultEvent';
import {
    MonitDomainSourcelistsResultEventFromJSON,
    MonitDomainSourcelistsResultEventFromJSONTyped,
    MonitDomainSourcelistsResultEventToJSON,
} from './MonitDomainSourcelistsResultEvent';
import type { MonitIPSourcelistsResultEvent } from './MonitIPSourcelistsResultEvent';
import {
    MonitIPSourcelistsResultEventFromJSON,
    MonitIPSourcelistsResultEventFromJSONTyped,
    MonitIPSourcelistsResultEventToJSON,
} from './MonitIPSourcelistsResultEvent';

/**
 * 
 * @export
 * @interface EventsInner
 */
export interface EventsInner {
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    ip_address?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    subcategory?: string;
    /**
     * 
     * @type {number}
     * @memberof EventsInner
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsInner
     */
    last_found?: number;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsInner
     */
    root_domain?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventsInner
     */
    ip_addresses?: Array<string>;
}

/**
 * Check if a given object implements the EventsInner interface.
 */
export function instanceOfEventsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventsInnerFromJSON(json: any): EventsInner {
    return EventsInnerFromJSONTyped(json, false);
}

export function EventsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'root_domain': !exists(json, 'root_domain') ? undefined : json['root_domain'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
    };
}

export function EventsInnerToJSON(value?: EventsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ip_address': value.ip_address,
        'service': value.service,
        'region': value.region,
        'source_list_name': value.source_list_name,
        'category': value.category,
        'subcategory': value.subcategory,
        'created': value.created,
        'last_found': value.last_found,
        'domain': value.domain,
        'path': value.path,
        'root_domain': value.root_domain,
        'ip_addresses': value.ip_addresses,
    };
}

