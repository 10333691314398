import React, { useMemo } from 'react';
import { Typography, TypographyLink } from '@explorer/core';
import ReactMarkdown from 'react-markdown';
import { CoalitionVariant } from '@mui/material';
/**
 * Markdown
 */
export const Markdown = ({
  md,
  textVariant = 'bodyXS',
  color = 'textSecondary',
}: MarkdownProps) => {
  const renderers = useMemo(
    () => ({
      paragraph: ({ children }: any) => (
        <Typography variant={textVariant} color={color} pb={0.5}>
          {children}
        </Typography>
      ),
      link: ({ href, children }: any) => (
        <TypographyLink
          href={href}
          title={href}
          variant={textVariant}
          pb={0.5}
          linkIcon="external"
          newTab
        >
          {children}
        </TypographyLink>
      ),
    }),
    [],
  );

  return <ReactMarkdown renderers={renderers}>{md}</ReactMarkdown>;
};

/**
 * Markdown Props
 */
export interface MarkdownProps {
  md: string;
  textVariant: CoalitionVariant;
  color?: 'textSecondary' | 'textPrimary' | 'inherit';
}
