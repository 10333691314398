import { Gradient } from '../types';

export const HEAT_GRADIENT: Gradient = {
  css: 'linear-gradient(90deg, #FFE865 0%, #FA4343 100%)',
  colors: [
    '#ffe464',
    '#ffdd63',
    '#ffd561',
    '#fecb5f',
    '#fec45e',
    '#febb5c',
    '#fdb35a',
    '#fdaa58',
    '#fda256',
    '#fd9a55',
    '#fd9253',
    '#fc8952',
    '#fc8150',
    '#fb784e',
    '#fb704c',
    '#fb684a',
    '#fb6049',
    '#fb5848',
    '#fb5046',
    '#fa4744',
  ],
};
