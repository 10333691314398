/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskCompanyInfoResponse } from './ExecRiskCompanyInfoResponse';
import {
    ExecRiskCompanyInfoResponseFromJSON,
    ExecRiskCompanyInfoResponseFromJSONTyped,
    ExecRiskCompanyInfoResponseToJSON,
} from './ExecRiskCompanyInfoResponse';

/**
 * 
 * @export
 * @interface ExecRiskCompanyInfoCategoryResponse
 */
export interface ExecRiskCompanyInfoCategoryResponse {
    /**
     * 
     * @type {ExecRiskCompanyInfoResponse}
     * @memberof ExecRiskCompanyInfoCategoryResponse
     */
    result: ExecRiskCompanyInfoResponse;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoCategoryResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoCategoryResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoCategoryResponse
     */
    report_url?: string;
}

/**
 * Check if a given object implements the ExecRiskCompanyInfoCategoryResponse interface.
 */
export function instanceOfExecRiskCompanyInfoCategoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function ExecRiskCompanyInfoCategoryResponseFromJSON(json: any): ExecRiskCompanyInfoCategoryResponse {
    return ExecRiskCompanyInfoCategoryResponseFromJSONTyped(json, false);
}

export function ExecRiskCompanyInfoCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskCompanyInfoCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ExecRiskCompanyInfoResponseFromJSON(json['result']),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'process_id': json['process_id'],
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
    };
}

export function ExecRiskCompanyInfoCategoryResponseToJSON(value?: ExecRiskCompanyInfoCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ExecRiskCompanyInfoResponseToJSON(value.result),
        'schema_version': value.schema_version,
        'process_id': value.process_id,
        'report_url': value.report_url,
    };
}

