/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsMessageStatus = {
    Scheduled: 'SCHEDULED',
    Finalised: 'FINALISED',
    Sending: 'SENDING',
    Failed: 'FAILED',
    Sent: 'SENT',
    Archived: 'ARCHIVED',
    Dismissed: 'DISMISSED'
} as const;
export type NotificationsMessageStatus = typeof NotificationsMessageStatus[keyof typeof NotificationsMessageStatus];


export function NotificationsMessageStatusFromJSON(json: any): NotificationsMessageStatus {
    return NotificationsMessageStatusFromJSONTyped(json, false);
}

export function NotificationsMessageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessageStatus {
    return json as NotificationsMessageStatus;
}

export function NotificationsMessageStatusToJSON(value?: NotificationsMessageStatus | null): any {
    return value as any;
}

