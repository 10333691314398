/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksStatusModelsScanStatus } from './ExecRiskAppApiV1EagerTasksStatusModelsScanStatus';
import {
    ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSON,
    ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped,
    ExecRiskAppApiV1EagerTasksStatusModelsScanStatusToJSON,
} from './ExecRiskAppApiV1EagerTasksStatusModelsScanStatus';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
 */
export interface ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    status?: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksStatusModelsScanStatus}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    scan_status?: ExecRiskAppApiV1EagerTasksStatusModelsScanStatus;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    finished?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "started" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSON(json: any): ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    return ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': json['process_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSON(json['scan_status']),
        'started': json['started'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'finished': !exists(json, 'finished') ? undefined : json['finished'],
    };
}

export function ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponseToJSON(value?: ExecRiskAppApiV1EagerTasksStatusModelsProcessStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'status': value.status,
        'scan_status': ExecRiskAppApiV1EagerTasksStatusModelsScanStatusToJSON(value.scan_status),
        'started': value.started,
        'updated': value.updated,
        'finished': value.finished,
    };
}

