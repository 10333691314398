/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ControlBooleanFieldDefinition,
    instanceOfControlBooleanFieldDefinition,
    ControlBooleanFieldDefinitionFromJSON,
    ControlBooleanFieldDefinitionFromJSONTyped,
    ControlBooleanFieldDefinitionToJSON,
} from './ControlBooleanFieldDefinition';
import {
    ControlMultiSelectFieldDefinition,
    instanceOfControlMultiSelectFieldDefinition,
    ControlMultiSelectFieldDefinitionFromJSON,
    ControlMultiSelectFieldDefinitionFromJSONTyped,
    ControlMultiSelectFieldDefinitionToJSON,
} from './ControlMultiSelectFieldDefinition';
import {
    ControlSingleSelectFieldDefinition,
    instanceOfControlSingleSelectFieldDefinition,
    ControlSingleSelectFieldDefinitionFromJSON,
    ControlSingleSelectFieldDefinitionFromJSONTyped,
    ControlSingleSelectFieldDefinitionToJSON,
} from './ControlSingleSelectFieldDefinition';
import {
    ControlTextFieldDefinition,
    instanceOfControlTextFieldDefinition,
    ControlTextFieldDefinitionFromJSON,
    ControlTextFieldDefinitionFromJSONTyped,
    ControlTextFieldDefinitionToJSON,
} from './ControlTextFieldDefinition';

/**
 * @type Field
 * 
 * @export
 */
export type Field = { field_type: 'boolean' } & ControlBooleanFieldDefinition | { field_type: 'multiselect' } & ControlMultiSelectFieldDefinition | { field_type: 'singleselect' } & ControlSingleSelectFieldDefinition | { field_type: 'text' } & ControlTextFieldDefinition;

export function FieldFromJSON(json: any): Field {
    return FieldFromJSONTyped(json, false);
}

export function FieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): Field {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['field_type']) {
        case 'boolean':
            return {...ControlBooleanFieldDefinitionFromJSONTyped(json, true), field_type: 'boolean'};
        case 'multiselect':
            return {...ControlMultiSelectFieldDefinitionFromJSONTyped(json, true), field_type: 'multiselect'};
        case 'singleselect':
            return {...ControlSingleSelectFieldDefinitionFromJSONTyped(json, true), field_type: 'singleselect'};
        case 'text':
            return {...ControlTextFieldDefinitionFromJSONTyped(json, true), field_type: 'text'};
        default:
            throw new Error(`No variant of Field exists with 'field_type=${json['field_type']}'`);
    }
}

export function FieldToJSON(value?: Field | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['field_type']) {
        case 'boolean':
            return ControlBooleanFieldDefinitionToJSON(value);
        case 'multiselect':
            return ControlMultiSelectFieldDefinitionToJSON(value);
        case 'singleselect':
            return ControlSingleSelectFieldDefinitionToJSON(value);
        case 'text':
            return ControlTextFieldDefinitionToJSON(value);
        default:
            throw new Error(`No variant of Field exists with 'field_type=${value['field_type']}'`);
    }

}

