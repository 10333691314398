/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewInsuranceTrigger
 */
export interface SecReviewInsuranceTrigger {
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    issue: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewInsuranceTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewInsuranceTrigger
     */
    nocache?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewInsuranceTrigger
     */
    bypass?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    rsa_version?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    se_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewInsuranceTrigger
     */
    dry_run?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    ts?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    insurance_env?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewInsuranceTrigger
     */
    request_origin?: string;
}

/**
 * Check if a given object implements the SecReviewInsuranceTrigger interface.
 */
export function instanceOfSecReviewInsuranceTrigger(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "issue" in value;

    return isInstance;
}

export function SecReviewInsuranceTriggerFromJSON(json: any): SecReviewInsuranceTrigger {
    return SecReviewInsuranceTriggerFromJSONTyped(json, false);
}

export function SecReviewInsuranceTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewInsuranceTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue': json['issue'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'bypass': !exists(json, 'bypass') ? undefined : json['bypass'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'rsa_version': !exists(json, 'rsa_version') ? undefined : json['rsa_version'],
        'se_version': !exists(json, 'se_version') ? undefined : json['se_version'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : json['insurance_env'],
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
    };
}

export function SecReviewInsuranceTriggerToJSON(value?: SecReviewInsuranceTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue': value.issue,
        'type': value.type,
        'force': value.force,
        'nocache': value.nocache,
        'bypass': value.bypass,
        'external_id': value.external_id,
        'user': value.user,
        'rsa_version': value.rsa_version,
        'se_version': value.se_version,
        'dry_run': value.dry_run,
        'ts': value.ts,
        'insurance_env': value.insurance_env,
        'account_uuid': value.account_uuid,
        'desired_policy_uuid': value.desired_policy_uuid,
        'renewal_of_uuid': value.renewal_of_uuid,
        'retained_from_uuid': value.retained_from_uuid,
        'request_origin': value.request_origin,
    };
}

