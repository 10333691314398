/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewJarm
 */
export interface SecReviewJarm {
    /**
     * 
     * @type {string}
     * @memberof SecReviewJarm
     */
    jarm?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewJarm
     */
    jarm_hash?: string;
}

/**
 * Check if a given object implements the SecReviewJarm interface.
 */
export function instanceOfSecReviewJarm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewJarmFromJSON(json: any): SecReviewJarm {
    return SecReviewJarmFromJSONTyped(json, false);
}

export function SecReviewJarmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewJarm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jarm': !exists(json, 'jarm') ? undefined : json['jarm'],
        'jarm_hash': !exists(json, 'jarm_hash') ? undefined : json['jarm_hash'],
    };
}

export function SecReviewJarmToJSON(value?: SecReviewJarm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jarm': value.jarm,
        'jarm_hash': value.jarm_hash,
    };
}

