/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityDataleaks
 */
export interface ControlEntityDataleaks {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaks
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaks
     */
    password?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaks
     */
    pii?: number;
}

/**
 * Check if a given object implements the ControlEntityDataleaks interface.
 */
export function instanceOfControlEntityDataleaks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityDataleaksFromJSON(json: any): ControlEntityDataleaks {
    return ControlEntityDataleaksFromJSONTyped(json, false);
}

export function ControlEntityDataleaksFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDataleaks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'pii': !exists(json, 'pii') ? undefined : json['pii'],
    };
}

export function ControlEntityDataleaksToJSON(value?: ControlEntityDataleaks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'password': value.password,
        'pii': value.pii,
    };
}

