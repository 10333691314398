import React from 'react';
import * as yup from 'yup';
import {
  FieldAutocomplete,
  FieldAutocompleteMultiple,
  FieldDatePicker,
  FieldText,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';

/**
 * Form Validation Schema
 */
export const validationSchema = (intl) =>
  yup
    .object({
      policy_id: yup
        .string()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredPolicy' })),
      claim_handler: yup
        .string()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredClaimHandler' })),
      coalition_handler: yup
        .string()
        .nullable()
        .required(
          intl.formatMessage({ id: 'errors.requiredCoalitionHandler' }),
        ),
      claim_type: yup
        .string()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredClaimType' })),
      event_types: yup
        .array(yup.string())
        .required(intl.formatMessage({ id: 'errors.requiredEventTypes' })),
      claim_notes: yup
        .string()
        .required(intl.formatMessage({ id: 'errors.requiredClaimNotes' })),
      date_reported: yup
        .string()
        .required(intl.formatMessage({ id: 'errors.requireReportedDate' })),
      status: yup.string().required(),
      date_of_loss: yup
        .string()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredDateLoss' })),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
  options,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  policy_id: {
    formId,
    formik,
    name: 'policy_id',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'claims.policy' }}
        options={options?.policies || []}
      />
    ),
  },
  claim_handler: {
    formId,
    formik,
    name: 'claim_handler',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'global.claimHandler' }}
        options={options?.claimHandlers || []}
      />
    ),
  },
  coalition_handler: {
    formId,
    formik,
    name: 'coalition_handler',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'global.coalitionHandler' }}
        options={options?.coalitionHandlers || []}
      />
    ),
  },
  claim_type: {
    formId,
    formik,
    name: 'claim_type',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'global.claimType' }}
        options={options?.claimTypes || []}
      />
    ),
  },
  event_types: {
    formId,
    formik,
    name: 'event_types',
    renderField: (props) => (
      <FieldAutocompleteMultiple
        {...props}
        label={{ id: 'global.eventType' }}
        options={options?.eventTypes || []}
        multiple
      />
    ),
  },
  claim_notes: {
    formId,
    formik,
    name: 'claim_notes',
    renderField: (props) => (
      <FieldText
        {...props}
        label={{ id: 'global.claimNotes' }}
        multiline
        rows={6}
      />
    ),
  },
  date_reported: {
    formId,
    formik,
    name: 'date_reported',
    renderField: (props) => (
      <FieldText {...props} label={{ id: 'global.dateReported' }} disabled />
    ),
  },
  status: {
    formId,
    formik,
    name: 'status',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'global.status' }}
        options={options?.claimStatus}
      />
    ),
  },
  date_of_loss: {
    formId,
    formik,
    name: 'date_of_loss',
    renderField: (props) => (
      <FieldDatePicker {...props} label={{ id: 'claims.date-of-loss' }} />
    ),
  },
});
