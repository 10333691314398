import { useMemo, useReducer } from 'react';
import Cookie from 'js-cookie';
import { useParam } from '@explorer/hooks';
import {
  ApiClientHeaders,
  ApiClientPromiseParams,
  UseQuery,
  UseQueryOptions,
  UseQueryReset,
  UseQueryState,
  UseQuerySubmit,
} from '../../types';
import { ApiClient } from '../ApiClient';
import { initialState, reducer } from './reducer';

export const useQuery = <Data = any>(
  options: UseQueryOptions<Data>,
): UseQuery<Data> => {
  const clientId = useParam('cid');

  const [state, dispatch] = useReducer(reducer, initialState);
  const { url, endpoint, method, auth, digest, forceToken, headers } = options;

  const token = getCredentials({ forceToken, auth });

  const apiClient = useMemo<ApiClient>(() => {
    const additionalHeaders: ApiClientHeaders = {};
    if (clientId) {
      additionalHeaders['EXTERNAL-ID'] = clientId;
    }

    return new ApiClient(url, auth, token, {
      ...headers,
      ...additionalHeaders,
    });
  }, [url, auth, token, headers, clientId]);

  /**
   * UseQuery API Response Handler
   */
  const handleResponse = ({
    rawData,
    error,
  }: ApiClientPromiseParams<Data>): any =>
    new Promise((resolve) => {
      if (error) {
        dispatch({
          type: 'FAILED',
          payload: { error },
        });

        return resolve({ error, data: null });
      }

      const data: Data = digest ? digest({ rawData }) : rawData;

      dispatch({
        type: 'SUCCEEDED',
        payload: { data },
      });

      return resolve({ error: null, data });
    });

  /**
   * UseQuery Submit
   */
  const submit: UseQuerySubmit = async (opt = {}) => {
    const { payload, params, dynamicPathVar } = opt;
    dispatch({ type: 'STARTED' });

    if (method === 'GET') {
      return apiClient
        .GET<Data>({ endpoint, params, payload, dynamicPathVar })
        .then(handleResponse);
    }

    if (method === 'POST') {
      return apiClient
        .POST<Data>({ endpoint, params, payload, dynamicPathVar })
        .then(handleResponse);
    }

    if (method === 'PUT') {
      return apiClient
        .PUT<Data>({ endpoint, params, payload, dynamicPathVar })
        .then(handleResponse);
    }

    if (method === 'PATCH') {
      return apiClient
        .PATCH<Data>({ endpoint, params, payload, dynamicPathVar })
        .then(handleResponse);
    }

    if (method === 'DELETE') {
      return apiClient
        .DELETE<Data>({ endpoint, params, payload, dynamicPathVar })
        .then(handleResponse);
    }

    throw new Error(`<useQuery> invalid api method "${method}"`);
  };

  /**
   * UseQuery Reset
   */
  const reset: UseQueryReset = () =>
    new Promise((resolve) => {
      dispatch({ type: 'RESET' });
      return resolve();
    });

  return {
    ...(state as UseQueryState<Data>),
    submit,
    reset,
  };
};
export function getCredentials(args: { forceToken?: string; auth: string }) {
  const { auth, forceToken } = args;

  return forceToken ?? global.localStorage?.getItem(auth) ?? Cookie.get(auth);
}
