/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlSurvey = {
    UserRole: 'user_role',
    CompanySize: 'company_size',
    UserFirstName: 'user_first_name',
    UserLastName: 'user_last_name',
    UserJobFunction: 'user_job_function',
    UserJobLevel: 'user_job_level',
    CompanyEmployeeCount: 'company_employee_count',
    CompanyHasMsp: 'company_has_msp',
    CompanyMspWebsite: 'company_msp_website',
    CompanyMspEmail: 'company_msp_email',
    MspCompanyClientCount: 'msp_company_client_count',
    MspCompanyName: 'msp_company_name',
    MspCompanyRevenue: 'msp_company_revenue'
} as const;
export type ControlSurvey = typeof ControlSurvey[keyof typeof ControlSurvey];


export function ControlSurveyFromJSON(json: any): ControlSurvey {
    return ControlSurveyFromJSONTyped(json, false);
}

export function ControlSurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSurvey {
    return json as ControlSurvey;
}

export function ControlSurveyToJSON(value?: ControlSurvey | null): any {
    return value as any;
}

