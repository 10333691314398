/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_adj_net_income_distributable_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_pct_85_adjusted_net_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_total_min_ast_distributable_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_greater_adjusted_minimum_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_income_tax_imposed_py_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    cy_distributable_as_adjusted_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount
     */
    first_year_type_3_non_func_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmountFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount {
    return ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmountFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cy_adj_net_income_distributable_amt': !exists(json, 'cy_adj_net_income_distributable_amt') ? undefined : json['cy_adj_net_income_distributable_amt'],
        'cy_pct_85_adjusted_net_income_amt': !exists(json, 'cy_pct_85_adjusted_net_income_amt') ? undefined : json['cy_pct_85_adjusted_net_income_amt'],
        'cy_total_min_ast_distributable_amt': !exists(json, 'cy_total_min_ast_distributable_amt') ? undefined : json['cy_total_min_ast_distributable_amt'],
        'cy_greater_adjusted_minimum_amt': !exists(json, 'cy_greater_adjusted_minimum_amt') ? undefined : json['cy_greater_adjusted_minimum_amt'],
        'cy_income_tax_imposed_py_amt': !exists(json, 'cy_income_tax_imposed_py_amt') ? undefined : json['cy_income_tax_imposed_py_amt'],
        'cy_distributable_as_adjusted_amt': !exists(json, 'cy_distributable_as_adjusted_amt') ? undefined : json['cy_distributable_as_adjusted_amt'],
        'first_year_type_3_non_func_ind': !exists(json, 'first_year_type_3_non_func_ind') ? undefined : json['first_year_type_3_non_func_ind'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmountToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleADistributableAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cy_adj_net_income_distributable_amt': value.cy_adj_net_income_distributable_amt,
        'cy_pct_85_adjusted_net_income_amt': value.cy_pct_85_adjusted_net_income_amt,
        'cy_total_min_ast_distributable_amt': value.cy_total_min_ast_distributable_amt,
        'cy_greater_adjusted_minimum_amt': value.cy_greater_adjusted_minimum_amt,
        'cy_income_tax_imposed_py_amt': value.cy_income_tax_imposed_py_amt,
        'cy_distributable_as_adjusted_amt': value.cy_distributable_as_adjusted_amt,
        'first_year_type_3_non_func_ind': value.first_year_type_3_non_func_ind,
    };
}

