/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    supported_organization_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    organization_type_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    governing_document_listed_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    support_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    other_support_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    return ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supported_organization_name': !exists(json, 'supported_organization_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['supported_organization_name']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'organization_type_cd': !exists(json, 'organization_type_cd') ? undefined : json['organization_type_cd'],
        'governing_document_listed_ind': !exists(json, 'governing_document_listed_ind') ? undefined : json['governing_document_listed_ind'],
        'support_amt': !exists(json, 'support_amt') ? undefined : json['support_amt'],
        'other_support_amt': !exists(json, 'other_support_amt') ? undefined : json['other_support_amt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformationToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleASupportedOrgInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supported_organization_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.supported_organization_name),
        'ein': value.ein,
        'organization_type_cd': value.organization_type_cd,
        'governing_document_listed_ind': value.governing_document_listed_ind,
        'support_amt': value.support_amt,
        'other_support_amt': value.other_support_amt,
    };
}

