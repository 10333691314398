const IS_IPV6_REGEX = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;

/**
 * Propertly format an asset (IPv4, IPv6, Domain) with the port.
 *
 * __> Example:__
 * ```ts
 * formatAsset('192.167.255.255', '8080');
 * // '192.167.255.255:8080'
 *
 * formatAsset('21DA:D3:0:2F3B:2AA:FF:FE28:9C5A', '8080');
 * // '[21DA:D3:0:2F3B:2AA:FF:FE28:9C5A]:8080'
 *
 * formatAsset('binaryedge.io', '8080');
 * // 'binaryedge.io:8080'
 * ```
 */

export const formatAsset: FormatAsset = (asset, port) => {
  if (!port) return asset;

  if (IS_IPV6_REGEX.test(asset)) return `[${asset}]:${port}`;

  return `${asset}:${port}`;
};

type FormatAsset = (asset: string, port?: string | number) => string;
