import React from 'react';
import clsx from 'clsx';
import MuiDivider, {
  DividerProps as MuiDividerProps,
} from '@mui/material/Divider';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Divider = withCoreProps<DividerProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<DividerProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiDivider {...props} className={clsxClass}>
        {children}
      </MuiDivider>
    );
  },
);

export interface DividerProps extends MuiDividerProps, CoreProps {}
