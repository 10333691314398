/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientInfoClient } from './ControlClientInfoClient';
import {
    ControlClientInfoClientFromJSON,
    ControlClientInfoClientFromJSONTyped,
    ControlClientInfoClientToJSON,
} from './ControlClientInfoClient';
import type { ControlClientInfoUser } from './ControlClientInfoUser';
import {
    ControlClientInfoUserFromJSON,
    ControlClientInfoUserFromJSONTyped,
    ControlClientInfoUserToJSON,
} from './ControlClientInfoUser';

/**
 * 
 * @export
 * @interface ControlClientInfoResponse
 */
export interface ControlClientInfoResponse {
    /**
     * 
     * @type {ControlClientInfoClient}
     * @memberof ControlClientInfoResponse
     */
    client: ControlClientInfoClient;
    /**
     * 
     * @type {Array<ControlClientInfoUser>}
     * @memberof ControlClientInfoResponse
     */
    associated_users: Array<ControlClientInfoUser>;
}

/**
 * Check if a given object implements the ControlClientInfoResponse interface.
 */
export function instanceOfControlClientInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "associated_users" in value;

    return isInstance;
}

export function ControlClientInfoResponseFromJSON(json: any): ControlClientInfoResponse {
    return ControlClientInfoResponseFromJSONTyped(json, false);
}

export function ControlClientInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlClientInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': ControlClientInfoClientFromJSON(json['client']),
        'associated_users': ((json['associated_users'] as Array<any>).map(ControlClientInfoUserFromJSON)),
    };
}

export function ControlClientInfoResponseToJSON(value?: ControlClientInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ControlClientInfoClientToJSON(value.client),
        'associated_users': ((value.associated_users as Array<any>).map(ControlClientInfoUserToJSON)),
    };
}

