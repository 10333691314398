/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskPPPLoansScan
 */
export interface ExecRiskPPPLoansScan {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    recipient?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    business_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    loan_amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    date_approved?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPPPLoansScan
     */
    jobs_reported?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    lender?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPPPLoansScan
     */
    updated?: string;
}

/**
 * Check if a given object implements the ExecRiskPPPLoansScan interface.
 */
export function instanceOfExecRiskPPPLoansScan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskPPPLoansScanFromJSON(json: any): ExecRiskPPPLoansScan {
    return ExecRiskPPPLoansScanFromJSONTyped(json, false);
}

export function ExecRiskPPPLoansScanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPPPLoansScan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
        'business_type': !exists(json, 'business_type') ? undefined : json['business_type'],
        'loan_amount': !exists(json, 'loan_amount') ? undefined : json['loan_amount'],
        'date_approved': !exists(json, 'date_approved') ? undefined : json['date_approved'],
        'jobs_reported': !exists(json, 'jobs_reported') ? undefined : json['jobs_reported'],
        'lender': !exists(json, 'lender') ? undefined : json['lender'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function ExecRiskPPPLoansScanToJSON(value?: ExecRiskPPPLoansScan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient': value.recipient,
        'business_type': value.business_type,
        'loan_amount': value.loan_amount,
        'date_approved': value.date_approved,
        'jobs_reported': value.jobs_reported,
        'lender': value.lender,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'address': value.address,
        'updated': value.updated,
    };
}

