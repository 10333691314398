/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CYDistributableAsAdjustedAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    UnderdistributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovYr5Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovYr4Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovYr3Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovYr2Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovYr1Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    TotalExcessDistributionCyovAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CyovAppliedUnderdistriPYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CyovAppliedUnderdistrCPYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CarryoverPYNotAppliedAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionCyovAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CYTotalAnnualDistributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CYDistribAppUnderdistriPYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    CYDistriAppDistributableAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistributionAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    RemainingUnderdistriPYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    RemainingUnderdistriCYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessDistriCyovToNextYrAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessFromYear5Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessFromYear4Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessFromYear3Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessFromYear2Amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations
     */
    ExcessFromYear1Amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CYDistributableAsAdjustedAmt': !exists(json, 'CYDistributableAsAdjustedAmt') ? undefined : json['CYDistributableAsAdjustedAmt'],
        'UnderdistributionsAmt': !exists(json, 'UnderdistributionsAmt') ? undefined : json['UnderdistributionsAmt'],
        'ExcessDistributionCyovYr5Amt': !exists(json, 'ExcessDistributionCyovYr5Amt') ? undefined : json['ExcessDistributionCyovYr5Amt'],
        'ExcessDistributionCyovYr4Amt': !exists(json, 'ExcessDistributionCyovYr4Amt') ? undefined : json['ExcessDistributionCyovYr4Amt'],
        'ExcessDistributionCyovYr3Amt': !exists(json, 'ExcessDistributionCyovYr3Amt') ? undefined : json['ExcessDistributionCyovYr3Amt'],
        'ExcessDistributionCyovYr2Amt': !exists(json, 'ExcessDistributionCyovYr2Amt') ? undefined : json['ExcessDistributionCyovYr2Amt'],
        'ExcessDistributionCyovYr1Amt': !exists(json, 'ExcessDistributionCyovYr1Amt') ? undefined : json['ExcessDistributionCyovYr1Amt'],
        'TotalExcessDistributionCyovAmt': !exists(json, 'TotalExcessDistributionCyovAmt') ? undefined : json['TotalExcessDistributionCyovAmt'],
        'CyovAppliedUnderdistriPYAmt': !exists(json, 'CyovAppliedUnderdistriPYAmt') ? undefined : json['CyovAppliedUnderdistriPYAmt'],
        'CyovAppliedUnderdistrCPYAmt': !exists(json, 'CyovAppliedUnderdistrCPYAmt') ? undefined : json['CyovAppliedUnderdistrCPYAmt'],
        'CarryoverPYNotAppliedAmt': !exists(json, 'CarryoverPYNotAppliedAmt') ? undefined : json['CarryoverPYNotAppliedAmt'],
        'ExcessDistributionCyovAmt': !exists(json, 'ExcessDistributionCyovAmt') ? undefined : json['ExcessDistributionCyovAmt'],
        'CYTotalAnnualDistributionsAmt': !exists(json, 'CYTotalAnnualDistributionsAmt') ? undefined : json['CYTotalAnnualDistributionsAmt'],
        'CYDistribAppUnderdistriPYAmt': !exists(json, 'CYDistribAppUnderdistriPYAmt') ? undefined : json['CYDistribAppUnderdistriPYAmt'],
        'CYDistriAppDistributableAmt': !exists(json, 'CYDistriAppDistributableAmt') ? undefined : json['CYDistriAppDistributableAmt'],
        'ExcessDistributionAmt': !exists(json, 'ExcessDistributionAmt') ? undefined : json['ExcessDistributionAmt'],
        'RemainingUnderdistriPYAmt': !exists(json, 'RemainingUnderdistriPYAmt') ? undefined : json['RemainingUnderdistriPYAmt'],
        'RemainingUnderdistriCYAmt': !exists(json, 'RemainingUnderdistriCYAmt') ? undefined : json['RemainingUnderdistriCYAmt'],
        'ExcessDistriCyovToNextYrAmt': !exists(json, 'ExcessDistriCyovToNextYrAmt') ? undefined : json['ExcessDistriCyovToNextYrAmt'],
        'ExcessFromYear5Amt': !exists(json, 'ExcessFromYear5Amt') ? undefined : json['ExcessFromYear5Amt'],
        'ExcessFromYear4Amt': !exists(json, 'ExcessFromYear4Amt') ? undefined : json['ExcessFromYear4Amt'],
        'ExcessFromYear3Amt': !exists(json, 'ExcessFromYear3Amt') ? undefined : json['ExcessFromYear3Amt'],
        'ExcessFromYear2Amt': !exists(json, 'ExcessFromYear2Amt') ? undefined : json['ExcessFromYear2Amt'],
        'ExcessFromYear1Amt': !exists(json, 'ExcessFromYear1Amt') ? undefined : json['ExcessFromYear1Amt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CYDistributableAsAdjustedAmt': value.CYDistributableAsAdjustedAmt,
        'UnderdistributionsAmt': value.UnderdistributionsAmt,
        'ExcessDistributionCyovYr5Amt': value.ExcessDistributionCyovYr5Amt,
        'ExcessDistributionCyovYr4Amt': value.ExcessDistributionCyovYr4Amt,
        'ExcessDistributionCyovYr3Amt': value.ExcessDistributionCyovYr3Amt,
        'ExcessDistributionCyovYr2Amt': value.ExcessDistributionCyovYr2Amt,
        'ExcessDistributionCyovYr1Amt': value.ExcessDistributionCyovYr1Amt,
        'TotalExcessDistributionCyovAmt': value.TotalExcessDistributionCyovAmt,
        'CyovAppliedUnderdistriPYAmt': value.CyovAppliedUnderdistriPYAmt,
        'CyovAppliedUnderdistrCPYAmt': value.CyovAppliedUnderdistrCPYAmt,
        'CarryoverPYNotAppliedAmt': value.CarryoverPYNotAppliedAmt,
        'ExcessDistributionCyovAmt': value.ExcessDistributionCyovAmt,
        'CYTotalAnnualDistributionsAmt': value.CYTotalAnnualDistributionsAmt,
        'CYDistribAppUnderdistriPYAmt': value.CYDistribAppUnderdistriPYAmt,
        'CYDistriAppDistributableAmt': value.CYDistriAppDistributableAmt,
        'ExcessDistributionAmt': value.ExcessDistributionAmt,
        'RemainingUnderdistriPYAmt': value.RemainingUnderdistriPYAmt,
        'RemainingUnderdistriCYAmt': value.RemainingUnderdistriCYAmt,
        'ExcessDistriCyovToNextYrAmt': value.ExcessDistriCyovToNextYrAmt,
        'ExcessFromYear5Amt': value.ExcessFromYear5Amt,
        'ExcessFromYear4Amt': value.ExcessFromYear4Amt,
        'ExcessFromYear3Amt': value.ExcessFromYear3Amt,
        'ExcessFromYear2Amt': value.ExcessFromYear2Amt,
        'ExcessFromYear1Amt': value.ExcessFromYear1Amt,
    };
}

