/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlGlassdoorRating
 */
export interface ControlGlassdoorRating {
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    overall_rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    review_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    ceo_rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    recommend_to_friend?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    culture_values?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    diversity_inclusion?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    opportunities?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    work_life_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    senior_management?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    compensation_benefits?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlGlassdoorRating
     */
    business_outlook?: number;
}

/**
 * Check if a given object implements the ControlGlassdoorRating interface.
 */
export function instanceOfControlGlassdoorRating(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlGlassdoorRatingFromJSON(json: any): ControlGlassdoorRating {
    return ControlGlassdoorRatingFromJSONTyped(json, false);
}

export function ControlGlassdoorRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGlassdoorRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overall_rating': !exists(json, 'overall_rating') ? undefined : json['overall_rating'],
        'review_count': !exists(json, 'review_count') ? undefined : json['review_count'],
        'ceo_rating': !exists(json, 'ceo_rating') ? undefined : json['ceo_rating'],
        'recommend_to_friend': !exists(json, 'recommend_to_friend') ? undefined : json['recommend_to_friend'],
        'culture_values': !exists(json, 'culture_values') ? undefined : json['culture_values'],
        'diversity_inclusion': !exists(json, 'diversity_inclusion') ? undefined : json['diversity_inclusion'],
        'opportunities': !exists(json, 'opportunities') ? undefined : json['opportunities'],
        'work_life_balance': !exists(json, 'work_life_balance') ? undefined : json['work_life_balance'],
        'senior_management': !exists(json, 'senior_management') ? undefined : json['senior_management'],
        'compensation_benefits': !exists(json, 'compensation_benefits') ? undefined : json['compensation_benefits'],
        'business_outlook': !exists(json, 'business_outlook') ? undefined : json['business_outlook'],
    };
}

export function ControlGlassdoorRatingToJSON(value?: ControlGlassdoorRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overall_rating': value.overall_rating,
        'review_count': value.review_count,
        'ceo_rating': value.ceo_rating,
        'recommend_to_friend': value.recommend_to_friend,
        'culture_values': value.culture_values,
        'diversity_inclusion': value.diversity_inclusion,
        'opportunities': value.opportunities,
        'work_life_balance': value.work_life_balance,
        'senior_management': value.senior_management,
        'compensation_benefits': value.compensation_benefits,
        'business_outlook': value.business_outlook,
    };
}

