/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewBluekeep } from './SecReviewBluekeep';
import {
    SecReviewBluekeepFromJSON,
    SecReviewBluekeepFromJSONTyped,
    SecReviewBluekeepToJSON,
} from './SecReviewBluekeep';
import type { SecReviewCVE } from './SecReviewCVE';
import {
    SecReviewCVEFromJSON,
    SecReviewCVEFromJSONTyped,
    SecReviewCVEToJSON,
} from './SecReviewCVE';
import type { SecReviewCassandra } from './SecReviewCassandra';
import {
    SecReviewCassandraFromJSON,
    SecReviewCassandraFromJSONTyped,
    SecReviewCassandraToJSON,
} from './SecReviewCassandra';
import type { SecReviewElasticSearch } from './SecReviewElasticSearch';
import {
    SecReviewElasticSearchFromJSON,
    SecReviewElasticSearchFromJSONTyped,
    SecReviewElasticSearchToJSON,
} from './SecReviewElasticSearch';
import type { SecReviewExchangeOWA } from './SecReviewExchangeOWA';
import {
    SecReviewExchangeOWAFromJSON,
    SecReviewExchangeOWAFromJSONTyped,
    SecReviewExchangeOWAToJSON,
} from './SecReviewExchangeOWA';
import type { SecReviewFTP } from './SecReviewFTP';
import {
    SecReviewFTPFromJSON,
    SecReviewFTPFromJSONTyped,
    SecReviewFTPToJSON,
} from './SecReviewFTP';
import type { SecReviewFortinetLambda } from './SecReviewFortinetLambda';
import {
    SecReviewFortinetLambdaFromJSON,
    SecReviewFortinetLambdaFromJSONTyped,
    SecReviewFortinetLambdaToJSON,
} from './SecReviewFortinetLambda';
import type { SecReviewJarm } from './SecReviewJarm';
import {
    SecReviewJarmFromJSON,
    SecReviewJarmFromJSONTyped,
    SecReviewJarmToJSON,
} from './SecReviewJarm';
import type { SecReviewKubernetes } from './SecReviewKubernetes';
import {
    SecReviewKubernetesFromJSON,
    SecReviewKubernetesFromJSONTyped,
    SecReviewKubernetesToJSON,
} from './SecReviewKubernetes';
import type { SecReviewMQTT } from './SecReviewMQTT';
import {
    SecReviewMQTTFromJSON,
    SecReviewMQTTFromJSONTyped,
    SecReviewMQTTToJSON,
} from './SecReviewMQTT';
import type { SecReviewManageEngine } from './SecReviewManageEngine';
import {
    SecReviewManageEngineFromJSON,
    SecReviewManageEngineFromJSONTyped,
    SecReviewManageEngineToJSON,
} from './SecReviewManageEngine';
import type { SecReviewMemcached } from './SecReviewMemcached';
import {
    SecReviewMemcachedFromJSON,
    SecReviewMemcachedFromJSONTyped,
    SecReviewMemcachedToJSON,
} from './SecReviewMemcached';
import type { SecReviewMongoDB } from './SecReviewMongoDB';
import {
    SecReviewMongoDBFromJSON,
    SecReviewMongoDBFromJSONTyped,
    SecReviewMongoDBToJSON,
} from './SecReviewMongoDB';
import type { SecReviewPulseSecureLambda } from './SecReviewPulseSecureLambda';
import {
    SecReviewPulseSecureLambdaFromJSON,
    SecReviewPulseSecureLambdaFromJSONTyped,
    SecReviewPulseSecureLambdaToJSON,
} from './SecReviewPulseSecureLambda';
import type { SecReviewRDP } from './SecReviewRDP';
import {
    SecReviewRDPFromJSON,
    SecReviewRDPFromJSONTyped,
    SecReviewRDPToJSON,
} from './SecReviewRDP';
import type { SecReviewRDWebLambda } from './SecReviewRDWebLambda';
import {
    SecReviewRDWebLambdaFromJSON,
    SecReviewRDWebLambdaFromJSONTyped,
    SecReviewRDWebLambdaToJSON,
} from './SecReviewRDWebLambda';
import type { SecReviewRedis } from './SecReviewRedis';
import {
    SecReviewRedisFromJSON,
    SecReviewRedisFromJSONTyped,
    SecReviewRedisToJSON,
} from './SecReviewRedis';
import type { SecReviewRemotePowershell } from './SecReviewRemotePowershell';
import {
    SecReviewRemotePowershellFromJSON,
    SecReviewRemotePowershellFromJSONTyped,
    SecReviewRemotePowershellToJSON,
} from './SecReviewRemotePowershell';
import type { SecReviewRsync } from './SecReviewRsync';
import {
    SecReviewRsyncFromJSON,
    SecReviewRsyncFromJSONTyped,
    SecReviewRsyncToJSON,
} from './SecReviewRsync';
import type { SecReviewSMB } from './SecReviewSMB';
import {
    SecReviewSMBFromJSON,
    SecReviewSMBFromJSONTyped,
    SecReviewSMBToJSON,
} from './SecReviewSMB';
import type { SecReviewSNMP } from './SecReviewSNMP';
import {
    SecReviewSNMPFromJSON,
    SecReviewSNMPFromJSONTyped,
    SecReviewSNMPToJSON,
} from './SecReviewSNMP';
import type { SecReviewSSH } from './SecReviewSSH';
import {
    SecReviewSSHFromJSON,
    SecReviewSSHFromJSONTyped,
    SecReviewSSHToJSON,
} from './SecReviewSSH';
import type { SecReviewSSLv2 } from './SecReviewSSLv2';
import {
    SecReviewSSLv2FromJSON,
    SecReviewSSLv2FromJSONTyped,
    SecReviewSSLv2ToJSON,
} from './SecReviewSSLv2';
import type { SecReviewServiceSimple } from './SecReviewServiceSimple';
import {
    SecReviewServiceSimpleFromJSON,
    SecReviewServiceSimpleFromJSONTyped,
    SecReviewServiceSimpleToJSON,
} from './SecReviewServiceSimple';
import type { SecReviewSonicWallLambda } from './SecReviewSonicWallLambda';
import {
    SecReviewSonicWallLambdaFromJSON,
    SecReviewSonicWallLambdaFromJSONTyped,
    SecReviewSonicWallLambdaToJSON,
} from './SecReviewSonicWallLambda';
import type { SecReviewTechnology } from './SecReviewTechnology';
import {
    SecReviewTechnologyFromJSON,
    SecReviewTechnologyFromJSONTyped,
    SecReviewTechnologyToJSON,
} from './SecReviewTechnology';
import type { SecReviewTelnet } from './SecReviewTelnet';
import {
    SecReviewTelnetFromJSON,
    SecReviewTelnetFromJSONTyped,
    SecReviewTelnetToJSON,
} from './SecReviewTelnet';
import type { SecReviewVNC } from './SecReviewVNC';
import {
    SecReviewVNCFromJSON,
    SecReviewVNCFromJSONTyped,
    SecReviewVNCToJSON,
} from './SecReviewVNC';
import type { SecReviewWebv2 } from './SecReviewWebv2';
import {
    SecReviewWebv2FromJSON,
    SecReviewWebv2FromJSONTyped,
    SecReviewWebv2ToJSON,
} from './SecReviewWebv2';
import type { SecReviewX11 } from './SecReviewX11';
import {
    SecReviewX11FromJSON,
    SecReviewX11FromJSONTyped,
    SecReviewX11ToJSON,
} from './SecReviewX11';

/**
 * 
 * @export
 * @interface SecReviewPort
 */
export interface SecReviewPort {
    /**
     * 
     * @type {number}
     * @memberof SecReviewPort
     */
    port: number;
    /**
     * 
     * @type {string}
     * @memberof SecReviewPort
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewPort
     */
    product?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewPort
     */
    version?: string;
    /**
     * 
     * @type {SecReviewServiceSimple}
     * @memberof SecReviewPort
     */
    service_simple?: SecReviewServiceSimple;
    /**
     * 
     * @type {SecReviewWebv2}
     * @memberof SecReviewPort
     */
    webv2?: SecReviewWebv2;
    /**
     * 
     * @type {SecReviewWebv2}
     * @memberof SecReviewPort
     */
    web_quick?: SecReviewWebv2;
    /**
     * 
     * @type {SecReviewSSLv2}
     * @memberof SecReviewPort
     */
    sslv2?: SecReviewSSLv2;
    /**
     * 
     * @type {SecReviewJarm}
     * @memberof SecReviewPort
     */
    jarm?: SecReviewJarm;
    /**
     * 
     * @type {SecReviewRDP}
     * @memberof SecReviewPort
     */
    rdp?: SecReviewRDP;
    /**
     * 
     * @type {SecReviewBluekeep}
     * @memberof SecReviewPort
     */
    bluekeep?: SecReviewBluekeep;
    /**
     * 
     * @type {SecReviewVNC}
     * @memberof SecReviewPort
     */
    vnc?: SecReviewVNC;
    /**
     * 
     * @type {SecReviewX11}
     * @memberof SecReviewPort
     */
    x11?: SecReviewX11;
    /**
     * 
     * @type {SecReviewSSH}
     * @memberof SecReviewPort
     */
    ssh?: SecReviewSSH;
    /**
     * 
     * @type {SecReviewRsync}
     * @memberof SecReviewPort
     */
    rsync?: SecReviewRsync;
    /**
     * 
     * @type {SecReviewFTP}
     * @memberof SecReviewPort
     */
    ftp?: SecReviewFTP;
    /**
     * 
     * @type {SecReviewTelnet}
     * @memberof SecReviewPort
     */
    telnet?: SecReviewTelnet;
    /**
     * 
     * @type {SecReviewSMB}
     * @memberof SecReviewPort
     */
    smb?: SecReviewSMB;
    /**
     * 
     * @type {SecReviewKubernetes}
     * @memberof SecReviewPort
     */
    kubernetes?: SecReviewKubernetes;
    /**
     * 
     * @type {SecReviewCassandra}
     * @memberof SecReviewPort
     */
    cassandra?: SecReviewCassandra;
    /**
     * 
     * @type {SecReviewElasticSearch}
     * @memberof SecReviewPort
     */
    elasticsearch?: SecReviewElasticSearch;
    /**
     * 
     * @type {SecReviewMemcached}
     * @memberof SecReviewPort
     */
    memcached?: SecReviewMemcached;
    /**
     * 
     * @type {SecReviewMongoDB}
     * @memberof SecReviewPort
     */
    mongodb?: SecReviewMongoDB;
    /**
     * 
     * @type {SecReviewRedis}
     * @memberof SecReviewPort
     */
    redis?: SecReviewRedis;
    /**
     * 
     * @type {SecReviewMQTT}
     * @memberof SecReviewPort
     */
    mqtt?: SecReviewMQTT;
    /**
     * 
     * @type {SecReviewExchangeOWA}
     * @memberof SecReviewPort
     */
    exchange_owa?: SecReviewExchangeOWA;
    /**
     * 
     * @type {SecReviewFortinetLambda}
     * @memberof SecReviewPort
     */
    fortinet?: SecReviewFortinetLambda;
    /**
     * 
     * @type {SecReviewPulseSecureLambda}
     * @memberof SecReviewPort
     */
    pulse_secure?: SecReviewPulseSecureLambda;
    /**
     * 
     * @type {SecReviewSonicWallLambda}
     * @memberof SecReviewPort
     */
    sonicwall?: SecReviewSonicWallLambda;
    /**
     * 
     * @type {SecReviewRDWebLambda}
     * @memberof SecReviewPort
     */
    rdweb?: SecReviewRDWebLambda;
    /**
     * 
     * @type {SecReviewRemotePowershell}
     * @memberof SecReviewPort
     */
    remote_powershell?: SecReviewRemotePowershell;
    /**
     * 
     * @type {SecReviewSNMP}
     * @memberof SecReviewPort
     */
    snmp?: SecReviewSNMP;
    /**
     * 
     * @type {SecReviewManageEngine}
     * @memberof SecReviewPort
     */
    manageengine?: SecReviewManageEngine;
    /**
     * 
     * @type {Array<SecReviewTechnology>}
     * @memberof SecReviewPort
     */
    technologies?: Array<SecReviewTechnology>;
    /**
     * 
     * @type {Array<SecReviewCVE>}
     * @memberof SecReviewPort
     */
    cves?: Array<SecReviewCVE>;
}

/**
 * Check if a given object implements the SecReviewPort interface.
 */
export function instanceOfSecReviewPort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "port" in value;

    return isInstance;
}

export function SecReviewPortFromJSON(json: any): SecReviewPort {
    return SecReviewPortFromJSONTyped(json, false);
}

export function SecReviewPortFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewPort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'port': json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'service_simple': !exists(json, 'service_simple') ? undefined : SecReviewServiceSimpleFromJSON(json['service_simple']),
        'webv2': !exists(json, 'webv2') ? undefined : SecReviewWebv2FromJSON(json['webv2']),
        'web_quick': !exists(json, 'web_quick') ? undefined : SecReviewWebv2FromJSON(json['web_quick']),
        'sslv2': !exists(json, 'sslv2') ? undefined : SecReviewSSLv2FromJSON(json['sslv2']),
        'jarm': !exists(json, 'jarm') ? undefined : SecReviewJarmFromJSON(json['jarm']),
        'rdp': !exists(json, 'rdp') ? undefined : SecReviewRDPFromJSON(json['rdp']),
        'bluekeep': !exists(json, 'bluekeep') ? undefined : SecReviewBluekeepFromJSON(json['bluekeep']),
        'vnc': !exists(json, 'vnc') ? undefined : SecReviewVNCFromJSON(json['vnc']),
        'x11': !exists(json, 'x11') ? undefined : SecReviewX11FromJSON(json['x11']),
        'ssh': !exists(json, 'ssh') ? undefined : SecReviewSSHFromJSON(json['ssh']),
        'rsync': !exists(json, 'rsync') ? undefined : SecReviewRsyncFromJSON(json['rsync']),
        'ftp': !exists(json, 'ftp') ? undefined : SecReviewFTPFromJSON(json['ftp']),
        'telnet': !exists(json, 'telnet') ? undefined : SecReviewTelnetFromJSON(json['telnet']),
        'smb': !exists(json, 'smb') ? undefined : SecReviewSMBFromJSON(json['smb']),
        'kubernetes': !exists(json, 'kubernetes') ? undefined : SecReviewKubernetesFromJSON(json['kubernetes']),
        'cassandra': !exists(json, 'cassandra') ? undefined : SecReviewCassandraFromJSON(json['cassandra']),
        'elasticsearch': !exists(json, 'elasticsearch') ? undefined : SecReviewElasticSearchFromJSON(json['elasticsearch']),
        'memcached': !exists(json, 'memcached') ? undefined : SecReviewMemcachedFromJSON(json['memcached']),
        'mongodb': !exists(json, 'mongodb') ? undefined : SecReviewMongoDBFromJSON(json['mongodb']),
        'redis': !exists(json, 'redis') ? undefined : SecReviewRedisFromJSON(json['redis']),
        'mqtt': !exists(json, 'mqtt') ? undefined : SecReviewMQTTFromJSON(json['mqtt']),
        'exchange_owa': !exists(json, 'exchange_owa') ? undefined : SecReviewExchangeOWAFromJSON(json['exchange_owa']),
        'fortinet': !exists(json, 'fortinet') ? undefined : SecReviewFortinetLambdaFromJSON(json['fortinet']),
        'pulse_secure': !exists(json, 'pulse_secure') ? undefined : SecReviewPulseSecureLambdaFromJSON(json['pulse_secure']),
        'sonicwall': !exists(json, 'sonicwall') ? undefined : SecReviewSonicWallLambdaFromJSON(json['sonicwall']),
        'rdweb': !exists(json, 'rdweb') ? undefined : SecReviewRDWebLambdaFromJSON(json['rdweb']),
        'remote_powershell': !exists(json, 'remote_powershell') ? undefined : SecReviewRemotePowershellFromJSON(json['remote_powershell']),
        'snmp': !exists(json, 'snmp') ? undefined : SecReviewSNMPFromJSON(json['snmp']),
        'manageengine': !exists(json, 'manageengine') ? undefined : SecReviewManageEngineFromJSON(json['manageengine']),
        'technologies': !exists(json, 'technologies') ? undefined : ((json['technologies'] as Array<any>).map(SecReviewTechnologyFromJSON)),
        'cves': !exists(json, 'cves') ? undefined : ((json['cves'] as Array<any>).map(SecReviewCVEFromJSON)),
    };
}

export function SecReviewPortToJSON(value?: SecReviewPort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'port': value.port,
        'protocol': value.protocol,
        'product': value.product,
        'version': value.version,
        'service_simple': SecReviewServiceSimpleToJSON(value.service_simple),
        'webv2': SecReviewWebv2ToJSON(value.webv2),
        'web_quick': SecReviewWebv2ToJSON(value.web_quick),
        'sslv2': SecReviewSSLv2ToJSON(value.sslv2),
        'jarm': SecReviewJarmToJSON(value.jarm),
        'rdp': SecReviewRDPToJSON(value.rdp),
        'bluekeep': SecReviewBluekeepToJSON(value.bluekeep),
        'vnc': SecReviewVNCToJSON(value.vnc),
        'x11': SecReviewX11ToJSON(value.x11),
        'ssh': SecReviewSSHToJSON(value.ssh),
        'rsync': SecReviewRsyncToJSON(value.rsync),
        'ftp': SecReviewFTPToJSON(value.ftp),
        'telnet': SecReviewTelnetToJSON(value.telnet),
        'smb': SecReviewSMBToJSON(value.smb),
        'kubernetes': SecReviewKubernetesToJSON(value.kubernetes),
        'cassandra': SecReviewCassandraToJSON(value.cassandra),
        'elasticsearch': SecReviewElasticSearchToJSON(value.elasticsearch),
        'memcached': SecReviewMemcachedToJSON(value.memcached),
        'mongodb': SecReviewMongoDBToJSON(value.mongodb),
        'redis': SecReviewRedisToJSON(value.redis),
        'mqtt': SecReviewMQTTToJSON(value.mqtt),
        'exchange_owa': SecReviewExchangeOWAToJSON(value.exchange_owa),
        'fortinet': SecReviewFortinetLambdaToJSON(value.fortinet),
        'pulse_secure': SecReviewPulseSecureLambdaToJSON(value.pulse_secure),
        'sonicwall': SecReviewSonicWallLambdaToJSON(value.sonicwall),
        'rdweb': SecReviewRDWebLambdaToJSON(value.rdweb),
        'remote_powershell': SecReviewRemotePowershellToJSON(value.remote_powershell),
        'snmp': SecReviewSNMPToJSON(value.snmp),
        'manageengine': SecReviewManageEngineToJSON(value.manageengine),
        'technologies': value.technologies === undefined ? undefined : ((value.technologies as Array<any>).map(SecReviewTechnologyToJSON)),
        'cves': value.cves === undefined ? undefined : ((value.cves as Array<any>).map(SecReviewCVEToJSON)),
    };
}

