/**
 * appConfig
 */
export { appConfig } from './appConfig';

/**
 * appRoutes
 */
export { appRoutes } from './appRoutes';
export type { Routes } from './appRoutes';

/**
 * appMeta
 */
export { appMeta } from './appMeta';
