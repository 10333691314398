/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskEVerify } from './ExecRiskEVerify';
import {
    ExecRiskEVerifyFromJSON,
    ExecRiskEVerifyFromJSONTyped,
    ExecRiskEVerifyToJSON,
} from './ExecRiskEVerify';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedEVerifyResponse
 */
export interface ExecRiskPaginatedEVerifyResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEVerifyResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskEVerify>}
     * @memberof ExecRiskPaginatedEVerifyResponse
     */
    results: Array<ExecRiskEVerify>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedEVerifyResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEVerifyResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEVerifyResponse
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedEVerifyResponse interface.
 */
export function instanceOfExecRiskPaginatedEVerifyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedEVerifyResponseFromJSON(json: any): ExecRiskPaginatedEVerifyResponse {
    return ExecRiskPaginatedEVerifyResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedEVerifyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedEVerifyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskEVerifyFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedEVerifyResponseToJSON(value?: ExecRiskPaginatedEVerifyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskEVerifyToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

