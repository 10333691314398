/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskNativeLandsEntry
 */
export interface ExecRiskNativeLandsEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskNativeLandsEntry
     */
    tribe_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskNativeLandsEntry
     */
    full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskNativeLandsEntry
     */
    geo_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskNativeLandsEntry
     */
    area_census_code: string;
}

/**
 * Check if a given object implements the ExecRiskNativeLandsEntry interface.
 */
export function instanceOfExecRiskNativeLandsEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geo_id" in value;
    isInstance = isInstance && "area_census_code" in value;

    return isInstance;
}

export function ExecRiskNativeLandsEntryFromJSON(json: any): ExecRiskNativeLandsEntry {
    return ExecRiskNativeLandsEntryFromJSONTyped(json, false);
}

export function ExecRiskNativeLandsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskNativeLandsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tribe_name': !exists(json, 'tribe_name') ? undefined : json['tribe_name'],
        'full_name': !exists(json, 'full_name') ? undefined : json['full_name'],
        'geo_id': json['geo_id'],
        'area_census_code': json['area_census_code'],
    };
}

export function ExecRiskNativeLandsEntryToJSON(value?: ExecRiskNativeLandsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tribe_name': value.tribe_name,
        'full_name': value.full_name,
        'geo_id': value.geo_id,
        'area_census_code': value.area_census_code,
    };
}

