/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskProximityOneScan
 */
export interface ExecRiskProximityOneScan {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskProximityOneScan
     */
    postal_code: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskProximityOneScan
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskProximityOneScan
     */
    urban_pop_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskProximityOneScan
     */
    rural_pop_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskProximityOneScan
     */
    urban_pop_percentage: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskProximityOneScan
     */
    rural_pop_percentage: number;
}

/**
 * Check if a given object implements the ExecRiskProximityOneScan interface.
 */
export function instanceOfExecRiskProximityOneScan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "postal_code" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "urban_pop_count" in value;
    isInstance = isInstance && "rural_pop_count" in value;
    isInstance = isInstance && "urban_pop_percentage" in value;
    isInstance = isInstance && "rural_pop_percentage" in value;

    return isInstance;
}

export function ExecRiskProximityOneScanFromJSON(json: any): ExecRiskProximityOneScan {
    return ExecRiskProximityOneScanFromJSONTyped(json, false);
}

export function ExecRiskProximityOneScanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskProximityOneScan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postal_code': json['postal_code'],
        'state': json['state'],
        'urban_pop_count': json['urban_pop_count'],
        'rural_pop_count': json['rural_pop_count'],
        'urban_pop_percentage': json['urban_pop_percentage'],
        'rural_pop_percentage': json['rural_pop_percentage'],
    };
}

export function ExecRiskProximityOneScanToJSON(value?: ExecRiskProximityOneScan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postal_code': value.postal_code,
        'state': value.state,
        'urban_pop_count': value.urban_pop_count,
        'rural_pop_count': value.rural_pop_count,
        'urban_pop_percentage': value.urban_pop_percentage,
        'rural_pop_percentage': value.rural_pop_percentage,
    };
}

