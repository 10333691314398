import React from 'react';
import clsx from 'clsx';
import MuiListItem, {
  ListItemProps as MuiListItemProps,
} from '@mui/material/ListItem';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const ListItem = withCoreProps<ListItemProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<ListItemProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiListItem {...props} className={clsxClass}>
        {children}
      </MuiListItem>
    );
  },
);

export interface ListItemProps
  extends Omit<MuiListItemProps, 'button'>,
    CoreProps {}
