import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ComputerReplacementIcon
 */
export const ComputerReplacementIcon = ({
  ...props
}: ComputerReplacementIconProps) => {
  return (
    <Icon label="computer_replacement" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8,18.5c0.1-0.5,0.4-0.9,0.6-1.3H8.7c-0.2,0-0.3-0.1-0.3-0.3V9.5c0-0.2,0.1-0.3,0.3-0.3h12c0.2,0,0.3,0.1,0.3,0.3v5.6c0.4-0.2,0.9-0.3,1.3-0.3V9.2c0-0.7-0.6-1.3-1.3-1.3H8.3C7.6,7.8,7,8.4,7,9.2v8c0,0.7,0.6,1.3,1.3,1.3H17.8z M17.1,10.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.2,0.2,0.2,0.3v1.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-1.6c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.2,0.1-0.3l0.4-0.4c-0.3-0.3-0.7-0.4-1.1-0.4c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.3-0.4,0.6c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.3,0.6,0.4c0.5,0.2,1,0.2,1.4,0c0.2-0.1,0.4-0.3,0.6-0.5c0,0,0.1-0.1,0.1-0.1h0c0.1,0,0.1,0,0.1,0l0.5,0.5c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c-0.3,0.3-0.6,0.6-1,0.7c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.5-0.5-0.6-0.9C12.1,14,12,13.6,12,13.3c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.5-0.5,0.9-0.6c0.7-0.3,1.4-0.3,2.1,0c0.3,0.1,0.6,0.3,0.8,0.5L17.1,10.8z M9.3,19.5h8.4c0,0.2,0,0.4,0,0.6c0,1.5,0.6,2.8,1.5,3.7H8.4c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.1-0.6l0.8-3C8.7,19.7,9,19.5,9.3,19.5z M23.6,20.7H25c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-1.3v-1.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v1.3H21c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.3v1.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V20.7z M18.9,20.1c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C20.7,24.1,18.9,22.3,18.9,20.1z"
        />
      </g>
    </Icon>
  );
};

/**
 * ComputerReplacementIcon Props
 */
export interface ComputerReplacementIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
