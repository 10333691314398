import React from 'react';
import MuiTabContext, {
  TabContextProps as MuiTabContextProps,
} from '@mui/lab/TabContext';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const TabContext = withCoreProps<TabContextProps>(
  ({ children, ...props }: React.PropsWithChildren<TabContextProps>) => {
    return <MuiTabContext {...props}>{children}</MuiTabContext>;
  },
);

export interface TabContextProps extends MuiTabContextProps, CoreProps {}
