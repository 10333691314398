/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskScheduleCDef } from './ExecRiskScheduleCDef';
import {
    ExecRiskScheduleCDefFromJSON,
    ExecRiskScheduleCDefFromJSONTyped,
    ExecRiskScheduleCDefToJSON,
} from './ExecRiskScheduleCDef';
import type { ExecRiskScheduleHDef } from './ExecRiskScheduleHDef';
import {
    ExecRiskScheduleHDefFromJSON,
    ExecRiskScheduleHDefFromJSONTyped,
    ExecRiskScheduleHDefToJSON,
} from './ExecRiskScheduleHDef';
import type { ExecRiskScheduleIDef } from './ExecRiskScheduleIDef';
import {
    ExecRiskScheduleIDefFromJSON,
    ExecRiskScheduleIDefFromJSONTyped,
    ExecRiskScheduleIDefToJSON,
} from './ExecRiskScheduleIDef';
import type { ExecRiskScheduleRDef } from './ExecRiskScheduleRDef';
import {
    ExecRiskScheduleRDefFromJSON,
    ExecRiskScheduleRDefFromJSONTyped,
    ExecRiskScheduleRDefToJSON,
} from './ExecRiskScheduleRDef';
import type { ExecRiskScheduleSBDef } from './ExecRiskScheduleSBDef';
import {
    ExecRiskScheduleSBDefFromJSON,
    ExecRiskScheduleSBDefFromJSONTyped,
    ExecRiskScheduleSBDefToJSON,
} from './ExecRiskScheduleSBDef';

/**
 * 
 * @export
 * @interface ExecRiskLongForm5500
 */
export interface ExecRiskLongForm5500 {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    form_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    ack_id_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    form_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    form_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    type_plan_entity_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    type_dfe_plan_entity_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    initial_filing_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    amended_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    final_filing_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    short_plan_yr_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    collective_bargain_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    f5558_application_filed_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    ext_automatic_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    dfvc_program_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    ext_special_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    ext_special_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_pn?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    plan_eff_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sponsor_dfe_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_dba_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_care_of_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_foreign_addr1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_foreign_addr2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_forgn_prov_st?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_mail_forgn_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_forgn_prov_st?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_loc_forgn_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    business_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_care_of_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    last_rpt_spons_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    last_rpt_spons_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    last_rpt_plan_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    dfe_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    dfe_signed_name?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    tot_partcp_boy_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    tot_active_partcp_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    rtd_sep_partcp_rcvg_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    rtd_sep_partcp_fut_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    subtl_act_rtd_sep_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    benef_rcvg_bnft_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    tot_act_rtd_sep_benef_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    partcp_account_bal_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    sep_partcp_partl_vstd_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    contrib_emplrs_cnt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    type_pension_bnft_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    type_welfare_bnft_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    funding_insurance_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    funding_sec412_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    funding_trust_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    funding_gen_asset_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    benefit_insurance_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    benefit_sec412_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    benefit_trust_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    benefit_gen_asset_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_r_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_mb_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_sb_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_h_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_i_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_a_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    num_sch_a_attached_cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_c_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_d_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    sch_g_attached_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    filing_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    date_received?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    valid_admin_signature?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    valid_dfe_signature?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    valid_sponsor_signature?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_dfe_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_name_same_spon_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_address_same_spon_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_firm_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    preparer_phone_num_foreign?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLongForm5500
     */
    tot_act_partcp_boy_cnt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    subj_m1_filing_req_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    compliance_m1_filing_req_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    m1_receipt_confirmation_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_manual_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    admin_manual_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    last_rpt_plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_manual_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    spons_manual_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    dfe_manual_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    dfe_manual_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    adopted_plan_perm_sec_act?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    normalized_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLongForm5500
     */
    zipcode_plus_four?: string;
    /**
     * 
     * @type {ExecRiskScheduleCDef}
     * @memberof ExecRiskLongForm5500
     */
    schedule_c?: ExecRiskScheduleCDef;
    /**
     * 
     * @type {ExecRiskScheduleHDef}
     * @memberof ExecRiskLongForm5500
     */
    schedule_h?: ExecRiskScheduleHDef;
    /**
     * 
     * @type {ExecRiskScheduleIDef}
     * @memberof ExecRiskLongForm5500
     */
    schedule_i?: ExecRiskScheduleIDef;
    /**
     * 
     * @type {ExecRiskScheduleRDef}
     * @memberof ExecRiskLongForm5500
     */
    schedule_r?: ExecRiskScheduleRDef;
    /**
     * 
     * @type {ExecRiskScheduleSBDef}
     * @memberof ExecRiskLongForm5500
     */
    schedule_sb?: ExecRiskScheduleSBDef;
}

/**
 * Check if a given object implements the ExecRiskLongForm5500 interface.
 */
export function instanceOfExecRiskLongForm5500(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskLongForm5500FromJSON(json: any): ExecRiskLongForm5500 {
    return ExecRiskLongForm5500FromJSONTyped(json, false);
}

export function ExecRiskLongForm5500FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskLongForm5500 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_type': !exists(json, 'form_type') ? undefined : json['form_type'],
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'ack_id_uuid': !exists(json, 'ack_id_uuid') ? undefined : json['ack_id_uuid'],
        'form_plan_year_begin_date': !exists(json, 'form_plan_year_begin_date') ? undefined : json['form_plan_year_begin_date'],
        'form_tax_prd': !exists(json, 'form_tax_prd') ? undefined : json['form_tax_prd'],
        'type_plan_entity_cd': !exists(json, 'type_plan_entity_cd') ? undefined : json['type_plan_entity_cd'],
        'type_dfe_plan_entity_cd': !exists(json, 'type_dfe_plan_entity_cd') ? undefined : json['type_dfe_plan_entity_cd'],
        'initial_filing_ind': !exists(json, 'initial_filing_ind') ? undefined : json['initial_filing_ind'],
        'amended_ind': !exists(json, 'amended_ind') ? undefined : json['amended_ind'],
        'final_filing_ind': !exists(json, 'final_filing_ind') ? undefined : json['final_filing_ind'],
        'short_plan_yr_ind': !exists(json, 'short_plan_yr_ind') ? undefined : json['short_plan_yr_ind'],
        'collective_bargain_ind': !exists(json, 'collective_bargain_ind') ? undefined : json['collective_bargain_ind'],
        'f5558_application_filed_ind': !exists(json, 'f5558_application_filed_ind') ? undefined : json['f5558_application_filed_ind'],
        'ext_automatic_ind': !exists(json, 'ext_automatic_ind') ? undefined : json['ext_automatic_ind'],
        'dfvc_program_ind': !exists(json, 'dfvc_program_ind') ? undefined : json['dfvc_program_ind'],
        'ext_special_ind': !exists(json, 'ext_special_ind') ? undefined : json['ext_special_ind'],
        'ext_special_text': !exists(json, 'ext_special_text') ? undefined : json['ext_special_text'],
        'plan_name': !exists(json, 'plan_name') ? undefined : json['plan_name'],
        'spons_dfe_pn': !exists(json, 'spons_dfe_pn') ? undefined : json['spons_dfe_pn'],
        'plan_eff_date': !exists(json, 'plan_eff_date') ? undefined : json['plan_eff_date'],
        'sponsor_dfe_name': !exists(json, 'sponsor_dfe_name') ? undefined : json['sponsor_dfe_name'],
        'spons_dfe_dba_name': !exists(json, 'spons_dfe_dba_name') ? undefined : json['spons_dfe_dba_name'],
        'spons_dfe_care_of_name': !exists(json, 'spons_dfe_care_of_name') ? undefined : json['spons_dfe_care_of_name'],
        'spons_dfe_mail_us_address1': !exists(json, 'spons_dfe_mail_us_address1') ? undefined : json['spons_dfe_mail_us_address1'],
        'spons_dfe_mail_us_address2': !exists(json, 'spons_dfe_mail_us_address2') ? undefined : json['spons_dfe_mail_us_address2'],
        'spons_dfe_mail_us_city': !exists(json, 'spons_dfe_mail_us_city') ? undefined : json['spons_dfe_mail_us_city'],
        'spons_dfe_mail_us_state': !exists(json, 'spons_dfe_mail_us_state') ? undefined : json['spons_dfe_mail_us_state'],
        'spons_dfe_mail_us_zip': !exists(json, 'spons_dfe_mail_us_zip') ? undefined : json['spons_dfe_mail_us_zip'],
        'spons_dfe_mail_foreign_addr1': !exists(json, 'spons_dfe_mail_foreign_addr1') ? undefined : json['spons_dfe_mail_foreign_addr1'],
        'spons_dfe_mail_foreign_addr2': !exists(json, 'spons_dfe_mail_foreign_addr2') ? undefined : json['spons_dfe_mail_foreign_addr2'],
        'spons_dfe_mail_foreign_city': !exists(json, 'spons_dfe_mail_foreign_city') ? undefined : json['spons_dfe_mail_foreign_city'],
        'spons_dfe_mail_forgn_prov_st': !exists(json, 'spons_dfe_mail_forgn_prov_st') ? undefined : json['spons_dfe_mail_forgn_prov_st'],
        'spons_dfe_mail_foreign_cntry': !exists(json, 'spons_dfe_mail_foreign_cntry') ? undefined : json['spons_dfe_mail_foreign_cntry'],
        'spons_dfe_mail_forgn_postal_cd': !exists(json, 'spons_dfe_mail_forgn_postal_cd') ? undefined : json['spons_dfe_mail_forgn_postal_cd'],
        'spons_dfe_loc_us_address1': !exists(json, 'spons_dfe_loc_us_address1') ? undefined : json['spons_dfe_loc_us_address1'],
        'spons_dfe_loc_us_address2': !exists(json, 'spons_dfe_loc_us_address2') ? undefined : json['spons_dfe_loc_us_address2'],
        'spons_dfe_loc_us_city': !exists(json, 'spons_dfe_loc_us_city') ? undefined : json['spons_dfe_loc_us_city'],
        'spons_dfe_loc_us_state': !exists(json, 'spons_dfe_loc_us_state') ? undefined : json['spons_dfe_loc_us_state'],
        'spons_dfe_loc_us_zip': !exists(json, 'spons_dfe_loc_us_zip') ? undefined : json['spons_dfe_loc_us_zip'],
        'spons_dfe_loc_foreign_address1': !exists(json, 'spons_dfe_loc_foreign_address1') ? undefined : json['spons_dfe_loc_foreign_address1'],
        'spons_dfe_loc_foreign_address2': !exists(json, 'spons_dfe_loc_foreign_address2') ? undefined : json['spons_dfe_loc_foreign_address2'],
        'spons_dfe_loc_foreign_city': !exists(json, 'spons_dfe_loc_foreign_city') ? undefined : json['spons_dfe_loc_foreign_city'],
        'spons_dfe_loc_forgn_prov_st': !exists(json, 'spons_dfe_loc_forgn_prov_st') ? undefined : json['spons_dfe_loc_forgn_prov_st'],
        'spons_dfe_loc_foreign_cntry': !exists(json, 'spons_dfe_loc_foreign_cntry') ? undefined : json['spons_dfe_loc_foreign_cntry'],
        'spons_dfe_loc_forgn_postal_cd': !exists(json, 'spons_dfe_loc_forgn_postal_cd') ? undefined : json['spons_dfe_loc_forgn_postal_cd'],
        'spons_dfe_ein': !exists(json, 'spons_dfe_ein') ? undefined : json['spons_dfe_ein'],
        'spons_dfe_phone_num': !exists(json, 'spons_dfe_phone_num') ? undefined : json['spons_dfe_phone_num'],
        'business_code': !exists(json, 'business_code') ? undefined : json['business_code'],
        'admin_name': !exists(json, 'admin_name') ? undefined : json['admin_name'],
        'admin_care_of_name': !exists(json, 'admin_care_of_name') ? undefined : json['admin_care_of_name'],
        'admin_us_address1': !exists(json, 'admin_us_address1') ? undefined : json['admin_us_address1'],
        'admin_us_address2': !exists(json, 'admin_us_address2') ? undefined : json['admin_us_address2'],
        'admin_us_city': !exists(json, 'admin_us_city') ? undefined : json['admin_us_city'],
        'admin_us_state': !exists(json, 'admin_us_state') ? undefined : json['admin_us_state'],
        'admin_us_zip': !exists(json, 'admin_us_zip') ? undefined : json['admin_us_zip'],
        'admin_foreign_address1': !exists(json, 'admin_foreign_address1') ? undefined : json['admin_foreign_address1'],
        'admin_foreign_address2': !exists(json, 'admin_foreign_address2') ? undefined : json['admin_foreign_address2'],
        'admin_foreign_city': !exists(json, 'admin_foreign_city') ? undefined : json['admin_foreign_city'],
        'admin_foreign_prov_state': !exists(json, 'admin_foreign_prov_state') ? undefined : json['admin_foreign_prov_state'],
        'admin_foreign_cntry': !exists(json, 'admin_foreign_cntry') ? undefined : json['admin_foreign_cntry'],
        'admin_foreign_postal_cd': !exists(json, 'admin_foreign_postal_cd') ? undefined : json['admin_foreign_postal_cd'],
        'admin_ein': !exists(json, 'admin_ein') ? undefined : json['admin_ein'],
        'admin_phone_num': !exists(json, 'admin_phone_num') ? undefined : json['admin_phone_num'],
        'last_rpt_spons_name': !exists(json, 'last_rpt_spons_name') ? undefined : json['last_rpt_spons_name'],
        'last_rpt_spons_ein': !exists(json, 'last_rpt_spons_ein') ? undefined : json['last_rpt_spons_ein'],
        'last_rpt_plan_num': !exists(json, 'last_rpt_plan_num') ? undefined : json['last_rpt_plan_num'],
        'admin_signed_date': !exists(json, 'admin_signed_date') ? undefined : json['admin_signed_date'],
        'admin_signed_name': !exists(json, 'admin_signed_name') ? undefined : json['admin_signed_name'],
        'spons_signed_date': !exists(json, 'spons_signed_date') ? undefined : json['spons_signed_date'],
        'spons_signed_name': !exists(json, 'spons_signed_name') ? undefined : json['spons_signed_name'],
        'dfe_signed_date': !exists(json, 'dfe_signed_date') ? undefined : json['dfe_signed_date'],
        'dfe_signed_name': !exists(json, 'dfe_signed_name') ? undefined : json['dfe_signed_name'],
        'tot_partcp_boy_cnt': !exists(json, 'tot_partcp_boy_cnt') ? undefined : json['tot_partcp_boy_cnt'],
        'tot_active_partcp_cnt': !exists(json, 'tot_active_partcp_cnt') ? undefined : json['tot_active_partcp_cnt'],
        'rtd_sep_partcp_rcvg_cnt': !exists(json, 'rtd_sep_partcp_rcvg_cnt') ? undefined : json['rtd_sep_partcp_rcvg_cnt'],
        'rtd_sep_partcp_fut_cnt': !exists(json, 'rtd_sep_partcp_fut_cnt') ? undefined : json['rtd_sep_partcp_fut_cnt'],
        'subtl_act_rtd_sep_cnt': !exists(json, 'subtl_act_rtd_sep_cnt') ? undefined : json['subtl_act_rtd_sep_cnt'],
        'benef_rcvg_bnft_cnt': !exists(json, 'benef_rcvg_bnft_cnt') ? undefined : json['benef_rcvg_bnft_cnt'],
        'tot_act_rtd_sep_benef_cnt': !exists(json, 'tot_act_rtd_sep_benef_cnt') ? undefined : json['tot_act_rtd_sep_benef_cnt'],
        'partcp_account_bal_cnt': !exists(json, 'partcp_account_bal_cnt') ? undefined : json['partcp_account_bal_cnt'],
        'sep_partcp_partl_vstd_cnt': !exists(json, 'sep_partcp_partl_vstd_cnt') ? undefined : json['sep_partcp_partl_vstd_cnt'],
        'contrib_emplrs_cnt': !exists(json, 'contrib_emplrs_cnt') ? undefined : json['contrib_emplrs_cnt'],
        'type_pension_bnft_code': !exists(json, 'type_pension_bnft_code') ? undefined : json['type_pension_bnft_code'],
        'type_welfare_bnft_code': !exists(json, 'type_welfare_bnft_code') ? undefined : json['type_welfare_bnft_code'],
        'funding_insurance_ind': !exists(json, 'funding_insurance_ind') ? undefined : json['funding_insurance_ind'],
        'funding_sec412_ind': !exists(json, 'funding_sec412_ind') ? undefined : json['funding_sec412_ind'],
        'funding_trust_ind': !exists(json, 'funding_trust_ind') ? undefined : json['funding_trust_ind'],
        'funding_gen_asset_ind': !exists(json, 'funding_gen_asset_ind') ? undefined : json['funding_gen_asset_ind'],
        'benefit_insurance_ind': !exists(json, 'benefit_insurance_ind') ? undefined : json['benefit_insurance_ind'],
        'benefit_sec412_ind': !exists(json, 'benefit_sec412_ind') ? undefined : json['benefit_sec412_ind'],
        'benefit_trust_ind': !exists(json, 'benefit_trust_ind') ? undefined : json['benefit_trust_ind'],
        'benefit_gen_asset_ind': !exists(json, 'benefit_gen_asset_ind') ? undefined : json['benefit_gen_asset_ind'],
        'sch_r_attached_ind': !exists(json, 'sch_r_attached_ind') ? undefined : json['sch_r_attached_ind'],
        'sch_mb_attached_ind': !exists(json, 'sch_mb_attached_ind') ? undefined : json['sch_mb_attached_ind'],
        'sch_sb_attached_ind': !exists(json, 'sch_sb_attached_ind') ? undefined : json['sch_sb_attached_ind'],
        'sch_h_attached_ind': !exists(json, 'sch_h_attached_ind') ? undefined : json['sch_h_attached_ind'],
        'sch_i_attached_ind': !exists(json, 'sch_i_attached_ind') ? undefined : json['sch_i_attached_ind'],
        'sch_a_attached_ind': !exists(json, 'sch_a_attached_ind') ? undefined : json['sch_a_attached_ind'],
        'num_sch_a_attached_cnt': !exists(json, 'num_sch_a_attached_cnt') ? undefined : json['num_sch_a_attached_cnt'],
        'sch_c_attached_ind': !exists(json, 'sch_c_attached_ind') ? undefined : json['sch_c_attached_ind'],
        'sch_d_attached_ind': !exists(json, 'sch_d_attached_ind') ? undefined : json['sch_d_attached_ind'],
        'sch_g_attached_ind': !exists(json, 'sch_g_attached_ind') ? undefined : json['sch_g_attached_ind'],
        'filing_status': !exists(json, 'filing_status') ? undefined : json['filing_status'],
        'date_received': !exists(json, 'date_received') ? undefined : json['date_received'],
        'valid_admin_signature': !exists(json, 'valid_admin_signature') ? undefined : json['valid_admin_signature'],
        'valid_dfe_signature': !exists(json, 'valid_dfe_signature') ? undefined : json['valid_dfe_signature'],
        'valid_sponsor_signature': !exists(json, 'valid_sponsor_signature') ? undefined : json['valid_sponsor_signature'],
        'admin_phone_num_foreign': !exists(json, 'admin_phone_num_foreign') ? undefined : json['admin_phone_num_foreign'],
        'spons_dfe_phone_num_foreign': !exists(json, 'spons_dfe_phone_num_foreign') ? undefined : json['spons_dfe_phone_num_foreign'],
        'admin_name_same_spon_ind': !exists(json, 'admin_name_same_spon_ind') ? undefined : json['admin_name_same_spon_ind'],
        'admin_address_same_spon_ind': !exists(json, 'admin_address_same_spon_ind') ? undefined : json['admin_address_same_spon_ind'],
        'preparer_name': !exists(json, 'preparer_name') ? undefined : json['preparer_name'],
        'preparer_firm_name': !exists(json, 'preparer_firm_name') ? undefined : json['preparer_firm_name'],
        'preparer_us_address1': !exists(json, 'preparer_us_address1') ? undefined : json['preparer_us_address1'],
        'preparer_us_address2': !exists(json, 'preparer_us_address2') ? undefined : json['preparer_us_address2'],
        'preparer_us_city': !exists(json, 'preparer_us_city') ? undefined : json['preparer_us_city'],
        'preparer_us_state': !exists(json, 'preparer_us_state') ? undefined : json['preparer_us_state'],
        'preparer_us_zip': !exists(json, 'preparer_us_zip') ? undefined : json['preparer_us_zip'],
        'preparer_foreign_address1': !exists(json, 'preparer_foreign_address1') ? undefined : json['preparer_foreign_address1'],
        'preparer_foreign_address2': !exists(json, 'preparer_foreign_address2') ? undefined : json['preparer_foreign_address2'],
        'preparer_foreign_city': !exists(json, 'preparer_foreign_city') ? undefined : json['preparer_foreign_city'],
        'preparer_foreign_prov_state': !exists(json, 'preparer_foreign_prov_state') ? undefined : json['preparer_foreign_prov_state'],
        'preparer_foreign_cntry': !exists(json, 'preparer_foreign_cntry') ? undefined : json['preparer_foreign_cntry'],
        'preparer_foreign_postal_cd': !exists(json, 'preparer_foreign_postal_cd') ? undefined : json['preparer_foreign_postal_cd'],
        'preparer_phone_num': !exists(json, 'preparer_phone_num') ? undefined : json['preparer_phone_num'],
        'preparer_phone_num_foreign': !exists(json, 'preparer_phone_num_foreign') ? undefined : json['preparer_phone_num_foreign'],
        'tot_act_partcp_boy_cnt': !exists(json, 'tot_act_partcp_boy_cnt') ? undefined : json['tot_act_partcp_boy_cnt'],
        'subj_m1_filing_req_ind': !exists(json, 'subj_m1_filing_req_ind') ? undefined : json['subj_m1_filing_req_ind'],
        'compliance_m1_filing_req_ind': !exists(json, 'compliance_m1_filing_req_ind') ? undefined : json['compliance_m1_filing_req_ind'],
        'm1_receipt_confirmation_code': !exists(json, 'm1_receipt_confirmation_code') ? undefined : json['m1_receipt_confirmation_code'],
        'admin_manual_signed_date': !exists(json, 'admin_manual_signed_date') ? undefined : json['admin_manual_signed_date'],
        'admin_manual_signed_name': !exists(json, 'admin_manual_signed_name') ? undefined : json['admin_manual_signed_name'],
        'last_rpt_plan_name': !exists(json, 'last_rpt_plan_name') ? undefined : json['last_rpt_plan_name'],
        'spons_manual_signed_date': !exists(json, 'spons_manual_signed_date') ? undefined : json['spons_manual_signed_date'],
        'spons_manual_signed_name': !exists(json, 'spons_manual_signed_name') ? undefined : json['spons_manual_signed_name'],
        'dfe_manual_signed_date': !exists(json, 'dfe_manual_signed_date') ? undefined : json['dfe_manual_signed_date'],
        'dfe_manual_signed_name': !exists(json, 'dfe_manual_signed_name') ? undefined : json['dfe_manual_signed_name'],
        'adopted_plan_perm_sec_act': !exists(json, 'adopted_plan_perm_sec_act') ? undefined : json['adopted_plan_perm_sec_act'],
        'normalized_name': !exists(json, 'normalized_name') ? undefined : json['normalized_name'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'zipcode_plus_four': !exists(json, 'zipcode_plus_four') ? undefined : json['zipcode_plus_four'],
        'schedule_c': !exists(json, 'schedule_c') ? undefined : ExecRiskScheduleCDefFromJSON(json['schedule_c']),
        'schedule_h': !exists(json, 'schedule_h') ? undefined : ExecRiskScheduleHDefFromJSON(json['schedule_h']),
        'schedule_i': !exists(json, 'schedule_i') ? undefined : ExecRiskScheduleIDefFromJSON(json['schedule_i']),
        'schedule_r': !exists(json, 'schedule_r') ? undefined : ExecRiskScheduleRDefFromJSON(json['schedule_r']),
        'schedule_sb': !exists(json, 'schedule_sb') ? undefined : ExecRiskScheduleSBDefFromJSON(json['schedule_sb']),
    };
}

export function ExecRiskLongForm5500ToJSON(value?: ExecRiskLongForm5500 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_type': value.form_type,
        'ack_id': value.ack_id,
        'ack_id_uuid': value.ack_id_uuid,
        'form_plan_year_begin_date': value.form_plan_year_begin_date,
        'form_tax_prd': value.form_tax_prd,
        'type_plan_entity_cd': value.type_plan_entity_cd,
        'type_dfe_plan_entity_cd': value.type_dfe_plan_entity_cd,
        'initial_filing_ind': value.initial_filing_ind,
        'amended_ind': value.amended_ind,
        'final_filing_ind': value.final_filing_ind,
        'short_plan_yr_ind': value.short_plan_yr_ind,
        'collective_bargain_ind': value.collective_bargain_ind,
        'f5558_application_filed_ind': value.f5558_application_filed_ind,
        'ext_automatic_ind': value.ext_automatic_ind,
        'dfvc_program_ind': value.dfvc_program_ind,
        'ext_special_ind': value.ext_special_ind,
        'ext_special_text': value.ext_special_text,
        'plan_name': value.plan_name,
        'spons_dfe_pn': value.spons_dfe_pn,
        'plan_eff_date': value.plan_eff_date,
        'sponsor_dfe_name': value.sponsor_dfe_name,
        'spons_dfe_dba_name': value.spons_dfe_dba_name,
        'spons_dfe_care_of_name': value.spons_dfe_care_of_name,
        'spons_dfe_mail_us_address1': value.spons_dfe_mail_us_address1,
        'spons_dfe_mail_us_address2': value.spons_dfe_mail_us_address2,
        'spons_dfe_mail_us_city': value.spons_dfe_mail_us_city,
        'spons_dfe_mail_us_state': value.spons_dfe_mail_us_state,
        'spons_dfe_mail_us_zip': value.spons_dfe_mail_us_zip,
        'spons_dfe_mail_foreign_addr1': value.spons_dfe_mail_foreign_addr1,
        'spons_dfe_mail_foreign_addr2': value.spons_dfe_mail_foreign_addr2,
        'spons_dfe_mail_foreign_city': value.spons_dfe_mail_foreign_city,
        'spons_dfe_mail_forgn_prov_st': value.spons_dfe_mail_forgn_prov_st,
        'spons_dfe_mail_foreign_cntry': value.spons_dfe_mail_foreign_cntry,
        'spons_dfe_mail_forgn_postal_cd': value.spons_dfe_mail_forgn_postal_cd,
        'spons_dfe_loc_us_address1': value.spons_dfe_loc_us_address1,
        'spons_dfe_loc_us_address2': value.spons_dfe_loc_us_address2,
        'spons_dfe_loc_us_city': value.spons_dfe_loc_us_city,
        'spons_dfe_loc_us_state': value.spons_dfe_loc_us_state,
        'spons_dfe_loc_us_zip': value.spons_dfe_loc_us_zip,
        'spons_dfe_loc_foreign_address1': value.spons_dfe_loc_foreign_address1,
        'spons_dfe_loc_foreign_address2': value.spons_dfe_loc_foreign_address2,
        'spons_dfe_loc_foreign_city': value.spons_dfe_loc_foreign_city,
        'spons_dfe_loc_forgn_prov_st': value.spons_dfe_loc_forgn_prov_st,
        'spons_dfe_loc_foreign_cntry': value.spons_dfe_loc_foreign_cntry,
        'spons_dfe_loc_forgn_postal_cd': value.spons_dfe_loc_forgn_postal_cd,
        'spons_dfe_ein': value.spons_dfe_ein,
        'spons_dfe_phone_num': value.spons_dfe_phone_num,
        'business_code': value.business_code,
        'admin_name': value.admin_name,
        'admin_care_of_name': value.admin_care_of_name,
        'admin_us_address1': value.admin_us_address1,
        'admin_us_address2': value.admin_us_address2,
        'admin_us_city': value.admin_us_city,
        'admin_us_state': value.admin_us_state,
        'admin_us_zip': value.admin_us_zip,
        'admin_foreign_address1': value.admin_foreign_address1,
        'admin_foreign_address2': value.admin_foreign_address2,
        'admin_foreign_city': value.admin_foreign_city,
        'admin_foreign_prov_state': value.admin_foreign_prov_state,
        'admin_foreign_cntry': value.admin_foreign_cntry,
        'admin_foreign_postal_cd': value.admin_foreign_postal_cd,
        'admin_ein': value.admin_ein,
        'admin_phone_num': value.admin_phone_num,
        'last_rpt_spons_name': value.last_rpt_spons_name,
        'last_rpt_spons_ein': value.last_rpt_spons_ein,
        'last_rpt_plan_num': value.last_rpt_plan_num,
        'admin_signed_date': value.admin_signed_date,
        'admin_signed_name': value.admin_signed_name,
        'spons_signed_date': value.spons_signed_date,
        'spons_signed_name': value.spons_signed_name,
        'dfe_signed_date': value.dfe_signed_date,
        'dfe_signed_name': value.dfe_signed_name,
        'tot_partcp_boy_cnt': value.tot_partcp_boy_cnt,
        'tot_active_partcp_cnt': value.tot_active_partcp_cnt,
        'rtd_sep_partcp_rcvg_cnt': value.rtd_sep_partcp_rcvg_cnt,
        'rtd_sep_partcp_fut_cnt': value.rtd_sep_partcp_fut_cnt,
        'subtl_act_rtd_sep_cnt': value.subtl_act_rtd_sep_cnt,
        'benef_rcvg_bnft_cnt': value.benef_rcvg_bnft_cnt,
        'tot_act_rtd_sep_benef_cnt': value.tot_act_rtd_sep_benef_cnt,
        'partcp_account_bal_cnt': value.partcp_account_bal_cnt,
        'sep_partcp_partl_vstd_cnt': value.sep_partcp_partl_vstd_cnt,
        'contrib_emplrs_cnt': value.contrib_emplrs_cnt,
        'type_pension_bnft_code': value.type_pension_bnft_code,
        'type_welfare_bnft_code': value.type_welfare_bnft_code,
        'funding_insurance_ind': value.funding_insurance_ind,
        'funding_sec412_ind': value.funding_sec412_ind,
        'funding_trust_ind': value.funding_trust_ind,
        'funding_gen_asset_ind': value.funding_gen_asset_ind,
        'benefit_insurance_ind': value.benefit_insurance_ind,
        'benefit_sec412_ind': value.benefit_sec412_ind,
        'benefit_trust_ind': value.benefit_trust_ind,
        'benefit_gen_asset_ind': value.benefit_gen_asset_ind,
        'sch_r_attached_ind': value.sch_r_attached_ind,
        'sch_mb_attached_ind': value.sch_mb_attached_ind,
        'sch_sb_attached_ind': value.sch_sb_attached_ind,
        'sch_h_attached_ind': value.sch_h_attached_ind,
        'sch_i_attached_ind': value.sch_i_attached_ind,
        'sch_a_attached_ind': value.sch_a_attached_ind,
        'num_sch_a_attached_cnt': value.num_sch_a_attached_cnt,
        'sch_c_attached_ind': value.sch_c_attached_ind,
        'sch_d_attached_ind': value.sch_d_attached_ind,
        'sch_g_attached_ind': value.sch_g_attached_ind,
        'filing_status': value.filing_status,
        'date_received': value.date_received,
        'valid_admin_signature': value.valid_admin_signature,
        'valid_dfe_signature': value.valid_dfe_signature,
        'valid_sponsor_signature': value.valid_sponsor_signature,
        'admin_phone_num_foreign': value.admin_phone_num_foreign,
        'spons_dfe_phone_num_foreign': value.spons_dfe_phone_num_foreign,
        'admin_name_same_spon_ind': value.admin_name_same_spon_ind,
        'admin_address_same_spon_ind': value.admin_address_same_spon_ind,
        'preparer_name': value.preparer_name,
        'preparer_firm_name': value.preparer_firm_name,
        'preparer_us_address1': value.preparer_us_address1,
        'preparer_us_address2': value.preparer_us_address2,
        'preparer_us_city': value.preparer_us_city,
        'preparer_us_state': value.preparer_us_state,
        'preparer_us_zip': value.preparer_us_zip,
        'preparer_foreign_address1': value.preparer_foreign_address1,
        'preparer_foreign_address2': value.preparer_foreign_address2,
        'preparer_foreign_city': value.preparer_foreign_city,
        'preparer_foreign_prov_state': value.preparer_foreign_prov_state,
        'preparer_foreign_cntry': value.preparer_foreign_cntry,
        'preparer_foreign_postal_cd': value.preparer_foreign_postal_cd,
        'preparer_phone_num': value.preparer_phone_num,
        'preparer_phone_num_foreign': value.preparer_phone_num_foreign,
        'tot_act_partcp_boy_cnt': value.tot_act_partcp_boy_cnt,
        'subj_m1_filing_req_ind': value.subj_m1_filing_req_ind,
        'compliance_m1_filing_req_ind': value.compliance_m1_filing_req_ind,
        'm1_receipt_confirmation_code': value.m1_receipt_confirmation_code,
        'admin_manual_signed_date': value.admin_manual_signed_date,
        'admin_manual_signed_name': value.admin_manual_signed_name,
        'last_rpt_plan_name': value.last_rpt_plan_name,
        'spons_manual_signed_date': value.spons_manual_signed_date,
        'spons_manual_signed_name': value.spons_manual_signed_name,
        'dfe_manual_signed_date': value.dfe_manual_signed_date,
        'dfe_manual_signed_name': value.dfe_manual_signed_name,
        'adopted_plan_perm_sec_act': value.adopted_plan_perm_sec_act,
        'normalized_name': value.normalized_name,
        'zipcode': value.zipcode,
        'zipcode_plus_four': value.zipcode_plus_four,
        'schedule_c': ExecRiskScheduleCDefToJSON(value.schedule_c),
        'schedule_h': ExecRiskScheduleHDefToJSON(value.schedule_h),
        'schedule_i': ExecRiskScheduleIDefToJSON(value.schedule_i),
        'schedule_r': ExecRiskScheduleRDefToJSON(value.schedule_r),
        'schedule_sb': ExecRiskScheduleSBDefToJSON(value.schedule_sb),
    };
}

