/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAttestation } from './ControlAttestation';
import {
    ControlAttestationFromJSON,
    ControlAttestationFromJSONTyped,
    ControlAttestationToJSON,
} from './ControlAttestation';
import type { ControlCategory } from './ControlCategory';
import {
    ControlCategoryFromJSON,
    ControlCategoryFromJSONTyped,
    ControlCategoryToJSON,
} from './ControlCategory';
import type { ControlThreat } from './ControlThreat';
import {
    ControlThreatFromJSON,
    ControlThreatFromJSONTyped,
    ControlThreatToJSON,
} from './ControlThreat';
import type { ControlTier } from './ControlTier';
import {
    ControlTierFromJSON,
    ControlTierFromJSONTyped,
    ControlTierToJSON,
} from './ControlTier';
import type { ControlVendor } from './ControlVendor';
import {
    ControlVendorFromJSON,
    ControlVendorFromJSONTyped,
    ControlVendorToJSON,
} from './ControlVendor';

/**
 * This class represents a task definition, not including client specific information
 * @export
 * @interface ControlTaskDefinition
 */
export interface ControlTaskDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDefinition
     */
    title: string;
    /**
     * 
     * @type {ControlCategory}
     * @memberof ControlTaskDefinition
     */
    category: ControlCategory;
    /**
     * 
     * @type {ControlTier}
     * @memberof ControlTaskDefinition
     */
    tier: ControlTier;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDefinition
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDefinition
     */
    rank: number;
    /**
     * 
     * @type {Array<ControlAttestation>}
     * @memberof ControlTaskDefinition
     */
    attestations: Array<ControlAttestation>;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDefinition
     */
    why_its_needed?: string;
    /**
     * 
     * @type {Array<ControlThreat>}
     * @memberof ControlTaskDefinition
     */
    threats?: Array<ControlThreat>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlTaskDefinition
     */
    references?: Array<string>;
    /**
     * 
     * @type {Array<ControlVendor>}
     * @memberof ControlTaskDefinition
     */
    recommended_vendors?: Array<ControlVendor>;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDefinition
     */
    upsell?: string;
}

/**
 * Check if a given object implements the ControlTaskDefinition interface.
 */
export function instanceOfControlTaskDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "tier" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "attestations" in value;

    return isInstance;
}

export function ControlTaskDefinitionFromJSON(json: any): ControlTaskDefinition {
    return ControlTaskDefinitionFromJSONTyped(json, false);
}

export function ControlTaskDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTaskDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'category': ControlCategoryFromJSON(json['category']),
        'tier': ControlTierFromJSON(json['tier']),
        'description': json['description'],
        'rank': json['rank'],
        'attestations': ((json['attestations'] as Array<any>).map(ControlAttestationFromJSON)),
        'why_its_needed': !exists(json, 'why_its_needed') ? undefined : json['why_its_needed'],
        'threats': !exists(json, 'threats') ? undefined : ((json['threats'] as Array<any>).map(ControlThreatFromJSON)),
        'references': !exists(json, 'references') ? undefined : json['references'],
        'recommended_vendors': !exists(json, 'recommended_vendors') ? undefined : ((json['recommended_vendors'] as Array<any>).map(ControlVendorFromJSON)),
        'upsell': !exists(json, 'upsell') ? undefined : json['upsell'],
    };
}

export function ControlTaskDefinitionToJSON(value?: ControlTaskDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'category': ControlCategoryToJSON(value.category),
        'tier': ControlTierToJSON(value.tier),
        'description': value.description,
        'rank': value.rank,
        'attestations': ((value.attestations as Array<any>).map(ControlAttestationToJSON)),
        'why_its_needed': value.why_its_needed,
        'threats': value.threats === undefined ? undefined : ((value.threats as Array<any>).map(ControlThreatToJSON)),
        'references': value.references,
        'recommended_vendors': value.recommended_vendors === undefined ? undefined : ((value.recommended_vendors as Array<any>).map(ControlVendorToJSON)),
        'upsell': value.upsell,
    };
}

