import React, { ReactElement, useEffect, ReactNode } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProps } from 'next/app';
import { AppProviders, AuthenticationWrapper } from '@explorer/containers';
import '../public/css/main.css';
import '../public/css/react-circular-progressbar.css';
import { NextPage } from 'next';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp: React.FunctionComponent<any> = ({
  Component,
  pageProps,
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode!.removeChild(jssStyles);
    }
  }, []);

  return (
    <AppProviders>
      <AuthenticationWrapper>
        {getLayout(<Component {...pageProps} />)}
      </AuthenticationWrapper>
    </AppProviders>
  );
};

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID || '',
  flags: {
    'explorer.manual-rsa': false,
    'show-msp-access-request-feature': false,
    'explorer-show-control-active-findings-table': false,
    'explorer.enable-vulnerability-page': false,
    'explorer.show-german-cra': false,
    'explorer.show-french-cra': false,
    'explorer.zero-day-alert': false,
    'explorer.user-impersonation': false,
    'explorer.invite-users-with-role': false,
    'explorer.deprecate-secreview-services': false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    allAttributesPrivate: true,
    bootstrap: 'localStorage',
    diagnosticOptOut: true,
    fetchGoals: false,
    sendEvents: false,
  },
})(MyApp);
