/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityNotificationsOrderBy } from './MonitEntityNotificationsOrderBy';
import {
    MonitEntityNotificationsOrderByFromJSON,
    MonitEntityNotificationsOrderByFromJSONTyped,
    MonitEntityNotificationsOrderByToJSON,
} from './MonitEntityNotificationsOrderBy';

/**
 * 
 * @export
 * @interface MonitEntityNotificationsQueries
 */
export interface MonitEntityNotificationsQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityNotificationsQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityNotificationsQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    notification_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    recipient?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    contact_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityNotificationsQueries
     */
    include_content?: string;
    /**
     * 
     * @type {MonitEntityNotificationsOrderBy}
     * @memberof MonitEntityNotificationsQueries
     */
    order_by?: MonitEntityNotificationsOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityNotificationsQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitEntityNotificationsQueries interface.
 */
export function instanceOfMonitEntityNotificationsQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityNotificationsQueriesFromJSON(json: any): MonitEntityNotificationsQueries {
    return MonitEntityNotificationsQueriesFromJSONTyped(json, false);
}

export function MonitEntityNotificationsQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityNotificationsQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'notification_type': !exists(json, 'notification_type') ? undefined : json['notification_type'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
        'contact_type': !exists(json, 'contact_type') ? undefined : json['contact_type'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'include_content': !exists(json, 'include_content') ? undefined : json['include_content'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityNotificationsOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitEntityNotificationsQueriesToJSON(value?: MonitEntityNotificationsQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'from': value.from,
        'to': value.to,
        'notification_type': value.notification_type,
        'search': value.search,
        'recipient': value.recipient,
        'contact_type': value.contact_type,
        'entity_id': value.entity_id,
        'external_id': value.external_id,
        'include_content': value.include_content,
        'order_by': MonitEntityNotificationsOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

