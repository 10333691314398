import React, { useMemo, useCallback, ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { Paper, PaperProps } from '@explorer/core';
import { formatMessage } from '@explorer/helpers';

const AutocompletePaper = (props: PaperProps) => (
  <Paper {...props} elevation={1} />
);

/**
 * FieldAutocomplete
 */
export const FieldAutocomplete = ({
  autoFocus = false,
  options = [],
  value,
  name,
  label,
  onBlur,
  error,
  onChange,
  setFieldValue,
  setFieldError,
  className,
  ...props
}: FieldAutocompleteProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  /**
   * Reformats the `value` because we use the `Option` structure.
   */
  const internalValue = useMemo<Option | Option | null>(() => {
    for (let i = 0, ii = options.length; i < ii; i++) {
      const option = options[i];

      if (value === option?.value) return option;
    }
    return null;
  }, [value, options]);

  /**
   * Handles the `onChange` event so it's compatible with `formik`
   */
  const handleChange = useCallback(
    (e: ChangeEvent<any>, selectedOption: Option) => {
      if (setFieldValue)
        setFieldValue(name, selectedOption ? selectedOption.value : null);
    },
    [],
  );

  /**
   * Handles the localized option labels
   */
  const handleOptionLabel = useCallback(
    (currentOption: Option) => {
      if (!currentOption) return '';

      return formatMessage(intl, currentOption?.label) as string;
    },
    [intl],
  );

  /**
   * Since we use the `Option` structure, this makes
   * sure that the options is indeed marked as selected.
   */
  const handleOptionSelect = useCallback(
    (option: Option, selectedOption: Option) => {
      if (selectedOption && selectedOption.value === option.value) return true;

      return false;
    },
    [],
  );

  /**
   * Wrapper around the `renderInput` function that
   * decreases the number of renders.
   */
  const renderInput = useCallback<
    (params: AutocompleteRenderInputParams) => ReactNode
  >(
    (params) => {
      return (
        <TextField
          {...params}
          variant="outlined"
          autoFocus={autoFocus}
          error={error}
          label={intlLabel}
        />
      );
    },
    [autoFocus, error, intlLabel],
  );

  return (
    <Autocomplete
      {...props}
      freeSolo={false}
      multiple={false}
      value={internalValue}
      options={options}
      onChange={handleChange as FieldAutocompleteProps['onChange']}
      onBlur={onBlur}
      renderInput={renderInput}
      getOptionLabel={handleOptionLabel}
      isOptionEqualToValue={handleOptionSelect}
      PaperComponent={AutocompletePaper}
      className={clsxClass}
      openOnFocus
      clearOnEscape
    />
  );
};

/**
 * FieldAutocomplete Props
 */
export interface FieldAutocompleteProps
  extends Omit<
    AutocompleteProps<Option, false, false, false>,
    | 'value'
    | 'freeSolo'
    | 'multiple'
    | 'renderInput'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'getOptionSelected'
    | 'PaperComponent'
    | 'openOnFocus'
    | 'clearOnEscape'
  > {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `value` - name of the input (used in `formik`)
   */
  value: string | null;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `options` - field options
   */
  options: Options;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `autoFocus` - focus the field
   */
  autoFocus?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
}
