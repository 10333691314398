/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Model of a validation error response element.
 * @export
 * @interface MonitValidationErrorElement
 */
export interface MonitValidationErrorElement {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitValidationErrorElement
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitValidationErrorElement
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof MonitValidationErrorElement
     */
    type: string;
    /**
     * 
     * @type {object}
     * @memberof MonitValidationErrorElement
     */
    ctx?: object;
}

/**
 * Check if a given object implements the MonitValidationErrorElement interface.
 */
export function instanceOfMonitValidationErrorElement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "loc" in value;
    isInstance = isInstance && "msg" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MonitValidationErrorElementFromJSON(json: any): MonitValidationErrorElement {
    return MonitValidationErrorElementFromJSONTyped(json, false);
}

export function MonitValidationErrorElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitValidationErrorElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loc': json['loc'],
        'msg': json['msg'],
        'type': json['type'],
        'ctx': !exists(json, 'ctx') ? undefined : json['ctx'],
    };
}

export function MonitValidationErrorElementToJSON(value?: MonitValidationErrorElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loc': value.loc,
        'msg': value.msg,
        'type': value.type,
        'ctx': value.ctx,
    };
}

