import { useFormik } from 'formik';
import { FormDialogProps } from '@explorer/forms/base/FormDialog';
import { CommonFormProps } from '@explorer/forms/types';
import { useFeatureFlag, useQuerySnackbar } from '@explorer/hooks';
import { FormValues, validationSchema } from './formSchema';
import { useIntl } from 'react-intl';
import { parseCommaSeparatedList } from '@explorer/helpers';
import { useQueryDataScanningList } from './queries/useQueryDataScanningList';
import { INITIAL_FORM_VALUES, DEFAULT_LOCALE } from './constant';
import { useMutationTriggerCRAScan } from './queries/useMutationTriggerCRAScan';
import { TriggerCRAScanForm } from './TriggerCRAScanForm';
import { useQuery, endpoints } from '@explorer/api';

export const TriggerCRAScan = (props: TriggerCRAScanProps) => {
  const { value: useNewEndpoints } = useFeatureFlag(
    'explorer.deprecate-secreview-services',
  );

  return useNewEndpoints ? (
    <TriggerNewCRAScan {...props} />
  ) : (
    <TriggerOldCRAScan {...props} />
  );
};

const TriggerNewCRAScan = ({
  handleClose,
  industries,
  maxWidth = 'md',
  open = false,
  querySearch = '',
}: TriggerCRAScanProps) => {
  const intl = useIntl();

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema(intl),
    validateOnChange: false,
    onSubmit: (values) => mutate(values),
  });

  const { handleFetchData, invalidateQueries } = useQueryDataScanningList(
    querySearch,
    formik,
  );

  const { mutate } = useMutationTriggerCRAScan(
    formik,
    handleClose,
    invalidateQueries,
  );

  return (
    <TriggerCRAScanForm
      formik={formik}
      handleFetchData={handleFetchData}
      handleClose={handleClose}
      industries={industries}
      maxWidth={maxWidth}
      open={open}
    />
  );
};

const TriggerOldCRAScan = ({
  handleClose,
  industries,
  maxWidth = 'md',
  open = false,
  querySearch = '',
}: TriggerCRAScanProps) => {
  const intl = useIntl();
  const { snackSuccess, snackError } = useQuerySnackbar();

  const queryTriggerScan = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1cra['/trigger'](),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema(intl),
    validateOnChange: false,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const domains = Array.from(parseCommaSeparatedList(values?.domains));
      const ip_addresses = Array.from(
        parseCommaSeparatedList(values?.ip_addresses),
      );
      const { force } = values?.scan_opts;

      queryTriggerScan
        .submit({
          payload: {
            entity_name: values.entity_name,
            entity_domain: domains[0],
            domains: domains.slice(1) ?? [],
            locale: values?.locale ?? DEFAULT_LOCALE,
            cobranded: values?.cobranded ?? null,
            ip_addresses,
            force,
            metrics: {
              industry: values.industry,
              number_of_employees: values.number_of_employees,
              number_of_pii_records: values.number_of_pii_records,
              company_revenue: values.company_revenue,
            },
          },
        })
        .then(({ error }) => {
          if (error) {
            snackError('Trigger New CRA Error', error);
            setSubmitting(false);
          } else {
            snackSuccess('Trigger New CRA Success');
            handleClose();
            resetForm();

            //invalidate list query
            invalidateQueries();
          }
        });
    },
  });

  const { handleFetchData, invalidateQueries } = useQueryDataScanningList(
    querySearch,
    formik,
  );

  return (
    <TriggerCRAScanForm
      formik={formik}
      handleFetchData={handleFetchData}
      handleClose={handleClose}
      industries={industries}
      maxWidth={maxWidth}
      open={open}
    />
  );
};

export interface TriggerCRAScanProps
  extends Pick<FormDialogProps, 'open' | 'handleClose' | 'maxWidth'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  industries: Options;
  querySearch?: string;
}
