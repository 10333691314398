/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewVirustotalData } from './CRSFSecReviewVirustotalData';
import {
    CRSFSecReviewVirustotalDataFromJSON,
    CRSFSecReviewVirustotalDataFromJSONTyped,
    CRSFSecReviewVirustotalDataToJSON,
} from './CRSFSecReviewVirustotalData';
import type { CRSFSecReviewVirustotalDataEmpty } from './CRSFSecReviewVirustotalDataEmpty';
import {
    CRSFSecReviewVirustotalDataEmptyFromJSON,
    CRSFSecReviewVirustotalDataEmptyFromJSONTyped,
    CRSFSecReviewVirustotalDataEmptyToJSON,
} from './CRSFSecReviewVirustotalDataEmpty';

/**
 * 
 * @export
 * @interface Virustotal1
 */
export interface Virustotal1 {
    /**
     * 
     * @type {string}
     * @memberof Virustotal1
     */
    asset: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof Virustotal1
     */
    detected_urls?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof Virustotal1
     */
    first_seen?: number;
    /**
     * 
     * @type {object}
     * @memberof Virustotal1
     */
    hits?: object;
    /**
     * 
     * @type {number}
     * @memberof Virustotal1
     */
    last_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof Virustotal1
     */
    total_events: number;
}

/**
 * Check if a given object implements the Virustotal1 interface.
 */
export function instanceOfVirustotal1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "total_events" in value;

    return isInstance;
}

export function Virustotal1FromJSON(json: any): Virustotal1 {
    return Virustotal1FromJSONTyped(json, false);
}

export function Virustotal1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Virustotal1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'detected_urls': !exists(json, 'detected_urls') ? undefined : json['detected_urls'],
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'hits': !exists(json, 'hits') ? undefined : json['hits'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'total_events': json['total_events'],
    };
}

export function Virustotal1ToJSON(value?: Virustotal1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'detected_urls': value.detected_urls,
        'first_seen': value.first_seen,
        'hits': value.hits,
        'last_seen': value.last_seen,
        'total_events': value.total_events,
    };
}

