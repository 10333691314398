/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientQuotas } from './ControlClientQuotas';
import {
    ControlClientQuotasFromJSON,
    ControlClientQuotasFromJSONTyped,
    ControlClientQuotasToJSON,
} from './ControlClientQuotas';
import type { ControlControlComponent } from './ControlControlComponent';
import {
    ControlControlComponentFromJSON,
    ControlControlComponentFromJSONTyped,
    ControlControlComponentToJSON,
} from './ControlControlComponent';
import type { ControlTrial } from './ControlTrial';
import {
    ControlTrialFromJSON,
    ControlTrialFromJSONTyped,
    ControlTrialToJSON,
} from './ControlTrial';

/**
 * 
 * @export
 * @interface ControlClientInfoClient
 */
export interface ControlClientInfoClient {
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoClient
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoClient
     */
    asm_entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoClient
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoClient
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlClientInfoClient
     */
    updated_at?: string;
    /**
     * 
     * @type {ControlClientQuotas}
     * @memberof ControlClientInfoClient
     */
    quotas?: ControlClientQuotas;
    /**
     * 
     * @type {ControlTrial}
     * @memberof ControlClientInfoClient
     */
    trial?: ControlTrial;
    /**
     * 
     * @type {{ [key: string]: Array<ControlControlComponent>; }}
     * @memberof ControlClientInfoClient
     */
    active_components?: { [key: string]: Array<ControlControlComponent>; };
    /**
     * 
     * @type {boolean}
     * @memberof ControlClientInfoClient
     */
    msp?: boolean;
}

/**
 * Check if a given object implements the ControlClientInfoClient interface.
 */
export function instanceOfControlClientInfoClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ControlClientInfoClientFromJSON(json: any): ControlClientInfoClient {
    return ControlClientInfoClientFromJSONTyped(json, false);
}

export function ControlClientInfoClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlClientInfoClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'asm_entity_id': !exists(json, 'asm_entity_id') ? undefined : json['asm_entity_id'],
        'role': json['role'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'quotas': !exists(json, 'quotas') ? undefined : ControlClientQuotasFromJSON(json['quotas']),
        'trial': !exists(json, 'trial') ? undefined : ControlTrialFromJSON(json['trial']),
        'active_components': !exists(json, 'active_components') ? undefined : json['active_components'],
        'msp': !exists(json, 'msp') ? undefined : json['msp'],
    };
}

export function ControlClientInfoClientToJSON(value?: ControlClientInfoClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'asm_entity_id': value.asm_entity_id,
        'role': value.role,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'quotas': ControlClientQuotasToJSON(value.quotas),
        'trial': ControlTrialToJSON(value.trial),
        'active_components': value.active_components,
        'msp': value.msp,
    };
}

