/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitExceptionStatus = {
    AcceptedRisk: 'accepted_risk',
    ThirdPartyRisk: 'third_party_risk',
    FalsePositive: 'false_positive'
} as const;
export type MonitExceptionStatus = typeof MonitExceptionStatus[keyof typeof MonitExceptionStatus];


export function MonitExceptionStatusFromJSON(json: any): MonitExceptionStatus {
    return MonitExceptionStatusFromJSONTyped(json, false);
}

export function MonitExceptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitExceptionStatus {
    return json as MonitExceptionStatus;
}

export function MonitExceptionStatusToJSON(value?: MonitExceptionStatus | null): any {
    return value as any;
}

