/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAdminInviteResponse
 */
export interface ControlAdminInviteResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminInviteResponse
     */
    sent: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminInviteResponse
     */
    failed: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminInviteResponse
     */
    existing: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminInviteResponse
     */
    deleted: Array<string>;
}

/**
 * Check if a given object implements the ControlAdminInviteResponse interface.
 */
export function instanceOfControlAdminInviteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sent" in value;
    isInstance = isInstance && "failed" in value;
    isInstance = isInstance && "existing" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function ControlAdminInviteResponseFromJSON(json: any): ControlAdminInviteResponse {
    return ControlAdminInviteResponseFromJSONTyped(json, false);
}

export function ControlAdminInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminInviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sent': json['sent'],
        'failed': json['failed'],
        'existing': json['existing'],
        'deleted': json['deleted'],
    };
}

export function ControlAdminInviteResponseToJSON(value?: ControlAdminInviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sent': value.sent,
        'failed': value.failed,
        'existing': value.existing,
        'deleted': value.deleted,
    };
}

