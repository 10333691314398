/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityDomainBlocklistAgg
 */
export interface MonitEntityDomainBlocklistAgg {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    root_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    last_found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainBlocklistAgg
     */
    asset?: string;
}

/**
 * Check if a given object implements the MonitEntityDomainBlocklistAgg interface.
 */
export function instanceOfMonitEntityDomainBlocklistAgg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDomainBlocklistAggFromJSON(json: any): MonitEntityDomainBlocklistAgg {
    return MonitEntityDomainBlocklistAggFromJSONTyped(json, false);
}

export function MonitEntityDomainBlocklistAggFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDomainBlocklistAgg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'root_domain': !exists(json, 'root_domain') ? undefined : json['root_domain'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
    };
}

export function MonitEntityDomainBlocklistAggToJSON(value?: MonitEntityDomainBlocklistAgg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'root_domain': value.root_domain,
        'source_list_name': value.source_list_name,
        'category': value.category,
        'subcategory': value.subcategory,
        'created': value.created,
        'last_found': value.last_found,
        'asset': value.asset,
    };
}

