/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlTargetClass = {
    User: 'user',
    Client: 'client'
} as const;
export type ControlTargetClass = typeof ControlTargetClass[keyof typeof ControlTargetClass];


export function ControlTargetClassFromJSON(json: any): ControlTargetClass {
    return ControlTargetClassFromJSONTyped(json, false);
}

export function ControlTargetClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTargetClass {
    return json as ControlTargetClass;
}

export function ControlTargetClassToJSON(value?: ControlTargetClass | null): any {
    return value as any;
}

