/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDataItem } from './CRSFSecReviewDataItem';
import {
    CRSFSecReviewDataItemFromJSON,
    CRSFSecReviewDataItemFromJSONTyped,
    CRSFSecReviewDataItemToJSON,
} from './CRSFSecReviewDataItem';
import type { CRSFSecReviewOriginItem } from './CRSFSecReviewOriginItem';
import {
    CRSFSecReviewOriginItemFromJSON,
    CRSFSecReviewOriginItemFromJSONTyped,
    CRSFSecReviewOriginItemToJSON,
} from './CRSFSecReviewOriginItem';
import type { CRSFSecReviewTargetItem } from './CRSFSecReviewTargetItem';
import {
    CRSFSecReviewTargetItemFromJSON,
    CRSFSecReviewTargetItemFromJSONTyped,
    CRSFSecReviewTargetItemToJSON,
} from './CRSFSecReviewTargetItem';
import type { CRSFSecReviewTargetMetadata } from './CRSFSecReviewTargetMetadata';
import {
    CRSFSecReviewTargetMetadataFromJSON,
    CRSFSecReviewTargetMetadataFromJSONTyped,
    CRSFSecReviewTargetMetadataToJSON,
} from './CRSFSecReviewTargetMetadata';

/**
 * 
 * @export
 * @interface CRSFSecReviewScanEventRecord
 */
export interface CRSFSecReviewScanEventRecord {
    /**
     * 
     * @type {CRSFSecReviewOriginItem}
     * @memberof CRSFSecReviewScanEventRecord
     */
    origin: CRSFSecReviewOriginItem;
    /**
     * 
     * @type {CRSFSecReviewDataItem}
     * @memberof CRSFSecReviewScanEventRecord
     */
    result: CRSFSecReviewDataItem;
    /**
     * 
     * @type {CRSFSecReviewTargetItem}
     * @memberof CRSFSecReviewScanEventRecord
     */
    target: CRSFSecReviewTargetItem;
    /**
     * 
     * @type {CRSFSecReviewTargetMetadata}
     * @memberof CRSFSecReviewScanEventRecord
     */
    target_metadata?: CRSFSecReviewTargetMetadata;
}

/**
 * Check if a given object implements the CRSFSecReviewScanEventRecord interface.
 */
export function instanceOfCRSFSecReviewScanEventRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "target" in value;

    return isInstance;
}

export function CRSFSecReviewScanEventRecordFromJSON(json: any): CRSFSecReviewScanEventRecord {
    return CRSFSecReviewScanEventRecordFromJSONTyped(json, false);
}

export function CRSFSecReviewScanEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewScanEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'origin': CRSFSecReviewOriginItemFromJSON(json['origin']),
        'result': CRSFSecReviewDataItemFromJSON(json['result']),
        'target': CRSFSecReviewTargetItemFromJSON(json['target']),
        'target_metadata': !exists(json, 'target_metadata') ? undefined : CRSFSecReviewTargetMetadataFromJSON(json['target_metadata']),
    };
}

export function CRSFSecReviewScanEventRecordToJSON(value?: CRSFSecReviewScanEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'origin': CRSFSecReviewOriginItemToJSON(value.origin),
        'result': CRSFSecReviewDataItemToJSON(value.result),
        'target': CRSFSecReviewTargetItemToJSON(value.target),
        'target_metadata': CRSFSecReviewTargetMetadataToJSON(value.target_metadata),
    };
}

