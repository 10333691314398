/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFDecisionTypePlain = {
    Pricing: 'pricing',
    Contingencies: 'contingencies',
    Declines: 'declines'
} as const;
export type CRSFDecisionTypePlain = typeof CRSFDecisionTypePlain[keyof typeof CRSFDecisionTypePlain];


export function CRSFDecisionTypePlainFromJSON(json: any): CRSFDecisionTypePlain {
    return CRSFDecisionTypePlainFromJSONTyped(json, false);
}

export function CRSFDecisionTypePlainFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDecisionTypePlain {
    return json as CRSFDecisionTypePlain;
}

export function CRSFDecisionTypePlainToJSON(value?: CRSFDecisionTypePlain | null): any {
    return value as any;
}

