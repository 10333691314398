/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupplementalUserType = {
    Internal: 'INTERNAL',
    External: 'EXTERNAL'
} as const;
export type SupplementalUserType = typeof SupplementalUserType[keyof typeof SupplementalUserType];


export function SupplementalUserTypeFromJSON(json: any): SupplementalUserType {
    return SupplementalUserTypeFromJSONTyped(json, false);
}

export function SupplementalUserTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalUserType {
    return json as SupplementalUserType;
}

export function SupplementalUserTypeToJSON(value?: SupplementalUserType | null): any {
    return value as any;
}

