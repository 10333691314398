/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAddressSchema } from './ExecRiskAddressSchema';
import {
    ExecRiskAddressSchemaFromJSON,
    ExecRiskAddressSchemaFromJSONTyped,
    ExecRiskAddressSchemaToJSON,
} from './ExecRiskAddressSchema';

/**
 * 
 * @export
 * @interface ExecRiskTriggerRequest
 */
export interface ExecRiskTriggerRequest {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerRequest
     */
    entity_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskTriggerRequest
     */
    domains: Array<string>;
    /**
     * 
     * @type {ExecRiskAddressSchema}
     * @memberof ExecRiskTriggerRequest
     */
    address: ExecRiskAddressSchema;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskTriggerRequest
     */
    force?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerRequest
     */
    account_id?: string;
}

/**
 * Check if a given object implements the ExecRiskTriggerRequest interface.
 */
export function instanceOfExecRiskTriggerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_name" in value;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function ExecRiskTriggerRequestFromJSON(json: any): ExecRiskTriggerRequest {
    return ExecRiskTriggerRequestFromJSONTyped(json, false);
}

export function ExecRiskTriggerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskTriggerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_name': json['entity_name'],
        'domains': json['domains'],
        'address': ExecRiskAddressSchemaFromJSON(json['address']),
        'force': !exists(json, 'force') ? undefined : json['force'],
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
    };
}

export function ExecRiskTriggerRequestToJSON(value?: ExecRiskTriggerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_name': value.entity_name,
        'domains': value.domains,
        'address': ExecRiskAddressSchemaToJSON(value.address),
        'force': value.force,
        'account_id': value.account_id,
    };
}

