import { createContext, useContext } from 'react';

/**
 * Context Type
 */
export interface ExecRisksContextType {
  company: APIExecRisksCompany;
  companyExists: boolean;
  fetchCompany: () => Promise<void | APIExecRisksCompany>;
  resetCompany: () => void;
}

/**
 * Context Initial State
 */
export const initialState: ExecRisksContextType = {
  company: {
    company_name: '',
    scanned: null,
    alexa_rank: null,
    company_type: '',
    domains_list: [],
    employee_range: '',
    incorporated_date: '',
    industries: [],
    naics_codes: [],
    primary_industries: [],
    revenue: '',
    revenue_range: '',
    sic_codes: [],
    fsr_score: 0,
    days_beyond_terms: {
      q_one: 0,
      q_two: 0,
      q_three: 0,
      q_four: 0,
      q_five: 0,
    },
    location: {
      country: '',
      formatted: '',
      id: null,
      line1: '',
      line2: '',
      locality: '',
      postal_code: '',
      region: '',
    },
    risk_flags: [],
    lien_count: 0,
    ppploan_count: 0,
    investor_count: 0,
    violation_count: 0,
    employee_rating: {
      business_outlook: 0,
      ceo_rating: 0,
      compensation_benefits: 0,
      culture_values: 0,
      diversity_inclusion: 0,
      oppotunities: 0,
      overall_rating: 0,
      recommend_to_friend: 0,
      review_count: 0,
      senior_management: 0,
      work_life_balance: 0,
    },
  },
  companyExists: false,
  fetchCompany: () => Promise.resolve(),
  resetCompany: () => undefined,
};

/**
 * Context
 */
export const ExecRisksContext = createContext<ExecRisksContextType>(
  initialState,
);

/**
 * useExecRisksContext
 */
export const useExecRisksContext = () =>
  useContext<ExecRisksContextType>(ExecRisksContext);
