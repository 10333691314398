/**
 * API Params
 */
export const P1 = '[p1]' as const;
export const P1_REGEX = /\[p1\]/g;

export const P2 = '[p2]' as const;
export const P2_REGEX = /\[p2\]/g;

export type FuncEndpoint = (params?: { p1?: string; p2?: string }) => string;

/**
 * createApiEndpoints
 *
 * Creates a Record of functions that build and return endpoint strings.
 */
export const createApiEndpoints = <Endpoints extends string>(
  prefix: string,
  endpoints: any = [],
) => {
  type ApiEndpoints = Record<Endpoints, FuncEndpoint>;

  const arr: Record<string, FuncEndpoint> = {};

  for (let i = 0, ii = endpoints.length; i < ii; i++) {
    const endpoint: string = endpoints[i];

    const func: FuncEndpoint = ({ p1, p2 } = {}) => {
      const ep: string = prefix + endpoint;

      /**
       * Endpoint has `P1` && `P2`
       */
      if (p1 && p2 && ep.includes(P1) && ep.includes(P2)) {
        return ep.replace(P1_REGEX, p1).replace(P2_REGEX, p2);
      }

      /**
       * Endpoint has `P1`
       */
      if (p1 && ep.includes(P1)) {
        return ep.replace(P1_REGEX, p1);
      }

      return ep;
    };

    arr[endpoint] = func;
  }

  return arr as ApiEndpoints;
};
