import React from 'react';
import { IconProps } from '../base/Icon';
import {
  BIPDFirstPartyIcon,
  BIPDThirdPartyIcon,
  BreachResponseCostsIcon,
  BreachResponseIcon,
  BreachResponseServicesIcon,
  BusinessInterruptionIcon,
  ComputerReplacementIcon,
  CourtAttendanceCostsIcon,
  CriminalRewardIcon,
  CrisisManagementIcon,
  CyberExtortionIcon,
  DigitalAssetRestorationIcon,
  FundTransferFraudIcon,
  FundsTransferLiabilityIcon,
  InvoiceManipulationIcon,
  MultimediaLiabilityIcon,
  NetworkInformationIcon,
  PCIIcon,
  PhishingIcon,
  PollutionIcon,
  ProofOfLossIcon,
  RegulatoryDefenseIcon,
  ReputationHarmLossIcon,
  ReputationRepairIcon,
  ServiceFraudIcon,
  SystemsIntegrityIcon,
  TechEOIcon,
} from '../coverages';

/**
 * CoalitionCoverageIcon
 */
export const CoalitionCoverageIcon = ({
  coverage,
  ...props
}: CoalitionCoverageIconProps) => {
  if (coverage === 'bipd_first_party') {
    return <BIPDFirstPartyIcon {...props} />;
  }
  if (coverage === 'bipd_third_party') {
    return <BIPDThirdPartyIcon {...props} />;
  }
  if (coverage === 'breach_response') {
    return <BreachResponseIcon {...props} />;
  }
  if (coverage === 'breach_response_costs') {
    return <BreachResponseCostsIcon {...props} />;
  }
  if (coverage === 'breach_response_services') {
    return <BreachResponseServicesIcon {...props} />;
  }
  if (coverage === 'business_interruption') {
    return <BusinessInterruptionIcon {...props} />;
  }
  if (coverage === 'computer_replacement') {
    return <ComputerReplacementIcon {...props} />;
  }
  if (coverage === 'court_attendance') {
    return <CourtAttendanceCostsIcon {...props} />;
  }
  if (coverage === 'criminal_reward') {
    return <CriminalRewardIcon {...props} />;
  }
  if (coverage === 'crisis_management') {
    return <CrisisManagementIcon {...props} />;
  }
  if (coverage === 'extortion') {
    return <CyberExtortionIcon {...props} />;
  }
  if (coverage === 'asset_restoration') {
    return <DigitalAssetRestorationIcon {...props} />;
  }
  if (coverage === 'funds_transfer') {
    return <FundTransferFraudIcon {...props} />;
  }
  if (coverage === 'funds_transfer_liability') {
    return <FundsTransferLiabilityIcon {...props} />;
  }
  if (coverage === 'invoice_manipulation') {
    return <InvoiceManipulationIcon {...props} />;
  }
  if (coverage === 'media_liability') {
    return <MultimediaLiabilityIcon {...props} />;
  }
  if (coverage === 'network') {
    return <NetworkInformationIcon {...props} />;
  }
  if (coverage === 'pci') {
    return <PCIIcon {...props} />;
  }
  if (coverage === 'phishing') {
    return <PhishingIcon {...props} />;
  }
  if (coverage === 'pollution') {
    return <PollutionIcon {...props} />;
  }
  if (coverage === 'proof_of_loss') {
    return <ProofOfLossIcon {...props} />;
  }
  if (coverage === 'regulatory_defense') {
    return <RegulatoryDefenseIcon {...props} />;
  }
  if (coverage === 'reputation') {
    return <ReputationRepairIcon {...props} />;
  }
  if (coverage === 'reputational_harm') {
    return <ReputationHarmLossIcon {...props} />;
  }
  if (coverage === 'service_fraud') {
    return <ServiceFraudIcon {...props} />;
  }
  if (coverage === 'systems_integrity') {
    return <SystemsIntegrityIcon {...props} />;
  }
  if (coverage === 'tech_eo') {
    return <TechEOIcon {...props} />;
  }

  return null;
};

/**
 * CoalitionCoverageIcon Props
 */
export interface CoalitionCoverageIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {
  coverage: CoalitionCoverages;
}
