/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';

/**
 * 
 * @export
 * @interface ControlFixedFindingsResultDetails
 */
export interface ControlFixedFindingsResultDetails {
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    request_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    email?: string;
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlFixedFindingsResultDetails
     */
    resolution_status?: ControlResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlFixedFindingsResultDetails
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    level?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFixedFindingsResultDetails
     */
    assets: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFixedFindingsResultDetails
     */
    attestations: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResultDetails
     */
    reason?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFixedFindingsResultDetails
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFixedFindingsResultDetails
     */
    references?: Array<string>;
}

/**
 * Check if a given object implements the ControlFixedFindingsResultDetails interface.
 */
export function instanceOfControlFixedFindingsResultDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request_id" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "attestations" in value;

    return isInstance;
}

export function ControlFixedFindingsResultDetailsFromJSON(json: any): ControlFixedFindingsResultDetails {
    return ControlFixedFindingsResultDetailsFromJSONTyped(json, false);
}

export function ControlFixedFindingsResultDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFixedFindingsResultDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_id': json['request_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'resolution_status': !exists(json, 'resolution_status') ? undefined : ControlResolutionRequestStatusFromJSON(json['resolution_status']),
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'assets': json['assets'],
        'attestations': json['attestations'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
    };
}

export function ControlFixedFindingsResultDetailsToJSON(value?: ControlFixedFindingsResultDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_id': value.request_id,
        'email': value.email,
        'resolution_status': ControlResolutionRequestStatusToJSON(value.resolution_status),
        'datapoint': value.datapoint,
        'created': value.created,
        'updated': value.updated,
        'name': value.name,
        'count': value.count,
        'level': value.level,
        'assets': value.assets,
        'attestations': value.attestations,
        'category': value.category,
        'description': value.description,
        'notes': value.notes,
        'reason': value.reason,
        'recommendation': value.recommendation,
        'references': value.references,
    };
}

