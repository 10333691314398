/* eslint-disable react/display-name */
import { IconButton } from '@mui/material';
import Link from 'next/link';
import React, { useMemo } from 'react';

export type IconLinkProps = React.ComponentProps<typeof IconButton> & {
  href: string;
};

export const IconLink = ({ href, children, ...otherProps }: IconLinkProps) => {
  const memoizedLink = useMemo(
    () =>
      React.forwardRef((props, ref: any) => (
        <Link ref={ref} href={href} {...props} />
      )),
    [href],
  );
  return (
    <IconButton
      component={memoizedLink as any}
      role="link"
      {...otherProps}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </IconButton>
  );
};
