/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskIndustryClassification
 */
export interface ExecRiskIndustryClassification {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIndustryClassification
     */
    domain?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    title?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    cannabis?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    payment_processor?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    professional_employer?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    managed_service_provider?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    crowd_funding?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    gaming?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    crypto?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    adult_media?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    adult_club?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    gambling?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    ico?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    casino?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    firearm?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    city_town_state?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    escrow_agent?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    exchange?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    hospital_district?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    opioid_exposure?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    scrap_metal_dealer?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    trust_corporate_service_provider?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIndustryClassification
     */
    trade_credit?: number;
}

/**
 * Check if a given object implements the ExecRiskIndustryClassification interface.
 */
export function instanceOfExecRiskIndustryClassification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskIndustryClassificationFromJSON(json: any): ExecRiskIndustryClassification {
    return ExecRiskIndustryClassificationFromJSONTyped(json, false);
}

export function ExecRiskIndustryClassificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskIndustryClassification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'cannabis': !exists(json, 'cannabis') ? undefined : json['cannabis'],
        'payment_processor': !exists(json, 'payment_processor') ? undefined : json['payment_processor'],
        'professional_employer': !exists(json, 'professional_employer') ? undefined : json['professional_employer'],
        'managed_service_provider': !exists(json, 'managed_service_provider') ? undefined : json['managed_service_provider'],
        'crowd_funding': !exists(json, 'crowd_funding') ? undefined : json['crowd_funding'],
        'gaming': !exists(json, 'gaming') ? undefined : json['gaming'],
        'crypto': !exists(json, 'crypto') ? undefined : json['crypto'],
        'adult_media': !exists(json, 'adult_media') ? undefined : json['adult_media'],
        'adult_club': !exists(json, 'adult_club') ? undefined : json['adult_club'],
        'gambling': !exists(json, 'gambling') ? undefined : json['gambling'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'casino': !exists(json, 'casino') ? undefined : json['casino'],
        'firearm': !exists(json, 'firearm') ? undefined : json['firearm'],
        'city_town_state': !exists(json, 'city_town_state') ? undefined : json['city_town_state'],
        'escrow_agent': !exists(json, 'escrow_agent') ? undefined : json['escrow_agent'],
        'exchange': !exists(json, 'exchange') ? undefined : json['exchange'],
        'hospital_district': !exists(json, 'hospital_district') ? undefined : json['hospital_district'],
        'opioid_exposure': !exists(json, 'opioid_exposure') ? undefined : json['opioid_exposure'],
        'scrap_metal_dealer': !exists(json, 'scrap_metal_dealer') ? undefined : json['scrap_metal_dealer'],
        'trust_corporate_service_provider': !exists(json, 'trust_corporate_service_provider') ? undefined : json['trust_corporate_service_provider'],
        'trade_credit': !exists(json, 'trade_credit') ? undefined : json['trade_credit'],
    };
}

export function ExecRiskIndustryClassificationToJSON(value?: ExecRiskIndustryClassification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'title': value.title,
        'cannabis': value.cannabis,
        'payment_processor': value.payment_processor,
        'professional_employer': value.professional_employer,
        'managed_service_provider': value.managed_service_provider,
        'crowd_funding': value.crowd_funding,
        'gaming': value.gaming,
        'crypto': value.crypto,
        'adult_media': value.adult_media,
        'adult_club': value.adult_club,
        'gambling': value.gambling,
        'ico': value.ico,
        'casino': value.casino,
        'firearm': value.firearm,
        'city_town_state': value.city_town_state,
        'escrow_agent': value.escrow_agent,
        'exchange': value.exchange,
        'hospital_district': value.hospital_district,
        'opioid_exposure': value.opioid_exposure,
        'scrap_metal_dealer': value.scrap_metal_dealer,
        'trust_corporate_service_provider': value.trust_corporate_service_provider,
        'trade_credit': value.trade_credit,
    };
}

