/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskLayoffsEntry
 */
export interface ExecRiskLayoffsEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLayoffsEntry
     */
    company: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLayoffsEntry
     */
    layoff_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLayoffsEntry
     */
    layoff_percentage?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLayoffsEntry
     */
    source: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskLayoffsEntry
     */
    money_raised?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskLayoffsEntry
     */
    country: string;
}

/**
 * Check if a given object implements the ExecRiskLayoffsEntry interface.
 */
export function instanceOfExecRiskLayoffsEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function ExecRiskLayoffsEntryFromJSON(json: any): ExecRiskLayoffsEntry {
    return ExecRiskLayoffsEntryFromJSONTyped(json, false);
}

export function ExecRiskLayoffsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskLayoffsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': json['company'],
        'layoff_count': !exists(json, 'layoff_count') ? undefined : json['layoff_count'],
        'layoff_percentage': !exists(json, 'layoff_percentage') ? undefined : json['layoff_percentage'],
        'source': json['source'],
        'money_raised': !exists(json, 'money_raised') ? undefined : json['money_raised'],
        'country': json['country'],
    };
}

export function ExecRiskLayoffsEntryToJSON(value?: ExecRiskLayoffsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': value.company,
        'layoff_count': value.layoff_count,
        'layoff_percentage': value.layoff_percentage,
        'source': value.source,
        'money_raised': value.money_raised,
        'country': value.country,
    };
}

