/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAddressSchema
 */
export interface ExecRiskAddressSchema {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    formatted?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    locality: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    postal_code: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAddressSchema
     */
    region: string;
}

/**
 * Check if a given object implements the ExecRiskAddressSchema interface.
 */
export function instanceOfExecRiskAddressSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "locality" in value;
    isInstance = isInstance && "postal_code" in value;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function ExecRiskAddressSchemaFromJSON(json: any): ExecRiskAddressSchema {
    return ExecRiskAddressSchemaFromJSONTyped(json, false);
}

export function ExecRiskAddressSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAddressSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'formatted': !exists(json, 'formatted') ? undefined : json['formatted'],
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'postal_code': json['postal_code'],
        'region': json['region'],
    };
}

export function ExecRiskAddressSchemaToJSON(value?: ExecRiskAddressSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'formatted': value.formatted,
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'postal_code': value.postal_code,
        'region': value.region,
    };
}

