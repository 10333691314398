/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormSchemaQuestion } from './SupplementalFormSchemaQuestion';
import {
    SupplementalFormSchemaQuestionFromJSON,
    SupplementalFormSchemaQuestionFromJSONTyped,
    SupplementalFormSchemaQuestionToJSON,
} from './SupplementalFormSchemaQuestion';
import type { SupplementalFormType } from './SupplementalFormType';
import {
    SupplementalFormTypeFromJSON,
    SupplementalFormTypeFromJSONTyped,
    SupplementalFormTypeToJSON,
} from './SupplementalFormType';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalFormSchema
 */
export interface SupplementalFormSchema {
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalFormSchema
     */
    locale?: SupplementalLocale;
    /**
     * 
     * @type {SupplementalFormType}
     * @memberof SupplementalFormSchema
     */
    form_type?: SupplementalFormType;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchema
     */
    form_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchema
     */
    form_title?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalFormSchema
     */
    form_description?: string;
    /**
     * 
     * @type {Array<SupplementalFormSchemaQuestion>}
     * @memberof SupplementalFormSchema
     */
    questions?: Array<SupplementalFormSchemaQuestion>;
}

/**
 * Check if a given object implements the SupplementalFormSchema interface.
 */
export function instanceOfSupplementalFormSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalFormSchemaFromJSON(json: any): SupplementalFormSchema {
    return SupplementalFormSchemaFromJSONTyped(json, false);
}

export function SupplementalFormSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFormSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': !exists(json, 'locale') ? undefined : SupplementalLocaleFromJSON(json['locale']),
        'form_type': !exists(json, 'form_type') ? undefined : SupplementalFormTypeFromJSON(json['form_type']),
        'form_id': !exists(json, 'form_id') ? undefined : json['form_id'],
        'form_title': !exists(json, 'form_title') ? undefined : json['form_title'],
        'form_description': !exists(json, 'form_description') ? undefined : json['form_description'],
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(SupplementalFormSchemaQuestionFromJSON)),
    };
}

export function SupplementalFormSchemaToJSON(value?: SupplementalFormSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': SupplementalLocaleToJSON(value.locale),
        'form_type': SupplementalFormTypeToJSON(value.form_type),
        'form_id': value.form_id,
        'form_title': value.form_title,
        'form_description': value.form_description,
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(SupplementalFormSchemaQuestionToJSON)),
    };
}

