import React, { useMemo } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Paper, PaperProps } from '@explorer/core';
import { isDarkTheme } from '@explorer/helpers';
import { useThemeContext } from '@explorer/themes';

const boxShadow = '0px 8px 16px rgba(0, 116, 193, 0.1)';
const border = '1px solid #ECF5FB';

const useStyles = ({ isDark }: Partial<ShadowPaperProps | any>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        shadowPaper: {
          boxShadow: isDark ? '0px 8px 16px rgba(0, 0, 0, 0.1)' : boxShadow,
          border: isDark ? '1px solid #303030' : border,
          borderRadius: '8px',
        },
      }),
    { index: 1 },
  )();

/**
 * ShadowPaper
 */
export const ShadowPaper = ({ children, ...props }: ShadowPaperProps) => {
  const { activeTheme } = useThemeContext();
  const isDark = useMemo(() => isDarkTheme(activeTheme.name), [activeTheme]);

  const classes = useStyles({ isDark });

  return (
    <Paper {...props} className={classes.shadowPaper}>
      {children}
    </Paper>
  );
};

/**
 * ShadowPaper Props
 */
export interface ShadowPaperProps extends PaperProps {}
