import React, { useMemo, RefObject, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { ListSubheader, ListSubheaderProps } from '@explorer/core';

type TitleItemRef =
  | ((instance: HTMLLIElement | null) => void)
  | RefObject<HTMLLIElement>
  | null
  | undefined;

/**
 * TitleItem
 */
export const TitleItem = React.forwardRef(
  ({ id, label, ...props }: TitleItemProps, ref: TitleItemRef) => {
    // const classes = useStyles({});
    const intl = useIntl();
    const optionId = `${id}-${label}`;

    const intlLabel = useMemo<string | ReactNode>(
      () => formatMessage(intl, label),
      [intl, label],
    );

    return (
      <ListSubheader
        {...props}
        ref={ref}
        id={optionId}
        value=""
        disableSticky
        color="primary"
        onChange={undefined}
      >
        {intlLabel}
      </ListSubheader>
    );
  },
);
TitleItem.displayName = 'TitleItem';

/**
 * TitleItem Props
 */
export interface TitleItemProps extends ListSubheaderProps {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `label` - label of the select option
   */
  label: IntlLabel;
}
