/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewMalware } from './CRSFSecReviewMalware';
import {
    CRSFSecReviewMalwareFromJSON,
    CRSFSecReviewMalwareFromJSONTyped,
    CRSFSecReviewMalwareToJSON,
} from './CRSFSecReviewMalware';

/**
 * 
 * @export
 * @interface CRSFSecReviewMalwareAggregate
 */
export interface CRSFSecReviewMalwareAggregate {
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewMalwareAggregate
     */
    assets: number;
    /**
     * 
     * @type {CRSFSecReviewMalware}
     * @memberof CRSFSecReviewMalwareAggregate
     */
    malicious: CRSFSecReviewMalware;
    /**
     * 
     * @type {CRSFSecReviewMalware}
     * @memberof CRSFSecReviewMalwareAggregate
     */
    spam: CRSFSecReviewMalware;
}

/**
 * Check if a given object implements the CRSFSecReviewMalwareAggregate interface.
 */
export function instanceOfCRSFSecReviewMalwareAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "malicious" in value;
    isInstance = isInstance && "spam" in value;

    return isInstance;
}

export function CRSFSecReviewMalwareAggregateFromJSON(json: any): CRSFSecReviewMalwareAggregate {
    return CRSFSecReviewMalwareAggregateFromJSONTyped(json, false);
}

export function CRSFSecReviewMalwareAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewMalwareAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': json['assets'],
        'malicious': CRSFSecReviewMalwareFromJSON(json['malicious']),
        'spam': CRSFSecReviewMalwareFromJSON(json['spam']),
    };
}

export function CRSFSecReviewMalwareAggregateToJSON(value?: CRSFSecReviewMalwareAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets,
        'malicious': CRSFSecReviewMalwareToJSON(value.malicious),
        'spam': CRSFSecReviewMalwareToJSON(value.spam),
    };
}

