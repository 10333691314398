/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDesiredPolicyFieldsInput } from './CRSFDesiredPolicyFieldsInput';
import {
    CRSFDesiredPolicyFieldsInputFromJSON,
    CRSFDesiredPolicyFieldsInputFromJSONTyped,
    CRSFDesiredPolicyFieldsInputToJSON,
} from './CRSFDesiredPolicyFieldsInput';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';
import type { CRSFTicketPayloadInput } from './CRSFTicketPayloadInput';
import {
    CRSFTicketPayloadInputFromJSON,
    CRSFTicketPayloadInputFromJSONTyped,
    CRSFTicketPayloadInputToJSON,
} from './CRSFTicketPayloadInput';

/**
 * The secondary scan trigger.
 * @export
 * @interface CRSFSrcHttpdlibCoreModelsSecondaryTrigger
 */
export interface CRSFSrcHttpdlibCoreModelsSecondaryTrigger {
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    account_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    attachment_domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    cobranded?: string;
    /**
     * 
     * @type {CRSFDesiredPolicyFieldsInput}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    desired_policy_fields?: CRSFDesiredPolicyFieldsInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    issue_key?: string;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    new_domains_at_bind?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {CRSFTicketPayloadInput}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    ticket_payload?: CRSFTicketPayloadInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTrigger
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSrcHttpdlibCoreModelsSecondaryTrigger interface.
 */
export function instanceOfCRSFSrcHttpdlibCoreModelsSecondaryTrigger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerFromJSON(json: any): CRSFSrcHttpdlibCoreModelsSecondaryTrigger {
    return CRSFSrcHttpdlibCoreModelsSecondaryTriggerFromJSONTyped(json, false);
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSrcHttpdlibCoreModelsSecondaryTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'attachment_domains': !exists(json, 'attachment_domains') ? undefined : json['attachment_domains'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_fields': !exists(json, 'desired_policy_fields') ? undefined : CRSFDesiredPolicyFieldsInputFromJSON(json['desired_policy_fields']),
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': !exists(json, 'issue_key') ? undefined : json['issue_key'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'new_domains_at_bind': !exists(json, 'new_domains_at_bind') ? undefined : json['new_domains_at_bind'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'ticket_payload': !exists(json, 'ticket_payload') ? undefined : CRSFTicketPayloadInputFromJSON(json['ticket_payload']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerToJSON(value?: CRSFSrcHttpdlibCoreModelsSecondaryTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_uuid': value.account_uuid,
        'attachment_domains': value.attachment_domains,
        'cobranded': value.cobranded,
        'desired_policy_fields': CRSFDesiredPolicyFieldsInputToJSON(value.desired_policy_fields),
        'desired_policy_uuid': value.desired_policy_uuid,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'locale': CRSFLocaleToJSON(value.locale),
        'new_domains_at_bind': value.new_domains_at_bind,
        'renewal_of_uuid': value.renewal_of_uuid,
        'request_origin': value.request_origin,
        'retained_from_uuid': value.retained_from_uuid,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'ticket_payload': CRSFTicketPayloadInputToJSON(value.ticket_payload),
        'user': value.user,
    };
}

