/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppCoreEnumsAuth0Roles } from './ControlAppCoreEnumsAuth0Roles';
import {
    ControlAppCoreEnumsAuth0RolesFromJSON,
    ControlAppCoreEnumsAuth0RolesFromJSONTyped,
    ControlAppCoreEnumsAuth0RolesToJSON,
} from './ControlAppCoreEnumsAuth0Roles';
import type { ControlAppSchemasUserClientResponse } from './ControlAppSchemasUserClientResponse';
import {
    ControlAppSchemasUserClientResponseFromJSON,
    ControlAppSchemasUserClientResponseFromJSONTyped,
    ControlAppSchemasUserClientResponseToJSON,
} from './ControlAppSchemasUserClientResponse';
import type { ControlLocale } from './ControlLocale';
import {
    ControlLocaleFromJSON,
    ControlLocaleFromJSONTyped,
    ControlLocaleToJSON,
} from './ControlLocale';

/**
 * 
 * @export
 * @interface ControlUserResponse
 */
export interface ControlUserResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    registered?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    registry_ip_address?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserResponse
     */
    last_login?: string;
    /**
     * 
     * @type {ControlLocale}
     * @memberof ControlUserResponse
     */
    locale?: ControlLocale;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserResponse
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlUserResponse
     */
    policies?: Array<string>;
    /**
     * 
     * @type {ControlAppSchemasUserClientResponse}
     * @memberof ControlUserResponse
     */
    client: ControlAppSchemasUserClientResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserResponse
     */
    policyholder?: boolean;
    /**
     * 
     * @type {Array<ControlAppCoreEnumsAuth0Roles>}
     * @memberof ControlUserResponse
     */
    roles?: Array<ControlAppCoreEnumsAuth0Roles>;
}

/**
 * Check if a given object implements the ControlUserResponse interface.
 */
export function instanceOfControlUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "client" in value;

    return isInstance;
}

export function ControlUserResponseFromJSON(json: any): ControlUserResponse {
    return ControlUserResponseFromJSONTyped(json, false);
}

export function ControlUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'email': json['email'],
        'client_id': json['client_id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'created': json['created'],
        'updated': json['updated'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'registry_ip_address': !exists(json, 'registry_ip_address') ? undefined : json['registry_ip_address'],
        'last_login': !exists(json, 'last_login') ? undefined : json['last_login'],
        'locale': !exists(json, 'locale') ? undefined : ControlLocaleFromJSON(json['locale']),
        'active': json['active'],
        'policies': !exists(json, 'policies') ? undefined : json['policies'],
        'client': ControlAppSchemasUserClientResponseFromJSON(json['client']),
        'policyholder': !exists(json, 'policyholder') ? undefined : json['policyholder'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(ControlAppCoreEnumsAuth0RolesFromJSON)),
    };
}

export function ControlUserResponseToJSON(value?: ControlUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'email': value.email,
        'client_id': value.client_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'phone': value.phone,
        'created': value.created,
        'updated': value.updated,
        'registered': value.registered,
        'registry_ip_address': value.registry_ip_address,
        'last_login': value.last_login,
        'locale': ControlLocaleToJSON(value.locale),
        'active': value.active,
        'policies': value.policies,
        'client': ControlAppSchemasUserClientResponseToJSON(value.client),
        'policyholder': value.policyholder,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(ControlAppCoreEnumsAuth0RolesToJSON)),
    };
}

