/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewSSLv2
 */
export interface SecReviewSSLv2 {
    /**
     * 
     * @type {string}
     * @memberof SecReviewSSLv2
     */
    ja3_digest?: string;
    /**
     * 
     * @type {object}
     * @memberof SecReviewSSLv2
     */
    vulnerabilities?: object;
}

/**
 * Check if a given object implements the SecReviewSSLv2 interface.
 */
export function instanceOfSecReviewSSLv2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSSLv2FromJSON(json: any): SecReviewSSLv2 {
    return SecReviewSSLv2FromJSONTyped(json, false);
}

export function SecReviewSSLv2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSSLv2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ja3_digest': !exists(json, 'ja3_digest') ? undefined : json['ja3_digest'],
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : json['vulnerabilities'],
    };
}

export function SecReviewSSLv2ToJSON(value?: SecReviewSSLv2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ja3_digest': value.ja3_digest,
        'vulnerabilities': value.vulnerabilities,
    };
}

