/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityKeywordOrderBy = {
    EntityId: 'entity_id',
    Asset: 'asset',
    Port: 'port',
    Module: 'module',
    Found: 'found',
    Created: 'created',
    Updated: 'updated'
} as const;
export type ControlEntityKeywordOrderBy = typeof ControlEntityKeywordOrderBy[keyof typeof ControlEntityKeywordOrderBy];


export function ControlEntityKeywordOrderByFromJSON(json: any): ControlEntityKeywordOrderBy {
    return ControlEntityKeywordOrderByFromJSONTyped(json, false);
}

export function ControlEntityKeywordOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityKeywordOrderBy {
    return json as ControlEntityKeywordOrderBy;
}

export function ControlEntityKeywordOrderByToJSON(value?: ControlEntityKeywordOrderBy | null): any {
    return value as any;
}

