/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Emails } from './Emails';
import {
    EmailsFromJSON,
    EmailsFromJSONTyped,
    EmailsToJSON,
} from './Emails';

/**
 * 
 * @export
 * @interface ControlUserInviteRequest
 */
export interface ControlUserInviteRequest {
    /**
     * 
     * @type {Emails}
     * @memberof ControlUserInviteRequest
     */
    emails: Emails;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserInviteRequest
     */
    skip_active_users?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserInviteRequest
     */
    force_notifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlUserInviteRequest
     */
    auth0_role?: string;
}

/**
 * Check if a given object implements the ControlUserInviteRequest interface.
 */
export function instanceOfControlUserInviteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "emails" in value;

    return isInstance;
}

export function ControlUserInviteRequestFromJSON(json: any): ControlUserInviteRequest {
    return ControlUserInviteRequestFromJSONTyped(json, false);
}

export function ControlUserInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserInviteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': EmailsFromJSON(json['emails']),
        'skip_active_users': !exists(json, 'skip_active_users') ? undefined : json['skip_active_users'],
        'force_notifications': !exists(json, 'force_notifications') ? undefined : json['force_notifications'],
        'auth0_role': !exists(json, 'auth0_role') ? undefined : json['auth0_role'],
    };
}

export function ControlUserInviteRequestToJSON(value?: ControlUserInviteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': EmailsToJSON(value.emails),
        'skip_active_users': value.skip_active_users,
        'force_notifications': value.force_notifications,
        'auth0_role': value.auth0_role,
    };
}

