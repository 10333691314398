import {
  BaseAPI,
  Configuration,
  ResponseError,
  V1Api,
} from '@explorer/open-api-client';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAPIClient } from './useV1APIClient';

export type useExplorerAPIMutationParamType<
  API extends BaseAPI,
  T = unknown,
  TVariables = unknown,
> = Omit<UseMutationOptions<T, unknown, TVariables>, 'mutationFn'> & {
  /* overload the mutationFn to accept the open api client as a param */
  mutationFn: (args: TVariables, client: API) => Promise<T>;
  explorerAPIOptions?: {
    clientId?: string;
    onSuccess?: (params: { data: T | undefined }) => void;
    onError?: (params: { data: unknown | undefined; error: unknown }) => void;
  };
  /* additional params to add to the mutationKey */
  additionalParams?: string[];
};

const useExplorerAPIMutation = <
  API extends BaseAPI,
  T = unknown,
  TVariables = unknown,
>(
  params: useExplorerAPIMutationParamType<API, T, TVariables>,
  APIClient: new (config: Configuration) => API,
) => {
  const { mutationFn, explorerAPIOptions, mutationKey, ...otherParams } =
    params;
  const { clientId, onSuccess, onError } = explorerAPIOptions ?? {};

  // subscribe to the user store to trigger a re-render when the user logs in
  const { client } = useAPIClient<API>({ clientId, APIClient });

  const mutationState = useMutation({
    mutationKey,
    mutationFn: (args) => {
      return params.mutationFn(args, client);
    },
    // no retries on mutations, in case it's something along 400s error.
    retry: false,
    ...otherParams,
  });

  useEffect(() => {
    if (mutationState.isSuccess) {
      onSuccess?.({ data: mutationState.data });
      return;
    }

    if (mutationState.isError) {
      /* If this is a ResponseError thrown from our client, we can parse the response */
      const response = (mutationState.error as ResponseError).response;

      if (response) {
        onError?.({
          data: response,
          error: response,
        });
      } else {
        onError?.({
          data: undefined,
          error: mutationState.error,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationState.error, mutationState.isSuccess]);

  return mutationState;
};

export const useV1APIMutation = <T = unknown, TVariables = unknown>(
  params: useExplorerAPIMutationParamType<V1Api, T, TVariables>,
) => {
  return useExplorerAPIMutation<V1Api, T, TVariables>(params, V1Api);
};
