/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityFindingsFilters } from './MonitEntityFindingsFilters';
import {
    MonitEntityFindingsFiltersFromJSON,
    MonitEntityFindingsFiltersFromJSONTyped,
    MonitEntityFindingsFiltersToJSON,
} from './MonitEntityFindingsFilters';
import type { MonitEntityFindingsResult } from './MonitEntityFindingsResult';
import {
    MonitEntityFindingsResultFromJSON,
    MonitEntityFindingsResultFromJSONTyped,
    MonitEntityFindingsResultToJSON,
} from './MonitEntityFindingsResult';

/**
 * 
 * @export
 * @interface MonitPaginatedEntityFindingsResponse
 */
export interface MonitPaginatedEntityFindingsResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityFindingsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityFindingsResult>}
     * @memberof MonitPaginatedEntityFindingsResponse
     */
    results: Array<MonitEntityFindingsResult>;
    /**
     * 
     * @type {MonitEntityFindingsFilters}
     * @memberof MonitPaginatedEntityFindingsResponse
     */
    filters: MonitEntityFindingsFilters;
}

/**
 * Check if a given object implements the MonitPaginatedEntityFindingsResponse interface.
 */
export function instanceOfMonitPaginatedEntityFindingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "filters" in value;

    return isInstance;
}

export function MonitPaginatedEntityFindingsResponseFromJSON(json: any): MonitPaginatedEntityFindingsResponse {
    return MonitPaginatedEntityFindingsResponseFromJSONTyped(json, false);
}

export function MonitPaginatedEntityFindingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitPaginatedEntityFindingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': ((json['results'] as Array<any>).map(MonitEntityFindingsResultFromJSON)),
        'filters': MonitEntityFindingsFiltersFromJSON(json['filters']),
    };
}

export function MonitPaginatedEntityFindingsResponseToJSON(value?: MonitPaginatedEntityFindingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': ((value.results as Array<any>).map(MonitEntityFindingsResultToJSON)),
        'filters': MonitEntityFindingsFiltersToJSON(value.filters),
    };
}

