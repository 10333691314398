import { useQuerySnackbar } from '@explorer/hooks';
import {
  CRSFLocale,
  CRSFSrcHttpdlibCoreModelsCraTriggerResponse,
} from '@explorer/open-api-client';
import { useV1APIMutation } from '@explorer/query';
import { FormValues } from '../formSchema';
import { parseCommaSeparatedList } from '@explorer/helpers';
import { DEFAULT_LOCALE } from '../constant';

export const useMutationTriggerCRAScan = (
  formik,
  handleClose,
  invalidateQueries,
) => {
  const { snackSuccess, snackError } = useQuerySnackbar();

  return useV1APIMutation<
    CRSFSrcHttpdlibCoreModelsCraTriggerResponse,
    FormValues
  >({
    mutationKey: ['v1CraWebCrsfEntityTriggerPost'],
    mutationFn(args, client) {
      const { force } = args?.scan_opts;
      const domains = Array.from(parseCommaSeparatedList(args?.domains));
      const ip_addresses = Array.from(
        parseCommaSeparatedList(args?.ip_addresses),
      );

      return client.v1CraWebCrsfEntityTriggerPost({
        CRSFSrcHttpdlibCoreModelsCraTrigger: {
          entity_name: args.entity_name,
          domains,
          locale: (args?.locale ?? DEFAULT_LOCALE) as CRSFLocale,
          cobranded: args?.cobranded ?? null,
          ip_addresses,
          force,
          metrics: {
            industry: args.industry,
            number_of_employees: args.number_of_employees,
            number_of_pii_records: args.number_of_pii_records,
            company_revenue: args.company_revenue,
          },
        },
      });
    },
    explorerAPIOptions: {
      onSuccess: () => {
        snackSuccess('Trigger New CRA Success');
        handleClose();
        formik.resetForm();

        //invalidate list query
        invalidateQueries();
      },
      onError: (error) => {
        snackError('Trigger New CRA Error', error.error);
        formik.setSubmitting(false);
      },
    },
  });
};
