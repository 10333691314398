import { coalition } from '@explorer/themes';

export const appConfig: AppConfig = {
  /**
   * App
   */
  NAME: 'Coalition Explorer',
  DOMAIN: process.env.APP_DOMAIN,
  CREATED_BY: 'Created by Coalition Inc. (https://www.coalitioninc.com/)',

  /**
   * Monitoring config
   */
  DATADOG: {
    appId: 'efdac83c-dbcf-49ac-9a86-cc9501d62982',
    serviceName: 'explorer',
    token: 'pub438124100e64d57fafbd40c12a92cef0',
    tracingOrigins: [
      /api\.explorer.*\.binaryedge\.io/,
      /api\.explorer.*\.coalitioninc\.com/,
      /https?:\/\/localhost/,
    ],
  },
  SEGMENT: 'uy1153FOmJ2RzH3CTqUHqm66eUxmw8dL',
  SENTRY:
    'https://4ae15252313a4fc0a7475ee2c8d69904@o78121.ingest.sentry.io/6065179',
  /**
   * Auth0 Config
   */
  AUTH_0: {
    clientId: process.env.AUTH_0_CLIENT_ID ?? '',
    domain: process.env.AUTH_0_DOMAIN ?? '',
  },
  /**
   * Themes
   */
  THEMES: {
    coalition: coalition.light,
    coalitionDark: coalition.dark,
  },
  /**
   * Locales
   */
  LOCALES: [
    {
      code: 'en',
      codeRegion: 'en_US',
      name: 'English',
    },
  ],
  /**
   * Formats
   */
  FORMATS: {
    DATE: 'MMM DD, YYYY',
    TIME: 'hh:mm A z',
    DATETIME: 'MMM DD, YYYY - hh:mm A z',
  },
};
