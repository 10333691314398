/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionsInner } from './ConditionsInner';
import {
    ConditionsInnerFromJSON,
    ConditionsInnerFromJSONTyped,
    ConditionsInnerToJSON,
} from './ConditionsInner';
import type { ControlAndCondition } from './ControlAndCondition';
import {
    ControlAndConditionFromJSON,
    ControlAndConditionFromJSONTyped,
    ControlAndConditionToJSON,
} from './ControlAndCondition';
import type { ControlAnsweredCondition } from './ControlAnsweredCondition';
import {
    ControlAnsweredConditionFromJSON,
    ControlAnsweredConditionFromJSONTyped,
    ControlAnsweredConditionToJSON,
} from './ControlAnsweredCondition';
import type { ControlBooleanCondition } from './ControlBooleanCondition';
import {
    ControlBooleanConditionFromJSON,
    ControlBooleanConditionFromJSONTyped,
    ControlBooleanConditionToJSON,
} from './ControlBooleanCondition';
import type { ControlNotCondition } from './ControlNotCondition';
import {
    ControlNotConditionFromJSON,
    ControlNotConditionFromJSONTyped,
    ControlNotConditionToJSON,
} from './ControlNotCondition';
import type { ControlOrCondition } from './ControlOrCondition';
import {
    ControlOrConditionFromJSON,
    ControlOrConditionFromJSONTyped,
    ControlOrConditionToJSON,
} from './ControlOrCondition';
import type { ControlSelectedAllCondition } from './ControlSelectedAllCondition';
import {
    ControlSelectedAllConditionFromJSON,
    ControlSelectedAllConditionFromJSONTyped,
    ControlSelectedAllConditionToJSON,
} from './ControlSelectedAllCondition';
import type { ControlSelectedAnyCondition } from './ControlSelectedAnyCondition';
import {
    ControlSelectedAnyConditionFromJSON,
    ControlSelectedAnyConditionFromJSONTyped,
    ControlSelectedAnyConditionToJSON,
} from './ControlSelectedAnyCondition';
import type { ControlTextCondition } from './ControlTextCondition';
import {
    ControlTextConditionFromJSON,
    ControlTextConditionFromJSONTyped,
    ControlTextConditionToJSON,
} from './ControlTextCondition';

/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    type?: ConditionTypeEnum;
    /**
     * 
     * @type {Array<ConditionsInner>}
     * @memberof Condition
     */
    conditions: Array<ConditionsInner>;
    /**
     * 
     * @type {Condition}
     * @memberof Condition
     */
    condition: Condition;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    question_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Condition
     */
    value: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Condition
     */
    option_ids: Array<string>;
}


/**
 * @export
 */
export const ConditionTypeEnum = {
    SelectedAll: 'selected_all'
} as const;
export type ConditionTypeEnum = typeof ConditionTypeEnum[keyof typeof ConditionTypeEnum];


/**
 * Check if a given object implements the Condition interface.
 */
export function instanceOfCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conditions" in value;
    isInstance = isInstance && "condition" in value;
    isInstance = isInstance && "question_id" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "option_ids" in value;

    return isInstance;
}

export function ConditionFromJSON(json: any): Condition {
    return ConditionFromJSONTyped(json, false);
}

export function ConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Condition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'conditions': ((json['conditions'] as Array<any>).map(ConditionsInnerFromJSON)),
        'condition': ConditionFromJSON(json['condition']),
        'question_id': json['question_id'],
        'value': json['value'],
        'option_ids': json['option_ids'],
    };
}

export function ConditionToJSON(value?: Condition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'conditions': ((value.conditions as Array<any>).map(ConditionsInnerToJSON)),
        'condition': ConditionToJSON(value.condition),
        'question_id': value.question_id,
        'value': value.value,
        'option_ids': value.option_ids,
    };
}

