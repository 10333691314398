import React, { ReactNode, useCallback, useState } from 'react';
import { endpoints, useQuery, UseQueryOptions } from '@explorer/api';
import { useParam, useQuerySnackbar } from '@explorer/hooks';
import {
  ExecRisksContext,
  ExecRisksContextType,
  initialState,
} from './context';

type FetchCompany = ExecRisksContextType['fetchCompany'];
type ResetCompany = ExecRisksContextType['resetCompany'];

const getExplorerCompanyInfoQuery = (processId: string): UseQueryOptions => {
  return {
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1execrisks['/scan/[p1]/companyinfo']({
      p1: processId,
    }),
    method: 'GET',
    auth: 'bearer',
    digest: ({ rawData }: any) => {
      return rawData?.result || {};
    },
  };
};

/**
 * ExecRisksProvider
 */
export const ExecRisksProvider = ({ children }: ExecRisksProviderProps) => {
  const { snackError } = useQuerySnackbar();
  const [state, setState] = useState<ExecRisksContextType>(initialState);
  const { company, companyExists } = state;
  const processId = useParam('processId');

  const queryCompanyInfo = useQuery(getExplorerCompanyInfoQuery(processId));

  const fetchCompany = useCallback<FetchCompany>(() => {
    resetCompany();

    return queryCompanyInfo.submit().then(({ data, error }) => {
      if (error) {
        snackError('Error fetching Executive Risks data', error?.data?.message);
        return;
      }

      const dataCompany: APIExecRisksCompany = data || [];

      setState((prevState) => ({
        ...prevState,
        company: dataCompany,
        companyExists: true,
      }));
    });
  }, [queryCompanyInfo]);

  const resetCompany = useCallback<ResetCompany>(() => {
    setState(initialState);
  }, []);

  const context: ExecRisksContextType = {
    company,
    companyExists,
    fetchCompany,
    resetCompany,
  };

  return (
    <ExecRisksContext.Provider value={context}>
      {children}
    </ExecRisksContext.Provider>
  );
};

interface ExecRisksProviderProps {
  children: ReactNode;
}
