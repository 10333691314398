/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityPasswordsAggCharacters
 */
export interface ControlEntityPasswordsAggCharacters {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggCharacters
     */
    lowercase?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggCharacters
     */
    uppercase?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggCharacters
     */
    numbers?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggCharacters
     */
    special?: number;
}

/**
 * Check if a given object implements the ControlEntityPasswordsAggCharacters interface.
 */
export function instanceOfControlEntityPasswordsAggCharacters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityPasswordsAggCharactersFromJSON(json: any): ControlEntityPasswordsAggCharacters {
    return ControlEntityPasswordsAggCharactersFromJSONTyped(json, false);
}

export function ControlEntityPasswordsAggCharactersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityPasswordsAggCharacters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowercase': !exists(json, 'lowercase') ? undefined : json['lowercase'],
        'uppercase': !exists(json, 'uppercase') ? undefined : json['uppercase'],
        'numbers': !exists(json, 'numbers') ? undefined : json['numbers'],
        'special': !exists(json, 'special') ? undefined : json['special'],
    };
}

export function ControlEntityPasswordsAggCharactersToJSON(value?: ControlEntityPasswordsAggCharacters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lowercase': value.lowercase,
        'uppercase': value.uppercase,
        'numbers': value.numbers,
        'special': value.special,
    };
}

