/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitHealthDatastores
 */
export interface MonitHealthDatastores {
    /**
     * 
     * @type {boolean}
     * @memberof MonitHealthDatastores
     */
    postgres?: boolean;
}

/**
 * Check if a given object implements the MonitHealthDatastores interface.
 */
export function instanceOfMonitHealthDatastores(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitHealthDatastoresFromJSON(json: any): MonitHealthDatastores {
    return MonitHealthDatastoresFromJSONTyped(json, false);
}

export function MonitHealthDatastoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitHealthDatastores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postgres': !exists(json, 'postgres') ? undefined : json['postgres'],
    };
}

export function MonitHealthDatastoresToJSON(value?: MonitHealthDatastores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postgres': value.postgres,
    };
}

