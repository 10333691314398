/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990ScheduleA } from './ExecRiskAppCoreModelsScansForm990ScheduleA';
import {
    ExecRiskAppCoreModelsScansForm990ScheduleAFromJSON,
    ExecRiskAppCoreModelsScansForm990ScheduleAFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990ScheduleAToJSON,
} from './ExecRiskAppCoreModelsScansForm990ScheduleA';
import type { ExecRiskAppCoreModelsScansForm990ScheduleR } from './ExecRiskAppCoreModelsScansForm990ScheduleR';
import {
    ExecRiskAppCoreModelsScansForm990ScheduleRFromJSON,
    ExecRiskAppCoreModelsScansForm990ScheduleRFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990ScheduleRToJSON,
} from './ExecRiskAppCoreModelsScansForm990ScheduleR';
import type { ExecRiskIRS990 } from './ExecRiskIRS990';
import {
    ExecRiskIRS990FromJSON,
    ExecRiskIRS990FromJSONTyped,
    ExecRiskIRS990ToJSON,
} from './ExecRiskIRS990';
import type { ExecRiskIRS990EZ } from './ExecRiskIRS990EZ';
import {
    ExecRiskIRS990EZFromJSON,
    ExecRiskIRS990EZFromJSONTyped,
    ExecRiskIRS990EZToJSON,
} from './ExecRiskIRS990EZ';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ReturnData
 */
export interface ExecRiskAppCoreModelsScansForm990ReturnData {
    /**
     * 
     * @type {ExecRiskIRS990}
     * @memberof ExecRiskAppCoreModelsScansForm990ReturnData
     */
    IRS990?: ExecRiskIRS990;
    /**
     * 
     * @type {ExecRiskIRS990EZ}
     * @memberof ExecRiskAppCoreModelsScansForm990ReturnData
     */
    IRS990EZ?: ExecRiskIRS990EZ;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990ScheduleA}
     * @memberof ExecRiskAppCoreModelsScansForm990ReturnData
     */
    IRS990ScheduleA?: ExecRiskAppCoreModelsScansForm990ScheduleA;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990ScheduleR}
     * @memberof ExecRiskAppCoreModelsScansForm990ReturnData
     */
    IRS990ScheduleR?: ExecRiskAppCoreModelsScansForm990ScheduleR;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ReturnData interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ReturnData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ReturnDataFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ReturnData {
    return ExecRiskAppCoreModelsScansForm990ReturnDataFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ReturnDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ReturnData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IRS990': !exists(json, 'IRS990') ? undefined : ExecRiskIRS990FromJSON(json['IRS990']),
        'IRS990EZ': !exists(json, 'IRS990EZ') ? undefined : ExecRiskIRS990EZFromJSON(json['IRS990EZ']),
        'IRS990ScheduleA': !exists(json, 'IRS990ScheduleA') ? undefined : ExecRiskAppCoreModelsScansForm990ScheduleAFromJSON(json['IRS990ScheduleA']),
        'IRS990ScheduleR': !exists(json, 'IRS990ScheduleR') ? undefined : ExecRiskAppCoreModelsScansForm990ScheduleRFromJSON(json['IRS990ScheduleR']),
    };
}

export function ExecRiskAppCoreModelsScansForm990ReturnDataToJSON(value?: ExecRiskAppCoreModelsScansForm990ReturnData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IRS990': ExecRiskIRS990ToJSON(value.IRS990),
        'IRS990EZ': ExecRiskIRS990EZToJSON(value.IRS990EZ),
        'IRS990ScheduleA': ExecRiskAppCoreModelsScansForm990ScheduleAToJSON(value.IRS990ScheduleA),
        'IRS990ScheduleR': ExecRiskAppCoreModelsScansForm990ScheduleRToJSON(value.IRS990ScheduleR),
    };
}

