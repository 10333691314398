/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskProcessStatusType } from './ExecRiskProcessStatusType';
import {
    ExecRiskProcessStatusTypeFromJSON,
    ExecRiskProcessStatusTypeFromJSONTyped,
    ExecRiskProcessStatusTypeToJSON,
} from './ExecRiskProcessStatusType';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksStatusModelsStatus
 */
export interface ExecRiskAppApiV1EagerTasksStatusModelsStatus {
    /**
     * 
     * @type {ExecRiskProcessStatusType}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsStatus
     */
    status: ExecRiskProcessStatusType;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsStatus
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsStatus
     */
    finished?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksStatusModelsStatus interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksStatusModelsStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "started" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSON(json: any): ExecRiskAppApiV1EagerTasksStatusModelsStatus {
    return ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksStatusModelsStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ExecRiskProcessStatusTypeFromJSON(json['status']),
        'started': json['started'],
        'finished': !exists(json, 'finished') ? undefined : json['finished'],
    };
}

export function ExecRiskAppApiV1EagerTasksStatusModelsStatusToJSON(value?: ExecRiskAppApiV1EagerTasksStatusModelsStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ExecRiskProcessStatusTypeToJSON(value.status),
        'started': value.started,
        'finished': value.finished,
    };
}

