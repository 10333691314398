/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityPasswordsAggCharacters } from './ControlEntityPasswordsAggCharacters';
import {
    ControlEntityPasswordsAggCharactersFromJSON,
    ControlEntityPasswordsAggCharactersFromJSONTyped,
    ControlEntityPasswordsAggCharactersToJSON,
} from './ControlEntityPasswordsAggCharacters';
import type { ControlEntityPasswordsAggComposition } from './ControlEntityPasswordsAggComposition';
import {
    ControlEntityPasswordsAggCompositionFromJSON,
    ControlEntityPasswordsAggCompositionFromJSONTyped,
    ControlEntityPasswordsAggCompositionToJSON,
} from './ControlEntityPasswordsAggComposition';

/**
 * 
 * @export
 * @interface ControlEntityPasswordsAggResponse
 */
export interface ControlEntityPasswordsAggResponse {
    /**
     * 
     * @type {ControlEntityPasswordsAggCharacters}
     * @memberof ControlEntityPasswordsAggResponse
     */
    characters?: ControlEntityPasswordsAggCharacters;
    /**
     * 
     * @type {ControlEntityPasswordsAggComposition}
     * @memberof ControlEntityPasswordsAggResponse
     */
    composition?: ControlEntityPasswordsAggComposition;
}

/**
 * Check if a given object implements the ControlEntityPasswordsAggResponse interface.
 */
export function instanceOfControlEntityPasswordsAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityPasswordsAggResponseFromJSON(json: any): ControlEntityPasswordsAggResponse {
    return ControlEntityPasswordsAggResponseFromJSONTyped(json, false);
}

export function ControlEntityPasswordsAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityPasswordsAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'characters': !exists(json, 'characters') ? undefined : ControlEntityPasswordsAggCharactersFromJSON(json['characters']),
        'composition': !exists(json, 'composition') ? undefined : ControlEntityPasswordsAggCompositionFromJSON(json['composition']),
    };
}

export function ControlEntityPasswordsAggResponseToJSON(value?: ControlEntityPasswordsAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'characters': ControlEntityPasswordsAggCharactersToJSON(value.characters),
        'composition': ControlEntityPasswordsAggCompositionToJSON(value.composition),
    };
}

