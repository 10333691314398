import {
  BaseAPI,
  Configuration,
  ResponseError,
  V1Api,
} from '@explorer/open-api-client';
import {
  InvalidateOptions,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useAPIClient } from './useV1APIClient';

export type useExplorerAPIQueryParamType<
  API extends BaseAPI,
  T = unknown,
> = Omit<UseQueryOptions<T>, 'queryFn'> & {
  /* overload the queryFn to accept the open api client as a param */
  queryFn: (client: API, context: QueryFunctionContext) => T | Promise<T>;
  explorerAPIOptions?: {
    clientId?: string;
    onSuccess?: (params: { data: T | undefined }) => void;
    onError?: (params: { data: unknown | undefined; error: unknown }) => void;
  };
  /* additional params to add to the queryKey */
  additionalParams?: string[];
};

const useExplorerAPIQuery = <API extends BaseAPI, T = unknown>(
  params: useExplorerAPIQueryParamType<API, T>,
  APIClient: new (config: Configuration) => API,
): Omit<UseQueryResult<T, unknown>, 'isLoading'> & {
  invalidateAllQueries: (options?: InvalidateOptions) => void;
  isLoading: boolean;
} => {
  const {
    additionalParams = [],
    enabled = true,
    explorerAPIOptions,
    queryFn,
    queryKey,
    ...otherParams
  } = params;
  const { clientId, onSuccess, onError } = explorerAPIOptions ?? {};

  // subscribe to the user store to trigger a re-render when the user logs in
  const { client, isReady } = useAPIClient<API>({ clientId, APIClient });
  const reactQueryClient = useQueryClient();

  const queryIsEnabled = !!isReady && !!enabled;

  const baseQueryKey = useMemo(() => {
    return [...queryKey, ...additionalParams];

    // Instead of comparing array equality, we compare the stringified version of the array's values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([...queryKey]), JSON.stringify([...additionalParams])]);

  const queryState = useQuery<T>({
    ...otherParams,
    queryKey: baseQueryKey,
    queryFn: (context) => {
      return params.queryFn(client, context);
    },
    enabled: queryIsEnabled,
  });

  useEffect(() => {
    if (queryState.isSuccess) {
      onSuccess?.({ data: queryState.data });
      return;
    }

    if (queryState.isError) {
      /* If this is a ResponseError thrown from our client, we can parse the response */
      const response = (queryState.error as ResponseError).response;

      if (response) {
        onError?.({
          data: response,
          error: response,
        });
      } else {
        onError?.({
          data: undefined,
          error: queryState.error,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState.error, queryState.isSuccess]);

  /* https://github.com/TanStack/query/pull/4244. When enabled is false, isLoading is true, we need to use isInitialLoading. */
  const isLoading = !queryIsEnabled
    ? queryState.isInitialLoading
    : queryState.isLoading;

  /* Invalidates all queries, regardless of the eventState params (i.e. page, pageSize, sortOrder). This should be run if we know some of our data has changed */
  const invalidateAllQueries = useCallback(
    (options?: InvalidateOptions) => {
      reactQueryClient.invalidateQueries(baseQueryKey, null, options);
    },
    [baseQueryKey, reactQueryClient],
  );

  return { ...queryState, isLoading, invalidateAllQueries };
};

export const useV1APIQuery = <T = unknown>(
  params: useExplorerAPIQueryParamType<V1Api, T>,
) => {
  return useExplorerAPIQuery<V1Api, T>(params, V1Api);
};
