import { PaletteOptionsCoalition } from '@mui/material/styles';
import { sharedPalette } from '../../shared';

export type ThemeTypes = 'light' | 'dark';

export const palette: Record<ThemeTypes, PaletteOptionsCoalition> = {
  light: {
    mode: 'light',
    /**
     * Main Colors
     */
    primary: {
      main: '#2773E0',
      dark: '#032F64',
      light: '#2EA0ED',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2EA0ED',
      dark: '#0B1641',
      light: '#6AC9FF',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#556271',
      disabled: '#bfccd3',
    },
    action: {
      disabled: '#919EAB',
      disabledBackground: '#CAD3DB',
      selected: '#2773E0',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    fileUpload: {
      text: 'rgba(0, 0, 0, 0.6)',
      icon: 'rgba(0, 0, 0, 0.54)',
    },
    grey: {
      '200': '#4F565C',
      '300': '#556271',
      '400': '#919EAB',
      '700': '#CAD3DB',
      '800': '#EAEDF0',
      '900': '#F6F8FA',
    },
    blue: {
      '200': '#0B1641',
      '300': '#032F64',
      '400': '#2773E0', // Wrong
      '500': '#2773E0',
      '600': '#2EA0ED',
      '700': '#6AC9FF',
      '800': '#D2EFFF',
      '900': '#EFF7FD',
    },
    /**
     * Utility Colors
     */
    ...sharedPalette,
  },
  dark: {
    /**
     * Main Colors
     */
    mode: 'dark',
    primary: {
      light: '#D2EFFF',
      main: '#6AC9FF',
      dark: '#032F64',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6AC9FF',
      main: '#2EA0ED',
      dark: '#0B1641',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#ffffffb3',
      disabled: '#ffffff4d',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    fileUpload: {
      text: 'rgba(0, 0, 0, 0.6)',
      icon: 'rgba(0, 0, 0, 0.54)',
    },
    grey: {
      '900': '#4F565C',
      '800': '#556271',
      '700': '#919EAB',
      '400': '#CAD3DB',
      '300': '#EAEDF0',
      '200': '#F6F8FA',
    },
    blue: {
      '900': '#032F64',
      '800': '#032F64',
      '700': '#2773E0', // Wrong
      '600': '#2773E0',
      '500': '#2EA0ED',
      '400': '#6AC9FF',
      '300': '#D2EFFF',
      '200': '#EFF7FD',
    },
    /**
     * Utility Colors
     */
    ...sharedPalette,
  },
};
