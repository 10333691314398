import React, { ReactNode } from 'react';

const DEFAULT_ICON_SIZE = 32;
const VIEW_SIZE_HALF = DEFAULT_ICON_SIZE / 2;
const VIEW_BOX = `0 0 ${DEFAULT_ICON_SIZE} ${DEFAULT_ICON_SIZE}`;

/**
 * Icon
 */
export const Icon = ({
  label,
  size = DEFAULT_ICON_SIZE,
  color,
  circleColor,
  children,
  viewBox,
  ...props
}: IconProps) => {
  return (
    <svg
      fill="currentColor"
      focusable={false}
      aria-label={label}
      viewBox={viewBox ?? VIEW_BOX}
      width={size}
      height={size}
      style={{ color }}
      alignmentBaseline="middle"
      {...props}
    >
      {circleColor && (
        <circle
          fill={circleColor}
          cx={VIEW_SIZE_HALF}
          cy={VIEW_SIZE_HALF}
          r={VIEW_SIZE_HALF}
        />
      )}
      {children}
    </svg>
  );
};

/**
 * Icon Props
 */
// export interface IconProps extends SVGProps<SVGSVGElement> {
export interface IconProps {
  /**
   * `label` - label of the icon.
   */
  label: string;
  /**
   * `size` - size of the icon.
   */
  size?: number;
  /**
   * `color` - color of the icon.
   */
  color?: string;
  /**
   * `circleColor` - color of the circle around the icon.
   */
  circleColor?: string;
  /**
   * `children` - actual svg code.
   */
  children: ReactNode;
  /**
   * `viewBox` - viewBox of the svg.
   */
  viewBox?: string;
}
