import React from 'react';
import clsx from 'clsx';
import MuiCollapse, {
  CollapseProps as MuiCollapseProps,
} from '@mui/material/Collapse';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Collapse = withCoreProps<CollapseProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<CollapseProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiCollapse {...props} className={clsxClass}>
        {children}
      </MuiCollapse>
    );
  },
);

export interface CollapseProps extends MuiCollapseProps, CoreProps {}
