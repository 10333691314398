/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityAssetIpAddressesHosting } from './ControlEntityAssetIpAddressesHosting';
import {
    ControlEntityAssetIpAddressesHostingFromJSON,
    ControlEntityAssetIpAddressesHostingFromJSONTyped,
    ControlEntityAssetIpAddressesHostingToJSON,
} from './ControlEntityAssetIpAddressesHosting';
import type { ControlEntityAssetsIpAddressesGeoip } from './ControlEntityAssetsIpAddressesGeoip';
import {
    ControlEntityAssetsIpAddressesGeoipFromJSON,
    ControlEntityAssetsIpAddressesGeoipFromJSONTyped,
    ControlEntityAssetsIpAddressesGeoipToJSON,
} from './ControlEntityAssetsIpAddressesGeoip';

/**
 * 
 * @export
 * @interface ControlEntityAssetsIpAddressesResult
 */
export interface ControlEntityAssetsIpAddressesResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    ip_address?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    source_asset?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    as_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    as_class?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    rdns?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    rdns_parent?: string;
    /**
     * 
     * @type {ControlEntityAssetsIpAddressesGeoip}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    geoip?: ControlEntityAssetsIpAddressesGeoip;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    report_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    first_detected?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    updated?: string;
    /**
     * 
     * @type {Array<ControlEntityAssetIpAddressesHosting>}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    hosting?: Array<ControlEntityAssetIpAddressesHosting>;
    /**
     * 
     * @type {Array<ControlEntityAssetIpAddressesHosting>}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    blocklists?: Array<ControlEntityAssetIpAddressesHosting>;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    findings_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    max_level?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    is_custom_ip?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityAssetsIpAddressesResult
     */
    custom_ip_info?: object;
}

/**
 * Check if a given object implements the ControlEntityAssetsIpAddressesResult interface.
 */
export function instanceOfControlEntityAssetsIpAddressesResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityAssetsIpAddressesResultFromJSON(json: any): ControlEntityAssetsIpAddressesResult {
    return ControlEntityAssetsIpAddressesResultFromJSONTyped(json, false);
}

export function ControlEntityAssetsIpAddressesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetsIpAddressesResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'as_class': !exists(json, 'as_class') ? undefined : json['as_class'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'rdns': !exists(json, 'rdns') ? undefined : json['rdns'],
        'rdns_parent': !exists(json, 'rdns_parent') ? undefined : json['rdns_parent'],
        'geoip': !exists(json, 'geoip') ? undefined : ControlEntityAssetsIpAddressesGeoipFromJSON(json['geoip']),
        'report_status': !exists(json, 'report_status') ? undefined : json['report_status'],
        'first_detected': !exists(json, 'first_detected') ? undefined : json['first_detected'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'hosting': !exists(json, 'hosting') ? undefined : ((json['hosting'] as Array<any>).map(ControlEntityAssetIpAddressesHostingFromJSON)),
        'blocklists': !exists(json, 'blocklists') ? undefined : ((json['blocklists'] as Array<any>).map(ControlEntityAssetIpAddressesHostingFromJSON)),
        'findings_count': !exists(json, 'findings_count') ? undefined : json['findings_count'],
        'max_level': !exists(json, 'max_level') ? undefined : json['max_level'],
        'is_custom_ip': !exists(json, 'is_custom_ip') ? undefined : json['is_custom_ip'],
        'custom_ip_info': !exists(json, 'custom_ip_info') ? undefined : json['custom_ip_info'],
    };
}

export function ControlEntityAssetsIpAddressesResultToJSON(value?: ControlEntityAssetsIpAddressesResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_logo_url': value.entity_logo_url,
        'ip_address': value.ip_address,
        'version': value.version,
        'source': value.source,
        'source_asset': value.source_asset,
        'domains': value.domains,
        'as_number': value.as_number,
        'as_name': value.as_name,
        'isp': value.isp,
        'as_class': value.as_class,
        'shared': value.shared,
        'rdns': value.rdns,
        'rdns_parent': value.rdns_parent,
        'geoip': ControlEntityAssetsIpAddressesGeoipToJSON(value.geoip),
        'report_status': value.report_status,
        'first_detected': value.first_detected,
        'created': value.created,
        'updated': value.updated,
        'hosting': value.hosting === undefined ? undefined : ((value.hosting as Array<any>).map(ControlEntityAssetIpAddressesHostingToJSON)),
        'blocklists': value.blocklists === undefined ? undefined : ((value.blocklists as Array<any>).map(ControlEntityAssetIpAddressesHostingToJSON)),
        'findings_count': value.findings_count,
        'max_level': value.max_level,
        'is_custom_ip': value.is_custom_ip,
        'custom_ip_info': value.custom_ip_info,
    };
}

