/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRAAggregationFindingAssets } from './CRAAggregationFindingAssets';
import {
    CRAAggregationFindingAssetsFromJSON,
    CRAAggregationFindingAssetsFromJSONTyped,
    CRAAggregationFindingAssetsToJSON,
} from './CRAAggregationFindingAssets';

/**
 * 
 * @export
 * @interface CRAAggregationFindings
 */
export interface CRAAggregationFindings {
    /**
     * 
     * @type {Array<CRAAggregationFindingAssets>}
     * @memberof CRAAggregationFindings
     */
    assets?: Array<CRAAggregationFindingAssets>;
    /**
     * 
     * @type {number}
     * @memberof CRAAggregationFindings
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof CRAAggregationFindings
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof CRAAggregationFindings
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CRAAggregationFindings
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof CRAAggregationFindings
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRAAggregationFindings
     */
    recommendation: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRAAggregationFindings
     */
    references: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRAAggregationFindings
     */
    risk_score: number;
}

/**
 * Check if a given object implements the CRAAggregationFindings interface.
 */
export function instanceOfCRAAggregationFindings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "recommendation" in value;
    isInstance = isInstance && "references" in value;
    isInstance = isInstance && "risk_score" in value;

    return isInstance;
}

export function CRAAggregationFindingsFromJSON(json: any): CRAAggregationFindings {
    return CRAAggregationFindingsFromJSONTyped(json, false);
}

export function CRAAggregationFindingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAAggregationFindings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(CRAAggregationFindingAssetsFromJSON)),
        'count': json['count'],
        'datapoint': json['datapoint'],
        'description': json['description'],
        'level': json['level'],
        'name': json['name'],
        'recommendation': json['recommendation'],
        'references': json['references'],
        'risk_score': json['risk_score'],
    };
}

export function CRAAggregationFindingsToJSON(value?: CRAAggregationFindings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(CRAAggregationFindingAssetsToJSON)),
        'count': value.count,
        'datapoint': value.datapoint,
        'description': value.description,
        'level': value.level,
        'name': value.name,
        'recommendation': value.recommendation,
        'references': value.references,
        'risk_score': value.risk_score,
    };
}

