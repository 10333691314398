/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityContactResult } from './MonitEntityContactResult';
import {
    MonitEntityContactResultFromJSON,
    MonitEntityContactResultFromJSONTyped,
    MonitEntityContactResultToJSON,
} from './MonitEntityContactResult';

/**
 * 
 * @export
 * @interface MonitAddOrUpdateEntityContactResponse
 */
export interface MonitAddOrUpdateEntityContactResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    contact_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    immutable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    cadency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitAddOrUpdateEntityContactResponse
     */
    contact_type?: string;
}

/**
 * Check if a given object implements the MonitAddOrUpdateEntityContactResponse interface.
 */
export function instanceOfMonitAddOrUpdateEntityContactResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitAddOrUpdateEntityContactResponseFromJSON(json: any): MonitAddOrUpdateEntityContactResponse {
    return MonitAddOrUpdateEntityContactResponseFromJSONTyped(json, false);
}

export function MonitAddOrUpdateEntityContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitAddOrUpdateEntityContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'contact_id': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'immutable': !exists(json, 'immutable') ? undefined : json['immutable'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'cadency': !exists(json, 'cadency') ? undefined : json['cadency'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'contact_type': !exists(json, 'contact_type') ? undefined : json['contact_type'],
    };
}

export function MonitAddOrUpdateEntityContactResponseToJSON(value?: MonitAddOrUpdateEntityContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'contact_id': value.contact_id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'immutable': value.immutable,
        'created': value.created,
        'updated': value.updated,
        'cadency': value.cadency,
        'disabled': value.disabled,
        'contact_type': value.contact_type,
    };
}

