/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRALocale = {
    EnUs: 'enUS',
    EnGb: 'enGB',
    EnCa: 'en-CA',
    EnAu: 'en-AU',
    FrCa: 'fr-CA'
} as const;
export type CRALocale = typeof CRALocale[keyof typeof CRALocale];


export function CRALocaleFromJSON(json: any): CRALocale {
    return CRALocaleFromJSONTyped(json, false);
}

export function CRALocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRALocale {
    return json as CRALocale;
}

export function CRALocaleToJSON(value?: CRALocale | null): any {
    return value as any;
}

