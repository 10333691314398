/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDataleakRiskSummary } from './CRSFSecReviewDataleakRiskSummary';
import {
    CRSFSecReviewDataleakRiskSummaryFromJSON,
    CRSFSecReviewDataleakRiskSummaryFromJSONTyped,
    CRSFSecReviewDataleakRiskSummaryToJSON,
} from './CRSFSecReviewDataleakRiskSummary';
import type { CRSFSecReviewDataleaksRiskSummaryObject } from './CRSFSecReviewDataleaksRiskSummaryObject';
import {
    CRSFSecReviewDataleaksRiskSummaryObjectFromJSON,
    CRSFSecReviewDataleaksRiskSummaryObjectFromJSONTyped,
    CRSFSecReviewDataleaksRiskSummaryObjectToJSON,
} from './CRSFSecReviewDataleaksRiskSummaryObject';

/**
 * 
 * @export
 * @interface RiskSummary1
 */
export interface RiskSummary1 {
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof RiskSummary1
     */
    all_time: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {string}
     * @memberof RiskSummary1
     */
    domain: string;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof RiskSummary1
     */
    previous_12_months: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof RiskSummary1
     */
    previous_1_month?: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof RiskSummary1
     */
    previous_6_months: CRSFSecReviewDataleaksRiskSummaryObject;
}

/**
 * Check if a given object implements the RiskSummary1 interface.
 */
export function instanceOfRiskSummary1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "all_time" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "previous_12_months" in value;
    isInstance = isInstance && "previous_6_months" in value;

    return isInstance;
}

export function RiskSummary1FromJSON(json: any): RiskSummary1 {
    return RiskSummary1FromJSONTyped(json, false);
}

export function RiskSummary1FromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskSummary1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all_time': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['all_time']),
        'domain': json['domain'],
        'previous_12_months': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_12_months']),
        'previous_1_month': !exists(json, 'previous_1_month') ? undefined : CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_1_month']),
        'previous_6_months': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_6_months']),
    };
}

export function RiskSummary1ToJSON(value?: RiskSummary1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all_time': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.all_time),
        'domain': value.domain,
        'previous_12_months': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_12_months),
        'previous_1_month': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_1_month),
        'previous_6_months': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_6_months),
    };
}

