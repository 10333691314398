/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityMuteNotificationResponse
 */
export interface MonitEntityMuteNotificationResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMuteNotificationResponse
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntityMuteNotificationResponse interface.
 */
export function instanceOfMonitEntityMuteNotificationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityMuteNotificationResponseFromJSON(json: any): MonitEntityMuteNotificationResponse {
    return MonitEntityMuteNotificationResponseFromJSONTyped(json, false);
}

export function MonitEntityMuteNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityMuteNotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntityMuteNotificationResponseToJSON(value?: MonitEntityMuteNotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'entity_id': value.entity_id,
        'asset': value.asset,
        'datapoint': value.datapoint,
        'status': value.status,
        'reason': value.reason,
        'created': value.created,
        'updated': value.updated,
    };
}

