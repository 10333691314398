import React from 'react';
import clsx from 'clsx';
import MuiAccordionActions, {
  AccordionActionsProps as MuiAccordionActionsProps,
} from '@mui/material/AccordionActions';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const AccordionActions = withCoreProps<AccordionActionsProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<AccordionActionsProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAccordionActions {...props} className={clsxClass}>
        {children}
      </MuiAccordionActions>
    );
  },
);

export interface AccordionActionsProps
  extends MuiAccordionActionsProps,
    CoreProps {}
