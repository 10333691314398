import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
} from '@explorer/core';
import { formatMessage } from '@explorer/helpers';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * FieldPassword
 */
export const FieldPassword = ({
  label,
  autoComplete = 'new-password',
  setFieldValue,
  setFieldError,
  className,
  ...props
}: FieldPasswordProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = useCallback(() => setShowPassword((v) => !v), []);

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const passwordAdornment = useMemo<ReactNode>(() => {
    const iconStyles = { color: 'rgba(0, 0, 0, 0.40)' };

    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle Password Visibility"
          onClick={handleShowPassword}
          size="small"
        >
          {showPassword ? (
            <Visibility style={iconStyles} />
          ) : (
            <VisibilityOff style={iconStyles} />
          )}
        </IconButton>
      </InputAdornment>
    );
  }, [showPassword]);

  return (
    <TextField
      {...props}
      label={intlLabel}
      type={showPassword ? 'text' : 'password'}
      autoComplete={autoComplete}
      className={clsxClass}
      InputProps={{
        endAdornment: passwordAdornment,
      }}
    />
  );
};

/**
 * FieldPassword Props
 */
export type FieldPasswordProps = TextFieldProps & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `autoComplete` - google autocomplete prop
   */
  autoComplete?:
    | 'off'
    | 'new-password' // New Password
    | 'current-password'; // Current Password
};
