/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewStats
 */
export interface SecReviewStats {
    /**
     * 
     * @type {string}
     * @memberof SecReviewStats
     */
    data_type: string;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    risk_score?: number;
    /**
     * 
     * @type {object}
     * @memberof SecReviewStats
     */
    risk_score_v2?: object;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    risk_ranking?: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    peer_average?: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    assets_count: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    vulnerabilities_count: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewStats
     */
    dataleaks_count: number;
    /**
     * 
     * @type {string}
     * @memberof SecReviewStats
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewStats
     */
    entity_domain: string;
    /**
     * 
     * @type {object}
     * @memberof SecReviewStats
     */
    vulnerabilities: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewStats
     */
    surface: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewStats
     */
    cyber_benchmark: object;
    /**
     * 
     * @type {string}
     * @memberof SecReviewStats
     */
    updated?: string;
}

/**
 * Check if a given object implements the SecReviewStats interface.
 */
export function instanceOfSecReviewStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data_type" in value;
    isInstance = isInstance && "assets_count" in value;
    isInstance = isInstance && "vulnerabilities_count" in value;
    isInstance = isInstance && "dataleaks_count" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "entity_domain" in value;
    isInstance = isInstance && "vulnerabilities" in value;
    isInstance = isInstance && "surface" in value;
    isInstance = isInstance && "cyber_benchmark" in value;

    return isInstance;
}

export function SecReviewStatsFromJSON(json: any): SecReviewStats {
    return SecReviewStatsFromJSONTyped(json, false);
}

export function SecReviewStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data_type': json['data_type'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : json['risk_score_v2'],
        'risk_ranking': !exists(json, 'risk_ranking') ? undefined : json['risk_ranking'],
        'peer_average': !exists(json, 'peer_average') ? undefined : json['peer_average'],
        'assets_count': json['assets_count'],
        'vulnerabilities_count': json['vulnerabilities_count'],
        'dataleaks_count': json['dataleaks_count'],
        'process_id': json['process_id'],
        'entity_domain': json['entity_domain'],
        'vulnerabilities': json['vulnerabilities'],
        'surface': json['surface'],
        'cyber_benchmark': json['cyber_benchmark'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function SecReviewStatsToJSON(value?: SecReviewStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data_type': value.data_type,
        'risk_score': value.risk_score,
        'risk_score_v2': value.risk_score_v2,
        'risk_ranking': value.risk_ranking,
        'peer_average': value.peer_average,
        'assets_count': value.assets_count,
        'vulnerabilities_count': value.vulnerabilities_count,
        'dataleaks_count': value.dataleaks_count,
        'process_id': value.process_id,
        'entity_domain': value.entity_domain,
        'vulnerabilities': value.vulnerabilities,
        'surface': value.surface,
        'cyber_benchmark': value.cyber_benchmark,
        'updated': value.updated,
    };
}

