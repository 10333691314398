/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormInstanceStatus } from './SupplementalFormInstanceStatus';
import {
    SupplementalFormInstanceStatusFromJSON,
    SupplementalFormInstanceStatusFromJSONTyped,
    SupplementalFormInstanceStatusToJSON,
} from './SupplementalFormInstanceStatus';
import type { SupplementalUserType } from './SupplementalUserType';
import {
    SupplementalUserTypeFromJSON,
    SupplementalUserTypeFromJSONTyped,
    SupplementalUserTypeToJSON,
} from './SupplementalUserType';

/**
 * 
 * @export
 * @interface SupplementalRecipientLog
 */
export interface SupplementalRecipientLog {
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipientLog
     */
    recipient_log_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipientLog
     */
    form_instance_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipientLog
     */
    recipient_id?: string;
    /**
     * 
     * @type {SupplementalUserType}
     * @memberof SupplementalRecipientLog
     */
    user_type?: SupplementalUserType;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipientLog
     */
    details?: string | null;
    /**
     * 
     * @type {SupplementalFormInstanceStatus}
     * @memberof SupplementalRecipientLog
     */
    activity?: SupplementalFormInstanceStatus;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipientLog
     */
    created_at?: string;
}

/**
 * Check if a given object implements the SupplementalRecipientLog interface.
 */
export function instanceOfSupplementalRecipientLog(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalRecipientLogFromJSON(json: any): SupplementalRecipientLog {
    return SupplementalRecipientLogFromJSONTyped(json, false);
}

export function SupplementalRecipientLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalRecipientLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient_log_id': !exists(json, 'recipient_log_id') ? undefined : json['recipient_log_id'],
        'form_instance_id': !exists(json, 'form_instance_id') ? undefined : json['form_instance_id'],
        'recipient_id': !exists(json, 'recipient_id') ? undefined : json['recipient_id'],
        'user_type': !exists(json, 'user_type') ? undefined : SupplementalUserTypeFromJSON(json['user_type']),
        'details': !exists(json, 'details') ? undefined : json['details'],
        'activity': !exists(json, 'activity') ? undefined : SupplementalFormInstanceStatusFromJSON(json['activity']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function SupplementalRecipientLogToJSON(value?: SupplementalRecipientLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_log_id': value.recipient_log_id,
        'form_instance_id': value.form_instance_id,
        'recipient_id': value.recipient_id,
        'user_type': SupplementalUserTypeToJSON(value.user_type),
        'details': value.details,
        'activity': SupplementalFormInstanceStatusToJSON(value.activity),
        'created_at': value.created_at,
    };
}

