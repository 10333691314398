/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewSREntityResultDataRequest } from './SecReviewSREntityResultDataRequest';
import {
    SecReviewSREntityResultDataRequestFromJSON,
    SecReviewSREntityResultDataRequestFromJSONTyped,
    SecReviewSREntityResultDataRequestToJSON,
} from './SecReviewSREntityResultDataRequest';
import type { SecReviewSREntityResultDataResponse } from './SecReviewSREntityResultDataResponse';
import {
    SecReviewSREntityResultDataResponseFromJSON,
    SecReviewSREntityResultDataResponseFromJSONTyped,
    SecReviewSREntityResultDataResponseToJSON,
} from './SecReviewSREntityResultDataResponse';

/**
 * 
 * @export
 * @interface SecReviewSREntityResultData
 */
export interface SecReviewSREntityResultData {
    /**
     * 
     * @type {SecReviewSREntityResultDataRequest}
     * @memberof SecReviewSREntityResultData
     */
    request?: SecReviewSREntityResultDataRequest;
    /**
     * 
     * @type {SecReviewSREntityResultDataResponse}
     * @memberof SecReviewSREntityResultData
     */
    response?: SecReviewSREntityResultDataResponse;
}

/**
 * Check if a given object implements the SecReviewSREntityResultData interface.
 */
export function instanceOfSecReviewSREntityResultData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSREntityResultDataFromJSON(json: any): SecReviewSREntityResultData {
    return SecReviewSREntityResultDataFromJSONTyped(json, false);
}

export function SecReviewSREntityResultDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSREntityResultData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : SecReviewSREntityResultDataRequestFromJSON(json['request']),
        'response': !exists(json, 'response') ? undefined : SecReviewSREntityResultDataResponseFromJSON(json['response']),
    };
}

export function SecReviewSREntityResultDataToJSON(value?: SecReviewSREntityResultData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': SecReviewSREntityResultDataRequestToJSON(value.request),
        'response': SecReviewSREntityResultDataResponseToJSON(value.response),
    };
}

