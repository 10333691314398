/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitGetEntityDomainsBlocklistsAgg
 */
export interface MonitGetEntityDomainsBlocklistsAgg {
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityDomainsBlocklistsAgg
     */
    process_id?: string;
}

/**
 * Check if a given object implements the MonitGetEntityDomainsBlocklistsAgg interface.
 */
export function instanceOfMonitGetEntityDomainsBlocklistsAgg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityDomainsBlocklistsAggFromJSON(json: any): MonitGetEntityDomainsBlocklistsAgg {
    return MonitGetEntityDomainsBlocklistsAggFromJSONTyped(json, false);
}

export function MonitGetEntityDomainsBlocklistsAggFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityDomainsBlocklistsAgg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
    };
}

export function MonitGetEntityDomainsBlocklistsAggToJSON(value?: MonitGetEntityDomainsBlocklistsAgg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
    };
}

