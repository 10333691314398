import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Typography, TypographyProps } from './Typography';
import { Tooltip } from '../Tooltip';
import { withCoreProps } from '../../withCoreProps';
import { formatDate, getRelativeTime } from '@explorer/helpers';

const useStyles = ({}: Partial<TypographyDateProps>) =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        tooltipText: {
          cursor: 'help',
          paddingBottom: '2px',
          borderBottomWidth: 2,
          borderBottomStyle: 'dotted',
          borderBottomColor: '#ccc',
          '&:hover': {
            borderBottomColor: palette.primary.main,
          },
        },
      }),
    { index: 1 },
  )();

export const TypographyDate = withCoreProps<TypographyDateProps>(
  ({
    date,
    format,
    tooltipPlacement = 'right',
    msUnix,
    className,
    ...props
  }: PropsWithChildren<TypographyDateProps>) => {
    const classes = useStyles({});
    const clsxClass = clsx(className, classes.tooltipText);

    if (!date) return null;

    return (
      <Tooltip
        title={getRelativeTime(date, msUnix)}
        placement={tooltipPlacement}
      >
        <Typography {...props} component="span" className={clsxClass}>
          {formatDate(date, format, msUnix)}
        </Typography>
      </Tooltip>
    );
  },
);

export interface TypographyDateProps extends Omit<TypographyProps, 'label'> {
  /**
   * `date` - date to be shown.
   */
  date?: string | number;
  /**
   * `format` - format of the date
   */
  format: string;
  /**
   * `tooltipPlacement` - *OPTIONAL* - changes the position of the tooltip. Default: `right`
   */
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  /**
   * Unix MS
   */
  msUnix?: boolean;
}
