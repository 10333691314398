/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlBooleanFieldAnswer } from './ControlBooleanFieldAnswer';
import {
    ControlBooleanFieldAnswerFromJSON,
    ControlBooleanFieldAnswerFromJSONTyped,
    ControlBooleanFieldAnswerToJSON,
} from './ControlBooleanFieldAnswer';
import type { ControlMultiSelectFieldAnswer } from './ControlMultiSelectFieldAnswer';
import {
    ControlMultiSelectFieldAnswerFromJSON,
    ControlMultiSelectFieldAnswerFromJSONTyped,
    ControlMultiSelectFieldAnswerToJSON,
} from './ControlMultiSelectFieldAnswer';
import type { ControlSingleSelectFieldAnswer } from './ControlSingleSelectFieldAnswer';
import {
    ControlSingleSelectFieldAnswerFromJSON,
    ControlSingleSelectFieldAnswerFromJSONTyped,
    ControlSingleSelectFieldAnswerToJSON,
} from './ControlSingleSelectFieldAnswer';
import type { ControlTextFieldAnswer } from './ControlTextFieldAnswer';
import {
    ControlTextFieldAnswerFromJSON,
    ControlTextFieldAnswerFromJSONTyped,
    ControlTextFieldAnswerToJSON,
} from './ControlTextFieldAnswer';

/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    field_type?: AnswerFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    selected: string;
    /**
     * 
     * @type {boolean}
     * @memberof Answer
     */
    value: boolean;
}


/**
 * @export
 */
export const AnswerFieldTypeEnum = {
    Boolean: 'boolean'
} as const;
export type AnswerFieldTypeEnum = typeof AnswerFieldTypeEnum[keyof typeof AnswerFieldTypeEnum];


/**
 * Check if a given object implements the Answer interface.
 */
export function instanceOfAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "selected" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AnswerFromJSON(json: any): Answer {
    return AnswerFromJSONTyped(json, false);
}

export function AnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Answer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_type': !exists(json, 'field_type') ? undefined : json['field_type'],
        'text': json['text'],
        'selected': json['selected'],
        'value': json['value'],
    };
}

export function AnswerToJSON(value?: Answer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_type': value.field_type,
        'text': value.text,
        'selected': value.selected,
        'value': value.value,
    };
}

