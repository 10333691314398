/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFTechnologyItem } from './CRSFTechnologyItem';
import {
    CRSFTechnologyItemFromJSON,
    CRSFTechnologyItemFromJSONTyped,
    CRSFTechnologyItemToJSON,
} from './CRSFTechnologyItem';

/**
 * 
 * @export
 * @interface CRSFPathItem
 */
export interface CRSFPathItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFPathItem
     */
    Domain: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFPathItem
     */
    FirstIndexed: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFPathItem
     */
    LastIndexed: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFPathItem
     */
    SubDomain: string;
    /**
     * 
     * @type {Array<CRSFTechnologyItem>}
     * @memberof CRSFPathItem
     */
    Technologies: Array<CRSFTechnologyItem>;
    /**
     * 
     * @type {string}
     * @memberof CRSFPathItem
     */
    Url: string;
}

/**
 * Check if a given object implements the CRSFPathItem interface.
 */
export function instanceOfCRSFPathItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Domain" in value;
    isInstance = isInstance && "FirstIndexed" in value;
    isInstance = isInstance && "LastIndexed" in value;
    isInstance = isInstance && "SubDomain" in value;
    isInstance = isInstance && "Technologies" in value;
    isInstance = isInstance && "Url" in value;

    return isInstance;
}

export function CRSFPathItemFromJSON(json: any): CRSFPathItem {
    return CRSFPathItemFromJSONTyped(json, false);
}

export function CRSFPathItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFPathItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Domain': json['Domain'],
        'FirstIndexed': json['FirstIndexed'],
        'LastIndexed': json['LastIndexed'],
        'SubDomain': json['SubDomain'],
        'Technologies': ((json['Technologies'] as Array<any>).map(CRSFTechnologyItemFromJSON)),
        'Url': json['Url'],
    };
}

export function CRSFPathItemToJSON(value?: CRSFPathItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Domain': value.Domain,
        'FirstIndexed': value.FirstIndexed,
        'LastIndexed': value.LastIndexed,
        'SubDomain': value.SubDomain,
        'Technologies': ((value.Technologies as Array<any>).map(CRSFTechnologyItemToJSON)),
        'Url': value.Url,
    };
}

