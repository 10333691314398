/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlResolutionRequestBody
 */
export interface ControlResolutionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionRequestBody
     */
    datapoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlResolutionRequestBody
     */
    attestations: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlResolutionRequestBody
     */
    assets?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionRequestBody
     */
    notes?: string;
}

/**
 * Check if a given object implements the ControlResolutionRequestBody interface.
 */
export function instanceOfControlResolutionRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "attestations" in value;

    return isInstance;
}

export function ControlResolutionRequestBodyFromJSON(json: any): ControlResolutionRequestBody {
    return ControlResolutionRequestBodyFromJSONTyped(json, false);
}

export function ControlResolutionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResolutionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoint': json['datapoint'],
        'attestations': json['attestations'],
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function ControlResolutionRequestBodyToJSON(value?: ControlResolutionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoint': value.datapoint,
        'attestations': value.attestations,
        'assets': value.assets,
        'notes': value.notes,
    };
}

