/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskZoomInfoCorpHierarchy
 */
export interface ExecRiskZoomInfoCorpHierarchy {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    acquisition_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    is_public: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoCorpHierarchy
     */
    ticker?: string;
}

/**
 * Check if a given object implements the ExecRiskZoomInfoCorpHierarchy interface.
 */
export function instanceOfExecRiskZoomInfoCorpHierarchy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_public" in value;

    return isInstance;
}

export function ExecRiskZoomInfoCorpHierarchyFromJSON(json: any): ExecRiskZoomInfoCorpHierarchy {
    return ExecRiskZoomInfoCorpHierarchyFromJSONTyped(json, false);
}

export function ExecRiskZoomInfoCorpHierarchyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskZoomInfoCorpHierarchy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'acquisition_date': !exists(json, 'acquisition_date') ? undefined : json['acquisition_date'],
        'is_public': json['is_public'],
        'ticker': !exists(json, 'ticker') ? undefined : json['ticker'],
    };
}

export function ExecRiskZoomInfoCorpHierarchyToJSON(value?: ExecRiskZoomInfoCorpHierarchy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'city': value.city,
        'state': value.state,
        'acquisition_date': value.acquisition_date,
        'is_public': value.is_public,
        'ticker': value.ticker,
    };
}

