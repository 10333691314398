/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityMalwareDataSample
 */
export interface MonitEntityMalwareDataSample {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    last_found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMalwareDataSample
     */
    asset?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityMalwareDataSample
     */
    shared?: boolean;
}

/**
 * Check if a given object implements the MonitEntityMalwareDataSample interface.
 */
export function instanceOfMonitEntityMalwareDataSample(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityMalwareDataSampleFromJSON(json: any): MonitEntityMalwareDataSample {
    return MonitEntityMalwareDataSampleFromJSONTyped(json, false);
}

export function MonitEntityMalwareDataSampleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityMalwareDataSample {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
    };
}

export function MonitEntityMalwareDataSampleToJSON(value?: MonitEntityMalwareDataSample | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'region': value.region,
        'source_list_name': value.source_list_name,
        'category': value.category,
        'created': value.created,
        'last_found': value.last_found,
        'asset': value.asset,
        'shared': value.shared,
    };
}

