/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlDomainValidationMethods } from './ControlDomainValidationMethods';
import {
    ControlDomainValidationMethodsFromJSON,
    ControlDomainValidationMethodsFromJSONTyped,
    ControlDomainValidationMethodsToJSON,
} from './ControlDomainValidationMethods';

/**
 * 
 * @export
 * @interface ControlCustomDomainRequest
 */
export interface ControlCustomDomainRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainRequest
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainRequest
     */
    domain: string;
    /**
     * 
     * @type {ControlDomainValidationMethods}
     * @memberof ControlCustomDomainRequest
     */
    verification_method: ControlDomainValidationMethods;
}

/**
 * Check if a given object implements the ControlCustomDomainRequest interface.
 */
export function instanceOfControlCustomDomainRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "verification_method" in value;

    return isInstance;
}

export function ControlCustomDomainRequestFromJSON(json: any): ControlCustomDomainRequest {
    return ControlCustomDomainRequestFromJSONTyped(json, false);
}

export function ControlCustomDomainRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCustomDomainRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'domain': json['domain'],
        'verification_method': ControlDomainValidationMethodsFromJSON(json['verification_method']),
    };
}

export function ControlCustomDomainRequestToJSON(value?: ControlCustomDomainRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'domain': value.domain,
        'verification_method': ControlDomainValidationMethodsToJSON(value.verification_method),
    };
}

