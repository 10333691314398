/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    AverageMonthlyFMVOfSecGrpPriorYearAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    AverageMonthlyFMVOfSecGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    AverageMonthlyCashBalancesGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    FMVOtherNonExemptUseAssetGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    TotalFMVOfNonExemptUseAssetGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    DiscountClaimedAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    AcquisitionIndebtednessGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    AdjustedFMVLessIndebtednessGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    CashDeemedCharitableGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    NetVlNonExemptUseAssetsGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    PctOfNetVlNonExemptUseAstGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    RecoveriesPYDistriMinAssetGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount
     */
    TotalMinimumAssetGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AverageMonthlyFMVOfSecGrpPriorYearAmt': !exists(json, 'AverageMonthlyFMVOfSecGrpPriorYearAmt') ? undefined : json['AverageMonthlyFMVOfSecGrpPriorYearAmt'],
        'AverageMonthlyFMVOfSecGrp': !exists(json, 'AverageMonthlyFMVOfSecGrp') ? undefined : ((json['AverageMonthlyFMVOfSecGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'AverageMonthlyCashBalancesGrp': !exists(json, 'AverageMonthlyCashBalancesGrp') ? undefined : ((json['AverageMonthlyCashBalancesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'FMVOtherNonExemptUseAssetGrp': !exists(json, 'FMVOtherNonExemptUseAssetGrp') ? undefined : ((json['FMVOtherNonExemptUseAssetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'TotalFMVOfNonExemptUseAssetGrp': !exists(json, 'TotalFMVOfNonExemptUseAssetGrp') ? undefined : ((json['TotalFMVOfNonExemptUseAssetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'DiscountClaimedAmt': !exists(json, 'DiscountClaimedAmt') ? undefined : json['DiscountClaimedAmt'],
        'AcquisitionIndebtednessGrp': !exists(json, 'AcquisitionIndebtednessGrp') ? undefined : ((json['AcquisitionIndebtednessGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'AdjustedFMVLessIndebtednessGrp': !exists(json, 'AdjustedFMVLessIndebtednessGrp') ? undefined : ((json['AdjustedFMVLessIndebtednessGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'CashDeemedCharitableGrp': !exists(json, 'CashDeemedCharitableGrp') ? undefined : ((json['CashDeemedCharitableGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'NetVlNonExemptUseAssetsGrp': !exists(json, 'NetVlNonExemptUseAssetsGrp') ? undefined : ((json['NetVlNonExemptUseAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'PctOfNetVlNonExemptUseAstGrp': !exists(json, 'PctOfNetVlNonExemptUseAstGrp') ? undefined : ((json['PctOfNetVlNonExemptUseAstGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'RecoveriesPYDistriMinAssetGrp': !exists(json, 'RecoveriesPYDistriMinAssetGrp') ? undefined : ((json['RecoveriesPYDistriMinAssetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'TotalMinimumAssetGrp': !exists(json, 'TotalMinimumAssetGrp') ? undefined : ((json['TotalMinimumAssetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AverageMonthlyFMVOfSecGrpPriorYearAmt': value.AverageMonthlyFMVOfSecGrpPriorYearAmt,
        'AverageMonthlyFMVOfSecGrp': value.AverageMonthlyFMVOfSecGrp === undefined ? undefined : ((value.AverageMonthlyFMVOfSecGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'AverageMonthlyCashBalancesGrp': value.AverageMonthlyCashBalancesGrp === undefined ? undefined : ((value.AverageMonthlyCashBalancesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'FMVOtherNonExemptUseAssetGrp': value.FMVOtherNonExemptUseAssetGrp === undefined ? undefined : ((value.FMVOtherNonExemptUseAssetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'TotalFMVOfNonExemptUseAssetGrp': value.TotalFMVOfNonExemptUseAssetGrp === undefined ? undefined : ((value.TotalFMVOfNonExemptUseAssetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'DiscountClaimedAmt': value.DiscountClaimedAmt,
        'AcquisitionIndebtednessGrp': value.AcquisitionIndebtednessGrp === undefined ? undefined : ((value.AcquisitionIndebtednessGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'AdjustedFMVLessIndebtednessGrp': value.AdjustedFMVLessIndebtednessGrp === undefined ? undefined : ((value.AdjustedFMVLessIndebtednessGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'CashDeemedCharitableGrp': value.CashDeemedCharitableGrp === undefined ? undefined : ((value.CashDeemedCharitableGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'NetVlNonExemptUseAssetsGrp': value.NetVlNonExemptUseAssetsGrp === undefined ? undefined : ((value.NetVlNonExemptUseAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'PctOfNetVlNonExemptUseAstGrp': value.PctOfNetVlNonExemptUseAstGrp === undefined ? undefined : ((value.PctOfNetVlNonExemptUseAstGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'RecoveriesPYDistriMinAssetGrp': value.RecoveriesPYDistriMinAssetGrp === undefined ? undefined : ((value.RecoveriesPYDistriMinAssetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'TotalMinimumAssetGrp': value.TotalMinimumAssetGrp === undefined ? undefined : ((value.TotalMinimumAssetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
    };
}

