/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityHoneypotsData
 */
export interface ControlEntityHoneypotsData {
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityHoneypotsData
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    raw?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityHoneypotsData
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityHoneypotsData
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    event_key?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityHoneypotsData
     */
    found?: string;
}

/**
 * Check if a given object implements the ControlEntityHoneypotsData interface.
 */
export function instanceOfControlEntityHoneypotsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityHoneypotsDataFromJSON(json: any): ControlEntityHoneypotsData {
    return ControlEntityHoneypotsDataFromJSONTyped(json, false);
}

export function ControlEntityHoneypotsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityHoneypotsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'event_key': !exists(json, 'event_key') ? undefined : json['event_key'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'found': !exists(json, 'found') ? undefined : json['found'],
    };
}

export function ControlEntityHoneypotsDataToJSON(value?: ControlEntityHoneypotsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keywords': value.keywords,
        'raw': value.raw,
        'tags': value.tags,
        'protocol': value.protocol,
        'asset': value.asset,
        'updated': value.updated,
        'entity_id': value.entity_id,
        'created': value.created,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'port': value.port,
        'event_key': value.event_key,
        'process_id': value.process_id,
        'found': value.found,
    };
}

