import { Gradient } from '../types';

export const RISKV2_GRADIENT: Gradient = {
  css: '',
  colors: [
    '#56e68f',
    '#5ee68e',
    '#6de68c',
    '#7ce688',
    '#8be586',
    '#9be684',
    '#aae681',
    '#bae67e',
    '#c8e57b',
    '#d8e679',
    '#e7e576',
    '#f1e574',
    '#ffc266',
    '#ffb966',
    '#ffad66',
    '#ffa266',
    '#ff9666',
    '#ff8a66',
    '#ff7e66',
    '#ff7266',
  ],
};
