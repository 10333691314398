import React from 'react';
import { TypographyLink, TypographyLinkProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellLink
 */
export const CellLink = ({
  text,
  href,
  title,
  variant = 'bodyXS',
  textColor = undefined,
  monospace = false,
  linkIcon = 'external',
}: CellLinkProps) => {
  if (!text || !href) return <CellEmpty variant={variant} />;

  return (
    <TypographyLink
      href={href}
      title={title}
      variant={variant}
      monospace={monospace}
      textColor={textColor}
      linkIcon={linkIcon}
      onClick={(e: any) => e.stopPropagation()}
      newTab
    >
      {text}
    </TypographyLink>
  );
};

/**
 * CellLink Props
 */
export interface CellLinkProps
  extends Pick<
    TypographyLinkProps,
    'href' | 'title' | 'variant' | 'monospace' | 'textColor' | 'linkIcon'
  > {
  text: string;
}
