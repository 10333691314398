/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Answer } from './Answer';
import {
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './Answer';
import type { ControlSuggestedAnswerSource } from './ControlSuggestedAnswerSource';
import {
    ControlSuggestedAnswerSourceFromJSON,
    ControlSuggestedAnswerSourceFromJSONTyped,
    ControlSuggestedAnswerSourceToJSON,
} from './ControlSuggestedAnswerSource';

/**
 * 
 * @export
 * @interface ControlSaveAnswerRequest
 */
export interface ControlSaveAnswerRequest {
    /**
     * 
     * @type {Answer}
     * @memberof ControlSaveAnswerRequest
     */
    answer: Answer;
    /**
     * 
     * @type {ControlSuggestedAnswerSource}
     * @memberof ControlSaveAnswerRequest
     */
    source?: ControlSuggestedAnswerSource;
}

/**
 * Check if a given object implements the ControlSaveAnswerRequest interface.
 */
export function instanceOfControlSaveAnswerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "answer" in value;

    return isInstance;
}

export function ControlSaveAnswerRequestFromJSON(json: any): ControlSaveAnswerRequest {
    return ControlSaveAnswerRequestFromJSONTyped(json, false);
}

export function ControlSaveAnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSaveAnswerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': AnswerFromJSON(json['answer']),
        'source': !exists(json, 'source') ? undefined : ControlSuggestedAnswerSourceFromJSON(json['source']),
    };
}

export function ControlSaveAnswerRequestToJSON(value?: ControlSaveAnswerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': AnswerToJSON(value.answer),
        'source': ControlSuggestedAnswerSourceToJSON(value.source),
    };
}

