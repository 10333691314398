/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityStatsSurfaceCategory } from './ControlEntityStatsSurfaceCategory';
import {
    ControlEntityStatsSurfaceCategoryFromJSON,
    ControlEntityStatsSurfaceCategoryFromJSONTyped,
    ControlEntityStatsSurfaceCategoryToJSON,
} from './ControlEntityStatsSurfaceCategory';

/**
 * 
 * @export
 * @interface ControlEntitySurfaceType
 */
export interface ControlEntitySurfaceType {
    /**
     * 
     * @type {number}
     * @memberof ControlEntitySurfaceType
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityStatsSurfaceCategory>}
     * @memberof ControlEntitySurfaceType
     */
    categories?: Array<ControlEntityStatsSurfaceCategory>;
}

/**
 * Check if a given object implements the ControlEntitySurfaceType interface.
 */
export function instanceOfControlEntitySurfaceType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntitySurfaceTypeFromJSON(json: any): ControlEntitySurfaceType {
    return ControlEntitySurfaceTypeFromJSONTyped(json, false);
}

export function ControlEntitySurfaceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntitySurfaceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(ControlEntityStatsSurfaceCategoryFromJSON)),
    };
}

export function ControlEntitySurfaceTypeToJSON(value?: ControlEntitySurfaceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(ControlEntityStatsSurfaceCategoryToJSON)),
    };
}

