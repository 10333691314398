/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityNotificationsResult
 */
export interface ControlEntityNotificationsResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    notification_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    contact_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    recipient?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    status_external?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    sent_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityNotificationsResult
     */
    created?: string;
}

/**
 * Check if a given object implements the ControlEntityNotificationsResult interface.
 */
export function instanceOfControlEntityNotificationsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityNotificationsResultFromJSON(json: any): ControlEntityNotificationsResult {
    return ControlEntityNotificationsResultFromJSONTyped(json, false);
}

export function ControlEntityNotificationsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityNotificationsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'notification_type': !exists(json, 'notification_type') ? undefined : json['notification_type'],
        'contact_type': !exists(json, 'contact_type') ? undefined : json['contact_type'],
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'status_external': !exists(json, 'status_external') ? undefined : json['status_external'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'sent_at': !exists(json, 'sent_at') ? undefined : json['sent_at'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function ControlEntityNotificationsResultToJSON(value?: ControlEntityNotificationsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_domain': value.entity_domain,
        'entity_name': value.entity_name,
        'notification_type': value.notification_type,
        'contact_type': value.contact_type,
        'recipient': value.recipient,
        'status': value.status,
        'status_external': value.status_external,
        'reason': value.reason,
        'sent_at': value.sent_at,
        'created': value.created,
    };
}

