/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlLocale = {
    EnUs: 'en-US',
    EnAu: 'en-AU',
    EnGb: 'en-GB',
    EnCa: 'en-CA',
    EnDe: 'en-DE',
    FrCa: 'fr-CA',
    DeDe: 'de-DE'
} as const;
export type ControlLocale = typeof ControlLocale[keyof typeof ControlLocale];


export function ControlLocaleFromJSON(json: any): ControlLocale {
    return ControlLocaleFromJSONTyped(json, false);
}

export function ControlLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlLocale {
    return json as ControlLocale;
}

export function ControlLocaleToJSON(value?: ControlLocale | null): any {
    return value as any;
}

