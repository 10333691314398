/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlCountFindingsInterval = {
    _050: '0-50',
    _51100: '51-100',
    _101500: '101-500',
    _500: '+500'
} as const;
export type ControlCountFindingsInterval = typeof ControlCountFindingsInterval[keyof typeof ControlCountFindingsInterval];


export function ControlCountFindingsIntervalFromJSON(json: any): ControlCountFindingsInterval {
    return ControlCountFindingsIntervalFromJSONTyped(json, false);
}

export function ControlCountFindingsIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCountFindingsInterval {
    return json as ControlCountFindingsInterval;
}

export function ControlCountFindingsIntervalToJSON(value?: ControlCountFindingsInterval | null): any {
    return value as any;
}

