/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Details } from './Details';
import {
    DetailsFromJSON,
    DetailsFromJSONTyped,
    DetailsToJSON,
} from './Details';

/**
 * 
 * @export
 * @interface NotificationsMessageForJiraService
 */
export interface NotificationsMessageForJiraService {
    /**
     * 
     * @type {Details}
     * @memberof NotificationsMessageForJiraService
     */
    details?: Details;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessageForJiraService
     */
    sent_at: string;
}

/**
 * Check if a given object implements the NotificationsMessageForJiraService interface.
 */
export function instanceOfNotificationsMessageForJiraService(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sent_at" in value;

    return isInstance;
}

export function NotificationsMessageForJiraServiceFromJSON(json: any): NotificationsMessageForJiraService {
    return NotificationsMessageForJiraServiceFromJSONTyped(json, false);
}

export function NotificationsMessageForJiraServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessageForJiraService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': !exists(json, 'details') ? undefined : DetailsFromJSON(json['details']),
        'sent_at': json['sent_at'],
    };
}

export function NotificationsMessageForJiraServiceToJSON(value?: NotificationsMessageForJiraService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': DetailsToJSON(value.details),
        'sent_at': value.sent_at,
    };
}

