import { format as formatFunc } from 'numerable';

/**
 * Formats a number with the given format.
 *
 * __> Example:__
 * ```ts
 * formatNumber(1337, '0,0');
 * // '1,337'
 * ```
 */

export const formatNumber: FormatNumber = (value, format = '0,0') => {
  return formatFunc(value, format);
};

type FormatNumber = (value: number, format?: string) => string;
