/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlUserJoinClientRequestStatus = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Denied: 'DENIED'
} as const;
export type ControlUserJoinClientRequestStatus = typeof ControlUserJoinClientRequestStatus[keyof typeof ControlUserJoinClientRequestStatus];


export function ControlUserJoinClientRequestStatusFromJSON(json: any): ControlUserJoinClientRequestStatus {
    return ControlUserJoinClientRequestStatusFromJSONTyped(json, false);
}

export function ControlUserJoinClientRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserJoinClientRequestStatus {
    return json as ControlUserJoinClientRequestStatus;
}

export function ControlUserJoinClientRequestStatusToJSON(value?: ControlUserJoinClientRequestStatus | null): any {
    return value as any;
}

