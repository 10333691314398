/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewSpiderAggregatedEvent } from './CRSFSecReviewSpiderAggregatedEvent';
import {
    CRSFSecReviewSpiderAggregatedEventFromJSON,
    CRSFSecReviewSpiderAggregatedEventFromJSONTyped,
    CRSFSecReviewSpiderAggregatedEventToJSON,
} from './CRSFSecReviewSpiderAggregatedEvent';

/**
 * 
 * @export
 * @interface CRSFSecReviewSpidersAggregatedResponse
 */
export interface CRSFSecReviewSpidersAggregatedResponse {
    /**
     * 
     * @type {Array<CRSFSecReviewSpiderAggregatedEvent>}
     * @memberof CRSFSecReviewSpidersAggregatedResponse
     */
    events?: Array<CRSFSecReviewSpiderAggregatedEvent>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSpidersAggregatedResponse
     */
    reported: boolean;
}

/**
 * Check if a given object implements the CRSFSecReviewSpidersAggregatedResponse interface.
 */
export function instanceOfCRSFSecReviewSpidersAggregatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reported" in value;

    return isInstance;
}

export function CRSFSecReviewSpidersAggregatedResponseFromJSON(json: any): CRSFSecReviewSpidersAggregatedResponse {
    return CRSFSecReviewSpidersAggregatedResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewSpidersAggregatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewSpidersAggregatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(CRSFSecReviewSpiderAggregatedEventFromJSON)),
        'reported': json['reported'],
    };
}

export function CRSFSecReviewSpidersAggregatedResponseToJSON(value?: CRSFSecReviewSpidersAggregatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(CRSFSecReviewSpiderAggregatedEventToJSON)),
        'reported': value.reported,
    };
}

