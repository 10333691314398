/**
 * Wrapper that handles all the Segment methods.
 *
 * __> Example:__
 * ```ts
 * segmentHandler.track('CTRL Email Entered');
 * ```
 */
export const segmentHandler: SegmentHandler = {
  page: (title) => {
    // @ts-ignore
    const { analytics, location } = window || {};

    if (analytics && location) {
      analytics.page({
        path: location?.pathname || '/',
        search: location?.search || '',
        url: location?.href || '',
        title,
      });
    }
  },
  identify: (user = {}) => {
    // @ts-ignore
    const { analytics } = window || {};
    const {
      broker,
      created,
      // email,
      entity_id,
      entity_logo_url,
      // entity_name,
      metrics,
      monit_type,
      policyholder,
      report_url,
      risk_score_v2,
      user_id,
      status,
    } = user || {};

    const { industry, number_of_employees } = metrics || {};

    // @ts-ignore
    if (analytics && user_id) {
      // @ts-ignore
      analytics.identify(user_id, {
        id: user_id,
        // email, // REMOVED BECAUSE OF LEGAL REASONS
        avatar: entity_logo_url,
        createdAt: new Date(created * 1000).toISOString(),
        company: {
          id: entity_id,
          // name: entity_name, // REMOVED BECAUSE OF LEGAL REASONS
          industry,
          employee_count: number_of_employees,
          plan: monit_type,
          status,
          risk_score_v2: risk_score_v2?.risk_score_v2,
          report_url,
        },
        policyholder,
        broker,
      });
    }
  },
  track: (eventName, properties) => {
    // @ts-ignore
    const { analytics } = window || {};

    // @ts-ignore
    if (analytics) {
      // @ts-ignore
      analytics.track(eventName, properties);
    }
  },
};

interface EventProperties {
  userId: string;
  [otherOptions: string]: unknown;
}

interface SegmentHandler {
  page: (title: string) => void;
  identify: (user?: any) => void;
  track: (eventName: EventNames, properties?: EventProperties) => void;
}

type EventNames =
  | 'Loaded a Page'
  | 'CTRL Add Alt Domains'
  | 'CTRL Add a company to watchlist'
  | 'CTRL Critical clicked'
  | 'CTRL Download CRA'
  | 'CTRL Download ERA'
  | 'CTRL Email Entered'
  | 'CTRL False positive asset reported'
  | 'CTRL Forgot PW Email Entered'
  | 'CTRL Forgot PW Link Clicked'
  | 'CTRL Get a Quote Clicked'
  | 'CTRL Hellosign Policy was declined'
  | 'CTRL Hellosign Policy was signed'
  | 'CTRL High clicked'
  | 'CTRL Invite Clicked'
  | 'CTRL Invite Email Entered'
  | 'CTRL Login Button Clicked'
  | 'CTRL Login Redirect Clicked'
  | 'CTRL Remove Risky Condition Item clicked'
  | 'CTRL Rescan vulnerability triggered'
  | 'CTRL Resolve vulnerability submitted'
  | 'CTRL Resource Grid Clicked'
  | 'CTRL Resource Hero Clicked'
  | 'CTRL Resource Search Query Changed'
  | 'CTRL Resource Category Filter Changed'
  | 'CTRL See all my recommendations'
  | 'CTRL Send Forgot PW Email Clicked'
  | 'CTRL Send Invite Email Clicked'
  | 'CTRL Signup Clicked'
  | 'CTRL Terms Accepted'
  | 'CTRL Upgrade Button Clicked'
  | 'CTRL View my company details clicked';
