/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    NameOfOrganization?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    OrganizationTypeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    DescriptionOfRelationship?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'NameOfOrganization': !exists(json, 'NameOfOrganization') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['NameOfOrganization']),
        'OrganizationTypeDesc': !exists(json, 'OrganizationTypeDesc') ? undefined : json['OrganizationTypeDesc'],
        'DescriptionOfRelationship': !exists(json, 'DescriptionOfRelationship') ? undefined : json['DescriptionOfRelationship'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'NameOfOrganization': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.NameOfOrganization),
        'OrganizationTypeDesc': value.OrganizationTypeDesc,
        'DescriptionOfRelationship': value.DescriptionOfRelationship,
    };
}

