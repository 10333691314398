/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFIpAddress
 */
export interface CRSFIpAddress {
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    as_class?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    as_class_t?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    as_name?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFIpAddress
     */
    as_number?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFIpAddress
     */
    blocklists?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    cidr?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFIpAddress
     */
    domains?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof CRSFIpAddress
     */
    geoip?: object;
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFIpAddress
     */
    hosting?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    ip_address: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    isp?: string;
    /**
     * 
     * @type {object}
     * @memberof CRSFIpAddress
     */
    proxy?: object;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    rdns?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    rdns_parent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFIpAddress
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFIpAddress
     */
    source_asset: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFIpAddress
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFIpAddress
     */
    version: number;
}

/**
 * Check if a given object implements the CRSFIpAddress interface.
 */
export function instanceOfCRSFIpAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ip_address" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "source_asset" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function CRSFIpAddressFromJSON(json: any): CRSFIpAddress {
    return CRSFIpAddressFromJSONTyped(json, false);
}

export function CRSFIpAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFIpAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'as_class': !exists(json, 'as_class') ? undefined : json['as_class'],
        'as_class_t': !exists(json, 'as_class_t') ? undefined : json['as_class_t'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'blocklists': !exists(json, 'blocklists') ? undefined : json['blocklists'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'geoip': !exists(json, 'geoip') ? undefined : json['geoip'],
        'hosting': !exists(json, 'hosting') ? undefined : json['hosting'],
        'ip_address': json['ip_address'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'proxy': !exists(json, 'proxy') ? undefined : json['proxy'],
        'rdns': !exists(json, 'rdns') ? undefined : json['rdns'],
        'rdns_parent': !exists(json, 'rdns_parent') ? undefined : json['rdns_parent'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source': json['source'],
        'source_asset': json['source_asset'],
        'updated': json['updated'],
        'version': json['version'],
    };
}

export function CRSFIpAddressToJSON(value?: CRSFIpAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'as_class': value.as_class,
        'as_class_t': value.as_class_t,
        'as_name': value.as_name,
        'as_number': value.as_number,
        'blocklists': value.blocklists,
        'cidr': value.cidr,
        'domains': value.domains,
        'geoip': value.geoip,
        'hosting': value.hosting,
        'ip_address': value.ip_address,
        'isp': value.isp,
        'proxy': value.proxy,
        'rdns': value.rdns,
        'rdns_parent': value.rdns_parent,
        'shared': value.shared,
        'source': value.source,
        'source_asset': value.source_asset,
        'updated': value.updated,
        'version': value.version,
    };
}

