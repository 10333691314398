/**
 * Returns a full description of a Scan Module.
 *
 * __> Example:__
 * ```ts
 * getScanModuleDescription('rdp');
 * // "The RDP module attempts to connect to an RDP server and take a screenshot of the display as well as extract the security level used, if any."
 * ```
 */

export const getScanModuleDescription: GetScanModuleDescription = (
  moduleName,
) => {
  if (!moduleName) return '';

  if (moduleName === 'service-simple') {
    return "The Service-Simple module attempts to connect to a remote server and identify service / product information by sending various payloads and analysing how the server responds. This module is much faster than the service module, since it doesn't perform any more actions than this.";
  }

  if (moduleName === 'service') {
    return 'The Service module attempts to connect to a remote server and identify service / product information by sending various payloads and analysing how the server responds as well as extract other available service information such as headers or hostnames if available.';
  }

  if (moduleName === 'malware-simple') {
    return 'The Malware-Simple module attempts to connect to a remote server and identify malware by sending various payloads and analysing how the server responds. It works similarly to the service-simple module except it is entirely focused at identifying malware instead of general service/product information.';
  }

  if (moduleName === 'rdp') {
    return 'The RDP module attempts to connect to an RDP server and take a screenshot of the display as well as extract the security level used, if any.';
  }

  if (moduleName === 'rdpeudp') {
    return 'The RDP: UDP Transport Extension module attempts to connect to an RDP server over UDP.';
  }

  if (moduleName === 'vnc') {
    return 'The VNC module attempts to connect to a VNC server and take a screenshot of the display as well as extract relevant information.';
  }

  if (moduleName === 'x11') {
    return 'The X11 module attempts to connect to a X11 server and take a screenshot of the display as well as extract relevant information.';
  }

  if (moduleName === 'bluekeep') {
    return 'The Bluekeep module attempts to determine if an RDP server is vulnerable to the Bluekeep vulnerability.';
  }

  if (moduleName === 'cassandra') {
    return 'The Cassandra module attempts to connect to a Cassandra server via client driver connection and extract cluster metadata as well as a list of keyspaces and respective tables.';
  }

  if (moduleName === 'elasticsearch') {
    return 'The Elasticsearch module attempts to connect to an Elasticsearch server via REST API and extract cluster metadata and stats as well as a list of indices.';
  }

  if (moduleName === 'memcached') {
    return 'The Memcached module attempts to connect to a Memcached server via client driver connection and extract server stats.';
  }

  if (moduleName === 'mongodb') {
    return 'The MongoDB module attempts to connect to a MongoDB server via client driver connection and extract server metadata as well as a list of databases and respective collections.';
  }

  if (moduleName === 'redis') {
    return 'The Redis module attempts to connect to a Redis server via client driver connection and extract server metadata.';
  }

  if (moduleName === 'mqtt') {
    return 'The MQTT module attempts to connect to a MQTT server and extract a few seconds of passing messages to determine active topics.';
  }

  if (moduleName === 'webv2') {
    return 'The Webv2 module attempts to connect to an HTTP server and extract HTTP headers, redirects, page title, favicon, HTML source code, the web technologies being used and take a screenshot of the web page. It combines and upgrades upon the functionality of http, https and web.';
  }

  if (moduleName === 'web-enrich') {
    return "The web-enrich module attempts to connect to an HTTP server and extract HTTP headers, redirects, HTML source code, the web technologies and enrich data that the module webv2 couldn't find or doesn't look for.";
  }

  if (moduleName === 'http-https') {
    return 'The HTTP/HTTPS modules attempt to connect to an HTTP server and extract HTTP headers, redirects, page title and HTML source code.';
  }

  if (moduleName === 'ssl-simple') {
    return "The SSL-Simple module attempts to connect to an SSL-wrapped server and extract (and parse) certificate chains. Recommended if you are only interested in certificates, since it is much faster than the sslv2 module as it doesn't need to do any additional testing.";
  }

  if (moduleName === 'sslv2') {
    return 'The SSLv2 module attempts to connect to an SSL-wrapped server and extract (and parse) certificate chains, ciphers and vulnerabilities. It is an upgraded version of the ssl module, and includes extra updates and features not available previously.';
  }

  if (moduleName === 'jarm') {
    return 'The JARM module attempts to actively fingerprint an SSL/TLS server via a series of TLS Client Hello packets to extract specific responses that can be used to quickly identify default applications or malware.';
  }

  if (moduleName === 'ssh') {
    return 'The SSH module attempts to connect to a SSH server and extract all the algorithms supported by the server.';
  }

  if (moduleName === 'rsync') {
    return 'The RSYNC module attempts to connect to an RSYNC server anonymously and list the available modules (list the contents at the root directory).';
  }

  if (moduleName === 'ftp') {
    return 'The FTP module attempts to connect to an FTP server anonymously and recursively list available directories.';
  }

  if (moduleName === 'telnet') {
    return 'The Telnet module attempts to connect to a server by opening a connection and extracting the initial payload, if any.';
  }

  if (moduleName === 'smb') {
    return 'The SMB module attempts to connect to a server with SMB by opening a connection and extracting dialects and shares, if any.';
  }

  if (moduleName === 'kubernetes') {
    return 'The Kubernetes module attempts to connect to a Kubernetes server via REST API and extract a list of pods and their respective metadata.';
  }

  return '';
};

type GetScanModuleDescription = (moduleName?: string) => string;
