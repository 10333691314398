/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';

/**
 * 
 * @export
 * @interface CRSFSecReviewValidationTriggerResponse
 */
export interface CRSFSecReviewValidationTriggerResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    account_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    datapoint?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    domains: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    issue?: string;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewValidationTriggerResponse
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewValidationTriggerResponse interface.
 */
export function instanceOfCRSFSecReviewValidationTriggerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSecReviewValidationTriggerResponseFromJSON(json: any): CRSFSecReviewValidationTriggerResponse {
    return CRSFSecReviewValidationTriggerResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewValidationTriggerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewValidationTriggerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'domains': json['domains'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'process_id': json['process_id'],
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSecReviewValidationTriggerResponseToJSON(value?: CRSFSecReviewValidationTriggerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'datapoint': value.datapoint,
        'domains': value.domains,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'issue': value.issue,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'process_id': value.process_id,
        'quote_id': value.quote_id,
        'request_origin': value.request_origin,
        'user': value.user,
    };
}

