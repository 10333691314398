import React, { ReactNode } from 'react';
import { Grid, Badge, TypographyProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellSeeMore
 */
export const CellSeeMore = ({
  content,
  contentLength = 0,
  variant = 'bodyXS',
  badgeColor = 'default',
  openDetails,
}: CellSeeMoreProps) => {
  if (!content || contentLength === 0) return <CellEmpty variant={variant} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>{content}</Grid>
      <Grid item>
        {contentLength > 1 && (
          <Badge
            noUserSelect
            onClick={openDetails}
            badgeContent={`+${contentLength - 1}`}
            color={badgeColor}
            style={{
              cursor: openDetails ? 'pointer' : 'default',
            }}
            ml={2.5}
          />
        )}
      </Grid>
    </Grid>
  );
};

/**
 * CellSeeMore Props
 */
export interface CellSeeMoreProps extends Pick<TypographyProps, 'variant'> {
  content: ReactNode;
  contentLength: number;
  badgeColor?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  openDetails?: () => void;
}
