/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    other_organization_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    transaction_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    involved_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    method_of_amount_determination_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    return ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'other_organization_name': !exists(json, 'other_organization_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['other_organization_name']),
        'transaction_type_txt': !exists(json, 'transaction_type_txt') ? undefined : json['transaction_type_txt'],
        'involved_amt': !exists(json, 'involved_amt') ? undefined : json['involved_amt'],
        'method_of_amount_determination_txt': !exists(json, 'method_of_amount_determination_txt') ? undefined : json['method_of_amount_determination_txt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'other_organization_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.other_organization_name),
        'transaction_type_txt': value.transaction_type_txt,
        'involved_amt': value.involved_amt,
        'method_of_amount_determination_txt': value.method_of_amount_determination_txt,
    };
}

