/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule
     */
    LineNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule
     */
    AmountInvolved?: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule
     */
    NameOfNoncharitableEO?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule
     */
    DescriptionOfTransfersEtc?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'LineNumber': !exists(json, 'LineNumber') ? undefined : json['LineNumber'],
        'AmountInvolved': !exists(json, 'AmountInvolved') ? undefined : json['AmountInvolved'],
        'NameOfNoncharitableEO': !exists(json, 'NameOfNoncharitableEO') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['NameOfNoncharitableEO']),
        'DescriptionOfTransfersEtc': !exists(json, 'DescriptionOfTransfersEtc') ? undefined : json['DescriptionOfTransfersEtc'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LineNumber': value.LineNumber,
        'AmountInvolved': value.AmountInvolved,
        'NameOfNoncharitableEO': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.NameOfNoncharitableEO),
        'DescriptionOfTransfersEtc': value.DescriptionOfTransfersEtc,
    };
}

