import React from 'react';
import clsx from 'clsx';
import MuiAccordionDetails, {
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const AccordionDetails = withCoreProps<AccordionDetailsProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<AccordionDetailsProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAccordionDetails {...props} className={clsxClass}>
        {children}
      </MuiAccordionDetails>
    );
  },
);

export interface AccordionDetailsProps
  extends MuiAccordionDetailsProps,
    CoreProps {}
