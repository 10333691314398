/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskScheduleRBase
 */
export interface ExecRiskScheduleRBase {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    sch_r_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    sch_r_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    sch_r_pn?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    sch_r_ein?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleRBase
     */
    pen_value_dstrb_pd_prpty_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_payor_01_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_payor_02_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_bnft_distrib_sngl_sum_cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_elec_satisfy_code_412_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_fndng_wvrs_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleRBase
     */
    pen_emplr_contrib_rqr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleRBase
     */
    pen_emplr_contrib_paid_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleRBase
     */
    pen_funding_deficiency_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_funding_deadline_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_chg_fndng_method_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_amdmt_incr_val_bnft_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_sec_repay_loan_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    esop_pref_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    esop_back_to_back_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    esop_stock_not_tradable_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_no_contrib_cur_yr_cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_no_contrib_prev_yr_cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_no_contrib_2nd_prev_yr_cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_no_contrib_cur_prev_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_no_contr_cur_2nd_prev_prc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_emplrs_withdrw_prev_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleRBase
     */
    pen_withdrw_liab_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_asset_liab_transfer_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_liab_mult_plans_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_stock_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_invst_grade_debt_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_hi_yld_debt_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_real_estate_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_oth_asset_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_average_duration_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_duration_measure_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_other_duration_type_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    f_401k_plan_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    f_401k_satisfy_rqmts_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    adp_acp_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    mthd_used_satisfy_rqmts_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    plan_satisfy_tests_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    plan_timely_amended_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    last_plan_amendment_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    tax_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    last_opin_advisory_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    last_opin_advisory_serial_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    fav_determ_ltr_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    plan_maintain_us_territory_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_401k_design_based_safe_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_401k_prior_year_adp_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_401k_current_year_adp_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_401k_na_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_mthd_ratio_prcnt_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_mthd_avg_bnft_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_mthd_na_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_unpaid_min_rqd_contrib_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_pbgc_notified_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_pbgc_notified_explan_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_counting_method_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_change_prev_yr_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleRBase
     */
    pen_change_2nd_prev_yr_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskScheduleRBase interface.
 */
export function instanceOfExecRiskScheduleRBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleRBaseFromJSON(json: any): ExecRiskScheduleRBase {
    return ExecRiskScheduleRBaseFromJSONTyped(json, false);
}

export function ExecRiskScheduleRBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleRBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'sch_r_plan_year_begin_date': !exists(json, 'sch_r_plan_year_begin_date') ? undefined : json['sch_r_plan_year_begin_date'],
        'sch_r_tax_prd': !exists(json, 'sch_r_tax_prd') ? undefined : json['sch_r_tax_prd'],
        'sch_r_pn': !exists(json, 'sch_r_pn') ? undefined : json['sch_r_pn'],
        'sch_r_ein': !exists(json, 'sch_r_ein') ? undefined : json['sch_r_ein'],
        'pen_value_dstrb_pd_prpty_amt': !exists(json, 'pen_value_dstrb_pd_prpty_amt') ? undefined : json['pen_value_dstrb_pd_prpty_amt'],
        'pen_payor_01_ein': !exists(json, 'pen_payor_01_ein') ? undefined : json['pen_payor_01_ein'],
        'pen_payor_02_ein': !exists(json, 'pen_payor_02_ein') ? undefined : json['pen_payor_02_ein'],
        'pen_bnft_distrib_sngl_sum_cnt': !exists(json, 'pen_bnft_distrib_sngl_sum_cnt') ? undefined : json['pen_bnft_distrib_sngl_sum_cnt'],
        'pen_elec_satisfy_code_412_ind': !exists(json, 'pen_elec_satisfy_code_412_ind') ? undefined : json['pen_elec_satisfy_code_412_ind'],
        'pen_fndng_wvrs_date': !exists(json, 'pen_fndng_wvrs_date') ? undefined : json['pen_fndng_wvrs_date'],
        'pen_emplr_contrib_rqr_amt': !exists(json, 'pen_emplr_contrib_rqr_amt') ? undefined : json['pen_emplr_contrib_rqr_amt'],
        'pen_emplr_contrib_paid_amt': !exists(json, 'pen_emplr_contrib_paid_amt') ? undefined : json['pen_emplr_contrib_paid_amt'],
        'pen_funding_deficiency_amt': !exists(json, 'pen_funding_deficiency_amt') ? undefined : json['pen_funding_deficiency_amt'],
        'pen_funding_deadline_ind': !exists(json, 'pen_funding_deadline_ind') ? undefined : json['pen_funding_deadline_ind'],
        'pen_chg_fndng_method_ind': !exists(json, 'pen_chg_fndng_method_ind') ? undefined : json['pen_chg_fndng_method_ind'],
        'pen_amdmt_incr_val_bnft_cd': !exists(json, 'pen_amdmt_incr_val_bnft_cd') ? undefined : json['pen_amdmt_incr_val_bnft_cd'],
        'pen_sec_repay_loan_ind': !exists(json, 'pen_sec_repay_loan_ind') ? undefined : json['pen_sec_repay_loan_ind'],
        'esop_pref_ind': !exists(json, 'esop_pref_ind') ? undefined : json['esop_pref_ind'],
        'esop_back_to_back_ind': !exists(json, 'esop_back_to_back_ind') ? undefined : json['esop_back_to_back_ind'],
        'esop_stock_not_tradable_ind': !exists(json, 'esop_stock_not_tradable_ind') ? undefined : json['esop_stock_not_tradable_ind'],
        'pen_no_contrib_cur_yr_cnt': !exists(json, 'pen_no_contrib_cur_yr_cnt') ? undefined : json['pen_no_contrib_cur_yr_cnt'],
        'pen_no_contrib_prev_yr_cnt': !exists(json, 'pen_no_contrib_prev_yr_cnt') ? undefined : json['pen_no_contrib_prev_yr_cnt'],
        'pen_no_contrib_2nd_prev_yr_cnt': !exists(json, 'pen_no_contrib_2nd_prev_yr_cnt') ? undefined : json['pen_no_contrib_2nd_prev_yr_cnt'],
        'pen_no_contrib_cur_prev_prcnt': !exists(json, 'pen_no_contrib_cur_prev_prcnt') ? undefined : json['pen_no_contrib_cur_prev_prcnt'],
        'pen_no_contr_cur_2nd_prev_prc': !exists(json, 'pen_no_contr_cur_2nd_prev_prc') ? undefined : json['pen_no_contr_cur_2nd_prev_prc'],
        'pen_emplrs_withdrw_prev_cnt': !exists(json, 'pen_emplrs_withdrw_prev_cnt') ? undefined : json['pen_emplrs_withdrw_prev_cnt'],
        'pen_withdrw_liab_amt': !exists(json, 'pen_withdrw_liab_amt') ? undefined : json['pen_withdrw_liab_amt'],
        'pen_asset_liab_transfer_ind': !exists(json, 'pen_asset_liab_transfer_ind') ? undefined : json['pen_asset_liab_transfer_ind'],
        'pen_liab_mult_plans_ind': !exists(json, 'pen_liab_mult_plans_ind') ? undefined : json['pen_liab_mult_plans_ind'],
        'pen_stock_prcnt': !exists(json, 'pen_stock_prcnt') ? undefined : json['pen_stock_prcnt'],
        'pen_invst_grade_debt_prcnt': !exists(json, 'pen_invst_grade_debt_prcnt') ? undefined : json['pen_invst_grade_debt_prcnt'],
        'pen_hi_yld_debt_prcnt': !exists(json, 'pen_hi_yld_debt_prcnt') ? undefined : json['pen_hi_yld_debt_prcnt'],
        'pen_real_estate_prcnt': !exists(json, 'pen_real_estate_prcnt') ? undefined : json['pen_real_estate_prcnt'],
        'pen_oth_asset_prcnt': !exists(json, 'pen_oth_asset_prcnt') ? undefined : json['pen_oth_asset_prcnt'],
        'pen_average_duration_cd': !exists(json, 'pen_average_duration_cd') ? undefined : json['pen_average_duration_cd'],
        'pen_duration_measure_cd': !exists(json, 'pen_duration_measure_cd') ? undefined : json['pen_duration_measure_cd'],
        'pen_other_duration_type_text': !exists(json, 'pen_other_duration_type_text') ? undefined : json['pen_other_duration_type_text'],
        'f_401k_plan_ind': !exists(json, 'f_401k_plan_ind') ? undefined : json['f_401k_plan_ind'],
        'f_401k_satisfy_rqmts_ind': !exists(json, 'f_401k_satisfy_rqmts_ind') ? undefined : json['f_401k_satisfy_rqmts_ind'],
        'adp_acp_test_ind': !exists(json, 'adp_acp_test_ind') ? undefined : json['adp_acp_test_ind'],
        'mthd_used_satisfy_rqmts_ind': !exists(json, 'mthd_used_satisfy_rqmts_ind') ? undefined : json['mthd_used_satisfy_rqmts_ind'],
        'plan_satisfy_tests_ind': !exists(json, 'plan_satisfy_tests_ind') ? undefined : json['plan_satisfy_tests_ind'],
        'plan_timely_amended_ind': !exists(json, 'plan_timely_amended_ind') ? undefined : json['plan_timely_amended_ind'],
        'last_plan_amendment_date': !exists(json, 'last_plan_amendment_date') ? undefined : json['last_plan_amendment_date'],
        'tax_code': !exists(json, 'tax_code') ? undefined : json['tax_code'],
        'last_opin_advisory_date': !exists(json, 'last_opin_advisory_date') ? undefined : json['last_opin_advisory_date'],
        'last_opin_advisory_serial_num': !exists(json, 'last_opin_advisory_serial_num') ? undefined : json['last_opin_advisory_serial_num'],
        'fav_determ_ltr_date': !exists(json, 'fav_determ_ltr_date') ? undefined : json['fav_determ_ltr_date'],
        'plan_maintain_us_territory_ind': !exists(json, 'plan_maintain_us_territory_ind') ? undefined : json['plan_maintain_us_territory_ind'],
        'pen_401k_design_based_safe_ind': !exists(json, 'pen_401k_design_based_safe_ind') ? undefined : json['pen_401k_design_based_safe_ind'],
        'pen_401k_prior_year_adp_ind': !exists(json, 'pen_401k_prior_year_adp_ind') ? undefined : json['pen_401k_prior_year_adp_ind'],
        'pen_401k_current_year_adp_ind': !exists(json, 'pen_401k_current_year_adp_ind') ? undefined : json['pen_401k_current_year_adp_ind'],
        'pen_401k_na_ind': !exists(json, 'pen_401k_na_ind') ? undefined : json['pen_401k_na_ind'],
        'pen_mthd_ratio_prcnt_test_ind': !exists(json, 'pen_mthd_ratio_prcnt_test_ind') ? undefined : json['pen_mthd_ratio_prcnt_test_ind'],
        'pen_mthd_avg_bnft_test_ind': !exists(json, 'pen_mthd_avg_bnft_test_ind') ? undefined : json['pen_mthd_avg_bnft_test_ind'],
        'pen_mthd_na_ind': !exists(json, 'pen_mthd_na_ind') ? undefined : json['pen_mthd_na_ind'],
        'pen_unpaid_min_rqd_contrib_ind': !exists(json, 'pen_unpaid_min_rqd_contrib_ind') ? undefined : json['pen_unpaid_min_rqd_contrib_ind'],
        'pen_pbgc_notified_cd': !exists(json, 'pen_pbgc_notified_cd') ? undefined : json['pen_pbgc_notified_cd'],
        'pen_pbgc_notified_explan_text': !exists(json, 'pen_pbgc_notified_explan_text') ? undefined : json['pen_pbgc_notified_explan_text'],
        'pen_counting_method_cd': !exists(json, 'pen_counting_method_cd') ? undefined : json['pen_counting_method_cd'],
        'pen_change_prev_yr_ind': !exists(json, 'pen_change_prev_yr_ind') ? undefined : json['pen_change_prev_yr_ind'],
        'pen_change_2nd_prev_yr_ind': !exists(json, 'pen_change_2nd_prev_yr_ind') ? undefined : json['pen_change_2nd_prev_yr_ind'],
    };
}

export function ExecRiskScheduleRBaseToJSON(value?: ExecRiskScheduleRBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'sch_r_plan_year_begin_date': value.sch_r_plan_year_begin_date,
        'sch_r_tax_prd': value.sch_r_tax_prd,
        'sch_r_pn': value.sch_r_pn,
        'sch_r_ein': value.sch_r_ein,
        'pen_value_dstrb_pd_prpty_amt': value.pen_value_dstrb_pd_prpty_amt,
        'pen_payor_01_ein': value.pen_payor_01_ein,
        'pen_payor_02_ein': value.pen_payor_02_ein,
        'pen_bnft_distrib_sngl_sum_cnt': value.pen_bnft_distrib_sngl_sum_cnt,
        'pen_elec_satisfy_code_412_ind': value.pen_elec_satisfy_code_412_ind,
        'pen_fndng_wvrs_date': value.pen_fndng_wvrs_date,
        'pen_emplr_contrib_rqr_amt': value.pen_emplr_contrib_rqr_amt,
        'pen_emplr_contrib_paid_amt': value.pen_emplr_contrib_paid_amt,
        'pen_funding_deficiency_amt': value.pen_funding_deficiency_amt,
        'pen_funding_deadline_ind': value.pen_funding_deadline_ind,
        'pen_chg_fndng_method_ind': value.pen_chg_fndng_method_ind,
        'pen_amdmt_incr_val_bnft_cd': value.pen_amdmt_incr_val_bnft_cd,
        'pen_sec_repay_loan_ind': value.pen_sec_repay_loan_ind,
        'esop_pref_ind': value.esop_pref_ind,
        'esop_back_to_back_ind': value.esop_back_to_back_ind,
        'esop_stock_not_tradable_ind': value.esop_stock_not_tradable_ind,
        'pen_no_contrib_cur_yr_cnt': value.pen_no_contrib_cur_yr_cnt,
        'pen_no_contrib_prev_yr_cnt': value.pen_no_contrib_prev_yr_cnt,
        'pen_no_contrib_2nd_prev_yr_cnt': value.pen_no_contrib_2nd_prev_yr_cnt,
        'pen_no_contrib_cur_prev_prcnt': value.pen_no_contrib_cur_prev_prcnt,
        'pen_no_contr_cur_2nd_prev_prc': value.pen_no_contr_cur_2nd_prev_prc,
        'pen_emplrs_withdrw_prev_cnt': value.pen_emplrs_withdrw_prev_cnt,
        'pen_withdrw_liab_amt': value.pen_withdrw_liab_amt,
        'pen_asset_liab_transfer_ind': value.pen_asset_liab_transfer_ind,
        'pen_liab_mult_plans_ind': value.pen_liab_mult_plans_ind,
        'pen_stock_prcnt': value.pen_stock_prcnt,
        'pen_invst_grade_debt_prcnt': value.pen_invst_grade_debt_prcnt,
        'pen_hi_yld_debt_prcnt': value.pen_hi_yld_debt_prcnt,
        'pen_real_estate_prcnt': value.pen_real_estate_prcnt,
        'pen_oth_asset_prcnt': value.pen_oth_asset_prcnt,
        'pen_average_duration_cd': value.pen_average_duration_cd,
        'pen_duration_measure_cd': value.pen_duration_measure_cd,
        'pen_other_duration_type_text': value.pen_other_duration_type_text,
        'f_401k_plan_ind': value.f_401k_plan_ind,
        'f_401k_satisfy_rqmts_ind': value.f_401k_satisfy_rqmts_ind,
        'adp_acp_test_ind': value.adp_acp_test_ind,
        'mthd_used_satisfy_rqmts_ind': value.mthd_used_satisfy_rqmts_ind,
        'plan_satisfy_tests_ind': value.plan_satisfy_tests_ind,
        'plan_timely_amended_ind': value.plan_timely_amended_ind,
        'last_plan_amendment_date': value.last_plan_amendment_date,
        'tax_code': value.tax_code,
        'last_opin_advisory_date': value.last_opin_advisory_date,
        'last_opin_advisory_serial_num': value.last_opin_advisory_serial_num,
        'fav_determ_ltr_date': value.fav_determ_ltr_date,
        'plan_maintain_us_territory_ind': value.plan_maintain_us_territory_ind,
        'pen_401k_design_based_safe_ind': value.pen_401k_design_based_safe_ind,
        'pen_401k_prior_year_adp_ind': value.pen_401k_prior_year_adp_ind,
        'pen_401k_current_year_adp_ind': value.pen_401k_current_year_adp_ind,
        'pen_401k_na_ind': value.pen_401k_na_ind,
        'pen_mthd_ratio_prcnt_test_ind': value.pen_mthd_ratio_prcnt_test_ind,
        'pen_mthd_avg_bnft_test_ind': value.pen_mthd_avg_bnft_test_ind,
        'pen_mthd_na_ind': value.pen_mthd_na_ind,
        'pen_unpaid_min_rqd_contrib_ind': value.pen_unpaid_min_rqd_contrib_ind,
        'pen_pbgc_notified_cd': value.pen_pbgc_notified_cd,
        'pen_pbgc_notified_explan_text': value.pen_pbgc_notified_explan_text,
        'pen_counting_method_cd': value.pen_counting_method_cd,
        'pen_change_prev_yr_ind': value.pen_change_prev_yr_ind,
        'pen_change_2nd_prev_yr_ind': value.pen_change_2nd_prev_yr_ind,
    };
}

