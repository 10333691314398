/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDataleakEvent } from './CRSFSecReviewDataleakEvent';
import {
    CRSFSecReviewDataleakEventFromJSON,
    CRSFSecReviewDataleakEventFromJSONTyped,
    CRSFSecReviewDataleakEventToJSON,
} from './CRSFSecReviewDataleakEvent';

/**
 * 
 * @export
 * @interface CRSFSecReviewDLAggregateSumResult
 */
export interface CRSFSecReviewDLAggregateSumResult {
    /**
     * 
     * @type {Array<CRSFSecReviewDataleakEvent>}
     * @memberof CRSFSecReviewDLAggregateSumResult
     */
    events?: Array<CRSFSecReviewDataleakEvent>;
    /**
     * 
     * @type {Array<CRSFSecReviewDataleakEvent>}
     * @memberof CRSFSecReviewDLAggregateSumResult
     */
    features?: Array<CRSFSecReviewDataleakEvent>;
    /**
     * 
     * @type {Array<CRSFSecReviewDataleakEvent>}
     * @memberof CRSFSecReviewDLAggregateSumResult
     */
    main_leaks?: Array<CRSFSecReviewDataleakEvent>;
}

/**
 * Check if a given object implements the CRSFSecReviewDLAggregateSumResult interface.
 */
export function instanceOfCRSFSecReviewDLAggregateSumResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewDLAggregateSumResultFromJSON(json: any): CRSFSecReviewDLAggregateSumResult {
    return CRSFSecReviewDLAggregateSumResultFromJSONTyped(json, false);
}

export function CRSFSecReviewDLAggregateSumResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDLAggregateSumResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(CRSFSecReviewDataleakEventFromJSON)),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(CRSFSecReviewDataleakEventFromJSON)),
        'main_leaks': !exists(json, 'main_leaks') ? undefined : ((json['main_leaks'] as Array<any>).map(CRSFSecReviewDataleakEventFromJSON)),
    };
}

export function CRSFSecReviewDLAggregateSumResultToJSON(value?: CRSFSecReviewDLAggregateSumResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(CRSFSecReviewDataleakEventToJSON)),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(CRSFSecReviewDataleakEventToJSON)),
        'main_leaks': value.main_leaks === undefined ? undefined : ((value.main_leaks as Array<any>).map(CRSFSecReviewDataleakEventToJSON)),
    };
}

