import React from 'react';
import clsx from 'clsx';
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/DialogTitle';
import { withCoreProps } from '../../withCoreProps';

export const DialogTitle = withCoreProps<DialogTitleProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<DialogTitleProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiDialogTitle {...props} className={clsxClass}>
        {children}
      </MuiDialogTitle>
    );
  },
);

export interface DialogTitleProps extends MuiDialogTitleProps {}
