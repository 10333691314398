/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Locations } from './Locations';
import {
    LocationsFromJSON,
    LocationsFromJSONTyped,
    LocationsToJSON,
} from './Locations';

/**
 * 
 * @export
 * @interface ExecRiskEVerify
 */
export interface ExecRiskEVerify {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    dba?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    account_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    date_enrolled?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    date_terminated?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskEVerify
     */
    workforce_range?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskEVerify
     */
    hiring_sites_count?: number;
    /**
     * 
     * @type {Locations}
     * @memberof ExecRiskEVerify
     */
    locations?: Locations;
}

/**
 * Check if a given object implements the ExecRiskEVerify interface.
 */
export function instanceOfExecRiskEVerify(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskEVerifyFromJSON(json: any): ExecRiskEVerify {
    return ExecRiskEVerifyFromJSONTyped(json, false);
}

export function ExecRiskEVerifyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskEVerify {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'dba': !exists(json, 'dba') ? undefined : json['dba'],
        'account_status': !exists(json, 'account_status') ? undefined : json['account_status'],
        'date_enrolled': !exists(json, 'date_enrolled') ? undefined : json['date_enrolled'],
        'date_terminated': !exists(json, 'date_terminated') ? undefined : json['date_terminated'],
        'workforce_range': !exists(json, 'workforce_range') ? undefined : json['workforce_range'],
        'hiring_sites_count': !exists(json, 'hiring_sites_count') ? undefined : json['hiring_sites_count'],
        'locations': !exists(json, 'locations') ? undefined : LocationsFromJSON(json['locations']),
    };
}

export function ExecRiskEVerifyToJSON(value?: ExecRiskEVerify | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employer': value.employer,
        'dba': value.dba,
        'account_status': value.account_status,
        'date_enrolled': value.date_enrolled,
        'date_terminated': value.date_terminated,
        'workforce_range': value.workforce_range,
        'hiring_sites_count': value.hiring_sites_count,
        'locations': LocationsToJSON(value.locations),
    };
}

