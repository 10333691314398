import { format as formatFunc } from 'numerable';
import { currencyCodeMap, CurrencyCode } from './currencyCodeMap';
/**
 * WARNING:
 *
 * This function EXPECTS integer numbers that the
 * LAST 2 DIGITS are supposed to the the decimals.
 *
 * __> Example:__
 * ```ts
 *
 * formatMoney(123499, 'USD');
 * // '$1,235'
 *
 * formatMoney(1200000, 'GBP', '0a');
 * // '£12K'
 *
 * formatMoney(100000000, 'EUR', '0a');
 * // '€1 Million'
 * ```
 */

export const formatMoney: FormatNumber = (
  value,
  currencyCode,
  format = '0,0',
): string => {
  const currencySymbol = currencyCodeMap[currencyCode];

  const valueWithDecimal = (value / 100).toFixed(2);
  const valueWithCommas = formatFunc(valueWithDecimal, format);
  const valueWithUppercaseLetters = valueWithCommas.toUpperCase();
  const valueReplaceMtoMillion = valueWithUppercaseLetters.replace(
    'M',
    ' Million',
  );
  const valueWithCurrencySymbol = `${currencySymbol}${valueReplaceMtoMillion}`;

  return valueWithCurrencySymbol;
};

export interface Money {
  amount: number;
  code: CurrencyCode;
}

type FormatNumber = (
  value: number,
  currencyCode: keyof typeof currencyCodeMap,
  format?: string,
) => string;
