/**
 * Check if there's is a BE_THEME dark palette theme active
 *
 * __> Example:__
 * ```ts
 * isDarkTheme();
 * // bool
 *
 */

export const isDarkTheme: isDarkType = (themeName) => {
  try {
    if (!themeName) {
      if (window?.localStorage.getItem('BE_THEME')?.includes('Dark')) {
        return true;
      }
    } else {
      if (themeName.includes('Dark')) {
        return true;
      }
      return false;
    }
    return false;
  } catch {
    return false;
  }
};

type isDarkType = (themeName?: string) => boolean;
