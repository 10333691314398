/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalRecipient
 */
export interface SupplementalRecipient {
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    recipient_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    account_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    quote_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    policyholder_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    jira_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    broker_uuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    broker_email?: string | null;
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalRecipient
     */
    locale: SupplementalLocale;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalRecipient
     */
    updated_at?: string;
}

/**
 * Check if a given object implements the SupplementalRecipient interface.
 */
export function instanceOfSupplementalRecipient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipient_id" in value;
    isInstance = isInstance && "account_uuid" in value;
    isInstance = isInstance && "quote_uuid" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function SupplementalRecipientFromJSON(json: any): SupplementalRecipient {
    return SupplementalRecipientFromJSONTyped(json, false);
}

export function SupplementalRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient_id': json['recipient_id'],
        'account_uuid': json['account_uuid'],
        'quote_uuid': json['quote_uuid'],
        'policyholder_email': !exists(json, 'policyholder_email') ? undefined : json['policyholder_email'],
        'jira_id': !exists(json, 'jira_id') ? undefined : json['jira_id'],
        'broker_uuid': !exists(json, 'broker_uuid') ? undefined : json['broker_uuid'],
        'broker_email': !exists(json, 'broker_email') ? undefined : json['broker_email'],
        'locale': SupplementalLocaleFromJSON(json['locale']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function SupplementalRecipientToJSON(value?: SupplementalRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_id': value.recipient_id,
        'account_uuid': value.account_uuid,
        'quote_uuid': value.quote_uuid,
        'policyholder_email': value.policyholder_email,
        'jira_id': value.jira_id,
        'broker_uuid': value.broker_uuid,
        'broker_email': value.broker_email,
        'locale': SupplementalLocaleToJSON(value.locale),
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}

