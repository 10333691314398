/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskInvestors } from './ExecRiskInvestors';
import {
    ExecRiskInvestorsFromJSON,
    ExecRiskInvestorsFromJSONTyped,
    ExecRiskInvestorsToJSON,
} from './ExecRiskInvestors';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedInvestorsScanResponse
 */
export interface ExecRiskPaginatedInvestorsScanResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedInvestorsScanResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskInvestors>}
     * @memberof ExecRiskPaginatedInvestorsScanResponse
     */
    results: Array<ExecRiskInvestors>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedInvestorsScanResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedInvestorsScanResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedInvestorsScanResponse
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedInvestorsScanResponse interface.
 */
export function instanceOfExecRiskPaginatedInvestorsScanResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedInvestorsScanResponseFromJSON(json: any): ExecRiskPaginatedInvestorsScanResponse {
    return ExecRiskPaginatedInvestorsScanResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedInvestorsScanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedInvestorsScanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskInvestorsFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedInvestorsScanResponseToJSON(value?: ExecRiskPaginatedInvestorsScanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskInvestorsToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

