/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDataleaksRiskSummaryObject } from './CRSFSecReviewDataleaksRiskSummaryObject';
import {
    CRSFSecReviewDataleaksRiskSummaryObjectFromJSON,
    CRSFSecReviewDataleaksRiskSummaryObjectFromJSONTyped,
    CRSFSecReviewDataleaksRiskSummaryObjectToJSON,
} from './CRSFSecReviewDataleaksRiskSummaryObject';

/**
 * Represents a DataleakRiskSummary entity: for a given domain, it has the representation of the risk summary for
 * three different time ranges.
 * @export
 * @interface CRSFSecReviewDataleakRiskSummary
 */
export interface CRSFSecReviewDataleakRiskSummary {
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof CRSFSecReviewDataleakRiskSummary
     */
    all_time: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDataleakRiskSummary
     */
    domain: string;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof CRSFSecReviewDataleakRiskSummary
     */
    previous_12_months: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof CRSFSecReviewDataleakRiskSummary
     */
    previous_1_month?: CRSFSecReviewDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFSecReviewDataleaksRiskSummaryObject}
     * @memberof CRSFSecReviewDataleakRiskSummary
     */
    previous_6_months: CRSFSecReviewDataleaksRiskSummaryObject;
}

/**
 * Check if a given object implements the CRSFSecReviewDataleakRiskSummary interface.
 */
export function instanceOfCRSFSecReviewDataleakRiskSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "all_time" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "previous_12_months" in value;
    isInstance = isInstance && "previous_6_months" in value;

    return isInstance;
}

export function CRSFSecReviewDataleakRiskSummaryFromJSON(json: any): CRSFSecReviewDataleakRiskSummary {
    return CRSFSecReviewDataleakRiskSummaryFromJSONTyped(json, false);
}

export function CRSFSecReviewDataleakRiskSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDataleakRiskSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all_time': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['all_time']),
        'domain': json['domain'],
        'previous_12_months': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_12_months']),
        'previous_1_month': !exists(json, 'previous_1_month') ? undefined : CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_1_month']),
        'previous_6_months': CRSFSecReviewDataleaksRiskSummaryObjectFromJSON(json['previous_6_months']),
    };
}

export function CRSFSecReviewDataleakRiskSummaryToJSON(value?: CRSFSecReviewDataleakRiskSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all_time': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.all_time),
        'domain': value.domain,
        'previous_12_months': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_12_months),
        'previous_1_month': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_1_month),
        'previous_6_months': CRSFSecReviewDataleaksRiskSummaryObjectToJSON(value.previous_6_months),
    };
}

