/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlInvestors } from './ControlInvestors';
import {
    ControlInvestorsFromJSON,
    ControlInvestorsFromJSONTyped,
    ControlInvestorsToJSON,
} from './ControlInvestors';

/**
 * 
 * @export
 * @interface ControlZoomInfoFunding
 */
export interface ControlZoomInfoFunding {
    /**
     * 
     * @type {string}
     * @memberof ControlZoomInfoFunding
     */
    round_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZoomInfoFunding
     */
    funding_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlZoomInfoFunding
     */
    funding_amount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlZoomInfoFunding
     */
    investors?: Array<string>;
    /**
     * 
     * @type {Array<ControlInvestors>}
     * @memberof ControlZoomInfoFunding
     */
    investors_details?: Array<ControlInvestors>;
}

/**
 * Check if a given object implements the ControlZoomInfoFunding interface.
 */
export function instanceOfControlZoomInfoFunding(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlZoomInfoFundingFromJSON(json: any): ControlZoomInfoFunding {
    return ControlZoomInfoFundingFromJSONTyped(json, false);
}

export function ControlZoomInfoFundingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZoomInfoFunding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'round_type': !exists(json, 'round_type') ? undefined : json['round_type'],
        'funding_date': !exists(json, 'funding_date') ? undefined : json['funding_date'],
        'funding_amount': !exists(json, 'funding_amount') ? undefined : json['funding_amount'],
        'investors': !exists(json, 'investors') ? undefined : json['investors'],
        'investors_details': !exists(json, 'investors_details') ? undefined : ((json['investors_details'] as Array<any>).map(ControlInvestorsFromJSON)),
    };
}

export function ControlZoomInfoFundingToJSON(value?: ControlZoomInfoFunding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'round_type': value.round_type,
        'funding_date': value.funding_date,
        'funding_amount': value.funding_amount,
        'investors': value.investors,
        'investors_details': value.investors_details === undefined ? undefined : ((value.investors_details as Array<any>).map(ControlInvestorsToJSON)),
    };
}

