/**
 * Returns the criticality level based on the CVSS score of a CVE.
 *
 * __> Example:__
 * ```ts
 * getCVECriticality(6.9);
 * // "medium"
 *
 * getCVECriticality(0.69);
 * // "low"
 * ```
 */

export const getCVECriticality: GetCVECriticality = (cvss) => {
  if (cvss >= 9) return 'critical';
  if (cvss >= 7) return 'high';
  if (cvss >= 4) return 'medium';
  if (cvss >= 0.1) return 'low';

  return 'info';
};

type GetCVECriticality = (
  cvss: number,
) => 'critical' | 'high' | 'medium' | 'low' | 'info';
