export function* parseCommaSeparatedList(input: string): Iterable<string> {
  // grab anything that's not a comma or whitespace until you see a comma OR whitespace OR the end of the string
  const regex = /\s*([^,\s]+)(?=[\s*,]|$)/g;

  let match: RegExpExecArray | null;
  match = regex.exec(input);
  while (match !== null) {
    const [_, value] = match;
    yield value;

    match = regex.exec(input);
  }

  return;
}
