import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * CrisisManagementIcon
 */
export const CrisisManagementIcon = ({
  ...props
}: CrisisManagementIconProps) => {
  return (
    <Icon label="crisis_management" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1,7.8c0.3,0,0.6-0.3,0.6-0.6V5.3c0-0.3-0.3-0.6-0.6-0.6h-0.2c-0.3,0-0.6,0.3-0.6,0.6v1.9c0,0.3,0.3,0.6,0.6,0.6H16.1z M21.8,9.7l1.4-1.3c0.2-0.2,0.2-0.6,0-0.9L23,7.4c-0.2-0.2-0.6-0.2-0.9,0l-1.4,1.3c-0.2,0.2-0.2,0.6,0,0.9l0.1,0.1C21.1,10,21.5,10,21.8,9.7z M10.6,17.9v-3.6c0-2.9,2.4-5.2,5.4-5.2c3,0,5.4,2.3,5.4,5.2v3.6H10.6z M9.9,7.4C9.6,7.2,9.2,7.2,9,7.4L8.9,7.5c-0.2,0.2-0.2,0.6,0,0.9l1.4,1.3c0.2,0.2,0.6,0.2,0.9,0l0.1-0.1c0.2-0.2,0.2-0.6,0-0.9L9.9,7.4z M24.6,22.1v1.4c0,0.2-0.2,0.3-0.3,0.3H7.7c-0.2,0-0.3-0.1-0.3-0.3v-1.4c0-1.4,1.2-2.5,2.6-2.5h12C23.4,19.6,24.6,20.7,24.6,22.1z M22.8,14.2v0.2c0,0.3,0.3,0.6,0.6,0.6h1.9c0.3,0,0.6-0.3,0.6-0.6v-0.2c0-0.3-0.3-0.6-0.6-0.6h-1.9C23.1,13.6,22.8,13.9,22.8,14.2z M8.6,15H6.6C6.3,15,6,14.7,6,14.4v-0.2c0-0.3,0.3-0.6,0.6-0.6h1.9c0.3,0,0.6,0.3,0.6,0.6v0.2C9.2,14.7,8.9,15,8.6,15z"
        />
      </g>
    </Icon>
  );
};

/**
 * CrisisManagementIcon Props
 */
export interface CrisisManagementIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
