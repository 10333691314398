/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZoomInfoFunding } from './ControlZoomInfoFunding';
import {
    ControlZoomInfoFundingFromJSON,
    ControlZoomInfoFundingFromJSONTyped,
    ControlZoomInfoFundingToJSON,
} from './ControlZoomInfoFunding';

/**
 * 
 * @export
 * @interface ControlPaginatedZoomInfoFunding
 */
export interface ControlPaginatedZoomInfoFunding {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedZoomInfoFunding
     */
    total: number;
    /**
     * 
     * @type {Array<ControlZoomInfoFunding>}
     * @memberof ControlPaginatedZoomInfoFunding
     */
    results: Array<ControlZoomInfoFunding>;
    /**
     * 
     * @type {string}
     * @memberof ControlPaginatedZoomInfoFunding
     */
    schema_version: string;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedZoomInfoFunding
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedZoomInfoFunding
     */
    page_size: number;
}

/**
 * Check if a given object implements the ControlPaginatedZoomInfoFunding interface.
 */
export function instanceOfControlPaginatedZoomInfoFunding(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "schema_version" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;

    return isInstance;
}

export function ControlPaginatedZoomInfoFundingFromJSON(json: any): ControlPaginatedZoomInfoFunding {
    return ControlPaginatedZoomInfoFundingFromJSONTyped(json, false);
}

export function ControlPaginatedZoomInfoFundingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedZoomInfoFunding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'results': ((json['results'] as Array<any>).map(ControlZoomInfoFundingFromJSON)),
        'schema_version': json['schema_version'],
        'page': json['page'],
        'page_size': json['page_size'],
    };
}

export function ControlPaginatedZoomInfoFundingToJSON(value?: ControlPaginatedZoomInfoFunding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ControlZoomInfoFundingToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

