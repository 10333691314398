import React from 'react';
import clsx from 'clsx';
import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
} from '@mui/material';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const Pagination = withCoreProps<PaginationProps>(
  ({ className, ...props }: React.PropsWithChildren<PaginationProps>) => {
    const clsxClass = clsx(className);

    return <MuiPagination {...props} className={clsxClass} />;
  },
);

export interface PaginationProps extends MuiPaginationProps, CoreProps {}
