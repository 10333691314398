/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppCoreEnumsAuth0Roles } from './ControlAppCoreEnumsAuth0Roles';
import {
    ControlAppCoreEnumsAuth0RolesFromJSON,
    ControlAppCoreEnumsAuth0RolesFromJSONTyped,
    ControlAppCoreEnumsAuth0RolesToJSON,
} from './ControlAppCoreEnumsAuth0Roles';

/**
 * 
 * @export
 * @interface ControlPostTestingUser
 */
export interface ControlPostTestingUser {
    /**
     * 
     * @type {boolean}
     * @memberof ControlPostTestingUser
     */
    onboarded?: boolean;
    /**
     * 
     * @type {ControlAppCoreEnumsAuth0Roles}
     * @memberof ControlPostTestingUser
     */
    role: ControlAppCoreEnumsAuth0Roles;
}

/**
 * Check if a given object implements the ControlPostTestingUser interface.
 */
export function instanceOfControlPostTestingUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ControlPostTestingUserFromJSON(json: any): ControlPostTestingUser {
    return ControlPostTestingUserFromJSONTyped(json, false);
}

export function ControlPostTestingUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPostTestingUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onboarded': !exists(json, 'onboarded') ? undefined : json['onboarded'],
        'role': ControlAppCoreEnumsAuth0RolesFromJSON(json['role']),
    };
}

export function ControlPostTestingUserToJSON(value?: ControlPostTestingUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onboarded': value.onboarded,
        'role': ControlAppCoreEnumsAuth0RolesToJSON(value.role),
    };
}

