/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * process flow
 * API triggers new scan - process.status -> requested in DB, bootstrap task triggered
 * bootstrap task receives scan message and checks if there is scan in progress for same parameters
 * no scan in progress - process.status -> started and bootstrap triggers work by creating threads
 * scan in progress - process.status -> pending. No work triggered for this scan.
 * @export
 */
export const CRSFStatusType = {
    Pending: 'pending',
    Requested: 'requested',
    Started: 'started',
    Finished: 'finished',
    Revoked: 'revoked'
} as const;
export type CRSFStatusType = typeof CRSFStatusType[keyof typeof CRSFStatusType];


export function CRSFStatusTypeFromJSON(json: any): CRSFStatusType {
    return CRSFStatusTypeFromJSONTyped(json, false);
}

export function CRSFStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFStatusType {
    return json as CRSFStatusType;
}

export function CRSFStatusTypeToJSON(value?: CRSFStatusType | null): any {
    return value as any;
}

