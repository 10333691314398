/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityInsuranceRequest
 */
export interface MonitEntityInsuranceRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policy_number?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    broker_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    broker_email?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    broker_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    agency_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    agency_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policyholder_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policyholder_email?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policyholder_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    policyholder_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    industry_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    industry_gics?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    n_employees?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    pii_phi_count?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    company_revenue?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityInsuranceRequest
     */
    aggregate_limit?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    retail_broker_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    retail_broker_email?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    effective_date?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityInsuranceRequest
     */
    end_date?: string;
}

/**
 * Check if a given object implements the MonitEntityInsuranceRequest interface.
 */
export function instanceOfMonitEntityInsuranceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityInsuranceRequestFromJSON(json: any): MonitEntityInsuranceRequest {
    return MonitEntityInsuranceRequestFromJSONTyped(json, false);
}

export function MonitEntityInsuranceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityInsuranceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy_number': !exists(json, 'policy_number') ? undefined : json['policy_number'],
        'policy_uuid': !exists(json, 'policy_uuid') ? undefined : json['policy_uuid'],
        'broker_name': !exists(json, 'broker_name') ? undefined : json['broker_name'],
        'broker_email': !exists(json, 'broker_email') ? undefined : json['broker_email'],
        'broker_uuid': !exists(json, 'broker_uuid') ? undefined : json['broker_uuid'],
        'agency_name': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'agency_uuid': !exists(json, 'agency_uuid') ? undefined : json['agency_uuid'],
        'policyholder_name': !exists(json, 'policyholder_name') ? undefined : json['policyholder_name'],
        'policyholder_email': !exists(json, 'policyholder_email') ? undefined : json['policyholder_email'],
        'policyholder_phone': !exists(json, 'policyholder_phone') ? undefined : json['policyholder_phone'],
        'policyholder_uuid': !exists(json, 'policyholder_uuid') ? undefined : json['policyholder_uuid'],
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'industry_name': !exists(json, 'industry_name') ? undefined : json['industry_name'],
        'industry_gics': !exists(json, 'industry_gics') ? undefined : json['industry_gics'],
        'n_employees': !exists(json, 'n_employees') ? undefined : json['n_employees'],
        'pii_phi_count': !exists(json, 'pii_phi_count') ? undefined : json['pii_phi_count'],
        'company_revenue': !exists(json, 'company_revenue') ? undefined : json['company_revenue'],
        'aggregate_limit': !exists(json, 'aggregate_limit') ? undefined : json['aggregate_limit'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'retail_broker_name': !exists(json, 'retail_broker_name') ? undefined : json['retail_broker_name'],
        'retail_broker_email': !exists(json, 'retail_broker_email') ? undefined : json['retail_broker_email'],
        'effective_date': !exists(json, 'effective_date') ? undefined : json['effective_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function MonitEntityInsuranceRequestToJSON(value?: MonitEntityInsuranceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy_number': value.policy_number,
        'policy_uuid': value.policy_uuid,
        'broker_name': value.broker_name,
        'broker_email': value.broker_email,
        'broker_uuid': value.broker_uuid,
        'agency_name': value.agency_name,
        'agency_uuid': value.agency_uuid,
        'policyholder_name': value.policyholder_name,
        'policyholder_email': value.policyholder_email,
        'policyholder_phone': value.policyholder_phone,
        'policyholder_uuid': value.policyholder_uuid,
        'account_uuid': value.account_uuid,
        'account_line_uuid': value.account_line_uuid,
        'industry_name': value.industry_name,
        'industry_gics': value.industry_gics,
        'n_employees': value.n_employees,
        'pii_phi_count': value.pii_phi_count,
        'company_revenue': value.company_revenue,
        'aggregate_limit': value.aggregate_limit,
        'country': value.country,
        'retail_broker_name': value.retail_broker_name,
        'retail_broker_email': value.retail_broker_email,
        'effective_date': value.effective_date,
        'end_date': value.end_date,
    };
}

