import React from 'react';
import { Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Image } from '@explorer/core';

const useStyles = ({}: Partial<CoalitionLoadingProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        '@keyframes fadeInOut': {
          '0%': { opacity: 0.3 },
          '50%': { opacity: 0.8 },
          '100%': { opacity: 0.3 },
        },
        loadingAnimation: {
          animation: '$fadeInOut 1.75s linear infinite',
        },
      }),
    { index: 1 },
  )();

/**
 * CoalitionLoading
 */
export const CoalitionLoading = ({
  height = '100vh',
}: CoalitionLoadingProps) => {
  const classes = useStyles({});
  const { palette } = useTheme();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      textAlign="center"
      height={height}
      bgcolor={palette.background.default}
    >
      <Grid item>
        <Image
          title="Coalition is Loading..."
          src="/meta/coalition-192x192.png"
          className={classes.loadingAnimation}
        />
      </Grid>
    </Grid>
  );
};

/**
 * CoalitionLoading Props
 */
interface CoalitionLoadingProps {
  height?: string;
}
