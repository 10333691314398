/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';

/**
 * 
 * @export
 * @interface CRSFSecReviewTriggerScanIssueRequest
 */
export interface CRSFSecReviewTriggerScanIssueRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTriggerScanIssueRequest
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewTriggerScanIssueRequest
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerScanIssueRequest
     */
    issue_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerScanIssueRequest
     */
    request_origin?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTriggerScanIssueRequest interface.
 */
export function instanceOfCRSFSecReviewTriggerScanIssueRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "issue_key" in value;

    return isInstance;
}

export function CRSFSecReviewTriggerScanIssueRequestFromJSON(json: any): CRSFSecReviewTriggerScanIssueRequest {
    return CRSFSecReviewTriggerScanIssueRequestFromJSONTyped(json, false);
}

export function CRSFSecReviewTriggerScanIssueRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTriggerScanIssueRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': json['issue_key'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
    };
}

export function CRSFSecReviewTriggerScanIssueRequestToJSON(value?: CRSFSecReviewTriggerScanIssueRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'request_origin': value.request_origin,
    };
}

