/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlManagedClientResponse } from './ControlManagedClientResponse';
import {
    ControlManagedClientResponseFromJSON,
    ControlManagedClientResponseFromJSONTyped,
    ControlManagedClientResponseToJSON,
} from './ControlManagedClientResponse';

/**
 * 
 * @export
 * @interface ControlManagedClientsListResponse
 */
export interface ControlManagedClientsListResponse {
    /**
     * 
     * @type {Array<ControlManagedClientResponse>}
     * @memberof ControlManagedClientsListResponse
     */
    results: Array<ControlManagedClientResponse>;
}

/**
 * Check if a given object implements the ControlManagedClientsListResponse interface.
 */
export function instanceOfControlManagedClientsListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ControlManagedClientsListResponseFromJSON(json: any): ControlManagedClientsListResponse {
    return ControlManagedClientsListResponseFromJSONTyped(json, false);
}

export function ControlManagedClientsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlManagedClientsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ControlManagedClientResponseFromJSON)),
    };
}

export function ControlManagedClientsListResponseToJSON(value?: ControlManagedClientsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ControlManagedClientResponseToJSON)),
    };
}

