/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';

/**
 * 
 * @export
 * @interface CRSFValidationScanTrigger
 */
export interface CRSFValidationScanTrigger {
    /**
     * 
     * @type {string}
     * @memberof CRSFValidationScanTrigger
     */
    account_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFValidationScanTrigger
     */
    datapoint?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFValidationScanTrigger
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFValidationScanTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFValidationScanTrigger
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFValidationScanTrigger
     */
    issue?: string;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFValidationScanTrigger
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {string}
     * @memberof CRSFValidationScanTrigger
     */
    quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFValidationScanTrigger
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFValidationScanTrigger
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFValidationScanTrigger interface.
 */
export function instanceOfCRSFValidationScanTrigger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFValidationScanTriggerFromJSON(json: any): CRSFValidationScanTrigger {
    return CRSFValidationScanTriggerFromJSONTyped(json, false);
}

export function CRSFValidationScanTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFValidationScanTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFValidationScanTriggerToJSON(value?: CRSFValidationScanTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'datapoint': value.datapoint,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'issue': value.issue,
        'locale': CRSFLocaleToJSON(value.locale),
        'quote_id': value.quote_id,
        'request_origin': value.request_origin,
        'user': value.user,
    };
}

