import React from 'react';
import clsx from 'clsx';
import MuiButtonBase, {
  ButtonBaseProps as MuiButtonBaseProps,
} from '@mui/material/ButtonBase';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const ButtonBase = withCoreProps<ButtonBaseProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<ButtonBaseProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiButtonBase {...props} className={clsxClass}>
        {children}
      </MuiButtonBase>
    );
  },
);

export interface ButtonBaseProps extends MuiButtonBaseProps, CoreProps {}
