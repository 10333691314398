import { ComponentsProps } from '@mui/material/styles';

export const props: ComponentsProps = {
  MuiTab: {
    disableRipple: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    disableElevation: true,
  },
  MuiPaper: {
    elevation: 0,
  },
  MuiCard: {
    elevation: 0,
  },
};
