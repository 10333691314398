/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityRelationQuotaResponse
 */
export interface MonitEntityRelationQuotaResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationQuotaResponse
     */
    lite_quota?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationQuotaResponse
     */
    extended_quota?: number;
}

/**
 * Check if a given object implements the MonitEntityRelationQuotaResponse interface.
 */
export function instanceOfMonitEntityRelationQuotaResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityRelationQuotaResponseFromJSON(json: any): MonitEntityRelationQuotaResponse {
    return MonitEntityRelationQuotaResponseFromJSONTyped(json, false);
}

export function MonitEntityRelationQuotaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRelationQuotaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lite_quota': !exists(json, 'lite_quota') ? undefined : json['lite_quota'],
        'extended_quota': !exists(json, 'extended_quota') ? undefined : json['extended_quota'],
    };
}

export function MonitEntityRelationQuotaResponseToJSON(value?: MonitEntityRelationQuotaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lite_quota': value.lite_quota,
        'extended_quota': value.extended_quota,
    };
}

