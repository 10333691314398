/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRACoBranded
 */
export interface CRACoBranded {
    /**
     * 
     * @type {string}
     * @memberof CRACoBranded
     */
    agency_name: string;
    /**
     * 
     * @type {string}
     * @memberof CRACoBranded
     */
    cobranded?: string;
}

/**
 * Check if a given object implements the CRACoBranded interface.
 */
export function instanceOfCRACoBranded(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agency_name" in value;

    return isInstance;
}

export function CRACoBrandedFromJSON(json: any): CRACoBranded {
    return CRACoBrandedFromJSONTyped(json, false);
}

export function CRACoBrandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRACoBranded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency_name': json['agency_name'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
    };
}

export function CRACoBrandedToJSON(value?: CRACoBranded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency_name': value.agency_name,
        'cobranded': value.cobranded,
    };
}

