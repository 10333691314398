/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlFindingTemplate
 */
export interface ControlFindingTemplate {
    /**
     * 
     * @type {string}
     * @memberof ControlFindingTemplate
     */
    template_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFindingTemplate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFindingTemplate
     */
    risk?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFindingTemplate
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFindingTemplate
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlFindingTemplate
     */
    level?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlFindingTemplate
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlFindingTemplate
     */
    score_category?: string;
}

/**
 * Check if a given object implements the ControlFindingTemplate interface.
 */
export function instanceOfControlFindingTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlFindingTemplateFromJSON(json: any): ControlFindingTemplate {
    return ControlFindingTemplateFromJSONTyped(json, false);
}

export function ControlFindingTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFindingTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template_id': !exists(json, 'template_id') ? undefined : json['template_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'risk': !exists(json, 'risk') ? undefined : json['risk'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
    };
}

export function ControlFindingTemplateToJSON(value?: ControlFindingTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_id': value.template_id,
        'title': value.title,
        'risk': value.risk,
        'recommendation': value.recommendation,
        'references': value.references,
        'level': value.level,
        'tags': value.tags,
        'score_category': value.score_category,
    };
}

