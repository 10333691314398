import { EndpointsASM, endpointsASM } from './asm';
import { EndpointsBREACH, endpointsBREACH } from './breach';
import { EndpointsEXECRISKS, endpointsEXECRISKS } from './execrisks';
import { EndpointsUSER, endpointsUSER } from './user';
import {
  EndpointsNOTIFICATIONS,
  endpointsNOTIFICATIONS,
} from './notifications';
import { EndpointsV1CLAIMS, endpointsV1CLAIMS } from './v1claims';
import {
  EndpointsV1CONTROLADMIN,
  endpointsV1CONTROLADMIN,
} from './v1controladmin';
import { EndpointsV1CRA, endpointsV1CRA } from './v1cra';
import { EndpointsV1EXECRISKS, endpointsV1EXECRISKS } from './v1execrisks';
import { EndpointsV1MONIT, endpointsV1MONIT } from './v1monit';
import {
  EndpointsV1NOTIFICATIONS,
  endpointsV1NOTIFICATIONS,
} from './v1notifications';
import { EndpointsV1SECREVIEW, endpointsV1SECREVIEW } from './v1secreview';
import { EndpointsSUPPLEMENTAL, endpointsSUPPLEMENTAL } from './supplemental';

export const endpoints: Endpoints = {
  asm: endpointsASM,
  user: endpointsUSER,
  breach: endpointsBREACH,
  execrisks: endpointsEXECRISKS,
  notifications: endpointsNOTIFICATIONS,
  v1claims: endpointsV1CLAIMS,
  v1controladmin: endpointsV1CONTROLADMIN,
  v1cra: endpointsV1CRA,
  v1execrisks: endpointsV1EXECRISKS,
  v1monit: endpointsV1MONIT,
  v1secreview: endpointsV1SECREVIEW,
  v1notifications: endpointsV1NOTIFICATIONS,
  supplemental: endpointsSUPPLEMENTAL,
};

export interface Endpoints {
  asm: EndpointsASM;
  user: EndpointsUSER;
  breach: EndpointsBREACH;
  execrisks: EndpointsEXECRISKS;
  notifications: EndpointsNOTIFICATIONS;
  v1claims: EndpointsV1CLAIMS;
  v1controladmin: EndpointsV1CONTROLADMIN;
  v1cra: EndpointsV1CRA;
  v1execrisks: EndpointsV1EXECRISKS;
  v1monit: EndpointsV1MONIT;
  v1secreview: EndpointsV1SECREVIEW;
  v1notifications: EndpointsV1NOTIFICATIONS;
  supplemental: EndpointsSUPPLEMENTAL;
}
