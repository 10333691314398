import React from 'react';
import clsx from 'clsx';
import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material/DialogActions';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const DialogActions = withCoreProps<DialogActionsProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<DialogActionsProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiDialogActions {...props} className={clsxClass}>
        {children}
      </MuiDialogActions>
    );
  },
);

export interface DialogActionsProps extends MuiDialogActionsProps, CoreProps {}
