/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityStatsVulnerabilitiesSslV2
 */
export interface ControlEntityStatsVulnerabilitiesSslV2 {
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsVulnerabilitiesSslV2
     */
    valid?: object;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsVulnerabilitiesSslV2
     */
    invalid?: object;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsVulnerabilitiesSslV2
     */
    count?: number;
}

/**
 * Check if a given object implements the ControlEntityStatsVulnerabilitiesSslV2 interface.
 */
export function instanceOfControlEntityStatsVulnerabilitiesSslV2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsVulnerabilitiesSslV2FromJSON(json: any): ControlEntityStatsVulnerabilitiesSslV2 {
    return ControlEntityStatsVulnerabilitiesSslV2FromJSONTyped(json, false);
}

export function ControlEntityStatsVulnerabilitiesSslV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsVulnerabilitiesSslV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'invalid': !exists(json, 'invalid') ? undefined : json['invalid'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ControlEntityStatsVulnerabilitiesSslV2ToJSON(value?: ControlEntityStatsVulnerabilitiesSslV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'invalid': value.invalid,
        'count': value.count,
    };
}

