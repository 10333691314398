/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalCoalitionUser } from './SupplementalCoalitionUser';
import {
    SupplementalCoalitionUserFromJSON,
    SupplementalCoalitionUserFromJSONTyped,
    SupplementalCoalitionUserToJSON,
} from './SupplementalCoalitionUser';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalPostFormInstanceInternalBody
 */
export interface SupplementalPostFormInstanceInternalBody {
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalPostFormInstanceInternalBody
     */
    locale?: SupplementalLocale;
    /**
     * 
     * @type {SupplementalCoalitionUser}
     * @memberof SupplementalPostFormInstanceInternalBody
     */
    internal_user: SupplementalCoalitionUser;
    /**
     * 
     * @type {boolean}
     * @memberof SupplementalPostFormInstanceInternalBody
     */
    copy_data?: boolean;
}

/**
 * Check if a given object implements the SupplementalPostFormInstanceInternalBody interface.
 */
export function instanceOfSupplementalPostFormInstanceInternalBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internal_user" in value;

    return isInstance;
}

export function SupplementalPostFormInstanceInternalBodyFromJSON(json: any): SupplementalPostFormInstanceInternalBody {
    return SupplementalPostFormInstanceInternalBodyFromJSONTyped(json, false);
}

export function SupplementalPostFormInstanceInternalBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalPostFormInstanceInternalBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': !exists(json, 'locale') ? undefined : SupplementalLocaleFromJSON(json['locale']),
        'internal_user': SupplementalCoalitionUserFromJSON(json['internal_user']),
        'copy_data': !exists(json, 'copy_data') ? undefined : json['copy_data'],
    };
}

export function SupplementalPostFormInstanceInternalBodyToJSON(value?: SupplementalPostFormInstanceInternalBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': SupplementalLocaleToJSON(value.locale),
        'internal_user': SupplementalCoalitionUserToJSON(value.internal_user),
        'copy_data': value.copy_data,
    };
}

