/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskGlassdoorRating } from './ExecRiskGlassdoorRating';
import {
    ExecRiskGlassdoorRatingFromJSON,
    ExecRiskGlassdoorRatingFromJSONTyped,
    ExecRiskGlassdoorRatingToJSON,
} from './ExecRiskGlassdoorRating';

/**
 * 
 * @export
 * @interface ExecRiskGlassDoorRatingsResponse
 */
export interface ExecRiskGlassDoorRatingsResponse {
    /**
     * 
     * @type {ExecRiskGlassdoorRating}
     * @memberof ExecRiskGlassDoorRatingsResponse
     */
    result: ExecRiskGlassdoorRating;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassDoorRatingsResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassDoorRatingsResponse
     */
    process_id: string;
}

/**
 * Check if a given object implements the ExecRiskGlassDoorRatingsResponse interface.
 */
export function instanceOfExecRiskGlassDoorRatingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function ExecRiskGlassDoorRatingsResponseFromJSON(json: any): ExecRiskGlassDoorRatingsResponse {
    return ExecRiskGlassDoorRatingsResponseFromJSONTyped(json, false);
}

export function ExecRiskGlassDoorRatingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskGlassDoorRatingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ExecRiskGlassdoorRatingFromJSON(json['result']),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'process_id': json['process_id'],
    };
}

export function ExecRiskGlassDoorRatingsResponseToJSON(value?: ExecRiskGlassDoorRatingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ExecRiskGlassdoorRatingToJSON(value.result),
        'schema_version': value.schema_version,
        'process_id': value.process_id,
    };
}

