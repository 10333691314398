/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormInstanceWithAttachments } from './SupplementalFormInstanceWithAttachments';
import {
    SupplementalFormInstanceWithAttachmentsFromJSON,
    SupplementalFormInstanceWithAttachmentsFromJSONTyped,
    SupplementalFormInstanceWithAttachmentsToJSON,
} from './SupplementalFormInstanceWithAttachments';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';
import type { SupplementalRecipientLog } from './SupplementalRecipientLog';
import {
    SupplementalRecipientLogFromJSON,
    SupplementalRecipientLogFromJSONTyped,
    SupplementalRecipientLogToJSON,
} from './SupplementalRecipientLog';

/**
 * 
 * @export
 * @interface SupplementalGetReportFormInstancesByFilterResponseResultsInner
 */
export interface SupplementalGetReportFormInstancesByFilterResponseResultsInner {
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    recipient_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    account_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    quote_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    policyholder_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    jira_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    broker_uuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    broker_email?: string | null;
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    locale: SupplementalLocale;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<SupplementalRecipientLog>}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    recipient_logs?: Array<SupplementalRecipientLog>;
    /**
     * 
     * @type {Array<SupplementalFormInstanceWithAttachments>}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInner
     */
    form_instances?: Array<SupplementalFormInstanceWithAttachments>;
}

/**
 * Check if a given object implements the SupplementalGetReportFormInstancesByFilterResponseResultsInner interface.
 */
export function instanceOfSupplementalGetReportFormInstancesByFilterResponseResultsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipient_id" in value;
    isInstance = isInstance && "account_uuid" in value;
    isInstance = isInstance && "quote_uuid" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSON(json: any): SupplementalGetReportFormInstancesByFilterResponseResultsInner {
    return SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSONTyped(json, false);
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetReportFormInstancesByFilterResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient_id': json['recipient_id'],
        'account_uuid': json['account_uuid'],
        'quote_uuid': json['quote_uuid'],
        'policyholder_email': !exists(json, 'policyholder_email') ? undefined : json['policyholder_email'],
        'jira_id': !exists(json, 'jira_id') ? undefined : json['jira_id'],
        'broker_uuid': !exists(json, 'broker_uuid') ? undefined : json['broker_uuid'],
        'broker_email': !exists(json, 'broker_email') ? undefined : json['broker_email'],
        'locale': SupplementalLocaleFromJSON(json['locale']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'recipient_logs': !exists(json, 'recipient_logs') ? undefined : ((json['recipient_logs'] as Array<any>).map(SupplementalRecipientLogFromJSON)),
        'form_instances': !exists(json, 'form_instances') ? undefined : ((json['form_instances'] as Array<any>).map(SupplementalFormInstanceWithAttachmentsFromJSON)),
    };
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerToJSON(value?: SupplementalGetReportFormInstancesByFilterResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_id': value.recipient_id,
        'account_uuid': value.account_uuid,
        'quote_uuid': value.quote_uuid,
        'policyholder_email': value.policyholder_email,
        'jira_id': value.jira_id,
        'broker_uuid': value.broker_uuid,
        'broker_email': value.broker_email,
        'locale': SupplementalLocaleToJSON(value.locale),
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'recipient_logs': value.recipient_logs === undefined ? undefined : ((value.recipient_logs as Array<any>).map(SupplementalRecipientLogToJSON)),
        'form_instances': value.form_instances === undefined ? undefined : ((value.form_instances as Array<any>).map(SupplementalFormInstanceWithAttachmentsToJSON)),
    };
}

