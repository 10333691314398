/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFDecisionConditionPlain = {
    Accepted: 'accepted',
    Waived: 'waived'
} as const;
export type CRSFDecisionConditionPlain = typeof CRSFDecisionConditionPlain[keyof typeof CRSFDecisionConditionPlain];


export function CRSFDecisionConditionPlainFromJSON(json: any): CRSFDecisionConditionPlain {
    return CRSFDecisionConditionPlainFromJSONTyped(json, false);
}

export function CRSFDecisionConditionPlainFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDecisionConditionPlain {
    return json as CRSFDecisionConditionPlain;
}

export function CRSFDecisionConditionPlainToJSON(value?: CRSFDecisionConditionPlain | null): any {
    return value as any;
}

