/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityReportResponse
 */
export interface MonitEntityReportResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportResponse
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportResponse
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityReportResponse
     */
    type?: string;
}

/**
 * Check if a given object implements the MonitEntityReportResponse interface.
 */
export function instanceOfMonitEntityReportResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityReportResponseFromJSON(json: any): MonitEntityReportResponse {
    return MonitEntityReportResponseFromJSONTyped(json, false);
}

export function MonitEntityReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MonitEntityReportResponseToJSON(value?: MonitEntityReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'external_id': value.external_id,
        'entity_domain': value.entity_domain,
        'entity_id': value.entity_id,
        'type': value.type,
    };
}

