import React from 'react';
import {
  Grid,
  Typography,
  TypographyProps,
  TypographyLink,
  CopyToClipboard,
} from '@explorer/core';
import { CellEmpty } from '../CellEmpty';
import { GotoClaimProps } from '../../../types';

/**
 * CellClaim
 */
export const CellClaim = ({
  claimId,
  coalitionClaimId,
  variant = 'bodyXS',
  gotoClaimProps,
}: CellClaimProps) => {
  if (!claimId || !coalitionClaimId) return <CellEmpty variant={variant} />;

  if (gotoClaimProps) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item mr={0.5}>
          <CopyToClipboard size="small" copyMe={coalitionClaimId} />
        </Grid>
        <Grid item>
          <TypographyLink
            variant={variant}
            title={{
              id: 'tables-presets.goto-claim',
              values: { claim: coalitionClaimId },
            }}
            params={{ id: claimId }}
            {...gotoClaimProps()}
          >
            {coalitionClaimId}
          </TypographyLink>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item mr={0.5}>
        <CopyToClipboard size="small" copyMe={coalitionClaimId} />
      </Grid>
      <Grid item>
        <Typography variant={variant}>{coalitionClaimId}</Typography>
      </Grid>
    </Grid>
  );
};

/**
 * CellClaim Props
 */
export interface CellClaimProps extends Pick<TypographyProps, 'variant'> {
  claimId?: string;
  coalitionClaimId?: string;
  gotoClaimProps?: GotoClaimProps;
}
