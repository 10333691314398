import axios, { AxiosResponse, AxiosError } from 'axios';
import { paramsToQuerystring } from '@explorer/helpers';
import {
  ApiClientAuth,
  ApiClientHeaders,
  ApiClientOptions,
  ApiClientPromise,
} from '../../types';

export class ApiClient {
  private url: string;

  private headers?: ApiClientHeaders = {};

  // private auth: ApiClientAuth = 'none';

  // private token?: string | null = null;

  constructor(
    url: string,
    auth: ApiClientAuth = 'none',
    token: string | null = null,
    headers: ApiClientHeaders = {},
  ) {
    this.url = url;
    // this.auth = auth;
    // this.token = token;

    if (token) {
      if (auth === 'bearer') {
        this.headers = { ...headers, Authorization: `Bearer ${token}` };
      }
      if (auth === 'token') {
        this.headers = { ...headers, Authorization: `Token ${token}` };
      }
      if (auth === 'x-token') {
        this.headers = { ...headers, 'X-Token': token };
      }
      if (auth === 'ott') {
        this.headers = { ...headers, OTT: `${token}` };
      }
      if (auth === 'none') {
        this.headers = { ...headers };
      }
    } else {
      this.headers = { ...headers };
    }
  }

  /**
   * The Request Method
   */
  private request = <Data = any>({
    method,
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: ApiClientOptions): ApiClientPromise<Data> => {
    const { url, headers } = this;

    const formDataHeaders =
      payload instanceof FormData
        ? {
            'Content-Type': `multipart/form-data;`,
          }
        : {};

    let requestURL = url + endpoint;
    if (dynamicPathVar) {
      requestURL = `${requestURL}/${dynamicPathVar}`;
    }

    return new Promise((resolve) =>
      axios({
        data: payload,
        method,
        url: requestURL + paramsToQuerystring(params),
        headers: {
          ...headers,
          ...formDataHeaders,
        },
      })
        .then((res: AxiosResponse) => {
          if (res.status >= 200 && res.status <= 299) {
            return resolve({
              rawData: res?.data || res,
              error: null,
            });
          }

          return resolve({
            rawData: null,
            error: res,
          });
        })
        .catch((err: AxiosError) => {
          return resolve({
            rawData: null,
            error: err?.response || err,
          });
        }),
    );
  };

  /**
   * GET Request
   */
  public GET = <Data = any>({
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: GETOptions): ApiClientPromise<Data> =>
    this.request({
      method: 'GET',
      endpoint,
      params,
      payload,
      dynamicPathVar,
    });

  /**
   * POST Request
   */
  public POST = <Data = any>({
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: POSTOptions): ApiClientPromise<Data> =>
    this.request({
      method: 'POST',
      endpoint,
      params,
      payload,
      dynamicPathVar,
    });

  /**
   * PUT Request
   */
  public PUT = <Data = any>({
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: PUTOptions): ApiClientPromise<Data> =>
    this.request({
      method: 'PUT',
      endpoint,
      params,
      payload,
      dynamicPathVar,
    });

  /**
   * PATCH Request
   */
  public PATCH = <Data = any>({
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: PATCHOptions): ApiClientPromise<Data> =>
    this.request({
      method: 'PATCH',
      endpoint,
      params,
      payload,
      dynamicPathVar,
    });

  /**
   * DELETE Request
   */
  public DELETE = <Data = any>({
    endpoint,
    params,
    payload,
    dynamicPathVar,
  }: DELETEOptions): ApiClientPromise<Data> =>
    this.request({
      method: 'DELETE',
      endpoint,
      params,
      payload,
      dynamicPathVar,
    });
}

type GETOptions = Pick<
  ApiClientOptions,
  'endpoint' | 'params' | 'payload' | 'dynamicPathVar'
>;

type POSTOptions = Pick<
  ApiClientOptions,
  'endpoint' | 'params' | 'payload' | 'dynamicPathVar'
>;

type PUTOptions = Pick<
  ApiClientOptions,
  'endpoint' | 'params' | 'payload' | 'dynamicPathVar'
>;

type PATCHOptions = Pick<
  ApiClientOptions,
  'endpoint' | 'params' | 'payload' | 'dynamicPathVar'
>;

type DELETEOptions = Pick<
  ApiClientOptions,
  'endpoint' | 'params' | 'payload' | 'dynamicPathVar'
>;
