/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityAssetDomainBlocklist
 */
export interface ControlEntityAssetDomainBlocklist {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    root_domain?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetDomainBlocklist
     */
    last_found?: string;
}

/**
 * Check if a given object implements the ControlEntityAssetDomainBlocklist interface.
 */
export function instanceOfControlEntityAssetDomainBlocklist(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityAssetDomainBlocklistFromJSON(json: any): ControlEntityAssetDomainBlocklist {
    return ControlEntityAssetDomainBlocklistFromJSONTyped(json, false);
}

export function ControlEntityAssetDomainBlocklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetDomainBlocklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'root_domain': !exists(json, 'root_domain') ? undefined : json['root_domain'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
    };
}

export function ControlEntityAssetDomainBlocklistToJSON(value?: ControlEntityAssetDomainBlocklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'path': value.path,
        'root_domain': value.root_domain,
        'ip_addresses': value.ip_addresses,
        'source_list_name': value.source_list_name,
        'category': value.category,
        'subcategory': value.subcategory,
        'created': value.created,
        'last_found': value.last_found,
    };
}

