/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlSupplementalFormType = {
    Static: 'RSA_STATIC',
    StaticShort: 'RSA_STATIC_SHORT',
    StaticV202401: 'RSA_STATIC_V202401'
} as const;
export type ControlSupplementalFormType = typeof ControlSupplementalFormType[keyof typeof ControlSupplementalFormType];


export function ControlSupplementalFormTypeFromJSON(json: any): ControlSupplementalFormType {
    return ControlSupplementalFormTypeFromJSONTyped(json, false);
}

export function ControlSupplementalFormTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSupplementalFormType {
    return json as ControlSupplementalFormType;
}

export function ControlSupplementalFormTypeToJSON(value?: ControlSupplementalFormType | null): any {
    return value as any;
}

