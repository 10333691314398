import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';
import { fontDMSans, fontPublicSans } from './fonts';
import { createTheme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import { CoalitionVariant } from '@mui/material';

const { breakpoints } = createTheme();

const coalitionTypography: Record<CoalitionVariant, TypographyStyleOptions> = {
  h1: {
    fontFamily: fontDMSans,
    fontSize: 72,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 60,
    },
  },
  h2: {
    fontFamily: fontDMSans,
    fontSize: 60,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 48,
    },
  },
  h3: {
    fontFamily: fontDMSans,
    fontSize: 48,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  h4: {
    fontFamily: fontDMSans,
    fontSize: 36,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  h5: {
    fontFamily: fontDMSans,
    fontSize: 24,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  h6: {
    fontFamily: fontDMSans,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  subtitle1: {
    fontFamily: fontDMSans,
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitle2: {
    fontFamily: fontDMSans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  subtitleXL: {
    fontFamily: fontDMSans,
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitleL: {
    fontFamily: fontDMSans,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitleM: {
    fontFamily: fontDMSans,
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitleS: {
    fontFamily: fontDMSans,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitleCapsL: {
    fontFamily: fontDMSans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  subtitleCapsS: {
    fontFamily: fontDMSans,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  body1: {
    fontFamily: fontPublicSans,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
  },
  body2: {
    fontFamily: fontPublicSans,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyXXL: {
    fontFamily: fontPublicSans,
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyXL: {
    fontFamily: fontPublicSans,
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyL: {
    fontFamily: fontPublicSans,
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyM: {
    fontFamily: fontPublicSans,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyS: {
    fontFamily: fontPublicSans,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    [breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  bodyXS: {
    fontFamily: fontPublicSans,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyXXS: {
    fontFamily: fontPublicSans,
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0,
  },
  caption: {
    fontFamily: fontPublicSans,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
  },
  button: {
    fontFamily: fontPublicSans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0,
    textTransform: 'none',
  },
  overline: {
    fontFamily: fontPublicSans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  overlineL: {
    fontFamily: fontPublicSans,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  overlineM: {
    fontFamily: fontPublicSans,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  overlineS: {
    fontFamily: fontPublicSans,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  overlineXS: {
    fontFamily: fontPublicSans,
    fontSize: 10,
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
};

export const typography: TypographyOptions = {
  fontFamily: fontPublicSans,
  ...coalitionTypography,
};

export const createTypographyClasses = () => {
  const PREFIX = 'CoalitionTypography';
  const typographyClasses: StyleRules = {};
  const variantKeys = Object.keys(coalitionTypography);

  for (let i = 0, ii = variantKeys.length; i < ii; i++) {
    const variant = variantKeys[i];

    typographyClasses[`.${PREFIX}-${variant}`] = coalitionTypography[variant];
  }

  return typographyClasses;
};
