/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppCoreEnumsAuth0Roles } from './ControlAppCoreEnumsAuth0Roles';
import {
    ControlAppCoreEnumsAuth0RolesFromJSON,
    ControlAppCoreEnumsAuth0RolesFromJSONTyped,
    ControlAppCoreEnumsAuth0RolesToJSON,
} from './ControlAppCoreEnumsAuth0Roles';

/**
 * 
 * @export
 * @interface ControlAbbreviatedUserResponse
 */
export interface ControlAbbreviatedUserResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAbbreviatedUserResponse
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    last_login?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAbbreviatedUserResponse
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAbbreviatedUserResponse
     */
    policyholder?: boolean;
    /**
     * 
     * @type {Array<ControlAppCoreEnumsAuth0Roles>}
     * @memberof ControlAbbreviatedUserResponse
     */
    roles?: Array<ControlAppCoreEnumsAuth0Roles>;
}

/**
 * Check if a given object implements the ControlAbbreviatedUserResponse interface.
 */
export function instanceOfControlAbbreviatedUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ControlAbbreviatedUserResponseFromJSON(json: any): ControlAbbreviatedUserResponse {
    return ControlAbbreviatedUserResponseFromJSONTyped(json, false);
}

export function ControlAbbreviatedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAbbreviatedUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'client_id': json['client_id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': json['email'],
        'active': json['active'],
        'last_login': !exists(json, 'last_login') ? undefined : json['last_login'],
        'created': json['created'],
        'updated': json['updated'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'policyholder': !exists(json, 'policyholder') ? undefined : json['policyholder'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(ControlAppCoreEnumsAuth0RolesFromJSON)),
    };
}

export function ControlAbbreviatedUserResponseToJSON(value?: ControlAbbreviatedUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'client_id': value.client_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'active': value.active,
        'last_login': value.last_login,
        'created': value.created,
        'updated': value.updated,
        'status': value.status,
        'policyholder': value.policyholder,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(ControlAppCoreEnumsAuth0RolesToJSON)),
    };
}

