import { createApiEndpoints, FuncEndpoint, P1, P2 } from '../utils';

const PREFIX_V1CLAIMS = '/v1/claims/web';

const ENDPOINTS_V1CLAIMS = [
  `/claims/${P1}/clr`,
  `/claims/${P1}/cls`,
  `/claims/${P1}/contacts/${P2}`,
  `/claims/${P1}/contacts`,
  `/claims/${P1}/coverages`,
  `/claims/${P1}/documents`,
  `/claims/${P1}/events`,
  `/claims/${P1}/losses`,
  `/claims/${P1}/vendors/${P2}`,
  `/claims/${P1}/vendors`,
  `/claims/${P1}`,
  `/claims`,
  `/clr`,
  `/contacts/${P1}`,
  `/contacts`,
  `/losses/${P1}`,
  `/losses`,
  `/options`,
  `/policies/${P1}`,
  `/policies`,
  `/vendors/${P1}`,
  `/vendors`,
] as const;

type Endpoints = typeof ENDPOINTS_V1CLAIMS[number];

export type EndpointsV1CLAIMS = Record<Endpoints, FuncEndpoint>;

export const endpointsV1CLAIMS = createApiEndpoints<Endpoints>(
  PREFIX_V1CLAIMS,
  ENDPOINTS_V1CLAIMS,
) as EndpointsV1CLAIMS;
