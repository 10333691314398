/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitIPSourcelistsResultEvent
 */
export interface MonitIPSourcelistsResultEvent {
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    ip_address?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitIPSourcelistsResultEvent
     */
    subcategory?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitIPSourcelistsResultEvent
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitIPSourcelistsResultEvent
     */
    last_found?: number;
}

/**
 * Check if a given object implements the MonitIPSourcelistsResultEvent interface.
 */
export function instanceOfMonitIPSourcelistsResultEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitIPSourcelistsResultEventFromJSON(json: any): MonitIPSourcelistsResultEvent {
    return MonitIPSourcelistsResultEventFromJSONTyped(json, false);
}

export function MonitIPSourcelistsResultEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitIPSourcelistsResultEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
    };
}

export function MonitIPSourcelistsResultEventToJSON(value?: MonitIPSourcelistsResultEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ip_address': value.ip_address,
        'service': value.service,
        'region': value.region,
        'source_list_name': value.source_list_name,
        'category': value.category,
        'subcategory': value.subcategory,
        'created': value.created,
        'last_found': value.last_found,
    };
}

