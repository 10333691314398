/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAddressSchemaORM } from './ControlAddressSchemaORM';
import {
    ControlAddressSchemaORMFromJSON,
    ControlAddressSchemaORMFromJSONTyped,
    ControlAddressSchemaORMToJSON,
} from './ControlAddressSchemaORM';
import type { ControlExperianTradeDBT } from './ControlExperianTradeDBT';
import {
    ControlExperianTradeDBTFromJSON,
    ControlExperianTradeDBTFromJSONTyped,
    ControlExperianTradeDBTToJSON,
} from './ControlExperianTradeDBT';
import type { ControlGlassdoorRating } from './ControlGlassdoorRating';
import {
    ControlGlassdoorRatingFromJSON,
    ControlGlassdoorRatingFromJSONTyped,
    ControlGlassdoorRatingToJSON,
} from './ControlGlassdoorRating';
import type { ControlRiskFlags } from './ControlRiskFlags';
import {
    ControlRiskFlagsFromJSON,
    ControlRiskFlagsFromJSONTyped,
    ControlRiskFlagsToJSON,
} from './ControlRiskFlags';

/**
 * 
 * @export
 * @interface ControlCompanyInfoResponse
 */
export interface ControlCompanyInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlCompanyInfoResponse
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlCompanyInfoResponse
     */
    scanned: string;
    /**
     * 
     * @type {ControlExperianTradeDBT}
     * @memberof ControlCompanyInfoResponse
     */
    days_beyond_terms?: ControlExperianTradeDBT;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    fsr_score?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlCompanyInfoResponse
     */
    domains_list?: Array<string>;
    /**
     * 
     * @type {ControlAddressSchemaORM}
     * @memberof ControlCompanyInfoResponse
     */
    location?: ControlAddressSchemaORM;
    /**
     * 
     * @type {string}
     * @memberof ControlCompanyInfoResponse
     */
    company_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlCompanyInfoResponse
     */
    industries?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlCompanyInfoResponse
     */
    primary_industries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlCompanyInfoResponse
     */
    employee_range?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlCompanyInfoResponse
     */
    revenue_range?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    revenue?: number;
    /**
     * 
     * @type {Array<ControlRiskFlags>}
     * @memberof ControlCompanyInfoResponse
     */
    risk_flags?: Array<ControlRiskFlags>;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    lien_count?: number;
    /**
     * 
     * @type {ControlGlassdoorRating}
     * @memberof ControlCompanyInfoResponse
     */
    employee_rating: ControlGlassdoorRating;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    ppploan_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    investor_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlCompanyInfoResponse
     */
    violation_count?: number;
}

/**
 * Check if a given object implements the ControlCompanyInfoResponse interface.
 */
export function instanceOfControlCompanyInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "scanned" in value;
    isInstance = isInstance && "employee_rating" in value;

    return isInstance;
}

export function ControlCompanyInfoResponseFromJSON(json: any): ControlCompanyInfoResponse {
    return ControlCompanyInfoResponseFromJSONTyped(json, false);
}

export function ControlCompanyInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCompanyInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company_name': json['company_name'],
        'scanned': json['scanned'],
        'days_beyond_terms': !exists(json, 'days_beyond_terms') ? undefined : ControlExperianTradeDBTFromJSON(json['days_beyond_terms']),
        'fsr_score': !exists(json, 'fsr_score') ? undefined : json['fsr_score'],
        'domains_list': !exists(json, 'domains_list') ? undefined : json['domains_list'],
        'location': !exists(json, 'location') ? undefined : ControlAddressSchemaORMFromJSON(json['location']),
        'company_type': !exists(json, 'company_type') ? undefined : json['company_type'],
        'industries': !exists(json, 'industries') ? undefined : json['industries'],
        'primary_industries': !exists(json, 'primary_industries') ? undefined : json['primary_industries'],
        'employee_range': !exists(json, 'employee_range') ? undefined : json['employee_range'],
        'revenue_range': !exists(json, 'revenue_range') ? undefined : json['revenue_range'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'risk_flags': !exists(json, 'risk_flags') ? undefined : ((json['risk_flags'] as Array<any>).map(ControlRiskFlagsFromJSON)),
        'lien_count': !exists(json, 'lien_count') ? undefined : json['lien_count'],
        'employee_rating': ControlGlassdoorRatingFromJSON(json['employee_rating']),
        'ppploan_count': !exists(json, 'ppploan_count') ? undefined : json['ppploan_count'],
        'investor_count': !exists(json, 'investor_count') ? undefined : json['investor_count'],
        'violation_count': !exists(json, 'violation_count') ? undefined : json['violation_count'],
    };
}

export function ControlCompanyInfoResponseToJSON(value?: ControlCompanyInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_name': value.company_name,
        'scanned': value.scanned,
        'days_beyond_terms': ControlExperianTradeDBTToJSON(value.days_beyond_terms),
        'fsr_score': value.fsr_score,
        'domains_list': value.domains_list,
        'location': ControlAddressSchemaORMToJSON(value.location),
        'company_type': value.company_type,
        'industries': value.industries,
        'primary_industries': value.primary_industries,
        'employee_range': value.employee_range,
        'revenue_range': value.revenue_range,
        'revenue': value.revenue,
        'risk_flags': value.risk_flags === undefined ? undefined : ((value.risk_flags as Array<any>).map(ControlRiskFlagsToJSON)),
        'lien_count': value.lien_count,
        'employee_rating': ControlGlassdoorRatingToJSON(value.employee_rating),
        'ppploan_count': value.ppploan_count,
        'investor_count': value.investor_count,
        'violation_count': value.violation_count,
    };
}

