/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlZeroDayAlertStats
 */
export interface ControlZeroDayAlertStats {
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    total_exposure_usd: number;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    client_count: number;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    fixed_count: number;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    ignored_count: number;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    false_positive_count: number;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlertStats
     */
    unresolved_count: number;
}

/**
 * Check if a given object implements the ControlZeroDayAlertStats interface.
 */
export function instanceOfControlZeroDayAlertStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total_exposure_usd" in value;
    isInstance = isInstance && "client_count" in value;
    isInstance = isInstance && "fixed_count" in value;
    isInstance = isInstance && "ignored_count" in value;
    isInstance = isInstance && "false_positive_count" in value;
    isInstance = isInstance && "unresolved_count" in value;

    return isInstance;
}

export function ControlZeroDayAlertStatsFromJSON(json: any): ControlZeroDayAlertStats {
    return ControlZeroDayAlertStatsFromJSONTyped(json, false);
}

export function ControlZeroDayAlertStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAlertStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_exposure_usd': json['total_exposure_usd'],
        'client_count': json['client_count'],
        'fixed_count': json['fixed_count'],
        'ignored_count': json['ignored_count'],
        'false_positive_count': json['false_positive_count'],
        'unresolved_count': json['unresolved_count'],
    };
}

export function ControlZeroDayAlertStatsToJSON(value?: ControlZeroDayAlertStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_exposure_usd': value.total_exposure_usd,
        'client_count': value.client_count,
        'fixed_count': value.fixed_count,
        'ignored_count': value.ignored_count,
        'false_positive_count': value.false_positive_count,
        'unresolved_count': value.unresolved_count,
    };
}

