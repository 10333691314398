/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDomainBlocklistAgg } from './MonitEntityDomainBlocklistAgg';
import {
    MonitEntityDomainBlocklistAggFromJSON,
    MonitEntityDomainBlocklistAggFromJSONTyped,
    MonitEntityDomainBlocklistAggToJSON,
} from './MonitEntityDomainBlocklistAgg';

/**
 * 
 * @export
 * @interface MonitEntityDomainsBlocklistsAggResponse
 */
export interface MonitEntityDomainsBlocklistsAggResponse {
    /**
     * 
     * @type {Array<MonitEntityDomainBlocklistAgg>}
     * @memberof MonitEntityDomainsBlocklistsAggResponse
     */
    results?: Array<MonitEntityDomainBlocklistAgg>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDomainsBlocklistsAggResponse
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDomainsBlocklistsAggResponse
     */
    assets?: number;
}

/**
 * Check if a given object implements the MonitEntityDomainsBlocklistsAggResponse interface.
 */
export function instanceOfMonitEntityDomainsBlocklistsAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDomainsBlocklistsAggResponseFromJSON(json: any): MonitEntityDomainsBlocklistsAggResponse {
    return MonitEntityDomainsBlocklistsAggResponseFromJSONTyped(json, false);
}

export function MonitEntityDomainsBlocklistsAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDomainsBlocklistsAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MonitEntityDomainBlocklistAggFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
    };
}

export function MonitEntityDomainsBlocklistsAggResponseToJSON(value?: MonitEntityDomainsBlocklistsAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MonitEntityDomainBlocklistAggToJSON)),
        'total': value.total,
        'assets': value.assets,
    };
}

