/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAuthenticationFactor } from './ControlAuthenticationFactor';
import {
    ControlAuthenticationFactorFromJSON,
    ControlAuthenticationFactorFromJSONTyped,
    ControlAuthenticationFactorToJSON,
} from './ControlAuthenticationFactor';

/**
 * 
 * @export
 * @interface ControlAuthenticator
 */
export interface ControlAuthenticator {
    /**
     * 
     * @type {ControlAuthenticationFactor}
     * @memberof ControlAuthenticator
     */
    factor: ControlAuthenticationFactor;
}

/**
 * Check if a given object implements the ControlAuthenticator interface.
 */
export function instanceOfControlAuthenticator(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "factor" in value;

    return isInstance;
}

export function ControlAuthenticatorFromJSON(json: any): ControlAuthenticator {
    return ControlAuthenticatorFromJSONTyped(json, false);
}

export function ControlAuthenticatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAuthenticator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'factor': ControlAuthenticationFactorFromJSON(json['factor']),
    };
}

export function ControlAuthenticatorToJSON(value?: ControlAuthenticator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'factor': ControlAuthenticationFactorToJSON(value.factor),
    };
}

