/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRAFindingsDatapoint = {
    Web: 'web',
    Services: 'services'
} as const;
export type CRAFindingsDatapoint = typeof CRAFindingsDatapoint[keyof typeof CRAFindingsDatapoint];


export function CRAFindingsDatapointFromJSON(json: any): CRAFindingsDatapoint {
    return CRAFindingsDatapointFromJSONTyped(json, false);
}

export function CRAFindingsDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAFindingsDatapoint {
    return json as CRAFindingsDatapoint;
}

export function CRAFindingsDatapointToJSON(value?: CRAFindingsDatapoint | null): any {
    return value as any;
}

