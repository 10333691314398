/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFSecReviewScanType = {
    Underwriting: 'underwriting',
    Cra: 'cra',
    Secondary: 'secondary',
    Validation: 'validation'
} as const;
export type CRSFSecReviewScanType = typeof CRSFSecReviewScanType[keyof typeof CRSFSecReviewScanType];


export function CRSFSecReviewScanTypeFromJSON(json: any): CRSFSecReviewScanType {
    return CRSFSecReviewScanTypeFromJSONTyped(json, false);
}

export function CRSFSecReviewScanTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewScanType {
    return json as CRSFSecReviewScanType;
}

export function CRSFSecReviewScanTypeToJSON(value?: CRSFSecReviewScanType | null): any {
    return value as any;
}

