/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewDLAggregateSumInnerResult } from './SecReviewDLAggregateSumInnerResult';
import {
    SecReviewDLAggregateSumInnerResultFromJSON,
    SecReviewDLAggregateSumInnerResultFromJSONTyped,
    SecReviewDLAggregateSumInnerResultToJSON,
} from './SecReviewDLAggregateSumInnerResult';

/**
 * 
 * @export
 * @interface SecReviewDLAggregateSumResult
 */
export interface SecReviewDLAggregateSumResult {
    /**
     * 
     * @type {Array<SecReviewDLAggregateSumInnerResult>}
     * @memberof SecReviewDLAggregateSumResult
     */
    main_leaks?: Array<SecReviewDLAggregateSumInnerResult>;
    /**
     * 
     * @type {Array<SecReviewDLAggregateSumInnerResult>}
     * @memberof SecReviewDLAggregateSumResult
     */
    features?: Array<SecReviewDLAggregateSumInnerResult>;
    /**
     * 
     * @type {Array<SecReviewDLAggregateSumInnerResult>}
     * @memberof SecReviewDLAggregateSumResult
     */
    events?: Array<SecReviewDLAggregateSumInnerResult>;
}

/**
 * Check if a given object implements the SecReviewDLAggregateSumResult interface.
 */
export function instanceOfSecReviewDLAggregateSumResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewDLAggregateSumResultFromJSON(json: any): SecReviewDLAggregateSumResult {
    return SecReviewDLAggregateSumResultFromJSONTyped(json, false);
}

export function SecReviewDLAggregateSumResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewDLAggregateSumResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'main_leaks': !exists(json, 'main_leaks') ? undefined : ((json['main_leaks'] as Array<any>).map(SecReviewDLAggregateSumInnerResultFromJSON)),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(SecReviewDLAggregateSumInnerResultFromJSON)),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(SecReviewDLAggregateSumInnerResultFromJSON)),
    };
}

export function SecReviewDLAggregateSumResultToJSON(value?: SecReviewDLAggregateSumResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'main_leaks': value.main_leaks === undefined ? undefined : ((value.main_leaks as Array<any>).map(SecReviewDLAggregateSumInnerResultToJSON)),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(SecReviewDLAggregateSumInnerResultToJSON)),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(SecReviewDLAggregateSumInnerResultToJSON)),
    };
}

