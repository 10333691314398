/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';

/**
 * 
 * @export
 * @interface CRSFTriggerScanIssueRequest
 */
export interface CRSFTriggerScanIssueRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerScanIssueRequest
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFTriggerScanIssueRequest
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerScanIssueRequest
     */
    issue_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerScanIssueRequest
     */
    request_origin?: string;
}

/**
 * Check if a given object implements the CRSFTriggerScanIssueRequest interface.
 */
export function instanceOfCRSFTriggerScanIssueRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "issue_key" in value;

    return isInstance;
}

export function CRSFTriggerScanIssueRequestFromJSON(json: any): CRSFTriggerScanIssueRequest {
    return CRSFTriggerScanIssueRequestFromJSONTyped(json, false);
}

export function CRSFTriggerScanIssueRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTriggerScanIssueRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': json['issue_key'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
    };
}

export function CRSFTriggerScanIssueRequestToJSON(value?: CRSFTriggerScanIssueRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'request_origin': value.request_origin,
    };
}

