/**
 * Capitalizes words in a sentence.
 *
 * __> Example:__
 * ```ts
 * capitalize('binary edge');
 * // "Binary Edge"
 *
 * capitalize('binary edge', true);
 * // "Binary edge"
 * ```
 */

export const capitalize: Capitalize = (str, onlyFirst = false) => {
  if (str) {
    if (onlyFirst) return `${str[0].toUpperCase()}${str.slice(1)}`;

    const splitedWords = str.replace(/\s+/g, ' ').trim().split(' ');
    const capitalizedWords = splitedWords.map(
      (item) => `${item[0].toUpperCase()}${item.slice(1)}`,
    );
    return capitalizedWords.join(' ');
  }
  return '';
};

type Capitalize = (str?: string, onlyFirst?: boolean) => string;
