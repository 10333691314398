import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ReputationHarmLossIcon
 */
export const ReputationHarmLossIcon = ({
  ...props
}: ReputationHarmLossIconProps) => {
  return (
    <Icon label="reputational_harm" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12,14.1c2,0,3.5-1.6,3.5-3.5c0-2-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5C8.4,12.5,10,14.1,12,14.1z M12,12.4c-0.8,0-1.6-0.6-1.8-1.4c0-0.1,0-0.2,0.1-0.3c0.6-0.2,1.1-0.5,1.5-1c0,0,0.1-0.1,0.1-0.1s0.1,0,0.1,0.1c0.4,0.4,0.9,0.8,1.5,1c0.1,0,0.2,0.1,0.1,0.3C13.6,11.9,12.8,12.4,12,12.4z M26.3,10.5c0.1-0.1,0.3-0.2,0.5-0.1c0.2,0.1,0.3,0.2,0.3,0.4v4.2c0,0.2-0.2,0.4-0.4,0.4h-3.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.3,0.1-0.4l1-1.1c0.1-0.1,0.1-0.2,0-0.3L22,11.5c-0.1-0.1-0.2-0.1-0.3,0l-1.2,0.9c-0.4,0.3-1.1,0.3-1.4-0.1l-2.9-3.3c-0.4-0.4-0.3-1.1,0.1-1.5c0.4-0.4,1.1-0.3,1.5,0.1l2.1,2.4c0.1,0.1,0.2,0.1,0.3,0l1.1-0.9c0.4-0.3,1.1-0.3,1.4,0.1l2.1,2.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1L26.3,10.5z M15.5,16.6l1.5,5.2v0c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2h-2c-0.1,0-0.2,0.1-0.2,0.2l-0.8,4.1c0,0.2-0.2,0.3-0.4,0.3h-2.5c-0.2,0-0.4-0.1-0.4-0.3l-0.8-4.1c0-0.1-0.1-0.2-0.2-0.2H7.4c-0.1,0-0.3-0.1-0.3-0.2C7,22.1,7,22,7,21.9l1.5-5.3c0.2-0.7,0.5-1.3,1-1.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.3,0.2l1.5,2.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2l1.5-2.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0.1C15,15.3,15.3,15.9,15.5,16.6z"
        />
      </g>
    </Icon>
  );
};

/**
 * ReputationHarmLossIcon Props
 */
export interface ReputationHarmLossIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
