import React from 'react';
import clsx from 'clsx';
import MuiList, { ListProps as MuiListProps } from '@mui/material/List';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const List = withCoreProps<ListProps>(
  ({ children, className, ...props }: React.PropsWithChildren<ListProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiList {...props} className={clsxClass}>
        {children}
      </MuiList>
    );
  },
);

export interface ListProps extends MuiListProps, CoreProps {}
