import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ProofOfLossIcon
 */
export const ProofOfLossIcon = ({ ...props }: ProofOfLossIconProps) => {
  return (
    <Icon label="proof_of_loss" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4,12.8l0.5-0.5c0.2-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.4,0-0.5,0.2L23.6,12L24.4,12.8z M16.3,19.4l0.8,0.8l6.6-6.8c-0.2-0.2-0.5-0.5-0.8-0.8c-0.4,0.4-1.5,1.5-2,2.1L16.3,19.4z M15,21.6l1.2-0.4l0.3-0.3l-0.8-0.8l-0.3,0.3L15,21.6z M10.3,18.2h4.3c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-4.3c-0.3,0-0.5,0.2-0.5,0.5S10,18.2,10.3,18.2z M10.3,20.5h2.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-2.3c-0.3,0-0.5,0.2-0.5,0.5C9.8,20.3,10,20.5,10.3,20.5z M10.3,22.8h1.6c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-1.6c-0.3,0-0.5,0.2-0.5,0.5C9.8,22.6,10,22.8,10.3,22.8z M14.1,11.5c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2C13.1,13.6,14.1,12.7,14.1,11.5z M16.9,15.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-6.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5H16.9z M19.5,11.6l-2.1-2.1v2.1H19.5z M24.5,10.3c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.2,0.8-0.5,1.2l-2.4,2.5v0l-1.9,1.9v7.1c0,0.4-0.3,0.7-0.7,0.7H8.5c-0.4,0-0.7-0.3-0.7-0.7V8.4c0-0.4,0.3-0.7,0.7-0.7h8.7l0,0l4.2,4.2l0,0.1v0.8l1.4-1.4l0.6-0.7C23.6,10.5,24.1,10.3,24.5,10.3L24.5,10.3z M13,11.5c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1C12.5,10.5,13,10.9,13,11.5z"
        />
      </g>
    </Icon>
  );
};

/**
 * ProofOfLossIcon Props
 */
export interface ProofOfLossIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
