/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskDocket
 */
export interface ExecRiskDocket {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    docket: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    court?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    date_filed?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    date_cached?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    cause?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    nature_of_suit?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    secondary_review?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    decline?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    category_expansion?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    endorsement?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskDocket
     */
    insurance_line?: string;
}

/**
 * Check if a given object implements the ExecRiskDocket interface.
 */
export function instanceOfExecRiskDocket(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "docket" in value;

    return isInstance;
}

export function ExecRiskDocketFromJSON(json: any): ExecRiskDocket {
    return ExecRiskDocketFromJSONTyped(json, false);
}

export function ExecRiskDocketFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskDocket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'docket': json['docket'],
        'court': !exists(json, 'court') ? undefined : json['court'],
        'date_filed': !exists(json, 'date_filed') ? undefined : json['date_filed'],
        'date_cached': !exists(json, 'date_cached') ? undefined : json['date_cached'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'cause': !exists(json, 'cause') ? undefined : json['cause'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'nature_of_suit': !exists(json, 'nature_of_suit') ? undefined : json['nature_of_suit'],
        'secondary_review': !exists(json, 'secondary_review') ? undefined : json['secondary_review'],
        'decline': !exists(json, 'decline') ? undefined : json['decline'],
        'category_expansion': !exists(json, 'category_expansion') ? undefined : json['category_expansion'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'endorsement': !exists(json, 'endorsement') ? undefined : json['endorsement'],
        'insurance_line': !exists(json, 'insurance_line') ? undefined : json['insurance_line'],
    };
}

export function ExecRiskDocketToJSON(value?: ExecRiskDocket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'docket': value.docket,
        'court': value.court,
        'date_filed': value.date_filed,
        'date_cached': value.date_cached,
        'title': value.title,
        'link': value.link,
        'cause': value.cause,
        'type': value.type,
        'nature_of_suit': value.nature_of_suit,
        'secondary_review': value.secondary_review,
        'decline': value.decline,
        'category_expansion': value.category_expansion,
        'action': value.action,
        'endorsement': value.endorsement,
        'insurance_line': value.insurance_line,
    };
}

