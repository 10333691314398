/**
 * Ensures that all the URLs end with a trailing slash (to ensure we don't break Next.js routing).
 *
 * __> Example:__
 * ```ts
 * nextjsRoute('/binaryedge');
 * // '/binaryedge/'
 * ```
 */
export const nextjsRoute: NextjsRoute = (url = '') => {
  if (!url?.length) return url;

  const lastChar = url[url.length - 1];

  if (lastChar !== '/') return `${url}/`;

  return url;
};

type NextjsRoute = (url: string) => string;
