import React from 'react';
import clsx from 'clsx';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Tabs = withCoreProps<TabsProps>(
  ({ children, className, ...props }: React.PropsWithChildren<TabsProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiTabs {...props} className={clsxClass}>
        {children}
      </MuiTabs>
    );
  },
  ['textColor'],
);

export interface TabsProps extends MuiTabsProps, Omit<CoreProps, 'textColor'> {}
