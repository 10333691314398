/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';
import type { CRSFSecReviewScanType } from './CRSFSecReviewScanType';
import {
    CRSFSecReviewScanTypeFromJSON,
    CRSFSecReviewScanTypeFromJSONTyped,
    CRSFSecReviewScanTypeToJSON,
} from './CRSFSecReviewScanType';
import type { CRSFSecReviewStatusType } from './CRSFSecReviewStatusType';
import {
    CRSFSecReviewStatusTypeFromJSON,
    CRSFSecReviewStatusTypeFromJSONTyped,
    CRSFSecReviewStatusTypeToJSON,
} from './CRSFSecReviewStatusType';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRSFSecReviewProcess
 */
export interface CRSFSecReviewProcess {
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewProcess
     */
    created: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewProcess
     */
    domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewProcess
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewProcess
     */
    entity_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewProcess
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewProcess
     */
    issue?: string;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewProcess
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFSecReviewProcess
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewProcess
     */
    no_result_flag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewProcess
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewProcess
     */
    report_url?: string;
    /**
     * 
     * @type {CRSFSecReviewScanType}
     * @memberof CRSFSecReviewProcess
     */
    scan_type: CRSFSecReviewScanType;
    /**
     * 
     * @type {{ [key: string]: CRSFSecReviewStatusType; }}
     * @memberof CRSFSecReviewProcess
     */
    status: { [key: string]: CRSFSecReviewStatusType; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CRSFSecReviewStatusType; }; }}
     * @memberof CRSFSecReviewProcess
     */
    statuses: { [key: string]: { [key: string]: CRSFSecReviewStatusType; }; };
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewProcess
     */
    updated: number;
}

/**
 * Check if a given object implements the CRSFSecReviewProcess interface.
 */
export function instanceOfCRSFSecReviewProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statuses" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFSecReviewProcessFromJSON(json: any): CRSFSecReviewProcess {
    return CRSFSecReviewProcessFromJSONTyped(json, false);
}

export function CRSFSecReviewProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'no_result_flag': !exists(json, 'no_result_flag') ? undefined : json['no_result_flag'],
        'process_id': json['process_id'],
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'scan_type': CRSFSecReviewScanTypeFromJSON(json['scan_type']),
        'status': (mapValues(json['status'], CRSFSecReviewStatusTypeFromJSON)),
        'statuses': json['statuses'],
        'updated': json['updated'],
    };
}

export function CRSFSecReviewProcessToJSON(value?: CRSFSecReviewProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'domains': value.domains,
        'entity_logo_url': value.entity_logo_url,
        'entity_name': value.entity_name,
        'ip_addresses': value.ip_addresses,
        'issue': value.issue,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'no_result_flag': value.no_result_flag,
        'process_id': value.process_id,
        'report_url': value.report_url,
        'scan_type': CRSFSecReviewScanTypeToJSON(value.scan_type),
        'status': (mapValues(value.status, CRSFSecReviewStatusTypeToJSON)),
        'statuses': value.statuses,
        'updated': value.updated,
    };
}

