import { createApiEndpoints, FuncEndpoint, P1, P2 } from '../utils';

const PREFIX_V1MONIT = '/v1/monit/web';

const ENDPOINTS_V1MONIT = [
  `/assets/reported`,
  `/entity/${P1}/assets/${P2}/report`,
  `/entity/${P1}/dataleaks`,
  `/entity/${P1}/domains/${P2}/scans`,
  `/entity/${P1}/domains/${P2}/findings`,
  `/entity/${P1}/domains/${P2}/stats`,
  `/entity/${P1}/domains/${P2}/techs`,
  `/entity/${P1}/domains/${P2}`,
  `/entity/${P1}/domains`,
  `/entity/${P1}/ip_addresses/${P2}/scans`,
  `/entity/${P1}/ip_addresses/${P2}/findings`,
  `/entity/${P1}/ip_addresses/${P2}/stats`,
  `/entity/${P1}/ip_addresses/${P2}/techs`,
  `/entity/${P1}/ip_addresses/${P2}`,
  `/entity/${P1}/ip_addresses`,
  `/entity/${P1}/findings/aggregate`,
  `/entity/${P1}/findings/top`,
  `/entity/${P1}/findings`,
  `/entity/${P1}/graph`,
  `/entity/${P1}/honeypots`,
  `/entity/${P1}/keywords`,
  `/entity/${P1}/lookalikes`,
  `/entity/${P1}/notifications`,
  `/entity/${P1}/pastebins`,
  `/entity/${P1}/remove`,
  `/entity/${P1}/remove/${P2}`,
  `/entity/${P1}/resolutions`,
  `/entity/${P1}/revoke`,
  `/entity/${P1}/stats/by_day`,
  `/entity/${P1}/stats/issues_found`,
  `/entity/${P1}/stats`,
  `/entity/${P1}/techs`,
  `/entity/${P1}/torrents`,
  `/entity/${P1}/trigger`,
  `/entity/${P1}`,
  `/impersonate`,
  `/link`,
  `/me`,
  `/notifications`,
  `/related/findings`,
  `/related/historical`,
  `/related/stats`,
  `/related`,
  `/resolutions/${P1}`,
  `/search`,
  `/techs`,
  `/token/create`,
  `/token/list`,
  `/token/revoke`,
] as const;

type Endpoints = typeof ENDPOINTS_V1MONIT[number];

export type EndpointsV1MONIT = Record<Endpoints, FuncEndpoint>;

export const endpointsV1MONIT = createApiEndpoints<Endpoints>(
  PREFIX_V1MONIT,
  ENDPOINTS_V1MONIT,
) as EndpointsV1MONIT;
