/**
 * Conditionally adds entries inside Array and object literals.
 *
 * __> Example:__
 * ```ts
 * [
 *  'foo',
 *  ...insertIf<string>(
 *     a === b,
 *     'bar'
 *  ),
 * ];
 * ```
 */
export function insertIf<T>(condition: boolean, ...elements: T[]): T[] {
  return condition ? elements : [];
}
