import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_V1SECREVIEW = '/v1/secreview/web';

const ENDPOINTS_V1SECREVIEW = [
  `/entity/${P1}/dataleaks`,
  `/entity/${P1}/domains`,
  `/entity/${P1}/findings`,
  `/entity/${P1}/honeypots`,
  `/entity/${P1}/ip_addresses`,
  `/entity/${P1}/list`,
  `/entity/${P1}/scans`,
  `/entity/${P1}/extscans`,
  `/entity/${P1}/stats`,
  `/entity/${P1}/status`,
  `/entity/${P1}/techs`,
  `/entity/${P1}/torrents`,
  `/entity/${P1}`,
  `/issues`,
  `/list`,
  `/trigger`,
] as const;

type Endpoints = (typeof ENDPOINTS_V1SECREVIEW)[number];

export type EndpointsV1SECREVIEW = Record<Endpoints, FuncEndpoint>;

export const endpointsV1SECREVIEW = createApiEndpoints<Endpoints>(
  PREFIX_V1SECREVIEW,
  ENDPOINTS_V1SECREVIEW,
) as EndpointsV1SECREVIEW;
