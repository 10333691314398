/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlRescanStatus = {
    Ongoing: 'ongoing',
    Finished: 'finished'
} as const;
export type ControlRescanStatus = typeof ControlRescanStatus[keyof typeof ControlRescanStatus];


export function ControlRescanStatusFromJSON(json: any): ControlRescanStatus {
    return ControlRescanStatusFromJSONTyped(json, false);
}

export function ControlRescanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRescanStatus {
    return json as ControlRescanStatus;
}

export function ControlRescanStatusToJSON(value?: ControlRescanStatus | null): any {
    return value as any;
}

