import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_NOTIFICATIONS = '/notifications';

const ENDPOINTS_NOTIFICATIONS = [`/alerts/${P1}`] as const;

type Endpoints = typeof ENDPOINTS_NOTIFICATIONS[number];

export type EndpointsNOTIFICATIONS = Record<Endpoints, FuncEndpoint>;

export const endpointsNOTIFICATIONS = createApiEndpoints<Endpoints>(
  PREFIX_NOTIFICATIONS,
  ENDPOINTS_NOTIFICATIONS,
) as EndpointsNOTIFICATIONS;
