import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_V1EXECRISKS = '/v1/execrisk/web';

const ENDPOINTS_V1EXECRISKS = [
  `/scan/${P1}/alexa`,
  `/scan/${P1}/bingnews`,
  `/scan/${P1}/companyinfo`,
  `/scan/${P1}/docketalarm`, // fastcase
  `/scan/${P1}/experian/liens`,
  `/scan/${P1}/experian`,
  `/scan/${P1}/form990`,
  `/scan/${P1}/glassdoor/ratings`,
  `/scan/${P1}/glassdoor/reviews`,
  `/scan/${P1}/glassdoor`,
  `/scan/${P1}/industry`,
  `/scan/${P1}/investors`, // zoominfo
  `/scan/${P1}/news/articles`,
  `/scan/${P1}/news`,
  `/scan/${P1}/ppploans`,
  `/scan/${P1}/proximityone`,
  `/scan/${P1}/status`,
  `/scan/${P1}/violations`, // violation_tracker
  `/scan/${P1}/watchlist`,
  `/scan/${P1}/wave`,
  `/scan/${P1}/webhose/articles`,
  `/scan/${P1}/webhose`,
  `/scan/${P1}/zoominfo/fundings`,
  `/scan/${P1}/zoominfo/orgchart`,
  `/scan/${P1}/zoominfo`,
  `/scans`,
  `/trigger`,
] as const;

type Endpoints = typeof ENDPOINTS_V1EXECRISKS[number];

export type EndpointsV1EXECRISKS = Record<Endpoints, FuncEndpoint>;

export const endpointsV1EXECRISKS = createApiEndpoints<Endpoints>(
  PREFIX_V1EXECRISKS,
  ENDPOINTS_V1EXECRISKS,
) as EndpointsV1EXECRISKS;
