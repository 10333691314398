/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityRiskScoreV2
 */
export interface ControlEntityRiskScoreV2 {
    /**
     * 
     * @type {object}
     * @memberof ControlEntityRiskScoreV2
     */
    critical?: object;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityRiskScoreV2
     */
    high?: object;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityRiskScoreV2
     */
    medium?: object;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityRiskScoreV2
     */
    low?: object;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityRiskScoreV2
     */
    total_percentages?: object;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityRiskScoreV2
     */
    risk_score_v1?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityRiskScoreV2
     */
    risk_score_v2?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityRiskScoreV2
     */
    version?: string;
}

/**
 * Check if a given object implements the ControlEntityRiskScoreV2 interface.
 */
export function instanceOfControlEntityRiskScoreV2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityRiskScoreV2FromJSON(json: any): ControlEntityRiskScoreV2 {
    return ControlEntityRiskScoreV2FromJSONTyped(json, false);
}

export function ControlEntityRiskScoreV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityRiskScoreV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'critical': !exists(json, 'critical') ? undefined : json['critical'],
        'high': !exists(json, 'high') ? undefined : json['high'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'low': !exists(json, 'low') ? undefined : json['low'],
        'total_percentages': !exists(json, 'total_percentages') ? undefined : json['total_percentages'],
        'risk_score_v1': !exists(json, 'risk_score_v1') ? undefined : json['risk_score_v1'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : json['risk_score_v2'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ControlEntityRiskScoreV2ToJSON(value?: ControlEntityRiskScoreV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'critical': value.critical,
        'high': value.high,
        'medium': value.medium,
        'low': value.low,
        'total_percentages': value.total_percentages,
        'risk_score_v1': value.risk_score_v1,
        'risk_score_v2': value.risk_score_v2,
        'version': value.version,
    };
}

