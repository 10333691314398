import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const MenuItem = withCoreProps<MenuItemProps>(
  ({ children, className, ...props }: PropsWithChildren<MenuItemProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiMenuItem {...props} className={clsxClass}>
        {children}
      </MuiMenuItem>
    );
  },
);

export interface MenuItemProps
  extends Omit<MuiMenuItemProps, 'button'>,
    CoreProps {}
