/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewBreachSourceItem } from './CRSFSecReviewBreachSourceItem';
import {
    CRSFSecReviewBreachSourceItemFromJSON,
    CRSFSecReviewBreachSourceItemFromJSONTyped,
    CRSFSecReviewBreachSourceItemToJSON,
} from './CRSFSecReviewBreachSourceItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewEmailLeakAggregatedEvent
 */
export interface CRSFSecReviewEmailLeakAggregatedEvent {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewEmailLeakAggregatedEvent
     */
    email: string;
    /**
     * 
     * @type {CRSFSecReviewBreachSourceItem}
     * @memberof CRSFSecReviewEmailLeakAggregatedEvent
     */
    source: CRSFSecReviewBreachSourceItem;
}

/**
 * Check if a given object implements the CRSFSecReviewEmailLeakAggregatedEvent interface.
 */
export function instanceOfCRSFSecReviewEmailLeakAggregatedEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function CRSFSecReviewEmailLeakAggregatedEventFromJSON(json: any): CRSFSecReviewEmailLeakAggregatedEvent {
    return CRSFSecReviewEmailLeakAggregatedEventFromJSONTyped(json, false);
}

export function CRSFSecReviewEmailLeakAggregatedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewEmailLeakAggregatedEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'source': CRSFSecReviewBreachSourceItemFromJSON(json['source']),
    };
}

export function CRSFSecReviewEmailLeakAggregatedEventToJSON(value?: CRSFSecReviewEmailLeakAggregatedEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'source': CRSFSecReviewBreachSourceItemToJSON(value.source),
    };
}

