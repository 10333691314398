/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFVirustotalDataEmpty
 */
export interface CRSFVirustotalDataEmpty {
    /**
     * 
     * @type {string}
     * @memberof CRSFVirustotalDataEmpty
     */
    asset: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CRSFVirustotalDataEmpty
     */
    detected_urls?: Array<any>;
}

/**
 * Check if a given object implements the CRSFVirustotalDataEmpty interface.
 */
export function instanceOfCRSFVirustotalDataEmpty(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;

    return isInstance;
}

export function CRSFVirustotalDataEmptyFromJSON(json: any): CRSFVirustotalDataEmpty {
    return CRSFVirustotalDataEmptyFromJSONTyped(json, false);
}

export function CRSFVirustotalDataEmptyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFVirustotalDataEmpty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'detected_urls': !exists(json, 'detected_urls') ? undefined : json['detected_urls'],
    };
}

export function CRSFVirustotalDataEmptyToJSON(value?: CRSFVirustotalDataEmpty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'detected_urls': value.detected_urls,
    };
}

