/**
 * Check if an "url" is from an external source.
 *
 * __> Example:__
 * ```ts
 * isExternalUrl('https://www.binaryedge.io/');
 * // true
 *
 * isExternalUrl('/company');
 * // false
 * ```
 */

export const isExternalUrl: IsExternalUrl = (url) => {
  return url ? url[0] !== '/' : false;
};

type IsExternalUrl = (url?: string) => boolean;
