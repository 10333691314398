/**
 * Truncate a text longer than `maxLength` characters and append an ellipsis.
 *
 * __> Example:__
 * ```ts
 * truncateText('Lorem ipsum dolor sit amet', 14);
 * // "Lorem ipsum..."
 * ```
 */

export const truncateText: TruncateText = (text, maxLength) => {
  if (!text) return text;
  if (maxLength <= 0) return text;

  if (text && text?.trim().length < maxLength) return text.trim();

  const ellipsis = '...';
  const truncatedText = text.trim().substring(0, maxLength - ellipsis.length);

  return (truncatedText + ellipsis).trim();
};

type TruncateText = (text: string, maxLength: number) => string;
