import React from 'react';
import clsx from 'clsx';
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Accordion = withCoreProps<AccordionProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<AccordionProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAccordion {...props} className={clsxClass}>
        {children}
      </MuiAccordion>
    );
  },
);

export interface AccordionProps extends MuiAccordionProps, CoreProps {}
