import React, { ImgHTMLAttributes } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

const useStyles = ({ responsive }: Partial<ImageProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        image: {
          height: responsive ? 'auto !important' : undefined,
          maxWidth: responsive ? '100% !important' : undefined,
        },
      }),
    { index: 1 },
  )();

export const Image = withCoreProps<ImageProps>(
  ({
    src,
    title,
    responsive = false,
    className,
    ...props
  }: React.PropsWithChildren<ImageProps>) => {
    const classes = useStyles({ responsive });
    const clsxClass = clsx(classes.image, className);

    return (
      <img
        {...props}
        src={src}
        title={title}
        alt={title}
        className={clsxClass}
      />
    );
  },
);

export interface ImageProps extends ImgHTMLAttributes<any>, CoreProps {
  /**
   * `src` - source of the static image
   */
  src: string;
  /**
   * `title` - title of the image (also used in the `alt` prop)
   */
  title: string;
  /**
   * `responsive` - *OPTIONAL* - sets `height: "auto !important"` and `maxWidth: "100% !important"`
   */
  responsive?: boolean;
}
