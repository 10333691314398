/* eslint-disable react/display-name */
import { Grid, Typography, TypographyProps } from '@explorer/core';
import { IconLink } from '@explorer/core/presets';
import { trailingUrl } from '@explorer/helpers';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ReactNode, useMemo } from 'react';
import { GotoProcessIDProps } from '../../../types';
import { CellEmpty } from '../CellEmpty';

/**
 * CellProcessID
 */
export const CellProcessID = ({
  processId = '',
  entityDomain = '',
  variant = 'bodyXS',
  disabled = false,
  gotoProcessIDProps,
}: CellProcessIDProps) => {
  const cellLink = useMemo<string | undefined>(() => {
    if (!gotoProcessIDProps) {
      return;
    }

    return trailingUrl(gotoProcessIDProps().href, {
      domain: entityDomain,
      processId,
    });
  }, [entityDomain, gotoProcessIDProps, processId]);

  const processIDComponent = useMemo<ReactNode>(() => {
    if (cellLink) {
      return (
        <IconLink
          href={cellLink}
          color={'primary'}
          size="small"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FindInPageIcon />
        </IconLink>
      );
    }

    if (!processId) return <CellEmpty variant={variant} />;

    return <Typography variant={variant}>{processId}</Typography>;
  }, [cellLink, disabled, processId, variant]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>{processIDComponent}</Grid>
    </Grid>
  );
};

/**
 * CellProcessID Props
 */
export interface CellProcessIDProps extends Pick<TypographyProps, 'variant'> {
  processId?: string;
  entityDomain?: string;
  gotoProcessIDProps?: GotoProcessIDProps;
  disabled?: boolean;
}
