import { useUserProvider, UserContext } from '@explorer/contexts';
import { useUser } from '../../stores';
import React, { PropsWithChildren, useEffect } from 'react';

type JustChildren = PropsWithChildren<unknown>;

/**
 * This wraps the existing UserProvider functionality that Explorer pre-SSO and Control v1 used. It does not provide much value at this point, but will allow legacy to components to continue to read from the User Context.
 *
 */
export const ExplorerUserProvider: React.FC<JustChildren> = ({ children }) => {
  const context = useUserProvider();

  const { user: explorerUser } = useUser();
  const { setUser, resetUser } = context;

  useEffect(() => {
    if (explorerUser) {
      const { email, name } = explorerUser;

      setUser({ email, name });
    } else {
      resetUser();
    }
  }, [explorerUser]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
