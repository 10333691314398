/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll
     */
    TimelyProvidedDocumentsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll
     */
    OfficersCloseRelationshipInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll
     */
    SupportedOrgVoiceInvestmentInd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'TimelyProvidedDocumentsInd': !exists(json, 'TimelyProvidedDocumentsInd') ? undefined : json['TimelyProvidedDocumentsInd'],
        'OfficersCloseRelationshipInd': !exists(json, 'OfficersCloseRelationshipInd') ? undefined : json['OfficersCloseRelationshipInd'],
        'SupportedOrgVoiceInvestmentInd': !exists(json, 'SupportedOrgVoiceInvestmentInd') ? undefined : json['SupportedOrgVoiceInvestmentInd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TimelyProvidedDocumentsInd': value.TimelyProvidedDocumentsInd,
        'OfficersCloseRelationshipInd': value.OfficersCloseRelationshipInd,
        'SupportedOrgVoiceInvestmentInd': value.SupportedOrgVoiceInvestmentInd,
    };
}

