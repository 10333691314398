import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * PhishingIcon
 */
export const PhishingIcon = ({ ...props }: PhishingIconProps) => {
  return (
    <Icon label="phishing" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2,19.1l7.6-6.5c0.1-0.1,0-0.3-0.1-0.3h-3.6v-0.6c0-1.7-1.4-3-3-3c-0.3,0-0.6,0.3-0.6,0.6v1.3c0,0.3,0.3,0.6,0.6,0.6h0.1c0.3,0,0.6-0.3,0.6-0.6V9.9c0.7,0.2,1.2,1,1.2,1.7v0.6h1.2v0.6c0,1.5-1.1,2.8-2.6,3c-0.2,0-0.4-0.1-0.4-0.4V15c0-0.2,0.1-0.3,0.3-0.4c0.9-0.1,1.5-0.9,1.5-1.8v-0.6h-3.2c-0.2,0-0.4-0.2-0.4-0.4V7.5c0-0.1,0.1-0.3,0.2-0.3c0.6-0.3,1-0.9,1-1.6c0-0.7-0.4-1.2-0.9-1.5c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4-0.2l0,0h0c-0.1,0-0.2-0.1-0.4-0.1h0c0,0,0,0,0,0l0,0l0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0l0,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1l0,0l0,0v0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0v0l0,0v0c0,0,0,0,0,0v0C13,5.2,13,5.4,13,5.6c0,0.2,0,0.4,0.1,0.6v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0v0l0,0v0c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0v4.6c0,0.2-0.2,0.4-0.4,0.4l-5.7,0c-0.1,0-0.2,0.2-0.1,0.3l7.7,6.5C15.9,19.2,16.1,19.2,16.2,19.1z M7.3,13.4l5.4,4.6c0.1,0.1,0.1,0.3,0,0.4l-5.4,4.6c-0.1,0.1-0.3,0-0.3-0.2v-9.4C7,13.4,7.2,13.3,7.3,13.4z M24.6,13.5L19.3,18c-0.1,0.1-0.1,0.3,0,0.4l5.3,4.5c0.2,0.1,0.4,0,0.4-0.2v-9.1C25,13.5,24.7,13.3,24.6,13.5z M16.2,20.6l1.5-1.3c0.2-0.2,0.6-0.2,0.8,0l5.1,4.4c0.2,0.2,0.1,0.5-0.2,0.5H8.6c-0.3,0-0.4-0.3-0.2-0.5l5.1-4.4c0.2-0.2,0.6-0.2,0.8,0l1.5,1.3C15.9,20.7,16.1,20.7,16.2,20.6z"
        />
      </g>
    </Icon>
  );
};

/**
 * PhishingIcon Props
 */
export interface PhishingIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
