/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskGlassdoorRating } from './ExecRiskGlassdoorRating';
import {
    ExecRiskGlassdoorRatingFromJSON,
    ExecRiskGlassdoorRatingFromJSONTyped,
    ExecRiskGlassdoorRatingToJSON,
} from './ExecRiskGlassdoorRating';
import type { ExecRiskGlassdoorReview } from './ExecRiskGlassdoorReview';
import {
    ExecRiskGlassdoorReviewFromJSON,
    ExecRiskGlassdoorReviewFromJSONTyped,
    ExecRiskGlassdoorReviewToJSON,
} from './ExecRiskGlassdoorReview';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskGlassdoorOverview
 */
export interface ExecRiskGlassdoorOverview {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    ceo_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    founded?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    headquarters?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    revenue?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    size?: string;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskGlassdoorOverview
     */
    triggers?: Triggers;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    review_count?: string;
    /**
     * 
     * @type {Array<ExecRiskGlassdoorReview>}
     * @memberof ExecRiskGlassdoorOverview
     */
    reviews: Array<ExecRiskGlassdoorReview>;
    /**
     * 
     * @type {Array<ExecRiskGlassdoorRating>}
     * @memberof ExecRiskGlassdoorOverview
     */
    ratings: Array<ExecRiskGlassdoorRating>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskGlassdoorOverview
     */
    updated: string;
}

/**
 * Check if a given object implements the ExecRiskGlassdoorOverview interface.
 */
export function instanceOfExecRiskGlassdoorOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reviews" in value;
    isInstance = isInstance && "ratings" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ExecRiskGlassdoorOverviewFromJSON(json: any): ExecRiskGlassdoorOverview {
    return ExecRiskGlassdoorOverviewFromJSONTyped(json, false);
}

export function ExecRiskGlassdoorOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskGlassdoorOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ceo_name': !exists(json, 'ceo_name') ? undefined : json['ceo_name'],
        'founded': !exists(json, 'founded') ? undefined : json['founded'],
        'headquarters': !exists(json, 'headquarters') ? undefined : json['headquarters'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'triggers': !exists(json, 'triggers') ? undefined : TriggersFromJSON(json['triggers']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'review_count': !exists(json, 'review_count') ? undefined : json['review_count'],
        'reviews': ((json['reviews'] as Array<any>).map(ExecRiskGlassdoorReviewFromJSON)),
        'ratings': ((json['ratings'] as Array<any>).map(ExecRiskGlassdoorRatingFromJSON)),
        'updated': json['updated'],
    };
}

export function ExecRiskGlassdoorOverviewToJSON(value?: ExecRiskGlassdoorOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ceo_name': value.ceo_name,
        'founded': value.founded,
        'headquarters': value.headquarters,
        'industry': value.industry,
        'name': value.name,
        'revenue': value.revenue,
        'size': value.size,
        'triggers': TriggersToJSON(value.triggers),
        'type': value.type,
        'website': value.website,
        'review_count': value.review_count,
        'reviews': ((value.reviews as Array<any>).map(ExecRiskGlassdoorReviewToJSON)),
        'ratings': ((value.ratings as Array<any>).map(ExecRiskGlassdoorRatingToJSON)),
        'updated': value.updated,
    };
}

