/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationsMessageType } from './NotificationsMessageType';
import {
    NotificationsMessageTypeFromJSON,
    NotificationsMessageTypeFromJSONTyped,
    NotificationsMessageTypeToJSON,
} from './NotificationsMessageType';
import type { NotificationsUpstreamMessageStatus } from './NotificationsUpstreamMessageStatus';
import {
    NotificationsUpstreamMessageStatusFromJSON,
    NotificationsUpstreamMessageStatusFromJSONTyped,
    NotificationsUpstreamMessageStatusToJSON,
} from './NotificationsUpstreamMessageStatus';

/**
 * 
 * @export
 * @interface NotificationsRecordResponse
 */
export interface NotificationsRecordResponse {
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    recipient: string;
    /**
     * 
     * @type {NotificationsMessageType}
     * @memberof NotificationsRecordResponse
     */
    record_type: NotificationsMessageType;
    /**
     * 
     * @type {NotificationsUpstreamMessageStatus}
     * @memberof NotificationsRecordResponse
     */
    status: NotificationsUpstreamMessageStatus;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    contact_type: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    sent_at: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRecordResponse
     */
    notification_type: string;
}

/**
 * Check if a given object implements the NotificationsRecordResponse interface.
 */
export function instanceOfNotificationsRecordResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sender" in value;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "record_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "contact_type" in value;
    isInstance = isInstance && "sent_at" in value;
    isInstance = isInstance && "notification_type" in value;

    return isInstance;
}

export function NotificationsRecordResponseFromJSON(json: any): NotificationsRecordResponse {
    return NotificationsRecordResponseFromJSONTyped(json, false);
}

export function NotificationsRecordResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsRecordResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sender': json['sender'],
        'recipient': json['recipient'],
        'record_type': NotificationsMessageTypeFromJSON(json['record_type']),
        'status': NotificationsUpstreamMessageStatusFromJSON(json['status']),
        'contact_type': json['contact_type'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'sent_at': json['sent_at'],
        'notification_type': json['notification_type'],
    };
}

export function NotificationsRecordResponseToJSON(value?: NotificationsRecordResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sender': value.sender,
        'recipient': value.recipient,
        'record_type': NotificationsMessageTypeToJSON(value.record_type),
        'status': NotificationsUpstreamMessageStatusToJSON(value.status),
        'contact_type': value.contact_type,
        'content': value.content,
        'client_id': value.client_id,
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'sent_at': value.sent_at,
        'notification_type': value.notification_type,
    };
}

