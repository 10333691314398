/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitEntityDataLeaksOrderBy = {
    Severity: 'severity',
    Date: 'date',
    Year: 'year',
    Fullname: 'fullname',
    TargetHostname: 'target_hostname',
    User: 'user',
    SourceAsset: 'source_asset',
    Created: 'created',
    Updated: 'updated'
} as const;
export type MonitEntityDataLeaksOrderBy = typeof MonitEntityDataLeaksOrderBy[keyof typeof MonitEntityDataLeaksOrderBy];


export function MonitEntityDataLeaksOrderByFromJSON(json: any): MonitEntityDataLeaksOrderBy {
    return MonitEntityDataLeaksOrderByFromJSONTyped(json, false);
}

export function MonitEntityDataLeaksOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDataLeaksOrderBy {
    return json as MonitEntityDataLeaksOrderBy;
}

export function MonitEntityDataLeaksOrderByToJSON(value?: MonitEntityDataLeaksOrderBy | null): any {
    return value as any;
}

