/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityDomainsDNSResultAsset
 */
export interface MonitEntityDomainsDNSResultAsset {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainsDNSResultAsset
     */
    asset?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityDomainsDNSResultAsset
     */
    answers?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDomainsDNSResultAsset
     */
    total_answers?: number;
}

/**
 * Check if a given object implements the MonitEntityDomainsDNSResultAsset interface.
 */
export function instanceOfMonitEntityDomainsDNSResultAsset(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDomainsDNSResultAssetFromJSON(json: any): MonitEntityDomainsDNSResultAsset {
    return MonitEntityDomainsDNSResultAssetFromJSONTyped(json, false);
}

export function MonitEntityDomainsDNSResultAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDomainsDNSResultAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
        'total_answers': !exists(json, 'total_answers') ? undefined : json['total_answers'],
    };
}

export function MonitEntityDomainsDNSResultAssetToJSON(value?: MonitEntityDomainsDNSResultAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'answers': value.answers,
        'total_answers': value.total_answers,
    };
}

