import React from 'react';
import { Button, ButtonProps } from '@explorer/core';
import { FormikProps } from 'formik';

/**
 * ResetButton
 */
export const ResetButton = ({
  formId,
  formik,
  type = 'button',
  onClick,
  ...props
}: ResetButtonProps) => {
  return (
    <Button
      {...props}
      id={`${formId}-Reset`}
      type={type}
      onClick={(e) => {
        if (onClick) onClick(e);
        if (!onClick) formik.resetForm();
      }}
    />
  );
};

/**
 * ResetButton Props
 */
export interface ResetButtonProps extends ButtonProps {
  /**
   * `formId` - the form id, used to generate ids for every input field
   */
  formId: string;
  /**
   * `formik` - the object returned by the `useFormik()` hook
   */
  formik: FormikProps<any>;
}
