/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityRelationStatsCriticalityCount } from './MonitEntityRelationStatsCriticalityCount';
import {
    MonitEntityRelationStatsCriticalityCountFromJSON,
    MonitEntityRelationStatsCriticalityCountFromJSONTyped,
    MonitEntityRelationStatsCriticalityCountToJSON,
} from './MonitEntityRelationStatsCriticalityCount';

/**
 * 
 * @export
 * @interface MonitEntityRelationStatsGroupResult
 */
export interface MonitEntityRelationStatsGroupResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    risk_score?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    risk_score_v2?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    assets_count?: number;
    /**
     * 
     * @type {MonitEntityRelationStatsCriticalityCount}
     * @memberof MonitEntityRelationStatsGroupResult
     */
    criticality_sum?: MonitEntityRelationStatsCriticalityCount;
}

/**
 * Check if a given object implements the MonitEntityRelationStatsGroupResult interface.
 */
export function instanceOfMonitEntityRelationStatsGroupResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityRelationStatsGroupResultFromJSON(json: any): MonitEntityRelationStatsGroupResult {
    return MonitEntityRelationStatsGroupResultFromJSONTyped(json, false);
}

export function MonitEntityRelationStatsGroupResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRelationStatsGroupResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : json['risk_score_v2'],
        'assets_count': !exists(json, 'assets_count') ? undefined : json['assets_count'],
        'criticality_sum': !exists(json, 'criticality_sum') ? undefined : MonitEntityRelationStatsCriticalityCountFromJSON(json['criticality_sum']),
    };
}

export function MonitEntityRelationStatsGroupResultToJSON(value?: MonitEntityRelationStatsGroupResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_logo_url': value.entity_logo_url,
        'risk_score': value.risk_score,
        'risk_score_v2': value.risk_score_v2,
        'assets_count': value.assets_count,
        'criticality_sum': MonitEntityRelationStatsCriticalityCountToJSON(value.criticality_sum),
    };
}

