import React from 'react';
import clsx from 'clsx';
import MuiFormLabel, {
  FormLabelProps as MuiFormLabelProps,
} from '@mui/material/FormLabel';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const FormLabel = withCoreProps<FormLabelProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<FormLabelProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiFormLabel {...props} className={clsxClass}>
        {children}
      </MuiFormLabel>
    );
  },
);

export interface FormLabelProps extends MuiFormLabelProps, CoreProps {}
