/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskExperianTradeDBT
 */
export interface ExecRiskExperianTradeDBT {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianTradeDBT
     */
    q_one: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianTradeDBT
     */
    q_two: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianTradeDBT
     */
    q_three: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianTradeDBT
     */
    q_four: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianTradeDBT
     */
    q_five: number;
}

/**
 * Check if a given object implements the ExecRiskExperianTradeDBT interface.
 */
export function instanceOfExecRiskExperianTradeDBT(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "q_one" in value;
    isInstance = isInstance && "q_two" in value;
    isInstance = isInstance && "q_three" in value;
    isInstance = isInstance && "q_four" in value;
    isInstance = isInstance && "q_five" in value;

    return isInstance;
}

export function ExecRiskExperianTradeDBTFromJSON(json: any): ExecRiskExperianTradeDBT {
    return ExecRiskExperianTradeDBTFromJSONTyped(json, false);
}

export function ExecRiskExperianTradeDBTFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskExperianTradeDBT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'q_one': json['q_one'],
        'q_two': json['q_two'],
        'q_three': json['q_three'],
        'q_four': json['q_four'],
        'q_five': json['q_five'],
    };
}

export function ExecRiskExperianTradeDBTToJSON(value?: ExecRiskExperianTradeDBT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'q_one': value.q_one,
        'q_two': value.q_two,
        'q_three': value.q_three,
        'q_four': value.q_four,
        'q_five': value.q_five,
    };
}

