/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Text } from './Text';
import {
    TextFromJSON,
    TextFromJSONTyped,
    TextToJSON,
} from './Text';

/**
 * 
 * @export
 * @interface ControlMultiSelectFieldOption
 */
export interface ControlMultiSelectFieldOption {
    /**
     * 
     * @type {string}
     * @memberof ControlMultiSelectFieldOption
     */
    id: string;
    /**
     * 
     * @type {Text}
     * @memberof ControlMultiSelectFieldOption
     */
    text: Text;
    /**
     * 
     * @type {boolean}
     * @memberof ControlMultiSelectFieldOption
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlMultiSelectFieldOption
     */
    exclusion_group?: string;
}

/**
 * Check if a given object implements the ControlMultiSelectFieldOption interface.
 */
export function instanceOfControlMultiSelectFieldOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function ControlMultiSelectFieldOptionFromJSON(json: any): ControlMultiSelectFieldOption {
    return ControlMultiSelectFieldOptionFromJSONTyped(json, false);
}

export function ControlMultiSelectFieldOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMultiSelectFieldOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': TextFromJSON(json['text']),
        'required': json['required'],
        'exclusion_group': !exists(json, 'exclusion_group') ? undefined : json['exclusion_group'],
    };
}

export function ControlMultiSelectFieldOptionToJSON(value?: ControlMultiSelectFieldOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': TextToJSON(value.text),
        'required': value.required,
        'exclusion_group': value.exclusion_group,
    };
}

