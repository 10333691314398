/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ListedByNameGoverningDocInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SuprtOrgNoIRSDeterminationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportedOrgSectionC456Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportedOrgQualifiedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SuprtExclusivelySec170c2BInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportedOrgNotOrganizedUSInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ControlDecidingGrntFrgnOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportForeignOrgNoDetermInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    OrganizationChangeSuprtOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportedOrgClassDesignatedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SubstitutionBeyondOrgCntlInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    SupportNonSupportedOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    PaymentSubstantialContribtrInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    LoanDisqualifiedPersonInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ControlledDisqualifiedPrsnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    DisqualifiedPrsnControllIntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    DisqualifiedPrsnOwnrIntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ExcessBusinessHoldingsRulesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ExcessBusinessHoldingsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ContributionControllerInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    ContributionFamilyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg
     */
    Contribution35ControlledInd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ListedByNameGoverningDocInd': !exists(json, 'ListedByNameGoverningDocInd') ? undefined : json['ListedByNameGoverningDocInd'],
        'SuprtOrgNoIRSDeterminationInd': !exists(json, 'SuprtOrgNoIRSDeterminationInd') ? undefined : json['SuprtOrgNoIRSDeterminationInd'],
        'SupportedOrgSectionC456Ind': !exists(json, 'SupportedOrgSectionC456Ind') ? undefined : json['SupportedOrgSectionC456Ind'],
        'SupportedOrgQualifiedInd': !exists(json, 'SupportedOrgQualifiedInd') ? undefined : json['SupportedOrgQualifiedInd'],
        'SuprtExclusivelySec170c2BInd': !exists(json, 'SuprtExclusivelySec170c2BInd') ? undefined : json['SuprtExclusivelySec170c2BInd'],
        'SupportedOrgNotOrganizedUSInd': !exists(json, 'SupportedOrgNotOrganizedUSInd') ? undefined : json['SupportedOrgNotOrganizedUSInd'],
        'ControlDecidingGrntFrgnOrgInd': !exists(json, 'ControlDecidingGrntFrgnOrgInd') ? undefined : json['ControlDecidingGrntFrgnOrgInd'],
        'SupportForeignOrgNoDetermInd': !exists(json, 'SupportForeignOrgNoDetermInd') ? undefined : json['SupportForeignOrgNoDetermInd'],
        'OrganizationChangeSuprtOrgInd': !exists(json, 'OrganizationChangeSuprtOrgInd') ? undefined : json['OrganizationChangeSuprtOrgInd'],
        'SupportedOrgClassDesignatedInd': !exists(json, 'SupportedOrgClassDesignatedInd') ? undefined : json['SupportedOrgClassDesignatedInd'],
        'SubstitutionBeyondOrgCntlInd': !exists(json, 'SubstitutionBeyondOrgCntlInd') ? undefined : json['SubstitutionBeyondOrgCntlInd'],
        'SupportNonSupportedOrgInd': !exists(json, 'SupportNonSupportedOrgInd') ? undefined : json['SupportNonSupportedOrgInd'],
        'PaymentSubstantialContribtrInd': !exists(json, 'PaymentSubstantialContribtrInd') ? undefined : json['PaymentSubstantialContribtrInd'],
        'LoanDisqualifiedPersonInd': !exists(json, 'LoanDisqualifiedPersonInd') ? undefined : json['LoanDisqualifiedPersonInd'],
        'ControlledDisqualifiedPrsnInd': !exists(json, 'ControlledDisqualifiedPrsnInd') ? undefined : json['ControlledDisqualifiedPrsnInd'],
        'DisqualifiedPrsnControllIntInd': !exists(json, 'DisqualifiedPrsnControllIntInd') ? undefined : json['DisqualifiedPrsnControllIntInd'],
        'DisqualifiedPrsnOwnrIntInd': !exists(json, 'DisqualifiedPrsnOwnrIntInd') ? undefined : json['DisqualifiedPrsnOwnrIntInd'],
        'ExcessBusinessHoldingsRulesInd': !exists(json, 'ExcessBusinessHoldingsRulesInd') ? undefined : json['ExcessBusinessHoldingsRulesInd'],
        'ExcessBusinessHoldingsInd': !exists(json, 'ExcessBusinessHoldingsInd') ? undefined : json['ExcessBusinessHoldingsInd'],
        'ContributionControllerInd': !exists(json, 'ContributionControllerInd') ? undefined : json['ContributionControllerInd'],
        'ContributionFamilyInd': !exists(json, 'ContributionFamilyInd') ? undefined : json['ContributionFamilyInd'],
        'Contribution35ControlledInd': !exists(json, 'Contribution35ControlledInd') ? undefined : json['Contribution35ControlledInd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ListedByNameGoverningDocInd': value.ListedByNameGoverningDocInd,
        'SuprtOrgNoIRSDeterminationInd': value.SuprtOrgNoIRSDeterminationInd,
        'SupportedOrgSectionC456Ind': value.SupportedOrgSectionC456Ind,
        'SupportedOrgQualifiedInd': value.SupportedOrgQualifiedInd,
        'SuprtExclusivelySec170c2BInd': value.SuprtExclusivelySec170c2BInd,
        'SupportedOrgNotOrganizedUSInd': value.SupportedOrgNotOrganizedUSInd,
        'ControlDecidingGrntFrgnOrgInd': value.ControlDecidingGrntFrgnOrgInd,
        'SupportForeignOrgNoDetermInd': value.SupportForeignOrgNoDetermInd,
        'OrganizationChangeSuprtOrgInd': value.OrganizationChangeSuprtOrgInd,
        'SupportedOrgClassDesignatedInd': value.SupportedOrgClassDesignatedInd,
        'SubstitutionBeyondOrgCntlInd': value.SubstitutionBeyondOrgCntlInd,
        'SupportNonSupportedOrgInd': value.SupportNonSupportedOrgInd,
        'PaymentSubstantialContribtrInd': value.PaymentSubstantialContribtrInd,
        'LoanDisqualifiedPersonInd': value.LoanDisqualifiedPersonInd,
        'ControlledDisqualifiedPrsnInd': value.ControlledDisqualifiedPrsnInd,
        'DisqualifiedPrsnControllIntInd': value.DisqualifiedPrsnControllIntInd,
        'DisqualifiedPrsnOwnrIntInd': value.DisqualifiedPrsnOwnrIntInd,
        'ExcessBusinessHoldingsRulesInd': value.ExcessBusinessHoldingsRulesInd,
        'ExcessBusinessHoldingsInd': value.ExcessBusinessHoldingsInd,
        'ContributionControllerInd': value.ContributionControllerInd,
        'ContributionFamilyInd': value.ContributionFamilyInd,
        'Contribution35ControlledInd': value.Contribution35ControlledInd,
    };
}

