/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityAssetIpAddressesHosting
 */
export interface ControlEntityAssetIpAddressesHosting {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    source_list_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetIpAddressesHosting
     */
    last_found?: string;
}

/**
 * Check if a given object implements the ControlEntityAssetIpAddressesHosting interface.
 */
export function instanceOfControlEntityAssetIpAddressesHosting(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityAssetIpAddressesHostingFromJSON(json: any): ControlEntityAssetIpAddressesHosting {
    return ControlEntityAssetIpAddressesHostingFromJSONTyped(json, false);
}

export function ControlEntityAssetIpAddressesHostingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetIpAddressesHosting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'source_list_name': !exists(json, 'source_list_name') ? undefined : json['source_list_name'],
        'subcategory': !exists(json, 'subcategory') ? undefined : json['subcategory'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'last_found': !exists(json, 'last_found') ? undefined : json['last_found'],
    };
}

export function ControlEntityAssetIpAddressesHostingToJSON(value?: ControlEntityAssetIpAddressesHosting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'region': value.region,
        'source_list_name': value.source_list_name,
        'subcategory': value.subcategory,
        'created': value.created,
        'last_found': value.last_found,
    };
}

