/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
 */
export interface ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
     */
    activity_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
     */
    expense_amt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
     */
    grant_amt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
     */
    revenue_amt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy
     */
    desc?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActyFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy {
    return ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActyFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity_cd': !exists(json, 'activity_cd') ? undefined : json['activity_cd'],
        'expense_amt': !exists(json, 'expense_amt') ? undefined : json['expense_amt'],
        'grant_amt': !exists(json, 'grant_amt') ? undefined : json['grant_amt'],
        'revenue_amt': !exists(json, 'revenue_amt') ? undefined : json['revenue_amt'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActyToJSON(value?: ExecRiskAppCoreModelsScansIRS990ProgSrvcAccomActy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_cd': value.activity_cd,
        'expense_amt': value.expense_amt,
        'grant_amt': value.grant_amt,
        'revenue_amt': value.revenue_amt,
        'desc': value.desc,
    };
}

