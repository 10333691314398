/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalFormInstanceWithLocaleAllOf
 */
export interface SupplementalFormInstanceWithLocaleAllOf {
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalFormInstanceWithLocaleAllOf
     */
    locale: SupplementalLocale;
}

/**
 * Check if a given object implements the SupplementalFormInstanceWithLocaleAllOf interface.
 */
export function instanceOfSupplementalFormInstanceWithLocaleAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function SupplementalFormInstanceWithLocaleAllOfFromJSON(json: any): SupplementalFormInstanceWithLocaleAllOf {
    return SupplementalFormInstanceWithLocaleAllOfFromJSONTyped(json, false);
}

export function SupplementalFormInstanceWithLocaleAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFormInstanceWithLocaleAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': SupplementalLocaleFromJSON(json['locale']),
    };
}

export function SupplementalFormInstanceWithLocaleAllOfToJSON(value?: SupplementalFormInstanceWithLocaleAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': SupplementalLocaleToJSON(value.locale),
    };
}

