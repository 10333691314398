/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksStatusModelsStatus } from './ExecRiskAppApiV1EagerTasksStatusModelsStatus';
import {
    ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSON,
    ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSONTyped,
    ExecRiskAppApiV1EagerTasksStatusModelsStatusToJSON,
} from './ExecRiskAppApiV1EagerTasksStatusModelsStatus';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksStatusModelsScanStatus
 */
export interface ExecRiskAppApiV1EagerTasksStatusModelsScanStatus {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksStatusModelsStatus}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsScanStatus
     */
    form990?: ExecRiskAppApiV1EagerTasksStatusModelsStatus;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksStatusModelsStatus}
     * @memberof ExecRiskAppApiV1EagerTasksStatusModelsScanStatus
     */
    form5500?: ExecRiskAppApiV1EagerTasksStatusModelsStatus;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksStatusModelsScanStatus interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksStatusModelsScanStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSON(json: any): ExecRiskAppApiV1EagerTasksStatusModelsScanStatus {
    return ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksStatusModelsScanStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form990': !exists(json, 'form990') ? undefined : ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSON(json['form990']),
        'form5500': !exists(json, 'form5500') ? undefined : ExecRiskAppApiV1EagerTasksStatusModelsStatusFromJSON(json['form5500']),
    };
}

export function ExecRiskAppApiV1EagerTasksStatusModelsScanStatusToJSON(value?: ExecRiskAppApiV1EagerTasksStatusModelsScanStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form990': ExecRiskAppApiV1EagerTasksStatusModelsStatusToJSON(value.form990),
        'form5500': ExecRiskAppApiV1EagerTasksStatusModelsStatusToJSON(value.form5500),
    };
}

