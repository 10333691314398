/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlFormDefinition } from './ControlFormDefinition';
import {
    ControlFormDefinitionFromJSON,
    ControlFormDefinitionFromJSONTyped,
    ControlFormDefinitionToJSON,
} from './ControlFormDefinition';
import type { ControlFormProgress } from './ControlFormProgress';
import {
    ControlFormProgressFromJSON,
    ControlFormProgressFromJSONTyped,
    ControlFormProgressToJSON,
} from './ControlFormProgress';

/**
 * 
 * @export
 * @interface ControlFormSummary
 */
export interface ControlFormSummary {
    /**
     * 
     * @type {ControlFormDefinition}
     * @memberof ControlFormSummary
     */
    form_definition: ControlFormDefinition;
    /**
     * 
     * @type {ControlFormProgress}
     * @memberof ControlFormSummary
     */
    form_progress: ControlFormProgress;
}

/**
 * Check if a given object implements the ControlFormSummary interface.
 */
export function instanceOfControlFormSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "form_definition" in value;
    isInstance = isInstance && "form_progress" in value;

    return isInstance;
}

export function ControlFormSummaryFromJSON(json: any): ControlFormSummary {
    return ControlFormSummaryFromJSONTyped(json, false);
}

export function ControlFormSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFormSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_definition': ControlFormDefinitionFromJSON(json['form_definition']),
        'form_progress': ControlFormProgressFromJSON(json['form_progress']),
    };
}

export function ControlFormSummaryToJSON(value?: ControlFormSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_definition': ControlFormDefinitionToJSON(value.form_definition),
        'form_progress': ControlFormProgressToJSON(value.form_progress),
    };
}

