/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlImpersonationType = {
    UserInternal: 'user_internal',
    Msp: 'msp'
} as const;
export type ControlImpersonationType = typeof ControlImpersonationType[keyof typeof ControlImpersonationType];


export function ControlImpersonationTypeFromJSON(json: any): ControlImpersonationType {
    return ControlImpersonationTypeFromJSONTyped(json, false);
}

export function ControlImpersonationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlImpersonationType {
    return json as ControlImpersonationType;
}

export function ControlImpersonationTypeToJSON(value?: ControlImpersonationType | null): any {
    return value as any;
}

