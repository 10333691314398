import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_EXECRISKS = '';

const ENDPOINTS_EXECRISKS = [
  `/er/company_info`,
  `/er/investors`,
  `/er/ppploans`,
  `/er/regulatory_issues`,
  `/scan/${P1}/companyinfo`,
  `/scan/${P1}/experian/liens`,
  `/scan/${P1}/glassdoor/ratings`,
  `/scan/${P1}/glassdoor/reviews`,
  `/scan/${P1}/glassdoor`,
  `/scan/${P1}/ppploans`,
  `/scan/${P1}/status`,
  `/scan/${P1}/violations`, // violation_tracker
  `/scan/${P1}/zoominfo/fundings`,
] as const;

type Endpoints = typeof ENDPOINTS_EXECRISKS[number];

export type EndpointsEXECRISKS = Record<Endpoints, FuncEndpoint>;

export const endpointsEXECRISKS = createApiEndpoints<Endpoints>(
  PREFIX_EXECRISKS,
  ENDPOINTS_EXECRISKS,
) as EndpointsEXECRISKS;
