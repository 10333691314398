import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Dialog = withCoreProps<DialogProps>(
  (props) => {
    const { children, className, stopPropagation, ...otherProps } = props;
    const clsxClass = clsx(className);

    return (
      <MuiDialog
        {...otherProps}
        className={clsxClass}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          if ((e.target as Element).classList.contains('MuiDialog-container')) {
            props.onClose(e);
          }
        }}
      >
        {children}
      </MuiDialog>
    );
  },
  ['maxWidth'],
);

export interface DialogProps
  extends MuiDialogProps,
    Omit<CoreProps, 'maxWidth'> {
  stopPropagation?: boolean;
}
