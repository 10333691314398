/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAlertTypes } from './ControlAlertTypes';
import {
    ControlAlertTypesFromJSON,
    ControlAlertTypesFromJSONTyped,
    ControlAlertTypesToJSON,
} from './ControlAlertTypes';

/**
 * 
 * @export
 * @interface ControlAlertEntry
 */
export interface ControlAlertEntry {
    /**
     * 
     * @type {string}
     * @memberof ControlAlertEntry
     */
    id: string;
    /**
     * 
     * @type {ControlAlertTypes}
     * @memberof ControlAlertEntry
     */
    alert_type: ControlAlertTypes;
    /**
     * 
     * @type {any}
     * @memberof ControlAlertEntry
     */
    payload?: any | null;
}

/**
 * Check if a given object implements the ControlAlertEntry interface.
 */
export function instanceOfControlAlertEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "alert_type" in value;

    return isInstance;
}

export function ControlAlertEntryFromJSON(json: any): ControlAlertEntry {
    return ControlAlertEntryFromJSONTyped(json, false);
}

export function ControlAlertEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAlertEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'alert_type': ControlAlertTypesFromJSON(json['alert_type']),
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
    };
}

export function ControlAlertEntryToJSON(value?: ControlAlertEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'alert_type': ControlAlertTypesToJSON(value.alert_type),
        'payload': value.payload,
    };
}

