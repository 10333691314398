/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Status1
 */
export interface Status1 {
}

/**
 * Check if a given object implements the Status1 interface.
 */
export function instanceOfStatus1(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Status1FromJSON(json: any): Status1 {
    return Status1FromJSONTyped(json, false);
}

export function Status1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Status1 {
    return json;
}

export function Status1ToJSON(value?: Status1 | null): any {
    return value;
}

