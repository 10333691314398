/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFScanType } from './CRSFScanType';
import {
    CRSFScanTypeFromJSON,
    CRSFScanTypeFromJSONTyped,
    CRSFScanTypeToJSON,
} from './CRSFScanType';
import type { CRSFStatusType } from './CRSFStatusType';
import {
    CRSFStatusTypeFromJSON,
    CRSFStatusTypeFromJSONTyped,
    CRSFStatusTypeToJSON,
} from './CRSFStatusType';
import type { CRSFThreadResponse } from './CRSFThreadResponse';
import {
    CRSFThreadResponseFromJSON,
    CRSFThreadResponseFromJSONTyped,
    CRSFThreadResponseToJSON,
} from './CRSFThreadResponse';

/**
 * 
 * @export
 * @interface CRSFGetStatusResponse
 */
export interface CRSFGetStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    account_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    created: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFGetStatusResponse
     */
    domains?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRSFGetStatusResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    quote_id?: string;
    /**
     * 
     * @type {CRSFScanType}
     * @memberof CRSFGetStatusResponse
     */
    scan_type: CRSFScanType;
    /**
     * 
     * @type {CRSFStatusType}
     * @memberof CRSFGetStatusResponse
     */
    status: CRSFStatusType;
    /**
     * 
     * @type {Array<CRSFThreadResponse>}
     * @memberof CRSFGetStatusResponse
     */
    threads: Array<CRSFThreadResponse>;
    /**
     * 
     * @type {string}
     * @memberof CRSFGetStatusResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFGetStatusResponse interface.
 */
export function instanceOfCRSFGetStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "threads" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFGetStatusResponseFromJSON(json: any): CRSFGetStatusResponse {
    return CRSFGetStatusResponseFromJSONTyped(json, false);
}

export function CRSFGetStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFGetStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'created': json['created'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'process_id': json['process_id'],
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'scan_type': CRSFScanTypeFromJSON(json['scan_type']),
        'status': CRSFStatusTypeFromJSON(json['status']),
        'threads': ((json['threads'] as Array<any>).map(CRSFThreadResponseFromJSON)),
        'updated': json['updated'],
    };
}

export function CRSFGetStatusResponseToJSON(value?: CRSFGetStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'created': value.created,
        'domains': value.domains,
        'elapsed': value.elapsed,
        'external_id': value.external_id,
        'process_id': value.process_id,
        'quote_id': value.quote_id,
        'scan_type': CRSFScanTypeToJSON(value.scan_type),
        'status': CRSFStatusTypeToJSON(value.status),
        'threads': ((value.threads as Array<any>).map(CRSFThreadResponseToJSON)),
        'updated': value.updated,
    };
}

