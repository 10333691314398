import React, { useMemo, HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/palenight';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

const useStyles = ({
  fontSize,
  overflowHeight,
}: Partial<CodeProps & Partial<CoreProps>>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        pre: {
          fontSize: fontSize || 14,
          textAlign: 'left',
          margin: 0,
          padding: '0.5em',
          '& .token-line': {
            lineHeight: '1.3em',
            height: '1.3em',
          },
        },
        line: {
          display: 'table-row',
          whiteSpace: 'normal',
        },
        lineNo: {
          display: 'table-cell',
          textAlign: 'right',
          paddingRight: '1em',
          userSelect: 'none',
          opacity: 0.5,
        },
        lineContent: {
          display: 'table-cell',
          wordBreak: 'break-all',
        },
        overflowWrapper: {
          overflowY: 'scroll',
          overflowX: 'hidden',
          maxHeight: overflowHeight || '400px',
        },
      }),
    { index: 1 },
  )();

export const Code = withCoreProps<CodeProps>(
  (props) => {
    const {
      code,
      className,
      fontSize = '10px',
      lang = 'markdown',
      overflow = false,
      overflowHeight,
      keepOriginal = false,
      ...otherProps
    } = props;

    const classes = useStyles({ fontSize, overflowHeight });
    const clsxClass = clsx(className, overflow ? classes.overflowWrapper : '');

    const transformedCode = useMemo<string>(() => {
      let tcode = code;

      if (keepOriginal) return tcode;

      // Remove Wrapping Quotation Marks
      if (tcode[0] === '"' && tcode[tcode.length - 1] === '"') {
        tcode = tcode.slice(1, tcode.length - 1);
      }

      // EOL - Mac (pre-OSX)
      tcode = tcode.replace(/(\\r)/g, '');

      // New Line - Windows
      tcode = tcode.replace(/(\\\\r\\\\n)/g, '\n');

      // New Line - Unix
      tcode = tcode.replace(/(\\n)/g, '\n');

      // Tab (4 spaces)
      tcode = tcode.replace(/(\\t)/g, '    ');

      // Quotation Marks
      tcode = tcode.replace(/(\\")/g, '"');

      // Apostrophes
      tcode = tcode.replace(/(\\')/g, "'");

      // Double Slash
      tcode = tcode.replace(/(\/\/\/)/g, '//');

      return tcode;
    }, [code, keepOriginal]);

    return (
      <div {...otherProps} className={clsxClass}>
        <Highlight
          {...defaultProps}
          theme={theme}
          code={transformedCode}
          language={lang as PropsFor<typeof Highlight>['language']}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={clsx(classes.pre, className)} style={style}>
              {tokens.map((line, i) => {
                return (
                  <div
                    key={uuidv4()}
                    {...getLineProps({ line, key: i })}
                    className={classes.line}
                  >
                    <span className={classes.lineNo}>{i + 1}</span>
                    <span className={classes.lineContent}>
                      {line.map((token, key) => (
                        <span
                          key={uuidv4()}
                          {...getTokenProps({ token, key })}
                        />
                      ))}
                    </span>
                  </div>
                );
              })}
            </pre>
          )}
        </Highlight>
      </div>
    );
  },
  ['fontSize'],
);

export interface CodeProps
  extends HTMLAttributes<any>,
    Omit<CoreProps, 'fontSize'> {
  /**
   * `code` - code to be rendered
   */
  code: string;
  /**
   * `lang` - code language for syntax highlight. Default: `markdown`
   */
  lang: Languages;
  /**
   * `overflow` - *OPTIONAL* - enables overflow in the code block. Default: `false`
   */
  overflow?: boolean;
  /**
   * `overflowHeight` - *OPTIONAL* - overflow height to be used in the code block
   */
  overflowHeight?: string;
  /**
   * `keepOriginal` - *OPTIONAL* - don't transform the code. Default: `false`
   */
  keepOriginal?: boolean;
}

type Languages =
  | 'markup'
  | 'bash'
  | 'clike'
  | 'c'
  | 'cpp'
  | 'css'
  | 'javascript'
  | 'jsx'
  | 'coffeescript'
  | 'actionscript'
  | 'css-extr'
  | 'diff'
  | 'git'
  | 'go'
  | 'graphql'
  | 'handlebars'
  | 'json'
  | 'less'
  | 'makefile'
  | 'markdown'
  | 'objectivec'
  | 'ocaml'
  | 'python'
  | 'reason'
  | 'sass'
  | 'scss'
  | 'sql'
  | 'stylus'
  | 'tsx'
  | 'typescript'
  | 'wasm'
  | 'yaml';
