import dayjs from 'dayjs';

/**
 * 
 * @param dateString: string 
 * 
 * example:
  const dateGiven = "2023-06-30T15:46:00+00:00";
  const isBeforeOrSame = isTodayBeforeGivenDate(dateGiven);
  console.log(isBeforeOrSame); // Output: false
 */

export const isTodayBeforeGivenDate = (dateString: string) => {
  if (!dateString) {
    return false;
  }

  return dayjs().isBefore(dayjs(dateString), 'day');
};
