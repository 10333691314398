import React, { useMemo, FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { coreKeys, useCoreStyles } from './useCoreStyles';
import { CoreProps, CorePropsKeys } from './types';

export function withCoreProps<Props = {}>(
  Component: FC<PropsWithChildren<Props>>,
  corePropsToKeep: CorePropsKeys[] = [],
): FC<PropsWithChildren<Props>> {
  // eslint-disable-next-line react/display-name
  return (p: PropsWithChildren<Props>) => {
    const classes = useCoreStyles({...p} as Partial<CoreProps>);

    const modifiers = useMemo<Modifiers<Props>>(() => {
      const pp: Record<string, any> = { ...p };
      const keys = Object.keys(pp);

      let className = '';
      let hasCoreProps = false;

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'className') className = pp.className;
        if (
          coreKeys.includes(keys[i] as CorePropsKeys) &&
          !corePropsToKeep.includes(keys[i] as CorePropsKeys)
        ) {
          hasCoreProps = true;
          delete pp[keys[i]];
        }
      }

      return { props: pp as Props, className, hasCoreProps };
    }, [p]);

    const clsxClass = clsx(
      modifiers.hasCoreProps ? classes.BE : '',
      modifiers.className || '',
    );

    return <Component {...modifiers.props} className={clsxClass} />;
  };
}

interface Modifiers<Props> {
  props: Props;
  className: string;
  hasCoreProps: boolean;
}
