import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { TextField, TextFieldProps, Tooltip } from '@explorer/core';
import { Info } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

/**
 * FieldText
 */
export const FieldText = ({
  type = 'text',
  autoComplete = 'off',
  label,
  onBlur,
  onChange,
  tooltip,
  setFieldValue,
  setFieldError,
  className,
  ...props
}: FieldTextProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();
  const { palette } = useTheme();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  return (
    <>
      <TextField
        {...props}
        type={type}
        label={intlLabel}
        autoComplete={autoComplete}
        onBlur={onBlur}
        onChange={onChange}
        className={clsxClass}
        InputProps={{
          endAdornment: tooltip && (
            <Tooltip placement="top" title={formatMessage(intl, tooltip)}>
              <Info
                fontSize="small"
                style={{ marginLeft: '5px', color: palette.grey[300] }}
              />
            </Tooltip>
          ),
        }}
      />
    </>
  );
};

/**
 * FieldText Props
 */
export type FieldTextProps = TextFieldProps & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `tooltip` - localized field label
   */
  tooltip?: IntlLabel;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `autoComplete` - google autocomplete prop
   */
  autoComplete?:
    | 'off'
    | 'name' // Full Name
    | 'given-name' // First Name
    | 'additional-name' // Middle Name
    | 'family-name' // Last Name
    | 'username' // Username
    | 'organization' // Company
    | 'organization-title' // Job Title
    | 'street-address' // Full Address
    | 'address-line1' // Address Line 1
    | 'address-line2' // Address Line 2
    | 'address-line3' // Address Line 3
    | 'country-name' // Country
    | 'url' // URL
    | 'tel'; // Telephone
};
