import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * RegulatoryDefenseIcon
 */
export const RegulatoryDefenseIcon = ({
  ...props
}: RegulatoryDefenseIconProps) => {
  return (
    <Icon label="regulatory_defense" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1,19.4c0.4,0.4,0.8,0.8,1.2,1.3c0.9,0.9,0.1,1.8-0.6,2.4c-0.7,0.7-1.5,1.4-2.4,0.6c-0.5-0.5-1-1-1.6-1.5c-0.3-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.3-0.6-0.6-0.9-0.9c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.2-0.9,0-1.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0-0.1-0.1-0.2-0.2l0,0c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.5-0.5-0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.7c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.2-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.1,0-0.2,0.1c0.5,0.5,0.5,1.1,0.1,1.6c-0.4,0.5-0.8,0.7-1.1,0c-0.4-1.2-1.3-2.3-2.4-2.9c-0.6-0.3-1.2-0.5-1.8-0.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.5-0.3-0.8c0-0.5,0.5-1,0.8-1.3c0.5-0.4,1.1-0.4,1.6,0.1c0.1-0.5,0.2-0.6,0.6-1c0.3-0.3,0.6-0.6,0.9-0.9c0.2-0.2,0.3-0.3,0.5-0.5c0.5-0.5,1-1,1.4-1.4c0,0,0,0,0,0c0.3-0.3,0.5-0.5,0.9-0.5c-0.7-0.7-0.5-1.3,0.1-1.9C16,6,16.7,5.8,17.3,6.3c0.8,0.7,1.5,1.4,2.2,2.2c0.4,0.4,0.7,0.7,1.1,1.1c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.7,0.6,0.9,1c0.3,0.6-0.2,1.1-0.6,1.5c-0.6,0.5-1.2,0.6-1.8,0c-0.1,0.5-0.2,0.6-0.6,1l-0.9,0.9c0.3,0.3,0.7,0.7,1,1s0.7,0.7,1,1c0,0,0.1,0.1,0.1,0.2c0.2,0.2,0.4,0.5,0.5,0.5c0,0,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.3-0.2c0.2-0.1,0.5-0.1,0.7,0c0.3,0.1,0.4,0.2,0.6,0.4C23.4,17.7,24.2,18.5,25.1,19.4z M9.2,16c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C13.1,17.8,11.3,16,9.2,16z M10.7,21.1c-0.1,0.5-0.5,1-1,1c-0.1,0-0.1,0.1-0.1,0.1v0.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.1-0.1-0.1-0.1-0.1H8.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h1.2c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.5L8.4,20c-0.5-0.2-0.8-0.7-0.8-1.3c0.1-0.5,0.5-1,1-1c0.1,0,0.1-0.1,0.1-0.1v-0.3c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v0.2c0,0.1,0.1,0.1,0.1,0.1h0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4H8.9c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.5l1.3,0.5C10.5,20,10.8,20.5,10.7,21.1z"
        />
      </g>
    </Icon>
  );
};

/**
 * RegulatoryDefenseIcon Props
 */
export interface RegulatoryDefenseIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
