import React from 'react';
import { Box, Skeleton } from '@explorer/core';

/**
 * BarLoadingSkeleton
 */
export const BarLoadingSkeleton = ({
  variant = 'small-h',
}: BarLoadingSkeletonProps) => {
  // const classes = useStyles({});

  if (variant === 'big-h') {
    return (
      <Box pt={1.5} pl={15} pr={4}>
        <Box py={0.6}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
        <Box py={0.6}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
        <Box py={0.6}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
        <Box py={0.6}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      </Box>
    );
  }

  return (
    <Box pt={1.5} pl={15} pr={4}>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
      <Box py={0.3}>
        <Skeleton variant="rectangular" width="100%" height={21} />
      </Box>
    </Box>
  );
};

/**
 * BarLoadingSkeleton Props
 */
export interface BarLoadingSkeletonProps {
  variant?: 'small-h' | 'big-h';
}
