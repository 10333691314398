import React from 'react';
import clsx from 'clsx';
import MuiDialogContentText, {
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material/DialogContentText';
import { withCoreProps } from '../../withCoreProps';

export const DialogContentText = withCoreProps<DialogContentTextProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<DialogContentTextProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiDialogContentText {...props} className={clsxClass}>
        {children}
      </MuiDialogContentText>
    );
  },
);

export interface DialogContentTextProps extends MuiDialogContentTextProps {}
