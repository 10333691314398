/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Level } from './Level';
import {
    LevelFromJSON,
    LevelFromJSONTyped,
    LevelToJSON,
} from './Level';
import type { MonitImpactedAssetsInterval } from './MonitImpactedAssetsInterval';
import {
    MonitImpactedAssetsIntervalFromJSON,
    MonitImpactedAssetsIntervalFromJSONTyped,
    MonitImpactedAssetsIntervalToJSON,
} from './MonitImpactedAssetsInterval';
import type { MonitLocale } from './MonitLocale';
import {
    MonitLocaleFromJSON,
    MonitLocaleFromJSONTyped,
    MonitLocaleToJSON,
} from './MonitLocale';
import type { MonitResolutionRequestStatus } from './MonitResolutionRequestStatus';
import {
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusFromJSONTyped,
    MonitResolutionRequestStatusToJSON,
} from './MonitResolutionRequestStatus';
import type { ResolutionTypes } from './ResolutionTypes';
import {
    ResolutionTypesFromJSON,
    ResolutionTypesFromJSONTyped,
    ResolutionTypesToJSON,
} from './ResolutionTypes';

/**
 * 
 * @export
 * @interface MonitGetEntityFindingsAggQueries
 */
export interface MonitGetEntityFindingsAggQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    page_size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    exclude_reported?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    samples?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    datapoint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    show_muted_status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    process_id?: string;
    /**
     * 
     * @type {Level}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    level?: Level;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    score_category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    exclude_removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    exclude_ignored?: boolean;
    /**
     * 
     * @type {MonitResolutionRequestStatus}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    muted_status?: MonitResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    first_detected_from?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    first_detected_to?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    last_detected_from?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    last_detected_to?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    search?: string;
    /**
     * 
     * @type {MonitImpactedAssetsInterval}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    n_assets?: MonitImpactedAssetsInterval;
    /**
     * 
     * @type {ResolutionTypes}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    resolution_types?: ResolutionTypes;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    no_actions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    contingency_status?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    is_contingency?: boolean;
    /**
     * 
     * @type {MonitLocale}
     * @memberof MonitGetEntityFindingsAggQueries
     */
    locale?: MonitLocale;
}

/**
 * Check if a given object implements the MonitGetEntityFindingsAggQueries interface.
 */
export function instanceOfMonitGetEntityFindingsAggQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityFindingsAggQueriesFromJSON(json: any): MonitGetEntityFindingsAggQueries {
    return MonitGetEntityFindingsAggQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityFindingsAggQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityFindingsAggQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'exclude_reported': !exists(json, 'exclude_reported') ? undefined : json['exclude_reported'],
        'samples': !exists(json, 'samples') ? undefined : json['samples'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'show_muted_status': !exists(json, 'show_muted_status') ? undefined : json['show_muted_status'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'level': !exists(json, 'level') ? undefined : LevelFromJSON(json['level']),
        'category': !exists(json, 'category') ? undefined : json['category'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'exclude_removed': !exists(json, 'exclude_removed') ? undefined : json['exclude_removed'],
        'exclude_ignored': !exists(json, 'exclude_ignored') ? undefined : json['exclude_ignored'],
        'muted_status': !exists(json, 'muted_status') ? undefined : MonitResolutionRequestStatusFromJSON(json['muted_status']),
        'first_detected_from': !exists(json, 'first_detected_from') ? undefined : json['first_detected_from'],
        'first_detected_to': !exists(json, 'first_detected_to') ? undefined : json['first_detected_to'],
        'last_detected_from': !exists(json, 'last_detected_from') ? undefined : json['last_detected_from'],
        'last_detected_to': !exists(json, 'last_detected_to') ? undefined : json['last_detected_to'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'n_assets': !exists(json, 'n_assets') ? undefined : MonitImpactedAssetsIntervalFromJSON(json['n_assets']),
        'resolution_types': !exists(json, 'resolution_types') ? undefined : ResolutionTypesFromJSON(json['resolution_types']),
        'no_actions': !exists(json, 'no_actions') ? undefined : json['no_actions'],
        'contingency_status': !exists(json, 'contingency_status') ? undefined : json['contingency_status'],
        'is_contingency': !exists(json, 'is_contingency') ? undefined : json['is_contingency'],
        'locale': !exists(json, 'locale') ? undefined : MonitLocaleFromJSON(json['locale']),
    };
}

export function MonitGetEntityFindingsAggQueriesToJSON(value?: MonitGetEntityFindingsAggQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'exclude_reported': value.exclude_reported,
        'samples': value.samples,
        'datapoint': value.datapoint,
        'show_muted_status': value.show_muted_status,
        'process_id': value.process_id,
        'level': LevelToJSON(value.level),
        'category': value.category,
        'score_category': value.score_category,
        'name': value.name,
        'exclude_removed': value.exclude_removed,
        'exclude_ignored': value.exclude_ignored,
        'muted_status': MonitResolutionRequestStatusToJSON(value.muted_status),
        'first_detected_from': value.first_detected_from,
        'first_detected_to': value.first_detected_to,
        'last_detected_from': value.last_detected_from,
        'last_detected_to': value.last_detected_to,
        'search': value.search,
        'n_assets': MonitImpactedAssetsIntervalToJSON(value.n_assets),
        'resolution_types': ResolutionTypesToJSON(value.resolution_types),
        'no_actions': value.no_actions,
        'contingency_status': value.contingency_status,
        'is_contingency': value.is_contingency,
        'locale': MonitLocaleToJSON(value.locale),
    };
}

