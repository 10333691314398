/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EvidencesInnerValue } from './EvidencesInnerValue';
import {
    EvidencesInnerValueFromJSON,
    EvidencesInnerValueFromJSONTyped,
    EvidencesInnerValueToJSON,
} from './EvidencesInnerValue';

/**
 * 
 * @export
 * @interface CRSFFindingAggregated
 */
export interface CRSFFindingAggregated {
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    description: string;
    /**
     * 
     * @type {Array<{ [key: string]: EvidencesInnerValue; }>}
     * @memberof CRSFFindingAggregated
     */
    evidences: Array<{ [key: string]: EvidencesInnerValue; }>;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    finding_id: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingAggregated
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    port: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    protocol: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFFindingAggregated
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFFindingAggregated
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    risk?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingAggregated
     */
    risk_score: number;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFFindingAggregated
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFFindingAggregated
     */
    state?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFFindingAggregated
     */
    tags: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingAggregated
     */
    updated: number;
}

/**
 * Check if a given object implements the CRSFFindingAggregated interface.
 */
export function instanceOfCRSFFindingAggregated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "evidences" in value;
    isInstance = isInstance && "finding_id" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "protocol" in value;
    isInstance = isInstance && "risk_score" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFFindingAggregatedFromJSON(json: any): CRSFFindingAggregated {
    return CRSFFindingAggregatedFromJSONTyped(json, false);
}

export function CRSFFindingAggregatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFFindingAggregated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'datapoint': json['datapoint'],
        'description': json['description'],
        'evidences': json['evidences'],
        'finding_id': json['finding_id'],
        'found': json['found'],
        'level': json['level'],
        'port': json['port'],
        'protocol': json['protocol'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'risk': !exists(json, 'risk') ? undefined : json['risk'],
        'risk_score': json['risk_score'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'tags': json['tags'],
        'updated': json['updated'],
    };
}

export function CRSFFindingAggregatedToJSON(value?: CRSFFindingAggregated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'datapoint': value.datapoint,
        'description': value.description,
        'evidences': value.evidences,
        'finding_id': value.finding_id,
        'found': value.found,
        'level': value.level,
        'port': value.port,
        'protocol': value.protocol,
        'recommendation': value.recommendation,
        'references': value.references,
        'risk': value.risk,
        'risk_score': value.risk_score,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'state': value.state,
        'tags': value.tags,
        'updated': value.updated,
    };
}

