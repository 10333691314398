/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewTechnologyItem } from './CRSFSecReviewTechnologyItem';
import {
    CRSFSecReviewTechnologyItemFromJSON,
    CRSFSecReviewTechnologyItemFromJSONTyped,
    CRSFSecReviewTechnologyItemToJSON,
} from './CRSFSecReviewTechnologyItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewPathItem
 */
export interface CRSFSecReviewPathItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewPathItem
     */
    Domain: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewPathItem
     */
    FirstIndexed: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewPathItem
     */
    LastIndexed: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewPathItem
     */
    SubDomain: string;
    /**
     * 
     * @type {Array<CRSFSecReviewTechnologyItem>}
     * @memberof CRSFSecReviewPathItem
     */
    Technologies: Array<CRSFSecReviewTechnologyItem>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewPathItem
     */
    Url: string;
}

/**
 * Check if a given object implements the CRSFSecReviewPathItem interface.
 */
export function instanceOfCRSFSecReviewPathItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Domain" in value;
    isInstance = isInstance && "FirstIndexed" in value;
    isInstance = isInstance && "LastIndexed" in value;
    isInstance = isInstance && "SubDomain" in value;
    isInstance = isInstance && "Technologies" in value;
    isInstance = isInstance && "Url" in value;

    return isInstance;
}

export function CRSFSecReviewPathItemFromJSON(json: any): CRSFSecReviewPathItem {
    return CRSFSecReviewPathItemFromJSONTyped(json, false);
}

export function CRSFSecReviewPathItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewPathItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Domain': json['Domain'],
        'FirstIndexed': json['FirstIndexed'],
        'LastIndexed': json['LastIndexed'],
        'SubDomain': json['SubDomain'],
        'Technologies': ((json['Technologies'] as Array<any>).map(CRSFSecReviewTechnologyItemFromJSON)),
        'Url': json['Url'],
    };
}

export function CRSFSecReviewPathItemToJSON(value?: CRSFSecReviewPathItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Domain': value.Domain,
        'FirstIndexed': value.FirstIndexed,
        'LastIndexed': value.LastIndexed,
        'SubDomain': value.SubDomain,
        'Technologies': ((value.Technologies as Array<any>).map(CRSFSecReviewTechnologyItemToJSON)),
        'Url': value.Url,
    };
}

