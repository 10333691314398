/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskLongForm5500 } from './ExecRiskLongForm5500';
import {
    ExecRiskLongForm5500FromJSON,
    ExecRiskLongForm5500FromJSONTyped,
    ExecRiskLongForm5500ToJSON,
} from './ExecRiskLongForm5500';
import type { ExecRiskShortForm5500 } from './ExecRiskShortForm5500';
import {
    ExecRiskShortForm5500FromJSON,
    ExecRiskShortForm5500FromJSONTyped,
    ExecRiskShortForm5500ToJSON,
} from './ExecRiskShortForm5500';

/**
 * 
 * @export
 * @interface ExecRiskForm5500BasicResponse
 */
export interface ExecRiskForm5500BasicResponse {
    /**
     * 
     * @type {Array<ExecRiskLongForm5500>}
     * @memberof ExecRiskForm5500BasicResponse
     */
    long_forms?: Array<ExecRiskLongForm5500>;
    /**
     * 
     * @type {Array<ExecRiskShortForm5500>}
     * @memberof ExecRiskForm5500BasicResponse
     */
    short_forms?: Array<ExecRiskShortForm5500>;
}

/**
 * Check if a given object implements the ExecRiskForm5500BasicResponse interface.
 */
export function instanceOfExecRiskForm5500BasicResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskForm5500BasicResponseFromJSON(json: any): ExecRiskForm5500BasicResponse {
    return ExecRiskForm5500BasicResponseFromJSONTyped(json, false);
}

export function ExecRiskForm5500BasicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskForm5500BasicResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'long_forms': !exists(json, 'long_forms') ? undefined : ((json['long_forms'] as Array<any>).map(ExecRiskLongForm5500FromJSON)),
        'short_forms': !exists(json, 'short_forms') ? undefined : ((json['short_forms'] as Array<any>).map(ExecRiskShortForm5500FromJSON)),
    };
}

export function ExecRiskForm5500BasicResponseToJSON(value?: ExecRiskForm5500BasicResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'long_forms': value.long_forms === undefined ? undefined : ((value.long_forms as Array<any>).map(ExecRiskLongForm5500ToJSON)),
        'short_forms': value.short_forms === undefined ? undefined : ((value.short_forms as Array<any>).map(ExecRiskShortForm5500ToJSON)),
    };
}

