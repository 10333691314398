/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlProfileField = {
    UserRole: 'user_role',
    CompanySize: 'company_size',
    UserFirstName: 'user_first_name',
    UserLastName: 'user_last_name',
    UserPhoneNumber: 'user_phone_number',
    UserJobFunction: 'user_job_function',
    UserJobLevel: 'user_job_level',
    CompanyEmployeeCount: 'company_employee_count',
    CompanyHasMsp: 'company_has_msp',
    CompanyMspWebsite: 'company_msp_website',
    CompanyMspEmail: 'company_msp_email',
    MspCompanyClientCount: 'msp_company_client_count',
    MspCompanyName: 'msp_company_name',
    MspCompanyRevenue: 'msp_company_revenue'
} as const;
export type ControlProfileField = typeof ControlProfileField[keyof typeof ControlProfileField];


export function ControlProfileFieldFromJSON(json: any): ControlProfileField {
    return ControlProfileFieldFromJSONTyped(json, false);
}

export function ControlProfileFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlProfileField {
    return json as ControlProfileField;
}

export function ControlProfileFieldToJSON(value?: ControlProfileField | null): any {
    return value as any;
}

