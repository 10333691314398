/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cy_distributable_as_adjusted_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    underdistributions_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_yr_5_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_yr_4_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_yr_3_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_yr_2_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_yr_1_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    total_excess_distribution_cyov_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cyov_applied_underdistri_py_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cyov_applied_underdistr_cpy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    carryover_py_not_applied_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_cyov_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cy_total_annual_distributions_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cy_distrib_app_underdistri_py_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    cy_distri_app_distributable_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distribution_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    remaining_underdistri_py_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    remaining_underdistri_cy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_distri_cyov_to_next_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_from_year_5_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_from_year_4_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_from_year_3_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_from_year_2_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations
     */
    excess_from_year_1_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations {
    return ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cy_distributable_as_adjusted_amt': !exists(json, 'cy_distributable_as_adjusted_amt') ? undefined : json['cy_distributable_as_adjusted_amt'],
        'underdistributions_amt': !exists(json, 'underdistributions_amt') ? undefined : json['underdistributions_amt'],
        'excess_distribution_cyov_yr_5_amt': !exists(json, 'excess_distribution_cyov_yr_5_amt') ? undefined : json['excess_distribution_cyov_yr_5_amt'],
        'excess_distribution_cyov_yr_4_amt': !exists(json, 'excess_distribution_cyov_yr_4_amt') ? undefined : json['excess_distribution_cyov_yr_4_amt'],
        'excess_distribution_cyov_yr_3_amt': !exists(json, 'excess_distribution_cyov_yr_3_amt') ? undefined : json['excess_distribution_cyov_yr_3_amt'],
        'excess_distribution_cyov_yr_2_amt': !exists(json, 'excess_distribution_cyov_yr_2_amt') ? undefined : json['excess_distribution_cyov_yr_2_amt'],
        'excess_distribution_cyov_yr_1_amt': !exists(json, 'excess_distribution_cyov_yr_1_amt') ? undefined : json['excess_distribution_cyov_yr_1_amt'],
        'total_excess_distribution_cyov_amt': !exists(json, 'total_excess_distribution_cyov_amt') ? undefined : json['total_excess_distribution_cyov_amt'],
        'cyov_applied_underdistri_py_amt': !exists(json, 'cyov_applied_underdistri_py_amt') ? undefined : json['cyov_applied_underdistri_py_amt'],
        'cyov_applied_underdistr_cpy_amt': !exists(json, 'cyov_applied_underdistr_cpy_amt') ? undefined : json['cyov_applied_underdistr_cpy_amt'],
        'carryover_py_not_applied_amt': !exists(json, 'carryover_py_not_applied_amt') ? undefined : json['carryover_py_not_applied_amt'],
        'excess_distribution_cyov_amt': !exists(json, 'excess_distribution_cyov_amt') ? undefined : json['excess_distribution_cyov_amt'],
        'cy_total_annual_distributions_amt': !exists(json, 'cy_total_annual_distributions_amt') ? undefined : json['cy_total_annual_distributions_amt'],
        'cy_distrib_app_underdistri_py_amt': !exists(json, 'cy_distrib_app_underdistri_py_amt') ? undefined : json['cy_distrib_app_underdistri_py_amt'],
        'cy_distri_app_distributable_amt': !exists(json, 'cy_distri_app_distributable_amt') ? undefined : json['cy_distri_app_distributable_amt'],
        'excess_distribution_amt': !exists(json, 'excess_distribution_amt') ? undefined : json['excess_distribution_amt'],
        'remaining_underdistri_py_amt': !exists(json, 'remaining_underdistri_py_amt') ? undefined : json['remaining_underdistri_py_amt'],
        'remaining_underdistri_cy_amt': !exists(json, 'remaining_underdistri_cy_amt') ? undefined : json['remaining_underdistri_cy_amt'],
        'excess_distri_cyov_to_next_yr_amt': !exists(json, 'excess_distri_cyov_to_next_yr_amt') ? undefined : json['excess_distri_cyov_to_next_yr_amt'],
        'excess_from_year_5_amt': !exists(json, 'excess_from_year_5_amt') ? undefined : json['excess_from_year_5_amt'],
        'excess_from_year_4_amt': !exists(json, 'excess_from_year_4_amt') ? undefined : json['excess_from_year_4_amt'],
        'excess_from_year_3_amt': !exists(json, 'excess_from_year_3_amt') ? undefined : json['excess_from_year_3_amt'],
        'excess_from_year_2_amt': !exists(json, 'excess_from_year_2_amt') ? undefined : json['excess_from_year_2_amt'],
        'excess_from_year_1_amt': !exists(json, 'excess_from_year_1_amt') ? undefined : json['excess_from_year_1_amt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocationsToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleADistributionAllocations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cy_distributable_as_adjusted_amt': value.cy_distributable_as_adjusted_amt,
        'underdistributions_amt': value.underdistributions_amt,
        'excess_distribution_cyov_yr_5_amt': value.excess_distribution_cyov_yr_5_amt,
        'excess_distribution_cyov_yr_4_amt': value.excess_distribution_cyov_yr_4_amt,
        'excess_distribution_cyov_yr_3_amt': value.excess_distribution_cyov_yr_3_amt,
        'excess_distribution_cyov_yr_2_amt': value.excess_distribution_cyov_yr_2_amt,
        'excess_distribution_cyov_yr_1_amt': value.excess_distribution_cyov_yr_1_amt,
        'total_excess_distribution_cyov_amt': value.total_excess_distribution_cyov_amt,
        'cyov_applied_underdistri_py_amt': value.cyov_applied_underdistri_py_amt,
        'cyov_applied_underdistr_cpy_amt': value.cyov_applied_underdistr_cpy_amt,
        'carryover_py_not_applied_amt': value.carryover_py_not_applied_amt,
        'excess_distribution_cyov_amt': value.excess_distribution_cyov_amt,
        'cy_total_annual_distributions_amt': value.cy_total_annual_distributions_amt,
        'cy_distrib_app_underdistri_py_amt': value.cy_distrib_app_underdistri_py_amt,
        'cy_distri_app_distributable_amt': value.cy_distri_app_distributable_amt,
        'excess_distribution_amt': value.excess_distribution_amt,
        'remaining_underdistri_py_amt': value.remaining_underdistri_py_amt,
        'remaining_underdistri_cy_amt': value.remaining_underdistri_cy_amt,
        'excess_distri_cyov_to_next_yr_amt': value.excess_distri_cyov_to_next_yr_amt,
        'excess_from_year_5_amt': value.excess_from_year_5_amt,
        'excess_from_year_4_amt': value.excess_from_year_4_amt,
        'excess_from_year_3_amt': value.excess_from_year_3_amt,
        'excess_from_year_2_amt': value.excess_from_year_2_amt,
        'excess_from_year_1_amt': value.excess_from_year_1_amt,
    };
}

