import React, { memo } from 'react';
import { Box, Grid, Typography } from '@explorer/core';

/**
 * TabView
 */
export const TabView = memo(
  //
  function TabView({
    title,
    description,
    renderAction = null,
    children = null,
  }: TabViewProps) {
    if (!title && !description && !renderAction) {
      return (
        <Box pb={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} px={2}>
              {children}
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box py={3}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Grid item>
            {title && <Typography variant="h5" label={title} />}
            {description && (
              <Typography
                variant="body2"
                color="textSecondary"
                label={description}
              />
            )}
          </Grid>
          {renderAction && <Grid item>{renderAction}</Grid>}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} px={2}>
            {children}
          </Grid>
        </Grid>
      </Box>
    );
  },
);

TabView.displayName = 'TabView';

/**
 * TabViewProps
 */
export interface TabViewProps {
  readonly title?: IntlLabel;
  readonly description?: IntlLabel;
  readonly renderAction?: JSX.Element[] | JSX.Element | null;
  readonly children: JSX.Element[] | JSX.Element | null;
}
