/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAbbreviatedUserResponse } from './ControlAbbreviatedUserResponse';
import {
    ControlAbbreviatedUserResponseFromJSON,
    ControlAbbreviatedUserResponseFromJSONTyped,
    ControlAbbreviatedUserResponseToJSON,
} from './ControlAbbreviatedUserResponse';
import type { ControlAppSchemasAsmEntityVulnerabilitiesCount } from './ControlAppSchemasAsmEntityVulnerabilitiesCount';
import {
    ControlAppSchemasAsmEntityVulnerabilitiesCountFromJSON,
    ControlAppSchemasAsmEntityVulnerabilitiesCountFromJSONTyped,
    ControlAppSchemasAsmEntityVulnerabilitiesCountToJSON,
} from './ControlAppSchemasAsmEntityVulnerabilitiesCount';

/**
 * 
 * @export
 * @interface ControlBrokerInfo
 */
export interface ControlBrokerInfo {
    /**
     * 
     * @type {boolean}
     * @memberof ControlBrokerInfo
     */
    policyholder_activated: boolean;
    /**
     * 
     * @type {ControlAppSchemasAsmEntityVulnerabilitiesCount}
     * @memberof ControlBrokerInfo
     */
    vulnerabilities: ControlAppSchemasAsmEntityVulnerabilitiesCount;
    /**
     * 
     * @type {number}
     * @memberof ControlBrokerInfo
     */
    invites_count: number;
    /**
     * 
     * @type {Array<ControlAbbreviatedUserResponse>}
     * @memberof ControlBrokerInfo
     */
    users: Array<ControlAbbreviatedUserResponse>;
    /**
     * 
     * @type {number}
     * @memberof ControlBrokerInfo
     */
    assets?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof ControlBrokerInfo
     */
    critical_findings?: Array<object>;
}

/**
 * Check if a given object implements the ControlBrokerInfo interface.
 */
export function instanceOfControlBrokerInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "policyholder_activated" in value;
    isInstance = isInstance && "vulnerabilities" in value;
    isInstance = isInstance && "invites_count" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function ControlBrokerInfoFromJSON(json: any): ControlBrokerInfo {
    return ControlBrokerInfoFromJSONTyped(json, false);
}

export function ControlBrokerInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlBrokerInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policyholder_activated': json['policyholder_activated'],
        'vulnerabilities': ControlAppSchemasAsmEntityVulnerabilitiesCountFromJSON(json['vulnerabilities']),
        'invites_count': json['invites_count'],
        'users': ((json['users'] as Array<any>).map(ControlAbbreviatedUserResponseFromJSON)),
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'critical_findings': !exists(json, 'critical_findings') ? undefined : json['critical_findings'],
    };
}

export function ControlBrokerInfoToJSON(value?: ControlBrokerInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policyholder_activated': value.policyholder_activated,
        'vulnerabilities': ControlAppSchemasAsmEntityVulnerabilitiesCountToJSON(value.vulnerabilities),
        'invites_count': value.invites_count,
        'users': ((value.users as Array<any>).map(ControlAbbreviatedUserResponseToJSON)),
        'assets': value.assets,
        'critical_findings': value.critical_findings,
    };
}

