import { CheckboxOptions } from '@explorer/forms/base/FieldCheckbox';
import { CRSFLocale } from '@explorer/open-api-client';

export const FORM_ID = 'TriggerCRAScan';

export const DEFAULT_LOCALE = 'en-US';

export const INITIAL_FORM_VALUES = {
  entity_name: '',
  entity_domain: '',
  domains: '',
  ip_addresses: '',
  industry: '',
  number_of_employees: 0,
  number_of_pii_records: 0,
  company_revenue: 0,
  locale: DEFAULT_LOCALE,
  cobranded: null,
  scan_opts: {
    force: false,
  },
};

export const SCAN_OPTS: CheckboxOptions = [
  {
    label: {
      id: 'forms-presets.scan-opts-force',
    },
    name: 'force',
  },
];

export const LOCALE_OPTS: Options = [
  {
    label: {
      id: 'forms-presets.locale-en-AU',
    },
    value: CRSFLocale.EnAu,
  },
  {
    label: {
      id: 'forms-presets.locale-en-CA',
    },
    value: CRSFLocale.EnCa,
  },
  {
    label: {
      id: 'forms-presets.locale-en-GB',
    },
    value: CRSFLocale.EnGb,
  },
  {
    label: {
      id: 'forms-presets.locale-en-US',
    },
    value: CRSFLocale.EnUs,
  },
  {
    label: {
      id: 'forms-presets.locale-de-DE',
    },
    value: CRSFLocale.DeDe,
  },
  {
    label: {
      id: 'forms-presets.locale-fr-CA',
    },
    value: CRSFLocale.FrCa,
  },
];

export const COBRANDED_OPTS: Options = [
  {
    label: 'Acrisure',
    value: 'acrisure',
  },
];
