/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityTorrentsResult
 */
export interface MonitEntityTorrentsResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    info_hash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityTorrentsResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTorrentsResult
     */
    category?: string;
}

/**
 * Check if a given object implements the MonitEntityTorrentsResult interface.
 */
export function instanceOfMonitEntityTorrentsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityTorrentsResultFromJSON(json: any): MonitEntityTorrentsResult {
    return MonitEntityTorrentsResultFromJSONTyped(json, false);
}

export function MonitEntityTorrentsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityTorrentsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'info_hash': !exists(json, 'info_hash') ? undefined : json['info_hash'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'category': !exists(json, 'category') ? undefined : json['category'],
    };
}

export function MonitEntityTorrentsResultToJSON(value?: MonitEntityTorrentsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_domain': value.entity_domain,
        'entity_name': value.entity_name,
        'asset': value.asset,
        'source_asset': value.source_asset,
        'name': value.name,
        'info_hash': value.info_hash,
        'shared': value.shared,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
        'category': value.category,
    };
}

