/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AsName,
  CRACRAPagedResponse,
  CRAEntity,
  CRAExceptionsOut,
  CRAHTTPValidationError,
  CRAProcess,
  CRAStats,
  CRATrigger,
  CRSFBadRequestError,
  CRSFDataleaksPagedResponse,
  CRSFDomainPagedResponse,
  CRSFGetStatusResponse,
  CRSFHoneypotsPagedResponse,
  CRSFInternalServerError,
  CRSFIpAddressesPagedResponse,
  CRSFProcess,
  CRSFProcessPagedResponse,
  CRSFResolvedFindingsPagedResponse,
  CRSFScanType,
  CRSFSecReviewBadRequestError,
  CRSFSecReviewDataleaksPagedResponse,
  CRSFSecReviewDomainPagedResponse,
  CRSFSecReviewGetStatusResponse,
  CRSFSecReviewHoneypotsPagedResponse,
  CRSFSecReviewInternalServerError,
  CRSFSecReviewIpAddressesPagedResponse,
  CRSFSecReviewProcess,
  CRSFSecReviewProcessPagedResponse,
  CRSFSecReviewResolvedFindingsPagedResponse,
  CRSFSecReviewScanType,
  CRSFSecReviewTechsPagedResponse,
  CRSFSecReviewTorrentsPagedResponse,
  CRSFSecReviewTriggerNoIssue,
  CRSFSecReviewTriggerNoIssueResponse,
  CRSFSecReviewTriggerScanIssueRequest,
  CRSFSecReviewTriggerScanIssueResponse,
  CRSFSecReviewUnauthorizedError,
  CRSFSecReviewValidationError,
  CRSFSecReviewValidationScanTrigger,
  CRSFSecReviewValidationTriggerResponse,
  CRSFSrcHttpdlibCoreModelsCraTrigger,
  CRSFSrcHttpdlibCoreModelsCraTriggerResponse,
  CRSFTechsPagedResponse,
  CRSFTorrentsPagedResponse,
  CRSFUnauthorizedError,
  CRSFValidationError,
  Category,
  ContactType,
  ControlAdminCreateUser,
  ControlAdminCreateUserResponse,
  ControlAdminInviteResponse,
  ControlAdminResolutionRequestBody,
  ControlAdminResolutionRequestResponse,
  ControlAdminResolutionRequestResponseWithEvidence,
  ControlAdminUpdateGrantRequestStatusRequest,
  ControlAdminUpdateGrantRequestStatusResponse,
  ControlAppSchemasAdminGetZeroDayAlertResponse,
  ControlAppSchemasUserClientResponse,
  ControlAssociateUserRequest,
  ControlAssociateUsersRequest,
  ControlAssociateUsersResponse,
  ControlAuthenticatorDetails,
  ControlBadRequestError,
  ControlClientInfoResponse,
  ControlClientUpdateRequest,
  ControlCreateZeroDayAlertRequest,
  ControlCreateZeroDayAlertResponse,
  ControlEntitySearchResponse,
  ControlExplorerRescanVulnerabilities,
  ControlFailedDependencyError,
  ControlForbiddenError,
  ControlGetZeroDayAlertClientsResponse,
  ControlGetZeroDayAlertNotificationsResponse,
  ControlGetZeroDayAlertSampleFileResponse,
  ControlGetZeroDayAlertsResponse,
  ControlGrantRequestStatus,
  ControlGrantScope,
  ControlImpersonateOTTResponse,
  ControlInternalServerError,
  ControlMethodNotAllowedError,
  ControlMuteRequestBodyAdmin,
  ControlMuteRequestResponse,
  ControlNotFoundError,
  ControlNotifyZeroDayAlertClientsRequest,
  ControlNotifyZeroDayAlertClientsResponse,
  ControlPaginatedAdminGrantRequestsResponse,
  ControlPaginatedAdminResolutionsResponse,
  ControlPaginatedUserResponse,
  ControlResolutionRequestOrderByMethods,
  ControlResolutionRequestResponse,
  ControlResolutionStatusUpdateBody,
  ControlSendZeroDayAlertTestEmailRequest,
  ControlSoftDeleteUserRequest,
  ControlTrialUpdateRequest,
  ControlTrialUpdateResponse,
  ControlUnauthorizedError,
  ControlUnsupportedMediaTypeError,
  ControlUpdateZeroDayAlertClientRequest,
  ControlUpdateZeroDayAlertClientResponse,
  ControlUpdateZeroDayAlertRequest,
  ControlUpdateZeroDayAlertResponse,
  ControlUserInviteRequest,
  ControlUserOrderByMethods,
  ControlUserResponse,
  ControlUserRolesResponse,
  ControlUserUpdateRoleRequest,
  ControlValidationError,
  ControlZeroDayAlertResolutionStatusFilter,
  CountFindingsFilter,
  Datapoint,
  DnsTypes,
  EntityId,
  ExcludeEntityId,
  ExecRiskAppCoreModelsTriggerProcessStatusResponse,
  ExecRiskBadRequestError,
  ExecRiskCompanyInfoCategoryResponse,
  ExecRiskGlassDoorRatingsResponse,
  ExecRiskInternalServerError,
  ExecRiskPaginatedAlexaResponse,
  ExecRiskPaginatedAylienArticlesResponse,
  ExecRiskPaginatedAylienResponse,
  ExecRiskPaginatedBingResponse,
  ExecRiskPaginatedDocketScanResponse,
  ExecRiskPaginatedEVerifyResponse,
  ExecRiskPaginatedEntityWatchlistResponse,
  ExecRiskPaginatedExperianLiensResponse,
  ExecRiskPaginatedExperianResponse,
  ExecRiskPaginatedForm990Response,
  ExecRiskPaginatedGlassDoorReviewsResponse,
  ExecRiskPaginatedGlassDoorScanResponse,
  ExecRiskPaginatedIndustryClassificationScanResponse,
  ExecRiskPaginatedInvestorsScanResponse,
  ExecRiskPaginatedLayoffsResponse,
  ExecRiskPaginatedPPPLoansScanResponse,
  ExecRiskPaginatedProximityOneResponse,
  ExecRiskPaginatedViolationTrackerResponse,
  ExecRiskPaginatedWaveResponse,
  ExecRiskPaginatedWebhoseArticlesResponse,
  ExecRiskPaginatedWebhoseResponse,
  ExecRiskPaginatedZoomInfoFundingResponse,
  ExecRiskPaginatedZoomInfoOrgchartResponse,
  ExecRiskPaginatedZoomInfoParentsResponse,
  ExecRiskPaginatedZoomInfoResponse,
  ExecRiskTriggerListResponse,
  ExecRiskTriggerRequest,
  ExecRiskTriggerResponse,
  ExecRiskUnauthorizedError,
  ExecRiskValidationError,
  ExternalIds,
  Level,
  MonitAccountResolutionRequest,
  MonitAccountResolutions,
  MonitAccountResolutionsPaginatedResponse,
  MonitAddOrUpdateEntityContactResponse,
  MonitEntityAssetOrderBy,
  MonitEntityAssetSource,
  MonitEntityAssetTechOrderBy,
  MonitEntityAssetsDomainsResult,
  MonitEntityAssetsIpAddressesResult,
  MonitEntityContactResponse,
  MonitEntityDataLeaksOrderBy,
  MonitEntityDataleaksCountResponse,
  MonitEntityDataleaksStandardResponse,
  MonitEntityFindingsTopResult,
  MonitEntityHoneypotsOrderBy,
  MonitEntityKeywordOrderBy,
  MonitEntityLookalikesOrderBy,
  MonitEntityMappingResponse,
  MonitEntityNoDomainResponse,
  MonitEntityNotificationsOrderBy,
  MonitEntityPastebinOrderBy,
  MonitEntityRelationOrderBy,
  MonitEntityRelationResponse,
  MonitEntityRelationStatsGroups,
  MonitEntityReportedAssetOrderBy,
  MonitEntityReportedAssetResult,
  MonitEntityRevokeResponse,
  MonitEntityStatsByDayResponse,
  MonitEntityStatsResponse,
  MonitEntityTechOrderBy,
  MonitEntityTorrentsOrderBy,
  MonitEntityTriggerResponse,
  MonitFindingResolutionRequest,
  MonitFindingResolutions,
  MonitImpactedAssetsInterval,
  MonitLocale,
  MonitPaginatedEntityAssetTechResponse,
  MonitPaginatedEntityAssetsResponse,
  MonitPaginatedEntityFindingsAggResponse,
  MonitPaginatedEntityFindingsResponse,
  MonitPaginatedEntityHoneypotsResponse,
  MonitPaginatedEntityKeywordsResponse,
  MonitPaginatedEntityLookalikesResponse,
  MonitPaginatedEntityPastebinResponse,
  MonitPaginatedEntityRelationResponse,
  MonitPaginatedEntityReportedAssetsResponse,
  MonitPaginatedEntityTechResponse,
  MonitPaginatedEntityTorrentsResponse,
  MonitPaginatedGetEntityNotificationsResponse,
  MonitReportAssetRequest,
  MonitResolutionRequestStatus,
  MonitRootAddUpdateContactRequest,
  MonitRootUpdateAssetReportRequest,
  MonitType,
  MonitUpdateReportedAssetResult,
  MonitValidationErrorElement,
  NotificationsBadRequestError,
  NotificationsContactType,
  NotificationsFailedDependencyError,
  NotificationsFilterResponse,
  NotificationsFilterTarget,
  NotificationsForbiddenError,
  NotificationsInternalServerError,
  NotificationsMessageAsAlertForJiraServiceResponse,
  NotificationsMethodNotAllowedError,
  NotificationsNotFoundError,
  NotificationsPaginatedRecordsResponse,
  NotificationsUnauthorizedError,
  NotificationsUnsupportedMediaTypeError,
  NotificationsUserFilterCode,
  NotificationsValidationError,
  ResolutionTypes,
  ResponseInsuranceTriggerInsuranceTriggerPost,
  ResponseRescanTriggerRescanTriggerPost,
  ResponseTriggerTriggerPost,
  ScoreCategory,
  Search,
  SecReviewEntity,
  SecReviewExceptionsOut,
  SecReviewHTTPValidationError,
  SecReviewInsuranceTrigger,
  SecReviewProcess,
  SecReviewRescanTrigger,
  SecReviewSREntityPagedResponse,
  SecReviewSRExtScanPagedResponse,
  SecReviewSRPagedResponse,
  SecReviewStats,
  SecReviewTrigger,
  Status,
  SupplementalCreateFormInstanceRequestBody,
  SupplementalCreateFormInstanceResponse,
  SupplementalFormInstanceStatus,
  SupplementalFormType,
  SupplementalGetAccountQuotesResponse,
  SupplementalGetInternalFormsByQuoteIdsResponse,
  SupplementalGetReportFormInstancesByFilterResponse,
  SupplementalGetReportFormInstancesByRecipientAccountResponse,
  SupplementalGetReportFormInstancesByRecipientIdResponse,
  SupplementalPostFormInstanceInternalBody,
  SupplementalReenableFormInstanceRequestBody,
  Tags,
} from '../models';
import {
    AsNameFromJSON,
    AsNameToJSON,
    CRACRAPagedResponseFromJSON,
    CRACRAPagedResponseToJSON,
    CRAEntityFromJSON,
    CRAEntityToJSON,
    CRAExceptionsOutFromJSON,
    CRAExceptionsOutToJSON,
    CRAHTTPValidationErrorFromJSON,
    CRAHTTPValidationErrorToJSON,
    CRAProcessFromJSON,
    CRAProcessToJSON,
    CRAStatsFromJSON,
    CRAStatsToJSON,
    CRATriggerFromJSON,
    CRATriggerToJSON,
    CRSFBadRequestErrorFromJSON,
    CRSFBadRequestErrorToJSON,
    CRSFDataleaksPagedResponseFromJSON,
    CRSFDataleaksPagedResponseToJSON,
    CRSFDomainPagedResponseFromJSON,
    CRSFDomainPagedResponseToJSON,
    CRSFGetStatusResponseFromJSON,
    CRSFGetStatusResponseToJSON,
    CRSFHoneypotsPagedResponseFromJSON,
    CRSFHoneypotsPagedResponseToJSON,
    CRSFInternalServerErrorFromJSON,
    CRSFInternalServerErrorToJSON,
    CRSFIpAddressesPagedResponseFromJSON,
    CRSFIpAddressesPagedResponseToJSON,
    CRSFProcessFromJSON,
    CRSFProcessToJSON,
    CRSFProcessPagedResponseFromJSON,
    CRSFProcessPagedResponseToJSON,
    CRSFResolvedFindingsPagedResponseFromJSON,
    CRSFResolvedFindingsPagedResponseToJSON,
    CRSFScanTypeFromJSON,
    CRSFScanTypeToJSON,
    CRSFSecReviewBadRequestErrorFromJSON,
    CRSFSecReviewBadRequestErrorToJSON,
    CRSFSecReviewDataleaksPagedResponseFromJSON,
    CRSFSecReviewDataleaksPagedResponseToJSON,
    CRSFSecReviewDomainPagedResponseFromJSON,
    CRSFSecReviewDomainPagedResponseToJSON,
    CRSFSecReviewGetStatusResponseFromJSON,
    CRSFSecReviewGetStatusResponseToJSON,
    CRSFSecReviewHoneypotsPagedResponseFromJSON,
    CRSFSecReviewHoneypotsPagedResponseToJSON,
    CRSFSecReviewInternalServerErrorFromJSON,
    CRSFSecReviewInternalServerErrorToJSON,
    CRSFSecReviewIpAddressesPagedResponseFromJSON,
    CRSFSecReviewIpAddressesPagedResponseToJSON,
    CRSFSecReviewProcessFromJSON,
    CRSFSecReviewProcessToJSON,
    CRSFSecReviewProcessPagedResponseFromJSON,
    CRSFSecReviewProcessPagedResponseToJSON,
    CRSFSecReviewResolvedFindingsPagedResponseFromJSON,
    CRSFSecReviewResolvedFindingsPagedResponseToJSON,
    CRSFSecReviewScanTypeFromJSON,
    CRSFSecReviewScanTypeToJSON,
    CRSFSecReviewTechsPagedResponseFromJSON,
    CRSFSecReviewTechsPagedResponseToJSON,
    CRSFSecReviewTorrentsPagedResponseFromJSON,
    CRSFSecReviewTorrentsPagedResponseToJSON,
    CRSFSecReviewTriggerNoIssueFromJSON,
    CRSFSecReviewTriggerNoIssueToJSON,
    CRSFSecReviewTriggerNoIssueResponseFromJSON,
    CRSFSecReviewTriggerNoIssueResponseToJSON,
    CRSFSecReviewTriggerScanIssueRequestFromJSON,
    CRSFSecReviewTriggerScanIssueRequestToJSON,
    CRSFSecReviewTriggerScanIssueResponseFromJSON,
    CRSFSecReviewTriggerScanIssueResponseToJSON,
    CRSFSecReviewUnauthorizedErrorFromJSON,
    CRSFSecReviewUnauthorizedErrorToJSON,
    CRSFSecReviewValidationErrorFromJSON,
    CRSFSecReviewValidationErrorToJSON,
    CRSFSecReviewValidationScanTriggerFromJSON,
    CRSFSecReviewValidationScanTriggerToJSON,
    CRSFSecReviewValidationTriggerResponseFromJSON,
    CRSFSecReviewValidationTriggerResponseToJSON,
    CRSFSrcHttpdlibCoreModelsCraTriggerFromJSON,
    CRSFSrcHttpdlibCoreModelsCraTriggerToJSON,
    CRSFSrcHttpdlibCoreModelsCraTriggerResponseFromJSON,
    CRSFSrcHttpdlibCoreModelsCraTriggerResponseToJSON,
    CRSFTechsPagedResponseFromJSON,
    CRSFTechsPagedResponseToJSON,
    CRSFTorrentsPagedResponseFromJSON,
    CRSFTorrentsPagedResponseToJSON,
    CRSFUnauthorizedErrorFromJSON,
    CRSFUnauthorizedErrorToJSON,
    CRSFValidationErrorFromJSON,
    CRSFValidationErrorToJSON,
    CategoryFromJSON,
    CategoryToJSON,
    ContactTypeFromJSON,
    ContactTypeToJSON,
    ControlAdminCreateUserFromJSON,
    ControlAdminCreateUserToJSON,
    ControlAdminCreateUserResponseFromJSON,
    ControlAdminCreateUserResponseToJSON,
    ControlAdminInviteResponseFromJSON,
    ControlAdminInviteResponseToJSON,
    ControlAdminResolutionRequestBodyFromJSON,
    ControlAdminResolutionRequestBodyToJSON,
    ControlAdminResolutionRequestResponseFromJSON,
    ControlAdminResolutionRequestResponseToJSON,
    ControlAdminResolutionRequestResponseWithEvidenceFromJSON,
    ControlAdminResolutionRequestResponseWithEvidenceToJSON,
    ControlAdminUpdateGrantRequestStatusRequestFromJSON,
    ControlAdminUpdateGrantRequestStatusRequestToJSON,
    ControlAdminUpdateGrantRequestStatusResponseFromJSON,
    ControlAdminUpdateGrantRequestStatusResponseToJSON,
    ControlAppSchemasAdminGetZeroDayAlertResponseFromJSON,
    ControlAppSchemasAdminGetZeroDayAlertResponseToJSON,
    ControlAppSchemasUserClientResponseFromJSON,
    ControlAppSchemasUserClientResponseToJSON,
    ControlAssociateUserRequestFromJSON,
    ControlAssociateUserRequestToJSON,
    ControlAssociateUsersRequestFromJSON,
    ControlAssociateUsersRequestToJSON,
    ControlAssociateUsersResponseFromJSON,
    ControlAssociateUsersResponseToJSON,
    ControlAuthenticatorDetailsFromJSON,
    ControlAuthenticatorDetailsToJSON,
    ControlBadRequestErrorFromJSON,
    ControlBadRequestErrorToJSON,
    ControlClientInfoResponseFromJSON,
    ControlClientInfoResponseToJSON,
    ControlClientUpdateRequestFromJSON,
    ControlClientUpdateRequestToJSON,
    ControlCreateZeroDayAlertRequestFromJSON,
    ControlCreateZeroDayAlertRequestToJSON,
    ControlCreateZeroDayAlertResponseFromJSON,
    ControlCreateZeroDayAlertResponseToJSON,
    ControlEntitySearchResponseFromJSON,
    ControlEntitySearchResponseToJSON,
    ControlExplorerRescanVulnerabilitiesFromJSON,
    ControlExplorerRescanVulnerabilitiesToJSON,
    ControlFailedDependencyErrorFromJSON,
    ControlFailedDependencyErrorToJSON,
    ControlForbiddenErrorFromJSON,
    ControlForbiddenErrorToJSON,
    ControlGetZeroDayAlertClientsResponseFromJSON,
    ControlGetZeroDayAlertClientsResponseToJSON,
    ControlGetZeroDayAlertNotificationsResponseFromJSON,
    ControlGetZeroDayAlertNotificationsResponseToJSON,
    ControlGetZeroDayAlertSampleFileResponseFromJSON,
    ControlGetZeroDayAlertSampleFileResponseToJSON,
    ControlGetZeroDayAlertsResponseFromJSON,
    ControlGetZeroDayAlertsResponseToJSON,
    ControlGrantRequestStatusFromJSON,
    ControlGrantRequestStatusToJSON,
    ControlGrantScopeFromJSON,
    ControlGrantScopeToJSON,
    ControlImpersonateOTTResponseFromJSON,
    ControlImpersonateOTTResponseToJSON,
    ControlInternalServerErrorFromJSON,
    ControlInternalServerErrorToJSON,
    ControlMethodNotAllowedErrorFromJSON,
    ControlMethodNotAllowedErrorToJSON,
    ControlMuteRequestBodyAdminFromJSON,
    ControlMuteRequestBodyAdminToJSON,
    ControlMuteRequestResponseFromJSON,
    ControlMuteRequestResponseToJSON,
    ControlNotFoundErrorFromJSON,
    ControlNotFoundErrorToJSON,
    ControlNotifyZeroDayAlertClientsRequestFromJSON,
    ControlNotifyZeroDayAlertClientsRequestToJSON,
    ControlNotifyZeroDayAlertClientsResponseFromJSON,
    ControlNotifyZeroDayAlertClientsResponseToJSON,
    ControlPaginatedAdminGrantRequestsResponseFromJSON,
    ControlPaginatedAdminGrantRequestsResponseToJSON,
    ControlPaginatedAdminResolutionsResponseFromJSON,
    ControlPaginatedAdminResolutionsResponseToJSON,
    ControlPaginatedUserResponseFromJSON,
    ControlPaginatedUserResponseToJSON,
    ControlResolutionRequestOrderByMethodsFromJSON,
    ControlResolutionRequestOrderByMethodsToJSON,
    ControlResolutionRequestResponseFromJSON,
    ControlResolutionRequestResponseToJSON,
    ControlResolutionStatusUpdateBodyFromJSON,
    ControlResolutionStatusUpdateBodyToJSON,
    ControlSendZeroDayAlertTestEmailRequestFromJSON,
    ControlSendZeroDayAlertTestEmailRequestToJSON,
    ControlSoftDeleteUserRequestFromJSON,
    ControlSoftDeleteUserRequestToJSON,
    ControlTrialUpdateRequestFromJSON,
    ControlTrialUpdateRequestToJSON,
    ControlTrialUpdateResponseFromJSON,
    ControlTrialUpdateResponseToJSON,
    ControlUnauthorizedErrorFromJSON,
    ControlUnauthorizedErrorToJSON,
    ControlUnsupportedMediaTypeErrorFromJSON,
    ControlUnsupportedMediaTypeErrorToJSON,
    ControlUpdateZeroDayAlertClientRequestFromJSON,
    ControlUpdateZeroDayAlertClientRequestToJSON,
    ControlUpdateZeroDayAlertClientResponseFromJSON,
    ControlUpdateZeroDayAlertClientResponseToJSON,
    ControlUpdateZeroDayAlertRequestFromJSON,
    ControlUpdateZeroDayAlertRequestToJSON,
    ControlUpdateZeroDayAlertResponseFromJSON,
    ControlUpdateZeroDayAlertResponseToJSON,
    ControlUserInviteRequestFromJSON,
    ControlUserInviteRequestToJSON,
    ControlUserOrderByMethodsFromJSON,
    ControlUserOrderByMethodsToJSON,
    ControlUserResponseFromJSON,
    ControlUserResponseToJSON,
    ControlUserRolesResponseFromJSON,
    ControlUserRolesResponseToJSON,
    ControlUserUpdateRoleRequestFromJSON,
    ControlUserUpdateRoleRequestToJSON,
    ControlValidationErrorFromJSON,
    ControlValidationErrorToJSON,
    ControlZeroDayAlertResolutionStatusFilterFromJSON,
    ControlZeroDayAlertResolutionStatusFilterToJSON,
    CountFindingsFilterFromJSON,
    CountFindingsFilterToJSON,
    DatapointFromJSON,
    DatapointToJSON,
    DnsTypesFromJSON,
    DnsTypesToJSON,
    EntityIdFromJSON,
    EntityIdToJSON,
    ExcludeEntityIdFromJSON,
    ExcludeEntityIdToJSON,
    ExecRiskAppCoreModelsTriggerProcessStatusResponseFromJSON,
    ExecRiskAppCoreModelsTriggerProcessStatusResponseToJSON,
    ExecRiskBadRequestErrorFromJSON,
    ExecRiskBadRequestErrorToJSON,
    ExecRiskCompanyInfoCategoryResponseFromJSON,
    ExecRiskCompanyInfoCategoryResponseToJSON,
    ExecRiskGlassDoorRatingsResponseFromJSON,
    ExecRiskGlassDoorRatingsResponseToJSON,
    ExecRiskInternalServerErrorFromJSON,
    ExecRiskInternalServerErrorToJSON,
    ExecRiskPaginatedAlexaResponseFromJSON,
    ExecRiskPaginatedAlexaResponseToJSON,
    ExecRiskPaginatedAylienArticlesResponseFromJSON,
    ExecRiskPaginatedAylienArticlesResponseToJSON,
    ExecRiskPaginatedAylienResponseFromJSON,
    ExecRiskPaginatedAylienResponseToJSON,
    ExecRiskPaginatedBingResponseFromJSON,
    ExecRiskPaginatedBingResponseToJSON,
    ExecRiskPaginatedDocketScanResponseFromJSON,
    ExecRiskPaginatedDocketScanResponseToJSON,
    ExecRiskPaginatedEVerifyResponseFromJSON,
    ExecRiskPaginatedEVerifyResponseToJSON,
    ExecRiskPaginatedEntityWatchlistResponseFromJSON,
    ExecRiskPaginatedEntityWatchlistResponseToJSON,
    ExecRiskPaginatedExperianLiensResponseFromJSON,
    ExecRiskPaginatedExperianLiensResponseToJSON,
    ExecRiskPaginatedExperianResponseFromJSON,
    ExecRiskPaginatedExperianResponseToJSON,
    ExecRiskPaginatedForm990ResponseFromJSON,
    ExecRiskPaginatedForm990ResponseToJSON,
    ExecRiskPaginatedGlassDoorReviewsResponseFromJSON,
    ExecRiskPaginatedGlassDoorReviewsResponseToJSON,
    ExecRiskPaginatedGlassDoorScanResponseFromJSON,
    ExecRiskPaginatedGlassDoorScanResponseToJSON,
    ExecRiskPaginatedIndustryClassificationScanResponseFromJSON,
    ExecRiskPaginatedIndustryClassificationScanResponseToJSON,
    ExecRiskPaginatedInvestorsScanResponseFromJSON,
    ExecRiskPaginatedInvestorsScanResponseToJSON,
    ExecRiskPaginatedLayoffsResponseFromJSON,
    ExecRiskPaginatedLayoffsResponseToJSON,
    ExecRiskPaginatedPPPLoansScanResponseFromJSON,
    ExecRiskPaginatedPPPLoansScanResponseToJSON,
    ExecRiskPaginatedProximityOneResponseFromJSON,
    ExecRiskPaginatedProximityOneResponseToJSON,
    ExecRiskPaginatedViolationTrackerResponseFromJSON,
    ExecRiskPaginatedViolationTrackerResponseToJSON,
    ExecRiskPaginatedWaveResponseFromJSON,
    ExecRiskPaginatedWaveResponseToJSON,
    ExecRiskPaginatedWebhoseArticlesResponseFromJSON,
    ExecRiskPaginatedWebhoseArticlesResponseToJSON,
    ExecRiskPaginatedWebhoseResponseFromJSON,
    ExecRiskPaginatedWebhoseResponseToJSON,
    ExecRiskPaginatedZoomInfoFundingResponseFromJSON,
    ExecRiskPaginatedZoomInfoFundingResponseToJSON,
    ExecRiskPaginatedZoomInfoOrgchartResponseFromJSON,
    ExecRiskPaginatedZoomInfoOrgchartResponseToJSON,
    ExecRiskPaginatedZoomInfoParentsResponseFromJSON,
    ExecRiskPaginatedZoomInfoParentsResponseToJSON,
    ExecRiskPaginatedZoomInfoResponseFromJSON,
    ExecRiskPaginatedZoomInfoResponseToJSON,
    ExecRiskTriggerListResponseFromJSON,
    ExecRiskTriggerListResponseToJSON,
    ExecRiskTriggerRequestFromJSON,
    ExecRiskTriggerRequestToJSON,
    ExecRiskTriggerResponseFromJSON,
    ExecRiskTriggerResponseToJSON,
    ExecRiskUnauthorizedErrorFromJSON,
    ExecRiskUnauthorizedErrorToJSON,
    ExecRiskValidationErrorFromJSON,
    ExecRiskValidationErrorToJSON,
    ExternalIdsFromJSON,
    ExternalIdsToJSON,
    LevelFromJSON,
    LevelToJSON,
    MonitAccountResolutionRequestFromJSON,
    MonitAccountResolutionRequestToJSON,
    MonitAccountResolutionsFromJSON,
    MonitAccountResolutionsToJSON,
    MonitAccountResolutionsPaginatedResponseFromJSON,
    MonitAccountResolutionsPaginatedResponseToJSON,
    MonitAddOrUpdateEntityContactResponseFromJSON,
    MonitAddOrUpdateEntityContactResponseToJSON,
    MonitEntityAssetOrderByFromJSON,
    MonitEntityAssetOrderByToJSON,
    MonitEntityAssetSourceFromJSON,
    MonitEntityAssetSourceToJSON,
    MonitEntityAssetTechOrderByFromJSON,
    MonitEntityAssetTechOrderByToJSON,
    MonitEntityAssetsDomainsResultFromJSON,
    MonitEntityAssetsDomainsResultToJSON,
    MonitEntityAssetsIpAddressesResultFromJSON,
    MonitEntityAssetsIpAddressesResultToJSON,
    MonitEntityContactResponseFromJSON,
    MonitEntityContactResponseToJSON,
    MonitEntityDataLeaksOrderByFromJSON,
    MonitEntityDataLeaksOrderByToJSON,
    MonitEntityDataleaksCountResponseFromJSON,
    MonitEntityDataleaksCountResponseToJSON,
    MonitEntityDataleaksStandardResponseFromJSON,
    MonitEntityDataleaksStandardResponseToJSON,
    MonitEntityFindingsTopResultFromJSON,
    MonitEntityFindingsTopResultToJSON,
    MonitEntityHoneypotsOrderByFromJSON,
    MonitEntityHoneypotsOrderByToJSON,
    MonitEntityKeywordOrderByFromJSON,
    MonitEntityKeywordOrderByToJSON,
    MonitEntityLookalikesOrderByFromJSON,
    MonitEntityLookalikesOrderByToJSON,
    MonitEntityMappingResponseFromJSON,
    MonitEntityMappingResponseToJSON,
    MonitEntityNoDomainResponseFromJSON,
    MonitEntityNoDomainResponseToJSON,
    MonitEntityNotificationsOrderByFromJSON,
    MonitEntityNotificationsOrderByToJSON,
    MonitEntityPastebinOrderByFromJSON,
    MonitEntityPastebinOrderByToJSON,
    MonitEntityRelationOrderByFromJSON,
    MonitEntityRelationOrderByToJSON,
    MonitEntityRelationResponseFromJSON,
    MonitEntityRelationResponseToJSON,
    MonitEntityRelationStatsGroupsFromJSON,
    MonitEntityRelationStatsGroupsToJSON,
    MonitEntityReportedAssetOrderByFromJSON,
    MonitEntityReportedAssetOrderByToJSON,
    MonitEntityReportedAssetResultFromJSON,
    MonitEntityReportedAssetResultToJSON,
    MonitEntityRevokeResponseFromJSON,
    MonitEntityRevokeResponseToJSON,
    MonitEntityStatsByDayResponseFromJSON,
    MonitEntityStatsByDayResponseToJSON,
    MonitEntityStatsResponseFromJSON,
    MonitEntityStatsResponseToJSON,
    MonitEntityTechOrderByFromJSON,
    MonitEntityTechOrderByToJSON,
    MonitEntityTorrentsOrderByFromJSON,
    MonitEntityTorrentsOrderByToJSON,
    MonitEntityTriggerResponseFromJSON,
    MonitEntityTriggerResponseToJSON,
    MonitFindingResolutionRequestFromJSON,
    MonitFindingResolutionRequestToJSON,
    MonitFindingResolutionsFromJSON,
    MonitFindingResolutionsToJSON,
    MonitImpactedAssetsIntervalFromJSON,
    MonitImpactedAssetsIntervalToJSON,
    MonitLocaleFromJSON,
    MonitLocaleToJSON,
    MonitPaginatedEntityAssetTechResponseFromJSON,
    MonitPaginatedEntityAssetTechResponseToJSON,
    MonitPaginatedEntityAssetsResponseFromJSON,
    MonitPaginatedEntityAssetsResponseToJSON,
    MonitPaginatedEntityFindingsAggResponseFromJSON,
    MonitPaginatedEntityFindingsAggResponseToJSON,
    MonitPaginatedEntityFindingsResponseFromJSON,
    MonitPaginatedEntityFindingsResponseToJSON,
    MonitPaginatedEntityHoneypotsResponseFromJSON,
    MonitPaginatedEntityHoneypotsResponseToJSON,
    MonitPaginatedEntityKeywordsResponseFromJSON,
    MonitPaginatedEntityKeywordsResponseToJSON,
    MonitPaginatedEntityLookalikesResponseFromJSON,
    MonitPaginatedEntityLookalikesResponseToJSON,
    MonitPaginatedEntityPastebinResponseFromJSON,
    MonitPaginatedEntityPastebinResponseToJSON,
    MonitPaginatedEntityRelationResponseFromJSON,
    MonitPaginatedEntityRelationResponseToJSON,
    MonitPaginatedEntityReportedAssetsResponseFromJSON,
    MonitPaginatedEntityReportedAssetsResponseToJSON,
    MonitPaginatedEntityTechResponseFromJSON,
    MonitPaginatedEntityTechResponseToJSON,
    MonitPaginatedEntityTorrentsResponseFromJSON,
    MonitPaginatedEntityTorrentsResponseToJSON,
    MonitPaginatedGetEntityNotificationsResponseFromJSON,
    MonitPaginatedGetEntityNotificationsResponseToJSON,
    MonitReportAssetRequestFromJSON,
    MonitReportAssetRequestToJSON,
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusToJSON,
    MonitRootAddUpdateContactRequestFromJSON,
    MonitRootAddUpdateContactRequestToJSON,
    MonitRootUpdateAssetReportRequestFromJSON,
    MonitRootUpdateAssetReportRequestToJSON,
    MonitTypeFromJSON,
    MonitTypeToJSON,
    MonitUpdateReportedAssetResultFromJSON,
    MonitUpdateReportedAssetResultToJSON,
    MonitValidationErrorElementFromJSON,
    MonitValidationErrorElementToJSON,
    NotificationsBadRequestErrorFromJSON,
    NotificationsBadRequestErrorToJSON,
    NotificationsContactTypeFromJSON,
    NotificationsContactTypeToJSON,
    NotificationsFailedDependencyErrorFromJSON,
    NotificationsFailedDependencyErrorToJSON,
    NotificationsFilterResponseFromJSON,
    NotificationsFilterResponseToJSON,
    NotificationsFilterTargetFromJSON,
    NotificationsFilterTargetToJSON,
    NotificationsForbiddenErrorFromJSON,
    NotificationsForbiddenErrorToJSON,
    NotificationsInternalServerErrorFromJSON,
    NotificationsInternalServerErrorToJSON,
    NotificationsMessageAsAlertForJiraServiceResponseFromJSON,
    NotificationsMessageAsAlertForJiraServiceResponseToJSON,
    NotificationsMethodNotAllowedErrorFromJSON,
    NotificationsMethodNotAllowedErrorToJSON,
    NotificationsNotFoundErrorFromJSON,
    NotificationsNotFoundErrorToJSON,
    NotificationsPaginatedRecordsResponseFromJSON,
    NotificationsPaginatedRecordsResponseToJSON,
    NotificationsUnauthorizedErrorFromJSON,
    NotificationsUnauthorizedErrorToJSON,
    NotificationsUnsupportedMediaTypeErrorFromJSON,
    NotificationsUnsupportedMediaTypeErrorToJSON,
    NotificationsUserFilterCodeFromJSON,
    NotificationsUserFilterCodeToJSON,
    NotificationsValidationErrorFromJSON,
    NotificationsValidationErrorToJSON,
    ResolutionTypesFromJSON,
    ResolutionTypesToJSON,
    ResponseInsuranceTriggerInsuranceTriggerPostFromJSON,
    ResponseInsuranceTriggerInsuranceTriggerPostToJSON,
    ResponseRescanTriggerRescanTriggerPostFromJSON,
    ResponseRescanTriggerRescanTriggerPostToJSON,
    ResponseTriggerTriggerPostFromJSON,
    ResponseTriggerTriggerPostToJSON,
    ScoreCategoryFromJSON,
    ScoreCategoryToJSON,
    SearchFromJSON,
    SearchToJSON,
    SecReviewEntityFromJSON,
    SecReviewEntityToJSON,
    SecReviewExceptionsOutFromJSON,
    SecReviewExceptionsOutToJSON,
    SecReviewHTTPValidationErrorFromJSON,
    SecReviewHTTPValidationErrorToJSON,
    SecReviewInsuranceTriggerFromJSON,
    SecReviewInsuranceTriggerToJSON,
    SecReviewProcessFromJSON,
    SecReviewProcessToJSON,
    SecReviewRescanTriggerFromJSON,
    SecReviewRescanTriggerToJSON,
    SecReviewSREntityPagedResponseFromJSON,
    SecReviewSREntityPagedResponseToJSON,
    SecReviewSRExtScanPagedResponseFromJSON,
    SecReviewSRExtScanPagedResponseToJSON,
    SecReviewSRPagedResponseFromJSON,
    SecReviewSRPagedResponseToJSON,
    SecReviewStatsFromJSON,
    SecReviewStatsToJSON,
    SecReviewTriggerFromJSON,
    SecReviewTriggerToJSON,
    StatusFromJSON,
    StatusToJSON,
    SupplementalCreateFormInstanceRequestBodyFromJSON,
    SupplementalCreateFormInstanceRequestBodyToJSON,
    SupplementalCreateFormInstanceResponseFromJSON,
    SupplementalCreateFormInstanceResponseToJSON,
    SupplementalFormInstanceStatusFromJSON,
    SupplementalFormInstanceStatusToJSON,
    SupplementalFormTypeFromJSON,
    SupplementalFormTypeToJSON,
    SupplementalGetAccountQuotesResponseFromJSON,
    SupplementalGetAccountQuotesResponseToJSON,
    SupplementalGetInternalFormsByQuoteIdsResponseFromJSON,
    SupplementalGetInternalFormsByQuoteIdsResponseToJSON,
    SupplementalGetReportFormInstancesByFilterResponseFromJSON,
    SupplementalGetReportFormInstancesByFilterResponseToJSON,
    SupplementalGetReportFormInstancesByRecipientAccountResponseFromJSON,
    SupplementalGetReportFormInstancesByRecipientAccountResponseToJSON,
    SupplementalGetReportFormInstancesByRecipientIdResponseFromJSON,
    SupplementalGetReportFormInstancesByRecipientIdResponseToJSON,
    SupplementalPostFormInstanceInternalBodyFromJSON,
    SupplementalPostFormInstanceInternalBodyToJSON,
    SupplementalReenableFormInstanceRequestBodyFromJSON,
    SupplementalReenableFormInstanceRequestBodyToJSON,
    TagsFromJSON,
    TagsToJSON,
} from '../models';

export interface V1ClaimsWebClaimsClrRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsClsRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsContactsCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsContactsCreate2Request {
    claim_uuid: string;
    contact_id: string;
}

export interface V1ClaimsWebClaimsContactsDestroyRequest {
    claim_uuid: string;
    contact_id: string;
}

export interface V1ClaimsWebClaimsContactsRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsCoveragesCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsCoveragesRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsCreate2Request {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsDestroyRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsDocumentsCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsDocumentsRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsEventsCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsEventsDestroyRequest {
    claim_uuid: string;
    event_id: string;
}

export interface V1ClaimsWebClaimsEventsRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsLossesCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsLossesRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsPdfRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsRetrieve2Request {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsVendorsCreateRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebClaimsVendorsCreate2Request {
    claim_uuid: string;
    vendor_id: string;
}

export interface V1ClaimsWebClaimsVendorsDestroyRequest {
    claim_uuid: string;
    vendor_id: string;
}

export interface V1ClaimsWebClaimsVendorsRetrieveRequest {
    claim_uuid: string;
}

export interface V1ClaimsWebContactsCreateRequest {
    contact_id: string;
}

export interface V1ClaimsWebLossesCreateRequest {
    loss_id: string;
}

export interface V1ClaimsWebLossesDestroyRequest {
    loss_id: string;
}

export interface V1ClaimsWebPoliciesCreateRequest {
    policy_number: string;
}

export interface V1ClaimsWebPoliciesRetrieve2Request {
    policy_number: string;
}

export interface V1ClaimsWebVendorsCreateRequest {
    vendor_id: string;
}

export interface V1ControlWebAdminAssociateUserClientIdPostRequest {
    client_id: string;
    ControlAssociateUserRequest: ControlAssociateUserRequest;
}

export interface V1ControlWebAdminAssociateUsersClientIdPostRequest {
    client_id: string;
    ControlAssociateUsersRequest: ControlAssociateUsersRequest;
}

export interface V1ControlWebAdminClientUpdateClientIdPostRequest {
    client_id: string;
    ControlClientUpdateRequest: ControlClientUpdateRequest;
}

export interface V1ControlWebAdminCreateUserPutRequest {
    ControlAdminCreateUser: ControlAdminCreateUser;
}

export interface V1ControlWebAdminEntityAssetsReportCreateRequest {
    asset: string;
    entity_id: string;
}

export interface V1ControlWebAdminEntityEntityIdMutePostRequest {
    entity_id: string;
    ControlMuteRequestBodyAdmin: ControlMuteRequestBodyAdmin;
}

export interface V1ControlWebAdminEntityEntityIdRescanPostRequest {
    entity_id: string;
    ControlExplorerRescanVulnerabilities: ControlExplorerRescanVulnerabilities;
}

export interface V1ControlWebAdminEntityEntityIdResolutionsPostRequest {
    entity_id: string;
    ControlAdminResolutionRequestBody: ControlAdminResolutionRequestBody;
}

export interface V1ControlWebAdminEntityEntityIdRiskHistoryGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ControlWebAdminGetClientInfoClientIdGetRequest {
    client_id: string;
    request_body?: { [key: string]: string; };
}

export interface V1ControlWebAdminGrantRequestsGetRequest {
    page?: number;
    page_size?: number;
    client_id?: Array<string>;
    request_status?: Array<ControlGrantRequestStatus>;
    new_scope?: Array<ControlGrantScope>;
}

export interface V1ControlWebAdminGrantRequestsRequestIdPatchRequest {
    request_id: string;
    ControlAdminUpdateGrantRequestStatusRequest: ControlAdminUpdateGrantRequestStatusRequest;
}

export interface V1ControlWebAdminImpersonateUserIdPostRequest {
    user_id: string;
}

export interface V1ControlWebAdminInviteClientIdPostRequest {
    client_id: string;
    ControlUserInviteRequest: ControlUserInviteRequest;
}

export interface V1ControlWebAdminInviteDestroyRequest {
    client_id: string;
}

export interface V1ControlWebAdminResolutionsGetRequest {
    page?: number;
    page_size?: number;
    order_by?: ControlResolutionRequestOrderByMethods;
    desc?: boolean;
    name?: string;
}

export interface V1ControlWebAdminResolutionsRequestIdGetRequest {
    request_id: any;
}

export interface V1ControlWebAdminResolutionsRequestIdPostRequest {
    request_id: string;
    file: Blob;
}

export interface V1ControlWebAdminResolutionsRequestIdPutRequest {
    request_id: string;
    ControlResolutionStatusUpdateBody: ControlResolutionStatusUpdateBody;
}

export interface V1ControlWebAdminSearchGetRequest {
    search?: string;
    relation?: string;
    policy_number?: string;
    policy_uuid?: string;
    policyholder_email?: string;
    policyholder_uuid?: string;
    account_uuid?: string;
    page?: number;
    page_size?: number;
    query?: string;
    trials_only?: boolean;
}

export interface V1ControlWebAdminUpdateClientTrialClientIdPatchRequest {
    client_id: string;
    ControlTrialUpdateRequest: ControlTrialUpdateRequest;
}

export interface V1ControlWebAdminUpgradeUserIdPostRequest {
    user_id: string;
}

export interface V1ControlWebAdminUserDeletedGetRequest {
    page?: number;
    page_size?: number;
    email?: string;
    search?: string;
}

export interface V1ControlWebAdminUserGetRequest {
    page?: number;
    page_size?: number;
    email?: string;
    search?: string;
    active?: boolean;
    client_id?: string;
    order_by?: ControlUserOrderByMethods;
    desc?: boolean;
    include_invites?: boolean;
}

export interface V1ControlWebAdminUserUserIdAuthenticatorsDeleteRequest {
    user_id: string;
}

export interface V1ControlWebAdminUserUserIdAuthenticatorsGetRequest {
    user_id: string;
}

export interface V1ControlWebAdminUserUserIdDeleteRequest {
    user_id: string;
    ControlSoftDeleteUserRequest: ControlSoftDeleteUserRequest;
}

export interface V1ControlWebAdminUserUserIdRolesGetRequest {
    user_id: string;
}

export interface V1ControlWebAdminUserUserIdRolesPostRequest {
    user_id: string;
    ControlUserUpdateRoleRequest: ControlUserUpdateRoleRequest;
}

export interface V1ControlWebAdminUserUserIdUndeletePostRequest {
    user_id: string;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDeleteRequest {
    alert_id: string;
    client_id: string;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPutRequest {
    alert_id: string;
    client_id: string;
    ControlUpdateZeroDayAlertClientRequest: ControlUpdateZeroDayAlertClientRequest;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdClientsGetRequest {
    alert_id: string;
    page?: number;
    page_size?: number;
    resolution_status?: ControlZeroDayAlertResolutionStatusFilter;
    notified?: boolean;
    client_ids?: Array<string>;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdClientsPostRequest {
    alert_id: string;
    client_file: Blob;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdGetRequest {
    alert_id: string;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdNotificationsGetRequest {
    alert_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdNotificationsPostRequest {
    alert_id: string;
    ControlNotifyZeroDayAlertClientsRequest: ControlNotifyZeroDayAlertClientsRequest;
}

export interface V1ControlWebAdminZeroDayAlertAlertIdPutRequest {
    alert_id: string;
    ControlUpdateZeroDayAlertRequest: ControlUpdateZeroDayAlertRequest;
}

export interface V1ControlWebAdminZeroDayAlertGetRequest {
    page?: number;
    page_size?: number;
    q?: string;
}

export interface V1ControlWebAdminZeroDayAlertPostRequest {
    ControlCreateZeroDayAlertRequest: ControlCreateZeroDayAlertRequest;
}

export interface V1ControlWebAdminZeroDayAlertSendTestEmailPostRequest {
    ControlSendZeroDayAlertTestEmailRequest: ControlSendZeroDayAlertTestEmailRequest;
}

export interface V1CraWebCrsfEntityListGetRequest {
    scan_type?: Array<CRSFScanType>;
    query?: string;
    account_id?: string;
    quote_id?: string;
    issue?: string;
    page?: number;
    page_size?: number;
}

export interface V1CraWebCrsfEntityPdfRetrieveRequest {
    process_id: string;
}

export interface V1CraWebCrsfEntityProcessIdDataleaksGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdDomainsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdFindingsGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdGetRequest {
    process_id: string;
}

export interface V1CraWebCrsfEntityProcessIdHoneypotsGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdIpAddressesGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdRevokePostRequest {
    process_id: string;
}

export interface V1CraWebCrsfEntityProcessIdTechsGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityProcessIdTorrentsGetRequest {
    process_id: string;
    scan_type?: CRSFScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebCrsfEntityTriggerPostRequest {
    CRSFSrcHttpdlibCoreModelsCraTrigger: CRSFSrcHttpdlibCoreModelsCraTrigger;
}

export interface V1CraWebEntityEntityDomainDataleaksGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainDomainsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    blocklists?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainFindingsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    tags?: Array<string>;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1CraWebEntityEntityDomainHoneypotsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainIpAddressesGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    blocklists?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainListGetRequest {
    entity_domain: string;
    process_id?: string;
    page?: number;
    page_size?: number;
}

export interface V1CraWebEntityEntityDomainPdfGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1CraWebEntityEntityDomainRevokePostRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1CraWebEntityEntityDomainScansGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    cves?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
    no_data?: boolean;
}

export interface V1CraWebEntityEntityDomainStatsGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1CraWebEntityEntityDomainStatusGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1CraWebEntityEntityDomainTechsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    cves?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityEntityDomainTorrentsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1CraWebEntityExtscansRetrieveRequest {
    entity_domain: string;
}

export interface V1CraWebListGetRequest {
    query?: string;
    page?: number;
    page_size?: number;
}

export interface V1CraWebTriggerPostRequest {
    CRATrigger: CRATrigger;
}

export interface V1ExecriskWebScanLitigationsRetrieveRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdAlexaGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdBingnewsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdCompanyinfoGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdDocketalarmGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
    date_from?: string;
    date_to?: string;
    keywords?: string;
}

export interface V1ExecriskWebScanProcessIdEverifyGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdExperianGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdExperianLiensGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdForm990GetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdGlassdoorGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdGlassdoorRatingsGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdGlassdoorReviewsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
    date_from?: string;
    date_to?: string;
}

export interface V1ExecriskWebScanProcessIdIndustryGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdInvestorsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdLayoffsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdNewsArticlesGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdNewsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdPpploansGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdProximityoneGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdStatusGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdViolationsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdWatchlistGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdWaveGetRequest {
    process_id: string;
}

export interface V1ExecriskWebScanProcessIdWebhoseArticlesGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdWebhoseGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdZoominfoFundingsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdZoominfoGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdZoominfoOrgchartGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScanProcessIdZoominfoParentsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
}

export interface V1ExecriskWebScansGetRequest {
    page?: number;
    page_size?: number;
    query?: string;
    date_from?: number;
    date_to?: number;
}

export interface V1ExecriskWebTriggerPostRequest {
    ExecRiskTriggerRequest: ExecRiskTriggerRequest;
}

export interface V1MonitWebAccountResolutionsAccountLineUuidGetRequest {
    account_line_uuid: string;
    page?: number;
    page_size?: number;
    domain?: string;
    asset?: string;
    port?: string;
    datapoint?: string;
}

export interface V1MonitWebAccountResolutionsAccountLineUuidPostRequest {
    account_line_uuid: string;
    MonitAccountResolutionRequest?: MonitAccountResolutionRequest;
}

export interface V1MonitWebAssetsGetRequest {
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebAssetsReportedGetRequest {
    page?: number;
    page_size?: number;
    status?: Status;
    reason?: string;
    asset_type?: string;
    search?: Search;
    reviewed_by?: string;
    to?: string;
    from?: string;
    order_by?: MonitEntityReportedAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebAssetsReportedPutRequest {
    EXTERNAL_ID?: string;
    MonitRootUpdateAssetReportRequest?: MonitRootUpdateAssetReportRequest;
}

export interface V1MonitWebContactsContactIdDeleteRequest {
    contact_id: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebContactsContactIdGetRequest {
    contact_id: string;
    page?: number;
    page_size?: number;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebContactsContactIdPostRequest {
    contact_id: string;
    EXTERNAL_ID?: string;
    MonitRootAddUpdateContactRequest?: MonitRootAddUpdateContactRequest;
}

export interface V1MonitWebContactsGetRequest {
    page?: number;
    page_size?: number;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebContactsPostRequest {
    EXTERNAL_ID?: string;
    MonitRootAddUpdateContactRequest?: MonitRootAddUpdateContactRequest;
}

export interface V1MonitWebContactsSearchGetRequest {
    page?: number;
    page_size?: number;
    search?: string;
    contact_type?: ContactType;
    email?: string;
    external_ids?: ExternalIds;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebDomainsGetRequest {
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityAssetsHistoricalRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityAssetsScansRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityAssetsStatsRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityDomainsHistoricalRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityDomainsScansRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityDomainsStatsRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityEntityIdAssetsAssetFindingsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    entity_id2?: string;
    asset2?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdAssetsAssetGetRequest {
    entity_id: string;
    asset: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdAssetsAssetReportPostRequest {
    entity_id: string;
    asset: string;
    EXTERNAL_ID?: string;
    MonitReportAssetRequest?: MonitReportAssetRequest;
}

export interface V1MonitWebEntityEntityIdAssetsAssetTechsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    source_asset?: string;
    process_id?: string;
    order_by?: MonitEntityAssetTechOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdAssetsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdAssetsReportedGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    status?: Status;
    reason?: string;
    asset_type?: string;
    search?: Search;
    reviewed_by?: string;
    to?: string;
    from?: string;
    order_by?: MonitEntityReportedAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDataleaksCountsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    source_asset?: string;
    order_by?: MonitEntityDataLeaksOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDataleaksGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    source_asset?: string;
    order_by?: MonitEntityDataLeaksOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDomainsAssetFindingsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    entity_id2?: string;
    asset2?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDomainsAssetGetRequest {
    entity_id: string;
    asset: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDomainsAssetTechsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    source_asset?: string;
    process_id?: string;
    order_by?: MonitEntityAssetTechOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdDomainsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdFindingsAggregateGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    exclude_reported?: boolean;
    samples?: number;
    datapoint?: string;
    show_muted_status?: boolean;
    process_id?: string;
    level?: Level;
    category?: string;
    score_category?: string;
    name?: string;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    muted_status?: MonitResolutionRequestStatus;
    first_detected_from?: string;
    first_detected_to?: string;
    last_detected_from?: string;
    last_detected_to?: string;
    search?: string;
    n_assets?: MonitImpactedAssetsInterval;
    resolution_types?: ResolutionTypes;
    no_actions?: boolean;
    contingency_status?: boolean;
    is_contingency?: boolean;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdFindingsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    entity_id2?: string;
    asset?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdFindingsTopGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    entity_id2?: string;
    asset?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdGetRequest {
    entity_id: string;
}

export interface V1MonitWebEntityEntityIdHoneypotsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    process_id?: string;
    tags?: Tags;
    source_asset?: string;
    search?: string;
    asset?: string;
    order_by?: MonitEntityHoneypotsOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdIpAddressesAssetFindingsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    entity_id2?: string;
    asset2?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdIpAddressesAssetGetRequest {
    entity_id: string;
    asset: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdIpAddressesAssetTechsGetRequest {
    entity_id: string;
    asset: string;
    page?: number;
    page_size?: number;
    source_asset?: string;
    process_id?: string;
    order_by?: MonitEntityAssetTechOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdIpAddressesGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdKeywordsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    process_id?: string;
    search?: string;
    order_by?: MonitEntityKeywordOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdLookalikesGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    search?: string;
    process_id?: string;
    mx?: boolean;
    ssl?: boolean;
    domain?: string;
    order_by?: MonitEntityLookalikesOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdMappingGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    domain?: string;
    type?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdNotificationsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    from?: string;
    to?: string;
    notification_type?: string;
    search?: string;
    recipient?: string;
    contact_type?: string;
    entity_id2?: string;
    external_id?: string;
    include_content?: string;
    order_by?: MonitEntityNotificationsOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdPastebinsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    order_by?: MonitEntityPastebinOrderBy;
    desc?: boolean;
    search?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdRemovePostRequest {
    entity_id: string;
    EXTERNAL_ID?: string;
    MonitFindingResolutionRequest?: MonitFindingResolutionRequest;
}

export interface V1MonitWebEntityEntityIdRevokeGetRequest {
    entity_id: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdStatsByDayGetRequest {
    entity_id: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdStatsGetRequest {
    entity_id: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdStatsIssuesFoundGetRequest {
    entity_id: string;
    process_id?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdTechsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    process_id?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    vuln?: boolean;
    shared?: boolean;
    trim?: boolean;
    tech?: string;
    order_by?: MonitEntityTechOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdTorrentsGetRequest {
    entity_id: string;
    page?: number;
    page_size?: number;
    process_id?: string;
    source_asset?: string;
    search?: string;
    order_by?: MonitEntityTorrentsOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityEntityIdTriggerGetRequest {
    entity_id: string;
    nocache?: boolean;
    trial?: boolean;
    quote_uuid?: string;
    lite_enum?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityFindingsCreateRequest {
    entity_id: string;
    finding_id: string;
}

export interface V1MonitWebEntityGraphRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebEntityHistoricalCreateRequest {
    entity_id: string;
    finding_id: string;
}

export interface V1MonitWebEntityHistoricalRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebEntityHistoricalTopRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebEntityIpAddressesHistoricalRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityIpAddressesScansRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityIpAddressesStatsRetrieveRequest {
    asset: string;
    entity_id: string;
}

export interface V1MonitWebEntityMappingGetRequest {
    page?: number;
    page_size?: number;
    domain?: string;
    type?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityNodomainGetRequest {
    entity_name?: string;
    location?: string;
    industry?: string;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebEntityScansRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebEntitySocialRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebEntitySpidersRetrieveRequest {
    entity_id: string;
}

export interface V1MonitWebIpAddressesGetRequest {
    page?: number;
    page_size?: number;
    tech?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    asset?: string;
    process_id?: string;
    shared?: boolean;
    domain?: string;
    ip_address?: string;
    count_findings?: boolean;
    count_findings_filter?: CountFindingsFilter;
    max_level?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    source?: MonitEntityAssetSource;
    last_detected_from?: string;
    last_detected_to?: string;
    dns_types?: DnsTypes;
    as_name?: AsName;
    order_by?: MonitEntityAssetOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebNotificationsGetRequest {
    page?: number;
    page_size?: number;
    from?: string;
    to?: string;
    notification_type?: string;
    search?: string;
    recipient?: string;
    contact_type?: string;
    entity_id?: string;
    external_id?: string;
    include_content?: string;
    order_by?: MonitEntityNotificationsOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebRelatedFindingsGetRequest {
    page?: number;
    page_size?: number;
    entity_id?: string;
    asset?: string;
    source_asset?: string;
    level?: Level;
    category?: Category;
    score_category?: ScoreCategory;
    search?: Search;
    tags?: Array<string>;
    datapoint?: Datapoint;
    status?: string;
    process_id?: string;
    shared?: boolean;
    trim?: boolean;
    filters_only?: string;
    desc?: boolean;
    count_findings?: boolean;
    exclude_removed?: boolean;
    exclude_ignored?: boolean;
    resolution_types?: ResolutionTypes;
    locale?: MonitLocale;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebRelatedGetRequest {
    page?: number;
    page_size?: number;
    monit_type?: MonitType;
    search?: string;
    entity_domain?: string;
    entity_id?: EntityId;
    exclude_entity_id?: ExcludeEntityId;
    order_by?: MonitEntityRelationOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebRelatedPolicyholdersGetRequest {
    page?: number;
    page_size?: number;
    monit_type?: MonitType;
    search?: string;
    entity_domain?: string;
    entity_id?: EntityId;
    exclude_entity_id?: ExcludeEntityId;
    order_by?: MonitEntityRelationOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1MonitWebRelatedStatsGetRequest {
    EXTERNAL_ID?: string;
}

export interface V1MonitWebTechsGetRequest {
    page?: number;
    page_size?: number;
    process_id?: string;
    category?: Category;
    source_asset?: string;
    search?: string;
    vuln?: boolean;
    shared?: boolean;
    trim?: boolean;
    tech?: string;
    order_by?: MonitEntityTechOrderBy;
    desc?: boolean;
    EXTERNAL_ID?: string;
}

export interface V1NotificationsWebAlertsGetRequest {
    page_size?: number;
    from?: string;
    entity_id?: string;
    page?: number;
}

export interface V1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDeleteRequest {
    filter_target: NotificationsFilterTarget;
    filter_value: string;
    filter_code: NotificationsUserFilterCode;
}

export interface V1NotificationsWebFiltersFilterTargetFilterValueFilterCodePutRequest {
    filter_target: NotificationsFilterTarget;
    filter_value: string;
    filter_code: NotificationsUserFilterCode;
}

export interface V1NotificationsWebFiltersFilterTargetTargetValueGetRequest {
    filter_target: NotificationsFilterTarget;
    target_value: string;
}

export interface V1NotificationsWebRecordsGetRequest {
    page?: number;
    page_size?: number;
    from?: string;
    to?: string;
    search?: string;
    client_id?: string;
    recipient?: string;
    entity_id?: string;
    entity_name?: string;
    contact_type?: NotificationsContactType;
    record_type?: string;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewDocumentsProcessIdCsvCsvGetRequest {
    process_id: string;
    csv: string;
}

export interface V1SecreviewDocumentsProcessIdIndexGetRequest {
    process_id: string;
}

export interface V1SecreviewDocumentsProcessIdSecAnalysisCommentGetRequest {
    process_id: string;
}

export interface V1SecreviewDocumentsProcessIdTxtDomainGetRequest {
    process_id: string;
    domain: string;
}

export interface V1SecreviewWebCrsfEntityListGetRequest {
    scan_type?: Array<CRSFSecReviewScanType>;
    query?: string;
    account_id?: string;
    quote_id?: string;
    issue?: string;
    page?: number;
    page_size?: number;
}

export interface V1SecreviewWebCrsfEntityProcessIdDataleaksGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdDomainsGetRequest {
    process_id: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdFindingsGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdGetRequest {
    process_id: string;
}

export interface V1SecreviewWebCrsfEntityProcessIdHoneypotsGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdIpAddressesGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdRevokePostRequest {
    process_id: string;
}

export interface V1SecreviewWebCrsfEntityProcessIdTechsGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityProcessIdTorrentsGetRequest {
    process_id: string;
    scan_type?: CRSFSecReviewScanType;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebCrsfEntityRescanTriggerPostRequest {
    CRSFSecReviewValidationScanTrigger: CRSFSecReviewValidationScanTrigger;
}

export interface V1SecreviewWebCrsfEntityTriggerIssuePostRequest {
    CRSFSecReviewTriggerScanIssueRequest: CRSFSecReviewTriggerScanIssueRequest;
}

export interface V1SecreviewWebCrsfEntityTriggerNoIssuePostRequest {
    CRSFSecReviewTriggerNoIssue: CRSFSecReviewTriggerNoIssue;
}

export interface V1SecreviewWebEntityEntityDomainCommentGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainDataleaksGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainDomainsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    blocklists?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainExtscansGetRequest {
    entity_domain: string;
    ext_type?: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainFindingsGetRequest {
    entity_domain: string;
    data_type?: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    tags?: Array<string>;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainGetRequest {
    entity_domain: string;
    process_id?: string;
    issue?: string;
}

export interface V1SecreviewWebEntityEntityDomainHoneypotsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainIndexGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainIpAddressesGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    blocklists?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainListGetRequest {
    entity_domain: string;
    process_id?: string;
    issue?: string;
    page?: number;
    page_size?: number;
}

export interface V1SecreviewWebEntityEntityDomainPostedDecisionsJsonGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainReportGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainRevokePostRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainScansGetRequest {
    entity_domain: string;
    data_type?: string;
    query?: string;
    process_id?: string;
    cves?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
    no_data?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainStatsGetRequest {
    entity_domain: string;
    data_type?: string;
    process_id?: string;
}

export interface V1SecreviewWebEntityEntityDomainStatusGetRequest {
    entity_domain: string;
    process_id?: string;
    issue?: string;
}

export interface V1SecreviewWebEntityEntityDomainTechsGetRequest {
    entity_domain: string;
    data_type?: string;
    query?: string;
    process_id?: string;
    cves?: boolean;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainTorrentsGetRequest {
    entity_domain: string;
    query?: string;
    process_id?: string;
    page?: number;
    page_size?: number;
    order_by?: string;
    desc?: boolean;
}

export interface V1SecreviewWebEntityEntityDomainTxtGetRequest {
    entity_domain: string;
    process_id?: string;
}

export interface V1SecreviewWebInsuranceTriggerPostRequest {
    SecReviewInsuranceTrigger: SecReviewInsuranceTrigger;
}

export interface V1SecreviewWebIssuesGetRequest {
    issue?: string;
    page?: number;
    page_size?: number;
}

export interface V1SecreviewWebListGetRequest {
    issue?: string;
    query?: string;
    page?: number;
    page_size?: number;
}

export interface V1SecreviewWebRescanTriggerPostRequest {
    SecReviewRescanTrigger: SecReviewRescanTrigger;
}

export interface V1SecreviewWebTriggerPostRequest {
    SecReviewTrigger: SecReviewTrigger;
}

export interface V1SrWebCrsfEntityPdfRetrieveRequest {
    process_id: string;
}

export interface V1SupplementalsWebInternalAttachmentAttachmentIdPdfGetRequest {
    attachment_id: string;
}

export interface V1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPostRequest {
    form_instance_id: string;
    SupplementalPostFormInstanceInternalBody?: SupplementalPostFormInstanceInternalBody;
}

export interface V1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGetRequest {
    form_instance_id: string;
}

export interface V1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePostRequest {
    form_instance_id: string;
    SupplementalReenableFormInstanceRequestBody?: SupplementalReenableFormInstanceRequestBody;
}

export interface V1SupplementalsWebInternalFormInstancePartialUpdateRequest {
    form_instance_id: string;
}

export interface V1SupplementalsWebInternalFormInstancePostRequest {
    SupplementalCreateFormInstanceRequestBody?: SupplementalCreateFormInstanceRequestBody;
}

export interface V1SupplementalsWebInternalFormInstanceSearchGetRequest {
    quote_uuids?: Array<string>;
    form_types?: Array<SupplementalFormType>;
    account_uuids?: Array<string>;
    account_line_uuids?: Array<string>;
    include_form_instances?: boolean;
    include_attachments?: boolean;
    form_statuses?: Array<SupplementalFormInstanceStatus>;
    page?: number;
    limit?: number;
}

export interface V1SupplementalsWebInternalFormsCompletedGetRequest {
    quote_uids?: Array<string>;
    account_uids?: Array<string>;
    form_types?: Array<SupplementalFormType>;
    page?: number;
    limit?: number;
}

export interface V1SupplementalsWebInternalRecipientAccountAccountIdGetRequest {
    account_id: string;
    form_statuses?: Array<SupplementalFormInstanceStatus>;
    form_types?: Array<SupplementalFormType>;
    quote_uuids?: Array<string>;
    page?: number;
    limit?: number;
}

export interface V1SupplementalsWebInternalRecipientAccountAccountIdQuotesGetRequest {
    account_id: string;
}

export interface V1SupplementalsWebInternalRecipientRecipientIdFormsGetRequest {
    recipient_id: string;
    page?: number;
    limit?: number;
}

/**
 * 
 */
export class V1Api extends runtime.BaseAPI {

    /**
     */
    async v1ClaimsWebAccountsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebAccountsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebAccountsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsClrRetrieveRaw(requestParameters: V1ClaimsWebClaimsClrRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsClrRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/clr`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsClrRetrieve(requestParameters: V1ClaimsWebClaimsClrRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsClrRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsClsRetrieveRaw(requestParameters: V1ClaimsWebClaimsClsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsClsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/cls`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsClsRetrieve(requestParameters: V1ClaimsWebClaimsClsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsClsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsCreateRaw(requestParameters: V1ClaimsWebClaimsContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsContactsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/contacts`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsCreate(requestParameters: V1ClaimsWebClaimsContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsContactsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsCreate2Raw(requestParameters: V1ClaimsWebClaimsContactsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsContactsCreate2.');
        }

        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1ClaimsWebClaimsContactsCreate2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/contacts/{contact_id}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))).replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsCreate2(requestParameters: V1ClaimsWebClaimsContactsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsContactsCreate2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsDestroyRaw(requestParameters: V1ClaimsWebClaimsContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsContactsDestroy.');
        }

        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1ClaimsWebClaimsContactsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/contacts/{contact_id}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))).replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsDestroy(requestParameters: V1ClaimsWebClaimsContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsContactsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsRetrieveRaw(requestParameters: V1ClaimsWebClaimsContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsContactsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/contacts`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsContactsRetrieve(requestParameters: V1ClaimsWebClaimsContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsContactsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsCoveragesCreateRaw(requestParameters: V1ClaimsWebClaimsCoveragesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsCoveragesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/coverages`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsCoveragesCreate(requestParameters: V1ClaimsWebClaimsCoveragesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsCoveragesCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsCoveragesRetrieveRaw(requestParameters: V1ClaimsWebClaimsCoveragesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsCoveragesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/coverages`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsCoveragesRetrieve(requestParameters: V1ClaimsWebClaimsCoveragesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsCoveragesRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsCreateRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsCreate2Raw(requestParameters: V1ClaimsWebClaimsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsCreate2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsCreate2(requestParameters: V1ClaimsWebClaimsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsCreate2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsDestroyRaw(requestParameters: V1ClaimsWebClaimsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsDestroy(requestParameters: V1ClaimsWebClaimsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsDocumentsCreateRaw(requestParameters: V1ClaimsWebClaimsDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsDocumentsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/documents`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsDocumentsCreate(requestParameters: V1ClaimsWebClaimsDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsDocumentsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsDocumentsRetrieveRaw(requestParameters: V1ClaimsWebClaimsDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsDocumentsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/documents`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsDocumentsRetrieve(requestParameters: V1ClaimsWebClaimsDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsDocumentsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsCreateRaw(requestParameters: V1ClaimsWebClaimsEventsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsEventsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/events`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsCreate(requestParameters: V1ClaimsWebClaimsEventsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsEventsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsDestroyRaw(requestParameters: V1ClaimsWebClaimsEventsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsEventsDestroy.');
        }

        if (requestParameters.event_id === null || requestParameters.event_id === undefined) {
            throw new runtime.RequiredError('event_id','Required parameter requestParameters.event_id was null or undefined when calling v1ClaimsWebClaimsEventsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/events/{event_id}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))).replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters.event_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsDestroy(requestParameters: V1ClaimsWebClaimsEventsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsEventsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsRetrieveRaw(requestParameters: V1ClaimsWebClaimsEventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsEventsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/events`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsEventsRetrieve(requestParameters: V1ClaimsWebClaimsEventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsEventsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsLossesCreateRaw(requestParameters: V1ClaimsWebClaimsLossesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsLossesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/losses`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsLossesCreate(requestParameters: V1ClaimsWebClaimsLossesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsLossesCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsLossesRetrieveRaw(requestParameters: V1ClaimsWebClaimsLossesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsLossesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/losses`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsLossesRetrieve(requestParameters: V1ClaimsWebClaimsLossesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsLossesRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsPdfRetrieveRaw(requestParameters: V1ClaimsWebClaimsPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsPdfRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/pdf`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsPdfRetrieve(requestParameters: V1ClaimsWebClaimsPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsPdfRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsRetrieve2Raw(requestParameters: V1ClaimsWebClaimsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsRetrieve2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsRetrieve2(requestParameters: V1ClaimsWebClaimsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsRetrieve2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsCreateRaw(requestParameters: V1ClaimsWebClaimsVendorsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsVendorsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/vendors`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsCreate(requestParameters: V1ClaimsWebClaimsVendorsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsVendorsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsCreate2Raw(requestParameters: V1ClaimsWebClaimsVendorsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsVendorsCreate2.');
        }

        if (requestParameters.vendor_id === null || requestParameters.vendor_id === undefined) {
            throw new runtime.RequiredError('vendor_id','Required parameter requestParameters.vendor_id was null or undefined when calling v1ClaimsWebClaimsVendorsCreate2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/vendors/{vendor_id}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))).replace(`{${"vendor_id"}}`, encodeURIComponent(String(requestParameters.vendor_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsCreate2(requestParameters: V1ClaimsWebClaimsVendorsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsVendorsCreate2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsDestroyRaw(requestParameters: V1ClaimsWebClaimsVendorsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsVendorsDestroy.');
        }

        if (requestParameters.vendor_id === null || requestParameters.vendor_id === undefined) {
            throw new runtime.RequiredError('vendor_id','Required parameter requestParameters.vendor_id was null or undefined when calling v1ClaimsWebClaimsVendorsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/vendors/{vendor_id}`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))).replace(`{${"vendor_id"}}`, encodeURIComponent(String(requestParameters.vendor_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsDestroy(requestParameters: V1ClaimsWebClaimsVendorsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsVendorsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsRetrieveRaw(requestParameters: V1ClaimsWebClaimsVendorsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claim_uuid === null || requestParameters.claim_uuid === undefined) {
            throw new runtime.RequiredError('claim_uuid','Required parameter requestParameters.claim_uuid was null or undefined when calling v1ClaimsWebClaimsVendorsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/claims/{claim_uuid}/vendors`.replace(`{${"claim_uuid"}}`, encodeURIComponent(String(requestParameters.claim_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClaimsVendorsRetrieve(requestParameters: V1ClaimsWebClaimsVendorsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClaimsVendorsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebClrCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/clr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebClrCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebClrCreateRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebContactsCreateRaw(requestParameters: V1ClaimsWebContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1ClaimsWebContactsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebContactsCreate(requestParameters: V1ClaimsWebContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebContactsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebContactsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebContactsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebContactsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebLossesCreateRaw(requestParameters: V1ClaimsWebLossesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.loss_id === null || requestParameters.loss_id === undefined) {
            throw new runtime.RequiredError('loss_id','Required parameter requestParameters.loss_id was null or undefined when calling v1ClaimsWebLossesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/losses/{loss_id}`.replace(`{${"loss_id"}}`, encodeURIComponent(String(requestParameters.loss_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebLossesCreate(requestParameters: V1ClaimsWebLossesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebLossesCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebLossesDestroyRaw(requestParameters: V1ClaimsWebLossesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.loss_id === null || requestParameters.loss_id === undefined) {
            throw new runtime.RequiredError('loss_id','Required parameter requestParameters.loss_id was null or undefined when calling v1ClaimsWebLossesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/losses/{loss_id}`.replace(`{${"loss_id"}}`, encodeURIComponent(String(requestParameters.loss_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebLossesDestroy(requestParameters: V1ClaimsWebLossesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebLossesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Handler method for HTTP \'OPTIONS\' request.
     */
    async v1ClaimsWebOptionsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handler method for HTTP \'OPTIONS\' request.
     */
    async v1ClaimsWebOptionsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebOptionsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebPoliciesCreateRaw(requestParameters: V1ClaimsWebPoliciesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.policy_number === null || requestParameters.policy_number === undefined) {
            throw new runtime.RequiredError('policy_number','Required parameter requestParameters.policy_number was null or undefined when calling v1ClaimsWebPoliciesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/policies/{policy_number}`.replace(`{${"policy_number"}}`, encodeURIComponent(String(requestParameters.policy_number))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebPoliciesCreate(requestParameters: V1ClaimsWebPoliciesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebPoliciesCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebPoliciesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebPoliciesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebPoliciesRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ClaimsWebPoliciesRetrieve2Raw(requestParameters: V1ClaimsWebPoliciesRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.policy_number === null || requestParameters.policy_number === undefined) {
            throw new runtime.RequiredError('policy_number','Required parameter requestParameters.policy_number was null or undefined when calling v1ClaimsWebPoliciesRetrieve2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/policies/{policy_number}`.replace(`{${"policy_number"}}`, encodeURIComponent(String(requestParameters.policy_number))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebPoliciesRetrieve2(requestParameters: V1ClaimsWebPoliciesRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebPoliciesRetrieve2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebVendorsCreateRaw(requestParameters: V1ClaimsWebVendorsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vendor_id === null || requestParameters.vendor_id === undefined) {
            throw new runtime.RequiredError('vendor_id','Required parameter requestParameters.vendor_id was null or undefined when calling v1ClaimsWebVendorsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/vendors/{vendor_id}`.replace(`{${"vendor_id"}}`, encodeURIComponent(String(requestParameters.vendor_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebVendorsCreate(requestParameters: V1ClaimsWebVendorsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebVendorsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1ClaimsWebVendorsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/claims/web/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ClaimsWebVendorsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ClaimsWebVendorsRetrieveRaw(initOverrides);
    }

    /**
     * Associate User With Client
     */
    async v1ControlWebAdminAssociateUserClientIdPostRaw(requestParameters: V1ControlWebAdminAssociateUserClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlUserResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminAssociateUserClientIdPost.');
        }

        if (requestParameters.ControlAssociateUserRequest === null || requestParameters.ControlAssociateUserRequest === undefined) {
            throw new runtime.RequiredError('ControlAssociateUserRequest','Required parameter requestParameters.ControlAssociateUserRequest was null or undefined when calling v1ControlWebAdminAssociateUserClientIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/associate-user/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlAssociateUserRequestToJSON(requestParameters.ControlAssociateUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlUserResponseFromJSON(jsonValue));
    }

    /**
     * Associate User With Client
     */
    async v1ControlWebAdminAssociateUserClientIdPost(requestParameters: V1ControlWebAdminAssociateUserClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlUserResponse> {
        const response = await this.v1ControlWebAdminAssociateUserClientIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Associate Users With Client
     */
    async v1ControlWebAdminAssociateUsersClientIdPostRaw(requestParameters: V1ControlWebAdminAssociateUsersClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAssociateUsersResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminAssociateUsersClientIdPost.');
        }

        if (requestParameters.ControlAssociateUsersRequest === null || requestParameters.ControlAssociateUsersRequest === undefined) {
            throw new runtime.RequiredError('ControlAssociateUsersRequest','Required parameter requestParameters.ControlAssociateUsersRequest was null or undefined when calling v1ControlWebAdminAssociateUsersClientIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/associate-users/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlAssociateUsersRequestToJSON(requestParameters.ControlAssociateUsersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAssociateUsersResponseFromJSON(jsonValue));
    }

    /**
     * Associate Users With Client
     */
    async v1ControlWebAdminAssociateUsersClientIdPost(requestParameters: V1ControlWebAdminAssociateUsersClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAssociateUsersResponse> {
        const response = await this.v1ControlWebAdminAssociateUsersClientIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Client
     */
    async v1ControlWebAdminClientUpdateClientIdPostRaw(requestParameters: V1ControlWebAdminClientUpdateClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAppSchemasUserClientResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminClientUpdateClientIdPost.');
        }

        if (requestParameters.ControlClientUpdateRequest === null || requestParameters.ControlClientUpdateRequest === undefined) {
            throw new runtime.RequiredError('ControlClientUpdateRequest','Required parameter requestParameters.ControlClientUpdateRequest was null or undefined when calling v1ControlWebAdminClientUpdateClientIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/client-update/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlClientUpdateRequestToJSON(requestParameters.ControlClientUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAppSchemasUserClientResponseFromJSON(jsonValue));
    }

    /**
     * Update Client
     */
    async v1ControlWebAdminClientUpdateClientIdPost(requestParameters: V1ControlWebAdminClientUpdateClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAppSchemasUserClientResponse> {
        const response = await this.v1ControlWebAdminClientUpdateClientIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create User
     */
    async v1ControlWebAdminCreateUserPutRaw(requestParameters: V1ControlWebAdminCreateUserPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAdminCreateUserResponse>> {
        if (requestParameters.ControlAdminCreateUser === null || requestParameters.ControlAdminCreateUser === undefined) {
            throw new runtime.RequiredError('ControlAdminCreateUser','Required parameter requestParameters.ControlAdminCreateUser was null or undefined when calling v1ControlWebAdminCreateUserPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/create-user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlAdminCreateUserToJSON(requestParameters.ControlAdminCreateUser),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAdminCreateUserResponseFromJSON(jsonValue));
    }

    /**
     * Create User
     */
    async v1ControlWebAdminCreateUserPut(requestParameters: V1ControlWebAdminCreateUserPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAdminCreateUserResponse> {
        const response = await this.v1ControlWebAdminCreateUserPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1ControlWebAdminEntityAssetsReportCreateRaw(requestParameters: V1ControlWebAdminEntityAssetsReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1ControlWebAdminEntityAssetsReportCreate.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1ControlWebAdminEntityAssetsReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/entity/{entity_id}/assets/{asset}/report`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ControlWebAdminEntityAssetsReportCreate(requestParameters: V1ControlWebAdminEntityAssetsReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ControlWebAdminEntityAssetsReportCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Admin Mute Request
     */
    async v1ControlWebAdminEntityEntityIdMutePostRaw(requestParameters: V1ControlWebAdminEntityEntityIdMutePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlMuteRequestResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1ControlWebAdminEntityEntityIdMutePost.');
        }

        if (requestParameters.ControlMuteRequestBodyAdmin === null || requestParameters.ControlMuteRequestBodyAdmin === undefined) {
            throw new runtime.RequiredError('ControlMuteRequestBodyAdmin','Required parameter requestParameters.ControlMuteRequestBodyAdmin was null or undefined when calling v1ControlWebAdminEntityEntityIdMutePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/entity/{entity_id}/mute`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlMuteRequestBodyAdminToJSON(requestParameters.ControlMuteRequestBodyAdmin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlMuteRequestResponseFromJSON(jsonValue));
    }

    /**
     * Admin Mute Request
     */
    async v1ControlWebAdminEntityEntityIdMutePost(requestParameters: V1ControlWebAdminEntityEntityIdMutePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlMuteRequestResponse> {
        const response = await this.v1ControlWebAdminEntityEntityIdMutePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Entity Rescan
     */
    async v1ControlWebAdminEntityEntityIdRescanPostRaw(requestParameters: V1ControlWebAdminEntityEntityIdRescanPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1ControlWebAdminEntityEntityIdRescanPost.');
        }

        if (requestParameters.ControlExplorerRescanVulnerabilities === null || requestParameters.ControlExplorerRescanVulnerabilities === undefined) {
            throw new runtime.RequiredError('ControlExplorerRescanVulnerabilities','Required parameter requestParameters.ControlExplorerRescanVulnerabilities was null or undefined when calling v1ControlWebAdminEntityEntityIdRescanPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/entity/{entity_id}/rescan`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlExplorerRescanVulnerabilitiesToJSON(requestParameters.ControlExplorerRescanVulnerabilities),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Entity Rescan
     */
    async v1ControlWebAdminEntityEntityIdRescanPost(requestParameters: V1ControlWebAdminEntityEntityIdRescanPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminEntityEntityIdRescanPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Create Resolution Request
     */
    async v1ControlWebAdminEntityEntityIdResolutionsPostRaw(requestParameters: V1ControlWebAdminEntityEntityIdResolutionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResolutionRequestResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1ControlWebAdminEntityEntityIdResolutionsPost.');
        }

        if (requestParameters.ControlAdminResolutionRequestBody === null || requestParameters.ControlAdminResolutionRequestBody === undefined) {
            throw new runtime.RequiredError('ControlAdminResolutionRequestBody','Required parameter requestParameters.ControlAdminResolutionRequestBody was null or undefined when calling v1ControlWebAdminEntityEntityIdResolutionsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/entity/{entity_id}/resolutions`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlAdminResolutionRequestBodyToJSON(requestParameters.ControlAdminResolutionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResolutionRequestResponseFromJSON(jsonValue));
    }

    /**
     * Admin Create Resolution Request
     */
    async v1ControlWebAdminEntityEntityIdResolutionsPost(requestParameters: V1ControlWebAdminEntityEntityIdResolutionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResolutionRequestResponse> {
        const response = await this.v1ControlWebAdminEntityEntityIdResolutionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Admin Risk History
     */
    async v1ControlWebAdminEntityEntityIdRiskHistoryGetRaw(requestParameters: V1ControlWebAdminEntityEntityIdRiskHistoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1ControlWebAdminEntityEntityIdRiskHistoryGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/entity/{entity_id}/risk_history`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Admin Risk History
     */
    async v1ControlWebAdminEntityEntityIdRiskHistoryGet(requestParameters: V1ControlWebAdminEntityEntityIdRiskHistoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminEntityEntityIdRiskHistoryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Client Info
     */
    async v1ControlWebAdminGetClientInfoClientIdGetRaw(requestParameters: V1ControlWebAdminGetClientInfoClientIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlClientInfoResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminGetClientInfoClientIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/get-client-info/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlClientInfoResponseFromJSON(jsonValue));
    }

    /**
     * Get Client Info
     */
    async v1ControlWebAdminGetClientInfoClientIdGet(requestParameters: V1ControlWebAdminGetClientInfoClientIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlClientInfoResponse> {
        const response = await this.v1ControlWebAdminGetClientInfoClientIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Grant Requests
     */
    async v1ControlWebAdminGrantRequestsGetRaw(requestParameters: V1ControlWebAdminGrantRequestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlPaginatedAdminGrantRequestsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.client_id) {
            queryParameters['client_id'] = requestParameters.client_id;
        }

        if (requestParameters.request_status) {
            queryParameters['request_status'] = requestParameters.request_status;
        }

        if (requestParameters.new_scope) {
            queryParameters['new_scope'] = requestParameters.new_scope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/grant-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlPaginatedAdminGrantRequestsResponseFromJSON(jsonValue));
    }

    /**
     * Get Grant Requests
     */
    async v1ControlWebAdminGrantRequestsGet(requestParameters: V1ControlWebAdminGrantRequestsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlPaginatedAdminGrantRequestsResponse> {
        const response = await this.v1ControlWebAdminGrantRequestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Grant Request Status
     */
    async v1ControlWebAdminGrantRequestsRequestIdPatchRaw(requestParameters: V1ControlWebAdminGrantRequestsRequestIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAdminUpdateGrantRequestStatusResponse>> {
        if (requestParameters.request_id === null || requestParameters.request_id === undefined) {
            throw new runtime.RequiredError('request_id','Required parameter requestParameters.request_id was null or undefined when calling v1ControlWebAdminGrantRequestsRequestIdPatch.');
        }

        if (requestParameters.ControlAdminUpdateGrantRequestStatusRequest === null || requestParameters.ControlAdminUpdateGrantRequestStatusRequest === undefined) {
            throw new runtime.RequiredError('ControlAdminUpdateGrantRequestStatusRequest','Required parameter requestParameters.ControlAdminUpdateGrantRequestStatusRequest was null or undefined when calling v1ControlWebAdminGrantRequestsRequestIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/grant-requests/{request_id}`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.request_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ControlAdminUpdateGrantRequestStatusRequestToJSON(requestParameters.ControlAdminUpdateGrantRequestStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAdminUpdateGrantRequestStatusResponseFromJSON(jsonValue));
    }

    /**
     * Update Grant Request Status
     */
    async v1ControlWebAdminGrantRequestsRequestIdPatch(requestParameters: V1ControlWebAdminGrantRequestsRequestIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAdminUpdateGrantRequestStatusResponse> {
        const response = await this.v1ControlWebAdminGrantRequestsRequestIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Impersonate
     */
    async v1ControlWebAdminImpersonateUserIdPostRaw(requestParameters: V1ControlWebAdminImpersonateUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlImpersonateOTTResponse>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminImpersonateUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/impersonate/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlImpersonateOTTResponseFromJSON(jsonValue));
    }

    /**
     * Impersonate
     */
    async v1ControlWebAdminImpersonateUserIdPost(requestParameters: V1ControlWebAdminImpersonateUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlImpersonateOTTResponse> {
        const response = await this.v1ControlWebAdminImpersonateUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Invite
     */
    async v1ControlWebAdminInviteClientIdPostRaw(requestParameters: V1ControlWebAdminInviteClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAdminInviteResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminInviteClientIdPost.');
        }

        if (requestParameters.ControlUserInviteRequest === null || requestParameters.ControlUserInviteRequest === undefined) {
            throw new runtime.RequiredError('ControlUserInviteRequest','Required parameter requestParameters.ControlUserInviteRequest was null or undefined when calling v1ControlWebAdminInviteClientIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/invite/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlUserInviteRequestToJSON(requestParameters.ControlUserInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAdminInviteResponseFromJSON(jsonValue));
    }

    /**
     * Admin Invite
     */
    async v1ControlWebAdminInviteClientIdPost(requestParameters: V1ControlWebAdminInviteClientIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAdminInviteResponse> {
        const response = await this.v1ControlWebAdminInviteClientIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1ControlWebAdminInviteDestroyRaw(requestParameters: V1ControlWebAdminInviteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminInviteDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/invite/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ControlWebAdminInviteDestroy(requestParameters: V1ControlWebAdminInviteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ControlWebAdminInviteDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Admin Get Resolution Requests
     */
    async v1ControlWebAdminResolutionsGetRaw(requestParameters: V1ControlWebAdminResolutionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlPaginatedAdminResolutionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/resolutions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlPaginatedAdminResolutionsResponseFromJSON(jsonValue));
    }

    /**
     * Admin Get Resolution Requests
     */
    async v1ControlWebAdminResolutionsGet(requestParameters: V1ControlWebAdminResolutionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlPaginatedAdminResolutionsResponse> {
        const response = await this.v1ControlWebAdminResolutionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Get Resolution Request
     */
    async v1ControlWebAdminResolutionsRequestIdGetRaw(requestParameters: V1ControlWebAdminResolutionsRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAdminResolutionRequestResponseWithEvidence>> {
        if (requestParameters.request_id === null || requestParameters.request_id === undefined) {
            throw new runtime.RequiredError('request_id','Required parameter requestParameters.request_id was null or undefined when calling v1ControlWebAdminResolutionsRequestIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/resolutions/{request_id}`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.request_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAdminResolutionRequestResponseWithEvidenceFromJSON(jsonValue));
    }

    /**
     * Admin Get Resolution Request
     */
    async v1ControlWebAdminResolutionsRequestIdGet(requestParameters: V1ControlWebAdminResolutionsRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAdminResolutionRequestResponseWithEvidence> {
        const response = await this.v1ControlWebAdminResolutionsRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Upload Evidence
     */
    async v1ControlWebAdminResolutionsRequestIdPostRaw(requestParameters: V1ControlWebAdminResolutionsRequestIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.request_id === null || requestParameters.request_id === undefined) {
            throw new runtime.RequiredError('request_id','Required parameter requestParameters.request_id was null or undefined when calling v1ControlWebAdminResolutionsRequestIdPost.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling v1ControlWebAdminResolutionsRequestIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/control/web/admin/resolutions/{request_id}`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.request_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Admin Upload Evidence
     */
    async v1ControlWebAdminResolutionsRequestIdPost(requestParameters: V1ControlWebAdminResolutionsRequestIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminResolutionsRequestIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Update Resolution Status
     */
    async v1ControlWebAdminResolutionsRequestIdPutRaw(requestParameters: V1ControlWebAdminResolutionsRequestIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAdminResolutionRequestResponse>> {
        if (requestParameters.request_id === null || requestParameters.request_id === undefined) {
            throw new runtime.RequiredError('request_id','Required parameter requestParameters.request_id was null or undefined when calling v1ControlWebAdminResolutionsRequestIdPut.');
        }

        if (requestParameters.ControlResolutionStatusUpdateBody === null || requestParameters.ControlResolutionStatusUpdateBody === undefined) {
            throw new runtime.RequiredError('ControlResolutionStatusUpdateBody','Required parameter requestParameters.ControlResolutionStatusUpdateBody was null or undefined when calling v1ControlWebAdminResolutionsRequestIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/resolutions/{request_id}`.replace(`{${"request_id"}}`, encodeURIComponent(String(requestParameters.request_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlResolutionStatusUpdateBodyToJSON(requestParameters.ControlResolutionStatusUpdateBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAdminResolutionRequestResponseFromJSON(jsonValue));
    }

    /**
     * Admin Update Resolution Status
     */
    async v1ControlWebAdminResolutionsRequestIdPut(requestParameters: V1ControlWebAdminResolutionsRequestIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAdminResolutionRequestResponse> {
        const response = await this.v1ControlWebAdminResolutionsRequestIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin Search
     */
    async v1ControlWebAdminSearchGetRaw(requestParameters: V1ControlWebAdminSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlEntitySearchResponse>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.relation !== undefined) {
            queryParameters['relation'] = requestParameters.relation;
        }

        if (requestParameters.policy_number !== undefined) {
            queryParameters['policy_number'] = requestParameters.policy_number;
        }

        if (requestParameters.policy_uuid !== undefined) {
            queryParameters['policy_uuid'] = requestParameters.policy_uuid;
        }

        if (requestParameters.policyholder_email !== undefined) {
            queryParameters['policyholder_email'] = requestParameters.policyholder_email;
        }

        if (requestParameters.policyholder_uuid !== undefined) {
            queryParameters['policyholder_uuid'] = requestParameters.policyholder_uuid;
        }

        if (requestParameters.account_uuid !== undefined) {
            queryParameters['account_uuid'] = requestParameters.account_uuid;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.trials_only !== undefined) {
            queryParameters['trials_only'] = requestParameters.trials_only;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlEntitySearchResponseFromJSON(jsonValue));
    }

    /**
     * Admin Search
     */
    async v1ControlWebAdminSearchGet(requestParameters: V1ControlWebAdminSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlEntitySearchResponse> {
        const response = await this.v1ControlWebAdminSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Client Trial
     */
    async v1ControlWebAdminUpdateClientTrialClientIdPatchRaw(requestParameters: V1ControlWebAdminUpdateClientTrialClientIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlTrialUpdateResponse>> {
        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminUpdateClientTrialClientIdPatch.');
        }

        if (requestParameters.ControlTrialUpdateRequest === null || requestParameters.ControlTrialUpdateRequest === undefined) {
            throw new runtime.RequiredError('ControlTrialUpdateRequest','Required parameter requestParameters.ControlTrialUpdateRequest was null or undefined when calling v1ControlWebAdminUpdateClientTrialClientIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/update-client-trial/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ControlTrialUpdateRequestToJSON(requestParameters.ControlTrialUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlTrialUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update Client Trial
     */
    async v1ControlWebAdminUpdateClientTrialClientIdPatch(requestParameters: V1ControlWebAdminUpdateClientTrialClientIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlTrialUpdateResponse> {
        const response = await this.v1ControlWebAdminUpdateClientTrialClientIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upgrade User
     */
    async v1ControlWebAdminUpgradeUserIdPostRaw(requestParameters: V1ControlWebAdminUpgradeUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUpgradeUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/upgrade/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upgrade User
     */
    async v1ControlWebAdminUpgradeUserIdPost(requestParameters: V1ControlWebAdminUpgradeUserIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminUpgradeUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Deleted Users
     */
    async v1ControlWebAdminUserDeletedGetRaw(requestParameters: V1ControlWebAdminUserDeletedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/deleted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Deleted Users
     */
    async v1ControlWebAdminUserDeletedGet(requestParameters: V1ControlWebAdminUserDeletedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminUserDeletedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Users
     */
    async v1ControlWebAdminUserGetRaw(requestParameters: V1ControlWebAdminUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlPaginatedUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.client_id !== undefined) {
            queryParameters['client_id'] = requestParameters.client_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.include_invites !== undefined) {
            queryParameters['include_invites'] = requestParameters.include_invites;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlPaginatedUserResponseFromJSON(jsonValue));
    }

    /**
     * Get Users
     */
    async v1ControlWebAdminUserGet(requestParameters: V1ControlWebAdminUserGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlPaginatedUserResponse> {
        const response = await this.v1ControlWebAdminUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete User Authenticators
     */
    async v1ControlWebAdminUserUserIdAuthenticatorsDeleteRaw(requestParameters: V1ControlWebAdminUserUserIdAuthenticatorsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdAuthenticatorsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}/authenticators`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete User Authenticators
     */
    async v1ControlWebAdminUserUserIdAuthenticatorsDelete(requestParameters: V1ControlWebAdminUserUserIdAuthenticatorsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminUserUserIdAuthenticatorsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Authenticators
     */
    async v1ControlWebAdminUserUserIdAuthenticatorsGetRaw(requestParameters: V1ControlWebAdminUserUserIdAuthenticatorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ControlAuthenticatorDetails>>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdAuthenticatorsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}/authenticators`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ControlAuthenticatorDetailsFromJSON));
    }

    /**
     * Get User Authenticators
     */
    async v1ControlWebAdminUserUserIdAuthenticatorsGet(requestParameters: V1ControlWebAdminUserUserIdAuthenticatorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ControlAuthenticatorDetails>> {
        const response = await this.v1ControlWebAdminUserUserIdAuthenticatorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Soft Delete User
     */
    async v1ControlWebAdminUserUserIdDeleteRaw(requestParameters: V1ControlWebAdminUserUserIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdDelete.');
        }

        if (requestParameters.ControlSoftDeleteUserRequest === null || requestParameters.ControlSoftDeleteUserRequest === undefined) {
            throw new runtime.RequiredError('ControlSoftDeleteUserRequest','Required parameter requestParameters.ControlSoftDeleteUserRequest was null or undefined when calling v1ControlWebAdminUserUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ControlSoftDeleteUserRequestToJSON(requestParameters.ControlSoftDeleteUserRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Soft Delete User
     */
    async v1ControlWebAdminUserUserIdDelete(requestParameters: V1ControlWebAdminUserUserIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminUserUserIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Valid User Roles
     */
    async v1ControlWebAdminUserUserIdRolesGetRaw(requestParameters: V1ControlWebAdminUserUserIdRolesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlUserRolesResponse>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdRolesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}/roles`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlUserRolesResponseFromJSON(jsonValue));
    }

    /**
     * Get Valid User Roles
     */
    async v1ControlWebAdminUserUserIdRolesGet(requestParameters: V1ControlWebAdminUserUserIdRolesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlUserRolesResponse> {
        const response = await this.v1ControlWebAdminUserUserIdRolesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Roles
     */
    async v1ControlWebAdminUserUserIdRolesPostRaw(requestParameters: V1ControlWebAdminUserUserIdRolesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlUserResponse>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdRolesPost.');
        }

        if (requestParameters.ControlUserUpdateRoleRequest === null || requestParameters.ControlUserUpdateRoleRequest === undefined) {
            throw new runtime.RequiredError('ControlUserUpdateRoleRequest','Required parameter requestParameters.ControlUserUpdateRoleRequest was null or undefined when calling v1ControlWebAdminUserUserIdRolesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}/roles`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlUserUpdateRoleRequestToJSON(requestParameters.ControlUserUpdateRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlUserResponseFromJSON(jsonValue));
    }

    /**
     * Update User Roles
     */
    async v1ControlWebAdminUserUserIdRolesPost(requestParameters: V1ControlWebAdminUserUserIdRolesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlUserResponse> {
        const response = await this.v1ControlWebAdminUserUserIdRolesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Undelete User
     */
    async v1ControlWebAdminUserUserIdUndeletePostRaw(requestParameters: V1ControlWebAdminUserUserIdUndeletePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling v1ControlWebAdminUserUserIdUndeletePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/user/{user_id}/undelete`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Undelete User
     */
    async v1ControlWebAdminUserUserIdUndeletePost(requestParameters: V1ControlWebAdminUserUserIdUndeletePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminUserUserIdUndeletePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1ControlWebAdminUsersDeletedRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/users/deleted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ControlWebAdminUsersDeletedRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ControlWebAdminUsersDeletedRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1ControlWebAdminUsersRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ControlWebAdminUsersRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ControlWebAdminUsersRetrieveRaw(initOverrides);
    }

    /**
     * Delete Zero Day Alert Client Association
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDeleteRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDelete.');
        }

        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/clients/{client_id}`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))).replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Zero Day Alert Client Association
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDelete(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Zero Day Alert Client Association
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPutRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlUpdateZeroDayAlertClientResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPut.');
        }

        if (requestParameters.client_id === null || requestParameters.client_id === undefined) {
            throw new runtime.RequiredError('client_id','Required parameter requestParameters.client_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPut.');
        }

        if (requestParameters.ControlUpdateZeroDayAlertClientRequest === null || requestParameters.ControlUpdateZeroDayAlertClientRequest === undefined) {
            throw new runtime.RequiredError('ControlUpdateZeroDayAlertClientRequest','Required parameter requestParameters.ControlUpdateZeroDayAlertClientRequest was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/clients/{client_id}`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))).replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.client_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlUpdateZeroDayAlertClientRequestToJSON(requestParameters.ControlUpdateZeroDayAlertClientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlUpdateZeroDayAlertClientResponseFromJSON(jsonValue));
    }

    /**
     * Update Zero Day Alert Client Association
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPut(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlUpdateZeroDayAlertClientResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdClientsClientIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Zero Day Alert Clients
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsGetRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlGetZeroDayAlertClientsResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.resolution_status !== undefined) {
            queryParameters['resolution_status'] = requestParameters.resolution_status;
        }

        if (requestParameters.notified !== undefined) {
            queryParameters['notified'] = requestParameters.notified;
        }

        if (requestParameters.client_ids) {
            queryParameters['client_ids'] = requestParameters.client_ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/clients`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlGetZeroDayAlertClientsResponseFromJSON(jsonValue));
    }

    /**
     * Get Zero Day Alert Clients
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsGet(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlGetZeroDayAlertClientsResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdClientsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Associate Clients With Zero Day
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsPostRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsPost.');
        }

        if (requestParameters.client_file === null || requestParameters.client_file === undefined) {
            throw new runtime.RequiredError('client_file','Required parameter requestParameters.client_file was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdClientsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.client_file !== undefined) {
            formParams.append('client_file', requestParameters.client_file as any);
        }

        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/clients`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Associate Clients With Zero Day
     */
    async v1ControlWebAdminZeroDayAlertAlertIdClientsPost(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdClientsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdClientsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertAlertIdGetRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlAppSchemasAdminGetZeroDayAlertResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlAppSchemasAdminGetZeroDayAlertResponseFromJSON(jsonValue));
    }

    /**
     * Get Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertAlertIdGet(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlAppSchemasAdminGetZeroDayAlertResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Zero Day Alert Notifications
     */
    async v1ControlWebAdminZeroDayAlertAlertIdNotificationsGetRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdNotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlGetZeroDayAlertNotificationsResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdNotificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/notifications`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlGetZeroDayAlertNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * Get Zero Day Alert Notifications
     */
    async v1ControlWebAdminZeroDayAlertAlertIdNotificationsGet(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdNotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlGetZeroDayAlertNotificationsResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Notify Zero Day Alert Clients
     */
    async v1ControlWebAdminZeroDayAlertAlertIdNotificationsPostRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdNotificationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlNotifyZeroDayAlertClientsResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdNotificationsPost.');
        }

        if (requestParameters.ControlNotifyZeroDayAlertClientsRequest === null || requestParameters.ControlNotifyZeroDayAlertClientsRequest === undefined) {
            throw new runtime.RequiredError('ControlNotifyZeroDayAlertClientsRequest','Required parameter requestParameters.ControlNotifyZeroDayAlertClientsRequest was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdNotificationsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}/notifications`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlNotifyZeroDayAlertClientsRequestToJSON(requestParameters.ControlNotifyZeroDayAlertClientsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlNotifyZeroDayAlertClientsResponseFromJSON(jsonValue));
    }

    /**
     * Notify Zero Day Alert Clients
     */
    async v1ControlWebAdminZeroDayAlertAlertIdNotificationsPost(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdNotificationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlNotifyZeroDayAlertClientsResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdNotificationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertAlertIdPutRaw(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlUpdateZeroDayAlertResponse>> {
        if (requestParameters.alert_id === null || requestParameters.alert_id === undefined) {
            throw new runtime.RequiredError('alert_id','Required parameter requestParameters.alert_id was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdPut.');
        }

        if (requestParameters.ControlUpdateZeroDayAlertRequest === null || requestParameters.ControlUpdateZeroDayAlertRequest === undefined) {
            throw new runtime.RequiredError('ControlUpdateZeroDayAlertRequest','Required parameter requestParameters.ControlUpdateZeroDayAlertRequest was null or undefined when calling v1ControlWebAdminZeroDayAlertAlertIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/{alert_id}`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alert_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlUpdateZeroDayAlertRequestToJSON(requestParameters.ControlUpdateZeroDayAlertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlUpdateZeroDayAlertResponseFromJSON(jsonValue));
    }

    /**
     * Update Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertAlertIdPut(requestParameters: V1ControlWebAdminZeroDayAlertAlertIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlUpdateZeroDayAlertResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertAlertIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Zero Day Alerts
     */
    async v1ControlWebAdminZeroDayAlertGetRaw(requestParameters: V1ControlWebAdminZeroDayAlertGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlGetZeroDayAlertsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlGetZeroDayAlertsResponseFromJSON(jsonValue));
    }

    /**
     * Get Zero Day Alerts
     */
    async v1ControlWebAdminZeroDayAlertGet(requestParameters: V1ControlWebAdminZeroDayAlertGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlGetZeroDayAlertsResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertPostRaw(requestParameters: V1ControlWebAdminZeroDayAlertPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlCreateZeroDayAlertResponse>> {
        if (requestParameters.ControlCreateZeroDayAlertRequest === null || requestParameters.ControlCreateZeroDayAlertRequest === undefined) {
            throw new runtime.RequiredError('ControlCreateZeroDayAlertRequest','Required parameter requestParameters.ControlCreateZeroDayAlertRequest was null or undefined when calling v1ControlWebAdminZeroDayAlertPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlCreateZeroDayAlertRequestToJSON(requestParameters.ControlCreateZeroDayAlertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlCreateZeroDayAlertResponseFromJSON(jsonValue));
    }

    /**
     * Create Zero Day Alert
     */
    async v1ControlWebAdminZeroDayAlertPost(requestParameters: V1ControlWebAdminZeroDayAlertPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlCreateZeroDayAlertResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Zero Day Alert Sample File
     */
    async v1ControlWebAdminZeroDayAlertSampleFileGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlGetZeroDayAlertSampleFileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/sample-file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlGetZeroDayAlertSampleFileResponseFromJSON(jsonValue));
    }

    /**
     * Get Zero Day Alert Sample File
     */
    async v1ControlWebAdminZeroDayAlertSampleFileGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlGetZeroDayAlertSampleFileResponse> {
        const response = await this.v1ControlWebAdminZeroDayAlertSampleFileGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Send Zero Day Alert Test Email
     */
    async v1ControlWebAdminZeroDayAlertSendTestEmailPostRaw(requestParameters: V1ControlWebAdminZeroDayAlertSendTestEmailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.ControlSendZeroDayAlertTestEmailRequest === null || requestParameters.ControlSendZeroDayAlertTestEmailRequest === undefined) {
            throw new runtime.RequiredError('ControlSendZeroDayAlertTestEmailRequest','Required parameter requestParameters.ControlSendZeroDayAlertTestEmailRequest was null or undefined when calling v1ControlWebAdminZeroDayAlertSendTestEmailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/control/web/admin/zero-day-alert/send-test-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlSendZeroDayAlertTestEmailRequestToJSON(requestParameters.ControlSendZeroDayAlertTestEmailRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send Zero Day Alert Test Email
     */
    async v1ControlWebAdminZeroDayAlertSendTestEmailPost(requestParameters: V1ControlWebAdminZeroDayAlertSendTestEmailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1ControlWebAdminZeroDayAlertSendTestEmailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Last
     */
    async v1CraWebCrsfEntityListGetRaw(requestParameters: V1CraWebCrsfEntityListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFProcessPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.scan_type) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.account_id !== undefined) {
            queryParameters['account_id'] = requestParameters.account_id;
        }

        if (requestParameters.quote_id !== undefined) {
            queryParameters['quote_id'] = requestParameters.quote_id;
        }

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFProcessPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Last
     */
    async v1CraWebCrsfEntityListGet(requestParameters: V1CraWebCrsfEntityListGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFProcessPagedResponse> {
        const response = await this.v1CraWebCrsfEntityListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1CraWebCrsfEntityPdfRetrieveRaw(requestParameters: V1CraWebCrsfEntityPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityPdfRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/pdf`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1CraWebCrsfEntityPdfRetrieve(requestParameters: V1CraWebCrsfEntityPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1CraWebCrsfEntityPdfRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Dataleaks
     */
    async v1CraWebCrsfEntityProcessIdDataleaksGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFDataleaksPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdDataleaksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/dataleaks`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFDataleaksPagedResponseFromJSON(jsonValue));
    }

    /**
     * Dataleaks
     */
    async v1CraWebCrsfEntityProcessIdDataleaksGet(requestParameters: V1CraWebCrsfEntityProcessIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFDataleaksPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdDataleaksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Domains
     */
    async v1CraWebCrsfEntityProcessIdDomainsGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFDomainPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdDomainsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/domains`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFDomainPagedResponseFromJSON(jsonValue));
    }

    /**
     * Domains
     */
    async v1CraWebCrsfEntityProcessIdDomainsGet(requestParameters: V1CraWebCrsfEntityProcessIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFDomainPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Findings
     */
    async v1CraWebCrsfEntityProcessIdFindingsGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFResolvedFindingsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/findings`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFResolvedFindingsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Findings
     */
    async v1CraWebCrsfEntityProcessIdFindingsGet(requestParameters: V1CraWebCrsfEntityProcessIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFResolvedFindingsPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Status
     */
    async v1CraWebCrsfEntityProcessIdGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFProcess>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFProcessFromJSON(jsonValue));
    }

    /**
     * Get Status
     */
    async v1CraWebCrsfEntityProcessIdGet(requestParameters: V1CraWebCrsfEntityProcessIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFProcess> {
        const response = await this.v1CraWebCrsfEntityProcessIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Honeypots
     */
    async v1CraWebCrsfEntityProcessIdHoneypotsGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFHoneypotsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdHoneypotsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/honeypots`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFHoneypotsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Honeypots
     */
    async v1CraWebCrsfEntityProcessIdHoneypotsGet(requestParameters: V1CraWebCrsfEntityProcessIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFHoneypotsPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdHoneypotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ip Addresses
     */
    async v1CraWebCrsfEntityProcessIdIpAddressesGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFIpAddressesPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdIpAddressesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/ip_addresses`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFIpAddressesPagedResponseFromJSON(jsonValue));
    }

    /**
     * Ip Addresses
     */
    async v1CraWebCrsfEntityProcessIdIpAddressesGet(requestParameters: V1CraWebCrsfEntityProcessIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFIpAddressesPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke
     */
    async v1CraWebCrsfEntityProcessIdRevokePostRaw(requestParameters: V1CraWebCrsfEntityProcessIdRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFGetStatusResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdRevokePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/revoke`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFGetStatusResponseFromJSON(jsonValue));
    }

    /**
     * Revoke
     */
    async v1CraWebCrsfEntityProcessIdRevokePost(requestParameters: V1CraWebCrsfEntityProcessIdRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFGetStatusResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdRevokePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Techs
     */
    async v1CraWebCrsfEntityProcessIdTechsGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFTechsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/techs`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFTechsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Techs
     */
    async v1CraWebCrsfEntityProcessIdTechsGet(requestParameters: V1CraWebCrsfEntityProcessIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFTechsPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Torrents
     */
    async v1CraWebCrsfEntityProcessIdTorrentsGetRaw(requestParameters: V1CraWebCrsfEntityProcessIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFTorrentsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1CraWebCrsfEntityProcessIdTorrentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/{process_id}/torrents`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFTorrentsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Torrents
     */
    async v1CraWebCrsfEntityProcessIdTorrentsGet(requestParameters: V1CraWebCrsfEntityProcessIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFTorrentsPagedResponse> {
        const response = await this.v1CraWebCrsfEntityProcessIdTorrentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger
     */
    async v1CraWebCrsfEntityTriggerPostRaw(requestParameters: V1CraWebCrsfEntityTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSrcHttpdlibCoreModelsCraTriggerResponse>> {
        if (requestParameters.CRSFSrcHttpdlibCoreModelsCraTrigger === null || requestParameters.CRSFSrcHttpdlibCoreModelsCraTrigger === undefined) {
            throw new runtime.RequiredError('CRSFSrcHttpdlibCoreModelsCraTrigger','Required parameter requestParameters.CRSFSrcHttpdlibCoreModelsCraTrigger was null or undefined when calling v1CraWebCrsfEntityTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/crsf_entity/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CRSFSrcHttpdlibCoreModelsCraTriggerToJSON(requestParameters.CRSFSrcHttpdlibCoreModelsCraTrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSrcHttpdlibCoreModelsCraTriggerResponseFromJSON(jsonValue));
    }

    /**
     * Trigger
     */
    async v1CraWebCrsfEntityTriggerPost(requestParameters: V1CraWebCrsfEntityTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSrcHttpdlibCoreModelsCraTriggerResponse> {
        const response = await this.v1CraWebCrsfEntityTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Dataleaks
     */
    async v1CraWebEntityEntityDomainDataleaksGetRaw(requestParameters: V1CraWebEntityEntityDomainDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainDataleaksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/dataleaks`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Dataleaks
     */
    async v1CraWebEntityEntityDomainDataleaksGet(requestParameters: V1CraWebEntityEntityDomainDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainDataleaksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Domains
     */
    async v1CraWebEntityEntityDomainDomainsGetRaw(requestParameters: V1CraWebEntityEntityDomainDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainDomainsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.blocklists !== undefined) {
            queryParameters['blocklists'] = requestParameters.blocklists;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/domains`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Domains
     */
    async v1CraWebEntityEntityDomainDomainsGet(requestParameters: V1CraWebEntityEntityDomainDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Findings
     */
    async v1CraWebEntityEntityDomainFindingsGetRaw(requestParameters: V1CraWebEntityEntityDomainFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/findings`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Findings
     */
    async v1CraWebEntityEntityDomainFindingsGet(requestParameters: V1CraWebEntityEntityDomainFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Entity
     */
    async v1CraWebEntityEntityDomainGetRaw(requestParameters: V1CraWebEntityEntityDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRAEntity>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRAEntityFromJSON(jsonValue));
    }

    /**
     * Entity
     */
    async v1CraWebEntityEntityDomainGet(requestParameters: V1CraWebEntityEntityDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRAEntity> {
        const response = await this.v1CraWebEntityEntityDomainGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Honeypots
     */
    async v1CraWebEntityEntityDomainHoneypotsGetRaw(requestParameters: V1CraWebEntityEntityDomainHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainHoneypotsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/honeypots`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Honeypots
     */
    async v1CraWebEntityEntityDomainHoneypotsGet(requestParameters: V1CraWebEntityEntityDomainHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainHoneypotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ip Addresses
     */
    async v1CraWebEntityEntityDomainIpAddressesGetRaw(requestParameters: V1CraWebEntityEntityDomainIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainIpAddressesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.blocklists !== undefined) {
            queryParameters['blocklists'] = requestParameters.blocklists;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/ip_addresses`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Ip Addresses
     */
    async v1CraWebEntityEntityDomainIpAddressesGet(requestParameters: V1CraWebEntityEntityDomainIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Entity
     */
    async v1CraWebEntityEntityDomainListGetRaw(requestParameters: V1CraWebEntityEntityDomainListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/list`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Entity
     */
    async v1CraWebEntityEntityDomainListGet(requestParameters: V1CraWebEntityEntityDomainListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect Pdf
     */
    async v1CraWebEntityEntityDomainPdfGetRaw(requestParameters: V1CraWebEntityEntityDomainPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainPdfGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/pdf`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Pdf
     */
    async v1CraWebEntityEntityDomainPdfGet(requestParameters: V1CraWebEntityEntityDomainPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1CraWebEntityEntityDomainPdfGetRaw(requestParameters, initOverrides);
    }

    /**
     * Revoke
     */
    async v1CraWebEntityEntityDomainRevokePostRaw(requestParameters: V1CraWebEntityEntityDomainRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRAEntity>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainRevokePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/revoke`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRAEntityFromJSON(jsonValue));
    }

    /**
     * Revoke
     */
    async v1CraWebEntityEntityDomainRevokePost(requestParameters: V1CraWebEntityEntityDomainRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRAEntity> {
        const response = await this.v1CraWebEntityEntityDomainRevokePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scans
     */
    async v1CraWebEntityEntityDomainScansGetRaw(requestParameters: V1CraWebEntityEntityDomainScansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainScansGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.cves !== undefined) {
            queryParameters['cves'] = requestParameters.cves;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.no_data !== undefined) {
            queryParameters['no_data'] = requestParameters.no_data;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/scans`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Scans
     */
    async v1CraWebEntityEntityDomainScansGet(requestParameters: V1CraWebEntityEntityDomainScansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainScansGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stats
     */
    async v1CraWebEntityEntityDomainStatsGetRaw(requestParameters: V1CraWebEntityEntityDomainStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRAStats>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainStatsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/stats`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRAStatsFromJSON(jsonValue));
    }

    /**
     * Stats
     */
    async v1CraWebEntityEntityDomainStatsGet(requestParameters: V1CraWebEntityEntityDomainStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRAStats> {
        const response = await this.v1CraWebEntityEntityDomainStatsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process Status
     */
    async v1CraWebEntityEntityDomainStatusGetRaw(requestParameters: V1CraWebEntityEntityDomainStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRAProcess>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainStatusGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/status`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRAProcessFromJSON(jsonValue));
    }

    /**
     * Process Status
     */
    async v1CraWebEntityEntityDomainStatusGet(requestParameters: V1CraWebEntityEntityDomainStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRAProcess> {
        const response = await this.v1CraWebEntityEntityDomainStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Techs
     */
    async v1CraWebEntityEntityDomainTechsGetRaw(requestParameters: V1CraWebEntityEntityDomainTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.cves !== undefined) {
            queryParameters['cves'] = requestParameters.cves;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/techs`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Techs
     */
    async v1CraWebEntityEntityDomainTechsGet(requestParameters: V1CraWebEntityEntityDomainTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Torrents
     */
    async v1CraWebEntityEntityDomainTorrentsGetRaw(requestParameters: V1CraWebEntityEntityDomainTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityEntityDomainTorrentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/torrents`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * Torrents
     */
    async v1CraWebEntityEntityDomainTorrentsGet(requestParameters: V1CraWebEntityEntityDomainTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebEntityEntityDomainTorrentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1CraWebEntityExtscansRetrieveRaw(requestParameters: V1CraWebEntityExtscansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1CraWebEntityExtscansRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/entity/{entity_domain}/extscans`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1CraWebEntityExtscansRetrieve(requestParameters: V1CraWebEntityExtscansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1CraWebEntityExtscansRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * List Last
     */
    async v1CraWebListGetRaw(requestParameters: V1CraWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRACRAPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRACRAPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Last
     */
    async v1CraWebListGet(requestParameters: V1CraWebListGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRACRAPagedResponse> {
        const response = await this.v1CraWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger
     */
    async v1CraWebTriggerPostRaw(requestParameters: V1CraWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRATrigger>> {
        if (requestParameters.CRATrigger === null || requestParameters.CRATrigger === undefined) {
            throw new runtime.RequiredError('CRATrigger','Required parameter requestParameters.CRATrigger was null or undefined when calling v1CraWebTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/cra/web/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CRATriggerToJSON(requestParameters.CRATrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRATriggerFromJSON(jsonValue));
    }

    /**
     * Trigger
     */
    async v1CraWebTriggerPost(requestParameters: V1CraWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRATrigger> {
        const response = await this.v1CraWebTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1ExecriskWebScanLitigationsRetrieveRaw(requestParameters: V1ExecriskWebScanLitigationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanLitigationsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/litigations`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1ExecriskWebScanLitigationsRetrieve(requestParameters: V1ExecriskWebScanLitigationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ExecriskWebScanLitigationsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Scan Alexa
     */
    async v1ExecriskWebScanProcessIdAlexaGetRaw(requestParameters: V1ExecriskWebScanProcessIdAlexaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedAlexaResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdAlexaGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/alexa`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedAlexaResponseFromJSON(jsonValue));
    }

    /**
     * Scan Alexa
     */
    async v1ExecriskWebScanProcessIdAlexaGet(requestParameters: V1ExecriskWebScanProcessIdAlexaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedAlexaResponse> {
        const response = await this.v1ExecriskWebScanProcessIdAlexaGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Bingnews
     */
    async v1ExecriskWebScanProcessIdBingnewsGetRaw(requestParameters: V1ExecriskWebScanProcessIdBingnewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedBingResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdBingnewsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/bingnews`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedBingResponseFromJSON(jsonValue));
    }

    /**
     * Scan Bingnews
     */
    async v1ExecriskWebScanProcessIdBingnewsGet(requestParameters: V1ExecriskWebScanProcessIdBingnewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedBingResponse> {
        const response = await this.v1ExecriskWebScanProcessIdBingnewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Company Info
     */
    async v1ExecriskWebScanProcessIdCompanyinfoGetRaw(requestParameters: V1ExecriskWebScanProcessIdCompanyinfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskCompanyInfoCategoryResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdCompanyinfoGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/companyinfo`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskCompanyInfoCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Get Company Info
     */
    async v1ExecriskWebScanProcessIdCompanyinfoGet(requestParameters: V1ExecriskWebScanProcessIdCompanyinfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskCompanyInfoCategoryResponse> {
        const response = await this.v1ExecriskWebScanProcessIdCompanyinfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Docketalarm
     */
    async v1ExecriskWebScanProcessIdDocketalarmGetRaw(requestParameters: V1ExecriskWebScanProcessIdDocketalarmGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedDocketScanResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdDocketalarmGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.date_from !== undefined) {
            queryParameters['date_from'] = requestParameters.date_from;
        }

        if (requestParameters.date_to !== undefined) {
            queryParameters['date_to'] = requestParameters.date_to;
        }

        if (requestParameters.keywords !== undefined) {
            queryParameters['keywords'] = requestParameters.keywords;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/docketalarm`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedDocketScanResponseFromJSON(jsonValue));
    }

    /**
     * Scan Docketalarm
     */
    async v1ExecriskWebScanProcessIdDocketalarmGet(requestParameters: V1ExecriskWebScanProcessIdDocketalarmGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedDocketScanResponse> {
        const response = await this.v1ExecriskWebScanProcessIdDocketalarmGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Everify
     */
    async v1ExecriskWebScanProcessIdEverifyGetRaw(requestParameters: V1ExecriskWebScanProcessIdEverifyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedEVerifyResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdEverifyGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/everify`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedEVerifyResponseFromJSON(jsonValue));
    }

    /**
     * Scan Everify
     */
    async v1ExecriskWebScanProcessIdEverifyGet(requestParameters: V1ExecriskWebScanProcessIdEverifyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedEVerifyResponse> {
        const response = await this.v1ExecriskWebScanProcessIdEverifyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Experian
     */
    async v1ExecriskWebScanProcessIdExperianGetRaw(requestParameters: V1ExecriskWebScanProcessIdExperianGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedExperianResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdExperianGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/experian`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedExperianResponseFromJSON(jsonValue));
    }

    /**
     * Scan Experian
     */
    async v1ExecriskWebScanProcessIdExperianGet(requestParameters: V1ExecriskWebScanProcessIdExperianGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedExperianResponse> {
        const response = await this.v1ExecriskWebScanProcessIdExperianGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Experian Liens
     */
    async v1ExecriskWebScanProcessIdExperianLiensGetRaw(requestParameters: V1ExecriskWebScanProcessIdExperianLiensGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedExperianLiensResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdExperianLiensGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/experian/liens`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedExperianLiensResponseFromJSON(jsonValue));
    }

    /**
     * Scan Experian Liens
     */
    async v1ExecriskWebScanProcessIdExperianLiensGet(requestParameters: V1ExecriskWebScanProcessIdExperianLiensGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedExperianLiensResponse> {
        const response = await this.v1ExecriskWebScanProcessIdExperianLiensGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Form990
     */
    async v1ExecriskWebScanProcessIdForm990GetRaw(requestParameters: V1ExecriskWebScanProcessIdForm990GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedForm990Response>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdForm990Get.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/form990`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedForm990ResponseFromJSON(jsonValue));
    }

    /**
     * Scan Form990
     */
    async v1ExecriskWebScanProcessIdForm990Get(requestParameters: V1ExecriskWebScanProcessIdForm990GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedForm990Response> {
        const response = await this.v1ExecriskWebScanProcessIdForm990GetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Glassdoor
     */
    async v1ExecriskWebScanProcessIdGlassdoorGetRaw(requestParameters: V1ExecriskWebScanProcessIdGlassdoorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedGlassDoorScanResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdGlassdoorGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/glassdoor`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedGlassDoorScanResponseFromJSON(jsonValue));
    }

    /**
     * Scan Glassdoor
     */
    async v1ExecriskWebScanProcessIdGlassdoorGet(requestParameters: V1ExecriskWebScanProcessIdGlassdoorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedGlassDoorScanResponse> {
        const response = await this.v1ExecriskWebScanProcessIdGlassdoorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Glassdoor Ratings
     */
    async v1ExecriskWebScanProcessIdGlassdoorRatingsGetRaw(requestParameters: V1ExecriskWebScanProcessIdGlassdoorRatingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskGlassDoorRatingsResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdGlassdoorRatingsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/glassdoor/ratings`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskGlassDoorRatingsResponseFromJSON(jsonValue));
    }

    /**
     * Scan Glassdoor Ratings
     */
    async v1ExecriskWebScanProcessIdGlassdoorRatingsGet(requestParameters: V1ExecriskWebScanProcessIdGlassdoorRatingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskGlassDoorRatingsResponse> {
        const response = await this.v1ExecriskWebScanProcessIdGlassdoorRatingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Glassdoor Reviews
     */
    async v1ExecriskWebScanProcessIdGlassdoorReviewsGetRaw(requestParameters: V1ExecriskWebScanProcessIdGlassdoorReviewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedGlassDoorReviewsResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdGlassdoorReviewsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.date_from !== undefined) {
            queryParameters['date_from'] = requestParameters.date_from;
        }

        if (requestParameters.date_to !== undefined) {
            queryParameters['date_to'] = requestParameters.date_to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/glassdoor/reviews`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedGlassDoorReviewsResponseFromJSON(jsonValue));
    }

    /**
     * Scan Glassdoor Reviews
     */
    async v1ExecriskWebScanProcessIdGlassdoorReviewsGet(requestParameters: V1ExecriskWebScanProcessIdGlassdoorReviewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedGlassDoorReviewsResponse> {
        const response = await this.v1ExecriskWebScanProcessIdGlassdoorReviewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Industry
     */
    async v1ExecriskWebScanProcessIdIndustryGetRaw(requestParameters: V1ExecriskWebScanProcessIdIndustryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedIndustryClassificationScanResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdIndustryGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/industry`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedIndustryClassificationScanResponseFromJSON(jsonValue));
    }

    /**
     * Scan Industry
     */
    async v1ExecriskWebScanProcessIdIndustryGet(requestParameters: V1ExecriskWebScanProcessIdIndustryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedIndustryClassificationScanResponse> {
        const response = await this.v1ExecriskWebScanProcessIdIndustryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Investors
     */
    async v1ExecriskWebScanProcessIdInvestorsGetRaw(requestParameters: V1ExecriskWebScanProcessIdInvestorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedInvestorsScanResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdInvestorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/investors`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedInvestorsScanResponseFromJSON(jsonValue));
    }

    /**
     * Scan Investors
     */
    async v1ExecriskWebScanProcessIdInvestorsGet(requestParameters: V1ExecriskWebScanProcessIdInvestorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedInvestorsScanResponse> {
        const response = await this.v1ExecriskWebScanProcessIdInvestorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Layoffs
     */
    async v1ExecriskWebScanProcessIdLayoffsGetRaw(requestParameters: V1ExecriskWebScanProcessIdLayoffsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedLayoffsResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdLayoffsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/layoffs`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedLayoffsResponseFromJSON(jsonValue));
    }

    /**
     * Scan Layoffs
     */
    async v1ExecriskWebScanProcessIdLayoffsGet(requestParameters: V1ExecriskWebScanProcessIdLayoffsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedLayoffsResponse> {
        const response = await this.v1ExecriskWebScanProcessIdLayoffsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Aylien Articles
     */
    async v1ExecriskWebScanProcessIdNewsArticlesGetRaw(requestParameters: V1ExecriskWebScanProcessIdNewsArticlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedAylienArticlesResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdNewsArticlesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/news/articles`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedAylienArticlesResponseFromJSON(jsonValue));
    }

    /**
     * Scan Aylien Articles
     */
    async v1ExecriskWebScanProcessIdNewsArticlesGet(requestParameters: V1ExecriskWebScanProcessIdNewsArticlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedAylienArticlesResponse> {
        const response = await this.v1ExecriskWebScanProcessIdNewsArticlesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Bingnews
     */
    async v1ExecriskWebScanProcessIdNewsGetRaw(requestParameters: V1ExecriskWebScanProcessIdNewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedAylienResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdNewsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/news`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedAylienResponseFromJSON(jsonValue));
    }

    /**
     * Scan Bingnews
     */
    async v1ExecriskWebScanProcessIdNewsGet(requestParameters: V1ExecriskWebScanProcessIdNewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedAylienResponse> {
        const response = await this.v1ExecriskWebScanProcessIdNewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Ppploans
     */
    async v1ExecriskWebScanProcessIdPpploansGetRaw(requestParameters: V1ExecriskWebScanProcessIdPpploansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedPPPLoansScanResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdPpploansGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/ppploans`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedPPPLoansScanResponseFromJSON(jsonValue));
    }

    /**
     * Scan Ppploans
     */
    async v1ExecriskWebScanProcessIdPpploansGet(requestParameters: V1ExecriskWebScanProcessIdPpploansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedPPPLoansScanResponse> {
        const response = await this.v1ExecriskWebScanProcessIdPpploansGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Proximity One
     */
    async v1ExecriskWebScanProcessIdProximityoneGetRaw(requestParameters: V1ExecriskWebScanProcessIdProximityoneGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedProximityOneResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdProximityoneGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/proximityone`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedProximityOneResponseFromJSON(jsonValue));
    }

    /**
     * Scan Proximity One
     */
    async v1ExecriskWebScanProcessIdProximityoneGet(requestParameters: V1ExecriskWebScanProcessIdProximityoneGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedProximityOneResponse> {
        const response = await this.v1ExecriskWebScanProcessIdProximityoneGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process Status
     */
    async v1ExecriskWebScanProcessIdStatusGetRaw(requestParameters: V1ExecriskWebScanProcessIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskAppCoreModelsTriggerProcessStatusResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/status`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskAppCoreModelsTriggerProcessStatusResponseFromJSON(jsonValue));
    }

    /**
     * Process Status
     */
    async v1ExecriskWebScanProcessIdStatusGet(requestParameters: V1ExecriskWebScanProcessIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskAppCoreModelsTriggerProcessStatusResponse> {
        const response = await this.v1ExecriskWebScanProcessIdStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Violations
     */
    async v1ExecriskWebScanProcessIdViolationsGetRaw(requestParameters: V1ExecriskWebScanProcessIdViolationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedViolationTrackerResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdViolationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/violations`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedViolationTrackerResponseFromJSON(jsonValue));
    }

    /**
     * Scan Violations
     */
    async v1ExecriskWebScanProcessIdViolationsGet(requestParameters: V1ExecriskWebScanProcessIdViolationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedViolationTrackerResponse> {
        const response = await this.v1ExecriskWebScanProcessIdViolationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Watchlist
     */
    async v1ExecriskWebScanProcessIdWatchlistGetRaw(requestParameters: V1ExecriskWebScanProcessIdWatchlistGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedEntityWatchlistResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdWatchlistGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/watchlist`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedEntityWatchlistResponseFromJSON(jsonValue));
    }

    /**
     * Scan Watchlist
     */
    async v1ExecriskWebScanProcessIdWatchlistGet(requestParameters: V1ExecriskWebScanProcessIdWatchlistGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedEntityWatchlistResponse> {
        const response = await this.v1ExecriskWebScanProcessIdWatchlistGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Wave
     */
    async v1ExecriskWebScanProcessIdWaveGetRaw(requestParameters: V1ExecriskWebScanProcessIdWaveGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedWaveResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdWaveGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/wave`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedWaveResponseFromJSON(jsonValue));
    }

    /**
     * Scan Wave
     */
    async v1ExecriskWebScanProcessIdWaveGet(requestParameters: V1ExecriskWebScanProcessIdWaveGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedWaveResponse> {
        const response = await this.v1ExecriskWebScanProcessIdWaveGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Webhose Articles
     */
    async v1ExecriskWebScanProcessIdWebhoseArticlesGetRaw(requestParameters: V1ExecriskWebScanProcessIdWebhoseArticlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedWebhoseArticlesResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdWebhoseArticlesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/webhose/articles`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedWebhoseArticlesResponseFromJSON(jsonValue));
    }

    /**
     * Scan Webhose Articles
     */
    async v1ExecriskWebScanProcessIdWebhoseArticlesGet(requestParameters: V1ExecriskWebScanProcessIdWebhoseArticlesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedWebhoseArticlesResponse> {
        const response = await this.v1ExecriskWebScanProcessIdWebhoseArticlesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Webhose
     */
    async v1ExecriskWebScanProcessIdWebhoseGetRaw(requestParameters: V1ExecriskWebScanProcessIdWebhoseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedWebhoseResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdWebhoseGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/webhose`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedWebhoseResponseFromJSON(jsonValue));
    }

    /**
     * Scan Webhose
     */
    async v1ExecriskWebScanProcessIdWebhoseGet(requestParameters: V1ExecriskWebScanProcessIdWebhoseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedWebhoseResponse> {
        const response = await this.v1ExecriskWebScanProcessIdWebhoseGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Zoominfo Fundings
     */
    async v1ExecriskWebScanProcessIdZoominfoFundingsGetRaw(requestParameters: V1ExecriskWebScanProcessIdZoominfoFundingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedZoomInfoFundingResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdZoominfoFundingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/zoominfo/fundings`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedZoomInfoFundingResponseFromJSON(jsonValue));
    }

    /**
     * Scan Zoominfo Fundings
     */
    async v1ExecriskWebScanProcessIdZoominfoFundingsGet(requestParameters: V1ExecriskWebScanProcessIdZoominfoFundingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedZoomInfoFundingResponse> {
        const response = await this.v1ExecriskWebScanProcessIdZoominfoFundingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Zoominfo
     */
    async v1ExecriskWebScanProcessIdZoominfoGetRaw(requestParameters: V1ExecriskWebScanProcessIdZoominfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedZoomInfoResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdZoominfoGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/zoominfo`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedZoomInfoResponseFromJSON(jsonValue));
    }

    /**
     * Scan Zoominfo
     */
    async v1ExecriskWebScanProcessIdZoominfoGet(requestParameters: V1ExecriskWebScanProcessIdZoominfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedZoomInfoResponse> {
        const response = await this.v1ExecriskWebScanProcessIdZoominfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Zoominfo Orgchart
     */
    async v1ExecriskWebScanProcessIdZoominfoOrgchartGetRaw(requestParameters: V1ExecriskWebScanProcessIdZoominfoOrgchartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedZoomInfoOrgchartResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdZoominfoOrgchartGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/zoominfo/orgchart`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedZoomInfoOrgchartResponseFromJSON(jsonValue));
    }

    /**
     * Scan Zoominfo Orgchart
     */
    async v1ExecriskWebScanProcessIdZoominfoOrgchartGet(requestParameters: V1ExecriskWebScanProcessIdZoominfoOrgchartGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedZoomInfoOrgchartResponse> {
        const response = await this.v1ExecriskWebScanProcessIdZoominfoOrgchartGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scan Zoominfo Parents
     */
    async v1ExecriskWebScanProcessIdZoominfoParentsGetRaw(requestParameters: V1ExecriskWebScanProcessIdZoominfoParentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskPaginatedZoomInfoParentsResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1ExecriskWebScanProcessIdZoominfoParentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scan/{process_id}/zoominfo/parents`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskPaginatedZoomInfoParentsResponseFromJSON(jsonValue));
    }

    /**
     * Scan Zoominfo Parents
     */
    async v1ExecriskWebScanProcessIdZoominfoParentsGet(requestParameters: V1ExecriskWebScanProcessIdZoominfoParentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskPaginatedZoomInfoParentsResponse> {
        const response = await this.v1ExecriskWebScanProcessIdZoominfoParentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scans
     */
    async v1ExecriskWebScansGetRaw(requestParameters: V1ExecriskWebScansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskTriggerListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.date_from !== undefined) {
            queryParameters['date_from'] = requestParameters.date_from;
        }

        if (requestParameters.date_to !== undefined) {
            queryParameters['date_to'] = requestParameters.date_to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/scans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskTriggerListResponseFromJSON(jsonValue));
    }

    /**
     * Scans
     */
    async v1ExecriskWebScansGet(requestParameters: V1ExecriskWebScansGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskTriggerListResponse> {
        const response = await this.v1ExecriskWebScansGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger
     */
    async v1ExecriskWebTriggerPostRaw(requestParameters: V1ExecriskWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecRiskTriggerResponse>> {
        if (requestParameters.ExecRiskTriggerRequest === null || requestParameters.ExecRiskTriggerRequest === undefined) {
            throw new runtime.RequiredError('ExecRiskTriggerRequest','Required parameter requestParameters.ExecRiskTriggerRequest was null or undefined when calling v1ExecriskWebTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/execrisk/web/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecRiskTriggerRequestToJSON(requestParameters.ExecRiskTriggerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecRiskTriggerResponseFromJSON(jsonValue));
    }

    /**
     * Trigger
     */
    async v1ExecriskWebTriggerPost(requestParameters: V1ExecriskWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecRiskTriggerResponse> {
        const response = await this.v1ExecriskWebTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAccountResolutionsAccountLineUuidGetRaw(requestParameters: V1MonitWebAccountResolutionsAccountLineUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitAccountResolutionsPaginatedResponse>> {
        if (requestParameters.account_line_uuid === null || requestParameters.account_line_uuid === undefined) {
            throw new runtime.RequiredError('account_line_uuid','Required parameter requestParameters.account_line_uuid was null or undefined when calling v1MonitWebAccountResolutionsAccountLineUuidGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.port !== undefined) {
            queryParameters['port'] = requestParameters.port;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/account_resolutions/{account_line_uuid}`.replace(`{${"account_line_uuid"}}`, encodeURIComponent(String(requestParameters.account_line_uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitAccountResolutionsPaginatedResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAccountResolutionsAccountLineUuidGet(requestParameters: V1MonitWebAccountResolutionsAccountLineUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitAccountResolutionsPaginatedResponse> {
        const response = await this.v1MonitWebAccountResolutionsAccountLineUuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebAccountResolutionsAccountLineUuidPostRaw(requestParameters: V1MonitWebAccountResolutionsAccountLineUuidPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitAccountResolutions>>> {
        if (requestParameters.account_line_uuid === null || requestParameters.account_line_uuid === undefined) {
            throw new runtime.RequiredError('account_line_uuid','Required parameter requestParameters.account_line_uuid was null or undefined when calling v1MonitWebAccountResolutionsAccountLineUuidPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/account_resolutions/{account_line_uuid}`.replace(`{${"account_line_uuid"}}`, encodeURIComponent(String(requestParameters.account_line_uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitAccountResolutionRequestToJSON(requestParameters.MonitAccountResolutionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitAccountResolutionsFromJSON));
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebAccountResolutionsAccountLineUuidPost(requestParameters: V1MonitWebAccountResolutionsAccountLineUuidPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitAccountResolutions>> {
        const response = await this.v1MonitWebAccountResolutionsAccountLineUuidPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebAgenciesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/agencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebAgenciesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebAgenciesRetrieveRaw(initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAssetsGetRaw(requestParameters: V1MonitWebAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAssetsGet(requestParameters: V1MonitWebAssetsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebAssetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAssetsReportedGetRaw(requestParameters: V1MonitWebAssetsReportedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityReportedAssetsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.asset_type !== undefined) {
            queryParameters['asset_type'] = requestParameters.asset_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.reviewed_by !== undefined) {
            queryParameters['reviewed_by'] = requestParameters.reviewed_by;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/assets/reported`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityReportedAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebAssetsReportedGet(requestParameters: V1MonitWebAssetsReportedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityReportedAssetsResponse> {
        const response = await this.v1MonitWebAssetsReportedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_put <PUT>
     */
    async v1MonitWebAssetsReportedPutRaw(requestParameters: V1MonitWebAssetsReportedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitUpdateReportedAssetResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/assets/reported`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitRootUpdateAssetReportRequestToJSON(requestParameters.MonitRootUpdateAssetReportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitUpdateReportedAssetResultFromJSON(jsonValue));
    }

    /**
     * on_put <PUT>
     */
    async v1MonitWebAssetsReportedPut(requestParameters: V1MonitWebAssetsReportedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitUpdateReportedAssetResult> {
        const response = await this.v1MonitWebAssetsReportedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_delete <DELETE>
     */
    async v1MonitWebContactsContactIdDeleteRaw(requestParameters: V1MonitWebContactsContactIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1MonitWebContactsContactIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * on_delete <DELETE>
     */
    async v1MonitWebContactsContactIdDelete(requestParameters: V1MonitWebContactsContactIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebContactsContactIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsContactIdGetRaw(requestParameters: V1MonitWebContactsContactIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityContactResponse>> {
        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1MonitWebContactsContactIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityContactResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsContactIdGet(requestParameters: V1MonitWebContactsContactIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityContactResponse> {
        const response = await this.v1MonitWebContactsContactIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebContactsContactIdPostRaw(requestParameters: V1MonitWebContactsContactIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitAddOrUpdateEntityContactResponse>> {
        if (requestParameters.contact_id === null || requestParameters.contact_id === undefined) {
            throw new runtime.RequiredError('contact_id','Required parameter requestParameters.contact_id was null or undefined when calling v1MonitWebContactsContactIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contact_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitRootAddUpdateContactRequestToJSON(requestParameters.MonitRootAddUpdateContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitAddOrUpdateEntityContactResponseFromJSON(jsonValue));
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebContactsContactIdPost(requestParameters: V1MonitWebContactsContactIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitAddOrUpdateEntityContactResponse> {
        const response = await this.v1MonitWebContactsContactIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsGetRaw(requestParameters: V1MonitWebContactsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityContactResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityContactResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsGet(requestParameters: V1MonitWebContactsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityContactResponse> {
        const response = await this.v1MonitWebContactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebContactsPostRaw(requestParameters: V1MonitWebContactsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitAddOrUpdateEntityContactResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitRootAddUpdateContactRequestToJSON(requestParameters.MonitRootAddUpdateContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitAddOrUpdateEntityContactResponseFromJSON(jsonValue));
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebContactsPost(requestParameters: V1MonitWebContactsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitAddOrUpdateEntityContactResponse> {
        const response = await this.v1MonitWebContactsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsSearchGetRaw(requestParameters: V1MonitWebContactsSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityContactResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.contact_type !== undefined) {
            queryParameters['contact_type'] = requestParameters.contact_type;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.external_ids !== undefined) {
            queryParameters['external_ids'] = requestParameters.external_ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/contacts/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityContactResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebContactsSearchGet(requestParameters: V1MonitWebContactsSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityContactResponse> {
        const response = await this.v1MonitWebContactsSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebDomainsGetRaw(requestParameters: V1MonitWebDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebDomainsGet(requestParameters: V1MonitWebDomainsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebEntityAssetsHistoricalRetrieveRaw(requestParameters: V1MonitWebEntityAssetsHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityAssetsHistoricalRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityAssetsHistoricalRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/historical`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityAssetsHistoricalRetrieve(requestParameters: V1MonitWebEntityAssetsHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityAssetsHistoricalRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityAssetsScansRetrieveRaw(requestParameters: V1MonitWebEntityAssetsScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityAssetsScansRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityAssetsScansRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/scans`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityAssetsScansRetrieve(requestParameters: V1MonitWebEntityAssetsScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityAssetsScansRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityAssetsStatsRetrieveRaw(requestParameters: V1MonitWebEntityAssetsStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityAssetsStatsRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityAssetsStatsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/stats`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityAssetsStatsRetrieve(requestParameters: V1MonitWebEntityAssetsStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityAssetsStatsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityDomainsHistoricalRetrieveRaw(requestParameters: V1MonitWebEntityDomainsHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityDomainsHistoricalRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityDomainsHistoricalRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}/historical`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityDomainsHistoricalRetrieve(requestParameters: V1MonitWebEntityDomainsHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityDomainsHistoricalRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityDomainsScansRetrieveRaw(requestParameters: V1MonitWebEntityDomainsScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityDomainsScansRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityDomainsScansRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}/scans`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityDomainsScansRetrieve(requestParameters: V1MonitWebEntityDomainsScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityDomainsScansRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityDomainsStatsRetrieveRaw(requestParameters: V1MonitWebEntityDomainsStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityDomainsStatsRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityDomainsStatsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}/stats`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityDomainsStatsRetrieve(requestParameters: V1MonitWebEntityDomainsStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityDomainsStatsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetFindingsGetRaw(requestParameters: V1MonitWebEntityEntityIdAssetsAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetFindingsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.asset2 !== undefined) {
            queryParameters['asset'] = requestParameters.asset2;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/findings`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetFindingsGet(requestParameters: V1MonitWebEntityEntityIdAssetsAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsResponse> {
        const response = await this.v1MonitWebEntityEntityIdAssetsAssetFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_asset <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetGetRaw(requestParameters: V1MonitWebEntityEntityIdAssetsAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * on_get_asset <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetGet(requestParameters: V1MonitWebEntityEntityIdAssetsAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityEntityIdAssetsAssetGetRaw(requestParameters, initOverrides);
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebEntityEntityIdAssetsAssetReportPostRaw(requestParameters: V1MonitWebEntityEntityIdAssetsAssetReportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityReportedAssetResult>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetReportPost.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetReportPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/report`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitReportAssetRequestToJSON(requestParameters.MonitReportAssetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityReportedAssetResultFromJSON(jsonValue));
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebEntityEntityIdAssetsAssetReportPost(requestParameters: V1MonitWebEntityEntityIdAssetsAssetReportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityReportedAssetResult> {
        const response = await this.v1MonitWebEntityEntityIdAssetsAssetReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetTechsGetRaw(requestParameters: V1MonitWebEntityEntityIdAssetsAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetTechResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetTechsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdAssetsAssetTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/{asset}/techs`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetTechResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsAssetTechsGet(requestParameters: V1MonitWebEntityEntityIdAssetsAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetTechResponse> {
        const response = await this.v1MonitWebEntityEntityIdAssetsAssetTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsGetRaw(requestParameters: V1MonitWebEntityEntityIdAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsGet(requestParameters: V1MonitWebEntityEntityIdAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebEntityEntityIdAssetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsReportedGetRaw(requestParameters: V1MonitWebEntityEntityIdAssetsReportedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityReportedAssetsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdAssetsReportedGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.asset_type !== undefined) {
            queryParameters['asset_type'] = requestParameters.asset_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.reviewed_by !== undefined) {
            queryParameters['reviewed_by'] = requestParameters.reviewed_by;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/assets/reported`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityReportedAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdAssetsReportedGet(requestParameters: V1MonitWebEntityEntityIdAssetsReportedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityReportedAssetsResponse> {
        const response = await this.v1MonitWebEntityEntityIdAssetsReportedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_counts <GET>
     */
    async v1MonitWebEntityEntityIdDataleaksCountsGetRaw(requestParameters: V1MonitWebEntityEntityIdDataleaksCountsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitEntityDataleaksCountResponse>>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDataleaksCountsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/dataleaks/counts`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitEntityDataleaksCountResponseFromJSON));
    }

    /**
     * on_get_counts <GET>
     */
    async v1MonitWebEntityEntityIdDataleaksCountsGet(requestParameters: V1MonitWebEntityEntityIdDataleaksCountsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitEntityDataleaksCountResponse>> {
        const response = await this.v1MonitWebEntityEntityIdDataleaksCountsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdDataleaksGetRaw(requestParameters: V1MonitWebEntityEntityIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityDataleaksStandardResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDataleaksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/dataleaks`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityDataleaksStandardResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdDataleaksGet(requestParameters: V1MonitWebEntityEntityIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityDataleaksStandardResponse> {
        const response = await this.v1MonitWebEntityEntityIdDataleaksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetFindingsGetRaw(requestParameters: V1MonitWebEntityEntityIdDomainsAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetFindingsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.asset2 !== undefined) {
            queryParameters['asset'] = requestParameters.asset2;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}/findings`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetFindingsGet(requestParameters: V1MonitWebEntityEntityIdDomainsAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsResponse> {
        const response = await this.v1MonitWebEntityEntityIdDomainsAssetFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_asset_domain <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetGetRaw(requestParameters: V1MonitWebEntityEntityIdDomainsAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityAssetsDomainsResult>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityAssetsDomainsResultFromJSON(jsonValue));
    }

    /**
     * on_get_asset_domain <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetGet(requestParameters: V1MonitWebEntityEntityIdDomainsAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityAssetsDomainsResult> {
        const response = await this.v1MonitWebEntityEntityIdDomainsAssetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetTechsGetRaw(requestParameters: V1MonitWebEntityEntityIdDomainsAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetTechResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetTechsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdDomainsAssetTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains/{asset}/techs`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetTechResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdDomainsAssetTechsGet(requestParameters: V1MonitWebEntityEntityIdDomainsAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetTechResponse> {
        const response = await this.v1MonitWebEntityEntityIdDomainsAssetTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdDomainsGetRaw(requestParameters: V1MonitWebEntityEntityIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdDomainsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/domains`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdDomainsGet(requestParameters: V1MonitWebEntityEntityIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebEntityEntityIdDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdFindingsAggregateGetRaw(requestParameters: V1MonitWebEntityEntityIdFindingsAggregateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsAggResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdFindingsAggregateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.exclude_reported !== undefined) {
            queryParameters['exclude_reported'] = requestParameters.exclude_reported;
        }

        if (requestParameters.samples !== undefined) {
            queryParameters['samples'] = requestParameters.samples;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.show_muted_status !== undefined) {
            queryParameters['show_muted_status'] = requestParameters.show_muted_status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.muted_status !== undefined) {
            queryParameters['muted_status'] = requestParameters.muted_status;
        }

        if (requestParameters.first_detected_from !== undefined) {
            queryParameters['first_detected_from'] = requestParameters.first_detected_from;
        }

        if (requestParameters.first_detected_to !== undefined) {
            queryParameters['first_detected_to'] = requestParameters.first_detected_to;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.n_assets !== undefined) {
            queryParameters['n_assets'] = requestParameters.n_assets;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.no_actions !== undefined) {
            queryParameters['no_actions'] = requestParameters.no_actions;
        }

        if (requestParameters.contingency_status !== undefined) {
            queryParameters['contingency_status'] = requestParameters.contingency_status;
        }

        if (requestParameters.is_contingency !== undefined) {
            queryParameters['is_contingency'] = requestParameters.is_contingency;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/findings/aggregate`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsAggResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdFindingsAggregateGet(requestParameters: V1MonitWebEntityEntityIdFindingsAggregateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsAggResponse> {
        const response = await this.v1MonitWebEntityEntityIdFindingsAggregateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdFindingsGetRaw(requestParameters: V1MonitWebEntityEntityIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/findings`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdFindingsGet(requestParameters: V1MonitWebEntityEntityIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsResponse> {
        const response = await this.v1MonitWebEntityEntityIdFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_top <GET>
     */
    async v1MonitWebEntityEntityIdFindingsTopGetRaw(requestParameters: V1MonitWebEntityEntityIdFindingsTopGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitEntityFindingsTopResult>>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdFindingsTopGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/findings/top`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitEntityFindingsTopResultFromJSON));
    }

    /**
     * on_get_top <GET>
     */
    async v1MonitWebEntityEntityIdFindingsTopGet(requestParameters: V1MonitWebEntityEntityIdFindingsTopGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitEntityFindingsTopResult>> {
        const response = await this.v1MonitWebEntityEntityIdFindingsTopGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdGetRaw(requestParameters: V1MonitWebEntityEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityRelationResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityRelationResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdGet(requestParameters: V1MonitWebEntityEntityIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityRelationResponse> {
        const response = await this.v1MonitWebEntityEntityIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdHoneypotsGetRaw(requestParameters: V1MonitWebEntityEntityIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityHoneypotsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdHoneypotsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.tags !== undefined) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/honeypots`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityHoneypotsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdHoneypotsGet(requestParameters: V1MonitWebEntityEntityIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityHoneypotsResponse> {
        const response = await this.v1MonitWebEntityEntityIdHoneypotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetFindingsGetRaw(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetFindingsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.asset2 !== undefined) {
            queryParameters['asset'] = requestParameters.asset2;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}/findings`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetFindingsGet(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsResponse> {
        const response = await this.v1MonitWebEntityEntityIdIpAddressesAssetFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_asset_ip_address <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetGetRaw(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityAssetsIpAddressesResult>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityAssetsIpAddressesResultFromJSON(jsonValue));
    }

    /**
     * on_get_asset_ip_address <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetGet(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityAssetsIpAddressesResult> {
        const response = await this.v1MonitWebEntityEntityIdIpAddressesAssetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetTechsGetRaw(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetTechResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetTechsGet.');
        }

        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesAssetTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}/techs`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetTechResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesAssetTechsGet(requestParameters: V1MonitWebEntityEntityIdIpAddressesAssetTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetTechResponse> {
        const response = await this.v1MonitWebEntityEntityIdIpAddressesAssetTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesGetRaw(requestParameters: V1MonitWebEntityEntityIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdIpAddressesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdIpAddressesGet(requestParameters: V1MonitWebEntityEntityIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebEntityEntityIdIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdKeywordsGetRaw(requestParameters: V1MonitWebEntityEntityIdKeywordsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityKeywordsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdKeywordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/keywords`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityKeywordsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdKeywordsGet(requestParameters: V1MonitWebEntityEntityIdKeywordsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityKeywordsResponse> {
        const response = await this.v1MonitWebEntityEntityIdKeywordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdLookalikesGetRaw(requestParameters: V1MonitWebEntityEntityIdLookalikesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityLookalikesResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdLookalikesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.mx !== undefined) {
            queryParameters['mx'] = requestParameters.mx;
        }

        if (requestParameters.ssl !== undefined) {
            queryParameters['ssl'] = requestParameters.ssl;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/lookalikes`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityLookalikesResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdLookalikesGet(requestParameters: V1MonitWebEntityEntityIdLookalikesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityLookalikesResponse> {
        const response = await this.v1MonitWebEntityEntityIdLookalikesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdMappingGetRaw(requestParameters: V1MonitWebEntityEntityIdMappingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityMappingResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdMappingGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/mapping`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityMappingResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdMappingGet(requestParameters: V1MonitWebEntityEntityIdMappingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityMappingResponse> {
        const response = await this.v1MonitWebEntityEntityIdMappingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdNotificationsGetRaw(requestParameters: V1MonitWebEntityEntityIdNotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedGetEntityNotificationsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdNotificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.notification_type !== undefined) {
            queryParameters['notification_type'] = requestParameters.notification_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.contact_type !== undefined) {
            queryParameters['contact_type'] = requestParameters.contact_type;
        }

        if (requestParameters.entity_id2 !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id2;
        }

        if (requestParameters.external_id !== undefined) {
            queryParameters['external_id'] = requestParameters.external_id;
        }

        if (requestParameters.include_content !== undefined) {
            queryParameters['include_content'] = requestParameters.include_content;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/notifications`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedGetEntityNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdNotificationsGet(requestParameters: V1MonitWebEntityEntityIdNotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedGetEntityNotificationsResponse> {
        const response = await this.v1MonitWebEntityEntityIdNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdPastebinsGetRaw(requestParameters: V1MonitWebEntityEntityIdPastebinsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityPastebinResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdPastebinsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/pastebins`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityPastebinResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdPastebinsGet(requestParameters: V1MonitWebEntityEntityIdPastebinsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityPastebinResponse> {
        const response = await this.v1MonitWebEntityEntityIdPastebinsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebEntityEntityIdRemovePostRaw(requestParameters: V1MonitWebEntityEntityIdRemovePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitFindingResolutions>>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdRemovePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/remove`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitFindingResolutionRequestToJSON(requestParameters.MonitFindingResolutionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitFindingResolutionsFromJSON));
    }

    /**
     * on_post <POST>
     */
    async v1MonitWebEntityEntityIdRemovePost(requestParameters: V1MonitWebEntityEntityIdRemovePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitFindingResolutions>> {
        const response = await this.v1MonitWebEntityEntityIdRemovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdRevokeGetRaw(requestParameters: V1MonitWebEntityEntityIdRevokeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitEntityRevokeResponse>>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdRevokeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/revoke`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitEntityRevokeResponseFromJSON));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdRevokeGet(requestParameters: V1MonitWebEntityEntityIdRevokeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitEntityRevokeResponse>> {
        const response = await this.v1MonitWebEntityEntityIdRevokeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_by_day <GET>
     */
    async v1MonitWebEntityEntityIdStatsByDayGetRaw(requestParameters: V1MonitWebEntityEntityIdStatsByDayGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitEntityStatsByDayResponse>>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdStatsByDayGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/stats/by_day`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitEntityStatsByDayResponseFromJSON));
    }

    /**
     * on_get_by_day <GET>
     */
    async v1MonitWebEntityEntityIdStatsByDayGet(requestParameters: V1MonitWebEntityEntityIdStatsByDayGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitEntityStatsByDayResponse>> {
        const response = await this.v1MonitWebEntityEntityIdStatsByDayGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdStatsGetRaw(requestParameters: V1MonitWebEntityEntityIdStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityStatsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdStatsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/stats`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityStatsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdStatsGet(requestParameters: V1MonitWebEntityEntityIdStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityStatsResponse> {
        const response = await this.v1MonitWebEntityEntityIdStatsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdStatsIssuesFoundGetRaw(requestParameters: V1MonitWebEntityEntityIdStatsIssuesFoundGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityStatsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdStatsIssuesFoundGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/stats/issues_found`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityStatsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebEntityEntityIdStatsIssuesFoundGet(requestParameters: V1MonitWebEntityEntityIdStatsIssuesFoundGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityStatsResponse> {
        const response = await this.v1MonitWebEntityEntityIdStatsIssuesFoundGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTechsGetRaw(requestParameters: V1MonitWebEntityEntityIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityTechResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vuln !== undefined) {
            queryParameters['vuln'] = requestParameters.vuln;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/techs`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityTechResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTechsGet(requestParameters: V1MonitWebEntityEntityIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityTechResponse> {
        const response = await this.v1MonitWebEntityEntityIdTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTorrentsGetRaw(requestParameters: V1MonitWebEntityEntityIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityTorrentsResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdTorrentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/torrents`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityTorrentsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTorrentsGet(requestParameters: V1MonitWebEntityEntityIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityTorrentsResponse> {
        const response = await this.v1MonitWebEntityEntityIdTorrentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTriggerGetRaw(requestParameters: V1MonitWebEntityEntityIdTriggerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityTriggerResponse>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityEntityIdTriggerGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.nocache !== undefined) {
            queryParameters['nocache'] = requestParameters.nocache;
        }

        if (requestParameters.trial !== undefined) {
            queryParameters['trial'] = requestParameters.trial;
        }

        if (requestParameters.quote_uuid !== undefined) {
            queryParameters['quote_uuid'] = requestParameters.quote_uuid;
        }

        if (requestParameters.lite_enum !== undefined) {
            queryParameters['lite_enum'] = requestParameters.lite_enum;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/trigger`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityTriggerResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityEntityIdTriggerGet(requestParameters: V1MonitWebEntityEntityIdTriggerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityTriggerResponse> {
        const response = await this.v1MonitWebEntityEntityIdTriggerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebEntityFindingsCreateRaw(requestParameters: V1MonitWebEntityFindingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityFindingsCreate.');
        }

        if (requestParameters.finding_id === null || requestParameters.finding_id === undefined) {
            throw new runtime.RequiredError('finding_id','Required parameter requestParameters.finding_id was null or undefined when calling v1MonitWebEntityFindingsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/findings/{finding_id}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"finding_id"}}`, encodeURIComponent(String(requestParameters.finding_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityFindingsCreate(requestParameters: V1MonitWebEntityFindingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityFindingsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityGraphRetrieveRaw(requestParameters: V1MonitWebEntityGraphRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityGraphRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/graph`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityGraphRetrieve(requestParameters: V1MonitWebEntityGraphRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityGraphRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityHistoricalCreateRaw(requestParameters: V1MonitWebEntityHistoricalCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityHistoricalCreate.');
        }

        if (requestParameters.finding_id === null || requestParameters.finding_id === undefined) {
            throw new runtime.RequiredError('finding_id','Required parameter requestParameters.finding_id was null or undefined when calling v1MonitWebEntityHistoricalCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/historical/{finding_id}`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))).replace(`{${"finding_id"}}`, encodeURIComponent(String(requestParameters.finding_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityHistoricalCreate(requestParameters: V1MonitWebEntityHistoricalCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityHistoricalCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityHistoricalRetrieveRaw(requestParameters: V1MonitWebEntityHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityHistoricalRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/historical`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityHistoricalRetrieve(requestParameters: V1MonitWebEntityHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityHistoricalRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityHistoricalTopRetrieveRaw(requestParameters: V1MonitWebEntityHistoricalTopRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityHistoricalTopRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/historical/top`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityHistoricalTopRetrieve(requestParameters: V1MonitWebEntityHistoricalTopRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityHistoricalTopRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesHistoricalRetrieveRaw(requestParameters: V1MonitWebEntityIpAddressesHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityIpAddressesHistoricalRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityIpAddressesHistoricalRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}/historical`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesHistoricalRetrieve(requestParameters: V1MonitWebEntityIpAddressesHistoricalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityIpAddressesHistoricalRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesScansRetrieveRaw(requestParameters: V1MonitWebEntityIpAddressesScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityIpAddressesScansRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityIpAddressesScansRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}/scans`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesScansRetrieve(requestParameters: V1MonitWebEntityIpAddressesScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityIpAddressesScansRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesStatsRetrieveRaw(requestParameters: V1MonitWebEntityIpAddressesStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling v1MonitWebEntityIpAddressesStatsRetrieve.');
        }

        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityIpAddressesStatsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/ip_addresses/{asset}/stats`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityIpAddressesStatsRetrieve(requestParameters: V1MonitWebEntityIpAddressesStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityIpAddressesStatsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityMappingGetRaw(requestParameters: V1MonitWebEntityMappingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityMappingResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/mapping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityMappingResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityMappingGet(requestParameters: V1MonitWebEntityMappingGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityMappingResponse> {
        const response = await this.v1MonitWebEntityMappingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityNodomainGetRaw(requestParameters: V1MonitWebEntityNodomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityNoDomainResponse>> {
        const queryParameters: any = {};

        if (requestParameters.entity_name !== undefined) {
            queryParameters['entity_name'] = requestParameters.entity_name;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.industry !== undefined) {
            queryParameters['industry'] = requestParameters.industry;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/nodomain`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityNoDomainResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebEntityNodomainGet(requestParameters: V1MonitWebEntityNodomainGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityNoDomainResponse> {
        const response = await this.v1MonitWebEntityNodomainGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebEntityScansRetrieveRaw(requestParameters: V1MonitWebEntityScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntityScansRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/scans`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntityScansRetrieve(requestParameters: V1MonitWebEntityScansRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntityScansRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntitySocialRetrieveRaw(requestParameters: V1MonitWebEntitySocialRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntitySocialRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/social`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntitySocialRetrieve(requestParameters: V1MonitWebEntitySocialRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntitySocialRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebEntitySpidersRetrieveRaw(requestParameters: V1MonitWebEntitySpidersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_id === null || requestParameters.entity_id === undefined) {
            throw new runtime.RequiredError('entity_id','Required parameter requestParameters.entity_id was null or undefined when calling v1MonitWebEntitySpidersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/entity/{entity_id}/spiders`.replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entity_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebEntitySpidersRetrieve(requestParameters: V1MonitWebEntitySpidersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebEntitySpidersRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1MonitWebImpersonateCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/impersonate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebImpersonateCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebImpersonateCreateRaw(initOverrides);
    }

    /**
     */
    async v1MonitWebImporterCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/importer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebImporterCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebImporterCreateRaw(initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebIpAddressesGetRaw(requestParameters: V1MonitWebIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityAssetsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.ip_address !== undefined) {
            queryParameters['ip_address'] = requestParameters.ip_address;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.count_findings_filter !== undefined) {
            queryParameters['count_findings_filter'] = requestParameters.count_findings_filter;
        }

        if (requestParameters.max_level !== undefined) {
            queryParameters['max_level'] = requestParameters.max_level;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.last_detected_from !== undefined) {
            queryParameters['last_detected_from'] = requestParameters.last_detected_from;
        }

        if (requestParameters.last_detected_to !== undefined) {
            queryParameters['last_detected_to'] = requestParameters.last_detected_to;
        }

        if (requestParameters.dns_types !== undefined) {
            queryParameters['dns_types'] = requestParameters.dns_types;
        }

        if (requestParameters.as_name !== undefined) {
            queryParameters['as_name'] = requestParameters.as_name;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/ip_addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityAssetsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebIpAddressesGet(requestParameters: V1MonitWebIpAddressesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityAssetsResponse> {
        const response = await this.v1MonitWebIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebLinkCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebLinkCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebLinkCreateRaw(initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebNotificationsGetRaw(requestParameters: V1MonitWebNotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedGetEntityNotificationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.notification_type !== undefined) {
            queryParameters['notification_type'] = requestParameters.notification_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.contact_type !== undefined) {
            queryParameters['contact_type'] = requestParameters.contact_type;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.external_id !== undefined) {
            queryParameters['external_id'] = requestParameters.external_id;
        }

        if (requestParameters.include_content !== undefined) {
            queryParameters['include_content'] = requestParameters.include_content;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedGetEntityNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebNotificationsGet(requestParameters: V1MonitWebNotificationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedGetEntityNotificationsResponse> {
        const response = await this.v1MonitWebNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebRelatedFindingsGetRaw(requestParameters: V1MonitWebRelatedFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityFindingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.asset !== undefined) {
            queryParameters['asset'] = requestParameters.asset;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.score_category !== undefined) {
            queryParameters['score_category'] = requestParameters.score_category;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.datapoint !== undefined) {
            queryParameters['datapoint'] = requestParameters.datapoint;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.filters_only !== undefined) {
            queryParameters['filters_only'] = requestParameters.filters_only;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.count_findings !== undefined) {
            queryParameters['count_findings'] = requestParameters.count_findings;
        }

        if (requestParameters.exclude_removed !== undefined) {
            queryParameters['exclude_removed'] = requestParameters.exclude_removed;
        }

        if (requestParameters.exclude_ignored !== undefined) {
            queryParameters['exclude_ignored'] = requestParameters.exclude_ignored;
        }

        if (requestParameters.resolution_types !== undefined) {
            queryParameters['resolution_types'] = requestParameters.resolution_types;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/related/findings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityFindingsResponseFromJSON(jsonValue));
    }

    /**
     * on_get_standard <GET>
     */
    async v1MonitWebRelatedFindingsGet(requestParameters: V1MonitWebRelatedFindingsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityFindingsResponse> {
        const response = await this.v1MonitWebRelatedFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedGetRaw(requestParameters: V1MonitWebRelatedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityRelationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.monit_type !== undefined) {
            queryParameters['monit_type'] = requestParameters.monit_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.entity_domain !== undefined) {
            queryParameters['entity_domain'] = requestParameters.entity_domain;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.exclude_entity_id !== undefined) {
            queryParameters['exclude_entity_id'] = requestParameters.exclude_entity_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/related`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityRelationResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedGet(requestParameters: V1MonitWebRelatedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityRelationResponse> {
        const response = await this.v1MonitWebRelatedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebRelatedHistoricalRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/related/historical`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebRelatedHistoricalRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebRelatedHistoricalRetrieveRaw(initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedPolicyholdersGetRaw(requestParameters: V1MonitWebRelatedPolicyholdersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityRelationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.monit_type !== undefined) {
            queryParameters['monit_type'] = requestParameters.monit_type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.entity_domain !== undefined) {
            queryParameters['entity_domain'] = requestParameters.entity_domain;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.exclude_entity_id !== undefined) {
            queryParameters['exclude_entity_id'] = requestParameters.exclude_entity_id;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/related/policyholders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityRelationResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedPolicyholdersGet(requestParameters: V1MonitWebRelatedPolicyholdersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityRelationResponse> {
        const response = await this.v1MonitWebRelatedPolicyholdersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedStatsGetRaw(requestParameters: V1MonitWebRelatedStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitEntityRelationStatsGroups>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/related/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitEntityRelationStatsGroupsFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebRelatedStatsGet(requestParameters: V1MonitWebRelatedStatsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitEntityRelationStatsGroups> {
        const response = await this.v1MonitWebRelatedStatsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebReportCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebReportCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebReportCreateRaw(initOverrides);
    }

    /**
     */
    async v1MonitWebSearchRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebSearchRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebSearchRetrieveRaw(initOverrides);
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebTechsGetRaw(requestParameters: V1MonitWebTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitPaginatedEntityTechResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.source_asset !== undefined) {
            queryParameters['source_asset'] = requestParameters.source_asset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vuln !== undefined) {
            queryParameters['vuln'] = requestParameters.vuln;
        }

        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }

        if (requestParameters.trim !== undefined) {
            queryParameters['trim'] = requestParameters.trim;
        }

        if (requestParameters.tech !== undefined) {
            queryParameters['tech'] = requestParameters.tech;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.EXTERNAL_ID !== undefined && requestParameters.EXTERNAL_ID !== null) {
            headerParameters['EXTERNAL-ID'] = String(requestParameters.EXTERNAL_ID);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/techs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitPaginatedEntityTechResponseFromJSON(jsonValue));
    }

    /**
     * on_get <GET>
     */
    async v1MonitWebTechsGet(requestParameters: V1MonitWebTechsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitPaginatedEntityTechResponse> {
        const response = await this.v1MonitWebTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1MonitWebTokenCreateCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/token/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebTokenCreateCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebTokenCreateCreateRaw(initOverrides);
    }

    /**
     */
    async v1MonitWebTokenListCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/token/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebTokenListCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebTokenListCreateRaw(initOverrides);
    }

    /**
     */
    async v1MonitWebTokenRevokeCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/token/revoke`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebTokenRevokeCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebTokenRevokeCreateRaw(initOverrides);
    }

    /**
     */
    async v1MonitWebVerifyRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/monit/web/verify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1MonitWebVerifyRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1MonitWebVerifyRetrieveRaw(initOverrides);
    }

    /**
     * Get Details For Jira Service Messages
     */
    async v1NotificationsWebAlertsGetRaw(requestParameters: V1NotificationsWebAlertsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationsMessageAsAlertForJiraServiceResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications/web/alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsMessageAsAlertForJiraServiceResponseFromJSON(jsonValue));
    }

    /**
     * Get Details For Jira Service Messages
     */
    async v1NotificationsWebAlertsGet(requestParameters: V1NotificationsWebAlertsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationsMessageAsAlertForJiraServiceResponse> {
        const response = await this.v1NotificationsWebAlertsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Value From Filter Code
     */
    async v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDeleteRaw(requestParameters: V1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.filter_target === null || requestParameters.filter_target === undefined) {
            throw new runtime.RequiredError('filter_target','Required parameter requestParameters.filter_target was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDelete.');
        }

        if (requestParameters.filter_value === null || requestParameters.filter_value === undefined) {
            throw new runtime.RequiredError('filter_value','Required parameter requestParameters.filter_value was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDelete.');
        }

        if (requestParameters.filter_code === null || requestParameters.filter_code === undefined) {
            throw new runtime.RequiredError('filter_code','Required parameter requestParameters.filter_code was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications/web/filters/{filter_target}/{filter_value}/{filter_code}`.replace(`{${"filter_target"}}`, encodeURIComponent(String(requestParameters.filter_target))).replace(`{${"filter_value"}}`, encodeURIComponent(String(requestParameters.filter_value))).replace(`{${"filter_code"}}`, encodeURIComponent(String(requestParameters.filter_code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Value From Filter Code
     */
    async v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDelete(requestParameters: V1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1NotificationsWebFiltersFilterTargetFilterValueFilterCodeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Put Value To Filter Code
     */
    async v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePutRaw(requestParameters: V1NotificationsWebFiltersFilterTargetFilterValueFilterCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.filter_target === null || requestParameters.filter_target === undefined) {
            throw new runtime.RequiredError('filter_target','Required parameter requestParameters.filter_target was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePut.');
        }

        if (requestParameters.filter_value === null || requestParameters.filter_value === undefined) {
            throw new runtime.RequiredError('filter_value','Required parameter requestParameters.filter_value was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePut.');
        }

        if (requestParameters.filter_code === null || requestParameters.filter_code === undefined) {
            throw new runtime.RequiredError('filter_code','Required parameter requestParameters.filter_code was null or undefined when calling v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications/web/filters/{filter_target}/{filter_value}/{filter_code}`.replace(`{${"filter_target"}}`, encodeURIComponent(String(requestParameters.filter_target))).replace(`{${"filter_value"}}`, encodeURIComponent(String(requestParameters.filter_value))).replace(`{${"filter_code"}}`, encodeURIComponent(String(requestParameters.filter_code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Put Value To Filter Code
     */
    async v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePut(requestParameters: V1NotificationsWebFiltersFilterTargetFilterValueFilterCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1NotificationsWebFiltersFilterTargetFilterValueFilterCodePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All Filters On Value
     */
    async v1NotificationsWebFiltersFilterTargetTargetValueGetRaw(requestParameters: V1NotificationsWebFiltersFilterTargetTargetValueGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationsFilterResponse>> {
        if (requestParameters.filter_target === null || requestParameters.filter_target === undefined) {
            throw new runtime.RequiredError('filter_target','Required parameter requestParameters.filter_target was null or undefined when calling v1NotificationsWebFiltersFilterTargetTargetValueGet.');
        }

        if (requestParameters.target_value === null || requestParameters.target_value === undefined) {
            throw new runtime.RequiredError('target_value','Required parameter requestParameters.target_value was null or undefined when calling v1NotificationsWebFiltersFilterTargetTargetValueGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications/web/filters/{filter_target}/{target_value}`.replace(`{${"filter_target"}}`, encodeURIComponent(String(requestParameters.filter_target))).replace(`{${"target_value"}}`, encodeURIComponent(String(requestParameters.target_value))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsFilterResponseFromJSON(jsonValue));
    }

    /**
     * Get All Filters On Value
     */
    async v1NotificationsWebFiltersFilterTargetTargetValueGet(requestParameters: V1NotificationsWebFiltersFilterTargetTargetValueGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationsFilterResponse> {
        const response = await this.v1NotificationsWebFiltersFilterTargetTargetValueGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Records
     */
    async v1NotificationsWebRecordsGetRaw(requestParameters: V1NotificationsWebRecordsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationsPaginatedRecordsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.client_id !== undefined) {
            queryParameters['client_id'] = requestParameters.client_id;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.entity_id !== undefined) {
            queryParameters['entity_id'] = requestParameters.entity_id;
        }

        if (requestParameters.entity_name !== undefined) {
            queryParameters['entity_name'] = requestParameters.entity_name;
        }

        if (requestParameters.contact_type !== undefined) {
            queryParameters['contact_type'] = requestParameters.contact_type;
        }

        if (requestParameters.record_type !== undefined) {
            queryParameters['record_type'] = requestParameters.record_type;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications/web/records`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsPaginatedRecordsResponseFromJSON(jsonValue));
    }

    /**
     * Get Records
     */
    async v1NotificationsWebRecordsGet(requestParameters: V1NotificationsWebRecordsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationsPaginatedRecordsResponse> {
        const response = await this.v1NotificationsWebRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdCsvCsvGetRaw(requestParameters: V1SecreviewDocumentsProcessIdCsvCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewDocumentsProcessIdCsvCsvGet.');
        }

        if (requestParameters.csv === null || requestParameters.csv === undefined) {
            throw new runtime.RequiredError('csv','Required parameter requestParameters.csv was null or undefined when calling v1SecreviewDocumentsProcessIdCsvCsvGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/documents/{process_id}/csv/{csv}`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))).replace(`{${"csv"}}`, encodeURIComponent(String(requestParameters.csv))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdCsvCsvGet(requestParameters: V1SecreviewDocumentsProcessIdCsvCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1SecreviewDocumentsProcessIdCsvCsvGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdIndexGetRaw(requestParameters: V1SecreviewDocumentsProcessIdIndexGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewDocumentsProcessIdIndexGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/documents/{process_id}/index`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdIndexGet(requestParameters: V1SecreviewDocumentsProcessIdIndexGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1SecreviewDocumentsProcessIdIndexGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdSecAnalysisCommentGetRaw(requestParameters: V1SecreviewDocumentsProcessIdSecAnalysisCommentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewDocumentsProcessIdSecAnalysisCommentGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/documents/{process_id}/sec_analysis_comment`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdSecAnalysisCommentGet(requestParameters: V1SecreviewDocumentsProcessIdSecAnalysisCommentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1SecreviewDocumentsProcessIdSecAnalysisCommentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdTxtDomainGetRaw(requestParameters: V1SecreviewDocumentsProcessIdTxtDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewDocumentsProcessIdTxtDomainGet.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling v1SecreviewDocumentsProcessIdTxtDomainGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/documents/{process_id}/txt/{domain}`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Document
     */
    async v1SecreviewDocumentsProcessIdTxtDomainGet(requestParameters: V1SecreviewDocumentsProcessIdTxtDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.v1SecreviewDocumentsProcessIdTxtDomainGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Last
     */
    async v1SecreviewWebCrsfEntityListGetRaw(requestParameters: V1SecreviewWebCrsfEntityListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewProcessPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.scan_type) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.account_id !== undefined) {
            queryParameters['account_id'] = requestParameters.account_id;
        }

        if (requestParameters.quote_id !== undefined) {
            queryParameters['quote_id'] = requestParameters.quote_id;
        }

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewProcessPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Last
     */
    async v1SecreviewWebCrsfEntityListGet(requestParameters: V1SecreviewWebCrsfEntityListGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewProcessPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Dataleaks
     */
    async v1SecreviewWebCrsfEntityProcessIdDataleaksGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewDataleaksPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdDataleaksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/dataleaks`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewDataleaksPagedResponseFromJSON(jsonValue));
    }

    /**
     * Dataleaks
     */
    async v1SecreviewWebCrsfEntityProcessIdDataleaksGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewDataleaksPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdDataleaksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Domains
     */
    async v1SecreviewWebCrsfEntityProcessIdDomainsGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewDomainPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdDomainsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/domains`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewDomainPagedResponseFromJSON(jsonValue));
    }

    /**
     * Domains
     */
    async v1SecreviewWebCrsfEntityProcessIdDomainsGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewDomainPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Findings
     */
    async v1SecreviewWebCrsfEntityProcessIdFindingsGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewResolvedFindingsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/findings`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewResolvedFindingsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Findings
     */
    async v1SecreviewWebCrsfEntityProcessIdFindingsGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewResolvedFindingsPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Status
     */
    async v1SecreviewWebCrsfEntityProcessIdGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewProcess>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewProcessFromJSON(jsonValue));
    }

    /**
     * Get Status
     */
    async v1SecreviewWebCrsfEntityProcessIdGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewProcess> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Honeypots
     */
    async v1SecreviewWebCrsfEntityProcessIdHoneypotsGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewHoneypotsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdHoneypotsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/honeypots`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewHoneypotsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Honeypots
     */
    async v1SecreviewWebCrsfEntityProcessIdHoneypotsGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewHoneypotsPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdHoneypotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ip Addresses
     */
    async v1SecreviewWebCrsfEntityProcessIdIpAddressesGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewIpAddressesPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdIpAddressesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/ip_addresses`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewIpAddressesPagedResponseFromJSON(jsonValue));
    }

    /**
     * Ip Addresses
     */
    async v1SecreviewWebCrsfEntityProcessIdIpAddressesGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewIpAddressesPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke
     */
    async v1SecreviewWebCrsfEntityProcessIdRevokePostRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewGetStatusResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdRevokePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/revoke`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewGetStatusResponseFromJSON(jsonValue));
    }

    /**
     * Revoke
     */
    async v1SecreviewWebCrsfEntityProcessIdRevokePost(requestParameters: V1SecreviewWebCrsfEntityProcessIdRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewGetStatusResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdRevokePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Techs
     */
    async v1SecreviewWebCrsfEntityProcessIdTechsGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewTechsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/techs`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewTechsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Techs
     */
    async v1SecreviewWebCrsfEntityProcessIdTechsGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewTechsPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Torrents
     */
    async v1SecreviewWebCrsfEntityProcessIdTorrentsGetRaw(requestParameters: V1SecreviewWebCrsfEntityProcessIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewTorrentsPagedResponse>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SecreviewWebCrsfEntityProcessIdTorrentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.scan_type !== undefined) {
            queryParameters['scan_type'] = requestParameters.scan_type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/torrents`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewTorrentsPagedResponseFromJSON(jsonValue));
    }

    /**
     * Torrents
     */
    async v1SecreviewWebCrsfEntityProcessIdTorrentsGet(requestParameters: V1SecreviewWebCrsfEntityProcessIdTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewTorrentsPagedResponse> {
        const response = await this.v1SecreviewWebCrsfEntityProcessIdTorrentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validation Trigger
     */
    async v1SecreviewWebCrsfEntityRescanTriggerPostRaw(requestParameters: V1SecreviewWebCrsfEntityRescanTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewValidationTriggerResponse>> {
        if (requestParameters.CRSFSecReviewValidationScanTrigger === null || requestParameters.CRSFSecReviewValidationScanTrigger === undefined) {
            throw new runtime.RequiredError('CRSFSecReviewValidationScanTrigger','Required parameter requestParameters.CRSFSecReviewValidationScanTrigger was null or undefined when calling v1SecreviewWebCrsfEntityRescanTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/rescan/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CRSFSecReviewValidationScanTriggerToJSON(requestParameters.CRSFSecReviewValidationScanTrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewValidationTriggerResponseFromJSON(jsonValue));
    }

    /**
     * Validation Trigger
     */
    async v1SecreviewWebCrsfEntityRescanTriggerPost(requestParameters: V1SecreviewWebCrsfEntityRescanTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewValidationTriggerResponse> {
        const response = await this.v1SecreviewWebCrsfEntityRescanTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger Issue
     */
    async v1SecreviewWebCrsfEntityTriggerIssuePostRaw(requestParameters: V1SecreviewWebCrsfEntityTriggerIssuePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewTriggerScanIssueResponse>> {
        if (requestParameters.CRSFSecReviewTriggerScanIssueRequest === null || requestParameters.CRSFSecReviewTriggerScanIssueRequest === undefined) {
            throw new runtime.RequiredError('CRSFSecReviewTriggerScanIssueRequest','Required parameter requestParameters.CRSFSecReviewTriggerScanIssueRequest was null or undefined when calling v1SecreviewWebCrsfEntityTriggerIssuePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/trigger/issue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CRSFSecReviewTriggerScanIssueRequestToJSON(requestParameters.CRSFSecReviewTriggerScanIssueRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewTriggerScanIssueResponseFromJSON(jsonValue));
    }

    /**
     * Trigger Issue
     */
    async v1SecreviewWebCrsfEntityTriggerIssuePost(requestParameters: V1SecreviewWebCrsfEntityTriggerIssuePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewTriggerScanIssueResponse> {
        const response = await this.v1SecreviewWebCrsfEntityTriggerIssuePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger No Issue
     */
    async v1SecreviewWebCrsfEntityTriggerNoIssuePostRaw(requestParameters: V1SecreviewWebCrsfEntityTriggerNoIssuePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CRSFSecReviewTriggerNoIssueResponse>> {
        if (requestParameters.CRSFSecReviewTriggerNoIssue === null || requestParameters.CRSFSecReviewTriggerNoIssue === undefined) {
            throw new runtime.RequiredError('CRSFSecReviewTriggerNoIssue','Required parameter requestParameters.CRSFSecReviewTriggerNoIssue was null or undefined when calling v1SecreviewWebCrsfEntityTriggerNoIssuePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/trigger/no-issue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CRSFSecReviewTriggerNoIssueToJSON(requestParameters.CRSFSecReviewTriggerNoIssue),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CRSFSecReviewTriggerNoIssueResponseFromJSON(jsonValue));
    }

    /**
     * Trigger No Issue
     */
    async v1SecreviewWebCrsfEntityTriggerNoIssuePost(requestParameters: V1SecreviewWebCrsfEntityTriggerNoIssuePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CRSFSecReviewTriggerNoIssueResponse> {
        const response = await this.v1SecreviewWebCrsfEntityTriggerNoIssuePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect Comment
     */
    async v1SecreviewWebEntityEntityDomainCommentGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainCommentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainCommentGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/comment`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Comment
     */
    async v1SecreviewWebEntityEntityDomainCommentGet(requestParameters: V1SecreviewWebEntityEntityDomainCommentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SecreviewWebEntityEntityDomainCommentGetRaw(requestParameters, initOverrides);
    }

    /**
     * Dataleaks
     */
    async v1SecreviewWebEntityEntityDomainDataleaksGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainDataleaksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/dataleaks`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Dataleaks
     */
    async v1SecreviewWebEntityEntityDomainDataleaksGet(requestParameters: V1SecreviewWebEntityEntityDomainDataleaksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainDataleaksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Domains
     */
    async v1SecreviewWebEntityEntityDomainDomainsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainDomainsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.blocklists !== undefined) {
            queryParameters['blocklists'] = requestParameters.blocklists;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/domains`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Domains
     */
    async v1SecreviewWebEntityEntityDomainDomainsGet(requestParameters: V1SecreviewWebEntityEntityDomainDomainsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extscans
     */
    async v1SecreviewWebEntityEntityDomainExtscansGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainExtscansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRExtScanPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainExtscansGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.ext_type !== undefined) {
            queryParameters['ext_type'] = requestParameters.ext_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/extscans`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRExtScanPagedResponseFromJSON(jsonValue));
    }

    /**
     * Extscans
     */
    async v1SecreviewWebEntityEntityDomainExtscansGet(requestParameters: V1SecreviewWebEntityEntityDomainExtscansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRExtScanPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainExtscansGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Findings
     */
    async v1SecreviewWebEntityEntityDomainFindingsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainFindingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.data_type !== undefined) {
            queryParameters['data_type'] = requestParameters.data_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/findings`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Findings
     */
    async v1SecreviewWebEntityEntityDomainFindingsGet(requestParameters: V1SecreviewWebEntityEntityDomainFindingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainFindingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Entity
     */
    async v1SecreviewWebEntityEntityDomainGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewEntity>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewEntityFromJSON(jsonValue));
    }

    /**
     * Entity
     */
    async v1SecreviewWebEntityEntityDomainGet(requestParameters: V1SecreviewWebEntityEntityDomainGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewEntity> {
        const response = await this.v1SecreviewWebEntityEntityDomainGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Honeypots
     */
    async v1SecreviewWebEntityEntityDomainHoneypotsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainHoneypotsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/honeypots`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Honeypots
     */
    async v1SecreviewWebEntityEntityDomainHoneypotsGet(requestParameters: V1SecreviewWebEntityEntityDomainHoneypotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainHoneypotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect Index
     */
    async v1SecreviewWebEntityEntityDomainIndexGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainIndexGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainIndexGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/index`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Index
     */
    async v1SecreviewWebEntityEntityDomainIndexGet(requestParameters: V1SecreviewWebEntityEntityDomainIndexGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SecreviewWebEntityEntityDomainIndexGetRaw(requestParameters, initOverrides);
    }

    /**
     * Ip Addresses
     */
    async v1SecreviewWebEntityEntityDomainIpAddressesGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainIpAddressesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.blocklists !== undefined) {
            queryParameters['blocklists'] = requestParameters.blocklists;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/ip_addresses`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Ip Addresses
     */
    async v1SecreviewWebEntityEntityDomainIpAddressesGet(requestParameters: V1SecreviewWebEntityEntityDomainIpAddressesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainIpAddressesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Entity
     */
    async v1SecreviewWebEntityEntityDomainListGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/list`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Entity
     */
    async v1SecreviewWebEntityEntityDomainListGet(requestParameters: V1SecreviewWebEntityEntityDomainListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect Posted Decisions Json
     */
    async v1SecreviewWebEntityEntityDomainPostedDecisionsJsonGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainPostedDecisionsJsonGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainPostedDecisionsJsonGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/posted_decisions_json`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Posted Decisions Json
     */
    async v1SecreviewWebEntityEntityDomainPostedDecisionsJsonGet(requestParameters: V1SecreviewWebEntityEntityDomainPostedDecisionsJsonGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SecreviewWebEntityEntityDomainPostedDecisionsJsonGetRaw(requestParameters, initOverrides);
    }

    /**
     * Redirect Report
     */
    async v1SecreviewWebEntityEntityDomainReportGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainReportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainReportGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/report`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Report
     */
    async v1SecreviewWebEntityEntityDomainReportGet(requestParameters: V1SecreviewWebEntityEntityDomainReportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SecreviewWebEntityEntityDomainReportGetRaw(requestParameters, initOverrides);
    }

    /**
     * Revoke
     */
    async v1SecreviewWebEntityEntityDomainRevokePostRaw(requestParameters: V1SecreviewWebEntityEntityDomainRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewEntity>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainRevokePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/revoke`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewEntityFromJSON(jsonValue));
    }

    /**
     * Revoke
     */
    async v1SecreviewWebEntityEntityDomainRevokePost(requestParameters: V1SecreviewWebEntityEntityDomainRevokePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewEntity> {
        const response = await this.v1SecreviewWebEntityEntityDomainRevokePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Scans
     */
    async v1SecreviewWebEntityEntityDomainScansGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainScansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSREntityPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainScansGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.data_type !== undefined) {
            queryParameters['data_type'] = requestParameters.data_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.cves !== undefined) {
            queryParameters['cves'] = requestParameters.cves;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        if (requestParameters.no_data !== undefined) {
            queryParameters['no_data'] = requestParameters.no_data;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/scans`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSREntityPagedResponseFromJSON(jsonValue));
    }

    /**
     * Scans
     */
    async v1SecreviewWebEntityEntityDomainScansGet(requestParameters: V1SecreviewWebEntityEntityDomainScansGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSREntityPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainScansGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stats
     */
    async v1SecreviewWebEntityEntityDomainStatsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewStats>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainStatsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.data_type !== undefined) {
            queryParameters['data_type'] = requestParameters.data_type;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/stats`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewStatsFromJSON(jsonValue));
    }

    /**
     * Stats
     */
    async v1SecreviewWebEntityEntityDomainStatsGet(requestParameters: V1SecreviewWebEntityEntityDomainStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewStats> {
        const response = await this.v1SecreviewWebEntityEntityDomainStatsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process Status
     */
    async v1SecreviewWebEntityEntityDomainStatusGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewProcess>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainStatusGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/status`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewProcessFromJSON(jsonValue));
    }

    /**
     * Process Status
     */
    async v1SecreviewWebEntityEntityDomainStatusGet(requestParameters: V1SecreviewWebEntityEntityDomainStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewProcess> {
        const response = await this.v1SecreviewWebEntityEntityDomainStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Techs
     */
    async v1SecreviewWebEntityEntityDomainTechsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainTechsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.data_type !== undefined) {
            queryParameters['data_type'] = requestParameters.data_type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.cves !== undefined) {
            queryParameters['cves'] = requestParameters.cves;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/techs`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Techs
     */
    async v1SecreviewWebEntityEntityDomainTechsGet(requestParameters: V1SecreviewWebEntityEntityDomainTechsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainTechsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Torrents
     */
    async v1SecreviewWebEntityEntityDomainTorrentsGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainTorrentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        if (requestParameters.order_by !== undefined) {
            queryParameters['order_by'] = requestParameters.order_by;
        }

        if (requestParameters.desc !== undefined) {
            queryParameters['desc'] = requestParameters.desc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/torrents`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Torrents
     */
    async v1SecreviewWebEntityEntityDomainTorrentsGet(requestParameters: V1SecreviewWebEntityEntityDomainTorrentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebEntityEntityDomainTorrentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect Txt
     */
    async v1SecreviewWebEntityEntityDomainTxtGetRaw(requestParameters: V1SecreviewWebEntityEntityDomainTxtGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entity_domain === null || requestParameters.entity_domain === undefined) {
            throw new runtime.RequiredError('entity_domain','Required parameter requestParameters.entity_domain was null or undefined when calling v1SecreviewWebEntityEntityDomainTxtGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.process_id !== undefined) {
            queryParameters['process_id'] = requestParameters.process_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/entity/{entity_domain}/txt`.replace(`{${"entity_domain"}}`, encodeURIComponent(String(requestParameters.entity_domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect Txt
     */
    async v1SecreviewWebEntityEntityDomainTxtGet(requestParameters: V1SecreviewWebEntityEntityDomainTxtGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SecreviewWebEntityEntityDomainTxtGetRaw(requestParameters, initOverrides);
    }

    /**
     * Insurance Trigger
     */
    async v1SecreviewWebInsuranceTriggerPostRaw(requestParameters: V1SecreviewWebInsuranceTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInsuranceTriggerInsuranceTriggerPost>> {
        if (requestParameters.SecReviewInsuranceTrigger === null || requestParameters.SecReviewInsuranceTrigger === undefined) {
            throw new runtime.RequiredError('SecReviewInsuranceTrigger','Required parameter requestParameters.SecReviewInsuranceTrigger was null or undefined when calling v1SecreviewWebInsuranceTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/insurance/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecReviewInsuranceTriggerToJSON(requestParameters.SecReviewInsuranceTrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInsuranceTriggerInsuranceTriggerPostFromJSON(jsonValue));
    }

    /**
     * Insurance Trigger
     */
    async v1SecreviewWebInsuranceTriggerPost(requestParameters: V1SecreviewWebInsuranceTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInsuranceTriggerInsuranceTriggerPost> {
        const response = await this.v1SecreviewWebInsuranceTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Issues
     */
    async v1SecreviewWebIssuesGetRaw(requestParameters: V1SecreviewWebIssuesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/issues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * Issues
     */
    async v1SecreviewWebIssuesGet(requestParameters: V1SecreviewWebIssuesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebIssuesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Last
     */
    async v1SecreviewWebListGetRaw(requestParameters: V1SecreviewWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecReviewSRPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.issue !== undefined) {
            queryParameters['issue'] = requestParameters.issue;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.page_size !== undefined) {
            queryParameters['page_size'] = requestParameters.page_size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecReviewSRPagedResponseFromJSON(jsonValue));
    }

    /**
     * List Last
     */
    async v1SecreviewWebListGet(requestParameters: V1SecreviewWebListGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecReviewSRPagedResponse> {
        const response = await this.v1SecreviewWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rescan Trigger
     */
    async v1SecreviewWebRescanTriggerPostRaw(requestParameters: V1SecreviewWebRescanTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseRescanTriggerRescanTriggerPost>> {
        if (requestParameters.SecReviewRescanTrigger === null || requestParameters.SecReviewRescanTrigger === undefined) {
            throw new runtime.RequiredError('SecReviewRescanTrigger','Required parameter requestParameters.SecReviewRescanTrigger was null or undefined when calling v1SecreviewWebRescanTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/rescan/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecReviewRescanTriggerToJSON(requestParameters.SecReviewRescanTrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseRescanTriggerRescanTriggerPostFromJSON(jsonValue));
    }

    /**
     * Rescan Trigger
     */
    async v1SecreviewWebRescanTriggerPost(requestParameters: V1SecreviewWebRescanTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseRescanTriggerRescanTriggerPost> {
        const response = await this.v1SecreviewWebRescanTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger
     */
    async v1SecreviewWebTriggerPostRaw(requestParameters: V1SecreviewWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseTriggerTriggerPost>> {
        if (requestParameters.SecReviewTrigger === null || requestParameters.SecReviewTrigger === undefined) {
            throw new runtime.RequiredError('SecReviewTrigger','Required parameter requestParameters.SecReviewTrigger was null or undefined when calling v1SecreviewWebTriggerPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecReviewTriggerToJSON(requestParameters.SecReviewTrigger),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseTriggerTriggerPostFromJSON(jsonValue));
    }

    /**
     * Trigger
     */
    async v1SecreviewWebTriggerPost(requestParameters: V1SecreviewWebTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseTriggerTriggerPost> {
        const response = await this.v1SecreviewWebTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1SrWebCrsfEntityPdfRetrieveRaw(requestParameters: V1SrWebCrsfEntityPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.process_id === null || requestParameters.process_id === undefined) {
            throw new runtime.RequiredError('process_id','Required parameter requestParameters.process_id was null or undefined when calling v1SrWebCrsfEntityPdfRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/secreview/web/crsf_entity/{process_id}/pdf`.replace(`{${"process_id"}}`, encodeURIComponent(String(requestParameters.process_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1SrWebCrsfEntityPdfRetrieve(requestParameters: V1SrWebCrsfEntityPdfRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SrWebCrsfEntityPdfRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Get a PDF copy of a filled in supplemental form instance
     */
    async v1SupplementalsWebInternalAttachmentAttachmentIdPdfGetRaw(requestParameters: V1SupplementalsWebInternalAttachmentAttachmentIdPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachment_id === null || requestParameters.attachment_id === undefined) {
            throw new runtime.RequiredError('attachment_id','Required parameter requestParameters.attachment_id was null or undefined when calling v1SupplementalsWebInternalAttachmentAttachmentIdPdfGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/attachment/{attachment_id}/pdf`.replace(`{${"attachment_id"}}`, encodeURIComponent(String(requestParameters.attachment_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get a PDF copy of a filled in supplemental form instance
     */
    async v1SupplementalsWebInternalAttachmentAttachmentIdPdfGet(requestParameters: V1SupplementalsWebInternalAttachmentAttachmentIdPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SupplementalsWebInternalAttachmentAttachmentIdPdfGetRaw(requestParameters, initOverrides);
    }

    /**
     * Reenable form instance to be filled out by Coalition employee
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPostRaw(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalCreateFormInstanceResponse>> {
        if (requestParameters.form_instance_id === null || requestParameters.form_instance_id === undefined) {
            throw new runtime.RequiredError('form_instance_id','Required parameter requestParameters.form_instance_id was null or undefined when calling v1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance/{form_instance_id}/internal`.replace(`{${"form_instance_id"}}`, encodeURIComponent(String(requestParameters.form_instance_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplementalPostFormInstanceInternalBodyToJSON(requestParameters.SupplementalPostFormInstanceInternalBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalCreateFormInstanceResponseFromJSON(jsonValue));
    }

    /**
     * Reenable form instance to be filled out by Coalition employee
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPost(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalCreateFormInstanceResponse> {
        const response = await this.v1SupplementalsWebInternalFormInstanceFormInstanceIdInternalPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a PDF copy of a filled in supplemental form instance
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGetRaw(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.form_instance_id === null || requestParameters.form_instance_id === undefined) {
            throw new runtime.RequiredError('form_instance_id','Required parameter requestParameters.form_instance_id was null or undefined when calling v1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance/{form_instance_id}/pdf`.replace(`{${"form_instance_id"}}`, encodeURIComponent(String(requestParameters.form_instance_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get a PDF copy of a filled in supplemental form instance
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGet(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SupplementalsWebInternalFormInstanceFormInstanceIdPdfGetRaw(requestParameters, initOverrides);
    }

    /**
     * Re-enable a supplemental form instance which is already submitted and closed
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePostRaw(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalCreateFormInstanceResponse>> {
        if (requestParameters.form_instance_id === null || requestParameters.form_instance_id === undefined) {
            throw new runtime.RequiredError('form_instance_id','Required parameter requestParameters.form_instance_id was null or undefined when calling v1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance/{form_instance_id}/re-enable`.replace(`{${"form_instance_id"}}`, encodeURIComponent(String(requestParameters.form_instance_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplementalReenableFormInstanceRequestBodyToJSON(requestParameters.SupplementalReenableFormInstanceRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalCreateFormInstanceResponseFromJSON(jsonValue));
    }

    /**
     * Re-enable a supplemental form instance which is already submitted and closed
     */
    async v1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePost(requestParameters: V1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalCreateFormInstanceResponse> {
        const response = await this.v1SupplementalsWebInternalFormInstanceFormInstanceIdReEnablePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1SupplementalsWebInternalFormInstancePartialUpdateRaw(requestParameters: V1SupplementalsWebInternalFormInstancePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.form_instance_id === null || requestParameters.form_instance_id === undefined) {
            throw new runtime.RequiredError('form_instance_id','Required parameter requestParameters.form_instance_id was null or undefined when calling v1SupplementalsWebInternalFormInstancePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance/{form_instance_id}`.replace(`{${"form_instance_id"}}`, encodeURIComponent(String(requestParameters.form_instance_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1SupplementalsWebInternalFormInstancePartialUpdate(requestParameters: V1SupplementalsWebInternalFormInstancePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1SupplementalsWebInternalFormInstancePartialUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a new supplemental form instance
     */
    async v1SupplementalsWebInternalFormInstancePostRaw(requestParameters: V1SupplementalsWebInternalFormInstancePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalCreateFormInstanceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplementalCreateFormInstanceRequestBodyToJSON(requestParameters.SupplementalCreateFormInstanceRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalCreateFormInstanceResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new supplemental form instance
     */
    async v1SupplementalsWebInternalFormInstancePost(requestParameters: V1SupplementalsWebInternalFormInstancePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalCreateFormInstanceResponse> {
        const response = await this.v1SupplementalsWebInternalFormInstancePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for supplemental form instances
     */
    async v1SupplementalsWebInternalFormInstanceSearchGetRaw(requestParameters: V1SupplementalsWebInternalFormInstanceSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalGetReportFormInstancesByFilterResponse>> {
        const queryParameters: any = {};

        if (requestParameters.quote_uuids) {
            queryParameters['quote_uuids'] = requestParameters.quote_uuids;
        }

        if (requestParameters.form_types) {
            queryParameters['form_types'] = requestParameters.form_types;
        }

        if (requestParameters.account_uuids) {
            queryParameters['account_uuids'] = requestParameters.account_uuids;
        }

        if (requestParameters.account_line_uuids) {
            queryParameters['account_line_uuids'] = requestParameters.account_line_uuids;
        }

        if (requestParameters.include_form_instances !== undefined) {
            queryParameters['include_form_instances'] = requestParameters.include_form_instances;
        }

        if (requestParameters.include_attachments !== undefined) {
            queryParameters['include_attachments'] = requestParameters.include_attachments;
        }

        if (requestParameters.form_statuses) {
            queryParameters['form_statuses'] = requestParameters.form_statuses;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/form-instance/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalGetReportFormInstancesByFilterResponseFromJSON(jsonValue));
    }

    /**
     * Search for supplemental form instances
     */
    async v1SupplementalsWebInternalFormInstanceSearchGet(requestParameters: V1SupplementalsWebInternalFormInstanceSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalGetReportFormInstancesByFilterResponse> {
        const response = await this.v1SupplementalsWebInternalFormInstanceSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all completed supplemental form instances of a given list of types for a given list of quote IDs
     */
    async v1SupplementalsWebInternalFormsCompletedGetRaw(requestParameters: V1SupplementalsWebInternalFormsCompletedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalGetInternalFormsByQuoteIdsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.quote_uids) {
            queryParameters['quote_uids'] = requestParameters.quote_uids;
        }

        if (requestParameters.account_uids) {
            queryParameters['account_uids'] = requestParameters.account_uids;
        }

        if (requestParameters.form_types) {
            queryParameters['form_types'] = requestParameters.form_types;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/forms/completed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalGetInternalFormsByQuoteIdsResponseFromJSON(jsonValue));
    }

    /**
     * List all completed supplemental form instances of a given list of types for a given list of quote IDs
     */
    async v1SupplementalsWebInternalFormsCompletedGet(requestParameters: V1SupplementalsWebInternalFormsCompletedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalGetInternalFormsByQuoteIdsResponse> {
        const response = await this.v1SupplementalsWebInternalFormsCompletedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all of the supplemental form instances and logs related to a recipient\'s account
     */
    async v1SupplementalsWebInternalRecipientAccountAccountIdGetRaw(requestParameters: V1SupplementalsWebInternalRecipientAccountAccountIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalGetReportFormInstancesByRecipientAccountResponse>> {
        if (requestParameters.account_id === null || requestParameters.account_id === undefined) {
            throw new runtime.RequiredError('account_id','Required parameter requestParameters.account_id was null or undefined when calling v1SupplementalsWebInternalRecipientAccountAccountIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.form_statuses) {
            queryParameters['form_statuses'] = requestParameters.form_statuses;
        }

        if (requestParameters.form_types) {
            queryParameters['form_types'] = requestParameters.form_types;
        }

        if (requestParameters.quote_uuids) {
            queryParameters['quote_uuids'] = requestParameters.quote_uuids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/recipient/account/{account_id}`.replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters.account_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalGetReportFormInstancesByRecipientAccountResponseFromJSON(jsonValue));
    }

    /**
     * List all of the supplemental form instances and logs related to a recipient\'s account
     */
    async v1SupplementalsWebInternalRecipientAccountAccountIdGet(requestParameters: V1SupplementalsWebInternalRecipientAccountAccountIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalGetReportFormInstancesByRecipientAccountResponse> {
        const response = await this.v1SupplementalsWebInternalRecipientAccountAccountIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all quotes under a given account UID
     */
    async v1SupplementalsWebInternalRecipientAccountAccountIdQuotesGetRaw(requestParameters: V1SupplementalsWebInternalRecipientAccountAccountIdQuotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalGetAccountQuotesResponse>> {
        if (requestParameters.account_id === null || requestParameters.account_id === undefined) {
            throw new runtime.RequiredError('account_id','Required parameter requestParameters.account_id was null or undefined when calling v1SupplementalsWebInternalRecipientAccountAccountIdQuotesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/recipient/account/{account_id}/quotes`.replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters.account_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalGetAccountQuotesResponseFromJSON(jsonValue));
    }

    /**
     * List all quotes under a given account UID
     */
    async v1SupplementalsWebInternalRecipientAccountAccountIdQuotesGet(requestParameters: V1SupplementalsWebInternalRecipientAccountAccountIdQuotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalGetAccountQuotesResponse> {
        const response = await this.v1SupplementalsWebInternalRecipientAccountAccountIdQuotesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all of the supplemental form instances and logs related to a recipient\'s account
     */
    async v1SupplementalsWebInternalRecipientRecipientIdFormsGetRaw(requestParameters: V1SupplementalsWebInternalRecipientRecipientIdFormsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplementalGetReportFormInstancesByRecipientIdResponse>> {
        if (requestParameters.recipient_id === null || requestParameters.recipient_id === undefined) {
            throw new runtime.RequiredError('recipient_id','Required parameter requestParameters.recipient_id was null or undefined when calling v1SupplementalsWebInternalRecipientRecipientIdFormsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplementals/web/internal/recipient/{recipient_id}/forms`.replace(`{${"recipient_id"}}`, encodeURIComponent(String(requestParameters.recipient_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplementalGetReportFormInstancesByRecipientIdResponseFromJSON(jsonValue));
    }

    /**
     * List all of the supplemental form instances and logs related to a recipient\'s account
     */
    async v1SupplementalsWebInternalRecipientRecipientIdFormsGet(requestParameters: V1SupplementalsWebInternalRecipientRecipientIdFormsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplementalGetReportFormInstancesByRecipientIdResponse> {
        const response = await this.v1SupplementalsWebInternalRecipientRecipientIdFormsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
