/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlAssetRemovalReasonCode = {
    AssetNoLongerBelongsToCompany: 'asset-no-longer-belongs-to-company',
    AssetNoLongerInUseByCompany: 'asset-no-longer-in-use-by-company',
    AssetBelongsToTechnologyOrServiceUsedByCompany: 'asset-belongs-to-technology-or-service-used-by-company',
    AssetBelongsToTechnologyOrServiceNoLongerUsedByOurCompany: 'asset-belongs-to-technology-or-service-no-longer-used-by-our-company',
    AssetBelongsToFranchiseOfOurCompany: 'asset-belongs-to-franchise-of-our-company',
    AssetBelongsToIsp: 'asset-belongs-to-isp',
    AssetBelongsToEmailProvider: 'asset-belongs-to-email-provider',
    Other: 'other'
} as const;
export type ControlAssetRemovalReasonCode = typeof ControlAssetRemovalReasonCode[keyof typeof ControlAssetRemovalReasonCode];


export function ControlAssetRemovalReasonCodeFromJSON(json: any): ControlAssetRemovalReasonCode {
    return ControlAssetRemovalReasonCodeFromJSONTyped(json, false);
}

export function ControlAssetRemovalReasonCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAssetRemovalReasonCode {
    return json as ControlAssetRemovalReasonCode;
}

export function ControlAssetRemovalReasonCodeToJSON(value?: ControlAssetRemovalReasonCode | null): any {
    return value as any;
}

