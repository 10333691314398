/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitAccountResolutions } from './MonitAccountResolutions';
import {
    MonitAccountResolutionsFromJSON,
    MonitAccountResolutionsFromJSONTyped,
    MonitAccountResolutionsToJSON,
} from './MonitAccountResolutions';

/**
 * 
 * @export
 * @interface MonitAccountResolutionsPaginatedResponse
 */
export interface MonitAccountResolutionsPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitAccountResolutionsPaginatedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitAccountResolutionsPaginatedResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitAccountResolutionsPaginatedResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitAccountResolutions>}
     * @memberof MonitAccountResolutionsPaginatedResponse
     */
    results: Array<MonitAccountResolutions>;
}

/**
 * Check if a given object implements the MonitAccountResolutionsPaginatedResponse interface.
 */
export function instanceOfMonitAccountResolutionsPaginatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function MonitAccountResolutionsPaginatedResponseFromJSON(json: any): MonitAccountResolutionsPaginatedResponse {
    return MonitAccountResolutionsPaginatedResponseFromJSONTyped(json, false);
}

export function MonitAccountResolutionsPaginatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitAccountResolutionsPaginatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': ((json['results'] as Array<any>).map(MonitAccountResolutionsFromJSON)),
    };
}

export function MonitAccountResolutionsPaginatedResponseToJSON(value?: MonitAccountResolutionsPaginatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': ((value.results as Array<any>).map(MonitAccountResolutionsToJSON)),
    };
}

