/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlClientRoles = {
    Internal: 'portal::internal',
    Binaryedge: 'portal::binaryedge',
    Superuser: 'portal::superuser',
    Policyholder: 'portal::policyholder',
    Freemium: 'portal::freemium',
    Broker: 'portal::broker',
    Premium: 'portal::premium',
    Execrisk: 'portal::execrisk',
    Mixed: 'portal::mixed',
    Controluser: 'portal::controluser',
    Monitor: 'portal::monitor',
    Riskaggregation: 'portal::riskaggregation'
} as const;
export type ControlClientRoles = typeof ControlClientRoles[keyof typeof ControlClientRoles];


export function ControlClientRolesFromJSON(json: any): ControlClientRoles {
    return ControlClientRolesFromJSONTyped(json, false);
}

export function ControlClientRolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlClientRoles {
    return json as ControlClientRoles;
}

export function ControlClientRolesToJSON(value?: ControlClientRoles | null): any {
    return value as any;
}

