/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlResolutionRequestAttestation } from './ControlResolutionRequestAttestation';
import {
    ControlResolutionRequestAttestationFromJSON,
    ControlResolutionRequestAttestationFromJSONTyped,
    ControlResolutionRequestAttestationToJSON,
} from './ControlResolutionRequestAttestation';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';
import type { ControlResolutionRequestUser } from './ControlResolutionRequestUser';
import {
    ControlResolutionRequestUserFromJSON,
    ControlResolutionRequestUserFromJSONTyped,
    ControlResolutionRequestUserToJSON,
} from './ControlResolutionRequestUser';
import type { Reason } from './Reason';
import {
    ReasonFromJSON,
    ReasonFromJSONTyped,
    ReasonToJSON,
} from './Reason';

/**
 * 
 * @export
 * @interface ControlAdminResolutionRequestResponseWithEvidence
 */
export interface ControlAdminResolutionRequestResponseWithEvidence {
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    request_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    entity_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    client_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    assets?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    name?: string;
    /**
     * 
     * @type {Reason}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    reason?: Reason;
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    status: ControlResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    updated: string;
    /**
     * 
     * @type {ControlResolutionRequestUser}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    user?: ControlResolutionRequestUser;
    /**
     * 
     * @type {Array<ControlResolutionRequestAttestation>}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    attestations?: Array<ControlResolutionRequestAttestation>;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    evidence_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    resolution_notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminResolutionRequestResponseWithEvidence
     */
    staff_email?: string;
}

/**
 * Check if a given object implements the ControlAdminResolutionRequestResponseWithEvidence interface.
 */
export function instanceOfControlAdminResolutionRequestResponseWithEvidence(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request_id" in value;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "entity_name" in value;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ControlAdminResolutionRequestResponseWithEvidenceFromJSON(json: any): ControlAdminResolutionRequestResponseWithEvidence {
    return ControlAdminResolutionRequestResponseWithEvidenceFromJSONTyped(json, false);
}

export function ControlAdminResolutionRequestResponseWithEvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminResolutionRequestResponseWithEvidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_id': json['request_id'],
        'entity_id': json['entity_id'],
        'entity_name': json['entity_name'],
        'client_id': json['client_id'],
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'datapoint': json['datapoint'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'reason': !exists(json, 'reason') ? undefined : ReasonFromJSON(json['reason']),
        'status': ControlResolutionRequestStatusFromJSON(json['status']),
        'created': json['created'],
        'updated': json['updated'],
        'user': !exists(json, 'user') ? undefined : ControlResolutionRequestUserFromJSON(json['user']),
        'attestations': !exists(json, 'attestations') ? undefined : ((json['attestations'] as Array<any>).map(ControlResolutionRequestAttestationFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'evidence_url': !exists(json, 'evidence_url') ? undefined : json['evidence_url'],
        'resolution_notes': !exists(json, 'resolution_notes') ? undefined : json['resolution_notes'],
        'staff_email': !exists(json, 'staff_email') ? undefined : json['staff_email'],
    };
}

export function ControlAdminResolutionRequestResponseWithEvidenceToJSON(value?: ControlAdminResolutionRequestResponseWithEvidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_id': value.request_id,
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'client_id': value.client_id,
        'assets': value.assets,
        'datapoint': value.datapoint,
        'name': value.name,
        'reason': ReasonToJSON(value.reason),
        'status': ControlResolutionRequestStatusToJSON(value.status),
        'created': value.created,
        'updated': value.updated,
        'user': ControlResolutionRequestUserToJSON(value.user),
        'attestations': value.attestations === undefined ? undefined : ((value.attestations as Array<any>).map(ControlResolutionRequestAttestationToJSON)),
        'notes': value.notes,
        'evidence_url': value.evidence_url,
        'resolution_notes': value.resolution_notes,
        'staff_email': value.staff_email,
    };
}

