/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewMQTT
 */
export interface SecReviewMQTT {
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewMQTT
     */
    connected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewMQTT
     */
    auth?: boolean;
}

/**
 * Check if a given object implements the SecReviewMQTT interface.
 */
export function instanceOfSecReviewMQTT(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewMQTTFromJSON(json: any): SecReviewMQTT {
    return SecReviewMQTTFromJSONTyped(json, false);
}

export function SecReviewMQTTFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewMQTT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connected': !exists(json, 'connected') ? undefined : json['connected'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
    };
}

export function SecReviewMQTTToJSON(value?: SecReviewMQTT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connected': value.connected,
        'auth': value.auth,
    };
}

