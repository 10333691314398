/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitEntityKeywordOrderBy = {
    EntityId: 'entity_id',
    Asset: 'asset',
    Port: 'port',
    Module: 'module',
    Found: 'found',
    Created: 'created',
    Updated: 'updated'
} as const;
export type MonitEntityKeywordOrderBy = typeof MonitEntityKeywordOrderBy[keyof typeof MonitEntityKeywordOrderBy];


export function MonitEntityKeywordOrderByFromJSON(json: any): MonitEntityKeywordOrderBy {
    return MonitEntityKeywordOrderByFromJSONTyped(json, false);
}

export function MonitEntityKeywordOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityKeywordOrderBy {
    return json as MonitEntityKeywordOrderBy;
}

export function MonitEntityKeywordOrderByToJSON(value?: MonitEntityKeywordOrderBy | null): any {
    return value as any;
}

