/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityMetrics
 */
export interface ControlEntityMetrics {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityMetrics
     */
    company_revenue?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityMetrics
     */
    number_of_employees?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityMetrics
     */
    number_of_pii_records?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityMetrics
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityMetrics
     */
    country_code?: string;
}

/**
 * Check if a given object implements the ControlEntityMetrics interface.
 */
export function instanceOfControlEntityMetrics(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityMetricsFromJSON(json: any): ControlEntityMetrics {
    return ControlEntityMetricsFromJSONTyped(json, false);
}

export function ControlEntityMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company_revenue': !exists(json, 'company_revenue') ? undefined : json['company_revenue'],
        'number_of_employees': !exists(json, 'number_of_employees') ? undefined : json['number_of_employees'],
        'number_of_pii_records': !exists(json, 'number_of_pii_records') ? undefined : json['number_of_pii_records'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'country_code': !exists(json, 'country_code') ? undefined : json['country_code'],
    };
}

export function ControlEntityMetricsToJSON(value?: ControlEntityMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_revenue': value.company_revenue,
        'number_of_employees': value.number_of_employees,
        'number_of_pii_records': value.number_of_pii_records,
        'industry': value.industry,
        'country_code': value.country_code,
    };
}

