/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlExperianLien } from './ControlExperianLien';
import {
    ControlExperianLienFromJSON,
    ControlExperianLienFromJSONTyped,
    ControlExperianLienToJSON,
} from './ControlExperianLien';

/**
 * 
 * @export
 * @interface ControlPaginatedExperianLiensResponse
 */
export interface ControlPaginatedExperianLiensResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedExperianLiensResponse
     */
    total: number;
    /**
     * 
     * @type {Array<ControlExperianLien>}
     * @memberof ControlPaginatedExperianLiensResponse
     */
    results: Array<ControlExperianLien>;
    /**
     * 
     * @type {string}
     * @memberof ControlPaginatedExperianLiensResponse
     */
    schema_version: string;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedExperianLiensResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedExperianLiensResponse
     */
    page_size: number;
}

/**
 * Check if a given object implements the ControlPaginatedExperianLiensResponse interface.
 */
export function instanceOfControlPaginatedExperianLiensResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "schema_version" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;

    return isInstance;
}

export function ControlPaginatedExperianLiensResponseFromJSON(json: any): ControlPaginatedExperianLiensResponse {
    return ControlPaginatedExperianLiensResponseFromJSONTyped(json, false);
}

export function ControlPaginatedExperianLiensResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedExperianLiensResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'results': ((json['results'] as Array<any>).map(ControlExperianLienFromJSON)),
        'schema_version': json['schema_version'],
        'page': json['page'],
        'page_size': json['page_size'],
    };
}

export function ControlPaginatedExperianLiensResponseToJSON(value?: ControlPaginatedExperianLiensResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ControlExperianLienToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

