/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFTorrent
 */
export interface CRSFTorrent {
    /**
     * 
     * @type {string}
     * @memberof CRSFTorrent
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTorrent
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFTorrent
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFTorrent
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFTorrent
     */
    infohash: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTorrent
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTorrent
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFTorrent
     */
    source_domain?: string;
}

/**
 * Check if a given object implements the CRSFTorrent interface.
 */
export function instanceOfCRSFTorrent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "infohash" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CRSFTorrentFromJSON(json: any): CRSFTorrent {
    return CRSFTorrentFromJSONTyped(json, false);
}

export function CRSFTorrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTorrent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'created': json['created'],
        'found': json['found'],
        'infohash': json['infohash'],
        'name': json['name'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_domain': !exists(json, 'source_domain') ? undefined : json['source_domain'],
    };
}

export function CRSFTorrentToJSON(value?: CRSFTorrent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'category': value.category,
        'created': value.created,
        'found': value.found,
        'infohash': value.infohash,
        'name': value.name,
        'shared': value.shared,
        'source_domain': value.source_domain,
    };
}

