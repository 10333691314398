import React, { ReactNode } from 'react';
import {
  Dialog as CoreDialog,
  DialogProps as CoreDialogProps,
  Grid,
  Button,
} from '@explorer/core';

/**
 * DialogConfirm
 */
export const DialogConfirm = ({
  open,
  handleClose,
  closeLabel = { id: 'global.cancel' },
  handleConfirm,
  confirmLabel = { id: 'global.confirm' },
  maxWidth = 'sm',
  children = null,
}: DialogConfirmProps) => {
  return (
    <CoreDialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      disableScrollLock
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        p={8}
      >
        <Grid item>
          {children}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            pt={4}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              label={confirmLabel}
              onClick={handleConfirm}
              px={4}
              mx={1}
            />
            <Button
              variant="outlined"
              color="primary"
              size="large"
              label={closeLabel}
              onClick={handleClose}
              px={4}
              mx={1}
            />
          </Grid>
        </Grid>
      </Grid>
    </CoreDialog>
  );
};

/**
 * DialogConfirm Props
 */
export interface DialogConfirmProps extends Pick<CoreDialogProps, 'maxWidth'> {
  /**
   * `open` - is the dialog open
   */
  open: boolean;
  /**
   * `handleClose` - function that closes the dialog
   */
  handleClose: () => void;
  /**
   * `closeLabel` - label of the close button
   */
  closeLabel?: IntlLabel;
  /**
   * `handleConfirm` - function that closes the dialog
   */
  handleConfirm: () => void;
  /**
   * `confirmLabel` - label of the close button
   */
  confirmLabel?: IntlLabel;
  /**
   * `children` - content of the form
   */
  children: ReactNode;
}
