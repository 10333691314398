import React from 'react';
import clsx from 'clsx';
import MuiProgressLinear, {
  LinearProgressProps as MuiProgressLinearProps,
} from '@mui/material/LinearProgress';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';
export const ProgressLinear = withCoreProps<ProgressLinearProps>(
  ({ className, ...props }: React.PropsWithChildren<ProgressLinearProps>) => {
    const clsxClass = clsx(className);

    return <MuiProgressLinear {...props} className={clsxClass} />;
  },
);

export interface ProgressLinearProps
  extends MuiProgressLinearProps,
    CoreProps {}
