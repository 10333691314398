import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Button } from '@mui/material';
import {
  SnackbarKey,
  SnackbarProvider,
  closeSnackbar,
  useSnackbar,
} from 'notistack';
import { useIntl } from 'react-intl';

const AUTO_HIDE = 5000;

export const ExplorerSnackBarProvider = ({ children }) => (
  <SnackbarProvider
    maxSnack={6}
    autoHideDuration={AUTO_HIDE}
    iconVariant={{
      error: (
        <ErrorOutlinedIcon
          style={{
            height: '0.8em',
            width: 'fit-content',
            marginRight: '0.25em',
          }}
        />
      ),
    }}
  >
    {children}
  </SnackbarProvider>
);

/**
 * Control the success and error snackbars.
 *
 * __> Example:__
 * ```tsx
 * const { snackSuccess , snackError, snackInfo, closeSnackbar } = useQuerySnackbar({ onlyDEV: true });
 *
 * snackSuccess('The request was successful')
 *
 * snackError('The request failed', error);
 *
 * const key = snackInfo('Request is in progress');
 * closeSnackbar(key);
 * ```
 */
export const useQuerySnackbar: UseSnackSnackbar = ({
  onlyDEV = false,
  noSuccesses = false,
  noErrors = false,
} = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const enabled = onlyDEV ? process.env.BE_ENV === 'dev' : true;

  const renderAction = (snackBarID) => (
    <Button
      size="small"
      variant="text"
      color="inherit"
      style={{ textDecoration: 'underline' }}
      onClick={() => closeSnackbar(snackBarID)}
    >
      {intl.formatMessage({ id: 'global.snackbarDismissal' })}
    </Button>
  );

  return {
    snackSuccess: (message) => {
      if (enabled && !noSuccesses) {
        enqueueSnackbar(message, {
          variant: 'success',
          action: renderAction,
        });
      }
    },
    snackInfo: (message) => {
      if (enabled) {
        return enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: null,
          action: renderAction,
        });
      }
    },
    snackError: (uxMessage, error = {}) => {
      const { status, detail, message } = error || {};
      const statusMessage = status ? ` (${status})` : '';
      const apiRes = detail || message || '';
      const apiMessage = apiRes ? `: ${apiRes}` : '';

      if (enabled && !noErrors) {
        enqueueSnackbar(`${uxMessage}${statusMessage}${apiMessage}`, {
          variant: 'error',
          autoHideDuration: null,
          action: renderAction,
        });
      }
    },
    closeSnackbar,
  };
};

export type UseSnackSnackbar = (params?: {
  onlyDEV?: boolean;
  noSuccesses?: boolean;
  noErrors?: boolean;
}) => UseSnackSnackbarReturn;

export type UseSnackSnackbarReturn = {
  snackSuccess: (message: string) => void;
  snackError: (message: string, error: any) => void;
  snackInfo: (message: string) => SnackbarKey;
  closeSnackbar: (key: SnackbarKey) => void;
};
