/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsName } from './AsName';
import {
    AsNameFromJSON,
    AsNameFromJSONTyped,
    AsNameToJSON,
} from './AsName';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { CountFindingsFilter } from './CountFindingsFilter';
import {
    CountFindingsFilterFromJSON,
    CountFindingsFilterFromJSONTyped,
    CountFindingsFilterToJSON,
} from './CountFindingsFilter';
import type { DnsTypes } from './DnsTypes';
import {
    DnsTypesFromJSON,
    DnsTypesFromJSONTyped,
    DnsTypesToJSON,
} from './DnsTypes';
import type { MonitEntityAssetOrderBy } from './MonitEntityAssetOrderBy';
import {
    MonitEntityAssetOrderByFromJSON,
    MonitEntityAssetOrderByFromJSONTyped,
    MonitEntityAssetOrderByToJSON,
} from './MonitEntityAssetOrderBy';
import type { MonitEntityAssetSource } from './MonitEntityAssetSource';
import {
    MonitEntityAssetSourceFromJSON,
    MonitEntityAssetSourceFromJSONTyped,
    MonitEntityAssetSourceToJSON,
} from './MonitEntityAssetSource';

/**
 * 
 * @export
 * @interface MonitGetEntityAssetQueries
 */
export interface MonitGetEntityAssetQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityAssetQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityAssetQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    tech?: string;
    /**
     * 
     * @type {Category}
     * @memberof MonitGetEntityAssetQueries
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    process_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    ip_address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    count_findings?: boolean;
    /**
     * 
     * @type {CountFindingsFilter}
     * @memberof MonitGetEntityAssetQueries
     */
    count_findings_filter?: CountFindingsFilter;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    max_level?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    exclude_removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    exclude_ignored?: boolean;
    /**
     * 
     * @type {MonitEntityAssetSource}
     * @memberof MonitGetEntityAssetQueries
     */
    source?: MonitEntityAssetSource;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    last_detected_from?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetQueries
     */
    last_detected_to?: string;
    /**
     * 
     * @type {DnsTypes}
     * @memberof MonitGetEntityAssetQueries
     */
    dns_types?: DnsTypes;
    /**
     * 
     * @type {AsName}
     * @memberof MonitGetEntityAssetQueries
     */
    as_name?: AsName;
    /**
     * 
     * @type {MonitEntityAssetOrderBy}
     * @memberof MonitGetEntityAssetQueries
     */
    order_by?: MonitEntityAssetOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityAssetQueries interface.
 */
export function instanceOfMonitGetEntityAssetQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityAssetQueriesFromJSON(json: any): MonitGetEntityAssetQueries {
    return MonitGetEntityAssetQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityAssetQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityAssetQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'tech': !exists(json, 'tech') ? undefined : json['tech'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'count_findings': !exists(json, 'count_findings') ? undefined : json['count_findings'],
        'count_findings_filter': !exists(json, 'count_findings_filter') ? undefined : CountFindingsFilterFromJSON(json['count_findings_filter']),
        'max_level': !exists(json, 'max_level') ? undefined : json['max_level'],
        'exclude_removed': !exists(json, 'exclude_removed') ? undefined : json['exclude_removed'],
        'exclude_ignored': !exists(json, 'exclude_ignored') ? undefined : json['exclude_ignored'],
        'source': !exists(json, 'source') ? undefined : MonitEntityAssetSourceFromJSON(json['source']),
        'last_detected_from': !exists(json, 'last_detected_from') ? undefined : json['last_detected_from'],
        'last_detected_to': !exists(json, 'last_detected_to') ? undefined : json['last_detected_to'],
        'dns_types': !exists(json, 'dns_types') ? undefined : DnsTypesFromJSON(json['dns_types']),
        'as_name': !exists(json, 'as_name') ? undefined : AsNameFromJSON(json['as_name']),
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityAssetOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityAssetQueriesToJSON(value?: MonitGetEntityAssetQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'tech': value.tech,
        'category': CategoryToJSON(value.category),
        'source_asset': value.source_asset,
        'search': value.search,
        'asset': value.asset,
        'process_id': value.process_id,
        'shared': value.shared,
        'domain': value.domain,
        'ip_address': value.ip_address,
        'count_findings': value.count_findings,
        'count_findings_filter': CountFindingsFilterToJSON(value.count_findings_filter),
        'max_level': value.max_level,
        'exclude_removed': value.exclude_removed,
        'exclude_ignored': value.exclude_ignored,
        'source': MonitEntityAssetSourceToJSON(value.source),
        'last_detected_from': value.last_detected_from,
        'last_detected_to': value.last_detected_to,
        'dns_types': DnsTypesToJSON(value.dns_types),
        'as_name': AsNameToJSON(value.as_name),
        'order_by': MonitEntityAssetOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

