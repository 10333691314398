import React from 'react';
import clsx from 'clsx';
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Box = withCoreProps<BoxProps>(
  ({ children, className, ...props }: React.PropsWithChildren<BoxProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiBox {...props} className={clsxClass}>
        {children}
      </MuiBox>
    );
  },
  [
    'fontSize',
    'fontWeight',
    'height',
    'letterSpacing',
    'maxHeight',
    'maxWidth',
    'minHeight',
    'minWidth',
    'textAlign',
    'width',
    'zIndex',
    'm',
    'mt',
    'mb',
    'mr',
    'ml',
    'my',
    'mx',
    'p',
    'pt',
    'pb',
    'pr',
    'pl',
    'py',
    'px',
    'borderColor',
    'borderRadius',
  ],
);

export interface BoxProps
  extends MuiBoxProps,
    Omit<
      CoreProps,
      | 'fontSize'
      | 'fontWeight'
      | 'height'
      | 'letterSpacing'
      | 'maxHeight'
      | 'maxWidth'
      | 'minHeight'
      | 'minWidth'
      | 'textAlign'
      | 'width'
      | 'zIndex'
      | 'm'
      | 'mt'
      | 'mb'
      | 'mr'
      | 'ml'
      | 'my'
      | 'mx'
      | 'p'
      | 'pt'
      | 'pb'
      | 'pr'
      | 'pl'
      | 'py'
      | 'px'
      | 'borderColor'
      | 'borderRadius'
      | 'textTransform'
    > {}
