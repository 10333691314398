import create from 'zustand';
import { parseJWT } from '@explorer/helpers';
import type { UserStore, AccessToken, IDToken, Permissions } from './types';

/**
 * Types
 */
export * from './types';

/**
 * useUser
 */
export const useUser = create<UserStore>((set, get) => ({
  userAuthenticated: false,
  setUserAuthenticated: (auth) => set({ userAuthenticated: auth }),
  user: null,
  /**
   * Create the `user` object by mixing properties from
   * the `access_token` and `id_token`.
   */
  setUser({ access_token, id_token }) {
    const accessToken = parseJWT<AccessToken>(access_token);
    const idToken = parseJWT<IDToken>(id_token);

    if (accessToken && idToken) {
      // app permissions are stored in the `permissions` property
      const permissions = accessToken?.permissions ?? [];
      // static permission are stored in scopes
      const scopes = accessToken?.scope?.split(' ') ?? [];

      const allPermissions = [...permissions, ...scopes] as Permissions[];

      const { sub, email, name, nickname, picture } = idToken;

      set({
        user: {
          sub,
          email,
          name,
          nickname,
          picture,
          permissions: allPermissions,
        },
      });
    }
  },
  /**
   * Set the `user` to `null`
   */
  resetUser() {
    set({ user: null });
  },
  /**
   * Checks if the user has the provided permissions
   */
  userHasPermissions(permissions = []) {
    const { user } = get();
    let hasPermissions = false;

    if (user) {
      hasPermissions =
        permissions.every((p) => user.permissions.includes(p)) ?? false;
    }

    return hasPermissions;
  },
  /**
   * Checks if the user has one of the provided permissions
   */
  userHasSomePermissions(permissions = []) {
    const { user } = get();
    let hasPermissions = false;

    if (user) {
      hasPermissions =
        permissions.some((p) => user.permissions.includes(p)) ?? false;
    }

    return hasPermissions;
  },
}));
