import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * BreachResponseIcon
 */
export const BreachResponseIcon = ({ ...props }: BreachResponseIconProps) => {
  return (
    <Icon label="breach_response" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8,13h-0.6v-2.3c0-2.8-2.1-5.2-4.9-5.6c-2.8-0.4-5.5,1.3-6.3,4c-0.1,0.4,0,0.8,0.2,1c0.3,0.3,0.6,0.4,1,0.3c0.4-0.1,0.7-0.4,0.8-0.7c0.5-1.7,2.2-2.8,4-2.5c1.8,0.3,3.1,1.8,3.1,3.6v1.9c0,0.2-0.2,0.4-0.4,0.4h-9.5c-0.9,0-1.7,0.8-1.7,1.7v8.9c0,0.9,0.8,1.7,1.7,1.7h12.7c0.9,0,1.7-0.8,1.7-1.7v-8.9C24.5,13.8,23.8,13,22.8,13z M16.5,23.2c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C20.5,21.4,18.7,23.2,16.5,23.2z M16.5,16.4c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7C19.2,17.6,18,16.4,16.5,16.4z M18.6,19.2c0,0.4-0.3,0.6-0.6,0.6h-1.5c-0.4,0-0.6-0.3-0.6-0.6v-1.5c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v0.6c0,0.1,0.1,0.2,0.2,0.2H18C18.3,18.5,18.6,18.8,18.6,19.2z"
        />
      </g>
    </Icon>
  );
};

/**
 * BreachResponseIcon Props
 */
export interface BreachResponseIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
