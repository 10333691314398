/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskScheduleIBase
 */
export interface ExecRiskScheduleIBase {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sch_i_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sch_i_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sch_i_plan_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sch_i_ein?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_liabilities_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_net_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_liabilities_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_net_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_emplr_contrib_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_participant_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_oth_contrib_rcvd_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_non_cash_contrib_bs_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_other_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_distrib_bnft_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_corrective_distrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_deem_dstrb_partcp_ln_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_admin_srvc_providers_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_oth_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_net_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_tot_plan_transfers_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_joint_venture_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_joint_venture_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_emplr_prop_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_emplr_prop_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_inv_real_estate_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_inv_real_estate_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_emplr_sec_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_emplr_sec_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_mortg_partcp_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_mortg_partcp_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_oth_lns_partcp_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_oth_lns_partcp_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_personal_prop_eoy_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_personal_prop_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_fail_transm_contrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_fail_transm_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_loans_in_default_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_loans_in_default_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_leases_in_default_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_leases_in_default_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sm_party_in_int_not_rptd_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    sm_party_in_int_not_rptd_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_plan_ins_fdlty_bond_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_plan_ins_fdlty_bond_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_loss_discv_dur_year_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_loss_discv_dur_year_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_asset_undeterm_val_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_asset_undeterm_val_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_non_cash_contrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_non_cash_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_20_prcnt_sngl_invst_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_20_prcnt_sngl_invst_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_all_plan_ast_distrib_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sm_waiv_annual_iqpa_report_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sm_fail_provide_benef_due_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    sm_fail_provide_benef_due_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_plan_blackout_period_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    sm_comply_blackout_notice_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_res_term_plan_adpt_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    small_res_term_plan_adpt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    fdcry_trust_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    fdcry_trust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    small_covered_pbgc_ins_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    trust_incur_unrel_tax_inc_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    trust_incur_unrel_tax_inc_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    in_service_distrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleIBase
     */
    in_service_distrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    fdcry_trustee_cust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    fdcry_trust_cust_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    fdcry_trust_cust_phon_nu_fore?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    distrib_made_employee_62_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleIBase
     */
    premium_filing_confirm_number?: string;
}

/**
 * Check if a given object implements the ExecRiskScheduleIBase interface.
 */
export function instanceOfExecRiskScheduleIBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleIBaseFromJSON(json: any): ExecRiskScheduleIBase {
    return ExecRiskScheduleIBaseFromJSONTyped(json, false);
}

export function ExecRiskScheduleIBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleIBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'sch_i_plan_year_begin_date': !exists(json, 'sch_i_plan_year_begin_date') ? undefined : json['sch_i_plan_year_begin_date'],
        'sch_i_tax_prd': !exists(json, 'sch_i_tax_prd') ? undefined : json['sch_i_tax_prd'],
        'sch_i_plan_num': !exists(json, 'sch_i_plan_num') ? undefined : json['sch_i_plan_num'],
        'sch_i_ein': !exists(json, 'sch_i_ein') ? undefined : json['sch_i_ein'],
        'small_tot_assets_boy_amt': !exists(json, 'small_tot_assets_boy_amt') ? undefined : json['small_tot_assets_boy_amt'],
        'small_tot_liabilities_boy_amt': !exists(json, 'small_tot_liabilities_boy_amt') ? undefined : json['small_tot_liabilities_boy_amt'],
        'small_net_assets_boy_amt': !exists(json, 'small_net_assets_boy_amt') ? undefined : json['small_net_assets_boy_amt'],
        'small_tot_assets_eoy_amt': !exists(json, 'small_tot_assets_eoy_amt') ? undefined : json['small_tot_assets_eoy_amt'],
        'small_tot_liabilities_eoy_amt': !exists(json, 'small_tot_liabilities_eoy_amt') ? undefined : json['small_tot_liabilities_eoy_amt'],
        'small_net_assets_eoy_amt': !exists(json, 'small_net_assets_eoy_amt') ? undefined : json['small_net_assets_eoy_amt'],
        'small_emplr_contrib_income_amt': !exists(json, 'small_emplr_contrib_income_amt') ? undefined : json['small_emplr_contrib_income_amt'],
        'small_participant_contrib_amt': !exists(json, 'small_participant_contrib_amt') ? undefined : json['small_participant_contrib_amt'],
        'small_oth_contrib_rcvd_amt': !exists(json, 'small_oth_contrib_rcvd_amt') ? undefined : json['small_oth_contrib_rcvd_amt'],
        'small_non_cash_contrib_bs_amt': !exists(json, 'small_non_cash_contrib_bs_amt') ? undefined : json['small_non_cash_contrib_bs_amt'],
        'small_other_income_amt': !exists(json, 'small_other_income_amt') ? undefined : json['small_other_income_amt'],
        'small_tot_income_amt': !exists(json, 'small_tot_income_amt') ? undefined : json['small_tot_income_amt'],
        'small_tot_distrib_bnft_amt': !exists(json, 'small_tot_distrib_bnft_amt') ? undefined : json['small_tot_distrib_bnft_amt'],
        'small_corrective_distrib_amt': !exists(json, 'small_corrective_distrib_amt') ? undefined : json['small_corrective_distrib_amt'],
        'small_deem_dstrb_partcp_ln_amt': !exists(json, 'small_deem_dstrb_partcp_ln_amt') ? undefined : json['small_deem_dstrb_partcp_ln_amt'],
        'small_admin_srvc_providers_amt': !exists(json, 'small_admin_srvc_providers_amt') ? undefined : json['small_admin_srvc_providers_amt'],
        'small_oth_expenses_amt': !exists(json, 'small_oth_expenses_amt') ? undefined : json['small_oth_expenses_amt'],
        'small_tot_expenses_amt': !exists(json, 'small_tot_expenses_amt') ? undefined : json['small_tot_expenses_amt'],
        'small_net_income_amt': !exists(json, 'small_net_income_amt') ? undefined : json['small_net_income_amt'],
        'small_tot_plan_transfers_amt': !exists(json, 'small_tot_plan_transfers_amt') ? undefined : json['small_tot_plan_transfers_amt'],
        'small_joint_venture_eoy_ind': !exists(json, 'small_joint_venture_eoy_ind') ? undefined : json['small_joint_venture_eoy_ind'],
        'small_joint_venture_eoy_amt': !exists(json, 'small_joint_venture_eoy_amt') ? undefined : json['small_joint_venture_eoy_amt'],
        'small_emplr_prop_eoy_ind': !exists(json, 'small_emplr_prop_eoy_ind') ? undefined : json['small_emplr_prop_eoy_ind'],
        'small_emplr_prop_eoy_amt': !exists(json, 'small_emplr_prop_eoy_amt') ? undefined : json['small_emplr_prop_eoy_amt'],
        'small_inv_real_estate_eoy_ind': !exists(json, 'small_inv_real_estate_eoy_ind') ? undefined : json['small_inv_real_estate_eoy_ind'],
        'small_inv_real_estate_eoy_amt': !exists(json, 'small_inv_real_estate_eoy_amt') ? undefined : json['small_inv_real_estate_eoy_amt'],
        'small_emplr_sec_eoy_ind': !exists(json, 'small_emplr_sec_eoy_ind') ? undefined : json['small_emplr_sec_eoy_ind'],
        'small_emplr_sec_eoy_amt': !exists(json, 'small_emplr_sec_eoy_amt') ? undefined : json['small_emplr_sec_eoy_amt'],
        'small_mortg_partcp_eoy_ind': !exists(json, 'small_mortg_partcp_eoy_ind') ? undefined : json['small_mortg_partcp_eoy_ind'],
        'small_mortg_partcp_eoy_amt': !exists(json, 'small_mortg_partcp_eoy_amt') ? undefined : json['small_mortg_partcp_eoy_amt'],
        'small_oth_lns_partcp_eoy_ind': !exists(json, 'small_oth_lns_partcp_eoy_ind') ? undefined : json['small_oth_lns_partcp_eoy_ind'],
        'small_oth_lns_partcp_eoy_amt': !exists(json, 'small_oth_lns_partcp_eoy_amt') ? undefined : json['small_oth_lns_partcp_eoy_amt'],
        'small_personal_prop_eoy_ind': !exists(json, 'small_personal_prop_eoy_ind') ? undefined : json['small_personal_prop_eoy_ind'],
        'small_personal_prop_eoy_amt': !exists(json, 'small_personal_prop_eoy_amt') ? undefined : json['small_personal_prop_eoy_amt'],
        'small_fail_transm_contrib_ind': !exists(json, 'small_fail_transm_contrib_ind') ? undefined : json['small_fail_transm_contrib_ind'],
        'small_fail_transm_contrib_amt': !exists(json, 'small_fail_transm_contrib_amt') ? undefined : json['small_fail_transm_contrib_amt'],
        'small_loans_in_default_ind': !exists(json, 'small_loans_in_default_ind') ? undefined : json['small_loans_in_default_ind'],
        'small_loans_in_default_amt': !exists(json, 'small_loans_in_default_amt') ? undefined : json['small_loans_in_default_amt'],
        'small_leases_in_default_ind': !exists(json, 'small_leases_in_default_ind') ? undefined : json['small_leases_in_default_ind'],
        'small_leases_in_default_amt': !exists(json, 'small_leases_in_default_amt') ? undefined : json['small_leases_in_default_amt'],
        'sm_party_in_int_not_rptd_ind': !exists(json, 'sm_party_in_int_not_rptd_ind') ? undefined : json['sm_party_in_int_not_rptd_ind'],
        'sm_party_in_int_not_rptd_amt': !exists(json, 'sm_party_in_int_not_rptd_amt') ? undefined : json['sm_party_in_int_not_rptd_amt'],
        'small_plan_ins_fdlty_bond_ind': !exists(json, 'small_plan_ins_fdlty_bond_ind') ? undefined : json['small_plan_ins_fdlty_bond_ind'],
        'small_plan_ins_fdlty_bond_amt': !exists(json, 'small_plan_ins_fdlty_bond_amt') ? undefined : json['small_plan_ins_fdlty_bond_amt'],
        'small_loss_discv_dur_year_ind': !exists(json, 'small_loss_discv_dur_year_ind') ? undefined : json['small_loss_discv_dur_year_ind'],
        'small_loss_discv_dur_year_amt': !exists(json, 'small_loss_discv_dur_year_amt') ? undefined : json['small_loss_discv_dur_year_amt'],
        'small_asset_undeterm_val_ind': !exists(json, 'small_asset_undeterm_val_ind') ? undefined : json['small_asset_undeterm_val_ind'],
        'small_asset_undeterm_val_amt': !exists(json, 'small_asset_undeterm_val_amt') ? undefined : json['small_asset_undeterm_val_amt'],
        'small_non_cash_contrib_ind': !exists(json, 'small_non_cash_contrib_ind') ? undefined : json['small_non_cash_contrib_ind'],
        'small_non_cash_contrib_amt': !exists(json, 'small_non_cash_contrib_amt') ? undefined : json['small_non_cash_contrib_amt'],
        'small_20_prcnt_sngl_invst_ind': !exists(json, 'small_20_prcnt_sngl_invst_ind') ? undefined : json['small_20_prcnt_sngl_invst_ind'],
        'small_20_prcnt_sngl_invst_amt': !exists(json, 'small_20_prcnt_sngl_invst_amt') ? undefined : json['small_20_prcnt_sngl_invst_amt'],
        'small_all_plan_ast_distrib_ind': !exists(json, 'small_all_plan_ast_distrib_ind') ? undefined : json['small_all_plan_ast_distrib_ind'],
        'sm_waiv_annual_iqpa_report_ind': !exists(json, 'sm_waiv_annual_iqpa_report_ind') ? undefined : json['sm_waiv_annual_iqpa_report_ind'],
        'sm_fail_provide_benef_due_ind': !exists(json, 'sm_fail_provide_benef_due_ind') ? undefined : json['sm_fail_provide_benef_due_ind'],
        'sm_fail_provide_benef_due_amt': !exists(json, 'sm_fail_provide_benef_due_amt') ? undefined : json['sm_fail_provide_benef_due_amt'],
        'small_plan_blackout_period_ind': !exists(json, 'small_plan_blackout_period_ind') ? undefined : json['small_plan_blackout_period_ind'],
        'sm_comply_blackout_notice_ind': !exists(json, 'sm_comply_blackout_notice_ind') ? undefined : json['sm_comply_blackout_notice_ind'],
        'small_res_term_plan_adpt_ind': !exists(json, 'small_res_term_plan_adpt_ind') ? undefined : json['small_res_term_plan_adpt_ind'],
        'small_res_term_plan_adpt_amt': !exists(json, 'small_res_term_plan_adpt_amt') ? undefined : json['small_res_term_plan_adpt_amt'],
        'fdcry_trust_ein': !exists(json, 'fdcry_trust_ein') ? undefined : json['fdcry_trust_ein'],
        'fdcry_trust_name': !exists(json, 'fdcry_trust_name') ? undefined : json['fdcry_trust_name'],
        'small_covered_pbgc_ins_ind': !exists(json, 'small_covered_pbgc_ins_ind') ? undefined : json['small_covered_pbgc_ins_ind'],
        'trust_incur_unrel_tax_inc_ind': !exists(json, 'trust_incur_unrel_tax_inc_ind') ? undefined : json['trust_incur_unrel_tax_inc_ind'],
        'trust_incur_unrel_tax_inc_amt': !exists(json, 'trust_incur_unrel_tax_inc_amt') ? undefined : json['trust_incur_unrel_tax_inc_amt'],
        'in_service_distrib_ind': !exists(json, 'in_service_distrib_ind') ? undefined : json['in_service_distrib_ind'],
        'in_service_distrib_amt': !exists(json, 'in_service_distrib_amt') ? undefined : json['in_service_distrib_amt'],
        'fdcry_trustee_cust_name': !exists(json, 'fdcry_trustee_cust_name') ? undefined : json['fdcry_trustee_cust_name'],
        'fdcry_trust_cust_phone_num': !exists(json, 'fdcry_trust_cust_phone_num') ? undefined : json['fdcry_trust_cust_phone_num'],
        'fdcry_trust_cust_phon_nu_fore': !exists(json, 'fdcry_trust_cust_phon_nu_fore') ? undefined : json['fdcry_trust_cust_phon_nu_fore'],
        'distrib_made_employee_62_ind': !exists(json, 'distrib_made_employee_62_ind') ? undefined : json['distrib_made_employee_62_ind'],
        'premium_filing_confirm_number': !exists(json, 'premium_filing_confirm_number') ? undefined : json['premium_filing_confirm_number'],
    };
}

export function ExecRiskScheduleIBaseToJSON(value?: ExecRiskScheduleIBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'sch_i_plan_year_begin_date': value.sch_i_plan_year_begin_date,
        'sch_i_tax_prd': value.sch_i_tax_prd,
        'sch_i_plan_num': value.sch_i_plan_num,
        'sch_i_ein': value.sch_i_ein,
        'small_tot_assets_boy_amt': value.small_tot_assets_boy_amt,
        'small_tot_liabilities_boy_amt': value.small_tot_liabilities_boy_amt,
        'small_net_assets_boy_amt': value.small_net_assets_boy_amt,
        'small_tot_assets_eoy_amt': value.small_tot_assets_eoy_amt,
        'small_tot_liabilities_eoy_amt': value.small_tot_liabilities_eoy_amt,
        'small_net_assets_eoy_amt': value.small_net_assets_eoy_amt,
        'small_emplr_contrib_income_amt': value.small_emplr_contrib_income_amt,
        'small_participant_contrib_amt': value.small_participant_contrib_amt,
        'small_oth_contrib_rcvd_amt': value.small_oth_contrib_rcvd_amt,
        'small_non_cash_contrib_bs_amt': value.small_non_cash_contrib_bs_amt,
        'small_other_income_amt': value.small_other_income_amt,
        'small_tot_income_amt': value.small_tot_income_amt,
        'small_tot_distrib_bnft_amt': value.small_tot_distrib_bnft_amt,
        'small_corrective_distrib_amt': value.small_corrective_distrib_amt,
        'small_deem_dstrb_partcp_ln_amt': value.small_deem_dstrb_partcp_ln_amt,
        'small_admin_srvc_providers_amt': value.small_admin_srvc_providers_amt,
        'small_oth_expenses_amt': value.small_oth_expenses_amt,
        'small_tot_expenses_amt': value.small_tot_expenses_amt,
        'small_net_income_amt': value.small_net_income_amt,
        'small_tot_plan_transfers_amt': value.small_tot_plan_transfers_amt,
        'small_joint_venture_eoy_ind': value.small_joint_venture_eoy_ind,
        'small_joint_venture_eoy_amt': value.small_joint_venture_eoy_amt,
        'small_emplr_prop_eoy_ind': value.small_emplr_prop_eoy_ind,
        'small_emplr_prop_eoy_amt': value.small_emplr_prop_eoy_amt,
        'small_inv_real_estate_eoy_ind': value.small_inv_real_estate_eoy_ind,
        'small_inv_real_estate_eoy_amt': value.small_inv_real_estate_eoy_amt,
        'small_emplr_sec_eoy_ind': value.small_emplr_sec_eoy_ind,
        'small_emplr_sec_eoy_amt': value.small_emplr_sec_eoy_amt,
        'small_mortg_partcp_eoy_ind': value.small_mortg_partcp_eoy_ind,
        'small_mortg_partcp_eoy_amt': value.small_mortg_partcp_eoy_amt,
        'small_oth_lns_partcp_eoy_ind': value.small_oth_lns_partcp_eoy_ind,
        'small_oth_lns_partcp_eoy_amt': value.small_oth_lns_partcp_eoy_amt,
        'small_personal_prop_eoy_ind': value.small_personal_prop_eoy_ind,
        'small_personal_prop_eoy_amt': value.small_personal_prop_eoy_amt,
        'small_fail_transm_contrib_ind': value.small_fail_transm_contrib_ind,
        'small_fail_transm_contrib_amt': value.small_fail_transm_contrib_amt,
        'small_loans_in_default_ind': value.small_loans_in_default_ind,
        'small_loans_in_default_amt': value.small_loans_in_default_amt,
        'small_leases_in_default_ind': value.small_leases_in_default_ind,
        'small_leases_in_default_amt': value.small_leases_in_default_amt,
        'sm_party_in_int_not_rptd_ind': value.sm_party_in_int_not_rptd_ind,
        'sm_party_in_int_not_rptd_amt': value.sm_party_in_int_not_rptd_amt,
        'small_plan_ins_fdlty_bond_ind': value.small_plan_ins_fdlty_bond_ind,
        'small_plan_ins_fdlty_bond_amt': value.small_plan_ins_fdlty_bond_amt,
        'small_loss_discv_dur_year_ind': value.small_loss_discv_dur_year_ind,
        'small_loss_discv_dur_year_amt': value.small_loss_discv_dur_year_amt,
        'small_asset_undeterm_val_ind': value.small_asset_undeterm_val_ind,
        'small_asset_undeterm_val_amt': value.small_asset_undeterm_val_amt,
        'small_non_cash_contrib_ind': value.small_non_cash_contrib_ind,
        'small_non_cash_contrib_amt': value.small_non_cash_contrib_amt,
        'small_20_prcnt_sngl_invst_ind': value.small_20_prcnt_sngl_invst_ind,
        'small_20_prcnt_sngl_invst_amt': value.small_20_prcnt_sngl_invst_amt,
        'small_all_plan_ast_distrib_ind': value.small_all_plan_ast_distrib_ind,
        'sm_waiv_annual_iqpa_report_ind': value.sm_waiv_annual_iqpa_report_ind,
        'sm_fail_provide_benef_due_ind': value.sm_fail_provide_benef_due_ind,
        'sm_fail_provide_benef_due_amt': value.sm_fail_provide_benef_due_amt,
        'small_plan_blackout_period_ind': value.small_plan_blackout_period_ind,
        'sm_comply_blackout_notice_ind': value.sm_comply_blackout_notice_ind,
        'small_res_term_plan_adpt_ind': value.small_res_term_plan_adpt_ind,
        'small_res_term_plan_adpt_amt': value.small_res_term_plan_adpt_amt,
        'fdcry_trust_ein': value.fdcry_trust_ein,
        'fdcry_trust_name': value.fdcry_trust_name,
        'small_covered_pbgc_ins_ind': value.small_covered_pbgc_ins_ind,
        'trust_incur_unrel_tax_inc_ind': value.trust_incur_unrel_tax_inc_ind,
        'trust_incur_unrel_tax_inc_amt': value.trust_incur_unrel_tax_inc_amt,
        'in_service_distrib_ind': value.in_service_distrib_ind,
        'in_service_distrib_amt': value.in_service_distrib_amt,
        'fdcry_trustee_cust_name': value.fdcry_trustee_cust_name,
        'fdcry_trust_cust_phone_num': value.fdcry_trust_cust_phone_num,
        'fdcry_trust_cust_phon_nu_fore': value.fdcry_trust_cust_phon_nu_fore,
        'distrib_made_employee_62_ind': value.distrib_made_employee_62_ind,
        'premium_filing_confirm_number': value.premium_filing_confirm_number,
    };
}

