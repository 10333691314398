import React from 'react';
import clsx from 'clsx';
import MuiTextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const TextField = withCoreProps<TextFieldProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<TextFieldProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiTextField {...props} variant="outlined" className={clsxClass}>
        {children}
      </MuiTextField>
    );
  },
);

export interface TextFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant'>,
    CoreProps {}
