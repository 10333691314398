/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskBingNewsOverviewEntry
 */
export interface ExecRiskBingNewsOverviewEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    entity: string;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    triggers: Triggers;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    triggers_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    positive_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    negative_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsOverviewEntry
     */
    neutral_count?: number;
}

/**
 * Check if a given object implements the ExecRiskBingNewsOverviewEntry interface.
 */
export function instanceOfExecRiskBingNewsOverviewEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "triggers" in value;
    isInstance = isInstance && "triggers_count" in value;

    return isInstance;
}

export function ExecRiskBingNewsOverviewEntryFromJSON(json: any): ExecRiskBingNewsOverviewEntry {
    return ExecRiskBingNewsOverviewEntryFromJSONTyped(json, false);
}

export function ExecRiskBingNewsOverviewEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskBingNewsOverviewEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'triggers': TriggersFromJSON(json['triggers']),
        'triggers_count': json['triggers_count'],
        'positive_count': !exists(json, 'positive_count') ? undefined : json['positive_count'],
        'negative_count': !exists(json, 'negative_count') ? undefined : json['negative_count'],
        'neutral_count': !exists(json, 'neutral_count') ? undefined : json['neutral_count'],
    };
}

export function ExecRiskBingNewsOverviewEntryToJSON(value?: ExecRiskBingNewsOverviewEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'triggers': TriggersToJSON(value.triggers),
        'triggers_count': value.triggers_count,
        'positive_count': value.positive_count,
        'negative_count': value.negative_count,
        'neutral_count': value.neutral_count,
    };
}

