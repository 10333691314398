import React, { useMemo, useCallback, ReactNode } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { TextField, TextFieldProps, InputAdornment } from '@explorer/core';
import { formatMessage } from '@explorer/helpers';

/**
 * FieldNumber
 */
export const FieldNumber = ({
  label,
  autoComplete = 'off',
  min = '0',
  max,
  setFieldValue,
  setFieldError,
  className,
  value,
  name,
  onChange,
  ref,
  currencySymbol,
  ...props
}: FieldNumberProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const handleChange = useCallback(
    (numberFormat: NumberFormatValues) => {
      const { floatValue } = numberFormat;

      if (setFieldValue) {
        if (typeof floatValue === 'number') {
          setFieldValue(name, floatValue);
        } else {
          setFieldValue(name, null);
        }
      }
    },
    [name, setFieldValue, min, max],
  );

  const InputProps = useMemo(() => {
    if (!currencySymbol) return {};

    return {
      startAdornment: (
        <InputAdornment position="start">{currencySymbol}</InputAdornment>
      ),
    };
  }, [currencySymbol]);

  return (
    <NumberFormat
      {...props}
      name={name}
      value={value}
      customInput={TextField}
      thousandSeparator
      label={intlLabel}
      className={clsxClass}
      autoComplete={autoComplete}
      onValueChange={handleChange}
      InputProps={InputProps}
    />
  );
};

/**
 * FieldNumber Props
 */
export type FieldNumberProps = Omit<
  TextFieldProps,
  'defaultValue' | 'type' | 'size'
> & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `value` - value of the input
   */
  value: number | null;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `min` - standard html input `min` prop
   */
  min?: string;
  /**
   * `max` - standard html input `max` prop
   */
  max?: string;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `autoComplete` - google autocomplete prop
   */
  autoComplete?: 'off';
  /**
   * `currencySymbol` - currency symbol to be used as an input adornment
   */
  currencySymbol?: '$' | '€' | 'USD' | 'EUR' | 'CAD';
};
