import React from 'react';
import { Button, ButtonProps } from '@explorer/core';
import { FormikProps } from 'formik';

/**
 * SubmitButton
 */
export const SubmitButton = ({
  formId,
  type = 'submit',
  formik,
  isLoading,
  ...props
}: SubmitButtonProps) => {
  return (
    <Button
      {...props}
      type={type}
      id={`${formId}-Submit`}
      loading={formik.isSubmitting || isLoading}
    />
  );
};

/**
 * SubmitButton Props
 */
export interface SubmitButtonProps extends ButtonProps {
  /**
   * `formId` - the form id, used to generate ids for every input field
   */
  formId: string;
  /**
   * `isLoading` - if true, the button will be disabled and show a loading spinner
   */
  isLoading?: boolean;
  /**
   * `formik` - the object returned by the `useFormik()` hook
   */
  formik: FormikProps<any>;
}
