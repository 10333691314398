/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlGetLicenseTypeOrderBy = {
    MonitType: 'monit_type',
    Created: 'created',
    Updated: 'updated'
} as const;
export type ControlGetLicenseTypeOrderBy = typeof ControlGetLicenseTypeOrderBy[keyof typeof ControlGetLicenseTypeOrderBy];


export function ControlGetLicenseTypeOrderByFromJSON(json: any): ControlGetLicenseTypeOrderBy {
    return ControlGetLicenseTypeOrderByFromJSONTyped(json, false);
}

export function ControlGetLicenseTypeOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetLicenseTypeOrderBy {
    return json as ControlGetLicenseTypeOrderBy;
}

export function ControlGetLicenseTypeOrderByToJSON(value?: ControlGetLicenseTypeOrderBy | null): any {
    return value as any;
}

