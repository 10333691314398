/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnership } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnership';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnershipToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnership';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetail } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetail';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetailFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetailFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetailToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetail';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR {
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    IdDisregardedEntitiesGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    IdRelatedTaxExemptOrgGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnership>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    IdRelatedOrgTxblPartnershipGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnership>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    IdRelatedOrgTxblCorpTrGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    ReceiptOfIntAnntsRntsRyltsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    GiftGrntOrCapContriToOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    GiftGrntCapContriFromOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    LoansOrGuaranteesToOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    LoansOrGuaranteesFromOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    DivRelatedOrganizationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    AssetSaleToOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    AssetPurchaseFromOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    AssetExchangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    RentalOfFacilitiesToOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    RentalOfFcltsFromOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    PerformOfServicesForOthOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    PerformOfServicesByOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    SharingOfFacilitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    PaidEmployeesSharingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    ReimbursementPaidToOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    ReimbursementPaidByOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    TransferToOtherOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    TransferFromOtherOrgInd?: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    TransactionsRelatedOrgGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    UnrelatedOrgTxblPartnershipGrp?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetail>}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR
     */
    SupplementalInformationDetail?: Array<ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetail>;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IdDisregardedEntitiesGrp': !exists(json, 'IdDisregardedEntitiesGrp') ? undefined : ((json['IdDisregardedEntitiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSON)),
        'IdRelatedTaxExemptOrgGrp': !exists(json, 'IdRelatedTaxExemptOrgGrp') ? undefined : ((json['IdRelatedTaxExemptOrgGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSON)),
        'IdRelatedOrgTxblPartnershipGrp': !exists(json, 'IdRelatedOrgTxblPartnershipGrp') ? undefined : ((json['IdRelatedOrgTxblPartnershipGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSON)),
        'IdRelatedOrgTxblCorpTrGrp': !exists(json, 'IdRelatedOrgTxblCorpTrGrp') ? undefined : ((json['IdRelatedOrgTxblCorpTrGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON)),
        'ReceiptOfIntAnntsRntsRyltsInd': !exists(json, 'ReceiptOfIntAnntsRntsRyltsInd') ? undefined : json['ReceiptOfIntAnntsRntsRyltsInd'],
        'GiftGrntOrCapContriToOthOrgInd': !exists(json, 'GiftGrntOrCapContriToOthOrgInd') ? undefined : json['GiftGrntOrCapContriToOthOrgInd'],
        'GiftGrntCapContriFromOthOrgInd': !exists(json, 'GiftGrntCapContriFromOthOrgInd') ? undefined : json['GiftGrntCapContriFromOthOrgInd'],
        'LoansOrGuaranteesToOtherOrgInd': !exists(json, 'LoansOrGuaranteesToOtherOrgInd') ? undefined : json['LoansOrGuaranteesToOtherOrgInd'],
        'LoansOrGuaranteesFromOthOrgInd': !exists(json, 'LoansOrGuaranteesFromOthOrgInd') ? undefined : json['LoansOrGuaranteesFromOthOrgInd'],
        'DivRelatedOrganizationInd': !exists(json, 'DivRelatedOrganizationInd') ? undefined : json['DivRelatedOrganizationInd'],
        'AssetSaleToOtherOrgInd': !exists(json, 'AssetSaleToOtherOrgInd') ? undefined : json['AssetSaleToOtherOrgInd'],
        'AssetPurchaseFromOtherOrgInd': !exists(json, 'AssetPurchaseFromOtherOrgInd') ? undefined : json['AssetPurchaseFromOtherOrgInd'],
        'AssetExchangeInd': !exists(json, 'AssetExchangeInd') ? undefined : json['AssetExchangeInd'],
        'RentalOfFacilitiesToOthOrgInd': !exists(json, 'RentalOfFacilitiesToOthOrgInd') ? undefined : json['RentalOfFacilitiesToOthOrgInd'],
        'RentalOfFcltsFromOthOrgInd': !exists(json, 'RentalOfFcltsFromOthOrgInd') ? undefined : json['RentalOfFcltsFromOthOrgInd'],
        'PerformOfServicesForOthOrgInd': !exists(json, 'PerformOfServicesForOthOrgInd') ? undefined : json['PerformOfServicesForOthOrgInd'],
        'PerformOfServicesByOtherOrgInd': !exists(json, 'PerformOfServicesByOtherOrgInd') ? undefined : json['PerformOfServicesByOtherOrgInd'],
        'SharingOfFacilitiesInd': !exists(json, 'SharingOfFacilitiesInd') ? undefined : json['SharingOfFacilitiesInd'],
        'PaidEmployeesSharingInd': !exists(json, 'PaidEmployeesSharingInd') ? undefined : json['PaidEmployeesSharingInd'],
        'ReimbursementPaidToOtherOrgInd': !exists(json, 'ReimbursementPaidToOtherOrgInd') ? undefined : json['ReimbursementPaidToOtherOrgInd'],
        'ReimbursementPaidByOtherOrgInd': !exists(json, 'ReimbursementPaidByOtherOrgInd') ? undefined : json['ReimbursementPaidByOtherOrgInd'],
        'TransferToOtherOrgInd': !exists(json, 'TransferToOtherOrgInd') ? undefined : json['TransferToOtherOrgInd'],
        'TransferFromOtherOrgInd': !exists(json, 'TransferFromOtherOrgInd') ? undefined : json['TransferFromOtherOrgInd'],
        'TransactionsRelatedOrgGrp': !exists(json, 'TransactionsRelatedOrgGrp') ? undefined : ((json['TransactionsRelatedOrgGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSON)),
        'UnrelatedOrgTxblPartnershipGrp': !exists(json, 'UnrelatedOrgTxblPartnershipGrp') ? undefined : ((json['UnrelatedOrgTxblPartnershipGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON)),
        'SupplementalInformationDetail': !exists(json, 'SupplementalInformationDetail') ? undefined : ((json['SupplementalInformationDetail'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetailFromJSON)),
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IdDisregardedEntitiesGrp': value.IdDisregardedEntitiesGrp === undefined ? undefined : ((value.IdDisregardedEntitiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesToJSON)),
        'IdRelatedTaxExemptOrgGrp': value.IdRelatedTaxExemptOrgGrp === undefined ? undefined : ((value.IdRelatedTaxExemptOrgGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgToJSON)),
        'IdRelatedOrgTxblPartnershipGrp': value.IdRelatedOrgTxblPartnershipGrp === undefined ? undefined : ((value.IdRelatedOrgTxblPartnershipGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblPartnershipToJSON)),
        'IdRelatedOrgTxblCorpTrGrp': value.IdRelatedOrgTxblCorpTrGrp === undefined ? undefined : ((value.IdRelatedOrgTxblCorpTrGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON)),
        'ReceiptOfIntAnntsRntsRyltsInd': value.ReceiptOfIntAnntsRntsRyltsInd,
        'GiftGrntOrCapContriToOthOrgInd': value.GiftGrntOrCapContriToOthOrgInd,
        'GiftGrntCapContriFromOthOrgInd': value.GiftGrntCapContriFromOthOrgInd,
        'LoansOrGuaranteesToOtherOrgInd': value.LoansOrGuaranteesToOtherOrgInd,
        'LoansOrGuaranteesFromOthOrgInd': value.LoansOrGuaranteesFromOthOrgInd,
        'DivRelatedOrganizationInd': value.DivRelatedOrganizationInd,
        'AssetSaleToOtherOrgInd': value.AssetSaleToOtherOrgInd,
        'AssetPurchaseFromOtherOrgInd': value.AssetPurchaseFromOtherOrgInd,
        'AssetExchangeInd': value.AssetExchangeInd,
        'RentalOfFacilitiesToOthOrgInd': value.RentalOfFacilitiesToOthOrgInd,
        'RentalOfFcltsFromOthOrgInd': value.RentalOfFcltsFromOthOrgInd,
        'PerformOfServicesForOthOrgInd': value.PerformOfServicesForOthOrgInd,
        'PerformOfServicesByOtherOrgInd': value.PerformOfServicesByOtherOrgInd,
        'SharingOfFacilitiesInd': value.SharingOfFacilitiesInd,
        'PaidEmployeesSharingInd': value.PaidEmployeesSharingInd,
        'ReimbursementPaidToOtherOrgInd': value.ReimbursementPaidToOtherOrgInd,
        'ReimbursementPaidByOtherOrgInd': value.ReimbursementPaidByOtherOrgInd,
        'TransferToOtherOrgInd': value.TransferToOtherOrgInd,
        'TransferFromOtherOrgInd': value.TransferFromOtherOrgInd,
        'TransactionsRelatedOrgGrp': value.TransactionsRelatedOrgGrp === undefined ? undefined : ((value.TransactionsRelatedOrgGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgToJSON)),
        'UnrelatedOrgTxblPartnershipGrp': value.UnrelatedOrgTxblPartnershipGrp === undefined ? undefined : ((value.UnrelatedOrgTxblPartnershipGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON)),
        'SupplementalInformationDetail': value.SupplementalInformationDetail === undefined ? undefined : ((value.SupplementalInformationDetail as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRSupplementalInformationDetailToJSON)),
    };
}

