/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSpiderDataItem } from './CRSFSpiderDataItem';
import {
    CRSFSpiderDataItemFromJSON,
    CRSFSpiderDataItemFromJSONTyped,
    CRSFSpiderDataItemToJSON,
} from './CRSFSpiderDataItem';

/**
 * 
 * @export
 * @interface CRSFSpidersEventItem
 */
export interface CRSFSpidersEventItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSpidersEventItem
     */
    asset: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSpidersEventItem
     */
    created: number;
    /**
     * 
     * @type {CRSFSpiderDataItem}
     * @memberof CRSFSpidersEventItem
     */
    data?: CRSFSpiderDataItem;
    /**
     * 
     * @type {string}
     * @memberof CRSFSpidersEventItem
     */
    ext_type: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSpidersEventItem
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSpidersEventItem
     */
    process_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSpidersEventItem
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSpidersEventItem
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSpidersEventItem
     */
    source_asset: string;
}

/**
 * Check if a given object implements the CRSFSpidersEventItem interface.
 */
export function instanceOfCRSFSpidersEventItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "ext_type" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "shared" in value;
    isInstance = isInstance && "source_asset" in value;

    return isInstance;
}

export function CRSFSpidersEventItemFromJSON(json: any): CRSFSpidersEventItem {
    return CRSFSpidersEventItemFromJSONTyped(json, false);
}

export function CRSFSpidersEventItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSpidersEventItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'created': json['created'],
        'data': !exists(json, 'data') ? undefined : CRSFSpiderDataItemFromJSON(json['data']),
        'ext_type': json['ext_type'],
        'found': json['found'],
        'process_id': json['process_id'],
        'shared': json['shared'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': json['source_asset'],
    };
}

export function CRSFSpidersEventItemToJSON(value?: CRSFSpidersEventItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'created': value.created,
        'data': CRSFSpiderDataItemToJSON(value.data),
        'ext_type': value.ext_type,
        'found': value.found,
        'process_id': value.process_id,
        'shared': value.shared,
        'source': value.source,
        'source_asset': value.source_asset,
    };
}

