/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityPasswordsAggCharacters } from './MonitEntityPasswordsAggCharacters';
import {
    MonitEntityPasswordsAggCharactersFromJSON,
    MonitEntityPasswordsAggCharactersFromJSONTyped,
    MonitEntityPasswordsAggCharactersToJSON,
} from './MonitEntityPasswordsAggCharacters';
import type { MonitEntityPasswordsAggComposition } from './MonitEntityPasswordsAggComposition';
import {
    MonitEntityPasswordsAggCompositionFromJSON,
    MonitEntityPasswordsAggCompositionFromJSONTyped,
    MonitEntityPasswordsAggCompositionToJSON,
} from './MonitEntityPasswordsAggComposition';

/**
 * 
 * @export
 * @interface MonitEntityPasswordsAggResponse
 */
export interface MonitEntityPasswordsAggResponse {
    /**
     * 
     * @type {MonitEntityPasswordsAggCharacters}
     * @memberof MonitEntityPasswordsAggResponse
     */
    characters?: MonitEntityPasswordsAggCharacters;
    /**
     * 
     * @type {MonitEntityPasswordsAggComposition}
     * @memberof MonitEntityPasswordsAggResponse
     */
    composition?: MonitEntityPasswordsAggComposition;
}

/**
 * Check if a given object implements the MonitEntityPasswordsAggResponse interface.
 */
export function instanceOfMonitEntityPasswordsAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityPasswordsAggResponseFromJSON(json: any): MonitEntityPasswordsAggResponse {
    return MonitEntityPasswordsAggResponseFromJSONTyped(json, false);
}

export function MonitEntityPasswordsAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityPasswordsAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'characters': !exists(json, 'characters') ? undefined : MonitEntityPasswordsAggCharactersFromJSON(json['characters']),
        'composition': !exists(json, 'composition') ? undefined : MonitEntityPasswordsAggCompositionFromJSON(json['composition']),
    };
}

export function MonitEntityPasswordsAggResponseToJSON(value?: MonitEntityPasswordsAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'characters': MonitEntityPasswordsAggCharactersToJSON(value.characters),
        'composition': MonitEntityPasswordsAggCompositionToJSON(value.composition),
    };
}

