import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import {
  RadioGroup,
  RadioGroupProps,
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
} from '@explorer/core';

/**
 * FieldRadio
 */
export const FieldRadio = ({
  id,
  label,
  noLabel,
  options,
  color = 'primary',
  className,
  error,
  setFieldValue,
  setFieldError,
  ...props
}: FieldRadioProps) => {
  // const classes = useStyles({});
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const radioOptions = useMemo(
    () =>
      options.map((option) => {
        const optionLabel = formatMessage(intl, option?.label);
        return (
          <FormControlLabel
            noUserSelect
            key={option.value}
            label={optionLabel}
            value={option.value}
            disabled={option?.disabled || false}
            control={<Radio color={color} id={`${id}-${option.value}`} />}
          />
        );
      }),
    [options],
  );

  return (
    <FormControl>
      {!noLabel && <FormLabel>{intlLabel}</FormLabel>}
      <RadioGroup {...props} id={id} className={clsxClass}>
        {radioOptions}
      </RadioGroup>
    </FormControl>
  );
};

/**
 * FieldRadio Props
 */
export type FieldRadioProps = RadioGroupProps & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `noLabel` - do not show a label
   */
  noLabel?: boolean;
  /**
   * `options` - field options
   */
  options: RadioOptions;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `color` - color of the Radio Input
   */
  color?: 'primary' | 'secondary' | 'default' | undefined;
};

export interface RadioOption {
  label: IntlLabel;
  value: string | number;
  disabled?: boolean;
}

export type RadioOptions = RadioOption[];
