/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityMappingResult
 */
export interface MonitEntityMappingResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMappingResult
     */
    asset?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityMappingResult
     */
    sources?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityMappingResult
     */
    extra?: object;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMappingResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityMappingResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntityMappingResult interface.
 */
export function instanceOfMonitEntityMappingResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityMappingResultFromJSON(json: any): MonitEntityMappingResult {
    return MonitEntityMappingResultFromJSONTyped(json, false);
}

export function MonitEntityMappingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityMappingResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'sources': !exists(json, 'sources') ? undefined : json['sources'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntityMappingResultToJSON(value?: MonitEntityMappingResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'sources': value.sources,
        'extra': value.extra,
        'created': value.created,
        'updated': value.updated,
    };
}

