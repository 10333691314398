/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormInstanceWithAttachments } from './SupplementalFormInstanceWithAttachments';
import {
    SupplementalFormInstanceWithAttachmentsFromJSON,
    SupplementalFormInstanceWithAttachmentsFromJSONTyped,
    SupplementalFormInstanceWithAttachmentsToJSON,
} from './SupplementalFormInstanceWithAttachments';

/**
 * 
 * @export
 * @interface SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1
 */
export interface SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1 {
    /**
     * 
     * @type {Array<SupplementalFormInstanceWithAttachments>}
     * @memberof SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1
     */
    form_instances?: Array<SupplementalFormInstanceWithAttachments>;
}

/**
 * Check if a given object implements the SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1 interface.
 */
export function instanceOfSupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1FromJSON(json: any): SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1 {
    return SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1FromJSONTyped(json, false);
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_instances': !exists(json, 'form_instances') ? undefined : ((json['form_instances'] as Array<any>).map(SupplementalFormInstanceWithAttachmentsFromJSON)),
    };
}

export function SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1ToJSON(value?: SupplementalGetReportFormInstancesByFilterResponseResultsInnerAllOf1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_instances': value.form_instances === undefined ? undefined : ((value.form_instances as Array<any>).map(SupplementalFormInstanceWithAttachmentsToJSON)),
    };
}

