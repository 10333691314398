/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFDataleaksAggregate
 */
export interface CRSFDataleaksAggregate {
    [key: string]: any | any;
    /**
     * 
     * @type {object}
     * @memberof CRSFDataleaksAggregate
     */
    others?: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleaksAggregate
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleaksAggregate
     */
    total_emails: number;
}

/**
 * Check if a given object implements the CRSFDataleaksAggregate interface.
 */
export function instanceOfCRSFDataleaksAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "total_emails" in value;

    return isInstance;
}

export function CRSFDataleaksAggregateFromJSON(json: any): CRSFDataleaksAggregate {
    return CRSFDataleaksAggregateFromJSONTyped(json, false);
}

export function CRSFDataleaksAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDataleaksAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'others': !exists(json, 'others') ? undefined : json['others'],
        'total': json['total'],
        'total_emails': json['total_emails'],
    };
}

export function CRSFDataleaksAggregateToJSON(value?: CRSFDataleaksAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'others': value.others,
        'total': value.total,
        'total_emails': value.total_emails,
    };
}

