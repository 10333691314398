import { nextjsRoute } from '../nextjsRoute';

/**
 * Transforms a array of routes to a appRoutes object.
 *
 * __> Example:__
 * ```ts
 * const routes = ['/', '/login', '/logout'] as const;
 *
 * type Routes = typeof routes[number];
 *
 * const appRoutes: AppRoutes = createAppRoutes();
 * ```
 */

export const createAppRoutes: CreateAppRoutes = <AR>(routes = []) => {
  const arr: Record<string, any> = {};

  for (let i = 0, ii = routes.length; i < ii; i++) {
    const route = routes[i];

    arr[route] = nextjsRoute(route);
  }

  return arr as AR;
};

type CreateAppRoutes = <AR>(routes: any) => AR;
