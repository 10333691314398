/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlRescanVulnerabilities } from './ControlRescanVulnerabilities';
import {
    ControlRescanVulnerabilitiesFromJSON,
    ControlRescanVulnerabilitiesFromJSONTyped,
    ControlRescanVulnerabilitiesToJSON,
} from './ControlRescanVulnerabilities';

/**
 * 
 * @export
 * @interface ControlExplorerRescanVulnerabilities
 */
export interface ControlExplorerRescanVulnerabilities {
    /**
     * 
     * @type {ControlRescanVulnerabilities}
     * @memberof ControlExplorerRescanVulnerabilities
     */
    rescan_vulnerabilities: ControlRescanVulnerabilities;
    /**
     * 
     * @type {string}
     * @memberof ControlExplorerRescanVulnerabilities
     */
    staff_email: string;
}

/**
 * Check if a given object implements the ControlExplorerRescanVulnerabilities interface.
 */
export function instanceOfControlExplorerRescanVulnerabilities(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rescan_vulnerabilities" in value;
    isInstance = isInstance && "staff_email" in value;

    return isInstance;
}

export function ControlExplorerRescanVulnerabilitiesFromJSON(json: any): ControlExplorerRescanVulnerabilities {
    return ControlExplorerRescanVulnerabilitiesFromJSONTyped(json, false);
}

export function ControlExplorerRescanVulnerabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlExplorerRescanVulnerabilities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rescan_vulnerabilities': ControlRescanVulnerabilitiesFromJSON(json['rescan_vulnerabilities']),
        'staff_email': json['staff_email'],
    };
}

export function ControlExplorerRescanVulnerabilitiesToJSON(value?: ControlExplorerRescanVulnerabilities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rescan_vulnerabilities': ControlRescanVulnerabilitiesToJSON(value.rescan_vulnerabilities),
        'staff_email': value.staff_email,
    };
}

