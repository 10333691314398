/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupplementalFileType = {
    Pdf: 'pdf'
} as const;
export type SupplementalFileType = typeof SupplementalFileType[keyof typeof SupplementalFileType];


export function SupplementalFileTypeFromJSON(json: any): SupplementalFileType {
    return SupplementalFileTypeFromJSONTyped(json, false);
}

export function SupplementalFileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalFileType {
    return json as SupplementalFileType;
}

export function SupplementalFileTypeToJSON(value?: SupplementalFileType | null): any {
    return value as any;
}

