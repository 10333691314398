/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlLocale } from './ControlLocale';
import {
    ControlLocaleFromJSON,
    ControlLocaleFromJSONTyped,
    ControlLocaleToJSON,
} from './ControlLocale';

/**
 * 
 * @export
 * @interface ControlUserUpdateRequest
 */
export interface ControlUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlUserUpdateRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserUpdateRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserUpdateRequest
     */
    phone?: string;
    /**
     * 
     * @type {ControlLocale}
     * @memberof ControlUserUpdateRequest
     */
    locale?: ControlLocale;
}

/**
 * Check if a given object implements the ControlUserUpdateRequest interface.
 */
export function instanceOfControlUserUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlUserUpdateRequestFromJSON(json: any): ControlUserUpdateRequest {
    return ControlUserUpdateRequestFromJSONTyped(json, false);
}

export function ControlUserUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'locale': !exists(json, 'locale') ? undefined : ControlLocaleFromJSON(json['locale']),
    };
}

export function ControlUserUpdateRequestToJSON(value?: ControlUserUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'phone': value.phone,
        'locale': ControlLocaleToJSON(value.locale),
    };
}

