/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewSREntityResultData } from './SecReviewSREntityResultData';
import {
    SecReviewSREntityResultDataFromJSON,
    SecReviewSREntityResultDataFromJSONTyped,
    SecReviewSREntityResultDataToJSON,
} from './SecReviewSREntityResultData';

/**
 * 
 * @export
 * @interface SecReviewSREntityResult
 */
export interface SecReviewSREntityResult {
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    data_type?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    module?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof SecReviewSREntityResult
     */
    cves?: Array<object>;
    /**
     * 
     * @type {SecReviewSREntityResultData}
     * @memberof SecReviewSREntityResult
     */
    data?: SecReviewSREntityResultData;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewSREntityResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResult
     */
    source_asset?: string;
    /**
     * 
     * @type {number}
     * @memberof SecReviewSREntityResult
     */
    found?: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewSREntityResult
     */
    updated?: number;
}

/**
 * Check if a given object implements the SecReviewSREntityResult interface.
 */
export function instanceOfSecReviewSREntityResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSREntityResultFromJSON(json: any): SecReviewSREntityResult {
    return SecReviewSREntityResultFromJSONTyped(json, false);
}

export function SecReviewSREntityResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSREntityResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data_type': !exists(json, 'data_type') ? undefined : json['data_type'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'cves': !exists(json, 'cves') ? undefined : json['cves'],
        'data': !exists(json, 'data') ? undefined : SecReviewSREntityResultDataFromJSON(json['data']),
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function SecReviewSREntityResultToJSON(value?: SecReviewSREntityResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data_type': value.data_type,
        'asset': value.asset,
        'port': value.port,
        'protocol': value.protocol,
        'path': value.path,
        'module': value.module,
        'cves': value.cves,
        'data': SecReviewSREntityResultDataToJSON(value.data),
        'shared': value.shared,
        'source_asset': value.source_asset,
        'found': value.found,
        'updated': value.updated,
    };
}

