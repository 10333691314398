import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { createStyles, makeStyles } from '@mui/styles';
import { useQuery, endpoints, UseQueryOptions } from '@explorer/api';
import { Grid, Typography } from '@explorer/core';
import { FormDialog, FormDialogProps, FormFieldControl } from '@explorer/forms';
import { CommonFormProps } from '@explorer/forms/types';
import { useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { useIntl } from 'react-intl';

const FORM_ID = 'reportAsset';
const INITIAL_FORM_VALUES = {
  mute_asset_reason: '',
  options: {
    attestation_legal: false,
  },
};

const useStyles = ({}: any) =>
  makeStyles(
    () =>
      createStyles({
        root: {
          '& label': {
            display: 'flex',
            alignItems: 'flex-start',
          },
          '& .MuiCheckbox-root': {
            paddingTop: 0,
          },
        },
      }),
    { index: 1 },
  )();

const createExplorerMuteQuery = (entityUUID: string): UseQueryOptions => {
  return {
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1controladmin['/entity/[p1]/mute']({
      p1: entityUUID,
    }),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  };
};

export const MuteNotifications = ({
  open = false,
  handleClose,
  onSuccess,
  maxWidth = 'sm',
  entityUUID,
  datapoint,
}: MuteNotificationsProps) => {
  const { snackSuccess, snackError } = useQuerySnackbar();
  const classes = useStyles({});
  const intl = useIntl();

  const queryReportAsset = useQuery(createExplorerMuteQuery(entityUUID));

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema(intl),
    validateOnChange: false,
    onSubmit: (values, { resetForm, setFieldError, setSubmitting }) => {
      if (!values?.options?.attestation_legal) {
        setFieldError(
          'options',
          intl.formatMessage({ id: 'forms-errors.required-confirm-read' }),
        );
        setSubmitting(false);
        return;
      }
      queryReportAsset
        .submit({
          payload: {
            datapoint,
            reason: values.mute_asset_reason,
          },
        })
        .then(({ data, error }) => {
          setSubmitting(false);
          if (error) {
            snackError('Error requesting mute', error);
          } else {
            snackSuccess(
              "Your request for 'Muting Notifications' has been recorded. This request typically takes 48 hours to process and may be followed up with a request for evidence collection.",
            );
            resetForm();
            if (onSuccess) onSuccess({ data, error, values });
          }

          handleClose();
        });
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId: FORM_ID,
        formik,
      }),
    [formik],
  );

  return (
    <FormDialog
      title={{ id: 'forms-presets.mute-notifications-title' }}
      subTitle={{ id: 'forms-presets.mute-notifications-sub-title' }}
      formId={FORM_ID}
      formik={formik}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
      submitButton
      loading={formik.isSubmitting}
      stopPropagation
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="bodyM" pb={2}>
            {datapoint}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.mute_asset_reason} />
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <FormFieldControl {...formSchema.options} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface MuteNotificationsProps
  extends Pick<FormDialogProps, 'open' | 'handleClose' | 'maxWidth'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  entityUUID: string;
  datapoint: string;
}
