/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntitySourcelistsResultEvent } from './MonitEntitySourcelistsResultEvent';
import {
    MonitEntitySourcelistsResultEventFromJSON,
    MonitEntitySourcelistsResultEventFromJSONTyped,
    MonitEntitySourcelistsResultEventToJSON,
} from './MonitEntitySourcelistsResultEvent';

/**
 * 
 * @export
 * @interface MonitEntitySourcelistsResult
 */
export interface MonitEntitySourcelistsResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    asset?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntitySourcelistsResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    first_seen?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    last_seen?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySourcelistsResult
     */
    total_events?: number;
    /**
     * 
     * @type {Array<MonitEntitySourcelistsResultEvent>}
     * @memberof MonitEntitySourcelistsResult
     */
    events?: Array<MonitEntitySourcelistsResultEvent>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySourcelistsResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntitySourcelistsResult interface.
 */
export function instanceOfMonitEntitySourcelistsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySourcelistsResultFromJSON(json: any): MonitEntitySourcelistsResult {
    return MonitEntitySourcelistsResultFromJSONTyped(json, false);
}

export function MonitEntitySourcelistsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySourcelistsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'total_events': !exists(json, 'total_events') ? undefined : json['total_events'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(MonitEntitySourcelistsResultEventFromJSON)),
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntitySourcelistsResultToJSON(value?: MonitEntitySourcelistsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_domain': value.entity_domain,
        'entity_name': value.entity_name,
        'asset': value.asset,
        'shared': value.shared,
        'first_seen': value.first_seen,
        'last_seen': value.last_seen,
        'total_events': value.total_events,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(MonitEntitySourcelistsResultEventToJSON)),
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
    };
}

