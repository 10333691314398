/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityNotificationsResult } from './ControlEntityNotificationsResult';
import {
    ControlEntityNotificationsResultFromJSON,
    ControlEntityNotificationsResultFromJSONTyped,
    ControlEntityNotificationsResultToJSON,
} from './ControlEntityNotificationsResult';

/**
 * 
 * @export
 * @interface ControlPaginatedGetEntityNotificationsResponse
 */
export interface ControlPaginatedGetEntityNotificationsResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedGetEntityNotificationsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedGetEntityNotificationsResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedGetEntityNotificationsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityNotificationsResult>}
     * @memberof ControlPaginatedGetEntityNotificationsResponse
     */
    results?: Array<ControlEntityNotificationsResult>;
}

/**
 * Check if a given object implements the ControlPaginatedGetEntityNotificationsResponse interface.
 */
export function instanceOfControlPaginatedGetEntityNotificationsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlPaginatedGetEntityNotificationsResponseFromJSON(json: any): ControlPaginatedGetEntityNotificationsResponse {
    return ControlPaginatedGetEntityNotificationsResponseFromJSONTyped(json, false);
}

export function ControlPaginatedGetEntityNotificationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedGetEntityNotificationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlEntityNotificationsResultFromJSON)),
    };
}

export function ControlPaginatedGetEntityNotificationsResponseToJSON(value?: ControlPaginatedGetEntityNotificationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlEntityNotificationsResultToJSON)),
    };
}

