/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityStatsByDayResponse
 */
export interface MonitEntityStatsByDayResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsByDayResponse
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsByDayResponse
     */
    value?: number;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsByDayResponse
     */
    data?: object;
}

/**
 * Check if a given object implements the MonitEntityStatsByDayResponse interface.
 */
export function instanceOfMonitEntityStatsByDayResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityStatsByDayResponseFromJSON(json: any): MonitEntityStatsByDayResponse {
    return MonitEntityStatsByDayResponseFromJSONTyped(json, false);
}

export function MonitEntityStatsByDayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsByDayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function MonitEntityStatsByDayResponseToJSON(value?: MonitEntityStatsByDayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'value': value.value,
        'data': value.data,
    };
}

