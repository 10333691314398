import React from 'react';
import { ChipSmall, TypographyProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';
import { SupplementalFormInstanceStatus } from '@explorer/open-api-client';

/**
 * CellSupplementalFormActivity
 */
export const CellSupplementalFormActivity = ({
  variant = 'bodyXS',
  activity,
}: CellSupplementalFormActivityProps) => {
  if (!activity) return <CellEmpty variant={variant} />;

  if (activity === 'SUBMITTED') {
    return <ChipSmall label={activity} color="low" />;
  }

  if (activity === 'EXPIRED') {
    return <ChipSmall label={activity} color="critical" />;
  }

  if (activity === 'PARTIALLY_COMPLETED') {
    return <ChipSmall label={activity} color="medium" />;
  }

  return <ChipSmall label={activity} color="neutral" />;
};

/**
 * CellSupplementalFormActivity Props
 */
export interface CellSupplementalFormActivityProps
  extends Pick<TypographyProps, 'variant'> {
  readonly activity?: SupplementalFormInstanceStatus;
}
