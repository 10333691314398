/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewIpAddress } from './CRSFSecReviewIpAddress';
import {
    CRSFSecReviewIpAddressFromJSON,
    CRSFSecReviewIpAddressFromJSONTyped,
    CRSFSecReviewIpAddressToJSON,
} from './CRSFSecReviewIpAddress';

/**
 * 
 * @export
 * @interface CRSFSecReviewIpAddressesPagedResponse
 */
export interface CRSFSecReviewIpAddressesPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewIpAddressesPagedResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewIpAddressesPagedResponse
     */
    page_size?: number;
    /**
     * 
     * @type {Array<CRSFSecReviewIpAddress>}
     * @memberof CRSFSecReviewIpAddressesPagedResponse
     */
    results?: Array<CRSFSecReviewIpAddress>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewIpAddressesPagedResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the CRSFSecReviewIpAddressesPagedResponse interface.
 */
export function instanceOfCRSFSecReviewIpAddressesPagedResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewIpAddressesPagedResponseFromJSON(json: any): CRSFSecReviewIpAddressesPagedResponse {
    return CRSFSecReviewIpAddressesPagedResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewIpAddressesPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewIpAddressesPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(CRSFSecReviewIpAddressFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function CRSFSecReviewIpAddressesPagedResponseToJSON(value?: CRSFSecReviewIpAddressesPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(CRSFSecReviewIpAddressToJSON)),
        'total': value.total,
    };
}

