/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDomainsEmailAggData } from './MonitEntityDomainsEmailAggData';
import {
    MonitEntityDomainsEmailAggDataFromJSON,
    MonitEntityDomainsEmailAggDataFromJSONTyped,
    MonitEntityDomainsEmailAggDataToJSON,
} from './MonitEntityDomainsEmailAggData';

/**
 * 
 * @export
 * @interface MonitEntityDomainsEmailAggResponse
 */
export interface MonitEntityDomainsEmailAggResponse {
    /**
     * 
     * @type {MonitEntityDomainsEmailAggData}
     * @memberof MonitEntityDomainsEmailAggResponse
     */
    spf?: MonitEntityDomainsEmailAggData;
    /**
     * 
     * @type {MonitEntityDomainsEmailAggData}
     * @memberof MonitEntityDomainsEmailAggResponse
     */
    without_spf?: MonitEntityDomainsEmailAggData;
    /**
     * 
     * @type {MonitEntityDomainsEmailAggData}
     * @memberof MonitEntityDomainsEmailAggResponse
     */
    dmarc?: MonitEntityDomainsEmailAggData;
    /**
     * 
     * @type {MonitEntityDomainsEmailAggData}
     * @memberof MonitEntityDomainsEmailAggResponse
     */
    without_dmarc?: MonitEntityDomainsEmailAggData;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDomainsEmailAggResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the MonitEntityDomainsEmailAggResponse interface.
 */
export function instanceOfMonitEntityDomainsEmailAggResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDomainsEmailAggResponseFromJSON(json: any): MonitEntityDomainsEmailAggResponse {
    return MonitEntityDomainsEmailAggResponseFromJSONTyped(json, false);
}

export function MonitEntityDomainsEmailAggResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDomainsEmailAggResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spf': !exists(json, 'spf') ? undefined : MonitEntityDomainsEmailAggDataFromJSON(json['spf']),
        'without_spf': !exists(json, 'without_spf') ? undefined : MonitEntityDomainsEmailAggDataFromJSON(json['without_spf']),
        'dmarc': !exists(json, 'dmarc') ? undefined : MonitEntityDomainsEmailAggDataFromJSON(json['dmarc']),
        'without_dmarc': !exists(json, 'without_dmarc') ? undefined : MonitEntityDomainsEmailAggDataFromJSON(json['without_dmarc']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function MonitEntityDomainsEmailAggResponseToJSON(value?: MonitEntityDomainsEmailAggResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spf': MonitEntityDomainsEmailAggDataToJSON(value.spf),
        'without_spf': MonitEntityDomainsEmailAggDataToJSON(value.without_spf),
        'dmarc': MonitEntityDomainsEmailAggDataToJSON(value.dmarc),
        'without_dmarc': MonitEntityDomainsEmailAggDataToJSON(value.without_dmarc),
        'total': value.total,
    };
}

