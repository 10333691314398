import React from 'react';
import { Box, IconButton, Tooltip } from '@explorer/core';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

/**
 * CellPDFDownload
 */
export const CellPDFDownload = ({ reportUrl }: CellPDFDownloadProps) => {
  if (!reportUrl) {
    return (
      <Box textAlign="center">
        <Tooltip
          title={{ id: 'tables-presets.open-file-error' }}
          placement="left"
        >
          <IconButton color="default" size="small" disabled>
            <ErrorOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box textAlign="center">
      <Tooltip title={{ id: 'tables-presets.open-file' }} placement="left">
        <IconButton
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            window.open(reportUrl, '_newtab');
          }}
        >
          <PictureAsPdfIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

/**
 * CellPDFDownload Props
 */
export interface CellPDFDownloadProps {
  reportUrl?: string | null;
}
