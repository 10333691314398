/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDecisionConditionPlain } from './CRSFDecisionConditionPlain';
import {
    CRSFDecisionConditionPlainFromJSON,
    CRSFDecisionConditionPlainFromJSONTyped,
    CRSFDecisionConditionPlainToJSON,
} from './CRSFDecisionConditionPlain';
import type { CRSFDecisionTypePlain } from './CRSFDecisionTypePlain';
import {
    CRSFDecisionTypePlainFromJSON,
    CRSFDecisionTypePlainFromJSONTyped,
    CRSFDecisionTypePlainToJSON,
} from './CRSFDecisionTypePlain';

/**
 * Class that represents one security decision (output of security_decisions module)
 * @export
 * @interface CRSFDecisionRecord
 */
export interface CRSFDecisionRecord {
    /**
     * 
     * @type {CRSFDecisionConditionPlain}
     * @memberof CRSFDecisionRecord
     */
    condition: CRSFDecisionConditionPlain;
    /**
     * 
     * @type {number}
     * @memberof CRSFDecisionRecord
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    datapoint: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    decision_key: string;
    /**
     * 
     * @type {CRSFDecisionTypePlain}
     * @memberof CRSFDecisionRecord
     */
    decision_type: CRSFDecisionTypePlain;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    enforce_type: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    evidence_type: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    finding_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    issue: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    process_id: string;
    /**
     * 
     * @type {object}
     * @memberof CRSFDecisionRecord
     */
    raw_finding_record?: object;
    /**
     * 
     * @type {string}
     * @memberof CRSFDecisionRecord
     */
    template_name: string;
}

/**
 * Check if a given object implements the CRSFDecisionRecord interface.
 */
export function instanceOfCRSFDecisionRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "condition" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "decision_key" in value;
    isInstance = isInstance && "decision_type" in value;
    isInstance = isInstance && "enforce_type" in value;
    isInstance = isInstance && "evidence_type" in value;
    isInstance = isInstance && "finding_key" in value;
    isInstance = isInstance && "issue" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "template_name" in value;

    return isInstance;
}

export function CRSFDecisionRecordFromJSON(json: any): CRSFDecisionRecord {
    return CRSFDecisionRecordFromJSONTyped(json, false);
}

export function CRSFDecisionRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDecisionRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition': CRSFDecisionConditionPlainFromJSON(json['condition']),
        'created': json['created'],
        'datapoint': json['datapoint'],
        'decision_key': json['decision_key'],
        'decision_type': CRSFDecisionTypePlainFromJSON(json['decision_type']),
        'enforce_type': json['enforce_type'],
        'evidence_type': json['evidence_type'],
        'finding_key': json['finding_key'],
        'issue': json['issue'],
        'process_id': json['process_id'],
        'raw_finding_record': !exists(json, 'raw_finding_record') ? undefined : json['raw_finding_record'],
        'template_name': json['template_name'],
    };
}

export function CRSFDecisionRecordToJSON(value?: CRSFDecisionRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition': CRSFDecisionConditionPlainToJSON(value.condition),
        'created': value.created,
        'datapoint': value.datapoint,
        'decision_key': value.decision_key,
        'decision_type': CRSFDecisionTypePlainToJSON(value.decision_type),
        'enforce_type': value.enforce_type,
        'evidence_type': value.evidence_type,
        'finding_key': value.finding_key,
        'issue': value.issue,
        'process_id': value.process_id,
        'raw_finding_record': value.raw_finding_record,
        'template_name': value.template_name,
    };
}

