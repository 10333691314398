/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFBuiltwithRecord } from './CRSFBuiltwithRecord';
import {
    CRSFBuiltwithRecordFromJSON,
    CRSFBuiltwithRecordFromJSONTyped,
    CRSFBuiltwithRecordToJSON,
} from './CRSFBuiltwithRecord';
import type { CRSFBuiltwithRecordItem } from './CRSFBuiltwithRecordItem';
import {
    CRSFBuiltwithRecordItemFromJSON,
    CRSFBuiltwithRecordItemFromJSONTyped,
    CRSFBuiltwithRecordItemToJSON,
} from './CRSFBuiltwithRecordItem';

/**
 * 
 * @export
 * @interface Builtwith
 */
export interface Builtwith {
    /**
     * 
     * @type {Array<CRSFBuiltwithRecordItem>}
     * @memberof Builtwith
     */
    Results: Array<CRSFBuiltwithRecordItem>;
    /**
     * 
     * @type {number}
     * @memberof Builtwith
     */
    created: number;
    /**
     * 
     * @type {string}
     * @memberof Builtwith
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof Builtwith
     */
    process_id: string;
}

/**
 * Check if a given object implements the Builtwith interface.
 */
export function instanceOfBuiltwith(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Results" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function BuiltwithFromJSON(json: any): Builtwith {
    return BuiltwithFromJSONTyped(json, false);
}

export function BuiltwithFromJSONTyped(json: any, ignoreDiscriminator: boolean): Builtwith {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Results': ((json['Results'] as Array<any>).map(CRSFBuiltwithRecordItemFromJSON)),
        'created': json['created'],
        'domain': json['domain'],
        'process_id': json['process_id'],
    };
}

export function BuiltwithToJSON(value?: Builtwith | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Results': ((value.Results as Array<any>).map(CRSFBuiltwithRecordItemToJSON)),
        'created': value.created,
        'domain': value.domain,
        'process_id': value.process_id,
    };
}

