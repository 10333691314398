import { createApiEndpoints, FuncEndpoint, P1, P2 } from '../utils';

const PREFIX_ASM = '/asm';

const ENDPOINTS_ASM = [
  `/domains`,
  `/entity/${P1}/assets/reported`,
  `/entity/${P1}/assets/${P2}/report`,
  `/entity/${P1}/dataleaks`,
  `/entity/${P1}/domains/${P2}/findings`,
  `/entity/${P1}/domains/${P2}/scans`,
  `/entity/${P1}/domains/${P2}/stats`,
  `/entity/${P1}/domains/${P2}/techs`,
  `/entity/${P1}/domains/${P2}`,
  `/entity/${P1}/domains`,
  `/entity/${P1}/findings/aggregate`,
  `/entity/${P1}/findings/top`,
  `/entity/${P1}/findings`,
  `/entity/${P1}/findings/aggregate`,
  `/entity/${P1}/honeypots`,
  `/entity/${P1}/ip_addresses/${P2}/findings`,
  `/entity/${P1}/ip_addresses/${P2}/scans`,
  `/entity/${P1}/ip_addresses/${P2}/stats`,
  `/entity/${P1}/ip_addresses/${P2}/techs`,
  `/entity/${P1}/ip_addresses/${P2}`,
  `/entity/${P1}/ip_addresses`,
  `/entity/${P1}/keywords`,
  `/entity/${P1}/lookalikes`,
  `/entity/${P1}/mute`,
  `/entity/${P1}/notifications`,
  `/entity/${P1}/pastebins`,
  `/entity/${P1}/rescan`,
  `/entity/${P1}/revoke`,
  `/entity/${P1}/risk_history`,
  `/entity/${P1}/resolutions`,
  `/entity/${P1}/resolutions/${P2}`,
  `/entity/${P1}/stats/by_day`,
  `/entity/${P1}/stats`,
  `/entity/${P1}/techs`,
  `/entity/${P1}/torrents`,
  `/entity/${P1}/trigger`,
  `/entity/${P1}`,
  `/entity`,
  `/entity/add-or-change-domain-name`,
  `/impersonate`,
  `/industries`,
  `/ip_addresses`,
  `/me`,
  `/licenses`,
  `/notifications`,
  `/related/findings`,
  `/related/historical`,
  `/related/stats`,
  `/related`,
  `/resolutions/${P1}`,
  `/techs`,
  `/token/create`,
  `/token/list`,
  `/token/revoke`,
] as const;

type Endpoints = (typeof ENDPOINTS_ASM)[number];

export type EndpointsASM = Record<Endpoints, FuncEndpoint>;

export const endpointsASM = createApiEndpoints<Endpoints>(
  PREFIX_ASM,
  ENDPOINTS_ASM,
) as EndpointsASM;
