/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewTicketScanRequest
 */
export interface SecReviewTicketScanRequest {
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    issue_key: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    user?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTicketScanRequest
     */
    force?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTicketScanRequest
     */
    bypass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTicketScanRequest
     */
    nocache?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    se_version?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    rsa_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTicketScanRequest
     */
    dry_run?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewTicketScanRequest
     */
    attachment_domains?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof SecReviewTicketScanRequest
     */
    ticket_payload: object;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    insurance_env?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanRequest
     */
    locale?: string;
}

/**
 * Check if a given object implements the SecReviewTicketScanRequest interface.
 */
export function instanceOfSecReviewTicketScanRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "issue_key" in value;
    isInstance = isInstance && "ticket_payload" in value;

    return isInstance;
}

export function SecReviewTicketScanRequestFromJSON(json: any): SecReviewTicketScanRequest {
    return SecReviewTicketScanRequestFromJSONTyped(json, false);
}

export function SecReviewTicketScanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewTicketScanRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_key': json['issue_key'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'bypass': !exists(json, 'bypass') ? undefined : json['bypass'],
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'se_version': !exists(json, 'se_version') ? undefined : json['se_version'],
        'rsa_version': !exists(json, 'rsa_version') ? undefined : json['rsa_version'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'attachment_domains': !exists(json, 'attachment_domains') ? undefined : json['attachment_domains'],
        'ticket_payload': json['ticket_payload'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : json['insurance_env'],
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
    };
}

export function SecReviewTicketScanRequestToJSON(value?: SecReviewTicketScanRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_key': value.issue_key,
        'user': value.user,
        'force': value.force,
        'bypass': value.bypass,
        'nocache': value.nocache,
        'se_version': value.se_version,
        'rsa_version': value.rsa_version,
        'dry_run': value.dry_run,
        'attachment_domains': value.attachment_domains,
        'ticket_payload': value.ticket_payload,
        'insurance_env': value.insurance_env,
        'account_uuid': value.account_uuid,
        'desired_policy_uuid': value.desired_policy_uuid,
        'renewal_of_uuid': value.renewal_of_uuid,
        'retained_from_uuid': value.retained_from_uuid,
        'request_origin': value.request_origin,
        'locale': value.locale,
    };
}

