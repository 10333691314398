/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlTechCategories = {
    Applications: 'applications',
    Services: 'services',
    Hosting: 'hosting',
    Miscellaneous: 'miscellaneous'
} as const;
export type ControlTechCategories = typeof ControlTechCategories[keyof typeof ControlTechCategories];


export function ControlTechCategoriesFromJSON(json: any): ControlTechCategories {
    return ControlTechCategoriesFromJSONTyped(json, false);
}

export function ControlTechCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTechCategories {
    return json as ControlTechCategories;
}

export function ControlTechCategoriesToJSON(value?: ControlTechCategories | null): any {
    return value as any;
}

