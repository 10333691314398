import React from 'react';
import {
  Grid,
  TypographyLink,
  TypographyLinkProps,
  CopyToClipboard,
} from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellCopyLink
 */
export const CellCopyLink = ({
  text,
  href,
  title,
  variant = 'bodyXS',
  textColor = undefined,
  monospace = false,
  linkIcon = undefined,
  params = {},
  newTab = true,
}: CellCopyLinkProps) => {
  if (!text || !href) return <CellEmpty variant={variant} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item mr={0.5}>
        <CopyToClipboard size="small" copyMe={href} />
      </Grid>
      <Grid item>
        <TypographyLink
          href={href}
          title={title}
          variant={variant}
          monospace={monospace}
          params={params}
          textColor={textColor}
          linkIcon={linkIcon}
          onClick={(e: any) => e.stopPropagation()}
          newTab={newTab}
        >
          {text}
        </TypographyLink>
      </Grid>
    </Grid>
  );
};

/**
 * CellCopyLink Props
 */
export interface CellCopyLinkProps
  extends Pick<
    TypographyLinkProps,
    | 'href'
    | 'title'
    | 'variant'
    | 'monospace'
    | 'textColor'
    | 'linkIcon'
    | 'newTab'
    | 'params'
  > {
  text?: string;
}
