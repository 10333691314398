import React from 'react';
import clsx from 'clsx';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Alert = withCoreProps<AlertProps>(
  ({ children, className, ...props }: React.PropsWithChildren<AlertProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAlert {...props} className={clsxClass}>
        {children}
      </MuiAlert>
    );
  },
);

export interface AlertProps extends MuiAlertProps, CoreProps {}
