/**
 * Formats a company address.
 *
 * __> Example:__
 * ```ts
 * formatCompanyAddress({
 *   line1: '1160 Battery St 350',
 *   locality: 'San Francisco',
 *   region: 'CA',
 *   postal_code: '94111',
 *   country: 'United States',
 * });
 * // '1160 Battery St 350, San Francisco, CA 94111, United States'
 * ```
 */
export const formatCompanyAddress: FormatCompanyAddress = (address) => {
  const {
    line1: l1,
    line2: l2,
    locality: loc,
    region: reg,
    postal_code: pc,
    country: c,
  } = address || {};

  const line1 = l1 ? `${l1}, ` : '';
  const line2 = l2 ? `${l2}, ` : '';
  const locality = loc ? `${loc}, ` : '';
  const region = reg ? `${reg} ` : '';
  const postal_code = pc ? `${pc} ` : '';
  const country = c || '';

  return line1 + line2 + locality + region + postal_code + country;
};

type FormatCompanyAddress = (
  address: APIExecRisksCompany['location'],
) => string;
