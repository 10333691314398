import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TypographyProps, ChipSmall, ChipSmallProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellChips
 */
export const CellChips = ({
  chips = [],
  variant = 'bodyXS',
  textTransform,
}: CellChipsProps) => {
  if (!chips.length) return <CellEmpty variant={variant} />;

  return (
    <>
      {chips.map((chip) => (
        <ChipSmall
          key={uuidv4()}
          label={chip.label}
          color={chip.color}
          textTransform={textTransform}
          mr={0.5}
        />
      ))}
    </>
  );
};

/**
 * CellChips Props
 */
export interface CellChipsProps
  extends Pick<TypographyProps, 'variant'>,
    Pick<ChipSmallProps, 'textTransform'> {
  chips: CellChipItems;
}

export interface CellChipItem extends Pick<ChipSmallProps, 'color' | 'label'> {}

export type CellChipItems = CellChipItem[];
