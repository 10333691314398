/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI
     */
    form_and_line_reference_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI
     */
    explanation_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI {
    return ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_and_line_reference_desc': !exists(json, 'form_and_line_reference_desc') ? undefined : json['form_and_line_reference_desc'],
        'explanation_txt': !exists(json, 'explanation_txt') ? undefined : json['explanation_txt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_and_line_reference_desc': value.form_and_line_reference_desc,
        'explanation_txt': value.explanation_txt,
    };
}

