/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewPayloadFieldsInput } from './CRSFSecReviewPayloadFieldsInput';
import {
    CRSFSecReviewPayloadFieldsInputFromJSON,
    CRSFSecReviewPayloadFieldsInputFromJSONTyped,
    CRSFSecReviewPayloadFieldsInputToJSON,
} from './CRSFSecReviewPayloadFieldsInput';

/**
 * 
 * @export
 * @interface CRSFSecReviewTicketPayloadInput
 */
export interface CRSFSecReviewTicketPayloadInput {
    /**
     * 
     * @type {CRSFSecReviewPayloadFieldsInput}
     * @memberof CRSFSecReviewTicketPayloadInput
     */
    fields?: CRSFSecReviewPayloadFieldsInput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTicketPayloadInput
     */
    key?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTicketPayloadInput interface.
 */
export function instanceOfCRSFSecReviewTicketPayloadInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewTicketPayloadInputFromJSON(json: any): CRSFSecReviewTicketPayloadInput {
    return CRSFSecReviewTicketPayloadInputFromJSONTyped(json, false);
}

export function CRSFSecReviewTicketPayloadInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTicketPayloadInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : CRSFSecReviewPayloadFieldsInputFromJSON(json['fields']),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function CRSFSecReviewTicketPayloadInputToJSON(value?: CRSFSecReviewTicketPayloadInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': CRSFSecReviewPayloadFieldsInputToJSON(value.fields),
        'key': value.key,
    };
}

