/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewPort } from './SecReviewPort';
import {
    SecReviewPortFromJSON,
    SecReviewPortFromJSONTyped,
    SecReviewPortToJSON,
} from './SecReviewPort';

/**
 * 
 * @export
 * @interface SecReviewItemDomain
 */
export interface SecReviewItemDomain {
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    as_number?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    as_class_t?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    shared?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    blocklisted?: string;
    /**
     * 
     * @type {Array<SecReviewPort>}
     * @memberof SecReviewItemDomain
     */
    ports?: Array<SecReviewPort>;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    domain: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SecReviewItemDomain
     */
    ips?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof SecReviewItemDomain
     */
    txt?: string;
}

/**
 * Check if a given object implements the SecReviewItemDomain interface.
 */
export function instanceOfSecReviewItemDomain(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;

    return isInstance;
}

export function SecReviewItemDomainFromJSON(json: any): SecReviewItemDomain {
    return SecReviewItemDomainFromJSONTyped(json, false);
}

export function SecReviewItemDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewItemDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : json['source'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'as_class_t': !exists(json, 'as_class_t') ? undefined : json['as_class_t'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'blocklisted': !exists(json, 'blocklisted') ? undefined : json['blocklisted'],
        'ports': !exists(json, 'ports') ? undefined : ((json['ports'] as Array<any>).map(SecReviewPortFromJSON)),
        'domain': json['domain'],
        'ips': !exists(json, 'ips') ? undefined : json['ips'],
        'txt': !exists(json, 'txt') ? undefined : json['txt'],
    };
}

export function SecReviewItemDomainToJSON(value?: SecReviewItemDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'isp': value.isp,
        'as_name': value.as_name,
        'as_number': value.as_number,
        'as_class_t': value.as_class_t,
        'shared': value.shared,
        'blocklisted': value.blocklisted,
        'ports': value.ports === undefined ? undefined : ((value.ports as Array<any>).map(SecReviewPortToJSON)),
        'domain': value.domain,
        'ips': value.ips === undefined ? undefined : Array.from(value.ips as Set<any>),
        'txt': value.txt,
    };
}

