/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAlertSchema } from './ControlAlertSchema';
import {
    ControlAlertSchemaFromJSON,
    ControlAlertSchemaFromJSONTyped,
    ControlAlertSchemaToJSON,
} from './ControlAlertSchema';
import type { ControlAlertSchemaWithContent } from './ControlAlertSchemaWithContent';
import {
    ControlAlertSchemaWithContentFromJSON,
    ControlAlertSchemaWithContentFromJSONTyped,
    ControlAlertSchemaWithContentToJSON,
} from './ControlAlertSchemaWithContent';

/**
 * 
 * @export
 * @interface ResultsInner
 */
export interface ResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    notification_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    contact_type: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    sent_at: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    severity?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    record_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner
     */
    sender?: string;
}

/**
 * Check if a given object implements the ResultsInner interface.
 */
export function instanceOfResultsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contact_type" in value;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "sent_at" in value;

    return isInstance;
}

export function ResultsInnerFromJSON(json: any): ResultsInner {
    return ResultsInnerFromJSONTyped(json, false);
}

export function ResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'notification_type': !exists(json, 'notification_type') ? undefined : json['notification_type'],
        'contact_type': json['contact_type'],
        'recipient': json['recipient'],
        'status': json['status'],
        'sent_at': json['sent_at'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'record_type': !exists(json, 'record_type') ? undefined : json['record_type'],
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
    };
}

export function ResultsInnerToJSON(value?: ResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'notification_type': value.notification_type,
        'contact_type': value.contact_type,
        'recipient': value.recipient,
        'status': value.status,
        'sent_at': value.sent_at,
        'severity': value.severity,
        'record_type': value.record_type,
        'client_id': value.client_id,
        'content': value.content,
        'sender': value.sender,
    };
}

