import React, { useMemo, ReactNode } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import { Box, FormControl, FormHelperText } from '@explorer/core';

const useStyles = ({}: Partial<FormFieldControlProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        hide: { visibility: 'hidden' },
      }),
    { index: 1 },
  )();

export const FormFieldControl = ({
  formId,
  formik,
  name,
  renderField,
  disableCondition,
  hideCondition,
  forceError = false,
}: FormFieldControlProps) => {
  const classes = useStyles({});

  const field = useMemo<ReactNode>(() => {
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      setFieldValue,
      setFieldError,
    } = formik;

    const error = !!(errors[name] && touched[name]);
    const errorMessage = errors[name];

    const disabled = disableCondition ? disableCondition(values) : false;

    return (
      <>
        <FormControl fullWidth error={error}>
          {renderField({
            id: `${formId}-Field-${name}`,
            name,
            value: formik.values[name],
            error: forceError ? true : disabled ? false : error,
            onBlur: handleBlur,
            onChange: handleChange,
            setFieldValue,
            setFieldError,
            disabled,
          })}
        </FormControl>
        <FormHelperText
          id={`${formId}-Error-${name}`}
          error={error}
          noUserSelect={error ? undefined : true}
        >
          {error && !disabled ? `${errorMessage}` : ' '}
        </FormHelperText>
      </>
    );
  }, [formId, name, formik, disableCondition]);

  const hide = useMemo<boolean>(() => {
    const { values } = formik;

    return hideCondition ? hideCondition(values) : false;
  }, [formik, hideCondition]);

  return <Box className={hide ? classes.hide : undefined}>{field}</Box>;
};

export interface FormFieldControlProps<Values = any, Keys = string> {
  /**
   * `formId` - the form id, used to generate ids for every input field
   */
  formId: string;
  /**
   * `formik` - the object returned by the `useFormik()` hook
   */
  formik: FormikProps<Values>;
  /**
   * `name` - name of the field
   */
  name: Keys;
  /**
   * `renderField` - function that return the input component used
   */
  renderField: (props: any) => ReactNode;
  /**
   * `disableCondition` - make the field disabled
   */
  disableCondition?: (values: Values) => boolean;
  /**
   * `hideCondition` - hide the field
   */
  hideCondition?: (values: Values) => boolean;
  /**
   * `forceError` - forces the error stats
   */
  forceError?: boolean;
}
