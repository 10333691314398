export function convertToFlatObject(obj: Object, prefix = '') {
  const flatObject: Record<string, string> = {};

  Object.entries(obj).forEach(([key, value]) => {
    const dotDelimitedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'object') {
      Object.assign(flatObject, convertToFlatObject(value, dotDelimitedKey));
    } else {
      flatObject[dotDelimitedKey] = value;
    }
  });

  return flatObject;
}
