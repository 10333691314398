/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDomainsDNSResultAsset } from './MonitEntityDomainsDNSResultAsset';
import {
    MonitEntityDomainsDNSResultAssetFromJSON,
    MonitEntityDomainsDNSResultAssetFromJSONTyped,
    MonitEntityDomainsDNSResultAssetToJSON,
} from './MonitEntityDomainsDNSResultAsset';

/**
 * 
 * @export
 * @interface MonitEntityDomainsDNSResult
 */
export interface MonitEntityDomainsDNSResult {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDomainsDNSResult
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityDomainsDNSResultAsset>}
     * @memberof MonitEntityDomainsDNSResult
     */
    assets?: Array<MonitEntityDomainsDNSResultAsset>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDomainsDNSResult
     */
    record?: string;
}

/**
 * Check if a given object implements the MonitEntityDomainsDNSResult interface.
 */
export function instanceOfMonitEntityDomainsDNSResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDomainsDNSResultFromJSON(json: any): MonitEntityDomainsDNSResult {
    return MonitEntityDomainsDNSResultFromJSONTyped(json, false);
}

export function MonitEntityDomainsDNSResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDomainsDNSResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(MonitEntityDomainsDNSResultAssetFromJSON)),
        'record': !exists(json, 'record') ? undefined : json['record'],
    };
}

export function MonitEntityDomainsDNSResultToJSON(value?: MonitEntityDomainsDNSResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(MonitEntityDomainsDNSResultAssetToJSON)),
        'record': value.record,
    };
}

