/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewOriginItem
 */
export interface CRSFSecReviewOriginItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewOriginItem
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewOriginItem
     */
    module?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewOriginItem
     */
    scan_type: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewOriginItem
     */
    se_version: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewOriginItem
     */
    ts: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewOriginItem
     */
    type: string;
}

/**
 * Check if a given object implements the CRSFSecReviewOriginItem interface.
 */
export function instanceOfCRSFSecReviewOriginItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "se_version" in value;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CRSFSecReviewOriginItemFromJSON(json: any): CRSFSecReviewOriginItem {
    return CRSFSecReviewOriginItemFromJSONTyped(json, false);
}

export function CRSFSecReviewOriginItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewOriginItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': json['country'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'scan_type': json['scan_type'],
        'se_version': json['se_version'],
        'ts': json['ts'],
        'type': json['type'],
    };
}

export function CRSFSecReviewOriginItemToJSON(value?: CRSFSecReviewOriginItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'module': value.module,
        'scan_type': value.scan_type,
        'se_version': value.se_version,
        'ts': value.ts,
        'type': value.type,
    };
}

