/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRALocale } from './CRALocale';
import {
    CRALocaleFromJSON,
    CRALocaleFromJSONTyped,
    CRALocaleToJSON,
} from './CRALocale';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRATrigger
 */
export interface CRATrigger {
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    process_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRATrigger
     */
    domains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRATrigger
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRATrigger
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {boolean}
     * @memberof CRATrigger
     */
    extended?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRATrigger
     */
    force?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRATrigger
     */
    nocache?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    ts?: string;
    /**
     * 
     * @type {CRALocale}
     * @memberof CRATrigger
     */
    locale?: CRALocale;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    cobranded?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    agency_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    renewal_of_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATrigger
     */
    user?: string;
}

/**
 * Check if a given object implements the CRATrigger interface.
 */
export function instanceOfCRATrigger(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRATriggerFromJSON(json: any): CRATrigger {
    return CRATriggerFromJSONTyped(json, false);
}

export function CRATriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRATrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'extended': !exists(json, 'extended') ? undefined : json['extended'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
        'locale': !exists(json, 'locale') ? undefined : CRALocaleFromJSON(json['locale']),
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'agency_name': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'renewal_of_id': !exists(json, 'renewal_of_id') ? undefined : json['renewal_of_id'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRATriggerToJSON(value?: CRATrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'process_id': value.process_id,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'extended': value.extended,
        'force': value.force,
        'nocache': value.nocache,
        'type': value.type,
        'ts': value.ts,
        'locale': CRALocaleToJSON(value.locale),
        'cobranded': value.cobranded,
        'agency_name': value.agency_name,
        'renewal_of_id': value.renewal_of_id,
        'desired_policy_uuid': value.desired_policy_uuid,
        'account_line_uuid': value.account_line_uuid,
        'user': value.user,
    };
}

