/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewAttributesItem } from './CRSFSecReviewAttributesItem';
import {
    CRSFSecReviewAttributesItemFromJSON,
    CRSFSecReviewAttributesItemFromJSONTyped,
    CRSFSecReviewAttributesItemToJSON,
} from './CRSFSecReviewAttributesItem';
import type { CRSFSecReviewMetaItem } from './CRSFSecReviewMetaItem';
import {
    CRSFSecReviewMetaItemFromJSON,
    CRSFSecReviewMetaItemFromJSONTyped,
    CRSFSecReviewMetaItemToJSON,
} from './CRSFSecReviewMetaItem';
import type { CRSFSecReviewResultItem } from './CRSFSecReviewResultItem';
import {
    CRSFSecReviewResultItemFromJSON,
    CRSFSecReviewResultItemFromJSONTyped,
    CRSFSecReviewResultItemToJSON,
} from './CRSFSecReviewResultItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewBuiltwithRecordItem
 */
export interface CRSFSecReviewBuiltwithRecordItem {
    /**
     * 
     * @type {CRSFSecReviewAttributesItem}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    Attributes: CRSFSecReviewAttributesItem;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    FirstIndexed: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    LastIndexed: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    Lookup: string;
    /**
     * 
     * @type {CRSFSecReviewMetaItem}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    Meta: CRSFSecReviewMetaItem;
    /**
     * 
     * @type {CRSFSecReviewResultItem}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    Result: CRSFSecReviewResultItem;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBuiltwithRecordItem
     */
    SalesRevenue: number;
}

/**
 * Check if a given object implements the CRSFSecReviewBuiltwithRecordItem interface.
 */
export function instanceOfCRSFSecReviewBuiltwithRecordItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Attributes" in value;
    isInstance = isInstance && "FirstIndexed" in value;
    isInstance = isInstance && "LastIndexed" in value;
    isInstance = isInstance && "Lookup" in value;
    isInstance = isInstance && "Meta" in value;
    isInstance = isInstance && "Result" in value;
    isInstance = isInstance && "SalesRevenue" in value;

    return isInstance;
}

export function CRSFSecReviewBuiltwithRecordItemFromJSON(json: any): CRSFSecReviewBuiltwithRecordItem {
    return CRSFSecReviewBuiltwithRecordItemFromJSONTyped(json, false);
}

export function CRSFSecReviewBuiltwithRecordItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewBuiltwithRecordItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Attributes': CRSFSecReviewAttributesItemFromJSON(json['Attributes']),
        'FirstIndexed': json['FirstIndexed'],
        'LastIndexed': json['LastIndexed'],
        'Lookup': json['Lookup'],
        'Meta': CRSFSecReviewMetaItemFromJSON(json['Meta']),
        'Result': CRSFSecReviewResultItemFromJSON(json['Result']),
        'SalesRevenue': json['SalesRevenue'],
    };
}

export function CRSFSecReviewBuiltwithRecordItemToJSON(value?: CRSFSecReviewBuiltwithRecordItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Attributes': CRSFSecReviewAttributesItemToJSON(value.Attributes),
        'FirstIndexed': value.FirstIndexed,
        'LastIndexed': value.LastIndexed,
        'Lookup': value.Lookup,
        'Meta': CRSFSecReviewMetaItemToJSON(value.Meta),
        'Result': CRSFSecReviewResultItemToJSON(value.Result),
        'SalesRevenue': value.SalesRevenue,
    };
}

