/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityHoneypotsResult } from './ControlEntityHoneypotsResult';
import {
    ControlEntityHoneypotsResultFromJSON,
    ControlEntityHoneypotsResultFromJSONTyped,
    ControlEntityHoneypotsResultToJSON,
} from './ControlEntityHoneypotsResult';

/**
 * 
 * @export
 * @interface ControlPaginatedEntityHoneypotsResponse
 */
export interface ControlPaginatedEntityHoneypotsResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityHoneypotsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityHoneypotsResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityHoneypotsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityHoneypotsResult>}
     * @memberof ControlPaginatedEntityHoneypotsResponse
     */
    results?: Array<ControlEntityHoneypotsResult>;
}

/**
 * Check if a given object implements the ControlPaginatedEntityHoneypotsResponse interface.
 */
export function instanceOfControlPaginatedEntityHoneypotsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlPaginatedEntityHoneypotsResponseFromJSON(json: any): ControlPaginatedEntityHoneypotsResponse {
    return ControlPaginatedEntityHoneypotsResponseFromJSONTyped(json, false);
}

export function ControlPaginatedEntityHoneypotsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedEntityHoneypotsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlEntityHoneypotsResultFromJSON)),
    };
}

export function ControlPaginatedEntityHoneypotsResponseToJSON(value?: ControlPaginatedEntityHoneypotsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlEntityHoneypotsResultToJSON)),
    };
}

