import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * Returns the value of a given query param.
 *
 * __> Example:__
 * ```tsx
 * // https://binaryedge.io/?myParam=xxx
 *
 * const myParam = useParam('myParam');
 * // 'xxx'
 * ```
 */

export const useParam: UseParam = (name) => {
  const router = useRouter();
  const { query, isReady } = router;
  const queryParam = useMemo<string>(() => {
    if (isReady) {
      const qp = query[name] || '';

      if (Array.isArray(qp)) return qp[0];

      return qp;
    }

    return '';
  }, [query, isReady]);

  return queryParam;
};

export type UseParam = (name: string) => string;
