/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlResolutionRequestStatus = {
    Requested: 'requested',
    Approved: 'approved',
    Rejected: 'rejected',
    Cancelled: 'cancelled',
    SelfResolved: 'self-resolved'
} as const;
export type ControlResolutionRequestStatus = typeof ControlResolutionRequestStatus[keyof typeof ControlResolutionRequestStatus];


export function ControlResolutionRequestStatusFromJSON(json: any): ControlResolutionRequestStatus {
    return ControlResolutionRequestStatusFromJSONTyped(json, false);
}

export function ControlResolutionRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResolutionRequestStatus {
    return json as ControlResolutionRequestStatus;
}

export function ControlResolutionRequestStatusToJSON(value?: ControlResolutionRequestStatus | null): any {
    return value as any;
}

