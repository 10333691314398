/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlLocale } from './ControlLocale';
import {
    ControlLocaleFromJSON,
    ControlLocaleFromJSONTyped,
    ControlLocaleToJSON,
} from './ControlLocale';
import type { ControlZeroDayAlertInputFields } from './ControlZeroDayAlertInputFields';
import {
    ControlZeroDayAlertInputFieldsFromJSON,
    ControlZeroDayAlertInputFieldsFromJSONTyped,
    ControlZeroDayAlertInputFieldsToJSON,
} from './ControlZeroDayAlertInputFields';

/**
 * 
 * @export
 * @interface ControlSendZeroDayAlertTestEmailRequest
 */
export interface ControlSendZeroDayAlertTestEmailRequest {
    /**
     * 
     * @type {ControlZeroDayAlertInputFields}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    alert: ControlZeroDayAlertInputFields;
    /**
     * 
     * @type {string}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    client_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    email_to: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    email_cc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    assets?: Array<string>;
    /**
     * 
     * @type {ControlLocale}
     * @memberof ControlSendZeroDayAlertTestEmailRequest
     */
    locale?: ControlLocale;
}

/**
 * Check if a given object implements the ControlSendZeroDayAlertTestEmailRequest interface.
 */
export function instanceOfControlSendZeroDayAlertTestEmailRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "alert" in value;
    isInstance = isInstance && "client_name" in value;
    isInstance = isInstance && "email_to" in value;

    return isInstance;
}

export function ControlSendZeroDayAlertTestEmailRequestFromJSON(json: any): ControlSendZeroDayAlertTestEmailRequest {
    return ControlSendZeroDayAlertTestEmailRequestFromJSONTyped(json, false);
}

export function ControlSendZeroDayAlertTestEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSendZeroDayAlertTestEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': ControlZeroDayAlertInputFieldsFromJSON(json['alert']),
        'client_name': json['client_name'],
        'email_to': json['email_to'],
        'email_cc': !exists(json, 'email_cc') ? undefined : json['email_cc'],
        'assets': !exists(json, 'assets') ? undefined : json['assets'],
        'locale': !exists(json, 'locale') ? undefined : ControlLocaleFromJSON(json['locale']),
    };
}

export function ControlSendZeroDayAlertTestEmailRequestToJSON(value?: ControlSendZeroDayAlertTestEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': ControlZeroDayAlertInputFieldsToJSON(value.alert),
        'client_name': value.client_name,
        'email_to': value.email_to,
        'email_cc': value.email_cc,
        'assets': value.assets,
        'locale': ControlLocaleToJSON(value.locale),
    };
}

