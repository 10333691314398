/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityReportedAssetResult } from './MonitEntityReportedAssetResult';
import {
    MonitEntityReportedAssetResultFromJSON,
    MonitEntityReportedAssetResultFromJSONTyped,
    MonitEntityReportedAssetResultToJSON,
} from './MonitEntityReportedAssetResult';

/**
 * 
 * @export
 * @interface MonitUpdateReportedAssetResult
 */
export interface MonitUpdateReportedAssetResult {
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    reason_internal?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    asset_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    removed?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    reviewed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitUpdateReportedAssetResult
     */
    user?: string;
}

/**
 * Check if a given object implements the MonitUpdateReportedAssetResult interface.
 */
export function instanceOfMonitUpdateReportedAssetResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitUpdateReportedAssetResultFromJSON(json: any): MonitUpdateReportedAssetResult {
    return MonitUpdateReportedAssetResultFromJSONTyped(json, false);
}

export function MonitUpdateReportedAssetResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitUpdateReportedAssetResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'reason_internal': !exists(json, 'reason_internal') ? undefined : json['reason_internal'],
        'asset_type': !exists(json, 'asset_type') ? undefined : json['asset_type'],
        'removed': !exists(json, 'removed') ? undefined : json['removed'],
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function MonitUpdateReportedAssetResultToJSON(value?: MonitUpdateReportedAssetResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'asset': value.asset,
        'status': value.status,
        'reason': value.reason,
        'reason_internal': value.reason_internal,
        'asset_type': value.asset_type,
        'removed': value.removed,
        'reviewed_by': value.reviewed_by,
        'created': value.created,
        'updated': value.updated,
        'code': value.code,
        'user': value.user,
    };
}

