/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityDataleaks
 */
export interface MonitEntityDataleaks {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaks
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaks
     */
    password?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaks
     */
    pii?: number;
}

/**
 * Check if a given object implements the MonitEntityDataleaks interface.
 */
export function instanceOfMonitEntityDataleaks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDataleaksFromJSON(json: any): MonitEntityDataleaks {
    return MonitEntityDataleaksFromJSONTyped(json, false);
}

export function MonitEntityDataleaksFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDataleaks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'pii': !exists(json, 'pii') ? undefined : json['pii'],
    };
}

export function MonitEntityDataleaksToJSON(value?: MonitEntityDataleaks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'password': value.password,
        'pii': value.pii,
    };
}

