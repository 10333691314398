/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFInsuranceEnv = {
    Dev: 'dev',
    Demo: 'demo',
    Staging: 'staging',
    Production: 'production'
} as const;
export type CRSFInsuranceEnv = typeof CRSFInsuranceEnv[keyof typeof CRSFInsuranceEnv];


export function CRSFInsuranceEnvFromJSON(json: any): CRSFInsuranceEnv {
    return CRSFInsuranceEnvFromJSONTyped(json, false);
}

export function CRSFInsuranceEnvFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFInsuranceEnv {
    return json as CRSFInsuranceEnv;
}

export function CRSFInsuranceEnvToJSON(value?: CRSFInsuranceEnv | null): any {
    return value as any;
}

