/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFAggregationFindings } from './CRSFAggregationFindings';
import {
    CRSFAggregationFindingsFromJSON,
    CRSFAggregationFindingsFromJSONTyped,
    CRSFAggregationFindingsToJSON,
} from './CRSFAggregationFindings';

/**
 * 
 * @export
 * @interface CRSFFindingsPagedResponseAgg
 */
export interface CRSFFindingsPagedResponseAgg {
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingsPagedResponseAgg
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingsPagedResponseAgg
     */
    page_size?: number;
    /**
     * 
     * @type {Array<CRSFAggregationFindings>}
     * @memberof CRSFFindingsPagedResponseAgg
     */
    results?: Array<CRSFAggregationFindings>;
    /**
     * 
     * @type {number}
     * @memberof CRSFFindingsPagedResponseAgg
     */
    total?: number;
}

/**
 * Check if a given object implements the CRSFFindingsPagedResponseAgg interface.
 */
export function instanceOfCRSFFindingsPagedResponseAgg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFFindingsPagedResponseAggFromJSON(json: any): CRSFFindingsPagedResponseAgg {
    return CRSFFindingsPagedResponseAggFromJSONTyped(json, false);
}

export function CRSFFindingsPagedResponseAggFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFFindingsPagedResponseAgg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(CRSFAggregationFindingsFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function CRSFFindingsPagedResponseAggToJSON(value?: CRSFFindingsPagedResponseAgg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(CRSFAggregationFindingsToJSON)),
        'total': value.total,
    };
}

