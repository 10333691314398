import React, { memo } from 'react';
import { useTheme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const fontSize = '12px';

export const ActiveUserIcon = memo(
  //
  function ActiveUserIcon({
    active = false,
    loading = false,
  }: ActiveUserIconProps) {
    const { palette } = useTheme();

    const color = loading
      ? palette?.grey[500]
      : active
      ? palette?.green[500]
      : palette?.red[500];

    return (
      <FiberManualRecordIcon
        style={{
          fontSize,
          color,
        }}
      />
    );
  },
);

export interface ActiveUserIconProps {
  readonly active?: boolean;
  readonly loading?: boolean;
}
