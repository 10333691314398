import React, { ReactNode, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { TabsProps } from '@mui/material';
import {
  Box,
  Tabs as CoreTabs,
  Tab as CoreTab,
  TabPanel,
  TabContext,
} from '../../base';

/**
 * TabMenu
 */
export const TabMenu = ({
  activeTab,
  items,
  onTabChange,
}: TabMenuProps<string>) => {
  const intl = useIntl();

  return (
    <TabContext value={activeTab}>
      <CoreTabs
        value={activeTab}
        variant="scrollable"
        scrollButtons="auto"
        onChange={onTabChange as TabsProps['onChange']}
      >
        {items.map(({ tab, label, disableCondition, hideCondition }) => {
          if (hideCondition) return null;

          return (
            <CoreTab
              py={2.1}
              key={tab}
              value={tab}
              label={formatMessage(intl, label)}
              disabled={disableCondition || false}
            />
          );
        })}
      </CoreTabs>
      <Box py={3} px={3}>
        {items.map(({ tab, children, hideCondition }) => {
          if (hideCondition) return null;

          return (
            <TabPanel value={tab} key={tab}>
              {children}
            </TabPanel>
          );
        })}
      </Box>
    </TabContext>
  );
};

/**
 * TabMenu Props
 */
export interface TabMenuProps<Tabs extends string> {
  activeTab: Tabs;
  items: TabMenuItem<Tabs>[];
  onTabChange: TabsProps['onChange'];
}

export interface TabMenuItem<Tabs extends string> {
  tab: Tabs;
  label: IntlLabel;
  children: ReactNode | ReactElement | null;
  disableCondition?: boolean;
  hideCondition?: boolean;
}
