/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityAssetTechData } from './ControlEntityAssetTechData';
import {
    ControlEntityAssetTechDataFromJSON,
    ControlEntityAssetTechDataFromJSONTyped,
    ControlEntityAssetTechDataToJSON,
} from './ControlEntityAssetTechData';
import type { ControlEntityFindingsAggAsset } from './ControlEntityFindingsAggAsset';
import {
    ControlEntityFindingsAggAssetFromJSON,
    ControlEntityFindingsAggAssetFromJSONTyped,
    ControlEntityFindingsAggAssetToJSON,
} from './ControlEntityFindingsAggAsset';

/**
 * 
 * @export
 * @interface ControlEntityTechAggResult
 */
export interface ControlEntityTechAggResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityTechAggResult
     */
    tech_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityTechAggResult
     */
    version?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityTechAggResult
     */
    count: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityTechAggResult
     */
    category?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityTechAggResult
     */
    cves?: Array<string>;
    /**
     * 
     * @type {Array<ControlEntityFindingsAggAsset>}
     * @memberof ControlEntityTechAggResult
     */
    assets?: Array<ControlEntityFindingsAggAsset>;
    /**
     * 
     * @type {ControlEntityAssetTechData}
     * @memberof ControlEntityTechAggResult
     */
    data?: ControlEntityAssetTechData;
}

/**
 * Check if a given object implements the ControlEntityTechAggResult interface.
 */
export function instanceOfControlEntityTechAggResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tech_name" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ControlEntityTechAggResultFromJSON(json: any): ControlEntityTechAggResult {
    return ControlEntityTechAggResultFromJSONTyped(json, false);
}

export function ControlEntityTechAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityTechAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tech_name': json['tech_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'count': json['count'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'cves': !exists(json, 'cves') ? undefined : json['cves'],
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(ControlEntityFindingsAggAssetFromJSON)),
        'data': !exists(json, 'data') ? undefined : ControlEntityAssetTechDataFromJSON(json['data']),
    };
}

export function ControlEntityTechAggResultToJSON(value?: ControlEntityTechAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tech_name': value.tech_name,
        'version': value.version,
        'count': value.count,
        'category': value.category,
        'cves': value.cves,
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(ControlEntityFindingsAggAssetToJSON)),
        'data': ControlEntityAssetTechDataToJSON(value.data),
    };
}

