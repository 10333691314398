/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlImpactedAssetsInterval = {
    _050: '0-50',
    _51100: '51-100',
    _101500: '101-500',
    _500: '+500'
} as const;
export type ControlImpactedAssetsInterval = typeof ControlImpactedAssetsInterval[keyof typeof ControlImpactedAssetsInterval];


export function ControlImpactedAssetsIntervalFromJSON(json: any): ControlImpactedAssetsInterval {
    return ControlImpactedAssetsIntervalFromJSONTyped(json, false);
}

export function ControlImpactedAssetsIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlImpactedAssetsInterval {
    return json as ControlImpactedAssetsInterval;
}

export function ControlImpactedAssetsIntervalToJSON(value?: ControlImpactedAssetsInterval | null): any {
    return value as any;
}

