/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalGetAccountQuotesResponseResultsInner } from './SupplementalGetAccountQuotesResponseResultsInner';
import {
    SupplementalGetAccountQuotesResponseResultsInnerFromJSON,
    SupplementalGetAccountQuotesResponseResultsInnerFromJSONTyped,
    SupplementalGetAccountQuotesResponseResultsInnerToJSON,
} from './SupplementalGetAccountQuotesResponseResultsInner';

/**
 * 
 * @export
 * @interface SupplementalGetAccountQuotesResponse
 */
export interface SupplementalGetAccountQuotesResponse {
    /**
     * 
     * @type {Array<SupplementalGetAccountQuotesResponseResultsInner>}
     * @memberof SupplementalGetAccountQuotesResponse
     */
    results?: Array<SupplementalGetAccountQuotesResponseResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof SupplementalGetAccountQuotesResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the SupplementalGetAccountQuotesResponse interface.
 */
export function instanceOfSupplementalGetAccountQuotesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetAccountQuotesResponseFromJSON(json: any): SupplementalGetAccountQuotesResponse {
    return SupplementalGetAccountQuotesResponseFromJSONTyped(json, false);
}

export function SupplementalGetAccountQuotesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetAccountQuotesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SupplementalGetAccountQuotesResponseResultsInnerFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SupplementalGetAccountQuotesResponseToJSON(value?: SupplementalGetAccountQuotesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SupplementalGetAccountQuotesResponseResultsInnerToJSON)),
        'count': value.count,
    };
}

