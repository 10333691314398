import React, { memo, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useQuery, endpoints } from '@explorer/api';
import { Divider, Grid } from '@explorer/core';
import { Form, FormFieldControl, SubmitButton } from '@explorer/forms';
import {
  FormValues,
  validationSchema,
  initializeFormSchema,
  getModules,
  getInitialValues,
} from './formSchema';
import { useQuerySnackbar } from '@explorer/hooks';
import { ClientRoles } from '@explorer/forms/types';
import { DisplayModules } from './Modules/Modules';

export const ModifyClient = memo(function ModifyClient({
  activeClient,
}: ModifyClientProps) {
  const { client_id } = activeClient?.client ?? {};
  const { snackError, snackSuccess } = useQuerySnackbar();
  const { formatMessage } = useIntl();
  const formId = `ModifyClient-${client_id}`;

  const modules = useMemo(
    () => getModules(activeClient?.client?.active_components ?? {}),
    [activeClient?.client?.active_components],
  );

  const formik = useFormik<FormValues>({
    validationSchema,
    initialValues: getInitialValues(activeClient, modules),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      queryModifyClient
        .submit({
          payload: {
            role: values?.role ?? ClientRoles.PORTAL_FREEMIUM,
            count_max_lite: values?.count_max_lite ?? 0,
            count_max_extended: values?.count_max_extended ?? 0,
            msp: values?.option?.msp ?? false,
            control_components: values?.control_components ?? {},
          },
        })
        .then(({ data, error }) => {
          if (error) {
            snackError('Modify Client Error', error);
            resetForm();
          } else {
            snackSuccess(
              formatMessage(
                { id: 'forms-presets.success-upgrade-client' },
                { client: client_id },
              ),
            );
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const queryModifyClient = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1controladmin['/client-update/[p1]']({
      p1: client_id ?? '',
    }),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId,
        formik,
      }),
    [formik],
  );

  // If the role is changed to premium, set count_max_extended to 1
  useEffect(() => {
    if (
      (formik?.values?.role === ClientRoles.PORTAL_PREMIUM ||
        formik?.values?.role === ClientRoles.PORTAL_POLICYHOLDER) &&
      formik.values.count_max_extended < 1
    ) {
      formik.setFieldValue('count_max_extended', 1);
    }
    // disabling this b/c we only want to set count_max_extended to 1 when the role is changed to premium
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.role]);

  /**
   * Disable the submit button
   */
  const disableSave = useMemo<boolean>(() => {
    const { isSubmitting, isValid } = formik;

    if (isSubmitting || !isValid) return true;

    return false;
  }, [formik]);

  return (
    <Form formId={formId} formik={formik}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={6}>
          <FormFieldControl {...formSchema.count_max_lite} />
        </Grid>
        <Grid item xs={6}>
          <FormFieldControl {...formSchema.count_max_extended} />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.role} />
        </Grid>
        <Grid item xs={12}>
          <Divider mb={2}>
            {formatMessage({ id: 'forms-presets.modules' })}
          </Divider>
        </Grid>
        <DisplayModules
          modules={modules.modules}
          formik={formik}
          formId={formId}
          schema={formSchema}
        />
        <Grid item xs={6}>
          <FormFieldControl {...formSchema.option} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <SubmitButton
            formId={formId}
            formik={formik}
            color="primary"
            label={{ id: 'forms-presets.save' }}
            disabled={disableSave}
          />
        </Grid>
      </Grid>
    </Form>
  );
});

export interface ModifyClientProps {
  readonly activeClient?: APIExplorerGetClient;
}
