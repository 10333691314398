/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewPayloadFieldsOutput } from './CRSFSecReviewPayloadFieldsOutput';
import {
    CRSFSecReviewPayloadFieldsOutputFromJSON,
    CRSFSecReviewPayloadFieldsOutputFromJSONTyped,
    CRSFSecReviewPayloadFieldsOutputToJSON,
} from './CRSFSecReviewPayloadFieldsOutput';

/**
 * 
 * @export
 * @interface CRSFSecReviewTicketPayloadOutput
 */
export interface CRSFSecReviewTicketPayloadOutput {
    /**
     * 
     * @type {CRSFSecReviewPayloadFieldsOutput}
     * @memberof CRSFSecReviewTicketPayloadOutput
     */
    fields?: CRSFSecReviewPayloadFieldsOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTicketPayloadOutput
     */
    key?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTicketPayloadOutput interface.
 */
export function instanceOfCRSFSecReviewTicketPayloadOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewTicketPayloadOutputFromJSON(json: any): CRSFSecReviewTicketPayloadOutput {
    return CRSFSecReviewTicketPayloadOutputFromJSONTyped(json, false);
}

export function CRSFSecReviewTicketPayloadOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTicketPayloadOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : CRSFSecReviewPayloadFieldsOutputFromJSON(json['fields']),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function CRSFSecReviewTicketPayloadOutputToJSON(value?: CRSFSecReviewTicketPayloadOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': CRSFSecReviewPayloadFieldsOutputToJSON(value.fields),
        'key': value.key,
    };
}

