/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Categories } from './Categories';
import {
    CategoriesFromJSON,
    CategoriesFromJSONTyped,
    CategoriesToJSON,
} from './Categories';

/**
 * 
 * @export
 * @interface ControlEntityStatsVulnerabilitiesSpecific
 */
export interface ControlEntityStatsVulnerabilitiesSpecific {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsVulnerabilitiesSpecific
     */
    count?: number;
    /**
     * 
     * @type {Categories}
     * @memberof ControlEntityStatsVulnerabilitiesSpecific
     */
    categories?: Categories;
}

/**
 * Check if a given object implements the ControlEntityStatsVulnerabilitiesSpecific interface.
 */
export function instanceOfControlEntityStatsVulnerabilitiesSpecific(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsVulnerabilitiesSpecificFromJSON(json: any): ControlEntityStatsVulnerabilitiesSpecific {
    return ControlEntityStatsVulnerabilitiesSpecificFromJSONTyped(json, false);
}

export function ControlEntityStatsVulnerabilitiesSpecificFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsVulnerabilitiesSpecific {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'categories': !exists(json, 'categories') ? undefined : CategoriesFromJSON(json['categories']),
    };
}

export function ControlEntityStatsVulnerabilitiesSpecificToJSON(value?: ControlEntityStatsVulnerabilitiesSpecific | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'categories': CategoriesToJSON(value.categories),
    };
}

