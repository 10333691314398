import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { createStyles, makeStyles } from '@mui/styles';
import { useQuery, endpoints, UseQueryOptions } from '@explorer/api';
import { Grid, Typography, Box } from '@explorer/core';
import { FormDialog, FormDialogProps, FormFieldControl } from '@explorer/forms';
import { segmentHandler } from '@explorer/helpers';
import { CommonFormProps } from '@explorer/forms/types';
import { useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { GotoAssetProps } from '@explorer/tables/types';
import { CellAsset, CellChip } from '@explorer/tables/presets/cells';
import { useIntl } from 'react-intl';
import { ControlReportedAssetStatus } from '@explorer/open-api-client';

const FORM_ID = 'reportAsset';
const INITIAL_FORM_VALUES = {
  report_asset_reason: '',
  options: {
    attestation_legal: false,
  },
};

const useStyles = ({}: any) =>
  makeStyles(
    () =>
      createStyles({
        root: {
          '& label': {
            display: 'flex',
            alignItems: 'flex-start',
          },
          '& .MuiCheckbox-root': {
            paddingTop: 0,
          },
        },
      }),
    { index: 1 },
  )();

const createExplorerReportAssetQuery = (
  entityUUID: string,
  asset: string,
): UseQueryOptions => {
  return {
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1monit['/entity/[p1]/assets/[p2]/report']({
      p1: entityUUID,
      p2: asset,
    }),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  };
};

export const ReportAsset = ({
  open = false,
  handleClose,
  onError,
  onSuccess,
  maxWidth = 'sm',
  entityUUID,
  asset,
  assetSource,
  gotoAssetProps,
}: ReportAssetProps) => {
  const { snackSuccess, snackError } = useQuerySnackbar();
  const classes = useStyles({});
  const intl = useIntl();
  const queryReportAsset = useQuery(
    createExplorerReportAssetQuery(entityUUID, asset),
  );

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema(intl),
    validateOnChange: false,
    onSubmit: (values, { resetForm, setFieldError, setSubmitting }) => {
      if (!values?.options?.attestation_legal) {
        setFieldError(
          'options',
          intl.formatMessage({ id: 'forms-errors.required-confirm-read' }),
        );
        setSubmitting(false);
        return;
      }

      const payload: any = { reason: values.report_asset_reason };
      Object.assign(payload, {
        reason_internal:
          'This asset has been removed by an internal Coalition employee.',
        status: ControlReportedAssetStatus.FalsePositiveConfirmed,
      });

      queryReportAsset
        .submit({
          payload,
        })
        .then(({ data, error }) => {
          setSubmitting(false);
          if (error) {
            snackError('Error reporting asset', error);
            if (onError) onError({ data, error, values });
          } else {
            segmentHandler.track('CTRL False positive asset reported');
            snackSuccess('Asset reported for removal');
            resetForm();
            if (onSuccess) onSuccess({ data, error, values });
          }

          handleClose();
        });
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId: FORM_ID,
        formik,
      }),
    [formik],
  );

  return (
    <FormDialog
      title={{ id: 'forms-presets.report-asset-title' }}
      formId={FORM_ID}
      formik={formik}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
      submitButton
      loading={formik.isSubmitting}
      stopPropagation
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12} mb={2} justifyContent="space-between">
          <Box display="flex" mb={0.5}>
            <Typography
              variant="bodyS"
              fontWeight="bold"
              mr={1}
              label={{ id: 'tables-presets.asset' }}
              colon
            />
            <CellAsset
              variant="bodyS"
              newTab
              gotoAssetProps={gotoAssetProps}
              entityId={entityUUID}
              asset={asset}
            />
          </Box>
          <Box display="flex">
            <Typography
              variant="bodyS"
              fontWeight="bold"
              mr={1}
              label={{ id: 'tables-presets.source' }}
              colon
            />
            <CellChip
              fullWidth={false}
              variant="bodyS"
              label={assetSource}
              color={assetSource === 'root-domain' ? 'primary' : 'neutral'}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.report_asset_reason} />
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <FormFieldControl {...formSchema.options} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface ReportAssetProps
  extends Pick<FormDialogProps, 'open' | 'handleClose' | 'maxWidth'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  entityUUID: string;
  asset: string;
  gotoAssetProps: GotoAssetProps;
  assetSource: string;
}
