/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlTrial } from './ControlTrial';
import {
    ControlTrialFromJSON,
    ControlTrialFromJSONTyped,
    ControlTrialToJSON,
} from './ControlTrial';

/**
 * 
 * @export
 * @interface ControlTrialUpdateResponse
 */
export interface ControlTrialUpdateResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlTrialUpdateResponse
     */
    client_id: string;
    /**
     * 
     * @type {ControlTrial}
     * @memberof ControlTrialUpdateResponse
     */
    original: ControlTrial;
    /**
     * 
     * @type {ControlTrial}
     * @memberof ControlTrialUpdateResponse
     */
    updated: ControlTrial;
}

/**
 * Check if a given object implements the ControlTrialUpdateResponse interface.
 */
export function instanceOfControlTrialUpdateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "original" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ControlTrialUpdateResponseFromJSON(json: any): ControlTrialUpdateResponse {
    return ControlTrialUpdateResponseFromJSONTyped(json, false);
}

export function ControlTrialUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTrialUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'original': ControlTrialFromJSON(json['original']),
        'updated': ControlTrialFromJSON(json['updated']),
    };
}

export function ControlTrialUpdateResponseToJSON(value?: ControlTrialUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'original': ControlTrialToJSON(value.original),
        'updated': ControlTrialToJSON(value.updated),
    };
}

