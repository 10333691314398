export const sharedPalette = {
  /**
   * Shared Utility Colors
   */
  success: {
    light: '#92F8C7',
    main: '#00D092',
    dark: '#1D6352',
    contrastText: '#fff',
  },
  error: {
    light: '#FF6F66',
    main: '#FA4343',
    dark: '#B52B27',
    contrastText: '#fff',
  },
  warning: {
    light: '#F9C941',
    main: '#FF9A3D',
    dark: '#FF7452',
    contrastText: '#fff',
  },
  info: {
    light: '#2EA0ED',
    main: '#2773E0',
    dark: '#032F64',
    contrastText: '#fff',
  },
  shades: {
    error: {
      A100: 'rgba(211, 47, 47, 0.04)',
      A200: 'rgba(211, 47, 47, 0.12)',
      A300: 'rgba(211, 47, 47, 0.5)',
    },
    info: {
      A100: 'rgba(0, 110, 230, 0.04)',
    },
    success: {
      A100: 'rgba(46, 125, 50, 0.04)',
      A200: 'rgba(46, 125, 50, 0.5)',
    },
    warning: {
      A100: 'rgba(237, 108, 2, 0.04)',
      A200: 'rgba(237, 108, 2, 0.5)',
    },
  },
  /**
   * Shared Criticality Colors
   */
  critical: {
    '300': '#B52B27',
    '700': 'rgba(181, 43, 39, 0.6)',
    '900': 'rgba(181, 43, 39, 0.1)',
  },
  high: {
    '300': '#FA4343',
    '700': 'rgba(250, 67, 67, 0.6)',
    '900': 'rgba(250, 67, 67, 0.05)',
  },
  medium: {
    '300': '#F9C941',
    '700': 'rgba(249, 201, 65, 0.6)',
    '900': 'rgba(249, 201, 65, 0.05)',
  },
  low: {
    '300': '#00D092',
    '700': 'rgba(0, 208, 146, 0.6)',
    '900': 'rgba(0, 208, 146, 0.05)',
  },
  informative: {
    '300': '#0074c1',
    '700': 'rgba(0, 116, 193, 0.6)',
    '900': '#f7fbfe',
  },
  neutral: {
    light: '#EAEDF0',
    dark: '#919eab',
    '300': '#919eab',
    '700': 'rgba(145, 158, 171, 0.6)',
    '800': '#EAEDF0',
    '900': '#f6f8fa',
  },
  /**
   * Shared Extra Colors
   */
  green: {
    A100: '#2E7D32',
    '300': '#07221C',
    '400': '#1D6352',
    '500': '#00D092',
    '600': '#19E98F',
    '700': '#92F8C7',
    '800': '#CCFFE7',
  },
  purple: {
    '400': '#3A3177',
    '500': '#6D49C5',
    '600': '#9A76FF',
    '700': '#BFA2FF',
    '800': '#F1F0FF',
  },
  red: {
    '300': '#572321',
    '400': '#B52B27',
    '500': '#FA4343',
    '600': '#FF6F66',
    '700': '#FFC0C0',
    '800': '#FDEBEA',
  },
  yellow: {
    '300': '#B83928',
    '400': '#FF7452',
    '500': '#FF9A3D',
    '600': '#F9C941',
    '700': '#FFE865',
    '800': '#FFF7C9',
  },
};
