import { createApiEndpoints, FuncEndpoint, P1 } from '../utils';

const PREFIX_SUPPLEMENTAL = '';

const ENDPOINTS_SUPPLEMENTAL = [
  `/internal/attachment/${P1}/pdf`,
  `/internal/form-instance/${P1}/internal`,
  `/internal/form-instance/${P1}/pdf`,
  `/internal/form-instance/${P1}/re-enable`,
  `/internal/form-instance`,
  `/internal/recipient/account/${P1}`,
  `/public/form-instance/${P1}`,
  `/public/form-instance/get-session-token`,
] as const;

type Endpoints = typeof ENDPOINTS_SUPPLEMENTAL[number];

export type EndpointsSUPPLEMENTAL = Record<Endpoints, FuncEndpoint>;

export const endpointsSUPPLEMENTAL = createApiEndpoints<Endpoints>(
  PREFIX_SUPPLEMENTAL,
  ENDPOINTS_SUPPLEMENTAL,
) as EndpointsSUPPLEMENTAL;
