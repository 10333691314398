/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlMonitTypeAlt } from './ControlMonitTypeAlt';
import {
    ControlMonitTypeAltFromJSON,
    ControlMonitTypeAltFromJSONTyped,
    ControlMonitTypeAltToJSON,
} from './ControlMonitTypeAlt';

/**
 * 
 * @export
 * @interface ControlAddEntityRequest
 */
export interface ControlAddEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    entity_domain?: string;
    /**
     * 
     * @type {ControlMonitTypeAlt}
     * @memberof ControlAddEntityRequest
     */
    monit_type?: ControlMonitTypeAlt;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    entity_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAddEntityRequest
     */
    data_sources?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAddEntityRequest
     */
    domains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAddEntityRequest
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAddEntityRequest
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    number_of_employees?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    number_of_pii_records?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    company_revenue?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddEntityRequest
     */
    country_code?: string;
}

/**
 * Check if a given object implements the ControlAddEntityRequest interface.
 */
export function instanceOfControlAddEntityRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlAddEntityRequestFromJSON(json: any): ControlAddEntityRequest {
    return ControlAddEntityRequestFromJSONTyped(json, false);
}

export function ControlAddEntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAddEntityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'monit_type': !exists(json, 'monit_type') ? undefined : ControlMonitTypeAltFromJSON(json['monit_type']),
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'data_sources': !exists(json, 'data_sources') ? undefined : json['data_sources'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'number_of_employees': !exists(json, 'number_of_employees') ? undefined : json['number_of_employees'],
        'number_of_pii_records': !exists(json, 'number_of_pii_records') ? undefined : json['number_of_pii_records'],
        'company_revenue': !exists(json, 'company_revenue') ? undefined : json['company_revenue'],
        'country_code': !exists(json, 'country_code') ? undefined : json['country_code'],
    };
}

export function ControlAddEntityRequestToJSON(value?: ControlAddEntityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_domain': value.entity_domain,
        'monit_type': ControlMonitTypeAltToJSON(value.monit_type),
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'data_sources': value.data_sources,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'keywords': value.keywords,
        'industry': value.industry,
        'number_of_employees': value.number_of_employees,
        'number_of_pii_records': value.number_of_pii_records,
        'company_revenue': value.company_revenue,
        'country_code': value.country_code,
    };
}

