/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlGrantRequestStatus = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Denied: 'DENIED'
} as const;
export type ControlGrantRequestStatus = typeof ControlGrantRequestStatus[keyof typeof ControlGrantRequestStatus];


export function ControlGrantRequestStatusFromJSON(json: any): ControlGrantRequestStatus {
    return ControlGrantRequestStatusFromJSONTyped(json, false);
}

export function ControlGrantRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGrantRequestStatus {
    return json as ControlGrantRequestStatus;
}

export function ControlGrantRequestStatusToJSON(value?: ControlGrantRequestStatus | null): any {
    return value as any;
}

