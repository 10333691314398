/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskSalaryRange
 */
export interface ExecRiskSalaryRange {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    short_name: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    posting_date: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    job_title: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    location: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskSalaryRange
     */
    min_salary?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskSalaryRange
     */
    max_salary?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    source_url: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskSalaryRange
     */
    source: string;
}

/**
 * Check if a given object implements the ExecRiskSalaryRange interface.
 */
export function instanceOfExecRiskSalaryRange(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "short_name" in value;
    isInstance = isInstance && "posting_date" in value;
    isInstance = isInstance && "job_title" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "source_url" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function ExecRiskSalaryRangeFromJSON(json: any): ExecRiskSalaryRange {
    return ExecRiskSalaryRangeFromJSONTyped(json, false);
}

export function ExecRiskSalaryRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskSalaryRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'short_name': json['short_name'],
        'posting_date': json['posting_date'],
        'job_title': json['job_title'],
        'location': json['location'],
        'min_salary': !exists(json, 'min_salary') ? undefined : json['min_salary'],
        'max_salary': !exists(json, 'max_salary') ? undefined : json['max_salary'],
        'source_url': json['source_url'],
        'source': json['source'],
    };
}

export function ExecRiskSalaryRangeToJSON(value?: ExecRiskSalaryRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'short_name': value.short_name,
        'posting_date': value.posting_date,
        'job_title': value.job_title,
        'location': value.location,
        'min_salary': value.min_salary,
        'max_salary': value.max_salary,
        'source_url': value.source_url,
        'source': value.source,
    };
}

