/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlExceptionStatus } from './ControlExceptionStatus';
import {
    ControlExceptionStatusFromJSON,
    ControlExceptionStatusFromJSONTyped,
    ControlExceptionStatusToJSON,
} from './ControlExceptionStatus';
import type { ControlFindingBlocklistScope } from './ControlFindingBlocklistScope';
import {
    ControlFindingBlocklistScopeFromJSON,
    ControlFindingBlocklistScopeFromJSONTyped,
    ControlFindingBlocklistScopeToJSON,
} from './ControlFindingBlocklistScope';

/**
 * 
 * @export
 * @interface ControlResolutionRequestBodyV2
 */
export interface ControlResolutionRequestBodyV2 {
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionRequestBodyV2
     */
    datapoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlResolutionRequestBodyV2
     */
    assets: Array<string>;
    /**
     * 
     * @type {ControlFindingBlocklistScope}
     * @memberof ControlResolutionRequestBodyV2
     */
    scope: ControlFindingBlocklistScope;
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionRequestBodyV2
     */
    notes?: string;
    /**
     * 
     * @type {ControlExceptionStatus}
     * @memberof ControlResolutionRequestBodyV2
     */
    status?: ControlExceptionStatus;
}

/**
 * Check if a given object implements the ControlResolutionRequestBodyV2 interface.
 */
export function instanceOfControlResolutionRequestBodyV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datapoint" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "scope" in value;

    return isInstance;
}

export function ControlResolutionRequestBodyV2FromJSON(json: any): ControlResolutionRequestBodyV2 {
    return ControlResolutionRequestBodyV2FromJSONTyped(json, false);
}

export function ControlResolutionRequestBodyV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResolutionRequestBodyV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoint': json['datapoint'],
        'assets': json['assets'],
        'scope': ControlFindingBlocklistScopeFromJSON(json['scope']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': !exists(json, 'status') ? undefined : ControlExceptionStatusFromJSON(json['status']),
    };
}

export function ControlResolutionRequestBodyV2ToJSON(value?: ControlResolutionRequestBodyV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoint': value.datapoint,
        'assets': value.assets,
        'scope': ControlFindingBlocklistScopeToJSON(value.scope),
        'notes': value.notes,
        'status': ControlExceptionStatusToJSON(value.status),
    };
}

