/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDataLeaksOrderBy } from './MonitEntityDataLeaksOrderBy';
import {
    MonitEntityDataLeaksOrderByFromJSON,
    MonitEntityDataLeaksOrderByFromJSONTyped,
    MonitEntityDataLeaksOrderByToJSON,
} from './MonitEntityDataLeaksOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityDataleaksStandardQueries
 */
export interface MonitGetEntityDataleaksStandardQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityDataleaksStandardQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityDataleaksStandardQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityDataleaksStandardQueries
     */
    source_asset?: string;
    /**
     * 
     * @type {MonitEntityDataLeaksOrderBy}
     * @memberof MonitGetEntityDataleaksStandardQueries
     */
    order_by?: MonitEntityDataLeaksOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityDataleaksStandardQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityDataleaksStandardQueries interface.
 */
export function instanceOfMonitGetEntityDataleaksStandardQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityDataleaksStandardQueriesFromJSON(json: any): MonitGetEntityDataleaksStandardQueries {
    return MonitGetEntityDataleaksStandardQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityDataleaksStandardQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityDataleaksStandardQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityDataLeaksOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityDataleaksStandardQueriesToJSON(value?: MonitGetEntityDataleaksStandardQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'source_asset': value.source_asset,
        'order_by': MonitEntityDataLeaksOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

