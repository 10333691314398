/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityPastebinResult } from './ControlEntityPastebinResult';
import {
    ControlEntityPastebinResultFromJSON,
    ControlEntityPastebinResultFromJSONTyped,
    ControlEntityPastebinResultToJSON,
} from './ControlEntityPastebinResult';

/**
 * 
 * @export
 * @interface ControlPaginatedEntityPastebinResponse
 */
export interface ControlPaginatedEntityPastebinResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityPastebinResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityPastebinResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityPastebinResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityPastebinResult>}
     * @memberof ControlPaginatedEntityPastebinResponse
     */
    results?: Array<ControlEntityPastebinResult>;
}

/**
 * Check if a given object implements the ControlPaginatedEntityPastebinResponse interface.
 */
export function instanceOfControlPaginatedEntityPastebinResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlPaginatedEntityPastebinResponseFromJSON(json: any): ControlPaginatedEntityPastebinResponse {
    return ControlPaginatedEntityPastebinResponseFromJSONTyped(json, false);
}

export function ControlPaginatedEntityPastebinResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedEntityPastebinResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlEntityPastebinResultFromJSON)),
    };
}

export function ControlPaginatedEntityPastebinResponseToJSON(value?: ControlPaginatedEntityPastebinResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlEntityPastebinResultToJSON)),
    };
}

