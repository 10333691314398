import React, { useMemo, RefObject, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { MenuItem, MenuItemProps } from '@explorer/core';

type OptionItemRef =
  | ((instance: HTMLLIElement | null) => void)
  | RefObject<HTMLLIElement>
  | null
  | undefined;

/**
 * OptionItem
 */
export const OptionItem = React.forwardRef(
  ({ id, label, value, ...props }: OptionItemProps, ref: OptionItemRef) => {
    // const classes = useStyles({});
    const intl = useIntl();
    const optionId = `${id}-${value}`;

    const intlLabel = useMemo<string | ReactNode>(
      () => formatMessage(intl, label),
      [intl, label],
    );

    return (
      <MenuItem {...props} ref={ref} id={optionId} value={value}>
        {intlLabel}
      </MenuItem>
    );
  },
);
OptionItem.displayName = 'OptionItem';

/**
 * OptionItem Props
 */
export interface OptionItemProps extends MenuItemProps {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `label` - label of the select option
   */
  label: IntlLabel;
  /**
   * `value` - value of the select option
   */
  value: string;
}
