import * as yup from 'yup';
import {
  FieldDatePicker,
  FormSchemaOptions,
  FieldSelect,
  FormSchemaReturn,
} from '@explorer/forms';

/**
 * Form Validation Schema
 */
export const validationSchema = yup.object().shape(
  {
    version: yup.mixed().oneOf(['free', 'trial']),
    start_date: yup
      .date()
      .nullable()
      .when('end_date', (end_date: Date, schema: any) =>
        end_date
          ? schema.max(
              end_date,
              'Trial start date must be before trial end date',
            )
          : schema,
      ),
    end_date: yup
      .date()
      .nullable()
      .when('start_date', (start_date: Date, schema: any) =>
        start_date
          ? schema.min(
              start_date,
              'Trial end date must be after trial start date',
            )
          : schema,
      ),
  },
  [['start_date', 'end_date']],
);

/**
 * Form Values
 */
export type FormValues = yup.InferType<typeof validationSchema>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  start_date: {
    formId,
    formik,
    name: 'start_date',
    renderField: (props) => (
      <FieldDatePicker
        {...props}
        required
        label={{ id: 'forms-presets.trial-start' }}
      />
    ),
  },
  end_date: {
    formId,
    formik,
    name: 'end_date',
    renderField: (props) => (
      <FieldDatePicker
        {...props}
        required
        label={{ id: 'forms-presets.trial-end' }}
      />
    ),
  },
  version: {
    formId,
    formik,
    name: 'version',
    renderField: (props) => (
      <FieldSelect
        {...props}
        disabled
        row
        label={{ id: 'forms-presets.version' }}
        options={[
          { label: { id: 'forms-presets.free' }, value: 'free' },
          { label: { id: 'forms-presets.trial' }, value: 'trial' },
        ]}
      />
    ),
  },
});
