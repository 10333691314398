/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlZeroDayAlertResolutionStatusFilter = {
    Fixed: 'fixed',
    Ignored: 'ignored',
    FalsePositive: 'false_positive',
    Unresolved: 'unresolved'
} as const;
export type ControlZeroDayAlertResolutionStatusFilter = typeof ControlZeroDayAlertResolutionStatusFilter[keyof typeof ControlZeroDayAlertResolutionStatusFilter];


export function ControlZeroDayAlertResolutionStatusFilterFromJSON(json: any): ControlZeroDayAlertResolutionStatusFilter {
    return ControlZeroDayAlertResolutionStatusFilterFromJSONTyped(json, false);
}

export function ControlZeroDayAlertResolutionStatusFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAlertResolutionStatusFilter {
    return json as ControlZeroDayAlertResolutionStatusFilter;
}

export function ControlZeroDayAlertResolutionStatusFilterToJSON(value?: ControlZeroDayAlertResolutionStatusFilter | null): any {
    return value as any;
}

