/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface SecReviewEntity
 */
export interface SecReviewEntity {
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    entity_domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewEntity
     */
    domains: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewEntity
     */
    ip_addresses: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof SecReviewEntity
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    issue?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewEntity
     */
    updated: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewEntity
     */
    no_result_flag?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SecReviewEntity
     */
    extra?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewEntity
     */
    status?: object;
}

/**
 * Check if a given object implements the SecReviewEntity interface.
 */
export function instanceOfSecReviewEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_domain" in value;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "ip_addresses" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function SecReviewEntityFromJSON(json: any): SecReviewEntity {
    return SecReviewEntityFromJSONTyped(json, false);
}

export function SecReviewEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': json['entity_domain'],
        'domains': json['domains'],
        'ip_addresses': json['ip_addresses'],
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'created': json['created'],
        'updated': json['updated'],
        'no_result_flag': !exists(json, 'no_result_flag') ? undefined : json['no_result_flag'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SecReviewEntityToJSON(value?: SecReviewEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'issue': value.issue,
        'created': value.created,
        'updated': value.updated,
        'no_result_flag': value.no_result_flag,
        'extra': value.extra,
        'status': value.status,
    };
}

