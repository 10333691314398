import React, { ReactNode } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Container } from '@explorer/core';
import { Footer } from '../Footer';
import { Navbar } from '../Navbar2';
import { Theme } from '@mui/material';

const useStyles = ({}: Partial<MainLayoutProps>) =>
  makeStyles(
    ({ palette, spacing }: Theme) =>
      createStyles({
        blueBanner: {
          backgroundColor: palette.primary.dark,
          position: 'absolute',
          top: 0,
          width: '100%',
          height: 280,
          zIndex: -1,
        },
        mainContent: {
          minHeight: 'calc(100vh - 560px)',
          paddingTop: spacing(12),
          paddingBottom: spacing(6),
          marginTop: spacing(3),
        },
      }),
    { index: 1 },
  )();

export const MainLayout = ({ title, children }: MainLayoutProps) => {
  const classes = useStyles({});

  return (
    <Box>
      <Navbar title={title} />
      <Container className={classes.mainContent}>{children}</Container>
      <Footer />
      <Box className={classes.blueBanner} />
    </Box>
  );
};

export interface MainLayoutProps {
  readonly title?: IntlLabel;
  readonly children: ReactNode;
}
