/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityHoneypotsOrderBy = {
    EntityId: 'entity_id',
    Asset: 'asset',
    SourceAsset: 'source_asset',
    Port: 'port',
    Shared: 'shared',
    Created: 'created',
    Updated: 'updated',
    Found: 'found'
} as const;
export type ControlEntityHoneypotsOrderBy = typeof ControlEntityHoneypotsOrderBy[keyof typeof ControlEntityHoneypotsOrderBy];


export function ControlEntityHoneypotsOrderByFromJSON(json: any): ControlEntityHoneypotsOrderBy {
    return ControlEntityHoneypotsOrderByFromJSONTyped(json, false);
}

export function ControlEntityHoneypotsOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityHoneypotsOrderBy {
    return json as ControlEntityHoneypotsOrderBy;
}

export function ControlEntityHoneypotsOrderByToJSON(value?: ControlEntityHoneypotsOrderBy | null): any {
    return value as any;
}

