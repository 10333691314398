/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityFindingsFilters
 */
export interface MonitEntityFindingsFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsFilters
     */
    level?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsFilters
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsFilters
     */
    status?: Array<string>;
}

/**
 * Check if a given object implements the MonitEntityFindingsFilters interface.
 */
export function instanceOfMonitEntityFindingsFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityFindingsFiltersFromJSON(json: any): MonitEntityFindingsFilters {
    return MonitEntityFindingsFiltersFromJSONTyped(json, false);
}

export function MonitEntityFindingsFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityFindingsFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': !exists(json, 'level') ? undefined : json['level'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MonitEntityFindingsFiltersToJSON(value?: MonitEntityFindingsFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'tags': value.tags,
        'status': value.status,
    };
}

