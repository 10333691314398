import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * CriminalRewardIcon
 */
export const CriminalRewardIcon = ({ ...props }: CriminalRewardIconProps) => {
  return (
    <Icon label="criminal_reward" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4,7.1L15.4,7.1L13,7.2c-0.3-0.5-0.6-1.2-0.7-1.4c0,0,0.1,0,0.2,0l0,0l0.1,0c0.5,0.1,1.3,0.2,1.8,0.2c0.4,0,1.1-0.1,1.6-0.2c0.1,0,0.2,0,0.3,0C16,6,15.8,6.3,15.7,6.7l-0.1,0.1C15.6,6.9,15.5,7,15.4,7.1L15.4,7.1L15.4,7.1z M12.5,9.4c0-0.2-0.2-0.3-0.9-0.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c1.2,0,2.1,0,2.8,0h0.9c0.3,0,0.5-0.2,0.6-0.4c0.1-0.2,0.1-0.3,0.2-0.5v0v0l0,0c0,0,0-0.1,0.1-0.1l0,0l0,0l0,0l0,0l0,0c0.2,0.1,0.5,0.3,0.7,0.6c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.5,0.5,0.8,0.6c0,0,0.1,0.1,0.1,0.1c0,1.2,0,3.4,0,4V13l-2.7,0.9h-2.2l0,0l-0.4,0c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.2,0.1-0.4,0.3-0.4c0.3-0.1,0.4-0.2,0.4-0.4c0-0.2-0.2-0.3-0.4-0.3c0,0-0.1,0-0.2,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.1-0.4,0.4-0.5c0.2-0.1,0.3-0.2,0.3-0.3c0-0.1-0.1-0.3-0.5-0.4c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.2,0.1-0.4,0.3-0.4C12.5,9.6,12.6,9.5,12.5,9.4z M15.2,22.4c-0.1,0-0.1,0.1-0.1,0.1v0.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.3c0-0.1-0.1-0.1-0.1-0.1h-0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4H15c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.4-0.3-0.5l-1.3-0.5c-0.5-0.2-0.9-0.8-0.8-1.3c0.1-0.6,0.5-1,1.1-1.1c0.1,0,0.1-0.1,0.1-0.1v-0.3c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v0.3c0,0.1,0.1,0.1,0.1,0.1h0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-1.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.4,0.3,0.5l1.3,0.5c0.5,0.2,0.9,0.8,0.8,1.3C16.2,21.9,15.8,22.4,15.2,22.4z M21.3,19.1L18,14.7c-0.3-0.4-0.8-0.5-1.2-0.4l-0.8,0.2l-0.4,0.2h-3l0,0c-0.5-0.3-1.1-0.3-1.5,0.2l-3.3,4.2c-0.7,0.8-1,1.7-0.9,2.8c0.1,2,1.8,3.5,3.7,3.5h7.8c2,0,3.8-1.5,3.9-3.5C22.3,20.8,21.9,20.1,21.3,19.1L21.3,19.1L21.3,19.1z M19.3,13.5c-0.1,0-0.2,0-0.2-0.3c0-0.6,0-1.3,0-1.9l0-1.8v-1c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.5,0,0.7,0h0.3v5.3l-0.3,0C19.8,13.6,19.7,13.6,19.3,13.5L19.3,13.5z M21.7,13.6h1.1V8.3h-1.1V13.6z M25.2,13.6H24V8.3h1.1V13.6z"
        />
      </g>
    </Icon>
  );
};

/**
 * CriminalRewardIcon Props
 */
export interface CriminalRewardIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
