/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment
 */
export interface ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment
     */
    description_program_srvc_accom_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment
     */
    grants_and_allocations_amt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment
     */
    foreign_grants_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment
     */
    program_service_expenses_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishmentFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment {
    return ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishmentFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description_program_srvc_accom_txt': !exists(json, 'description_program_srvc_accom_txt') ? undefined : json['description_program_srvc_accom_txt'],
        'grants_and_allocations_amt': !exists(json, 'grants_and_allocations_amt') ? undefined : json['grants_and_allocations_amt'],
        'foreign_grants_ind': !exists(json, 'foreign_grants_ind') ? undefined : json['foreign_grants_ind'],
        'program_service_expenses_amt': !exists(json, 'program_service_expenses_amt') ? undefined : json['program_service_expenses_amt'],
    };
}

export function ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishmentToJSON(value?: ExecRiskAppCoreModelsScansIRS990EZProgramSrvcAccomplishment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description_program_srvc_accom_txt': value.description_program_srvc_accom_txt,
        'grants_and_allocations_amt': value.grants_and_allocations_amt,
        'foreign_grants_ind': value.foreign_grants_ind,
        'program_service_expenses_amt': value.program_service_expenses_amt,
    };
}

