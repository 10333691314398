/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewCassandra
 */
export interface SecReviewCassandra {
    /**
     * 
     * @type {string}
     * @memberof SecReviewCassandra
     */
    cluster_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewCassandra
     */
    release_version?: string;
}

/**
 * Check if a given object implements the SecReviewCassandra interface.
 */
export function instanceOfSecReviewCassandra(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewCassandraFromJSON(json: any): SecReviewCassandra {
    return SecReviewCassandraFromJSONTyped(json, false);
}

export function SecReviewCassandraFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewCassandra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cluster_name': !exists(json, 'cluster_name') ? undefined : json['cluster_name'],
        'release_version': !exists(json, 'release_version') ? undefined : json['release_version'],
    };
}

export function SecReviewCassandraToJSON(value?: SecReviewCassandra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cluster_name': value.cluster_name,
        'release_version': value.release_version,
    };
}

