/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewSREntityResultDataResponseBody
 */
export interface SecReviewSREntityResultDataResponseBody {
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseBody
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseBody
     */
    md5?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseBody
     */
    mmh3?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSREntityResultDataResponseBody
     */
    sha256?: string;
}

/**
 * Check if a given object implements the SecReviewSREntityResultDataResponseBody interface.
 */
export function instanceOfSecReviewSREntityResultDataResponseBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSREntityResultDataResponseBodyFromJSON(json: any): SecReviewSREntityResultDataResponseBody {
    return SecReviewSREntityResultDataResponseBodyFromJSONTyped(json, false);
}

export function SecReviewSREntityResultDataResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSREntityResultDataResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'md5': !exists(json, 'md5') ? undefined : json['md5'],
        'mmh3': !exists(json, 'mmh3') ? undefined : json['mmh3'],
        'sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
    };
}

export function SecReviewSREntityResultDataResponseBodyToJSON(value?: SecReviewSREntityResultDataResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'md5': value.md5,
        'mmh3': value.mmh3,
        'sha256': value.sha256,
    };
}

