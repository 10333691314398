/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlExperianLien
 */
export interface ControlExperianLien {
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    date_filed?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    legal_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    legal_action?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    document_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    filing_location?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    liability_amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlExperianLien
     */
    owner?: string;
}

/**
 * Check if a given object implements the ControlExperianLien interface.
 */
export function instanceOfControlExperianLien(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlExperianLienFromJSON(json: any): ControlExperianLien {
    return ControlExperianLienFromJSONTyped(json, false);
}

export function ControlExperianLienFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlExperianLien {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_filed': !exists(json, 'date_filed') ? undefined : json['date_filed'],
        'legal_type': !exists(json, 'legal_type') ? undefined : json['legal_type'],
        'legal_action': !exists(json, 'legal_action') ? undefined : json['legal_action'],
        'document_number': !exists(json, 'document_number') ? undefined : json['document_number'],
        'filing_location': !exists(json, 'filing_location') ? undefined : json['filing_location'],
        'liability_amount': !exists(json, 'liability_amount') ? undefined : json['liability_amount'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
    };
}

export function ControlExperianLienToJSON(value?: ControlExperianLien | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_filed': value.date_filed,
        'legal_type': value.legal_type,
        'legal_action': value.legal_action,
        'document_number': value.document_number,
        'filing_location': value.filing_location,
        'liability_amount': value.liability_amount,
        'owner': value.owner,
    };
}

