/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityStatsByDayResponse
 */
export interface ControlEntityStatsByDayResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsByDayResponse
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsByDayResponse
     */
    value?: number;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsByDayResponse
     */
    data?: object;
}

/**
 * Check if a given object implements the ControlEntityStatsByDayResponse interface.
 */
export function instanceOfControlEntityStatsByDayResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsByDayResponseFromJSON(json: any): ControlEntityStatsByDayResponse {
    return ControlEntityStatsByDayResponseFromJSONTyped(json, false);
}

export function ControlEntityStatsByDayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsByDayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function ControlEntityStatsByDayResponseToJSON(value?: ControlEntityStatsByDayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'value': value.value,
        'data': value.data,
    };
}

