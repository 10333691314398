/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAppSchemasAdminAuth0Roles
 */
export interface ControlAppSchemasAdminAuth0Roles {
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminAuth0Roles
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminAuth0Roles
     */
    description: string;
}

/**
 * Check if a given object implements the ControlAppSchemasAdminAuth0Roles interface.
 */
export function instanceOfControlAppSchemasAdminAuth0Roles(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ControlAppSchemasAdminAuth0RolesFromJSON(json: any): ControlAppSchemasAdminAuth0Roles {
    return ControlAppSchemasAdminAuth0RolesFromJSONTyped(json, false);
}

export function ControlAppSchemasAdminAuth0RolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppSchemasAdminAuth0Roles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
    };
}

export function ControlAppSchemasAdminAuth0RolesToJSON(value?: ControlAppSchemasAdminAuth0Roles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

