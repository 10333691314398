/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewPathItem } from './CRSFSecReviewPathItem';
import {
    CRSFSecReviewPathItemFromJSON,
    CRSFSecReviewPathItemFromJSONTyped,
    CRSFSecReviewPathItemToJSON,
} from './CRSFSecReviewPathItem';

/**
 * 
 * @export
 * @interface CRSFSecReviewResultItem
 */
export interface CRSFSecReviewResultItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewResultItem
     */
    IsDB: string;
    /**
     * 
     * @type {Array<CRSFSecReviewPathItem>}
     * @memberof CRSFSecReviewResultItem
     */
    Paths: Array<CRSFSecReviewPathItem>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewResultItem
     */
    Spend: number;
}

/**
 * Check if a given object implements the CRSFSecReviewResultItem interface.
 */
export function instanceOfCRSFSecReviewResultItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "IsDB" in value;
    isInstance = isInstance && "Paths" in value;
    isInstance = isInstance && "Spend" in value;

    return isInstance;
}

export function CRSFSecReviewResultItemFromJSON(json: any): CRSFSecReviewResultItem {
    return CRSFSecReviewResultItemFromJSONTyped(json, false);
}

export function CRSFSecReviewResultItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewResultItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IsDB': json['IsDB'],
        'Paths': ((json['Paths'] as Array<any>).map(CRSFSecReviewPathItemFromJSON)),
        'Spend': json['Spend'],
    };
}

export function CRSFSecReviewResultItemToJSON(value?: CRSFSecReviewResultItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IsDB': value.IsDB,
        'Paths': ((value.Paths as Array<any>).map(CRSFSecReviewPathItemToJSON)),
        'Spend': value.Spend,
    };
}

