'use client';
import React, { useEffect } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeContext, useThemeContextProvider } from './context';

// use styles
const useStyles = (typographyClasses = {}) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        '@global': {
          ...typographyClasses,
        },
      }),
    { index: 1 },
  )();

/**
 * ThemeContextProvider
 */
export const ThemeContextProvider = ({
  appConfig,
  children,
  forceTheme,
}: React.PropsWithChildren<ThemeContextProviderProps>) => {
  const themeProviderValue = useThemeContextProvider(appConfig);
  const { THEMES } = appConfig;
  const { activeTheme, activeThemeSet } = themeProviderValue;

  useEffect(() => {
    if (forceTheme && THEMES[forceTheme] && activeTheme.name !== forceTheme) {
      activeThemeSet(THEMES[forceTheme]);
    }
  }, [forceTheme, THEMES, activeThemeSet, activeTheme]);

  useStyles(
    activeTheme.createTypographyClasses
      ? activeTheme.createTypographyClasses()
      : {},
  );

  return (
    <>
      {activeTheme && (
        <ThemeContext.Provider value={themeProviderValue}>
          <ThemeProvider theme={activeTheme.muiTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </ThemeContext.Provider>
      )}
    </>
  );
};

/**
 * ThemeContextProvider Props
 */
export interface ThemeContextProviderProps {
  appConfig: AppConfig | any;
  forceTheme?: string;
}
