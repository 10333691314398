/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
 */
export interface CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    agency_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    cobranded?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    domains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    external_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    renewal_of_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse
     */
    retained_from_uuid?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse interface.
 */
export function instanceOfCRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponseFromJSON(json: any): CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse {
    return CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'agency_name': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'domains': json['domains'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'process_id': json['process_id'],
        'renewal_of_id': !exists(json, 'renewal_of_id') ? undefined : json['renewal_of_id'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
    };
}

export function CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponseToJSON(value?: CRSFSecReviewSrcHttpdlibCoreModelsCraTriggerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_line_uuid': value.account_line_uuid,
        'agency_name': value.agency_name,
        'cobranded': value.cobranded,
        'desired_policy_uuid': value.desired_policy_uuid,
        'domains': value.domains,
        'entity_name': value.entity_name,
        'external_id': value.external_id,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'ip_addresses': value.ip_addresses,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'process_id': value.process_id,
        'renewal_of_id': value.renewal_of_id,
        'retained_from_uuid': value.retained_from_uuid,
    };
}

