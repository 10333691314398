import React from 'react';
import { Typography, TypographyProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';
import { truncateText } from '@explorer/helpers';
import { useTheme } from '@mui/material';

/**
 * CellText
 */
export const CellText = ({
  text,
  truncateAt,
  variant = 'bodyXS',
  textColor = undefined,
  monospace = false,
  textTransform,
  ...otherProps
}: CellTextProps) => {
  const theme = useTheme();
  if (!text) return <CellEmpty variant={variant} />;

  return (
    <Typography
      variant={variant}
      monospace={monospace}
      textColor={textColor || theme.palette.text.primary}
      label={truncateAt ? truncateText(text, truncateAt) : text}
      textTransform={textTransform}
      {...otherProps}
    />
  );
};

/**
 * CellText Props
 */
export interface CellTextProps
  extends Pick<
    TypographyProps,
    'variant' | 'monospace' | 'textColor' | 'textTransform'
  > {
  text?: string;
  truncateAt?: number;
}
