import React from 'react';
import clsx from 'clsx';
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const RadioGroup = withCoreProps<RadioGroupProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<RadioGroupProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiRadioGroup {...props} className={clsxClass}>
        {children}
      </MuiRadioGroup>
    );
  },
);

export interface RadioGroupProps extends MuiRadioGroupProps, CoreProps {}
