/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFPayloadFieldsOutput } from './CRSFPayloadFieldsOutput';
import {
    CRSFPayloadFieldsOutputFromJSON,
    CRSFPayloadFieldsOutputFromJSONTyped,
    CRSFPayloadFieldsOutputToJSON,
} from './CRSFPayloadFieldsOutput';

/**
 * 
 * @export
 * @interface CRSFTicketPayloadOutput
 */
export interface CRSFTicketPayloadOutput {
    /**
     * 
     * @type {CRSFPayloadFieldsOutput}
     * @memberof CRSFTicketPayloadOutput
     */
    fields?: CRSFPayloadFieldsOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFTicketPayloadOutput
     */
    key?: string;
}

/**
 * Check if a given object implements the CRSFTicketPayloadOutput interface.
 */
export function instanceOfCRSFTicketPayloadOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFTicketPayloadOutputFromJSON(json: any): CRSFTicketPayloadOutput {
    return CRSFTicketPayloadOutputFromJSONTyped(json, false);
}

export function CRSFTicketPayloadOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTicketPayloadOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : CRSFPayloadFieldsOutputFromJSON(json['fields']),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function CRSFTicketPayloadOutputToJSON(value?: CRSFTicketPayloadOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': CRSFPayloadFieldsOutputToJSON(value.fields),
        'key': value.key,
    };
}

