/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZeroDayAlertClientNotificationEvent } from './ControlZeroDayAlertClientNotificationEvent';
import {
    ControlZeroDayAlertClientNotificationEventFromJSON,
    ControlZeroDayAlertClientNotificationEventFromJSONTyped,
    ControlZeroDayAlertClientNotificationEventToJSON,
} from './ControlZeroDayAlertClientNotificationEvent';
import type { ControlZeroDayAlertResolutionStatus } from './ControlZeroDayAlertResolutionStatus';
import {
    ControlZeroDayAlertResolutionStatusFromJSON,
    ControlZeroDayAlertResolutionStatusFromJSONTyped,
    ControlZeroDayAlertResolutionStatusToJSON,
} from './ControlZeroDayAlertResolutionStatus';

/**
 * 
 * @export
 * @interface ControlUpdateZeroDayAlertClientResponse
 */
export interface ControlUpdateZeroDayAlertClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    zero_day_alert_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    client_id: string;
    /**
     * 
     * @type {ControlZeroDayAlertResolutionStatus}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    resolution_status?: ControlZeroDayAlertResolutionStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    resolved_by?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    resolved_version?: number;
    /**
     * 
     * @type {ControlZeroDayAlertClientNotificationEvent}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    last_notification_event?: ControlZeroDayAlertClientNotificationEvent;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    impacted_assets?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {number}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    exposure_usd: number;
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateZeroDayAlertClientResponse
     */
    created: string;
}

/**
 * Check if a given object implements the ControlUpdateZeroDayAlertClientResponse interface.
 */
export function instanceOfControlUpdateZeroDayAlertClientResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "zero_day_alert_id" in value;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "exposure_usd" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function ControlUpdateZeroDayAlertClientResponseFromJSON(json: any): ControlUpdateZeroDayAlertClientResponse {
    return ControlUpdateZeroDayAlertClientResponseFromJSONTyped(json, false);
}

export function ControlUpdateZeroDayAlertClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUpdateZeroDayAlertClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zero_day_alert_id': json['zero_day_alert_id'],
        'client_id': json['client_id'],
        'resolution_status': !exists(json, 'resolution_status') ? undefined : ControlZeroDayAlertResolutionStatusFromJSON(json['resolution_status']),
        'resolved_by': !exists(json, 'resolved_by') ? undefined : json['resolved_by'],
        'resolved_version': !exists(json, 'resolved_version') ? undefined : json['resolved_version'],
        'last_notification_event': !exists(json, 'last_notification_event') ? undefined : ControlZeroDayAlertClientNotificationEventFromJSON(json['last_notification_event']),
        'impacted_assets': !exists(json, 'impacted_assets') ? undefined : json['impacted_assets'],
        'exposure_usd': json['exposure_usd'],
        'created': json['created'],
    };
}

export function ControlUpdateZeroDayAlertClientResponseToJSON(value?: ControlUpdateZeroDayAlertClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zero_day_alert_id': value.zero_day_alert_id,
        'client_id': value.client_id,
        'resolution_status': ControlZeroDayAlertResolutionStatusToJSON(value.resolution_status),
        'resolved_by': value.resolved_by,
        'resolved_version': value.resolved_version,
        'last_notification_event': ControlZeroDayAlertClientNotificationEventToJSON(value.last_notification_event),
        'impacted_assets': value.impacted_assets,
        'exposure_usd': value.exposure_usd,
        'created': value.created,
    };
}

