/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Builtwith1 } from './Builtwith1';
import {
    Builtwith1FromJSON,
    Builtwith1FromJSONTyped,
    Builtwith1ToJSON,
} from './Builtwith1';
import type { CRSFSecReviewEmailLeaksAggregatedResponse } from './CRSFSecReviewEmailLeaksAggregatedResponse';
import {
    CRSFSecReviewEmailLeaksAggregatedResponseFromJSON,
    CRSFSecReviewEmailLeaksAggregatedResponseFromJSONTyped,
    CRSFSecReviewEmailLeaksAggregatedResponseToJSON,
} from './CRSFSecReviewEmailLeaksAggregatedResponse';
import type { CRSFSecReviewFindingAggregated } from './CRSFSecReviewFindingAggregated';
import {
    CRSFSecReviewFindingAggregatedFromJSON,
    CRSFSecReviewFindingAggregatedFromJSONTyped,
    CRSFSecReviewFindingAggregatedToJSON,
} from './CRSFSecReviewFindingAggregated';
import type { CRSFSecReviewSourcelistsData } from './CRSFSecReviewSourcelistsData';
import {
    CRSFSecReviewSourcelistsDataFromJSON,
    CRSFSecReviewSourcelistsDataFromJSONTyped,
    CRSFSecReviewSourcelistsDataToJSON,
} from './CRSFSecReviewSourcelistsData';
import type { CRSFSecReviewSpidersAggregatedResponse } from './CRSFSecReviewSpidersAggregatedResponse';
import {
    CRSFSecReviewSpidersAggregatedResponseFromJSON,
    CRSFSecReviewSpidersAggregatedResponseFromJSONTyped,
    CRSFSecReviewSpidersAggregatedResponseToJSON,
} from './CRSFSecReviewSpidersAggregatedResponse';
import type { CRSFSecReviewWappalyzerAggregatedData } from './CRSFSecReviewWappalyzerAggregatedData';
import {
    CRSFSecReviewWappalyzerAggregatedDataFromJSON,
    CRSFSecReviewWappalyzerAggregatedDataFromJSONTyped,
    CRSFSecReviewWappalyzerAggregatedDataToJSON,
} from './CRSFSecReviewWappalyzerAggregatedData';
import type { RiskSummary1 } from './RiskSummary1';
import {
    RiskSummary1FromJSON,
    RiskSummary1FromJSONTyped,
    RiskSummary1ToJSON,
} from './RiskSummary1';
import type { TechnologiesInner1 } from './TechnologiesInner1';
import {
    TechnologiesInner1FromJSON,
    TechnologiesInner1FromJSONTyped,
    TechnologiesInner1ToJSON,
} from './TechnologiesInner1';
import type { Virustotal1 } from './Virustotal1';
import {
    Virustotal1FromJSON,
    Virustotal1FromJSONTyped,
    Virustotal1ToJSON,
} from './Virustotal1';

/**
 * 
 * @export
 * @interface CRSFSecReviewAggregatedFindingsResponse
 */
export interface CRSFSecReviewAggregatedFindingsResponse {
    /**
     * 
     * @type {Builtwith1}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    builtwith: Builtwith1;
    /**
     * 
     * @type {CRSFSecReviewEmailLeaksAggregatedResponse}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    dataleaks: CRSFSecReviewEmailLeaksAggregatedResponse;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    enumerated: object;
    /**
     * 
     * @type {Array<CRSFSecReviewFindingAggregated>}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    findings: Array<CRSFSecReviewFindingAggregated>;
    /**
     * 
     * @type {RiskSummary1}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    risk_summary: RiskSummary1;
    /**
     * 
     * @type {CRSFSecReviewSourcelistsData}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    sourcelists: CRSFSecReviewSourcelistsData;
    /**
     * 
     * @type {CRSFSecReviewSpidersAggregatedResponse}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    spiders: CRSFSecReviewSpidersAggregatedResponse;
    /**
     * 
     * @type {Array<TechnologiesInner1>}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    technologies: Array<TechnologiesInner1>;
    /**
     * 
     * @type {Virustotal1}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    virustotal: Virustotal1;
    /**
     * 
     * @type {Array<CRSFSecReviewWappalyzerAggregatedData>}
     * @memberof CRSFSecReviewAggregatedFindingsResponse
     */
    wappalyzer?: Array<CRSFSecReviewWappalyzerAggregatedData>;
}

/**
 * Check if a given object implements the CRSFSecReviewAggregatedFindingsResponse interface.
 */
export function instanceOfCRSFSecReviewAggregatedFindingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "builtwith" in value;
    isInstance = isInstance && "dataleaks" in value;
    isInstance = isInstance && "enumerated" in value;
    isInstance = isInstance && "findings" in value;
    isInstance = isInstance && "risk_summary" in value;
    isInstance = isInstance && "sourcelists" in value;
    isInstance = isInstance && "spiders" in value;
    isInstance = isInstance && "technologies" in value;
    isInstance = isInstance && "virustotal" in value;

    return isInstance;
}

export function CRSFSecReviewAggregatedFindingsResponseFromJSON(json: any): CRSFSecReviewAggregatedFindingsResponse {
    return CRSFSecReviewAggregatedFindingsResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewAggregatedFindingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewAggregatedFindingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builtwith': Builtwith1FromJSON(json['builtwith']),
        'dataleaks': CRSFSecReviewEmailLeaksAggregatedResponseFromJSON(json['dataleaks']),
        'enumerated': json['enumerated'],
        'findings': ((json['findings'] as Array<any>).map(CRSFSecReviewFindingAggregatedFromJSON)),
        'risk_summary': RiskSummary1FromJSON(json['risk_summary']),
        'sourcelists': CRSFSecReviewSourcelistsDataFromJSON(json['sourcelists']),
        'spiders': CRSFSecReviewSpidersAggregatedResponseFromJSON(json['spiders']),
        'technologies': ((json['technologies'] as Array<any>).map(TechnologiesInner1FromJSON)),
        'virustotal': Virustotal1FromJSON(json['virustotal']),
        'wappalyzer': !exists(json, 'wappalyzer') ? undefined : ((json['wappalyzer'] as Array<any>).map(CRSFSecReviewWappalyzerAggregatedDataFromJSON)),
    };
}

export function CRSFSecReviewAggregatedFindingsResponseToJSON(value?: CRSFSecReviewAggregatedFindingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builtwith': Builtwith1ToJSON(value.builtwith),
        'dataleaks': CRSFSecReviewEmailLeaksAggregatedResponseToJSON(value.dataleaks),
        'enumerated': value.enumerated,
        'findings': ((value.findings as Array<any>).map(CRSFSecReviewFindingAggregatedToJSON)),
        'risk_summary': RiskSummary1ToJSON(value.risk_summary),
        'sourcelists': CRSFSecReviewSourcelistsDataToJSON(value.sourcelists),
        'spiders': CRSFSecReviewSpidersAggregatedResponseToJSON(value.spiders),
        'technologies': ((value.technologies as Array<any>).map(TechnologiesInner1ToJSON)),
        'virustotal': Virustotal1ToJSON(value.virustotal),
        'wappalyzer': value.wappalyzer === undefined ? undefined : ((value.wappalyzer as Array<any>).map(CRSFSecReviewWappalyzerAggregatedDataToJSON)),
    };
}

