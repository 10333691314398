/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDataleaksInnerData } from './MonitEntityDataleaksInnerData';
import {
    MonitEntityDataleaksInnerDataFromJSON,
    MonitEntityDataleaksInnerDataFromJSONTyped,
    MonitEntityDataleaksInnerDataToJSON,
} from './MonitEntityDataleaksInnerData';

/**
 * 
 * @export
 * @interface MonitEntityDataleaksAggResult
 */
export interface MonitEntityDataleaksAggResult {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaksAggResult
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaksAggResult
     */
    total_emails?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityDataleaksAggResult
     */
    emails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityDataleaksAggResult
     */
    features?: Array<string>;
    /**
     * 
     * @type {Array<MonitEntityDataleaksInnerData>}
     * @memberof MonitEntityDataleaksAggResult
     */
    events?: Array<MonitEntityDataleaksInnerData>;
}

/**
 * Check if a given object implements the MonitEntityDataleaksAggResult interface.
 */
export function instanceOfMonitEntityDataleaksAggResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityDataleaksAggResultFromJSON(json: any): MonitEntityDataleaksAggResult {
    return MonitEntityDataleaksAggResultFromJSONTyped(json, false);
}

export function MonitEntityDataleaksAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDataleaksAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'total_emails': !exists(json, 'total_emails') ? undefined : json['total_emails'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(MonitEntityDataleaksInnerDataFromJSON)),
    };
}

export function MonitEntityDataleaksAggResultToJSON(value?: MonitEntityDataleaksAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'total_emails': value.total_emails,
        'emails': value.emails,
        'features': value.features,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(MonitEntityDataleaksInnerDataToJSON)),
    };
}

