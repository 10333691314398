/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlTrial
 */
export interface ControlTrial {
    /**
     * 
     * @type {string}
     * @memberof ControlTrial
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTrial
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTrial
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTrial
     */
    activation_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTrial
     */
    quote_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTrial
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTrial
     */
    active?: boolean;
}

/**
 * Check if a given object implements the ControlTrial interface.
 */
export function instanceOfControlTrial(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlTrialFromJSON(json: any): ControlTrial {
    return ControlTrialFromJSONTyped(json, false);
}

export function ControlTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTrial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : json['created'],
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'activation_date': !exists(json, 'activation_date') ? undefined : json['activation_date'],
        'quote_uuid': !exists(json, 'quote_uuid') ? undefined : json['quote_uuid'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function ControlTrialToJSON(value?: ControlTrial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'activation_date': value.activation_date,
        'quote_uuid': value.quote_uuid,
        'enabled': value.enabled,
        'active': value.active,
    };
}

