/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    CurrentTaxYearMinus4YearsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    CurrentTaxYearMinus3YearsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    CurrentTaxYearMinus2YearsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    CurrentTaxYearMinus1YearAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    CurrentTaxYearAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear
     */
    TotalAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear {
    return ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CurrentTaxYearMinus4YearsAmt': !exists(json, 'CurrentTaxYearMinus4YearsAmt') ? undefined : json['CurrentTaxYearMinus4YearsAmt'],
        'CurrentTaxYearMinus3YearsAmt': !exists(json, 'CurrentTaxYearMinus3YearsAmt') ? undefined : json['CurrentTaxYearMinus3YearsAmt'],
        'CurrentTaxYearMinus2YearsAmt': !exists(json, 'CurrentTaxYearMinus2YearsAmt') ? undefined : json['CurrentTaxYearMinus2YearsAmt'],
        'CurrentTaxYearMinus1YearAmt': !exists(json, 'CurrentTaxYearMinus1YearAmt') ? undefined : json['CurrentTaxYearMinus1YearAmt'],
        'CurrentTaxYearAmt': !exists(json, 'CurrentTaxYearAmt') ? undefined : json['CurrentTaxYearAmt'],
        'TotalAmt': !exists(json, 'TotalAmt') ? undefined : json['TotalAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CurrentTaxYearMinus4YearsAmt': value.CurrentTaxYearMinus4YearsAmt,
        'CurrentTaxYearMinus3YearsAmt': value.CurrentTaxYearMinus3YearsAmt,
        'CurrentTaxYearMinus2YearsAmt': value.CurrentTaxYearMinus2YearsAmt,
        'CurrentTaxYearMinus1YearAmt': value.CurrentTaxYearMinus1YearAmt,
        'CurrentTaxYearAmt': value.CurrentTaxYearAmt,
        'TotalAmt': value.TotalAmt,
    };
}

