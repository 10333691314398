/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    disregarded_entity_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    foreign_address?: ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    primary_activities_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    legal_domicile_state_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    legal_domicile_foreign_country_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    exempt_code_section_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    public_charity_status_txt?: string;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    direct_controlling_entity_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    direct_controlling_na_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg
     */
    controlled_organization_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg {
    return ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disregarded_entity_name': !exists(json, 'disregarded_entity_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['disregarded_entity_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'primary_activities_txt': !exists(json, 'primary_activities_txt') ? undefined : json['primary_activities_txt'],
        'legal_domicile_state_cd': !exists(json, 'legal_domicile_state_cd') ? undefined : json['legal_domicile_state_cd'],
        'legal_domicile_foreign_country_cd': !exists(json, 'legal_domicile_foreign_country_cd') ? undefined : json['legal_domicile_foreign_country_cd'],
        'exempt_code_section_txt': !exists(json, 'exempt_code_section_txt') ? undefined : json['exempt_code_section_txt'],
        'public_charity_status_txt': !exists(json, 'public_charity_status_txt') ? undefined : json['public_charity_status_txt'],
        'direct_controlling_entity_name': !exists(json, 'direct_controlling_entity_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['direct_controlling_entity_name']),
        'direct_controlling_na_cd': !exists(json, 'direct_controlling_na_cd') ? undefined : json['direct_controlling_na_cd'],
        'controlled_organization_ind': !exists(json, 'controlled_organization_ind') ? undefined : json['controlled_organization_ind'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disregarded_entity_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.disregarded_entity_name),
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'ein': value.ein,
        'primary_activities_txt': value.primary_activities_txt,
        'legal_domicile_state_cd': value.legal_domicile_state_cd,
        'legal_domicile_foreign_country_cd': value.legal_domicile_foreign_country_cd,
        'exempt_code_section_txt': value.exempt_code_section_txt,
        'public_charity_status_txt': value.public_charity_status_txt,
        'direct_controlling_entity_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.direct_controlling_entity_name),
        'direct_controlling_na_cd': value.direct_controlling_na_cd,
        'controlled_organization_ind': value.controlled_organization_ind,
    };
}

