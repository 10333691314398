/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityTechResult } from './MonitEntityTechResult';
import {
    MonitEntityTechResultFromJSON,
    MonitEntityTechResultFromJSONTyped,
    MonitEntityTechResultToJSON,
} from './MonitEntityTechResult';

/**
 * 
 * @export
 * @interface MonitPaginatedEntityTechResponse
 */
export interface MonitPaginatedEntityTechResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityTechResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityTechResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitPaginatedEntityTechResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityTechResult>}
     * @memberof MonitPaginatedEntityTechResponse
     */
    results?: Array<MonitEntityTechResult>;
}

/**
 * Check if a given object implements the MonitPaginatedEntityTechResponse interface.
 */
export function instanceOfMonitPaginatedEntityTechResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitPaginatedEntityTechResponseFromJSON(json: any): MonitPaginatedEntityTechResponse {
    return MonitPaginatedEntityTechResponseFromJSONTyped(json, false);
}

export function MonitPaginatedEntityTechResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitPaginatedEntityTechResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MonitEntityTechResultFromJSON)),
    };
}

export function MonitPaginatedEntityTechResponseToJSON(value?: MonitPaginatedEntityTechResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MonitEntityTechResultToJSON)),
    };
}

