import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * PollutionIcon
 */
export const PollutionIcon = ({ ...props }: PollutionIconProps) => {
  return (
    <Icon label="pollution" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3,8.9c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-3c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V8.9z M11.9,8.9c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V7.3c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V8.9z M21.4,20.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9C21.8,19.6,21.4,20,21.4,20.5z M17.4,20.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9C17.8,19.6,17.4,20,17.4,20.5z M13.4,20.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9C13.8,19.6,13.4,20,13.4,20.5z M21.2,16.9l3.6-2.8c0.3-0.2,0.7,0,0.7,0.3V24h-19l0.9-13c0-0.2,0.2-0.4,0.4-0.4h4c0.2,0,0.4,0.2,0.4,0.4l0.3,5.8l3.5-2.8c0.3-0.2,0.7,0,0.7,0.3v2.5l3.6-2.8c0.3-0.2,0.7,0,0.7,0.3V16.9z M8.3,9.3c0.2,0,0.4-0.2,0.4-0.4V7.3c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v1.5C7.9,9.1,8.1,9.3,8.3,9.3z"
        />
      </g>
    </Icon>
  );
};

/**
 * PollutionIcon Props
 */
export interface PollutionIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
