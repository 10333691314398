/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsQueue = {
    Asm: 'asm',
    Control: 'control',
    Integrations: 'integrations'
} as const;
export type NotificationsQueue = typeof NotificationsQueue[keyof typeof NotificationsQueue];


export function NotificationsQueueFromJSON(json: any): NotificationsQueue {
    return NotificationsQueueFromJSONTyped(json, false);
}

export function NotificationsQueueFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsQueue {
    return json as NotificationsQueue;
}

export function NotificationsQueueToJSON(value?: NotificationsQueue | null): any {
    return value as any;
}

