/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalGetOrCreateFormInstanceResponseAllOfFormInstance } from './SupplementalGetOrCreateFormInstanceResponseAllOfFormInstance';
import {
    SupplementalGetOrCreateFormInstanceResponseAllOfFormInstanceFromJSON,
    SupplementalGetOrCreateFormInstanceResponseAllOfFormInstanceFromJSONTyped,
    SupplementalGetOrCreateFormInstanceResponseAllOfFormInstanceToJSON,
} from './SupplementalGetOrCreateFormInstanceResponseAllOfFormInstance';

/**
 * 
 * @export
 * @interface SupplementalGetOrCreateFormInstanceResponseAllOf
 */
export interface SupplementalGetOrCreateFormInstanceResponseAllOf {
    /**
     * 
     * @type {SupplementalGetOrCreateFormInstanceResponseAllOfFormInstance}
     * @memberof SupplementalGetOrCreateFormInstanceResponseAllOf
     */
    form_instance?: SupplementalGetOrCreateFormInstanceResponseAllOfFormInstance;
}

/**
 * Check if a given object implements the SupplementalGetOrCreateFormInstanceResponseAllOf interface.
 */
export function instanceOfSupplementalGetOrCreateFormInstanceResponseAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetOrCreateFormInstanceResponseAllOfFromJSON(json: any): SupplementalGetOrCreateFormInstanceResponseAllOf {
    return SupplementalGetOrCreateFormInstanceResponseAllOfFromJSONTyped(json, false);
}

export function SupplementalGetOrCreateFormInstanceResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetOrCreateFormInstanceResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_instance': !exists(json, 'form_instance') ? undefined : SupplementalGetOrCreateFormInstanceResponseAllOfFormInstanceFromJSON(json['form_instance']),
    };
}

export function SupplementalGetOrCreateFormInstanceResponseAllOfToJSON(value?: SupplementalGetOrCreateFormInstanceResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_instance': SupplementalGetOrCreateFormInstanceResponseAllOfFormInstanceToJSON(value.form_instance),
    };
}

