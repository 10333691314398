import React from 'react';
import * as yup from 'yup';
import {
  FieldRadio,
  FieldText,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';
import { ControlReportedAssetStatus } from '@explorer/open-api-client';

/**
 * Form Validation Schema
 */
export const validationSchema = (intl) =>
  yup
    .object({
      status: yup
        .mixed()
        .oneOf(Object.values(ControlReportedAssetStatus))
        .required(
          intl.formatMessage({
            id: 'forms-errors.required-report-asset-action',
          }),
        ),
      resolution_notes: yup.string().required(
        intl.formatMessage({
          id: 'forms-errors.required-report-asset-reason',
        }),
      ),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
  options,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  status: {
    formId,
    formik,
    name: 'status',
    renderField: (props) => (
      <FieldRadio
        {...props}
        label={{ id: 'forms-presets.action' }}
        options={options?.statusOptions}
        row
      />
    ),
  },
  resolution_notes: {
    formId,
    formik,
    name: 'resolution_notes',
    renderField: (props) => (
      <FieldText
        {...props}
        label={{ id: 'forms-presets.resolution-notes' }}
        multiline
        rows={6}
      />
    ),
  },
});
