/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityLookalikesSslIssuer } from './ControlEntityLookalikesSslIssuer';
import {
    ControlEntityLookalikesSslIssuerFromJSON,
    ControlEntityLookalikesSslIssuerFromJSONTyped,
    ControlEntityLookalikesSslIssuerToJSON,
} from './ControlEntityLookalikesSslIssuer';
import type { ControlEntityLookalikesSslSubject } from './ControlEntityLookalikesSslSubject';
import {
    ControlEntityLookalikesSslSubjectFromJSON,
    ControlEntityLookalikesSslSubjectFromJSONTyped,
    ControlEntityLookalikesSslSubjectToJSON,
} from './ControlEntityLookalikesSslSubject';
import type { ControlEntityLookalikesSslValidity } from './ControlEntityLookalikesSslValidity';
import {
    ControlEntityLookalikesSslValidityFromJSON,
    ControlEntityLookalikesSslValidityFromJSONTyped,
    ControlEntityLookalikesSslValidityToJSON,
} from './ControlEntityLookalikesSslValidity';

/**
 * 
 * @export
 * @interface ControlEntityLookalikesSsl
 */
export interface ControlEntityLookalikesSsl {
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityLookalikesSsl
     */
    _exists?: boolean;
    /**
     * 
     * @type {ControlEntityLookalikesSslSubject}
     * @memberof ControlEntityLookalikesSsl
     */
    subject?: ControlEntityLookalikesSslSubject;
    /**
     * 
     * @type {ControlEntityLookalikesSslIssuer}
     * @memberof ControlEntityLookalikesSsl
     */
    issuer?: ControlEntityLookalikesSslIssuer;
    /**
     * 
     * @type {ControlEntityLookalikesSslValidity}
     * @memberof ControlEntityLookalikesSsl
     */
    validity?: ControlEntityLookalikesSslValidity;
}

/**
 * Check if a given object implements the ControlEntityLookalikesSsl interface.
 */
export function instanceOfControlEntityLookalikesSsl(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityLookalikesSslFromJSON(json: any): ControlEntityLookalikesSsl {
    return ControlEntityLookalikesSslFromJSONTyped(json, false);
}

export function ControlEntityLookalikesSslFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityLookalikesSsl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'subject': !exists(json, 'subject') ? undefined : ControlEntityLookalikesSslSubjectFromJSON(json['subject']),
        'issuer': !exists(json, 'issuer') ? undefined : ControlEntityLookalikesSslIssuerFromJSON(json['issuer']),
        'validity': !exists(json, 'validity') ? undefined : ControlEntityLookalikesSslValidityFromJSON(json['validity']),
    };
}

export function ControlEntityLookalikesSslToJSON(value?: ControlEntityLookalikesSsl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'subject': ControlEntityLookalikesSslSubjectToJSON(value.subject),
        'issuer': ControlEntityLookalikesSslIssuerToJSON(value.issuer),
        'validity': ControlEntityLookalikesSslValidityToJSON(value.validity),
    };
}

