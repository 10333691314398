import { useFeatureFlag, useQuerySnackbar } from '@explorer/hooks';
import {
  CRSFProcessPagedResponse,
  CRACRAPagedResponse,
} from '@explorer/open-api-client';
import { DEFAULT_LOCALE } from '@mui/x-date-pickers';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

const predicate = (queryKey, querySearch) => (query) =>
  JSON.stringify(query.queryKey).includes(queryKey) &&
  (!querySearch || JSON.stringify(query.queryKey).includes(querySearch));

export const useQueryDataScanningList = (querySearch = '', formik) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useQuerySnackbar();

  const { value: useNewEndpoints, isLoading } = useFeatureFlag(
    'explorer.deprecate-secreview-services',
  );

  const handleFetchData = useCallback(() => {
    const useDeprecatedEndpoints = !useNewEndpoints && !isLoading;

    let fetchedCRAScans:
      | CRSFProcessPagedResponse
      | CRACRAPagedResponse
      | undefined;

    const queryKey = useDeprecatedEndpoints
      ? 'v1CraWebListGet'
      : 'v1CraWebCrsfEntityListGet';

    fetchedCRAScans = queryClient.getQueryData([queryKey], {
      exact: false,
      type: 'active',
      predicate: predicate(queryKey, querySearch),
    });

    if (fetchedCRAScans && fetchedCRAScans.results.length > 0) {
      const craData = fetchedCRAScans.results[0];
      const domains = useDeprecatedEndpoints
        ? [craData.entity_domain, ...craData.domains]
        : craData.domains;

      formik.setValues({
        entity_name: craData.entity_name,
        domains: domains.join(','),
        ip_addresses: craData.ip_addresses?.join(',') || '',
        industry: `${craData?.metrics?.industry}` || '',
        number_of_employees:
          (craData?.metrics?.number_of_employees as number) || 0,
        number_of_pii_records:
          (craData?.metrics?.number_of_pii_records as number) || 0,
        company_revenue: (craData?.metrics?.company_revenue as number) || 0,
        locale: (craData?.locale as string) ?? DEFAULT_LOCALE,
        cobranded: null,
        scan_opts: {
          force: false,
        },
      });

      snackSuccess('Fetch CRA Data Success');
    } else {
      snackError('Fetch CRA Data Error', 'No data found');
    }
  }, [
    formik,
    isLoading,
    queryClient,
    querySearch,
    snackError,
    snackSuccess,
    useNewEndpoints,
  ]);

  const invalidateQueries = useCallback(() => {
    const useDeprecatedEndpoints = !useNewEndpoints && !isLoading;

    return useDeprecatedEndpoints
      ? queryClient.invalidateQueries(['v1CraWebListGet'])
      : queryClient.invalidateQueries(['v1CraWebCrsfEntityListGet']);
  }, [isLoading, queryClient, useNewEndpoints]);

  return { handleFetchData, invalidateQueries };
};
