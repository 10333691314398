/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ControlAndCondition,
    instanceOfControlAndCondition,
    ControlAndConditionFromJSON,
    ControlAndConditionFromJSONTyped,
    ControlAndConditionToJSON,
} from './ControlAndCondition';
import {
    ControlAnsweredCondition,
    instanceOfControlAnsweredCondition,
    ControlAnsweredConditionFromJSON,
    ControlAnsweredConditionFromJSONTyped,
    ControlAnsweredConditionToJSON,
} from './ControlAnsweredCondition';
import {
    ControlBooleanCondition,
    instanceOfControlBooleanCondition,
    ControlBooleanConditionFromJSON,
    ControlBooleanConditionFromJSONTyped,
    ControlBooleanConditionToJSON,
} from './ControlBooleanCondition';
import {
    ControlNotCondition,
    instanceOfControlNotCondition,
    ControlNotConditionFromJSON,
    ControlNotConditionFromJSONTyped,
    ControlNotConditionToJSON,
} from './ControlNotCondition';
import {
    ControlOrCondition,
    instanceOfControlOrCondition,
    ControlOrConditionFromJSON,
    ControlOrConditionFromJSONTyped,
    ControlOrConditionToJSON,
} from './ControlOrCondition';
import {
    ControlSelectedAllCondition,
    instanceOfControlSelectedAllCondition,
    ControlSelectedAllConditionFromJSON,
    ControlSelectedAllConditionFromJSONTyped,
    ControlSelectedAllConditionToJSON,
} from './ControlSelectedAllCondition';
import {
    ControlSelectedAnyCondition,
    instanceOfControlSelectedAnyCondition,
    ControlSelectedAnyConditionFromJSON,
    ControlSelectedAnyConditionFromJSONTyped,
    ControlSelectedAnyConditionToJSON,
} from './ControlSelectedAnyCondition';
import {
    ControlTextCondition,
    instanceOfControlTextCondition,
    ControlTextConditionFromJSON,
    ControlTextConditionFromJSONTyped,
    ControlTextConditionToJSON,
} from './ControlTextCondition';

/**
 * @type DisplayCondition
 * 
 * @export
 */
export type DisplayCondition = { type: 'and' } & ControlAndCondition | { type: 'bool' } & ControlBooleanCondition | { type: 'has_answered' } & ControlAnsweredCondition | { type: 'not' } & ControlNotCondition | { type: 'or' } & ControlOrCondition | { type: 'selected_all' } & ControlSelectedAllCondition | { type: 'selected_any' } & ControlSelectedAnyCondition | { type: 'text' } & ControlTextCondition;

export function DisplayConditionFromJSON(json: any): DisplayCondition {
    return DisplayConditionFromJSONTyped(json, false);
}

export function DisplayConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'and':
            return {...ControlAndConditionFromJSONTyped(json, true), type: 'and'};
        case 'bool':
            return {...ControlBooleanConditionFromJSONTyped(json, true), type: 'bool'};
        case 'has_answered':
            return {...ControlAnsweredConditionFromJSONTyped(json, true), type: 'has_answered'};
        case 'not':
            return {...ControlNotConditionFromJSONTyped(json, true), type: 'not'};
        case 'or':
            return {...ControlOrConditionFromJSONTyped(json, true), type: 'or'};
        case 'selected_all':
            return {...ControlSelectedAllConditionFromJSONTyped(json, true), type: 'selected_all'};
        case 'selected_any':
            return {...ControlSelectedAnyConditionFromJSONTyped(json, true), type: 'selected_any'};
        case 'text':
            return {...ControlTextConditionFromJSONTyped(json, true), type: 'text'};
        default:
            throw new Error(`No variant of DisplayCondition exists with 'type=${json['type']}'`);
    }
}

export function DisplayConditionToJSON(value?: DisplayCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'and':
            return ControlAndConditionToJSON(value);
        case 'bool':
            return ControlBooleanConditionToJSON(value);
        case 'has_answered':
            return ControlAnsweredConditionToJSON(value);
        case 'not':
            return ControlNotConditionToJSON(value);
        case 'or':
            return ControlOrConditionToJSON(value);
        case 'selected_all':
            return ControlSelectedAllConditionToJSON(value);
        case 'selected_any':
            return ControlSelectedAnyConditionToJSON(value);
        case 'text':
            return ControlTextConditionToJSON(value);
        default:
            throw new Error(`No variant of DisplayCondition exists with 'type=${value['type']}'`);
    }

}

