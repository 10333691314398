/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewDataleaksAggregate
 */
export interface SecReviewDataleaksAggregate {
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2016?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2017?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2018?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2019?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2020?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2021?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2022?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    _2023?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewDataleaksAggregate
     */
    others?: object;
    /**
     * 
     * @type {number}
     * @memberof SecReviewDataleaksAggregate
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SecReviewDataleaksAggregate
     */
    total_emails: number;
}

/**
 * Check if a given object implements the SecReviewDataleaksAggregate interface.
 */
export function instanceOfSecReviewDataleaksAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "total_emails" in value;

    return isInstance;
}

export function SecReviewDataleaksAggregateFromJSON(json: any): SecReviewDataleaksAggregate {
    return SecReviewDataleaksAggregateFromJSONTyped(json, false);
}

export function SecReviewDataleaksAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewDataleaksAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_2016': !exists(json, '2016') ? undefined : json['2016'],
        '_2017': !exists(json, '2017') ? undefined : json['2017'],
        '_2018': !exists(json, '2018') ? undefined : json['2018'],
        '_2019': !exists(json, '2019') ? undefined : json['2019'],
        '_2020': !exists(json, '2020') ? undefined : json['2020'],
        '_2021': !exists(json, '2021') ? undefined : json['2021'],
        '_2022': !exists(json, '2022') ? undefined : json['2022'],
        '_2023': !exists(json, '2023') ? undefined : json['2023'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'total': json['total'],
        'total_emails': json['total_emails'],
    };
}

export function SecReviewDataleaksAggregateToJSON(value?: SecReviewDataleaksAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '2016': value._2016,
        '2017': value._2017,
        '2018': value._2018,
        '2019': value._2019,
        '2020': value._2020,
        '2021': value._2021,
        '2022': value._2022,
        '2023': value._2023,
        'others': value.others,
        'total': value.total,
        'total_emails': value.total_emails,
    };
}

