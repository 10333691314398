/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlViolationTrackerViolation
 */
export interface ControlViolationTrackerViolation {
    /**
     * 
     * @type {number}
     * @memberof ControlViolationTrackerViolation
     */
    penalty_year?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlViolationTrackerViolation
     */
    primary_offense?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlViolationTrackerViolation
     */
    agency?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlViolationTrackerViolation
     */
    penalty?: number;
}

/**
 * Check if a given object implements the ControlViolationTrackerViolation interface.
 */
export function instanceOfControlViolationTrackerViolation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlViolationTrackerViolationFromJSON(json: any): ControlViolationTrackerViolation {
    return ControlViolationTrackerViolationFromJSONTyped(json, false);
}

export function ControlViolationTrackerViolationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlViolationTrackerViolation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'penalty_year': !exists(json, 'penalty_year') ? undefined : json['penalty_year'],
        'primary_offense': !exists(json, 'primary_offense') ? undefined : json['primary_offense'],
        'agency': !exists(json, 'agency') ? undefined : json['agency'],
        'penalty': !exists(json, 'penalty') ? undefined : json['penalty'],
    };
}

export function ControlViolationTrackerViolationToJSON(value?: ControlViolationTrackerViolation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'penalty_year': value.penalty_year,
        'primary_offense': value.primary_offense,
        'agency': value.agency,
        'penalty': value.penalty,
    };
}

