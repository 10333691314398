/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewTicketScanResponse
 */
export interface SecReviewTicketScanResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTicketScanResponse
     */
    accepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanResponse
     */
    ts?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTicketScanResponse
     */
    process_id?: string;
}

/**
 * Check if a given object implements the SecReviewTicketScanResponse interface.
 */
export function instanceOfSecReviewTicketScanResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accepted" in value;

    return isInstance;
}

export function SecReviewTicketScanResponseFromJSON(json: any): SecReviewTicketScanResponse {
    return SecReviewTicketScanResponseFromJSONTyped(json, false);
}

export function SecReviewTicketScanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewTicketScanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accepted': json['accepted'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
    };
}

export function SecReviewTicketScanResponseToJSON(value?: SecReviewTicketScanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted': value.accepted,
        'ts': value.ts,
        'process_id': value.process_id,
    };
}

