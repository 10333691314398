/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityDataleaksResultEvidence
 */
export interface MonitEntityDataleaksResultEvidence {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    target_url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    ip_addresses: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    user_hostname: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    user_os: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    user_browser: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    infected_machine_id: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    infected_path: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    infected_time: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksResultEvidence
     */
    publish_date: string;
}

/**
 * Check if a given object implements the MonitEntityDataleaksResultEvidence interface.
 */
export function instanceOfMonitEntityDataleaksResultEvidence(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "target_url" in value;
    isInstance = isInstance && "ip_addresses" in value;
    isInstance = isInstance && "user_hostname" in value;
    isInstance = isInstance && "user_os" in value;
    isInstance = isInstance && "user_browser" in value;
    isInstance = isInstance && "infected_machine_id" in value;
    isInstance = isInstance && "infected_path" in value;
    isInstance = isInstance && "infected_time" in value;
    isInstance = isInstance && "publish_date" in value;

    return isInstance;
}

export function MonitEntityDataleaksResultEvidenceFromJSON(json: any): MonitEntityDataleaksResultEvidence {
    return MonitEntityDataleaksResultEvidenceFromJSONTyped(json, false);
}

export function MonitEntityDataleaksResultEvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDataleaksResultEvidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target_url': json['target_url'],
        'ip_addresses': json['ip_addresses'],
        'user_hostname': json['user_hostname'],
        'user_os': json['user_os'],
        'user_browser': json['user_browser'],
        'infected_machine_id': json['infected_machine_id'],
        'infected_path': json['infected_path'],
        'infected_time': json['infected_time'],
        'publish_date': json['publish_date'],
    };
}

export function MonitEntityDataleaksResultEvidenceToJSON(value?: MonitEntityDataleaksResultEvidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_url': value.target_url,
        'ip_addresses': value.ip_addresses,
        'user_hostname': value.user_hostname,
        'user_os': value.user_os,
        'user_browser': value.user_browser,
        'infected_machine_id': value.infected_machine_id,
        'infected_path': value.infected_path,
        'infected_time': value.infected_time,
        'publish_date': value.publish_date,
    };
}

