/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityStatsVulnerabilityCategories } from './ControlEntityStatsVulnerabilityCategories';
import {
    ControlEntityStatsVulnerabilityCategoriesFromJSON,
    ControlEntityStatsVulnerabilityCategoriesFromJSONTyped,
    ControlEntityStatsVulnerabilityCategoriesToJSON,
} from './ControlEntityStatsVulnerabilityCategories';

/**
 * 
 * @export
 * @interface ControlEntityStatsCriticalityCategories
 */
export interface ControlEntityStatsCriticalityCategories {
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategories}
     * @memberof ControlEntityStatsCriticalityCategories
     */
    critical?: ControlEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategories}
     * @memberof ControlEntityStatsCriticalityCategories
     */
    high?: ControlEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategories}
     * @memberof ControlEntityStatsCriticalityCategories
     */
    medium?: ControlEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategories}
     * @memberof ControlEntityStatsCriticalityCategories
     */
    low?: ControlEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategories}
     * @memberof ControlEntityStatsCriticalityCategories
     */
    info?: ControlEntityStatsVulnerabilityCategories;
}

/**
 * Check if a given object implements the ControlEntityStatsCriticalityCategories interface.
 */
export function instanceOfControlEntityStatsCriticalityCategories(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsCriticalityCategoriesFromJSON(json: any): ControlEntityStatsCriticalityCategories {
    return ControlEntityStatsCriticalityCategoriesFromJSONTyped(json, false);
}

export function ControlEntityStatsCriticalityCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsCriticalityCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'critical': !exists(json, 'critical') ? undefined : ControlEntityStatsVulnerabilityCategoriesFromJSON(json['critical']),
        'high': !exists(json, 'high') ? undefined : ControlEntityStatsVulnerabilityCategoriesFromJSON(json['high']),
        'medium': !exists(json, 'medium') ? undefined : ControlEntityStatsVulnerabilityCategoriesFromJSON(json['medium']),
        'low': !exists(json, 'low') ? undefined : ControlEntityStatsVulnerabilityCategoriesFromJSON(json['low']),
        'info': !exists(json, 'info') ? undefined : ControlEntityStatsVulnerabilityCategoriesFromJSON(json['info']),
    };
}

export function ControlEntityStatsCriticalityCategoriesToJSON(value?: ControlEntityStatsCriticalityCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'critical': ControlEntityStatsVulnerabilityCategoriesToJSON(value.critical),
        'high': ControlEntityStatsVulnerabilityCategoriesToJSON(value.high),
        'medium': ControlEntityStatsVulnerabilityCategoriesToJSON(value.medium),
        'low': ControlEntityStatsVulnerabilityCategoriesToJSON(value.low),
        'info': ControlEntityStatsVulnerabilityCategoriesToJSON(value.info),
    };
}

