/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityDataleaksResultEvidence } from './MonitEntityDataleaksResultEvidence';
import {
    MonitEntityDataleaksResultEvidenceFromJSON,
    MonitEntityDataleaksResultEvidenceFromJSONTyped,
    MonitEntityDataleaksResultEvidenceToJSON,
} from './MonitEntityDataleaksResultEvidence';

/**
 * 
 * @export
 * @interface MonitEntityDataleaksStandardResult
 */
export interface MonitEntityDataleaksStandardResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    source_asset: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    fullname: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityDataleaksStandardResult
     */
    features: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityDataleaksStandardResult
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    severity: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    target_hostname: string;
    /**
     * 
     * @type {MonitEntityDataleaksResultEvidence}
     * @memberof MonitEntityDataleaksStandardResult
     */
    evidences: MonitEntityDataleaksResultEvidence;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityDataleaksStandardResult
     */
    created?: string;
}

/**
 * Check if a given object implements the MonitEntityDataleaksStandardResult interface.
 */
export function instanceOfMonitEntityDataleaksStandardResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "source_asset" in value;
    isInstance = isInstance && "fullname" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "severity" in value;
    isInstance = isInstance && "target_hostname" in value;
    isInstance = isInstance && "evidences" in value;

    return isInstance;
}

export function MonitEntityDataleaksStandardResultFromJSON(json: any): MonitEntityDataleaksStandardResult {
    return MonitEntityDataleaksStandardResultFromJSONTyped(json, false);
}

export function MonitEntityDataleaksStandardResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityDataleaksStandardResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': json['user'],
        'name': json['name'],
        'domain': json['domain'],
        'source_asset': json['source_asset'],
        'fullname': json['fullname'],
        'features': json['features'],
        'year': json['year'],
        'date': json['date'],
        'severity': json['severity'],
        'target_hostname': json['target_hostname'],
        'evidences': MonitEntityDataleaksResultEvidenceFromJSON(json['evidences']),
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function MonitEntityDataleaksStandardResultToJSON(value?: MonitEntityDataleaksStandardResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'name': value.name,
        'domain': value.domain,
        'source_asset': value.source_asset,
        'fullname': value.fullname,
        'features': value.features,
        'year': value.year,
        'date': value.date,
        'severity': value.severity,
        'target_hostname': value.target_hostname,
        'evidences': MonitEntityDataleaksResultEvidenceToJSON(value.evidences),
        'updated': value.updated,
        'created': value.created,
    };
}

