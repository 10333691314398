/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormType } from './SupplementalFormType';
import {
    SupplementalFormTypeFromJSON,
    SupplementalFormTypeFromJSONTyped,
    SupplementalFormTypeToJSON,
} from './SupplementalFormType';

/**
 * 
 * @export
 * @interface SupplementalGetSessionTokenResponse
 */
export interface SupplementalGetSessionTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetSessionTokenResponse
     */
    form_instance_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetSessionTokenResponse
     */
    recipient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetSessionTokenResponse
     */
    expires_at?: string;
    /**
     * 
     * @type {SupplementalFormType}
     * @memberof SupplementalGetSessionTokenResponse
     */
    form_type?: SupplementalFormType;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetSessionTokenResponse
     */
    session_token?: string;
}

/**
 * Check if a given object implements the SupplementalGetSessionTokenResponse interface.
 */
export function instanceOfSupplementalGetSessionTokenResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetSessionTokenResponseFromJSON(json: any): SupplementalGetSessionTokenResponse {
    return SupplementalGetSessionTokenResponseFromJSONTyped(json, false);
}

export function SupplementalGetSessionTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetSessionTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_instance_id': !exists(json, 'form_instance_id') ? undefined : json['form_instance_id'],
        'recipient_id': !exists(json, 'recipient_id') ? undefined : json['recipient_id'],
        'expires_at': !exists(json, 'expires_at') ? undefined : json['expires_at'],
        'form_type': !exists(json, 'form_type') ? undefined : SupplementalFormTypeFromJSON(json['form_type']),
        'session_token': !exists(json, 'session_token') ? undefined : json['session_token'],
    };
}

export function SupplementalGetSessionTokenResponseToJSON(value?: SupplementalGetSessionTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_instance_id': value.form_instance_id,
        'recipient_id': value.recipient_id,
        'expires_at': value.expires_at,
        'form_type': SupplementalFormTypeToJSON(value.form_type),
        'session_token': value.session_token,
    };
}

