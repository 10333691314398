/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewSRExtScanResult
 */
export interface SecReviewSRExtScanResult {
    /**
     * 
     * @type {string}
     * @memberof SecReviewSRExtScanResult
     */
    ext_type?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSRExtScanResult
     */
    asset?: string;
    /**
     * 
     * @type {object}
     * @memberof SecReviewSRExtScanResult
     */
    data?: object;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewSRExtScanResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSRExtScanResult
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSRExtScanResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSRExtScanResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the SecReviewSRExtScanResult interface.
 */
export function instanceOfSecReviewSRExtScanResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSRExtScanResultFromJSON(json: any): SecReviewSRExtScanResult {
    return SecReviewSRExtScanResultFromJSONTyped(json, false);
}

export function SecReviewSRExtScanResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSRExtScanResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ext_type': !exists(json, 'ext_type') ? undefined : json['ext_type'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function SecReviewSRExtScanResultToJSON(value?: SecReviewSRExtScanResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ext_type': value.ext_type,
        'asset': value.asset,
        'data': value.data,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'found': value.found,
        'updated': value.updated,
    };
}

