import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * DigitalAssetRestorationIcon
 */
export const DigitalAssetRestorationIcon = ({
  ...props
}: DigitalAssetRestorationIconProps) => {
  return (
    <Icon label="asset_restoration" {...props}>
      <g fill="currentColor">
        <path d="M12.9,8.4c0.6,0,1.2,0.3,1.5,0.7l1,1.3h7.9V8c0-0.2-0.2-0.4-0.4-0.4H9.1C8.9,7.7,8.7,7.8,8.7,8v0.4H12.9L12.9,8.4z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7,11.9h10.1c0.3,0,0.5,0.2,0.5,0.5v10.4c0,0.3-0.2,0.5-0.5,0.5H7.3c-0.3,0-0.5-0.2-0.5-0.5V10c0-0.3,0.2-0.5,0.5-0.5h5.5c0.2,0,0.3,0.1,0.4,0.2L14.7,11.9z M19.2,17.2c0.1-0.1,0.1-0.1,0.1-0.2V15c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0-0.3,0.1l-0.6,0.6c-0.3-0.3-0.7-0.5-1-0.7c-0.4-0.2-0.8-0.2-1.2-0.2c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.6,0.5,1.1,0.7c0.4,0.2,0.8,0.3,1.3,0.3c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.1-0.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l-0.6-0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.2,0.3-0.5,0.5-0.8,0.6C16.7,20,16.3,20,16,20c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.1,0.2,1.5,0.6l-0.6,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.3,0.2H19C19.1,17.2,19.2,17.2,19.2,17.2z"
        />
      </g>
    </Icon>
  );
};

/**
 * DigitalAssetRestorationIcon Props
 */
export interface DigitalAssetRestorationIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
