/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlCategory = {
    AccessControl: 'Access Control',
    ApplicationSecurity: 'Application Security',
    CloudSecurity: 'Cloud Security',
    CybersecurityEducation: 'Cybersecurity Education',
    DataBackupAndRestore: 'Data Backup and Restore',
    DeviceSecurity: 'Device Security',
    EmailSecurity: 'Email Security',
    IncidentReadiness: 'Incident Readiness',
    MonitorAndResolve: 'Monitor and Resolve',
    NetworkSecurity: 'Network Security',
    RemoteAccess: 'Remote Access'
} as const;
export type ControlCategory = typeof ControlCategory[keyof typeof ControlCategory];


export function ControlCategoryFromJSON(json: any): ControlCategory {
    return ControlCategoryFromJSONTyped(json, false);
}

export function ControlCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCategory {
    return json as ControlCategory;
}

export function ControlCategoryToJSON(value?: ControlCategory | null): any {
    return value as any;
}

