/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlRelationPeriod = {
    Oneoff: 'oneoff',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Biweekly: 'biweekly',
    Weekly: 'weekly'
} as const;
export type ControlRelationPeriod = typeof ControlRelationPeriod[keyof typeof ControlRelationPeriod];


export function ControlRelationPeriodFromJSON(json: any): ControlRelationPeriod {
    return ControlRelationPeriodFromJSONTyped(json, false);
}

export function ControlRelationPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRelationPeriod {
    return json as ControlRelationPeriod;
}

export function ControlRelationPeriodToJSON(value?: ControlRelationPeriod | null): any {
    return value as any;
}

