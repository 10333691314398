/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewTechsAggregatedRecord
 */
export interface CRSFSecReviewTechsAggregatedRecord {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    asset: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    category: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    created: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    cves: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    data: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    module?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    se_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    source_asset: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    tech_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    tech_name: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTechsAggregatedRecord
     */
    version?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTechsAggregatedRecord interface.
 */
export function instanceOfCRSFSecReviewTechsAggregatedRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cves" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "shared" in value;
    isInstance = isInstance && "source_asset" in value;
    isInstance = isInstance && "tech_key" in value;
    isInstance = isInstance && "tech_name" in value;

    return isInstance;
}

export function CRSFSecReviewTechsAggregatedRecordFromJSON(json: any): CRSFSecReviewTechsAggregatedRecord {
    return CRSFSecReviewTechsAggregatedRecordFromJSONTyped(json, false);
}

export function CRSFSecReviewTechsAggregatedRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTechsAggregatedRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'category': json['category'],
        'created': json['created'],
        'cves': json['cves'],
        'data': json['data'],
        'found': json['found'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'process_id': json['process_id'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'se_version': !exists(json, 'se_version') ? undefined : json['se_version'],
        'shared': json['shared'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': json['source_asset'],
        'tech_key': json['tech_key'],
        'tech_name': json['tech_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function CRSFSecReviewTechsAggregatedRecordToJSON(value?: CRSFSecReviewTechsAggregatedRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'category': value.category,
        'created': value.created,
        'cves': value.cves,
        'data': value.data,
        'found': value.found,
        'module': value.module,
        'port': value.port,
        'process_id': value.process_id,
        'protocol': value.protocol,
        'se_version': value.se_version,
        'shared': value.shared,
        'source': value.source,
        'source_asset': value.source_asset,
        'tech_key': value.tech_key,
        'tech_name': value.tech_name,
        'version': value.version,
    };
}

