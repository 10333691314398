import { ClientRoles } from '@explorer/forms/types';

export const clientRoleOptions = [
  {
    label: { id: 'forms-presets.role-freemium' },
    value: ClientRoles.PORTAL_FREEMIUM, // T1: Starter (Assess)
  },
  {
    label: { id: 'forms-presets.role-monitor' },
    value: ClientRoles.PORTAL_MONITOR, // T2: Essential (Assess + Monitor)
  },
  {
    label: { id: 'forms-presets.role-policyholder' },
    value: ClientRoles.PORTAL_POLICYHOLDER, // T3: Advanced (Assess + Monitor + Insure)
  },
  {
    label: { id: 'forms-presets.role-premium' },
    value: ClientRoles.PORTAL_PREMIUM, // T4: Enterprise (Customize)
  },
];
