/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlHyperlink
 */
export interface ControlHyperlink {
    /**
     * 
     * @type {string}
     * @memberof ControlHyperlink
     */
    href: string;
    /**
     * 
     * @type {string}
     * @memberof ControlHyperlink
     */
    text: string;
}

/**
 * Check if a given object implements the ControlHyperlink interface.
 */
export function instanceOfControlHyperlink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "href" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function ControlHyperlinkFromJSON(json: any): ControlHyperlink {
    return ControlHyperlinkFromJSONTyped(json, false);
}

export function ControlHyperlinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlHyperlink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'href': json['href'],
        'text': json['text'],
    };
}

export function ControlHyperlinkToJSON(value?: ControlHyperlink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'href': value.href,
        'text': value.text,
    };
}

