import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { getFSRScoreRating } from '@explorer/helpers';
import { RatingScore, RatingScoreProps } from '../RatingScore';

/**
 * RatingScoreFSR
 */
export const RatingScoreFSR = ({ value, ...props }: RatingScoreFSRProps) => {
  const { palette } = useTheme();

  const overwriteColor = useMemo(() => {
    const fsr = getFSRScoreRating(value);

    return palette[fsr.riskPalette][300];
  }, [value]);

  return (
    <RatingScore value={value} overwriteColor={overwriteColor} {...props} />
  );
};

/**
 * RatingScoreFSR Props
 */
export interface RatingScoreFSRProps
  extends Omit<RatingScoreProps, 'inverseColors'> {}
