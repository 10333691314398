/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAlertSchema
 */
export interface ControlAlertSchema {
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    notification_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    contact_type: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    sent_at: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    severity?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    record_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAlertSchema
     */
    client_id?: string;
}

/**
 * Check if a given object implements the ControlAlertSchema interface.
 */
export function instanceOfControlAlertSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contact_type" in value;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "sent_at" in value;

    return isInstance;
}

export function ControlAlertSchemaFromJSON(json: any): ControlAlertSchema {
    return ControlAlertSchemaFromJSONTyped(json, false);
}

export function ControlAlertSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAlertSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'notification_type': !exists(json, 'notification_type') ? undefined : json['notification_type'],
        'contact_type': json['contact_type'],
        'recipient': json['recipient'],
        'status': json['status'],
        'sent_at': json['sent_at'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'record_type': !exists(json, 'record_type') ? undefined : json['record_type'],
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function ControlAlertSchemaToJSON(value?: ControlAlertSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'notification_type': value.notification_type,
        'contact_type': value.contact_type,
        'recipient': value.recipient,
        'status': value.status,
        'sent_at': value.sent_at,
        'severity': value.severity,
        'record_type': value.record_type,
        'client_id': value.client_id,
    };
}

