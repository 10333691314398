import { Gradient } from '../types';

export const PURPLE_GRADIENT: Gradient = {
  css: 'linear-gradient(90deg, #BFA1FF 0%, #3A3177 100%)',
  colors: [
    '#bd9ffd',
    '#b599f5',
    '#af94ef',
    '#a88de7',
    '#a288e1',
    '#9b83da',
    '#947dd3',
    '#8e78cd',
    '#8671c5',
    '#806cbf',
    '#7966b7',
    '#7361b1',
    '#6c5baa',
    '#6555a3',
    '#5f509d',
    '#584a96',
    '#51458f',
    '#4b3f88',
    '#443981',
    '#3d347a',
  ],
};
