import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { ParsedUrlQuery } from 'querystring';

const VIEW_QUERY_PARAM = 'v';

/**
 *
 * @example
 *
 * const {tab, setTab} = useQueryParamTabs({
 *  params: ['ips', 'domain'],
 *  defaultTab: 'ips',
 * }, {
 *   replace: true,
 * });
 */
export const useQueryParamTabs = (
  tabs: string[],
  options?: {
    defaultTab?: string;
    replace?: boolean;
    queryParamName?: string;
  },
) => {
  const {
    defaultTab = tabs[0],
    replace = true,
    queryParamName = VIEW_QUERY_PARAM,
  } = options ?? {};

  const router = useRouter();
  const urlTab = getSingleQueryParam(router.query, queryParamName);

  const setTab = useCallback(
    (
      newTab: string,
      overrideOptions?: {
        replace?: boolean;
      },
    ) => {
      const overrideReplace = overrideOptions?.replace ?? replace;

      let validatedTab = newTab;
      if (!tabs.includes(newTab)) {
        validatedTab = defaultTab;
      }

      const newPath = new URL(window.location.href);
      newPath.searchParams.set(queryParamName, validatedTab);

      const historyMethod = overrideReplace ? 'replace' : 'push';

      router[historyMethod]?.(newPath.toString(), undefined, { scroll: false });
    },
    [replace],
  );

  /* Immediately return the default tab if the urlTab is empty or invalid */
  const tab = tabs.includes(urlTab) ? urlTab : defaultTab;

  /* Queue a useEffect to initialize the tab. The returned tab value will always be valid, but the url navigation is async and eventually consistent */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (tab !== urlTab) {
      /* When defaulting initial tab, always use replace to avoid adding an extra history entry */
      setTab(tab, {
        replace: true,
      });
    }
  }, [tab, router.isReady, setTab, urlTab]);

  return { tab, setTab };
};

export const getSingleQueryParam = (
  searchParams: ParsedUrlQuery,
  key: string,
): string => {
  let value: string | undefined;

  if (Array.isArray(searchParams[key])) {
    value = searchParams[key]?.[0];
  } else {
    value = searchParams[key] as string;
  }

  return value ?? '';
};
