import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * MutedIcon
 */
export const MutedIcon = ({ ...props }: MutedIconProps) => {
  return (
    <Icon label="muted" {...props}>
      <path
        d="M13.6666 13.5757L3.53325 3.11732L1.39159 0.908984L0.333252 1.96732L2.66659 4.30065V4.30898C2.23325 5.13398 1.99992 6.10898 1.99992 7.15898V11.3257L0.333252 12.9923V13.8257H11.7749L13.4416 15.4923L14.4999 14.434L13.6666 13.5757ZM6.99992 16.334C7.92492 16.334 8.66659 15.5923 8.66659 14.6673H5.33325C5.33325 15.5923 6.07492 16.334 6.99992 16.334ZM11.9999 10.234V7.16732C11.9999 4.60065 10.6333 2.46732 8.24992 1.90065V1.33398C8.24992 0.642318 7.69159 0.0839844 6.99992 0.0839844C6.30825 0.0839844 5.74992 0.642318 5.74992 1.33398V1.90065C5.62492 1.92565 5.50825 1.96732 5.39992 2.00065C5.31659 2.02565 5.23325 2.05898 5.14992 2.09232H5.14159C5.13325 2.09232 5.13325 2.09232 5.12492 2.10065C4.93325 2.17565 4.74159 2.26732 4.55825 2.35898C4.55825 2.35898 4.54992 2.35898 4.54992 2.36732L11.9999 10.234Z"
        fill="black"
        fillOpacity="0.87"
      />
    </Icon>
  );
};

/**
 * MutedIcon Props
 */
export interface MutedIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor' | 'viewBox'> {}
