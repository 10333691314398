/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskWatchlistScan
 */
export interface ExecRiskWatchlistScan {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWatchlistScan
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWatchlistScan
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWatchlistScan
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskWatchlistScan
     */
    updated: string;
}

/**
 * Check if a given object implements the ExecRiskWatchlistScan interface.
 */
export function instanceOfExecRiskWatchlistScan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ExecRiskWatchlistScanFromJSON(json: any): ExecRiskWatchlistScan {
    return ExecRiskWatchlistScanFromJSONTyped(json, false);
}

export function ExecRiskWatchlistScanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskWatchlistScan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'reason': json['reason'],
        'action': json['action'],
        'updated': json['updated'],
    };
}

export function ExecRiskWatchlistScanToJSON(value?: ExecRiskWatchlistScan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'reason': value.reason,
        'action': value.action,
        'updated': value.updated,
    };
}

