/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFStatusType } from './CRSFStatusType';
import {
    CRSFStatusTypeFromJSON,
    CRSFStatusTypeFromJSONTyped,
    CRSFStatusTypeToJSON,
} from './CRSFStatusType';
import type { CRSFTaskResponse } from './CRSFTaskResponse';
import {
    CRSFTaskResponseFromJSON,
    CRSFTaskResponseFromJSONTyped,
    CRSFTaskResponseToJSON,
} from './CRSFTaskResponse';

/**
 * 
 * @export
 * @interface CRSFThreadResponse
 */
export interface CRSFThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFThreadResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFThreadResponse
     */
    domain: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFThreadResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {CRSFStatusType}
     * @memberof CRSFThreadResponse
     */
    status: CRSFStatusType;
    /**
     * 
     * @type {Array<CRSFTaskResponse>}
     * @memberof CRSFThreadResponse
     */
    tasks: Array<CRSFTaskResponse>;
    /**
     * 
     * @type {string}
     * @memberof CRSFThreadResponse
     */
    thread_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFThreadResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFThreadResponse interface.
 */
export function instanceOfCRSFThreadResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "tasks" in value;
    isInstance = isInstance && "thread_id" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFThreadResponseFromJSON(json: any): CRSFThreadResponse {
    return CRSFThreadResponseFromJSONTyped(json, false);
}

export function CRSFThreadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFThreadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'domain': json['domain'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'status': CRSFStatusTypeFromJSON(json['status']),
        'tasks': ((json['tasks'] as Array<any>).map(CRSFTaskResponseFromJSON)),
        'thread_id': json['thread_id'],
        'updated': json['updated'],
    };
}

export function CRSFThreadResponseToJSON(value?: CRSFThreadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'domain': value.domain,
        'elapsed': value.elapsed,
        'status': CRSFStatusTypeToJSON(value.status),
        'tasks': ((value.tasks as Array<any>).map(CRSFTaskResponseToJSON)),
        'thread_id': value.thread_id,
        'updated': value.updated,
    };
}

