import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { formatMessage } from '@explorer/helpers';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Chip = withCoreProps<ChipProps>(
  ({ label, className, ...props }: React.PropsWithChildren<ChipProps>) => {
    // const classes = useStyles({});
    const intl = useIntl();
    const clsxClass = clsx(className);

    return (
      <MuiChip
        {...props}
        label={formatMessage(intl, label)}
        className={clsxClass}
      />
    );
  },
);

export type ChipProps = Omit<MuiChipProps, 'label'> &
  CoreProps & { label: IntlLabel };
