/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitHealthSimpleResponse
 */
export interface MonitHealthSimpleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MonitHealthSimpleResponse
     */
    ok?: boolean;
}

/**
 * Check if a given object implements the MonitHealthSimpleResponse interface.
 */
export function instanceOfMonitHealthSimpleResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitHealthSimpleResponseFromJSON(json: any): MonitHealthSimpleResponse {
    return MonitHealthSimpleResponseFromJSONTyped(json, false);
}

export function MonitHealthSimpleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitHealthSimpleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ok': !exists(json, 'ok') ? undefined : json['ok'],
    };
}

export function MonitHealthSimpleResponseToJSON(value?: MonitHealthSimpleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ok': value.ok,
    };
}

