/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityKeywordOrderBy } from './MonitEntityKeywordOrderBy';
import {
    MonitEntityKeywordOrderByFromJSON,
    MonitEntityKeywordOrderByFromJSONTyped,
    MonitEntityKeywordOrderByToJSON,
} from './MonitEntityKeywordOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityKeywordsQueries
 */
export interface MonitGetEntityKeywordsQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityKeywordsQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityKeywordsQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityKeywordsQueries
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityKeywordsQueries
     */
    search?: string;
    /**
     * 
     * @type {MonitEntityKeywordOrderBy}
     * @memberof MonitGetEntityKeywordsQueries
     */
    order_by?: MonitEntityKeywordOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityKeywordsQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityKeywordsQueries interface.
 */
export function instanceOfMonitGetEntityKeywordsQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityKeywordsQueriesFromJSON(json: any): MonitGetEntityKeywordsQueries {
    return MonitGetEntityKeywordsQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityKeywordsQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityKeywordsQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityKeywordOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityKeywordsQueriesToJSON(value?: MonitGetEntityKeywordsQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'process_id': value.process_id,
        'search': value.search,
        'order_by': MonitEntityKeywordOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

