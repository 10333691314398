/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionsInner } from './ConditionsInner';
import {
    ConditionsInnerFromJSON,
    ConditionsInnerFromJSONTyped,
    ConditionsInnerToJSON,
} from './ConditionsInner';

/**
 * 
 * @export
 * @interface ControlOrCondition
 */
export interface ControlOrCondition {
    /**
     * 
     * @type {string}
     * @memberof ControlOrCondition
     */
    type?: ControlOrConditionTypeEnum;
    /**
     * 
     * @type {Array<ConditionsInner>}
     * @memberof ControlOrCondition
     */
    conditions: Array<ConditionsInner>;
}


/**
 * @export
 */
export const ControlOrConditionTypeEnum = {
    Or: 'or'
} as const;
export type ControlOrConditionTypeEnum = typeof ControlOrConditionTypeEnum[keyof typeof ControlOrConditionTypeEnum];


/**
 * Check if a given object implements the ControlOrCondition interface.
 */
export function instanceOfControlOrCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conditions" in value;

    return isInstance;
}

export function ControlOrConditionFromJSON(json: any): ControlOrCondition {
    return ControlOrConditionFromJSONTyped(json, false);
}

export function ControlOrConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlOrCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'conditions': ((json['conditions'] as Array<any>).map(ConditionsInnerFromJSON)),
    };
}

export function ControlOrConditionToJSON(value?: ControlOrCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'conditions': ((value.conditions as Array<any>).map(ConditionsInnerToJSON)),
    };
}

