/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDomainsBlocklistRecord } from './CRSFSecReviewDomainsBlocklistRecord';
import {
    CRSFSecReviewDomainsBlocklistRecordFromJSON,
    CRSFSecReviewDomainsBlocklistRecordFromJSONTyped,
    CRSFSecReviewDomainsBlocklistRecordToJSON,
} from './CRSFSecReviewDomainsBlocklistRecord';

/**
 * 
 * @export
 * @interface CRSFSecReviewMalware
 */
export interface CRSFSecReviewMalware {
    /**
     * 
     * @type {Array<CRSFSecReviewDomainsBlocklistRecord>}
     * @memberof CRSFSecReviewMalware
     */
    samples?: Array<CRSFSecReviewDomainsBlocklistRecord>;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewMalware
     */
    total: number;
}

/**
 * Check if a given object implements the CRSFSecReviewMalware interface.
 */
export function instanceOfCRSFSecReviewMalware(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CRSFSecReviewMalwareFromJSON(json: any): CRSFSecReviewMalware {
    return CRSFSecReviewMalwareFromJSONTyped(json, false);
}

export function CRSFSecReviewMalwareFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewMalware {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'samples': !exists(json, 'samples') ? undefined : ((json['samples'] as Array<any>).map(CRSFSecReviewDomainsBlocklistRecordFromJSON)),
        'total': json['total'],
    };
}

export function CRSFSecReviewMalwareToJSON(value?: CRSFSecReviewMalware | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'samples': value.samples === undefined ? undefined : ((value.samples as Array<any>).map(CRSFSecReviewDomainsBlocklistRecordToJSON)),
        'total': value.total,
    };
}

