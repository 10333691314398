/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityAssetIPAddressesOrderBy = {
    IpAddress: 'ip_address',
    Version: 'version',
    AsNumber: 'as_number',
    AsName: 'as_name',
    AsClass: 'as_class',
    Isp: 'isp',
    Shared: 'shared',
    Rdns: 'rdns',
    RdnsParent: 'rdns_parent',
    Created: 'created',
    Updated: 'updated',
    FirstDetected: 'first_detected',
    Source: 'source',
    SourceAsset: 'source_asset',
    CountFindings: 'count_findings',
    MaxLevel: 'max_level'
} as const;
export type ControlEntityAssetIPAddressesOrderBy = typeof ControlEntityAssetIPAddressesOrderBy[keyof typeof ControlEntityAssetIPAddressesOrderBy];


export function ControlEntityAssetIPAddressesOrderByFromJSON(json: any): ControlEntityAssetIPAddressesOrderBy {
    return ControlEntityAssetIPAddressesOrderByFromJSONTyped(json, false);
}

export function ControlEntityAssetIPAddressesOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetIPAddressesOrderBy {
    return json as ControlEntityAssetIPAddressesOrderBy;
}

export function ControlEntityAssetIPAddressesOrderByToJSON(value?: ControlEntityAssetIPAddressesOrderBy | null): any {
    return value as any;
}

