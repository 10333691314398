/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface SecReviewTrigger
 */
export interface SecReviewTrigger {
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    entity_domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewTrigger
     */
    domains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewTrigger
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof SecReviewTrigger
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    extended?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    bypass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    hazmat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    priority?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    nocache?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    uselambdas?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    aggregate?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SecReviewTrigger
     */
    extra?: object;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    issue?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    rsa_version?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    se_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewTrigger
     */
    dry_run?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    ts?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    insurance_env?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewTrigger
     */
    retained_from_uuid?: string;
}

/**
 * Check if a given object implements the SecReviewTrigger interface.
 */
export function instanceOfSecReviewTrigger(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_domain" in value;

    return isInstance;
}

export function SecReviewTriggerFromJSON(json: any): SecReviewTrigger {
    return SecReviewTriggerFromJSONTyped(json, false);
}

export function SecReviewTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': json['entity_domain'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'extended': !exists(json, 'extended') ? undefined : json['extended'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'bypass': !exists(json, 'bypass') ? undefined : json['bypass'],
        'hazmat': !exists(json, 'hazmat') ? undefined : json['hazmat'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'uselambdas': !exists(json, 'uselambdas') ? undefined : json['uselambdas'],
        'aggregate': !exists(json, 'aggregate') ? undefined : json['aggregate'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'rsa_version': !exists(json, 'rsa_version') ? undefined : json['rsa_version'],
        'se_version': !exists(json, 'se_version') ? undefined : json['se_version'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'ts': !exists(json, 'ts') ? undefined : json['ts'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : json['insurance_env'],
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
    };
}

export function SecReviewTriggerToJSON(value?: SecReviewTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'extended': value.extended,
        'force': value.force,
        'bypass': value.bypass,
        'hazmat': value.hazmat,
        'priority': value.priority,
        'nocache': value.nocache,
        'uselambdas': value.uselambdas,
        'aggregate': value.aggregate,
        'extra': value.extra,
        'issue': value.issue,
        'type': value.type,
        'rsa_version': value.rsa_version,
        'se_version': value.se_version,
        'dry_run': value.dry_run,
        'user': value.user,
        'ts': value.ts,
        'insurance_env': value.insurance_env,
        'account_uuid': value.account_uuid,
        'desired_policy_uuid': value.desired_policy_uuid,
        'renewal_of_uuid': value.renewal_of_uuid,
        'retained_from_uuid': value.retained_from_uuid,
    };
}

