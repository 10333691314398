/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewCVE
 */
export interface SecReviewCVE {
    /**
     * 
     * @type {string}
     * @memberof SecReviewCVE
     */
    cve_id: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewCVE
     */
    cvss2?: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewCVE
     */
    cvss3?: string;
    /**
     * 
     * @type {number}
     * @memberof SecReviewCVE
     */
    epss_score?: number;
}

/**
 * Check if a given object implements the SecReviewCVE interface.
 */
export function instanceOfSecReviewCVE(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cve_id" in value;

    return isInstance;
}

export function SecReviewCVEFromJSON(json: any): SecReviewCVE {
    return SecReviewCVEFromJSONTyped(json, false);
}

export function SecReviewCVEFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewCVE {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cve_id': json['cve_id'],
        'cvss2': !exists(json, 'cvss2') ? undefined : json['cvss2'],
        'cvss3': !exists(json, 'cvss3') ? undefined : json['cvss3'],
        'epss_score': !exists(json, 'epss_score') ? undefined : json['epss_score'],
    };
}

export function SecReviewCVEToJSON(value?: SecReviewCVE | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cve_id': value.cve_id,
        'cvss2': value.cvss2,
        'cvss3': value.cvss3,
        'epss_score': value.epss_score,
    };
}

