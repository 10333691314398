/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    listed_by_name_governing_doc_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    suprt_org_no_irs_determination_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    supported_org_section_c_456_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    supported_org_qualified_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    suprt_exclusively_sec_170_c_2_b_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    supported_org_not_organized_us_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    control_deciding_grnt_frgn_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    support_foreign_org_no_determ_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    organization_change_suprt_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    supported_org_class_designated_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    substitution_beyond_org_cntl_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    support_non_supported_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    payment_substantial_contribtr_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    loan_disqualified_person_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    controlled_disqualified_prsn_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    disqualified_prsn_controll_int_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    disqualified_prsn_ownr_int_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    excess_business_holdings_rules_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    excess_business_holdings_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    contribution_controller_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    contribution_family_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg
     */
    contribution_35_controlled_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg {
    return ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listed_by_name_governing_doc_ind': !exists(json, 'listed_by_name_governing_doc_ind') ? undefined : json['listed_by_name_governing_doc_ind'],
        'suprt_org_no_irs_determination_ind': !exists(json, 'suprt_org_no_irs_determination_ind') ? undefined : json['suprt_org_no_irs_determination_ind'],
        'supported_org_section_c_456_ind': !exists(json, 'supported_org_section_c_456_ind') ? undefined : json['supported_org_section_c_456_ind'],
        'supported_org_qualified_ind': !exists(json, 'supported_org_qualified_ind') ? undefined : json['supported_org_qualified_ind'],
        'suprt_exclusively_sec_170_c_2_b_ind': !exists(json, 'suprt_exclusively_sec_170_c_2_b_ind') ? undefined : json['suprt_exclusively_sec_170_c_2_b_ind'],
        'supported_org_not_organized_us_ind': !exists(json, 'supported_org_not_organized_us_ind') ? undefined : json['supported_org_not_organized_us_ind'],
        'control_deciding_grnt_frgn_org_ind': !exists(json, 'control_deciding_grnt_frgn_org_ind') ? undefined : json['control_deciding_grnt_frgn_org_ind'],
        'support_foreign_org_no_determ_ind': !exists(json, 'support_foreign_org_no_determ_ind') ? undefined : json['support_foreign_org_no_determ_ind'],
        'organization_change_suprt_org_ind': !exists(json, 'organization_change_suprt_org_ind') ? undefined : json['organization_change_suprt_org_ind'],
        'supported_org_class_designated_ind': !exists(json, 'supported_org_class_designated_ind') ? undefined : json['supported_org_class_designated_ind'],
        'substitution_beyond_org_cntl_ind': !exists(json, 'substitution_beyond_org_cntl_ind') ? undefined : json['substitution_beyond_org_cntl_ind'],
        'support_non_supported_org_ind': !exists(json, 'support_non_supported_org_ind') ? undefined : json['support_non_supported_org_ind'],
        'payment_substantial_contribtr_ind': !exists(json, 'payment_substantial_contribtr_ind') ? undefined : json['payment_substantial_contribtr_ind'],
        'loan_disqualified_person_ind': !exists(json, 'loan_disqualified_person_ind') ? undefined : json['loan_disqualified_person_ind'],
        'controlled_disqualified_prsn_ind': !exists(json, 'controlled_disqualified_prsn_ind') ? undefined : json['controlled_disqualified_prsn_ind'],
        'disqualified_prsn_controll_int_ind': !exists(json, 'disqualified_prsn_controll_int_ind') ? undefined : json['disqualified_prsn_controll_int_ind'],
        'disqualified_prsn_ownr_int_ind': !exists(json, 'disqualified_prsn_ownr_int_ind') ? undefined : json['disqualified_prsn_ownr_int_ind'],
        'excess_business_holdings_rules_ind': !exists(json, 'excess_business_holdings_rules_ind') ? undefined : json['excess_business_holdings_rules_ind'],
        'excess_business_holdings_ind': !exists(json, 'excess_business_holdings_ind') ? undefined : json['excess_business_holdings_ind'],
        'contribution_controller_ind': !exists(json, 'contribution_controller_ind') ? undefined : json['contribution_controller_ind'],
        'contribution_family_ind': !exists(json, 'contribution_family_ind') ? undefined : json['contribution_family_ind'],
        'contribution_35_controlled_ind': !exists(json, 'contribution_35_controlled_ind') ? undefined : json['contribution_35_controlled_ind'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'listed_by_name_governing_doc_ind': value.listed_by_name_governing_doc_ind,
        'suprt_org_no_irs_determination_ind': value.suprt_org_no_irs_determination_ind,
        'supported_org_section_c_456_ind': value.supported_org_section_c_456_ind,
        'supported_org_qualified_ind': value.supported_org_qualified_ind,
        'suprt_exclusively_sec_170_c_2_b_ind': value.suprt_exclusively_sec_170_c_2_b_ind,
        'supported_org_not_organized_us_ind': value.supported_org_not_organized_us_ind,
        'control_deciding_grnt_frgn_org_ind': value.control_deciding_grnt_frgn_org_ind,
        'support_foreign_org_no_determ_ind': value.support_foreign_org_no_determ_ind,
        'organization_change_suprt_org_ind': value.organization_change_suprt_org_ind,
        'supported_org_class_designated_ind': value.supported_org_class_designated_ind,
        'substitution_beyond_org_cntl_ind': value.substitution_beyond_org_cntl_ind,
        'support_non_supported_org_ind': value.support_non_supported_org_ind,
        'payment_substantial_contribtr_ind': value.payment_substantial_contribtr_ind,
        'loan_disqualified_person_ind': value.loan_disqualified_person_ind,
        'controlled_disqualified_prsn_ind': value.controlled_disqualified_prsn_ind,
        'disqualified_prsn_controll_int_ind': value.disqualified_prsn_controll_int_ind,
        'disqualified_prsn_ownr_int_ind': value.disqualified_prsn_ownr_int_ind,
        'excess_business_holdings_rules_ind': value.excess_business_holdings_rules_ind,
        'excess_business_holdings_ind': value.excess_business_holdings_ind,
        'contribution_controller_ind': value.contribution_controller_ind,
        'contribution_family_ind': value.contribution_family_ind,
        'contribution_35_controlled_ind': value.contribution_35_controlled_ind,
    };
}

