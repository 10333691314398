/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityDataleaksInnerData } from './ControlEntityDataleaksInnerData';
import {
    ControlEntityDataleaksInnerDataFromJSON,
    ControlEntityDataleaksInnerDataFromJSONTyped,
    ControlEntityDataleaksInnerDataToJSON,
} from './ControlEntityDataleaksInnerData';

/**
 * 
 * @export
 * @interface ControlEntityDataleaksSumResponse
 */
export interface ControlEntityDataleaksSumResponse {
    /**
     * 
     * @type {Array<ControlEntityDataleaksInnerData>}
     * @memberof ControlEntityDataleaksSumResponse
     */
    events?: Array<ControlEntityDataleaksInnerData>;
    /**
     * 
     * @type {Array<ControlEntityDataleaksInnerData>}
     * @memberof ControlEntityDataleaksSumResponse
     */
    features?: Array<ControlEntityDataleaksInnerData>;
    /**
     * 
     * @type {Array<ControlEntityDataleaksInnerData>}
     * @memberof ControlEntityDataleaksSumResponse
     */
    main_leaks?: Array<ControlEntityDataleaksInnerData>;
}

/**
 * Check if a given object implements the ControlEntityDataleaksSumResponse interface.
 */
export function instanceOfControlEntityDataleaksSumResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityDataleaksSumResponseFromJSON(json: any): ControlEntityDataleaksSumResponse {
    return ControlEntityDataleaksSumResponseFromJSONTyped(json, false);
}

export function ControlEntityDataleaksSumResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDataleaksSumResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(ControlEntityDataleaksInnerDataFromJSON)),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(ControlEntityDataleaksInnerDataFromJSON)),
        'main_leaks': !exists(json, 'main_leaks') ? undefined : ((json['main_leaks'] as Array<any>).map(ControlEntityDataleaksInnerDataFromJSON)),
    };
}

export function ControlEntityDataleaksSumResponseToJSON(value?: ControlEntityDataleaksSumResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(ControlEntityDataleaksInnerDataToJSON)),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(ControlEntityDataleaksInnerDataToJSON)),
        'main_leaks': value.main_leaks === undefined ? undefined : ((value.main_leaks as Array<any>).map(ControlEntityDataleaksInnerDataToJSON)),
    };
}

