import React from 'react';
import {
  CopyToClipboard,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  TypographyProps,
  TypographyLink,
} from '@explorer/core';
import PublicIcon from '@mui/icons-material/Public';
import { formatAsset, truncateText } from '@explorer/helpers';
import { CellEmpty } from '../CellEmpty';
import { GotoAssetProps } from '../../../types';

const AssetComponent = React.memo(
  ({
    entityId,
    asset,
    port,
    variant,
    truncateAt,
    gotoAssetProps,
    newTab,
  }: CellAssetProps) => {
    const formatedAsset: string = formatAsset(asset!, port);

    if (gotoAssetProps) {
      return (
        <TypographyLink
          variant={variant}
          newTab={newTab}
          title={{
            id: 'tables-presets.goto-asset',
            values: { asset },
          }}
          onClick={(e: any) => e.stopPropagation()}
          {...gotoAssetProps({ id: entityId, asset })}
        >
          {truncateAt ? truncateText(formatedAsset, truncateAt) : formatedAsset}
        </TypographyLink>
      );
    }

    return (
      <Typography variant={variant}>
        {truncateAt ? truncateText(formatedAsset, truncateAt) : formatedAsset}
      </Typography>
    );
  },
);

/**
 * CellAsset
 */
export const CellAsset = ({
  entityId,
  asset,
  port,
  shared,
  truncateAt,
  variant = 'bodyXS',
  newTab = false,
  gotoAssetProps,
}: CellAssetProps) => {
  if (!asset) return <CellEmpty variant={variant} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item mr={0.5}>
        <CopyToClipboard size="small" copyMe={asset} />
      </Grid>
      <Grid item>
        <AssetComponent
          entityId={entityId}
          asset={asset}
          port={port}
          truncateAt={truncateAt}
          variant={variant}
          newTab={newTab}
          gotoAssetProps={gotoAssetProps}
        />
      </Grid>
      {shared && (
        <Grid item ml={0.5}>
          <Tooltip title="Shared Asset" placement="right">
            <IconButton size="small" style={{ cursor: 'help' }}>
              <PublicIcon color="disabled" style={{ fontSize: '0.9rem' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

/**
 * CellAsset Props
 */
export interface CellAssetProps extends Pick<TypographyProps, 'variant'> {
  entityId?: string;
  asset?: string;
  port?: string | number;
  shared?: boolean | null;
  truncateAt?: number;
  newTab?: boolean;
  gotoAssetProps?: GotoAssetProps;
}
