/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityAssetDomainBlocklist } from './MonitEntityAssetDomainBlocklist';
import {
    MonitEntityAssetDomainBlocklistFromJSON,
    MonitEntityAssetDomainBlocklistFromJSONTyped,
    MonitEntityAssetDomainBlocklistToJSON,
} from './MonitEntityAssetDomainBlocklist';
import type { MonitEntityAssetIpAddressesHosting } from './MonitEntityAssetIpAddressesHosting';
import {
    MonitEntityAssetIpAddressesHostingFromJSON,
    MonitEntityAssetIpAddressesHostingFromJSONTyped,
    MonitEntityAssetIpAddressesHostingToJSON,
} from './MonitEntityAssetIpAddressesHosting';
import type { MonitEntityAssetsDomainDnsRecord } from './MonitEntityAssetsDomainDnsRecord';
import {
    MonitEntityAssetsDomainDnsRecordFromJSON,
    MonitEntityAssetsDomainDnsRecordFromJSONTyped,
    MonitEntityAssetsDomainDnsRecordToJSON,
} from './MonitEntityAssetsDomainDnsRecord';
import type { MonitEntityAssetsDomainsResult } from './MonitEntityAssetsDomainsResult';
import {
    MonitEntityAssetsDomainsResultFromJSON,
    MonitEntityAssetsDomainsResultFromJSONTyped,
    MonitEntityAssetsDomainsResultToJSON,
} from './MonitEntityAssetsDomainsResult';
import type { MonitEntityAssetsIpAddressesGeoip } from './MonitEntityAssetsIpAddressesGeoip';
import {
    MonitEntityAssetsIpAddressesGeoipFromJSON,
    MonitEntityAssetsIpAddressesGeoipFromJSONTyped,
    MonitEntityAssetsIpAddressesGeoipToJSON,
} from './MonitEntityAssetsIpAddressesGeoip';
import type { MonitEntityAssetsIpAddressesResult } from './MonitEntityAssetsIpAddressesResult';
import {
    MonitEntityAssetsIpAddressesResultFromJSON,
    MonitEntityAssetsIpAddressesResultFromJSONTyped,
    MonitEntityAssetsIpAddressesResultToJSON,
} from './MonitEntityAssetsIpAddressesResult';

/**
 * 
 * @export
 * @interface ResultsInner1
 */
export interface ResultsInner1 {
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    ip_address?: string;
    /**
     * 
     * @type {number}
     * @memberof ResultsInner1
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    source_asset?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResultsInner1
     */
    domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    as_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    as_class?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResultsInner1
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    rdns?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    rdns_parent?: string;
    /**
     * 
     * @type {MonitEntityAssetsIpAddressesGeoip}
     * @memberof ResultsInner1
     */
    geoip?: MonitEntityAssetsIpAddressesGeoip;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    report_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    first_detected?: string;
    /**
     * 
     * @type {Array<MonitEntityAssetIpAddressesHosting>}
     * @memberof ResultsInner1
     */
    hosting?: Array<MonitEntityAssetIpAddressesHosting>;
    /**
     * 
     * @type {Array<MonitEntityAssetDomainBlocklist>}
     * @memberof ResultsInner1
     */
    blocklists?: Array<MonitEntityAssetDomainBlocklist>;
    /**
     * 
     * @type {number}
     * @memberof ResultsInner1
     */
    findings_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    max_level?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsInner1
     */
    domain?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResultsInner1
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {Array<MonitEntityAssetsDomainDnsRecord>}
     * @memberof ResultsInner1
     */
    dns_records?: Array<MonitEntityAssetsDomainDnsRecord>;
}

/**
 * Check if a given object implements the ResultsInner1 interface.
 */
export function instanceOfResultsInner1(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultsInner1FromJSON(json: any): ResultsInner1 {
    return ResultsInner1FromJSONTyped(json, false);
}

export function ResultsInner1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultsInner1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'as_class': !exists(json, 'as_class') ? undefined : json['as_class'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'rdns': !exists(json, 'rdns') ? undefined : json['rdns'],
        'rdns_parent': !exists(json, 'rdns_parent') ? undefined : json['rdns_parent'],
        'geoip': !exists(json, 'geoip') ? undefined : MonitEntityAssetsIpAddressesGeoipFromJSON(json['geoip']),
        'report_status': !exists(json, 'report_status') ? undefined : json['report_status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'first_detected': !exists(json, 'first_detected') ? undefined : json['first_detected'],
        'hosting': !exists(json, 'hosting') ? undefined : ((json['hosting'] as Array<any>).map(MonitEntityAssetIpAddressesHostingFromJSON)),
        'blocklists': !exists(json, 'blocklists') ? undefined : ((json['blocklists'] as Array<any>).map(MonitEntityAssetDomainBlocklistFromJSON)),
        'findings_count': !exists(json, 'findings_count') ? undefined : json['findings_count'],
        'max_level': !exists(json, 'max_level') ? undefined : json['max_level'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'dns_records': !exists(json, 'dns_records') ? undefined : ((json['dns_records'] as Array<any>).map(MonitEntityAssetsDomainDnsRecordFromJSON)),
    };
}

export function ResultsInner1ToJSON(value?: ResultsInner1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_logo_url': value.entity_logo_url,
        'ip_address': value.ip_address,
        'version': value.version,
        'source': value.source,
        'source_asset': value.source_asset,
        'domains': value.domains,
        'as_number': value.as_number,
        'as_name': value.as_name,
        'isp': value.isp,
        'as_class': value.as_class,
        'shared': value.shared,
        'rdns': value.rdns,
        'rdns_parent': value.rdns_parent,
        'geoip': MonitEntityAssetsIpAddressesGeoipToJSON(value.geoip),
        'report_status': value.report_status,
        'created': value.created,
        'updated': value.updated,
        'first_detected': value.first_detected,
        'hosting': value.hosting === undefined ? undefined : ((value.hosting as Array<any>).map(MonitEntityAssetIpAddressesHostingToJSON)),
        'blocklists': value.blocklists === undefined ? undefined : ((value.blocklists as Array<any>).map(MonitEntityAssetDomainBlocklistToJSON)),
        'findings_count': value.findings_count,
        'max_level': value.max_level,
        'domain': value.domain,
        'ip_addresses': value.ip_addresses,
        'dns_records': value.dns_records === undefined ? undefined : ((value.dns_records as Array<any>).map(MonitEntityAssetsDomainDnsRecordToJSON)),
    };
}

