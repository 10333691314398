/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppCoreEnumsAuth0Roles } from './ControlAppCoreEnumsAuth0Roles';
import {
    ControlAppCoreEnumsAuth0RolesFromJSON,
    ControlAppCoreEnumsAuth0RolesFromJSONTyped,
    ControlAppCoreEnumsAuth0RolesToJSON,
} from './ControlAppCoreEnumsAuth0Roles';

/**
 * 
 * @export
 * @interface ControlUserUpdateRoleRequest
 */
export interface ControlUserUpdateRoleRequest {
    /**
     * 
     * @type {Array<ControlAppCoreEnumsAuth0Roles>}
     * @memberof ControlUserUpdateRoleRequest
     */
    roles: Array<ControlAppCoreEnumsAuth0Roles>;
}

/**
 * Check if a given object implements the ControlUserUpdateRoleRequest interface.
 */
export function instanceOfControlUserUpdateRoleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function ControlUserUpdateRoleRequestFromJSON(json: any): ControlUserUpdateRoleRequest {
    return ControlUserUpdateRoleRequestFromJSONTyped(json, false);
}

export function ControlUserUpdateRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserUpdateRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': ((json['roles'] as Array<any>).map(ControlAppCoreEnumsAuth0RolesFromJSON)),
    };
}

export function ControlUserUpdateRoleRequestToJSON(value?: ControlUserUpdateRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': ((value.roles as Array<any>).map(ControlAppCoreEnumsAuth0RolesToJSON)),
    };
}

