/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityKeywordsResult
 */
export interface ControlEntityKeywordsResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityKeywordsResult
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ControlEntityKeywordsResult
     */
    highlighted?: Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    module?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    path?: string;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityKeywordsResult
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityKeywordsResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the ControlEntityKeywordsResult interface.
 */
export function instanceOfControlEntityKeywordsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityKeywordsResultFromJSON(json: any): ControlEntityKeywordsResult {
    return ControlEntityKeywordsResultFromJSONTyped(json, false);
}

export function ControlEntityKeywordsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityKeywordsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'highlighted': !exists(json, 'highlighted') ? undefined : json['highlighted'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function ControlEntityKeywordsResultToJSON(value?: ControlEntityKeywordsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_domain': value.entity_domain,
        'entity_name': value.entity_name,
        'keywords': value.keywords,
        'highlighted': value.highlighted,
        'asset': value.asset,
        'port': value.port,
        'module': value.module,
        'path': value.path,
        'data': value.data,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
    };
}

