/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitGetImpactedAssetsSummaryQueries
 */
export interface MonitGetImpactedAssetsSummaryQueries {
    /**
     * 
     * @type {string}
     * @memberof MonitGetImpactedAssetsSummaryQueries
     */
    min_severity_level?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetImpactedAssetsSummaryQueries
     */
    exclude_removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetImpactedAssetsSummaryQueries
     */
    exclude_ignored?: boolean;
}

/**
 * Check if a given object implements the MonitGetImpactedAssetsSummaryQueries interface.
 */
export function instanceOfMonitGetImpactedAssetsSummaryQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetImpactedAssetsSummaryQueriesFromJSON(json: any): MonitGetImpactedAssetsSummaryQueries {
    return MonitGetImpactedAssetsSummaryQueriesFromJSONTyped(json, false);
}

export function MonitGetImpactedAssetsSummaryQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetImpactedAssetsSummaryQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min_severity_level': !exists(json, 'min_severity_level') ? undefined : json['min_severity_level'],
        'exclude_removed': !exists(json, 'exclude_removed') ? undefined : json['exclude_removed'],
        'exclude_ignored': !exists(json, 'exclude_ignored') ? undefined : json['exclude_ignored'],
    };
}

export function MonitGetImpactedAssetsSummaryQueriesToJSON(value?: MonitGetImpactedAssetsSummaryQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min_severity_level': value.min_severity_level,
        'exclude_removed': value.exclude_removed,
        'exclude_ignored': value.exclude_ignored,
    };
}

