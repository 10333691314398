/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityAssetDomainBlocklist } from './ControlEntityAssetDomainBlocklist';
import {
    ControlEntityAssetDomainBlocklistFromJSON,
    ControlEntityAssetDomainBlocklistFromJSONTyped,
    ControlEntityAssetDomainBlocklistToJSON,
} from './ControlEntityAssetDomainBlocklist';
import type { ControlEntityLookalikesDns } from './ControlEntityLookalikesDns';
import {
    ControlEntityLookalikesDnsFromJSON,
    ControlEntityLookalikesDnsFromJSONTyped,
    ControlEntityLookalikesDnsToJSON,
} from './ControlEntityLookalikesDns';
import type { ControlEntityLookalikesHttp } from './ControlEntityLookalikesHttp';
import {
    ControlEntityLookalikesHttpFromJSON,
    ControlEntityLookalikesHttpFromJSONTyped,
    ControlEntityLookalikesHttpToJSON,
} from './ControlEntityLookalikesHttp';
import type { ControlEntityLookalikesSsl } from './ControlEntityLookalikesSsl';
import {
    ControlEntityLookalikesSslFromJSON,
    ControlEntityLookalikesSslFromJSONTyped,
    ControlEntityLookalikesSslToJSON,
} from './ControlEntityLookalikesSsl';

/**
 * 
 * @export
 * @interface ControlEntityLookalikesResult
 */
export interface ControlEntityLookalikesResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesResult
     */
    domain?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityLookalikesResult
     */
    category?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityLookalikesResult
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {Array<ControlEntityLookalikesDns>}
     * @memberof ControlEntityLookalikesResult
     */
    dns: Array<ControlEntityLookalikesDns>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityLookalikesResult
     */
    mx?: boolean;
    /**
     * 
     * @type {ControlEntityLookalikesSsl}
     * @memberof ControlEntityLookalikesResult
     */
    ssl?: ControlEntityLookalikesSsl;
    /**
     * 
     * @type {ControlEntityLookalikesHttp}
     * @memberof ControlEntityLookalikesResult
     */
    http?: ControlEntityLookalikesHttp;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesResult
     */
    updated?: string;
    /**
     * 
     * @type {ControlEntityAssetDomainBlocklist}
     * @memberof ControlEntityLookalikesResult
     */
    blocklists?: ControlEntityAssetDomainBlocklist;
}

/**
 * Check if a given object implements the ControlEntityLookalikesResult interface.
 */
export function instanceOfControlEntityLookalikesResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dns" in value;

    return isInstance;
}

export function ControlEntityLookalikesResultFromJSON(json: any): ControlEntityLookalikesResult {
    return ControlEntityLookalikesResultFromJSONTyped(json, false);
}

export function ControlEntityLookalikesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityLookalikesResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'dns': ((json['dns'] as Array<any>).map(ControlEntityLookalikesDnsFromJSON)),
        'mx': !exists(json, 'mx') ? undefined : json['mx'],
        'ssl': !exists(json, 'ssl') ? undefined : ControlEntityLookalikesSslFromJSON(json['ssl']),
        'http': !exists(json, 'http') ? undefined : ControlEntityLookalikesHttpFromJSON(json['http']),
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'blocklists': !exists(json, 'blocklists') ? undefined : ControlEntityAssetDomainBlocklistFromJSON(json['blocklists']),
    };
}

export function ControlEntityLookalikesResultToJSON(value?: ControlEntityLookalikesResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'category': value.category,
        'ip_addresses': value.ip_addresses,
        'dns': ((value.dns as Array<any>).map(ControlEntityLookalikesDnsToJSON)),
        'mx': value.mx,
        'ssl': ControlEntityLookalikesSslToJSON(value.ssl),
        'http': ControlEntityLookalikesHttpToJSON(value.http),
        'created': value.created,
        'updated': value.updated,
        'blocklists': ControlEntityAssetDomainBlocklistToJSON(value.blocklists),
    };
}

