import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMemo, useState } from 'react';

export type ExplorerAPIClientContextType = {
  client: QueryClient;
};

export const ExplorerAPIClientProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [queryClientManager] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClientManager}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export const useExplorerAPIClientContext = (): ExplorerAPIClientContextType => {
  const client = useQueryClient();
  const context = useMemo(() => ({ client }), [client]);
  if (!context.client) {
    throw new Error(
      'useExplorerApiClientContext must be used within a ExplorerApiClientProvider',
    );
  }

  return context;
};
