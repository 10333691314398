/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlDomainValidationMethods } from './ControlDomainValidationMethods';
import {
    ControlDomainValidationMethodsFromJSON,
    ControlDomainValidationMethodsFromJSONTyped,
    ControlDomainValidationMethodsToJSON,
} from './ControlDomainValidationMethods';

/**
 * 
 * @export
 * @interface ControlCustomDomainResponse
 */
export interface ControlCustomDomainResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainResponse
     */
    domain: string;
    /**
     * 
     * @type {ControlDomainValidationMethods}
     * @memberof ControlCustomDomainResponse
     */
    verification_method: ControlDomainValidationMethods;
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainResponse
     */
    verification_code: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlCustomDomainResponse
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlCustomDomainResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the ControlCustomDomainResponse interface.
 */
export function instanceOfControlCustomDomainResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "verification_method" in value;
    isInstance = isInstance && "verification_code" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ControlCustomDomainResponseFromJSON(json: any): ControlCustomDomainResponse {
    return ControlCustomDomainResponseFromJSONTyped(json, false);
}

export function ControlCustomDomainResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlCustomDomainResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'verification_method': ControlDomainValidationMethodsFromJSON(json['verification_method']),
        'verification_code': json['verification_code'],
        'confirmed': !exists(json, 'confirmed') ? undefined : json['confirmed'],
        'created': json['created'],
        'updated': json['updated'],
    };
}

export function ControlCustomDomainResponseToJSON(value?: ControlCustomDomainResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'verification_method': ControlDomainValidationMethodsToJSON(value.verification_method),
        'verification_code': value.verification_code,
        'confirmed': value.confirmed,
        'created': value.created,
        'updated': value.updated,
    };
}

