/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    name_of_organization?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    organization_type_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    description_of_relationship?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    return ExecRiskAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name_of_organization': !exists(json, 'name_of_organization') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['name_of_organization']),
        'organization_type_desc': !exists(json, 'organization_type_desc') ? undefined : json['organization_type_desc'],
        'description_of_relationship': !exists(json, 'description_of_relationship') ? undefined : json['description_of_relationship'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleARelationshipScheduleToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleARelationshipSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name_of_organization': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.name_of_organization),
        'organization_type_desc': value.organization_type_desc,
        'description_of_relationship': value.description_of_relationship,
    };
}

