/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cpe1 } from './Cpe1';
import {
    Cpe1FromJSON,
    Cpe1FromJSONTyped,
    Cpe1ToJSON,
} from './Cpe1';
import type { Cve1 } from './Cve1';
import {
    Cve1FromJSON,
    Cve1FromJSONTyped,
    Cve1ToJSON,
} from './Cve1';
import type { Value2 } from './Value2';
import {
    Value2FromJSON,
    Value2FromJSONTyped,
    Value2ToJSON,
} from './Value2';

/**
 * 
 * @export
 * @interface MonitEntityTechResultData
 */
export interface MonitEntityTechResultData {
    /**
     * 
     * @type {Value2}
     * @memberof MonitEntityTechResultData
     */
    value?: Value2;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    banner?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    product?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityTechResultData
     */
    confidence?: number;
    /**
     * 
     * @type {Cve1}
     * @memberof MonitEntityTechResultData
     */
    cve?: Cve1;
    /**
     * 
     * @type {Cpe1}
     * @memberof MonitEntityTechResultData
     */
    cpe?: Cpe1;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    ostype?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTechResultData
     */
    extrainfo?: string;
}

/**
 * Check if a given object implements the MonitEntityTechResultData interface.
 */
export function instanceOfMonitEntityTechResultData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityTechResultDataFromJSON(json: any): MonitEntityTechResultData {
    return MonitEntityTechResultDataFromJSONTyped(json, false);
}

export function MonitEntityTechResultDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityTechResultData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : Value2FromJSON(json['value']),
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
        'cve': !exists(json, 'cve') ? undefined : Cve1FromJSON(json['cve']),
        'cpe': !exists(json, 'cpe') ? undefined : Cpe1FromJSON(json['cpe']),
        'ostype': !exists(json, 'ostype') ? undefined : json['ostype'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'extrainfo': !exists(json, 'extrainfo') ? undefined : json['extrainfo'],
    };
}

export function MonitEntityTechResultDataToJSON(value?: MonitEntityTechResultData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': Value2ToJSON(value.value),
        'banner': value.banner,
        'method': value.method,
        'name': value.name,
        'product': value.product,
        'confidence': value.confidence,
        'cve': Cve1ToJSON(value.cve),
        'cpe': Cpe1ToJSON(value.cpe),
        'ostype': value.ostype,
        'version': value.version,
        'extrainfo': value.extrainfo,
    };
}

