import React from 'react';
import clsx from 'clsx';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const Radio = withCoreProps<RadioProps>(
  ({ className, ...props }: React.PropsWithChildren<RadioProps>) => {
    const clsxClass = clsx(className);

    return <MuiRadio {...props} className={clsxClass} />;
  },
);

export interface RadioProps extends MuiRadioProps, CoreProps {}
