/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityRelationStatsCriticalityCount } from './ControlEntityRelationStatsCriticalityCount';
import {
    ControlEntityRelationStatsCriticalityCountFromJSON,
    ControlEntityRelationStatsCriticalityCountFromJSONTyped,
    ControlEntityRelationStatsCriticalityCountToJSON,
} from './ControlEntityRelationStatsCriticalityCount';

/**
 * 
 * @export
 * @interface ControlEntityRelationStatsGroupResult
 */
export interface ControlEntityRelationStatsGroupResult {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    risk_score?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    risk_score_v2?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    assets_count?: number;
    /**
     * 
     * @type {ControlEntityRelationStatsCriticalityCount}
     * @memberof ControlEntityRelationStatsGroupResult
     */
    criticality_sum?: ControlEntityRelationStatsCriticalityCount;
}

/**
 * Check if a given object implements the ControlEntityRelationStatsGroupResult interface.
 */
export function instanceOfControlEntityRelationStatsGroupResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityRelationStatsGroupResultFromJSON(json: any): ControlEntityRelationStatsGroupResult {
    return ControlEntityRelationStatsGroupResultFromJSONTyped(json, false);
}

export function ControlEntityRelationStatsGroupResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityRelationStatsGroupResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : json['risk_score_v2'],
        'assets_count': !exists(json, 'assets_count') ? undefined : json['assets_count'],
        'criticality_sum': !exists(json, 'criticality_sum') ? undefined : ControlEntityRelationStatsCriticalityCountFromJSON(json['criticality_sum']),
    };
}

export function ControlEntityRelationStatsGroupResultToJSON(value?: ControlEntityRelationStatsGroupResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_logo_url': value.entity_logo_url,
        'risk_score': value.risk_score,
        'risk_score_v2': value.risk_score_v2,
        'assets_count': value.assets_count,
        'criticality_sum': ControlEntityRelationStatsCriticalityCountToJSON(value.criticality_sum),
    };
}

