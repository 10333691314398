import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

/**
 * Returns the relative time compared by the `date` param.
 *
 * __> Example:__
 * ```ts
 * getRelativeTime(1601463204);
 * // "20 years ago"
 * ```
 */

export const getRelativeTime: GetRelativeTime = (date, msUnix) => {
  dayjs.extend(relativeTime);

  if (msUnix) return dayjs(date).fromNow();

  // BE will sometimes send a 13 or 10 digit timestamp
  // dayjs only uses the `.unix()` method for 10 digit ones
  if (typeof date === 'number' && date.toString().length === 10)
    return dayjs.unix(date).fromNow();

  return dayjs(date).fromNow();
};

type GetRelativeTime = (
  date: number | string | Date,
  msUnix?: boolean,
) => string;
