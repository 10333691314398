import { UseQueryState, UseQueryReducer } from '../../types';

export const initialState: UseQueryState = {
  data: null,
  error: null,
  hasError: false,
  hasSucceeded: false,
  isLoading: false,
};

export const reducer: UseQueryReducer = (state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case 'STARTED':
      return {
        ...state,
        data: null,
        error: null,
        hasError: false,
        hasSucceeded: false,
        isLoading: true,
      };
    case 'SUCCEEDED':
      return {
        ...state,
        data: payload?.data || null,
        error: null,
        hasError: false,
        hasSucceeded: true,
        isLoading: false,
      };
    case 'FAILED':
      return {
        ...state,
        data: null,
        error: payload?.error || null,
        hasError: true,
        hasSucceeded: false,
        isLoading: false,
      };
    case 'RESET':
      return { ...initialState };

    default:
      throw new Error(`<useQuery> invalid dispatch type "${type}"`);
  }
};
