/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityStatsVulnerabilityCategoriesCount } from './ControlEntityStatsVulnerabilityCategoriesCount';
import {
    ControlEntityStatsVulnerabilityCategoriesCountFromJSON,
    ControlEntityStatsVulnerabilityCategoriesCountFromJSONTyped,
    ControlEntityStatsVulnerabilityCategoriesCountToJSON,
} from './ControlEntityStatsVulnerabilityCategoriesCount';

/**
 * 
 * @export
 * @interface ControlEntityStatsVulnerabilityCategories
 */
export interface ControlEntityStatsVulnerabilityCategories {
    /**
     * 
     * @type {ControlEntityStatsVulnerabilityCategoriesCount}
     * @memberof ControlEntityStatsVulnerabilityCategories
     */
    categories?: ControlEntityStatsVulnerabilityCategoriesCount;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsVulnerabilityCategories
     */
    score_categories?: object;
}

/**
 * Check if a given object implements the ControlEntityStatsVulnerabilityCategories interface.
 */
export function instanceOfControlEntityStatsVulnerabilityCategories(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsVulnerabilityCategoriesFromJSON(json: any): ControlEntityStatsVulnerabilityCategories {
    return ControlEntityStatsVulnerabilityCategoriesFromJSONTyped(json, false);
}

export function ControlEntityStatsVulnerabilityCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsVulnerabilityCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': !exists(json, 'categories') ? undefined : ControlEntityStatsVulnerabilityCategoriesCountFromJSON(json['categories']),
        'score_categories': !exists(json, 'score_categories') ? undefined : json['score_categories'],
    };
}

export function ControlEntityStatsVulnerabilityCategoriesToJSON(value?: ControlEntityStatsVulnerabilityCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ControlEntityStatsVulnerabilityCategoriesCountToJSON(value.categories),
        'score_categories': value.score_categories,
    };
}

