import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import { formatMessage } from '@explorer/helpers';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton } from '@explorer/core';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import bytes from 'bytes';
import ErrorMessageUpload from './ErrorMessageUpload';
import { useTheme } from '@mui/material';

/**
 * FieldFileUpload
 */
export const FieldFileUpload = ({
  filesLimit = 1,
  acceptedExtensions,
  name,
  label,
  maxSize,
  title,
  fileFormatText,
  setFieldValue,
}: FieldFileUploadProps) => {
  const intl = useIntl();
  const [files, setFiles] = useState<File[]>([]);
  const theme = useTheme();

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    maxFiles: filesLimit,
    maxSize: maxSize ?? Infinity,
    accept: acceptedExtensions,
    onDrop: (dropfiles: File[]) => {
      setFieldValue(name, dropfiles);
      setFiles(dropfiles);
    },
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    setFieldValue(name, newFiles);
  };

  return (
    <Box paddingBottom="16px">
      {title ? (
        <Box display="flex">
          <ErrorOutlineIcon fontSize="small" />
          <Typography variant="bodyM" style={{ padding: '0px 8px 16px' }}>
            {formatMessage(intl, title)}
          </Typography>
        </Box>
      ) : null}
      <section className="container">
        <Box
          {...getRootProps({ className: 'dropzone' })}
          display="flex"
          padding={3}
          justifyContent="space-between"
          bgColor={theme.palette.grey[100]}
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <CloudUploadIcon style={{ color: theme.palette.fileUpload.icon }} />
            <Box paddingX={2.5} paddingY={0}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <Typography variant="bodyS" color="textPrimary">
                  {formatMessage(intl, label)}
                </Typography>
                <Typography
                  variant="bodyXS"
                  style={{ color: theme.palette.fileUpload.text }}
                >
                  {intl.formatMessage(
                    { id: 'forms-presets.upload.accepted-formats' },
                    {
                      formats: fileFormatText,
                    },
                  )}
                </Typography>
              </Box>
              <Typography variant="bodyXS" color="textSecondary">
                {formatMessage(intl, {
                  id: 'forms-presets.upload.drag-and-drop',
                })}
              </Typography>
            </Box>
          </Box>
          <input {...getInputProps()} data-testid="uploader" />
          {maxSize ? (
            <Typography
              variant="bodyXS"
              style={{ color: theme.palette.fileUpload.text }}
            >
              {intl.formatMessage(
                { id: 'forms-presets.upload.max-image' },
                {
                  size: bytes.format(maxSize, { decimalPlaces: 0 }),
                },
              )}
            </Typography>
          ) : null}
        </Box>
        <ErrorMessageUpload
          fileRejections={fileRejections}
          maxSize={maxSize ?? Infinity}
          filesLimit={filesLimit}
          fileFormatText={fileFormatText}
        />

        <aside>
          {files.map((file: File) => (
            <Box
              display="flex"
              key={file.name}
              width="100%"
              justifyContent="space-between"
              padding="16px 16px 0"
            >
              <Box display="flex">
                <ImageIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                <Box padding="0 10px">
                  <Typography variant="bodyM">{file.name}</Typography>
                  <Typography variant="bodyXS">{bytes(file.size)}</Typography>
                </Box>
              </Box>
              <IconButton size="small" onClick={() => removeFile(file)}>
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </aside>
      </section>
    </Box>
  );
};

/**
 * FieldFileUpload Props
 */
export interface FieldFileUploadProps {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id?: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name?: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `filesLimit` - max number of files
   */
  filesLimit: number;
  /**
   * `maxSize` - Maximum file size (in bytes)
   */
  maxSize?: number;
  /**
   * `acceptedFiles` - accepted file extensions
   */
  acceptedExtensions: Accept;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `title` - optional title
   */
  title?: IntlLabel;
  /**
   * `fileFormatText` - accepted file format to fill in upload container
   */
  fileFormatText: string;
}
