import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * InvoiceManipulationIcon
 */
export const InvoiceManipulationIcon = ({
  ...props
}: InvoiceManipulationIconProps) => {
  return (
    <Icon label="invoice_manipulation" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6,8.9l0.5-0.5c0.1-0.1,0.1-0.2,0-0.3l-0.6-0.6c-0.1-0.1-0.2-0.1-0.3,0L22.7,8L23.6,8.9z M21.6,11l1.6-1.6l0.1-0.1l-0.9-0.9l-1.7,1.7L21.6,11z M20.9,11.7L20.9,11.7l0.3-0.3l-0.9-0.9L20,10.7l0,0c0,0,0,0,0,0l0,0v0c0,0,0,0.1,0,0.1l-0.2,0.7c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0L20.9,11.7C20.8,11.7,20.8,11.7,20.9,11.7z M17.9,9.7c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1C19.7,13.7,17.9,11.9,17.9,9.7z M19.2,15c0,0.3-0.2,0.5-0.5,0.5H14c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h4.7C19,14.6,19.2,14.8,19.2,15z M18.7,18.6H14c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h4.7c0.3,0,0.5,0.2,0.5,0.5C19.2,18.4,19,18.6,18.7,18.6z M11.5,12.9c-0.6,0-1-0.4-1-1c0-0.5,0.5-1,1-1c0.6,0,1,0.4,1,1C12.5,12.4,12.1,12.9,11.5,12.9z M11.5,16c-0.6,0-1-0.4-1-1c0-0.5,0.5-1,1-1c0.6,0,1,0.4,1,1C12.5,15.5,12.1,16,11.5,16z M11.5,19.1c-0.6,0-1-0.4-1-1c0-0.5,0.5-1,1-1c0.6,0,1,0.4,1,1C12.5,18.7,12.1,19.1,11.5,19.1z M17.5,12.4H14c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h3.1c-0.2-0.6-0.3-1.1-0.3-1.7c0-0.3,0-0.6,0.1-0.9H9v11.7h7.5h0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.1,0.4v1.6c0,1,0.9,1.9,1.9,1.9c1,0,1.9-0.9,1.9-1.9v-7.9C19.5,14.4,18.3,13.5,17.5,12.4z M15.9,21.6H6v1c0,1,0.9,1.9,1.9,1.9h8.7c-0.4-0.5-0.7-1.2-0.7-1.9V21.6z"
        />
      </g>
    </Icon>
  );
};

/**
 * InvoiceManipulationIcon Props
 */
export interface InvoiceManipulationIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
