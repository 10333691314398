/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * process flow
 * API triggers new scan - process.status -> requested in DB, bootstrap task triggered
 * bootstrap task receives scan message and checks if there is scan in progress for same parameters
 * no scan in progress - process.status -> started and bootstrap triggers work by creating threads
 * scan in progress - process.status -> pending. No work triggered for this scan.
 * @export
 */
export const CRSFSecReviewStatusType = {
    Pending: 'pending',
    Requested: 'requested',
    Started: 'started',
    Finished: 'finished',
    Revoked: 'revoked'
} as const;
export type CRSFSecReviewStatusType = typeof CRSFSecReviewStatusType[keyof typeof CRSFSecReviewStatusType];


export function CRSFSecReviewStatusTypeFromJSON(json: any): CRSFSecReviewStatusType {
    return CRSFSecReviewStatusTypeFromJSONTyped(json, false);
}

export function CRSFSecReviewStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewStatusType {
    return json as CRSFSecReviewStatusType;
}

export function CRSFSecReviewStatusTypeToJSON(value?: CRSFSecReviewStatusType | null): any {
    return value as any;
}

