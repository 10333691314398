/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDesiredPolicyFieldsOutput } from './CRSFDesiredPolicyFieldsOutput';
import {
    CRSFDesiredPolicyFieldsOutputFromJSON,
    CRSFDesiredPolicyFieldsOutputFromJSONTyped,
    CRSFDesiredPolicyFieldsOutputToJSON,
} from './CRSFDesiredPolicyFieldsOutput';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';
import type { CRSFTicketPayloadOutput } from './CRSFTicketPayloadOutput';
import {
    CRSFTicketPayloadOutputFromJSON,
    CRSFTicketPayloadOutputFromJSONTyped,
    CRSFTicketPayloadOutputToJSON,
} from './CRSFTicketPayloadOutput';

/**
 * 
 * @export
 * @interface CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
 */
export interface CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    asm_entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    asm_external_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    attachment_domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    cobranded?: string;
    /**
     * 
     * @type {CRSFDesiredPolicyFieldsOutput}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    desired_policy_fields?: CRSFDesiredPolicyFieldsOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    domains: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    issue_key?: string;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    new_domains_at_bind?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {CRSFTicketPayloadOutput}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    ticket_payload?: CRSFTicketPayloadOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse interface.
 */
export function instanceOfCRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSON(json: any): CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    return CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSONTyped(json, false);
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'asm_entity_id': !exists(json, 'asm_entity_id') ? undefined : json['asm_entity_id'],
        'asm_external_id': !exists(json, 'asm_external_id') ? undefined : json['asm_external_id'],
        'attachment_domains': !exists(json, 'attachment_domains') ? undefined : json['attachment_domains'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_fields': !exists(json, 'desired_policy_fields') ? undefined : CRSFDesiredPolicyFieldsOutputFromJSON(json['desired_policy_fields']),
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'domains': json['domains'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': !exists(json, 'issue_key') ? undefined : json['issue_key'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'new_domains_at_bind': !exists(json, 'new_domains_at_bind') ? undefined : json['new_domains_at_bind'],
        'process_id': json['process_id'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'ticket_payload': !exists(json, 'ticket_payload') ? undefined : CRSFTicketPayloadOutputFromJSON(json['ticket_payload']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponseToJSON(value?: CRSFSrcHttpdlibCoreModelsSecondaryTriggerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_uuid': value.account_uuid,
        'asm_entity_id': value.asm_entity_id,
        'asm_external_id': value.asm_external_id,
        'attachment_domains': value.attachment_domains,
        'cobranded': value.cobranded,
        'desired_policy_fields': CRSFDesiredPolicyFieldsOutputToJSON(value.desired_policy_fields),
        'desired_policy_uuid': value.desired_policy_uuid,
        'domains': value.domains,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'locale': CRSFLocaleToJSON(value.locale),
        'new_domains_at_bind': value.new_domains_at_bind,
        'process_id': value.process_id,
        'renewal_of_uuid': value.renewal_of_uuid,
        'request_origin': value.request_origin,
        'retained_from_uuid': value.retained_from_uuid,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'ticket_payload': CRSFTicketPayloadOutputToJSON(value.ticket_payload),
        'user': value.user,
    };
}

