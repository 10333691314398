/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlResolutionTypes = {
    Scannable: 'scannable',
    Auditable: 'auditable',
    Evidence: 'evidence'
} as const;
export type ControlResolutionTypes = typeof ControlResolutionTypes[keyof typeof ControlResolutionTypes];


export function ControlResolutionTypesFromJSON(json: any): ControlResolutionTypes {
    return ControlResolutionTypesFromJSONTyped(json, false);
}

export function ControlResolutionTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResolutionTypes {
    return json as ControlResolutionTypes;
}

export function ControlResolutionTypesToJSON(value?: ControlResolutionTypes | null): any {
    return value as any;
}

