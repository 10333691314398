/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitResolutionRequestStatus = {
    Requested: 'requested',
    Approved: 'approved',
    Rejected: 'rejected',
    Cancelled: 'cancelled'
} as const;
export type MonitResolutionRequestStatus = typeof MonitResolutionRequestStatus[keyof typeof MonitResolutionRequestStatus];


export function MonitResolutionRequestStatusFromJSON(json: any): MonitResolutionRequestStatus {
    return MonitResolutionRequestStatusFromJSONTyped(json, false);
}

export function MonitResolutionRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitResolutionRequestStatus {
    return json as MonitResolutionRequestStatus;
}

export function MonitResolutionRequestStatusToJSON(value?: MonitResolutionRequestStatus | null): any {
    return value as any;
}

