/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitPolicyMetrics
 */
export interface MonitPolicyMetrics {
    /**
     * 
     * @type {string}
     * @memberof MonitPolicyMetrics
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitPolicyMetrics
     */
    number_of_employees?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitPolicyMetrics
     */
    number_of_records?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitPolicyMetrics
     */
    quote_company_revenue?: string;
}

/**
 * Check if a given object implements the MonitPolicyMetrics interface.
 */
export function instanceOfMonitPolicyMetrics(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitPolicyMetricsFromJSON(json: any): MonitPolicyMetrics {
    return MonitPolicyMetricsFromJSONTyped(json, false);
}

export function MonitPolicyMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitPolicyMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'number_of_employees': !exists(json, 'number_of_employees') ? undefined : json['number_of_employees'],
        'number_of_records': !exists(json, 'number_of_records') ? undefined : json['number_of_records'],
        'quote_company_revenue': !exists(json, 'quote_company_revenue') ? undefined : json['quote_company_revenue'],
    };
}

export function MonitPolicyMetricsToJSON(value?: MonitPolicyMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industry': value.industry,
        'number_of_employees': value.number_of_employees,
        'number_of_records': value.number_of_records,
        'quote_company_revenue': value.quote_company_revenue,
    };
}

