/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';

/**
 * 
 * @export
 * @interface ControlResolutionStatusUpdateBody
 */
export interface ControlResolutionStatusUpdateBody {
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlResolutionStatusUpdateBody
     */
    status: ControlResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionStatusUpdateBody
     */
    resolution_notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlResolutionStatusUpdateBody
     */
    reviewed_by: string;
}

/**
 * Check if a given object implements the ControlResolutionStatusUpdateBody interface.
 */
export function instanceOfControlResolutionStatusUpdateBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "reviewed_by" in value;

    return isInstance;
}

export function ControlResolutionStatusUpdateBodyFromJSON(json: any): ControlResolutionStatusUpdateBody {
    return ControlResolutionStatusUpdateBodyFromJSONTyped(json, false);
}

export function ControlResolutionStatusUpdateBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlResolutionStatusUpdateBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ControlResolutionRequestStatusFromJSON(json['status']),
        'resolution_notes': !exists(json, 'resolution_notes') ? undefined : json['resolution_notes'],
        'reviewed_by': json['reviewed_by'],
    };
}

export function ControlResolutionStatusUpdateBodyToJSON(value?: ControlResolutionStatusUpdateBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ControlResolutionRequestStatusToJSON(value.status),
        'resolution_notes': value.resolution_notes,
        'reviewed_by': value.reviewed_by,
    };
}

