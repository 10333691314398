import React from 'react';
import clsx from 'clsx';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const IconButton = withCoreProps<IconButtonProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<IconButtonProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiIconButton {...props} className={clsxClass}>
        {children}
      </MuiIconButton>
    );
  },
);

export interface IconButtonProps extends MuiIconButtonProps, CoreProps {}
