/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Port } from './Port';
import {
    PortFromJSON,
    PortFromJSONTyped,
    PortToJSON,
} from './Port';

/**
 * 
 * @export
 * @interface CRSFTech
 */
export interface CRSFTech {
    /**
     * 
     * @type {string}
     * @memberof CRSFTech
     */
    asset: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTech
     */
    category: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTech
     */
    cves?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CRSFTech
     */
    data: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFTech
     */
    found: number;
    /**
     * 
     * @type {Port}
     * @memberof CRSFTech
     */
    port?: Port;
    /**
     * 
     * @type {string}
     * @memberof CRSFTech
     */
    protocol?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTech
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFTech
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTech
     */
    tech_name: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFTech
     */
    updated: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFTech
     */
    version?: string;
}

/**
 * Check if a given object implements the CRSFTech interface.
 */
export function instanceOfCRSFTech(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "tech_name" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFTechFromJSON(json: any): CRSFTech {
    return CRSFTechFromJSONTyped(json, false);
}

export function CRSFTechFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTech {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'category': json['category'],
        'cves': !exists(json, 'cves') ? undefined : json['cves'],
        'data': json['data'],
        'found': json['found'],
        'port': !exists(json, 'port') ? undefined : PortFromJSON(json['port']),
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'tech_name': json['tech_name'],
        'updated': json['updated'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function CRSFTechToJSON(value?: CRSFTech | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'category': value.category,
        'cves': value.cves,
        'data': value.data,
        'found': value.found,
        'port': PortToJSON(value.port),
        'protocol': value.protocol,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'tech_name': value.tech_name,
        'updated': value.updated,
        'version': value.version,
    };
}

