/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityAssetDomainBlocklist } from './MonitEntityAssetDomainBlocklist';
import {
    MonitEntityAssetDomainBlocklistFromJSON,
    MonitEntityAssetDomainBlocklistFromJSONTyped,
    MonitEntityAssetDomainBlocklistToJSON,
} from './MonitEntityAssetDomainBlocklist';
import type { MonitEntityAssetsDomainDnsRecord } from './MonitEntityAssetsDomainDnsRecord';
import {
    MonitEntityAssetsDomainDnsRecordFromJSON,
    MonitEntityAssetsDomainDnsRecordFromJSONTyped,
    MonitEntityAssetsDomainDnsRecordToJSON,
} from './MonitEntityAssetsDomainDnsRecord';

/**
 * 
 * @export
 * @interface MonitEntityAssetsDomainsResult
 */
export interface MonitEntityAssetsDomainsResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    source_asset?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityAssetsDomainsResult
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {Array<MonitEntityAssetsDomainDnsRecord>}
     * @memberof MonitEntityAssetsDomainsResult
     */
    dns_records?: Array<MonitEntityAssetsDomainDnsRecord>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    report_status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    first_detected?: string;
    /**
     * 
     * @type {Array<MonitEntityAssetDomainBlocklist>}
     * @memberof MonitEntityAssetsDomainsResult
     */
    blocklists?: Array<MonitEntityAssetDomainBlocklist>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityAssetsDomainsResult
     */
    findings_count?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetsDomainsResult
     */
    max_level?: string;
}

/**
 * Check if a given object implements the MonitEntityAssetsDomainsResult interface.
 */
export function instanceOfMonitEntityAssetsDomainsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityAssetsDomainsResultFromJSON(json: any): MonitEntityAssetsDomainsResult {
    return MonitEntityAssetsDomainsResultFromJSONTyped(json, false);
}

export function MonitEntityAssetsDomainsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityAssetsDomainsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'dns_records': !exists(json, 'dns_records') ? undefined : ((json['dns_records'] as Array<any>).map(MonitEntityAssetsDomainDnsRecordFromJSON)),
        'report_status': !exists(json, 'report_status') ? undefined : json['report_status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'first_detected': !exists(json, 'first_detected') ? undefined : json['first_detected'],
        'blocklists': !exists(json, 'blocklists') ? undefined : ((json['blocklists'] as Array<any>).map(MonitEntityAssetDomainBlocklistFromJSON)),
        'findings_count': !exists(json, 'findings_count') ? undefined : json['findings_count'],
        'max_level': !exists(json, 'max_level') ? undefined : json['max_level'],
    };
}

export function MonitEntityAssetsDomainsResultToJSON(value?: MonitEntityAssetsDomainsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_logo_url': value.entity_logo_url,
        'domain': value.domain,
        'source': value.source,
        'source_asset': value.source_asset,
        'ip_addresses': value.ip_addresses,
        'dns_records': value.dns_records === undefined ? undefined : ((value.dns_records as Array<any>).map(MonitEntityAssetsDomainDnsRecordToJSON)),
        'report_status': value.report_status,
        'created': value.created,
        'updated': value.updated,
        'first_detected': value.first_detected,
        'blocklists': value.blocklists === undefined ? undefined : ((value.blocklists as Array<any>).map(MonitEntityAssetDomainBlocklistToJSON)),
        'findings_count': value.findings_count,
        'max_level': value.max_level,
    };
}

