/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
     */
    PersonNm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
     */
    TitleTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
     */
    AverageHoursPerWeekRt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
     */
    CompensationAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp
     */
    EmployeeBenefitsAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'TitleTxt': !exists(json, 'TitleTxt') ? undefined : json['TitleTxt'],
        'AverageHoursPerWeekRt': !exists(json, 'AverageHoursPerWeekRt') ? undefined : json['AverageHoursPerWeekRt'],
        'CompensationAmt': !exists(json, 'CompensationAmt') ? undefined : json['CompensationAmt'],
        'EmployeeBenefitsAmt': !exists(json, 'EmployeeBenefitsAmt') ? undefined : json['EmployeeBenefitsAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'TitleTxt': value.TitleTxt,
        'AverageHoursPerWeekRt': value.AverageHoursPerWeekRt,
        'CompensationAmt': value.CompensationAmt,
        'EmployeeBenefitsAmt': value.EmployeeBenefitsAmt,
    };
}

