/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskPartVII } from './ExecRiskPartVII';
import {
    ExecRiskPartVIIFromJSON,
    ExecRiskPartVIIFromJSONTyped,
    ExecRiskPartVIIToJSON,
} from './ExecRiskPartVII';

/**
 * 
 * @export
 * @interface ExecRiskShortForm5500
 */
export interface ExecRiskShortForm5500 {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_entity_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_initial_filing_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_amended_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_final_filing_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_short_plan_yr_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_5558_application_filed_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_ext_automatic_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_dfvc_program_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_ext_special_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_ext_special_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_eff_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_sponsor_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_sponsor_dfe_dba_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_business_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_care_of_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_rpt_spons_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_rpt_spons_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_rpt_plan_num?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_partcp_boy_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_act_rtd_sep_benef_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_partcp_account_bal_cnt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_eligible_assets_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_iqpa_waiver_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_liabilities_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_net_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_liabilities_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_net_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_emplr_contrib_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_particip_contrib_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_oth_contrib_rcvd_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_other_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_distrib_bnft_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_corrective_deemed_distr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_srvc_providers_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_oth_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_net_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_plan_transfers_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_type_pension_bnft_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_type_welfare_bnft_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fail_transmit_contrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_fail_transmit_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_party_in_int_not_rptd_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_party_in_int_not_rptd_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_ins_fdlty_bond_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_ins_fdlty_bond_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_loss_discv_dur_year_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_loss_discv_dur_year_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_broker_fees_paid_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_broker_fees_paid_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fail_provide_benef_due_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_fail_provide_benef_due_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_partcp_loans_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_partcp_loans_eoy_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_blackout_period_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_comply_blackout_notice_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_db_plan_funding_reqd_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_dc_plan_funding_reqd_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_ruling_letter_grant_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_sec_412_req_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_emplr_contrib_paid_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_funding_deficiency_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_funding_deadline_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_res_term_plan_adpt_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_res_term_plan_adpt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_all_plan_ast_distrib_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    filing_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    date_received?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    valid_admin_signature?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    valid_sponsor_signature?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_care_of_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_foreign_prov_stat?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_loc_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_name_same_spon_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_addrss_same_spon_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_firm_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_preparer_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fdcry_trust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fdcry_trust_ein?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_unp_min_cont_cur_yrtot_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_covered_pbgc_insurance_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_act_partcp_boy_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_tot_act_partcp_eoy_cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_sep_partcp_partl_vstd_cnt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_trus_inc_unrel_tax_inc_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_trus_inc_unrel_tax_inc_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fdcry_truste_cust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fdcry_truste_cust_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fdcry_trus_cus_phon_numfore?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_plan_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_satisfy_rqmts_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_adp_acp_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_mthd_used_satisfy_rqmts_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_satisfy_tests_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_timely_amended_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_plan_amendment_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_tax_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_opin_advi_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_opin_advi_serial_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_fav_determ_ltr_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_plan_maintain_us_terri_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_in_service_distrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskShortForm5500
     */
    sf_in_service_distrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_min_req_distrib_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_manual_sign_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_admin_manual_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_design_based_safe_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_prior_year_adp_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_current_year_adp_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_401k_na_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_mthd_ratio_prcnt_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_mthd_avg_bnft_test_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_mthd_na_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_distrib_made_employe_62_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_last_rpt_plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_premium_filing_confirm_no?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_manual_signed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_spons_manual_signed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_pbgc_notified_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_pbgc_notified_explan_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskShortForm5500
     */
    sf_adopted_plan_perm_sec_act?: string;
    /**
     * 
     * @type {ExecRiskPartVII}
     * @memberof ExecRiskShortForm5500
     */
    part7?: ExecRiskPartVII;
}

/**
 * Check if a given object implements the ExecRiskShortForm5500 interface.
 */
export function instanceOfExecRiskShortForm5500(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskShortForm5500FromJSON(json: any): ExecRiskShortForm5500 {
    return ExecRiskShortForm5500FromJSONTyped(json, false);
}

export function ExecRiskShortForm5500FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskShortForm5500 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'sf_plan_year_begin_date': !exists(json, 'sf_plan_year_begin_date') ? undefined : json['sf_plan_year_begin_date'],
        'sf_tax_prd': !exists(json, 'sf_tax_prd') ? undefined : json['sf_tax_prd'],
        'sf_plan_entity_cd': !exists(json, 'sf_plan_entity_cd') ? undefined : json['sf_plan_entity_cd'],
        'sf_initial_filing_ind': !exists(json, 'sf_initial_filing_ind') ? undefined : json['sf_initial_filing_ind'],
        'sf_amended_ind': !exists(json, 'sf_amended_ind') ? undefined : json['sf_amended_ind'],
        'sf_final_filing_ind': !exists(json, 'sf_final_filing_ind') ? undefined : json['sf_final_filing_ind'],
        'sf_short_plan_yr_ind': !exists(json, 'sf_short_plan_yr_ind') ? undefined : json['sf_short_plan_yr_ind'],
        'sf_5558_application_filed_ind': !exists(json, 'sf_5558_application_filed_ind') ? undefined : json['sf_5558_application_filed_ind'],
        'sf_ext_automatic_ind': !exists(json, 'sf_ext_automatic_ind') ? undefined : json['sf_ext_automatic_ind'],
        'sf_dfvc_program_ind': !exists(json, 'sf_dfvc_program_ind') ? undefined : json['sf_dfvc_program_ind'],
        'sf_ext_special_ind': !exists(json, 'sf_ext_special_ind') ? undefined : json['sf_ext_special_ind'],
        'sf_ext_special_text': !exists(json, 'sf_ext_special_text') ? undefined : json['sf_ext_special_text'],
        'sf_plan_name': !exists(json, 'sf_plan_name') ? undefined : json['sf_plan_name'],
        'sf_plan_num': !exists(json, 'sf_plan_num') ? undefined : json['sf_plan_num'],
        'sf_plan_eff_date': !exists(json, 'sf_plan_eff_date') ? undefined : json['sf_plan_eff_date'],
        'sf_sponsor_name': !exists(json, 'sf_sponsor_name') ? undefined : json['sf_sponsor_name'],
        'sf_sponsor_dfe_dba_name': !exists(json, 'sf_sponsor_dfe_dba_name') ? undefined : json['sf_sponsor_dfe_dba_name'],
        'sf_spons_us_address1': !exists(json, 'sf_spons_us_address1') ? undefined : json['sf_spons_us_address1'],
        'sf_spons_us_address2': !exists(json, 'sf_spons_us_address2') ? undefined : json['sf_spons_us_address2'],
        'sf_spons_us_city': !exists(json, 'sf_spons_us_city') ? undefined : json['sf_spons_us_city'],
        'sf_spons_us_state': !exists(json, 'sf_spons_us_state') ? undefined : json['sf_spons_us_state'],
        'sf_spons_us_zip': !exists(json, 'sf_spons_us_zip') ? undefined : json['sf_spons_us_zip'],
        'sf_spons_foreign_address1': !exists(json, 'sf_spons_foreign_address1') ? undefined : json['sf_spons_foreign_address1'],
        'sf_spons_foreign_address2': !exists(json, 'sf_spons_foreign_address2') ? undefined : json['sf_spons_foreign_address2'],
        'sf_spons_foreign_city': !exists(json, 'sf_spons_foreign_city') ? undefined : json['sf_spons_foreign_city'],
        'sf_spons_foreign_prov_state': !exists(json, 'sf_spons_foreign_prov_state') ? undefined : json['sf_spons_foreign_prov_state'],
        'sf_spons_foreign_cntry': !exists(json, 'sf_spons_foreign_cntry') ? undefined : json['sf_spons_foreign_cntry'],
        'sf_spons_foreign_postal_cd': !exists(json, 'sf_spons_foreign_postal_cd') ? undefined : json['sf_spons_foreign_postal_cd'],
        'sf_spons_ein': !exists(json, 'sf_spons_ein') ? undefined : json['sf_spons_ein'],
        'sf_spons_phone_num': !exists(json, 'sf_spons_phone_num') ? undefined : json['sf_spons_phone_num'],
        'sf_business_code': !exists(json, 'sf_business_code') ? undefined : json['sf_business_code'],
        'sf_admin_name': !exists(json, 'sf_admin_name') ? undefined : json['sf_admin_name'],
        'sf_admin_care_of_name': !exists(json, 'sf_admin_care_of_name') ? undefined : json['sf_admin_care_of_name'],
        'sf_admin_us_address1': !exists(json, 'sf_admin_us_address1') ? undefined : json['sf_admin_us_address1'],
        'sf_admin_us_address2': !exists(json, 'sf_admin_us_address2') ? undefined : json['sf_admin_us_address2'],
        'sf_admin_us_city': !exists(json, 'sf_admin_us_city') ? undefined : json['sf_admin_us_city'],
        'sf_admin_us_state': !exists(json, 'sf_admin_us_state') ? undefined : json['sf_admin_us_state'],
        'sf_admin_us_zip': !exists(json, 'sf_admin_us_zip') ? undefined : json['sf_admin_us_zip'],
        'sf_admin_foreign_address1': !exists(json, 'sf_admin_foreign_address1') ? undefined : json['sf_admin_foreign_address1'],
        'sf_admin_foreign_address2': !exists(json, 'sf_admin_foreign_address2') ? undefined : json['sf_admin_foreign_address2'],
        'sf_admin_foreign_city': !exists(json, 'sf_admin_foreign_city') ? undefined : json['sf_admin_foreign_city'],
        'sf_admin_foreign_prov_state': !exists(json, 'sf_admin_foreign_prov_state') ? undefined : json['sf_admin_foreign_prov_state'],
        'sf_admin_foreign_cntry': !exists(json, 'sf_admin_foreign_cntry') ? undefined : json['sf_admin_foreign_cntry'],
        'sf_admin_foreign_postal_cd': !exists(json, 'sf_admin_foreign_postal_cd') ? undefined : json['sf_admin_foreign_postal_cd'],
        'sf_admin_ein': !exists(json, 'sf_admin_ein') ? undefined : json['sf_admin_ein'],
        'sf_admin_phone_num': !exists(json, 'sf_admin_phone_num') ? undefined : json['sf_admin_phone_num'],
        'sf_last_rpt_spons_name': !exists(json, 'sf_last_rpt_spons_name') ? undefined : json['sf_last_rpt_spons_name'],
        'sf_last_rpt_spons_ein': !exists(json, 'sf_last_rpt_spons_ein') ? undefined : json['sf_last_rpt_spons_ein'],
        'sf_last_rpt_plan_num': !exists(json, 'sf_last_rpt_plan_num') ? undefined : json['sf_last_rpt_plan_num'],
        'sf_tot_partcp_boy_cnt': !exists(json, 'sf_tot_partcp_boy_cnt') ? undefined : json['sf_tot_partcp_boy_cnt'],
        'sf_tot_act_rtd_sep_benef_cnt': !exists(json, 'sf_tot_act_rtd_sep_benef_cnt') ? undefined : json['sf_tot_act_rtd_sep_benef_cnt'],
        'sf_partcp_account_bal_cnt': !exists(json, 'sf_partcp_account_bal_cnt') ? undefined : json['sf_partcp_account_bal_cnt'],
        'sf_eligible_assets_ind': !exists(json, 'sf_eligible_assets_ind') ? undefined : json['sf_eligible_assets_ind'],
        'sf_iqpa_waiver_ind': !exists(json, 'sf_iqpa_waiver_ind') ? undefined : json['sf_iqpa_waiver_ind'],
        'sf_tot_assets_boy_amt': !exists(json, 'sf_tot_assets_boy_amt') ? undefined : json['sf_tot_assets_boy_amt'],
        'sf_tot_liabilities_boy_amt': !exists(json, 'sf_tot_liabilities_boy_amt') ? undefined : json['sf_tot_liabilities_boy_amt'],
        'sf_net_assets_boy_amt': !exists(json, 'sf_net_assets_boy_amt') ? undefined : json['sf_net_assets_boy_amt'],
        'sf_tot_assets_eoy_amt': !exists(json, 'sf_tot_assets_eoy_amt') ? undefined : json['sf_tot_assets_eoy_amt'],
        'sf_tot_liabilities_eoy_amt': !exists(json, 'sf_tot_liabilities_eoy_amt') ? undefined : json['sf_tot_liabilities_eoy_amt'],
        'sf_net_assets_eoy_amt': !exists(json, 'sf_net_assets_eoy_amt') ? undefined : json['sf_net_assets_eoy_amt'],
        'sf_emplr_contrib_income_amt': !exists(json, 'sf_emplr_contrib_income_amt') ? undefined : json['sf_emplr_contrib_income_amt'],
        'sf_particip_contrib_income_amt': !exists(json, 'sf_particip_contrib_income_amt') ? undefined : json['sf_particip_contrib_income_amt'],
        'sf_oth_contrib_rcvd_amt': !exists(json, 'sf_oth_contrib_rcvd_amt') ? undefined : json['sf_oth_contrib_rcvd_amt'],
        'sf_other_income_amt': !exists(json, 'sf_other_income_amt') ? undefined : json['sf_other_income_amt'],
        'sf_tot_income_amt': !exists(json, 'sf_tot_income_amt') ? undefined : json['sf_tot_income_amt'],
        'sf_tot_distrib_bnft_amt': !exists(json, 'sf_tot_distrib_bnft_amt') ? undefined : json['sf_tot_distrib_bnft_amt'],
        'sf_corrective_deemed_distr_amt': !exists(json, 'sf_corrective_deemed_distr_amt') ? undefined : json['sf_corrective_deemed_distr_amt'],
        'sf_admin_srvc_providers_amt': !exists(json, 'sf_admin_srvc_providers_amt') ? undefined : json['sf_admin_srvc_providers_amt'],
        'sf_oth_expenses_amt': !exists(json, 'sf_oth_expenses_amt') ? undefined : json['sf_oth_expenses_amt'],
        'sf_tot_expenses_amt': !exists(json, 'sf_tot_expenses_amt') ? undefined : json['sf_tot_expenses_amt'],
        'sf_net_income_amt': !exists(json, 'sf_net_income_amt') ? undefined : json['sf_net_income_amt'],
        'sf_tot_plan_transfers_amt': !exists(json, 'sf_tot_plan_transfers_amt') ? undefined : json['sf_tot_plan_transfers_amt'],
        'sf_type_pension_bnft_code': !exists(json, 'sf_type_pension_bnft_code') ? undefined : json['sf_type_pension_bnft_code'],
        'sf_type_welfare_bnft_code': !exists(json, 'sf_type_welfare_bnft_code') ? undefined : json['sf_type_welfare_bnft_code'],
        'sf_fail_transmit_contrib_ind': !exists(json, 'sf_fail_transmit_contrib_ind') ? undefined : json['sf_fail_transmit_contrib_ind'],
        'sf_fail_transmit_contrib_amt': !exists(json, 'sf_fail_transmit_contrib_amt') ? undefined : json['sf_fail_transmit_contrib_amt'],
        'sf_party_in_int_not_rptd_ind': !exists(json, 'sf_party_in_int_not_rptd_ind') ? undefined : json['sf_party_in_int_not_rptd_ind'],
        'sf_party_in_int_not_rptd_amt': !exists(json, 'sf_party_in_int_not_rptd_amt') ? undefined : json['sf_party_in_int_not_rptd_amt'],
        'sf_plan_ins_fdlty_bond_ind': !exists(json, 'sf_plan_ins_fdlty_bond_ind') ? undefined : json['sf_plan_ins_fdlty_bond_ind'],
        'sf_plan_ins_fdlty_bond_amt': !exists(json, 'sf_plan_ins_fdlty_bond_amt') ? undefined : json['sf_plan_ins_fdlty_bond_amt'],
        'sf_loss_discv_dur_year_ind': !exists(json, 'sf_loss_discv_dur_year_ind') ? undefined : json['sf_loss_discv_dur_year_ind'],
        'sf_loss_discv_dur_year_amt': !exists(json, 'sf_loss_discv_dur_year_amt') ? undefined : json['sf_loss_discv_dur_year_amt'],
        'sf_broker_fees_paid_ind': !exists(json, 'sf_broker_fees_paid_ind') ? undefined : json['sf_broker_fees_paid_ind'],
        'sf_broker_fees_paid_amt': !exists(json, 'sf_broker_fees_paid_amt') ? undefined : json['sf_broker_fees_paid_amt'],
        'sf_fail_provide_benef_due_ind': !exists(json, 'sf_fail_provide_benef_due_ind') ? undefined : json['sf_fail_provide_benef_due_ind'],
        'sf_fail_provide_benef_due_amt': !exists(json, 'sf_fail_provide_benef_due_amt') ? undefined : json['sf_fail_provide_benef_due_amt'],
        'sf_partcp_loans_ind': !exists(json, 'sf_partcp_loans_ind') ? undefined : json['sf_partcp_loans_ind'],
        'sf_partcp_loans_eoy_amt': !exists(json, 'sf_partcp_loans_eoy_amt') ? undefined : json['sf_partcp_loans_eoy_amt'],
        'sf_plan_blackout_period_ind': !exists(json, 'sf_plan_blackout_period_ind') ? undefined : json['sf_plan_blackout_period_ind'],
        'sf_comply_blackout_notice_ind': !exists(json, 'sf_comply_blackout_notice_ind') ? undefined : json['sf_comply_blackout_notice_ind'],
        'sf_db_plan_funding_reqd_ind': !exists(json, 'sf_db_plan_funding_reqd_ind') ? undefined : json['sf_db_plan_funding_reqd_ind'],
        'sf_dc_plan_funding_reqd_ind': !exists(json, 'sf_dc_plan_funding_reqd_ind') ? undefined : json['sf_dc_plan_funding_reqd_ind'],
        'sf_ruling_letter_grant_date': !exists(json, 'sf_ruling_letter_grant_date') ? undefined : json['sf_ruling_letter_grant_date'],
        'sf_sec_412_req_contrib_amt': !exists(json, 'sf_sec_412_req_contrib_amt') ? undefined : json['sf_sec_412_req_contrib_amt'],
        'sf_emplr_contrib_paid_amt': !exists(json, 'sf_emplr_contrib_paid_amt') ? undefined : json['sf_emplr_contrib_paid_amt'],
        'sf_funding_deficiency_amt': !exists(json, 'sf_funding_deficiency_amt') ? undefined : json['sf_funding_deficiency_amt'],
        'sf_funding_deadline_ind': !exists(json, 'sf_funding_deadline_ind') ? undefined : json['sf_funding_deadline_ind'],
        'sf_res_term_plan_adpt_ind': !exists(json, 'sf_res_term_plan_adpt_ind') ? undefined : json['sf_res_term_plan_adpt_ind'],
        'sf_res_term_plan_adpt_amt': !exists(json, 'sf_res_term_plan_adpt_amt') ? undefined : json['sf_res_term_plan_adpt_amt'],
        'sf_all_plan_ast_distrib_ind': !exists(json, 'sf_all_plan_ast_distrib_ind') ? undefined : json['sf_all_plan_ast_distrib_ind'],
        'sf_admin_signed_date': !exists(json, 'sf_admin_signed_date') ? undefined : json['sf_admin_signed_date'],
        'sf_admin_signed_name': !exists(json, 'sf_admin_signed_name') ? undefined : json['sf_admin_signed_name'],
        'sf_spons_signed_date': !exists(json, 'sf_spons_signed_date') ? undefined : json['sf_spons_signed_date'],
        'sf_spons_signed_name': !exists(json, 'sf_spons_signed_name') ? undefined : json['sf_spons_signed_name'],
        'filing_status': !exists(json, 'filing_status') ? undefined : json['filing_status'],
        'date_received': !exists(json, 'date_received') ? undefined : json['date_received'],
        'valid_admin_signature': !exists(json, 'valid_admin_signature') ? undefined : json['valid_admin_signature'],
        'valid_sponsor_signature': !exists(json, 'valid_sponsor_signature') ? undefined : json['valid_sponsor_signature'],
        'sf_admin_phone_num_foreign': !exists(json, 'sf_admin_phone_num_foreign') ? undefined : json['sf_admin_phone_num_foreign'],
        'sf_spons_care_of_name': !exists(json, 'sf_spons_care_of_name') ? undefined : json['sf_spons_care_of_name'],
        'sf_spons_loc_foreign_address1': !exists(json, 'sf_spons_loc_foreign_address1') ? undefined : json['sf_spons_loc_foreign_address1'],
        'sf_spons_loc_foreign_address2': !exists(json, 'sf_spons_loc_foreign_address2') ? undefined : json['sf_spons_loc_foreign_address2'],
        'sf_spons_loc_foreign_city': !exists(json, 'sf_spons_loc_foreign_city') ? undefined : json['sf_spons_loc_foreign_city'],
        'sf_spons_loc_foreign_cntry': !exists(json, 'sf_spons_loc_foreign_cntry') ? undefined : json['sf_spons_loc_foreign_cntry'],
        'sf_spons_loc_foreign_postal_cd': !exists(json, 'sf_spons_loc_foreign_postal_cd') ? undefined : json['sf_spons_loc_foreign_postal_cd'],
        'sf_spons_loc_foreign_prov_stat': !exists(json, 'sf_spons_loc_foreign_prov_stat') ? undefined : json['sf_spons_loc_foreign_prov_stat'],
        'sf_spons_loc_us_address1': !exists(json, 'sf_spons_loc_us_address1') ? undefined : json['sf_spons_loc_us_address1'],
        'sf_spons_loc_us_address2': !exists(json, 'sf_spons_loc_us_address2') ? undefined : json['sf_spons_loc_us_address2'],
        'sf_spons_loc_us_city': !exists(json, 'sf_spons_loc_us_city') ? undefined : json['sf_spons_loc_us_city'],
        'sf_spons_loc_us_state': !exists(json, 'sf_spons_loc_us_state') ? undefined : json['sf_spons_loc_us_state'],
        'sf_spons_loc_us_zip': !exists(json, 'sf_spons_loc_us_zip') ? undefined : json['sf_spons_loc_us_zip'],
        'sf_spons_phone_num_foreign': !exists(json, 'sf_spons_phone_num_foreign') ? undefined : json['sf_spons_phone_num_foreign'],
        'sf_admin_name_same_spon_ind': !exists(json, 'sf_admin_name_same_spon_ind') ? undefined : json['sf_admin_name_same_spon_ind'],
        'sf_admin_addrss_same_spon_ind': !exists(json, 'sf_admin_addrss_same_spon_ind') ? undefined : json['sf_admin_addrss_same_spon_ind'],
        'sf_preparer_name': !exists(json, 'sf_preparer_name') ? undefined : json['sf_preparer_name'],
        'sf_preparer_firm_name': !exists(json, 'sf_preparer_firm_name') ? undefined : json['sf_preparer_firm_name'],
        'sf_preparer_us_address1': !exists(json, 'sf_preparer_us_address1') ? undefined : json['sf_preparer_us_address1'],
        'sf_preparer_us_address2': !exists(json, 'sf_preparer_us_address2') ? undefined : json['sf_preparer_us_address2'],
        'sf_preparer_us_city': !exists(json, 'sf_preparer_us_city') ? undefined : json['sf_preparer_us_city'],
        'sf_preparer_us_state': !exists(json, 'sf_preparer_us_state') ? undefined : json['sf_preparer_us_state'],
        'sf_preparer_us_zip': !exists(json, 'sf_preparer_us_zip') ? undefined : json['sf_preparer_us_zip'],
        'sf_preparer_foreign_address1': !exists(json, 'sf_preparer_foreign_address1') ? undefined : json['sf_preparer_foreign_address1'],
        'sf_preparer_foreign_address2': !exists(json, 'sf_preparer_foreign_address2') ? undefined : json['sf_preparer_foreign_address2'],
        'sf_preparer_foreign_city': !exists(json, 'sf_preparer_foreign_city') ? undefined : json['sf_preparer_foreign_city'],
        'sf_preparer_foreign_prov_state': !exists(json, 'sf_preparer_foreign_prov_state') ? undefined : json['sf_preparer_foreign_prov_state'],
        'sf_preparer_foreign_cntry': !exists(json, 'sf_preparer_foreign_cntry') ? undefined : json['sf_preparer_foreign_cntry'],
        'sf_preparer_foreign_postal_cd': !exists(json, 'sf_preparer_foreign_postal_cd') ? undefined : json['sf_preparer_foreign_postal_cd'],
        'sf_preparer_phone_num': !exists(json, 'sf_preparer_phone_num') ? undefined : json['sf_preparer_phone_num'],
        'sf_preparer_phone_num_foreign': !exists(json, 'sf_preparer_phone_num_foreign') ? undefined : json['sf_preparer_phone_num_foreign'],
        'sf_fdcry_trust_name': !exists(json, 'sf_fdcry_trust_name') ? undefined : json['sf_fdcry_trust_name'],
        'sf_fdcry_trust_ein': !exists(json, 'sf_fdcry_trust_ein') ? undefined : json['sf_fdcry_trust_ein'],
        'sf_unp_min_cont_cur_yrtot_amt': !exists(json, 'sf_unp_min_cont_cur_yrtot_amt') ? undefined : json['sf_unp_min_cont_cur_yrtot_amt'],
        'sf_covered_pbgc_insurance_ind': !exists(json, 'sf_covered_pbgc_insurance_ind') ? undefined : json['sf_covered_pbgc_insurance_ind'],
        'sf_tot_act_partcp_boy_cnt': !exists(json, 'sf_tot_act_partcp_boy_cnt') ? undefined : json['sf_tot_act_partcp_boy_cnt'],
        'sf_tot_act_partcp_eoy_cnt': !exists(json, 'sf_tot_act_partcp_eoy_cnt') ? undefined : json['sf_tot_act_partcp_eoy_cnt'],
        'sf_sep_partcp_partl_vstd_cnt': !exists(json, 'sf_sep_partcp_partl_vstd_cnt') ? undefined : json['sf_sep_partcp_partl_vstd_cnt'],
        'sf_trus_inc_unrel_tax_inc_ind': !exists(json, 'sf_trus_inc_unrel_tax_inc_ind') ? undefined : json['sf_trus_inc_unrel_tax_inc_ind'],
        'sf_trus_inc_unrel_tax_inc_amt': !exists(json, 'sf_trus_inc_unrel_tax_inc_amt') ? undefined : json['sf_trus_inc_unrel_tax_inc_amt'],
        'sf_fdcry_truste_cust_name': !exists(json, 'sf_fdcry_truste_cust_name') ? undefined : json['sf_fdcry_truste_cust_name'],
        'sf_fdcry_truste_cust_phone_num': !exists(json, 'sf_fdcry_truste_cust_phone_num') ? undefined : json['sf_fdcry_truste_cust_phone_num'],
        'sf_fdcry_trus_cus_phon_numfore': !exists(json, 'sf_fdcry_trus_cus_phon_numfore') ? undefined : json['sf_fdcry_trus_cus_phon_numfore'],
        'sf_401k_plan_ind': !exists(json, 'sf_401k_plan_ind') ? undefined : json['sf_401k_plan_ind'],
        'sf_401k_satisfy_rqmts_ind': !exists(json, 'sf_401k_satisfy_rqmts_ind') ? undefined : json['sf_401k_satisfy_rqmts_ind'],
        'sf_adp_acp_test_ind': !exists(json, 'sf_adp_acp_test_ind') ? undefined : json['sf_adp_acp_test_ind'],
        'sf_mthd_used_satisfy_rqmts_ind': !exists(json, 'sf_mthd_used_satisfy_rqmts_ind') ? undefined : json['sf_mthd_used_satisfy_rqmts_ind'],
        'sf_plan_satisfy_tests_ind': !exists(json, 'sf_plan_satisfy_tests_ind') ? undefined : json['sf_plan_satisfy_tests_ind'],
        'sf_plan_timely_amended_ind': !exists(json, 'sf_plan_timely_amended_ind') ? undefined : json['sf_plan_timely_amended_ind'],
        'sf_last_plan_amendment_date': !exists(json, 'sf_last_plan_amendment_date') ? undefined : json['sf_last_plan_amendment_date'],
        'sf_tax_code': !exists(json, 'sf_tax_code') ? undefined : json['sf_tax_code'],
        'sf_last_opin_advi_date': !exists(json, 'sf_last_opin_advi_date') ? undefined : json['sf_last_opin_advi_date'],
        'sf_last_opin_advi_serial_num': !exists(json, 'sf_last_opin_advi_serial_num') ? undefined : json['sf_last_opin_advi_serial_num'],
        'sf_fav_determ_ltr_date': !exists(json, 'sf_fav_determ_ltr_date') ? undefined : json['sf_fav_determ_ltr_date'],
        'sf_plan_maintain_us_terri_ind': !exists(json, 'sf_plan_maintain_us_terri_ind') ? undefined : json['sf_plan_maintain_us_terri_ind'],
        'sf_in_service_distrib_ind': !exists(json, 'sf_in_service_distrib_ind') ? undefined : json['sf_in_service_distrib_ind'],
        'sf_in_service_distrib_amt': !exists(json, 'sf_in_service_distrib_amt') ? undefined : json['sf_in_service_distrib_amt'],
        'sf_min_req_distrib_ind': !exists(json, 'sf_min_req_distrib_ind') ? undefined : json['sf_min_req_distrib_ind'],
        'sf_admin_manual_sign_date': !exists(json, 'sf_admin_manual_sign_date') ? undefined : json['sf_admin_manual_sign_date'],
        'sf_admin_manual_signed_name': !exists(json, 'sf_admin_manual_signed_name') ? undefined : json['sf_admin_manual_signed_name'],
        'sf_401k_design_based_safe_ind': !exists(json, 'sf_401k_design_based_safe_ind') ? undefined : json['sf_401k_design_based_safe_ind'],
        'sf_401k_prior_year_adp_ind': !exists(json, 'sf_401k_prior_year_adp_ind') ? undefined : json['sf_401k_prior_year_adp_ind'],
        'sf_401k_current_year_adp_ind': !exists(json, 'sf_401k_current_year_adp_ind') ? undefined : json['sf_401k_current_year_adp_ind'],
        'sf_401k_na_ind': !exists(json, 'sf_401k_na_ind') ? undefined : json['sf_401k_na_ind'],
        'sf_mthd_ratio_prcnt_test_ind': !exists(json, 'sf_mthd_ratio_prcnt_test_ind') ? undefined : json['sf_mthd_ratio_prcnt_test_ind'],
        'sf_mthd_avg_bnft_test_ind': !exists(json, 'sf_mthd_avg_bnft_test_ind') ? undefined : json['sf_mthd_avg_bnft_test_ind'],
        'sf_mthd_na_ind': !exists(json, 'sf_mthd_na_ind') ? undefined : json['sf_mthd_na_ind'],
        'sf_distrib_made_employe_62_ind': !exists(json, 'sf_distrib_made_employe_62_ind') ? undefined : json['sf_distrib_made_employe_62_ind'],
        'sf_last_rpt_plan_name': !exists(json, 'sf_last_rpt_plan_name') ? undefined : json['sf_last_rpt_plan_name'],
        'sf_premium_filing_confirm_no': !exists(json, 'sf_premium_filing_confirm_no') ? undefined : json['sf_premium_filing_confirm_no'],
        'sf_spons_manual_signed_date': !exists(json, 'sf_spons_manual_signed_date') ? undefined : json['sf_spons_manual_signed_date'],
        'sf_spons_manual_signed_name': !exists(json, 'sf_spons_manual_signed_name') ? undefined : json['sf_spons_manual_signed_name'],
        'sf_pbgc_notified_cd': !exists(json, 'sf_pbgc_notified_cd') ? undefined : json['sf_pbgc_notified_cd'],
        'sf_pbgc_notified_explan_text': !exists(json, 'sf_pbgc_notified_explan_text') ? undefined : json['sf_pbgc_notified_explan_text'],
        'sf_adopted_plan_perm_sec_act': !exists(json, 'sf_adopted_plan_perm_sec_act') ? undefined : json['sf_adopted_plan_perm_sec_act'],
        'part7': !exists(json, 'part7') ? undefined : ExecRiskPartVIIFromJSON(json['part7']),
    };
}

export function ExecRiskShortForm5500ToJSON(value?: ExecRiskShortForm5500 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'sf_plan_year_begin_date': value.sf_plan_year_begin_date,
        'sf_tax_prd': value.sf_tax_prd,
        'sf_plan_entity_cd': value.sf_plan_entity_cd,
        'sf_initial_filing_ind': value.sf_initial_filing_ind,
        'sf_amended_ind': value.sf_amended_ind,
        'sf_final_filing_ind': value.sf_final_filing_ind,
        'sf_short_plan_yr_ind': value.sf_short_plan_yr_ind,
        'sf_5558_application_filed_ind': value.sf_5558_application_filed_ind,
        'sf_ext_automatic_ind': value.sf_ext_automatic_ind,
        'sf_dfvc_program_ind': value.sf_dfvc_program_ind,
        'sf_ext_special_ind': value.sf_ext_special_ind,
        'sf_ext_special_text': value.sf_ext_special_text,
        'sf_plan_name': value.sf_plan_name,
        'sf_plan_num': value.sf_plan_num,
        'sf_plan_eff_date': value.sf_plan_eff_date,
        'sf_sponsor_name': value.sf_sponsor_name,
        'sf_sponsor_dfe_dba_name': value.sf_sponsor_dfe_dba_name,
        'sf_spons_us_address1': value.sf_spons_us_address1,
        'sf_spons_us_address2': value.sf_spons_us_address2,
        'sf_spons_us_city': value.sf_spons_us_city,
        'sf_spons_us_state': value.sf_spons_us_state,
        'sf_spons_us_zip': value.sf_spons_us_zip,
        'sf_spons_foreign_address1': value.sf_spons_foreign_address1,
        'sf_spons_foreign_address2': value.sf_spons_foreign_address2,
        'sf_spons_foreign_city': value.sf_spons_foreign_city,
        'sf_spons_foreign_prov_state': value.sf_spons_foreign_prov_state,
        'sf_spons_foreign_cntry': value.sf_spons_foreign_cntry,
        'sf_spons_foreign_postal_cd': value.sf_spons_foreign_postal_cd,
        'sf_spons_ein': value.sf_spons_ein,
        'sf_spons_phone_num': value.sf_spons_phone_num,
        'sf_business_code': value.sf_business_code,
        'sf_admin_name': value.sf_admin_name,
        'sf_admin_care_of_name': value.sf_admin_care_of_name,
        'sf_admin_us_address1': value.sf_admin_us_address1,
        'sf_admin_us_address2': value.sf_admin_us_address2,
        'sf_admin_us_city': value.sf_admin_us_city,
        'sf_admin_us_state': value.sf_admin_us_state,
        'sf_admin_us_zip': value.sf_admin_us_zip,
        'sf_admin_foreign_address1': value.sf_admin_foreign_address1,
        'sf_admin_foreign_address2': value.sf_admin_foreign_address2,
        'sf_admin_foreign_city': value.sf_admin_foreign_city,
        'sf_admin_foreign_prov_state': value.sf_admin_foreign_prov_state,
        'sf_admin_foreign_cntry': value.sf_admin_foreign_cntry,
        'sf_admin_foreign_postal_cd': value.sf_admin_foreign_postal_cd,
        'sf_admin_ein': value.sf_admin_ein,
        'sf_admin_phone_num': value.sf_admin_phone_num,
        'sf_last_rpt_spons_name': value.sf_last_rpt_spons_name,
        'sf_last_rpt_spons_ein': value.sf_last_rpt_spons_ein,
        'sf_last_rpt_plan_num': value.sf_last_rpt_plan_num,
        'sf_tot_partcp_boy_cnt': value.sf_tot_partcp_boy_cnt,
        'sf_tot_act_rtd_sep_benef_cnt': value.sf_tot_act_rtd_sep_benef_cnt,
        'sf_partcp_account_bal_cnt': value.sf_partcp_account_bal_cnt,
        'sf_eligible_assets_ind': value.sf_eligible_assets_ind,
        'sf_iqpa_waiver_ind': value.sf_iqpa_waiver_ind,
        'sf_tot_assets_boy_amt': value.sf_tot_assets_boy_amt,
        'sf_tot_liabilities_boy_amt': value.sf_tot_liabilities_boy_amt,
        'sf_net_assets_boy_amt': value.sf_net_assets_boy_amt,
        'sf_tot_assets_eoy_amt': value.sf_tot_assets_eoy_amt,
        'sf_tot_liabilities_eoy_amt': value.sf_tot_liabilities_eoy_amt,
        'sf_net_assets_eoy_amt': value.sf_net_assets_eoy_amt,
        'sf_emplr_contrib_income_amt': value.sf_emplr_contrib_income_amt,
        'sf_particip_contrib_income_amt': value.sf_particip_contrib_income_amt,
        'sf_oth_contrib_rcvd_amt': value.sf_oth_contrib_rcvd_amt,
        'sf_other_income_amt': value.sf_other_income_amt,
        'sf_tot_income_amt': value.sf_tot_income_amt,
        'sf_tot_distrib_bnft_amt': value.sf_tot_distrib_bnft_amt,
        'sf_corrective_deemed_distr_amt': value.sf_corrective_deemed_distr_amt,
        'sf_admin_srvc_providers_amt': value.sf_admin_srvc_providers_amt,
        'sf_oth_expenses_amt': value.sf_oth_expenses_amt,
        'sf_tot_expenses_amt': value.sf_tot_expenses_amt,
        'sf_net_income_amt': value.sf_net_income_amt,
        'sf_tot_plan_transfers_amt': value.sf_tot_plan_transfers_amt,
        'sf_type_pension_bnft_code': value.sf_type_pension_bnft_code,
        'sf_type_welfare_bnft_code': value.sf_type_welfare_bnft_code,
        'sf_fail_transmit_contrib_ind': value.sf_fail_transmit_contrib_ind,
        'sf_fail_transmit_contrib_amt': value.sf_fail_transmit_contrib_amt,
        'sf_party_in_int_not_rptd_ind': value.sf_party_in_int_not_rptd_ind,
        'sf_party_in_int_not_rptd_amt': value.sf_party_in_int_not_rptd_amt,
        'sf_plan_ins_fdlty_bond_ind': value.sf_plan_ins_fdlty_bond_ind,
        'sf_plan_ins_fdlty_bond_amt': value.sf_plan_ins_fdlty_bond_amt,
        'sf_loss_discv_dur_year_ind': value.sf_loss_discv_dur_year_ind,
        'sf_loss_discv_dur_year_amt': value.sf_loss_discv_dur_year_amt,
        'sf_broker_fees_paid_ind': value.sf_broker_fees_paid_ind,
        'sf_broker_fees_paid_amt': value.sf_broker_fees_paid_amt,
        'sf_fail_provide_benef_due_ind': value.sf_fail_provide_benef_due_ind,
        'sf_fail_provide_benef_due_amt': value.sf_fail_provide_benef_due_amt,
        'sf_partcp_loans_ind': value.sf_partcp_loans_ind,
        'sf_partcp_loans_eoy_amt': value.sf_partcp_loans_eoy_amt,
        'sf_plan_blackout_period_ind': value.sf_plan_blackout_period_ind,
        'sf_comply_blackout_notice_ind': value.sf_comply_blackout_notice_ind,
        'sf_db_plan_funding_reqd_ind': value.sf_db_plan_funding_reqd_ind,
        'sf_dc_plan_funding_reqd_ind': value.sf_dc_plan_funding_reqd_ind,
        'sf_ruling_letter_grant_date': value.sf_ruling_letter_grant_date,
        'sf_sec_412_req_contrib_amt': value.sf_sec_412_req_contrib_amt,
        'sf_emplr_contrib_paid_amt': value.sf_emplr_contrib_paid_amt,
        'sf_funding_deficiency_amt': value.sf_funding_deficiency_amt,
        'sf_funding_deadline_ind': value.sf_funding_deadline_ind,
        'sf_res_term_plan_adpt_ind': value.sf_res_term_plan_adpt_ind,
        'sf_res_term_plan_adpt_amt': value.sf_res_term_plan_adpt_amt,
        'sf_all_plan_ast_distrib_ind': value.sf_all_plan_ast_distrib_ind,
        'sf_admin_signed_date': value.sf_admin_signed_date,
        'sf_admin_signed_name': value.sf_admin_signed_name,
        'sf_spons_signed_date': value.sf_spons_signed_date,
        'sf_spons_signed_name': value.sf_spons_signed_name,
        'filing_status': value.filing_status,
        'date_received': value.date_received,
        'valid_admin_signature': value.valid_admin_signature,
        'valid_sponsor_signature': value.valid_sponsor_signature,
        'sf_admin_phone_num_foreign': value.sf_admin_phone_num_foreign,
        'sf_spons_care_of_name': value.sf_spons_care_of_name,
        'sf_spons_loc_foreign_address1': value.sf_spons_loc_foreign_address1,
        'sf_spons_loc_foreign_address2': value.sf_spons_loc_foreign_address2,
        'sf_spons_loc_foreign_city': value.sf_spons_loc_foreign_city,
        'sf_spons_loc_foreign_cntry': value.sf_spons_loc_foreign_cntry,
        'sf_spons_loc_foreign_postal_cd': value.sf_spons_loc_foreign_postal_cd,
        'sf_spons_loc_foreign_prov_stat': value.sf_spons_loc_foreign_prov_stat,
        'sf_spons_loc_us_address1': value.sf_spons_loc_us_address1,
        'sf_spons_loc_us_address2': value.sf_spons_loc_us_address2,
        'sf_spons_loc_us_city': value.sf_spons_loc_us_city,
        'sf_spons_loc_us_state': value.sf_spons_loc_us_state,
        'sf_spons_loc_us_zip': value.sf_spons_loc_us_zip,
        'sf_spons_phone_num_foreign': value.sf_spons_phone_num_foreign,
        'sf_admin_name_same_spon_ind': value.sf_admin_name_same_spon_ind,
        'sf_admin_addrss_same_spon_ind': value.sf_admin_addrss_same_spon_ind,
        'sf_preparer_name': value.sf_preparer_name,
        'sf_preparer_firm_name': value.sf_preparer_firm_name,
        'sf_preparer_us_address1': value.sf_preparer_us_address1,
        'sf_preparer_us_address2': value.sf_preparer_us_address2,
        'sf_preparer_us_city': value.sf_preparer_us_city,
        'sf_preparer_us_state': value.sf_preparer_us_state,
        'sf_preparer_us_zip': value.sf_preparer_us_zip,
        'sf_preparer_foreign_address1': value.sf_preparer_foreign_address1,
        'sf_preparer_foreign_address2': value.sf_preparer_foreign_address2,
        'sf_preparer_foreign_city': value.sf_preparer_foreign_city,
        'sf_preparer_foreign_prov_state': value.sf_preparer_foreign_prov_state,
        'sf_preparer_foreign_cntry': value.sf_preparer_foreign_cntry,
        'sf_preparer_foreign_postal_cd': value.sf_preparer_foreign_postal_cd,
        'sf_preparer_phone_num': value.sf_preparer_phone_num,
        'sf_preparer_phone_num_foreign': value.sf_preparer_phone_num_foreign,
        'sf_fdcry_trust_name': value.sf_fdcry_trust_name,
        'sf_fdcry_trust_ein': value.sf_fdcry_trust_ein,
        'sf_unp_min_cont_cur_yrtot_amt': value.sf_unp_min_cont_cur_yrtot_amt,
        'sf_covered_pbgc_insurance_ind': value.sf_covered_pbgc_insurance_ind,
        'sf_tot_act_partcp_boy_cnt': value.sf_tot_act_partcp_boy_cnt,
        'sf_tot_act_partcp_eoy_cnt': value.sf_tot_act_partcp_eoy_cnt,
        'sf_sep_partcp_partl_vstd_cnt': value.sf_sep_partcp_partl_vstd_cnt,
        'sf_trus_inc_unrel_tax_inc_ind': value.sf_trus_inc_unrel_tax_inc_ind,
        'sf_trus_inc_unrel_tax_inc_amt': value.sf_trus_inc_unrel_tax_inc_amt,
        'sf_fdcry_truste_cust_name': value.sf_fdcry_truste_cust_name,
        'sf_fdcry_truste_cust_phone_num': value.sf_fdcry_truste_cust_phone_num,
        'sf_fdcry_trus_cus_phon_numfore': value.sf_fdcry_trus_cus_phon_numfore,
        'sf_401k_plan_ind': value.sf_401k_plan_ind,
        'sf_401k_satisfy_rqmts_ind': value.sf_401k_satisfy_rqmts_ind,
        'sf_adp_acp_test_ind': value.sf_adp_acp_test_ind,
        'sf_mthd_used_satisfy_rqmts_ind': value.sf_mthd_used_satisfy_rqmts_ind,
        'sf_plan_satisfy_tests_ind': value.sf_plan_satisfy_tests_ind,
        'sf_plan_timely_amended_ind': value.sf_plan_timely_amended_ind,
        'sf_last_plan_amendment_date': value.sf_last_plan_amendment_date,
        'sf_tax_code': value.sf_tax_code,
        'sf_last_opin_advi_date': value.sf_last_opin_advi_date,
        'sf_last_opin_advi_serial_num': value.sf_last_opin_advi_serial_num,
        'sf_fav_determ_ltr_date': value.sf_fav_determ_ltr_date,
        'sf_plan_maintain_us_terri_ind': value.sf_plan_maintain_us_terri_ind,
        'sf_in_service_distrib_ind': value.sf_in_service_distrib_ind,
        'sf_in_service_distrib_amt': value.sf_in_service_distrib_amt,
        'sf_min_req_distrib_ind': value.sf_min_req_distrib_ind,
        'sf_admin_manual_sign_date': value.sf_admin_manual_sign_date,
        'sf_admin_manual_signed_name': value.sf_admin_manual_signed_name,
        'sf_401k_design_based_safe_ind': value.sf_401k_design_based_safe_ind,
        'sf_401k_prior_year_adp_ind': value.sf_401k_prior_year_adp_ind,
        'sf_401k_current_year_adp_ind': value.sf_401k_current_year_adp_ind,
        'sf_401k_na_ind': value.sf_401k_na_ind,
        'sf_mthd_ratio_prcnt_test_ind': value.sf_mthd_ratio_prcnt_test_ind,
        'sf_mthd_avg_bnft_test_ind': value.sf_mthd_avg_bnft_test_ind,
        'sf_mthd_na_ind': value.sf_mthd_na_ind,
        'sf_distrib_made_employe_62_ind': value.sf_distrib_made_employe_62_ind,
        'sf_last_rpt_plan_name': value.sf_last_rpt_plan_name,
        'sf_premium_filing_confirm_no': value.sf_premium_filing_confirm_no,
        'sf_spons_manual_signed_date': value.sf_spons_manual_signed_date,
        'sf_spons_manual_signed_name': value.sf_spons_manual_signed_name,
        'sf_pbgc_notified_cd': value.sf_pbgc_notified_cd,
        'sf_pbgc_notified_explan_text': value.sf_pbgc_notified_explan_text,
        'sf_adopted_plan_perm_sec_act': value.sf_adopted_plan_perm_sec_act,
        'part7': ExecRiskPartVIIToJSON(value.part7),
    };
}

