'use client';
import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ExplorerSnackBarProvider } from '@explorer/hooks';
import { ExecRisksProvider } from '@explorer/contexts';
import { en as enForms } from '@explorer/forms/presets/locales/en';
import { useAppLocale } from '@explorer/hooks';
import { en as enTables } from '@explorer/tables/presets/locales/en';
import activeFindings from '@explorer/tables/presets/locales/active-findings.json';
import { ThemeContextProvider } from '@explorer/themes';
import { en } from '@explorer/locales/en';
import { appConfig } from '@explorer/src';
import {
  ClaimContext,
  RiskAssessmentContext,
  SecReviewContext,
  useClaim,
  useRiskAssessmentProvider,
  useSecReviewProvider,
} from '@explorer/src/context';
import { AuthProvider } from '@explorer/stores';
import { LaunchDarklyWrapper } from '../LaunchDarklyWrapper';
import { ExplorerMonitoringWrapper } from '../ExplorerMonitoringWrapper';
import { ExplorerUserProvider } from '../ExplorerUserProvider';
import { ExplorerAPIClientProvider } from '@explorer/query';
import { convertToFlatObject } from '../../helpers/intl/convertToFlatObject';

const isTestEnv = process.env.REACT_ENV === 'test';

const activeFindingsTranslations = convertToFlatObject(
  activeFindings,
  'activeFindings',
);

/**
 * AppProviders
 */
export const AppProviders = memo(
  //
  function AppProviders({ children = null }: AppProvidersProps) {
    const claimProviderValue = useClaim();
    const secReviewProvider = useSecReviewProvider();
    const riskAssessmentProvider = useRiskAssessmentProvider();
    const appLocale = useAppLocale(appConfig);

    return (
      <AuthProvider>
        <ThemeContextProvider appConfig={appConfig}>
          <IntlProvider
            messages={{
              ...en,
              ...enTables,
              ...enForms,
              ...activeFindingsTranslations,
            }}
            locale={appLocale.code}
            defaultLocale={appLocale.code}
          >
            <ExplorerUserProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={appLocale.code}
              >
                <ExplorerSnackBarProvider>
                  <ExplorerMonitoringWrapper>
                    <LaunchDarklyWrapper enabled={!isTestEnv}>
                      <ExecRisksProvider>
                        <ClaimContext.Provider value={claimProviderValue}>
                          <SecReviewContext.Provider value={secReviewProvider}>
                            <RiskAssessmentContext.Provider
                              value={riskAssessmentProvider}
                            >
                              <ExplorerAPIClientProvider>
                                {children}
                              </ExplorerAPIClientProvider>
                            </RiskAssessmentContext.Provider>
                          </SecReviewContext.Provider>
                        </ClaimContext.Provider>
                      </ExecRisksProvider>
                    </LaunchDarklyWrapper>
                  </ExplorerMonitoringWrapper>
                </ExplorerSnackBarProvider>
              </LocalizationProvider>
            </ExplorerUserProvider>
          </IntlProvider>
        </ThemeContextProvider>
      </AuthProvider>
    );
  },
);

AppProviders.displayName = 'AppProviders';

/**
 * AppProvidersProps
 */
export interface AppProvidersProps {
  readonly children: JSX.Element[] | JSX.Element | null;
}
