/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFPathItem } from './CRSFPathItem';
import {
    CRSFPathItemFromJSON,
    CRSFPathItemFromJSONTyped,
    CRSFPathItemToJSON,
} from './CRSFPathItem';

/**
 * 
 * @export
 * @interface CRSFResultItem
 */
export interface CRSFResultItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFResultItem
     */
    IsDB: string;
    /**
     * 
     * @type {Array<CRSFPathItem>}
     * @memberof CRSFResultItem
     */
    Paths: Array<CRSFPathItem>;
    /**
     * 
     * @type {number}
     * @memberof CRSFResultItem
     */
    Spend: number;
}

/**
 * Check if a given object implements the CRSFResultItem interface.
 */
export function instanceOfCRSFResultItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "IsDB" in value;
    isInstance = isInstance && "Paths" in value;
    isInstance = isInstance && "Spend" in value;

    return isInstance;
}

export function CRSFResultItemFromJSON(json: any): CRSFResultItem {
    return CRSFResultItemFromJSONTyped(json, false);
}

export function CRSFResultItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFResultItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IsDB': json['IsDB'],
        'Paths': ((json['Paths'] as Array<any>).map(CRSFPathItemFromJSON)),
        'Spend': json['Spend'],
    };
}

export function CRSFResultItemToJSON(value?: CRSFResultItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IsDB': value.IsDB,
        'Paths': ((value.Paths as Array<any>).map(CRSFPathItemToJSON)),
        'Spend': value.Spend,
    };
}

