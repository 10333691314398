import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Image,
  Menu,
  MenuItem,
  Typography,
} from '@explorer/core';
import { truncateText } from '@explorer/helpers';
import { appRoutes } from '@explorer/src';
import { useAuth, useUser } from '@explorer/stores';
import { useThemeContext } from '@explorer/themes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Link from 'next/link';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';

const useStyles = () =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        coalitionLogo: {
          width: 50,
          cursor: 'pointer',
          userSelect: 'none',
          transition: 'filter 0.2s linear',
          '&:hover': {
            filter: `drop-shadow(0px 0px 2px ${palette.grey[800]})`,
          },
        },
        userAvatar: {
          backgroundColor: palette.green[500],
          color: palette.green[300],
        },
      }),
    { index: 1 },
  )();

export const Navbar = ({ title }: NavbarProps) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const { palette } = useTheme();
  const { activeTheme, activeThemeSet, THEMES } = useThemeContext();
  const { user } = useUser();

  const isDarkMode = useMemo<boolean>(
    () => activeTheme.name.includes('Dark'),
    [activeTheme],
  );

  const handleSwitchChange = useCallback(() => {
    if (isDarkMode) {
      const target: AvailableThemes = activeTheme.name.replace(
        'Dark',
        '',
      ) as AvailableThemes;
      activeThemeSet(THEMES[target]!);
    } else {
      const target: AvailableThemes =
        `${activeTheme.name}Dark` as AvailableThemes;
      activeThemeSet(THEMES[target]!);
    }
  }, [activeTheme, isDarkMode]);

  /**
   * User Menu Controls
   */
  const [anchorUserMenu, setAnchorUserMenu] =
    useState<HTMLButtonElement | null>(null);
  const closeUserMenu = useCallback(() => setAnchorUserMenu(null), []);
  const openUserMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) =>
      setAnchorUserMenu(event.currentTarget),
    [],
  );

  return (
    <AppBar bgColor={palette.primary.dark} elevation={0}>
      <Container py={1.5}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Grid container alignItems="center" width="100%">
              <Grid item>
                <Link href={appRoutes['/']}>
                  <Image
                    src="/img/coalition-outlined-logo-i.png"
                    title="Back to the Main Menu"
                    alt="Coalition Logo"
                    className={classes.coalitionLogo}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="h1"
                  textColor="#fff"
                  ml={2}
                  mb={0.25}
                  label={title ?? 'Coalition Explorer'}
                  noUserSelect
                  style={{ cursor: 'default' }}
                />
              </Grid>
            </Grid>
          </Grid>
          {user && (
            <Grid item>
              <Button
                variant="text"
                color="inherit"
                textColor="#fff"
                fontWeight="normal"
                aria-controls={anchorUserMenu ? 'user-menu-grow' : undefined}
                onClick={openUserMenu}
                endIcon={
                  <Avatar alt={user.name} className={classes.userAvatar}>
                    {user.name[0]}
                  </Avatar>
                }
              >
                Hi, {user.name}
              </Button>
              <Menu
                open={Boolean(anchorUserMenu)}
                anchorEl={anchorUserMenu}
                onClose={closeUserMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                py={1}
                disableScrollLock
              >
                <Box minWidth={210}>
                  {user && (
                    <UserMenuItem
                      label={truncateText(user?.email, 30)}
                      renderIcon={(props) => <AccountCircleIcon {...props} />}
                      onClick={null}
                    />
                  )}
                  <Divider />
                  <UserMenuItem
                    label={`Switch to ${isDarkMode ? 'Light' : 'Dark'} Mode`}
                    onClick={handleSwitchChange}
                    renderIcon={(props) =>
                      isDarkMode ? (
                        <WbSunnyIcon {...props} />
                      ) : (
                        <NightsStayIcon {...props} />
                      )
                    }
                  />
                  <Divider />
                  <UserMenuItem
                    label="Logout"
                    onClick={logout}
                    renderIcon={(props) => <ExitToAppIcon {...props} />}
                  />
                </Box>
              </Menu>
            </Grid>
          )}
        </Grid>
      </Container>
    </AppBar>
  );
};

export interface NavbarProps {
  readonly title?: IntlLabel;
}

const UserMenuItem = ({ label, renderIcon, onClick }: UserMenuItemProps) => {
  const { palette } = useTheme();

  const props = onClick !== null ? { onClick } : { disabled: true };

  return (
    <MenuItem {...props}>
      <Grid container spacing={1} alignItems="center">
        <Grid item mt={0.5}>
          {renderIcon({
            style: { fontSize: 20, color: palette.text.secondary },
          })}
        </Grid>
        <Grid item>
          <Typography variant="bodyS" label={label} color="textSecondary" />
        </Grid>
      </Grid>
    </MenuItem>
  );
};

interface UserMenuItemProps {
  readonly label: IntlLabel;
  readonly renderIcon: (props: any) => ReactNode;
  readonly onClick: null | (() => void);
}
