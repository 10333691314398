/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewStatusType } from './CRSFSecReviewStatusType';
import {
    CRSFSecReviewStatusTypeFromJSON,
    CRSFSecReviewStatusTypeFromJSONTyped,
    CRSFSecReviewStatusTypeToJSON,
} from './CRSFSecReviewStatusType';
import type { CRSFSecReviewTaskType } from './CRSFSecReviewTaskType';
import {
    CRSFSecReviewTaskTypeFromJSON,
    CRSFSecReviewTaskTypeFromJSONTyped,
    CRSFSecReviewTaskTypeToJSON,
} from './CRSFSecReviewTaskType';

/**
 * 
 * @export
 * @interface CRSFSecReviewTaskResponse
 */
export interface CRSFSecReviewTaskResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTaskResponse
     */
    created: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewTaskResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTaskResponse
     */
    parent_id?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CRSFSecReviewTaskResponse
     */
    results?: { [key: string]: object; };
    /**
     * 
     * @type {CRSFSecReviewStatusType}
     * @memberof CRSFSecReviewTaskResponse
     */
    status: CRSFSecReviewStatusType;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTaskResponse
     */
    task_id: string;
    /**
     * 
     * @type {CRSFSecReviewTaskType}
     * @memberof CRSFSecReviewTaskResponse
     */
    task_type: CRSFSecReviewTaskType;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTaskResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTaskResponse interface.
 */
export function instanceOfCRSFSecReviewTaskResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "task_id" in value;
    isInstance = isInstance && "task_type" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFSecReviewTaskResponseFromJSON(json: any): CRSFSecReviewTaskResponse {
    return CRSFSecReviewTaskResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'parent_id': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'results': !exists(json, 'results') ? undefined : json['results'],
        'status': CRSFSecReviewStatusTypeFromJSON(json['status']),
        'task_id': json['task_id'],
        'task_type': CRSFSecReviewTaskTypeFromJSON(json['task_type']),
        'updated': json['updated'],
    };
}

export function CRSFSecReviewTaskResponseToJSON(value?: CRSFSecReviewTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'elapsed': value.elapsed,
        'parent_id': value.parent_id,
        'results': value.results,
        'status': CRSFSecReviewStatusTypeToJSON(value.status),
        'task_id': value.task_id,
        'task_type': CRSFSecReviewTaskTypeToJSON(value.task_type),
        'updated': value.updated,
    };
}

