import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export const useDecodeEncodeQueryParams = <T>(route?: string) => {
  const router = useRouter();
  const { query } = router;
  const decodedQuery = useMemo(() => {
    try {
      return typeof query.w === 'string'
        ? JSON.parse(window.atob(query.w))
        : {};
    } catch (e) {
      return {};
    }
  }, [query.w]);

  const encodedQuery = useCallback((values: T) => {
    return window.btoa(JSON.stringify(values));
  }, []);

  const pushWithEncodedQuery = useCallback(
    (values: T) => {
      const encodedQueryParams = {
        pathname: route ?? router.pathname,
        query: {
          w: encodedQuery(values),
        },
      };
      router.push(encodedQueryParams);
    },
    [encodedQuery, route, router],
  );

  return { decodedQuery, encodedQuery, pushWithEncodedQuery };
};
