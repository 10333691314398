/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewSMB
 */
export interface SecReviewSMB {
    /**
     * 
     * @type {Array<string>}
     * @memberof SecReviewSMB
     */
    smb_dialects?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SecReviewSMB
     */
    potential_os?: string;
}

/**
 * Check if a given object implements the SecReviewSMB interface.
 */
export function instanceOfSecReviewSMB(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewSMBFromJSON(json: any): SecReviewSMB {
    return SecReviewSMBFromJSONTyped(json, false);
}

export function SecReviewSMBFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewSMB {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'smb_dialects': !exists(json, 'smb_dialects') ? undefined : json['smb_dialects'],
        'potential_os': !exists(json, 'potential_os') ? undefined : json['potential_os'],
    };
}

export function SecReviewSMBToJSON(value?: SecReviewSMB | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'smb_dialects': value.smb_dialects,
        'potential_os': value.potential_os,
    };
}

