/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityId } from './EntityId';
import {
    EntityIdFromJSON,
    EntityIdFromJSONTyped,
    EntityIdToJSON,
} from './EntityId';
import type { ExcludeEntityId } from './ExcludeEntityId';
import {
    ExcludeEntityIdFromJSON,
    ExcludeEntityIdFromJSONTyped,
    ExcludeEntityIdToJSON,
} from './ExcludeEntityId';
import type { MonitEntityRelationOrderBy } from './MonitEntityRelationOrderBy';
import {
    MonitEntityRelationOrderByFromJSON,
    MonitEntityRelationOrderByFromJSONTyped,
    MonitEntityRelationOrderByToJSON,
} from './MonitEntityRelationOrderBy';
import type { MonitType } from './MonitType';
import {
    MonitTypeFromJSON,
    MonitTypeFromJSONTyped,
    MonitTypeToJSON,
} from './MonitType';

/**
 * 
 * @export
 * @interface MonitGetEntityRelationQueries
 */
export interface MonitGetEntityRelationQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityRelationQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityRelationQueries
     */
    page_size?: number;
    /**
     * 
     * @type {MonitType}
     * @memberof MonitGetEntityRelationQueries
     */
    monit_type?: MonitType;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityRelationQueries
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityRelationQueries
     */
    entity_domain?: string;
    /**
     * 
     * @type {EntityId}
     * @memberof MonitGetEntityRelationQueries
     */
    entity_id?: EntityId;
    /**
     * 
     * @type {ExcludeEntityId}
     * @memberof MonitGetEntityRelationQueries
     */
    exclude_entity_id?: ExcludeEntityId;
    /**
     * 
     * @type {MonitEntityRelationOrderBy}
     * @memberof MonitGetEntityRelationQueries
     */
    order_by?: MonitEntityRelationOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityRelationQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityRelationQueries interface.
 */
export function instanceOfMonitGetEntityRelationQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityRelationQueriesFromJSON(json: any): MonitGetEntityRelationQueries {
    return MonitGetEntityRelationQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityRelationQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityRelationQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'monit_type': !exists(json, 'monit_type') ? undefined : MonitTypeFromJSON(json['monit_type']),
        'search': !exists(json, 'search') ? undefined : json['search'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_id': !exists(json, 'entity_id') ? undefined : EntityIdFromJSON(json['entity_id']),
        'exclude_entity_id': !exists(json, 'exclude_entity_id') ? undefined : ExcludeEntityIdFromJSON(json['exclude_entity_id']),
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityRelationOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityRelationQueriesToJSON(value?: MonitGetEntityRelationQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'monit_type': MonitTypeToJSON(value.monit_type),
        'search': value.search,
        'entity_domain': value.entity_domain,
        'entity_id': EntityIdToJSON(value.entity_id),
        'exclude_entity_id': ExcludeEntityIdToJSON(value.exclude_entity_id),
        'order_by': MonitEntityRelationOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

