import React from 'react';
import * as yup from 'yup';
import {
  FieldChipsInput,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';

const isEmailValid = (formatMessage) =>
  yup
    .array()
    .required()
    .of(yup.string())
    .test(
      'is-valid-email',
      formatMessage({ id: 'errors.requiredEmail' }),
      (value) => {
        if (!value || !value.length) return true;
        return value.every((v) =>
          yup
            .string()
            .email()
            .isValidSync(v ?? ''),
        );
      },
    );

/**
 * Form Validation Schema
 */
export const validationSchema = (formatMessage) =>
  yup
    .object({
      email: isEmailValid(formatMessage),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  email: {
    formId,
    formik,
    name: 'email',
    renderField: (props) => (
      <FieldChipsInput
        {...props}
        helperText={{ id: 'forms-presets.helper-text.emails' }}
        label={{ id: 'forms-presets.emails' }}
        placeholder="user1@email.com,user2@email.com"
      />
    ),
  },
});
