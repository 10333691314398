/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlAppCoreEnumsAuth0Roles = {
    AccountAdmin: 'Control - Account Admin',
    ExecRisksRole: 'Control - ExecRisks Role',
    InsuranceRole: 'Control - Insurance Role',
    ItSecurity: 'Control - IT/Security',
    MspAnalystRole: 'Control - MSP Analyst role',
    Superuser: 'Control - Superuser',
    ViewerRole: 'Control - Viewer Role'
} as const;
export type ControlAppCoreEnumsAuth0Roles = typeof ControlAppCoreEnumsAuth0Roles[keyof typeof ControlAppCoreEnumsAuth0Roles];


export function ControlAppCoreEnumsAuth0RolesFromJSON(json: any): ControlAppCoreEnumsAuth0Roles {
    return ControlAppCoreEnumsAuth0RolesFromJSONTyped(json, false);
}

export function ControlAppCoreEnumsAuth0RolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppCoreEnumsAuth0Roles {
    return json as ControlAppCoreEnumsAuth0Roles;
}

export function ControlAppCoreEnumsAuth0RolesToJSON(value?: ControlAppCoreEnumsAuth0Roles | null): any {
    return value as any;
}

