/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFSecReviewDecisionTypePlain = {
    Pricing: 'pricing',
    Contingencies: 'contingencies',
    Declines: 'declines'
} as const;
export type CRSFSecReviewDecisionTypePlain = typeof CRSFSecReviewDecisionTypePlain[keyof typeof CRSFSecReviewDecisionTypePlain];


export function CRSFSecReviewDecisionTypePlainFromJSON(json: any): CRSFSecReviewDecisionTypePlain {
    return CRSFSecReviewDecisionTypePlainFromJSONTyped(json, false);
}

export function CRSFSecReviewDecisionTypePlainFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDecisionTypePlain {
    return json as CRSFSecReviewDecisionTypePlain;
}

export function CRSFSecReviewDecisionTypePlainToJSON(value?: CRSFSecReviewDecisionTypePlain | null): any {
    return value as any;
}

