import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import {
  Dialog as CoreDialog,
  DialogProps as CoreDialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  IconButton,
  Button,
  Typography,
} from '@explorer/core';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Dialog
 */
export const Dialog = ({
  open,
  title,
  handleClose,
  closeButton = false,
  closeLabel = { id: 'global.close' },
  maxWidth = 'md',
  children = null,
}: DialogProps) => {
  const { palette } = useTheme();
  return (
    <CoreDialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="subtitleCapsL"
              color="textSecondary"
              textColor={palette.grey[400]}
              label={title || ''}
              noUserSelect
            />
          </Grid>
          <Grid item>
            <Box textAlign="right">
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon style={{ color: palette.grey[400] }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {closeButton && (
          <Button
            variant="text"
            label={closeLabel}
            onClick={handleClose}
            mx={1}
          />
        )}
      </DialogActions>
    </CoreDialog>
  );
};

/**
 * Dialog Props
 */
export interface DialogProps extends Pick<CoreDialogProps, 'maxWidth'> {
  /**
   * `open` - is the dialog open
   */
  open: boolean;
  /**
   * `title` - title of the dialog
   */
  title: IntlLabel;
  /**
   * `handleClose` - function that closes the dialog
   */
  handleClose: () => void;
  /**
   * `closeButton` - enables the close button
   */
  closeButton?: boolean;
  /**
   * `closeLabel` - label of the close button
   */
  closeLabel?: IntlLabel;
  /**
   * `children` - content of the form
   */
  children: ReactNode;
}
