import React from 'react';
import Settings from '@mui/icons-material/Settings';
import { Box, Button } from '@explorer/core';
import { useDialog, useParam } from '@explorer/hooks';
import { useIntl } from 'react-intl';
import { ManageTrialDialog } from '@explorer/tables/presets/tables/TableAdminCompanies/actions/ManageTrialDialog';
import { useTheme } from '@mui/material/styles';
import { isTodayBeforeGivenDate } from '@explorer/helpers';
import { ControlTrial } from '@explorer/open-api-client';

export const ManageTrial = ({
  trialInfo,
  refreshTrialInfo,
}: {
  trialInfo: ControlTrial;
  refreshTrialInfo: () => void;
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const clientId = useParam('cid');

  const isManageTrialEnabled =
    isTodayBeforeGivenDate(trialInfo?.end_date) || trialInfo?.active;

  const {
    open: openManageTrial,
    openDialog: openManageTrialDialog,
    closeDialog: closeManageTrialDialog,
  } = useDialog();

  return (
    isManageTrialEnabled && (
      <Box textAlign="right" pb={1}>
        <Button
          width={150}
          color="secondary"
          bgColor={theme.palette.primary.dark}
          onClick={openManageTrialDialog}
          startIcon={<Settings />}
        >
          {intl.formatMessage({
            id: 'admin.search-companies.manage-trial',
          })}
        </Button>
        <ManageTrialDialog
          key={trialInfo?.end_date + trialInfo?.start_date}
          open={openManageTrial}
          onClose={closeManageTrialDialog}
          item={{ trial: trialInfo, external_id: clientId }}
          refresh={refreshTrialInfo}
        />
      </Box>
    )
  );
};
