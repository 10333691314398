/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationsMessageStatus } from './NotificationsMessageStatus';
import {
    NotificationsMessageStatusFromJSON,
    NotificationsMessageStatusFromJSONTyped,
    NotificationsMessageStatusToJSON,
} from './NotificationsMessageStatus';

/**
 * 
 * @export
 * @interface NotificationsMessage
 */
export interface NotificationsMessage {
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    schedule_name: string;
    /**
     * 
     * @type {NotificationsMessageStatus}
     * @memberof NotificationsMessage
     */
    message_status: NotificationsMessageStatus;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    message_type: string;
    /**
     * 
     * @type {object}
     * @memberof NotificationsMessage
     */
    message_data: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    step_date: string;
    /**
     * 
     * @type {object}
     * @memberof NotificationsMessage
     */
    message_metadata: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessage
     */
    content?: string;
}

/**
 * Check if a given object implements the NotificationsMessage interface.
 */
export function instanceOfNotificationsMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uid" in value;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "schedule_name" in value;
    isInstance = isInstance && "message_status" in value;
    isInstance = isInstance && "message_type" in value;
    isInstance = isInstance && "message_data" in value;
    isInstance = isInstance && "step_date" in value;
    isInstance = isInstance && "message_metadata" in value;

    return isInstance;
}

export function NotificationsMessageFromJSON(json: any): NotificationsMessage {
    return NotificationsMessageFromJSONTyped(json, false);
}

export function NotificationsMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'recipient': json['recipient'],
        'schedule_name': json['schedule_name'],
        'message_status': NotificationsMessageStatusFromJSON(json['message_status']),
        'message_type': json['message_type'],
        'message_data': json['message_data'],
        'step_date': json['step_date'],
        'message_metadata': json['message_metadata'],
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function NotificationsMessageToJSON(value?: NotificationsMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'recipient': value.recipient,
        'schedule_name': value.schedule_name,
        'message_status': NotificationsMessageStatusToJSON(value.message_status),
        'message_type': value.message_type,
        'message_data': value.message_data,
        'step_date': value.step_date,
        'message_metadata': value.message_metadata,
        'content': value.content,
    };
}

