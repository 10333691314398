/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskServiceCodes } from './ExecRiskServiceCodes';
import {
    ExecRiskServiceCodesFromJSON,
    ExecRiskServiceCodesFromJSONTyped,
    ExecRiskServiceCodesToJSON,
} from './ExecRiskServiceCodes';

/**
 * 
 * @export
 * @interface ExecRiskItemII
 */
export interface ExecRiskItemII {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    ack_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemII
     */
    row_order?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_srvc_codes?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_relation?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemII
     */
    provider_other_direct_comp_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_indirect_comp_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    prov_other_elig_ind_comp_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskItemII
     */
    prov_other_tot_ind_comp_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskItemII
     */
    provider_other_amt_formula_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskServiceCodes>}
     * @memberof ExecRiskItemII
     */
    service_codes?: Array<ExecRiskServiceCodes>;
}

/**
 * Check if a given object implements the ExecRiskItemII interface.
 */
export function instanceOfExecRiskItemII(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskItemIIFromJSON(json: any): ExecRiskItemII {
    return ExecRiskItemIIFromJSONTyped(json, false);
}

export function ExecRiskItemIIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskItemII {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'row_order': !exists(json, 'row_order') ? undefined : json['row_order'],
        'provider_other_name': !exists(json, 'provider_other_name') ? undefined : json['provider_other_name'],
        'provider_other_ein': !exists(json, 'provider_other_ein') ? undefined : json['provider_other_ein'],
        'provider_other_us_address1': !exists(json, 'provider_other_us_address1') ? undefined : json['provider_other_us_address1'],
        'provider_other_us_address2': !exists(json, 'provider_other_us_address2') ? undefined : json['provider_other_us_address2'],
        'provider_other_us_city': !exists(json, 'provider_other_us_city') ? undefined : json['provider_other_us_city'],
        'provider_other_us_state': !exists(json, 'provider_other_us_state') ? undefined : json['provider_other_us_state'],
        'provider_other_us_zip': !exists(json, 'provider_other_us_zip') ? undefined : json['provider_other_us_zip'],
        'prov_other_foreign_address1': !exists(json, 'prov_other_foreign_address1') ? undefined : json['prov_other_foreign_address1'],
        'prov_other_foreign_address2': !exists(json, 'prov_other_foreign_address2') ? undefined : json['prov_other_foreign_address2'],
        'prov_other_foreign_city': !exists(json, 'prov_other_foreign_city') ? undefined : json['prov_other_foreign_city'],
        'prov_other_foreign_prov_state': !exists(json, 'prov_other_foreign_prov_state') ? undefined : json['prov_other_foreign_prov_state'],
        'prov_other_foreign_cntry': !exists(json, 'prov_other_foreign_cntry') ? undefined : json['prov_other_foreign_cntry'],
        'prov_other_foreign_postal_cd': !exists(json, 'prov_other_foreign_postal_cd') ? undefined : json['prov_other_foreign_postal_cd'],
        'provider_other_srvc_codes': !exists(json, 'provider_other_srvc_codes') ? undefined : json['provider_other_srvc_codes'],
        'provider_other_relation': !exists(json, 'provider_other_relation') ? undefined : json['provider_other_relation'],
        'provider_other_direct_comp_amt': !exists(json, 'provider_other_direct_comp_amt') ? undefined : json['provider_other_direct_comp_amt'],
        'prov_other_indirect_comp_ind': !exists(json, 'prov_other_indirect_comp_ind') ? undefined : json['prov_other_indirect_comp_ind'],
        'prov_other_elig_ind_comp_ind': !exists(json, 'prov_other_elig_ind_comp_ind') ? undefined : json['prov_other_elig_ind_comp_ind'],
        'prov_other_tot_ind_comp_amt': !exists(json, 'prov_other_tot_ind_comp_amt') ? undefined : json['prov_other_tot_ind_comp_amt'],
        'provider_other_amt_formula_ind': !exists(json, 'provider_other_amt_formula_ind') ? undefined : json['provider_other_amt_formula_ind'],
        'service_codes': !exists(json, 'service_codes') ? undefined : ((json['service_codes'] as Array<any>).map(ExecRiskServiceCodesFromJSON)),
    };
}

export function ExecRiskItemIIToJSON(value?: ExecRiskItemII | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'row_order': value.row_order,
        'provider_other_name': value.provider_other_name,
        'provider_other_ein': value.provider_other_ein,
        'provider_other_us_address1': value.provider_other_us_address1,
        'provider_other_us_address2': value.provider_other_us_address2,
        'provider_other_us_city': value.provider_other_us_city,
        'provider_other_us_state': value.provider_other_us_state,
        'provider_other_us_zip': value.provider_other_us_zip,
        'prov_other_foreign_address1': value.prov_other_foreign_address1,
        'prov_other_foreign_address2': value.prov_other_foreign_address2,
        'prov_other_foreign_city': value.prov_other_foreign_city,
        'prov_other_foreign_prov_state': value.prov_other_foreign_prov_state,
        'prov_other_foreign_cntry': value.prov_other_foreign_cntry,
        'prov_other_foreign_postal_cd': value.prov_other_foreign_postal_cd,
        'provider_other_srvc_codes': value.provider_other_srvc_codes,
        'provider_other_relation': value.provider_other_relation,
        'provider_other_direct_comp_amt': value.provider_other_direct_comp_amt,
        'prov_other_indirect_comp_ind': value.prov_other_indirect_comp_ind,
        'prov_other_elig_ind_comp_ind': value.prov_other_elig_ind_comp_ind,
        'prov_other_tot_ind_comp_amt': value.prov_other_tot_ind_comp_amt,
        'provider_other_amt_formula_ind': value.provider_other_amt_formula_ind,
        'service_codes': value.service_codes === undefined ? undefined : ((value.service_codes as Array<any>).map(ExecRiskServiceCodesToJSON)),
    };
}

