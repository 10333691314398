import React from 'react';
import clsx from 'clsx';
import MuiProgressCircular, {
  CircularProgressProps as MuiProgressCircularProps,
} from '@mui/material/CircularProgress';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const ProgressCircular = withCoreProps<ProgressCircularProps>(
  ({ className, ...props }: React.PropsWithChildren<ProgressCircularProps>) => {
    const clsxClass = clsx(className);

    return <MuiProgressCircular {...props} className={clsxClass} />;
  },
);

export interface ProgressCircularProps
  extends MuiProgressCircularProps,
    CoreProps {}
