import React, { useCallback } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Button } from '@explorer/core';
import { appRoutes } from '@explorer/src';
import { useParam } from '@explorer/hooks';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';

export const ModifyAccount = (props: { clientId?: string }) => {
  const intl = useIntl();
  const theme = useTheme();
  const router = useRouter();
  const clientIdFromParam = useParam('cid');

  const clientId = props.clientId || clientIdFromParam;

  const modifyAccount = useCallback(() => {
    const w = window.btoa(
      JSON.stringify({
        client_id: clientId || '',
      }),
    );
    router.push({
      pathname: appRoutes['/admin-control/modify-client'],
      query: {
        w,
      },
    });
  }, [clientId, router]);

  return (
    <Box textAlign="right" pb={1}>
      <Button
        width={150}
        color="secondary"
        bgColor={theme.palette.primary.dark}
        onClick={modifyAccount}
        startIcon={<PublishIcon />}
      >
        {intl.formatMessage({
          id: 'admin.modify-client',
        })}
      </Button>
    </Box>
  );
};
