/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsMessageType = {
    BrokerSecurityAlert: 'BROKER_SECURITY_ALERT',
    UserSecurityAlert: 'USER_SECURITY_ALERT',
    UserSecurityAlertSingleVuln: 'USER_SECURITY_ALERT_SINGLE_VULN',
    ExecRiskSecurityAlert: 'EXEC_RISK_SECURITY_ALERT',
    RsaAttachment: 'RSA_ATTACHMENT',
    ControlTrialInitial: 'CONTROL_TRIAL_INITIAL',
    ControlTrialReminder: 'CONTROL_TRIAL_REMINDER',
    ControlTrialConfirmation: 'CONTROL_TRIAL_CONFIRMATION',
    ControlTrialOverSoon: 'CONTROL_TRIAL_OVER_SOON',
    ControlTrialUpgraded: 'CONTROL_TRIAL_UPGRADED',
    ControlTrialDowngrade: 'CONTROL_TRIAL_DOWNGRADE',
    ControlAssetRemoval: 'CONTROL_ASSET_REMOVAL',
    ControlRescanCompleted: 'CONTROL_RESCAN_COMPLETED',
    ControlResolutionUpdate: 'CONTROL_RESOLUTION_UPDATE',
    ControlMuteAccepted: 'CONTROL_MUTE_ACCEPTED',
    ControlTrialBrokerInvite: 'CONTROL_TRIAL_BROKER_INVITE',
    ClientChangeRequest: 'CLIENT_CHANGE_REQUEST',
    ClientChangeRequestRejected: 'CLIENT_CHANGE_REQUEST_REJECTED',
    ClientChangeRequestApproved: 'CLIENT_CHANGE_REQUEST_APPROVED',
    ConfirmRegistration: 'CONFIRM_REGISTRATION',
    PasswordReset: 'PASSWORD_RESET',
    PasswordResetComplete: 'PASSWORD_RESET_COMPLETE',
    RegistrationComplete: 'REGISTRATION_COMPLETE',
    SignupAttempt: 'SIGNUP_ATTEMPT',
    InvitationByPh: 'INVITATION_BY_PH',
    InvitationByNonPh: 'INVITATION_BY_NON_PH',
    InvitationExistingUser: 'INVITATION_EXISTING_USER',
    BrokerBouncedAlert: 'BROKER_BOUNCED_ALERT',
    FtfSms: 'FTF_SMS',
    ControlMdrUserAssessment: 'CONTROL_MDR_USER_ASSESSMENT',
    SentinelOneSingleThreat: 'SENTINEL_ONE_SINGLE_THREAT',
    FtfSuspiciousTransaction: 'FTF_SUSPICIOUS_TRANSACTION',
    FtfUserSuspiciousTransaction: 'FTF_USER_SUSPICIOUS_TRANSACTION',
    SentinelOneGroupThreat: 'SENTINEL_ONE_GROUP_THREAT',
    ZeroDayAlert: 'ZERO_DAY_ALERT',
    StripeTrialEnd: 'STRIPE_TRIAL_END',
    RenewalReminder: 'RENEWAL_REMINDER',
    RenewalReminderTechnical: 'RENEWAL_REMINDER_TECHNICAL',
    ShareContingentFindings: 'SHARE_CONTINGENT_FINDINGS',
    SatTrialStart: 'SAT_TRIAL_START',
    SatTrialTryPhishingSimulation: 'SAT_TRIAL_TRY_PHISHING_SIMULATION',
    SatTrialExpiryReminder: 'SAT_TRIAL_EXPIRY_REMINDER',
    SatTrialExpired: 'SAT_TRIAL_EXPIRED'
} as const;
export type NotificationsMessageType = typeof NotificationsMessageType[keyof typeof NotificationsMessageType];


export function NotificationsMessageTypeFromJSON(json: any): NotificationsMessageType {
    return NotificationsMessageTypeFromJSONTyped(json, false);
}

export function NotificationsMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessageType {
    return json as NotificationsMessageType;
}

export function NotificationsMessageTypeToJSON(value?: NotificationsMessageType | null): any {
    return value as any;
}

