/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFScanType = {
    Underwriting: 'underwriting',
    Cra: 'cra',
    Secondary: 'secondary',
    Validation: 'validation'
} as const;
export type CRSFScanType = typeof CRSFScanType[keyof typeof CRSFScanType];


export function CRSFScanTypeFromJSON(json: any): CRSFScanType {
    return CRSFScanTypeFromJSONTyped(json, false);
}

export function CRSFScanTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFScanType {
    return json as CRSFScanType;
}

export function CRSFScanTypeToJSON(value?: CRSFScanType | null): any {
    return value as any;
}

