/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskScheduleSBBase
 */
export interface ExecRiskScheduleSBBase {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pn?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_plan_type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_cnt_partcp_pr_yr_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_value_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_curr_value_ast_01_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actrl_value_ast_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_rtd_partcp_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_rtd_fndng_tgt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_term_partcp_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_term_fndng_tgt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_act_nonvstd_fndng_tgt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_act_vstd_fndng_tgt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_act_partcp_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_liab_act_tot_fndng_tgt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tot_partcp_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tot_fndng_tgt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_plan_at_risk_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tgt_disregard_assump_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tgt_reflect_assump_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_eff_int_rate_prcnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tgt_nrml_cost_01_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_signature_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_name_line?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_firm_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_us_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_us_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_us_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_us_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_us_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_prov_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_cntry?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_foreign_postal_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actry_enrlmt_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_not_reflect_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_carryover_pr_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_fndng_pr_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_carryover_used_pr_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_fndng_used_pr_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_carryover_pr_yr_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_fndng_pr_yr_tot_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_int_pr_yr_prcnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_int_pr_yr_carryover_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_int_pr_yr_pre_fndng_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_contrib_int_prcnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_contrib_int_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_contrib_avail_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_contrib_added_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_carryover_reduction_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_fndng_reduction_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_carryover_boy_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_fndng_boy_tot_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_fndng_tgt_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_adj_fndng_tgt_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pr_yr_fndng_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_ast_less_70_prcnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tot_emplr_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tot_emplee_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_contrib_alloc_pr_yr_01_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_contrib_avoid_restric_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_contrib_alloc_curr_yr_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_fndng_short_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_qrtly_install_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_1st_liquidity_short_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_2nd_liquidity_short_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_3rd_liquidity_short_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_4th_liquidity_short_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_1st_seg_rate_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_2nd_seg_rate_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_3rd_seg_rate_prcnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_yield_curve_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_applicable_month_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_weighted_rtm_age?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_mortality_tbl_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_chg_actrl_assump_curr_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_chg_method_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_sch_active_partcp_rqd_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_alt_fndng_rules_cd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_unpaid_pr_yr_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_contrib_alloc_pr_yr_02_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_unpaid_min_rqd_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tgt_nrml_cost_02_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_short_amortz_outstd_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_short_amortz_inst?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_wvrs_amortz_outstd_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_wvrs_amortz_inst?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_wvr_approved_ltr_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_waived_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_fndng_rqmt_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_offset_carryover_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_offset_pre_fndng_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_offset_bal?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_addl_cash_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_contr_alloc_curr_yr_02_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_exces_contr_curr_yr_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_unp_min_cont_cur_yr_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_unp_min_contrib_all_yr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_acceleration_adj_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_actuary_phone_num_foreign?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_eligible_plan_year_1_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_eligible_plan_year_2_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_eligible_plan_year_3_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_eligible_plan_year_4_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_excess_install_acceler_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_min_req_cont_excess_ast_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_port_prefndng_fndng_car_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_present_val_exces_cont_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_shor_amor_base_sch_elec_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_rtd_vstd_tgt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_term_vstd_fndng_tgt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_tot_vstd_fndng_tgt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_int_prior_year_actual_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_pre_val_cur_pln_yr_acr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleSBBase
     */
    sb_antic_plan_related_exp_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskScheduleSBBase interface.
 */
export function instanceOfExecRiskScheduleSBBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleSBBaseFromJSON(json: any): ExecRiskScheduleSBBase {
    return ExecRiskScheduleSBBaseFromJSONTyped(json, false);
}

export function ExecRiskScheduleSBBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleSBBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'sb_plan_year_begin_date': !exists(json, 'sb_plan_year_begin_date') ? undefined : json['sb_plan_year_begin_date'],
        'sb_tax_prd': !exists(json, 'sb_tax_prd') ? undefined : json['sb_tax_prd'],
        'sb_pn': !exists(json, 'sb_pn') ? undefined : json['sb_pn'],
        'sb_ein': !exists(json, 'sb_ein') ? undefined : json['sb_ein'],
        'sb_plan_type_code': !exists(json, 'sb_plan_type_code') ? undefined : json['sb_plan_type_code'],
        'sb_cnt_partcp_pr_yr_cd': !exists(json, 'sb_cnt_partcp_pr_yr_cd') ? undefined : json['sb_cnt_partcp_pr_yr_cd'],
        'sb_value_date': !exists(json, 'sb_value_date') ? undefined : json['sb_value_date'],
        'sb_curr_value_ast_01_amt': !exists(json, 'sb_curr_value_ast_01_amt') ? undefined : json['sb_curr_value_ast_01_amt'],
        'sb_actrl_value_ast_amt': !exists(json, 'sb_actrl_value_ast_amt') ? undefined : json['sb_actrl_value_ast_amt'],
        'sb_rtd_partcp_cnt': !exists(json, 'sb_rtd_partcp_cnt') ? undefined : json['sb_rtd_partcp_cnt'],
        'sb_rtd_fndng_tgt_amt': !exists(json, 'sb_rtd_fndng_tgt_amt') ? undefined : json['sb_rtd_fndng_tgt_amt'],
        'sb_term_partcp_cnt': !exists(json, 'sb_term_partcp_cnt') ? undefined : json['sb_term_partcp_cnt'],
        'sb_term_fndng_tgt_amt': !exists(json, 'sb_term_fndng_tgt_amt') ? undefined : json['sb_term_fndng_tgt_amt'],
        'sb_act_nonvstd_fndng_tgt_amt': !exists(json, 'sb_act_nonvstd_fndng_tgt_amt') ? undefined : json['sb_act_nonvstd_fndng_tgt_amt'],
        'sb_act_vstd_fndng_tgt_amt': !exists(json, 'sb_act_vstd_fndng_tgt_amt') ? undefined : json['sb_act_vstd_fndng_tgt_amt'],
        'sb_act_partcp_cnt': !exists(json, 'sb_act_partcp_cnt') ? undefined : json['sb_act_partcp_cnt'],
        'sb_liab_act_tot_fndng_tgt_amt': !exists(json, 'sb_liab_act_tot_fndng_tgt_amt') ? undefined : json['sb_liab_act_tot_fndng_tgt_amt'],
        'sb_tot_partcp_cnt': !exists(json, 'sb_tot_partcp_cnt') ? undefined : json['sb_tot_partcp_cnt'],
        'sb_tot_fndng_tgt_amt': !exists(json, 'sb_tot_fndng_tgt_amt') ? undefined : json['sb_tot_fndng_tgt_amt'],
        'sb_plan_at_risk_ind': !exists(json, 'sb_plan_at_risk_ind') ? undefined : json['sb_plan_at_risk_ind'],
        'sb_tgt_disregard_assump_amt': !exists(json, 'sb_tgt_disregard_assump_amt') ? undefined : json['sb_tgt_disregard_assump_amt'],
        'sb_tgt_reflect_assump_amt': !exists(json, 'sb_tgt_reflect_assump_amt') ? undefined : json['sb_tgt_reflect_assump_amt'],
        'sb_eff_int_rate_prcnt': !exists(json, 'sb_eff_int_rate_prcnt') ? undefined : json['sb_eff_int_rate_prcnt'],
        'sb_tgt_nrml_cost_01_amt': !exists(json, 'sb_tgt_nrml_cost_01_amt') ? undefined : json['sb_tgt_nrml_cost_01_amt'],
        'sb_signature_date': !exists(json, 'sb_signature_date') ? undefined : json['sb_signature_date'],
        'sb_actuary_name_line': !exists(json, 'sb_actuary_name_line') ? undefined : json['sb_actuary_name_line'],
        'sb_actuary_firm_name': !exists(json, 'sb_actuary_firm_name') ? undefined : json['sb_actuary_firm_name'],
        'sb_actuary_us_address1': !exists(json, 'sb_actuary_us_address1') ? undefined : json['sb_actuary_us_address1'],
        'sb_actuary_us_address2': !exists(json, 'sb_actuary_us_address2') ? undefined : json['sb_actuary_us_address2'],
        'sb_actuary_us_city': !exists(json, 'sb_actuary_us_city') ? undefined : json['sb_actuary_us_city'],
        'sb_actuary_us_state': !exists(json, 'sb_actuary_us_state') ? undefined : json['sb_actuary_us_state'],
        'sb_actuary_us_zip': !exists(json, 'sb_actuary_us_zip') ? undefined : json['sb_actuary_us_zip'],
        'sb_actuary_foreign_address1': !exists(json, 'sb_actuary_foreign_address1') ? undefined : json['sb_actuary_foreign_address1'],
        'sb_actuary_foreign_address2': !exists(json, 'sb_actuary_foreign_address2') ? undefined : json['sb_actuary_foreign_address2'],
        'sb_actuary_foreign_city': !exists(json, 'sb_actuary_foreign_city') ? undefined : json['sb_actuary_foreign_city'],
        'sb_actuary_foreign_prov_state': !exists(json, 'sb_actuary_foreign_prov_state') ? undefined : json['sb_actuary_foreign_prov_state'],
        'sb_actuary_foreign_cntry': !exists(json, 'sb_actuary_foreign_cntry') ? undefined : json['sb_actuary_foreign_cntry'],
        'sb_actuary_foreign_postal_cd': !exists(json, 'sb_actuary_foreign_postal_cd') ? undefined : json['sb_actuary_foreign_postal_cd'],
        'sb_actuary_phone_num': !exists(json, 'sb_actuary_phone_num') ? undefined : json['sb_actuary_phone_num'],
        'sb_actry_enrlmt_num': !exists(json, 'sb_actry_enrlmt_num') ? undefined : json['sb_actry_enrlmt_num'],
        'sb_actuary_not_reflect_ind': !exists(json, 'sb_actuary_not_reflect_ind') ? undefined : json['sb_actuary_not_reflect_ind'],
        'sb_carryover_pr_yr_amt': !exists(json, 'sb_carryover_pr_yr_amt') ? undefined : json['sb_carryover_pr_yr_amt'],
        'sb_pre_fndng_pr_yr_amt': !exists(json, 'sb_pre_fndng_pr_yr_amt') ? undefined : json['sb_pre_fndng_pr_yr_amt'],
        'sb_carryover_used_pr_yr_amt': !exists(json, 'sb_carryover_used_pr_yr_amt') ? undefined : json['sb_carryover_used_pr_yr_amt'],
        'sb_pre_fndng_used_pr_yr_amt': !exists(json, 'sb_pre_fndng_used_pr_yr_amt') ? undefined : json['sb_pre_fndng_used_pr_yr_amt'],
        'sb_carryover_pr_yr_tot_amt': !exists(json, 'sb_carryover_pr_yr_tot_amt') ? undefined : json['sb_carryover_pr_yr_tot_amt'],
        'sb_pre_fndng_pr_yr_tot_amt': !exists(json, 'sb_pre_fndng_pr_yr_tot_amt') ? undefined : json['sb_pre_fndng_pr_yr_tot_amt'],
        'sb_int_pr_yr_prcnt': !exists(json, 'sb_int_pr_yr_prcnt') ? undefined : json['sb_int_pr_yr_prcnt'],
        'sb_int_pr_yr_carryover_amt': !exists(json, 'sb_int_pr_yr_carryover_amt') ? undefined : json['sb_int_pr_yr_carryover_amt'],
        'sb_int_pr_yr_pre_fndng_amt': !exists(json, 'sb_int_pr_yr_pre_fndng_amt') ? undefined : json['sb_int_pr_yr_pre_fndng_amt'],
        'sb_excess_contrib_amt': !exists(json, 'sb_excess_contrib_amt') ? undefined : json['sb_excess_contrib_amt'],
        'sb_excess_contrib_int_prcnt': !exists(json, 'sb_excess_contrib_int_prcnt') ? undefined : json['sb_excess_contrib_int_prcnt'],
        'sb_excess_contrib_int_amt': !exists(json, 'sb_excess_contrib_int_amt') ? undefined : json['sb_excess_contrib_int_amt'],
        'sb_excess_contrib_avail_amt': !exists(json, 'sb_excess_contrib_avail_amt') ? undefined : json['sb_excess_contrib_avail_amt'],
        'sb_excess_contrib_added_amt': !exists(json, 'sb_excess_contrib_added_amt') ? undefined : json['sb_excess_contrib_added_amt'],
        'sb_carryover_reduction_amt': !exists(json, 'sb_carryover_reduction_amt') ? undefined : json['sb_carryover_reduction_amt'],
        'sb_pre_fndng_reduction_amt': !exists(json, 'sb_pre_fndng_reduction_amt') ? undefined : json['sb_pre_fndng_reduction_amt'],
        'sb_carryover_boy_tot_amt': !exists(json, 'sb_carryover_boy_tot_amt') ? undefined : json['sb_carryover_boy_tot_amt'],
        'sb_pre_fndng_boy_tot_amt': !exists(json, 'sb_pre_fndng_boy_tot_amt') ? undefined : json['sb_pre_fndng_boy_tot_amt'],
        'sb_fndng_tgt_prcnt': !exists(json, 'sb_fndng_tgt_prcnt') ? undefined : json['sb_fndng_tgt_prcnt'],
        'sb_adj_fndng_tgt_prcnt': !exists(json, 'sb_adj_fndng_tgt_prcnt') ? undefined : json['sb_adj_fndng_tgt_prcnt'],
        'sb_pr_yr_fndng_prcnt': !exists(json, 'sb_pr_yr_fndng_prcnt') ? undefined : json['sb_pr_yr_fndng_prcnt'],
        'sb_ast_less_70_prcnt': !exists(json, 'sb_ast_less_70_prcnt') ? undefined : json['sb_ast_less_70_prcnt'],
        'sb_tot_emplr_contrib_amt': !exists(json, 'sb_tot_emplr_contrib_amt') ? undefined : json['sb_tot_emplr_contrib_amt'],
        'sb_tot_emplee_contrib_amt': !exists(json, 'sb_tot_emplee_contrib_amt') ? undefined : json['sb_tot_emplee_contrib_amt'],
        'sb_contrib_alloc_pr_yr_01_amt': !exists(json, 'sb_contrib_alloc_pr_yr_01_amt') ? undefined : json['sb_contrib_alloc_pr_yr_01_amt'],
        'sb_contrib_avoid_restric_amt': !exists(json, 'sb_contrib_avoid_restric_amt') ? undefined : json['sb_contrib_avoid_restric_amt'],
        'sb_contrib_alloc_curr_yr_amt': !exists(json, 'sb_contrib_alloc_curr_yr_amt') ? undefined : json['sb_contrib_alloc_curr_yr_amt'],
        'sb_fndng_short_ind': !exists(json, 'sb_fndng_short_ind') ? undefined : json['sb_fndng_short_ind'],
        'sb_qrtly_install_ind': !exists(json, 'sb_qrtly_install_ind') ? undefined : json['sb_qrtly_install_ind'],
        'sb_1st_liquidity_short_amt': !exists(json, 'sb_1st_liquidity_short_amt') ? undefined : json['sb_1st_liquidity_short_amt'],
        'sb_2nd_liquidity_short_amt': !exists(json, 'sb_2nd_liquidity_short_amt') ? undefined : json['sb_2nd_liquidity_short_amt'],
        'sb_3rd_liquidity_short_amt': !exists(json, 'sb_3rd_liquidity_short_amt') ? undefined : json['sb_3rd_liquidity_short_amt'],
        'sb_4th_liquidity_short_amt': !exists(json, 'sb_4th_liquidity_short_amt') ? undefined : json['sb_4th_liquidity_short_amt'],
        'sb_1st_seg_rate_prcnt': !exists(json, 'sb_1st_seg_rate_prcnt') ? undefined : json['sb_1st_seg_rate_prcnt'],
        'sb_2nd_seg_rate_prcnt': !exists(json, 'sb_2nd_seg_rate_prcnt') ? undefined : json['sb_2nd_seg_rate_prcnt'],
        'sb_3rd_seg_rate_prcnt': !exists(json, 'sb_3rd_seg_rate_prcnt') ? undefined : json['sb_3rd_seg_rate_prcnt'],
        'sb_yield_curve_ind': !exists(json, 'sb_yield_curve_ind') ? undefined : json['sb_yield_curve_ind'],
        'sb_applicable_month_cd': !exists(json, 'sb_applicable_month_cd') ? undefined : json['sb_applicable_month_cd'],
        'sb_weighted_rtm_age': !exists(json, 'sb_weighted_rtm_age') ? undefined : json['sb_weighted_rtm_age'],
        'sb_mortality_tbl_cd': !exists(json, 'sb_mortality_tbl_cd') ? undefined : json['sb_mortality_tbl_cd'],
        'sb_chg_actrl_assump_curr_ind': !exists(json, 'sb_chg_actrl_assump_curr_ind') ? undefined : json['sb_chg_actrl_assump_curr_ind'],
        'sb_chg_method_ind': !exists(json, 'sb_chg_method_ind') ? undefined : json['sb_chg_method_ind'],
        'sb_sch_active_partcp_rqd_ind': !exists(json, 'sb_sch_active_partcp_rqd_ind') ? undefined : json['sb_sch_active_partcp_rqd_ind'],
        'sb_alt_fndng_rules_cd': !exists(json, 'sb_alt_fndng_rules_cd') ? undefined : json['sb_alt_fndng_rules_cd'],
        'sb_unpaid_pr_yr_contrib_amt': !exists(json, 'sb_unpaid_pr_yr_contrib_amt') ? undefined : json['sb_unpaid_pr_yr_contrib_amt'],
        'sb_contrib_alloc_pr_yr_02_amt': !exists(json, 'sb_contrib_alloc_pr_yr_02_amt') ? undefined : json['sb_contrib_alloc_pr_yr_02_amt'],
        'sb_unpaid_min_rqd_tot_amt': !exists(json, 'sb_unpaid_min_rqd_tot_amt') ? undefined : json['sb_unpaid_min_rqd_tot_amt'],
        'sb_tgt_nrml_cost_02_amt': !exists(json, 'sb_tgt_nrml_cost_02_amt') ? undefined : json['sb_tgt_nrml_cost_02_amt'],
        'sb_short_amortz_outstd_amt': !exists(json, 'sb_short_amortz_outstd_amt') ? undefined : json['sb_short_amortz_outstd_amt'],
        'sb_short_amortz_inst': !exists(json, 'sb_short_amortz_inst') ? undefined : json['sb_short_amortz_inst'],
        'sb_wvrs_amortz_outstd_amt': !exists(json, 'sb_wvrs_amortz_outstd_amt') ? undefined : json['sb_wvrs_amortz_outstd_amt'],
        'sb_wvrs_amortz_inst': !exists(json, 'sb_wvrs_amortz_inst') ? undefined : json['sb_wvrs_amortz_inst'],
        'sb_wvr_approved_ltr_date': !exists(json, 'sb_wvr_approved_ltr_date') ? undefined : json['sb_wvr_approved_ltr_date'],
        'sb_waived_amt': !exists(json, 'sb_waived_amt') ? undefined : json['sb_waived_amt'],
        'sb_fndng_rqmt_tot_amt': !exists(json, 'sb_fndng_rqmt_tot_amt') ? undefined : json['sb_fndng_rqmt_tot_amt'],
        'sb_offset_carryover_amt': !exists(json, 'sb_offset_carryover_amt') ? undefined : json['sb_offset_carryover_amt'],
        'sb_offset_pre_fndng_amt': !exists(json, 'sb_offset_pre_fndng_amt') ? undefined : json['sb_offset_pre_fndng_amt'],
        'sb_offset_bal': !exists(json, 'sb_offset_bal') ? undefined : json['sb_offset_bal'],
        'sb_addl_cash_tot_amt': !exists(json, 'sb_addl_cash_tot_amt') ? undefined : json['sb_addl_cash_tot_amt'],
        'sb_contr_alloc_curr_yr_02_amt': !exists(json, 'sb_contr_alloc_curr_yr_02_amt') ? undefined : json['sb_contr_alloc_curr_yr_02_amt'],
        'sb_exces_contr_curr_yr_tot_amt': !exists(json, 'sb_exces_contr_curr_yr_tot_amt') ? undefined : json['sb_exces_contr_curr_yr_tot_amt'],
        'sb_unp_min_cont_cur_yr_tot_amt': !exists(json, 'sb_unp_min_cont_cur_yr_tot_amt') ? undefined : json['sb_unp_min_cont_cur_yr_tot_amt'],
        'sb_unp_min_contrib_all_yr_amt': !exists(json, 'sb_unp_min_contrib_all_yr_amt') ? undefined : json['sb_unp_min_contrib_all_yr_amt'],
        'sb_acceleration_adj_amt': !exists(json, 'sb_acceleration_adj_amt') ? undefined : json['sb_acceleration_adj_amt'],
        'sb_actuary_phone_num_foreign': !exists(json, 'sb_actuary_phone_num_foreign') ? undefined : json['sb_actuary_phone_num_foreign'],
        'sb_eligible_plan_year_1_ind': !exists(json, 'sb_eligible_plan_year_1_ind') ? undefined : json['sb_eligible_plan_year_1_ind'],
        'sb_eligible_plan_year_2_ind': !exists(json, 'sb_eligible_plan_year_2_ind') ? undefined : json['sb_eligible_plan_year_2_ind'],
        'sb_eligible_plan_year_3_ind': !exists(json, 'sb_eligible_plan_year_3_ind') ? undefined : json['sb_eligible_plan_year_3_ind'],
        'sb_eligible_plan_year_4_ind': !exists(json, 'sb_eligible_plan_year_4_ind') ? undefined : json['sb_eligible_plan_year_4_ind'],
        'sb_excess_install_acceler_amt': !exists(json, 'sb_excess_install_acceler_amt') ? undefined : json['sb_excess_install_acceler_amt'],
        'sb_min_req_cont_excess_ast_amt': !exists(json, 'sb_min_req_cont_excess_ast_amt') ? undefined : json['sb_min_req_cont_excess_ast_amt'],
        'sb_port_prefndng_fndng_car_amt': !exists(json, 'sb_port_prefndng_fndng_car_amt') ? undefined : json['sb_port_prefndng_fndng_car_amt'],
        'sb_present_val_exces_cont_amt': !exists(json, 'sb_present_val_exces_cont_amt') ? undefined : json['sb_present_val_exces_cont_amt'],
        'sb_shor_amor_base_sch_elec_ind': !exists(json, 'sb_shor_amor_base_sch_elec_ind') ? undefined : json['sb_shor_amor_base_sch_elec_ind'],
        'sb_rtd_vstd_tgt_amt': !exists(json, 'sb_rtd_vstd_tgt_amt') ? undefined : json['sb_rtd_vstd_tgt_amt'],
        'sb_term_vstd_fndng_tgt_amt': !exists(json, 'sb_term_vstd_fndng_tgt_amt') ? undefined : json['sb_term_vstd_fndng_tgt_amt'],
        'sb_tot_vstd_fndng_tgt_amt': !exists(json, 'sb_tot_vstd_fndng_tgt_amt') ? undefined : json['sb_tot_vstd_fndng_tgt_amt'],
        'sb_int_prior_year_actual_amt': !exists(json, 'sb_int_prior_year_actual_amt') ? undefined : json['sb_int_prior_year_actual_amt'],
        'sb_pre_val_cur_pln_yr_acr_amt': !exists(json, 'sb_pre_val_cur_pln_yr_acr_amt') ? undefined : json['sb_pre_val_cur_pln_yr_acr_amt'],
        'sb_antic_plan_related_exp_amt': !exists(json, 'sb_antic_plan_related_exp_amt') ? undefined : json['sb_antic_plan_related_exp_amt'],
    };
}

export function ExecRiskScheduleSBBaseToJSON(value?: ExecRiskScheduleSBBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'sb_plan_year_begin_date': value.sb_plan_year_begin_date,
        'sb_tax_prd': value.sb_tax_prd,
        'sb_pn': value.sb_pn,
        'sb_ein': value.sb_ein,
        'sb_plan_type_code': value.sb_plan_type_code,
        'sb_cnt_partcp_pr_yr_cd': value.sb_cnt_partcp_pr_yr_cd,
        'sb_value_date': value.sb_value_date,
        'sb_curr_value_ast_01_amt': value.sb_curr_value_ast_01_amt,
        'sb_actrl_value_ast_amt': value.sb_actrl_value_ast_amt,
        'sb_rtd_partcp_cnt': value.sb_rtd_partcp_cnt,
        'sb_rtd_fndng_tgt_amt': value.sb_rtd_fndng_tgt_amt,
        'sb_term_partcp_cnt': value.sb_term_partcp_cnt,
        'sb_term_fndng_tgt_amt': value.sb_term_fndng_tgt_amt,
        'sb_act_nonvstd_fndng_tgt_amt': value.sb_act_nonvstd_fndng_tgt_amt,
        'sb_act_vstd_fndng_tgt_amt': value.sb_act_vstd_fndng_tgt_amt,
        'sb_act_partcp_cnt': value.sb_act_partcp_cnt,
        'sb_liab_act_tot_fndng_tgt_amt': value.sb_liab_act_tot_fndng_tgt_amt,
        'sb_tot_partcp_cnt': value.sb_tot_partcp_cnt,
        'sb_tot_fndng_tgt_amt': value.sb_tot_fndng_tgt_amt,
        'sb_plan_at_risk_ind': value.sb_plan_at_risk_ind,
        'sb_tgt_disregard_assump_amt': value.sb_tgt_disregard_assump_amt,
        'sb_tgt_reflect_assump_amt': value.sb_tgt_reflect_assump_amt,
        'sb_eff_int_rate_prcnt': value.sb_eff_int_rate_prcnt,
        'sb_tgt_nrml_cost_01_amt': value.sb_tgt_nrml_cost_01_amt,
        'sb_signature_date': value.sb_signature_date,
        'sb_actuary_name_line': value.sb_actuary_name_line,
        'sb_actuary_firm_name': value.sb_actuary_firm_name,
        'sb_actuary_us_address1': value.sb_actuary_us_address1,
        'sb_actuary_us_address2': value.sb_actuary_us_address2,
        'sb_actuary_us_city': value.sb_actuary_us_city,
        'sb_actuary_us_state': value.sb_actuary_us_state,
        'sb_actuary_us_zip': value.sb_actuary_us_zip,
        'sb_actuary_foreign_address1': value.sb_actuary_foreign_address1,
        'sb_actuary_foreign_address2': value.sb_actuary_foreign_address2,
        'sb_actuary_foreign_city': value.sb_actuary_foreign_city,
        'sb_actuary_foreign_prov_state': value.sb_actuary_foreign_prov_state,
        'sb_actuary_foreign_cntry': value.sb_actuary_foreign_cntry,
        'sb_actuary_foreign_postal_cd': value.sb_actuary_foreign_postal_cd,
        'sb_actuary_phone_num': value.sb_actuary_phone_num,
        'sb_actry_enrlmt_num': value.sb_actry_enrlmt_num,
        'sb_actuary_not_reflect_ind': value.sb_actuary_not_reflect_ind,
        'sb_carryover_pr_yr_amt': value.sb_carryover_pr_yr_amt,
        'sb_pre_fndng_pr_yr_amt': value.sb_pre_fndng_pr_yr_amt,
        'sb_carryover_used_pr_yr_amt': value.sb_carryover_used_pr_yr_amt,
        'sb_pre_fndng_used_pr_yr_amt': value.sb_pre_fndng_used_pr_yr_amt,
        'sb_carryover_pr_yr_tot_amt': value.sb_carryover_pr_yr_tot_amt,
        'sb_pre_fndng_pr_yr_tot_amt': value.sb_pre_fndng_pr_yr_tot_amt,
        'sb_int_pr_yr_prcnt': value.sb_int_pr_yr_prcnt,
        'sb_int_pr_yr_carryover_amt': value.sb_int_pr_yr_carryover_amt,
        'sb_int_pr_yr_pre_fndng_amt': value.sb_int_pr_yr_pre_fndng_amt,
        'sb_excess_contrib_amt': value.sb_excess_contrib_amt,
        'sb_excess_contrib_int_prcnt': value.sb_excess_contrib_int_prcnt,
        'sb_excess_contrib_int_amt': value.sb_excess_contrib_int_amt,
        'sb_excess_contrib_avail_amt': value.sb_excess_contrib_avail_amt,
        'sb_excess_contrib_added_amt': value.sb_excess_contrib_added_amt,
        'sb_carryover_reduction_amt': value.sb_carryover_reduction_amt,
        'sb_pre_fndng_reduction_amt': value.sb_pre_fndng_reduction_amt,
        'sb_carryover_boy_tot_amt': value.sb_carryover_boy_tot_amt,
        'sb_pre_fndng_boy_tot_amt': value.sb_pre_fndng_boy_tot_amt,
        'sb_fndng_tgt_prcnt': value.sb_fndng_tgt_prcnt,
        'sb_adj_fndng_tgt_prcnt': value.sb_adj_fndng_tgt_prcnt,
        'sb_pr_yr_fndng_prcnt': value.sb_pr_yr_fndng_prcnt,
        'sb_ast_less_70_prcnt': value.sb_ast_less_70_prcnt,
        'sb_tot_emplr_contrib_amt': value.sb_tot_emplr_contrib_amt,
        'sb_tot_emplee_contrib_amt': value.sb_tot_emplee_contrib_amt,
        'sb_contrib_alloc_pr_yr_01_amt': value.sb_contrib_alloc_pr_yr_01_amt,
        'sb_contrib_avoid_restric_amt': value.sb_contrib_avoid_restric_amt,
        'sb_contrib_alloc_curr_yr_amt': value.sb_contrib_alloc_curr_yr_amt,
        'sb_fndng_short_ind': value.sb_fndng_short_ind,
        'sb_qrtly_install_ind': value.sb_qrtly_install_ind,
        'sb_1st_liquidity_short_amt': value.sb_1st_liquidity_short_amt,
        'sb_2nd_liquidity_short_amt': value.sb_2nd_liquidity_short_amt,
        'sb_3rd_liquidity_short_amt': value.sb_3rd_liquidity_short_amt,
        'sb_4th_liquidity_short_amt': value.sb_4th_liquidity_short_amt,
        'sb_1st_seg_rate_prcnt': value.sb_1st_seg_rate_prcnt,
        'sb_2nd_seg_rate_prcnt': value.sb_2nd_seg_rate_prcnt,
        'sb_3rd_seg_rate_prcnt': value.sb_3rd_seg_rate_prcnt,
        'sb_yield_curve_ind': value.sb_yield_curve_ind,
        'sb_applicable_month_cd': value.sb_applicable_month_cd,
        'sb_weighted_rtm_age': value.sb_weighted_rtm_age,
        'sb_mortality_tbl_cd': value.sb_mortality_tbl_cd,
        'sb_chg_actrl_assump_curr_ind': value.sb_chg_actrl_assump_curr_ind,
        'sb_chg_method_ind': value.sb_chg_method_ind,
        'sb_sch_active_partcp_rqd_ind': value.sb_sch_active_partcp_rqd_ind,
        'sb_alt_fndng_rules_cd': value.sb_alt_fndng_rules_cd,
        'sb_unpaid_pr_yr_contrib_amt': value.sb_unpaid_pr_yr_contrib_amt,
        'sb_contrib_alloc_pr_yr_02_amt': value.sb_contrib_alloc_pr_yr_02_amt,
        'sb_unpaid_min_rqd_tot_amt': value.sb_unpaid_min_rqd_tot_amt,
        'sb_tgt_nrml_cost_02_amt': value.sb_tgt_nrml_cost_02_amt,
        'sb_short_amortz_outstd_amt': value.sb_short_amortz_outstd_amt,
        'sb_short_amortz_inst': value.sb_short_amortz_inst,
        'sb_wvrs_amortz_outstd_amt': value.sb_wvrs_amortz_outstd_amt,
        'sb_wvrs_amortz_inst': value.sb_wvrs_amortz_inst,
        'sb_wvr_approved_ltr_date': value.sb_wvr_approved_ltr_date,
        'sb_waived_amt': value.sb_waived_amt,
        'sb_fndng_rqmt_tot_amt': value.sb_fndng_rqmt_tot_amt,
        'sb_offset_carryover_amt': value.sb_offset_carryover_amt,
        'sb_offset_pre_fndng_amt': value.sb_offset_pre_fndng_amt,
        'sb_offset_bal': value.sb_offset_bal,
        'sb_addl_cash_tot_amt': value.sb_addl_cash_tot_amt,
        'sb_contr_alloc_curr_yr_02_amt': value.sb_contr_alloc_curr_yr_02_amt,
        'sb_exces_contr_curr_yr_tot_amt': value.sb_exces_contr_curr_yr_tot_amt,
        'sb_unp_min_cont_cur_yr_tot_amt': value.sb_unp_min_cont_cur_yr_tot_amt,
        'sb_unp_min_contrib_all_yr_amt': value.sb_unp_min_contrib_all_yr_amt,
        'sb_acceleration_adj_amt': value.sb_acceleration_adj_amt,
        'sb_actuary_phone_num_foreign': value.sb_actuary_phone_num_foreign,
        'sb_eligible_plan_year_1_ind': value.sb_eligible_plan_year_1_ind,
        'sb_eligible_plan_year_2_ind': value.sb_eligible_plan_year_2_ind,
        'sb_eligible_plan_year_3_ind': value.sb_eligible_plan_year_3_ind,
        'sb_eligible_plan_year_4_ind': value.sb_eligible_plan_year_4_ind,
        'sb_excess_install_acceler_amt': value.sb_excess_install_acceler_amt,
        'sb_min_req_cont_excess_ast_amt': value.sb_min_req_cont_excess_ast_amt,
        'sb_port_prefndng_fndng_car_amt': value.sb_port_prefndng_fndng_car_amt,
        'sb_present_val_exces_cont_amt': value.sb_present_val_exces_cont_amt,
        'sb_shor_amor_base_sch_elec_ind': value.sb_shor_amor_base_sch_elec_ind,
        'sb_rtd_vstd_tgt_amt': value.sb_rtd_vstd_tgt_amt,
        'sb_term_vstd_fndng_tgt_amt': value.sb_term_vstd_fndng_tgt_amt,
        'sb_tot_vstd_fndng_tgt_amt': value.sb_tot_vstd_fndng_tgt_amt,
        'sb_int_prior_year_actual_amt': value.sb_int_prior_year_actual_amt,
        'sb_pre_val_cur_pln_yr_acr_amt': value.sb_pre_val_cur_pln_yr_acr_amt,
        'sb_antic_plan_related_exp_amt': value.sb_antic_plan_related_exp_amt,
    };
}

