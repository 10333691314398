import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Menu = withCoreProps<MenuProps>(
  ({ children, className, ...props }: PropsWithChildren<MenuProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiMenu {...props} className={clsxClass}>
        {children}
      </MuiMenu>
    );
  },
);

export interface MenuProps extends MuiMenuProps, CoreProps {}
