/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlProgress } from './ControlProgress';
import {
    ControlProgressFromJSON,
    ControlProgressFromJSONTyped,
    ControlProgressToJSON,
} from './ControlProgress';
import type { ControlTaskView } from './ControlTaskView';
import {
    ControlTaskViewFromJSON,
    ControlTaskViewFromJSONTyped,
    ControlTaskViewToJSON,
} from './ControlTaskView';

/**
 * This class represents all the information pertaining to each tier
 * @export
 * @interface ControlTierView
 */
export interface ControlTierView {
    /**
     * 
     * @type {Array<ControlTaskView>}
     * @memberof ControlTierView
     */
    tasks: Array<ControlTaskView>;
    /**
     * 
     * @type {ControlProgress}
     * @memberof ControlTierView
     */
    progress: ControlProgress;
}

/**
 * Check if a given object implements the ControlTierView interface.
 */
export function instanceOfControlTierView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tasks" in value;
    isInstance = isInstance && "progress" in value;

    return isInstance;
}

export function ControlTierViewFromJSON(json: any): ControlTierView {
    return ControlTierViewFromJSONTyped(json, false);
}

export function ControlTierViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTierView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tasks': ((json['tasks'] as Array<any>).map(ControlTaskViewFromJSON)),
        'progress': ControlProgressFromJSON(json['progress']),
    };
}

export function ControlTierViewToJSON(value?: ControlTierView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tasks': ((value.tasks as Array<any>).map(ControlTaskViewToJSON)),
        'progress': ControlProgressToJSON(value.progress),
    };
}

