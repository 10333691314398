/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Datapoint } from './Datapoint';
import {
    DatapointFromJSON,
    DatapointFromJSONTyped,
    DatapointToJSON,
} from './Datapoint';
import type { Level } from './Level';
import {
    LevelFromJSON,
    LevelFromJSONTyped,
    LevelToJSON,
} from './Level';
import type { MonitLocale } from './MonitLocale';
import {
    MonitLocaleFromJSON,
    MonitLocaleFromJSONTyped,
    MonitLocaleToJSON,
} from './MonitLocale';
import type { ResolutionTypes } from './ResolutionTypes';
import {
    ResolutionTypesFromJSON,
    ResolutionTypesFromJSONTyped,
    ResolutionTypesToJSON,
} from './ResolutionTypes';
import type { ScoreCategory } from './ScoreCategory';
import {
    ScoreCategoryFromJSON,
    ScoreCategoryFromJSONTyped,
    ScoreCategoryToJSON,
} from './ScoreCategory';
import type { Search } from './Search';
import {
    SearchFromJSON,
    SearchFromJSONTyped,
    SearchToJSON,
} from './Search';

/**
 * 
 * @export
 * @interface MonitGetEntityFindingsQueries
 */
export interface MonitGetEntityFindingsQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityFindingsQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityFindingsQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    source_asset?: string;
    /**
     * 
     * @type {Level}
     * @memberof MonitGetEntityFindingsQueries
     */
    level?: Level;
    /**
     * 
     * @type {Category}
     * @memberof MonitGetEntityFindingsQueries
     */
    category?: Category;
    /**
     * 
     * @type {ScoreCategory}
     * @memberof MonitGetEntityFindingsQueries
     */
    score_category?: ScoreCategory;
    /**
     * 
     * @type {Search}
     * @memberof MonitGetEntityFindingsQueries
     */
    search?: Search;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitGetEntityFindingsQueries
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Datapoint}
     * @memberof MonitGetEntityFindingsQueries
     */
    datapoint?: Datapoint;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    process_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    shared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    trim?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityFindingsQueries
     */
    filters_only?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    desc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    count_findings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    exclude_removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityFindingsQueries
     */
    exclude_ignored?: boolean;
    /**
     * 
     * @type {ResolutionTypes}
     * @memberof MonitGetEntityFindingsQueries
     */
    resolution_types?: ResolutionTypes;
    /**
     * 
     * @type {MonitLocale}
     * @memberof MonitGetEntityFindingsQueries
     */
    locale?: MonitLocale;
}

/**
 * Check if a given object implements the MonitGetEntityFindingsQueries interface.
 */
export function instanceOfMonitGetEntityFindingsQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityFindingsQueriesFromJSON(json: any): MonitGetEntityFindingsQueries {
    return MonitGetEntityFindingsQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityFindingsQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityFindingsQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'level': !exists(json, 'level') ? undefined : LevelFromJSON(json['level']),
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'score_category': !exists(json, 'score_category') ? undefined : ScoreCategoryFromJSON(json['score_category']),
        'search': !exists(json, 'search') ? undefined : SearchFromJSON(json['search']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'datapoint': !exists(json, 'datapoint') ? undefined : DatapointFromJSON(json['datapoint']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'trim': !exists(json, 'trim') ? undefined : json['trim'],
        'filters_only': !exists(json, 'filters_only') ? undefined : json['filters_only'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'count_findings': !exists(json, 'count_findings') ? undefined : json['count_findings'],
        'exclude_removed': !exists(json, 'exclude_removed') ? undefined : json['exclude_removed'],
        'exclude_ignored': !exists(json, 'exclude_ignored') ? undefined : json['exclude_ignored'],
        'resolution_types': !exists(json, 'resolution_types') ? undefined : ResolutionTypesFromJSON(json['resolution_types']),
        'locale': !exists(json, 'locale') ? undefined : MonitLocaleFromJSON(json['locale']),
    };
}

export function MonitGetEntityFindingsQueriesToJSON(value?: MonitGetEntityFindingsQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'entity_id': value.entity_id,
        'asset': value.asset,
        'source_asset': value.source_asset,
        'level': LevelToJSON(value.level),
        'category': CategoryToJSON(value.category),
        'score_category': ScoreCategoryToJSON(value.score_category),
        'search': SearchToJSON(value.search),
        'tags': value.tags,
        'datapoint': DatapointToJSON(value.datapoint),
        'status': value.status,
        'process_id': value.process_id,
        'shared': value.shared,
        'trim': value.trim,
        'filters_only': value.filters_only,
        'desc': value.desc,
        'count_findings': value.count_findings,
        'exclude_removed': value.exclude_removed,
        'exclude_ignored': value.exclude_ignored,
        'resolution_types': ResolutionTypesToJSON(value.resolution_types),
        'locale': MonitLocaleToJSON(value.locale),
    };
}

