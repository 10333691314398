import React, { useEffect } from 'react';
import { debounce } from 'lodash';

export function useAnchoredMousePosition(args: {
  open: boolean;
  popperRef: React.RefObject<HTMLDivElement>;
  anchorRef: React.RefObject<HTMLButtonElement>;
  closePopup: () => void;
}) {
  const { open, popperRef, anchorRef, closePopup } = args;

  useEffect(() => {
    if (!open) {
      return;
    }

    const checkMousePosition = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      const popperDimensions = popperRef.current?.getBoundingClientRect();
      const anchorDimensions = anchorRef.current?.getBoundingClientRect();

      if (!popperDimensions || !anchorDimensions) {
        return;
      }

      if (
        checkWithinBounds({
          x: clientX,
          y: clientY,
          boundingRects: [popperDimensions, anchorDimensions],
        })
      ) {
        return;
      }

      closePopup();
    };

    /* This will debounce the mousemove event to avoid performance issues, but will trigger at least one check if the mouse continues to move */
    const scheduleCheck = debounce(checkMousePosition, 50, { maxWait: 300 });

    const controller = new AbortController();
    document.addEventListener(
      'mousemove',
      scheduleCheck,

      /* TODO `signal` allows us to easily unsubscribe from the event listener. However, older versions of typescript don't have the latest browser DOM specs, hence we need to force the definition via `as`. Remove once we upgrade to typescript >=v5 */
      {
        passive: true,
        signal: controller.signal,
      } as AddEventListenerOptions,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort();
      scheduleCheck.cancel();
    };
  }, [open]);
}
/**
 * Will check whether a coordinate is within the rectangular bounds that encompasses one or more elements. This is useful for popup context menus that are are offset from the button that triggers them.
 */

export function checkWithinBounds(args: {
  x: number;
  y: number;
  boundingRects: DOMRect[];
}): boolean {
  const { x, y, boundingRects } = args;

  const leftMost = Math.min.apply(
    null,
    boundingRects.map((rect) => rect.left),
  );
  const rightMost = Math.max.apply(
    null,
    boundingRects.map((rect) => rect.right),
  );
  const topMost = Math.min.apply(
    null,
    boundingRects.map((rect) => rect.top),
  );
  const bottomMost = Math.max.apply(
    null,
    boundingRects.map((rect) => rect.bottom),
  );

  const withinBounds =
    x >= leftMost && x <= rightMost && y >= topMost && y <= bottomMost;

  return withinBounds;
}
