import { UseQueryOptions, ApiClientMethods } from '../../types';
import { API_MAPPING } from './mappings';

export const useQueryMiddleware: UseQueryMiddleware = (
  api,
  method,
  endpoint,
) => {
  const { url, auth, prefix } = API_MAPPING[api];

  return {
    url,
    endpoint: `${prefix}${endpoint}`,
    method,
    auth,
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  };
};

type UseQueryMiddleware = (
  api: MonorepoAPIs,
  method: ApiClientMethods,
  endpoint: string,
) => UseQueryOptions;
