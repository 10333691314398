/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityDataleaks } from './ControlEntityDataleaks';
import {
    ControlEntityDataleaksFromJSON,
    ControlEntityDataleaksFromJSONTyped,
    ControlEntityDataleaksToJSON,
} from './ControlEntityDataleaks';
import type { ControlEntitySurfaceAssets } from './ControlEntitySurfaceAssets';
import {
    ControlEntitySurfaceAssetsFromJSON,
    ControlEntitySurfaceAssetsFromJSONTyped,
    ControlEntitySurfaceAssetsToJSON,
} from './ControlEntitySurfaceAssets';
import type { ControlEntitySurfaceType } from './ControlEntitySurfaceType';
import {
    ControlEntitySurfaceTypeFromJSON,
    ControlEntitySurfaceTypeFromJSONTyped,
    ControlEntitySurfaceTypeToJSON,
} from './ControlEntitySurfaceType';

/**
 * 
 * @export
 * @interface ControlEntityStatsSurface
 */
export interface ControlEntityStatsSurface {
    /**
     * 
     * @type {ControlEntitySurfaceType}
     * @memberof ControlEntityStatsSurface
     */
    applications?: ControlEntitySurfaceType;
    /**
     * 
     * @type {ControlEntitySurfaceType}
     * @memberof ControlEntityStatsSurface
     */
    services?: ControlEntitySurfaceType;
    /**
     * 
     * @type {ControlEntitySurfaceType}
     * @memberof ControlEntityStatsSurface
     */
    hosting?: ControlEntitySurfaceType;
    /**
     * 
     * @type {ControlEntitySurfaceAssets}
     * @memberof ControlEntityStatsSurface
     */
    assets?: ControlEntitySurfaceAssets;
    /**
     * 
     * @type {ControlEntityDataleaks}
     * @memberof ControlEntityStatsSurface
     */
    dataleaks?: ControlEntityDataleaks;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsSurface
     */
    homoglyphs?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsSurface
     */
    sinkhole?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsSurface
     */
    torrents?: number;
}

/**
 * Check if a given object implements the ControlEntityStatsSurface interface.
 */
export function instanceOfControlEntityStatsSurface(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityStatsSurfaceFromJSON(json: any): ControlEntityStatsSurface {
    return ControlEntityStatsSurfaceFromJSONTyped(json, false);
}

export function ControlEntityStatsSurfaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsSurface {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : ControlEntitySurfaceTypeFromJSON(json['applications']),
        'services': !exists(json, 'services') ? undefined : ControlEntitySurfaceTypeFromJSON(json['services']),
        'hosting': !exists(json, 'hosting') ? undefined : ControlEntitySurfaceTypeFromJSON(json['hosting']),
        'assets': !exists(json, 'assets') ? undefined : ControlEntitySurfaceAssetsFromJSON(json['assets']),
        'dataleaks': !exists(json, 'dataleaks') ? undefined : ControlEntityDataleaksFromJSON(json['dataleaks']),
        'homoglyphs': !exists(json, 'homoglyphs') ? undefined : json['homoglyphs'],
        'sinkhole': !exists(json, 'sinkhole') ? undefined : json['sinkhole'],
        'torrents': !exists(json, 'torrents') ? undefined : json['torrents'],
    };
}

export function ControlEntityStatsSurfaceToJSON(value?: ControlEntityStatsSurface | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': ControlEntitySurfaceTypeToJSON(value.applications),
        'services': ControlEntitySurfaceTypeToJSON(value.services),
        'hosting': ControlEntitySurfaceTypeToJSON(value.hosting),
        'assets': ControlEntitySurfaceAssetsToJSON(value.assets),
        'dataleaks': ControlEntityDataleaksToJSON(value.dataleaks),
        'homoglyphs': value.homoglyphs,
        'sinkhole': value.sinkhole,
        'torrents': value.torrents,
    };
}

