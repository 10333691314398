import { CurrencyCode, currencyCodeMap } from '@explorer/helpers';
import { createContext, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

const initClaim = null;

export const ClaimContext = createContext<any>(initClaim);

export const useClaim = () => {
  const [claim, claimSet] = useState<any>(initClaim);
  const [claimCoverages, claimCoveragesSet] = useState<any>(initClaim);
  const [claimOptions, claimOptionsSet] = useState<any>(initClaim);
  const [claimEvents, claimEventsSet] = useState<any>(initClaim);
  const [claimLossRuns, claimLossRunsSet] = useState<any>(initClaim);
  const [coaSumsData, coaSumsDataSet] = useState<any>(initClaim);
  const [policy, policySet] = useState<any>(initClaim);
  const [availableContacts, availableContactsSet] = useState<any>(initClaim);
  const [availableVendors, availableVendorsSet] = useState<any>(initClaim);

  const [showContext, showContextSet] = useState<boolean>(true);
  const refreshContext = useCallback(() => {
    showContextSet(false);
    setTimeout(() => showContextSet(true), 0);
  }, []);

  const currencyCode = useMemo<CurrencyCode | undefined>(
    () => _.get(claim, 'desired_policy.currency_code'),
    [claim],
  );

  const currencySymbol = useMemo(() => {
    if (currencyCode === undefined) return '';
    return currencyCodeMap[currencyCode];
  }, [currencyCode]);

  const resetClaim = useCallback(() => {
    if (claim !== null) claimSet(null);
    if (claimCoverages !== null) claimCoveragesSet(null);
    if (claimOptions !== null) claimOptionsSet(null);
    if (claimEvents !== null) claimEventsSet(null);
    if (claimLossRuns !== null) claimLossRunsSet(null);
    if (coaSumsData !== null) coaSumsDataSet(null);
    if (policy !== null) policySet(null);
    if (availableContacts !== null) availableContactsSet(null);
    if (availableVendors !== null) availableVendorsSet(null);
  }, [
    claim,
    claimCoverages,
    claimOptions,
    claimEvents,
    claimLossRuns,
    coaSumsData,
    policy,
    availableContacts,
    availableVendors,
  ]);

  const claimProviderValue = useMemo(() => {
    return {
      resetClaim,
      claim,
      claimSet,
      claimCoverages,
      claimCoveragesSet,
      claimOptions,
      claimOptionsSet,
      claimEvents,
      claimEventsSet,
      claimLossRuns,
      claimLossRunsSet,
      currencyCode,
      currencySymbol,
      coaSumsData,
      coaSumsDataSet,
      policy,
      policySet,
      availableContacts,
      availableContactsSet,
      availableVendors,
      availableVendorsSet,
      refreshContext,
      showContext,
    };
  }, [
    claim,
    claimSet,
    claimCoverages,
    claimCoveragesSet,
    claimOptions,
    claimOptionsSet,
    claimEvents,
    claimEventsSet,
    claimLossRuns,
    claimLossRunsSet,
    coaSumsData,
    coaSumsDataSet,
    policy,
    policySet,
    availableContacts,
    availableContactsSet,
    availableVendors,
    availableVendorsSet,
    refreshContext,
    showContext,
  ]);

  return claimProviderValue;
};
