import React from 'react';
import { TypographyDate, TypographyDateProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellDate
 */
export const CellDate = ({
  variant = 'bodyXS',
  date,
  format,
  msUnix,
}: CellDateProps) => {
  if (!date) return <CellEmpty variant={variant} />;

  return (
    <TypographyDate
      variant={variant}
      date={date}
      format={format}
      msUnix={msUnix}
    />
  );
};

/**
 * CellDate Props
 */
export interface CellDateProps
  extends Pick<TypographyDateProps, 'variant' | 'date' | 'format'> {
  msUnix?: boolean;
}
