/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewItemDomain } from './SecReviewItemDomain';
import {
    SecReviewItemDomainFromJSON,
    SecReviewItemDomainFromJSONTyped,
    SecReviewItemDomainToJSON,
} from './SecReviewItemDomain';
import type { SecReviewItemIP } from './SecReviewItemIP';
import {
    SecReviewItemIPFromJSON,
    SecReviewItemIPFromJSONTyped,
    SecReviewItemIPToJSON,
} from './SecReviewItemIP';
import type { SecReviewSecchecks } from './SecReviewSecchecks';
import {
    SecReviewSecchecksFromJSON,
    SecReviewSecchecksFromJSONTyped,
    SecReviewSecchecksToJSON,
} from './SecReviewSecchecks';
import type { SecReviewTechnology } from './SecReviewTechnology';
import {
    SecReviewTechnologyFromJSON,
    SecReviewTechnologyFromJSONTyped,
    SecReviewTechnologyToJSON,
} from './SecReviewTechnology';

/**
 * 
 * @export
 * @interface SecReviewAggregate
 */
export interface SecReviewAggregate {
    /**
     * 
     * @type {string}
     * @memberof SecReviewAggregate
     */
    scan_type: string;
    /**
     * 
     * @type {Array<SecReviewItemIP>}
     * @memberof SecReviewAggregate
     */
    items_ips: Array<SecReviewItemIP>;
    /**
     * 
     * @type {Array<SecReviewItemDomain>}
     * @memberof SecReviewAggregate
     */
    items_domains: Array<SecReviewItemDomain>;
    /**
     * 
     * @type {Array<SecReviewSecchecks>}
     * @memberof SecReviewAggregate
     */
    secchecks: Array<SecReviewSecchecks>;
    /**
     * 
     * @type {Array<SecReviewTechnology>}
     * @memberof SecReviewAggregate
     */
    technologies: Array<SecReviewTechnology>;
}

/**
 * Check if a given object implements the SecReviewAggregate interface.
 */
export function instanceOfSecReviewAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scan_type" in value;
    isInstance = isInstance && "items_ips" in value;
    isInstance = isInstance && "items_domains" in value;
    isInstance = isInstance && "secchecks" in value;
    isInstance = isInstance && "technologies" in value;

    return isInstance;
}

export function SecReviewAggregateFromJSON(json: any): SecReviewAggregate {
    return SecReviewAggregateFromJSONTyped(json, false);
}

export function SecReviewAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scan_type': json['scan_type'],
        'items_ips': ((json['items_ips'] as Array<any>).map(SecReviewItemIPFromJSON)),
        'items_domains': ((json['items_domains'] as Array<any>).map(SecReviewItemDomainFromJSON)),
        'secchecks': ((json['secchecks'] as Array<any>).map(SecReviewSecchecksFromJSON)),
        'technologies': ((json['technologies'] as Array<any>).map(SecReviewTechnologyFromJSON)),
    };
}

export function SecReviewAggregateToJSON(value?: SecReviewAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scan_type': value.scan_type,
        'items_ips': ((value.items_ips as Array<any>).map(SecReviewItemIPToJSON)),
        'items_domains': ((value.items_domains as Array<any>).map(SecReviewItemDomainToJSON)),
        'secchecks': ((value.secchecks as Array<any>).map(SecReviewSecchecksToJSON)),
        'technologies': ((value.technologies as Array<any>).map(SecReviewTechnologyToJSON)),
    };
}

