/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskZoomInfoCorpHierarchy } from './ExecRiskZoomInfoCorpHierarchy';
import {
    ExecRiskZoomInfoCorpHierarchyFromJSON,
    ExecRiskZoomInfoCorpHierarchyFromJSONTyped,
    ExecRiskZoomInfoCorpHierarchyToJSON,
} from './ExecRiskZoomInfoCorpHierarchy';
import type { ExecRiskZoomInfoFunding } from './ExecRiskZoomInfoFunding';
import {
    ExecRiskZoomInfoFundingFromJSON,
    ExecRiskZoomInfoFundingFromJSONTyped,
    ExecRiskZoomInfoFundingToJSON,
} from './ExecRiskZoomInfoFunding';
import type { ExecRiskZoomInfoOrgChart } from './ExecRiskZoomInfoOrgChart';
import {
    ExecRiskZoomInfoOrgChartFromJSON,
    ExecRiskZoomInfoOrgChartFromJSONTyped,
    ExecRiskZoomInfoOrgChartToJSON,
} from './ExecRiskZoomInfoOrgChart';
import type { Industries } from './Industries';
import {
    IndustriesFromJSON,
    IndustriesFromJSONTyped,
    IndustriesToJSON,
} from './Industries';
import type { NaicsCodes } from './NaicsCodes';
import {
    NaicsCodesFromJSON,
    NaicsCodesFromJSONTyped,
    NaicsCodesToJSON,
} from './NaicsCodes';
import type { PrimaryIndustry } from './PrimaryIndustry';
import {
    PrimaryIndustryFromJSON,
    PrimaryIndustryFromJSONTyped,
    PrimaryIndustryToJSON,
} from './PrimaryIndustry';
import type { RootDomainList } from './RootDomainList';
import {
    RootDomainListFromJSON,
    RootDomainListFromJSONTyped,
    RootDomainListToJSON,
} from './RootDomainList';
import type { SicCodes } from './SicCodes';
import {
    SicCodesFromJSON,
    SicCodesFromJSONTyped,
    SicCodesToJSON,
} from './SicCodes';

/**
 * 
 * @export
 * @interface ExecRiskZoomInfoScanResult
 */
export interface ExecRiskZoomInfoScanResult {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    company_name: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskZoomInfoScanResult
     */
    alexa_rank?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    employee_range?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    logo?: string;
    /**
     * 
     * @type {Industries}
     * @memberof ExecRiskZoomInfoScanResult
     */
    industries?: Industries;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskZoomInfoScanResult
     */
    most_recent_funding_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    most_recent_funding_date?: string;
    /**
     * 
     * @type {PrimaryIndustry}
     * @memberof ExecRiskZoomInfoScanResult
     */
    primary_industry?: PrimaryIndustry;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskZoomInfoScanResult
     */
    total_funding_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    facebook_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    linkedin_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    twitter_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    revenue_range?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskZoomInfoScanResult
     */
    revenue?: number;
    /**
     * 
     * @type {RootDomainList}
     * @memberof ExecRiskZoomInfoScanResult
     */
    root_domain_list?: RootDomainList;
    /**
     * 
     * @type {SicCodes}
     * @memberof ExecRiskZoomInfoScanResult
     */
    sic_codes?: SicCodes;
    /**
     * 
     * @type {NaicsCodes}
     * @memberof ExecRiskZoomInfoScanResult
     */
    naics_codes?: NaicsCodes;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoScanResult
     */
    ticker?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskZoomInfoScanResult
     */
    is_public?: boolean;
    /**
     * 
     * @type {Array<ExecRiskZoomInfoFunding>}
     * @memberof ExecRiskZoomInfoScanResult
     */
    funding: Array<ExecRiskZoomInfoFunding>;
    /**
     * 
     * @type {Array<ExecRiskZoomInfoOrgChart>}
     * @memberof ExecRiskZoomInfoScanResult
     */
    org_chart: Array<ExecRiskZoomInfoOrgChart>;
    /**
     * 
     * @type {Array<ExecRiskZoomInfoCorpHierarchy>}
     * @memberof ExecRiskZoomInfoScanResult
     */
    corp_hierarchy: Array<ExecRiskZoomInfoCorpHierarchy>;
}

/**
 * Check if a given object implements the ExecRiskZoomInfoScanResult interface.
 */
export function instanceOfExecRiskZoomInfoScanResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "funding" in value;
    isInstance = isInstance && "org_chart" in value;
    isInstance = isInstance && "corp_hierarchy" in value;

    return isInstance;
}

export function ExecRiskZoomInfoScanResultFromJSON(json: any): ExecRiskZoomInfoScanResult {
    return ExecRiskZoomInfoScanResultFromJSONTyped(json, false);
}

export function ExecRiskZoomInfoScanResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskZoomInfoScanResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company_name': json['company_name'],
        'alexa_rank': !exists(json, 'alexa_rank') ? undefined : json['alexa_rank'],
        'employee_range': !exists(json, 'employee_range') ? undefined : json['employee_range'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'industries': !exists(json, 'industries') ? undefined : IndustriesFromJSON(json['industries']),
        'most_recent_funding_amount': !exists(json, 'most_recent_funding_amount') ? undefined : json['most_recent_funding_amount'],
        'most_recent_funding_date': !exists(json, 'most_recent_funding_date') ? undefined : json['most_recent_funding_date'],
        'primary_industry': !exists(json, 'primary_industry') ? undefined : PrimaryIndustryFromJSON(json['primary_industry']),
        'total_funding_amount': !exists(json, 'total_funding_amount') ? undefined : json['total_funding_amount'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'facebook_url': !exists(json, 'facebook_url') ? undefined : json['facebook_url'],
        'linkedin_url': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'twitter_url': !exists(json, 'twitter_url') ? undefined : json['twitter_url'],
        'revenue_range': !exists(json, 'revenue_range') ? undefined : json['revenue_range'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'root_domain_list': !exists(json, 'root_domain_list') ? undefined : RootDomainListFromJSON(json['root_domain_list']),
        'sic_codes': !exists(json, 'sic_codes') ? undefined : SicCodesFromJSON(json['sic_codes']),
        'naics_codes': !exists(json, 'naics_codes') ? undefined : NaicsCodesFromJSON(json['naics_codes']),
        'ticker': !exists(json, 'ticker') ? undefined : json['ticker'],
        'is_public': !exists(json, 'is_public') ? undefined : json['is_public'],
        'funding': ((json['funding'] as Array<any>).map(ExecRiskZoomInfoFundingFromJSON)),
        'org_chart': ((json['org_chart'] as Array<any>).map(ExecRiskZoomInfoOrgChartFromJSON)),
        'corp_hierarchy': ((json['corp_hierarchy'] as Array<any>).map(ExecRiskZoomInfoCorpHierarchyFromJSON)),
    };
}

export function ExecRiskZoomInfoScanResultToJSON(value?: ExecRiskZoomInfoScanResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_name': value.company_name,
        'alexa_rank': value.alexa_rank,
        'employee_range': value.employee_range,
        'logo': value.logo,
        'industries': IndustriesToJSON(value.industries),
        'most_recent_funding_amount': value.most_recent_funding_amount,
        'most_recent_funding_date': value.most_recent_funding_date,
        'primary_industry': PrimaryIndustryToJSON(value.primary_industry),
        'total_funding_amount': value.total_funding_amount,
        'website': value.website,
        'facebook_url': value.facebook_url,
        'linkedin_url': value.linkedin_url,
        'twitter_url': value.twitter_url,
        'revenue_range': value.revenue_range,
        'revenue': value.revenue,
        'root_domain_list': RootDomainListToJSON(value.root_domain_list),
        'sic_codes': SicCodesToJSON(value.sic_codes),
        'naics_codes': NaicsCodesToJSON(value.naics_codes),
        'ticker': value.ticker,
        'is_public': value.is_public,
        'funding': ((value.funding as Array<any>).map(ExecRiskZoomInfoFundingToJSON)),
        'org_chart': ((value.org_chart as Array<any>).map(ExecRiskZoomInfoOrgChartToJSON)),
        'corp_hierarchy': ((value.corp_hierarchy as Array<any>).map(ExecRiskZoomInfoCorpHierarchyToJSON)),
    };
}

