/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEvidence } from './ControlEvidence';
import {
    ControlEvidenceFromJSON,
    ControlEvidenceFromJSONTyped,
    ControlEvidenceToJSON,
} from './ControlEvidence';

/**
 * 
 * @export
 * @interface ControlEvidenceCollection
 */
export interface ControlEvidenceCollection {
    /**
     * 
     * @type {Array<ControlEvidence>}
     * @memberof ControlEvidenceCollection
     */
    evidence_list?: Array<ControlEvidence>;
}

/**
 * Check if a given object implements the ControlEvidenceCollection interface.
 */
export function instanceOfControlEvidenceCollection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEvidenceCollectionFromJSON(json: any): ControlEvidenceCollection {
    return ControlEvidenceCollectionFromJSONTyped(json, false);
}

export function ControlEvidenceCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEvidenceCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evidence_list': !exists(json, 'evidence_list') ? undefined : ((json['evidence_list'] as Array<any>).map(ControlEvidenceFromJSON)),
    };
}

export function ControlEvidenceCollectionToJSON(value?: ControlEvidenceCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evidence_list': value.evidence_list === undefined ? undefined : ((value.evidence_list as Array<any>).map(ControlEvidenceToJSON)),
    };
}

