/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewEmailLeakAggregatedEvent } from './CRSFSecReviewEmailLeakAggregatedEvent';
import {
    CRSFSecReviewEmailLeakAggregatedEventFromJSON,
    CRSFSecReviewEmailLeakAggregatedEventFromJSONTyped,
    CRSFSecReviewEmailLeakAggregatedEventToJSON,
} from './CRSFSecReviewEmailLeakAggregatedEvent';

/**
 * 
 * @export
 * @interface CRSFSecReviewEmailLeaksAggregatedResponse
 */
export interface CRSFSecReviewEmailLeaksAggregatedResponse {
    /**
     * 
     * @type {Array<CRSFSecReviewEmailLeakAggregatedEvent>}
     * @memberof CRSFSecReviewEmailLeaksAggregatedResponse
     */
    events: Array<CRSFSecReviewEmailLeakAggregatedEvent>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewEmailLeaksAggregatedResponse
     */
    query: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewEmailLeaksAggregatedResponse
     */
    total: number;
}

/**
 * Check if a given object implements the CRSFSecReviewEmailLeaksAggregatedResponse interface.
 */
export function instanceOfCRSFSecReviewEmailLeaksAggregatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CRSFSecReviewEmailLeaksAggregatedResponseFromJSON(json: any): CRSFSecReviewEmailLeaksAggregatedResponse {
    return CRSFSecReviewEmailLeaksAggregatedResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewEmailLeaksAggregatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewEmailLeaksAggregatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(CRSFSecReviewEmailLeakAggregatedEventFromJSON)),
        'query': json['query'],
        'total': json['total'],
    };
}

export function CRSFSecReviewEmailLeaksAggregatedResponseToJSON(value?: CRSFSecReviewEmailLeaksAggregatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': ((value.events as Array<any>).map(CRSFSecReviewEmailLeakAggregatedEventToJSON)),
        'query': value.query,
        'total': value.total,
    };
}

