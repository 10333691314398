/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlExceptionStatus } from './ControlExceptionStatus';
import {
    ControlExceptionStatusFromJSON,
    ControlExceptionStatusFromJSONTyped,
    ControlExceptionStatusToJSON,
} from './ControlExceptionStatus';

/**
 * 
 * @export
 * @interface ControlIgnoredFindingsResultDetails
 */
export interface ControlIgnoredFindingsResultDetails {
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    reason?: string;
    /**
     * 
     * @type {ControlExceptionStatus}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    status: ControlExceptionStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    assets: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResultDetails
     */
    evidence_url?: string;
}

/**
 * Check if a given object implements the ControlIgnoredFindingsResultDetails interface.
 */
export function instanceOfControlIgnoredFindingsResultDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "assets" in value;

    return isInstance;
}

export function ControlIgnoredFindingsResultDetailsFromJSON(json: any): ControlIgnoredFindingsResultDetails {
    return ControlIgnoredFindingsResultDetailsFromJSONTyped(json, false);
}

export function ControlIgnoredFindingsResultDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlIgnoredFindingsResultDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'status': ControlExceptionStatusFromJSON(json['status']),
        'assets': json['assets'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'evidence_url': !exists(json, 'evidence_url') ? undefined : json['evidence_url'],
    };
}

export function ControlIgnoredFindingsResultDetailsToJSON(value?: ControlIgnoredFindingsResultDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'updated': value.updated,
        'count': value.count,
        'datapoint': value.datapoint,
        'email': value.email,
        'level': value.level,
        'name': value.name,
        'notes': value.notes,
        'reason': value.reason,
        'status': ControlExceptionStatusToJSON(value.status),
        'assets': value.assets,
        'description': value.description,
        'recommendation': value.recommendation,
        'references': value.references,
        'category': value.category,
        'evidence_url': value.evidence_url,
    };
}

