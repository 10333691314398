/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewDecisionsSummary
 */
export interface CRSFSecReviewDecisionsSummary {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    aggr_sec_analysis_comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    aggr_sec_analysis_report?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    decisions_json?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    detailed_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    has_contingencies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    has_declines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    has_pricing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    has_relevant_issues?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    has_warnings?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    warnings?: Array<object>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CRSFSecReviewDecisionsSummary
     */
    wiretapping_technologies?: { [key: string]: Array<string>; };
}

/**
 * Check if a given object implements the CRSFSecReviewDecisionsSummary interface.
 */
export function instanceOfCRSFSecReviewDecisionsSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFSecReviewDecisionsSummaryFromJSON(json: any): CRSFSecReviewDecisionsSummary {
    return CRSFSecReviewDecisionsSummaryFromJSONTyped(json, false);
}

export function CRSFSecReviewDecisionsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewDecisionsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggr_sec_analysis_comment': !exists(json, 'aggr_sec_analysis_comment') ? undefined : json['aggr_sec_analysis_comment'],
        'aggr_sec_analysis_report': !exists(json, 'aggr_sec_analysis_report') ? undefined : json['aggr_sec_analysis_report'],
        'decisions_json': !exists(json, 'decisions_json') ? undefined : json['decisions_json'],
        'detailed_url': !exists(json, 'detailed_url') ? undefined : json['detailed_url'],
        'has_contingencies': !exists(json, 'has_contingencies') ? undefined : json['has_contingencies'],
        'has_declines': !exists(json, 'has_declines') ? undefined : json['has_declines'],
        'has_pricing': !exists(json, 'has_pricing') ? undefined : json['has_pricing'],
        'has_relevant_issues': !exists(json, 'has_relevant_issues') ? undefined : json['has_relevant_issues'],
        'has_warnings': !exists(json, 'has_warnings') ? undefined : json['has_warnings'],
        'warnings': !exists(json, 'warnings') ? undefined : json['warnings'],
        'wiretapping_technologies': !exists(json, 'wiretapping_technologies') ? undefined : json['wiretapping_technologies'],
    };
}

export function CRSFSecReviewDecisionsSummaryToJSON(value?: CRSFSecReviewDecisionsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggr_sec_analysis_comment': value.aggr_sec_analysis_comment,
        'aggr_sec_analysis_report': value.aggr_sec_analysis_report,
        'decisions_json': value.decisions_json,
        'detailed_url': value.detailed_url,
        'has_contingencies': value.has_contingencies,
        'has_declines': value.has_declines,
        'has_pricing': value.has_pricing,
        'has_relevant_issues': value.has_relevant_issues,
        'has_warnings': value.has_warnings,
        'warnings': value.warnings,
        'wiretapping_technologies': value.wiretapping_technologies,
    };
}

