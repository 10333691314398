/**
 * Formats a number based on the amount of zeros.
 *
 * __> Example:__
 * ```ts
 * nFormatter(1337, 3);
 * // '1.337k'
 * ```
 */

export const nFormatter: FormatNumber = (num, digits) => {
  const si = [
    { value: 1, symbol: '' },
    // { value: 1e3, symbol: 'k' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    // { value: 1e9, symbol: 'G' },
    // { value: 1e12, symbol: 'T' },
    // { value: 1e15, symbol: 'P' },
    // { value: 1e18, symbol: 'E' },
    // commented SI values for Coalition reqs
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

type FormatNumber = (num: number, digits: number) => string;
