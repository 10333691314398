import { useContext, createContext } from 'react';

/**
 * Context Type
 */
export type UserContextType =
  | {
      setUser: (data: any) => void;
      resetUser: () => void;
      user: UserDataType;
      userFlags: {
        hasCyberBenchmark: boolean;
        isBroker: boolean;
        isGoodRisk: boolean;
        isPolicyholder: boolean;
        isPremium: boolean;
        isScanned: boolean;
        isStaff: boolean;
        isSuperUser: boolean;
        userExists: boolean;
        weShouldSellInsuranceToThisGuy: boolean;
      };
      segmentIdentify: boolean;
      segmentIdentifyWasTriggered: () => void;
    }
  | any;

/**
 * Context Initial State
 */
export const initialState: UserContextType = {
  setUser: () => undefined,
  resetUser: () => undefined,
  user: {},
  userFlags: {
    hasCyberBenchmark: false,
    isBroker: false,
    isGoodRisk: false,
    isPolicyholder: false,
    isPremium: false,
    isScanned: false,
    isStaff: false,
    isSuperUser: false,
    userExists: false,
    weShouldSellInsuranceToThisGuy: false,
  },
  segmentIdentify: false,
  segmentIdentifyWasTriggered: () => undefined,
};

/**
 * Context
 */
export const UserContext = createContext<UserContextType>(initialState);

/**
 * useUserContext
 */
export const useUserContext = () => useContext<UserContextType>(UserContext);

interface UserDataType {
  entity_id?: string;
  entity_name?: string;
  entity_domain?: string;
  entity_logo_url?: string;
  domains?: any[];
  ip_addresses?: any[];
  monit_type?: string;
  data_sources?: any[];
  keywords?: string[];
  metrics?: UserDataTypeMetrics;
  extra?: Extra;
  use_mapping?: boolean;
  assets?: number;
  vulnerabilities?: Vulnerabilities;
  dataleaks?: Dataleaks;
  lookalikes?: number;
  honeypots?: number;
  torrents?: number;
  risk_score_v2: RiskScoreV2;
  peer_average?: number;
  report_url?: string;
  status?: string;
  created?: number;
  updated?: number;
  scanned?: number;
  cyber_benchmark?: CyberBenchmark;
  email?: string;
  user_id?: string;
  coalition_user_uuid?: null | any;
  policyholder?: boolean;
  broker?: boolean;
  quotas?: Quotas;
}

interface RiskScoreV2 {
  risk_score_v1?: number;
  risk_score_v2?: number;
}

interface CyberBenchmark {
  overall?: Overall;
  estimated_premium?: number;
}

interface Overall {
  '1_10_loss'?: number;
  '1_100_loss'?: number;
  median_loss?: number;
  percentile_45?: number;
  relative_frequency?: number;
}

interface Dataleaks {
  count?: number;
  password?: number;
  pii?: number;
}

interface Extra {
  sector?: string;
  industry?: string;
  subindustry?: string;
  industrygroup?: string;
  tags?: string[];
  metrics?: ExtraMetrics;
  geo?: Geo;
}

interface Geo {
  lat?: null | any;
  lng?: null | any;
  city?: string;
  state?: string;
  country?: string;
  stateCode?: string;
  postalCode?: string;
  streetName?: string;
  subPremise?: null | any;
  countryCode?: string;
  streetNumber?: string;
}

interface ExtraMetrics {
  raised?: null | any;
  employees?: number;
  marketCap?: null | any;
  alexaUsRank?: null | any;
  annualRevenue?: null | any;
  fiscalYearEnd?: null | any;
  employeesRange?: string;
  alexaGlobalRank?: number;
  estimatedAnnualRevenue?: null | any;
}

interface UserDataTypeMetrics {
  industry?: string;
  number_of_employees?: number;
  number_of_pii_records?: number;
  company_revenue?: number;
}

interface Quotas {
  lite_quota?: number;
  extended_quota?: number;
}

interface Vulnerabilities {
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
  info?: number;
  count?: number;
}
