/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlMuteRequestResponse
 */
export interface ControlMuteRequestResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlMuteRequestResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the ControlMuteRequestResponse interface.
 */
export function instanceOfControlMuteRequestResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function ControlMuteRequestResponseFromJSON(json: any): ControlMuteRequestResponse {
    return ControlMuteRequestResponseFromJSONTyped(json, false);
}

export function ControlMuteRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMuteRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'reason': json['reason'],
        'created': json['created'],
        'updated': json['updated'],
    };
}

export function ControlMuteRequestResponseToJSON(value?: ControlMuteRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'asset': value.asset,
        'datapoint': value.datapoint,
        'reason': value.reason,
        'created': value.created,
        'updated': value.updated,
    };
}

