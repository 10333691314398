import { createApiEndpoints, FuncEndpoint } from '../utils';

const PREFIX_V1NOTIFICATIONS = '/v1/notifications/web';

const ENDPOINTS_V1NOTIFICATIONS = [`/alerts`] as const;

type Endpoints = typeof ENDPOINTS_V1NOTIFICATIONS[number];

export type EndpointsV1NOTIFICATIONS = Record<Endpoints, FuncEndpoint>;

export const endpointsV1NOTIFICATIONS = createApiEndpoints<Endpoints>(
  PREFIX_V1NOTIFICATIONS,
  ENDPOINTS_V1NOTIFICATIONS,
) as EndpointsV1NOTIFICATIONS;
