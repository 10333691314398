/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityAssetTechOrderBy } from './MonitEntityAssetTechOrderBy';
import {
    MonitEntityAssetTechOrderByFromJSON,
    MonitEntityAssetTechOrderByFromJSONTyped,
    MonitEntityAssetTechOrderByToJSON,
} from './MonitEntityAssetTechOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityAssetTechQueries
 */
export interface MonitGetEntityAssetTechQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityAssetTechQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityAssetTechQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetTechQueries
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityAssetTechQueries
     */
    process_id?: string;
    /**
     * 
     * @type {MonitEntityAssetTechOrderBy}
     * @memberof MonitGetEntityAssetTechQueries
     */
    order_by?: MonitEntityAssetTechOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityAssetTechQueries
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityAssetTechQueries interface.
 */
export function instanceOfMonitGetEntityAssetTechQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityAssetTechQueriesFromJSON(json: any): MonitGetEntityAssetTechQueries {
    return MonitGetEntityAssetTechQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityAssetTechQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityAssetTechQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityAssetTechOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityAssetTechQueriesToJSON(value?: MonitGetEntityAssetTechQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'source_asset': value.source_asset,
        'process_id': value.process_id,
        'order_by': MonitEntityAssetTechOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

