/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskInvestors } from './ExecRiskInvestors';
import {
    ExecRiskInvestorsFromJSON,
    ExecRiskInvestorsFromJSONTyped,
    ExecRiskInvestorsToJSON,
} from './ExecRiskInvestors';
import type { Investors } from './Investors';
import {
    InvestorsFromJSON,
    InvestorsFromJSONTyped,
    InvestorsToJSON,
} from './Investors';

/**
 * 
 * @export
 * @interface ExecRiskZoomInfoFunding
 */
export interface ExecRiskZoomInfoFunding {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoFunding
     */
    round_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskZoomInfoFunding
     */
    funding_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskZoomInfoFunding
     */
    funding_amount?: number;
    /**
     * 
     * @type {Investors}
     * @memberof ExecRiskZoomInfoFunding
     */
    investors?: Investors;
    /**
     * 
     * @type {Array<ExecRiskInvestors>}
     * @memberof ExecRiskZoomInfoFunding
     */
    investors_details?: Array<ExecRiskInvestors>;
}

/**
 * Check if a given object implements the ExecRiskZoomInfoFunding interface.
 */
export function instanceOfExecRiskZoomInfoFunding(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskZoomInfoFundingFromJSON(json: any): ExecRiskZoomInfoFunding {
    return ExecRiskZoomInfoFundingFromJSONTyped(json, false);
}

export function ExecRiskZoomInfoFundingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskZoomInfoFunding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'round_type': !exists(json, 'round_type') ? undefined : json['round_type'],
        'funding_date': !exists(json, 'funding_date') ? undefined : json['funding_date'],
        'funding_amount': !exists(json, 'funding_amount') ? undefined : json['funding_amount'],
        'investors': !exists(json, 'investors') ? undefined : InvestorsFromJSON(json['investors']),
        'investors_details': !exists(json, 'investors_details') ? undefined : ((json['investors_details'] as Array<any>).map(ExecRiskInvestorsFromJSON)),
    };
}

export function ExecRiskZoomInfoFundingToJSON(value?: ExecRiskZoomInfoFunding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'round_type': value.round_type,
        'funding_date': value.funding_date,
        'funding_amount': value.funding_amount,
        'investors': InvestorsToJSON(value.investors),
        'investors_details': value.investors_details === undefined ? undefined : ((value.investors_details as Array<any>).map(ExecRiskInvestorsToJSON)),
    };
}

