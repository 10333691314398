/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlLocale } from './ControlLocale';
import {
    ControlLocaleFromJSON,
    ControlLocaleFromJSONTyped,
    ControlLocaleToJSON,
} from './ControlLocale';

/**
 * 
 * @export
 * @interface ControlSignupRequest
 */
export interface ControlSignupRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    invited_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSignupRequest
     */
    origin?: string;
    /**
     * 
     * @type {ControlLocale}
     * @memberof ControlSignupRequest
     */
    locale?: ControlLocale;
}

/**
 * Check if a given object implements the ControlSignupRequest interface.
 */
export function instanceOfControlSignupRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ControlSignupRequestFromJSON(json: any): ControlSignupRequest {
    return ControlSignupRequestFromJSONTyped(json, false);
}

export function ControlSignupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSignupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'invited_by': !exists(json, 'invited_by') ? undefined : json['invited_by'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'company_name': !exists(json, 'company_name') ? undefined : json['company_name'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'locale': !exists(json, 'locale') ? undefined : ControlLocaleFromJSON(json['locale']),
    };
}

export function ControlSignupRequestToJSON(value?: ControlSignupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'invited_by': value.invited_by,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'company_name': value.company_name,
        'origin': value.origin,
        'locale': ControlLocaleToJSON(value.locale),
    };
}

