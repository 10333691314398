/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlUserJoinClientRequestStatus } from './ControlUserJoinClientRequestStatus';
import {
    ControlUserJoinClientRequestStatusFromJSON,
    ControlUserJoinClientRequestStatusFromJSONTyped,
    ControlUserJoinClientRequestStatusToJSON,
} from './ControlUserJoinClientRequestStatus';

/**
 * 
 * @export
 * @interface ControlUpgradeResponseResponse
 */
export interface ControlUpgradeResponseResponse {
    /**
     * 
     * @type {ControlUserJoinClientRequestStatus}
     * @memberof ControlUpgradeResponseResponse
     */
    request_status: ControlUserJoinClientRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlUpgradeResponseResponse
     */
    requestor_email: string;
}

/**
 * Check if a given object implements the ControlUpgradeResponseResponse interface.
 */
export function instanceOfControlUpgradeResponseResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request_status" in value;
    isInstance = isInstance && "requestor_email" in value;

    return isInstance;
}

export function ControlUpgradeResponseResponseFromJSON(json: any): ControlUpgradeResponseResponse {
    return ControlUpgradeResponseResponseFromJSONTyped(json, false);
}

export function ControlUpgradeResponseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUpgradeResponseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_status': ControlUserJoinClientRequestStatusFromJSON(json['request_status']),
        'requestor_email': json['requestor_email'],
    };
}

export function ControlUpgradeResponseResponseToJSON(value?: ControlUpgradeResponseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_status': ControlUserJoinClientRequestStatusToJSON(value.request_status),
        'requestor_email': value.requestor_email,
    };
}

