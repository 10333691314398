/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
 */
export interface ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
     */
    address_line_1_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
     */
    address_line_2_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
     */
    city_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
     */
    state_abbreviation_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress
     */
    zip_cd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990CommonTypesUSAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json: any): ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress {
    return ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address_line_1_txt': !exists(json, 'address_line_1_txt') ? undefined : json['address_line_1_txt'],
        'address_line_2_txt': !exists(json, 'address_line_2_txt') ? undefined : json['address_line_2_txt'],
        'city_nm': !exists(json, 'city_nm') ? undefined : json['city_nm'],
        'state_abbreviation_cd': !exists(json, 'state_abbreviation_cd') ? undefined : json['state_abbreviation_cd'],
        'zip_cd': !exists(json, 'zip_cd') ? undefined : json['zip_cd'],
    };
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_line_1_txt': value.address_line_1_txt,
        'address_line_2_txt': value.address_line_2_txt,
        'city_nm': value.city_nm,
        'state_abbreviation_cd': value.state_abbreviation_cd,
        'zip_cd': value.zip_cd,
    };
}

