/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityFindingsAggAsset
 */
export interface ControlEntityFindingsAggAsset {
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsAggAsset
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsAggAsset
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityFindingsAggAsset
     */
    source?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityFindingsAggAsset
     */
    shared?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityFindingsAggAsset
     */
    found?: number;
}

/**
 * Check if a given object implements the ControlEntityFindingsAggAsset interface.
 */
export function instanceOfControlEntityFindingsAggAsset(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityFindingsAggAssetFromJSON(json: any): ControlEntityFindingsAggAsset {
    return ControlEntityFindingsAggAssetFromJSONTyped(json, false);
}

export function ControlEntityFindingsAggAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityFindingsAggAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'found': !exists(json, 'found') ? undefined : json['found'],
    };
}

export function ControlEntityFindingsAggAssetToJSON(value?: ControlEntityFindingsAggAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'port': value.port,
        'source': value.source,
        'shared': value.shared,
        'found': value.found,
    };
}

