/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ContractorName?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ContractorAddress?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ServicesDesc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    CompensationAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ContractorName': !exists(json, 'ContractorName') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSON(json['ContractorName']),
        'ContractorAddress': !exists(json, 'ContractorAddress') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSON(json['ContractorAddress']),
        'ServicesDesc': !exists(json, 'ServicesDesc') ? undefined : json['ServicesDesc'],
        'CompensationAmt': !exists(json, 'CompensationAmt') ? undefined : json['CompensationAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ContractorName': ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorToJSON(value.ContractorName),
        'ContractorAddress': ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressToJSON(value.ContractorAddress),
        'ServicesDesc': value.ServicesDesc,
        'CompensationAmt': value.CompensationAmt,
    };
}

