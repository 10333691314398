import React from 'react';
import clsx from 'clsx';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const FormControlLabel = withCoreProps<FormControlLabelProps>(
  ({ className, ...props }: React.PropsWithChildren<FormControlLabelProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return <MuiFormControlLabel {...props} className={clsxClass} />;
  },
);

export interface FormControlLabelProps
  extends MuiFormControlLabelProps,
    CoreProps {}
