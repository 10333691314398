/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRSFTaskType = {
    Bootstrap: 'bootstrap',
    Builtwith: 'builtwith',
    Dataleaks: 'dataleaks',
    Enumeration: 'enumeration',
    Honeypots: 'honeypots',
    PdfGeneration: 'pdf_generation',
    ScanHistorical: 'scan_historical',
    ScanQuick: 'scan_quick',
    ScanSe: 'scan_se',
    ScanWeb: 'scan_web',
    SecurityDecisions: 'security_decisions',
    SecurityFindings: 'security_findings',
    Sourcelists: 'sourcelists',
    Spiders: 'spiders',
    Summary: 'summary',
    TechExtraction: 'tech_extraction',
    Torrents: 'torrents',
    TxtGeneration: 'txt_generation',
    Virustotal: 'virustotal',
    Wappalyzer: 'wappalyzer',
    SecurityAnalysis: 'security_analysis'
} as const;
export type CRSFTaskType = typeof CRSFTaskType[keyof typeof CRSFTaskType];


export function CRSFTaskTypeFromJSON(json: any): CRSFTaskType {
    return CRSFTaskTypeFromJSONTyped(json, false);
}

export function CRSFTaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTaskType {
    return json as CRSFTaskType;
}

export function CRSFTaskTypeToJSON(value?: CRSFTaskType | null): any {
    return value as any;
}

