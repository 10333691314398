/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAnsweredCondition
 */
export interface ControlAnsweredCondition {
    /**
     * 
     * @type {string}
     * @memberof ControlAnsweredCondition
     */
    type?: ControlAnsweredConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlAnsweredCondition
     */
    question_id: string;
}


/**
 * @export
 */
export const ControlAnsweredConditionTypeEnum = {
    HasAnswered: 'has_answered'
} as const;
export type ControlAnsweredConditionTypeEnum = typeof ControlAnsweredConditionTypeEnum[keyof typeof ControlAnsweredConditionTypeEnum];


/**
 * Check if a given object implements the ControlAnsweredCondition interface.
 */
export function instanceOfControlAnsweredCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "question_id" in value;

    return isInstance;
}

export function ControlAnsweredConditionFromJSON(json: any): ControlAnsweredCondition {
    return ControlAnsweredConditionFromJSONTyped(json, false);
}

export function ControlAnsweredConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAnsweredCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'question_id': json['question_id'],
    };
}

export function ControlAnsweredConditionToJSON(value?: ControlAnsweredCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'question_id': value.question_id,
    };
}

