import { useIntl } from 'react-intl';
import { formatMessage, parseCommaSeparatedList } from '@explorer/helpers';
import React, { ReactNode, useMemo } from 'react';
import { ChipsInput } from '@crucible-risk/security-applications';
import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const ENTER_KEY = 'Enter';
const COMMA_KEY = ',';
const BLANK_SPACE_KEY = ' ';

const useStyles = () =>
  makeStyles(({ palette, typography }: Theme) =>
    createStyles({
      root: {
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
          color: palette.action.disabled,
        },
        '& .MuiChipsInput-Chip': {
          backgroundColor: palette.blue[900],
          color: palette.grey[300],
          fontSize: typography.body2.fontSize,
          '& .MuiChip-deleteIcon': {
            color: palette.blue[500],
            opacity: 0.5,
          },
        },
        '& .MuiInputBase-root': {
          marginBottom: 0,
        },
      },
    }),
  )();

export const FieldChipsInput = ({
  label,
  name,
  helperText,
  placeholder,
  setFieldValue,
  setFieldError,
  value = [],
  error,
  ...props
}: FieldChipsInputProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const intlHelperText = useMemo<string | ReactNode>(
    () => formatMessage(intl, helperText),
    [intl, helperText],
  );

  const intlPlaceholder = useMemo<string>(
    () =>
      placeholder
        ? typeof placeholder === 'string'
          ? placeholder
          : (formatMessage(intl, placeholder) as string)
        : '',
    [intl, placeholder],
  );

  const handleChips = (chips: unknown[]) => {
    if (setFieldValue) {
      if (chips.length === 0) {
        setFieldValue(name, []);
        return;
      }

      let newChips = [];
      const lastChip = chips[chips.length - 1] as string;
      const chipArray = Array.from(parseCommaSeparatedList(lastChip));
      newChips = [...(value as string[]), ...(chipArray as string[])];
      setFieldValue(name, newChips);
    }
  };

  const handleOnDelete = (deletedChip: string) => {
    if (setFieldValue) {
      const newChips = (value as string[]).filter((c) => c !== deletedChip);
      setFieldValue(name, newChips);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      return;
    }
    setFieldValue(name, [
      ...(value as string[]),
      ...Array.from(parseCommaSeparatedList(e.target.value)),
    ]);
  };

  return (
    <Box display={'flex'}>
      <ChipsInput
        {...props}
        classes={classes}
        value={value as string[]}
        clearInputOnBlur
        disableEdition
        placeholder={!value.length ? intlPlaceholder : ''}
        style={{ width: '100%' }}
        color="primary"
        label={intlLabel}
        onDeleteChip={handleOnDelete}
        onChange={handleChips}
        onBlur={handleOnBlur}
        helperText={error ? error?.[name] : intlHelperText}
        addOnWhichKey={[ENTER_KEY, COMMA_KEY, BLANK_SPACE_KEY]}
      />
    </Box>
  );
};

export interface FieldChipsInputProps {
  label: { id: string };
  helperText: { id: string };
  placeholder?: { id: string } | string;
  name: string;
  setFieldValue: (name: unknown, value: any) => void;
  setFieldError?: any;
  error: any;
  value?: unknown[];
}
