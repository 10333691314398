import React, { HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

const useStyles = ({ size }: Partial<IconFlagProps>) =>
  makeStyles(
    ({}: Theme) =>
      createStyles({
        flag: {
          width: size,
          height: size,
        },
      }),
    { index: 1 },
  )();

export const IconFlag = withCoreProps<IconFlagProps>(
  ({
    countryCode = '',
    size = '25px',
    className,
    ...props
  }: PropsWithChildren<IconFlagProps>) => {
    const classes = useStyles({ size });

    const clsxClass = clsx(
      className,
      classes.flag,
      'flag-icon',
      `flag-icon-${countryCode.toLowerCase()}`,
    );

    return <span {...props} className={clsxClass} />;
  },
);

export interface IconFlagProps extends HTMLAttributes<any>, CoreProps {
  /**
   * `countryCode` - country ISO
   */
  countryCode: string;
  /**
   * `size` - sets the `width` and `height` props
   */
  size: string;
}
