/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    DisregardedEntityName?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    USAddress?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    ForeignAddress?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    EIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    PrimaryActivitiesTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    LegalDomicileStateCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    LegalDomicileForeignCountryCd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    TotalIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    EndOfYearAssetsAmt?: number;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    DirectControllingEntityName?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities
     */
    DirectControllingNACd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DisregardedEntityName': !exists(json, 'DisregardedEntityName') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DisregardedEntityName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'EIN': !exists(json, 'EIN') ? undefined : json['EIN'],
        'PrimaryActivitiesTxt': !exists(json, 'PrimaryActivitiesTxt') ? undefined : json['PrimaryActivitiesTxt'],
        'LegalDomicileStateCd': !exists(json, 'LegalDomicileStateCd') ? undefined : json['LegalDomicileStateCd'],
        'LegalDomicileForeignCountryCd': !exists(json, 'LegalDomicileForeignCountryCd') ? undefined : json['LegalDomicileForeignCountryCd'],
        'TotalIncomeAmt': !exists(json, 'TotalIncomeAmt') ? undefined : json['TotalIncomeAmt'],
        'EndOfYearAssetsAmt': !exists(json, 'EndOfYearAssetsAmt') ? undefined : json['EndOfYearAssetsAmt'],
        'DirectControllingEntityName': !exists(json, 'DirectControllingEntityName') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DirectControllingEntityName']),
        'DirectControllingNACd': !exists(json, 'DirectControllingNACd') ? undefined : json['DirectControllingNACd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntitiesToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdDisregardedEntities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DisregardedEntityName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DisregardedEntityName),
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'EIN': value.EIN,
        'PrimaryActivitiesTxt': value.PrimaryActivitiesTxt,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileForeignCountryCd': value.LegalDomicileForeignCountryCd,
        'TotalIncomeAmt': value.TotalIncomeAmt,
        'EndOfYearAssetsAmt': value.EndOfYearAssetsAmt,
        'DirectControllingEntityName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DirectControllingEntityName),
        'DirectControllingNACd': value.DirectControllingNACd,
    };
}

