import React from 'react';
import * as yup from 'yup';
import {
  FieldText,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';

/**
 * Form Validation Schema
 */
export const validationSchema = (intl) =>
  yup
    .object({
      client_id: yup
        .string()
        .required(
          intl.formatMessage({ id: 'forms-errors.required-client-id' }),
        ),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  client_id: {
    formId,
    formik,
    name: 'client_id',
    renderField: (props) => (
      <FieldText
        {...props}
        label={{ id: 'forms-presets.client-id' }}
        placeholder="eg: coalitiongeneral"
      />
    ),
  },
});
