/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlHealthzAPIs } from './ControlHealthzAPIs';
import {
    ControlHealthzAPIsFromJSON,
    ControlHealthzAPIsFromJSONTyped,
    ControlHealthzAPIsToJSON,
} from './ControlHealthzAPIs';
import type { ControlHealthzDatastores } from './ControlHealthzDatastores';
import {
    ControlHealthzDatastoresFromJSON,
    ControlHealthzDatastoresFromJSONTyped,
    ControlHealthzDatastoresToJSON,
} from './ControlHealthzDatastores';

/**
 * 
 * @export
 * @interface ControlHealthz
 */
export interface ControlHealthz {
    /**
     * 
     * @type {ControlHealthzDatastores}
     * @memberof ControlHealthz
     */
    datastores?: ControlHealthzDatastores;
    /**
     * 
     * @type {ControlHealthzAPIs}
     * @memberof ControlHealthz
     */
    apis?: ControlHealthzAPIs;
    /**
     * 
     * @type {string}
     * @memberof ControlHealthz
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof ControlHealthz
     */
    build_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlHealthz
     */
    ok: boolean;
}

/**
 * Check if a given object implements the ControlHealthz interface.
 */
export function instanceOfControlHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hostname" in value;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function ControlHealthzFromJSON(json: any): ControlHealthz {
    return ControlHealthzFromJSONTyped(json, false);
}

export function ControlHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datastores': !exists(json, 'datastores') ? undefined : ControlHealthzDatastoresFromJSON(json['datastores']),
        'apis': !exists(json, 'apis') ? undefined : ControlHealthzAPIsFromJSON(json['apis']),
        'hostname': json['hostname'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
        'ok': json['ok'],
    };
}

export function ControlHealthzToJSON(value?: ControlHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datastores': ControlHealthzDatastoresToJSON(value.datastores),
        'apis': ControlHealthzAPIsToJSON(value.apis),
        'hostname': value.hostname,
        'build_version': value.build_version,
        'ok': value.ok,
    };
}

