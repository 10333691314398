/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewHealthz
 */
export interface SecReviewHealthz {
    /**
     * 
     * @type {object}
     * @memberof SecReviewHealthz
     */
    datastores?: object;
    /**
     * 
     * @type {object}
     * @memberof SecReviewHealthz
     */
    apis?: object;
    /**
     * 
     * @type {string}
     * @memberof SecReviewHealthz
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof SecReviewHealthz
     */
    build_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewHealthz
     */
    ok: boolean;
}

/**
 * Check if a given object implements the SecReviewHealthz interface.
 */
export function instanceOfSecReviewHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hostname" in value;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function SecReviewHealthzFromJSON(json: any): SecReviewHealthz {
    return SecReviewHealthzFromJSONTyped(json, false);
}

export function SecReviewHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datastores': !exists(json, 'datastores') ? undefined : json['datastores'],
        'apis': !exists(json, 'apis') ? undefined : json['apis'],
        'hostname': json['hostname'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
        'ok': json['ok'],
    };
}

export function SecReviewHealthzToJSON(value?: SecReviewHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datastores': value.datastores,
        'apis': value.apis,
        'hostname': value.hostname,
        'build_version': value.build_version,
        'ok': value.ok,
    };
}

