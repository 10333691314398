/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityLookalikesHttp
 */
export interface ControlEntityLookalikesHttp {
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityLookalikesHttp
     */
    _exists?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesHttp
     */
    screenshot?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityLookalikesHttp
     */
    ssdeep_score?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntityLookalikesHttp
     */
    redirect_to_original?: boolean;
}

/**
 * Check if a given object implements the ControlEntityLookalikesHttp interface.
 */
export function instanceOfControlEntityLookalikesHttp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityLookalikesHttpFromJSON(json: any): ControlEntityLookalikesHttp {
    return ControlEntityLookalikesHttpFromJSONTyped(json, false);
}

export function ControlEntityLookalikesHttpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityLookalikesHttp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'screenshot': !exists(json, 'screenshot') ? undefined : json['screenshot'],
        'ssdeep_score': !exists(json, 'ssdeep_score') ? undefined : json['ssdeep_score'],
        'redirect_to_original': !exists(json, 'redirect_to_original') ? undefined : json['redirect_to_original'],
    };
}

export function ControlEntityLookalikesHttpToJSON(value?: ControlEntityLookalikesHttp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'screenshot': value.screenshot,
        'ssdeep_score': value.ssdeep_score,
        'redirect_to_original': value.redirect_to_original,
    };
}

