/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationsMessageAttachmentSendRequest
 */
export interface NotificationsMessageAttachmentSendRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessageAttachmentSendRequest
     */
    recipient_email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessageAttachmentSendRequest
     */
    s3_link: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsMessageAttachmentSendRequest
     */
    locale?: string;
}

/**
 * Check if a given object implements the NotificationsMessageAttachmentSendRequest interface.
 */
export function instanceOfNotificationsMessageAttachmentSendRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipient_email" in value;
    isInstance = isInstance && "s3_link" in value;

    return isInstance;
}

export function NotificationsMessageAttachmentSendRequestFromJSON(json: any): NotificationsMessageAttachmentSendRequest {
    return NotificationsMessageAttachmentSendRequestFromJSONTyped(json, false);
}

export function NotificationsMessageAttachmentSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsMessageAttachmentSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient_email': json['recipient_email'],
        's3_link': json['s3_link'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
    };
}

export function NotificationsMessageAttachmentSendRequestToJSON(value?: NotificationsMessageAttachmentSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_email': value.recipient_email,
        's3_link': value.s3_link,
        'locale': value.locale,
    };
}

