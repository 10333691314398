/* eslint-disable react/display-name */
import { formatMessage } from '@explorer/helpers';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Tab as CoreTab, Tabs as CoreTabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

/**
 * TabMenu
 */
export const AnchorTabMenu = ({
  items,
  children: AnchorTabChildren,
}: AnchorTabMenuProps) => {
  return (
    <AnchorTabList items={items}>
      <Box py={3} px={3}>
        {AnchorTabChildren ||
          items.map(
            ({ pathname: href, children, hideCondition, disableCondition }) => {
              if (hideCondition || disableCondition || !children) return null;

              const hrefWithTrailingUrl = standardizeHref(href);

              return (
                <TabPanel value={hrefWithTrailingUrl} key={hrefWithTrailingUrl}>
                  {children}
                </TabPanel>
              );
            },
          )}
      </Box>
    </AnchorTabList>
  );
};

export const AnchorTabList = ({ items, children }: AnchorTabMenuProps) => {
  const intl = useIntl();
  const { asPath } = useRouter();
  const activeTabPath = standardizeHref(asPath);

  return (
    <TabContext value={activeTabPath}>
      <CoreTabs value={activeTabPath} variant="scrollable" scrollButtons="auto">
        {items.map(
          ({ pathname, label, disableCondition, hideCondition, query }) => {
            if (hideCondition) return null;

            const tabValue = standardizeHref(pathname);

            let hrefWithQueryParamValues = pathname;

            if (query) {
              const urlWithQueryParams = new URL(
                pathname,
                window.location.origin,
              );
              Object.entries(query).forEach(([key, value]) => {
                urlWithQueryParams.searchParams.set(key, value);
              });
              hrefWithQueryParamValues = `${
                urlWithQueryParams.pathname
              }?${urlWithQueryParams.searchParams.toString()}`;
            }

            return (
              <AnchorTab
                key={tabValue}
                href={disableCondition ? undefined : hrefWithQueryParamValues}
                value={disableCondition ? undefined : tabValue}
                disableCondition={disableCondition}
              >
                {formatMessage(intl, label)}
              </AnchorTab>
            );
          },
        )}
      </CoreTabs>
      {children ? children : null}
    </TabContext>
  );
};

/**
 *  This will take in a url and return just the pathname with a trailing /. This will allow us to compare an exact match of the current url path with the href of the tab. It will ignore any query params or hashes.
 *
 *
 * @param href the href of the tab's link
 */
const standardizeHref = (href: string) => {
  const url = new URL(href, window.location.origin);

  if (url.pathname.at(-1) !== '/') {
    url.pathname = `${url.pathname}/`;
  }

  return url.pathname;
};

function AnchorTab(
  props: React.PropsWithChildren<{
    href: string;
    disableCondition: boolean;
    value: string;
  }>,
) {
  const { href, disableCondition, value, children } = props;

  /* If we don't have an href, which is the case for disabled tabs, we need to render a span instead of a link or Next will throw an error */
  const MemoizedLink = useMemo<React.FC>(
    () =>
      React.forwardRef((props, ref: any) =>
        href ? (
          <Link ref={ref} scroll={false} {...props} href={href} />
        ) : (
          <span ref={ref} {...props} />
        ),
      ),
    [href],
  );

  return (
    <CoreTab
      py={2.1}
      key={href}
      value={value}
      component={MemoizedLink as any}
      label={children}
      disabled={disableCondition || false}
    />
  );
}

/**
 * TabMenu Props
 */
export type AnchorTabMenuProps = React.PropsWithChildren<{
  items: AnchorTabMenuItem[];
}>;

export interface AnchorTabMenuItem {
  pathname: string;
  query?: Record<string, string>;
  label: IntlLabel;
  children?: ReactNode | ReactElement | null;
  disableCondition?: boolean;
  hideCondition?: boolean;
}
