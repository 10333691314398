import React, { useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { ProgressCircular } from '../Progress';
import { formatMessage } from '@explorer/helpers';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

const useStyles = ({ color, loading }: Partial<ButtonProps>) =>
  makeStyles(
    (theme: Theme) =>
      createStyles({
        button: {
          color: loading ? 'transparent !important' : undefined,
          backgroundColor: loading
            ? color === 'secondary'
              ? `${theme.palette.secondary.dark} !important`
              : `${theme.palette.primary.dark} !important`
            : undefined,
        },
        loading: {
          width: `25px !important`,
          height: `25px !important`,
          position: 'absolute',
          color: '#fff',
        },
      }),
    { index: 1 },
  )();

export const Button = withCoreProps<ButtonProps>((props) => {
  const {
    color,
    label,
    variant = 'contained',
    loading = false,
    disabled,
    children,
    className,
    ...otherProps
  } = props;
  const classes = useStyles({ color, loading });
  const clsxClass = clsx(classes.button, className);
  const intl = useIntl();

  const content = useMemo(() => {
    if (label && children) {
      // eslint-disable-next-line no-console
      console.warn(
        'Warning: [packages/core/Button] `children` is overwritten by the usage of a `label`. Please use only one.',
      );
    }

    if (label) return formatMessage(intl, label);

    return children;
  }, [intl, label, children]);

  return (
    <MuiButton
      {...otherProps}
      color={color}
      variant={variant}
      disabled={loading ? true : disabled}
      className={clsxClass}
      disableRipple
    >
      {loading && <ProgressCircular className={classes.loading} />}
      {content}
    </MuiButton>
  );
});

export interface ButtonProps extends MuiButtonProps, CoreProps {
  /**
   * `label` - *OPTIONAL* - string or localized message id
   */
  label?: IntlLabel;
  /**
   * `loading` - *OPTIONAL* - enables the loading animation
   */
  loading?: boolean;
}
