/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlCreateUserVersion } from './ControlCreateUserVersion';
import {
    ControlCreateUserVersionFromJSON,
    ControlCreateUserVersionFromJSONTyped,
    ControlCreateUserVersionToJSON,
} from './ControlCreateUserVersion';
import type { ControlMonitTypeAlt } from './ControlMonitTypeAlt';
import {
    ControlMonitTypeAltFromJSON,
    ControlMonitTypeAltFromJSONTyped,
    ControlMonitTypeAltToJSON,
} from './ControlMonitTypeAlt';
import type { ControlQuotaUpdate } from './ControlQuotaUpdate';
import {
    ControlQuotaUpdateFromJSON,
    ControlQuotaUpdateFromJSONTyped,
    ControlQuotaUpdateToJSON,
} from './ControlQuotaUpdate';

/**
 * 
 * @export
 * @interface ControlAdminCreateUser
 */
export interface ControlAdminCreateUser {
    /**
     * 
     * @type {ControlCreateUserVersion}
     * @memberof ControlAdminCreateUser
     */
    version: ControlCreateUserVersion;
    /**
     * 
     * @type {ControlMonitTypeAlt}
     * @memberof ControlAdminCreateUser
     */
    monit_type?: ControlMonitTypeAlt;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    policy_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    company_domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAdminCreateUser
     */
    domains?: Array<string>;
    /**
     * 
     * @type {ControlQuotaUpdate}
     * @memberof ControlAdminCreateUser
     */
    quota?: ControlQuotaUpdate;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    trial_start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    trial_end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAdminCreateUser
     */
    created_by?: string;
}

/**
 * Check if a given object implements the ControlAdminCreateUser interface.
 */
export function instanceOfControlAdminCreateUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "company_domain" in value;

    return isInstance;
}

export function ControlAdminCreateUserFromJSON(json: any): ControlAdminCreateUser {
    return ControlAdminCreateUserFromJSONTyped(json, false);
}

export function ControlAdminCreateUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminCreateUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': ControlCreateUserVersionFromJSON(json['version']),
        'monit_type': !exists(json, 'monit_type') ? undefined : ControlMonitTypeAltFromJSON(json['monit_type']),
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'],
        'company_name': json['company_name'],
        'policy_number': !exists(json, 'policy_number') ? undefined : json['policy_number'],
        'company_domain': json['company_domain'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'quota': !exists(json, 'quota') ? undefined : ControlQuotaUpdateFromJSON(json['quota']),
        'trial_start_date': !exists(json, 'trial_start_date') ? undefined : json['trial_start_date'],
        'trial_end_date': !exists(json, 'trial_end_date') ? undefined : json['trial_end_date'],
        'created_by': !exists(json, 'created_by') ? undefined : json['created_by'],
    };
}

export function ControlAdminCreateUserToJSON(value?: ControlAdminCreateUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': ControlCreateUserVersionToJSON(value.version),
        'monit_type': ControlMonitTypeAltToJSON(value.monit_type),
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'company_name': value.company_name,
        'policy_number': value.policy_number,
        'company_domain': value.company_domain,
        'domains': value.domains,
        'quota': ControlQuotaUpdateToJSON(value.quota),
        'trial_start_date': value.trial_start_date,
        'trial_end_date': value.trial_end_date,
        'created_by': value.created_by,
    };
}

