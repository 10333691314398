/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsUserFilterCode = {
    Marketo: 'MARKETO',
    SecurityAlert: 'SECURITY_ALERT',
    BrokerAlert: 'BROKER_ALERT',
    ControlTrialReminder: 'CONTROL_TRIAL_REMINDER'
} as const;
export type NotificationsUserFilterCode = typeof NotificationsUserFilterCode[keyof typeof NotificationsUserFilterCode];


export function NotificationsUserFilterCodeFromJSON(json: any): NotificationsUserFilterCode {
    return NotificationsUserFilterCodeFromJSONTyped(json, false);
}

export function NotificationsUserFilterCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsUserFilterCode {
    return json as NotificationsUserFilterCode;
}

export function NotificationsUserFilterCodeToJSON(value?: NotificationsUserFilterCode | null): any {
    return value as any;
}

