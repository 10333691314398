/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlRiskHistoryLogType } from './ControlRiskHistoryLogType';
import {
    ControlRiskHistoryLogTypeFromJSON,
    ControlRiskHistoryLogTypeFromJSONTyped,
    ControlRiskHistoryLogTypeToJSON,
} from './ControlRiskHistoryLogType';
import type { ControlRiskHistoryVulnerabilityStats } from './ControlRiskHistoryVulnerabilityStats';
import {
    ControlRiskHistoryVulnerabilityStatsFromJSON,
    ControlRiskHistoryVulnerabilityStatsFromJSONTyped,
    ControlRiskHistoryVulnerabilityStatsToJSON,
} from './ControlRiskHistoryVulnerabilityStats';

/**
 * 
 * @export
 * @interface ControlRiskHistoryEntry
 */
export interface ControlRiskHistoryEntry {
    /**
     * 
     * @type {string}
     * @memberof ControlRiskHistoryEntry
     */
    created: string;
    /**
     * 
     * @type {ControlRiskHistoryLogType}
     * @memberof ControlRiskHistoryEntry
     */
    type: ControlRiskHistoryLogType;
    /**
     * 
     * @type {number}
     * @memberof ControlRiskHistoryEntry
     */
    vulnerable_assets: number;
    /**
     * 
     * @type {number}
     * @memberof ControlRiskHistoryEntry
     */
    previous_vulnerable_assets?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlRiskHistoryEntry
     */
    previous_risk_score?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlRiskHistoryEntry
     */
    risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlRiskHistoryEntry
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRiskHistoryEntry
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRiskHistoryEntry
     */
    user?: string;
    /**
     * 
     * @type {ControlRiskHistoryVulnerabilityStats}
     * @memberof ControlRiskHistoryEntry
     */
    vulnerabilities?: ControlRiskHistoryVulnerabilityStats;
}

/**
 * Check if a given object implements the ControlRiskHistoryEntry interface.
 */
export function instanceOfControlRiskHistoryEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "vulnerable_assets" in value;

    return isInstance;
}

export function ControlRiskHistoryEntryFromJSON(json: any): ControlRiskHistoryEntry {
    return ControlRiskHistoryEntryFromJSONTyped(json, false);
}

export function ControlRiskHistoryEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRiskHistoryEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'type': ControlRiskHistoryLogTypeFromJSON(json['type']),
        'vulnerable_assets': json['vulnerable_assets'],
        'previous_vulnerable_assets': !exists(json, 'previous_vulnerable_assets') ? undefined : json['previous_vulnerable_assets'],
        'previous_risk_score': !exists(json, 'previous_risk_score') ? undefined : json['previous_risk_score'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : ControlRiskHistoryVulnerabilityStatsFromJSON(json['vulnerabilities']),
    };
}

export function ControlRiskHistoryEntryToJSON(value?: ControlRiskHistoryEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'type': ControlRiskHistoryLogTypeToJSON(value.type),
        'vulnerable_assets': value.vulnerable_assets,
        'previous_vulnerable_assets': value.previous_vulnerable_assets,
        'previous_risk_score': value.previous_risk_score,
        'risk_score': value.risk_score,
        'datapoint': value.datapoint,
        'name': value.name,
        'user': value.user,
        'vulnerabilities': ControlRiskHistoryVulnerabilityStatsToJSON(value.vulnerabilities),
    };
}

