import { FormControlLabel, Grid, Switch } from '@mui/material';
import React from 'react';

export const ToggleWithDescription = (
  props: {
    label: React.ReactNode;
    description: React.ReactNode;
    emailsEnabled: boolean;
    toggleDisabled?: boolean;
    toggleHidden?: boolean;
    onChange?: (value: boolean) => void;
  } & React.ComponentProps<typeof Grid>,
) => {
  const {
    label,
    description,
    emailsEnabled,
    toggleDisabled,
    onChange,
    toggleHidden,
    ...gridProps
  } = props;

  return (
    <Grid container gap={1} direction={'column'} item {...gridProps}>
      <Grid item>
        <FormControlLabel
          labelPlacement="start"
          componentsProps={{
            typography: {
              sx: { fontSize: 'h5.fontSize', flex: 1 },
            },
          }}
          sx={{ m: 0, width: '100%' }}
          control={
            <Switch
              sx={{ display: toggleHidden ? 'none' : 'inherit' }}
              size="medium"
              checked={emailsEnabled}
              onChange={onChange}
              disabled={toggleDisabled || toggleHidden}
            />
          }
          label={label}
        />
      </Grid>
      <Grid item>{description}</Grid>
    </Grid>
  );
};
