/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecReviewHoneypot } from './SecReviewHoneypot';
import {
    SecReviewHoneypotFromJSON,
    SecReviewHoneypotFromJSONTyped,
    SecReviewHoneypotToJSON,
} from './SecReviewHoneypot';

/**
 * 
 * @export
 * @interface SecReviewHoneypotsAggregate
 */
export interface SecReviewHoneypotsAggregate {
    /**
     * 
     * @type {number}
     * @memberof SecReviewHoneypotsAggregate
     */
    total: number;
    /**
     * 
     * @type {Array<SecReviewHoneypot>}
     * @memberof SecReviewHoneypotsAggregate
     */
    malicious: Array<SecReviewHoneypot>;
    /**
     * 
     * @type {Array<SecReviewHoneypot>}
     * @memberof SecReviewHoneypotsAggregate
     */
    other: Array<SecReviewHoneypot>;
}

/**
 * Check if a given object implements the SecReviewHoneypotsAggregate interface.
 */
export function instanceOfSecReviewHoneypotsAggregate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "malicious" in value;
    isInstance = isInstance && "other" in value;

    return isInstance;
}

export function SecReviewHoneypotsAggregateFromJSON(json: any): SecReviewHoneypotsAggregate {
    return SecReviewHoneypotsAggregateFromJSONTyped(json, false);
}

export function SecReviewHoneypotsAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewHoneypotsAggregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'malicious': ((json['malicious'] as Array<any>).map(SecReviewHoneypotFromJSON)),
        'other': ((json['other'] as Array<any>).map(SecReviewHoneypotFromJSON)),
    };
}

export function SecReviewHoneypotsAggregateToJSON(value?: SecReviewHoneypotsAggregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'malicious': ((value.malicious as Array<any>).map(SecReviewHoneypotToJSON)),
        'other': ((value.other as Array<any>).map(SecReviewHoneypotToJSON)),
    };
}

