/**
 * Truncate a text longer than `maxLength` characters but keeping
 * the last word and adding an ellipsis in the middle.
 *
 * __> Example:__
 * ```ts
 * truncateWords('Some Cool and Big Company Name', 16);
 * // "Some Cool...Name"
 * ```
 */

export const truncateWords: TruncateWords = (text, maxLength) => {
  if (text?.length < maxLength) return text;

  if (text) {
    const ellipsis = '...';
    const words = text.split(' ');
    const endingWord = `${ellipsis}${words[words.length - 1]}`;

    let truncatedText = '';

    for (let i = 0, ii = words.length; i < ii; i++) {
      if ((truncatedText + endingWord).length >= maxLength) break;

      truncatedText += `${i ? ' ' : ''}${words[i]}`;
    }

    return (truncatedText + endingWord).trim();
  }
  return '';
};

type TruncateWords = (text: string, maxLength: number) => string;
