/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskHealthzAPIs
 */
export interface ExecRiskHealthzAPIs {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthzAPIs
     */
    source: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskHealthzAPIs
     */
    status_code: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskHealthzAPIs
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthzAPIs
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthzAPIs
     */
    details?: string;
}

/**
 * Check if a given object implements the ExecRiskHealthzAPIs interface.
 */
export function instanceOfExecRiskHealthzAPIs(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "status_code" in value;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function ExecRiskHealthzAPIsFromJSON(json: any): ExecRiskHealthzAPIs {
    return ExecRiskHealthzAPIsFromJSONTyped(json, false);
}

export function ExecRiskHealthzAPIsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskHealthzAPIs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'status_code': json['status_code'],
        'success': json['success'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function ExecRiskHealthzAPIsToJSON(value?: ExecRiskHealthzAPIs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'status_code': value.status_code,
        'success': value.success,
        'message': value.message,
        'details': value.details,
    };
}

