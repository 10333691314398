/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalServiceReadinessDependencies } from './SupplementalServiceReadinessDependencies';
import {
    SupplementalServiceReadinessDependenciesFromJSON,
    SupplementalServiceReadinessDependenciesFromJSONTyped,
    SupplementalServiceReadinessDependenciesToJSON,
} from './SupplementalServiceReadinessDependencies';

/**
 * 
 * @export
 * @interface SupplementalServiceReadiness
 */
export interface SupplementalServiceReadiness {
    /**
     * 
     * @type {string}
     * @memberof SupplementalServiceReadiness
     */
    readiness?: SupplementalServiceReadinessReadinessEnum;
    /**
     * 
     * @type {SupplementalServiceReadinessDependencies}
     * @memberof SupplementalServiceReadiness
     */
    dependencies?: SupplementalServiceReadinessDependencies;
}


/**
 * @export
 */
export const SupplementalServiceReadinessReadinessEnum = {
    Ok: 'OK',
    Degraded: 'DEGRADED',
    Failed: 'FAILED'
} as const;
export type SupplementalServiceReadinessReadinessEnum = typeof SupplementalServiceReadinessReadinessEnum[keyof typeof SupplementalServiceReadinessReadinessEnum];


/**
 * Check if a given object implements the SupplementalServiceReadiness interface.
 */
export function instanceOfSupplementalServiceReadiness(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalServiceReadinessFromJSON(json: any): SupplementalServiceReadiness {
    return SupplementalServiceReadinessFromJSONTyped(json, false);
}

export function SupplementalServiceReadinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalServiceReadiness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readiness': !exists(json, 'readiness') ? undefined : json['readiness'],
        'dependencies': !exists(json, 'dependencies') ? undefined : SupplementalServiceReadinessDependenciesFromJSON(json['dependencies']),
    };
}

export function SupplementalServiceReadinessToJSON(value?: SupplementalServiceReadiness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readiness': value.readiness,
        'dependencies': SupplementalServiceReadinessDependenciesToJSON(value.dependencies),
    };
}

