/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewHealthz
 */
export interface CRSFSecReviewHealthz {
    /**
     * ISO 8601 date and time in UTC of the Git commit from which Krang's Docker image was built. Note that this was not the time at which this Docker image was deployed to this environment.
     * @type {string}
     * @memberof CRSFSecReviewHealthz
     */
    build_datetime?: string;
    /**
     * SHA-1 of the Git commit from which Krang's Docker image was built.
     * @type {string}
     * @memberof CRSFSecReviewHealthz
     */
    build_version?: string;
    /**
     * Service disposition.
     * @type {boolean}
     * @memberof CRSFSecReviewHealthz
     */
    ok: boolean;
}

/**
 * Check if a given object implements the CRSFSecReviewHealthz interface.
 */
export function instanceOfCRSFSecReviewHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function CRSFSecReviewHealthzFromJSON(json: any): CRSFSecReviewHealthz {
    return CRSFSecReviewHealthzFromJSONTyped(json, false);
}

export function CRSFSecReviewHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'build_datetime': !exists(json, 'build_datetime') ? undefined : json['build_datetime'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
        'ok': json['ok'],
    };
}

export function CRSFSecReviewHealthzToJSON(value?: CRSFSecReviewHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'build_datetime': value.build_datetime,
        'build_version': value.build_version,
        'ok': value.ok,
    };
}

