/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityStatsSurfaceCategory } from './MonitEntityStatsSurfaceCategory';
import {
    MonitEntityStatsSurfaceCategoryFromJSON,
    MonitEntityStatsSurfaceCategoryFromJSONTyped,
    MonitEntityStatsSurfaceCategoryToJSON,
} from './MonitEntityStatsSurfaceCategory';

/**
 * 
 * @export
 * @interface MonitEntitySurfaceType
 */
export interface MonitEntitySurfaceType {
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySurfaceType
     */
    count?: number;
    /**
     * 
     * @type {Array<MonitEntityStatsSurfaceCategory>}
     * @memberof MonitEntitySurfaceType
     */
    categories?: Array<MonitEntityStatsSurfaceCategory>;
}

/**
 * Check if a given object implements the MonitEntitySurfaceType interface.
 */
export function instanceOfMonitEntitySurfaceType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySurfaceTypeFromJSON(json: any): MonitEntitySurfaceType {
    return MonitEntitySurfaceTypeFromJSONTyped(json, false);
}

export function MonitEntitySurfaceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySurfaceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(MonitEntityStatsSurfaceCategoryFromJSON)),
    };
}

export function MonitEntitySurfaceTypeToJSON(value?: MonitEntitySurfaceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(MonitEntityStatsSurfaceCategoryToJSON)),
    };
}

