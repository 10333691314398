/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewStatusType } from './CRSFSecReviewStatusType';
import {
    CRSFSecReviewStatusTypeFromJSON,
    CRSFSecReviewStatusTypeFromJSONTyped,
    CRSFSecReviewStatusTypeToJSON,
} from './CRSFSecReviewStatusType';
import type { CRSFSecReviewTaskResponse } from './CRSFSecReviewTaskResponse';
import {
    CRSFSecReviewTaskResponseFromJSON,
    CRSFSecReviewTaskResponseFromJSONTyped,
    CRSFSecReviewTaskResponseToJSON,
} from './CRSFSecReviewTaskResponse';

/**
 * 
 * @export
 * @interface CRSFSecReviewThreadResponse
 */
export interface CRSFSecReviewThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewThreadResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewThreadResponse
     */
    domain: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewThreadResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {CRSFSecReviewStatusType}
     * @memberof CRSFSecReviewThreadResponse
     */
    status: CRSFSecReviewStatusType;
    /**
     * 
     * @type {Array<CRSFSecReviewTaskResponse>}
     * @memberof CRSFSecReviewThreadResponse
     */
    tasks: Array<CRSFSecReviewTaskResponse>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewThreadResponse
     */
    thread_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewThreadResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFSecReviewThreadResponse interface.
 */
export function instanceOfCRSFSecReviewThreadResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "tasks" in value;
    isInstance = isInstance && "thread_id" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFSecReviewThreadResponseFromJSON(json: any): CRSFSecReviewThreadResponse {
    return CRSFSecReviewThreadResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewThreadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewThreadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'domain': json['domain'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'status': CRSFSecReviewStatusTypeFromJSON(json['status']),
        'tasks': ((json['tasks'] as Array<any>).map(CRSFSecReviewTaskResponseFromJSON)),
        'thread_id': json['thread_id'],
        'updated': json['updated'],
    };
}

export function CRSFSecReviewThreadResponseToJSON(value?: CRSFSecReviewThreadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'domain': value.domain,
        'elapsed': value.elapsed,
        'status': CRSFSecReviewStatusTypeToJSON(value.status),
        'tasks': ((value.tasks as Array<any>).map(CRSFSecReviewTaskResponseToJSON)),
        'thread_id': value.thread_id,
        'updated': value.updated,
    };
}

