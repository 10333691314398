import { V1Api } from '@explorer/open-api-client';
import { EventState, TableEvents, TableStates } from '@explorer/tables';
import { QueryFunctionContext, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useExplorerAPIQueryParamType, useV1APIQuery } from '../base';

export type PaginatedResponse<T = unknown> = {
  results?: T[];
  // Exec Risk uses 'result' instead of 'results'
  result?: T[];
  count?: number;
  // SecReview and CRA use 'total' instead of 'count'
  total?: number;
};

type Params<T, DSelect> = Omit<
  useExplorerAPIQueryParamType<V1Api, PaginatedResponse<T>>,
  'queryFn' | 'select'
> & {
  queryFn: (
    client: V1Api,
    context: QueryFunctionContext,
    options: { tableParams: EventState },
  ) => PaginatedResponse<T> | Promise<PaginatedResponse<T>>;
  select?: (data: PaginatedResponse<T>) => DSelect;
};
/**
 *
 * @param args
 * @returns
 */
export function usePaginatedExplorerAPIQuery<T = unknown, DSelect = T[]>(
  args: Params<T, DSelect>,
) {
  const { enabled = true, queryFn, additionalParams = [] } = args;

  /* TableParams are sent from the table component, and will contain the page, pageSize, sort order and custom search params (these differ between tables) */
  const [tableParams, setTableParams] = useState<EventState | undefined>(
    undefined,
  );

  const previousRowCount = useRef<number>(0);

  const enabledWithTableParams = enabled && !!tableParams;

  const queryState = useV1APIQuery<PaginatedResponse<T>>({
    ...args,
    queryFn: (client, context) =>
      queryFn(client, context, { tableParams: tableParams }),
    enabled: enabledWithTableParams,
    refetchOnMount: true,
    retry: false,
    additionalParams: [...additionalParams, JSON.stringify(tableParams)],
  });

  const {
    data,
    invalidateAllQueries,
    error,
    isSuccess,
    isLoading,
    ...otherQueryState
  } = queryState;

  /**
   * Table Events
   */
  const tableEvents = useMemo<TableEvents>(
    () => ({
      onMount: setTableParams,
      onPageChange: setTableParams,
      onSort: setTableParams,
      onAdvSearch: setTableParams,
    }),
    [],
  );

  /**
   * Table States
   */
  const tableStates = useMemo<TableStates>(
    () => ({
      hasError: !!error,
      hasSucceeded: isSuccess,
      isLoading: isLoading,
    }),
    [error, isSuccess, isLoading],
  );

  const rows = data?.results ?? data?.result ?? [];

  let rowCount: number | undefined;
  if (queryState.isSuccess) {
    // If we have a successful query, we can use the 'count' or 'total' property to determine the number of rows

    rowCount = (data?.count || data?.total) ?? 0; // some APIs use 'count', some use 'total'
    previousRowCount.current = rowCount;
  } else {
    // otherwise, preserve the previous row count
    rowCount = previousRowCount.current;
  }

  return {
    tableEvents,
    tableStates,
    ...otherQueryState,
    invalidateAllQueries,
    rows,
    rowCount: rowCount ?? previousRowCount.current,
  };
}

/* Generic Pagination Params. Different apis define different enums for 'order_by', so we need to force the request param with an any */
type PaginationParams = {
  page?: number;
  page_size?: number;
  order_by?: any;
  desc?: boolean;
};

export function generatePaginationAPIParams<T extends PaginationParams>(
  tableParams: EventState,
  options?: { pageOneIndexed?: boolean },
): PaginationParams {
  const { page, pageSize, sortOrder } = tableParams;

  const { pageOneIndexed = false } = options ?? {};

  const pageToUse = pageOneIndexed ? page : page - 1;

  return {
    page: pageToUse,
    page_size: pageSize,
    order_by: sortOrder?.name || undefined,
    desc: sortOrder?.direction ? sortOrder?.direction === 'DESC' : undefined,
  };
}
