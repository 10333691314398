import { Components, Theme } from '@mui/material';
import { palette } from './palette';

export const overrides: Components<Omit<Theme, 'components'>> = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        backgroundImage: 'unset',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px',
        letterSpacing: '0.2px',
        backgroundColor: (palette.light.blue || {})?.[300],
        padding: 8,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        border: '1px solid transparent !important',
      },
      containedSecondary: {
        border: '1px solid transparent !important',
      },
      outlinedPrimary: {
        borderWidth: '2px !important',
      },
      outlinedSecondary: {
        borderWidth: '2px !important',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: 3,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: 0,
        paddingLeft: 21,
        paddingRight: 21,
        transition: 'opacity 0.1s ease',
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
        '&:hover': {
          opacity: 1,
        },
      },
    },
  },
  // @ts-ignore
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      barColorSecondary: {
        backgroundColor: '#FA4343',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&$expanded': {
          '&$expanded': {
            margin: 0,
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&$selected': {
          color: '#fff',
        },
      },
    },
  },
};
