import React from 'react';
import clsx from 'clsx';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const Checkbox = withCoreProps<CheckboxProps>(
  ({ className, ...props }: React.PropsWithChildren<CheckboxProps>) => {
    const clsxClass = clsx(className);

    return <MuiCheckbox {...props} className={clsxClass} />;
  },
);

export interface CheckboxProps extends MuiCheckboxProps, CoreProps {}
