import React from 'react';
import clsx from 'clsx';
import MuiListSubheader, {
  ListSubheaderProps as MuiListSubheaderProps,
} from '@mui/material/ListSubheader';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const ListSubheader = withCoreProps<ListSubheaderProps>(
  ({
    children,
    className,
    ...props
  }: React.PropsWithChildren<ListSubheaderProps>) => {
    // const classes = useStyles({});
    const clsxClass = clsx(className);

    return (
      <MuiListSubheader {...props} className={clsxClass}>
        {children}
      </MuiListSubheader>
    );
  },
);

export interface ListSubheaderProps extends MuiListSubheaderProps, CoreProps {}
