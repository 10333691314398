/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitGetEntityContactQueries
 */
export interface MonitGetEntityContactQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityContactQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityContactQueries
     */
    page_size?: number;
}

/**
 * Check if a given object implements the MonitGetEntityContactQueries interface.
 */
export function instanceOfMonitGetEntityContactQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityContactQueriesFromJSON(json: any): MonitGetEntityContactQueries {
    return MonitGetEntityContactQueriesFromJSONTyped(json, false);
}

export function MonitGetEntityContactQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityContactQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function MonitGetEntityContactQueriesToJSON(value?: MonitGetEntityContactQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
    };
}

