import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { formatMessage } from '@explorer/helpers';
import { Box, BoxProps } from '../Box';
import { Text, TextProps } from '../Text';
import { withCoreProps } from '../../withCoreProps';

const useStyles = ({ borderless }: Partial<ChipBigProps>) =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        chip: {
          borderRadius: 4,
          borderStyle: borderless ? 'none' : 'solid',
          borderWidth: borderless ? 0 : 2,
        },
        critical: {
          backgroundColor: palette.critical[900],
          borderColor: palette.critical[700],
          color: palette.critical[300],
        },
        high: {
          backgroundColor: palette.high[900],
          borderColor: palette.high[700],
          color: palette.high[300],
        },
        medium: {
          backgroundColor: palette.medium[900],
          borderColor: palette.medium[700],
          color: palette.medium[300],
        },
        low: {
          backgroundColor: palette.low[900],
          borderColor: palette.low[700],
          color: palette.low[300],
        },
        info: {
          backgroundColor: palette.info.light,
          borderColor: palette.info.main,
          color: palette.info.dark,
        },
        primary: {
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.main,
          color: palette.primary.dark,
        },
        secondary: {
          backgroundColor: palette.secondary.light,
          borderColor: palette.secondary.main,
          color: palette.secondary.dark,
        },
        neutral: {
          color: palette.grey[300],
          borderColor: palette.grey[700],
          backgroundColor: palette.grey[900],
        },
        clickable: {
          cursor: 'pointer',
          transition: 'opacity .1s ease-out',
          opacity: 1,
          '&:hover': {
            opacity: 0.8,
          },
        },
      }),
    { index: 1 },
  )();

export const ChipBig = withCoreProps<ChipBigProps>((props) => {
  const {
    label,
    value,
    borderless = false,
    fullWidth = false,
    color = 'neutral',
    size = 'large',
    onClick,
    className,
    renderCustomValue,
  } = props;

  const intl = useIntl();
  const classes = useStyles({ borderless });
  const clsxClass = clsx(
    className,
    classes.chip,
    classes[color],
    onClick ? classes.clickable : undefined,
  );

  const sizeProps = useMemo<SizeProps>(() => {
    if (size === 'small') {
      return {
        chip: {
          pt: 1.2,
          pb: 1,
          minWidth: 90,
          width: 120,
        },
        label: {
          fontSize: '0.8rem',
          pb: 1,
        },
        value: {
          fontSize: '1.2rem',
        },
      };
    }

    return {
      chip: {
        pt: 1.4,
        pb: 1.2,
        minWidth: 140,
        width: 140,
      },
      label: {
        fontSize: '0.8rem',
      },
      value: {
        fontSize: '1.8rem',
      },
    };
  }, [size]);

  return (
    <Box
      {...props}
      {...sizeProps.chip}
      width={fullWidth ? '100%' : sizeProps.chip.width}
      textAlign="center"
      borderRadius={4}
      borderStyle={!borderless ? 'solid' : 'none'}
      borderWidth={!borderless ? 2 : 0}
      className={clsxClass}
      onClick={onClick}
      m={1}
    >
      <Text
        {...sizeProps.label}
        fontWeight="bold"
        letterSpacing="0.5px"
        textTransform="capitalize"
      >
        {formatMessage(intl, label)}
      </Text>
      {renderCustomValue ? (
        renderCustomValue()
      ) : (
        <Text {...sizeProps.value} fontWeight="bold">
          {value}
        </Text>
      )}
    </Box>
  );
});

export interface ChipBigProps extends BoxProps {
  /**
   * Chip label
   */
  label: IntlLabel;
  /**
   * Chip value
   */
  value: string | number;
  /**
   * Removes the `background` and `border`
   */
  borderless?: boolean;
  /**
   * Makes the container full width
   */
  fullWidth?: boolean;
  /**
   * Size of the chip
   */
  size?: 'small' | 'large';
  /**
   * Which palette to be used
   */
  color?:
    | 'primary'
    | 'secondary'
    | 'critical'
    | 'high'
    | 'medium'
    | 'low'
    | 'info'
    | 'neutral';
  /**
   * Render custom component in place of value
   */
  renderCustomValue?: any | null | undefined;
}

interface SizeProps {
  chip: Partial<ChipBigProps>;
  label: Partial<TextProps>;
  value: Partial<TextProps>;
}
