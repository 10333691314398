/**
 *
 * Coalition Theme
 *
 */
import { palette, ThemeTypes } from './palette';
import { createTypographyClasses, typography } from './typography';
import { overrides } from './overrides';
import { props } from './props';
import { createTheme, ThemeOptions } from '@mui/material';

export * from './entity';

export const coalition: Record<ThemeTypes, AppTheme<ThemeOptions>> = {
  light: {
    name: 'coalition',
    metaColor: '#0074c1',
    googleFonts: [
      'family=DM+Sans:wght@400;700',
      'family=Public+Sans:wght@400;700',
    ],
    createTypographyClasses,
    muiTheme: createTheme({
      palette: palette.light,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1280,
          xl: 1536,
        },
      },
      typography,
      ...props,
      components: { ...overrides },
    }),
  },
  dark: {
    name: 'coalitionDark',
    metaColor: '#0074c1',
    googleFonts: [
      'family=DM+Sans:wght@400;700',
      'family=Public+Sans:wght@400;700',
    ],
    createTypographyClasses,
    muiTheme: createTheme({
      palette: palette.dark,
      typography,
      ...props,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1280,
          xl: 1536,
        },
      },
      components: { ...overrides },
    }),
  },
};
