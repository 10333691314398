/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlEntityPasswordsAggComposition
 */
export interface ControlEntityPasswordsAggComposition {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggComposition
     */
    letters_only?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggComposition
     */
    numbers_only?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggComposition
     */
    letters_numbers?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityPasswordsAggComposition
     */
    all?: number;
}

/**
 * Check if a given object implements the ControlEntityPasswordsAggComposition interface.
 */
export function instanceOfControlEntityPasswordsAggComposition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityPasswordsAggCompositionFromJSON(json: any): ControlEntityPasswordsAggComposition {
    return ControlEntityPasswordsAggCompositionFromJSONTyped(json, false);
}

export function ControlEntityPasswordsAggCompositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityPasswordsAggComposition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'letters_only': !exists(json, 'letters_only') ? undefined : json['letters_only'],
        'numbers_only': !exists(json, 'numbers_only') ? undefined : json['numbers_only'],
        'letters_numbers': !exists(json, 'letters_numbers') ? undefined : json['letters_numbers'],
        'all': !exists(json, 'all') ? undefined : json['all'],
    };
}

export function ControlEntityPasswordsAggCompositionToJSON(value?: ControlEntityPasswordsAggComposition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'letters_only': value.letters_only,
        'numbers_only': value.numbers_only,
        'letters_numbers': value.letters_numbers,
        'all': value.all,
    };
}

