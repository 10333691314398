/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlSingleSelectFieldOption } from './ControlSingleSelectFieldOption';
import {
    ControlSingleSelectFieldOptionFromJSON,
    ControlSingleSelectFieldOptionFromJSONTyped,
    ControlSingleSelectFieldOptionToJSON,
} from './ControlSingleSelectFieldOption';

/**
 * 
 * @export
 * @interface ControlSingleSelectFieldDefinition
 */
export interface ControlSingleSelectFieldDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlSingleSelectFieldDefinition
     */
    field_type?: ControlSingleSelectFieldDefinitionFieldTypeEnum;
    /**
     * 
     * @type {Array<ControlSingleSelectFieldOption>}
     * @memberof ControlSingleSelectFieldDefinition
     */
    options: Array<ControlSingleSelectFieldOption>;
}


/**
 * @export
 */
export const ControlSingleSelectFieldDefinitionFieldTypeEnum = {
    Singleselect: 'singleselect'
} as const;
export type ControlSingleSelectFieldDefinitionFieldTypeEnum = typeof ControlSingleSelectFieldDefinitionFieldTypeEnum[keyof typeof ControlSingleSelectFieldDefinitionFieldTypeEnum];


/**
 * Check if a given object implements the ControlSingleSelectFieldDefinition interface.
 */
export function instanceOfControlSingleSelectFieldDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "options" in value;

    return isInstance;
}

export function ControlSingleSelectFieldDefinitionFromJSON(json: any): ControlSingleSelectFieldDefinition {
    return ControlSingleSelectFieldDefinitionFromJSONTyped(json, false);
}

export function ControlSingleSelectFieldDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSingleSelectFieldDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_type': !exists(json, 'field_type') ? undefined : json['field_type'],
        'options': ((json['options'] as Array<any>).map(ControlSingleSelectFieldOptionFromJSON)),
    };
}

export function ControlSingleSelectFieldDefinitionToJSON(value?: ControlSingleSelectFieldDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_type': value.field_type,
        'options': ((value.options as Array<any>).map(ControlSingleSelectFieldOptionToJSON)),
    };
}

