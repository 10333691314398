import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from '@explorer/core';
import CloseIcon from '@mui/icons-material/Close';
import { ControlUserResponse } from '@explorer/open-api-client';
import { useV1APIMutation } from '@explorer/query';
import { useQuerySnackbar } from '@explorer/hooks';
import { Button } from '@explorer/core';
import { useIntl } from 'react-intl';

/**
 * DeleteUserDialog
 */
export const ResetAuthenticatorsDialog = ({
  user,
  forceRender,
  onClose,
}: ResetAuthenticatorsDialogProps) => {
  const [checked, setChecked] = useState(false);
  const { snackError, snackSuccess } = useQuerySnackbar();
  const intl = useIntl();

  const { mutate } = useV1APIMutation({
    mutationFn(args, client) {
      return client.v1ControlWebAdminUserUserIdAuthenticatorsDelete({
        user_id: user.user_id,
      });
    },
    onSuccess: () => {
      snackSuccess('Success: MFA reset');
      forceRender();
      onClose();
    },
    onError: (error) => {
      snackError('Error:', error);
    },
  });
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" stopPropagation>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              label={{
                id: 'tables-presets.adminusers-reset-authenticators-title',
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box textAlign="right">
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="left">
          <Typography
            variant="bodyM"
            label={{
              id: 'tables-presets.adminusers-explain-reset-authenticators',
            }}
          />
          <Typography variant="subtitleM" my={1} textAlign='center'>
            {user.email}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            }
            label={intl.formatMessage({
              id: 'tables-presets.adminusers-reset-authenticators-attestation',
            })}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              width={150}
              bgColor="primary"
              onClick={mutate}
              disabled={!checked}
              label={{id: 'global.reset'}}
              data-testid="submit-reset-authenticators-button"
            />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export interface ResetAuthenticatorsDialogProps {
  user: ControlUserResponse;
  forceRender: () => void;
  onClose: () => void;
}
