/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Port } from './Port';
import {
    PortFromJSON,
    PortFromJSONTyped,
    PortToJSON,
} from './Port';

/**
 * 
 * @export
 * @interface CRATech
 */
export interface CRATech {
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    tech_name: string;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    asset: string;
    /**
     * 
     * @type {Port}
     * @memberof CRATech
     */
    port?: Port;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    protocol?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRATech
     */
    category: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CRATech
     */
    data: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRATech
     */
    cves?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRATech
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    found: string;
    /**
     * 
     * @type {string}
     * @memberof CRATech
     */
    updated: string;
}

/**
 * Check if a given object implements the CRATech interface.
 */
export function instanceOfCRATech(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tech_name" in value;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRATechFromJSON(json: any): CRATech {
    return CRATechFromJSONTyped(json, false);
}

export function CRATechFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRATech {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tech_name': json['tech_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'asset': json['asset'],
        'port': !exists(json, 'port') ? undefined : PortFromJSON(json['port']),
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'category': json['category'],
        'data': json['data'],
        'cves': !exists(json, 'cves') ? undefined : json['cves'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'found': json['found'],
        'updated': json['updated'],
    };
}

export function CRATechToJSON(value?: CRATech | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tech_name': value.tech_name,
        'version': value.version,
        'asset': value.asset,
        'port': PortToJSON(value.port),
        'protocol': value.protocol,
        'category': value.category,
        'data': value.data,
        'cves': value.cves,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'found': value.found,
        'updated': value.updated,
    };
}

