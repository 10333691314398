/**
 * Returns a full description of a DNS Record.
 *
 * __> Example:__
 * ```ts
 * getDNSRecordDescription('AAAA');
 * // "Address Records are used to translate a human-readable string into a machine-readable IPv6 address."
 * ```
 */

export const getDNSRecordDescription: GetDNSRecordDescription = (record) => {
  if (!record) return '';

  if (record === 'A') {
    return 'Address Records are used to translate a human-readable string into a machine-readable IPv4 address.';
  }
  if (record === 'AAAA') {
    return 'Address Records are used to translate a human-readable string into a machine-readable IPv6 address.';
  }
  if (record === 'AFSDB') {
    return 'A record of a database (DB) server location in an Andrew File System (AFS) cell, used by AFS clients to locate shared resources.';
  }
  if (record === 'APL') {
    return 'Address Prefix List Record specify a list of IP address ranges for various address families.';
  }
  if (record === 'AXFR') {
    return 'Authoritative Zone Transfer Record is used to support the query initiating a zone transfer, or the sharing of DNS records between name servers.';
  }
  if (record === 'CAA') {
    return 'Certification Authority Authorization Records support a security policy mechanism for domain name holders to explicitly authorize certificate authorities capable of issuing certificates for a given domain name.';
  }
  if (record === 'CDNSKEY') {
    return 'Child of the DNS Key Record, which supports the exchange of IPsec public keys. CDNSKEY records facilitate the transfer of key information to a parent server.';
  }
  if (record === 'CDS') {
    return 'A Child DS Record supports transfer of DS key material to a parent. A DS Records is used to identify the DNSSEC signing key of a delegated zone.';
  }
  if (record === 'CERT') {
    return 'Certificate Record CERT allows digital certificates and information about their revocation status to be stored in the Domain Name System (DNS).';
  }
  if (record === 'CNAME') {
    return 'Canonical Name Records are used as an alias. This enables the utilization of external resources, and for those resources to appear as part of the organization’s domain.';
  }
  if (record === 'CSYNC') {
    return 'Child-to-Parent Synchronization Record specifies a synchronization mechanism between a child and a parent DNS zone.';
  }
  if (record === 'DHCID') {
    return 'DHCP Identifier Record supports the storage of information needed by DHCP to associated domain names and DHCP clients.';
  }
  if (record === 'DLV') {
    return 'DNSSEC Lookaside Validation Record provides a record for publishing trust anchors outside a DNS validation chain. They provide a method for determining trust in DNSSEC when conventional records may not be available.';
  }
  if (record === 'DNAME') {
    return 'Delegation Name Records are similar in function to CNAME records, and allow an alias and subnames to appear as part of an organization’s domain. They enable external resources to appear as if they are part of an organization’s domain.';
  }
  if (record === 'DNSKEY') {
    return 'DNS Key Record is a method for providing a public key required to support DNSSEC.';
  }
  if (record === 'DS') {
    return 'A Delegation Signer Record is used to identify the DNSSEC signing key of a delegated zone.';
  }
  if (record === 'EUI48') {
    return 'MAC Address (EUI-48) Record allows for encoding a 48-bit Ethernet MAC address in a DNS record for physical addressing.';
  }
  if (record === 'EUI64') {
    return 'MAC Address (EUI-64) Record allows for encoding a 64-bit Ethernet MAC address in a DNS record for physical addressing.';
  }
  if (record === 'HINFO') {
    return 'Host Information Record provides guidance for the behavior of DNS servers or clients when a DNS server does not respond to a query type (QTYPE) “ANY”.';
  }
  if (record === 'HIP') {
    return 'Host Identity Protocol Record stores information about a Host Identity address in mobile computing and multihoming environments where DNS and IP name spaces are insufficient to locate hosts.';
  }
  if (record === 'IPSECKEY') {
    return 'IPsec Key Record publishes a public key to be used with IPsec implementations.';
  }
  if (record === 'IXFR') {
    return 'Incremental Zone Transfer Record is used to trigger an incremental zone transfer where only data changed since the last transfer is provided.';
  }
  if (record === 'KEY') {
    return 'Key Record is used to provide a public key within DNS, largely replaced by record type DNSKEY in DNSSEC and IPSECKEY in IPsec.';
  }
  if (record === 'LOC') {
    return 'Location Record specifies a geographic location associated with a domain name.';
  }
  if (record === 'MX') {
    return 'Mail Exchange Records denote where mail for a particular domain should be routed.';
  }
  if (record === 'NAPTR') {
    return 'Naming Authority Pointer Record provides details to be used when regular expressions are supported for rewriting domain names in Uniform Resource Identifiers (URIs).';
  }
  if (record === 'NS') {
    return 'Name Server Records indicate the hosts that should be used as an authoritative source for records for a particular domain.';
  }
  if (record === 'NSEC') {
    return 'Next Secure Record provides authenticated denial of existence for DNS data in DNSSEC, by identifying the authoritative source for proving a name does not exist.';
  }
  if (record === 'NSEC3') {
    return 'Next Secure 3 Record provides authenticated denial of existence for DNS data without permitting the querying of other names in the zone, known as zone walking.';
  }
  if (record === 'NSEC3PARAM') {
    return 'NSEC3 Parameters Record provides parameters used in the NSEC3 protocol, including hash function and salt values.';
  }
  if (record === 'OPENPGPKEY') {
    return 'OpenPGP Public Key Record allows publishing and use of OpenPGP public keys in DNS for a specific email address.';
  }
  if (record === 'OPT') {
    return 'Option Record is a pseudo DNS record designed to support extension mechanisms for the original DNS, a set of backwards-compatible extensions to the original standard designed to support new and larger quantities of data.';
  }
  if (record === 'PTR') {
    return 'PTR Resource Record is a pointer to a canonical name, which links one domain name to another similar to a CNAME record.';
  }
  if (record === 'RP') {
    return 'Responsible Person Record contains email contact information belonging to a person responsible for a specific domain.';
  }
  if (record === 'RRSIG') {
    return 'DNSSEC Signature Record contains the digital signature(s) used in DNSSEC authentication.';
  }
  if (record === 'SIG') {
    return 'Signature Record contains a digital signature for authentication of a domain; replaced by the RRSIG record type in DNSSEC.';
  }
  if (record === 'SMIMEA') {
    return 'S/MIME Certificate Association Record provides an association between a certificate used for S/MIME and a specific domain to establish email sender authentication.';
  }
  if (record === 'SOA') {
    return 'Start of Authority Records contain metadata around the parameters of retrieving records for a particular domain. These records contain a serial number, refresh duration, retry duration, expiry duration, and time to live duration.';
  }
  if (record === 'SPF') {
    return 'Sender Policy Framework Record is a deprecated record type for defining a Sender Policy Framework, which authorizes the use of a domain for email. Largely replaced by SPF details in a TXT record.';
  }
  if (record === 'SRV') {
    return 'Service Locator Record publishes the location of a generic service, rather than creating a specific record type for each service like MX for mail routing information.';
  }
  if (record === 'SVCB') {
    return 'Service Binding Record is a draft record type which provides details needed for a client to access services which are  provided from multiple alternative endpoints. Details of each endpoint’s parameters are included, such as configuration and keys needed to encrypt the TLS ClientHello message.';
  }
  if (record === 'TA') {
    return 'DNSSEC Trust Authorities Record provide trust statements for zones which are not children of the current zone, including keying information needed to authenticate responses from those non-child zones.';
  }
  if (record === 'TKEY') {
    return 'Transaction Key Record provides a method for supplying key material for use with TSIG, an implementation of trust and authentication for DNS similar to DNSSEC.';
  }
  if (record === 'TLSA') {
    return 'TLSA Certificate Association Record provides an association between a certificate or public key used to establish TLS connectivity and the domain where the record is found.';
  }
  if (record === 'TSIG') {
    return 'Transaction Signature Record stores information needed for transaction-level authentication of dynamic zone updates, such as hashing, approved clients, or authentication of responses from a name server.';
  }
  if (record === 'TXT') {
    return 'Text Records are used for a variety of purposes. One of the most common functions is to hold Sender Policy Framework(SPF) strings. It is also frequently used to verify domain ownership.';
  }
  if (record === 'URI') {
    return 'Uniform Resource Identifier Records provide storage for mappings of hostnames to URIs.';
  }

  return '';
};

type GetDNSRecordDescription = (record?: string) => string;
