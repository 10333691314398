/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityFindingsAssetsResult
 */
export interface MonitEntityFindingsAssetsResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    port?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityFindingsAssetsResult
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    score_category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    datapoint?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof MonitEntityFindingsAssetsResult
     */
    evidences?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityFindingsAssetsResult
     */
    risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    finding_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    process_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    monit_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsAssetsResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    as_number?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    as_class?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAssetsResult
     */
    removal_status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsAssetsResult
     */
    resolution_types?: Array<string>;
}

/**
 * Check if a given object implements the MonitEntityFindingsAssetsResult interface.
 */
export function instanceOfMonitEntityFindingsAssetsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityFindingsAssetsResultFromJSON(json: any): MonitEntityFindingsAssetsResult {
    return MonitEntityFindingsAssetsResultFromJSONTyped(json, false);
}

export function MonitEntityFindingsAssetsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityFindingsAssetsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'evidences': !exists(json, 'evidences') ? undefined : json['evidences'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'finding_id': !exists(json, 'finding_id') ? undefined : json['finding_id'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'monit_type': !exists(json, 'monit_type') ? undefined : json['monit_type'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'as_number': !exists(json, 'as_number') ? undefined : json['as_number'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'as_class': !exists(json, 'as_class') ? undefined : json['as_class'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'removal_status': !exists(json, 'removal_status') ? undefined : json['removal_status'],
        'resolution_types': !exists(json, 'resolution_types') ? undefined : json['resolution_types'],
    };
}

export function MonitEntityFindingsAssetsResultToJSON(value?: MonitEntityFindingsAssetsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'asset': value.asset,
        'source_asset': value.source_asset,
        'port': value.port,
        'shared': value.shared,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
        'level': value.level,
        'category': value.category,
        'score_category': value.score_category,
        'datapoint': value.datapoint,
        'evidences': value.evidences,
        'risk_score': value.risk_score,
        'status': value.status,
        'finding_id': value.finding_id,
        'process_id': value.process_id,
        'monit_type': value.monit_type,
        'tags': value.tags,
        'source': value.source,
        'as_number': value.as_number,
        'as_name': value.as_name,
        'as_class': value.as_class,
        'isp': value.isp,
        'removal_status': value.removal_status,
        'resolution_types': value.resolution_types,
    };
}

