/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAppSchemasAdminClientResponse
 */
export interface ControlAppSchemasAdminClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminClientResponse
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminClientResponse
     */
    asm_entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminClientResponse
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminClientResponse
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasAdminClientResponse
     */
    updated_at?: string;
}

/**
 * Check if a given object implements the ControlAppSchemasAdminClientResponse interface.
 */
export function instanceOfControlAppSchemasAdminClientResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ControlAppSchemasAdminClientResponseFromJSON(json: any): ControlAppSchemasAdminClientResponse {
    return ControlAppSchemasAdminClientResponseFromJSONTyped(json, false);
}

export function ControlAppSchemasAdminClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppSchemasAdminClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'asm_entity_id': !exists(json, 'asm_entity_id') ? undefined : json['asm_entity_id'],
        'role': json['role'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function ControlAppSchemasAdminClientResponseToJSON(value?: ControlAppSchemasAdminClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'asm_entity_id': value.asm_entity_id,
        'role': value.role,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}

