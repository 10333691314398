/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFStatusType } from './CRSFStatusType';
import {
    CRSFStatusTypeFromJSON,
    CRSFStatusTypeFromJSONTyped,
    CRSFStatusTypeToJSON,
} from './CRSFStatusType';
import type { CRSFTaskType } from './CRSFTaskType';
import {
    CRSFTaskTypeFromJSON,
    CRSFTaskTypeFromJSONTyped,
    CRSFTaskTypeToJSON,
} from './CRSFTaskType';

/**
 * 
 * @export
 * @interface CRSFTaskResponse
 */
export interface CRSFTaskResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFTaskResponse
     */
    created: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFTaskResponse
     */
    elapsed?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFTaskResponse
     */
    parent_id?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CRSFTaskResponse
     */
    results?: { [key: string]: object; };
    /**
     * 
     * @type {CRSFStatusType}
     * @memberof CRSFTaskResponse
     */
    status: CRSFStatusType;
    /**
     * 
     * @type {string}
     * @memberof CRSFTaskResponse
     */
    task_id: string;
    /**
     * 
     * @type {CRSFTaskType}
     * @memberof CRSFTaskResponse
     */
    task_type: CRSFTaskType;
    /**
     * 
     * @type {string}
     * @memberof CRSFTaskResponse
     */
    updated: string;
}

/**
 * Check if a given object implements the CRSFTaskResponse interface.
 */
export function instanceOfCRSFTaskResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "task_id" in value;
    isInstance = isInstance && "task_type" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFTaskResponseFromJSON(json: any): CRSFTaskResponse {
    return CRSFTaskResponseFromJSONTyped(json, false);
}

export function CRSFTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
        'parent_id': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'results': !exists(json, 'results') ? undefined : json['results'],
        'status': CRSFStatusTypeFromJSON(json['status']),
        'task_id': json['task_id'],
        'task_type': CRSFTaskTypeFromJSON(json['task_type']),
        'updated': json['updated'],
    };
}

export function CRSFTaskResponseToJSON(value?: CRSFTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'elapsed': value.elapsed,
        'parent_id': value.parent_id,
        'results': value.results,
        'status': CRSFStatusTypeToJSON(value.status),
        'task_id': value.task_id,
        'task_type': CRSFTaskTypeToJSON(value.task_type),
        'updated': value.updated,
    };
}

