/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler } from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp';

/**
 * 
 * @export
 * @interface ExecRiskReturnHeader
 */
export interface ExecRiskReturnHeader {
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler}
     * @memberof ExecRiskReturnHeader
     */
    Filer?: ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFiler;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    TaxYr?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    BuildTS?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    ReturnTs?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    ReturnTypeCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    TaxPeriodEndDt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskReturnHeader
     */
    TaxPeriodBeginDt?: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp}
     * @memberof ExecRiskReturnHeader
     */
    PreparerPersonGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp}
     * @memberof ExecRiskReturnHeader
     */
    BusinessOfficerGrp?: ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp;
}

/**
 * Check if a given object implements the ExecRiskReturnHeader interface.
 */
export function instanceOfExecRiskReturnHeader(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskReturnHeaderFromJSON(json: any): ExecRiskReturnHeader {
    return ExecRiskReturnHeaderFromJSONTyped(json, false);
}

export function ExecRiskReturnHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskReturnHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Filer': !exists(json, 'Filer') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerFromJSON(json['Filer']),
        'TaxYr': !exists(json, 'TaxYr') ? undefined : json['TaxYr'],
        'BuildTS': !exists(json, 'BuildTS') ? undefined : json['BuildTS'],
        'ReturnTs': !exists(json, 'ReturnTs') ? undefined : json['ReturnTs'],
        'ReturnTypeCd': !exists(json, 'ReturnTypeCd') ? undefined : json['ReturnTypeCd'],
        'TaxPeriodEndDt': !exists(json, 'TaxPeriodEndDt') ? undefined : json['TaxPeriodEndDt'],
        'TaxPeriodBeginDt': !exists(json, 'TaxPeriodBeginDt') ? undefined : json['TaxPeriodBeginDt'],
        'PreparerPersonGrp': !exists(json, 'PreparerPersonGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSON(json['PreparerPersonGrp']),
        'BusinessOfficerGrp': !exists(json, 'BusinessOfficerGrp') ? undefined : ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSON(json['BusinessOfficerGrp']),
    };
}

export function ExecRiskReturnHeaderToJSON(value?: ExecRiskReturnHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Filer': ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderFilerToJSON(value.Filer),
        'TaxYr': value.TaxYr,
        'BuildTS': value.BuildTS,
        'ReturnTs': value.ReturnTs,
        'ReturnTypeCd': value.ReturnTypeCd,
        'TaxPeriodEndDt': value.TaxPeriodEndDt,
        'TaxPeriodBeginDt': value.TaxPeriodBeginDt,
        'PreparerPersonGrp': ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpToJSON(value.PreparerPersonGrp),
        'BusinessOfficerGrp': ExecRiskAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpToJSON(value.BusinessOfficerGrp),
    };
}

