/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsUpstreamMessageStatus = {
    Sent: 'sent',
    Delivered: 'delivered',
    Bounced: 'bounced',
    BouncedSoft: 'bounced_soft',
    Opened: 'opened',
    Clicked: 'clicked',
    Error: 'error',
    OptedOut: 'opted_out',
    Rejected: 'rejected'
} as const;
export type NotificationsUpstreamMessageStatus = typeof NotificationsUpstreamMessageStatus[keyof typeof NotificationsUpstreamMessageStatus];


export function NotificationsUpstreamMessageStatusFromJSON(json: any): NotificationsUpstreamMessageStatus {
    return NotificationsUpstreamMessageStatusFromJSONTyped(json, false);
}

export function NotificationsUpstreamMessageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsUpstreamMessageStatus {
    return json as NotificationsUpstreamMessageStatus;
}

export function NotificationsUpstreamMessageStatusToJSON(value?: NotificationsUpstreamMessageStatus | null): any {
    return value as any;
}

