/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlRescanReason } from './ControlRescanReason';
import {
    ControlRescanReasonFromJSON,
    ControlRescanReasonFromJSONTyped,
    ControlRescanReasonToJSON,
} from './ControlRescanReason';

/**
 * 
 * @export
 * @interface ControlRescanVulnerabilities
 */
export interface ControlRescanVulnerabilities {
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlRescanVulnerabilities
     */
    datapoints: Array<string>;
    /**
     * 
     * @type {ControlRescanReason}
     * @memberof ControlRescanVulnerabilities
     */
    reason: ControlRescanReason;
    /**
     * 
     * @type {string}
     * @memberof ControlRescanVulnerabilities
     */
    custom_reason?: string;
}

/**
 * Check if a given object implements the ControlRescanVulnerabilities interface.
 */
export function instanceOfControlRescanVulnerabilities(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datapoints" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ControlRescanVulnerabilitiesFromJSON(json: any): ControlRescanVulnerabilities {
    return ControlRescanVulnerabilitiesFromJSONTyped(json, false);
}

export function ControlRescanVulnerabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRescanVulnerabilities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': json['datapoints'],
        'reason': ControlRescanReasonFromJSON(json['reason']),
        'custom_reason': !exists(json, 'custom_reason') ? undefined : json['custom_reason'],
    };
}

export function ControlRescanVulnerabilitiesToJSON(value?: ControlRescanVulnerabilities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': value.datapoints,
        'reason': ControlRescanReasonToJSON(value.reason),
        'custom_reason': value.custom_reason,
    };
}

