import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const STRING_TO_RETURN_WHEN_ERROR = '';

/**
 * Function that provides safe error handling for the `intl.formatMessage()`,
 * which originally breaks everytime the localized message object
 * is not properly structured (why, just why...).
 *
 * __> Example:__
 * ```ts
 * formatMessage(intl, { id: 'global.gamestop' });
 * // 'GameStop'
 *
 * formatMessage(intl, { random: 'Random Object' });
 * // ''
 * ```
 */
export const formatMessage: FormatMessage = (intl, message) => {
  if (!message) return STRING_TO_RETURN_WHEN_ERROR;

  if (typeof message === 'object' && message.id) {
    return intl.formatMessage({ id: message.id }, message?.values || {});
  }
  return typeof message === 'string' ? message : STRING_TO_RETURN_WHEN_ERROR;
};

type FormatMessage = (
  intl: IntlShape,
  message?: IntlLabel | null,
) => string | ReactNode;
