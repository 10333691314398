import {
  CurrencyCode,
  currencyCodeMap,
  formatNumber,
} from '@explorer/helpers';

/**
 * Function that returns formatted claims money like $100,000 USD
 */
export const formatMoneyWithCurrencyCode = (
  amount: number | undefined | null,
  currencyCode: CurrencyCode | undefined,
  format?: string,
) => {
  if (amount === undefined || amount === null || currencyCode === undefined)
    return '';
  const currencySymbol = currencyCodeMap[currencyCode];

  return `${currencySymbol}${formatNumber(amount, format)} ${currencyCode}`;
};
