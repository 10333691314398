/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress
     */
    college_university_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress
     */
    city_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress
     */
    state_abbreviation_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress
     */
    country_cd?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress {
    return ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'college_university_name': !exists(json, 'college_university_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['college_university_name']),
        'city_nm': !exists(json, 'city_nm') ? undefined : json['city_nm'],
        'state_abbreviation_cd': !exists(json, 'state_abbreviation_cd') ? undefined : json['state_abbreviation_cd'],
        'country_cd': !exists(json, 'country_cd') ? undefined : json['country_cd'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'college_university_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.college_university_name),
        'city_nm': value.city_nm,
        'state_abbreviation_cd': value.state_abbreviation_cd,
        'country_cd': value.country_cd,
    };
}

