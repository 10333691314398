import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { initialState, UserContextType } from './context';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type Actions =
  | { type: 'SET_USER'; payload: UserContextType['user'] }
  | { type: 'RESET_USER'; payload?: undefined }
  | { type: 'TRIGGER_SEGMENT'; payload?: undefined };

export const reducer: Reducer<UserContextType, Actions> = (
  prevState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_USER': {
      const {
        scanned,
        risk_score_v2,
        cyber_benchmark,
        policyholder,
        broker,
        staff,
        superuser,
        premium,
      } = payload || {};
      const riskScore = risk_score_v2?.risk_score_v2 || 0;

      const isStaff = staff || false;
      const isPremium = premium || true;
      const isSuperUser = superuser || false;
      const isScanned = !!scanned;
      const isGoodRisk = riskScore < 60;
      const isPolicyholder = policyholder || false;
      const isBroker = broker || false;
      const hasCyberBenchmark =
        cyber_benchmark && Object.keys(cyber_benchmark)?.length > 0;

      const weShouldSellInsuranceToThisGuy =
        isGoodRisk && !isPolicyholder && !isPremium && !isBroker;

      return {
        ...prevState,
        user: payload,
        userFlags: {
          userExists: true,
          hasCyberBenchmark,
          isBroker,
          isGoodRisk,
          isPolicyholder,
          isPremium,
          isScanned,
          isStaff,
          isSuperUser,
          weShouldSellInsuranceToThisGuy,
        },
      };
    }
    case 'TRIGGER_SEGMENT': {
      return { ...prevState, segmentIdentify: true };
    }
    case 'RESET_USER': {
      return { ...initialState };
    }
    default:
      throw new Error(
        `<UserContext> reducer has an invalid dispatch type "${type}"`,
      );
  }
};
