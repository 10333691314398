import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';
import { Box, Button, Grid, Image, Markdown, Typography } from '@explorer/core';
import { appRoutes } from '@explorer/src';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MainLayout } from '../MainLayout';
import { useUser } from '@explorer/stores';

/**
 * ErrorPage
 */
export const ErrorPage = ({
  error = 'unexpected-error',
  resetSentryError,
}: ErrorPageProps) => {
  const { formatMessage } = useIntl();
  const { push, reload, isReady } = useRouter();
  const { addError } = useUnsafeDataDogRum();
  const { user } = useUser();

  useEffect(() => {
    addError(new Error('Error Page Shown'), {
      errorMessage: error,
      permissions: user?.permissions,
    });
  }, [addError, error, user?.permissions]);

  const { errorTitle, errorDetails, errorImage } = useMemo<ErrorPageContent>(
    () => ({
      errorImage:
        error === 'user-not-allowed' || error === 'user-has-no-permissions'
          ? '/img/error-user-not-allowed.png'
          : '/img/error-page-not-found.png',
      errorTitle: formatMessage({
        id: `errors.${error}-title`,
      }),
      errorDetails: formatMessage({
        id: `errors.${error}-details`,
      }),
    }),
    [error, formatMessage],
  );

  const retryCallback = useCallback(() => {
    if (isReady) {
      return resetSentryError ? resetSentryError() : reload();
    }

    return null;
  }, [isReady, reload, resetSentryError]);

  return (
    <MainLayout>
      <Box px={10}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Image
              height={400}
              src={errorImage}
              title={errorTitle}
              noUserSelect
              alt="Permissions Error Image"
            />
          </Grid>
          <Grid item>
            <Typography variant="h3" component="h1" label={errorTitle} />
          </Grid>
          <Grid item textAlign="center" mb={4}>
            <Box px={6} data-testid="error-details">
              <Markdown
                textVariant="body1"
                color="textSecondary"
                md={errorDetails}
              />
            </Box>
          </Grid>
          {error !== 'user-has-no-permissions' && (
            <Grid item>
              {retryCallback && (
                <Button
                  variant="text"
                  color="primary"
                  label={{ id: 'global.retry' }}
                  onClick={retryCallback}
                  mr={1}
                />
              )}
              <Button
                color="primary"
                label={{ id: 'global.back-to-home' }}
                onClick={() => push({ pathname: appRoutes['/'] })}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </MainLayout>
  );
};

/**
 * ErrorPageProps
 */
interface ErrorPageProps {
  readonly error?:
    | 'unexpected-error'
    | 'page-not-found'
    | 'user-not-allowed'
    | 'user-has-no-permissions';
  readonly resetSentryError?: () => void;
}

interface ErrorPageContent {
  readonly errorImage: string;
  readonly errorTitle: string;
  readonly errorDetails: string;
}
