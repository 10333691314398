/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityStatsCriticalityCategories } from './MonitEntityStatsCriticalityCategories';
import {
    MonitEntityStatsCriticalityCategoriesFromJSON,
    MonitEntityStatsCriticalityCategoriesFromJSONTyped,
    MonitEntityStatsCriticalityCategoriesToJSON,
} from './MonitEntityStatsCriticalityCategories';
import type { MonitEntityStatsVulnerabilitiesSpecific } from './MonitEntityStatsVulnerabilitiesSpecific';
import {
    MonitEntityStatsVulnerabilitiesSpecificFromJSON,
    MonitEntityStatsVulnerabilitiesSpecificFromJSONTyped,
    MonitEntityStatsVulnerabilitiesSpecificToJSON,
} from './MonitEntityStatsVulnerabilitiesSpecific';
import type { MonitEntityStatsVulnerabilitiesSsl } from './MonitEntityStatsVulnerabilitiesSsl';
import {
    MonitEntityStatsVulnerabilitiesSslFromJSON,
    MonitEntityStatsVulnerabilitiesSslFromJSONTyped,
    MonitEntityStatsVulnerabilitiesSslToJSON,
} from './MonitEntityStatsVulnerabilitiesSsl';
import type { MonitEntityVulnerabilitiesCount } from './MonitEntityVulnerabilitiesCount';
import {
    MonitEntityVulnerabilitiesCountFromJSON,
    MonitEntityVulnerabilitiesCountFromJSONTyped,
    MonitEntityVulnerabilitiesCountToJSON,
} from './MonitEntityVulnerabilitiesCount';

/**
 * 
 * @export
 * @interface MonitEntityStatsVulnerabilities
 */
export interface MonitEntityStatsVulnerabilities {
    /**
     * 
     * @type {number}
     * @memberof MonitEntityStatsVulnerabilities
     */
    count: number;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsVulnerabilities
     */
    score_categories?: object;
    /**
     * 
     * @type {MonitEntityVulnerabilitiesCount}
     * @memberof MonitEntityStatsVulnerabilities
     */
    criticality?: MonitEntityVulnerabilitiesCount;
    /**
     * 
     * @type {MonitEntityVulnerabilitiesCount}
     * @memberof MonitEntityStatsVulnerabilities
     */
    criticality_sum?: MonitEntityVulnerabilitiesCount;
    /**
     * 
     * @type {MonitEntityStatsCriticalityCategories}
     * @memberof MonitEntityStatsVulnerabilities
     */
    criticality_categories?: MonitEntityStatsCriticalityCategories;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSsl}
     * @memberof MonitEntityStatsVulnerabilities
     */
    ssl?: MonitEntityStatsVulnerabilitiesSsl;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    encryption?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    web?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    iot?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    malware?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    storage?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    remote?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    eol?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    cve?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    dns?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    token?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    exposure?: MonitEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilitiesSpecific}
     * @memberof MonitEntityStatsVulnerabilities
     */
    other?: MonitEntityStatsVulnerabilitiesSpecific;
}

/**
 * Check if a given object implements the MonitEntityStatsVulnerabilities interface.
 */
export function instanceOfMonitEntityStatsVulnerabilities(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function MonitEntityStatsVulnerabilitiesFromJSON(json: any): MonitEntityStatsVulnerabilities {
    return MonitEntityStatsVulnerabilitiesFromJSONTyped(json, false);
}

export function MonitEntityStatsVulnerabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsVulnerabilities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'score_categories': !exists(json, 'score_categories') ? undefined : json['score_categories'],
        'criticality': !exists(json, 'criticality') ? undefined : MonitEntityVulnerabilitiesCountFromJSON(json['criticality']),
        'criticality_sum': !exists(json, 'criticality_sum') ? undefined : MonitEntityVulnerabilitiesCountFromJSON(json['criticality_sum']),
        'criticality_categories': !exists(json, 'criticality_categories') ? undefined : MonitEntityStatsCriticalityCategoriesFromJSON(json['criticality_categories']),
        'ssl': !exists(json, 'ssl') ? undefined : MonitEntityStatsVulnerabilitiesSslFromJSON(json['ssl']),
        'encryption': !exists(json, 'encryption') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['encryption']),
        'web': !exists(json, 'web') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['web']),
        'iot': !exists(json, 'iot') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['iot']),
        'malware': !exists(json, 'malware') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['malware']),
        'storage': !exists(json, 'storage') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['storage']),
        'remote': !exists(json, 'remote') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['remote']),
        'eol': !exists(json, 'eol') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['eol']),
        'cve': !exists(json, 'cve') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['cve']),
        'dns': !exists(json, 'dns') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['dns']),
        'token': !exists(json, 'token') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['token']),
        'exposure': !exists(json, 'exposure') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['exposure']),
        'other': !exists(json, 'other') ? undefined : MonitEntityStatsVulnerabilitiesSpecificFromJSON(json['other']),
    };
}

export function MonitEntityStatsVulnerabilitiesToJSON(value?: MonitEntityStatsVulnerabilities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'score_categories': value.score_categories,
        'criticality': MonitEntityVulnerabilitiesCountToJSON(value.criticality),
        'criticality_sum': MonitEntityVulnerabilitiesCountToJSON(value.criticality_sum),
        'criticality_categories': MonitEntityStatsCriticalityCategoriesToJSON(value.criticality_categories),
        'ssl': MonitEntityStatsVulnerabilitiesSslToJSON(value.ssl),
        'encryption': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.encryption),
        'web': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.web),
        'iot': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.iot),
        'malware': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.malware),
        'storage': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.storage),
        'remote': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.remote),
        'eol': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.eol),
        'cve': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.cve),
        'dns': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.dns),
        'token': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.token),
        'exposure': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.exposure),
        'other': MonitEntityStatsVulnerabilitiesSpecificToJSON(value.other),
    };
}

