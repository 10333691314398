/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlEntityDataLeaksOrderBy = {
    Severity: 'severity',
    Date: 'date',
    Year: 'year',
    Fullname: 'fullname',
    TargetHostname: 'target_hostname',
    User: 'user',
    SourceAsset: 'source_asset',
    Created: 'created',
    Updated: 'updated'
} as const;
export type ControlEntityDataLeaksOrderBy = typeof ControlEntityDataLeaksOrderBy[keyof typeof ControlEntityDataLeaksOrderBy];


export function ControlEntityDataLeaksOrderByFromJSON(json: any): ControlEntityDataLeaksOrderBy {
    return ControlEntityDataLeaksOrderByFromJSONTyped(json, false);
}

export function ControlEntityDataLeaksOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDataLeaksOrderBy {
    return json as ControlEntityDataLeaksOrderBy;
}

export function ControlEntityDataLeaksOrderByToJSON(value?: ControlEntityDataLeaksOrderBy | null): any {
    return value as any;
}

