/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAddressSchemaORM } from './ExecRiskAddressSchemaORM';
import {
    ExecRiskAddressSchemaORMFromJSON,
    ExecRiskAddressSchemaORMFromJSONTyped,
    ExecRiskAddressSchemaORMToJSON,
} from './ExecRiskAddressSchemaORM';

/**
 * 
 * @export
 * @interface ExecRiskTriggerResponse
 */
export interface ExecRiskTriggerResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerResponse
     */
    report_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerResponse
     */
    entity_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskTriggerResponse
     */
    domains: Array<string>;
    /**
     * 
     * @type {ExecRiskAddressSchemaORM}
     * @memberof ExecRiskTriggerResponse
     */
    address: ExecRiskAddressSchemaORM;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskTriggerResponse
     */
    account_id: string;
}

/**
 * Check if a given object implements the ExecRiskTriggerResponse interface.
 */
export function instanceOfExecRiskTriggerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "entity_name" in value;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "account_id" in value;

    return isInstance;
}

export function ExecRiskTriggerResponseFromJSON(json: any): ExecRiskTriggerResponse {
    return ExecRiskTriggerResponseFromJSONTyped(json, false);
}

export function ExecRiskTriggerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskTriggerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': json['process_id'],
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'entity_name': json['entity_name'],
        'domains': json['domains'],
        'address': ExecRiskAddressSchemaORMFromJSON(json['address']),
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'account_id': json['account_id'],
    };
}

export function ExecRiskTriggerResponseToJSON(value?: ExecRiskTriggerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'report_url': value.report_url,
        'entity_name': value.entity_name,
        'domains': value.domains,
        'address': ExecRiskAddressSchemaORMToJSON(value.address),
        'updated': value.updated,
        'account_id': value.account_id,
    };
}

