/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlHyperlink } from './ControlHyperlink';
import {
    ControlHyperlinkFromJSON,
    ControlHyperlinkFromJSONTyped,
    ControlHyperlinkToJSON,
} from './ControlHyperlink';

/**
 * 
 * @export
 * @interface ControlZeroDayAlertInputFields
 */
export interface ControlZeroDayAlertInputFields {
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    short_risk_description: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    long_risk_description: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    how_to_resolve: string;
    /**
     * 
     * @type {Array<ControlHyperlink>}
     * @memberof ControlZeroDayAlertInputFields
     */
    references: Array<ControlHyperlink>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlZeroDayAlertInputFields
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlertInputFields
     */
    first_detected_date?: string;
}

/**
 * Check if a given object implements the ControlZeroDayAlertInputFields interface.
 */
export function instanceOfControlZeroDayAlertInputFields(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "short_risk_description" in value;
    isInstance = isInstance && "long_risk_description" in value;
    isInstance = isInstance && "how_to_resolve" in value;
    isInstance = isInstance && "references" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function ControlZeroDayAlertInputFieldsFromJSON(json: any): ControlZeroDayAlertInputFields {
    return ControlZeroDayAlertInputFieldsFromJSONTyped(json, false);
}

export function ControlZeroDayAlertInputFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAlertInputFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'title': json['title'],
        'short_risk_description': json['short_risk_description'],
        'long_risk_description': json['long_risk_description'],
        'how_to_resolve': json['how_to_resolve'],
        'references': ((json['references'] as Array<any>).map(ControlHyperlinkFromJSON)),
        'active': json['active'],
        'first_detected_date': !exists(json, 'first_detected_date') ? undefined : json['first_detected_date'],
    };
}

export function ControlZeroDayAlertInputFieldsToJSON(value?: ControlZeroDayAlertInputFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'title': value.title,
        'short_risk_description': value.short_risk_description,
        'long_risk_description': value.long_risk_description,
        'how_to_resolve': value.how_to_resolve,
        'references': ((value.references as Array<any>).map(ControlHyperlinkToJSON)),
        'active': value.active,
        'first_detected_date': value.first_detected_date,
    };
}

