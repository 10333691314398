import { ReactNode, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { TextField, TextFieldProps } from '@explorer/core';

/**
 * FieldEmail
 */
export const FieldEmail = ({
  name,
  label,
  autoComplete = 'email',
  setFieldValue,
  setFieldError,
  className,
  ...props
}: FieldEmailProps) => {
  const clsxClass = clsx(className);
  const intl = useIntl();

  const intlLabel = useMemo<string | ReactNode>(
    () => formatMessage(intl, label),
    [intl, label],
  );

  const onChangeEmail = useCallback(
    (e) => {
      const { value } = e.target || {};

      if (setFieldValue) setFieldValue(name, (value || '')?.toLowerCase());
    },
    [name],
  );

  return (
    <TextField
      {...props}
      name={name}
      type="email"
      label={intlLabel}
      autoComplete={autoComplete}
      className={clsxClass}
      onChange={onChangeEmail}
    />
  );
};

/**
 * FieldEmail Props
 */
export type FieldEmailProps = TextFieldProps & {
  /**
   * `id` - html id used to identify the field (used in `end user tests`)
   */
  id: string;
  /**
   * `name` - name of the prop (used in `formik`)
   */
  name: string;
  /**
   * `label` - localized field label
   */
  label: IntlLabel;
  /**
   * `error` - field has a validation error
   */
  error?: boolean;
  /**
   * `setFieldValue` - formik method to set the field value
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  /**
   * `setFieldError` - formik method to set the field error
   */
  setFieldError?: (field: string, message: string) => void;
  /**
   * `autoComplete` - google autocomplete prop
   */
  autoComplete?: 'off' | 'email';
};
