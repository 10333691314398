/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt
     */
    PersonalAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt
     */
    RealAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonalAmt': !exists(json, 'PersonalAmt') ? undefined : json['PersonalAmt'],
        'RealAmt': !exists(json, 'RealAmt') ? undefined : json['RealAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonalAmt': value.PersonalAmt,
        'RealAmt': value.RealAmt,
    };
}

