/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitEntityLookalikesOrderBy = {
    Domain: 'domain',
    Created: 'created',
    Updated: 'updated'
} as const;
export type MonitEntityLookalikesOrderBy = typeof MonitEntityLookalikesOrderBy[keyof typeof MonitEntityLookalikesOrderBy];


export function MonitEntityLookalikesOrderByFromJSON(json: any): MonitEntityLookalikesOrderBy {
    return MonitEntityLookalikesOrderByFromJSONTyped(json, false);
}

export function MonitEntityLookalikesOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityLookalikesOrderBy {
    return json as MonitEntityLookalikesOrderBy;
}

export function MonitEntityLookalikesOrderByToJSON(value?: MonitEntityLookalikesOrderBy | null): any {
    return value as any;
}

