import { useMemo } from 'react';

/**
 * Returns the AppLocale object from the AppConfig.
 *
 * __> Example:__
 * ```
 * useAppLocale(appConfig);
 * // { appLocale }
 * ```
 */

const BE_LOCALE = 'BE_LOCALE';

export const useAppLocale: UseAppLocale = ({ LOCALES }) => {
  const defaultLocale = LOCALES[0];

  if (!process.browser) return defaultLocale;

  const appLocale = useMemo(() => {
    try {
      const code = localStorage?.getItem(BE_LOCALE) as string;

      if (defaultLocale.code !== code) {
        for (let i = 0, ii = LOCALES.length; i < ii; i++) {
          const theme = LOCALES[i];
          if (theme.code === code) {
            localStorage.setItem(BE_LOCALE, theme.code);
            return theme;
          }
        }
        localStorage.setItem(BE_LOCALE, defaultLocale.code);
      }
      return defaultLocale;
    } catch (err) {
      return defaultLocale;
    }
  }, []);

  return appLocale;
};

type UseAppLocale = (appConfig: AppConfig) => AppLocale;
