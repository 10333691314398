import React from 'react';
import { useTheme } from '@mui/material';
import { Tooltip, Box, IconFlag } from '@explorer/core';

/**
 * CellFlag
 */
export const CellFlag = ({
  size = '20px',
  countryCode,
  tooltipLabel,
  mr = 0,
}: CellFlagProps) => {
  const { palette } = useTheme();

  if (!countryCode) {
    return (
      <Box width={size} height={size} bgColor={palette.grey[800]} mr={mr} />
    );
  }

  if (tooltipLabel) {
    return (
      <Tooltip title={tooltipLabel} placement="right">
        <IconFlag countryCode={countryCode} size={size} mr={mr} />
      </Tooltip>
    );
  }

  return <IconFlag countryCode={countryCode} size={size} mr={mr} />;
};

/**
 * CellFlag Props
 */
export interface CellFlagProps {
  readonly size?: string;
  readonly countryCode?: string;
  readonly tooltipLabel?: string;
  readonly mr?: number;
}
