/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityPastebinResultMetadata } from './MonitEntityPastebinResultMetadata';
import {
    MonitEntityPastebinResultMetadataFromJSON,
    MonitEntityPastebinResultMetadataFromJSONTyped,
    MonitEntityPastebinResultMetadataToJSON,
} from './MonitEntityPastebinResultMetadata';

/**
 * 
 * @export
 * @interface MonitEntityPastebinResult
 */
export interface MonitEntityPastebinResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    url?: string;
    /**
     * 
     * @type {MonitEntityPastebinResultMetadata}
     * @memberof MonitEntityPastebinResult
     */
    metadata?: MonitEntityPastebinResultMetadata;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityPastebinResult
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    found?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResult
     */
    updated?: string;
}

/**
 * Check if a given object implements the MonitEntityPastebinResult interface.
 */
export function instanceOfMonitEntityPastebinResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityPastebinResultFromJSON(json: any): MonitEntityPastebinResult {
    return MonitEntityPastebinResultFromJSONTyped(json, false);
}

export function MonitEntityPastebinResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityPastebinResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'metadata': !exists(json, 'metadata') ? undefined : MonitEntityPastebinResultMetadataFromJSON(json['metadata']),
        'content': !exists(json, 'content') ? undefined : json['content'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'found': !exists(json, 'found') ? undefined : json['found'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function MonitEntityPastebinResultToJSON(value?: MonitEntityPastebinResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'key': value.key,
        'url': value.url,
        'metadata': MonitEntityPastebinResultMetadataToJSON(value.metadata),
        'content': value.content,
        'keywords': value.keywords,
        'found': value.found,
        'created': value.created,
        'updated': value.updated,
    };
}

