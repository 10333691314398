/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAnswer } from './ControlAnswer';
import {
    ControlAnswerFromJSON,
    ControlAnswerFromJSONTyped,
    ControlAnswerToJSON,
} from './ControlAnswer';
import type { ControlFormDefinition } from './ControlFormDefinition';
import {
    ControlFormDefinitionFromJSON,
    ControlFormDefinitionFromJSONTyped,
    ControlFormDefinitionToJSON,
} from './ControlFormDefinition';
import type { ControlFormProgress } from './ControlFormProgress';
import {
    ControlFormProgressFromJSON,
    ControlFormProgressFromJSONTyped,
    ControlFormProgressToJSON,
} from './ControlFormProgress';
import type { ControlSuggestedAnswer } from './ControlSuggestedAnswer';
import {
    ControlSuggestedAnswerFromJSON,
    ControlSuggestedAnswerFromJSONTyped,
    ControlSuggestedAnswerToJSON,
} from './ControlSuggestedAnswer';

/**
 * 
 * @export
 * @interface ControlGetFormResponse
 */
export interface ControlGetFormResponse {
    /**
     * 
     * @type {ControlFormDefinition}
     * @memberof ControlGetFormResponse
     */
    definition: ControlFormDefinition;
    /**
     * 
     * @type {ControlFormProgress}
     * @memberof ControlGetFormResponse
     */
    progress: ControlFormProgress;
    /**
     * 
     * @type {{ [key: string]: ControlAnswer; }}
     * @memberof ControlGetFormResponse
     */
    answers: { [key: string]: ControlAnswer; };
    /**
     * 
     * @type {{ [key: string]: Array<ControlSuggestedAnswer>; }}
     * @memberof ControlGetFormResponse
     */
    suggested_answers: { [key: string]: Array<ControlSuggestedAnswer>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlGetFormResponse
     */
    visible_questions: Array<string>;
}

/**
 * Check if a given object implements the ControlGetFormResponse interface.
 */
export function instanceOfControlGetFormResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "progress" in value;
    isInstance = isInstance && "answers" in value;
    isInstance = isInstance && "suggested_answers" in value;
    isInstance = isInstance && "visible_questions" in value;

    return isInstance;
}

export function ControlGetFormResponseFromJSON(json: any): ControlGetFormResponse {
    return ControlGetFormResponseFromJSONTyped(json, false);
}

export function ControlGetFormResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetFormResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': ControlFormDefinitionFromJSON(json['definition']),
        'progress': ControlFormProgressFromJSON(json['progress']),
        'answers': (mapValues(json['answers'], ControlAnswerFromJSON)),
        'suggested_answers': json['suggested_answers'],
        'visible_questions': json['visible_questions'],
    };
}

export function ControlGetFormResponseToJSON(value?: ControlGetFormResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': ControlFormDefinitionToJSON(value.definition),
        'progress': ControlFormProgressToJSON(value.progress),
        'answers': (mapValues(value.answers, ControlAnswerToJSON)),
        'suggested_answers': value.suggested_answers,
        'visible_questions': value.visible_questions,
    };
}

