/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlQuotaUpdate
 */
export interface ControlQuotaUpdate {
    /**
     * 
     * @type {number}
     * @memberof ControlQuotaUpdate
     */
    lite_quota: number;
    /**
     * 
     * @type {number}
     * @memberof ControlQuotaUpdate
     */
    extended_quota: number;
}

/**
 * Check if a given object implements the ControlQuotaUpdate interface.
 */
export function instanceOfControlQuotaUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lite_quota" in value;
    isInstance = isInstance && "extended_quota" in value;

    return isInstance;
}

export function ControlQuotaUpdateFromJSON(json: any): ControlQuotaUpdate {
    return ControlQuotaUpdateFromJSONTyped(json, false);
}

export function ControlQuotaUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlQuotaUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lite_quota': json['lite_quota'],
        'extended_quota': json['extended_quota'],
    };
}

export function ControlQuotaUpdateToJSON(value?: ControlQuotaUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lite_quota': value.lite_quota,
        'extended_quota': value.extended_quota,
    };
}

