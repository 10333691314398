/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlLicenseResponse } from './ControlLicenseResponse';
import {
    ControlLicenseResponseFromJSON,
    ControlLicenseResponseFromJSONTyped,
    ControlLicenseResponseToJSON,
} from './ControlLicenseResponse';

/**
 * 
 * @export
 * @interface ControlLicenseResponseCollection
 */
export interface ControlLicenseResponseCollection {
    /**
     * 
     * @type {number}
     * @memberof ControlLicenseResponseCollection
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlLicenseResponseCollection
     */
    lite_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlLicenseResponseCollection
     */
    lite_quota?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlLicenseResponseCollection
     */
    extended_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlLicenseResponseCollection
     */
    extended_quota?: number;
    /**
     * 
     * @type {Array<ControlLicenseResponse>}
     * @memberof ControlLicenseResponseCollection
     */
    results?: Array<ControlLicenseResponse>;
}

/**
 * Check if a given object implements the ControlLicenseResponseCollection interface.
 */
export function instanceOfControlLicenseResponseCollection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlLicenseResponseCollectionFromJSON(json: any): ControlLicenseResponseCollection {
    return ControlLicenseResponseCollectionFromJSONTyped(json, false);
}

export function ControlLicenseResponseCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlLicenseResponseCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'lite_count': !exists(json, 'lite_count') ? undefined : json['lite_count'],
        'lite_quota': !exists(json, 'lite_quota') ? undefined : json['lite_quota'],
        'extended_count': !exists(json, 'extended_count') ? undefined : json['extended_count'],
        'extended_quota': !exists(json, 'extended_quota') ? undefined : json['extended_quota'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlLicenseResponseFromJSON)),
    };
}

export function ControlLicenseResponseCollectionToJSON(value?: ControlLicenseResponseCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'lite_count': value.lite_count,
        'lite_quota': value.lite_quota,
        'extended_count': value.extended_count,
        'extended_quota': value.extended_quota,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlLicenseResponseToJSON)),
    };
}

