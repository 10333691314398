/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
 */
export interface ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    person_name?: string;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    business_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    foreign_address?: ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    service_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    compensation_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    return ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'service_type_txt': !exists(json, 'service_type_txt') ? undefined : json['service_type_txt'],
        'compensation_amt': !exists(json, 'compensation_amt') ? undefined : json['compensation_amt'],
    };
}

export function ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpToJSON(value?: ExecRiskAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'business_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'service_type_txt': value.service_type_txt,
        'compensation_amt': value.compensation_amt,
    };
}

