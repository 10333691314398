/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalAccountPackageRecipient } from './SupplementalAccountPackageRecipient';
import {
    SupplementalAccountPackageRecipientFromJSON,
    SupplementalAccountPackageRecipientFromJSONTyped,
    SupplementalAccountPackageRecipientToJSON,
} from './SupplementalAccountPackageRecipient';

/**
 * 
 * @export
 * @interface SupplementalGetAccountQuotesResponseResultsInner
 */
export interface SupplementalGetAccountQuotesResponseResultsInner {
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    coalition_policy_number?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    lifecycle_state?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    domain_names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    end_date?: string;
    /**
     * 
     * @type {SupplementalAccountPackageRecipient}
     * @memberof SupplementalGetAccountQuotesResponseResultsInner
     */
    recipient?: SupplementalAccountPackageRecipient;
}

/**
 * Check if a given object implements the SupplementalGetAccountQuotesResponseResultsInner interface.
 */
export function instanceOfSupplementalGetAccountQuotesResponseResultsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementalGetAccountQuotesResponseResultsInnerFromJSON(json: any): SupplementalGetAccountQuotesResponseResultsInner {
    return SupplementalGetAccountQuotesResponseResultsInnerFromJSONTyped(json, false);
}

export function SupplementalGetAccountQuotesResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalGetAccountQuotesResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'coalition_policy_number': !exists(json, 'coalition_policy_number') ? undefined : json['coalition_policy_number'],
        'lifecycle_state': !exists(json, 'lifecycle_state') ? undefined : json['lifecycle_state'],
        'domain_names': !exists(json, 'domain_names') ? undefined : json['domain_names'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'recipient': !exists(json, 'recipient') ? undefined : SupplementalAccountPackageRecipientFromJSON(json['recipient']),
    };
}

export function SupplementalGetAccountQuotesResponseResultsInnerToJSON(value?: SupplementalGetAccountQuotesResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'coalition_policy_number': value.coalition_policy_number,
        'lifecycle_state': value.lifecycle_state,
        'domain_names': value.domain_names,
        'end_date': value.end_date,
        'recipient': SupplementalAccountPackageRecipientToJSON(value.recipient),
    };
}

