import { ApiClientParams } from '@explorer/api';
import { paramsToQuerystring } from '../paramsToQuerystring';

/**
 * Return the correct version of an "url" based on the
 * `process.env.TRAIL` environmental variable.
 *
 * __> Example:__
 * ```ts
 * trailingUrl('/some/url'); // TRAIL=true
 * // "/some/url/"
 *
 * trailingUrl('/some/url', {id: 1}); // TRAIL=true
 * // "/some/url/?id=1"
 * ```
 */

export const trailingUrl: TrailingUrl = (url, params) => {
  const hasTrail = url[url.length - 1] === '/';

  const u = process.env.TRAIL ? (hasTrail ? url : `${url}/`) : url;

  if (!params || !Object.keys(params)?.length) return u;

  const querystring = paramsToQuerystring(params);

  return `${u}${querystring}`;
};

type TrailingUrl = (url: string, params?: ApiClientParams) => string;
