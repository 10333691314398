/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewDesiredPolicyFieldsOutput } from './CRSFSecReviewDesiredPolicyFieldsOutput';
import {
    CRSFSecReviewDesiredPolicyFieldsOutputFromJSON,
    CRSFSecReviewDesiredPolicyFieldsOutputFromJSONTyped,
    CRSFSecReviewDesiredPolicyFieldsOutputToJSON,
} from './CRSFSecReviewDesiredPolicyFieldsOutput';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';
import type { CRSFSecReviewTicketPayloadOutput } from './CRSFSecReviewTicketPayloadOutput';
import {
    CRSFSecReviewTicketPayloadOutputFromJSON,
    CRSFSecReviewTicketPayloadOutputFromJSONTyped,
    CRSFSecReviewTicketPayloadOutputToJSON,
} from './CRSFSecReviewTicketPayloadOutput';

/**
 * 
 * @export
 * @interface CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
 */
export interface CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    asm_entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    asm_external_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    attachment_domains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    cobranded?: string;
    /**
     * 
     * @type {CRSFSecReviewDesiredPolicyFieldsOutput}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    desired_policy_fields?: CRSFSecReviewDesiredPolicyFieldsOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    domains: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    dry_run?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    issue_key?: string;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    new_domains_at_bind?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    request_origin?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    retained_from_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewTicketPayloadOutput}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    ticket_payload?: CRSFSecReviewTicketPayloadOutput;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse interface.
 */
export function instanceOfCRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;
    isInstance = isInstance && "process_id" in value;

    return isInstance;
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSON(json: any): CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    return CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'asm_entity_id': !exists(json, 'asm_entity_id') ? undefined : json['asm_entity_id'],
        'asm_external_id': !exists(json, 'asm_external_id') ? undefined : json['asm_external_id'],
        'attachment_domains': !exists(json, 'attachment_domains') ? undefined : json['attachment_domains'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_fields': !exists(json, 'desired_policy_fields') ? undefined : CRSFSecReviewDesiredPolicyFieldsOutputFromJSON(json['desired_policy_fields']),
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'domains': json['domains'],
        'dry_run': !exists(json, 'dry_run') ? undefined : json['dry_run'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'issue_key': !exists(json, 'issue_key') ? undefined : json['issue_key'],
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'new_domains_at_bind': !exists(json, 'new_domains_at_bind') ? undefined : json['new_domains_at_bind'],
        'process_id': json['process_id'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'ticket_payload': !exists(json, 'ticket_payload') ? undefined : CRSFSecReviewTicketPayloadOutputFromJSON(json['ticket_payload']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponseToJSON(value?: CRSFSecReviewSrcHttpdlibCoreModelsSecondaryTriggerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_uuid': value.account_uuid,
        'asm_entity_id': value.asm_entity_id,
        'asm_external_id': value.asm_external_id,
        'attachment_domains': value.attachment_domains,
        'cobranded': value.cobranded,
        'desired_policy_fields': CRSFSecReviewDesiredPolicyFieldsOutputToJSON(value.desired_policy_fields),
        'desired_policy_uuid': value.desired_policy_uuid,
        'domains': value.domains,
        'dry_run': value.dry_run,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'issue_key': value.issue_key,
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'new_domains_at_bind': value.new_domains_at_bind,
        'process_id': value.process_id,
        'renewal_of_uuid': value.renewal_of_uuid,
        'request_origin': value.request_origin,
        'retained_from_uuid': value.retained_from_uuid,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'ticket_payload': CRSFSecReviewTicketPayloadOutputToJSON(value.ticket_payload),
        'user': value.user,
    };
}

