/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityLookalikesOrderBy } from './MonitEntityLookalikesOrderBy';
import {
    MonitEntityLookalikesOrderByFromJSON,
    MonitEntityLookalikesOrderByFromJSONTyped,
    MonitEntityLookalikesOrderByToJSON,
} from './MonitEntityLookalikesOrderBy';

/**
 * 
 * @export
 * @interface MonitGetEntityLookalikesQuery
 */
export interface MonitGetEntityLookalikesQuery {
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityLookalikesQuery
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitGetEntityLookalikesQuery
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityLookalikesQuery
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityLookalikesQuery
     */
    process_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityLookalikesQuery
     */
    mx?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityLookalikesQuery
     */
    ssl?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitGetEntityLookalikesQuery
     */
    domain?: string;
    /**
     * 
     * @type {MonitEntityLookalikesOrderBy}
     * @memberof MonitGetEntityLookalikesQuery
     */
    order_by?: MonitEntityLookalikesOrderBy;
    /**
     * 
     * @type {boolean}
     * @memberof MonitGetEntityLookalikesQuery
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the MonitGetEntityLookalikesQuery interface.
 */
export function instanceOfMonitGetEntityLookalikesQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitGetEntityLookalikesQueryFromJSON(json: any): MonitGetEntityLookalikesQuery {
    return MonitGetEntityLookalikesQueryFromJSONTyped(json, false);
}

export function MonitGetEntityLookalikesQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitGetEntityLookalikesQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'mx': !exists(json, 'mx') ? undefined : json['mx'],
        'ssl': !exists(json, 'ssl') ? undefined : json['ssl'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'order_by': !exists(json, 'order_by') ? undefined : MonitEntityLookalikesOrderByFromJSON(json['order_by']),
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function MonitGetEntityLookalikesQueryToJSON(value?: MonitGetEntityLookalikesQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'search': value.search,
        'process_id': value.process_id,
        'mx': value.mx,
        'ssl': value.ssl,
        'domain': value.domain,
        'order_by': MonitEntityLookalikesOrderByToJSON(value.order_by),
        'desc': value.desc,
    };
}

