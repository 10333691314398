/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewTargetItem
 */
export interface CRSFSecReviewTargetItem {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    as_class?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    as_class_t?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    as_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    asn?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    ip: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    isp?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    org?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewTargetItem
     */
    port: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTargetItem
     */
    protocol: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTargetItem
     */
    shared: boolean;
}

/**
 * Check if a given object implements the CRSFSecReviewTargetItem interface.
 */
export function instanceOfCRSFSecReviewTargetItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ip" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "protocol" in value;
    isInstance = isInstance && "shared" in value;

    return isInstance;
}

export function CRSFSecReviewTargetItemFromJSON(json: any): CRSFSecReviewTargetItem {
    return CRSFSecReviewTargetItemFromJSONTyped(json, false);
}

export function CRSFSecReviewTargetItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTargetItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'as_class': !exists(json, 'as_class') ? undefined : json['as_class'],
        'as_class_t': !exists(json, 'as_class_t') ? undefined : json['as_class_t'],
        'as_name': !exists(json, 'as_name') ? undefined : json['as_name'],
        'asn': !exists(json, 'asn') ? undefined : json['asn'],
        'ip': json['ip'],
        'isp': !exists(json, 'isp') ? undefined : json['isp'],
        'org': !exists(json, 'org') ? undefined : json['org'],
        'port': json['port'],
        'protocol': json['protocol'],
        'shared': json['shared'],
    };
}

export function CRSFSecReviewTargetItemToJSON(value?: CRSFSecReviewTargetItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'as_class': value.as_class,
        'as_class_t': value.as_class_t,
        'as_name': value.as_name,
        'asn': value.asn,
        'ip': value.ip,
        'isp': value.isp,
        'org': value.org,
        'port': value.port,
        'protocol': value.protocol,
        'shared': value.shared,
    };
}

