/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDomainsBlocklistRecord } from './CRSFDomainsBlocklistRecord';
import {
    CRSFDomainsBlocklistRecordFromJSON,
    CRSFDomainsBlocklistRecordFromJSONTyped,
    CRSFDomainsBlocklistRecordToJSON,
} from './CRSFDomainsBlocklistRecord';

/**
 * 
 * @export
 * @interface CRSFMalware
 */
export interface CRSFMalware {
    /**
     * 
     * @type {Array<CRSFDomainsBlocklistRecord>}
     * @memberof CRSFMalware
     */
    samples?: Array<CRSFDomainsBlocklistRecord>;
    /**
     * 
     * @type {number}
     * @memberof CRSFMalware
     */
    total: number;
}

/**
 * Check if a given object implements the CRSFMalware interface.
 */
export function instanceOfCRSFMalware(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CRSFMalwareFromJSON(json: any): CRSFMalware {
    return CRSFMalwareFromJSONTyped(json, false);
}

export function CRSFMalwareFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFMalware {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'samples': !exists(json, 'samples') ? undefined : ((json['samples'] as Array<any>).map(CRSFDomainsBlocklistRecordFromJSON)),
        'total': json['total'],
    };
}

export function CRSFMalwareToJSON(value?: CRSFMalware | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'samples': value.samples === undefined ? undefined : ((value.samples as Array<any>).map(CRSFDomainsBlocklistRecordToJSON)),
        'total': value.total,
    };
}

