/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    business_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    foreign_address?: ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    primary_activities_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    legal_domicile_state_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    legal_domicile_foreign_country_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    predominate_income_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    all_partners_c_3_s_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    share_of_total_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    share_of_eoy_assets_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    disproportionate_allocations_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ubi_code_v_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    general_or_managing_partner_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ownership_pct?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership {
    return ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'primary_activities_txt': !exists(json, 'primary_activities_txt') ? undefined : json['primary_activities_txt'],
        'legal_domicile_state_cd': !exists(json, 'legal_domicile_state_cd') ? undefined : json['legal_domicile_state_cd'],
        'legal_domicile_foreign_country_cd': !exists(json, 'legal_domicile_foreign_country_cd') ? undefined : json['legal_domicile_foreign_country_cd'],
        'predominate_income_desc': !exists(json, 'predominate_income_desc') ? undefined : json['predominate_income_desc'],
        'all_partners_c_3_s_ind': !exists(json, 'all_partners_c_3_s_ind') ? undefined : json['all_partners_c_3_s_ind'],
        'share_of_total_income_amt': !exists(json, 'share_of_total_income_amt') ? undefined : json['share_of_total_income_amt'],
        'share_of_eoy_assets_amt': !exists(json, 'share_of_eoy_assets_amt') ? undefined : json['share_of_eoy_assets_amt'],
        'disproportionate_allocations_ind': !exists(json, 'disproportionate_allocations_ind') ? undefined : json['disproportionate_allocations_ind'],
        'ubi_code_v_amt': !exists(json, 'ubi_code_v_amt') ? undefined : json['ubi_code_v_amt'],
        'general_or_managing_partner_ind': !exists(json, 'general_or_managing_partner_ind') ? undefined : json['general_or_managing_partner_ind'],
        'ownership_pct': !exists(json, 'ownership_pct') ? undefined : json['ownership_pct'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'ein': value.ein,
        'primary_activities_txt': value.primary_activities_txt,
        'legal_domicile_state_cd': value.legal_domicile_state_cd,
        'legal_domicile_foreign_country_cd': value.legal_domicile_foreign_country_cd,
        'predominate_income_desc': value.predominate_income_desc,
        'all_partners_c_3_s_ind': value.all_partners_c_3_s_ind,
        'share_of_total_income_amt': value.share_of_total_income_amt,
        'share_of_eoy_assets_amt': value.share_of_eoy_assets_amt,
        'disproportionate_allocations_ind': value.disproportionate_allocations_ind,
        'ubi_code_v_amt': value.ubi_code_v_amt,
        'general_or_managing_partner_ind': value.general_or_managing_partner_ind,
        'ownership_pct': value.ownership_pct,
    };
}

