import { GridColDef } from '@crucible-risk/security-applications';
import { Typography, TypographyDate } from '@explorer/core';
import { appConfig } from '@explorer/src';

export type SupportedSystemsTableRow = {
  id: number;
  name: string;
  type: string;
  endDate: string;
  expired: string;
};

export const useGetColumns = (formatMessage) => {
  const columns: GridColDef<SupportedSystemsTableRow>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'global.name' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="bodyS">{params.row.name}</Typography>
      ),
    },
    {
      field: 'type',
      headerName: formatMessage({ id: 'global.type' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="bodyS">{params.row.type}</Typography>
      ),
    },
    {
      field: 'expired',
      headerName: formatMessage({ id: 'global.expired' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="bodyS">{params.row.expired}</Typography>
      ),
    },
    {
      field: 'endDate',
      headerName: formatMessage({ id: 'global.end-date' }),
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <TypographyDate
          format={appConfig.FORMATS.DATETIME}
          variant="bodyS"
          date={params.row.endDate}
        />
      ),
    },
  ];

  return { columns };
};
