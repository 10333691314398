import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * MultimediaLiabilityIcon
 */
export const MultimediaLiabilityIcon = ({
  ...props
}: MultimediaLiabilityIconProps) => {
  return (
    <Icon label="media_liability" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24,21.7V10H8.2v10.3l3.2-3.8c0.4-0.4,1-0.4,1.4,0l3.7,4.4l2.8-2.7c0.4-0.3,0.9-0.3,1.3,0L24,21.7z M7.7,8h16.8C25.3,8,26,8.7,26,9.5v13c0,0.8-0.7,1.5-1.5,1.5H7.7c-0.8,0-1.5-0.7-1.5-1.5v-13C6.2,8.7,6.9,8,7.7,8z M16.5,12.2c-0.4,0-0.8,0.4-0.8,0.8v0.6c0,0.1,0,0.1,0.1,0.1h1.4c0.1,0,0.1,0,0.1-0.1V13C17.3,12.6,16.9,12.2,16.5,12.2z M18.3,17.2h-3.6c-0.3,0-0.6-0.3-0.6-0.6v-2.2c0-0.3,0.3-0.6,0.6-0.6h0.1c0.1,0,0.1-0.1,0.1-0.1V13c0-0.9,0.7-1.7,1.6-1.7c0.9,0,1.6,0.7,1.6,1.7v0.6c0,0.1,0.1,0.1,0.1,0.1h0.1c0.3,0,0.6,0.3,0.6,0.6v2.2C18.9,16.9,18.6,17.2,18.3,17.2z"
        />
      </g>
    </Icon>
  );
};

/**
 * MultimediaLiabilityIcon Props
 */
export interface MultimediaLiabilityIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
