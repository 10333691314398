/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskNativeLandsEntry } from './ExecRiskNativeLandsEntry';
import {
    ExecRiskNativeLandsEntryFromJSON,
    ExecRiskNativeLandsEntryFromJSONTyped,
    ExecRiskNativeLandsEntryToJSON,
} from './ExecRiskNativeLandsEntry';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedNativeLandsResponse
 */
export interface ExecRiskPaginatedNativeLandsResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedNativeLandsResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskNativeLandsEntry>}
     * @memberof ExecRiskPaginatedNativeLandsResponse
     */
    results: Array<ExecRiskNativeLandsEntry>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedNativeLandsResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedNativeLandsResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedNativeLandsResponse
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedNativeLandsResponse interface.
 */
export function instanceOfExecRiskPaginatedNativeLandsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedNativeLandsResponseFromJSON(json: any): ExecRiskPaginatedNativeLandsResponse {
    return ExecRiskPaginatedNativeLandsResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedNativeLandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedNativeLandsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskNativeLandsEntryFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedNativeLandsResponseToJSON(value?: ExecRiskPaginatedNativeLandsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskNativeLandsEntryToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

