/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlProductPurchaseLinkRequest
 */
export interface ControlProductPurchaseLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlProductPurchaseLinkRequest
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlProductPurchaseLinkRequest
     */
    success_url: string;
    /**
     * 
     * @type {number}
     * @memberof ControlProductPurchaseLinkRequest
     */
    quantity?: number;
}

/**
 * Check if a given object implements the ControlProductPurchaseLinkRequest interface.
 */
export function instanceOfControlProductPurchaseLinkRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "success_url" in value;

    return isInstance;
}

export function ControlProductPurchaseLinkRequestFromJSON(json: any): ControlProductPurchaseLinkRequest {
    return ControlProductPurchaseLinkRequestFromJSONTyped(json, false);
}

export function ControlProductPurchaseLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlProductPurchaseLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_id': json['product_id'],
        'success_url': json['success_url'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function ControlProductPurchaseLinkRequestToJSON(value?: ControlProductPurchaseLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.product_id,
        'success_url': value.success_url,
        'quantity': value.quantity,
    };
}

