/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlExceptionStatus } from './ControlExceptionStatus';
import {
    ControlExceptionStatusFromJSON,
    ControlExceptionStatusFromJSONTyped,
    ControlExceptionStatusToJSON,
} from './ControlExceptionStatus';

/**
 * 
 * @export
 * @interface ControlIgnoredFindingsResult
 */
export interface ControlIgnoredFindingsResult {
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlIgnoredFindingsResult
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlIgnoredFindingsResult
     */
    reason?: string;
    /**
     * 
     * @type {ControlExceptionStatus}
     * @memberof ControlIgnoredFindingsResult
     */
    status: ControlExceptionStatus;
}

/**
 * Check if a given object implements the ControlIgnoredFindingsResult interface.
 */
export function instanceOfControlIgnoredFindingsResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ControlIgnoredFindingsResultFromJSON(json: any): ControlIgnoredFindingsResult {
    return ControlIgnoredFindingsResultFromJSONTyped(json, false);
}

export function ControlIgnoredFindingsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlIgnoredFindingsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'status': ControlExceptionStatusFromJSON(json['status']),
    };
}

export function ControlIgnoredFindingsResultToJSON(value?: ControlIgnoredFindingsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'updated': value.updated,
        'count': value.count,
        'datapoint': value.datapoint,
        'email': value.email,
        'level': value.level,
        'name': value.name,
        'notes': value.notes,
        'reason': value.reason,
        'status': ControlExceptionStatusToJSON(value.status),
    };
}

