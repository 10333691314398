/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LienDetails } from './LienDetails';
import {
    LienDetailsFromJSON,
    LienDetailsFromJSONTyped,
    LienDetailsToJSON,
} from './LienDetails';

/**
 * 
 * @export
 * @interface ExecRiskExperianLienDetails
 */
export interface ExecRiskExperianLienDetails {
    /**
     * 
     * @type {LienDetails}
     * @memberof ExecRiskExperianLienDetails
     */
    lien_details: LienDetails;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskExperianLienDetails
     */
    lien_count?: number;
}

/**
 * Check if a given object implements the ExecRiskExperianLienDetails interface.
 */
export function instanceOfExecRiskExperianLienDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lien_details" in value;

    return isInstance;
}

export function ExecRiskExperianLienDetailsFromJSON(json: any): ExecRiskExperianLienDetails {
    return ExecRiskExperianLienDetailsFromJSONTyped(json, false);
}

export function ExecRiskExperianLienDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskExperianLienDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lien_details': LienDetailsFromJSON(json['lien_details']),
        'lien_count': !exists(json, 'lien_count') ? undefined : json['lien_count'],
    };
}

export function ExecRiskExperianLienDetailsToJSON(value?: ExecRiskExperianLienDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lien_details': LienDetailsToJSON(value.lien_details),
        'lien_count': value.lien_count,
    };
}

