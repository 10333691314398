/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskScheduleHBase
 */
export interface ExecRiskScheduleHBase {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    sch_h_plan_year_begin_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    sch_h_tax_prd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    sch_h_pn?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    sch_h_ein?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    non_int_bear_cash_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_contrib_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    partcp_contrib_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_receivables_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_bear_cash_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    govt_sec_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    corp_debt_preferred_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    corp_debt_other_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    pref_stock_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    common_stock_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    joint_venture_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    real_estate_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_loans_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    partcp_loans_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_common_tr_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_pool_sep_acct_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_master_tr_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_103_12_invst_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_reg_invst_co_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    ins_co_gen_acct_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oth_invst_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_sec_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_prop_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    bldgs_used_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    bnfts_payable_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oprtng_payable_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    acquis_indbt_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_liab_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_liabilities_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    net_assets_boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    non_int_bear_cash_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_contrib_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    partcp_contrib_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_receivables_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_bear_cash_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    govt_sec_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    corp_debt_preferred_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    corp_debt_other_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    pref_stock_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    common_stock_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    joint_venture_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    real_estate_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_loans_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    partcp_loans_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_common_tr_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_pool_sep_acct_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_master_tr_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_103_12_invst_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_reg_invst_co_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    ins_co_gen_acct_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oth_invst_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_sec_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_prop_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    bldgs_used_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    bnfts_payable_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oprtng_payable_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    acquis_indbt_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_liab_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_liabilities_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    net_assets_eoy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    emplr_contrib_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    participant_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oth_contrib_rcvd_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    non_cash_contrib_bs_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_contrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_bear_cash_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_on_govt_sec_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_on_corp_debt_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_on_oth_loans_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_on_partcp_loans_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    int_on_oth_invst_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    total_interest_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    divnd_pref_stock_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    divnd_common_stock_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    registered_invst_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    total_dividends_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    total_rents_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    aggregate_proceeds_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    aggregate_costs_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_gain_loss_sale_ast_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    unrealzd_apprctn_re_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    unrealzd_apprctn_oth_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_unrealzd_apprctn_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    gain_loss_com_trust_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    gain_loss_pool_sep_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    gain_loss_master_tr_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    gain_loss_103_12_invst_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    gain_loss_reg_invst_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    distrib_drt_partcp_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    ins_carrier_bnfts_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    oth_bnft_payment_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_distrib_bnft_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_corrective_distrib_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_deemed_distr_part_lns_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_int_expense_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    professional_fees_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    contract_admin_fees_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    invst_mgmt_fees_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    other_admin_fees_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_admin_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_expenses_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    net_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_transfers_to_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    tot_transfers_from_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acctnt_opinion_type_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acct_performed_ltd_audit_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    accountant_firm_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    accountant_firm_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acct_opin_not_on_file_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fail_transmit_contrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    fail_transmit_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    loans_in_default_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    loans_in_default_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    leases_in_default_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    leases_in_default_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    party_in_int_not_rptd_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    party_in_int_not_rptd_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    plan_ins_fdlty_bond_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    plan_ins_fdlty_bond_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    loss_discv_dur_year_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    loss_discv_dur_year_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    asset_undeterm_val_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    asset_undeterm_val_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    non_cash_contrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    non_cash_contrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    ast_held_invst_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    five_prcnt_trans_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    all_plan_ast_distrib_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fail_provide_benefit_due_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    fail_provide_benefit_due_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    plan_blackout_period_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    comply_blackout_notice_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    res_term_plan_adpt_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    res_term_plan_adpt_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fdcry_trust_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fdcry_trust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    covered_pbgc_insurance_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    trust_incur_unrel_tax_inc_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    trust_incur_unrel_tax_inc_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    in_service_distrib_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScheduleHBase
     */
    in_service_distrib_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fdcry_trustee_cust_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fdcry_trust_cust_phon_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    fdcry_trust_cust_phon_nu_fore?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    distrib_made_employee_62_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    premium_filing_confirm_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acct_perf_ltd_audit_103_8_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acct_perf_ltd_audit_103_12_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskScheduleHBase
     */
    acct_perf_not_ltd_audit_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskScheduleHBase interface.
 */
export function instanceOfExecRiskScheduleHBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleHBaseFromJSON(json: any): ExecRiskScheduleHBase {
    return ExecRiskScheduleHBaseFromJSONTyped(json, false);
}

export function ExecRiskScheduleHBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleHBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'sch_h_plan_year_begin_date': !exists(json, 'sch_h_plan_year_begin_date') ? undefined : json['sch_h_plan_year_begin_date'],
        'sch_h_tax_prd': !exists(json, 'sch_h_tax_prd') ? undefined : json['sch_h_tax_prd'],
        'sch_h_pn': !exists(json, 'sch_h_pn') ? undefined : json['sch_h_pn'],
        'sch_h_ein': !exists(json, 'sch_h_ein') ? undefined : json['sch_h_ein'],
        'non_int_bear_cash_boy_amt': !exists(json, 'non_int_bear_cash_boy_amt') ? undefined : json['non_int_bear_cash_boy_amt'],
        'emplr_contrib_boy_amt': !exists(json, 'emplr_contrib_boy_amt') ? undefined : json['emplr_contrib_boy_amt'],
        'partcp_contrib_boy_amt': !exists(json, 'partcp_contrib_boy_amt') ? undefined : json['partcp_contrib_boy_amt'],
        'other_receivables_boy_amt': !exists(json, 'other_receivables_boy_amt') ? undefined : json['other_receivables_boy_amt'],
        'int_bear_cash_boy_amt': !exists(json, 'int_bear_cash_boy_amt') ? undefined : json['int_bear_cash_boy_amt'],
        'govt_sec_boy_amt': !exists(json, 'govt_sec_boy_amt') ? undefined : json['govt_sec_boy_amt'],
        'corp_debt_preferred_boy_amt': !exists(json, 'corp_debt_preferred_boy_amt') ? undefined : json['corp_debt_preferred_boy_amt'],
        'corp_debt_other_boy_amt': !exists(json, 'corp_debt_other_boy_amt') ? undefined : json['corp_debt_other_boy_amt'],
        'pref_stock_boy_amt': !exists(json, 'pref_stock_boy_amt') ? undefined : json['pref_stock_boy_amt'],
        'common_stock_boy_amt': !exists(json, 'common_stock_boy_amt') ? undefined : json['common_stock_boy_amt'],
        'joint_venture_boy_amt': !exists(json, 'joint_venture_boy_amt') ? undefined : json['joint_venture_boy_amt'],
        'real_estate_boy_amt': !exists(json, 'real_estate_boy_amt') ? undefined : json['real_estate_boy_amt'],
        'other_loans_boy_amt': !exists(json, 'other_loans_boy_amt') ? undefined : json['other_loans_boy_amt'],
        'partcp_loans_boy_amt': !exists(json, 'partcp_loans_boy_amt') ? undefined : json['partcp_loans_boy_amt'],
        'int_common_tr_boy_amt': !exists(json, 'int_common_tr_boy_amt') ? undefined : json['int_common_tr_boy_amt'],
        'int_pool_sep_acct_boy_amt': !exists(json, 'int_pool_sep_acct_boy_amt') ? undefined : json['int_pool_sep_acct_boy_amt'],
        'int_master_tr_boy_amt': !exists(json, 'int_master_tr_boy_amt') ? undefined : json['int_master_tr_boy_amt'],
        'int_103_12_invst_boy_amt': !exists(json, 'int_103_12_invst_boy_amt') ? undefined : json['int_103_12_invst_boy_amt'],
        'int_reg_invst_co_boy_amt': !exists(json, 'int_reg_invst_co_boy_amt') ? undefined : json['int_reg_invst_co_boy_amt'],
        'ins_co_gen_acct_boy_amt': !exists(json, 'ins_co_gen_acct_boy_amt') ? undefined : json['ins_co_gen_acct_boy_amt'],
        'oth_invst_boy_amt': !exists(json, 'oth_invst_boy_amt') ? undefined : json['oth_invst_boy_amt'],
        'emplr_sec_boy_amt': !exists(json, 'emplr_sec_boy_amt') ? undefined : json['emplr_sec_boy_amt'],
        'emplr_prop_boy_amt': !exists(json, 'emplr_prop_boy_amt') ? undefined : json['emplr_prop_boy_amt'],
        'bldgs_used_boy_amt': !exists(json, 'bldgs_used_boy_amt') ? undefined : json['bldgs_used_boy_amt'],
        'tot_assets_boy_amt': !exists(json, 'tot_assets_boy_amt') ? undefined : json['tot_assets_boy_amt'],
        'bnfts_payable_boy_amt': !exists(json, 'bnfts_payable_boy_amt') ? undefined : json['bnfts_payable_boy_amt'],
        'oprtng_payable_boy_amt': !exists(json, 'oprtng_payable_boy_amt') ? undefined : json['oprtng_payable_boy_amt'],
        'acquis_indbt_boy_amt': !exists(json, 'acquis_indbt_boy_amt') ? undefined : json['acquis_indbt_boy_amt'],
        'other_liab_boy_amt': !exists(json, 'other_liab_boy_amt') ? undefined : json['other_liab_boy_amt'],
        'tot_liabilities_boy_amt': !exists(json, 'tot_liabilities_boy_amt') ? undefined : json['tot_liabilities_boy_amt'],
        'net_assets_boy_amt': !exists(json, 'net_assets_boy_amt') ? undefined : json['net_assets_boy_amt'],
        'non_int_bear_cash_eoy_amt': !exists(json, 'non_int_bear_cash_eoy_amt') ? undefined : json['non_int_bear_cash_eoy_amt'],
        'emplr_contrib_eoy_amt': !exists(json, 'emplr_contrib_eoy_amt') ? undefined : json['emplr_contrib_eoy_amt'],
        'partcp_contrib_eoy_amt': !exists(json, 'partcp_contrib_eoy_amt') ? undefined : json['partcp_contrib_eoy_amt'],
        'other_receivables_eoy_amt': !exists(json, 'other_receivables_eoy_amt') ? undefined : json['other_receivables_eoy_amt'],
        'int_bear_cash_eoy_amt': !exists(json, 'int_bear_cash_eoy_amt') ? undefined : json['int_bear_cash_eoy_amt'],
        'govt_sec_eoy_amt': !exists(json, 'govt_sec_eoy_amt') ? undefined : json['govt_sec_eoy_amt'],
        'corp_debt_preferred_eoy_amt': !exists(json, 'corp_debt_preferred_eoy_amt') ? undefined : json['corp_debt_preferred_eoy_amt'],
        'corp_debt_other_eoy_amt': !exists(json, 'corp_debt_other_eoy_amt') ? undefined : json['corp_debt_other_eoy_amt'],
        'pref_stock_eoy_amt': !exists(json, 'pref_stock_eoy_amt') ? undefined : json['pref_stock_eoy_amt'],
        'common_stock_eoy_amt': !exists(json, 'common_stock_eoy_amt') ? undefined : json['common_stock_eoy_amt'],
        'joint_venture_eoy_amt': !exists(json, 'joint_venture_eoy_amt') ? undefined : json['joint_venture_eoy_amt'],
        'real_estate_eoy_amt': !exists(json, 'real_estate_eoy_amt') ? undefined : json['real_estate_eoy_amt'],
        'other_loans_eoy_amt': !exists(json, 'other_loans_eoy_amt') ? undefined : json['other_loans_eoy_amt'],
        'partcp_loans_eoy_amt': !exists(json, 'partcp_loans_eoy_amt') ? undefined : json['partcp_loans_eoy_amt'],
        'int_common_tr_eoy_amt': !exists(json, 'int_common_tr_eoy_amt') ? undefined : json['int_common_tr_eoy_amt'],
        'int_pool_sep_acct_eoy_amt': !exists(json, 'int_pool_sep_acct_eoy_amt') ? undefined : json['int_pool_sep_acct_eoy_amt'],
        'int_master_tr_eoy_amt': !exists(json, 'int_master_tr_eoy_amt') ? undefined : json['int_master_tr_eoy_amt'],
        'int_103_12_invst_eoy_amt': !exists(json, 'int_103_12_invst_eoy_amt') ? undefined : json['int_103_12_invst_eoy_amt'],
        'int_reg_invst_co_eoy_amt': !exists(json, 'int_reg_invst_co_eoy_amt') ? undefined : json['int_reg_invst_co_eoy_amt'],
        'ins_co_gen_acct_eoy_amt': !exists(json, 'ins_co_gen_acct_eoy_amt') ? undefined : json['ins_co_gen_acct_eoy_amt'],
        'oth_invst_eoy_amt': !exists(json, 'oth_invst_eoy_amt') ? undefined : json['oth_invst_eoy_amt'],
        'emplr_sec_eoy_amt': !exists(json, 'emplr_sec_eoy_amt') ? undefined : json['emplr_sec_eoy_amt'],
        'emplr_prop_eoy_amt': !exists(json, 'emplr_prop_eoy_amt') ? undefined : json['emplr_prop_eoy_amt'],
        'bldgs_used_eoy_amt': !exists(json, 'bldgs_used_eoy_amt') ? undefined : json['bldgs_used_eoy_amt'],
        'tot_assets_eoy_amt': !exists(json, 'tot_assets_eoy_amt') ? undefined : json['tot_assets_eoy_amt'],
        'bnfts_payable_eoy_amt': !exists(json, 'bnfts_payable_eoy_amt') ? undefined : json['bnfts_payable_eoy_amt'],
        'oprtng_payable_eoy_amt': !exists(json, 'oprtng_payable_eoy_amt') ? undefined : json['oprtng_payable_eoy_amt'],
        'acquis_indbt_eoy_amt': !exists(json, 'acquis_indbt_eoy_amt') ? undefined : json['acquis_indbt_eoy_amt'],
        'other_liab_eoy_amt': !exists(json, 'other_liab_eoy_amt') ? undefined : json['other_liab_eoy_amt'],
        'tot_liabilities_eoy_amt': !exists(json, 'tot_liabilities_eoy_amt') ? undefined : json['tot_liabilities_eoy_amt'],
        'net_assets_eoy_amt': !exists(json, 'net_assets_eoy_amt') ? undefined : json['net_assets_eoy_amt'],
        'emplr_contrib_income_amt': !exists(json, 'emplr_contrib_income_amt') ? undefined : json['emplr_contrib_income_amt'],
        'participant_contrib_amt': !exists(json, 'participant_contrib_amt') ? undefined : json['participant_contrib_amt'],
        'oth_contrib_rcvd_amt': !exists(json, 'oth_contrib_rcvd_amt') ? undefined : json['oth_contrib_rcvd_amt'],
        'non_cash_contrib_bs_amt': !exists(json, 'non_cash_contrib_bs_amt') ? undefined : json['non_cash_contrib_bs_amt'],
        'tot_contrib_amt': !exists(json, 'tot_contrib_amt') ? undefined : json['tot_contrib_amt'],
        'int_bear_cash_amt': !exists(json, 'int_bear_cash_amt') ? undefined : json['int_bear_cash_amt'],
        'int_on_govt_sec_amt': !exists(json, 'int_on_govt_sec_amt') ? undefined : json['int_on_govt_sec_amt'],
        'int_on_corp_debt_amt': !exists(json, 'int_on_corp_debt_amt') ? undefined : json['int_on_corp_debt_amt'],
        'int_on_oth_loans_amt': !exists(json, 'int_on_oth_loans_amt') ? undefined : json['int_on_oth_loans_amt'],
        'int_on_partcp_loans_amt': !exists(json, 'int_on_partcp_loans_amt') ? undefined : json['int_on_partcp_loans_amt'],
        'int_on_oth_invst_amt': !exists(json, 'int_on_oth_invst_amt') ? undefined : json['int_on_oth_invst_amt'],
        'total_interest_amt': !exists(json, 'total_interest_amt') ? undefined : json['total_interest_amt'],
        'divnd_pref_stock_amt': !exists(json, 'divnd_pref_stock_amt') ? undefined : json['divnd_pref_stock_amt'],
        'divnd_common_stock_amt': !exists(json, 'divnd_common_stock_amt') ? undefined : json['divnd_common_stock_amt'],
        'registered_invst_amt': !exists(json, 'registered_invst_amt') ? undefined : json['registered_invst_amt'],
        'total_dividends_amt': !exists(json, 'total_dividends_amt') ? undefined : json['total_dividends_amt'],
        'total_rents_amt': !exists(json, 'total_rents_amt') ? undefined : json['total_rents_amt'],
        'aggregate_proceeds_amt': !exists(json, 'aggregate_proceeds_amt') ? undefined : json['aggregate_proceeds_amt'],
        'aggregate_costs_amt': !exists(json, 'aggregate_costs_amt') ? undefined : json['aggregate_costs_amt'],
        'tot_gain_loss_sale_ast_amt': !exists(json, 'tot_gain_loss_sale_ast_amt') ? undefined : json['tot_gain_loss_sale_ast_amt'],
        'unrealzd_apprctn_re_amt': !exists(json, 'unrealzd_apprctn_re_amt') ? undefined : json['unrealzd_apprctn_re_amt'],
        'unrealzd_apprctn_oth_amt': !exists(json, 'unrealzd_apprctn_oth_amt') ? undefined : json['unrealzd_apprctn_oth_amt'],
        'tot_unrealzd_apprctn_amt': !exists(json, 'tot_unrealzd_apprctn_amt') ? undefined : json['tot_unrealzd_apprctn_amt'],
        'gain_loss_com_trust_amt': !exists(json, 'gain_loss_com_trust_amt') ? undefined : json['gain_loss_com_trust_amt'],
        'gain_loss_pool_sep_amt': !exists(json, 'gain_loss_pool_sep_amt') ? undefined : json['gain_loss_pool_sep_amt'],
        'gain_loss_master_tr_amt': !exists(json, 'gain_loss_master_tr_amt') ? undefined : json['gain_loss_master_tr_amt'],
        'gain_loss_103_12_invst_amt': !exists(json, 'gain_loss_103_12_invst_amt') ? undefined : json['gain_loss_103_12_invst_amt'],
        'gain_loss_reg_invst_amt': !exists(json, 'gain_loss_reg_invst_amt') ? undefined : json['gain_loss_reg_invst_amt'],
        'other_income_amt': !exists(json, 'other_income_amt') ? undefined : json['other_income_amt'],
        'tot_income_amt': !exists(json, 'tot_income_amt') ? undefined : json['tot_income_amt'],
        'distrib_drt_partcp_amt': !exists(json, 'distrib_drt_partcp_amt') ? undefined : json['distrib_drt_partcp_amt'],
        'ins_carrier_bnfts_amt': !exists(json, 'ins_carrier_bnfts_amt') ? undefined : json['ins_carrier_bnfts_amt'],
        'oth_bnft_payment_amt': !exists(json, 'oth_bnft_payment_amt') ? undefined : json['oth_bnft_payment_amt'],
        'tot_distrib_bnft_amt': !exists(json, 'tot_distrib_bnft_amt') ? undefined : json['tot_distrib_bnft_amt'],
        'tot_corrective_distrib_amt': !exists(json, 'tot_corrective_distrib_amt') ? undefined : json['tot_corrective_distrib_amt'],
        'tot_deemed_distr_part_lns_amt': !exists(json, 'tot_deemed_distr_part_lns_amt') ? undefined : json['tot_deemed_distr_part_lns_amt'],
        'tot_int_expense_amt': !exists(json, 'tot_int_expense_amt') ? undefined : json['tot_int_expense_amt'],
        'professional_fees_amt': !exists(json, 'professional_fees_amt') ? undefined : json['professional_fees_amt'],
        'contract_admin_fees_amt': !exists(json, 'contract_admin_fees_amt') ? undefined : json['contract_admin_fees_amt'],
        'invst_mgmt_fees_amt': !exists(json, 'invst_mgmt_fees_amt') ? undefined : json['invst_mgmt_fees_amt'],
        'other_admin_fees_amt': !exists(json, 'other_admin_fees_amt') ? undefined : json['other_admin_fees_amt'],
        'tot_admin_expenses_amt': !exists(json, 'tot_admin_expenses_amt') ? undefined : json['tot_admin_expenses_amt'],
        'tot_expenses_amt': !exists(json, 'tot_expenses_amt') ? undefined : json['tot_expenses_amt'],
        'net_income_amt': !exists(json, 'net_income_amt') ? undefined : json['net_income_amt'],
        'tot_transfers_to_amt': !exists(json, 'tot_transfers_to_amt') ? undefined : json['tot_transfers_to_amt'],
        'tot_transfers_from_amt': !exists(json, 'tot_transfers_from_amt') ? undefined : json['tot_transfers_from_amt'],
        'acctnt_opinion_type_cd': !exists(json, 'acctnt_opinion_type_cd') ? undefined : json['acctnt_opinion_type_cd'],
        'acct_performed_ltd_audit_ind': !exists(json, 'acct_performed_ltd_audit_ind') ? undefined : json['acct_performed_ltd_audit_ind'],
        'accountant_firm_name': !exists(json, 'accountant_firm_name') ? undefined : json['accountant_firm_name'],
        'accountant_firm_ein': !exists(json, 'accountant_firm_ein') ? undefined : json['accountant_firm_ein'],
        'acct_opin_not_on_file_ind': !exists(json, 'acct_opin_not_on_file_ind') ? undefined : json['acct_opin_not_on_file_ind'],
        'fail_transmit_contrib_ind': !exists(json, 'fail_transmit_contrib_ind') ? undefined : json['fail_transmit_contrib_ind'],
        'fail_transmit_contrib_amt': !exists(json, 'fail_transmit_contrib_amt') ? undefined : json['fail_transmit_contrib_amt'],
        'loans_in_default_ind': !exists(json, 'loans_in_default_ind') ? undefined : json['loans_in_default_ind'],
        'loans_in_default_amt': !exists(json, 'loans_in_default_amt') ? undefined : json['loans_in_default_amt'],
        'leases_in_default_ind': !exists(json, 'leases_in_default_ind') ? undefined : json['leases_in_default_ind'],
        'leases_in_default_amt': !exists(json, 'leases_in_default_amt') ? undefined : json['leases_in_default_amt'],
        'party_in_int_not_rptd_ind': !exists(json, 'party_in_int_not_rptd_ind') ? undefined : json['party_in_int_not_rptd_ind'],
        'party_in_int_not_rptd_amt': !exists(json, 'party_in_int_not_rptd_amt') ? undefined : json['party_in_int_not_rptd_amt'],
        'plan_ins_fdlty_bond_ind': !exists(json, 'plan_ins_fdlty_bond_ind') ? undefined : json['plan_ins_fdlty_bond_ind'],
        'plan_ins_fdlty_bond_amt': !exists(json, 'plan_ins_fdlty_bond_amt') ? undefined : json['plan_ins_fdlty_bond_amt'],
        'loss_discv_dur_year_ind': !exists(json, 'loss_discv_dur_year_ind') ? undefined : json['loss_discv_dur_year_ind'],
        'loss_discv_dur_year_amt': !exists(json, 'loss_discv_dur_year_amt') ? undefined : json['loss_discv_dur_year_amt'],
        'asset_undeterm_val_ind': !exists(json, 'asset_undeterm_val_ind') ? undefined : json['asset_undeterm_val_ind'],
        'asset_undeterm_val_amt': !exists(json, 'asset_undeterm_val_amt') ? undefined : json['asset_undeterm_val_amt'],
        'non_cash_contrib_ind': !exists(json, 'non_cash_contrib_ind') ? undefined : json['non_cash_contrib_ind'],
        'non_cash_contrib_amt': !exists(json, 'non_cash_contrib_amt') ? undefined : json['non_cash_contrib_amt'],
        'ast_held_invst_ind': !exists(json, 'ast_held_invst_ind') ? undefined : json['ast_held_invst_ind'],
        'five_prcnt_trans_ind': !exists(json, 'five_prcnt_trans_ind') ? undefined : json['five_prcnt_trans_ind'],
        'all_plan_ast_distrib_ind': !exists(json, 'all_plan_ast_distrib_ind') ? undefined : json['all_plan_ast_distrib_ind'],
        'fail_provide_benefit_due_ind': !exists(json, 'fail_provide_benefit_due_ind') ? undefined : json['fail_provide_benefit_due_ind'],
        'fail_provide_benefit_due_amt': !exists(json, 'fail_provide_benefit_due_amt') ? undefined : json['fail_provide_benefit_due_amt'],
        'plan_blackout_period_ind': !exists(json, 'plan_blackout_period_ind') ? undefined : json['plan_blackout_period_ind'],
        'comply_blackout_notice_ind': !exists(json, 'comply_blackout_notice_ind') ? undefined : json['comply_blackout_notice_ind'],
        'res_term_plan_adpt_ind': !exists(json, 'res_term_plan_adpt_ind') ? undefined : json['res_term_plan_adpt_ind'],
        'res_term_plan_adpt_amt': !exists(json, 'res_term_plan_adpt_amt') ? undefined : json['res_term_plan_adpt_amt'],
        'fdcry_trust_ein': !exists(json, 'fdcry_trust_ein') ? undefined : json['fdcry_trust_ein'],
        'fdcry_trust_name': !exists(json, 'fdcry_trust_name') ? undefined : json['fdcry_trust_name'],
        'covered_pbgc_insurance_ind': !exists(json, 'covered_pbgc_insurance_ind') ? undefined : json['covered_pbgc_insurance_ind'],
        'trust_incur_unrel_tax_inc_ind': !exists(json, 'trust_incur_unrel_tax_inc_ind') ? undefined : json['trust_incur_unrel_tax_inc_ind'],
        'trust_incur_unrel_tax_inc_amt': !exists(json, 'trust_incur_unrel_tax_inc_amt') ? undefined : json['trust_incur_unrel_tax_inc_amt'],
        'in_service_distrib_ind': !exists(json, 'in_service_distrib_ind') ? undefined : json['in_service_distrib_ind'],
        'in_service_distrib_amt': !exists(json, 'in_service_distrib_amt') ? undefined : json['in_service_distrib_amt'],
        'fdcry_trustee_cust_name': !exists(json, 'fdcry_trustee_cust_name') ? undefined : json['fdcry_trustee_cust_name'],
        'fdcry_trust_cust_phon_num': !exists(json, 'fdcry_trust_cust_phon_num') ? undefined : json['fdcry_trust_cust_phon_num'],
        'fdcry_trust_cust_phon_nu_fore': !exists(json, 'fdcry_trust_cust_phon_nu_fore') ? undefined : json['fdcry_trust_cust_phon_nu_fore'],
        'distrib_made_employee_62_ind': !exists(json, 'distrib_made_employee_62_ind') ? undefined : json['distrib_made_employee_62_ind'],
        'premium_filing_confirm_number': !exists(json, 'premium_filing_confirm_number') ? undefined : json['premium_filing_confirm_number'],
        'acct_perf_ltd_audit_103_8_ind': !exists(json, 'acct_perf_ltd_audit_103_8_ind') ? undefined : json['acct_perf_ltd_audit_103_8_ind'],
        'acct_perf_ltd_audit_103_12_ind': !exists(json, 'acct_perf_ltd_audit_103_12_ind') ? undefined : json['acct_perf_ltd_audit_103_12_ind'],
        'acct_perf_not_ltd_audit_ind': !exists(json, 'acct_perf_not_ltd_audit_ind') ? undefined : json['acct_perf_not_ltd_audit_ind'],
    };
}

export function ExecRiskScheduleHBaseToJSON(value?: ExecRiskScheduleHBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'sch_h_plan_year_begin_date': value.sch_h_plan_year_begin_date,
        'sch_h_tax_prd': value.sch_h_tax_prd,
        'sch_h_pn': value.sch_h_pn,
        'sch_h_ein': value.sch_h_ein,
        'non_int_bear_cash_boy_amt': value.non_int_bear_cash_boy_amt,
        'emplr_contrib_boy_amt': value.emplr_contrib_boy_amt,
        'partcp_contrib_boy_amt': value.partcp_contrib_boy_amt,
        'other_receivables_boy_amt': value.other_receivables_boy_amt,
        'int_bear_cash_boy_amt': value.int_bear_cash_boy_amt,
        'govt_sec_boy_amt': value.govt_sec_boy_amt,
        'corp_debt_preferred_boy_amt': value.corp_debt_preferred_boy_amt,
        'corp_debt_other_boy_amt': value.corp_debt_other_boy_amt,
        'pref_stock_boy_amt': value.pref_stock_boy_amt,
        'common_stock_boy_amt': value.common_stock_boy_amt,
        'joint_venture_boy_amt': value.joint_venture_boy_amt,
        'real_estate_boy_amt': value.real_estate_boy_amt,
        'other_loans_boy_amt': value.other_loans_boy_amt,
        'partcp_loans_boy_amt': value.partcp_loans_boy_amt,
        'int_common_tr_boy_amt': value.int_common_tr_boy_amt,
        'int_pool_sep_acct_boy_amt': value.int_pool_sep_acct_boy_amt,
        'int_master_tr_boy_amt': value.int_master_tr_boy_amt,
        'int_103_12_invst_boy_amt': value.int_103_12_invst_boy_amt,
        'int_reg_invst_co_boy_amt': value.int_reg_invst_co_boy_amt,
        'ins_co_gen_acct_boy_amt': value.ins_co_gen_acct_boy_amt,
        'oth_invst_boy_amt': value.oth_invst_boy_amt,
        'emplr_sec_boy_amt': value.emplr_sec_boy_amt,
        'emplr_prop_boy_amt': value.emplr_prop_boy_amt,
        'bldgs_used_boy_amt': value.bldgs_used_boy_amt,
        'tot_assets_boy_amt': value.tot_assets_boy_amt,
        'bnfts_payable_boy_amt': value.bnfts_payable_boy_amt,
        'oprtng_payable_boy_amt': value.oprtng_payable_boy_amt,
        'acquis_indbt_boy_amt': value.acquis_indbt_boy_amt,
        'other_liab_boy_amt': value.other_liab_boy_amt,
        'tot_liabilities_boy_amt': value.tot_liabilities_boy_amt,
        'net_assets_boy_amt': value.net_assets_boy_amt,
        'non_int_bear_cash_eoy_amt': value.non_int_bear_cash_eoy_amt,
        'emplr_contrib_eoy_amt': value.emplr_contrib_eoy_amt,
        'partcp_contrib_eoy_amt': value.partcp_contrib_eoy_amt,
        'other_receivables_eoy_amt': value.other_receivables_eoy_amt,
        'int_bear_cash_eoy_amt': value.int_bear_cash_eoy_amt,
        'govt_sec_eoy_amt': value.govt_sec_eoy_amt,
        'corp_debt_preferred_eoy_amt': value.corp_debt_preferred_eoy_amt,
        'corp_debt_other_eoy_amt': value.corp_debt_other_eoy_amt,
        'pref_stock_eoy_amt': value.pref_stock_eoy_amt,
        'common_stock_eoy_amt': value.common_stock_eoy_amt,
        'joint_venture_eoy_amt': value.joint_venture_eoy_amt,
        'real_estate_eoy_amt': value.real_estate_eoy_amt,
        'other_loans_eoy_amt': value.other_loans_eoy_amt,
        'partcp_loans_eoy_amt': value.partcp_loans_eoy_amt,
        'int_common_tr_eoy_amt': value.int_common_tr_eoy_amt,
        'int_pool_sep_acct_eoy_amt': value.int_pool_sep_acct_eoy_amt,
        'int_master_tr_eoy_amt': value.int_master_tr_eoy_amt,
        'int_103_12_invst_eoy_amt': value.int_103_12_invst_eoy_amt,
        'int_reg_invst_co_eoy_amt': value.int_reg_invst_co_eoy_amt,
        'ins_co_gen_acct_eoy_amt': value.ins_co_gen_acct_eoy_amt,
        'oth_invst_eoy_amt': value.oth_invst_eoy_amt,
        'emplr_sec_eoy_amt': value.emplr_sec_eoy_amt,
        'emplr_prop_eoy_amt': value.emplr_prop_eoy_amt,
        'bldgs_used_eoy_amt': value.bldgs_used_eoy_amt,
        'tot_assets_eoy_amt': value.tot_assets_eoy_amt,
        'bnfts_payable_eoy_amt': value.bnfts_payable_eoy_amt,
        'oprtng_payable_eoy_amt': value.oprtng_payable_eoy_amt,
        'acquis_indbt_eoy_amt': value.acquis_indbt_eoy_amt,
        'other_liab_eoy_amt': value.other_liab_eoy_amt,
        'tot_liabilities_eoy_amt': value.tot_liabilities_eoy_amt,
        'net_assets_eoy_amt': value.net_assets_eoy_amt,
        'emplr_contrib_income_amt': value.emplr_contrib_income_amt,
        'participant_contrib_amt': value.participant_contrib_amt,
        'oth_contrib_rcvd_amt': value.oth_contrib_rcvd_amt,
        'non_cash_contrib_bs_amt': value.non_cash_contrib_bs_amt,
        'tot_contrib_amt': value.tot_contrib_amt,
        'int_bear_cash_amt': value.int_bear_cash_amt,
        'int_on_govt_sec_amt': value.int_on_govt_sec_amt,
        'int_on_corp_debt_amt': value.int_on_corp_debt_amt,
        'int_on_oth_loans_amt': value.int_on_oth_loans_amt,
        'int_on_partcp_loans_amt': value.int_on_partcp_loans_amt,
        'int_on_oth_invst_amt': value.int_on_oth_invst_amt,
        'total_interest_amt': value.total_interest_amt,
        'divnd_pref_stock_amt': value.divnd_pref_stock_amt,
        'divnd_common_stock_amt': value.divnd_common_stock_amt,
        'registered_invst_amt': value.registered_invst_amt,
        'total_dividends_amt': value.total_dividends_amt,
        'total_rents_amt': value.total_rents_amt,
        'aggregate_proceeds_amt': value.aggregate_proceeds_amt,
        'aggregate_costs_amt': value.aggregate_costs_amt,
        'tot_gain_loss_sale_ast_amt': value.tot_gain_loss_sale_ast_amt,
        'unrealzd_apprctn_re_amt': value.unrealzd_apprctn_re_amt,
        'unrealzd_apprctn_oth_amt': value.unrealzd_apprctn_oth_amt,
        'tot_unrealzd_apprctn_amt': value.tot_unrealzd_apprctn_amt,
        'gain_loss_com_trust_amt': value.gain_loss_com_trust_amt,
        'gain_loss_pool_sep_amt': value.gain_loss_pool_sep_amt,
        'gain_loss_master_tr_amt': value.gain_loss_master_tr_amt,
        'gain_loss_103_12_invst_amt': value.gain_loss_103_12_invst_amt,
        'gain_loss_reg_invst_amt': value.gain_loss_reg_invst_amt,
        'other_income_amt': value.other_income_amt,
        'tot_income_amt': value.tot_income_amt,
        'distrib_drt_partcp_amt': value.distrib_drt_partcp_amt,
        'ins_carrier_bnfts_amt': value.ins_carrier_bnfts_amt,
        'oth_bnft_payment_amt': value.oth_bnft_payment_amt,
        'tot_distrib_bnft_amt': value.tot_distrib_bnft_amt,
        'tot_corrective_distrib_amt': value.tot_corrective_distrib_amt,
        'tot_deemed_distr_part_lns_amt': value.tot_deemed_distr_part_lns_amt,
        'tot_int_expense_amt': value.tot_int_expense_amt,
        'professional_fees_amt': value.professional_fees_amt,
        'contract_admin_fees_amt': value.contract_admin_fees_amt,
        'invst_mgmt_fees_amt': value.invst_mgmt_fees_amt,
        'other_admin_fees_amt': value.other_admin_fees_amt,
        'tot_admin_expenses_amt': value.tot_admin_expenses_amt,
        'tot_expenses_amt': value.tot_expenses_amt,
        'net_income_amt': value.net_income_amt,
        'tot_transfers_to_amt': value.tot_transfers_to_amt,
        'tot_transfers_from_amt': value.tot_transfers_from_amt,
        'acctnt_opinion_type_cd': value.acctnt_opinion_type_cd,
        'acct_performed_ltd_audit_ind': value.acct_performed_ltd_audit_ind,
        'accountant_firm_name': value.accountant_firm_name,
        'accountant_firm_ein': value.accountant_firm_ein,
        'acct_opin_not_on_file_ind': value.acct_opin_not_on_file_ind,
        'fail_transmit_contrib_ind': value.fail_transmit_contrib_ind,
        'fail_transmit_contrib_amt': value.fail_transmit_contrib_amt,
        'loans_in_default_ind': value.loans_in_default_ind,
        'loans_in_default_amt': value.loans_in_default_amt,
        'leases_in_default_ind': value.leases_in_default_ind,
        'leases_in_default_amt': value.leases_in_default_amt,
        'party_in_int_not_rptd_ind': value.party_in_int_not_rptd_ind,
        'party_in_int_not_rptd_amt': value.party_in_int_not_rptd_amt,
        'plan_ins_fdlty_bond_ind': value.plan_ins_fdlty_bond_ind,
        'plan_ins_fdlty_bond_amt': value.plan_ins_fdlty_bond_amt,
        'loss_discv_dur_year_ind': value.loss_discv_dur_year_ind,
        'loss_discv_dur_year_amt': value.loss_discv_dur_year_amt,
        'asset_undeterm_val_ind': value.asset_undeterm_val_ind,
        'asset_undeterm_val_amt': value.asset_undeterm_val_amt,
        'non_cash_contrib_ind': value.non_cash_contrib_ind,
        'non_cash_contrib_amt': value.non_cash_contrib_amt,
        'ast_held_invst_ind': value.ast_held_invst_ind,
        'five_prcnt_trans_ind': value.five_prcnt_trans_ind,
        'all_plan_ast_distrib_ind': value.all_plan_ast_distrib_ind,
        'fail_provide_benefit_due_ind': value.fail_provide_benefit_due_ind,
        'fail_provide_benefit_due_amt': value.fail_provide_benefit_due_amt,
        'plan_blackout_period_ind': value.plan_blackout_period_ind,
        'comply_blackout_notice_ind': value.comply_blackout_notice_ind,
        'res_term_plan_adpt_ind': value.res_term_plan_adpt_ind,
        'res_term_plan_adpt_amt': value.res_term_plan_adpt_amt,
        'fdcry_trust_ein': value.fdcry_trust_ein,
        'fdcry_trust_name': value.fdcry_trust_name,
        'covered_pbgc_insurance_ind': value.covered_pbgc_insurance_ind,
        'trust_incur_unrel_tax_inc_ind': value.trust_incur_unrel_tax_inc_ind,
        'trust_incur_unrel_tax_inc_amt': value.trust_incur_unrel_tax_inc_amt,
        'in_service_distrib_ind': value.in_service_distrib_ind,
        'in_service_distrib_amt': value.in_service_distrib_amt,
        'fdcry_trustee_cust_name': value.fdcry_trustee_cust_name,
        'fdcry_trust_cust_phon_num': value.fdcry_trust_cust_phon_num,
        'fdcry_trust_cust_phon_nu_fore': value.fdcry_trust_cust_phon_nu_fore,
        'distrib_made_employee_62_ind': value.distrib_made_employee_62_ind,
        'premium_filing_confirm_number': value.premium_filing_confirm_number,
        'acct_perf_ltd_audit_103_8_ind': value.acct_perf_ltd_audit_103_8_ind,
        'acct_perf_ltd_audit_103_12_ind': value.acct_perf_ltd_audit_103_12_ind,
        'acct_perf_not_ltd_audit_ind': value.acct_perf_not_ltd_audit_ind,
    };
}

