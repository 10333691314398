import { useUnsafeDataDogRum } from '@crucible-risk/react-monitoring';
import { LaunchDarklyWrapperContext } from '@explorer/containers/LaunchDarklyWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

export const LAUNCH_DARKLY_FLAG_OVERRIDES = 'LAUNCH_DARKLY_FLAG_OVERRIDES';

/**
 * A wrapper utility around the LaunchDarkly SDK to make it easier to use feature flags in React components and to avoid reading flags before they are ready + individualized.
 * 
 * ### Example
 *
 * ```
 * const {
    value: enableControlTrialFeature,
    initialized,
    anonymous,
    error,
  } = useFeatureFlag<boolean>(
    'disable-old-feature',
  );

  if (!initialized) {
    // Don't do anything until we get the current flags
    return <Loading />;
  }

  if (anonymous) {
    // Don't do anything until we know whether the user is a beta tester
    return <Loading />;
  }

  if (error) {
    // Better safe than sorry. Don't disable it
    return <Feature />;
  }

  if (!value) {
    return <Feature />;
  } else {
    return <NewFeature />;
  }

  ### Overrides

  You can override the value of a flag by calling the global function `__OVERRIDE_LAUNCH_DARKLY_FLAGS__(flagName, value)` in the browser console. This will override the value of the flag for the current session. The override will be cleared when the browser tab is closed.

 * ```
 *
 * @param flagName the LaunchDarkly flag key (non-camel case)
 * @returns `value: T` - the value of the flag
 * @returns `initialized: boolean` - While launch darkly is initializing, flags will be unavailable and the value will be undefined. This flag can be used to avoid accidentally rendering/enabling features and wait for initialization instead. If there is a safe default, you can instead use that while waiting for initialization to complete.
 * @returns `anonymous: boolean` - We can target flags to certain users, but this requires that we authenticate and load the user first. While fetching and loading the user info, the flag will use the anonymous LaunchDarkly values, not individualized values that are targeted to that user specifically (ie testers, internal users). Once the user is loaded, the values can change. If we want to avoid a potential flash of enabled/disabled features, we can use the anonymous flag to determine whether the individualized value is ready yet. If we don't care about the flash, or if we are not using targeting, then we can ignore this flag.
 * @returns `error: Error` - If there is an error initializing the LaunchDarkly SDK, this will be set to the error. The UI can use this fallback to a safe default
 * @returns `isLoading: boolean` - This is a combination of `initialized` and `anonymous` and `error`. It is true while the flag is not ready to be used.
 */
export function useFeatureFlag<T extends string | boolean | number>(
  flagName: string,
): {
  value?: T;
  anonymous: boolean;
  initialized: boolean;
  error?: Error;
  isLoading: boolean;
} {
  const { addError } = useUnsafeDataDogRum();
  const isLocalhost =
    process.env.REACT_ENV === 'development' || process.env.REACT_ENV === 'test';

  const { anonymous, initialized, error } = useContext(
    LaunchDarklyWrapperContext,
  );

  const flags = useFlags();

  if (!initialized) {
    return {
      value: undefined,
      anonymous,
      initialized,
      error,
      isLoading: !error,
    };
  }

  let value = flags[flagName] as T | undefined;

  const flagOverride = getOverride<T>(flagName);
  if (flagOverride !== undefined) {
    !isLocalhost &&
      console.debug(
        `Overriding flag ${flagName} from ${value} to ${flagOverride}`,
      );
    value = flagOverride;
  }

  if (value === undefined) {
    /* Send this to datadog to keep track of missing or badly named flags. Or catch an instance of someone deleting a flag that is still being used */
    !isLocalhost && console.warn(`Flag ${flagName} is not defined`);
    addError(`LaunchDarkly flag is unknown`, {
      flagName,
    });
  }

  // unless we have an error, wait for the feature flags to be loaded and for the user targeting to be ready
  const isLoading = (!initialized || anonymous) && !error;

  return { value, anonymous, initialized, error, isLoading };
}

function getOverride<T extends string | boolean | number>(flagName: string) {
  let flagOverride: T | undefined;

  const rawOverrides = window.sessionStorage.getItem(
    LAUNCH_DARKLY_FLAG_OVERRIDES,
  );
  if (rawOverrides) {
    try {
      const overrides = JSON.parse(rawOverrides);
      flagOverride = overrides[flagName] as T;
    } catch (e) {}
  }
  return flagOverride;
}

global.__OVERRIDE_LAUNCH_DARKLY_FLAGS__ = function (flagName, overriddenValue) {
  const rawOverrides = window.sessionStorage.getItem(
    LAUNCH_DARKLY_FLAG_OVERRIDES,
  );

  let overrides;

  try {
    overrides = rawOverrides ? JSON.parse(rawOverrides) : {};
  } catch (e) {
    overrides = {};
  }

  overrides[flagName] = overriddenValue;

  window.sessionStorage.setItem(
    LAUNCH_DARKLY_FLAG_OVERRIDES,
    JSON.stringify(overrides),
  );
};
