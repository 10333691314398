import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * PCIIcon
 */
export const PCIIcon = ({ ...props }: PCIIconProps) => {
  return (
    <Icon label="pci" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5,8.8c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3C20,5.5,21.5,7,21.5,8.8z M17.8,6.8c0,0.1,0,0.1-0.1,0.1c-0.5,0.1-0.8,0.4-0.9,0.9c-0.1,0.5,0.2,0.9,0.6,1.1l1.1,0.4c0.2,0.1,0.3,0.2,0.2,0.4c0,0.2-0.2,0.3-0.4,0.3h-1.1c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h0.3c0.1,0,0.1,0,0.1,0.1V11c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-0.2c0-0.1,0-0.1,0.1-0.1c0.5-0.1,0.8-0.4,0.9-0.9c0.1-0.5-0.2-0.9-0.6-1.1l-1.1-0.4c-0.2-0.1-0.3-0.2-0.2-0.4c0-0.2,0.2-0.3,0.4-0.3h1.1c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h-0.3c-0.1,0-0.1,0-0.1-0.1V6.6c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3V6.8z M9.6,14.1h13c0.8,0,1.4,0.6,1.4,1.4v8.2c0,0.8-0.6,1.4-1.4,1.4h-13c-0.8,0-1.4-0.6-1.4-1.4v-8.2C8.3,14.7,8.9,14.1,9.6,14.1z M22.6,17.8h-13v-2c0-0.2,0.2-0.3,0.3-0.3h12.3c0.2,0,0.3,0.2,0.3,0.3V17.8z M22.6,23.3c0,0.2-0.2,0.3-0.3,0.3H10c-0.2,0-0.3-0.2-0.3-0.3v-4h13V23.3z M10.5,22.7H12V22h-1.5V22.7z M14.1,22.7h-1.5V22h1.5V22.7z M15.7,12.5c0.2,0.2,0.1,0.5-0.2,0.5h-4.7c0,0,0,0,0,0c-0.1-1.8,1.2-3.3,3-3.5C14,10.7,14.7,11.8,15.7,12.5z"
        />
      </g>
    </Icon>
  );
};

/**
 * PCIIcon Props
 */
export interface PCIIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
