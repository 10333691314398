/**
 * Simplifies the `get` of URLSearchParams.
 *
 * __> Example:__
 * ```ts
 * urlParams.get('param');
 * ```
 */

import { deprecate } from 'util';

export const urlParams: URLParams = {
  /* @deprecated - use router.query to access/watch query params within NextJs application */
  get: (key) => {
    const { location } = window || {};

    const searchParams = new URLSearchParams(location.search);

    return searchParams.get(key)?.toString() as string;
  },
};

type URLParams = {
  get: (key: string) => string;
};
