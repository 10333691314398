/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount } from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import {
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSONTyped,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON,
} from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';

/**
 * 
 * @export
 * @interface ControlIgnoredVulnerabilitiesCount
 */
export interface ControlIgnoredVulnerabilitiesCount {
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlIgnoredVulnerabilitiesCount
     */
    vulnerabilities?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlIgnoredVulnerabilitiesCount
     */
    findings?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
}

/**
 * Check if a given object implements the ControlIgnoredVulnerabilitiesCount interface.
 */
export function instanceOfControlIgnoredVulnerabilitiesCount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlIgnoredVulnerabilitiesCountFromJSON(json: any): ControlIgnoredVulnerabilitiesCount {
    return ControlIgnoredVulnerabilitiesCountFromJSONTyped(json, false);
}

export function ControlIgnoredVulnerabilitiesCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlIgnoredVulnerabilitiesCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['vulnerabilities']),
        'findings': !exists(json, 'findings') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['findings']),
    };
}

export function ControlIgnoredVulnerabilitiesCountToJSON(value?: ControlIgnoredVulnerabilitiesCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vulnerabilities': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.vulnerabilities),
        'findings': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.findings),
    };
}

