import React from 'react';
import { Typography, TypographyProps } from '@explorer/core';

/**
 * CellEmpty
 */
export const CellEmpty = ({ variant = 'bodyXS' }: CellEmptyProps) => {
  return (
    <Typography variant={variant} noUserSelect>
      -
    </Typography>
  );
};

/**
 * CellEmpty Props
 */
export interface CellEmptyProps extends Pick<TypographyProps, 'variant'> {}
