import React, { ReactNode, useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { InputAdornment, Tooltip } from '@explorer/core';
import { FieldText, FieldTextProps } from '../FieldText';

export const FieldTextWithInfo = ({
  minRows,
  tooltipText,
  spellCheck = false,
  ...props
}: FieldTextWithInfoProps) => {
  const infoAdornment = useMemo<ReactNode>(() => {
    const iconStyles = { color: 'rgba(0, 0, 0, 0.40)' };

    return (
      <InputAdornment position="end">
        <Tooltip title={tooltipText}>
          <InfoIcon style={iconStyles} />
        </Tooltip>
      </InputAdornment>
    );
  }, [tooltipText]);

  return (
    <FieldText
      {...props}
      autoComplete="off"
      minRows={minRows}
      multiline
      InputProps={{
        endAdornment: infoAdornment,
        spellCheck,
      }}
    />
  );
};

export type FieldTextWithInfoProps = FieldTextProps & {
  /**
   * `minRows` - minimum number of rows to display
   */
  minRows: number;
  /**
   * `tooltipText` - localized tooltip content
   */
  tooltipText: IntlLabel;
  /**
   * `spellCheck` - enables spellcheck on this field
   */
  spellCheck?: boolean;
};
