/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlControlComponent
 */
export interface ControlControlComponent {
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    component_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    component_json?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    end_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlControlComponent
     */
    is_trial: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    source_system: string;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlControlComponent
     */
    source_id_field: string;
}

/**
 * Check if a given object implements the ControlControlComponent interface.
 */
export function instanceOfControlControlComponent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "component_name" in value;
    isInstance = isInstance && "is_trial" in value;
    isInstance = isInstance && "source_system" in value;
    isInstance = isInstance && "source_id" in value;
    isInstance = isInstance && "source_id_field" in value;

    return isInstance;
}

export function ControlControlComponentFromJSON(json: any): ControlControlComponent {
    return ControlControlComponentFromJSONTyped(json, false);
}

export function ControlControlComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlControlComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'component_name': json['component_name'],
        'component_json': !exists(json, 'component_json') ? undefined : json['component_json'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'is_trial': json['is_trial'],
        'source_system': json['source_system'],
        'source_id': json['source_id'],
        'source_id_field': json['source_id_field'],
    };
}

export function ControlControlComponentToJSON(value?: ControlControlComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'component_name': value.component_name,
        'component_json': value.component_json,
        'end_date': value.end_date,
        'is_trial': value.is_trial,
        'source_system': value.source_system,
        'source_id': value.source_id,
        'source_id_field': value.source_id_field,
    };
}

