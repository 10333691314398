/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementalFormType } from './SupplementalFormType';
import {
    SupplementalFormTypeFromJSON,
    SupplementalFormTypeFromJSONTyped,
    SupplementalFormTypeToJSON,
} from './SupplementalFormType';
import type { SupplementalLocale } from './SupplementalLocale';
import {
    SupplementalLocaleFromJSON,
    SupplementalLocaleFromJSONTyped,
    SupplementalLocaleToJSON,
} from './SupplementalLocale';

/**
 * 
 * @export
 * @interface SupplementalCreateFormInstanceRequestBody
 */
export interface SupplementalCreateFormInstanceRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    account_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    quote_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    policyholder_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    jira_id?: string | null;
    /**
     * 
     * @type {SupplementalFormType}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    form_type: SupplementalFormType;
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    broker_uuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    broker_email?: string | null;
    /**
     * 
     * @type {SupplementalLocale}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    locale: SupplementalLocale;
    /**
     * Include Submitted Forms to be considered when
     * @type {boolean}
     * @memberof SupplementalCreateFormInstanceRequestBody
     */
    include_submitted?: boolean;
}

/**
 * Check if a given object implements the SupplementalCreateFormInstanceRequestBody interface.
 */
export function instanceOfSupplementalCreateFormInstanceRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "account_uuid" in value;
    isInstance = isInstance && "form_type" in value;
    isInstance = isInstance && "locale" in value;

    return isInstance;
}

export function SupplementalCreateFormInstanceRequestBodyFromJSON(json: any): SupplementalCreateFormInstanceRequestBody {
    return SupplementalCreateFormInstanceRequestBodyFromJSONTyped(json, false);
}

export function SupplementalCreateFormInstanceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalCreateFormInstanceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_uuid': json['account_uuid'],
        'quote_uuid': !exists(json, 'quote_uuid') ? undefined : json['quote_uuid'],
        'policyholder_email': !exists(json, 'policyholder_email') ? undefined : json['policyholder_email'],
        'jira_id': !exists(json, 'jira_id') ? undefined : json['jira_id'],
        'form_type': SupplementalFormTypeFromJSON(json['form_type']),
        'broker_uuid': !exists(json, 'broker_uuid') ? undefined : json['broker_uuid'],
        'broker_email': !exists(json, 'broker_email') ? undefined : json['broker_email'],
        'locale': SupplementalLocaleFromJSON(json['locale']),
        'include_submitted': !exists(json, 'include_submitted') ? undefined : json['include_submitted'],
    };
}

export function SupplementalCreateFormInstanceRequestBodyToJSON(value?: SupplementalCreateFormInstanceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_uuid': value.account_uuid,
        'quote_uuid': value.quote_uuid,
        'policyholder_email': value.policyholder_email,
        'jira_id': value.jira_id,
        'form_type': SupplementalFormTypeToJSON(value.form_type),
        'broker_uuid': value.broker_uuid,
        'broker_email': value.broker_email,
        'locale': SupplementalLocaleToJSON(value.locale),
        'include_submitted': value.include_submitted,
    };
}

