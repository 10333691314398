import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Button,
  Typography,
} from '@explorer/core';
import { FormikProps } from 'formik';
import { SubmitButton } from '../SubmitButton';
import { ResetButton } from '../ResetButton';
import { Form } from '../Form';
import CloseIcon from '@mui/icons-material/Close';

/**
 * FormDialog
 */
export const FormDialog = ({
  formId,
  formik,
  open,
  title,
  subTitle,
  handleClose,
  submitButton = true,
  resetButton = false,
  closeButton = false,
  submitLabel = { id: 'global.submit' },
  resetLabel = { id: 'global.reset' },
  closeLabel = { id: 'global.close' },
  disableSubmit = false,
  loading = false,
  maxWidth = 'md',
  children = null,
  stopPropagation = false,
}: FormDialogProps) => {
  const { palette } = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      stopPropagation={stopPropagation}
    >
      <Form formId={formId} formik={formik}>
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item justifyContent="space-between">
              <Grid item xs={10}>
                <Typography
                  variant="subtitleCapsL"
                  color="textSecondary"
                  textColor={palette.grey[400]}
                  label={title || ''}
                  noUserSelect
                />
                {subTitle && (
                  <Typography
                    variant="bodyXS"
                    color="textSecondary"
                    textColor={palette.grey[400]}
                    label={subTitle}
                    noUserSelect
                  />
                )}
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon style={{ color: palette.grey[400] }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {closeButton && (
            <Button
              variant="text"
              label={closeLabel}
              onClick={handleClose}
              mx={1}
            />
          )}
          {resetButton && (
            <ResetButton
              formId={formId}
              formik={formik}
              variant="text"
              label={resetLabel}
              mx={1}
            />
          )}
          {submitButton && (
            <SubmitButton
              formId={formId}
              formik={formik}
              color="primary"
              label={submitLabel}
              disabled={disableSubmit}
              loading={loading}
              mx={1}
            />
          )}
        </DialogActions>
      </Form>
    </Dialog>
  );
};

/**
 * FormDialog Props
 */
export interface FormDialogProps extends Pick<DialogProps, 'maxWidth'> {
  /**
   * `formId` - the form id, used to generate ids for every input field
   */
  formId: string;
  /**
   * `formik` - the object returned by the `useFormik()` hook
   */
  formik: FormikProps<any>;
  /**
   * `open` - is the dialog open
   */
  open: boolean;
  /**
   * `title` - title of the dialog
   */
  title: IntlLabel;
  /**
   * `subtitle` - subtitle of the dialog
   */
  subTitle?: IntlLabel;
  /**
   * `handleClose` - function that closes the dialog
   */
  handleClose: (e?: any) => void;
  /**
   * `disableSubmit` - disables the submit button
   */
  disableSubmit?: boolean;
  /**
   * `submitButton` - enables the submit button
   */
  submitButton?: boolean;
  /**
   * `resetButton` - enables the reset button
   */
  resetButton?: boolean;
  /**
   * `closeButton` - enables the close button
   */
  closeButton?: boolean;
  /**
   * `submitLabel` - label of the submit button
   */
  submitLabel?: IntlLabel;
  /**
   * `resetLabel` - label of the reset button
   */
  resetLabel?: IntlLabel;
  /**
   * `closeLabel` - label of the close button
   */
  closeLabel?: IntlLabel;
  /**
   * `loading` - if the form is loading
   */
  loading?: boolean;
  /**
   * `stopPropagation` - stop event propagation. Helpful for pages with multiple modals.
   */
  stopPropagation?: boolean;
  /**
   * `children` - content of the form
   */
  children: ReactNode;
}
