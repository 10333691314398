/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityRiskScoreV2
 */
export interface MonitEntityRiskScoreV2 {
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRiskScoreV2
     */
    critical?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRiskScoreV2
     */
    high?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRiskScoreV2
     */
    medium?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRiskScoreV2
     */
    low?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityRiskScoreV2
     */
    total_percentages?: object;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRiskScoreV2
     */
    risk_score_v1?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRiskScoreV2
     */
    risk_score_v2?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRiskScoreV2
     */
    version?: string;
}

/**
 * Check if a given object implements the MonitEntityRiskScoreV2 interface.
 */
export function instanceOfMonitEntityRiskScoreV2(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityRiskScoreV2FromJSON(json: any): MonitEntityRiskScoreV2 {
    return MonitEntityRiskScoreV2FromJSONTyped(json, false);
}

export function MonitEntityRiskScoreV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRiskScoreV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'critical': !exists(json, 'critical') ? undefined : json['critical'],
        'high': !exists(json, 'high') ? undefined : json['high'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'low': !exists(json, 'low') ? undefined : json['low'],
        'total_percentages': !exists(json, 'total_percentages') ? undefined : json['total_percentages'],
        'risk_score_v1': !exists(json, 'risk_score_v1') ? undefined : json['risk_score_v1'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : json['risk_score_v2'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function MonitEntityRiskScoreV2ToJSON(value?: MonitEntityRiskScoreV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'critical': value.critical,
        'high': value.high,
        'medium': value.medium,
        'low': value.low,
        'total_percentages': value.total_percentages,
        'risk_score_v1': value.risk_score_v1,
        'risk_score_v2': value.risk_score_v2,
        'version': value.version,
    };
}

