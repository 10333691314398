import React, { PropsWithChildren } from 'react';
import { AppMonitoring } from '@explorer/core/presets';
import { ErrorPage } from '@explorer/components/shared';
import { appConfig } from '@explorer/src';
import { useUser } from '../../stores/UserStore';

const { DATADOG, SEGMENT, SENTRY } = appConfig;

export const ExplorerMonitoringWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { user } = useUser();

  return (
    <AppMonitoring
      userId={user?.email ?? ''}
      userEmail={user?.email ?? ''}
      userName={user?.name ?? ''}
      datadogConfig={DATADOG ?? {}}
      errorView={ErrorPage}
      segmentKey={SEGMENT ?? ''}
      sentryKey={SENTRY ?? ''}
    >
      {children}
    </AppMonitoring>
  );
};
