import React from 'react';
import clsx from 'clsx';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Badge = withCoreProps<BadgeProps>(
  ({
    children,
    className,
    color = 'default',
    ...props
  }: React.PropsWithChildren<BadgeProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiBadge
        {...props}
        className={clsxClass}
        color={color}
        sx={{
          '& .MuiBadge-badge': {
            bgcolor: (theme) =>
              color === 'default'
                ? theme.palette.mode === 'light'
                  ? theme.palette.neutral.light
                  : theme.palette.neutral.dark
                : 'inherit',
          },
        }}
      >
        {children}
      </MuiBadge>
    );
  },
);

export interface BadgeProps extends MuiBadgeProps, CoreProps {}
