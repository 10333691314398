import React from 'react';
import { ChipSmall, TypographyProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';

/**
 * CellSupplementalFormUserType
 */
export const CellSupplementalFormUserType = ({
  variant = 'bodyXS',
  userType,
}: CellSupplementalFormUserTypeProps) => {
  if (!userType) return <CellEmpty variant={variant} />;

  if (userType === 'EXTERNAL') {
    return <ChipSmall label={userType} color="primary" />;
  }

  return <ChipSmall label={userType} color="neutral" />;
};

/**
 * CellSupplementalFormUserType Props
 */
export interface CellSupplementalFormUserTypeProps
  extends Pick<TypographyProps, 'variant'> {
  readonly userType?: APISupplementalsAccountRecipientLogsUserType;
}
