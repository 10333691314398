/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress
 */
export interface ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress {
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress
     */
    foreign_address?: ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddress;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress interface.
 */
export function instanceOfExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSON(json: any): ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress {
    return ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
    };
}

export function ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddressToJSON(value?: ExecRiskAppCoreModelsScansIRS990ContractorCompensationContractorAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
    };
}

