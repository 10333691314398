/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlInviterResponse } from './ControlInviterResponse';
import {
    ControlInviterResponseFromJSON,
    ControlInviterResponseFromJSONTyped,
    ControlInviterResponseToJSON,
} from './ControlInviterResponse';

/**
 * 
 * @export
 * @interface ControlInvitedByClientResponse
 */
export interface ControlInvitedByClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlInvitedByClientResponse
     */
    user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlInvitedByClientResponse
     */
    registered: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlInvitedByClientResponse
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ControlInvitedByClientResponse
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ControlInvitedByClientResponse
     */
    invitation_email: string;
    /**
     * 
     * @type {ControlInviterResponse}
     * @memberof ControlInvitedByClientResponse
     */
    user: ControlInviterResponse;
}

/**
 * Check if a given object implements the ControlInvitedByClientResponse interface.
 */
export function instanceOfControlInvitedByClientResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "registered" in value;
    isInstance = isInstance && "updated" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "invitation_email" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function ControlInvitedByClientResponseFromJSON(json: any): ControlInvitedByClientResponse {
    return ControlInvitedByClientResponseFromJSONTyped(json, false);
}

export function ControlInvitedByClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlInvitedByClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': json['user_id'],
        'registered': json['registered'],
        'updated': json['updated'],
        'created': json['created'],
        'invitation_email': json['invitation_email'],
        'user': ControlInviterResponseFromJSON(json['user']),
    };
}

export function ControlInvitedByClientResponseToJSON(value?: ControlInvitedByClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'registered': value.registered,
        'updated': value.updated,
        'created': value.created,
        'invitation_email': value.invitation_email,
        'user': ControlInviterResponseToJSON(value.user),
    };
}

