/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Assets } from './Assets';
import {
    AssetsFromJSON,
    AssetsFromJSONTyped,
    AssetsToJSON,
} from './Assets';
import type { MonitAbridgedCyberBenchmark } from './MonitAbridgedCyberBenchmark';
import {
    MonitAbridgedCyberBenchmarkFromJSON,
    MonitAbridgedCyberBenchmarkFromJSONTyped,
    MonitAbridgedCyberBenchmarkToJSON,
} from './MonitAbridgedCyberBenchmark';
import type { MonitEntityDataleaks } from './MonitEntityDataleaks';
import {
    MonitEntityDataleaksFromJSON,
    MonitEntityDataleaksFromJSONTyped,
    MonitEntityDataleaksToJSON,
} from './MonitEntityDataleaks';
import type { MonitEntityMetrics } from './MonitEntityMetrics';
import {
    MonitEntityMetricsFromJSON,
    MonitEntityMetricsFromJSONTyped,
    MonitEntityMetricsToJSON,
} from './MonitEntityMetrics';
import type { MonitEntityRiskScoreV2 } from './MonitEntityRiskScoreV2';
import {
    MonitEntityRiskScoreV2FromJSON,
    MonitEntityRiskScoreV2FromJSONTyped,
    MonitEntityRiskScoreV2ToJSON,
} from './MonitEntityRiskScoreV2';
import type { MonitEntityVulnerabilitiesCount } from './MonitEntityVulnerabilitiesCount';
import {
    MonitEntityVulnerabilitiesCountFromJSON,
    MonitEntityVulnerabilitiesCountFromJSONTyped,
    MonitEntityVulnerabilitiesCountToJSON,
} from './MonitEntityVulnerabilitiesCount';
import type { MonitPolicy } from './MonitPolicy';
import {
    MonitPolicyFromJSON,
    MonitPolicyFromJSONTyped,
    MonitPolicyToJSON,
} from './MonitPolicy';
import type { MonitPolicyMetrics } from './MonitPolicyMetrics';
import {
    MonitPolicyMetricsFromJSON,
    MonitPolicyMetricsFromJSONTyped,
    MonitPolicyMetricsToJSON,
} from './MonitPolicyMetrics';

/**
 * Extends from entity - which includes a large number of values.
 * @export
 * @interface MonitEntityRelationResponse
 */
export interface MonitEntityRelationResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityRelationResponse
     */
    domains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityRelationResponse
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    monit_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityRelationResponse
     */
    data_sources?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityRelationResponse
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {MonitEntityMetrics}
     * @memberof MonitEntityRelationResponse
     */
    metrics?: MonitEntityMetrics;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityRelationResponse
     */
    use_mapping?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    process_id?: string;
    /**
     * 
     * @type {Assets}
     * @memberof MonitEntityRelationResponse
     */
    assets: Assets;
    /**
     * 
     * @type {MonitEntityVulnerabilitiesCount}
     * @memberof MonitEntityRelationResponse
     */
    vulnerabilities?: MonitEntityVulnerabilitiesCount;
    /**
     * 
     * @type {MonitEntityDataleaks}
     * @memberof MonitEntityRelationResponse
     */
    dataleaks?: MonitEntityDataleaks;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationResponse
     */
    lookalikes?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationResponse
     */
    honeypots?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationResponse
     */
    torrents?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityRelationResponse
     */
    risk_score?: number;
    /**
     * 
     * @type {MonitEntityRiskScoreV2}
     * @memberof MonitEntityRelationResponse
     */
    risk_score_v2?: MonitEntityRiskScoreV2;
    /**
     * 
     * @type {MonitEntityRiskScoreV2}
     * @memberof MonitEntityRelationResponse
     */
    previous_risk_score_v2?: MonitEntityRiskScoreV2;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    report_url?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MonitEntityRelationResponse
     */
    localized_reports?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    scanned?: string;
    /**
     * 
     * @type {MonitAbridgedCyberBenchmark}
     * @memberof MonitEntityRelationResponse
     */
    cyber_benchmark?: MonitAbridgedCyberBenchmark;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    external_id?: string;
    /**
     * 
     * @type {MonitPolicy}
     * @memberof MonitEntityRelationResponse
     */
    policy?: MonitPolicy;
    /**
     * 
     * @type {MonitPolicyMetrics}
     * @memberof MonitEntityRelationResponse
     */
    policy_metrics?: MonitPolicyMetrics;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityRelationResponse
     */
    account_line_uuid?: string;
}

/**
 * Check if a given object implements the MonitEntityRelationResponse interface.
 */
export function instanceOfMonitEntityRelationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assets" in value;

    return isInstance;
}

export function MonitEntityRelationResponseFromJSON(json: any): MonitEntityRelationResponse {
    return MonitEntityRelationResponseFromJSONTyped(json, false);
}

export function MonitEntityRelationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityRelationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'monit_type': !exists(json, 'monit_type') ? undefined : json['monit_type'],
        'data_sources': !exists(json, 'data_sources') ? undefined : json['data_sources'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'metrics': !exists(json, 'metrics') ? undefined : MonitEntityMetricsFromJSON(json['metrics']),
        'use_mapping': !exists(json, 'use_mapping') ? undefined : json['use_mapping'],
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'assets': AssetsFromJSON(json['assets']),
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : MonitEntityVulnerabilitiesCountFromJSON(json['vulnerabilities']),
        'dataleaks': !exists(json, 'dataleaks') ? undefined : MonitEntityDataleaksFromJSON(json['dataleaks']),
        'lookalikes': !exists(json, 'lookalikes') ? undefined : json['lookalikes'],
        'honeypots': !exists(json, 'honeypots') ? undefined : json['honeypots'],
        'torrents': !exists(json, 'torrents') ? undefined : json['torrents'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : MonitEntityRiskScoreV2FromJSON(json['risk_score_v2']),
        'previous_risk_score_v2': !exists(json, 'previous_risk_score_v2') ? undefined : MonitEntityRiskScoreV2FromJSON(json['previous_risk_score_v2']),
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'localized_reports': !exists(json, 'localized_reports') ? undefined : json['localized_reports'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'scanned': !exists(json, 'scanned') ? undefined : json['scanned'],
        'cyber_benchmark': !exists(json, 'cyber_benchmark') ? undefined : MonitAbridgedCyberBenchmarkFromJSON(json['cyber_benchmark']),
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'policy': !exists(json, 'policy') ? undefined : MonitPolicyFromJSON(json['policy']),
        'policy_metrics': !exists(json, 'policy_metrics') ? undefined : MonitPolicyMetricsFromJSON(json['policy_metrics']),
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
    };
}

export function MonitEntityRelationResponseToJSON(value?: MonitEntityRelationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'entity_logo_url': value.entity_logo_url,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'monit_type': value.monit_type,
        'data_sources': value.data_sources,
        'keywords': value.keywords,
        'metrics': MonitEntityMetricsToJSON(value.metrics),
        'use_mapping': value.use_mapping,
        'process_id': value.process_id,
        'assets': AssetsToJSON(value.assets),
        'vulnerabilities': MonitEntityVulnerabilitiesCountToJSON(value.vulnerabilities),
        'dataleaks': MonitEntityDataleaksToJSON(value.dataleaks),
        'lookalikes': value.lookalikes,
        'honeypots': value.honeypots,
        'torrents': value.torrents,
        'risk_score': value.risk_score,
        'risk_score_v2': MonitEntityRiskScoreV2ToJSON(value.risk_score_v2),
        'previous_risk_score_v2': MonitEntityRiskScoreV2ToJSON(value.previous_risk_score_v2),
        'report_url': value.report_url,
        'localized_reports': value.localized_reports,
        'status': value.status,
        'created': value.created,
        'updated': value.updated,
        'scanned': value.scanned,
        'cyber_benchmark': MonitAbridgedCyberBenchmarkToJSON(value.cyber_benchmark),
        'external_id': value.external_id,
        'policy': MonitPolicyToJSON(value.policy),
        'policy_metrics': MonitPolicyMetricsToJSON(value.policy_metrics),
        'account_line_uuid': value.account_line_uuid,
    };
}

