/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Datapoints } from './Datapoints';
import {
    DatapointsFromJSON,
    DatapointsFromJSONTyped,
    DatapointsToJSON,
} from './Datapoints';

/**
 * 
 * @export
 * @interface MonitRescanRequest
 */
export interface MonitRescanRequest {
    /**
     * 
     * @type {Datapoints}
     * @memberof MonitRescanRequest
     */
    datapoints?: Datapoints;
    /**
     * 
     * @type {string}
     * @memberof MonitRescanRequest
     */
    requested_by?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitRescanRequest
     */
    trial?: boolean;
}

/**
 * Check if a given object implements the MonitRescanRequest interface.
 */
export function instanceOfMonitRescanRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitRescanRequestFromJSON(json: any): MonitRescanRequest {
    return MonitRescanRequestFromJSONTyped(json, false);
}

export function MonitRescanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitRescanRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': !exists(json, 'datapoints') ? undefined : DatapointsFromJSON(json['datapoints']),
        'requested_by': !exists(json, 'requested_by') ? undefined : json['requested_by'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
    };
}

export function MonitRescanRequestToJSON(value?: MonitRescanRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': DatapointsToJSON(value.datapoints),
        'requested_by': value.requested_by,
        'trial': value.trial,
    };
}

