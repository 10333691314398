import React from 'react';
import { Box, Grid, ProgressLinear } from '@explorer/core';

/**
 * LoadingState description.
 */
export const LoadingState = ({ height = 360 }: LoadingStateProps) => {
  return (
    <Box height={height}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        height={height}
      >
        <Grid item width="100%">
          <ProgressLinear width="100%" height="6px" />
        </Grid>
      </Grid>
    </Box>
  );
};

export interface LoadingStateProps {
  height?: number;
}
