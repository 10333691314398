/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitHealthDatastores } from './MonitHealthDatastores';
import {
    MonitHealthDatastoresFromJSON,
    MonitHealthDatastoresFromJSONTyped,
    MonitHealthDatastoresToJSON,
} from './MonitHealthDatastores';

/**
 * 
 * @export
 * @interface MonitHealthResponse
 */
export interface MonitHealthResponse {
    /**
     * 
     * @type {MonitHealthDatastores}
     * @memberof MonitHealthResponse
     */
    datastores?: MonitHealthDatastores;
    /**
     * 
     * @type {object}
     * @memberof MonitHealthResponse
     */
    apis?: object;
    /**
     * 
     * @type {boolean}
     * @memberof MonitHealthResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitHealthResponse
     */
    hostname?: string;
}

/**
 * Check if a given object implements the MonitHealthResponse interface.
 */
export function instanceOfMonitHealthResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitHealthResponseFromJSON(json: any): MonitHealthResponse {
    return MonitHealthResponseFromJSONTyped(json, false);
}

export function MonitHealthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitHealthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datastores': !exists(json, 'datastores') ? undefined : MonitHealthDatastoresFromJSON(json['datastores']),
        'apis': !exists(json, 'apis') ? undefined : json['apis'],
        'ok': !exists(json, 'ok') ? undefined : json['ok'],
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
    };
}

export function MonitHealthResponseToJSON(value?: MonitHealthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datastores': MonitHealthDatastoresToJSON(value.datastores),
        'apis': value.apis,
        'ok': value.ok,
        'hostname': value.hostname,
    };
}

