/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientSettings } from './ControlClientSettings';
import {
    ControlClientSettingsFromJSON,
    ControlClientSettingsFromJSONTyped,
    ControlClientSettingsToJSON,
} from './ControlClientSettings';
import type { ControlFormSummary } from './ControlFormSummary';
import {
    ControlFormSummaryFromJSON,
    ControlFormSummaryFromJSONTyped,
    ControlFormSummaryToJSON,
} from './ControlFormSummary';

/**
 * 
 * @export
 * @interface ControlGetAllFormsResponse
 */
export interface ControlGetAllFormsResponse {
    /**
     * 
     * @type {Array<ControlFormSummary>}
     * @memberof ControlGetAllFormsResponse
     */
    forms: Array<ControlFormSummary>;
    /**
     * 
     * @type {ControlClientSettings}
     * @memberof ControlGetAllFormsResponse
     */
    client_settings: ControlClientSettings;
}

/**
 * Check if a given object implements the ControlGetAllFormsResponse interface.
 */
export function instanceOfControlGetAllFormsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "forms" in value;
    isInstance = isInstance && "client_settings" in value;

    return isInstance;
}

export function ControlGetAllFormsResponseFromJSON(json: any): ControlGetAllFormsResponse {
    return ControlGetAllFormsResponseFromJSONTyped(json, false);
}

export function ControlGetAllFormsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetAllFormsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forms': ((json['forms'] as Array<any>).map(ControlFormSummaryFromJSON)),
        'client_settings': ControlClientSettingsFromJSON(json['client_settings']),
    };
}

export function ControlGetAllFormsResponseToJSON(value?: ControlGetAllFormsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'forms': ((value.forms as Array<any>).map(ControlFormSummaryToJSON)),
        'client_settings': ControlClientSettingsToJSON(value.client_settings),
    };
}

