/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlResolutionRequestStatus } from './ControlResolutionRequestStatus';
import {
    ControlResolutionRequestStatusFromJSON,
    ControlResolutionRequestStatusFromJSONTyped,
    ControlResolutionRequestStatusToJSON,
} from './ControlResolutionRequestStatus';

/**
 * 
 * @export
 * @interface ControlFixedFindingsResult
 */
export interface ControlFixedFindingsResult {
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    request_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    email?: string;
    /**
     * 
     * @type {ControlResolutionRequestStatus}
     * @memberof ControlFixedFindingsResult
     */
    resolution_status?: ControlResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlFixedFindingsResult
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlFixedFindingsResult
     */
    level?: string;
}

/**
 * Check if a given object implements the ControlFixedFindingsResult interface.
 */
export function instanceOfControlFixedFindingsResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request_id" in value;

    return isInstance;
}

export function ControlFixedFindingsResultFromJSON(json: any): ControlFixedFindingsResult {
    return ControlFixedFindingsResultFromJSONTyped(json, false);
}

export function ControlFixedFindingsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFixedFindingsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request_id': json['request_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'resolution_status': !exists(json, 'resolution_status') ? undefined : ControlResolutionRequestStatusFromJSON(json['resolution_status']),
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'level': !exists(json, 'level') ? undefined : json['level'],
    };
}

export function ControlFixedFindingsResultToJSON(value?: ControlFixedFindingsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_id': value.request_id,
        'email': value.email,
        'resolution_status': ControlResolutionRequestStatusToJSON(value.resolution_status),
        'datapoint': value.datapoint,
        'created': value.created,
        'updated': value.updated,
        'name': value.name,
        'count': value.count,
        'level': value.level,
    };
}

