/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SecReviewFindingsDatapoint = {
    Web: 'web',
    Services: 'services'
} as const;
export type SecReviewFindingsDatapoint = typeof SecReviewFindingsDatapoint[keyof typeof SecReviewFindingsDatapoint];


export function SecReviewFindingsDatapointFromJSON(json: any): SecReviewFindingsDatapoint {
    return SecReviewFindingsDatapointFromJSONTyped(json, false);
}

export function SecReviewFindingsDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewFindingsDatapoint {
    return json as SecReviewFindingsDatapoint;
}

export function SecReviewFindingsDatapointToJSON(value?: SecReviewFindingsDatapoint | null): any {
    return value as any;
}

