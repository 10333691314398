/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitResolutionRequestStatus } from './MonitResolutionRequestStatus';
import {
    MonitResolutionRequestStatusFromJSON,
    MonitResolutionRequestStatusFromJSONTyped,
    MonitResolutionRequestStatusToJSON,
} from './MonitResolutionRequestStatus';

/**
 * 
 * @export
 * @interface MonitMuteRequest
 */
export interface MonitMuteRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitMuteRequest
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitMuteRequest
     */
    datapoint?: string;
    /**
     * 
     * @type {MonitResolutionRequestStatus}
     * @memberof MonitMuteRequest
     */
    status?: MonitResolutionRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof MonitMuteRequest
     */
    requested_by?: string;
}

/**
 * Check if a given object implements the MonitMuteRequest interface.
 */
export function instanceOfMonitMuteRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitMuteRequestFromJSON(json: any): MonitMuteRequest {
    return MonitMuteRequestFromJSONTyped(json, false);
}

export function MonitMuteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitMuteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'status': !exists(json, 'status') ? undefined : MonitResolutionRequestStatusFromJSON(json['status']),
        'requested_by': !exists(json, 'requested_by') ? undefined : json['requested_by'],
    };
}

export function MonitMuteRequestToJSON(value?: MonitMuteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'datapoint': value.datapoint,
        'status': MonitResolutionRequestStatusToJSON(value.status),
        'requested_by': value.requested_by,
    };
}

