import React from 'react';
import clsx from 'clsx';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Avatar = withCoreProps<AvatarProps>(
  ({ children, className, ...props }: React.PropsWithChildren<AvatarProps>) => {
    const clsxClass = clsx(className);

    return (
      <MuiAvatar {...props} className={clsxClass}>
        {children}
      </MuiAvatar>
    );
  },
);

export interface AvatarProps extends MuiAvatarProps, CoreProps {}
