/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFSecReviewBTechsAggregatedRecord
 */
export interface CRSFSecReviewBTechsAggregatedRecord {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    asset: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    category: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    cpe: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    created: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    cves: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    data: object;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    found: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    module: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    port: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    protocol: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    se_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    source_asset: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    tech_key: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    tech_name: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewBTechsAggregatedRecord
     */
    version: string;
}

/**
 * Check if a given object implements the CRSFSecReviewBTechsAggregatedRecord interface.
 */
export function instanceOfCRSFSecReviewBTechsAggregatedRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "cpe" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cves" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "module" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "protocol" in value;
    isInstance = isInstance && "shared" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "source_asset" in value;
    isInstance = isInstance && "tech_key" in value;
    isInstance = isInstance && "tech_name" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function CRSFSecReviewBTechsAggregatedRecordFromJSON(json: any): CRSFSecReviewBTechsAggregatedRecord {
    return CRSFSecReviewBTechsAggregatedRecordFromJSONTyped(json, false);
}

export function CRSFSecReviewBTechsAggregatedRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewBTechsAggregatedRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'category': json['category'],
        'cpe': json['cpe'],
        'created': json['created'],
        'cves': json['cves'],
        'data': json['data'],
        'found': json['found'],
        'module': json['module'],
        'port': json['port'],
        'process_id': json['process_id'],
        'protocol': json['protocol'],
        'se_version': !exists(json, 'se_version') ? undefined : json['se_version'],
        'shared': json['shared'],
        'source': json['source'],
        'source_asset': json['source_asset'],
        'tech_key': json['tech_key'],
        'tech_name': json['tech_name'],
        'version': json['version'],
    };
}

export function CRSFSecReviewBTechsAggregatedRecordToJSON(value?: CRSFSecReviewBTechsAggregatedRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'category': value.category,
        'cpe': value.cpe,
        'created': value.created,
        'cves': value.cves,
        'data': value.data,
        'found': value.found,
        'module': value.module,
        'port': value.port,
        'process_id': value.process_id,
        'protocol': value.protocol,
        'se_version': value.se_version,
        'shared': value.shared,
        'source': value.source,
        'source_asset': value.source_asset,
        'tech_key': value.tech_key,
        'tech_name': value.tech_name,
        'version': value.version,
    };
}

