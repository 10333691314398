/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount } from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import {
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSONTyped,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON,
} from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import type { ControlEntityStatsVulnerabilitiesSpecific } from './ControlEntityStatsVulnerabilitiesSpecific';
import {
    ControlEntityStatsVulnerabilitiesSpecificFromJSON,
    ControlEntityStatsVulnerabilitiesSpecificFromJSONTyped,
    ControlEntityStatsVulnerabilitiesSpecificToJSON,
} from './ControlEntityStatsVulnerabilitiesSpecific';
import type { ControlEntityStatsVulnerabilitiesSsl } from './ControlEntityStatsVulnerabilitiesSsl';
import {
    ControlEntityStatsVulnerabilitiesSslFromJSON,
    ControlEntityStatsVulnerabilitiesSslFromJSONTyped,
    ControlEntityStatsVulnerabilitiesSslToJSON,
} from './ControlEntityStatsVulnerabilitiesSsl';

/**
 * 
 * @export
 * @interface ControlEntityStatsVulnerabilities
 */
export interface ControlEntityStatsVulnerabilities {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityStatsVulnerabilities
     */
    count: number;
    /**
     * 
     * @type {object}
     * @memberof ControlEntityStatsVulnerabilities
     */
    score_categories?: object;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlEntityStatsVulnerabilities
     */
    criticality?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlEntityStatsVulnerabilities
     */
    criticality_sum?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSsl}
     * @memberof ControlEntityStatsVulnerabilities
     */
    ssl?: ControlEntityStatsVulnerabilitiesSsl;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    encryption?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    web?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    iot?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    malware?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    remote?: ControlEntityStatsVulnerabilitiesSpecific;
    /**
     * 
     * @type {ControlEntityStatsVulnerabilitiesSpecific}
     * @memberof ControlEntityStatsVulnerabilities
     */
    storage?: ControlEntityStatsVulnerabilitiesSpecific;
}

/**
 * Check if a given object implements the ControlEntityStatsVulnerabilities interface.
 */
export function instanceOfControlEntityStatsVulnerabilities(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ControlEntityStatsVulnerabilitiesFromJSON(json: any): ControlEntityStatsVulnerabilities {
    return ControlEntityStatsVulnerabilitiesFromJSONTyped(json, false);
}

export function ControlEntityStatsVulnerabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityStatsVulnerabilities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'score_categories': !exists(json, 'score_categories') ? undefined : json['score_categories'],
        'criticality': !exists(json, 'criticality') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['criticality']),
        'criticality_sum': !exists(json, 'criticality_sum') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['criticality_sum']),
        'ssl': !exists(json, 'ssl') ? undefined : ControlEntityStatsVulnerabilitiesSslFromJSON(json['ssl']),
        'encryption': !exists(json, 'encryption') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['encryption']),
        'web': !exists(json, 'web') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['web']),
        'iot': !exists(json, 'iot') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['iot']),
        'malware': !exists(json, 'malware') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['malware']),
        'remote': !exists(json, 'remote') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['remote']),
        'storage': !exists(json, 'storage') ? undefined : ControlEntityStatsVulnerabilitiesSpecificFromJSON(json['storage']),
    };
}

export function ControlEntityStatsVulnerabilitiesToJSON(value?: ControlEntityStatsVulnerabilities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'score_categories': value.score_categories,
        'criticality': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.criticality),
        'criticality_sum': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.criticality_sum),
        'ssl': ControlEntityStatsVulnerabilitiesSslToJSON(value.ssl),
        'encryption': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.encryption),
        'web': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.web),
        'iot': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.iot),
        'malware': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.malware),
        'remote': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.remote),
        'storage': ControlEntityStatsVulnerabilitiesSpecificToJSON(value.storage),
    };
}

