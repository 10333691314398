import React, { FormHTMLAttributes } from 'react';
import clsx from 'clsx';
import { withCoreProps } from '../withCoreProps';
import { CoreProps } from '../types';

export const Form = withCoreProps<FormProps>(
  ({ children, className, ...props }: React.PropsWithChildren<FormProps>) => {
    const clsxClass = clsx(className);

    return (
      <form {...props} className={clsxClass}>
        {children}
      </form>
    );
  },
);

export interface FormProps extends FormHTMLAttributes<any>, CoreProps {}
