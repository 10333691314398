import { Gradient } from '../types';

export const RISK_GRADIENT: Gradient = {
  css: 'linear-gradient(90deg, #19E98F 0%, #FFE865 50.36%, #FA4343 100%)',
  colors: [
    '#22e98d',
    '#3ae989',
    '#51e884',
    '#69e981',
    '#7fe87c',
    '#96e979',
    '#ade874',
    '#c4e86f',
    '#dae86c',
    '#f1e868',
    '#ffe163',
    '#fed060',
    '#febf5d',
    '#fdaf59',
    '#fd9e56',
    '#fc8e52',
    '#fc7d4f',
    '#fc6d4c',
    '#fb5c48',
    '#fa4b45',
  ],
};
