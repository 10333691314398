/**
 * Parse a JWT token to a valid object (or `null` if it fails).
 *
 * __> Example:__
 * ```ts
 * parseJWT('eyJ0IjoiSFMyNTYiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0ZXN0IjoiY29hbGl0aW9uIn0.W1v1qOjJJQm7af08_6fKjXSn-AUGAZAqq3dtL1aWbbA');
 * // { "test": "coalition" }
 * ```
 */
export function parseJWT<T = unknown>(jwtToken: string): T | null {
  try {
    const [, payload] = jwtToken.split('.'); // A JWT token is divided by `.` into 3 sections: Header, Payload and Signature

    return JSON.parse(unicodeBase64Decode(payload)) as T;
  } catch (e) {
    return null;
  }
}

/**
 * Decode a Base64 string.
 *
 * NOTE: The default JavaScript `window.atob` function does
 * not decode unicode functions.
 */
function unicodeBase64Decode(unicodeBase64: string) {
  const base64 = unicodeBase64
    .replace(/\s+/g, '')
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  return decodeURIComponent(
    Array.prototype.map
      .call(window.atob(base64), (c) => {
        const char = `00${c.charCodeAt(0).toString(16)}`;

        return `%${char.slice(-2)}`;
      })
      .join(''),
  );
}
