import React, { memo, useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@explorer/core';
import { useQueryParamTabs } from '@explorer/hooks';

/**
 * TabViewMultiple
 */
export const TabViewMultiple = memo(
  //
  function TabViewMultiple({ defaultView, views }: TabViewMultipleProps) {
    const tabs = views.map(({ view }) => view);
    const { tab: activeView, setTab: setActiveView } = useQueryParamTabs(tabs, {
      defaultTab: defaultView,
    });
    /**
     * Change the View: OnChange
     */
    const onFilterChange = useCallback(
      (event) => {
        const v = event?.target?.value ?? defaultView;

        setActiveView(v);
      },
      [defaultView, setActiveView],
    );

    return (
      <Box py={3}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <RadioGroup row value={activeView} onChange={onFilterChange}>
              {views.map(({ view, viewLabel }) => (
                <FormControlLabel
                  key={view}
                  value={view}
                  label={viewLabel}
                  control={<Radio color="primary" />}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        {views.map(({ view, title, description, renderAction, children }) => {
          const isActiveView = activeView === view;

          if (!isActiveView) return null;

          return (
            <React.Fragment key={view}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" label={title} />
                  {description && (
                    <Typography variant="body2" label={description} mt={2} />
                  )}
                </Grid>
                {renderAction && <Grid item>{renderAction}</Grid>}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item width="100%">
                  {children}
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Box>
    );
  },
);

TabViewMultiple.displayName = 'TabViewMultiple';

/**
 * TabViewMultipleProps
 */
export interface TabViewMultipleProps<V extends string = string> {
  readonly defaultView: V;
  readonly views: TabView<V>[];
}

interface TabView<V extends string = string> {
  readonly view: V;
  readonly viewLabel: React.ReactNode;
  readonly title: IntlLabel;
  readonly description?: IntlLabel;
  readonly renderAction?: JSX.Element[] | JSX.Element | null;
  readonly children: JSX.Element[] | JSX.Element | null;
}
