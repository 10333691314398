/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskHealthzAPIs } from './ExecRiskHealthzAPIs';
import {
    ExecRiskHealthzAPIsFromJSON,
    ExecRiskHealthzAPIsFromJSONTyped,
    ExecRiskHealthzAPIsToJSON,
} from './ExecRiskHealthzAPIs';
import type { ExecRiskHealthzDatastores } from './ExecRiskHealthzDatastores';
import {
    ExecRiskHealthzDatastoresFromJSON,
    ExecRiskHealthzDatastoresFromJSONTyped,
    ExecRiskHealthzDatastoresToJSON,
} from './ExecRiskHealthzDatastores';

/**
 * 
 * @export
 * @interface ExecRiskHealthz
 */
export interface ExecRiskHealthz {
    /**
     * 
     * @type {ExecRiskHealthzDatastores}
     * @memberof ExecRiskHealthz
     */
    datastores?: ExecRiskHealthzDatastores;
    /**
     * 
     * @type {Array<ExecRiskHealthzAPIs>}
     * @memberof ExecRiskHealthz
     */
    apis?: Array<ExecRiskHealthzAPIs>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthz
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthz
     */
    hostname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskHealthz
     */
    ok: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskHealthz
     */
    build_version?: string;
}

/**
 * Check if a given object implements the ExecRiskHealthz interface.
 */
export function instanceOfExecRiskHealthz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ok" in value;

    return isInstance;
}

export function ExecRiskHealthzFromJSON(json: any): ExecRiskHealthz {
    return ExecRiskHealthzFromJSONTyped(json, false);
}

export function ExecRiskHealthzFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskHealthz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datastores': !exists(json, 'datastores') ? undefined : ExecRiskHealthzDatastoresFromJSON(json['datastores']),
        'apis': !exists(json, 'apis') ? undefined : ((json['apis'] as Array<any>).map(ExecRiskHealthzAPIsFromJSON)),
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
        'ok': json['ok'],
        'build_version': !exists(json, 'build_version') ? undefined : json['build_version'],
    };
}

export function ExecRiskHealthzToJSON(value?: ExecRiskHealthz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datastores': ExecRiskHealthzDatastoresToJSON(value.datastores),
        'apis': value.apis === undefined ? undefined : ((value.apis as Array<any>).map(ExecRiskHealthzAPIsToJSON)),
        'timestamp': value.timestamp,
        'hostname': value.hostname,
        'ok': value.ok,
        'build_version': value.build_version,
    };
}

