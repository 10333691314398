/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskScraperSources
 */
export interface ExecRiskScraperSources {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_scraped_linkedin?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_to_scrape_linkedin?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_scraped_glassdoor?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_to_scrape_glassdoor?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_scraped_indeed?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskScraperSources
     */
    jobs_to_scrape_indeed?: number;
}

/**
 * Check if a given object implements the ExecRiskScraperSources interface.
 */
export function instanceOfExecRiskScraperSources(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScraperSourcesFromJSON(json: any): ExecRiskScraperSources {
    return ExecRiskScraperSourcesFromJSONTyped(json, false);
}

export function ExecRiskScraperSourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScraperSources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobs_scraped_linkedin': !exists(json, 'jobs_scraped_linkedin') ? undefined : json['jobs_scraped_linkedin'],
        'jobs_to_scrape_linkedin': !exists(json, 'jobs_to_scrape_linkedin') ? undefined : json['jobs_to_scrape_linkedin'],
        'jobs_scraped_glassdoor': !exists(json, 'jobs_scraped_glassdoor') ? undefined : json['jobs_scraped_glassdoor'],
        'jobs_to_scrape_glassdoor': !exists(json, 'jobs_to_scrape_glassdoor') ? undefined : json['jobs_to_scrape_glassdoor'],
        'jobs_scraped_indeed': !exists(json, 'jobs_scraped_indeed') ? undefined : json['jobs_scraped_indeed'],
        'jobs_to_scrape_indeed': !exists(json, 'jobs_to_scrape_indeed') ? undefined : json['jobs_to_scrape_indeed'],
    };
}

export function ExecRiskScraperSourcesToJSON(value?: ExecRiskScraperSources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobs_scraped_linkedin': value.jobs_scraped_linkedin,
        'jobs_to_scrape_linkedin': value.jobs_to_scrape_linkedin,
        'jobs_scraped_glassdoor': value.jobs_scraped_glassdoor,
        'jobs_to_scrape_glassdoor': value.jobs_to_scrape_glassdoor,
        'jobs_scraped_indeed': value.jobs_scraped_indeed,
        'jobs_to_scrape_indeed': value.jobs_to_scrape_indeed,
    };
}

