/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityPastebinResultMetadata
 */
export interface MonitEntityPastebinResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResultMetadata
     */
    scrape_url?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPastebinResultMetadata
     */
    full_url?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityPastebinResultMetadata
     */
    size?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityPastebinResultMetadata
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the MonitEntityPastebinResultMetadata interface.
 */
export function instanceOfMonitEntityPastebinResultMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityPastebinResultMetadataFromJSON(json: any): MonitEntityPastebinResultMetadata {
    return MonitEntityPastebinResultMetadataFromJSONTyped(json, false);
}

export function MonitEntityPastebinResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityPastebinResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scrape_url': !exists(json, 'scrape_url') ? undefined : json['scrape_url'],
        'full_url': !exists(json, 'full_url') ? undefined : json['full_url'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function MonitEntityPastebinResultMetadataToJSON(value?: MonitEntityPastebinResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scrape_url': value.scrape_url,
        'full_url': value.full_url,
        'size': value.size,
        'tags': value.tags,
    };
}

