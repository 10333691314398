import { Gradient } from '../types';

export const COLD_GRADIENT: Gradient = {
  css: 'linear-gradient(90deg, #6AC9FF 0%, #032F64 100%)',
  colors: [
    '#68c6fc',
    '#63bef4',
    '#5eb7ed',
    '#59afe5',
    '#53a7dd',
    '#4e9fd5',
    '#4998cd',
    '#4490c5',
    '#3e88bd',
    '#3980b6',
    '#3478ad',
    '#2f71a6',
    '#29699e',
    '#256197',
    '#205a8f',
    '#1b5287',
    '#154a80',
    '#104277',
    '#0b3a6f',
    '#053267',
  ],
};
