/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990ScheduleADistributions
 */
export interface ExecRiskAppCoreModelsScansForm990ScheduleADistributions {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_paid_accomplish_exempt_prps_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_pd_in_excess_income_activity_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_administrative_expense_paid_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    exempt_use_assets_acquis_paid_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    qualified_set_aside_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_other_distributions_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_total_annual_distributions_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_distri_attentive_suprt_org_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_distributable_as_adjusted_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansForm990ScheduleADistributions
     */
    cy_distribution_yr_rt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990ScheduleADistributions interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990ScheduleADistributions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionsFromJSON(json: any): ExecRiskAppCoreModelsScansForm990ScheduleADistributions {
    return ExecRiskAppCoreModelsScansForm990ScheduleADistributionsFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990ScheduleADistributions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cy_paid_accomplish_exempt_prps_amt': !exists(json, 'cy_paid_accomplish_exempt_prps_amt') ? undefined : json['cy_paid_accomplish_exempt_prps_amt'],
        'cy_pd_in_excess_income_activity_amt': !exists(json, 'cy_pd_in_excess_income_activity_amt') ? undefined : json['cy_pd_in_excess_income_activity_amt'],
        'cy_administrative_expense_paid_amt': !exists(json, 'cy_administrative_expense_paid_amt') ? undefined : json['cy_administrative_expense_paid_amt'],
        'exempt_use_assets_acquis_paid_amt': !exists(json, 'exempt_use_assets_acquis_paid_amt') ? undefined : json['exempt_use_assets_acquis_paid_amt'],
        'qualified_set_aside_amt': !exists(json, 'qualified_set_aside_amt') ? undefined : json['qualified_set_aside_amt'],
        'cy_other_distributions_amt': !exists(json, 'cy_other_distributions_amt') ? undefined : json['cy_other_distributions_amt'],
        'cy_total_annual_distributions_amt': !exists(json, 'cy_total_annual_distributions_amt') ? undefined : json['cy_total_annual_distributions_amt'],
        'cy_distri_attentive_suprt_org_amt': !exists(json, 'cy_distri_attentive_suprt_org_amt') ? undefined : json['cy_distri_attentive_suprt_org_amt'],
        'cy_distributable_as_adjusted_amt': !exists(json, 'cy_distributable_as_adjusted_amt') ? undefined : json['cy_distributable_as_adjusted_amt'],
        'cy_distribution_yr_rt': !exists(json, 'cy_distribution_yr_rt') ? undefined : json['cy_distribution_yr_rt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990ScheduleADistributionsToJSON(value?: ExecRiskAppCoreModelsScansForm990ScheduleADistributions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cy_paid_accomplish_exempt_prps_amt': value.cy_paid_accomplish_exempt_prps_amt,
        'cy_pd_in_excess_income_activity_amt': value.cy_pd_in_excess_income_activity_amt,
        'cy_administrative_expense_paid_amt': value.cy_administrative_expense_paid_amt,
        'exempt_use_assets_acquis_paid_amt': value.exempt_use_assets_acquis_paid_amt,
        'qualified_set_aside_amt': value.qualified_set_aside_amt,
        'cy_other_distributions_amt': value.cy_other_distributions_amt,
        'cy_total_annual_distributions_amt': value.cy_total_annual_distributions_amt,
        'cy_distri_attentive_suprt_org_amt': value.cy_distri_attentive_suprt_org_amt,
        'cy_distributable_as_adjusted_amt': value.cy_distributable_as_adjusted_amt,
        'cy_distribution_yr_rt': value.cy_distribution_yr_rt,
    };
}

