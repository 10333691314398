/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRATorrent
 */
export interface CRATorrent {
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    infohash: string;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    category?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRATorrent
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    source_asset?: string;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    found: string;
    /**
     * 
     * @type {string}
     * @memberof CRATorrent
     */
    updated: string;
}

/**
 * Check if a given object implements the CRATorrent interface.
 */
export function instanceOfCRATorrent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "infohash" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "found" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRATorrentFromJSON(json: any): CRATorrent {
    return CRATorrentFromJSONTyped(json, false);
}

export function CRATorrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRATorrent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'infohash': json['infohash'],
        'name': json['name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'shared': !exists(json, 'shared') ? undefined : json['shared'],
        'source_asset': !exists(json, 'source_asset') ? undefined : json['source_asset'],
        'found': json['found'],
        'updated': json['updated'],
    };
}

export function CRATorrentToJSON(value?: CRATorrent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': value.asset,
        'infohash': value.infohash,
        'name': value.name,
        'category': value.category,
        'shared': value.shared,
        'source_asset': value.source_asset,
        'found': value.found,
        'updated': value.updated,
    };
}

