/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansBaseFilingHeaderFiler
 */
export interface ExecRiskAppCoreModelsScansBaseFilingHeaderFiler {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderFiler
     */
    ein: string;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderFiler
     */
    us_address?: ExecRiskAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderFiler
     */
    business_name?: ExecRiskAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppCoreModelsScansBaseFilingHeaderFiler
     */
    business_name_control_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansBaseFilingHeaderFiler interface.
 */
export function instanceOfExecRiskAppCoreModelsScansBaseFilingHeaderFiler(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ein" in value;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderFilerFromJSON(json: any): ExecRiskAppCoreModelsScansBaseFilingHeaderFiler {
    return ExecRiskAppCoreModelsScansBaseFilingHeaderFilerFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderFilerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansBaseFilingHeaderFiler {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ein': json['ein'],
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'business_name_control_txt': !exists(json, 'business_name_control_txt') ? undefined : json['business_name_control_txt'],
    };
}

export function ExecRiskAppCoreModelsScansBaseFilingHeaderFilerToJSON(value?: ExecRiskAppCoreModelsScansBaseFilingHeaderFiler | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ein': value.ein,
        'us_address': ExecRiskAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'business_name': ExecRiskAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'business_name_control_txt': value.business_name_control_txt,
    };
}

