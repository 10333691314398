export const extractJiraIssue = (issueKey: string) => {
  const jiraProject = process.env.BE_ENV === 'prod' ? 'OPS-' : 'QAOPS-';

  const regex = new RegExp(`${jiraProject}\\d+`);
  const urlRegex = /\/browse\/(OPS-\d+|QAOPS-\d+)/;

  // Check if the issueKey is a URL
  const urlMatch = issueKey.match(urlRegex);
  if (urlMatch) {
    return urlMatch[1];
  }

  // Check if the issueKey already contains the project prefix
  const match = issueKey.match(regex);
  if (match) {
    return match[0];
  }

  // Otherwise, assume it's just the ticket number
  return `${jiraProject}${issueKey}`;
};
