import React from 'react';
import { Icon, IconProps } from '../../base/Icon';

/**
 * ReputationRepairIcon
 */
export const ReputationRepairIcon = ({
  ...props
}: ReputationRepairIconProps) => {
  return (
    <Icon label="reputation" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15,10.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4C8.2,8.5,9.7,7,11.6,7C13.5,7,15,8.5,15,10.4z M10.1,9.7C10,9.7,10,9.7,10,9.8c-0.1,0.2-0.1,0.4-0.1,0.6c0,1,0.8,1.7,1.7,1.7c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2,0c-0.2,0-0.4,0-0.6,0c-0.8,0-1.5-0.2-2.2-0.6C10.2,9.6,10.2,9.6,10.1,9.7z M25.6,13c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0.1,0.7,0.6,0.5,1c-0.8,2.7-3.5,4.2-6.3,3.7c-2.7-0.6-4.6-3.1-4.3-5.9C16.5,9.1,18.8,7,21.6,7c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8c-1.9,0-3.5,1.5-3.7,3.4c-0.2,1.9,1.1,3.7,3,4.1c1.9,0.4,3.8-0.7,4.3-2.6C25.2,13.3,25.4,13.1,25.6,13z M26.5,8.2c0.1,0,0.2,0.1,0.3,0.2C27,8.5,27,8.7,27,8.9c0,0.2-0.1,0.5-0.2,0.7l-4.2,4.2c-0.6,0.5-1.4,0.5-2,0l-1.3-1.3c-0.3-0.3-0.3-0.8,0-1.2c0.3-0.3,0.8-0.3,1.2,0l1,1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1l3.9-3.9C25.8,8.1,26.2,8.1,26.5,8.2z M11.6,14.5C9,14.5,7,16.5,7,19.1v2.5C7,21.8,7.2,22,7.4,22h1.5c0.1,0,0.2,0.1,0.2,0.2l0.4,4.4c0,0.2,0.2,0.4,0.4,0.4h3.3c0.2,0,0.4-0.2,0.4-0.4l0.4-4.4c0-0.1,0.1-0.2,0.2-0.2h1.5c0.2,0,0.4-0.2,0.4-0.4v-2.5C16.2,16.5,14.1,14.5,11.6,14.5z M12.2,19.4c0,0.3-0.3,0.6-0.6,0.6S11,19.8,11,19.4v-3.3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6V19.4z"
        />
      </g>
    </Icon>
  );
};

/**
 * ReputationRepairIcon Props
 */
export interface ReputationRepairIconProps
  extends Pick<IconProps, 'size' | 'color' | 'circleColor'> {}
