/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlFindingBlocklistScope = {
    Remove: 'remove',
    Ignore: 'ignore'
} as const;
export type ControlFindingBlocklistScope = typeof ControlFindingBlocklistScope[keyof typeof ControlFindingBlocklistScope];


export function ControlFindingBlocklistScopeFromJSON(json: any): ControlFindingBlocklistScope {
    return ControlFindingBlocklistScopeFromJSONTyped(json, false);
}

export function ControlFindingBlocklistScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFindingBlocklistScope {
    return json as ControlFindingBlocklistScope;
}

export function ControlFindingBlocklistScopeToJSON(value?: ControlFindingBlocklistScope | null): any {
    return value as any;
}

