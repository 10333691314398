/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskWatchlistScan } from './ExecRiskWatchlistScan';
import {
    ExecRiskWatchlistScanFromJSON,
    ExecRiskWatchlistScanFromJSONTyped,
    ExecRiskWatchlistScanToJSON,
} from './ExecRiskWatchlistScan';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedEntityWatchlistResponse
 */
export interface ExecRiskPaginatedEntityWatchlistResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEntityWatchlistResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskWatchlistScan>}
     * @memberof ExecRiskPaginatedEntityWatchlistResponse
     */
    results: Array<ExecRiskWatchlistScan>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedEntityWatchlistResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEntityWatchlistResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedEntityWatchlistResponse
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedEntityWatchlistResponse interface.
 */
export function instanceOfExecRiskPaginatedEntityWatchlistResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedEntityWatchlistResponseFromJSON(json: any): ExecRiskPaginatedEntityWatchlistResponse {
    return ExecRiskPaginatedEntityWatchlistResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedEntityWatchlistResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedEntityWatchlistResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskWatchlistScanFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedEntityWatchlistResponseToJSON(value?: ExecRiskPaginatedEntityWatchlistResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskWatchlistScanToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

