/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
 */
export interface ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
     */
    prior_year_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
     */
    current_year_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt interface.
 */
export function instanceOfExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON(json: any): ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    return ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped(json, false);
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prior_year_amt': !exists(json, 'prior_year_amt') ? undefined : json['prior_year_amt'],
        'current_year_amt': !exists(json, 'current_year_amt') ? undefined : json['current_year_amt'],
    };
}

export function ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON(value?: ExecRiskAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prior_year_amt': value.prior_year_amt,
        'current_year_amt': value.current_year_amt,
    };
}

