/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientRoles } from './ControlClientRoles';
import {
    ControlClientRolesFromJSON,
    ControlClientRolesFromJSONTyped,
    ControlClientRolesToJSON,
} from './ControlClientRoles';
import type { ControlControlComponent } from './ControlControlComponent';
import {
    ControlControlComponentFromJSON,
    ControlControlComponentFromJSONTyped,
    ControlControlComponentToJSON,
} from './ControlControlComponent';
import type { ControlLocale } from './ControlLocale';
import {
    ControlLocaleFromJSON,
    ControlLocaleFromJSONTyped,
    ControlLocaleToJSON,
} from './ControlLocale';

/**
 * 
 * @export
 * @interface ControlUserContext
 */
export interface ControlUserContext {
    /**
     * 
     * @type {string}
     * @memberof ControlUserContext
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserContext
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUserContext
     */
    auth0_id?: string;
    /**
     * 
     * @type {ControlLocale}
     * @memberof ControlUserContext
     */
    locale?: ControlLocale;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserContext
     */
    policyholder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserContext
     */
    premium: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserContext
     */
    broker: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlUserContext
     */
    entity_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserContext
     */
    msp: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlUserContext
     */
    managed_by_msp: boolean;
    /**
     * 
     * @type {ControlClientRoles}
     * @memberof ControlUserContext
     */
    client_role: ControlClientRoles;
    /**
     * 
     * @type {string}
     * @memberof ControlUserContext
     */
    client_id: string;
    /**
     * 
     * @type {{ [key: string]: Array<ControlControlComponent>; }}
     * @memberof ControlUserContext
     */
    active_components?: { [key: string]: Array<ControlControlComponent>; };
    /**
     * 
     * @type {{ [key: string]: Array<ControlControlComponent>; }}
     * @memberof ControlUserContext
     */
    expired_components?: { [key: string]: Array<ControlControlComponent>; };
    /**
     * 
     * @type {{ [key: string]: Array<ControlControlComponent>; }}
     * @memberof ControlUserContext
     */
    expiring_subscriptions?: { [key: string]: Array<ControlControlComponent>; };
}

/**
 * Check if a given object implements the ControlUserContext interface.
 */
export function instanceOfControlUserContext(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "policyholder" in value;
    isInstance = isInstance && "premium" in value;
    isInstance = isInstance && "broker" in value;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "msp" in value;
    isInstance = isInstance && "managed_by_msp" in value;
    isInstance = isInstance && "client_role" in value;
    isInstance = isInstance && "client_id" in value;

    return isInstance;
}

export function ControlUserContextFromJSON(json: any): ControlUserContext {
    return ControlUserContextFromJSONTyped(json, false);
}

export function ControlUserContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUserContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'user_id': json['user_id'],
        'auth0_id': !exists(json, 'auth0_id') ? undefined : json['auth0_id'],
        'locale': !exists(json, 'locale') ? undefined : ControlLocaleFromJSON(json['locale']),
        'policyholder': json['policyholder'],
        'premium': json['premium'],
        'broker': json['broker'],
        'entity_id': json['entity_id'],
        'msp': json['msp'],
        'managed_by_msp': json['managed_by_msp'],
        'client_role': ControlClientRolesFromJSON(json['client_role']),
        'client_id': json['client_id'],
        'active_components': !exists(json, 'active_components') ? undefined : json['active_components'],
        'expired_components': !exists(json, 'expired_components') ? undefined : json['expired_components'],
        'expiring_subscriptions': !exists(json, 'expiring_subscriptions') ? undefined : json['expiring_subscriptions'],
    };
}

export function ControlUserContextToJSON(value?: ControlUserContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'user_id': value.user_id,
        'auth0_id': value.auth0_id,
        'locale': ControlLocaleToJSON(value.locale),
        'policyholder': value.policyholder,
        'premium': value.premium,
        'broker': value.broker,
        'entity_id': value.entity_id,
        'msp': value.msp,
        'managed_by_msp': value.managed_by_msp,
        'client_role': ControlClientRolesToJSON(value.client_role),
        'client_id': value.client_id,
        'active_components': value.active_components,
        'expired_components': value.expired_components,
        'expiring_subscriptions': value.expiring_subscriptions,
    };
}

