/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitUpdateAssetReportRequest } from './MonitUpdateAssetReportRequest';
import {
    MonitUpdateAssetReportRequestFromJSON,
    MonitUpdateAssetReportRequestFromJSONTyped,
    MonitUpdateAssetReportRequestToJSON,
} from './MonitUpdateAssetReportRequest';

/**
 * 
 * @export
 * @interface MonitRootUpdateAssetReportRequest
 */
export interface MonitRootUpdateAssetReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitRootUpdateAssetReportRequest
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitRootUpdateAssetReportRequest
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitRootUpdateAssetReportRequest
     */
    reviewed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitRootUpdateAssetReportRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitRootUpdateAssetReportRequest
     */
    external_id?: string;
}

/**
 * Check if a given object implements the MonitRootUpdateAssetReportRequest interface.
 */
export function instanceOfMonitRootUpdateAssetReportRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitRootUpdateAssetReportRequestFromJSON(json: any): MonitRootUpdateAssetReportRequest {
    return MonitRootUpdateAssetReportRequestFromJSONTyped(json, false);
}

export function MonitRootUpdateAssetReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitRootUpdateAssetReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
    };
}

export function MonitRootUpdateAssetReportRequestToJSON(value?: MonitRootUpdateAssetReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'asset': value.asset,
        'reviewed_by': value.reviewed_by,
        'status': value.status,
        'external_id': value.external_id,
    };
}

