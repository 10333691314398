/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYPaidAccomplishExemptPrpsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYPdInExcessIncomeActivityAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYAdministrativeExpensePaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    ExemptUseAssetsAcquisPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    QualifiedSetAsideAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYOtherDistributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYTotalAnnualDistributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYDistriAttentiveSuprtOrgAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYDistributableAsAdjustedAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions
     */
    CYDistributionYrRt?: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CYPaidAccomplishExemptPrpsAmt': !exists(json, 'CYPaidAccomplishExemptPrpsAmt') ? undefined : json['CYPaidAccomplishExemptPrpsAmt'],
        'CYPdInExcessIncomeActivityAmt': !exists(json, 'CYPdInExcessIncomeActivityAmt') ? undefined : json['CYPdInExcessIncomeActivityAmt'],
        'CYAdministrativeExpensePaidAmt': !exists(json, 'CYAdministrativeExpensePaidAmt') ? undefined : json['CYAdministrativeExpensePaidAmt'],
        'ExemptUseAssetsAcquisPaidAmt': !exists(json, 'ExemptUseAssetsAcquisPaidAmt') ? undefined : json['ExemptUseAssetsAcquisPaidAmt'],
        'QualifiedSetAsideAmt': !exists(json, 'QualifiedSetAsideAmt') ? undefined : json['QualifiedSetAsideAmt'],
        'CYOtherDistributionsAmt': !exists(json, 'CYOtherDistributionsAmt') ? undefined : json['CYOtherDistributionsAmt'],
        'CYTotalAnnualDistributionsAmt': !exists(json, 'CYTotalAnnualDistributionsAmt') ? undefined : json['CYTotalAnnualDistributionsAmt'],
        'CYDistriAttentiveSuprtOrgAmt': !exists(json, 'CYDistriAttentiveSuprtOrgAmt') ? undefined : json['CYDistriAttentiveSuprtOrgAmt'],
        'CYDistributableAsAdjustedAmt': !exists(json, 'CYDistributableAsAdjustedAmt') ? undefined : json['CYDistributableAsAdjustedAmt'],
        'CYDistributionYrRt': !exists(json, 'CYDistributionYrRt') ? undefined : json['CYDistributionYrRt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CYPaidAccomplishExemptPrpsAmt': value.CYPaidAccomplishExemptPrpsAmt,
        'CYPdInExcessIncomeActivityAmt': value.CYPdInExcessIncomeActivityAmt,
        'CYAdministrativeExpensePaidAmt': value.CYAdministrativeExpensePaidAmt,
        'ExemptUseAssetsAcquisPaidAmt': value.ExemptUseAssetsAcquisPaidAmt,
        'QualifiedSetAsideAmt': value.QualifiedSetAsideAmt,
        'CYOtherDistributionsAmt': value.CYOtherDistributionsAmt,
        'CYTotalAnnualDistributionsAmt': value.CYTotalAnnualDistributionsAmt,
        'CYDistriAttentiveSuprtOrgAmt': value.CYDistriAttentiveSuprtOrgAmt,
        'CYDistributableAsAdjustedAmt': value.CYDistributableAsAdjustedAmt,
        'CYDistributionYrRt': value.CYDistributionYrRt,
    };
}

