import React from 'react';
import MuiHidden, { HiddenProps as MuiHiddenProps } from '@mui/material/Hidden';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Hidden = withCoreProps<HiddenProps>((props) => {
  const { children, className, ...otherProps } = props;

  return <MuiHidden {...otherProps}>{children}</MuiHidden>;
});

export type HiddenProps = MuiHiddenProps &
  CoreProps & { children?: React.ReactNode };
