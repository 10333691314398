/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityRelationResponse } from './ControlEntityRelationResponse';
import {
    ControlEntityRelationResponseFromJSON,
    ControlEntityRelationResponseFromJSONTyped,
    ControlEntityRelationResponseToJSON,
} from './ControlEntityRelationResponse';

/**
 * 
 * @export
 * @interface ControlPaginatedEntityRelationResponse
 */
export interface ControlPaginatedEntityRelationResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ControlEntityRelationResponse>}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    results?: Array<ControlEntityRelationResponse>;
    /**
     * 
     * @type {object}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    filters: object;
    /**
     * 
     * @type {ControlEntityRelationResponse}
     * @memberof ControlPaginatedEntityRelationResponse
     */
    main_entity?: ControlEntityRelationResponse;
}

/**
 * Check if a given object implements the ControlPaginatedEntityRelationResponse interface.
 */
export function instanceOfControlPaginatedEntityRelationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filters" in value;

    return isInstance;
}

export function ControlPaginatedEntityRelationResponseFromJSON(json: any): ControlPaginatedEntityRelationResponse {
    return ControlPaginatedEntityRelationResponseFromJSONTyped(json, false);
}

export function ControlPaginatedEntityRelationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPaginatedEntityRelationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlEntityRelationResponseFromJSON)),
        'filters': json['filters'],
        'main_entity': !exists(json, 'main_entity') ? undefined : ControlEntityRelationResponseFromJSON(json['main_entity']),
    };
}

export function ControlPaginatedEntityRelationResponseToJSON(value?: ControlPaginatedEntityRelationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlEntityRelationResponseToJSON)),
        'filters': value.filters,
        'main_entity': ControlEntityRelationResponseToJSON(value.main_entity),
    };
}

