/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntitySurfaceAssets
 */
export interface MonitEntitySurfaceAssets {
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySurfaceAssets
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySurfaceAssets
     */
    domains?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySurfaceAssets
     */
    ips?: number;
}

/**
 * Check if a given object implements the MonitEntitySurfaceAssets interface.
 */
export function instanceOfMonitEntitySurfaceAssets(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySurfaceAssetsFromJSON(json: any): MonitEntitySurfaceAssets {
    return MonitEntitySurfaceAssetsFromJSONTyped(json, false);
}

export function MonitEntitySurfaceAssetsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySurfaceAssets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ips': !exists(json, 'ips') ? undefined : json['ips'],
    };
}

export function MonitEntitySurfaceAssetsToJSON(value?: MonitEntitySurfaceAssets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'domains': value.domains,
        'ips': value.ips,
    };
}

