import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Typography, Box } from '@explorer/core';

/**
 * RatingScore
 */
export const RatingScore = ({
  label,
  value,
  size,
  inverseColors = false,
  overwriteColor,
  ...props
}: RatingScoreProps) => {
  const { palette } = useTheme();

  const circleColor = useMemo(() => {
    if (overwriteColor) return overwriteColor;

    if (inverseColors) {
      if (value >= 80) return palette.critical[300];
      if (value >= 50) return palette.high[300];
      if (value >= 20) return palette.medium[300];
      if (value >= 1) return palette.low[300];

      return palette.neutral[300];
    }

    if (value >= 80) return palette.low[300];
    if (value >= 50) return palette.medium[300];
    if (value >= 20) return palette.high[300];
    if (value >= 1) return palette.critical[300];

    return palette.neutral[300];
  }, [value, inverseColors, overwriteColor]);

  const circleSize = useMemo(() => {
    if (size === 'large') return 160;
    if (size === 'medium') return 100;

    return 60;
  }, [size]);

  const fontSize = useMemo(() => {
    if (size === 'large') return '30px';
    if (size === 'medium') return '20px';

    return '14px';
  }, [size]);

  return (
    <Box position="relative" display="inline-flex">
      <Box position="relative">
        <CircularProgress
          variant="determinate"
          {...props}
          value={100}
          style={{
            position: 'absolute',
            color: palette.grey[800],
            width: circleSize,
            height: circleSize,
          }}
        />
        <CircularProgress
          variant="determinate"
          {...props}
          value={value}
          style={{ color: circleColor, width: circleSize, height: circleSize }}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textPrimary"
          fontWeight="bold"
          fontSize={fontSize}
          noUserSelect
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

/**
 * RatingScore Props
 */
export interface RatingScoreProps
  extends Omit<CircularProgressProps, 'size' | 'color'> {
  value: number;
  label: string;
  size: 'small' | 'medium' | 'large';
  inverseColors?: boolean;
  overwriteColor?: string;
}
