import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatMessage } from '@explorer/helpers';
import { Box, BoxProps } from '../Box';
import { Grid } from '../Grid';
import { Typography } from '../Typography';
import { withCoreProps } from '../../withCoreProps';

const useStyles = ({}: Partial<ChipSmallProps>) =>
  makeStyles(
    ({ palette }: Theme) =>
      createStyles({
        chip: {
          borderRadius: 4,
        },
        chipText: {
          fontWeight: 'bold',
          letterSpacing: '0.25px',
          lineHeight: '20px',
          fontSize: '0.7rem',
        },
        primary: {
          backgroundColor: palette.info.main,
          color: '#fff',
        },
        critical: {
          backgroundColor: palette.critical[300],
          color: '#fff',
        },
        high: {
          backgroundColor: palette.high[300],
          color: '#fff',
        },
        medium: {
          backgroundColor: palette.medium[300],
          color: '#fff',
        },
        low: {
          backgroundColor: palette.low[300],
          color: '#fff',
        },
        info: {
          backgroundColor: palette.blue[700],
          color: '#fff',
        },
        neutral: {
          backgroundColor: palette.grey[800],
          color: '#000',
        },
        clickable: {
          cursor: 'pointer',
          transition: 'opacity .1s ease-out',
          opacity: 1,
          '&:hover': {
            opacity: 0.8,
          },
        },
      }),
    { index: 1 },
  )();

export const ChipSmall = withCoreProps<ChipSmallProps>((props) => {
  const {
    label,
    fullWidth = false,
    color = 'neutral',
    onClick,
    className,
    endIcon,
    px = 1,
    ...otherProps
  } = props;

  const intl = useIntl();
  const classes = useStyles({});
  const clsxClass = clsx(
    className,
    classes.chip,
    classes[color],
    onClick ? classes.clickable : undefined,
  );

  return (
    <Grid
      {...otherProps}
      width={fullWidth ? '100%' : 'auto'}
      display="inline-block"
      className={clsxClass}
      textAlign="center"
      onClick={onClick}
      px={px}
      noUserSelect
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      item
    >
      <Typography variant="caption" className={classes.chipText}>
        {formatMessage(intl, typeof label === 'number' ? `${label}` : label)}
      </Typography>
      {endIcon ? (
        <Grid item pl={1} style={{ transform: 'translateY(2px)' }}>
          {endIcon}
        </Grid>
      ) : null}
    </Grid>
  );
});

export interface ChipSmallProps extends BoxProps {
  /**
   * Chip label
   */
  label: IntlLabel | number;
  /**
   * Makes the Smallchip full width
   */
  fullWidth?: boolean;
  /**
   * Which palette to be used
   */
  color?:
    | 'primary'
    | 'critical'
    | 'high'
    | 'medium'
    | 'low'
    | 'info'
    | 'neutral';
  /**
   * End Icon
   */
  endIcon?: any;
}
