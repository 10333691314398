/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlTaskDefinition } from './ControlTaskDefinition';
import {
    ControlTaskDefinitionFromJSON,
    ControlTaskDefinitionFromJSONTyped,
    ControlTaskDefinitionToJSON,
} from './ControlTaskDefinition';

/**
 * This class represents a view of a task, and includes the definition as well as the client specific information
 * @export
 * @interface ControlTaskView
 */
export interface ControlTaskView {
    /**
     * 
     * @type {ControlTaskDefinition}
     * @memberof ControlTaskView
     */
    definition: ControlTaskDefinition;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlTaskView
     */
    completed_attestations: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTaskView
     */
    done: boolean;
}

/**
 * Check if a given object implements the ControlTaskView interface.
 */
export function instanceOfControlTaskView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "completed_attestations" in value;
    isInstance = isInstance && "done" in value;

    return isInstance;
}

export function ControlTaskViewFromJSON(json: any): ControlTaskView {
    return ControlTaskViewFromJSONTyped(json, false);
}

export function ControlTaskViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTaskView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': ControlTaskDefinitionFromJSON(json['definition']),
        'completed_attestations': json['completed_attestations'],
        'done': json['done'],
    };
}

export function ControlTaskViewToJSON(value?: ControlTaskView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': ControlTaskDefinitionToJSON(value.definition),
        'completed_attestations': value.completed_attestations,
        'done': value.done,
    };
}

