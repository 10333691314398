/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityStatsCyberBenchmark
 */
export interface MonitEntityStatsCyberBenchmark {
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsCyberBenchmark
     */
    overall?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsCyberBenchmark
     */
    ransomware?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsCyberBenchmark
     */
    ftf?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityStatsCyberBenchmark
     */
    breach?: object;
}

/**
 * Check if a given object implements the MonitEntityStatsCyberBenchmark interface.
 */
export function instanceOfMonitEntityStatsCyberBenchmark(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityStatsCyberBenchmarkFromJSON(json: any): MonitEntityStatsCyberBenchmark {
    return MonitEntityStatsCyberBenchmarkFromJSONTyped(json, false);
}

export function MonitEntityStatsCyberBenchmarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsCyberBenchmark {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overall': !exists(json, 'overall') ? undefined : json['overall'],
        'ransomware': !exists(json, 'ransomware') ? undefined : json['ransomware'],
        'ftf': !exists(json, 'ftf') ? undefined : json['ftf'],
        'breach': !exists(json, 'breach') ? undefined : json['breach'],
    };
}

export function MonitEntityStatsCyberBenchmarkToJSON(value?: MonitEntityStatsCyberBenchmark | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overall': value.overall,
        'ransomware': value.ransomware,
        'ftf': value.ftf,
        'breach': value.breach,
    };
}

