import * as yup from 'yup';

export const stringFieldRequired = (formatMessage) =>
  yup.string().required(formatMessage({ id: 'forms-presets.error.required' }));

export const referenceTextField = (hrefField, formatMessage) =>
  yup.string().when(hrefField, {
    is: (href: string) => !!href,
    then: stringFieldRequired(formatMessage),
  });

export const referenceHrefField = (textField, formatMessage) =>
  yup.string().when(textField, {
    is: (text: string) => !!text,
    then: stringFieldRequired(formatMessage).url(
      formatMessage({ id: 'forms-presets.error.zero-day-url' }),
    ),
  });
