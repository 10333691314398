import React from 'react';
import { ChipSmall, TypographyProps } from '@explorer/core';
import { CellEmpty } from '../CellEmpty';
import { SupplementalFormType } from '@explorer/open-api-client';

/**
 * CellSupplementalFormType
 */
export const CellSupplementalFormType = ({
  variant = 'bodyXS',
  formType,
}: CellSupplementalFormTypeProps) => {
  if (!formType) return <CellEmpty variant={variant} />;

  return (
    <ChipSmall
      label={formType as string}
      color="primary"
      textTransform="uppercase"
    />
  );
};

/**
 * CellSupplementalFormType Props
 */
export interface CellSupplementalFormTypeProps
  extends Pick<TypographyProps, 'variant'> {
  readonly formType?: SupplementalFormType;
}
