import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  validationSchema,
  FormValues,
  initializeFormSchema,
} from './formSchema';
import { useQuery, endpoints, UseQueryOptions } from '@explorer/api';
import { Grid, Typography, Button } from '@explorer/core';
import { Form, FormFieldControl } from '@explorer/forms';
import { useQuerySnackbar } from '@explorer/hooks';

const formId = 'DeleteUser';

const getExplorerDeleteUserQuery = (userId: string): UseQueryOptions => {
  return {
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1controladmin['/user/[p1]']({
      p1: userId,
    }),
    method: 'DELETE',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  };
};

export const DeleteUser = ({
  userId,
  handleClose,
  refresh,
}: DeleteUserProps) => {
  const { snackSuccess, snackError } = useQuerySnackbar();
  const [confirm, setConfirm] = useState<boolean>(false);
  const queryDeleteUser = useQuery(getExplorerDeleteUserQuery(userId));

  const formik = useFormik<FormValues>({
    validationSchema,
    initialValues: { reason: '' },
    onSubmit: (values, { resetForm, setSubmitting }) => {
      queryDeleteUser
        .submit({
          payload: values,
        })
        .then(({ error }) => {
          if (error) {
            snackError('Delete User Error', error);
            setSubmitting(false);
          } else {
            snackSuccess('Delete User Success');
            resetForm();
            handleClose();
            refresh();
          }
        });
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId,
        formik,
        options: {},
      }),
    [formik],
  );

  return (
    <Form formId={formId} formik={formik} my={2}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.reason} />
        </Grid>
        <Grid item xs={12} textAlign="right">
          {!confirm && (
            <Button
              mx={1}
              color="primary"
              label={{ id: 'global.submit' }}
              onClick={() => setConfirm(true)}
            />
          )}

          {confirm && (
            <>
              <Typography
                variant="bodyS"
                fontWeight="bold"
                component="span"
                label={{
                  id: 'global.are-you-sure',
                }}
              />
              <Button
                ml={1}
                label={{ id: 'global.yes' }}
                onClick={formik.submitForm}
                loading={formik.isSubmitting}
              />
              <Button
                mx={1}
                variant="text"
                onClick={() => {
                  setConfirm(false);
                }}
                label={{ id: 'global.no' }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

export interface DeleteUserProps {
  userId: string;
  handleClose: () => void;
  refresh: () => void;
}
