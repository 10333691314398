/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cpe1 } from './Cpe1';
import {
    Cpe1FromJSON,
    Cpe1FromJSONTyped,
    Cpe1ToJSON,
} from './Cpe1';
import type { Cve1 } from './Cve1';
import {
    Cve1FromJSON,
    Cve1FromJSONTyped,
    Cve1ToJSON,
} from './Cve1';
import type { Value3 } from './Value3';
import {
    Value3FromJSON,
    Value3FromJSONTyped,
    Value3ToJSON,
} from './Value3';

/**
 * 
 * @export
 * @interface MonitEntityAssetTechData
 */
export interface MonitEntityAssetTechData {
    /**
     * 
     * @type {Value3}
     * @memberof MonitEntityAssetTechData
     */
    value?: Value3;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetTechData
     */
    banner?: string;
    /**
     * 
     * @type {Cpe1}
     * @memberof MonitEntityAssetTechData
     */
    cpe?: Cpe1;
    /**
     * 
     * @type {Cve1}
     * @memberof MonitEntityAssetTechData
     */
    cve?: Cve1;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetTechData
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetTechData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityAssetTechData
     */
    product?: string;
}

/**
 * Check if a given object implements the MonitEntityAssetTechData interface.
 */
export function instanceOfMonitEntityAssetTechData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityAssetTechDataFromJSON(json: any): MonitEntityAssetTechData {
    return MonitEntityAssetTechDataFromJSONTyped(json, false);
}

export function MonitEntityAssetTechDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityAssetTechData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : Value3FromJSON(json['value']),
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'cpe': !exists(json, 'cpe') ? undefined : Cpe1FromJSON(json['cpe']),
        'cve': !exists(json, 'cve') ? undefined : Cve1FromJSON(json['cve']),
        'method': !exists(json, 'method') ? undefined : json['method'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'product': !exists(json, 'product') ? undefined : json['product'],
    };
}

export function MonitEntityAssetTechDataToJSON(value?: MonitEntityAssetTechData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': Value3ToJSON(value.value),
        'banner': value.banner,
        'cpe': Cpe1ToJSON(value.cpe),
        'cve': Cve1ToJSON(value.cve),
        'method': value.method,
        'name': value.name,
        'product': value.product,
    };
}

