/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityStatsVulnerabilityCategories } from './MonitEntityStatsVulnerabilityCategories';
import {
    MonitEntityStatsVulnerabilityCategoriesFromJSON,
    MonitEntityStatsVulnerabilityCategoriesFromJSONTyped,
    MonitEntityStatsVulnerabilityCategoriesToJSON,
} from './MonitEntityStatsVulnerabilityCategories';

/**
 * 
 * @export
 * @interface MonitEntityStatsCriticalityCategories
 */
export interface MonitEntityStatsCriticalityCategories {
    /**
     * 
     * @type {MonitEntityStatsVulnerabilityCategories}
     * @memberof MonitEntityStatsCriticalityCategories
     */
    critical: MonitEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilityCategories}
     * @memberof MonitEntityStatsCriticalityCategories
     */
    high: MonitEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilityCategories}
     * @memberof MonitEntityStatsCriticalityCategories
     */
    medium: MonitEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilityCategories}
     * @memberof MonitEntityStatsCriticalityCategories
     */
    low: MonitEntityStatsVulnerabilityCategories;
    /**
     * 
     * @type {MonitEntityStatsVulnerabilityCategories}
     * @memberof MonitEntityStatsCriticalityCategories
     */
    info: MonitEntityStatsVulnerabilityCategories;
}

/**
 * Check if a given object implements the MonitEntityStatsCriticalityCategories interface.
 */
export function instanceOfMonitEntityStatsCriticalityCategories(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "critical" in value;
    isInstance = isInstance && "high" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "low" in value;
    isInstance = isInstance && "info" in value;

    return isInstance;
}

export function MonitEntityStatsCriticalityCategoriesFromJSON(json: any): MonitEntityStatsCriticalityCategories {
    return MonitEntityStatsCriticalityCategoriesFromJSONTyped(json, false);
}

export function MonitEntityStatsCriticalityCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityStatsCriticalityCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'critical': MonitEntityStatsVulnerabilityCategoriesFromJSON(json['critical']),
        'high': MonitEntityStatsVulnerabilityCategoriesFromJSON(json['high']),
        'medium': MonitEntityStatsVulnerabilityCategoriesFromJSON(json['medium']),
        'low': MonitEntityStatsVulnerabilityCategoriesFromJSON(json['low']),
        'info': MonitEntityStatsVulnerabilityCategoriesFromJSON(json['info']),
    };
}

export function MonitEntityStatsCriticalityCategoriesToJSON(value?: MonitEntityStatsCriticalityCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'critical': MonitEntityStatsVulnerabilityCategoriesToJSON(value.critical),
        'high': MonitEntityStatsVulnerabilityCategoriesToJSON(value.high),
        'medium': MonitEntityStatsVulnerabilityCategoriesToJSON(value.medium),
        'low': MonitEntityStatsVulnerabilityCategoriesToJSON(value.low),
        'info': MonitEntityStatsVulnerabilityCategoriesToJSON(value.info),
    };
}

