import { useState, useCallback } from 'react';

/**
 * A hook that encapsulates all the `Dialog` logic.
 *
 * __> Example:__
 * ```tsx
 * const { open, openDialog, toggleDialog, closeDialog } = useDialog();
 * ```
 */

export const useDialog: UseDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = useCallback(() => setOpen(true), []);
  const toggleDialog = useCallback(() => setOpen((v) => !v), []);
  const closeDialog = useCallback(() => setOpen(false), []);

  return {
    open,
    openDialog,
    toggleDialog,
    closeDialog,
  };
};

export type UseDialog = () => {
  open: boolean;
  openDialog: () => void;
  toggleDialog: () => void;
  closeDialog: () => void;
};
