/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecReviewKubernetes
 */
export interface SecReviewKubernetes {
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewKubernetes
     */
    auth_required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecReviewKubernetes
     */
    connected?: boolean;
}

/**
 * Check if a given object implements the SecReviewKubernetes interface.
 */
export function instanceOfSecReviewKubernetes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecReviewKubernetesFromJSON(json: any): SecReviewKubernetes {
    return SecReviewKubernetesFromJSONTyped(json, false);
}

export function SecReviewKubernetesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecReviewKubernetes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth_required': !exists(json, 'auth_required') ? undefined : json['auth_required'],
        'connected': !exists(json, 'connected') ? undefined : json['connected'],
    };
}

export function SecReviewKubernetesToJSON(value?: SecReviewKubernetes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth_required': value.auth_required,
        'connected': value.connected,
    };
}

