/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlTier = {
    Standard: 'Standard',
    Strong: 'Strong',
    BestInClass: 'Best In Class'
} as const;
export type ControlTier = typeof ControlTier[keyof typeof ControlTier];


export function ControlTierFromJSON(json: any): ControlTier {
    return ControlTierFromJSONTyped(json, false);
}

export function ControlTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTier {
    return json as ControlTier;
}

export function ControlTierToJSON(value?: ControlTier | null): any {
    return value as any;
}

