/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cpe } from './Cpe';
import {
    CpeFromJSON,
    CpeFromJSONTyped,
    CpeToJSON,
} from './Cpe';
import type { Cve } from './Cve';
import {
    CveFromJSON,
    CveFromJSONTyped,
    CveToJSON,
} from './Cve';
import type { Value } from './Value';
import {
    ValueFromJSON,
    ValueFromJSONTyped,
    ValueToJSON,
} from './Value';

/**
 * 
 * @export
 * @interface ControlEntityAssetTechData
 */
export interface ControlEntityAssetTechData {
    /**
     * 
     * @type {Value}
     * @memberof ControlEntityAssetTechData
     */
    value?: Value;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetTechData
     */
    banner?: string;
    /**
     * 
     * @type {Cpe}
     * @memberof ControlEntityAssetTechData
     */
    cpe?: Cpe;
    /**
     * 
     * @type {Cve}
     * @memberof ControlEntityAssetTechData
     */
    cve?: Cve;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetTechData
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetTechData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntityAssetTechData
     */
    product?: string;
}

/**
 * Check if a given object implements the ControlEntityAssetTechData interface.
 */
export function instanceOfControlEntityAssetTechData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityAssetTechDataFromJSON(json: any): ControlEntityAssetTechData {
    return ControlEntityAssetTechDataFromJSONTyped(json, false);
}

export function ControlEntityAssetTechDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityAssetTechData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : ValueFromJSON(json['value']),
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'cpe': !exists(json, 'cpe') ? undefined : CpeFromJSON(json['cpe']),
        'cve': !exists(json, 'cve') ? undefined : CveFromJSON(json['cve']),
        'method': !exists(json, 'method') ? undefined : json['method'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'product': !exists(json, 'product') ? undefined : json['product'],
    };
}

export function ControlEntityAssetTechDataToJSON(value?: ControlEntityAssetTechData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': ValueToJSON(value.value),
        'banner': value.banner,
        'cpe': CpeToJSON(value.cpe),
        'cve': CveToJSON(value.cve),
        'method': value.method,
        'name': value.name,
        'product': value.product,
    };
}

