/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlHyperlink } from './ControlHyperlink';
import {
    ControlHyperlinkFromJSON,
    ControlHyperlinkFromJSONTyped,
    ControlHyperlinkToJSON,
} from './ControlHyperlink';

/**
 * 
 * @export
 * @interface ControlZeroDayAlert
 */
export interface ControlZeroDayAlert {
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    zero_day_alert_id: string;
    /**
     * 
     * @type {number}
     * @memberof ControlZeroDayAlert
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    short_risk_description: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    long_risk_description: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    how_to_resolve: string;
    /**
     * 
     * @type {Array<ControlHyperlink>}
     * @memberof ControlZeroDayAlert
     */
    references: Array<ControlHyperlink>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlZeroDayAlert
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    version_message?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    first_detected_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    created_date: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAlert
     */
    updated_date: string;
}

/**
 * Check if a given object implements the ControlZeroDayAlert interface.
 */
export function instanceOfControlZeroDayAlert(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "zero_day_alert_id" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "short_risk_description" in value;
    isInstance = isInstance && "long_risk_description" in value;
    isInstance = isInstance && "how_to_resolve" in value;
    isInstance = isInstance && "references" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "created_date" in value;
    isInstance = isInstance && "updated_date" in value;

    return isInstance;
}

export function ControlZeroDayAlertFromJSON(json: any): ControlZeroDayAlert {
    return ControlZeroDayAlertFromJSONTyped(json, false);
}

export function ControlZeroDayAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zero_day_alert_id': json['zero_day_alert_id'],
        'version': json['version'],
        'name': json['name'],
        'title': json['title'],
        'short_risk_description': json['short_risk_description'],
        'long_risk_description': json['long_risk_description'],
        'how_to_resolve': json['how_to_resolve'],
        'references': ((json['references'] as Array<any>).map(ControlHyperlinkFromJSON)),
        'active': json['active'],
        'version_message': !exists(json, 'version_message') ? undefined : json['version_message'],
        'first_detected_date': !exists(json, 'first_detected_date') ? undefined : json['first_detected_date'],
        'created_date': json['created_date'],
        'updated_date': json['updated_date'],
    };
}

export function ControlZeroDayAlertToJSON(value?: ControlZeroDayAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zero_day_alert_id': value.zero_day_alert_id,
        'version': value.version,
        'name': value.name,
        'title': value.title,
        'short_risk_description': value.short_risk_description,
        'long_risk_description': value.long_risk_description,
        'how_to_resolve': value.how_to_resolve,
        'references': ((value.references as Array<any>).map(ControlHyperlinkToJSON)),
        'active': value.active,
        'version_message': value.version_message,
        'first_detected_date': value.first_detected_date,
        'created_date': value.created_date,
        'updated_date': value.updated_date,
    };
}

