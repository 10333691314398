import React from 'react';
import MuiGrow, { GrowProps as MuiGrowProps } from '@mui/material/Grow';
import { withCoreProps } from '../../withCoreProps';
import { CoreProps } from '../../types';

export const Grow = withCoreProps<GrowProps>(
  ({ children, ...props }: React.PropsWithChildren<GrowProps>) => {
    return <MuiGrow {...props}>{children}</MuiGrow>;
  },
);

export interface GrowProps extends MuiGrowProps, CoreProps {}
