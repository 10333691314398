import { ApiClientParams } from '@explorer/api';

const isValid = (value: any) => {
  return (
    (typeof value === 'string' && value.length > 0) ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  );
};

/**
 * Converts an object to a querystring.
 *
 * __> Example:__
 * ```ts
 * paramsToQuerystring({
 *   name: 'BinaryEdge',
 *   code: [0, 1],
 *   undef: undefined,
 *   nullable: null,
 * });
 * // "?name=BinaryEdge&code=0&code=1"
 * ```
 */

export const paramsToQuerystring: ParamsToQuerystring = (params) => {
  if (!params || !Object.keys(params)?.length) return '';

  let querystring = '?';

  Object.keys(params).forEach((key) => {
    const param = params[key];

    if (Array.isArray(param)) {
      param.forEach((value) => {
        if (isValid(value)) {
          const escaped =
            typeof value === 'string' ? encodeURIComponent(value) : value;
          querystring += `${key}=${escaped}&`;
        }
      });
    } else if (isValid(param)) {
      const escaped =
        typeof param === 'string' ? encodeURIComponent(param) : param;
      querystring += `${key}=${escaped}&`;
    }
  });

  return querystring.slice(0, querystring.length - 1);
};

type ParamsToQuerystring = (params: ApiClientParams | undefined) => string;
