/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRAProcessLocale } from './CRAProcessLocale';
import {
    CRAProcessLocaleFromJSON,
    CRAProcessLocaleFromJSONTyped,
    CRAProcessLocaleToJSON,
} from './CRAProcessLocale';
import type { Domains } from './Domains';
import {
    DomainsFromJSON,
    DomainsFromJSONTyped,
    DomainsToJSON,
} from './Domains';
import type { EntityLogoUrl } from './EntityLogoUrl';
import {
    EntityLogoUrlFromJSON,
    EntityLogoUrlFromJSONTyped,
    EntityLogoUrlToJSON,
} from './EntityLogoUrl';
import type { EntityName } from './EntityName';
import {
    EntityNameFromJSON,
    EntityNameFromJSONTyped,
    EntityNameToJSON,
} from './EntityName';
import type { IpAddresses } from './IpAddresses';
import {
    IpAddressesFromJSON,
    IpAddressesFromJSONTyped,
    IpAddressesToJSON,
} from './IpAddresses';
import type { Metrics1 } from './Metrics1';
import {
    Metrics1FromJSON,
    Metrics1FromJSONTyped,
    Metrics1ToJSON,
} from './Metrics1';
import type { NoResultFlag } from './NoResultFlag';
import {
    NoResultFlagFromJSON,
    NoResultFlagFromJSONTyped,
    NoResultFlagToJSON,
} from './NoResultFlag';
import type { ReportUrl } from './ReportUrl';
import {
    ReportUrlFromJSON,
    ReportUrlFromJSONTyped,
    ReportUrlToJSON,
} from './ReportUrl';
import type { Status1 } from './Status1';
import {
    Status1FromJSON,
    Status1FromJSONTyped,
    Status1ToJSON,
} from './Status1';

/**
 * 
 * @export
 * @interface CRAProcess
 */
export interface CRAProcess {
    /**
     * 
     * @type {string}
     * @memberof CRAProcess
     */
    process_id?: string;
    /**
     * 
     * @type {EntityName}
     * @memberof CRAProcess
     */
    entity_name?: EntityName;
    /**
     * 
     * @type {string}
     * @memberof CRAProcess
     */
    entity_domain: string;
    /**
     * 
     * @type {EntityLogoUrl}
     * @memberof CRAProcess
     */
    entity_logo_url?: EntityLogoUrl;
    /**
     * 
     * @type {Domains}
     * @memberof CRAProcess
     */
    domains?: Domains;
    /**
     * 
     * @type {IpAddresses}
     * @memberof CRAProcess
     */
    ip_addresses?: IpAddresses;
    /**
     * 
     * @type {Metrics1}
     * @memberof CRAProcess
     */
    metrics?: Metrics1;
    /**
     * 
     * @type {string}
     * @memberof CRAProcess
     */
    updated: string;
    /**
     * 
     * @type {NoResultFlag}
     * @memberof CRAProcess
     */
    no_result_flag?: NoResultFlag;
    /**
     * 
     * @type {ReportUrl}
     * @memberof CRAProcess
     */
    report_url?: ReportUrl;
    /**
     * 
     * @type {Status1}
     * @memberof CRAProcess
     */
    status?: Status1;
    /**
     * 
     * @type {CRAProcessLocale}
     * @memberof CRAProcess
     */
    locale?: CRAProcessLocale;
}

/**
 * Check if a given object implements the CRAProcess interface.
 */
export function instanceOfCRAProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_domain" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRAProcessFromJSON(json: any): CRAProcess {
    return CRAProcessFromJSONTyped(json, false);
}

export function CRAProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRAProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : EntityNameFromJSON(json['entity_name']),
        'entity_domain': json['entity_domain'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : EntityLogoUrlFromJSON(json['entity_logo_url']),
        'domains': !exists(json, 'domains') ? undefined : DomainsFromJSON(json['domains']),
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : IpAddressesFromJSON(json['ip_addresses']),
        'metrics': !exists(json, 'metrics') ? undefined : Metrics1FromJSON(json['metrics']),
        'updated': json['updated'],
        'no_result_flag': !exists(json, 'no_result_flag') ? undefined : NoResultFlagFromJSON(json['no_result_flag']),
        'report_url': !exists(json, 'report_url') ? undefined : ReportUrlFromJSON(json['report_url']),
        'status': !exists(json, 'status') ? undefined : Status1FromJSON(json['status']),
        'locale': !exists(json, 'locale') ? undefined : CRAProcessLocaleFromJSON(json['locale']),
    };
}

export function CRAProcessToJSON(value?: CRAProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'entity_name': EntityNameToJSON(value.entity_name),
        'entity_domain': value.entity_domain,
        'entity_logo_url': EntityLogoUrlToJSON(value.entity_logo_url),
        'domains': DomainsToJSON(value.domains),
        'ip_addresses': IpAddressesToJSON(value.ip_addresses),
        'metrics': Metrics1ToJSON(value.metrics),
        'updated': value.updated,
        'no_result_flag': NoResultFlagToJSON(value.no_result_flag),
        'report_url': ReportUrlToJSON(value.report_url),
        'status': Status1ToJSON(value.status),
        'locale': CRAProcessLocaleToJSON(value.locale),
    };
}

