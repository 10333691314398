/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlEntityDataleaksInnerData } from './ControlEntityDataleaksInnerData';
import {
    ControlEntityDataleaksInnerDataFromJSON,
    ControlEntityDataleaksInnerDataFromJSONTyped,
    ControlEntityDataleaksInnerDataToJSON,
} from './ControlEntityDataleaksInnerData';

/**
 * 
 * @export
 * @interface ControlEntityDataleaksAggResult
 */
export interface ControlEntityDataleaksAggResult {
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaksAggResult
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntityDataleaksAggResult
     */
    total_emails?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityDataleaksAggResult
     */
    emails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntityDataleaksAggResult
     */
    features?: Array<string>;
    /**
     * 
     * @type {Array<ControlEntityDataleaksInnerData>}
     * @memberof ControlEntityDataleaksAggResult
     */
    events?: Array<ControlEntityDataleaksInnerData>;
}

/**
 * Check if a given object implements the ControlEntityDataleaksAggResult interface.
 */
export function instanceOfControlEntityDataleaksAggResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntityDataleaksAggResultFromJSON(json: any): ControlEntityDataleaksAggResult {
    return ControlEntityDataleaksAggResultFromJSONTyped(json, false);
}

export function ControlEntityDataleaksAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntityDataleaksAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'total_emails': !exists(json, 'total_emails') ? undefined : json['total_emails'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(ControlEntityDataleaksInnerDataFromJSON)),
    };
}

export function ControlEntityDataleaksAggResultToJSON(value?: ControlEntityDataleaksAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'total_emails': value.total_emails,
        'emails': value.emails,
        'features': value.features,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(ControlEntityDataleaksInnerDataToJSON)),
    };
}

