import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { BoxProps, Grid, Typography } from '@explorer/core';
import { getGradient } from '@explorer/helpers';

const useStyles = ({}: Partial<RiskScoreChipProps>) =>
  makeStyles(
    ({ spacing }: Theme) =>
      createStyles({
        chip: {
          borderRadius: 4,
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },
        chipText: {
          fontWeight: 'bold',
          letterSpacing: '0.25px',
          lineHeight: '20px',
          fontSize: '0.7rem',
          color: '#fff',
        },
        clickable: {
          cursor: 'pointer',
          transition: 'opacity .1s ease-out',
          opacity: 1,
          '&:hover': {
            opacity: 0.8,
          },
        },
      }),
    { index: 1 },
  )();

/**
 * RiskScoreChip
 */
export const RiskScoreChip = ({
  riskScore = 0,
  fullWidth = false,
  onClick,
  ...props
}: RiskScoreChipProps) => {
  const classes = useStyles({});
  const clsxClass = clsx(classes.chip, onClick ? classes.clickable : undefined);

  const score = useMemo<number>(() => Math.round(riskScore), [riskScore]);

  return (
    <Grid
      {...props}
      width={fullWidth ? '100%' : undefined}
      textAlign="center"
      bgcolor={getGradient('riskV2', score)}
      className={clsxClass}
      onClick={onClick}
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      item
    >
      <Typography variant="caption" className={classes.chipText}>
        {score}
      </Typography>
    </Grid>
  );
};

/**
 * RiskScoreChip Props
 */
export interface RiskScoreChipProps extends Omit<BoxProps, 'ref'> {
  riskScore: number;
  fullWidth?: boolean;
}
